@import "../../_breakpoints";

.sales_wrapper {
    width: 100%;
    height: 60vh;
    // height: max-content;
    display: flex;
        flex-direction: column;
        gap: 3.5rem;

    .page_top {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .page_title {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            h6 {
                font-size:2rem ;
            }

            p {
                color: #4c4c4c;
                font-weight: 400;
                font-size: 1.2rem;
            }
        }

        .stats_card {
            display: flex;
            width: 100%;
            gap: 2rem;
            justify-content: space-between;

            .stat {
                padding: 1.5rem;
                width: max-content;
                height: fit-content;
                background: #FFFFFF;
                border: 1px solid #EAECEE;
                border-radius: 12px;
                display: flex;
                gap: 10rem;
                // align-items: center;

                figure {
                    img {
                        width: 22rem;
                        padding-bottom: 2.4rem;
                    }
                }

                span {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    p {
                        font-size: 1.4rem;
                        font-weight: 600;
                        color: #373F47;
                    }

                    h6 {
                        color: #242D35;               
                         }
                }
            }

            .stat_minimal {
                display: flex;
                flex-direction: column;
                // gap: 1rem;
                width: 30%;
                justify-content: space-between;
                margin-left: 3rem;


                span {
                    border: 1px solid #EAECEE;
                    padding: 1rem;
                    border-radius: 1rem;
                }
                

                p {
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: #373F47;
                }

                h6 {
                    color: #242D35;  
                    font-size: 1.6rem;             
                     
                }

                
            }
            
         
        }

    }

    .page_body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: start;

        .action_grid {
            width: 100%;
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-gap: 10px;
            background-color: #f4f1ed;
            padding: 10px;
     
          
          > div {
            background-color: rgba(255, 255, 255, 0.8);
            text-align: center;
            padding: 20px 0;
            align-items: flex-start;
            cursor: pointer;

            p:first-child {
                font-size: 2rem;
                font-weight: 600;
                align-items: flex-start;
            }

            p:last-child {
                font-size: 1.2rem;
                color:#373F47
            }

          }
          
        //   .item1 {
        //     grid-column: 1;
        //   }
    }
        
        .table-wrap {
            width: 100%;
            height: 100%;
            overflow: auto;
    
        }
    }
}

@include xs {

    .sales_wrapper {
        overflow-y: auto;
        padding-bottom: 2rem;
    .page_body {

        .action_grid {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            > div {
                padding: 2rem 3rem;
                width: 100%;
            }
        }
    }
}

.information_wrapper {
    padding: 0 2rem;
}

.withdrawal_wrapper {
    padding: 0 2rem;
}

.table-wrap{
    height: unset !important;
.table {
   max-width: 200% !important;
   width: 200% !important;
   }
}
}