.no-content-reuseable-component {
  width: 100%;
  // min-height: calc(100vh - 17rem);
  display: grid;
  place-items: center;
  background-color: aqua;
  // flex: 1;
  // margin-top: auto;
  // margin-bottom: auto;

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-wrap {
      margin-bottom: 2rem;
      .img-box {
        width: 20rem;
        height: 20rem;
      }
    }

    .big-text {
      font-size: 2.3rem;
      color: #020202;
      font-weight: 800;
    }

    .btn-reuse {
      margin-top: 3rem;
    }

    .small-text-box {
      margin-top: 1rem;

      .small-text {
        font-size: 1.3rem;
        color: #676767;
        line-height: 1.6rem;
        text-align: center;
      }
    }
  }
}
