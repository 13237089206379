.register-left {
  background-color: #ff8f66;
  opacity: 96%;
  background-image: url("../../../assets/img/side-image.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.register-right .register-form-wrapper {
  align-items: flex-start;
  justify-content: flex-start;
}
.register-right .remember-me {
  width: 100%;
  justify-content: space-between;
}
.register-right .register-btn {
  width: 100% !important;
}

@media screen and (max-width: 29.9375em) {
  .register-left {
    display: none;
  }
  .register-right {
    width: 100vw;
    margin-left: 0;
    padding-left: 0;
    padding: 3rem;
  }
  .register-right div {
    width: 100%;
  }
  .register-right .have-account {
    width: 100%;
  }
}
.form-group__black-light .input-group__phone .phone-wrap {
  width: 20%;
}/*# sourceMappingURL=style.css.map */