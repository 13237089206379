@import '../../../_breakpoints';

.register-left {
  background-color: #ff8f66;
  opacity: 96%;
  background-image: url('../../../assets/img/side-image.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.register-right {
  .register-form-wrapper {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .remember-me {
    width: 100%;
    justify-content: space-between;
  }

  .register-btn {
    width: 100% !important;
  }
}

@include xs {
  .register-left {
    display: none;
  }

  .register-right {
    width: 100vw;
    margin-left: 0;
    padding-left: 0;
    padding: 3rem;

    div {
      width: 100%;
    }

    .have-account {
      width: 100%;
    }
  }
}

.form-group__black-light .input-group__phone .phone-wrap {
  width: 20%;
}
