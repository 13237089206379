.sales_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}
.sales_wrapper .page_top {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.sales_wrapper .page_top .page_title {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.sales_wrapper .page_top .page_title h6 {
  font-size: 2rem;
}
.sales_wrapper .page_top .page_title p {
  color: #4c4c4c;
  font-weight: 400;
  font-size: 1.2rem;
}
.sales_wrapper .page_top .stats_card {
  display: flex;
  width: 100%;
  gap: 2rem;
  justify-content: space-between;
}
.sales_wrapper .page_top .stats_card .stat {
  padding: 1.5rem;
  width: -moz-max-content;
  width: max-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #eaecee;
  border-radius: 12px;
  display: flex;
  gap: 10rem;
}
.sales_wrapper .page_top .stats_card .stat span {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.sales_wrapper .page_top .stats_card .stat span p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #373f47;
}
.sales_wrapper .page_top .stats_card .stat span h6 {
  color: #242d35;
}
.sales_wrapper .page_top .stats_card .stat_minimal {
  display: flex;
  flex-direction: column;
  width: 100rem;
  justify-content: space-between;
  margin-left: 3rem;
}
.sales_wrapper .page_top .stats_card .stat_minimal span {
  border: 1px solid #eaecee;
  padding: 1rem;
  border-radius: 1rem;
}
.sales_wrapper .page_top .stats_card .stat_minimal p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #373f47;
}
.sales_wrapper .page_top .stats_card .stat_minimal h6 {
  color: #242d35;
  font-size: 1.6rem;
}
.sales_wrapper .page_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 90%;
  align-items: end;
}
.sales_wrapper .page_body .table-wrap {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  overflow: auto;
}

@media screen and (max-width: 29.9375em) {
  .sales_wrapper {
    padding: 0 2rem;
  }
  .sales_wrapper .stats_card {
    flex-direction: column;
    width: 100%;
  }
  .sales_wrapper .stats_card div:nth-child(2) figure {
    width: 10rem;
  }
  .sales_wrapper .stats_card .stat {
    width: 100% !important;
    justify-content: space-between;
  }
  .sales_wrapper .stats_card .stat_minimal {
    padding: 0;
    margin: 0 !important;
    width: 100% !important;
    gap: 1rem;
  }
}/*# sourceMappingURL=styles.css.map */