.btn {
  background-color: var(--raven-black);
  color: white;
  /* padding: 2.1rem 12.8rem; */
  /* width: 2.4rem;
      height: 62px; */
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 1rem;
  align-items: center;
  flex-wrap: none;
  font-weight: 700;
  line-height: 2rem;
  font-style: normal;
  letter-spacing: -0.01em;
  font-size: 1.6rem;
  border-width: 0px;
  cursor: pointer;

  .icon {
    display: grid;
    place-items: center;
  }
}

.button-secondary {
  background: #f7f8f7;
  border-radius: 6.4rem;
  color: var(--raven-black);
}

.btn_label {
  font-weight: 800;
}

a {
  color: white;
  text-decoration: none; /* no underline */
}

.btn div {
  white-space: nowrap;
}

.btn:disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.btn_disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: rgb(229, 229, 229) !important;
}

.button-tertiary {
  /* background: rgba(255, 255, 255, 0) !important; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 2rem; */
  /* gap: 1rem; */
  color: var(--raven-black);
}

.button-tertiary div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

button:hover {
  -webkit-transform: translateY(-0.1rem);
  transform: translateY(-0.1rem);
}
