.balance {
  height: -moz-fit-content;
  height: fit-content;
  width: 36.6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.balance .mainBalance {
  position: relative;
  height: 22.8rem;
  background-color: #ffe7d6;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
}
.balance .mainBalance .btn-add-money {
  width: 100%;
}
.balance .mainBalance .left {
  margin: 3rem;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
.balance .mainBalance .left h2 {
  font-size: 3.2rem;
  font-weight: normal;
  margin-top: 2rem;
}
.balance .mainBalance .left p {
  font-size: 1.6rem;
}
.balance .mainBalance .right {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
}
.balance .details {
  display: flex;
  margin-top: 3rem;
  width: 100%;
  justify-content: space-between;
}
.balance .details .withdrawn,
.balance .details .pending {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 0.8rem;
  padding: 1rem 2rem;
  align-items: center;
}
.balance .details .withdrawn p,
.balance .details .pending p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #929191;
}
.balance .details .withdrawn h2,
.balance .details .pending h2 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 19px;
  color: #212121;
}
.balance .details .withdrawn .left,
.balance .details .pending .left {
  align-items: flex-start;
  display: flex;
}
.balance .details .withdrawn .left svg,
.balance .details .pending .left svg {
  width: 70%;
}
.balance .details .withdrawn .left.icon,
.balance .details .pending .left.icon {
  color: #df4309;
}
.balance .details .withdrawn .right,
.balance .details .pending .right {
  align-items: start;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
}

.modal_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.modal_content_wrapper .modal_title {
  display: flex;
  flex-direction: column;
}
.modal_content_wrapper .modal_title p {
  font-size: 1.8rem;
  font-weight: 600;
  color: rgba(20, 24, 31, 0.963);
}
.modal_content_wrapper .modal_title span {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.modal_content_wrapper .modal_title span h6 {
  font-weight: 2.4rem;
  color: #df4309;
}
.modal_content_wrapper .modal_title span p {
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
}
.modal_content_wrapper .info_content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.modal_content_wrapper .info_content span h6 {
  font-weight: 500;
  font-size: 1rem;
  color: #4c4c4c;
  text-transform: uppercase;
}
.modal_content_wrapper .info_content span p {
  font-weight: 400;
  font-size: 1.2rem;
}
.modal_content_wrapper .author_section {
  border-top: 0.5px solid #cccccc;
  width: 100%;
  margin-top: 1rem;
  padding-top: 1rem;
}
.modal_content_wrapper .author_section span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.modal_content_wrapper .author_section span h6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #313030;
  gap: 0.5rem;
  text-transform: uppercase;
}
.modal_content_wrapper .author_section span h6 svg {
  height: 1.5rem;
  width: 1.5rem;
  display: grid;
  place-items: center;
}
.modal_content_wrapper .author_section span p {
  font-weight: 400;
  font-size: 1rem;
}/*# sourceMappingURL=style.css.map */