@import '../../_breakpoints';

.mainDashboard {
  display: flex;
  gap: 5rem;
  justify-content: space-between;
  width: 100%;
  padding: 0 3rem;
  overflow: auto;

  .table-wrapper {
    overflow: auto;
  }

  // margin: 3rem;

  .top {
    display: flex;

    .topBalance {
      display: flex;
      height: min-content;

      .submit {
        border-radius: 0.8rem;
        background-color: #3fa302;
        border: none;
      }
    }

    // .topWidget{
    //     margin-left: 70px;
    // }
  }

  .bottom {
    display: flex;
    margin-top: 3rem;

    .bottomWidget {
      margin-top: 4rem;
    }
  }

  .left-dashboard {
    display: flex;
    flex-direction: column;
    
    .widget {
      // border: 0.01rem solid #cccc;
      border-radius: 0.8rem;
      height: 100%;
      position: relative;
      overflow: hidden;
      // width: 100%;
      background-color: white;
      overflow: auto;
      box-shadow: 0px 4px 39px -3px rgba(136, 136, 136, 0.1);

      h2 {
        margin: 2rem;
        font-size: 1.4rem;
        font-weight: 700;
        color: black;
      }

      .wrapper {
        margin: 2rem;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        gap: 2rem;
        width: 100%;

        .comment:not(:last-child) {
          border-bottom: 0.05rem solid #cccccc;
          padding-bottom: 2rem;
          width: 100%;
        }

        .comment {
          margin-bottom: auto;
          color: #212121;
          font-size: 1.2rem !important;
          width: 100%;
          display: flex;
          padding-right: 2rem;
          flex-direction: column;
          gap: 2rem;

          .button {
            justify-content: space-between;
            display: flex;

            button {
              width: 119px;
              height: 38px;
              border-radius: 8px;
              background-color: #3fa30240;
              margin-right: 20px;
              border: none;
            }

            .info {
              margin-left: 20px;
              background-color: transparent;
              border: 1px solid #999999;
            }
          }
        }
      }

      .viewMore {
        border-top: 0.001rem solid #cccccc;
        border-radius: 0.8rem;

        p {
          text-align: center;
          padding: 1rem;
        }
      }
    }
  }
}

.bottom_wrapper {
  margin-top: 3rem;

  .actions {
    border: 0.01rem solid #ccc;
    border-radius: 0.8rem;
  }
}

.table {
  border: 0.1rem solid #e5e6e6;
  border-radius: 0.8rem;
  width: 100%;

  thead {
    tr {
      th {
        font-weight: 700;
        color: #020202;
        font-size: 1.4rem;
        padding: 1.5rem 1rem;
        text-align: left;
      }

      & > *:first-child {
        padding-left: 2rem;
      }

      .last-th {
        width: 5rem;
      }
    }
  }

  tbody {
    & > *:nth-child(odd) {
      background-color: #f7f8f7;
    }

    tr {
      cursor: pointer;
      position: relative;
      z-index: 2;

      & > *:first-child {
        padding-left: 2rem;
        width: 30%;
      }

      &:hover td .arrow-wrap .img-box {
        transform: translateX(1rem);
      }

      &:hover td .arrow-tr {
        transform: translateX(0.5rem);
      }

      td {
        text-align: left;
        padding: 1.5rem 1rem;
        font-size: 1.2rem;
        color: #676767;
        font-weight: 500;

        .table_content_wrap {
          display: flex;
          align-items: center;

          .img-box {
            margin-right: 1rem;
            width: 3rem;
            height: 3rem;
          }
        }

        .status-box {
          display: flex;
          flex-direction: column;

          .status {
            padding: 0.5rem 2rem;
            border-radius: 2rem;
            background-color: #e8fff6;
            align-self: flex-start;
            opacity: 0.6;

            span {
              font-size: 1.2rem;
              text-transform: capitalize;
              font-weight: 700;
            }
          }
        }

        .status-box-not-verified {
          .status {
            background-color: #ffeceb;

            span {
              color: #ff0f00;
            }
          }
        }

        .status-box-pending {
          .status {
            background-color: #ffe6cd;

            span {
              color: #ea872d;
            }
          }
        }

        .status-box-verified {
          .status {
            background-color: #e8fff6;

            span {
              color: #1ace37;
            }
          }
        }

        .img-name-box {
          display: flex;
          align-items: center;

          .img-box {
            width: 2.7rem;
            height: 2.7rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1rem;
          }
        }

        .arrow-wrap {
          .img-box {
            width: 2.7rem;
            height: 2.7rem;
            border-radius: 50%;
            background-color: #f7f8f7;
            display: grid;
            place-items: center;

            .img {
              width: 80%;
              height: 80%;
            }
          }
        }

        .wrap {
          display: flex;
          align-items: center;

          .img-wrap {
            margin-right: 1rem;
            .img-box {
              width: 2.7rem;
              height: 2.7rem;
              border-radius: 50%;
              background-color: #edfdef;
              display: grid;
              place-items: center;

              .img {
                width: 60%;
                height: 60%;
              }
            }

            .red {
              background-color: #ffeceb;
            }
          }
        }

        .action-copy-box {
          display: flex;
          align-items: center;
          position: relative;
          // background-color: red;
          width: 8rem;

          .action-box {
            margin-left: 1.5rem;
            margin-left: auto;
            position: relative;

            .icon-box {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              background-color: #fff6ed;
              display: grid;
              place-items: center;
              cursor: pointer;
              transition: all 0.3s;
            }

            .drop-down-box {
              position: absolute;
              top: 100%;
              right: 0;
              padding: 1rem 0rem;
              background-color: var(--white-color);
              box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);
              border-radius: 1rem;
              z-index: 3;
              transform: scale(0);
              transition: all 0.4s ease-in-out;
              transform-origin: top right;
              display: flex;
              flex-direction: column;
              width: 23rem;
              // background-color: red;
              // height: 23rem !important;

              & > *:not(:last-child) {
                border-bottom: 0.1rem solid #e5e6e6;
              }

              .box {
                display: flex;
                align-items: center;
                padding: 1.2rem 1.5rem;
                cursor: pointer;

                .img-box {
                  width: 3rem;
                  margin-right: 1rem;
                  height: 3rem;
                  border-radius: 50%;
                  background-color: #f5f5f5;
                  display: grid;
                  place-items: center;

                  .img {
                    width: 50%;
                    height: 50%;
                    object-fit: contain;
                  }
                }

                .img-box-two {
                  .img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .delete {
                .img-box {
                  background-color: #ffeceb;
                }

                .text {
                  color: #ff0f00;
                }
              }
            }

            .drop-down-box-show {
              transform: scale(1);
              // height: 100%;
            }

            &:hover {
              // transform: scale(1.15);
            }
          }

          .copy-icon-box {
            // margin-right: 1.5rem;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: #e9f5ff;
            display: grid;
            place-items: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.15);
            }

            .img-box {
              width: 1.6rem;
              height: 1.6rem;
            }
          }

          .copy-icon-box-copied {
            width: 2.6rem;
            height: 2.6rem;
            border-radius: 50%;
            background-color: #014345;
            position: relative;
            // margin-right: 1.5rem;
            // transform: translateX(-110%);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) translateX(0.25rem);

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .icon {
                color: var(--white-color);
                width: 1rem;
                height: 1rem;
                margin-top: 0rem;
                transform: unset;
                margin-left: unset;
              }
            }
          }
        }

        .arrow-tr {
          width: 3rem;
          height: 3rem;
          background-color: #e9f5ff;
          border-radius: 50%;
          display: grid;
          place-items: center;
          transition: all 0.3s;

          .icon {
            width: 1rem;
            height: 1rem;
            color: #476885;
          }
        }
      }
    }
  }
}

.home-dash-ad {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  p:first-child {
    font-size: 2.2rem;
    font-weight: 600;
    width: 70%;
  }

  span {
    font-size: 1.4rem;
    width: 70%;
    color: rgba(0, 0, 0, 0.534);
  }

  .btn-create {
    font-size: 1.2rem;
    color: black;
    font-weight: 600;
    align-items: center;
    display: flex;
    gap: 1rem;
  }
}

@include xs {
  .mainDashboard {
    flex-direction: column;
    width: 100%;

    .right {
      width: 100%;

      .balance {
        width: 100%;
      }
    }
  }
}
