.create_post_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.create_post_wrapper .create_card {
  width: 80%;
  height: 100%;
  overflow: hidden;
  margin-top: 3rem;
  box-shadow: 0px 4px 39px -3px rgba(136, 136, 136, 0.1);
}
.create_post_wrapper .header {
  padding: 3rem;
  border-bottom: 0.1rem solid #eee;
}
.create_post_wrapper .header h6 {
  color: rgb(25, 25, 25);
}
.create_post_wrapper .header p {
  font-weight: 400;
  font-size: 1.2rem;
  color: rgb(126, 126, 126);
}
.create_post_wrapper .post_body {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  overflow-y: scroll !important;
  flex-direction: column;
  padding: 3rem;
  flex: 1;
  height: 80%;
  justify-content: flex-start;
}
.create_post_wrapper .post_body .grouped-input-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  z-index: 3000;
}
.create_post_wrapper .post_body .input-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.4rem;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
}/*# sourceMappingURL=style.css.map */