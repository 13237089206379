.mobile_header {
  display: none;
  visibility: hidden;
}

.timeline-mobile {
  display: none;
}

.latest_header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.latest_header p {
  font-size: 2rem;
  font-weight: 700;
}

.ask_input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  GAP: 2rem;
}

.desktop-timeline {
  margin: 0 auto;
  min-width: 40%;
  display: flex;
  border: 0.5px solid #cccccc;
  gap: 2rem;
  padding: 2rem;
  border-radius: 2rem;
  flex-direction: column;
}
.desktop-timeline .author_section {
  display: flex;
  justify-content: space-between;
}
.desktop-timeline .advert {
  display: flex;
  width: auto;
  margin-left: auto;
  align-self: flex-end;
  justify-self: flex-end;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  background-color: rgba(128, 0, 128, 0.207);
}
.desktop-timeline .advert p {
  color: rgb(125, 7, 125);
  font-size: 1.4rem;
  font-weight: 700;
}
.desktop-timeline .info_price {
  display: flex;
  height: 4rem;
  color: orange;
  width: auto;
  margin-left: auto;
  align-self: flex-end;
  justify-self: flex-end;
}
.desktop-timeline .info_price h6 {
  font-size: 1.6rem;
}
.desktop-timeline .profile {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
}
.desktop-timeline .profile .name p {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(44, 44, 44);
}
.desktop-timeline .profile .avatar {
  width: 5rem;
  height: 5rem;
}
.desktop-timeline .profile .avatar img {
  border-radius: 50%;
}

.dashboard-view {
  border: none;
  width: 100%;
}

.advert {
  display: flex;
  width: auto;
  margin-left: auto;
  align-self: flex-end;
  justify-self: flex-end;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.8rem;
  background-color: rgba(128, 0, 128, 0.207);
}
.advert p {
  color: rgb(125, 7, 125);
  font-size: 1.4rem;
  font-weight: 700;
}

@media screen and (max-width: 29.9375em) {
  .hero-navbar .nav-right {
    display: none;
  }
  .hero-navbar .nav-left {
    display: none;
  }
  .hero-navbar .mobile-hamburger {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .hero-navbar .mobile-hamburger figure svg {
    font-size: 5rem;
  }
  .desktop-timeline {
    display: none;
  }
  .timeline-mobile {
    display: flex;
    width: 100%;
    border: 0.5px solid #cccccc;
    gap: 2rem;
    padding: 2rem;
    border-radius: 2rem;
    flex-direction: column;
  }
  .timeline-mobile .author_section {
    display: flex;
    justify-content: space-between;
  }
  .timeline-mobile .info_price {
    display: flex;
    height: 4rem;
    color: orange;
    width: auto;
    margin-left: auto;
    align-self: flex-end;
    justify-self: flex-end;
  }
  .timeline-mobile .info_price h6 {
    font-size: 1.6rem;
  }
  .timeline-mobile .profile {
    display: flex;
    gap: 1rem;
    width: 100%;
    height: -moz-max-content;
    height: max-content;
  }
  .timeline-mobile .profile .name p {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgb(44, 44, 44);
  }
  .timeline-mobile .profile .avatar {
    width: 5rem;
    height: 5rem;
  }
  .timeline-mobile .profile .avatar img {
    border-radius: 50%;
  }
  .mobile_header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    visibility: visible;
    color: white;
    padding: 1rem 2rem;
  }
  .mobile_header figure {
    width: 10rem;
  }
  .mobile_header .menu_icon {
    width: 3rem;
    height: 3rem;
  }
  .mobile_header .menu_icon svg {
    width: 100%;
    height: 100%;
  }
  .hero-text-container h5 {
    width: unset;
  }
  .hero-text-container p {
    width: unset;
  }
  .hero-text-container .search-container {
    width: unset;
  }
  .hero-main-container {
    height: -moz-max-content !important;
    height: max-content !important;
    padding-bottom: 10rem;
  }
}
@media screen and (min-width: 30em) and (max-width: 44.9375em) {
  .desktop-timeline {
    min-width: 70%;
  }
}
@media screen and (min-width: 60em) and (max-width: 74.9375em) {
  .desktop-timeline {
    min-width: 50%;
  }
}/*# sourceMappingURL=style.css.map */