.invite_banner {
  height: -moz-max-content;
  height: max-content;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 90% center;
  background-image: url("../../src/assets/img/banner.png");
}
.invite_banner h5 {
  text-transform: uppercase;
}

.img-box svg.img.dull-bright-filter {
  stroke: black;
}/*# sourceMappingURL=style.css.map */