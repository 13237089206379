.w.invite_banner {
  height: -moz-max-content;
  height: max-content;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 90% center;
  background-image: url("../../../src/assets/img/banner.png");
}
.w.invite_banner h5 {
  text-transform: uppercase;
}

@media screen and (max-width: 29.9375em) {
  .desktop-feed {
    display: none;
  }
}/*# sourceMappingURL=style.css.map */