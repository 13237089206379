:root {
  --white: #ffffff;
  --black: #1c100b;
  --gray: #85888c;
}

.mobile_header {
  display: none;
  visibility: hidden;
}

@media screen and (max-width: 29.9375em) {
  .desktop-header {
    display: none;
    visibility: hidden;
  }
  .mobile_sidebar {
    width: 100%;
    max-height: 80%;
    overflow: hidden;
  }
  .mobile_sidebar div:first-child {
    width: 100%;
  }
  .mobile_sidebar div:first-child div:last-child {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 20rem;
  }
  .mobile_header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    visibility: visible;
    color: white;
    padding: 1rem 2rem;
    /* Nav menu */
    /* Menu Icon */
    /* Style label tag */
    /* Hide checkbox */
    /* Toggle menu icon */
  }
  .mobile_header a {
    text-decoration: none;
  }
  .mobile_header ul {
    list-style: none;
  }
  .mobile_header figure {
    width: 10rem;
  }
  .mobile_header .menu_icon {
    width: 3rem;
    height: 3rem;
  }
  .mobile_header .menu_icon svg {
    width: 100%;
    height: 100%;
  }
  .mobile_header .nav {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--black);
    overflow: hidden;
    top: 0;
    max-height: 0;
    z-index: 4;
    transition: max-height 0.5s ease-out;
  }
  .mobile_header .nav .menu_title {
    margin-top: 10rem;
    padding-left: 4rem;
  }
  .mobile_header .nav .menu_title p {
    font-size: 2rem;
    font-weight: 600;
  }
  .mobile_header .nav .action_title {
    margin-top: 3rem;
    padding-left: 4rem;
  }
  .mobile_header .nav .action_title p {
    font-size: 2rem;
    font-weight: 600;
  }
  .mobile_header .nav .nav_act_btn {
    display: flex;
    flex-direction: row;
    padding: 2rem 4rem;
    gap: 2rem;
  }
  .mobile_header .nav .menu {
    margin-top: 2rem;
  }
  .mobile_header .nav .menu li {
    display: block;
    padding: 2rem 3rem;
    font-size: 1.4rem;
    color: var(--white);
  }
  .mobile_header .nav .menu li:hover {
    background-color: orange;
  }
  .mobile_header .hamb {
    cursor: pointer;
    float: right;
    z-index: 2000;
    /* Style span tag */
  }
  .mobile_header .hamb .hamb-line {
    background: var(--white);
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
  }
  .mobile_header .hamb .hamb-line::before,
  .mobile_header .hamb .hamb-line::after {
    background: var(--white);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }
  .mobile_header .hamb .hamb-line::before {
    top: 5px;
  }
  .mobile_header .hamb .hamb-line::after {
    top: -5px;
  }
  .mobile_header .side-menu {
    display: none;
  }
  .mobile_header .side-menu:checked ~ nav {
    max-height: 100%;
  }
  .mobile_header .side-menu:checked ~ .hamb .hamb-line {
    background: transparent;
  }
  .mobile_header .side-menu:checked ~ .hamb .hamb-line::before {
    transform: rotate(-45deg);
    top: 0;
  }
  .mobile_header .side-menu:checked ~ .hamb .hamb-line::after {
    transform: rotate(45deg);
    top: 0;
  }
}/*# sourceMappingURL=style.css.map */