.no-content-reuseable-component {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: aqua;
}
.no-content-reuseable-component .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-content-reuseable-component .wrap .img-wrap {
  margin-bottom: 2rem;
}
.no-content-reuseable-component .wrap .img-wrap .img-box {
  width: 20rem;
  height: 20rem;
}
.no-content-reuseable-component .wrap .big-text {
  font-size: 2.3rem;
  color: #020202;
  font-weight: 800;
}
.no-content-reuseable-component .wrap .btn-reuse {
  margin-top: 3rem;
}
.no-content-reuseable-component .wrap .small-text-box {
  margin-top: 1rem;
}
.no-content-reuseable-component .wrap .small-text-box .small-text {
  font-size: 1.3rem;
  color: #676767;
  line-height: 1.6rem;
  text-align: center;
}/*# sourceMappingURL=NoContentReuseable.css.map */