.sales_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
        flex-direction: column;
        gap: 3.5rem;

    .page_top {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .page_title {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            h6 {
                font-size:2rem ;
            }

            p {
                color: #4c4c4c;
                font-weight: 400;
                font-size: 1.2rem;
            }
        }

        .stats_card {
            display: flex;
            width: 100%;
            gap: 2rem;
            justify-content: space-between;

            .stat {
                padding: 1.5rem;
                width: max-content;
                height: fit-content;
                background: #FFFFFF;
                border: 1px solid #EAECEE;
                border-radius: 12px;
                display: flex;
                gap: 10rem;
                // align-items: center;

                span {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    p {
                        font-size: 1.4rem;
                        font-weight: 600;
                        color: #373F47;
                    }

                    h6 {
                        color: #242D35;               
                         }
                }
            }

            .stat_minimal {
                display: flex;
                flex-direction: column;
                // gap: 1rem;
                width: 100rem;
                justify-content: space-between;
                margin-left: 3rem;


                span {
                    border: 1px solid #EAECEE;
                    padding: 1rem;
                    border-radius: 1rem;
                }
                

                p {
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: #373F47;
                }

                h6 {
                    color: #242D35;  
                    font-size: 1.6rem;             
                     
                }

                
            }
            
         
        }

    }

    .page_body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: end;
        
        .table-wrap {
            width: 100%;
            height: max-content;
            overflow: auto;

    
        }
    }
}