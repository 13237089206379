.login-left {
  background-color: #ff8f66;
  opacity: 96%;
  background-image: url("../../../assets/img/side-image.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.login-right .login-form-wrapper {
  align-items: flex-start;
  justify-content: flex-start;
}
.login-right .remember-me {
  width: 100%;
  justify-content: space-between;
}
.login-right .login-btn {
  width: 100% !important;
}

@media screen and (max-width: 29.9375em) {
  .login-left {
    display: none;
  }
  .login-right {
    width: 100vw;
    margin-left: 0;
    padding-left: 0;
    padding: 3rem;
  }
  .login-right div {
    width: 100%;
  }
  .login-right .have-account {
    width: 100%;
  }
}/*# sourceMappingURL=style.css.map */