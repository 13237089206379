@import '../../../_breakpoints';

.settings_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .page_top {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .page_title {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h6 {
        font-size: 2rem;
      }

      p {
        color: #4c4c4c;
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
  }

  .page_body {
    width: 100%;
    max-height: 90%;
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;

    .settings_card_wrapper {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 18.4818px rgba(170, 170, 170, 0.16),
        0px 6.16058px 12.3212px rgba(194, 194, 194, 0.16);
      border-radius: 6.16058px;
      padding: 2rem;

      .settings_bar {
        display: flex;
        align-items: center;
        gap: 5rem;
        border-bottom: 0.5px solid #cccccc;

        p {
          padding: 1rem;
          font-size: 1.4rem;
          font-weight: 400;
          color: #717b8c;
          cursor: pointer;

          &.active {
            border-bottom: 2px solid #d74632;
            font-weight: 600;
            color: #d74632;
            font-size: 1.4rem;
          }
        }
      }

      .settings_main_wrapper {
        display: flex;
        width: 100%;
        overflow: hidden;
        height: 100%;

        section {
          display: flex;
          padding-top: 3rem;
          overflow: auto;
          height: 90%;
          padding-bottom: 3rem;
        }

        .account_setting {
          flex-direction: column;
          width: 100%;
          gap: 3rem;
          padding-bottom: 3rem;

          .profile_img {
            width: 30%;
          }

          .title_wrapper {
            display: flex;
            flex-direction: column;

            .title {
              font-size: 1.6rem;
              font-weight: 600;
              color: rgb(45, 45, 45);
            }

            .sub_title {
              font-size: 1.2rem;
              font-weight: 300;
              color: #717b8c;
            }
          }

          .form_group {
            width: 100%;
            display: flex;
            gap: 3rem;
            justify-content: space-between;

            .left,
            .right {
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 3rem;
            }
          }

          .btn_group {
            display: flex;
            gap: 3rem;
            align-items: center;

            .reset_btn {
              cursor: pointer;
              font-weight: 500;
              font-size: 1.4rem;
              color: rgb(45, 45, 45);
            }
          }
        }

        .security {
          gap: 2rem;

          .btn_group {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

@include xs {
  .settings_wrapper {
    padding: 0 2rem;

    .page_body {
      width: 100%;

      .settings_card_wrapper {
        width: 100%;

        .account_setting {
          .form-group,
          .form_group {
            width: 100% !important;
          }

          .form_group {
            .left {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
