@import '../../../_breakpoints';
:root {
  --white: #ffffff;
  --black: #1c100b;
  --gray: #85888c;
}
.mobile_header {
  display: none;
  visibility: hidden;
}

@include xs {
  .desktop-header {
    display: none;
    visibility: hidden;
  }

  .mobile_sidebar {
    width: 100%;
    max-height: 80%;
    overflow: hidden;

    div:first-child {
      width: 100%;

      div:last-child {
        overflow-y: auto;
        height: 100%;
        padding-bottom: 20rem;
      }
    }
  }

  .mobile_header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    visibility: visible;
    color: white;
    padding: 1rem 2rem;

    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
    }

    figure {
      width: 10rem;
    }

    .menu_icon {
      width: 3rem;
      height: 3rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    /* Nav menu */
    .nav {
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: var(--black);
      overflow: hidden;
      top: 0;
      max-height: 0;
      z-index: 4;
      transition: max-height 0.5s ease-out;

      .menu_title {
        margin-top: 10rem;
        padding-left: 4rem;

        p {
          font-size: 2rem;
          font-weight: 600;
        }
      }

      .action_title {
        margin-top: 3rem;
        padding-left: 4rem;

        p {
          font-size: 2rem;
          font-weight: 600;
        }
      }

      .nav_act_btn {
        display: flex;
        flex-direction: row;
        padding: 2rem 4rem;
        gap: 2rem;
      }

      .menu {
        margin-top: 2rem;

        li {
          display: block;
          padding: 2rem 3rem;
          font-size: 1.4rem;
          color: var(--white);
        }
        li:hover {
          background-color: orange;
        }
      }
    }

    /* Menu Icon */
    .hamb {
      cursor: pointer;
      float: right;
      // padding: 40px 20px;
      z-index: 2000;

      .hamb-line {
        background: var(--white);
        display: block;
        height: 2px;
        position: relative;
        width: 24px;
      } /* Style span tag */

      .hamb-line::before,
      .hamb-line::after {
        background: var(--white);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
      }
      .hamb-line::before {
        top: 5px;
      }
      .hamb-line::after {
        top: -5px;
      }
    } /* Style label tag */

    .side-menu {
      display: none;
    } /* Hide checkbox */

    /* Toggle menu icon */
    .side-menu:checked ~ nav {
      max-height: 100%;
    }
    .side-menu:checked ~ .hamb .hamb-line {
      background: transparent;
    }
    .side-menu:checked ~ .hamb .hamb-line::before {
      transform: rotate(-45deg);
      top: 0;
    }
    .side-menu:checked ~ .hamb .hamb-line::after {
      transform: rotate(45deg);
      top: 0;
    }
  }
}
