@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
/* reset styles */
* {
  color: inherit;
  margin: 0;
}

* {
  font-family: manrope !important;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 50%;
  }
}

body {
  font-family: manrope;
}

ul {
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 7.2rem;
}

h2 {
  font-size: 6rem;
}

h3 {
  font-size: 4.8rem;
}

h4 {
  font-size: 3.6rem;
}

h5 {
  font-size: 3.2rem;
}

h6 {
  font-size: 2.4rem;
}

p {
  font-size: 1.4rem;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.container-100 {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-101 {
  width: 100%;
  max-width: 101rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-102 {
  width: 100%;
  max-width: 102rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-103 {
  width: 100%;
  max-width: 103rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-104 {
  width: 100%;
  max-width: 104rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-105 {
  width: 100%;
  max-width: 105rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-106 {
  width: 100%;
  max-width: 106rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-107 {
  width: 100%;
  max-width: 107rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-108 {
  width: 100%;
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-109 {
  width: 100%;
  max-width: 109rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-110 {
  width: 100%;
  max-width: 110rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-111 {
  width: 100%;
  max-width: 111rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-112 {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-113 {
  width: 100%;
  max-width: 113rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-114 {
  width: 100%;
  max-width: 114rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-115 {
  width: 100%;
  max-width: 115rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-116 {
  width: 100%;
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-117 {
  width: 100%;
  max-width: 117rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-118 {
  width: 100%;
  max-width: 118rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-119 {
  width: 100%;
  max-width: 119rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-120 {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-121 {
  width: 100%;
  max-width: 121rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-122 {
  width: 100%;
  max-width: 122rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-123 {
  width: 100%;
  max-width: 123rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-124 {
  width: 100%;
  max-width: 124rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-125 {
  width: 100%;
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-126 {
  width: 100%;
  max-width: 126rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-127 {
  width: 100%;
  max-width: 127rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-128 {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-129 {
  width: 100%;
  max-width: 129rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-130 {
  width: 100%;
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-131 {
  width: 100%;
  max-width: 131rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-132 {
  width: 100%;
  max-width: 132rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-133 {
  width: 100%;
  max-width: 133rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-134 {
  width: 100%;
  max-width: 134rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-135 {
  width: 100%;
  max-width: 135rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-136 {
  width: 100%;
  max-width: 136rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-137 {
  width: 100%;
  max-width: 137rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-138 {
  width: 100%;
  max-width: 138rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-139 {
  width: 100%;
  max-width: 139rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-140 {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-141 {
  width: 100%;
  max-width: 141rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-142 {
  width: 100%;
  max-width: 142rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-143 {
  width: 100%;
  max-width: 143rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-144 {
  width: 100%;
  max-width: 144rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-145 {
  width: 100%;
  max-width: 145rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-146 {
  width: 100%;
  max-width: 146rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-147 {
  width: 100%;
  max-width: 147rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-148 {
  width: 100%;
  max-width: 148rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-149 {
  width: 100%;
  max-width: 149rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-150 {
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-151 {
  width: 100%;
  max-width: 151rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-152 {
  width: 100%;
  max-width: 152rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-153 {
  width: 100%;
  max-width: 153rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-154 {
  width: 100%;
  max-width: 154rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-155 {
  width: 100%;
  max-width: 155rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-156 {
  width: 100%;
  max-width: 156rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-157 {
  width: 100%;
  max-width: 157rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-158 {
  width: 100%;
  max-width: 158rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-159 {
  width: 100%;
  max-width: 159rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-160 {
  width: 100%;
  max-width: 160rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-161 {
  width: 100%;
  max-width: 161rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-162 {
  width: 100%;
  max-width: 162rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-163 {
  width: 100%;
  max-width: 163rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-164 {
  width: 100%;
  max-width: 164rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-165 {
  width: 100%;
  max-width: 165rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-166 {
  width: 100%;
  max-width: 166rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-167 {
  width: 100%;
  max-width: 167rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-168 {
  width: 100%;
  max-width: 168rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-169 {
  width: 100%;
  max-width: 169rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-170 {
  width: 100%;
  max-width: 170rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-171 {
  width: 100%;
  max-width: 171rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-172 {
  width: 100%;
  max-width: 172rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-173 {
  width: 100%;
  max-width: 173rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-174 {
  width: 100%;
  max-width: 174rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-175 {
  width: 100%;
  max-width: 175rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-176 {
  width: 100%;
  max-width: 176rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-177 {
  width: 100%;
  max-width: 177rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-178 {
  width: 100%;
  max-width: 178rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-179 {
  width: 100%;
  max-width: 179rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-180 {
  width: 100%;
  max-width: 180rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-181 {
  width: 100%;
  max-width: 181rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-182 {
  width: 100%;
  max-width: 182rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-183 {
  width: 100%;
  max-width: 183rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-184 {
  width: 100%;
  max-width: 184rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-185 {
  width: 100%;
  max-width: 185rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-186 {
  width: 100%;
  max-width: 186rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-187 {
  width: 100%;
  max-width: 187rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-188 {
  width: 100%;
  max-width: 188rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-189 {
  width: 100%;
  max-width: 189rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-190 {
  width: 100%;
  max-width: 190rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-191 {
  width: 100%;
  max-width: 191rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-192 {
  width: 100%;
  max-width: 192rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-193 {
  width: 100%;
  max-width: 193rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-194 {
  width: 100%;
  max-width: 194rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-195 {
  width: 100%;
  max-width: 195rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-196 {
  width: 100%;
  max-width: 196rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-197 {
  width: 100%;
  max-width: 197rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-198 {
  width: 100%;
  max-width: 198rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-199 {
  width: 100%;
  max-width: 199rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container-200 {
  width: 100%;
  max-width: 200rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.grid-row {
  display: flex;
  flex-flow: row wrap;
}

.grid-gap-0 > * {
  padding: 0;
}

.grid-gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.grid-gap-1 > * {
  padding: 1rem;
}

.grid-gap-1 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.grid-gap-2 > * {
  padding: 2rem;
}

.grid-gap-2 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.grid-gap-3 > * {
  padding: 3rem;
}

.grid-gap-3 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

@media (min-width: 0) {
  .col-1-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 480px) {
  .col-1-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .col-1-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-1-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-1-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
.text-primary {
  color: #de430a;
}

.text-hover-primary:hover {
  color: #de430a;
}

.bg-primary {
  background-color: #de430a;
}

.text-primary-light-1 {
  color: #e15623;
}

.text-hover-primary-light-1:hover {
  color: #e15623;
}

.bg-primary-light-1 {
  background-color: #e15623;
}

.text-primary-light-2 {
  color: #e5693b;
}

.text-hover-primary-light-2:hover {
  color: #e5693b;
}

.bg-primary-light-2 {
  background-color: #e5693b;
}

.text-primary-light-3 {
  color: #e87b54;
}

.text-hover-primary-light-3:hover {
  color: #e87b54;
}

.bg-primary-light-3 {
  background-color: #e87b54;
}

.text-primary-light-4 {
  color: #eb8e6c;
}

.text-hover-primary-light-4:hover {
  color: #eb8e6c;
}

.bg-primary-light-4 {
  background-color: #eb8e6c;
}

.text-primary-light-5 {
  color: #efa185;
}

.text-hover-primary-light-5:hover {
  color: #efa185;
}

.bg-primary-light-5 {
  background-color: #efa185;
}

.text-primary-light-6 {
  color: #f2b49d;
}

.text-hover-primary-light-6:hover {
  color: #f2b49d;
}

.bg-primary-light-6 {
  background-color: #f2b49d;
}

.text-primary-light-7 {
  color: #f5c7b6;
}

.text-hover-primary-light-7:hover {
  color: #f5c7b6;
}

.bg-primary-light-7 {
  background-color: #f5c7b6;
}

.text-primary-light-8 {
  color: #f8d9ce;
}

.text-hover-primary-light-8:hover {
  color: #f8d9ce;
}

.bg-primary-light-8 {
  background-color: #f8d9ce;
}

.text-primary-light-9 {
  color: #fcece7;
}

.text-hover-primary-light-9:hover {
  color: #fcece7;
}

.bg-primary-light-9 {
  background-color: #fcece7;
}

.text-primary-dark-1 {
  color: #c83c09;
}

.text-hover-primary-dark-1:hover {
  color: #c83c09;
}

.bg-primary-dark-1 {
  background-color: #c83c09;
}

.text-primary-dark-2 {
  color: #b23608;
}

.text-hover-primary-dark-2:hover {
  color: #b23608;
}

.bg-primary-dark-2 {
  background-color: #b23608;
}

.text-primary-dark-3 {
  color: #9b2f07;
}

.text-hover-primary-dark-3:hover {
  color: #9b2f07;
}

.bg-primary-dark-3 {
  background-color: #9b2f07;
}

.text-primary-dark-4 {
  color: #852806;
}

.text-hover-primary-dark-4:hover {
  color: #852806;
}

.bg-primary-dark-4 {
  background-color: #852806;
}

.text-primary-dark-5 {
  color: #6f2205;
}

.text-hover-primary-dark-5:hover {
  color: #6f2205;
}

.bg-primary-dark-5 {
  background-color: #6f2205;
}

.text-primary-dark-6 {
  color: #591b04;
}

.text-hover-primary-dark-6:hover {
  color: #591b04;
}

.bg-primary-dark-6 {
  background-color: #591b04;
}

.text-primary-dark-7 {
  color: #431403;
}

.text-hover-primary-dark-7:hover {
  color: #431403;
}

.bg-primary-dark-7 {
  background-color: #431403;
}

.text-primary-dark-8 {
  color: #2c0d02;
}

.text-hover-primary-dark-8:hover {
  color: #2c0d02;
}

.bg-primary-dark-8 {
  background-color: #2c0d02;
}

.text-primary-dark-9 {
  color: #160701;
}

.text-hover-primary-dark-9:hover {
  color: #160701;
}

.bg-primary-dark-9 {
  background-color: #160701;
}

.text-secondary {
  color: #3fa302;
}

.text-hover-secondary:hover {
  color: #3fa302;
}

.bg-secondary {
  background-color: #3fa302;
}

.text-secondary-light-1 {
  color: #52ac1b;
}

.text-hover-secondary-light-1:hover {
  color: #52ac1b;
}

.bg-secondary-light-1 {
  background-color: #52ac1b;
}

.text-secondary-light-2 {
  color: #65b535;
}

.text-hover-secondary-light-2:hover {
  color: #65b535;
}

.bg-secondary-light-2 {
  background-color: #65b535;
}

.text-secondary-light-3 {
  color: #79bf4e;
}

.text-hover-secondary-light-3:hover {
  color: #79bf4e;
}

.bg-secondary-light-3 {
  background-color: #79bf4e;
}

.text-secondary-light-4 {
  color: #8cc867;
}

.text-hover-secondary-light-4:hover {
  color: #8cc867;
}

.bg-secondary-light-4 {
  background-color: #8cc867;
}

.text-secondary-light-5 {
  color: #9fd181;
}

.text-hover-secondary-light-5:hover {
  color: #9fd181;
}

.bg-secondary-light-5 {
  background-color: #9fd181;
}

.text-secondary-light-6 {
  color: #b2da9a;
}

.text-hover-secondary-light-6:hover {
  color: #b2da9a;
}

.bg-secondary-light-6 {
  background-color: #b2da9a;
}

.text-secondary-light-7 {
  color: #c5e3b3;
}

.text-hover-secondary-light-7:hover {
  color: #c5e3b3;
}

.bg-secondary-light-7 {
  background-color: #c5e3b3;
}

.text-secondary-light-8 {
  color: #d9edcc;
}

.text-hover-secondary-light-8:hover {
  color: #d9edcc;
}

.bg-secondary-light-8 {
  background-color: #d9edcc;
}

.text-secondary-light-9 {
  color: #ecf6e6;
}

.text-hover-secondary-light-9:hover {
  color: #ecf6e6;
}

.bg-secondary-light-9 {
  background-color: #ecf6e6;
}

.text-secondary-dark-1 {
  color: #399302;
}

.text-hover-secondary-dark-1:hover {
  color: #399302;
}

.bg-secondary-dark-1 {
  background-color: #399302;
}

.text-secondary-dark-2 {
  color: #328202;
}

.text-hover-secondary-dark-2:hover {
  color: #328202;
}

.bg-secondary-dark-2 {
  background-color: #328202;
}

.text-secondary-dark-3 {
  color: #2c7201;
}

.text-hover-secondary-dark-3:hover {
  color: #2c7201;
}

.bg-secondary-dark-3 {
  background-color: #2c7201;
}

.text-secondary-dark-4 {
  color: #266201;
}

.text-hover-secondary-dark-4:hover {
  color: #266201;
}

.bg-secondary-dark-4 {
  background-color: #266201;
}

.text-secondary-dark-5 {
  color: #205201;
}

.text-hover-secondary-dark-5:hover {
  color: #205201;
}

.bg-secondary-dark-5 {
  background-color: #205201;
}

.text-secondary-dark-6 {
  color: #194101;
}

.text-hover-secondary-dark-6:hover {
  color: #194101;
}

.bg-secondary-dark-6 {
  background-color: #194101;
}

.text-secondary-dark-7 {
  color: #133101;
}

.text-hover-secondary-dark-7:hover {
  color: #133101;
}

.bg-secondary-dark-7 {
  background-color: #133101;
}

.text-secondary-dark-8 {
  color: #0d2100;
}

.text-hover-secondary-dark-8:hover {
  color: #0d2100;
}

.bg-secondary-dark-8 {
  background-color: #0d2100;
}

.text-secondary-dark-9 {
  color: #061000;
}

.text-hover-secondary-dark-9:hover {
  color: #061000;
}

.bg-secondary-dark-9 {
  background-color: #061000;
}

.text-accent-primary {
  color: #ffe7d6;
}

.text-hover-accent-primary:hover {
  color: #ffe7d6;
}

.bg-accent-primary {
  background-color: #ffe7d6;
}

.text-accent-primary-light-1 {
  color: #ffe9da;
}

.text-hover-accent-primary-light-1:hover {
  color: #ffe9da;
}

.bg-accent-primary-light-1 {
  background-color: #ffe9da;
}

.text-accent-primary-light-2 {
  color: #ffecde;
}

.text-hover-accent-primary-light-2:hover {
  color: #ffecde;
}

.bg-accent-primary-light-2 {
  background-color: #ffecde;
}

.text-accent-primary-light-3 {
  color: #ffeee2;
}

.text-hover-accent-primary-light-3:hover {
  color: #ffeee2;
}

.bg-accent-primary-light-3 {
  background-color: #ffeee2;
}

.text-accent-primary-light-4 {
  color: #fff1e6;
}

.text-hover-accent-primary-light-4:hover {
  color: #fff1e6;
}

.bg-accent-primary-light-4 {
  background-color: #fff1e6;
}

.text-accent-primary-light-5 {
  color: #fff3eb;
}

.text-hover-accent-primary-light-5:hover {
  color: #fff3eb;
}

.bg-accent-primary-light-5 {
  background-color: #fff3eb;
}

.text-accent-primary-light-6 {
  color: #fff5ef;
}

.text-hover-accent-primary-light-6:hover {
  color: #fff5ef;
}

.bg-accent-primary-light-6 {
  background-color: #fff5ef;
}

.text-accent-primary-light-7 {
  color: #fff8f3;
}

.text-hover-accent-primary-light-7:hover {
  color: #fff8f3;
}

.bg-accent-primary-light-7 {
  background-color: #fff8f3;
}

.text-accent-primary-light-8 {
  color: #fffaf7;
}

.text-hover-accent-primary-light-8:hover {
  color: #fffaf7;
}

.bg-accent-primary-light-8 {
  background-color: #fffaf7;
}

.text-accent-primary-light-9 {
  color: #fffdfb;
}

.text-hover-accent-primary-light-9:hover {
  color: #fffdfb;
}

.bg-accent-primary-light-9 {
  background-color: #fffdfb;
}

.text-accent-primary-dark-1 {
  color: #e6d0c1;
}

.text-hover-accent-primary-dark-1:hover {
  color: #e6d0c1;
}

.bg-accent-primary-dark-1 {
  background-color: #e6d0c1;
}

.text-accent-primary-dark-2 {
  color: #ccb9ab;
}

.text-hover-accent-primary-dark-2:hover {
  color: #ccb9ab;
}

.bg-accent-primary-dark-2 {
  background-color: #ccb9ab;
}

.text-accent-primary-dark-3 {
  color: #b3a296;
}

.text-hover-accent-primary-dark-3:hover {
  color: #b3a296;
}

.bg-accent-primary-dark-3 {
  background-color: #b3a296;
}

.text-accent-primary-dark-4 {
  color: #998b80;
}

.text-hover-accent-primary-dark-4:hover {
  color: #998b80;
}

.bg-accent-primary-dark-4 {
  background-color: #998b80;
}

.text-accent-primary-dark-5 {
  color: #80746b;
}

.text-hover-accent-primary-dark-5:hover {
  color: #80746b;
}

.bg-accent-primary-dark-5 {
  background-color: #80746b;
}

.text-accent-primary-dark-6 {
  color: #665c56;
}

.text-hover-accent-primary-dark-6:hover {
  color: #665c56;
}

.bg-accent-primary-dark-6 {
  background-color: #665c56;
}

.text-accent-primary-dark-7 {
  color: #4d4540;
}

.text-hover-accent-primary-dark-7:hover {
  color: #4d4540;
}

.bg-accent-primary-dark-7 {
  background-color: #4d4540;
}

.text-accent-primary-dark-8 {
  color: #332e2b;
}

.text-hover-accent-primary-dark-8:hover {
  color: #332e2b;
}

.bg-accent-primary-dark-8 {
  background-color: #332e2b;
}

.text-accent-primary-dark-9 {
  color: #1a1715;
}

.text-hover-accent-primary-dark-9:hover {
  color: #1a1715;
}

.bg-accent-primary-dark-9 {
  background-color: #1a1715;
}

.text-accent-secondary {
  color: #ffe7d6;
}

.text-hover-accent-secondary:hover {
  color: #ffe7d6;
}

.bg-accent-secondary {
  background-color: #ffe7d6;
}

.text-accent-secondary-light-1 {
  color: #ffe9da;
}

.text-hover-accent-secondary-light-1:hover {
  color: #ffe9da;
}

.bg-accent-secondary-light-1 {
  background-color: #ffe9da;
}

.text-accent-secondary-light-2 {
  color: #ffecde;
}

.text-hover-accent-secondary-light-2:hover {
  color: #ffecde;
}

.bg-accent-secondary-light-2 {
  background-color: #ffecde;
}

.text-accent-secondary-light-3 {
  color: #ffeee2;
}

.text-hover-accent-secondary-light-3:hover {
  color: #ffeee2;
}

.bg-accent-secondary-light-3 {
  background-color: #ffeee2;
}

.text-accent-secondary-light-4 {
  color: #fff1e6;
}

.text-hover-accent-secondary-light-4:hover {
  color: #fff1e6;
}

.bg-accent-secondary-light-4 {
  background-color: #fff1e6;
}

.text-accent-secondary-light-5 {
  color: #fff3eb;
}

.text-hover-accent-secondary-light-5:hover {
  color: #fff3eb;
}

.bg-accent-secondary-light-5 {
  background-color: #fff3eb;
}

.text-accent-secondary-light-6 {
  color: #fff5ef;
}

.text-hover-accent-secondary-light-6:hover {
  color: #fff5ef;
}

.bg-accent-secondary-light-6 {
  background-color: #fff5ef;
}

.text-accent-secondary-light-7 {
  color: #fff8f3;
}

.text-hover-accent-secondary-light-7:hover {
  color: #fff8f3;
}

.bg-accent-secondary-light-7 {
  background-color: #fff8f3;
}

.text-accent-secondary-light-8 {
  color: #fffaf7;
}

.text-hover-accent-secondary-light-8:hover {
  color: #fffaf7;
}

.bg-accent-secondary-light-8 {
  background-color: #fffaf7;
}

.text-accent-secondary-light-9 {
  color: #fffdfb;
}

.text-hover-accent-secondary-light-9:hover {
  color: #fffdfb;
}

.bg-accent-secondary-light-9 {
  background-color: #fffdfb;
}

.text-accent-secondary-dark-1 {
  color: #e6d0c1;
}

.text-hover-accent-secondary-dark-1:hover {
  color: #e6d0c1;
}

.bg-accent-secondary-dark-1 {
  background-color: #e6d0c1;
}

.text-accent-secondary-dark-2 {
  color: #ccb9ab;
}

.text-hover-accent-secondary-dark-2:hover {
  color: #ccb9ab;
}

.bg-accent-secondary-dark-2 {
  background-color: #ccb9ab;
}

.text-accent-secondary-dark-3 {
  color: #b3a296;
}

.text-hover-accent-secondary-dark-3:hover {
  color: #b3a296;
}

.bg-accent-secondary-dark-3 {
  background-color: #b3a296;
}

.text-accent-secondary-dark-4 {
  color: #998b80;
}

.text-hover-accent-secondary-dark-4:hover {
  color: #998b80;
}

.bg-accent-secondary-dark-4 {
  background-color: #998b80;
}

.text-accent-secondary-dark-5 {
  color: #80746b;
}

.text-hover-accent-secondary-dark-5:hover {
  color: #80746b;
}

.bg-accent-secondary-dark-5 {
  background-color: #80746b;
}

.text-accent-secondary-dark-6 {
  color: #665c56;
}

.text-hover-accent-secondary-dark-6:hover {
  color: #665c56;
}

.bg-accent-secondary-dark-6 {
  background-color: #665c56;
}

.text-accent-secondary-dark-7 {
  color: #4d4540;
}

.text-hover-accent-secondary-dark-7:hover {
  color: #4d4540;
}

.bg-accent-secondary-dark-7 {
  background-color: #4d4540;
}

.text-accent-secondary-dark-8 {
  color: #332e2b;
}

.text-hover-accent-secondary-dark-8:hover {
  color: #332e2b;
}

.bg-accent-secondary-dark-8 {
  background-color: #332e2b;
}

.text-accent-secondary-dark-9 {
  color: #1a1715;
}

.text-hover-accent-secondary-dark-9:hover {
  color: #1a1715;
}

.bg-accent-secondary-dark-9 {
  background-color: #1a1715;
}

.text-error {
  color: #ff0f00;
}

.text-hover-error:hover {
  color: #ff0f00;
}

.bg-error {
  background-color: #ff0f00;
}

.text-error-light-1 {
  color: #ff271a;
}

.text-hover-error-light-1:hover {
  color: #ff271a;
}

.bg-error-light-1 {
  background-color: #ff271a;
}

.text-error-light-2 {
  color: #ff3f33;
}

.text-hover-error-light-2:hover {
  color: #ff3f33;
}

.bg-error-light-2 {
  background-color: #ff3f33;
}

.text-error-light-3 {
  color: #ff574d;
}

.text-hover-error-light-3:hover {
  color: #ff574d;
}

.bg-error-light-3 {
  background-color: #ff574d;
}

.text-error-light-4 {
  color: #ff6f66;
}

.text-hover-error-light-4:hover {
  color: #ff6f66;
}

.bg-error-light-4 {
  background-color: #ff6f66;
}

.text-error-light-5 {
  color: #ff8780;
}

.text-hover-error-light-5:hover {
  color: #ff8780;
}

.bg-error-light-5 {
  background-color: #ff8780;
}

.text-error-light-6 {
  color: #ff9f99;
}

.text-hover-error-light-6:hover {
  color: #ff9f99;
}

.bg-error-light-6 {
  background-color: #ff9f99;
}

.text-error-light-7 {
  color: #ffb7b3;
}

.text-hover-error-light-7:hover {
  color: #ffb7b3;
}

.bg-error-light-7 {
  background-color: #ffb7b3;
}

.text-error-light-8 {
  color: #ffcfcc;
}

.text-hover-error-light-8:hover {
  color: #ffcfcc;
}

.bg-error-light-8 {
  background-color: #ffcfcc;
}

.text-error-light-9 {
  color: #ffe7e6;
}

.text-hover-error-light-9:hover {
  color: #ffe7e6;
}

.bg-error-light-9 {
  background-color: #ffe7e6;
}

.text-error-dark-1 {
  color: #e60e00;
}

.text-hover-error-dark-1:hover {
  color: #e60e00;
}

.bg-error-dark-1 {
  background-color: #e60e00;
}

.text-error-dark-2 {
  color: #cc0c00;
}

.text-hover-error-dark-2:hover {
  color: #cc0c00;
}

.bg-error-dark-2 {
  background-color: #cc0c00;
}

.text-error-dark-3 {
  color: #b30b00;
}

.text-hover-error-dark-3:hover {
  color: #b30b00;
}

.bg-error-dark-3 {
  background-color: #b30b00;
}

.text-error-dark-4 {
  color: #990900;
}

.text-hover-error-dark-4:hover {
  color: #990900;
}

.bg-error-dark-4 {
  background-color: #990900;
}

.text-error-dark-5 {
  color: #800800;
}

.text-hover-error-dark-5:hover {
  color: #800800;
}

.bg-error-dark-5 {
  background-color: #800800;
}

.text-error-dark-6 {
  color: #660600;
}

.text-hover-error-dark-6:hover {
  color: #660600;
}

.bg-error-dark-6 {
  background-color: #660600;
}

.text-error-dark-7 {
  color: #4d0500;
}

.text-hover-error-dark-7:hover {
  color: #4d0500;
}

.bg-error-dark-7 {
  background-color: #4d0500;
}

.text-error-dark-8 {
  color: #330300;
}

.text-hover-error-dark-8:hover {
  color: #330300;
}

.bg-error-dark-8 {
  background-color: #330300;
}

.text-error-dark-9 {
  color: #1a0200;
}

.text-hover-error-dark-9:hover {
  color: #1a0200;
}

.bg-error-dark-9 {
  background-color: #1a0200;
}

.text-info {
  color: #f6c31c;
}

.text-hover-info:hover {
  color: #f6c31c;
}

.bg-info {
  background-color: #f6c31c;
}

.text-info-light-1 {
  color: #f7c933;
}

.text-hover-info-light-1:hover {
  color: #f7c933;
}

.bg-info-light-1 {
  background-color: #f7c933;
}

.text-info-light-2 {
  color: #f8cf49;
}

.text-hover-info-light-2:hover {
  color: #f8cf49;
}

.bg-info-light-2 {
  background-color: #f8cf49;
}

.text-info-light-3 {
  color: #f9d560;
}

.text-hover-info-light-3:hover {
  color: #f9d560;
}

.bg-info-light-3 {
  background-color: #f9d560;
}

.text-info-light-4 {
  color: #fadb77;
}

.text-hover-info-light-4:hover {
  color: #fadb77;
}

.bg-info-light-4 {
  background-color: #fadb77;
}

.text-info-light-5 {
  color: #fbe18e;
}

.text-hover-info-light-5:hover {
  color: #fbe18e;
}

.bg-info-light-5 {
  background-color: #fbe18e;
}

.text-info-light-6 {
  color: #fbe7a4;
}

.text-hover-info-light-6:hover {
  color: #fbe7a4;
}

.bg-info-light-6 {
  background-color: #fbe7a4;
}

.text-info-light-7 {
  color: #fcedbb;
}

.text-hover-info-light-7:hover {
  color: #fcedbb;
}

.bg-info-light-7 {
  background-color: #fcedbb;
}

.text-info-light-8 {
  color: #fdf3d2;
}

.text-hover-info-light-8:hover {
  color: #fdf3d2;
}

.bg-info-light-8 {
  background-color: #fdf3d2;
}

.text-info-light-9 {
  color: #fef9e8;
}

.text-hover-info-light-9:hover {
  color: #fef9e8;
}

.bg-info-light-9 {
  background-color: #fef9e8;
}

.text-info-dark-1 {
  color: #ddb019;
}

.text-hover-info-dark-1:hover {
  color: #ddb019;
}

.bg-info-dark-1 {
  background-color: #ddb019;
}

.text-info-dark-2 {
  color: #c59c16;
}

.text-hover-info-dark-2:hover {
  color: #c59c16;
}

.bg-info-dark-2 {
  background-color: #c59c16;
}

.text-info-dark-3 {
  color: #ac8914;
}

.text-hover-info-dark-3:hover {
  color: #ac8914;
}

.bg-info-dark-3 {
  background-color: #ac8914;
}

.text-info-dark-4 {
  color: #947511;
}

.text-hover-info-dark-4:hover {
  color: #947511;
}

.bg-info-dark-4 {
  background-color: #947511;
}

.text-info-dark-5 {
  color: #7b620e;
}

.text-hover-info-dark-5:hover {
  color: #7b620e;
}

.bg-info-dark-5 {
  background-color: #7b620e;
}

.text-info-dark-6 {
  color: #624e0b;
}

.text-hover-info-dark-6:hover {
  color: #624e0b;
}

.bg-info-dark-6 {
  background-color: #624e0b;
}

.text-info-dark-7 {
  color: #4a3b08;
}

.text-hover-info-dark-7:hover {
  color: #4a3b08;
}

.bg-info-dark-7 {
  background-color: #4a3b08;
}

.text-info-dark-8 {
  color: #312706;
}

.text-hover-info-dark-8:hover {
  color: #312706;
}

.bg-info-dark-8 {
  background-color: #312706;
}

.text-info-dark-9 {
  color: #191403;
}

.text-hover-info-dark-9:hover {
  color: #191403;
}

.bg-info-dark-9 {
  background-color: #191403;
}

.text-white {
  color: #f7f8f7;
}

.text-hover-white:hover {
  color: #f7f8f7;
}

.bg-white {
  background-color: #f7f8f7;
}

.text-green {
  color: #0b8376;
}

.text-hover-green:hover {
  color: #0b8376;
}

.bg-green {
  background-color: #0b8376;
}

.text-green-light-1 {
  color: #238f84;
}

.text-hover-green-light-1:hover {
  color: #238f84;
}

.bg-green-light-1 {
  background-color: #238f84;
}

.text-green-light-2 {
  color: #3c9c91;
}

.text-hover-green-light-2:hover {
  color: #3c9c91;
}

.bg-green-light-2 {
  background-color: #3c9c91;
}

.text-green-light-3 {
  color: #54a89f;
}

.text-hover-green-light-3:hover {
  color: #54a89f;
}

.bg-green-light-3 {
  background-color: #54a89f;
}

.text-green-light-4 {
  color: #6db5ad;
}

.text-hover-green-light-4:hover {
  color: #6db5ad;
}

.bg-green-light-4 {
  background-color: #6db5ad;
}

.text-green-light-5 {
  color: #85c1bb;
}

.text-hover-green-light-5:hover {
  color: #85c1bb;
}

.bg-green-light-5 {
  background-color: #85c1bb;
}

.text-green-light-6 {
  color: #9dcdc8;
}

.text-hover-green-light-6:hover {
  color: #9dcdc8;
}

.bg-green-light-6 {
  background-color: #9dcdc8;
}

.text-green-light-7 {
  color: #b6dad6;
}

.text-hover-green-light-7:hover {
  color: #b6dad6;
}

.bg-green-light-7 {
  background-color: #b6dad6;
}

.text-green-light-8 {
  color: #cee6e4;
}

.text-hover-green-light-8:hover {
  color: #cee6e4;
}

.bg-green-light-8 {
  background-color: #cee6e4;
}

.text-green-light-9 {
  color: #e7f3f1;
}

.text-hover-green-light-9:hover {
  color: #e7f3f1;
}

.bg-green-light-9 {
  background-color: #e7f3f1;
}

.text-green-dark-1 {
  color: #0a766a;
}

.text-hover-green-dark-1:hover {
  color: #0a766a;
}

.bg-green-dark-1 {
  background-color: #0a766a;
}

.text-green-dark-2 {
  color: #09695e;
}

.text-hover-green-dark-2:hover {
  color: #09695e;
}

.bg-green-dark-2 {
  background-color: #09695e;
}

.text-green-dark-3 {
  color: #085c53;
}

.text-hover-green-dark-3:hover {
  color: #085c53;
}

.bg-green-dark-3 {
  background-color: #085c53;
}

.text-green-dark-4 {
  color: #074f47;
}

.text-hover-green-dark-4:hover {
  color: #074f47;
}

.bg-green-dark-4 {
  background-color: #074f47;
}

.text-green-dark-5 {
  color: #06423b;
}

.text-hover-green-dark-5:hover {
  color: #06423b;
}

.bg-green-dark-5 {
  background-color: #06423b;
}

.text-green-dark-6 {
  color: #04342f;
}

.text-hover-green-dark-6:hover {
  color: #04342f;
}

.bg-green-dark-6 {
  background-color: #04342f;
}

.text-green-dark-7 {
  color: #032723;
}

.text-hover-green-dark-7:hover {
  color: #032723;
}

.bg-green-dark-7 {
  background-color: #032723;
}

.text-green-dark-8 {
  color: #021a18;
}

.text-hover-green-dark-8:hover {
  color: #021a18;
}

.bg-green-dark-8 {
  background-color: #021a18;
}

.text-green-dark-9 {
  color: #010d0c;
}

.text-hover-green-dark-9:hover {
  color: #010d0c;
}

.bg-green-dark-9 {
  background-color: #010d0c;
}

.text-purple {
  color: #755ae2;
}

.text-hover-purple:hover {
  color: #755ae2;
}

.bg-purple {
  background-color: #755ae2;
}

.text-purple-light-1 {
  color: #836be5;
}

.text-hover-purple-light-1:hover {
  color: #836be5;
}

.bg-purple-light-1 {
  background-color: #836be5;
}

.text-purple-light-2 {
  color: #917be8;
}

.text-hover-purple-light-2:hover {
  color: #917be8;
}

.bg-purple-light-2 {
  background-color: #917be8;
}

.text-purple-light-3 {
  color: #9e8ceb;
}

.text-hover-purple-light-3:hover {
  color: #9e8ceb;
}

.bg-purple-light-3 {
  background-color: #9e8ceb;
}

.text-purple-light-4 {
  color: #ac9cee;
}

.text-hover-purple-light-4:hover {
  color: #ac9cee;
}

.bg-purple-light-4 {
  background-color: #ac9cee;
}

.text-purple-light-5 {
  color: #baadf1;
}

.text-hover-purple-light-5:hover {
  color: #baadf1;
}

.bg-purple-light-5 {
  background-color: #baadf1;
}

.text-purple-light-6 {
  color: #c8bdf3;
}

.text-hover-purple-light-6:hover {
  color: #c8bdf3;
}

.bg-purple-light-6 {
  background-color: #c8bdf3;
}

.text-purple-light-7 {
  color: #d6cef6;
}

.text-hover-purple-light-7:hover {
  color: #d6cef6;
}

.bg-purple-light-7 {
  background-color: #d6cef6;
}

.text-purple-light-8 {
  color: #e3def9;
}

.text-hover-purple-light-8:hover {
  color: #e3def9;
}

.bg-purple-light-8 {
  background-color: #e3def9;
}

.text-purple-light-9 {
  color: #f1effc;
}

.text-hover-purple-light-9:hover {
  color: #f1effc;
}

.bg-purple-light-9 {
  background-color: #f1effc;
}

.text-purple-dark-1 {
  color: #6951cb;
}

.text-hover-purple-dark-1:hover {
  color: #6951cb;
}

.bg-purple-dark-1 {
  background-color: #6951cb;
}

.text-purple-dark-2 {
  color: #5e48b5;
}

.text-hover-purple-dark-2:hover {
  color: #5e48b5;
}

.bg-purple-dark-2 {
  background-color: #5e48b5;
}

.text-purple-dark-3 {
  color: #523f9e;
}

.text-hover-purple-dark-3:hover {
  color: #523f9e;
}

.bg-purple-dark-3 {
  background-color: #523f9e;
}

.text-purple-dark-4 {
  color: #463688;
}

.text-hover-purple-dark-4:hover {
  color: #463688;
}

.bg-purple-dark-4 {
  background-color: #463688;
}

.text-purple-dark-5 {
  color: #3b2d71;
}

.text-hover-purple-dark-5:hover {
  color: #3b2d71;
}

.bg-purple-dark-5 {
  background-color: #3b2d71;
}

.text-purple-dark-6 {
  color: #2f245a;
}

.text-hover-purple-dark-6:hover {
  color: #2f245a;
}

.bg-purple-dark-6 {
  background-color: #2f245a;
}

.text-purple-dark-7 {
  color: #231b44;
}

.text-hover-purple-dark-7:hover {
  color: #231b44;
}

.bg-purple-dark-7 {
  background-color: #231b44;
}

.text-purple-dark-8 {
  color: #17122d;
}

.text-hover-purple-dark-8:hover {
  color: #17122d;
}

.bg-purple-dark-8 {
  background-color: #17122d;
}

.text-purple-dark-9 {
  color: #0c0917;
}

.text-hover-purple-dark-9:hover {
  color: #0c0917;
}

.bg-purple-dark-9 {
  background-color: #0c0917;
}

.text-orange {
  color: #ea872d;
}

.text-hover-orange:hover {
  color: #ea872d;
}

.bg-orange {
  background-color: #ea872d;
}

.text-orange-light-1 {
  color: #ec9342;
}

.text-hover-orange-light-1:hover {
  color: #ec9342;
}

.bg-orange-light-1 {
  background-color: #ec9342;
}

.text-orange-light-2 {
  color: #ee9f57;
}

.text-hover-orange-light-2:hover {
  color: #ee9f57;
}

.bg-orange-light-2 {
  background-color: #ee9f57;
}

.text-orange-light-3 {
  color: #f0ab6c;
}

.text-hover-orange-light-3:hover {
  color: #f0ab6c;
}

.bg-orange-light-3 {
  background-color: #f0ab6c;
}

.text-orange-light-4 {
  color: #f2b781;
}

.text-hover-orange-light-4:hover {
  color: #f2b781;
}

.bg-orange-light-4 {
  background-color: #f2b781;
}

.text-orange-light-5 {
  color: #f5c396;
}

.text-hover-orange-light-5:hover {
  color: #f5c396;
}

.bg-orange-light-5 {
  background-color: #f5c396;
}

.text-orange-light-6 {
  color: #f7cfab;
}

.text-hover-orange-light-6:hover {
  color: #f7cfab;
}

.bg-orange-light-6 {
  background-color: #f7cfab;
}

.text-orange-light-7 {
  color: #f9dbc0;
}

.text-hover-orange-light-7:hover {
  color: #f9dbc0;
}

.bg-orange-light-7 {
  background-color: #f9dbc0;
}

.text-orange-light-8 {
  color: #fbe7d5;
}

.text-hover-orange-light-8:hover {
  color: #fbe7d5;
}

.bg-orange-light-8 {
  background-color: #fbe7d5;
}

.text-orange-light-9 {
  color: #fdf3ea;
}

.text-hover-orange-light-9:hover {
  color: #fdf3ea;
}

.bg-orange-light-9 {
  background-color: #fdf3ea;
}

.text-orange-dark-1 {
  color: #d37a29;
}

.text-hover-orange-dark-1:hover {
  color: #d37a29;
}

.bg-orange-dark-1 {
  background-color: #d37a29;
}

.text-orange-dark-2 {
  color: #bb6c24;
}

.text-hover-orange-dark-2:hover {
  color: #bb6c24;
}

.bg-orange-dark-2 {
  background-color: #bb6c24;
}

.text-orange-dark-3 {
  color: #a45f20;
}

.text-hover-orange-dark-3:hover {
  color: #a45f20;
}

.bg-orange-dark-3 {
  background-color: #a45f20;
}

.text-orange-dark-4 {
  color: #8c511b;
}

.text-hover-orange-dark-4:hover {
  color: #8c511b;
}

.bg-orange-dark-4 {
  background-color: #8c511b;
}

.text-orange-dark-5 {
  color: #754417;
}

.text-hover-orange-dark-5:hover {
  color: #754417;
}

.bg-orange-dark-5 {
  background-color: #754417;
}

.text-orange-dark-6 {
  color: #5e3612;
}

.text-hover-orange-dark-6:hover {
  color: #5e3612;
}

.bg-orange-dark-6 {
  background-color: #5e3612;
}

.text-orange-dark-7 {
  color: #46290e;
}

.text-hover-orange-dark-7:hover {
  color: #46290e;
}

.bg-orange-dark-7 {
  background-color: #46290e;
}

.text-orange-dark-8 {
  color: #2f1b09;
}

.text-hover-orange-dark-8:hover {
  color: #2f1b09;
}

.bg-orange-dark-8 {
  background-color: #2f1b09;
}

.text-orange-dark-9 {
  color: #170e05;
}

.text-hover-orange-dark-9:hover {
  color: #170e05;
}

.bg-orange-dark-9 {
  background-color: #170e05;
}

.text-black {
  color: #020202;
}

.text-hover-black:hover {
  color: #020202;
}

.bg-black {
  background-color: #020202;
}

.text-deep-green {
  color: #014345;
}

.text-hover-deep-green:hover {
  color: #014345;
}

.bg-deep-green {
  background-color: #014345;
}

.text-deep-green-light-1 {
  color: #1a5658;
}

.text-hover-deep-green-light-1:hover {
  color: #1a5658;
}

.bg-deep-green-light-1 {
  background-color: #1a5658;
}

.text-deep-green-light-2 {
  color: #34696a;
}

.text-hover-deep-green-light-2:hover {
  color: #34696a;
}

.bg-deep-green-light-2 {
  background-color: #34696a;
}

.text-deep-green-light-3 {
  color: #4d7b7d;
}

.text-hover-deep-green-light-3:hover {
  color: #4d7b7d;
}

.bg-deep-green-light-3 {
  background-color: #4d7b7d;
}

.text-deep-green-light-4 {
  color: #678e8f;
}

.text-hover-deep-green-light-4:hover {
  color: #678e8f;
}

.bg-deep-green-light-4 {
  background-color: #678e8f;
}

.text-deep-green-light-5 {
  color: #80a1a2;
}

.text-hover-deep-green-light-5:hover {
  color: #80a1a2;
}

.bg-deep-green-light-5 {
  background-color: #80a1a2;
}

.text-deep-green-light-6 {
  color: #99b4b5;
}

.text-hover-deep-green-light-6:hover {
  color: #99b4b5;
}

.bg-deep-green-light-6 {
  background-color: #99b4b5;
}

.text-deep-green-light-7 {
  color: #b3c7c7;
}

.text-hover-deep-green-light-7:hover {
  color: #b3c7c7;
}

.bg-deep-green-light-7 {
  background-color: #b3c7c7;
}

.text-deep-green-light-8 {
  color: #ccd9da;
}

.text-hover-deep-green-light-8:hover {
  color: #ccd9da;
}

.bg-deep-green-light-8 {
  background-color: #ccd9da;
}

.text-deep-green-light-9 {
  color: #e6ecec;
}

.text-hover-deep-green-light-9:hover {
  color: #e6ecec;
}

.bg-deep-green-light-9 {
  background-color: #e6ecec;
}

.text-deep-green-dark-1 {
  color: #013c3e;
}

.text-hover-deep-green-dark-1:hover {
  color: #013c3e;
}

.bg-deep-green-dark-1 {
  background-color: #013c3e;
}

.text-deep-green-dark-2 {
  color: #013637;
}

.text-hover-deep-green-dark-2:hover {
  color: #013637;
}

.bg-deep-green-dark-2 {
  background-color: #013637;
}

.text-deep-green-dark-3 {
  color: #012f30;
}

.text-hover-deep-green-dark-3:hover {
  color: #012f30;
}

.bg-deep-green-dark-3 {
  background-color: #012f30;
}

.text-deep-green-dark-4 {
  color: #012829;
}

.text-hover-deep-green-dark-4:hover {
  color: #012829;
}

.bg-deep-green-dark-4 {
  background-color: #012829;
}

.text-deep-green-dark-5 {
  color: #012223;
}

.text-hover-deep-green-dark-5:hover {
  color: #012223;
}

.bg-deep-green-dark-5 {
  background-color: #012223;
}

.text-deep-green-dark-6 {
  color: #001b1c;
}

.text-hover-deep-green-dark-6:hover {
  color: #001b1c;
}

.bg-deep-green-dark-6 {
  background-color: #001b1c;
}

.text-deep-green-dark-7 {
  color: #001415;
}

.text-hover-deep-green-dark-7:hover {
  color: #001415;
}

.bg-deep-green-dark-7 {
  background-color: #001415;
}

.text-deep-green-dark-8 {
  color: #000d0e;
}

.text-hover-deep-green-dark-8:hover {
  color: #000d0e;
}

.bg-deep-green-dark-8 {
  background-color: #000d0e;
}

.text-deep-green-dark-9 {
  color: #000707;
}

.text-hover-deep-green-dark-9:hover {
  color: #000707;
}

.bg-deep-green-dark-9 {
  background-color: #000707;
}

.card {
  display: block;
  padding: 1rem;
}
.card .card-title {
  font-size: 1rem;
  padding-bottom: base-padding;
  font-weight: bold;
}
.card .card-body {
  font-size: 1rem;
}

button:hover {
  -webkit-transform: translateY(-0.1rem);
  transform: translateY(-0.1rem);
}

.btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
}

.btn-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
}
.btn-primary:hover {
  background-color: #f44b0d;
}

.btn-outlined-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #de430a;
}
.btn-outlined-primary:hover {
  background-color: #de430a;
}

.btn-complement-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  color: #87dbfa;
}
.btn-complement-primary:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-secondary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
}
.btn-secondary:hover {
  background-color: #49bc02;
}

.btn-outlined-secondary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #3fa302;
}
.btn-outlined-secondary:hover {
  background-color: #3fa302;
}

.btn-complement-secondary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  color: #b641fd;
}
.btn-complement-secondary:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-accent-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-primary:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-primary:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-primary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-primary:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-accent-secondary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-secondary:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-secondary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-secondary:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-secondary {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-secondary:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-error {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
}
.btn-error:hover {
  background-color: #ff271a;
}

.btn-outlined-error {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ff0f00;
}
.btn-outlined-error:hover {
  background-color: #ff0f00;
}

.btn-complement-error {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  color: #99f9ff;
}
.btn-complement-error:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-info {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
}
.btn-info:hover {
  background-color: #f7c935;
}

.btn-outlined-info {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #f6c31c;
}
.btn-outlined-info:hover {
  background-color: #f6c31c;
}

.btn-complement-info {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  color: #afc1fc;
}
.btn-complement-info:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-white {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
}
.btn-white:hover {
  background-color: white;
}

.btn-outlined-white {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #f7f8f7;
}
.btn-outlined-white:hover {
  background-color: #f7f8f7;
}

.btn-complement-white {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  color: white;
}
.btn-complement-white:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-green {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
}
.btn-green:hover {
  background-color: #0d9b8b;
}

.btn-outlined-green {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b8376;
}
.btn-outlined-green:hover {
  background-color: #0b8376;
}

.btn-complement-green {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  color: #ee394c;
}
.btn-complement-green:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-purple {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
}
.btn-purple:hover {
  background-color: #8770e6;
}

.btn-outlined-purple {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #755ae2;
}
.btn-outlined-purple:hover {
  background-color: #755ae2;
}

.btn-complement-purple {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  color: #f3f9dc;
}
.btn-complement-purple:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-orange {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
}
.btn-orange:hover {
  background-color: #ec9444;
}

.btn-outlined-orange {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ea872d;
}
.btn-outlined-orange:hover {
  background-color: #ea872d;
}

.btn-complement-orange {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  color: #b8daf8;
}
.btn-complement-orange:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-black {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
}
.btn-black:hover {
  background-color: #0f0f0f;
}

.btn-outlined-black {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #020202;
}
.btn-outlined-black:hover {
  background-color: #020202;
}

.btn-complement-black {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  color: #4f4f4f;
}
.btn-complement-black:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-deep-green {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
}
.btn-deep-green:hover {
  background-color: #015b5e;
}

.btn-outlined-deep-green {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #014345;
}
.btn-outlined-deep-green:hover {
  background-color: #014345;
}

.btn-complement-deep-green {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  color: #dc0a03;
}
.btn-complement-deep-green:hover {
  color: #014345;
  background-color: #dc0a03;
}

.btn-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
}
.btn-primary-sm:hover {
  background-color: #f44b0d;
}

.btn-outlined-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #de430a;
}
.btn-outlined-primary-sm:hover {
  background-color: #de430a;
}

.btn-complement-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  color: #87dbfa;
}
.btn-complement-primary-sm:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
}
.btn-secondary-sm:hover {
  background-color: #49bc02;
}

.btn-outlined-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #3fa302;
}
.btn-outlined-secondary-sm:hover {
  background-color: #3fa302;
}

.btn-complement-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  color: #b641fd;
}
.btn-complement-secondary-sm:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-accent-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-primary-sm:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-primary-sm:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-primary-sm:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-accent-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-secondary-sm:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-secondary-sm:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-secondary-sm:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-error-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
}
.btn-error-sm:hover {
  background-color: #ff271a;
}

.btn-outlined-error-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ff0f00;
}
.btn-outlined-error-sm:hover {
  background-color: #ff0f00;
}

.btn-complement-error-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  color: #99f9ff;
}
.btn-complement-error-sm:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-info-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
}
.btn-info-sm:hover {
  background-color: #f7c935;
}

.btn-outlined-info-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #f6c31c;
}
.btn-outlined-info-sm:hover {
  background-color: #f6c31c;
}

.btn-complement-info-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  color: #afc1fc;
}
.btn-complement-info-sm:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-white-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
}
.btn-white-sm:hover {
  background-color: white;
}

.btn-outlined-white-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #f7f8f7;
}
.btn-outlined-white-sm:hover {
  background-color: #f7f8f7;
}

.btn-complement-white-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  color: white;
}
.btn-complement-white-sm:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
}
.btn-green-sm:hover {
  background-color: #0d9b8b;
}

.btn-outlined-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #0b8376;
}
.btn-outlined-green-sm:hover {
  background-color: #0b8376;
}

.btn-complement-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  color: #ee394c;
}
.btn-complement-green-sm:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-purple-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
}
.btn-purple-sm:hover {
  background-color: #8770e6;
}

.btn-outlined-purple-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #755ae2;
}
.btn-outlined-purple-sm:hover {
  background-color: #755ae2;
}

.btn-complement-purple-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  color: #f3f9dc;
}
.btn-complement-purple-sm:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-orange-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
}
.btn-orange-sm:hover {
  background-color: #ec9444;
}

.btn-outlined-orange-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ea872d;
}
.btn-outlined-orange-sm:hover {
  background-color: #ea872d;
}

.btn-complement-orange-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  color: #b8daf8;
}
.btn-complement-orange-sm:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-black-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
}
.btn-black-sm:hover {
  background-color: #0f0f0f;
}

.btn-outlined-black-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #020202;
}
.btn-outlined-black-sm:hover {
  background-color: #020202;
}

.btn-complement-black-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  color: #4f4f4f;
}
.btn-complement-black-sm:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-deep-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
}
.btn-deep-green-sm:hover {
  background-color: #015b5e;
}

.btn-outlined-deep-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #014345;
}
.btn-outlined-deep-green-sm:hover {
  background-color: #014345;
}

.btn-complement-deep-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  color: #dc0a03;
}
.btn-complement-deep-green-sm:hover {
  color: #014345;
  background-color: #dc0a03;
}

.btn-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
}
.btn-primary-md:hover {
  background-color: #f44b0d;
}

.btn-outlined-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #de430a;
}
.btn-outlined-primary-md:hover {
  background-color: #de430a;
}

.btn-complement-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  color: #87dbfa;
}
.btn-complement-primary-md:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
}
.btn-secondary-md:hover {
  background-color: #49bc02;
}

.btn-outlined-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #3fa302;
}
.btn-outlined-secondary-md:hover {
  background-color: #3fa302;
}

.btn-complement-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  color: #b641fd;
}
.btn-complement-secondary-md:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-accent-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-primary-md:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-primary-md:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-primary-md:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-accent-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-secondary-md:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-secondary-md:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-secondary-md:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-error-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
}
.btn-error-md:hover {
  background-color: #ff271a;
}

.btn-outlined-error-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ff0f00;
}
.btn-outlined-error-md:hover {
  background-color: #ff0f00;
}

.btn-complement-error-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  color: #99f9ff;
}
.btn-complement-error-md:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-info-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
}
.btn-info-md:hover {
  background-color: #f7c935;
}

.btn-outlined-info-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #f6c31c;
}
.btn-outlined-info-md:hover {
  background-color: #f6c31c;
}

.btn-complement-info-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  color: #afc1fc;
}
.btn-complement-info-md:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-white-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
}
.btn-white-md:hover {
  background-color: white;
}

.btn-outlined-white-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #f7f8f7;
}
.btn-outlined-white-md:hover {
  background-color: #f7f8f7;
}

.btn-complement-white-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  color: white;
}
.btn-complement-white-md:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
}
.btn-green-md:hover {
  background-color: #0d9b8b;
}

.btn-outlined-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #0b8376;
}
.btn-outlined-green-md:hover {
  background-color: #0b8376;
}

.btn-complement-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  color: #ee394c;
}
.btn-complement-green-md:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-purple-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
}
.btn-purple-md:hover {
  background-color: #8770e6;
}

.btn-outlined-purple-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #755ae2;
}
.btn-outlined-purple-md:hover {
  background-color: #755ae2;
}

.btn-complement-purple-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  color: #f3f9dc;
}
.btn-complement-purple-md:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-orange-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
}
.btn-orange-md:hover {
  background-color: #ec9444;
}

.btn-outlined-orange-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ea872d;
}
.btn-outlined-orange-md:hover {
  background-color: #ea872d;
}

.btn-complement-orange-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  color: #b8daf8;
}
.btn-complement-orange-md:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-black-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
}
.btn-black-md:hover {
  background-color: #0f0f0f;
}

.btn-outlined-black-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #020202;
}
.btn-outlined-black-md:hover {
  background-color: #020202;
}

.btn-complement-black-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  color: #4f4f4f;
}
.btn-complement-black-md:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-deep-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
}
.btn-deep-green-md:hover {
  background-color: #015b5e;
}

.btn-outlined-deep-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #014345;
}
.btn-outlined-deep-green-md:hover {
  background-color: #014345;
}

.btn-complement-deep-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  color: #dc0a03;
}
.btn-complement-deep-green-md:hover {
  color: #014345;
  background-color: #dc0a03;
}

.btn-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
}
.btn-primary-lg:hover {
  background-color: #f44b0d;
}

.btn-outlined-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #de430a;
}
.btn-outlined-primary-lg:hover {
  background-color: #de430a;
}

.btn-complement-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  color: #87dbfa;
}

.btn-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
}
.btn-secondary-lg:hover {
  background-color: #49bc02;
}

.btn-outlined-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #3fa302;
}
.btn-outlined-secondary-lg:hover {
  background-color: #3fa302;
}

.btn-complement-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  color: #b641fd;
}

.btn-accent-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-primary-lg:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-primary-lg:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}

.btn-accent-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
}
.btn-accent-secondary-lg:hover {
  background-color: #fff6f0;
}

.btn-outlined-accent-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-secondary-lg:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}

.btn-error-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
}
.btn-error-lg:hover {
  background-color: #ff271a;
}

.btn-outlined-error-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ff0f00;
}
.btn-outlined-error-lg:hover {
  background-color: #ff0f00;
}

.btn-complement-error-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  color: #99f9ff;
}

.btn-info-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
}
.btn-info-lg:hover {
  background-color: #f7c935;
}

.btn-outlined-info-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #f6c31c;
}
.btn-outlined-info-lg:hover {
  background-color: #f6c31c;
}

.btn-complement-info-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  color: #afc1fc;
}

.btn-white-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
}
.btn-white-lg:hover {
  background-color: white;
}

.btn-outlined-white-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #f7f8f7;
}
.btn-outlined-white-lg:hover {
  background-color: #f7f8f7;
}

.btn-complement-white-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  color: white;
}

.btn-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
}
.btn-green-lg:hover {
  background-color: #0d9b8b;
}

.btn-outlined-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #0b8376;
}
.btn-outlined-green-lg:hover {
  background-color: #0b8376;
}

.btn-complement-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  color: #ee394c;
}

.btn-purple-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
}
.btn-purple-lg:hover {
  background-color: #8770e6;
}

.btn-outlined-purple-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #755ae2;
}
.btn-outlined-purple-lg:hover {
  background-color: #755ae2;
}

.btn-complement-purple-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  color: #f3f9dc;
}

.btn-orange-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
}
.btn-orange-lg:hover {
  background-color: #ec9444;
}

.btn-outlined-orange-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #ea872d;
}
.btn-outlined-orange-lg:hover {
  background-color: #ea872d;
}

.btn-complement-orange-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  color: #b8daf8;
}

.btn-black-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
}
.btn-black-lg:hover {
  background-color: #0f0f0f;
}

.btn-outlined-black-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #020202;
}
.btn-outlined-black-lg:hover {
  background-color: #020202;
}

.btn-complement-black-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  color: #4f4f4f;
}

.btn-deep-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
}
.btn-deep-green-lg:hover {
  background-color: #015b5e;
}

.btn-outlined-deep-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: unset;
  border: 1px solid #014345;
}
.btn-outlined-deep-green-lg:hover {
  background-color: #014345;
}

.btn-complement-deep-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  color: #dc0a03;
}

.btn-primary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #de430a;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-primary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  border: 1px solid #de430a;
}
.btn-outlined-primary-rounded:hover {
  background-color: #de430a;
}

.btn-complement-primary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  color: #87dbfa;
}
.btn-complement-primary-rounded:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-secondary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #3fa302;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-secondary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  border: 1px solid #3fa302;
}
.btn-outlined-secondary-rounded:hover {
  background-color: #3fa302;
}

.btn-complement-secondary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  color: #b641fd;
}
.btn-complement-secondary-rounded:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-accent-primary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #ffe7d6;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-accent-primary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-primary-rounded:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-primary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-primary-rounded:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-accent-secondary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #ffe7d6;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-accent-secondary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  border: 1px solid #ffe7d6;
}
.btn-outlined-accent-secondary-rounded:hover {
  background-color: #ffe7d6;
}

.btn-complement-accent-secondary-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  color: white;
}
.btn-complement-accent-secondary-rounded:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-error-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #ff0f00;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-error-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  border: 1px solid #ff0f00;
}
.btn-outlined-error-rounded:hover {
  background-color: #ff0f00;
}

.btn-complement-error-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  color: #99f9ff;
}
.btn-complement-error-rounded:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-info-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #f6c31c;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-info-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  border: 1px solid #f6c31c;
}
.btn-outlined-info-rounded:hover {
  background-color: #f6c31c;
}

.btn-complement-info-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  color: #afc1fc;
}
.btn-complement-info-rounded:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-white-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #f7f8f7;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-white-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  border: 1px solid #f7f8f7;
}
.btn-outlined-white-rounded:hover {
  background-color: #f7f8f7;
}

.btn-complement-white-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  color: white;
}
.btn-complement-white-rounded:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-green-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #0b8376;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-green-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  border: 1px solid #0b8376;
}
.btn-outlined-green-rounded:hover {
  background-color: #0b8376;
}

.btn-complement-green-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  color: #ee394c;
}
.btn-complement-green-rounded:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-purple-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #755ae2;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-purple-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  border: 1px solid #755ae2;
}
.btn-outlined-purple-rounded:hover {
  background-color: #755ae2;
}

.btn-complement-purple-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  color: #f3f9dc;
}
.btn-complement-purple-rounded:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-orange-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #ea872d;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-orange-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  border: 1px solid #ea872d;
}
.btn-outlined-orange-rounded:hover {
  background-color: #ea872d;
}

.btn-complement-orange-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  color: #b8daf8;
}
.btn-complement-orange-rounded:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-black-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #020202;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-black-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  border: 1px solid #020202;
}
.btn-outlined-black-rounded:hover {
  background-color: #020202;
}

.btn-complement-black-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  color: #4f4f4f;
}
.btn-complement-black-rounded:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-deep-green-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #014345;
  display: grid;
  place-items: center;
  height: 5.6rem;
  width: 5.6rem;
}

.btn-outlined-deep-green-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  border: 1px solid #014345;
}
.btn-outlined-deep-green-rounded:hover {
  background-color: #014345;
}

.btn-complement-deep-green-rounded {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  color: #dc0a03;
}
.btn-complement-deep-green-rounded:hover {
  color: #014345;
  background-color: #dc0a03;
}

.btn-icon-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-primary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-primary-sm:hover {
  background-color: #f44b0d;
}

.btn-icon-outlined-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #de430a;
}
.btn-icon-outlined-primary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-primary-sm:hover {
  background-color: #de430a;
}

.btn-icon-complement-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #87dbfa;
}
.btn-icon-complement-primary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-primary-sm:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-icon-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-secondary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-secondary-sm:hover {
  background-color: #49bc02;
}

.btn-icon-outlined-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #3fa302;
}
.btn-icon-outlined-secondary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-secondary-sm:hover {
  background-color: #3fa302;
}

.btn-icon-complement-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #b641fd;
}
.btn-icon-complement-secondary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-secondary-sm:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-icon-accent-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-accent-primary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-accent-primary-sm:hover {
  background-color: #fff6f0;
}

.btn-icon-outlined-accent-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ffe7d6;
}
.btn-icon-outlined-accent-primary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-accent-primary-sm:hover {
  background-color: #ffe7d6;
}

.btn-icon-complement-accent-primary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-accent-primary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-accent-primary-sm:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-icon-accent-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-accent-secondary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-accent-secondary-sm:hover {
  background-color: #fff6f0;
}

.btn-icon-outlined-accent-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ffe7d6;
}
.btn-icon-outlined-accent-secondary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-accent-secondary-sm:hover {
  background-color: #ffe7d6;
}

.btn-icon-complement-accent-secondary-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-accent-secondary-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-accent-secondary-sm:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-icon-error-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-error-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-error-sm:hover {
  background-color: #ff271a;
}

.btn-icon-outlined-error-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ff0f00;
}
.btn-icon-outlined-error-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-error-sm:hover {
  background-color: #ff0f00;
}

.btn-icon-complement-error-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #99f9ff;
}
.btn-icon-complement-error-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-error-sm:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-icon-info-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-info-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-info-sm:hover {
  background-color: #f7c935;
}

.btn-icon-outlined-info-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #f6c31c;
}
.btn-icon-outlined-info-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-info-sm:hover {
  background-color: #f6c31c;
}

.btn-icon-complement-info-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #afc1fc;
}
.btn-icon-complement-info-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-info-sm:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-icon-white-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-white-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-white-sm:hover {
  background-color: white;
}

.btn-icon-outlined-white-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #f7f8f7;
}
.btn-icon-outlined-white-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-white-sm:hover {
  background-color: #f7f8f7;
}

.btn-icon-complement-white-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-white-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-white-sm:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-icon-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-green-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-green-sm:hover {
  background-color: #0d9b8b;
}

.btn-icon-outlined-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #0b8376;
}
.btn-icon-outlined-green-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-green-sm:hover {
  background-color: #0b8376;
}

.btn-icon-complement-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #ee394c;
}
.btn-icon-complement-green-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-green-sm:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-icon-purple-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-purple-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-purple-sm:hover {
  background-color: #8770e6;
}

.btn-icon-outlined-purple-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #755ae2;
}
.btn-icon-outlined-purple-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-purple-sm:hover {
  background-color: #755ae2;
}

.btn-icon-complement-purple-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #f3f9dc;
}
.btn-icon-complement-purple-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-purple-sm:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-icon-orange-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-orange-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-orange-sm:hover {
  background-color: #ec9444;
}

.btn-icon-outlined-orange-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ea872d;
}
.btn-icon-outlined-orange-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-orange-sm:hover {
  background-color: #ea872d;
}

.btn-icon-complement-orange-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #b8daf8;
}
.btn-icon-complement-orange-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-orange-sm:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-icon-black-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-black-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-black-sm:hover {
  background-color: #0f0f0f;
}

.btn-icon-outlined-black-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #020202;
}
.btn-icon-outlined-black-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-black-sm:hover {
  background-color: #020202;
}

.btn-icon-complement-black-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #4f4f4f;
}
.btn-icon-complement-black-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-black-sm:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-icon-deep-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-deep-green-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-deep-green-sm:hover {
  background-color: #015b5e;
}

.btn-icon-outlined-deep-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #014345;
}
.btn-icon-outlined-deep-green-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-deep-green-sm:hover {
  background-color: #014345;
}

.btn-icon-complement-deep-green-sm {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.1rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #dc0a03;
}
.btn-icon-complement-deep-green-sm svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-deep-green-sm:hover {
  color: #014345;
  background-color: #dc0a03;
}

.btn-icon-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-primary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-primary-md:hover {
  background-color: #f44b0d;
}

.btn-icon-outlined-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #de430a;
}
.btn-icon-outlined-primary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-primary-md:hover {
  background-color: #de430a;
}

.btn-icon-complement-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #87dbfa;
}
.btn-icon-complement-primary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-primary-md:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-icon-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-secondary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-secondary-md:hover {
  background-color: #49bc02;
}

.btn-icon-outlined-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #3fa302;
}
.btn-icon-outlined-secondary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-secondary-md:hover {
  background-color: #3fa302;
}

.btn-icon-complement-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #b641fd;
}
.btn-icon-complement-secondary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-secondary-md:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-icon-accent-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-accent-primary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-accent-primary-md:hover {
  background-color: #fff6f0;
}

.btn-icon-outlined-accent-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ffe7d6;
}
.btn-icon-outlined-accent-primary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-accent-primary-md:hover {
  background-color: #ffe7d6;
}

.btn-icon-complement-accent-primary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-accent-primary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-accent-primary-md:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-icon-accent-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-accent-secondary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-accent-secondary-md:hover {
  background-color: #fff6f0;
}

.btn-icon-outlined-accent-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ffe7d6;
}
.btn-icon-outlined-accent-secondary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-accent-secondary-md:hover {
  background-color: #ffe7d6;
}

.btn-icon-complement-accent-secondary-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-accent-secondary-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-accent-secondary-md:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-icon-error-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-error-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-error-md:hover {
  background-color: #ff271a;
}

.btn-icon-outlined-error-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ff0f00;
}
.btn-icon-outlined-error-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-error-md:hover {
  background-color: #ff0f00;
}

.btn-icon-complement-error-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #99f9ff;
}
.btn-icon-complement-error-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-error-md:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-icon-info-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-info-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-info-md:hover {
  background-color: #f7c935;
}

.btn-icon-outlined-info-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #f6c31c;
}
.btn-icon-outlined-info-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-info-md:hover {
  background-color: #f6c31c;
}

.btn-icon-complement-info-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #afc1fc;
}
.btn-icon-complement-info-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-info-md:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-icon-white-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-white-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-white-md:hover {
  background-color: white;
}

.btn-icon-outlined-white-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #f7f8f7;
}
.btn-icon-outlined-white-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-white-md:hover {
  background-color: #f7f8f7;
}

.btn-icon-complement-white-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-white-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-white-md:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-icon-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-green-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-green-md:hover {
  background-color: #0d9b8b;
}

.btn-icon-outlined-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #0b8376;
}
.btn-icon-outlined-green-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-green-md:hover {
  background-color: #0b8376;
}

.btn-icon-complement-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #ee394c;
}
.btn-icon-complement-green-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-green-md:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-icon-purple-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-purple-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-purple-md:hover {
  background-color: #8770e6;
}

.btn-icon-outlined-purple-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #755ae2;
}
.btn-icon-outlined-purple-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-purple-md:hover {
  background-color: #755ae2;
}

.btn-icon-complement-purple-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #f3f9dc;
}
.btn-icon-complement-purple-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-purple-md:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-icon-orange-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-orange-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-orange-md:hover {
  background-color: #ec9444;
}

.btn-icon-outlined-orange-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ea872d;
}
.btn-icon-outlined-orange-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-orange-md:hover {
  background-color: #ea872d;
}

.btn-icon-complement-orange-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #b8daf8;
}
.btn-icon-complement-orange-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-orange-md:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-icon-black-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-black-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-black-md:hover {
  background-color: #0f0f0f;
}

.btn-icon-outlined-black-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #020202;
}
.btn-icon-outlined-black-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-black-md:hover {
  background-color: #020202;
}

.btn-icon-complement-black-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #4f4f4f;
}
.btn-icon-complement-black-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-black-md:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-icon-deep-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-deep-green-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-deep-green-md:hover {
  background-color: #015b5e;
}

.btn-icon-outlined-deep-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #014345;
}
.btn-icon-outlined-deep-green-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-deep-green-md:hover {
  background-color: #014345;
}

.btn-icon-complement-deep-green-md {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 1.6rem 2.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #dc0a03;
}
.btn-icon-complement-deep-green-md svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-deep-green-md:hover {
  color: #014345;
  background-color: #dc0a03;
}

.btn-icon-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-primary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-primary-lg:hover {
  background-color: #f44b0d;
}

.btn-icon-outlined-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #de430a;
}
.btn-icon-outlined-primary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-primary-lg:hover {
  background-color: #de430a;
}

.btn-icon-complement-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #de430a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #87dbfa;
}
.btn-icon-complement-primary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-primary-lg:hover {
  color: #de430a;
  background-color: #87dbfa;
}

.btn-icon-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-secondary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-secondary-lg:hover {
  background-color: #49bc02;
}

.btn-icon-outlined-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #3fa302;
}
.btn-icon-outlined-secondary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-secondary-lg:hover {
  background-color: #3fa302;
}

.btn-icon-complement-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #3fa302;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #b641fd;
}
.btn-icon-complement-secondary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-secondary-lg:hover {
  color: #3fa302;
  background-color: #b641fd;
}

.btn-icon-accent-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-accent-primary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-accent-primary-lg:hover {
  background-color: #fff6f0;
}

.btn-icon-outlined-accent-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ffe7d6;
}
.btn-icon-outlined-accent-primary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-accent-primary-lg:hover {
  background-color: #ffe7d6;
}

.btn-icon-complement-accent-primary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-accent-primary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-accent-primary-lg:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-icon-accent-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-accent-secondary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-accent-secondary-lg:hover {
  background-color: #fff6f0;
}

.btn-icon-outlined-accent-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ffe7d6;
}
.btn-icon-outlined-accent-secondary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-accent-secondary-lg:hover {
  background-color: #ffe7d6;
}

.btn-icon-complement-accent-secondary-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ffe7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-accent-secondary-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-accent-secondary-lg:hover {
  color: #ffe7d6;
  background-color: white;
}

.btn-icon-error-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-error-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-error-lg:hover {
  background-color: #ff271a;
}

.btn-icon-outlined-error-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ff0f00;
}
.btn-icon-outlined-error-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-error-lg:hover {
  background-color: #ff0f00;
}

.btn-icon-complement-error-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ff0f00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #99f9ff;
}
.btn-icon-complement-error-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-error-lg:hover {
  color: #ff0f00;
  background-color: #99f9ff;
}

.btn-icon-info-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-info-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-info-lg:hover {
  background-color: #f7c935;
}

.btn-icon-outlined-info-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #f6c31c;
}
.btn-icon-outlined-info-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-info-lg:hover {
  background-color: #f6c31c;
}

.btn-icon-complement-info-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f6c31c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #afc1fc;
}
.btn-icon-complement-info-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-info-lg:hover {
  color: #f6c31c;
  background-color: #afc1fc;
}

.btn-icon-white-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-white-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-white-lg:hover {
  background-color: white;
}

.btn-icon-outlined-white-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #f7f8f7;
}
.btn-icon-outlined-white-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-white-lg:hover {
  background-color: #f7f8f7;
}

.btn-icon-complement-white-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #f7f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: white;
}
.btn-icon-complement-white-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-white-lg:hover {
  color: #f7f8f7;
  background-color: white;
}

.btn-icon-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-green-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-green-lg:hover {
  background-color: #0d9b8b;
}

.btn-icon-outlined-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #0b8376;
}
.btn-icon-outlined-green-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-green-lg:hover {
  background-color: #0b8376;
}

.btn-icon-complement-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #0b8376;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #ee394c;
}
.btn-icon-complement-green-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-green-lg:hover {
  color: #0b8376;
  background-color: #ee394c;
}

.btn-icon-purple-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-purple-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-purple-lg:hover {
  background-color: #8770e6;
}

.btn-icon-outlined-purple-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #755ae2;
}
.btn-icon-outlined-purple-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-purple-lg:hover {
  background-color: #755ae2;
}

.btn-icon-complement-purple-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #755ae2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #f3f9dc;
}
.btn-icon-complement-purple-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-purple-lg:hover {
  color: #755ae2;
  background-color: #f3f9dc;
}

.btn-icon-orange-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-orange-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-orange-lg:hover {
  background-color: #ec9444;
}

.btn-icon-outlined-orange-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #ea872d;
}
.btn-icon-outlined-orange-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-orange-lg:hover {
  background-color: #ea872d;
}

.btn-icon-complement-orange-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #ea872d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #b8daf8;
}
.btn-icon-complement-orange-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-orange-lg:hover {
  color: #ea872d;
  background-color: #b8daf8;
}

.btn-icon-black-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-black-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-black-lg:hover {
  background-color: #0f0f0f;
}

.btn-icon-outlined-black-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #020202;
}
.btn-icon-outlined-black-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-black-lg:hover {
  background-color: #020202;
}

.btn-icon-complement-black-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #4f4f4f;
}
.btn-icon-complement-black-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-black-lg:hover {
  color: #020202;
  background-color: #4f4f4f;
}

.btn-icon-deep-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
}
.btn-icon-deep-green-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-deep-green-lg:hover {
  background-color: #015b5e;
}

.btn-icon-outlined-deep-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid #014345;
}
.btn-icon-outlined-deep-green-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-outlined-deep-green-lg:hover {
  background-color: #014345;
}

.btn-icon-complement-deep-green-lg {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 2rem 5.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  background-color: #014345;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: max-content;
  color: #dc0a03;
}
.btn-icon-complement-deep-green-lg svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon-complement-deep-green-lg:hover {
  color: #014345;
  background-color: #dc0a03;
}

.navbar .container,
.navbar-deep-green .container,
.navbar-black .container,
.navbar-orange .container,
.navbar-purple .container,
.navbar-green .container,
.navbar-white .container,
.navbar-info .container,
.navbar-error .container,
.navbar-accent-secondary .container,
.navbar-accent-primary .container,
.navbar-secondary .container,
.navbar-primary .container,
.navbar,
.navbar-deep-green,
.navbar-black,
.navbar-orange,
.navbar-purple,
.navbar-green,
.navbar-white,
.navbar-info,
.navbar-error,
.navbar-accent-secondary,
.navbar-accent-primary,
.navbar-secondary,
.navbar-primary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.navbar,
.navbar-deep-green,
.navbar-black,
.navbar-orange,
.navbar-purple,
.navbar-green,
.navbar-white,
.navbar-info,
.navbar-error,
.navbar-accent-secondary,
.navbar-accent-primary,
.navbar-secondary,
.navbar-primary {
  padding: 1rem 2rem;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}
.navbar .site-title,
.navbar-deep-green .site-title,
.navbar-black .site-title,
.navbar-orange .site-title,
.navbar-purple .site-title,
.navbar-green .site-title,
.navbar-white .site-title,
.navbar-info .site-title,
.navbar-error .site-title,
.navbar-accent-secondary .site-title,
.navbar-accent-primary .site-title,
.navbar-secondary .site-title,
.navbar-primary .site-title {
  font-size: 2rem;
}

.navbar-primary {
  background-color: #de430a;
}

.navbar-secondary {
  background-color: #3fa302;
}

.navbar-accent-primary {
  background-color: #ffe7d6;
}

.navbar-accent-secondary {
  background-color: #ffe7d6;
}

.navbar-error {
  background-color: #ff0f00;
}

.navbar-info {
  background-color: #f6c31c;
}

.navbar-white {
  background-color: #f7f8f7;
}

.navbar-green {
  background-color: #0b8376;
}

.navbar-purple {
  background-color: #755ae2;
}

.navbar-orange {
  background-color: #ea872d;
}

.navbar-black {
  background-color: #020202;
}

.navbar-deep-green {
  background-color: #014345;
}

.avatar-xs {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
}

.avatar-sm {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
}

.avatar {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}

.avatar-md {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}

.avatar-lg {
  width: 14rem;
  height: 14rem;
  object-fit: cover;
}

.avatar-xl {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
}

@media (min-width: 0) {
  .xs:p-0 {
    padding: 0rem;
  }
  .xs:p-1 {
    padding: 1rem;
  }
  .xs:p-2 {
    padding: 2rem;
  }
  .xs:p-3 {
    padding: 3rem;
  }
  .xs:p-4 {
    padding: 4rem;
  }
  .xs:p-5 {
    padding: 5rem;
  }
  .xs:p-6 {
    padding: 6rem;
  }
  .xs:p-7 {
    padding: 7rem;
  }
  .xs:p-8 {
    padding: 8rem;
  }
  .xs:p-9 {
    padding: 9rem;
  }
  .xs:p-10 {
    padding: 10rem;
  }
  .xs:p-11 {
    padding: 11rem;
  }
  .xs:p-12 {
    padding: 12rem;
  }
  .xs:p-13 {
    padding: 13rem;
  }
  .xs:p-14 {
    padding: 14rem;
  }
  .xs:p-15 {
    padding: 15rem;
  }
  .xs:p-16 {
    padding: 16rem;
  }
  .xs:p-17 {
    padding: 17rem;
  }
  .xs:p-18 {
    padding: 18rem;
  }
  .xs:p-19 {
    padding: 19rem;
  }
  .xs:p-20 {
    padding: 20rem;
  }
  .xs:p-21 {
    padding: 21rem;
  }
  .xs:p-22 {
    padding: 22rem;
  }
  .xs:p-23 {
    padding: 23rem;
  }
  .xs:p-24 {
    padding: 24rem;
  }
  .xs:p-25 {
    padding: 25rem;
  }
  .xs:p-26 {
    padding: 26rem;
  }
  .xs:p-27 {
    padding: 27rem;
  }
  .xs:p-28 {
    padding: 28rem;
  }
  .xs:p-29 {
    padding: 29rem;
  }
  .xs:p-30 {
    padding: 30rem;
  }
  .xs:p-31 {
    padding: 31rem;
  }
  .xs:p-32 {
    padding: 32rem;
  }
  .xs:p-33 {
    padding: 33rem;
  }
  .xs:p-34 {
    padding: 34rem;
  }
  .xs:p-35 {
    padding: 35rem;
  }
  .xs:p-36 {
    padding: 36rem;
  }
  .xs:p-37 {
    padding: 37rem;
  }
  .xs:p-38 {
    padding: 38rem;
  }
  .xs:p-39 {
    padding: 39rem;
  }
  .xs:p-40 {
    padding: 40rem;
  }
  .xs:p-41 {
    padding: 41rem;
  }
  .xs:p-42 {
    padding: 42rem;
  }
  .xs:p-43 {
    padding: 43rem;
  }
  .xs:p-44 {
    padding: 44rem;
  }
  .xs:p-45 {
    padding: 45rem;
  }
  .xs:p-46 {
    padding: 46rem;
  }
  .xs:p-47 {
    padding: 47rem;
  }
  .xs:p-48 {
    padding: 48rem;
  }
  .xs:p-49 {
    padding: 49rem;
  }
  .xs:p-50 {
    padding: 50rem;
  }
  .xs:p-51 {
    padding: 51rem;
  }
  .xs:p-52 {
    padding: 52rem;
  }
  .xs:p-53 {
    padding: 53rem;
  }
  .xs:p-54 {
    padding: 54rem;
  }
  .xs:p-55 {
    padding: 55rem;
  }
  .xs:p-56 {
    padding: 56rem;
  }
  .xs:p-57 {
    padding: 57rem;
  }
  .xs:p-58 {
    padding: 58rem;
  }
  .xs:p-59 {
    padding: 59rem;
  }
  .xs:p-60 {
    padding: 60rem;
  }
  .xs:p-61 {
    padding: 61rem;
  }
  .xs:p-62 {
    padding: 62rem;
  }
  .xs:p-63 {
    padding: 63rem;
  }
  .xs:p-64 {
    padding: 64rem;
  }
  .xs:p-65 {
    padding: 65rem;
  }
  .xs:p-66 {
    padding: 66rem;
  }
  .xs:p-67 {
    padding: 67rem;
  }
  .xs:p-68 {
    padding: 68rem;
  }
  .xs:p-69 {
    padding: 69rem;
  }
  .xs:p-70 {
    padding: 70rem;
  }
  .xs:p-71 {
    padding: 71rem;
  }
  .xs:p-72 {
    padding: 72rem;
  }
  .xs:p-0-0 {
    padding: 0rem;
  }
  .xs:p-0-1 {
    padding: 0.1rem;
  }
  .xs:p-0-2 {
    padding: 0.2rem;
  }
  .xs:p-0-3 {
    padding: 0.3rem;
  }
  .xs:p-0-4 {
    padding: 0.4rem;
  }
  .xs:p-0-5 {
    padding: 0.5rem;
  }
  .xs:p-0-6 {
    padding: 0.6rem;
  }
  .xs:p-0-7 {
    padding: 0.7rem;
  }
  .xs:p-0-8 {
    padding: 0.8rem;
  }
  .xs:p-0-9 {
    padding: 0.9rem;
  }
  .xs:p-1-0 {
    padding: 1rem;
  }
  .xs:p-1-1 {
    padding: 1.1rem;
  }
  .xs:p-1-2 {
    padding: 1.2rem;
  }
  .xs:p-1-3 {
    padding: 1.3rem;
  }
  .xs:p-1-4 {
    padding: 1.4rem;
  }
  .xs:p-1-5 {
    padding: 1.5rem;
  }
  .xs:p-1-6 {
    padding: 1.6rem;
  }
  .xs:p-1-7 {
    padding: 1.7rem;
  }
  .xs:p-1-8 {
    padding: 1.8rem;
  }
  .xs:p-1-9 {
    padding: 1.9rem;
  }
  .xs:p-2-0 {
    padding: 2rem;
  }
  .xs:p-2-1 {
    padding: 2.1rem;
  }
  .xs:p-2-2 {
    padding: 2.2rem;
  }
  .xs:p-2-3 {
    padding: 2.3rem;
  }
  .xs:p-2-4 {
    padding: 2.4rem;
  }
  .xs:p-2-5 {
    padding: 2.5rem;
  }
  .xs:p-2-6 {
    padding: 2.6rem;
  }
  .xs:p-2-7 {
    padding: 2.7rem;
  }
  .xs:p-2-8 {
    padding: 2.8rem;
  }
  .xs:p-2-9 {
    padding: 2.9rem;
  }
  .xs:p-3-0 {
    padding: 3rem;
  }
  .xs:p-3-1 {
    padding: 3.1rem;
  }
  .xs:p-3-2 {
    padding: 3.2rem;
  }
  .xs:p-3-3 {
    padding: 3.3rem;
  }
  .xs:p-3-4 {
    padding: 3.4rem;
  }
  .xs:p-3-5 {
    padding: 3.5rem;
  }
  .xs:p-3-6 {
    padding: 3.6rem;
  }
  .xs:p-3-7 {
    padding: 3.7rem;
  }
  .xs:p-3-8 {
    padding: 3.8rem;
  }
  .xs:p-3-9 {
    padding: 3.9rem;
  }
  .xs:p-4-0 {
    padding: 4rem;
  }
  .xs:p-4-1 {
    padding: 4.1rem;
  }
  .xs:p-4-2 {
    padding: 4.2rem;
  }
  .xs:p-4-3 {
    padding: 4.3rem;
  }
  .xs:p-4-4 {
    padding: 4.4rem;
  }
  .xs:p-4-5 {
    padding: 4.5rem;
  }
  .xs:p-4-6 {
    padding: 4.6rem;
  }
  .xs:p-4-7 {
    padding: 4.7rem;
  }
  .xs:p-4-8 {
    padding: 4.8rem;
  }
  .xs:p-4-9 {
    padding: 4.9rem;
  }
  .xs:p-5-0 {
    padding: 5rem;
  }
  .xs:p-5-1 {
    padding: 5.1rem;
  }
  .xs:p-5-2 {
    padding: 5.2rem;
  }
  .xs:p-5-3 {
    padding: 5.3rem;
  }
  .xs:p-5-4 {
    padding: 5.4rem;
  }
  .xs:p-5-5 {
    padding: 5.5rem;
  }
  .xs:p-5-6 {
    padding: 5.6rem;
  }
  .xs:p-5-7 {
    padding: 5.7rem;
  }
  .xs:p-5-8 {
    padding: 5.8rem;
  }
  .xs:p-5-9 {
    padding: 5.9rem;
  }
  .xs:p-6-0 {
    padding: 6rem;
  }
  .xs:p-6-1 {
    padding: 6.1rem;
  }
  .xs:p-6-2 {
    padding: 6.2rem;
  }
  .xs:p-6-3 {
    padding: 6.3rem;
  }
  .xs:p-6-4 {
    padding: 6.4rem;
  }
  .xs:p-6-5 {
    padding: 6.5rem;
  }
  .xs:p-6-6 {
    padding: 6.6rem;
  }
  .xs:p-6-7 {
    padding: 6.7rem;
  }
  .xs:p-6-8 {
    padding: 6.8rem;
  }
  .xs:p-6-9 {
    padding: 6.9rem;
  }
  .xs:p-7-0 {
    padding: 7rem;
  }
  .xs:p-7-1 {
    padding: 7.1rem;
  }
  .xs:p-7-2 {
    padding: 7.2rem;
  }
  .xs:p-7-3 {
    padding: 7.3rem;
  }
  .xs:p-7-4 {
    padding: 7.4rem;
  }
  .xs:p-7-5 {
    padding: 7.5rem;
  }
  .xs:p-7-6 {
    padding: 7.6rem;
  }
  .xs:p-7-7 {
    padding: 7.7rem;
  }
  .xs:p-7-8 {
    padding: 7.8rem;
  }
  .xs:p-7-9 {
    padding: 7.9rem;
  }
  .xs:p-8-0 {
    padding: 8rem;
  }
  .xs:p-8-1 {
    padding: 8.1rem;
  }
  .xs:p-8-2 {
    padding: 8.2rem;
  }
  .xs:p-8-3 {
    padding: 8.3rem;
  }
  .xs:p-8-4 {
    padding: 8.4rem;
  }
  .xs:p-8-5 {
    padding: 8.5rem;
  }
  .xs:p-8-6 {
    padding: 8.6rem;
  }
  .xs:p-8-7 {
    padding: 8.7rem;
  }
  .xs:p-8-8 {
    padding: 8.8rem;
  }
  .xs:p-8-9 {
    padding: 8.9rem;
  }
  .xs:p-9-0 {
    padding: 9rem;
  }
  .xs:p-9-1 {
    padding: 9.1rem;
  }
  .xs:p-9-2 {
    padding: 9.2rem;
  }
  .xs:p-9-3 {
    padding: 9.3rem;
  }
  .xs:p-9-4 {
    padding: 9.4rem;
  }
  .xs:p-9-5 {
    padding: 9.5rem;
  }
  .xs:p-9-6 {
    padding: 9.6rem;
  }
  .xs:p-9-7 {
    padding: 9.7rem;
  }
  .xs:p-9-8 {
    padding: 9.8rem;
  }
  .xs:p-9-9 {
    padding: 9.9rem;
  }
  .xs:p-10-0 {
    padding: 10rem;
  }
  .xs:p-10-1 {
    padding: 10.1rem;
  }
  .xs:p-10-2 {
    padding: 10.2rem;
  }
  .xs:p-10-3 {
    padding: 10.3rem;
  }
  .xs:p-10-4 {
    padding: 10.4rem;
  }
  .xs:p-10-5 {
    padding: 10.5rem;
  }
  .xs:p-10-6 {
    padding: 10.6rem;
  }
  .xs:p-10-7 {
    padding: 10.7rem;
  }
  .xs:p-10-8 {
    padding: 10.8rem;
  }
  .xs:p-10-9 {
    padding: 10.9rem;
  }
  .xs:p-11-0 {
    padding: 11rem;
  }
  .xs:p-11-1 {
    padding: 11.1rem;
  }
  .xs:p-11-2 {
    padding: 11.2rem;
  }
  .xs:p-11-3 {
    padding: 11.3rem;
  }
  .xs:p-11-4 {
    padding: 11.4rem;
  }
  .xs:p-11-5 {
    padding: 11.5rem;
  }
  .xs:p-11-6 {
    padding: 11.6rem;
  }
  .xs:p-11-7 {
    padding: 11.7rem;
  }
  .xs:p-11-8 {
    padding: 11.8rem;
  }
  .xs:p-11-9 {
    padding: 11.9rem;
  }
  .xs:p-12-0 {
    padding: 12rem;
  }
  .xs:p-12-1 {
    padding: 12.1rem;
  }
  .xs:p-12-2 {
    padding: 12.2rem;
  }
  .xs:p-12-3 {
    padding: 12.3rem;
  }
  .xs:p-12-4 {
    padding: 12.4rem;
  }
  .xs:p-12-5 {
    padding: 12.5rem;
  }
  .xs:p-12-6 {
    padding: 12.6rem;
  }
  .xs:p-12-7 {
    padding: 12.7rem;
  }
  .xs:p-12-8 {
    padding: 12.8rem;
  }
  .xs:p-12-9 {
    padding: 12.9rem;
  }
  .xs:p-13-0 {
    padding: 13rem;
  }
  .xs:p-13-1 {
    padding: 13.1rem;
  }
  .xs:p-13-2 {
    padding: 13.2rem;
  }
  .xs:p-13-3 {
    padding: 13.3rem;
  }
  .xs:p-13-4 {
    padding: 13.4rem;
  }
  .xs:p-13-5 {
    padding: 13.5rem;
  }
  .xs:p-13-6 {
    padding: 13.6rem;
  }
  .xs:p-13-7 {
    padding: 13.7rem;
  }
  .xs:p-13-8 {
    padding: 13.8rem;
  }
  .xs:p-13-9 {
    padding: 13.9rem;
  }
  .xs:p-14-0 {
    padding: 14rem;
  }
  .xs:p-14-1 {
    padding: 14.1rem;
  }
  .xs:p-14-2 {
    padding: 14.2rem;
  }
  .xs:p-14-3 {
    padding: 14.3rem;
  }
  .xs:p-14-4 {
    padding: 14.4rem;
  }
  .xs:p-14-5 {
    padding: 14.5rem;
  }
  .xs:p-14-6 {
    padding: 14.6rem;
  }
  .xs:p-14-7 {
    padding: 14.7rem;
  }
  .xs:p-14-8 {
    padding: 14.8rem;
  }
  .xs:p-14-9 {
    padding: 14.9rem;
  }
  .xs:p-15-0 {
    padding: 15rem;
  }
  .xs:p-15-1 {
    padding: 15.1rem;
  }
  .xs:p-15-2 {
    padding: 15.2rem;
  }
  .xs:p-15-3 {
    padding: 15.3rem;
  }
  .xs:p-15-4 {
    padding: 15.4rem;
  }
  .xs:p-15-5 {
    padding: 15.5rem;
  }
  .xs:p-15-6 {
    padding: 15.6rem;
  }
  .xs:p-15-7 {
    padding: 15.7rem;
  }
  .xs:p-15-8 {
    padding: 15.8rem;
  }
  .xs:p-15-9 {
    padding: 15.9rem;
  }
  .xs:p-16-0 {
    padding: 16rem;
  }
  .xs:p-16-1 {
    padding: 16.1rem;
  }
  .xs:p-16-2 {
    padding: 16.2rem;
  }
  .xs:p-16-3 {
    padding: 16.3rem;
  }
  .xs:p-16-4 {
    padding: 16.4rem;
  }
  .xs:p-16-5 {
    padding: 16.5rem;
  }
  .xs:p-16-6 {
    padding: 16.6rem;
  }
  .xs:p-16-7 {
    padding: 16.7rem;
  }
  .xs:p-16-8 {
    padding: 16.8rem;
  }
  .xs:p-16-9 {
    padding: 16.9rem;
  }
  .xs:p-17-0 {
    padding: 17rem;
  }
  .xs:p-17-1 {
    padding: 17.1rem;
  }
  .xs:p-17-2 {
    padding: 17.2rem;
  }
  .xs:p-17-3 {
    padding: 17.3rem;
  }
  .xs:p-17-4 {
    padding: 17.4rem;
  }
  .xs:p-17-5 {
    padding: 17.5rem;
  }
  .xs:p-17-6 {
    padding: 17.6rem;
  }
  .xs:p-17-7 {
    padding: 17.7rem;
  }
  .xs:p-17-8 {
    padding: 17.8rem;
  }
  .xs:p-17-9 {
    padding: 17.9rem;
  }
  .xs:p-18-0 {
    padding: 18rem;
  }
  .xs:p-18-1 {
    padding: 18.1rem;
  }
  .xs:p-18-2 {
    padding: 18.2rem;
  }
  .xs:p-18-3 {
    padding: 18.3rem;
  }
  .xs:p-18-4 {
    padding: 18.4rem;
  }
  .xs:p-18-5 {
    padding: 18.5rem;
  }
  .xs:p-18-6 {
    padding: 18.6rem;
  }
  .xs:p-18-7 {
    padding: 18.7rem;
  }
  .xs:p-18-8 {
    padding: 18.8rem;
  }
  .xs:p-18-9 {
    padding: 18.9rem;
  }
  .xs:p-19-0 {
    padding: 19rem;
  }
  .xs:p-19-1 {
    padding: 19.1rem;
  }
  .xs:p-19-2 {
    padding: 19.2rem;
  }
  .xs:p-19-3 {
    padding: 19.3rem;
  }
  .xs:p-19-4 {
    padding: 19.4rem;
  }
  .xs:p-19-5 {
    padding: 19.5rem;
  }
  .xs:p-19-6 {
    padding: 19.6rem;
  }
  .xs:p-19-7 {
    padding: 19.7rem;
  }
  .xs:p-19-8 {
    padding: 19.8rem;
  }
  .xs:p-19-9 {
    padding: 19.9rem;
  }
  .xs:p-20-0 {
    padding: 20rem;
  }
  .xs:p-20-1 {
    padding: 20.1rem;
  }
  .xs:p-20-2 {
    padding: 20.2rem;
  }
  .xs:p-20-3 {
    padding: 20.3rem;
  }
  .xs:p-20-4 {
    padding: 20.4rem;
  }
  .xs:p-20-5 {
    padding: 20.5rem;
  }
  .xs:p-20-6 {
    padding: 20.6rem;
  }
  .xs:p-20-7 {
    padding: 20.7rem;
  }
  .xs:p-20-8 {
    padding: 20.8rem;
  }
  .xs:p-20-9 {
    padding: 20.9rem;
  }
  .xs:p-21-0 {
    padding: 21rem;
  }
  .xs:p-21-1 {
    padding: 21.1rem;
  }
  .xs:p-21-2 {
    padding: 21.2rem;
  }
  .xs:p-21-3 {
    padding: 21.3rem;
  }
  .xs:p-21-4 {
    padding: 21.4rem;
  }
  .xs:p-21-5 {
    padding: 21.5rem;
  }
  .xs:p-21-6 {
    padding: 21.6rem;
  }
  .xs:p-21-7 {
    padding: 21.7rem;
  }
  .xs:p-21-8 {
    padding: 21.8rem;
  }
  .xs:p-21-9 {
    padding: 21.9rem;
  }
  .xs:p-22-0 {
    padding: 22rem;
  }
  .xs:p-22-1 {
    padding: 22.1rem;
  }
  .xs:p-22-2 {
    padding: 22.2rem;
  }
  .xs:p-22-3 {
    padding: 22.3rem;
  }
  .xs:p-22-4 {
    padding: 22.4rem;
  }
  .xs:p-22-5 {
    padding: 22.5rem;
  }
  .xs:p-22-6 {
    padding: 22.6rem;
  }
  .xs:p-22-7 {
    padding: 22.7rem;
  }
  .xs:p-22-8 {
    padding: 22.8rem;
  }
  .xs:p-22-9 {
    padding: 22.9rem;
  }
  .xs:p-23-0 {
    padding: 23rem;
  }
  .xs:p-23-1 {
    padding: 23.1rem;
  }
  .xs:p-23-2 {
    padding: 23.2rem;
  }
  .xs:p-23-3 {
    padding: 23.3rem;
  }
  .xs:p-23-4 {
    padding: 23.4rem;
  }
  .xs:p-23-5 {
    padding: 23.5rem;
  }
  .xs:p-23-6 {
    padding: 23.6rem;
  }
  .xs:p-23-7 {
    padding: 23.7rem;
  }
  .xs:p-23-8 {
    padding: 23.8rem;
  }
  .xs:p-23-9 {
    padding: 23.9rem;
  }
  .xs:p-24-0 {
    padding: 24rem;
  }
  .xs:p-24-1 {
    padding: 24.1rem;
  }
  .xs:p-24-2 {
    padding: 24.2rem;
  }
  .xs:p-24-3 {
    padding: 24.3rem;
  }
  .xs:p-24-4 {
    padding: 24.4rem;
  }
  .xs:p-24-5 {
    padding: 24.5rem;
  }
  .xs:p-24-6 {
    padding: 24.6rem;
  }
  .xs:p-24-7 {
    padding: 24.7rem;
  }
  .xs:p-24-8 {
    padding: 24.8rem;
  }
  .xs:p-24-9 {
    padding: 24.9rem;
  }
  .xs:p-25-0 {
    padding: 25rem;
  }
  .xs:p-25-1 {
    padding: 25.1rem;
  }
  .xs:p-25-2 {
    padding: 25.2rem;
  }
  .xs:p-25-3 {
    padding: 25.3rem;
  }
  .xs:p-25-4 {
    padding: 25.4rem;
  }
  .xs:p-25-5 {
    padding: 25.5rem;
  }
  .xs:p-25-6 {
    padding: 25.6rem;
  }
  .xs:p-25-7 {
    padding: 25.7rem;
  }
  .xs:p-25-8 {
    padding: 25.8rem;
  }
  .xs:p-25-9 {
    padding: 25.9rem;
  }
  .xs:p-26-0 {
    padding: 26rem;
  }
  .xs:p-26-1 {
    padding: 26.1rem;
  }
  .xs:p-26-2 {
    padding: 26.2rem;
  }
  .xs:p-26-3 {
    padding: 26.3rem;
  }
  .xs:p-26-4 {
    padding: 26.4rem;
  }
  .xs:p-26-5 {
    padding: 26.5rem;
  }
  .xs:p-26-6 {
    padding: 26.6rem;
  }
  .xs:p-26-7 {
    padding: 26.7rem;
  }
  .xs:p-26-8 {
    padding: 26.8rem;
  }
  .xs:p-26-9 {
    padding: 26.9rem;
  }
  .xs:p-27-0 {
    padding: 27rem;
  }
  .xs:p-27-1 {
    padding: 27.1rem;
  }
  .xs:p-27-2 {
    padding: 27.2rem;
  }
  .xs:p-27-3 {
    padding: 27.3rem;
  }
  .xs:p-27-4 {
    padding: 27.4rem;
  }
  .xs:p-27-5 {
    padding: 27.5rem;
  }
  .xs:p-27-6 {
    padding: 27.6rem;
  }
  .xs:p-27-7 {
    padding: 27.7rem;
  }
  .xs:p-27-8 {
    padding: 27.8rem;
  }
  .xs:p-27-9 {
    padding: 27.9rem;
  }
  .xs:p-28-0 {
    padding: 28rem;
  }
  .xs:p-28-1 {
    padding: 28.1rem;
  }
  .xs:p-28-2 {
    padding: 28.2rem;
  }
  .xs:p-28-3 {
    padding: 28.3rem;
  }
  .xs:p-28-4 {
    padding: 28.4rem;
  }
  .xs:p-28-5 {
    padding: 28.5rem;
  }
  .xs:p-28-6 {
    padding: 28.6rem;
  }
  .xs:p-28-7 {
    padding: 28.7rem;
  }
  .xs:p-28-8 {
    padding: 28.8rem;
  }
  .xs:p-28-9 {
    padding: 28.9rem;
  }
  .xs:p-29-0 {
    padding: 29rem;
  }
  .xs:p-29-1 {
    padding: 29.1rem;
  }
  .xs:p-29-2 {
    padding: 29.2rem;
  }
  .xs:p-29-3 {
    padding: 29.3rem;
  }
  .xs:p-29-4 {
    padding: 29.4rem;
  }
  .xs:p-29-5 {
    padding: 29.5rem;
  }
  .xs:p-29-6 {
    padding: 29.6rem;
  }
  .xs:p-29-7 {
    padding: 29.7rem;
  }
  .xs:p-29-8 {
    padding: 29.8rem;
  }
  .xs:p-29-9 {
    padding: 29.9rem;
  }
  .xs:p-30-0 {
    padding: 30rem;
  }
  .xs:p-30-1 {
    padding: 30.1rem;
  }
  .xs:p-30-2 {
    padding: 30.2rem;
  }
  .xs:p-30-3 {
    padding: 30.3rem;
  }
  .xs:p-30-4 {
    padding: 30.4rem;
  }
  .xs:p-30-5 {
    padding: 30.5rem;
  }
  .xs:p-30-6 {
    padding: 30.6rem;
  }
  .xs:p-30-7 {
    padding: 30.7rem;
  }
  .xs:p-30-8 {
    padding: 30.8rem;
  }
  .xs:p-30-9 {
    padding: 30.9rem;
  }
  .xs:p-31-0 {
    padding: 31rem;
  }
  .xs:p-31-1 {
    padding: 31.1rem;
  }
  .xs:p-31-2 {
    padding: 31.2rem;
  }
  .xs:p-31-3 {
    padding: 31.3rem;
  }
  .xs:p-31-4 {
    padding: 31.4rem;
  }
  .xs:p-31-5 {
    padding: 31.5rem;
  }
  .xs:p-31-6 {
    padding: 31.6rem;
  }
  .xs:p-31-7 {
    padding: 31.7rem;
  }
  .xs:p-31-8 {
    padding: 31.8rem;
  }
  .xs:p-31-9 {
    padding: 31.9rem;
  }
  .xs:p-32-0 {
    padding: 32rem;
  }
  .xs:p-32-1 {
    padding: 32.1rem;
  }
  .xs:p-32-2 {
    padding: 32.2rem;
  }
  .xs:p-32-3 {
    padding: 32.3rem;
  }
  .xs:p-32-4 {
    padding: 32.4rem;
  }
  .xs:p-32-5 {
    padding: 32.5rem;
  }
  .xs:p-32-6 {
    padding: 32.6rem;
  }
  .xs:p-32-7 {
    padding: 32.7rem;
  }
  .xs:p-32-8 {
    padding: 32.8rem;
  }
  .xs:p-32-9 {
    padding: 32.9rem;
  }
  .xs:p-33-0 {
    padding: 33rem;
  }
  .xs:p-33-1 {
    padding: 33.1rem;
  }
  .xs:p-33-2 {
    padding: 33.2rem;
  }
  .xs:p-33-3 {
    padding: 33.3rem;
  }
  .xs:p-33-4 {
    padding: 33.4rem;
  }
  .xs:p-33-5 {
    padding: 33.5rem;
  }
  .xs:p-33-6 {
    padding: 33.6rem;
  }
  .xs:p-33-7 {
    padding: 33.7rem;
  }
  .xs:p-33-8 {
    padding: 33.8rem;
  }
  .xs:p-33-9 {
    padding: 33.9rem;
  }
  .xs:p-34-0 {
    padding: 34rem;
  }
  .xs:p-34-1 {
    padding: 34.1rem;
  }
  .xs:p-34-2 {
    padding: 34.2rem;
  }
  .xs:p-34-3 {
    padding: 34.3rem;
  }
  .xs:p-34-4 {
    padding: 34.4rem;
  }
  .xs:p-34-5 {
    padding: 34.5rem;
  }
  .xs:p-34-6 {
    padding: 34.6rem;
  }
  .xs:p-34-7 {
    padding: 34.7rem;
  }
  .xs:p-34-8 {
    padding: 34.8rem;
  }
  .xs:p-34-9 {
    padding: 34.9rem;
  }
  .xs:p-35-0 {
    padding: 35rem;
  }
  .xs:p-35-1 {
    padding: 35.1rem;
  }
  .xs:p-35-2 {
    padding: 35.2rem;
  }
  .xs:p-35-3 {
    padding: 35.3rem;
  }
  .xs:p-35-4 {
    padding: 35.4rem;
  }
  .xs:p-35-5 {
    padding: 35.5rem;
  }
  .xs:p-35-6 {
    padding: 35.6rem;
  }
  .xs:p-35-7 {
    padding: 35.7rem;
  }
  .xs:p-35-8 {
    padding: 35.8rem;
  }
  .xs:p-35-9 {
    padding: 35.9rem;
  }
  .xs:p-36-0 {
    padding: 36rem;
  }
  .xs:p-36-1 {
    padding: 36.1rem;
  }
  .xs:p-36-2 {
    padding: 36.2rem;
  }
  .xs:p-36-3 {
    padding: 36.3rem;
  }
  .xs:p-36-4 {
    padding: 36.4rem;
  }
  .xs:p-36-5 {
    padding: 36.5rem;
  }
  .xs:p-36-6 {
    padding: 36.6rem;
  }
  .xs:p-36-7 {
    padding: 36.7rem;
  }
  .xs:p-36-8 {
    padding: 36.8rem;
  }
  .xs:p-36-9 {
    padding: 36.9rem;
  }
  .xs:p-37-0 {
    padding: 37rem;
  }
  .xs:p-37-1 {
    padding: 37.1rem;
  }
  .xs:p-37-2 {
    padding: 37.2rem;
  }
  .xs:p-37-3 {
    padding: 37.3rem;
  }
  .xs:p-37-4 {
    padding: 37.4rem;
  }
  .xs:p-37-5 {
    padding: 37.5rem;
  }
  .xs:p-37-6 {
    padding: 37.6rem;
  }
  .xs:p-37-7 {
    padding: 37.7rem;
  }
  .xs:p-37-8 {
    padding: 37.8rem;
  }
  .xs:p-37-9 {
    padding: 37.9rem;
  }
  .xs:p-38-0 {
    padding: 38rem;
  }
  .xs:p-38-1 {
    padding: 38.1rem;
  }
  .xs:p-38-2 {
    padding: 38.2rem;
  }
  .xs:p-38-3 {
    padding: 38.3rem;
  }
  .xs:p-38-4 {
    padding: 38.4rem;
  }
  .xs:p-38-5 {
    padding: 38.5rem;
  }
  .xs:p-38-6 {
    padding: 38.6rem;
  }
  .xs:p-38-7 {
    padding: 38.7rem;
  }
  .xs:p-38-8 {
    padding: 38.8rem;
  }
  .xs:p-38-9 {
    padding: 38.9rem;
  }
  .xs:p-39-0 {
    padding: 39rem;
  }
  .xs:p-39-1 {
    padding: 39.1rem;
  }
  .xs:p-39-2 {
    padding: 39.2rem;
  }
  .xs:p-39-3 {
    padding: 39.3rem;
  }
  .xs:p-39-4 {
    padding: 39.4rem;
  }
  .xs:p-39-5 {
    padding: 39.5rem;
  }
  .xs:p-39-6 {
    padding: 39.6rem;
  }
  .xs:p-39-7 {
    padding: 39.7rem;
  }
  .xs:p-39-8 {
    padding: 39.8rem;
  }
  .xs:p-39-9 {
    padding: 39.9rem;
  }
  .xs:p-40-0 {
    padding: 40rem;
  }
  .xs:p-40-1 {
    padding: 40.1rem;
  }
  .xs:p-40-2 {
    padding: 40.2rem;
  }
  .xs:p-40-3 {
    padding: 40.3rem;
  }
  .xs:p-40-4 {
    padding: 40.4rem;
  }
  .xs:p-40-5 {
    padding: 40.5rem;
  }
  .xs:p-40-6 {
    padding: 40.6rem;
  }
  .xs:p-40-7 {
    padding: 40.7rem;
  }
  .xs:p-40-8 {
    padding: 40.8rem;
  }
  .xs:p-40-9 {
    padding: 40.9rem;
  }
  .xs:p-41-0 {
    padding: 41rem;
  }
  .xs:p-41-1 {
    padding: 41.1rem;
  }
  .xs:p-41-2 {
    padding: 41.2rem;
  }
  .xs:p-41-3 {
    padding: 41.3rem;
  }
  .xs:p-41-4 {
    padding: 41.4rem;
  }
  .xs:p-41-5 {
    padding: 41.5rem;
  }
  .xs:p-41-6 {
    padding: 41.6rem;
  }
  .xs:p-41-7 {
    padding: 41.7rem;
  }
  .xs:p-41-8 {
    padding: 41.8rem;
  }
  .xs:p-41-9 {
    padding: 41.9rem;
  }
  .xs:p-42-0 {
    padding: 42rem;
  }
  .xs:p-42-1 {
    padding: 42.1rem;
  }
  .xs:p-42-2 {
    padding: 42.2rem;
  }
  .xs:p-42-3 {
    padding: 42.3rem;
  }
  .xs:p-42-4 {
    padding: 42.4rem;
  }
  .xs:p-42-5 {
    padding: 42.5rem;
  }
  .xs:p-42-6 {
    padding: 42.6rem;
  }
  .xs:p-42-7 {
    padding: 42.7rem;
  }
  .xs:p-42-8 {
    padding: 42.8rem;
  }
  .xs:p-42-9 {
    padding: 42.9rem;
  }
  .xs:p-43-0 {
    padding: 43rem;
  }
  .xs:p-43-1 {
    padding: 43.1rem;
  }
  .xs:p-43-2 {
    padding: 43.2rem;
  }
  .xs:p-43-3 {
    padding: 43.3rem;
  }
  .xs:p-43-4 {
    padding: 43.4rem;
  }
  .xs:p-43-5 {
    padding: 43.5rem;
  }
  .xs:p-43-6 {
    padding: 43.6rem;
  }
  .xs:p-43-7 {
    padding: 43.7rem;
  }
  .xs:p-43-8 {
    padding: 43.8rem;
  }
  .xs:p-43-9 {
    padding: 43.9rem;
  }
  .xs:p-44-0 {
    padding: 44rem;
  }
  .xs:p-44-1 {
    padding: 44.1rem;
  }
  .xs:p-44-2 {
    padding: 44.2rem;
  }
  .xs:p-44-3 {
    padding: 44.3rem;
  }
  .xs:p-44-4 {
    padding: 44.4rem;
  }
  .xs:p-44-5 {
    padding: 44.5rem;
  }
  .xs:p-44-6 {
    padding: 44.6rem;
  }
  .xs:p-44-7 {
    padding: 44.7rem;
  }
  .xs:p-44-8 {
    padding: 44.8rem;
  }
  .xs:p-44-9 {
    padding: 44.9rem;
  }
  .xs:p-45-0 {
    padding: 45rem;
  }
  .xs:p-45-1 {
    padding: 45.1rem;
  }
  .xs:p-45-2 {
    padding: 45.2rem;
  }
  .xs:p-45-3 {
    padding: 45.3rem;
  }
  .xs:p-45-4 {
    padding: 45.4rem;
  }
  .xs:p-45-5 {
    padding: 45.5rem;
  }
  .xs:p-45-6 {
    padding: 45.6rem;
  }
  .xs:p-45-7 {
    padding: 45.7rem;
  }
  .xs:p-45-8 {
    padding: 45.8rem;
  }
  .xs:p-45-9 {
    padding: 45.9rem;
  }
  .xs:p-46-0 {
    padding: 46rem;
  }
  .xs:p-46-1 {
    padding: 46.1rem;
  }
  .xs:p-46-2 {
    padding: 46.2rem;
  }
  .xs:p-46-3 {
    padding: 46.3rem;
  }
  .xs:p-46-4 {
    padding: 46.4rem;
  }
  .xs:p-46-5 {
    padding: 46.5rem;
  }
  .xs:p-46-6 {
    padding: 46.6rem;
  }
  .xs:p-46-7 {
    padding: 46.7rem;
  }
  .xs:p-46-8 {
    padding: 46.8rem;
  }
  .xs:p-46-9 {
    padding: 46.9rem;
  }
  .xs:p-47-0 {
    padding: 47rem;
  }
  .xs:p-47-1 {
    padding: 47.1rem;
  }
  .xs:p-47-2 {
    padding: 47.2rem;
  }
  .xs:p-47-3 {
    padding: 47.3rem;
  }
  .xs:p-47-4 {
    padding: 47.4rem;
  }
  .xs:p-47-5 {
    padding: 47.5rem;
  }
  .xs:p-47-6 {
    padding: 47.6rem;
  }
  .xs:p-47-7 {
    padding: 47.7rem;
  }
  .xs:p-47-8 {
    padding: 47.8rem;
  }
  .xs:p-47-9 {
    padding: 47.9rem;
  }
  .xs:p-48-0 {
    padding: 48rem;
  }
  .xs:p-48-1 {
    padding: 48.1rem;
  }
  .xs:p-48-2 {
    padding: 48.2rem;
  }
  .xs:p-48-3 {
    padding: 48.3rem;
  }
  .xs:p-48-4 {
    padding: 48.4rem;
  }
  .xs:p-48-5 {
    padding: 48.5rem;
  }
  .xs:p-48-6 {
    padding: 48.6rem;
  }
  .xs:p-48-7 {
    padding: 48.7rem;
  }
  .xs:p-48-8 {
    padding: 48.8rem;
  }
  .xs:p-48-9 {
    padding: 48.9rem;
  }
  .xs:p-49-0 {
    padding: 49rem;
  }
  .xs:p-49-1 {
    padding: 49.1rem;
  }
  .xs:p-49-2 {
    padding: 49.2rem;
  }
  .xs:p-49-3 {
    padding: 49.3rem;
  }
  .xs:p-49-4 {
    padding: 49.4rem;
  }
  .xs:p-49-5 {
    padding: 49.5rem;
  }
  .xs:p-49-6 {
    padding: 49.6rem;
  }
  .xs:p-49-7 {
    padding: 49.7rem;
  }
  .xs:p-49-8 {
    padding: 49.8rem;
  }
  .xs:p-49-9 {
    padding: 49.9rem;
  }
  .xs:p-50-0 {
    padding: 50rem;
  }
  .xs:p-50-1 {
    padding: 50.1rem;
  }
  .xs:p-50-2 {
    padding: 50.2rem;
  }
  .xs:p-50-3 {
    padding: 50.3rem;
  }
  .xs:p-50-4 {
    padding: 50.4rem;
  }
  .xs:p-50-5 {
    padding: 50.5rem;
  }
  .xs:p-50-6 {
    padding: 50.6rem;
  }
  .xs:p-50-7 {
    padding: 50.7rem;
  }
  .xs:p-50-8 {
    padding: 50.8rem;
  }
  .xs:p-50-9 {
    padding: 50.9rem;
  }
  .xs:p-51-0 {
    padding: 51rem;
  }
  .xs:p-51-1 {
    padding: 51.1rem;
  }
  .xs:p-51-2 {
    padding: 51.2rem;
  }
  .xs:p-51-3 {
    padding: 51.3rem;
  }
  .xs:p-51-4 {
    padding: 51.4rem;
  }
  .xs:p-51-5 {
    padding: 51.5rem;
  }
  .xs:p-51-6 {
    padding: 51.6rem;
  }
  .xs:p-51-7 {
    padding: 51.7rem;
  }
  .xs:p-51-8 {
    padding: 51.8rem;
  }
  .xs:p-51-9 {
    padding: 51.9rem;
  }
  .xs:p-52-0 {
    padding: 52rem;
  }
  .xs:p-52-1 {
    padding: 52.1rem;
  }
  .xs:p-52-2 {
    padding: 52.2rem;
  }
  .xs:p-52-3 {
    padding: 52.3rem;
  }
  .xs:p-52-4 {
    padding: 52.4rem;
  }
  .xs:p-52-5 {
    padding: 52.5rem;
  }
  .xs:p-52-6 {
    padding: 52.6rem;
  }
  .xs:p-52-7 {
    padding: 52.7rem;
  }
  .xs:p-52-8 {
    padding: 52.8rem;
  }
  .xs:p-52-9 {
    padding: 52.9rem;
  }
  .xs:p-53-0 {
    padding: 53rem;
  }
  .xs:p-53-1 {
    padding: 53.1rem;
  }
  .xs:p-53-2 {
    padding: 53.2rem;
  }
  .xs:p-53-3 {
    padding: 53.3rem;
  }
  .xs:p-53-4 {
    padding: 53.4rem;
  }
  .xs:p-53-5 {
    padding: 53.5rem;
  }
  .xs:p-53-6 {
    padding: 53.6rem;
  }
  .xs:p-53-7 {
    padding: 53.7rem;
  }
  .xs:p-53-8 {
    padding: 53.8rem;
  }
  .xs:p-53-9 {
    padding: 53.9rem;
  }
  .xs:p-54-0 {
    padding: 54rem;
  }
  .xs:p-54-1 {
    padding: 54.1rem;
  }
  .xs:p-54-2 {
    padding: 54.2rem;
  }
  .xs:p-54-3 {
    padding: 54.3rem;
  }
  .xs:p-54-4 {
    padding: 54.4rem;
  }
  .xs:p-54-5 {
    padding: 54.5rem;
  }
  .xs:p-54-6 {
    padding: 54.6rem;
  }
  .xs:p-54-7 {
    padding: 54.7rem;
  }
  .xs:p-54-8 {
    padding: 54.8rem;
  }
  .xs:p-54-9 {
    padding: 54.9rem;
  }
  .xs:p-55-0 {
    padding: 55rem;
  }
  .xs:p-55-1 {
    padding: 55.1rem;
  }
  .xs:p-55-2 {
    padding: 55.2rem;
  }
  .xs:p-55-3 {
    padding: 55.3rem;
  }
  .xs:p-55-4 {
    padding: 55.4rem;
  }
  .xs:p-55-5 {
    padding: 55.5rem;
  }
  .xs:p-55-6 {
    padding: 55.6rem;
  }
  .xs:p-55-7 {
    padding: 55.7rem;
  }
  .xs:p-55-8 {
    padding: 55.8rem;
  }
  .xs:p-55-9 {
    padding: 55.9rem;
  }
  .xs:p-56-0 {
    padding: 56rem;
  }
  .xs:p-56-1 {
    padding: 56.1rem;
  }
  .xs:p-56-2 {
    padding: 56.2rem;
  }
  .xs:p-56-3 {
    padding: 56.3rem;
  }
  .xs:p-56-4 {
    padding: 56.4rem;
  }
  .xs:p-56-5 {
    padding: 56.5rem;
  }
  .xs:p-56-6 {
    padding: 56.6rem;
  }
  .xs:p-56-7 {
    padding: 56.7rem;
  }
  .xs:p-56-8 {
    padding: 56.8rem;
  }
  .xs:p-56-9 {
    padding: 56.9rem;
  }
  .xs:p-57-0 {
    padding: 57rem;
  }
  .xs:p-57-1 {
    padding: 57.1rem;
  }
  .xs:p-57-2 {
    padding: 57.2rem;
  }
  .xs:p-57-3 {
    padding: 57.3rem;
  }
  .xs:p-57-4 {
    padding: 57.4rem;
  }
  .xs:p-57-5 {
    padding: 57.5rem;
  }
  .xs:p-57-6 {
    padding: 57.6rem;
  }
  .xs:p-57-7 {
    padding: 57.7rem;
  }
  .xs:p-57-8 {
    padding: 57.8rem;
  }
  .xs:p-57-9 {
    padding: 57.9rem;
  }
  .xs:p-58-0 {
    padding: 58rem;
  }
  .xs:p-58-1 {
    padding: 58.1rem;
  }
  .xs:p-58-2 {
    padding: 58.2rem;
  }
  .xs:p-58-3 {
    padding: 58.3rem;
  }
  .xs:p-58-4 {
    padding: 58.4rem;
  }
  .xs:p-58-5 {
    padding: 58.5rem;
  }
  .xs:p-58-6 {
    padding: 58.6rem;
  }
  .xs:p-58-7 {
    padding: 58.7rem;
  }
  .xs:p-58-8 {
    padding: 58.8rem;
  }
  .xs:p-58-9 {
    padding: 58.9rem;
  }
  .xs:p-59-0 {
    padding: 59rem;
  }
  .xs:p-59-1 {
    padding: 59.1rem;
  }
  .xs:p-59-2 {
    padding: 59.2rem;
  }
  .xs:p-59-3 {
    padding: 59.3rem;
  }
  .xs:p-59-4 {
    padding: 59.4rem;
  }
  .xs:p-59-5 {
    padding: 59.5rem;
  }
  .xs:p-59-6 {
    padding: 59.6rem;
  }
  .xs:p-59-7 {
    padding: 59.7rem;
  }
  .xs:p-59-8 {
    padding: 59.8rem;
  }
  .xs:p-59-9 {
    padding: 59.9rem;
  }
  .xs:p-60-0 {
    padding: 60rem;
  }
  .xs:p-60-1 {
    padding: 60.1rem;
  }
  .xs:p-60-2 {
    padding: 60.2rem;
  }
  .xs:p-60-3 {
    padding: 60.3rem;
  }
  .xs:p-60-4 {
    padding: 60.4rem;
  }
  .xs:p-60-5 {
    padding: 60.5rem;
  }
  .xs:p-60-6 {
    padding: 60.6rem;
  }
  .xs:p-60-7 {
    padding: 60.7rem;
  }
  .xs:p-60-8 {
    padding: 60.8rem;
  }
  .xs:p-60-9 {
    padding: 60.9rem;
  }
  .xs:p-61-0 {
    padding: 61rem;
  }
  .xs:p-61-1 {
    padding: 61.1rem;
  }
  .xs:p-61-2 {
    padding: 61.2rem;
  }
  .xs:p-61-3 {
    padding: 61.3rem;
  }
  .xs:p-61-4 {
    padding: 61.4rem;
  }
  .xs:p-61-5 {
    padding: 61.5rem;
  }
  .xs:p-61-6 {
    padding: 61.6rem;
  }
  .xs:p-61-7 {
    padding: 61.7rem;
  }
  .xs:p-61-8 {
    padding: 61.8rem;
  }
  .xs:p-61-9 {
    padding: 61.9rem;
  }
  .xs:p-62-0 {
    padding: 62rem;
  }
  .xs:p-62-1 {
    padding: 62.1rem;
  }
  .xs:p-62-2 {
    padding: 62.2rem;
  }
  .xs:p-62-3 {
    padding: 62.3rem;
  }
  .xs:p-62-4 {
    padding: 62.4rem;
  }
  .xs:p-62-5 {
    padding: 62.5rem;
  }
  .xs:p-62-6 {
    padding: 62.6rem;
  }
  .xs:p-62-7 {
    padding: 62.7rem;
  }
  .xs:p-62-8 {
    padding: 62.8rem;
  }
  .xs:p-62-9 {
    padding: 62.9rem;
  }
  .xs:p-63-0 {
    padding: 63rem;
  }
  .xs:p-63-1 {
    padding: 63.1rem;
  }
  .xs:p-63-2 {
    padding: 63.2rem;
  }
  .xs:p-63-3 {
    padding: 63.3rem;
  }
  .xs:p-63-4 {
    padding: 63.4rem;
  }
  .xs:p-63-5 {
    padding: 63.5rem;
  }
  .xs:p-63-6 {
    padding: 63.6rem;
  }
  .xs:p-63-7 {
    padding: 63.7rem;
  }
  .xs:p-63-8 {
    padding: 63.8rem;
  }
  .xs:p-63-9 {
    padding: 63.9rem;
  }
  .xs:p-64-0 {
    padding: 64rem;
  }
  .xs:p-64-1 {
    padding: 64.1rem;
  }
  .xs:p-64-2 {
    padding: 64.2rem;
  }
  .xs:p-64-3 {
    padding: 64.3rem;
  }
  .xs:p-64-4 {
    padding: 64.4rem;
  }
  .xs:p-64-5 {
    padding: 64.5rem;
  }
  .xs:p-64-6 {
    padding: 64.6rem;
  }
  .xs:p-64-7 {
    padding: 64.7rem;
  }
  .xs:p-64-8 {
    padding: 64.8rem;
  }
  .xs:p-64-9 {
    padding: 64.9rem;
  }
  .xs:p-65-0 {
    padding: 65rem;
  }
  .xs:p-65-1 {
    padding: 65.1rem;
  }
  .xs:p-65-2 {
    padding: 65.2rem;
  }
  .xs:p-65-3 {
    padding: 65.3rem;
  }
  .xs:p-65-4 {
    padding: 65.4rem;
  }
  .xs:p-65-5 {
    padding: 65.5rem;
  }
  .xs:p-65-6 {
    padding: 65.6rem;
  }
  .xs:p-65-7 {
    padding: 65.7rem;
  }
  .xs:p-65-8 {
    padding: 65.8rem;
  }
  .xs:p-65-9 {
    padding: 65.9rem;
  }
  .xs:p-66-0 {
    padding: 66rem;
  }
  .xs:p-66-1 {
    padding: 66.1rem;
  }
  .xs:p-66-2 {
    padding: 66.2rem;
  }
  .xs:p-66-3 {
    padding: 66.3rem;
  }
  .xs:p-66-4 {
    padding: 66.4rem;
  }
  .xs:p-66-5 {
    padding: 66.5rem;
  }
  .xs:p-66-6 {
    padding: 66.6rem;
  }
  .xs:p-66-7 {
    padding: 66.7rem;
  }
  .xs:p-66-8 {
    padding: 66.8rem;
  }
  .xs:p-66-9 {
    padding: 66.9rem;
  }
  .xs:p-67-0 {
    padding: 67rem;
  }
  .xs:p-67-1 {
    padding: 67.1rem;
  }
  .xs:p-67-2 {
    padding: 67.2rem;
  }
  .xs:p-67-3 {
    padding: 67.3rem;
  }
  .xs:p-67-4 {
    padding: 67.4rem;
  }
  .xs:p-67-5 {
    padding: 67.5rem;
  }
  .xs:p-67-6 {
    padding: 67.6rem;
  }
  .xs:p-67-7 {
    padding: 67.7rem;
  }
  .xs:p-67-8 {
    padding: 67.8rem;
  }
  .xs:p-67-9 {
    padding: 67.9rem;
  }
  .xs:p-68-0 {
    padding: 68rem;
  }
  .xs:p-68-1 {
    padding: 68.1rem;
  }
  .xs:p-68-2 {
    padding: 68.2rem;
  }
  .xs:p-68-3 {
    padding: 68.3rem;
  }
  .xs:p-68-4 {
    padding: 68.4rem;
  }
  .xs:p-68-5 {
    padding: 68.5rem;
  }
  .xs:p-68-6 {
    padding: 68.6rem;
  }
  .xs:p-68-7 {
    padding: 68.7rem;
  }
  .xs:p-68-8 {
    padding: 68.8rem;
  }
  .xs:p-68-9 {
    padding: 68.9rem;
  }
  .xs:p-69-0 {
    padding: 69rem;
  }
  .xs:p-69-1 {
    padding: 69.1rem;
  }
  .xs:p-69-2 {
    padding: 69.2rem;
  }
  .xs:p-69-3 {
    padding: 69.3rem;
  }
  .xs:p-69-4 {
    padding: 69.4rem;
  }
  .xs:p-69-5 {
    padding: 69.5rem;
  }
  .xs:p-69-6 {
    padding: 69.6rem;
  }
  .xs:p-69-7 {
    padding: 69.7rem;
  }
  .xs:p-69-8 {
    padding: 69.8rem;
  }
  .xs:p-69-9 {
    padding: 69.9rem;
  }
  .xs:p-70-0 {
    padding: 70rem;
  }
  .xs:p-70-1 {
    padding: 70.1rem;
  }
  .xs:p-70-2 {
    padding: 70.2rem;
  }
  .xs:p-70-3 {
    padding: 70.3rem;
  }
  .xs:p-70-4 {
    padding: 70.4rem;
  }
  .xs:p-70-5 {
    padding: 70.5rem;
  }
  .xs:p-70-6 {
    padding: 70.6rem;
  }
  .xs:p-70-7 {
    padding: 70.7rem;
  }
  .xs:p-70-8 {
    padding: 70.8rem;
  }
  .xs:p-70-9 {
    padding: 70.9rem;
  }
  .xs:p-71-0 {
    padding: 71rem;
  }
  .xs:p-71-1 {
    padding: 71.1rem;
  }
  .xs:p-71-2 {
    padding: 71.2rem;
  }
  .xs:p-71-3 {
    padding: 71.3rem;
  }
  .xs:p-71-4 {
    padding: 71.4rem;
  }
  .xs:p-71-5 {
    padding: 71.5rem;
  }
  .xs:p-71-6 {
    padding: 71.6rem;
  }
  .xs:p-71-7 {
    padding: 71.7rem;
  }
  .xs:p-71-8 {
    padding: 71.8rem;
  }
  .xs:p-71-9 {
    padding: 71.9rem;
  }
  .xs:p-72-0 {
    padding: 72rem;
  }
  .xs:p-72-1 {
    padding: 72.1rem;
  }
  .xs:p-72-2 {
    padding: 72.2rem;
  }
  .xs:p-72-3 {
    padding: 72.3rem;
  }
  .xs:p-72-4 {
    padding: 72.4rem;
  }
  .xs:p-72-5 {
    padding: 72.5rem;
  }
  .xs:p-72-6 {
    padding: 72.6rem;
  }
  .xs:p-72-7 {
    padding: 72.7rem;
  }
  .xs:p-72-8 {
    padding: 72.8rem;
  }
  .xs:p-72-9 {
    padding: 72.9rem;
  }
  .xs:p-0 {
    padding: 0;
  }
  .xs:p-1 {
    padding: 1rem;
  }
  .xs:p-2 {
    padding: 2rem;
  }
  .xs:p-3 {
    padding: 4rem;
  }
  .xs:p-4 {
    padding: 6rem;
  }
  .xs:p-5 {
    padding: 8rem;
  }
  .xs:pl-0 {
    padding-left: 0;
  }
  .xs:pl-1 {
    padding-left: 1rem;
  }
  .xs:pl-2 {
    padding-left: 2rem;
  }
  .xs:pl-3 {
    padding-left: 4rem;
  }
  .xs:pl-4 {
    padding-left: 6rem;
  }
  .xs:pl-5 {
    padding-left: 8rem;
  }
  .xs:pr-0 {
    padding-right: 0;
  }
  .xs:pr-1 {
    padding-right: 1rem;
  }
  .xs:pr-2 {
    padding-right: 2rem;
  }
  .xs:pr-3 {
    padding-right: 4rem;
  }
  .xs:pr-4 {
    padding-right: 6rem;
  }
  .xs:pr-5 {
    padding-right: 8rem;
  }
  .xs:pt-0 {
    padding-top: 0;
  }
  .xs:pt-1 {
    padding-top: 1rem;
  }
  .xs:pt-2 {
    padding-top: 2rem;
  }
  .xs:pt-3 {
    padding-top: 4rem;
  }
  .xs:pt-4 {
    padding-top: 6rem;
  }
  .xs:pt-5 {
    padding-top: 8rem;
  }
  .xs:pb-0 {
    padding-bottom: 0;
  }
  .xs:pb-1 {
    padding-bottom: 1rem;
  }
  .xs:pb-2 {
    padding-bottom: 2rem;
  }
  .xs:pb-3 {
    padding-bottom: 4rem;
  }
  .xs:pb-4 {
    padding-bottom: 6rem;
  }
  .xs:pb-5 {
    padding-bottom: 8rem;
  }
  .xs:m-0 {
    margin: 0;
  }
  .xs:m-1 {
    margin: 1rem;
  }
  .xs:m-2 {
    margin: 2rem;
  }
  .xs:m-3 {
    margin: 4rem;
  }
  .xs:m-4 {
    margin: 6rem;
  }
  .xs:m-5 {
    margin: 8rem;
  }
  .xs:ml-0 {
    margin-left: 0;
  }
  .xs:ml-1 {
    margin-left: 1rem;
  }
  .xs:ml-2 {
    margin-left: 2rem;
  }
  .xs:ml-3 {
    margin-left: 4rem;
  }
  .xs:ml-4 {
    margin-left: 6rem;
  }
  .xs:ml-5 {
    margin-left: 8rem;
  }
  .xs:mr-0 {
    margin-right: 0;
  }
  .xs:mr-1 {
    margin-right: 1rem;
  }
  .xs:mr-2 {
    margin-right: 2rem;
  }
  .xs:mr-3 {
    margin-right: 4rem;
  }
  .xs:mr-4 {
    margin-right: 6rem;
  }
  .xs:mr-5 {
    margin-right: 8rem;
  }
  .xs:mt-0 {
    margin-top: 0;
  }
  .xs:mt-1 {
    margin-top: 1rem;
  }
  .xs:mt-2 {
    margin-top: 2rem;
  }
  .xs:mt-3 {
    margin-top: 4rem;
  }
  .xs:mt-4 {
    margin-top: 6rem;
  }
  .xs:mt-5 {
    margin-top: 8rem;
  }
  .xs:mb-0 {
    margin-bottom: 0;
  }
  .xs:mb-1 {
    margin-bottom: 1rem;
  }
  .xs:mb-2 {
    margin-bottom: 2rem;
  }
  .xs:mb-3 {
    margin-bottom: 4rem;
  }
  .xs:mb-4 {
    margin-bottom: 6rem;
  }
  .xs:mb-5 {
    margin-bottom: 8rem;
  }
  .xs:o-10 {
    opacity: 0.1;
  }
  .xs:o-20 {
    opacity: 0.2;
  }
  .xs:o-30 {
    opacity: 0.3;
  }
  .xs:o-40 {
    opacity: 0.4;
  }
  .xs:o-50 {
    opacity: 0.5;
  }
  .xs:o-60 {
    opacity: 0.6;
  }
  .xs:o-70 {
    opacity: 0.7;
  }
  .xs:o-80 {
    opacity: 0.8;
  }
  .xs:o-90 {
    opacity: 0.9;
  }
  .xs:o-100 {
    opacity: 1;
  }
  .xs:br {
    border-radius: 0.8rem;
  }
  .xs:br-none {
    border-radius: 0;
  }
  .xs:br-xs {
    border-radius: 0.2rem;
  }
  .xs:br-sm {
    border-radius: 0.4rem;
  }
  .xs:br-lg {
    border-radius: 1.6rem;
  }
  .xs:br-full {
    border-radius: 50%;
  }
  .xs:display-n {
    display: none;
  }
  .xs:display-b {
    display: block;
  }
  .xs:display-f {
    display: flex;
  }
  .xs:display-i {
    display: inline;
  }
  .xs:display-i-b {
    display: inline-block;
  }
  .xs:align-f-start {
    align-items: flex-start;
  }
  .xs:align-f-end {
    align-items: flex-end;
  }
  .xs:align-center {
    align-items: center;
  }
  .xs:flex-row {
    flex-direction: row;
  }
  .xs:flex-column {
    flex-direction: column;
  }
  .xs:justify-between {
    justify-content: space-between;
  }
  .xs:justify-f-end {
    justify-content: flex-end;
  }
  .xs:justify-center {
    justify-content: center;
  }
  .xs:justify-around {
    justify-content: space-around;
  }
  .xs:justify-evenly {
    justify-content: space-evenly;
  }
  .xs:text-center {
    text-align: center;
  }
  .xs:text-start {
    text-align: start;
  }
  .xs:text-end {
    text-align: end;
  }
  .xs:text-left {
    text-align: left;
  }
  .xs:text-right {
    text-align: right;
  }
  .xs:text-justify {
    text-align: justify;
  }
  .xs:text-d2xl {
    font-size: 7.2rem;
  }
  .xs:text-dxl {
    font-size: 6rem;
  }
  .xs:text-dlg {
    font-size: 4.8rem;
  }
  .xs:text-dmd {
    font-size: 3.6rem;
  }
  .xs:text-dsm {
    font-size: 3.2rem;
  }
  .xs:text-dxs {
    font-size: 2.4rem;
  }
  .xs:text-xxl {
    font-size: 2.4rem;
  }
  .xs:text-xl {
    font-size: 2rem;
  }
  .xs:text-lg {
    font-size: 1.8rem;
  }
  .xs:text-md {
    font-size: 1.6rem;
  }
  .xs:text-sm {
    font-size: 1.4rem;
  }
  .xs:text-xs {
    font-size: 1.2rem;
  }
  .xs:text-macro {
    font-size: 1rem;
  }
  .xs:gap-0 {
    gap: 0rem;
  }
  .xs:gap-1 {
    gap: 1rem;
  }
  .xs:gap-2 {
    gap: 2rem;
  }
  .xs:gap-3 {
    gap: 3rem;
  }
  .xs:gap-4 {
    gap: 4rem;
  }
  .xs:gap-5 {
    gap: 5rem;
  }
  .xs:gap-6 {
    gap: 6rem;
  }
  .xs:gap-7 {
    gap: 7rem;
  }
  .xs:gap-8 {
    gap: 8rem;
  }
  .xs:gap-9 {
    gap: 9rem;
  }
  .xs:gap-10 {
    gap: 10rem;
  }
  .xs:gap-11 {
    gap: 11rem;
  }
  .xs:gap-12 {
    gap: 12rem;
  }
  .xs:gap-13 {
    gap: 13rem;
  }
  .xs:gap-14 {
    gap: 14rem;
  }
  .xs:gap-15 {
    gap: 15rem;
  }
  .xs:gap-16 {
    gap: 16rem;
  }
  .xs:gap-17 {
    gap: 17rem;
  }
  .xs:gap-18 {
    gap: 18rem;
  }
  .xs:gap-19 {
    gap: 19rem;
  }
  .xs:gap-20 {
    gap: 20rem;
  }
  .xs:gap-21 {
    gap: 21rem;
  }
  .xs:gap-22 {
    gap: 22rem;
  }
  .xs:gap-23 {
    gap: 23rem;
  }
  .xs:gap-24 {
    gap: 24rem;
  }
  .xs:gap-25 {
    gap: 25rem;
  }
  .xs:gap-26 {
    gap: 26rem;
  }
  .xs:gap-27 {
    gap: 27rem;
  }
  .xs:gap-28 {
    gap: 28rem;
  }
  .xs:gap-29 {
    gap: 29rem;
  }
  .xs:gap-30 {
    gap: 30rem;
  }
  .xs:gap-31 {
    gap: 31rem;
  }
  .xs:gap-32 {
    gap: 32rem;
  }
  .xs:gap-33 {
    gap: 33rem;
  }
  .xs:gap-34 {
    gap: 34rem;
  }
  .xs:gap-35 {
    gap: 35rem;
  }
  .xs:gap-36 {
    gap: 36rem;
  }
  .xs:gap-37 {
    gap: 37rem;
  }
  .xs:gap-38 {
    gap: 38rem;
  }
  .xs:gap-39 {
    gap: 39rem;
  }
  .xs:gap-40 {
    gap: 40rem;
  }
  .xs:gap-41 {
    gap: 41rem;
  }
  .xs:gap-42 {
    gap: 42rem;
  }
  .xs:gap-43 {
    gap: 43rem;
  }
  .xs:gap-44 {
    gap: 44rem;
  }
  .xs:gap-45 {
    gap: 45rem;
  }
  .xs:gap-46 {
    gap: 46rem;
  }
  .xs:gap-47 {
    gap: 47rem;
  }
  .xs:gap-48 {
    gap: 48rem;
  }
  .xs:gap-49 {
    gap: 49rem;
  }
  .grid-center {
    display: grid;
    place-items: center;
  }
  .xs:w-0 {
    width: 0rem;
  }
  .xs:w-1 {
    width: 1rem;
  }
  .xs:w-2 {
    width: 2rem;
  }
  .xs:w-3 {
    width: 3rem;
  }
  .xs:w-4 {
    width: 4rem;
  }
  .xs:w-5 {
    width: 5rem;
  }
  .xs:w-6 {
    width: 6rem;
  }
  .xs:w-7 {
    width: 7rem;
  }
  .xs:w-8 {
    width: 8rem;
  }
  .xs:w-9 {
    width: 9rem;
  }
  .xs:w-10 {
    width: 10rem;
  }
  .xs:w-11 {
    width: 11rem;
  }
  .xs:w-12 {
    width: 12rem;
  }
  .xs:w-13 {
    width: 13rem;
  }
  .xs:w-14 {
    width: 14rem;
  }
  .xs:w-15 {
    width: 15rem;
  }
  .xs:w-16 {
    width: 16rem;
  }
  .xs:w-17 {
    width: 17rem;
  }
  .xs:w-18 {
    width: 18rem;
  }
  .xs:w-19 {
    width: 19rem;
  }
  .xs:w-20 {
    width: 20rem;
  }
  .xs:w-21 {
    width: 21rem;
  }
  .xs:w-22 {
    width: 22rem;
  }
  .xs:w-23 {
    width: 23rem;
  }
  .xs:w-24 {
    width: 24rem;
  }
  .xs:w-25 {
    width: 25rem;
  }
  .xs:w-26 {
    width: 26rem;
  }
  .xs:w-27 {
    width: 27rem;
  }
  .xs:w-28 {
    width: 28rem;
  }
  .xs:w-29 {
    width: 29rem;
  }
  .xs:w-30 {
    width: 30rem;
  }
  .xs:w-31 {
    width: 31rem;
  }
  .xs:w-32 {
    width: 32rem;
  }
  .xs:w-33 {
    width: 33rem;
  }
  .xs:w-34 {
    width: 34rem;
  }
  .xs:w-35 {
    width: 35rem;
  }
  .xs:w-36 {
    width: 36rem;
  }
  .xs:w-37 {
    width: 37rem;
  }
  .xs:w-38 {
    width: 38rem;
  }
  .xs:w-39 {
    width: 39rem;
  }
  .xs:w-40 {
    width: 40rem;
  }
  .xs:w-41 {
    width: 41rem;
  }
  .xs:w-42 {
    width: 42rem;
  }
  .xs:w-43 {
    width: 43rem;
  }
  .xs:w-44 {
    width: 44rem;
  }
  .xs:w-45 {
    width: 45rem;
  }
  .xs:w-46 {
    width: 46rem;
  }
  .xs:w-47 {
    width: 47rem;
  }
  .xs:w-48 {
    width: 48rem;
  }
  .xs:w-49 {
    width: 49rem;
  }
  .xs:w-50 {
    width: 50rem;
  }
  .xs:w-51 {
    width: 51rem;
  }
  .xs:w-52 {
    width: 52rem;
  }
  .xs:w-53 {
    width: 53rem;
  }
  .xs:w-54 {
    width: 54rem;
  }
  .xs:w-55 {
    width: 55rem;
  }
  .xs:w-56 {
    width: 56rem;
  }
  .xs:w-57 {
    width: 57rem;
  }
  .xs:w-58 {
    width: 58rem;
  }
  .xs:w-59 {
    width: 59rem;
  }
  .xs:w-60 {
    width: 60rem;
  }
  .xs:w-61 {
    width: 61rem;
  }
  .xs:w-62 {
    width: 62rem;
  }
  .xs:w-63 {
    width: 63rem;
  }
  .xs:w-64 {
    width: 64rem;
  }
  .xs:w-65 {
    width: 65rem;
  }
  .xs:w-66 {
    width: 66rem;
  }
  .xs:w-67 {
    width: 67rem;
  }
  .xs:w-68 {
    width: 68rem;
  }
  .xs:w-69 {
    width: 69rem;
  }
  .xs:w-70 {
    width: 70rem;
  }
  .xs:w-71 {
    width: 71rem;
  }
  .xs:w-72 {
    width: 72rem;
  }
  .xs:w-73 {
    width: 73rem;
  }
  .xs:w-74 {
    width: 74rem;
  }
  .xs:w-75 {
    width: 75rem;
  }
  .xs:w-76 {
    width: 76rem;
  }
  .xs:w-77 {
    width: 77rem;
  }
  .xs:w-78 {
    width: 78rem;
  }
  .xs:w-79 {
    width: 79rem;
  }
  .xs:w-80 {
    width: 80rem;
  }
  .xs:w-81 {
    width: 81rem;
  }
  .xs:w-82 {
    width: 82rem;
  }
  .xs:w-83 {
    width: 83rem;
  }
  .xs:w-84 {
    width: 84rem;
  }
  .xs:w-85 {
    width: 85rem;
  }
  .xs:w-86 {
    width: 86rem;
  }
  .xs:w-87 {
    width: 87rem;
  }
  .xs:w-88 {
    width: 88rem;
  }
  .xs:w-89 {
    width: 89rem;
  }
  .xs:w-90 {
    width: 90rem;
  }
  .xs:w-91 {
    width: 91rem;
  }
  .xs:w-92 {
    width: 92rem;
  }
  .xs:w-93 {
    width: 93rem;
  }
  .xs:w-94 {
    width: 94rem;
  }
  .xs:w-95 {
    width: 95rem;
  }
  .xs:w-96 {
    width: 96rem;
  }
  .xs:w-97 {
    width: 97rem;
  }
  .xs:w-98 {
    width: 98rem;
  }
  .xs:w-99 {
    width: 99rem;
  }
  .xs:w-100 {
    width: 100rem;
  }
  .xs:w-101 {
    width: 101rem;
  }
  .xs:w-102 {
    width: 102rem;
  }
  .xs:w-103 {
    width: 103rem;
  }
  .xs:w-104 {
    width: 104rem;
  }
  .xs:w-105 {
    width: 105rem;
  }
  .xs:w-106 {
    width: 106rem;
  }
  .xs:w-107 {
    width: 107rem;
  }
  .xs:w-108 {
    width: 108rem;
  }
  .xs:w-109 {
    width: 109rem;
  }
  .xs:w-110 {
    width: 110rem;
  }
  .xs:w-111 {
    width: 111rem;
  }
  .xs:w-112 {
    width: 112rem;
  }
  .xs:w-113 {
    width: 113rem;
  }
  .xs:w-114 {
    width: 114rem;
  }
  .xs:w-115 {
    width: 115rem;
  }
  .xs:w-116 {
    width: 116rem;
  }
  .xs:w-117 {
    width: 117rem;
  }
  .xs:w-118 {
    width: 118rem;
  }
  .xs:w-119 {
    width: 119rem;
  }
  .xs:w-120 {
    width: 120rem;
  }
  .xs:w-121 {
    width: 121rem;
  }
  .xs:w-122 {
    width: 122rem;
  }
  .xs:w-123 {
    width: 123rem;
  }
  .xs:w-124 {
    width: 124rem;
  }
  .xs:w-125 {
    width: 125rem;
  }
  .xs:w-126 {
    width: 126rem;
  }
  .xs:w-127 {
    width: 127rem;
  }
  .xs:w-128 {
    width: 128rem;
  }
  .xs:w-129 {
    width: 129rem;
  }
  .xs:w-130 {
    width: 130rem;
  }
  .xs:w-131 {
    width: 131rem;
  }
  .xs:w-132 {
    width: 132rem;
  }
  .xs:w-133 {
    width: 133rem;
  }
  .xs:w-134 {
    width: 134rem;
  }
  .xs:w-135 {
    width: 135rem;
  }
  .xs:w-136 {
    width: 136rem;
  }
  .xs:w-137 {
    width: 137rem;
  }
  .xs:w-138 {
    width: 138rem;
  }
  .xs:w-139 {
    width: 139rem;
  }
  .xs:w-140 {
    width: 140rem;
  }
  .xs:w-141 {
    width: 141rem;
  }
  .xs:w-142 {
    width: 142rem;
  }
  .xs:w-143 {
    width: 143rem;
  }
  .xs:w-144 {
    width: 144rem;
  }
  .xs:w-145 {
    width: 145rem;
  }
  .xs:w-146 {
    width: 146rem;
  }
  .xs:w-147 {
    width: 147rem;
  }
  .xs:w-148 {
    width: 148rem;
  }
  .xs:w-149 {
    width: 149rem;
  }
  .xs:w-150 {
    width: 150rem;
  }
  .xs:w-151 {
    width: 151rem;
  }
  .xs:w-152 {
    width: 152rem;
  }
  .xs:w-153 {
    width: 153rem;
  }
  .xs:w-154 {
    width: 154rem;
  }
  .xs:w-155 {
    width: 155rem;
  }
  .xs:w-156 {
    width: 156rem;
  }
  .xs:w-157 {
    width: 157rem;
  }
  .xs:w-158 {
    width: 158rem;
  }
  .xs:w-159 {
    width: 159rem;
  }
  .xs:w-160 {
    width: 160rem;
  }
  .xs:w-161 {
    width: 161rem;
  }
  .xs:w-162 {
    width: 162rem;
  }
  .xs:w-163 {
    width: 163rem;
  }
  .xs:w-164 {
    width: 164rem;
  }
  .xs:w-165 {
    width: 165rem;
  }
  .xs:w-166 {
    width: 166rem;
  }
  .xs:w-167 {
    width: 167rem;
  }
  .xs:w-168 {
    width: 168rem;
  }
  .xs:w-169 {
    width: 169rem;
  }
  .xs:w-170 {
    width: 170rem;
  }
  .xs:w-171 {
    width: 171rem;
  }
  .xs:w-172 {
    width: 172rem;
  }
  .xs:w-173 {
    width: 173rem;
  }
  .xs:w-174 {
    width: 174rem;
  }
  .xs:w-175 {
    width: 175rem;
  }
  .xs:w-176 {
    width: 176rem;
  }
  .xs:w-177 {
    width: 177rem;
  }
  .xs:w-178 {
    width: 178rem;
  }
  .xs:w-179 {
    width: 179rem;
  }
  .xs:w-180 {
    width: 180rem;
  }
  .xs:w-181 {
    width: 181rem;
  }
  .xs:w-182 {
    width: 182rem;
  }
  .xs:w-183 {
    width: 183rem;
  }
  .xs:w-184 {
    width: 184rem;
  }
  .xs:w-185 {
    width: 185rem;
  }
  .xs:w-186 {
    width: 186rem;
  }
  .xs:w-187 {
    width: 187rem;
  }
  .xs:w-188 {
    width: 188rem;
  }
  .xs:w-189 {
    width: 189rem;
  }
  .xs:w-190 {
    width: 190rem;
  }
  .xs:w-191 {
    width: 191rem;
  }
  .xs:w-192 {
    width: 192rem;
  }
  .xs:w-193 {
    width: 193rem;
  }
  .xs:w-194 {
    width: 194rem;
  }
  .xs:w-195 {
    width: 195rem;
  }
  .xs:w-196 {
    width: 196rem;
  }
  .xs:w-197 {
    width: 197rem;
  }
  .xs:w-198 {
    width: 198rem;
  }
  .xs:w-199 {
    width: 199rem;
  }
  .xs:w-200 {
    width: 200rem;
  }
  .xs:w-201 {
    width: 201rem;
  }
  .xs:w-202 {
    width: 202rem;
  }
  .xs:w-203 {
    width: 203rem;
  }
  .xs:w-204 {
    width: 204rem;
  }
  .xs:w-205 {
    width: 205rem;
  }
  .xs:w-206 {
    width: 206rem;
  }
  .xs:w-207 {
    width: 207rem;
  }
  .xs:w-208 {
    width: 208rem;
  }
  .xs:w-209 {
    width: 209rem;
  }
  .xs:w-210 {
    width: 210rem;
  }
  .xs:w-211 {
    width: 211rem;
  }
  .xs:w-212 {
    width: 212rem;
  }
  .xs:w-213 {
    width: 213rem;
  }
  .xs:w-214 {
    width: 214rem;
  }
  .xs:w-215 {
    width: 215rem;
  }
  .xs:w-216 {
    width: 216rem;
  }
  .xs:w-217 {
    width: 217rem;
  }
  .xs:w-218 {
    width: 218rem;
  }
  .xs:w-219 {
    width: 219rem;
  }
  .xs:w-220 {
    width: 220rem;
  }
  .xs:w-221 {
    width: 221rem;
  }
  .xs:w-222 {
    width: 222rem;
  }
  .xs:w-223 {
    width: 223rem;
  }
  .xs:w-224 {
    width: 224rem;
  }
  .xs:w-225 {
    width: 225rem;
  }
  .xs:w-226 {
    width: 226rem;
  }
  .xs:w-227 {
    width: 227rem;
  }
  .xs:w-228 {
    width: 228rem;
  }
  .xs:w-229 {
    width: 229rem;
  }
  .xs:w-230 {
    width: 230rem;
  }
  .xs:w-231 {
    width: 231rem;
  }
  .xs:w-232 {
    width: 232rem;
  }
  .xs:w-233 {
    width: 233rem;
  }
  .xs:w-234 {
    width: 234rem;
  }
  .xs:w-235 {
    width: 235rem;
  }
  .xs:w-236 {
    width: 236rem;
  }
  .xs:w-237 {
    width: 237rem;
  }
  .xs:w-238 {
    width: 238rem;
  }
  .xs:w-239 {
    width: 239rem;
  }
  .xs:w-240 {
    width: 240rem;
  }
  .xs:w-241 {
    width: 241rem;
  }
  .xs:w-242 {
    width: 242rem;
  }
  .xs:w-243 {
    width: 243rem;
  }
  .xs:w-244 {
    width: 244rem;
  }
  .xs:w-245 {
    width: 245rem;
  }
  .xs:w-246 {
    width: 246rem;
  }
  .xs:w-247 {
    width: 247rem;
  }
  .xs:w-248 {
    width: 248rem;
  }
  .xs:w-249 {
    width: 249rem;
  }
  .xs:w-250 {
    width: 250rem;
  }
  .xs:w-251 {
    width: 251rem;
  }
  .xs:w-252 {
    width: 252rem;
  }
  .xs:w-253 {
    width: 253rem;
  }
  .xs:w-254 {
    width: 254rem;
  }
  .xs:w-255 {
    width: 255rem;
  }
  .xs:w-256 {
    width: 256rem;
  }
  .xs:w-257 {
    width: 257rem;
  }
  .xs:w-258 {
    width: 258rem;
  }
  .xs:w-259 {
    width: 259rem;
  }
  .xs:w-260 {
    width: 260rem;
  }
  .xs:w-261 {
    width: 261rem;
  }
  .xs:w-262 {
    width: 262rem;
  }
  .xs:w-263 {
    width: 263rem;
  }
  .xs:w-264 {
    width: 264rem;
  }
  .xs:w-265 {
    width: 265rem;
  }
  .xs:w-266 {
    width: 266rem;
  }
  .xs:w-267 {
    width: 267rem;
  }
  .xs:w-268 {
    width: 268rem;
  }
  .xs:w-269 {
    width: 269rem;
  }
  .xs:w-270 {
    width: 270rem;
  }
  .xs:w-271 {
    width: 271rem;
  }
  .xs:w-272 {
    width: 272rem;
  }
  .xs:w-273 {
    width: 273rem;
  }
  .xs:w-274 {
    width: 274rem;
  }
  .xs:w-275 {
    width: 275rem;
  }
  .xs:w-276 {
    width: 276rem;
  }
  .xs:w-277 {
    width: 277rem;
  }
  .xs:w-278 {
    width: 278rem;
  }
  .xs:w-279 {
    width: 279rem;
  }
  .xs:w-280 {
    width: 280rem;
  }
  .xs:w-281 {
    width: 281rem;
  }
  .xs:w-282 {
    width: 282rem;
  }
  .xs:w-283 {
    width: 283rem;
  }
  .xs:w-284 {
    width: 284rem;
  }
  .xs:w-285 {
    width: 285rem;
  }
  .xs:w-286 {
    width: 286rem;
  }
  .xs:w-287 {
    width: 287rem;
  }
  .xs:w-288 {
    width: 288rem;
  }
  .xs:w-289 {
    width: 289rem;
  }
  .xs:w-290 {
    width: 290rem;
  }
  .xs:w-291 {
    width: 291rem;
  }
  .xs:w-292 {
    width: 292rem;
  }
  .xs:w-293 {
    width: 293rem;
  }
  .xs:w-294 {
    width: 294rem;
  }
  .xs:w-295 {
    width: 295rem;
  }
  .xs:w-296 {
    width: 296rem;
  }
  .xs:w-297 {
    width: 297rem;
  }
  .xs:w-298 {
    width: 298rem;
  }
  .xs:w-299 {
    width: 299rem;
  }
  .xs:w-300 {
    width: 300rem;
  }
  .xs:w-301 {
    width: 301rem;
  }
  .xs:w-302 {
    width: 302rem;
  }
  .xs:w-303 {
    width: 303rem;
  }
  .xs:w-304 {
    width: 304rem;
  }
  .xs:w-305 {
    width: 305rem;
  }
  .xs:w-306 {
    width: 306rem;
  }
  .xs:w-307 {
    width: 307rem;
  }
  .xs:w-308 {
    width: 308rem;
  }
  .xs:w-309 {
    width: 309rem;
  }
  .xs:w-310 {
    width: 310rem;
  }
  .xs:w-311 {
    width: 311rem;
  }
  .xs:w-312 {
    width: 312rem;
  }
  .xs:w-313 {
    width: 313rem;
  }
  .xs:w-314 {
    width: 314rem;
  }
  .xs:w-315 {
    width: 315rem;
  }
  .xs:w-316 {
    width: 316rem;
  }
  .xs:w-317 {
    width: 317rem;
  }
  .xs:w-318 {
    width: 318rem;
  }
  .xs:w-319 {
    width: 319rem;
  }
  .xs:w-320 {
    width: 320rem;
  }
  .xs:w-321 {
    width: 321rem;
  }
  .xs:w-322 {
    width: 322rem;
  }
  .xs:w-323 {
    width: 323rem;
  }
  .xs:w-324 {
    width: 324rem;
  }
  .xs:w-325 {
    width: 325rem;
  }
  .xs:w-326 {
    width: 326rem;
  }
  .xs:w-327 {
    width: 327rem;
  }
  .xs:w-328 {
    width: 328rem;
  }
  .xs:w-329 {
    width: 329rem;
  }
  .xs:w-330 {
    width: 330rem;
  }
  .xs:w-331 {
    width: 331rem;
  }
  .xs:w-332 {
    width: 332rem;
  }
  .xs:w-333 {
    width: 333rem;
  }
  .xs:w-334 {
    width: 334rem;
  }
  .xs:w-335 {
    width: 335rem;
  }
  .xs:w-336 {
    width: 336rem;
  }
  .xs:w-337 {
    width: 337rem;
  }
  .xs:w-338 {
    width: 338rem;
  }
  .xs:w-339 {
    width: 339rem;
  }
  .xs:w-340 {
    width: 340rem;
  }
  .xs:w-341 {
    width: 341rem;
  }
  .xs:w-342 {
    width: 342rem;
  }
  .xs:w-343 {
    width: 343rem;
  }
  .xs:w-344 {
    width: 344rem;
  }
  .xs:w-345 {
    width: 345rem;
  }
  .xs:w-346 {
    width: 346rem;
  }
  .xs:w-347 {
    width: 347rem;
  }
  .xs:w-348 {
    width: 348rem;
  }
  .xs:w-349 {
    width: 349rem;
  }
  .xs:w-350 {
    width: 350rem;
  }
  .xs:w-351 {
    width: 351rem;
  }
  .xs:w-352 {
    width: 352rem;
  }
  .xs:w-353 {
    width: 353rem;
  }
  .xs:w-354 {
    width: 354rem;
  }
  .xs:w-355 {
    width: 355rem;
  }
  .xs:w-356 {
    width: 356rem;
  }
  .xs:w-357 {
    width: 357rem;
  }
  .xs:w-358 {
    width: 358rem;
  }
  .xs:w-359 {
    width: 359rem;
  }
  .xs:w-360 {
    width: 360rem;
  }
  .xs:w-361 {
    width: 361rem;
  }
  .xs:w-362 {
    width: 362rem;
  }
  .xs:w-363 {
    width: 363rem;
  }
  .xs:w-364 {
    width: 364rem;
  }
  .xs:w-365 {
    width: 365rem;
  }
  .xs:w-366 {
    width: 366rem;
  }
  .xs:w-367 {
    width: 367rem;
  }
  .xs:w-368 {
    width: 368rem;
  }
  .xs:w-369 {
    width: 369rem;
  }
  .xs:w-370 {
    width: 370rem;
  }
  .xs:w-371 {
    width: 371rem;
  }
  .xs:w-372 {
    width: 372rem;
  }
  .xs:w-373 {
    width: 373rem;
  }
  .xs:w-374 {
    width: 374rem;
  }
  .xs:w-375 {
    width: 375rem;
  }
  .xs:w-376 {
    width: 376rem;
  }
  .xs:w-377 {
    width: 377rem;
  }
  .xs:w-378 {
    width: 378rem;
  }
  .xs:w-379 {
    width: 379rem;
  }
  .xs:w-380 {
    width: 380rem;
  }
  .xs:w-381 {
    width: 381rem;
  }
  .xs:w-382 {
    width: 382rem;
  }
  .xs:w-383 {
    width: 383rem;
  }
  .xs:w-384 {
    width: 384rem;
  }
  .xs:w-385 {
    width: 385rem;
  }
  .xs:w-386 {
    width: 386rem;
  }
  .xs:w-387 {
    width: 387rem;
  }
  .xs:w-388 {
    width: 388rem;
  }
  .xs:w-389 {
    width: 389rem;
  }
  .xs:w-390 {
    width: 390rem;
  }
  .xs:w-391 {
    width: 391rem;
  }
  .xs:w-392 {
    width: 392rem;
  }
  .xs:w-393 {
    width: 393rem;
  }
  .xs:w-394 {
    width: 394rem;
  }
  .xs:w-395 {
    width: 395rem;
  }
  .xs:w-396 {
    width: 396rem;
  }
  .xs:w-397 {
    width: 397rem;
  }
  .xs:w-398 {
    width: 398rem;
  }
  .xs:w-399 {
    width: 399rem;
  }
  .xs:w-400 {
    width: 400rem;
  }
  .xs:w-401 {
    width: 401rem;
  }
  .xs:w-402 {
    width: 402rem;
  }
  .xs:w-403 {
    width: 403rem;
  }
  .xs:w-404 {
    width: 404rem;
  }
  .xs:w-405 {
    width: 405rem;
  }
  .xs:w-406 {
    width: 406rem;
  }
  .xs:w-407 {
    width: 407rem;
  }
  .xs:w-408 {
    width: 408rem;
  }
  .xs:w-409 {
    width: 409rem;
  }
  .xs:w-410 {
    width: 410rem;
  }
  .xs:w-411 {
    width: 411rem;
  }
  .xs:w-412 {
    width: 412rem;
  }
  .xs:w-413 {
    width: 413rem;
  }
  .xs:w-414 {
    width: 414rem;
  }
  .xs:w-415 {
    width: 415rem;
  }
  .xs:w-416 {
    width: 416rem;
  }
  .xs:w-417 {
    width: 417rem;
  }
  .xs:w-418 {
    width: 418rem;
  }
  .xs:w-419 {
    width: 419rem;
  }
  .xs:w-420 {
    width: 420rem;
  }
  .xs:w-421 {
    width: 421rem;
  }
  .xs:w-422 {
    width: 422rem;
  }
  .xs:w-423 {
    width: 423rem;
  }
  .xs:w-424 {
    width: 424rem;
  }
  .xs:w-425 {
    width: 425rem;
  }
  .xs:w-426 {
    width: 426rem;
  }
  .xs:w-427 {
    width: 427rem;
  }
  .xs:w-428 {
    width: 428rem;
  }
  .xs:w-429 {
    width: 429rem;
  }
  .xs:w-430 {
    width: 430rem;
  }
  .xs:w-431 {
    width: 431rem;
  }
  .xs:w-432 {
    width: 432rem;
  }
  .xs:w-433 {
    width: 433rem;
  }
  .xs:w-434 {
    width: 434rem;
  }
  .xs:w-435 {
    width: 435rem;
  }
  .xs:w-436 {
    width: 436rem;
  }
  .xs:w-437 {
    width: 437rem;
  }
  .xs:w-438 {
    width: 438rem;
  }
  .xs:w-439 {
    width: 439rem;
  }
  .xs:w-440 {
    width: 440rem;
  }
  .xs:w-441 {
    width: 441rem;
  }
  .xs:w-442 {
    width: 442rem;
  }
  .xs:w-443 {
    width: 443rem;
  }
  .xs:w-444 {
    width: 444rem;
  }
  .xs:w-445 {
    width: 445rem;
  }
  .xs:w-446 {
    width: 446rem;
  }
  .xs:w-447 {
    width: 447rem;
  }
  .xs:w-448 {
    width: 448rem;
  }
  .xs:w-449 {
    width: 449rem;
  }
  .xs:w-450 {
    width: 450rem;
  }
  .xs:w-451 {
    width: 451rem;
  }
  .xs:w-452 {
    width: 452rem;
  }
  .xs:w-453 {
    width: 453rem;
  }
  .xs:w-454 {
    width: 454rem;
  }
  .xs:w-455 {
    width: 455rem;
  }
  .xs:w-456 {
    width: 456rem;
  }
  .xs:w-457 {
    width: 457rem;
  }
  .xs:w-458 {
    width: 458rem;
  }
  .xs:w-459 {
    width: 459rem;
  }
  .xs:w-460 {
    width: 460rem;
  }
  .xs:w-461 {
    width: 461rem;
  }
  .xs:w-462 {
    width: 462rem;
  }
  .xs:w-463 {
    width: 463rem;
  }
  .xs:w-464 {
    width: 464rem;
  }
  .xs:w-465 {
    width: 465rem;
  }
  .xs:w-466 {
    width: 466rem;
  }
  .xs:w-467 {
    width: 467rem;
  }
  .xs:w-468 {
    width: 468rem;
  }
  .xs:w-469 {
    width: 469rem;
  }
  .xs:w-470 {
    width: 470rem;
  }
  .xs:w-471 {
    width: 471rem;
  }
  .xs:w-472 {
    width: 472rem;
  }
  .xs:w-473 {
    width: 473rem;
  }
  .xs:w-474 {
    width: 474rem;
  }
  .xs:w-475 {
    width: 475rem;
  }
  .xs:w-476 {
    width: 476rem;
  }
  .xs:w-477 {
    width: 477rem;
  }
  .xs:w-478 {
    width: 478rem;
  }
  .xs:w-479 {
    width: 479rem;
  }
  .xs:w-480 {
    width: 480rem;
  }
  .xs:w-481 {
    width: 481rem;
  }
  .xs:w-482 {
    width: 482rem;
  }
  .xs:w-483 {
    width: 483rem;
  }
  .xs:w-484 {
    width: 484rem;
  }
  .xs:w-485 {
    width: 485rem;
  }
  .xs:w-486 {
    width: 486rem;
  }
  .xs:w-487 {
    width: 487rem;
  }
  .xs:w-488 {
    width: 488rem;
  }
  .xs:w-489 {
    width: 489rem;
  }
  .xs:w-490 {
    width: 490rem;
  }
  .xs:w-491 {
    width: 491rem;
  }
  .xs:w-492 {
    width: 492rem;
  }
  .xs:w-493 {
    width: 493rem;
  }
  .xs:w-494 {
    width: 494rem;
  }
  .xs:w-495 {
    width: 495rem;
  }
  .xs:w-496 {
    width: 496rem;
  }
  .xs:w-497 {
    width: 497rem;
  }
  .xs:w-498 {
    width: 498rem;
  }
  .xs:w-499 {
    width: 499rem;
  }
  .xs:w-500 {
    width: 500rem;
  }
  .xs:w-501 {
    width: 501rem;
  }
  .xs:w-502 {
    width: 502rem;
  }
  .xs:w-503 {
    width: 503rem;
  }
  .xs:w-504 {
    width: 504rem;
  }
  .xs:w-505 {
    width: 505rem;
  }
  .xs:w-506 {
    width: 506rem;
  }
  .xs:w-507 {
    width: 507rem;
  }
  .xs:w-508 {
    width: 508rem;
  }
  .xs:w-509 {
    width: 509rem;
  }
  .xs:w-510 {
    width: 510rem;
  }
  .xs:w-511 {
    width: 511rem;
  }
  .xs:w-512 {
    width: 512rem;
  }
  .xs:w-513 {
    width: 513rem;
  }
  .xs:w-514 {
    width: 514rem;
  }
  .xs:w-515 {
    width: 515rem;
  }
  .xs:w-516 {
    width: 516rem;
  }
  .xs:w-517 {
    width: 517rem;
  }
  .xs:w-518 {
    width: 518rem;
  }
  .xs:w-519 {
    width: 519rem;
  }
  .xs:w-520 {
    width: 520rem;
  }
  .xs:w-521 {
    width: 521rem;
  }
  .xs:w-522 {
    width: 522rem;
  }
  .xs:w-523 {
    width: 523rem;
  }
  .xs:w-524 {
    width: 524rem;
  }
  .xs:w-525 {
    width: 525rem;
  }
  .xs:w-526 {
    width: 526rem;
  }
  .xs:w-527 {
    width: 527rem;
  }
  .xs:w-528 {
    width: 528rem;
  }
  .xs:w-529 {
    width: 529rem;
  }
  .xs:w-530 {
    width: 530rem;
  }
  .xs:w-531 {
    width: 531rem;
  }
  .xs:w-532 {
    width: 532rem;
  }
  .xs:w-533 {
    width: 533rem;
  }
  .xs:w-534 {
    width: 534rem;
  }
  .xs:w-535 {
    width: 535rem;
  }
  .xs:w-536 {
    width: 536rem;
  }
  .xs:w-537 {
    width: 537rem;
  }
  .xs:w-538 {
    width: 538rem;
  }
  .xs:w-539 {
    width: 539rem;
  }
  .xs:w-540 {
    width: 540rem;
  }
  .xs:w-541 {
    width: 541rem;
  }
  .xs:w-542 {
    width: 542rem;
  }
  .xs:w-543 {
    width: 543rem;
  }
  .xs:w-544 {
    width: 544rem;
  }
  .xs:w-545 {
    width: 545rem;
  }
  .xs:w-546 {
    width: 546rem;
  }
  .xs:w-547 {
    width: 547rem;
  }
  .xs:w-548 {
    width: 548rem;
  }
  .xs:w-549 {
    width: 549rem;
  }
  .xs:w-550 {
    width: 550rem;
  }
  .xs:w-551 {
    width: 551rem;
  }
  .xs:w-552 {
    width: 552rem;
  }
  .xs:w-553 {
    width: 553rem;
  }
  .xs:w-554 {
    width: 554rem;
  }
  .xs:w-555 {
    width: 555rem;
  }
  .xs:w-556 {
    width: 556rem;
  }
  .xs:w-557 {
    width: 557rem;
  }
  .xs:w-558 {
    width: 558rem;
  }
  .xs:w-559 {
    width: 559rem;
  }
  .xs:w-560 {
    width: 560rem;
  }
  .xs:w-561 {
    width: 561rem;
  }
  .xs:w-562 {
    width: 562rem;
  }
  .xs:w-563 {
    width: 563rem;
  }
  .xs:w-564 {
    width: 564rem;
  }
  .xs:w-565 {
    width: 565rem;
  }
  .xs:w-566 {
    width: 566rem;
  }
  .xs:w-567 {
    width: 567rem;
  }
  .xs:w-568 {
    width: 568rem;
  }
  .xs:w-569 {
    width: 569rem;
  }
  .xs:w-570 {
    width: 570rem;
  }
  .xs:w-571 {
    width: 571rem;
  }
  .xs:w-572 {
    width: 572rem;
  }
  .xs:w-573 {
    width: 573rem;
  }
  .xs:w-574 {
    width: 574rem;
  }
  .xs:w-575 {
    width: 575rem;
  }
  .xs:w-576 {
    width: 576rem;
  }
  .xs:w-577 {
    width: 577rem;
  }
  .xs:w-578 {
    width: 578rem;
  }
  .xs:w-579 {
    width: 579rem;
  }
  .xs:w-580 {
    width: 580rem;
  }
  .xs:w-581 {
    width: 581rem;
  }
  .xs:w-582 {
    width: 582rem;
  }
  .xs:w-583 {
    width: 583rem;
  }
  .xs:w-584 {
    width: 584rem;
  }
  .xs:w-585 {
    width: 585rem;
  }
  .xs:w-586 {
    width: 586rem;
  }
  .xs:w-587 {
    width: 587rem;
  }
  .xs:w-588 {
    width: 588rem;
  }
  .xs:w-589 {
    width: 589rem;
  }
  .xs:w-590 {
    width: 590rem;
  }
  .xs:w-591 {
    width: 591rem;
  }
  .xs:w-592 {
    width: 592rem;
  }
  .xs:w-593 {
    width: 593rem;
  }
  .xs:w-594 {
    width: 594rem;
  }
  .xs:w-595 {
    width: 595rem;
  }
  .xs:w-596 {
    width: 596rem;
  }
  .xs:w-597 {
    width: 597rem;
  }
  .xs:w-598 {
    width: 598rem;
  }
  .xs:w-599 {
    width: 599rem;
  }
  .xs:w-600 {
    width: 600rem;
  }
  .xs:w-601 {
    width: 601rem;
  }
  .xs:w-602 {
    width: 602rem;
  }
  .xs:w-603 {
    width: 603rem;
  }
  .xs:w-604 {
    width: 604rem;
  }
  .xs:w-605 {
    width: 605rem;
  }
  .xs:w-606 {
    width: 606rem;
  }
  .xs:w-607 {
    width: 607rem;
  }
  .xs:w-608 {
    width: 608rem;
  }
  .xs:w-609 {
    width: 609rem;
  }
  .xs:w-610 {
    width: 610rem;
  }
  .xs:w-611 {
    width: 611rem;
  }
  .xs:w-612 {
    width: 612rem;
  }
  .xs:w-613 {
    width: 613rem;
  }
  .xs:w-614 {
    width: 614rem;
  }
  .xs:w-615 {
    width: 615rem;
  }
  .xs:w-616 {
    width: 616rem;
  }
  .xs:w-617 {
    width: 617rem;
  }
  .xs:w-618 {
    width: 618rem;
  }
  .xs:w-619 {
    width: 619rem;
  }
  .xs:w-620 {
    width: 620rem;
  }
  .xs:w-621 {
    width: 621rem;
  }
  .xs:w-622 {
    width: 622rem;
  }
  .xs:w-623 {
    width: 623rem;
  }
  .xs:w-624 {
    width: 624rem;
  }
  .xs:w-625 {
    width: 625rem;
  }
  .xs:w-626 {
    width: 626rem;
  }
  .xs:w-627 {
    width: 627rem;
  }
  .xs:w-628 {
    width: 628rem;
  }
  .xs:w-629 {
    width: 629rem;
  }
  .xs:w-630 {
    width: 630rem;
  }
  .xs:w-631 {
    width: 631rem;
  }
  .xs:w-632 {
    width: 632rem;
  }
  .xs:w-633 {
    width: 633rem;
  }
  .xs:w-634 {
    width: 634rem;
  }
  .xs:w-635 {
    width: 635rem;
  }
  .xs:w-636 {
    width: 636rem;
  }
  .xs:w-637 {
    width: 637rem;
  }
  .xs:w-638 {
    width: 638rem;
  }
  .xs:w-639 {
    width: 639rem;
  }
  .xs:w-640 {
    width: 640rem;
  }
  .xs:w-641 {
    width: 641rem;
  }
  .xs:w-642 {
    width: 642rem;
  }
  .xs:w-643 {
    width: 643rem;
  }
  .xs:w-644 {
    width: 644rem;
  }
  .xs:w-645 {
    width: 645rem;
  }
  .xs:w-646 {
    width: 646rem;
  }
  .xs:w-647 {
    width: 647rem;
  }
  .xs:w-648 {
    width: 648rem;
  }
  .xs:w-649 {
    width: 649rem;
  }
  .xs:w-650 {
    width: 650rem;
  }
  .xs:w-651 {
    width: 651rem;
  }
  .xs:w-652 {
    width: 652rem;
  }
  .xs:w-653 {
    width: 653rem;
  }
  .xs:w-654 {
    width: 654rem;
  }
  .xs:w-655 {
    width: 655rem;
  }
  .xs:w-656 {
    width: 656rem;
  }
  .xs:w-657 {
    width: 657rem;
  }
  .xs:w-658 {
    width: 658rem;
  }
  .xs:w-659 {
    width: 659rem;
  }
  .xs:w-660 {
    width: 660rem;
  }
  .xs:w-661 {
    width: 661rem;
  }
  .xs:w-662 {
    width: 662rem;
  }
  .xs:w-663 {
    width: 663rem;
  }
  .xs:w-664 {
    width: 664rem;
  }
  .xs:w-665 {
    width: 665rem;
  }
  .xs:w-666 {
    width: 666rem;
  }
  .xs:w-667 {
    width: 667rem;
  }
  .xs:w-668 {
    width: 668rem;
  }
  .xs:w-669 {
    width: 669rem;
  }
  .xs:w-670 {
    width: 670rem;
  }
  .xs:w-671 {
    width: 671rem;
  }
  .xs:w-672 {
    width: 672rem;
  }
  .xs:w-673 {
    width: 673rem;
  }
  .xs:w-674 {
    width: 674rem;
  }
  .xs:w-675 {
    width: 675rem;
  }
  .xs:w-676 {
    width: 676rem;
  }
  .xs:w-677 {
    width: 677rem;
  }
  .xs:w-678 {
    width: 678rem;
  }
  .xs:w-679 {
    width: 679rem;
  }
  .xs:w-680 {
    width: 680rem;
  }
  .xs:w-681 {
    width: 681rem;
  }
  .xs:w-682 {
    width: 682rem;
  }
  .xs:w-683 {
    width: 683rem;
  }
  .xs:w-684 {
    width: 684rem;
  }
  .xs:w-685 {
    width: 685rem;
  }
  .xs:w-686 {
    width: 686rem;
  }
  .xs:w-687 {
    width: 687rem;
  }
  .xs:w-688 {
    width: 688rem;
  }
  .xs:w-689 {
    width: 689rem;
  }
  .xs:w-690 {
    width: 690rem;
  }
  .xs:w-691 {
    width: 691rem;
  }
  .xs:w-692 {
    width: 692rem;
  }
  .xs:w-693 {
    width: 693rem;
  }
  .xs:w-694 {
    width: 694rem;
  }
  .xs:w-695 {
    width: 695rem;
  }
  .xs:w-696 {
    width: 696rem;
  }
  .xs:w-697 {
    width: 697rem;
  }
  .xs:w-698 {
    width: 698rem;
  }
  .xs:w-699 {
    width: 699rem;
  }
  .xs:w-700 {
    width: 700rem;
  }
  .xs:w-701 {
    width: 701rem;
  }
  .xs:w-702 {
    width: 702rem;
  }
  .xs:w-703 {
    width: 703rem;
  }
  .xs:w-704 {
    width: 704rem;
  }
  .xs:w-705 {
    width: 705rem;
  }
  .xs:w-706 {
    width: 706rem;
  }
  .xs:w-707 {
    width: 707rem;
  }
  .xs:w-708 {
    width: 708rem;
  }
  .xs:w-709 {
    width: 709rem;
  }
  .xs:w-710 {
    width: 710rem;
  }
  .xs:w-711 {
    width: 711rem;
  }
  .xs:w-712 {
    width: 712rem;
  }
  .xs:w-713 {
    width: 713rem;
  }
  .xs:w-714 {
    width: 714rem;
  }
  .xs:w-715 {
    width: 715rem;
  }
  .xs:w-716 {
    width: 716rem;
  }
  .xs:w-717 {
    width: 717rem;
  }
  .xs:w-718 {
    width: 718rem;
  }
  .xs:w-719 {
    width: 719rem;
  }
  .xs:w-720 {
    width: 720rem;
  }
  .xs:w-721 {
    width: 721rem;
  }
  .xs:w-722 {
    width: 722rem;
  }
  .xs:w-723 {
    width: 723rem;
  }
  .xs:w-724 {
    width: 724rem;
  }
  .xs:w-725 {
    width: 725rem;
  }
  .xs:w-726 {
    width: 726rem;
  }
  .xs:w-727 {
    width: 727rem;
  }
  .xs:w-728 {
    width: 728rem;
  }
  .xs:w-729 {
    width: 729rem;
  }
  .xs:w-730 {
    width: 730rem;
  }
  .xs:w-731 {
    width: 731rem;
  }
  .xs:w-732 {
    width: 732rem;
  }
  .xs:w-733 {
    width: 733rem;
  }
  .xs:w-734 {
    width: 734rem;
  }
  .xs:w-735 {
    width: 735rem;
  }
  .xs:w-736 {
    width: 736rem;
  }
  .xs:w-737 {
    width: 737rem;
  }
  .xs:w-738 {
    width: 738rem;
  }
  .xs:w-739 {
    width: 739rem;
  }
  .xs:w-740 {
    width: 740rem;
  }
  .xs:w-741 {
    width: 741rem;
  }
  .xs:w-742 {
    width: 742rem;
  }
  .xs:w-743 {
    width: 743rem;
  }
  .xs:w-744 {
    width: 744rem;
  }
  .xs:w-745 {
    width: 745rem;
  }
  .xs:w-746 {
    width: 746rem;
  }
  .xs:w-747 {
    width: 747rem;
  }
  .xs:w-748 {
    width: 748rem;
  }
  .xs:w-749 {
    width: 749rem;
  }
  .xs:w-750 {
    width: 750rem;
  }
  .xs:w-751 {
    width: 751rem;
  }
  .xs:w-752 {
    width: 752rem;
  }
  .xs:w-753 {
    width: 753rem;
  }
  .xs:w-754 {
    width: 754rem;
  }
  .xs:w-755 {
    width: 755rem;
  }
  .xs:w-756 {
    width: 756rem;
  }
  .xs:w-757 {
    width: 757rem;
  }
  .xs:w-758 {
    width: 758rem;
  }
  .xs:w-759 {
    width: 759rem;
  }
  .xs:w-760 {
    width: 760rem;
  }
  .xs:w-761 {
    width: 761rem;
  }
  .xs:w-762 {
    width: 762rem;
  }
  .xs:w-763 {
    width: 763rem;
  }
  .xs:w-764 {
    width: 764rem;
  }
  .xs:w-765 {
    width: 765rem;
  }
  .xs:w-766 {
    width: 766rem;
  }
  .xs:w-767 {
    width: 767rem;
  }
  .xs:w-768 {
    width: 768rem;
  }
  .xs:w-769 {
    width: 769rem;
  }
  .xs:w-770 {
    width: 770rem;
  }
  .xs:w-771 {
    width: 771rem;
  }
  .xs:w-772 {
    width: 772rem;
  }
  .xs:w-773 {
    width: 773rem;
  }
  .xs:w-774 {
    width: 774rem;
  }
  .xs:w-775 {
    width: 775rem;
  }
  .xs:w-776 {
    width: 776rem;
  }
  .xs:w-777 {
    width: 777rem;
  }
  .xs:w-778 {
    width: 778rem;
  }
  .xs:w-779 {
    width: 779rem;
  }
  .xs:w-780 {
    width: 780rem;
  }
  .xs:w-781 {
    width: 781rem;
  }
  .xs:w-782 {
    width: 782rem;
  }
  .xs:w-783 {
    width: 783rem;
  }
  .xs:w-784 {
    width: 784rem;
  }
  .xs:w-785 {
    width: 785rem;
  }
  .xs:w-786 {
    width: 786rem;
  }
  .xs:w-787 {
    width: 787rem;
  }
  .xs:w-788 {
    width: 788rem;
  }
  .xs:w-789 {
    width: 789rem;
  }
  .xs:w-790 {
    width: 790rem;
  }
  .xs:w-791 {
    width: 791rem;
  }
  .xs:w-792 {
    width: 792rem;
  }
  .xs:w-793 {
    width: 793rem;
  }
  .xs:w-794 {
    width: 794rem;
  }
  .xs:w-795 {
    width: 795rem;
  }
  .xs:w-796 {
    width: 796rem;
  }
  .xs:w-797 {
    width: 797rem;
  }
  .xs:w-798 {
    width: 798rem;
  }
  .xs:w-799 {
    width: 799rem;
  }
  .xs:w-800 {
    width: 800rem;
  }
  .xs:w-801 {
    width: 801rem;
  }
  .xs:w-802 {
    width: 802rem;
  }
  .xs:w-803 {
    width: 803rem;
  }
  .xs:w-804 {
    width: 804rem;
  }
  .xs:w-805 {
    width: 805rem;
  }
  .xs:w-806 {
    width: 806rem;
  }
  .xs:w-807 {
    width: 807rem;
  }
  .xs:w-808 {
    width: 808rem;
  }
  .xs:w-809 {
    width: 809rem;
  }
  .xs:w-810 {
    width: 810rem;
  }
  .xs:w-811 {
    width: 811rem;
  }
  .xs:w-812 {
    width: 812rem;
  }
  .xs:w-813 {
    width: 813rem;
  }
  .xs:w-814 {
    width: 814rem;
  }
  .xs:w-815 {
    width: 815rem;
  }
  .xs:w-816 {
    width: 816rem;
  }
  .xs:w-817 {
    width: 817rem;
  }
  .xs:w-818 {
    width: 818rem;
  }
  .xs:w-819 {
    width: 819rem;
  }
  .xs:w-820 {
    width: 820rem;
  }
  .xs:w-821 {
    width: 821rem;
  }
  .xs:w-822 {
    width: 822rem;
  }
  .xs:w-823 {
    width: 823rem;
  }
  .xs:w-824 {
    width: 824rem;
  }
  .xs:w-825 {
    width: 825rem;
  }
  .xs:w-826 {
    width: 826rem;
  }
  .xs:w-827 {
    width: 827rem;
  }
  .xs:w-828 {
    width: 828rem;
  }
  .xs:w-829 {
    width: 829rem;
  }
  .xs:w-830 {
    width: 830rem;
  }
  .xs:w-831 {
    width: 831rem;
  }
  .xs:w-832 {
    width: 832rem;
  }
  .xs:w-833 {
    width: 833rem;
  }
  .xs:w-834 {
    width: 834rem;
  }
  .xs:w-835 {
    width: 835rem;
  }
  .xs:w-836 {
    width: 836rem;
  }
  .xs:w-837 {
    width: 837rem;
  }
  .xs:w-838 {
    width: 838rem;
  }
  .xs:w-839 {
    width: 839rem;
  }
  .xs:w-840 {
    width: 840rem;
  }
  .xs:w-841 {
    width: 841rem;
  }
  .xs:w-842 {
    width: 842rem;
  }
  .xs:w-843 {
    width: 843rem;
  }
  .xs:w-844 {
    width: 844rem;
  }
  .xs:w-845 {
    width: 845rem;
  }
  .xs:w-846 {
    width: 846rem;
  }
  .xs:w-847 {
    width: 847rem;
  }
  .xs:w-848 {
    width: 848rem;
  }
  .xs:w-849 {
    width: 849rem;
  }
  .xs:w-850 {
    width: 850rem;
  }
  .xs:w-851 {
    width: 851rem;
  }
  .xs:w-852 {
    width: 852rem;
  }
  .xs:w-853 {
    width: 853rem;
  }
  .xs:w-854 {
    width: 854rem;
  }
  .xs:w-855 {
    width: 855rem;
  }
  .xs:w-856 {
    width: 856rem;
  }
  .xs:w-857 {
    width: 857rem;
  }
  .xs:w-858 {
    width: 858rem;
  }
  .xs:w-859 {
    width: 859rem;
  }
  .xs:w-860 {
    width: 860rem;
  }
  .xs:w-861 {
    width: 861rem;
  }
  .xs:w-862 {
    width: 862rem;
  }
  .xs:w-863 {
    width: 863rem;
  }
  .xs:w-864 {
    width: 864rem;
  }
  .xs:w-865 {
    width: 865rem;
  }
  .xs:w-866 {
    width: 866rem;
  }
  .xs:w-867 {
    width: 867rem;
  }
  .xs:w-868 {
    width: 868rem;
  }
  .xs:w-869 {
    width: 869rem;
  }
  .xs:w-870 {
    width: 870rem;
  }
  .xs:w-871 {
    width: 871rem;
  }
  .xs:w-872 {
    width: 872rem;
  }
  .xs:w-873 {
    width: 873rem;
  }
  .xs:w-874 {
    width: 874rem;
  }
  .xs:w-875 {
    width: 875rem;
  }
  .xs:w-876 {
    width: 876rem;
  }
  .xs:w-877 {
    width: 877rem;
  }
  .xs:w-878 {
    width: 878rem;
  }
  .xs:w-879 {
    width: 879rem;
  }
  .xs:w-880 {
    width: 880rem;
  }
  .xs:w-881 {
    width: 881rem;
  }
  .xs:w-882 {
    width: 882rem;
  }
  .xs:w-883 {
    width: 883rem;
  }
  .xs:w-884 {
    width: 884rem;
  }
  .xs:w-885 {
    width: 885rem;
  }
  .xs:w-886 {
    width: 886rem;
  }
  .xs:w-887 {
    width: 887rem;
  }
  .xs:w-888 {
    width: 888rem;
  }
  .xs:w-889 {
    width: 889rem;
  }
  .xs:w-890 {
    width: 890rem;
  }
  .xs:w-891 {
    width: 891rem;
  }
  .xs:w-892 {
    width: 892rem;
  }
  .xs:w-893 {
    width: 893rem;
  }
  .xs:w-894 {
    width: 894rem;
  }
  .xs:w-895 {
    width: 895rem;
  }
  .xs:w-896 {
    width: 896rem;
  }
  .xs:w-897 {
    width: 897rem;
  }
  .xs:w-898 {
    width: 898rem;
  }
  .xs:w-899 {
    width: 899rem;
  }
  .xs:w-900 {
    width: 900rem;
  }
  .xs:w-901 {
    width: 901rem;
  }
  .xs:w-902 {
    width: 902rem;
  }
  .xs:w-903 {
    width: 903rem;
  }
  .xs:w-904 {
    width: 904rem;
  }
  .xs:w-905 {
    width: 905rem;
  }
  .xs:w-906 {
    width: 906rem;
  }
  .xs:w-907 {
    width: 907rem;
  }
  .xs:w-908 {
    width: 908rem;
  }
  .xs:w-909 {
    width: 909rem;
  }
  .xs:w-910 {
    width: 910rem;
  }
  .xs:w-911 {
    width: 911rem;
  }
  .xs:w-912 {
    width: 912rem;
  }
  .xs:w-913 {
    width: 913rem;
  }
  .xs:w-914 {
    width: 914rem;
  }
  .xs:w-915 {
    width: 915rem;
  }
  .xs:w-916 {
    width: 916rem;
  }
  .xs:w-917 {
    width: 917rem;
  }
  .xs:w-918 {
    width: 918rem;
  }
  .xs:w-919 {
    width: 919rem;
  }
  .xs:w-920 {
    width: 920rem;
  }
  .xs:w-921 {
    width: 921rem;
  }
  .xs:w-922 {
    width: 922rem;
  }
  .xs:w-923 {
    width: 923rem;
  }
  .xs:w-924 {
    width: 924rem;
  }
  .xs:w-925 {
    width: 925rem;
  }
  .xs:w-926 {
    width: 926rem;
  }
  .xs:w-927 {
    width: 927rem;
  }
  .xs:w-928 {
    width: 928rem;
  }
  .xs:w-929 {
    width: 929rem;
  }
  .xs:w-930 {
    width: 930rem;
  }
  .xs:w-931 {
    width: 931rem;
  }
  .xs:w-932 {
    width: 932rem;
  }
  .xs:w-933 {
    width: 933rem;
  }
  .xs:w-934 {
    width: 934rem;
  }
  .xs:w-935 {
    width: 935rem;
  }
  .xs:w-936 {
    width: 936rem;
  }
  .xs:w-937 {
    width: 937rem;
  }
  .xs:w-938 {
    width: 938rem;
  }
  .xs:w-939 {
    width: 939rem;
  }
  .xs:w-940 {
    width: 940rem;
  }
  .xs:w-941 {
    width: 941rem;
  }
  .xs:w-942 {
    width: 942rem;
  }
  .xs:w-943 {
    width: 943rem;
  }
  .xs:w-944 {
    width: 944rem;
  }
  .xs:w-945 {
    width: 945rem;
  }
  .xs:w-946 {
    width: 946rem;
  }
  .xs:w-947 {
    width: 947rem;
  }
  .xs:w-948 {
    width: 948rem;
  }
  .xs:w-949 {
    width: 949rem;
  }
  .xs:w-950 {
    width: 950rem;
  }
  .xs:w-951 {
    width: 951rem;
  }
  .xs:w-952 {
    width: 952rem;
  }
  .xs:w-953 {
    width: 953rem;
  }
  .xs:w-954 {
    width: 954rem;
  }
  .xs:w-955 {
    width: 955rem;
  }
  .xs:w-956 {
    width: 956rem;
  }
  .xs:w-957 {
    width: 957rem;
  }
  .xs:w-958 {
    width: 958rem;
  }
  .xs:w-959 {
    width: 959rem;
  }
  .xs:w-960 {
    width: 960rem;
  }
  .xs:w-961 {
    width: 961rem;
  }
  .xs:w-962 {
    width: 962rem;
  }
  .xs:w-963 {
    width: 963rem;
  }
  .xs:w-964 {
    width: 964rem;
  }
  .xs:w-965 {
    width: 965rem;
  }
  .xs:w-966 {
    width: 966rem;
  }
  .xs:w-967 {
    width: 967rem;
  }
  .xs:w-968 {
    width: 968rem;
  }
  .xs:w-969 {
    width: 969rem;
  }
  .xs:w-970 {
    width: 970rem;
  }
  .xs:w-971 {
    width: 971rem;
  }
  .xs:w-972 {
    width: 972rem;
  }
  .xs:w-973 {
    width: 973rem;
  }
  .xs:w-974 {
    width: 974rem;
  }
  .xs:w-975 {
    width: 975rem;
  }
  .xs:w-976 {
    width: 976rem;
  }
  .xs:w-977 {
    width: 977rem;
  }
  .xs:w-978 {
    width: 978rem;
  }
  .xs:w-979 {
    width: 979rem;
  }
  .xs:w-980 {
    width: 980rem;
  }
  .xs:w-981 {
    width: 981rem;
  }
  .xs:w-982 {
    width: 982rem;
  }
  .xs:w-983 {
    width: 983rem;
  }
  .xs:w-984 {
    width: 984rem;
  }
  .xs:w-985 {
    width: 985rem;
  }
  .xs:w-986 {
    width: 986rem;
  }
  .xs:w-987 {
    width: 987rem;
  }
  .xs:w-988 {
    width: 988rem;
  }
  .xs:w-989 {
    width: 989rem;
  }
  .xs:w-990 {
    width: 990rem;
  }
  .xs:w-991 {
    width: 991rem;
  }
  .xs:w-992 {
    width: 992rem;
  }
  .xs:w-993 {
    width: 993rem;
  }
  .xs:w-994 {
    width: 994rem;
  }
  .xs:w-995 {
    width: 995rem;
  }
  .xs:w-996 {
    width: 996rem;
  }
  .xs:w-997 {
    width: 997rem;
  }
  .xs:w-998 {
    width: 998rem;
  }
  .xs:w-999 {
    width: 999rem;
  }
  .xs:w-1000 {
    width: 1000rem;
  }
  .xs:w-1001 {
    width: 1001rem;
  }
  .xs:w-1002 {
    width: 1002rem;
  }
  .xs:w-1003 {
    width: 1003rem;
  }
  .xs:w-1004 {
    width: 1004rem;
  }
  .xs:w-1005 {
    width: 1005rem;
  }
  .xs:w-1006 {
    width: 1006rem;
  }
  .xs:w-1007 {
    width: 1007rem;
  }
  .xs:w-1008 {
    width: 1008rem;
  }
  .xs:w-1009 {
    width: 1009rem;
  }
  .xs:w-1010 {
    width: 1010rem;
  }
  .xs:w-1011 {
    width: 1011rem;
  }
  .xs:w-1012 {
    width: 1012rem;
  }
  .xs:w-1013 {
    width: 1013rem;
  }
  .xs:w-1014 {
    width: 1014rem;
  }
  .xs:w-1015 {
    width: 1015rem;
  }
  .xs:w-1016 {
    width: 1016rem;
  }
  .xs:w-1017 {
    width: 1017rem;
  }
  .xs:w-1018 {
    width: 1018rem;
  }
  .xs:w-1019 {
    width: 1019rem;
  }
  .xs:w-1020 {
    width: 1020rem;
  }
  .xs:w-1021 {
    width: 1021rem;
  }
  .xs:w-1022 {
    width: 1022rem;
  }
  .xs:w-1023 {
    width: 1023rem;
  }
  .xs:w-1024 {
    width: 1024rem;
  }
  .xs:w-1025 {
    width: 1025rem;
  }
  .xs:w-1026 {
    width: 1026rem;
  }
  .xs:w-1027 {
    width: 1027rem;
  }
  .xs:w-1028 {
    width: 1028rem;
  }
  .xs:w-1029 {
    width: 1029rem;
  }
  .xs:w-1030 {
    width: 1030rem;
  }
  .xs:w-1031 {
    width: 1031rem;
  }
  .xs:w-1032 {
    width: 1032rem;
  }
  .xs:w-1033 {
    width: 1033rem;
  }
  .xs:w-1034 {
    width: 1034rem;
  }
  .xs:w-1035 {
    width: 1035rem;
  }
  .xs:w-1036 {
    width: 1036rem;
  }
  .xs:w-1037 {
    width: 1037rem;
  }
  .xs:w-1038 {
    width: 1038rem;
  }
  .xs:w-1039 {
    width: 1039rem;
  }
  .xs:w-1040 {
    width: 1040rem;
  }
  .xs:w-1041 {
    width: 1041rem;
  }
  .xs:w-1042 {
    width: 1042rem;
  }
  .xs:w-1043 {
    width: 1043rem;
  }
  .xs:w-1044 {
    width: 1044rem;
  }
  .xs:w-1045 {
    width: 1045rem;
  }
  .xs:w-1046 {
    width: 1046rem;
  }
  .xs:w-1047 {
    width: 1047rem;
  }
  .xs:w-1048 {
    width: 1048rem;
  }
  .xs:w-1049 {
    width: 1049rem;
  }
  .xs:w-1050 {
    width: 1050rem;
  }
  .xs:w-1051 {
    width: 1051rem;
  }
  .xs:w-1052 {
    width: 1052rem;
  }
  .xs:w-1053 {
    width: 1053rem;
  }
  .xs:w-1054 {
    width: 1054rem;
  }
  .xs:w-1055 {
    width: 1055rem;
  }
  .xs:w-1056 {
    width: 1056rem;
  }
  .xs:w-1057 {
    width: 1057rem;
  }
  .xs:w-1058 {
    width: 1058rem;
  }
  .xs:w-1059 {
    width: 1059rem;
  }
  .xs:w-1060 {
    width: 1060rem;
  }
  .xs:w-1061 {
    width: 1061rem;
  }
  .xs:w-1062 {
    width: 1062rem;
  }
  .xs:w-1063 {
    width: 1063rem;
  }
  .xs:w-1064 {
    width: 1064rem;
  }
  .xs:w-1065 {
    width: 1065rem;
  }
  .xs:w-1066 {
    width: 1066rem;
  }
  .xs:w-1067 {
    width: 1067rem;
  }
  .xs:w-1068 {
    width: 1068rem;
  }
  .xs:w-1069 {
    width: 1069rem;
  }
  .xs:w-1070 {
    width: 1070rem;
  }
  .xs:w-1071 {
    width: 1071rem;
  }
  .xs:w-1072 {
    width: 1072rem;
  }
  .xs:w-1073 {
    width: 1073rem;
  }
  .xs:w-1074 {
    width: 1074rem;
  }
  .xs:w-1075 {
    width: 1075rem;
  }
  .xs:w-1076 {
    width: 1076rem;
  }
  .xs:w-1077 {
    width: 1077rem;
  }
  .xs:w-1078 {
    width: 1078rem;
  }
  .xs:w-1079 {
    width: 1079rem;
  }
  .xs:w-1080 {
    width: 1080rem;
  }
  .xs:w-1081 {
    width: 1081rem;
  }
  .xs:w-1082 {
    width: 1082rem;
  }
  .xs:w-1083 {
    width: 1083rem;
  }
  .xs:w-1084 {
    width: 1084rem;
  }
  .xs:w-1085 {
    width: 1085rem;
  }
  .xs:w-1086 {
    width: 1086rem;
  }
  .xs:w-1087 {
    width: 1087rem;
  }
  .xs:w-1088 {
    width: 1088rem;
  }
  .xs:w-1089 {
    width: 1089rem;
  }
  .xs:w-1090 {
    width: 1090rem;
  }
  .xs:w-1091 {
    width: 1091rem;
  }
  .xs:w-1092 {
    width: 1092rem;
  }
  .xs:w-1093 {
    width: 1093rem;
  }
  .xs:w-1094 {
    width: 1094rem;
  }
  .xs:w-1095 {
    width: 1095rem;
  }
  .xs:w-1096 {
    width: 1096rem;
  }
  .xs:w-1097 {
    width: 1097rem;
  }
  .xs:w-1098 {
    width: 1098rem;
  }
  .xs:w-1099 {
    width: 1099rem;
  }
  .xs:w-1100 {
    width: 1100rem;
  }
  .xs:w-1101 {
    width: 1101rem;
  }
  .xs:w-1102 {
    width: 1102rem;
  }
  .xs:w-1103 {
    width: 1103rem;
  }
  .xs:w-1104 {
    width: 1104rem;
  }
  .xs:w-1105 {
    width: 1105rem;
  }
  .xs:w-1106 {
    width: 1106rem;
  }
  .xs:w-1107 {
    width: 1107rem;
  }
  .xs:w-1108 {
    width: 1108rem;
  }
  .xs:w-1109 {
    width: 1109rem;
  }
  .xs:w-1110 {
    width: 1110rem;
  }
  .xs:w-1111 {
    width: 1111rem;
  }
  .xs:w-1112 {
    width: 1112rem;
  }
  .xs:w-1113 {
    width: 1113rem;
  }
  .xs:w-1114 {
    width: 1114rem;
  }
  .xs:w-1115 {
    width: 1115rem;
  }
  .xs:w-1116 {
    width: 1116rem;
  }
  .xs:w-1117 {
    width: 1117rem;
  }
  .xs:w-1118 {
    width: 1118rem;
  }
  .xs:w-1119 {
    width: 1119rem;
  }
  .xs:w-1120 {
    width: 1120rem;
  }
  .xs:w-1121 {
    width: 1121rem;
  }
  .xs:w-1122 {
    width: 1122rem;
  }
  .xs:w-1123 {
    width: 1123rem;
  }
  .xs:w-1124 {
    width: 1124rem;
  }
  .xs:w-1125 {
    width: 1125rem;
  }
  .xs:w-1126 {
    width: 1126rem;
  }
  .xs:w-1127 {
    width: 1127rem;
  }
  .xs:w-1128 {
    width: 1128rem;
  }
  .xs:w-1129 {
    width: 1129rem;
  }
  .xs:w-1130 {
    width: 1130rem;
  }
  .xs:w-1131 {
    width: 1131rem;
  }
  .xs:w-1132 {
    width: 1132rem;
  }
  .xs:w-1133 {
    width: 1133rem;
  }
  .xs:w-1134 {
    width: 1134rem;
  }
  .xs:w-1135 {
    width: 1135rem;
  }
  .xs:w-1136 {
    width: 1136rem;
  }
  .xs:w-1137 {
    width: 1137rem;
  }
  .xs:w-1138 {
    width: 1138rem;
  }
  .xs:w-1139 {
    width: 1139rem;
  }
  .xs:w-1140 {
    width: 1140rem;
  }
  .xs:w-1141 {
    width: 1141rem;
  }
  .xs:w-1142 {
    width: 1142rem;
  }
  .xs:w-1143 {
    width: 1143rem;
  }
  .xs:w-1144 {
    width: 1144rem;
  }
  .xs:w-1145 {
    width: 1145rem;
  }
  .xs:w-1146 {
    width: 1146rem;
  }
  .xs:w-1147 {
    width: 1147rem;
  }
  .xs:w-1148 {
    width: 1148rem;
  }
  .xs:w-1149 {
    width: 1149rem;
  }
  .xs:w-1150 {
    width: 1150rem;
  }
  .xs:w-1151 {
    width: 1151rem;
  }
  .xs:w-1152 {
    width: 1152rem;
  }
  .xs:w-1153 {
    width: 1153rem;
  }
  .xs:w-1154 {
    width: 1154rem;
  }
  .xs:w-1155 {
    width: 1155rem;
  }
  .xs:w-1156 {
    width: 1156rem;
  }
  .xs:w-1157 {
    width: 1157rem;
  }
  .xs:w-1158 {
    width: 1158rem;
  }
  .xs:w-1159 {
    width: 1159rem;
  }
  .xs:w-1160 {
    width: 1160rem;
  }
  .xs:w-1161 {
    width: 1161rem;
  }
  .xs:w-1162 {
    width: 1162rem;
  }
  .xs:w-1163 {
    width: 1163rem;
  }
  .xs:w-1164 {
    width: 1164rem;
  }
  .xs:w-1165 {
    width: 1165rem;
  }
  .xs:w-1166 {
    width: 1166rem;
  }
  .xs:w-1167 {
    width: 1167rem;
  }
  .xs:w-1168 {
    width: 1168rem;
  }
  .xs:w-1169 {
    width: 1169rem;
  }
  .xs:w-1170 {
    width: 1170rem;
  }
  .xs:w-1171 {
    width: 1171rem;
  }
  .xs:w-1172 {
    width: 1172rem;
  }
  .xs:w-1173 {
    width: 1173rem;
  }
  .xs:w-1174 {
    width: 1174rem;
  }
  .xs:w-1175 {
    width: 1175rem;
  }
  .xs:w-1176 {
    width: 1176rem;
  }
  .xs:w-1177 {
    width: 1177rem;
  }
  .xs:w-1178 {
    width: 1178rem;
  }
  .xs:w-1179 {
    width: 1179rem;
  }
  .xs:w-1180 {
    width: 1180rem;
  }
  .xs:w-1181 {
    width: 1181rem;
  }
  .xs:w-1182 {
    width: 1182rem;
  }
  .xs:w-1183 {
    width: 1183rem;
  }
  .xs:w-1184 {
    width: 1184rem;
  }
  .xs:w-1185 {
    width: 1185rem;
  }
  .xs:w-1186 {
    width: 1186rem;
  }
  .xs:w-1187 {
    width: 1187rem;
  }
  .xs:w-1188 {
    width: 1188rem;
  }
  .xs:w-1189 {
    width: 1189rem;
  }
  .xs:w-1190 {
    width: 1190rem;
  }
  .xs:w-1191 {
    width: 1191rem;
  }
  .xs:w-1192 {
    width: 1192rem;
  }
  .xs:w-1193 {
    width: 1193rem;
  }
  .xs:w-1194 {
    width: 1194rem;
  }
  .xs:w-1195 {
    width: 1195rem;
  }
  .xs:w-1196 {
    width: 1196rem;
  }
  .xs:w-1197 {
    width: 1197rem;
  }
  .xs:w-1198 {
    width: 1198rem;
  }
  .xs:w-1199 {
    width: 1199rem;
  }
  .xs:w-1200 {
    width: 1200rem;
  }
  .xs:w-1201 {
    width: 1201rem;
  }
  .xs:w-1202 {
    width: 1202rem;
  }
  .xs:w-1203 {
    width: 1203rem;
  }
  .xs:w-1204 {
    width: 1204rem;
  }
  .xs:w-1205 {
    width: 1205rem;
  }
  .xs:w-1206 {
    width: 1206rem;
  }
  .xs:w-1207 {
    width: 1207rem;
  }
  .xs:w-1208 {
    width: 1208rem;
  }
  .xs:w-1209 {
    width: 1209rem;
  }
  .xs:w-1210 {
    width: 1210rem;
  }
  .xs:w-1211 {
    width: 1211rem;
  }
  .xs:w-1212 {
    width: 1212rem;
  }
  .xs:w-1213 {
    width: 1213rem;
  }
  .xs:w-1214 {
    width: 1214rem;
  }
  .xs:w-1215 {
    width: 1215rem;
  }
  .xs:w-1216 {
    width: 1216rem;
  }
  .xs:w-1217 {
    width: 1217rem;
  }
  .xs:w-1218 {
    width: 1218rem;
  }
  .xs:w-1219 {
    width: 1219rem;
  }
  .xs:w-1220 {
    width: 1220rem;
  }
  .xs:w-1221 {
    width: 1221rem;
  }
  .xs:w-1222 {
    width: 1222rem;
  }
  .xs:w-1223 {
    width: 1223rem;
  }
  .xs:w-1224 {
    width: 1224rem;
  }
  .xs:w-1225 {
    width: 1225rem;
  }
  .xs:w-1226 {
    width: 1226rem;
  }
  .xs:w-1227 {
    width: 1227rem;
  }
  .xs:w-1228 {
    width: 1228rem;
  }
  .xs:w-1229 {
    width: 1229rem;
  }
  .xs:w-1230 {
    width: 1230rem;
  }
  .xs:w-1231 {
    width: 1231rem;
  }
  .xs:w-1232 {
    width: 1232rem;
  }
  .xs:w-1233 {
    width: 1233rem;
  }
  .xs:w-1234 {
    width: 1234rem;
  }
  .xs:w-1235 {
    width: 1235rem;
  }
  .xs:w-1236 {
    width: 1236rem;
  }
  .xs:w-1237 {
    width: 1237rem;
  }
  .xs:w-1238 {
    width: 1238rem;
  }
  .xs:w-1239 {
    width: 1239rem;
  }
  .xs:w-1240 {
    width: 1240rem;
  }
  .xs:w-1241 {
    width: 1241rem;
  }
  .xs:w-1242 {
    width: 1242rem;
  }
  .xs:w-1243 {
    width: 1243rem;
  }
  .xs:w-1244 {
    width: 1244rem;
  }
  .xs:w-1245 {
    width: 1245rem;
  }
  .xs:w-1246 {
    width: 1246rem;
  }
  .xs:w-1247 {
    width: 1247rem;
  }
  .xs:w-1248 {
    width: 1248rem;
  }
  .xs:w-1249 {
    width: 1249rem;
  }
  .xs:w-1250 {
    width: 1250rem;
  }
  .xs:w-1251 {
    width: 1251rem;
  }
  .xs:w-1252 {
    width: 1252rem;
  }
  .xs:w-1253 {
    width: 1253rem;
  }
  .xs:w-1254 {
    width: 1254rem;
  }
  .xs:w-1255 {
    width: 1255rem;
  }
  .xs:w-1256 {
    width: 1256rem;
  }
  .xs:w-1257 {
    width: 1257rem;
  }
  .xs:w-1258 {
    width: 1258rem;
  }
  .xs:w-1259 {
    width: 1259rem;
  }
  .xs:w-1260 {
    width: 1260rem;
  }
  .xs:w-1261 {
    width: 1261rem;
  }
  .xs:w-1262 {
    width: 1262rem;
  }
  .xs:w-1263 {
    width: 1263rem;
  }
  .xs:w-1264 {
    width: 1264rem;
  }
  .xs:w-1265 {
    width: 1265rem;
  }
  .xs:w-1266 {
    width: 1266rem;
  }
  .xs:w-1267 {
    width: 1267rem;
  }
  .xs:w-1268 {
    width: 1268rem;
  }
  .xs:w-1269 {
    width: 1269rem;
  }
  .xs:w-1270 {
    width: 1270rem;
  }
  .xs:w-1271 {
    width: 1271rem;
  }
  .xs:w-1272 {
    width: 1272rem;
  }
  .xs:w-1273 {
    width: 1273rem;
  }
  .xs:w-1274 {
    width: 1274rem;
  }
  .xs:w-1275 {
    width: 1275rem;
  }
  .xs:w-1276 {
    width: 1276rem;
  }
  .xs:w-1277 {
    width: 1277rem;
  }
  .xs:w-1278 {
    width: 1278rem;
  }
  .xs:w-1279 {
    width: 1279rem;
  }
  .xs:w-1280 {
    width: 1280rem;
  }
  .xs:w-1281 {
    width: 1281rem;
  }
  .xs:w-1282 {
    width: 1282rem;
  }
  .xs:w-1283 {
    width: 1283rem;
  }
  .xs:w-1284 {
    width: 1284rem;
  }
  .xs:w-1285 {
    width: 1285rem;
  }
  .xs:w-1286 {
    width: 1286rem;
  }
  .xs:w-1287 {
    width: 1287rem;
  }
  .xs:w-1288 {
    width: 1288rem;
  }
  .xs:w-1289 {
    width: 1289rem;
  }
  .xs:w-1290 {
    width: 1290rem;
  }
  .xs:w-1291 {
    width: 1291rem;
  }
  .xs:w-1292 {
    width: 1292rem;
  }
  .xs:w-1293 {
    width: 1293rem;
  }
  .xs:w-1294 {
    width: 1294rem;
  }
  .xs:w-1295 {
    width: 1295rem;
  }
  .xs:w-1296 {
    width: 1296rem;
  }
  .xs:w-1297 {
    width: 1297rem;
  }
  .xs:w-1298 {
    width: 1298rem;
  }
  .xs:w-1299 {
    width: 1299rem;
  }
  .xs:w-1300 {
    width: 1300rem;
  }
  .xs:w-1301 {
    width: 1301rem;
  }
  .xs:w-1302 {
    width: 1302rem;
  }
  .xs:w-1303 {
    width: 1303rem;
  }
  .xs:w-1304 {
    width: 1304rem;
  }
  .xs:w-1305 {
    width: 1305rem;
  }
  .xs:w-1306 {
    width: 1306rem;
  }
  .xs:w-1307 {
    width: 1307rem;
  }
  .xs:w-1308 {
    width: 1308rem;
  }
  .xs:w-1309 {
    width: 1309rem;
  }
  .xs:w-1310 {
    width: 1310rem;
  }
  .xs:w-1311 {
    width: 1311rem;
  }
  .xs:w-1312 {
    width: 1312rem;
  }
  .xs:w-1313 {
    width: 1313rem;
  }
  .xs:w-1314 {
    width: 1314rem;
  }
  .xs:w-1315 {
    width: 1315rem;
  }
  .xs:w-1316 {
    width: 1316rem;
  }
  .xs:w-1317 {
    width: 1317rem;
  }
  .xs:w-1318 {
    width: 1318rem;
  }
  .xs:w-1319 {
    width: 1319rem;
  }
  .xs:w-1320 {
    width: 1320rem;
  }
  .xs:w-1321 {
    width: 1321rem;
  }
  .xs:w-1322 {
    width: 1322rem;
  }
  .xs:w-1323 {
    width: 1323rem;
  }
  .xs:w-1324 {
    width: 1324rem;
  }
  .xs:w-1325 {
    width: 1325rem;
  }
  .xs:w-1326 {
    width: 1326rem;
  }
  .xs:w-1327 {
    width: 1327rem;
  }
  .xs:w-1328 {
    width: 1328rem;
  }
  .xs:w-1329 {
    width: 1329rem;
  }
  .xs:w-1330 {
    width: 1330rem;
  }
  .xs:w-1331 {
    width: 1331rem;
  }
  .xs:w-1332 {
    width: 1332rem;
  }
  .xs:w-1333 {
    width: 1333rem;
  }
  .xs:w-1334 {
    width: 1334rem;
  }
  .xs:w-1335 {
    width: 1335rem;
  }
  .xs:w-1336 {
    width: 1336rem;
  }
  .xs:w-1337 {
    width: 1337rem;
  }
  .xs:w-1338 {
    width: 1338rem;
  }
  .xs:w-1339 {
    width: 1339rem;
  }
  .xs:w-1340 {
    width: 1340rem;
  }
  .xs:w-1341 {
    width: 1341rem;
  }
  .xs:w-1342 {
    width: 1342rem;
  }
  .xs:w-1343 {
    width: 1343rem;
  }
  .xs:w-1344 {
    width: 1344rem;
  }
  .xs:w-1345 {
    width: 1345rem;
  }
  .xs:w-1346 {
    width: 1346rem;
  }
  .xs:w-1347 {
    width: 1347rem;
  }
  .xs:w-1348 {
    width: 1348rem;
  }
  .xs:w-1349 {
    width: 1349rem;
  }
  .xs:w-1350 {
    width: 1350rem;
  }
  .xs:w-1351 {
    width: 1351rem;
  }
  .xs:w-1352 {
    width: 1352rem;
  }
  .xs:w-1353 {
    width: 1353rem;
  }
  .xs:w-1354 {
    width: 1354rem;
  }
  .xs:w-1355 {
    width: 1355rem;
  }
  .xs:w-1356 {
    width: 1356rem;
  }
  .xs:w-1357 {
    width: 1357rem;
  }
  .xs:w-1358 {
    width: 1358rem;
  }
  .xs:w-1359 {
    width: 1359rem;
  }
  .xs:w-1360 {
    width: 1360rem;
  }
  .xs:w-1361 {
    width: 1361rem;
  }
  .xs:w-1362 {
    width: 1362rem;
  }
  .xs:w-1363 {
    width: 1363rem;
  }
  .xs:w-1364 {
    width: 1364rem;
  }
  .xs:w-1365 {
    width: 1365rem;
  }
  .xs:w-1366 {
    width: 1366rem;
  }
  .xs:w-1367 {
    width: 1367rem;
  }
  .xs:w-1368 {
    width: 1368rem;
  }
  .xs:w-1369 {
    width: 1369rem;
  }
  .xs:w-1370 {
    width: 1370rem;
  }
  .xs:w-1371 {
    width: 1371rem;
  }
  .xs:w-1372 {
    width: 1372rem;
  }
  .xs:w-1373 {
    width: 1373rem;
  }
  .xs:w-1374 {
    width: 1374rem;
  }
  .xs:w-1375 {
    width: 1375rem;
  }
  .xs:w-1376 {
    width: 1376rem;
  }
  .xs:w-1377 {
    width: 1377rem;
  }
  .xs:w-1378 {
    width: 1378rem;
  }
  .xs:w-1379 {
    width: 1379rem;
  }
  .xs:w-1380 {
    width: 1380rem;
  }
  .xs:w-1381 {
    width: 1381rem;
  }
  .xs:w-1382 {
    width: 1382rem;
  }
  .xs:w-1383 {
    width: 1383rem;
  }
  .xs:w-1384 {
    width: 1384rem;
  }
  .xs:w-1385 {
    width: 1385rem;
  }
  .xs:w-1386 {
    width: 1386rem;
  }
  .xs:w-1387 {
    width: 1387rem;
  }
  .xs:w-1388 {
    width: 1388rem;
  }
  .xs:w-1389 {
    width: 1389rem;
  }
  .xs:w-1390 {
    width: 1390rem;
  }
  .xs:w-1391 {
    width: 1391rem;
  }
  .xs:w-1392 {
    width: 1392rem;
  }
  .xs:w-1393 {
    width: 1393rem;
  }
  .xs:w-1394 {
    width: 1394rem;
  }
  .xs:w-1395 {
    width: 1395rem;
  }
  .xs:w-1396 {
    width: 1396rem;
  }
  .xs:w-1397 {
    width: 1397rem;
  }
  .xs:w-1398 {
    width: 1398rem;
  }
  .xs:w-1399 {
    width: 1399rem;
  }
  .xs:w-1400 {
    width: 1400rem;
  }
  .xs:w-1401 {
    width: 1401rem;
  }
  .xs:w-1402 {
    width: 1402rem;
  }
  .xs:w-1403 {
    width: 1403rem;
  }
  .xs:w-1404 {
    width: 1404rem;
  }
  .xs:w-1405 {
    width: 1405rem;
  }
  .xs:w-1406 {
    width: 1406rem;
  }
  .xs:w-1407 {
    width: 1407rem;
  }
  .xs:w-1408 {
    width: 1408rem;
  }
  .xs:w-1409 {
    width: 1409rem;
  }
  .xs:w-1410 {
    width: 1410rem;
  }
  .xs:w-1411 {
    width: 1411rem;
  }
  .xs:w-1412 {
    width: 1412rem;
  }
  .xs:w-1413 {
    width: 1413rem;
  }
  .xs:w-1414 {
    width: 1414rem;
  }
  .xs:w-1415 {
    width: 1415rem;
  }
  .xs:w-1416 {
    width: 1416rem;
  }
  .xs:w-1417 {
    width: 1417rem;
  }
  .xs:w-1418 {
    width: 1418rem;
  }
  .xs:w-1419 {
    width: 1419rem;
  }
  .xs:w-1420 {
    width: 1420rem;
  }
  .xs:w-1421 {
    width: 1421rem;
  }
  .xs:w-1422 {
    width: 1422rem;
  }
  .xs:w-1423 {
    width: 1423rem;
  }
  .xs:w-1424 {
    width: 1424rem;
  }
  .xs:w-1425 {
    width: 1425rem;
  }
  .xs:w-1426 {
    width: 1426rem;
  }
  .xs:w-1427 {
    width: 1427rem;
  }
  .xs:w-1428 {
    width: 1428rem;
  }
  .xs:w-1429 {
    width: 1429rem;
  }
  .xs:w-1430 {
    width: 1430rem;
  }
  .xs:w-1431 {
    width: 1431rem;
  }
  .xs:w-1432 {
    width: 1432rem;
  }
  .xs:w-1433 {
    width: 1433rem;
  }
  .xs:w-1434 {
    width: 1434rem;
  }
  .xs:w-1435 {
    width: 1435rem;
  }
  .xs:w-1436 {
    width: 1436rem;
  }
  .xs:w-1437 {
    width: 1437rem;
  }
  .xs:w-1438 {
    width: 1438rem;
  }
  .xs:w-1439 {
    width: 1439rem;
  }
  .xs:w-1440 {
    width: 1440rem;
  }
  .xs:w-1441 {
    width: 1441rem;
  }
  .xs:w-1442 {
    width: 1442rem;
  }
  .xs:w-1443 {
    width: 1443rem;
  }
  .xs:w-1444 {
    width: 1444rem;
  }
  .xs:w-1445 {
    width: 1445rem;
  }
  .xs:w-1446 {
    width: 1446rem;
  }
  .xs:w-1447 {
    width: 1447rem;
  }
  .xs:w-1448 {
    width: 1448rem;
  }
  .xs:w-1449 {
    width: 1449rem;
  }
  .xs:w-1450 {
    width: 1450rem;
  }
  .xs:w-1451 {
    width: 1451rem;
  }
  .xs:w-1452 {
    width: 1452rem;
  }
  .xs:w-1453 {
    width: 1453rem;
  }
  .xs:w-1454 {
    width: 1454rem;
  }
  .xs:w-1455 {
    width: 1455rem;
  }
  .xs:w-1456 {
    width: 1456rem;
  }
  .xs:w-1457 {
    width: 1457rem;
  }
  .xs:w-1458 {
    width: 1458rem;
  }
  .xs:w-1459 {
    width: 1459rem;
  }
  .xs:w-1460 {
    width: 1460rem;
  }
  .xs:w-1461 {
    width: 1461rem;
  }
  .xs:w-1462 {
    width: 1462rem;
  }
  .xs:w-1463 {
    width: 1463rem;
  }
  .xs:w-1464 {
    width: 1464rem;
  }
  .xs:w-1465 {
    width: 1465rem;
  }
  .xs:w-1466 {
    width: 1466rem;
  }
  .xs:w-1467 {
    width: 1467rem;
  }
  .xs:w-1468 {
    width: 1468rem;
  }
  .xs:w-1469 {
    width: 1469rem;
  }
  .xs:w-1470 {
    width: 1470rem;
  }
  .xs:w-1471 {
    width: 1471rem;
  }
  .xs:w-1472 {
    width: 1472rem;
  }
  .xs:w-1473 {
    width: 1473rem;
  }
  .xs:w-1474 {
    width: 1474rem;
  }
  .xs:w-1475 {
    width: 1475rem;
  }
  .xs:w-1476 {
    width: 1476rem;
  }
  .xs:w-1477 {
    width: 1477rem;
  }
  .xs:w-1478 {
    width: 1478rem;
  }
  .xs:w-1479 {
    width: 1479rem;
  }
  .xs:w-1480 {
    width: 1480rem;
  }
  .xs:w-1481 {
    width: 1481rem;
  }
  .xs:w-1482 {
    width: 1482rem;
  }
  .xs:w-1483 {
    width: 1483rem;
  }
  .xs:w-1484 {
    width: 1484rem;
  }
  .xs:w-1485 {
    width: 1485rem;
  }
  .xs:w-1486 {
    width: 1486rem;
  }
  .xs:w-1487 {
    width: 1487rem;
  }
  .xs:w-1488 {
    width: 1488rem;
  }
  .xs:w-1489 {
    width: 1489rem;
  }
  .xs:w-1490 {
    width: 1490rem;
  }
  .xs:w-1491 {
    width: 1491rem;
  }
  .xs:w-1492 {
    width: 1492rem;
  }
  .xs:w-1493 {
    width: 1493rem;
  }
  .xs:w-1494 {
    width: 1494rem;
  }
  .xs:w-1495 {
    width: 1495rem;
  }
  .xs:w-1496 {
    width: 1496rem;
  }
  .xs:w-1497 {
    width: 1497rem;
  }
  .xs:w-1498 {
    width: 1498rem;
  }
  .xs:w-1499 {
    width: 1499rem;
  }
  .xs:w-1500 {
    width: 1500rem;
  }
  .xs:w-1501 {
    width: 1501rem;
  }
  .xs:w-1502 {
    width: 1502rem;
  }
  .xs:w-1503 {
    width: 1503rem;
  }
  .xs:w-1504 {
    width: 1504rem;
  }
  .xs:w-1505 {
    width: 1505rem;
  }
  .xs:w-1506 {
    width: 1506rem;
  }
  .xs:w-1507 {
    width: 1507rem;
  }
  .xs:w-1508 {
    width: 1508rem;
  }
  .xs:w-1509 {
    width: 1509rem;
  }
  .xs:w-1510 {
    width: 1510rem;
  }
  .xs:w-1511 {
    width: 1511rem;
  }
  .xs:w-1512 {
    width: 1512rem;
  }
  .xs:w-1513 {
    width: 1513rem;
  }
  .xs:w-1514 {
    width: 1514rem;
  }
  .xs:w-1515 {
    width: 1515rem;
  }
  .xs:w-1516 {
    width: 1516rem;
  }
  .xs:w-1517 {
    width: 1517rem;
  }
  .xs:w-1518 {
    width: 1518rem;
  }
  .xs:w-1519 {
    width: 1519rem;
  }
  .xs:w-1520 {
    width: 1520rem;
  }
  .xs:w-1521 {
    width: 1521rem;
  }
  .xs:w-1522 {
    width: 1522rem;
  }
  .xs:w-1523 {
    width: 1523rem;
  }
  .xs:w-1524 {
    width: 1524rem;
  }
  .xs:w-1525 {
    width: 1525rem;
  }
  .xs:w-1526 {
    width: 1526rem;
  }
  .xs:w-1527 {
    width: 1527rem;
  }
  .xs:w-1528 {
    width: 1528rem;
  }
  .xs:w-1529 {
    width: 1529rem;
  }
  .xs:w-1530 {
    width: 1530rem;
  }
  .xs:w-1531 {
    width: 1531rem;
  }
  .xs:w-1532 {
    width: 1532rem;
  }
  .xs:w-1533 {
    width: 1533rem;
  }
  .xs:w-1534 {
    width: 1534rem;
  }
  .xs:w-1535 {
    width: 1535rem;
  }
  .xs:w-1536 {
    width: 1536rem;
  }
  .xs:w-1537 {
    width: 1537rem;
  }
  .xs:w-1538 {
    width: 1538rem;
  }
  .xs:w-1539 {
    width: 1539rem;
  }
  .xs:w-1540 {
    width: 1540rem;
  }
  .xs:w-1541 {
    width: 1541rem;
  }
  .xs:w-1542 {
    width: 1542rem;
  }
  .xs:w-1543 {
    width: 1543rem;
  }
  .xs:w-1544 {
    width: 1544rem;
  }
  .xs:w-1545 {
    width: 1545rem;
  }
  .xs:w-1546 {
    width: 1546rem;
  }
  .xs:w-1547 {
    width: 1547rem;
  }
  .xs:w-1548 {
    width: 1548rem;
  }
  .xs:w-1549 {
    width: 1549rem;
  }
  .xs:w-1550 {
    width: 1550rem;
  }
  .xs:w-1551 {
    width: 1551rem;
  }
  .xs:w-1552 {
    width: 1552rem;
  }
  .xs:w-1553 {
    width: 1553rem;
  }
  .xs:w-1554 {
    width: 1554rem;
  }
  .xs:w-1555 {
    width: 1555rem;
  }
  .xs:w-1556 {
    width: 1556rem;
  }
  .xs:w-1557 {
    width: 1557rem;
  }
  .xs:w-1558 {
    width: 1558rem;
  }
  .xs:w-1559 {
    width: 1559rem;
  }
  .xs:w-1560 {
    width: 1560rem;
  }
  .xs:w-1561 {
    width: 1561rem;
  }
  .xs:w-1562 {
    width: 1562rem;
  }
  .xs:w-1563 {
    width: 1563rem;
  }
  .xs:w-1564 {
    width: 1564rem;
  }
  .xs:w-1565 {
    width: 1565rem;
  }
  .xs:w-1566 {
    width: 1566rem;
  }
  .xs:w-1567 {
    width: 1567rem;
  }
  .xs:w-1568 {
    width: 1568rem;
  }
  .xs:w-1569 {
    width: 1569rem;
  }
  .xs:w-1570 {
    width: 1570rem;
  }
  .xs:w-1571 {
    width: 1571rem;
  }
  .xs:w-1572 {
    width: 1572rem;
  }
  .xs:w-1573 {
    width: 1573rem;
  }
  .xs:w-1574 {
    width: 1574rem;
  }
  .xs:w-1575 {
    width: 1575rem;
  }
  .xs:w-1576 {
    width: 1576rem;
  }
  .xs:w-1577 {
    width: 1577rem;
  }
  .xs:w-1578 {
    width: 1578rem;
  }
  .xs:w-1579 {
    width: 1579rem;
  }
  .xs:w-1580 {
    width: 1580rem;
  }
  .xs:w-1581 {
    width: 1581rem;
  }
  .xs:w-1582 {
    width: 1582rem;
  }
  .xs:w-1583 {
    width: 1583rem;
  }
  .xs:w-1584 {
    width: 1584rem;
  }
  .xs:w-1585 {
    width: 1585rem;
  }
  .xs:w-1586 {
    width: 1586rem;
  }
  .xs:w-1587 {
    width: 1587rem;
  }
  .xs:w-1588 {
    width: 1588rem;
  }
  .xs:w-1589 {
    width: 1589rem;
  }
  .xs:w-1590 {
    width: 1590rem;
  }
  .xs:w-1591 {
    width: 1591rem;
  }
  .xs:w-1592 {
    width: 1592rem;
  }
  .xs:w-1593 {
    width: 1593rem;
  }
  .xs:w-1594 {
    width: 1594rem;
  }
  .xs:w-1595 {
    width: 1595rem;
  }
  .xs:w-1596 {
    width: 1596rem;
  }
  .xs:w-1597 {
    width: 1597rem;
  }
  .xs:w-1598 {
    width: 1598rem;
  }
  .xs:w-1599 {
    width: 1599rem;
  }
  .xs:w-1600 {
    width: 1600rem;
  }
  .xs:w-1601 {
    width: 1601rem;
  }
  .xs:w-1602 {
    width: 1602rem;
  }
  .xs:w-1603 {
    width: 1603rem;
  }
  .xs:w-1604 {
    width: 1604rem;
  }
  .xs:w-1605 {
    width: 1605rem;
  }
  .xs:w-1606 {
    width: 1606rem;
  }
  .xs:w-1607 {
    width: 1607rem;
  }
  .xs:w-1608 {
    width: 1608rem;
  }
  .xs:w-1609 {
    width: 1609rem;
  }
  .xs:w-1610 {
    width: 1610rem;
  }
  .xs:w-1611 {
    width: 1611rem;
  }
  .xs:w-1612 {
    width: 1612rem;
  }
  .xs:w-1613 {
    width: 1613rem;
  }
  .xs:w-1614 {
    width: 1614rem;
  }
  .xs:w-1615 {
    width: 1615rem;
  }
  .xs:w-1616 {
    width: 1616rem;
  }
  .xs:w-1617 {
    width: 1617rem;
  }
  .xs:w-1618 {
    width: 1618rem;
  }
  .xs:w-1619 {
    width: 1619rem;
  }
  .xs:w-1620 {
    width: 1620rem;
  }
  .xs:w-1621 {
    width: 1621rem;
  }
  .xs:w-1622 {
    width: 1622rem;
  }
  .xs:w-1623 {
    width: 1623rem;
  }
  .xs:w-1624 {
    width: 1624rem;
  }
  .xs:w-1625 {
    width: 1625rem;
  }
  .xs:w-1626 {
    width: 1626rem;
  }
  .xs:w-1627 {
    width: 1627rem;
  }
  .xs:w-1628 {
    width: 1628rem;
  }
  .xs:w-1629 {
    width: 1629rem;
  }
  .xs:w-1630 {
    width: 1630rem;
  }
  .xs:w-1631 {
    width: 1631rem;
  }
  .xs:w-1632 {
    width: 1632rem;
  }
  .xs:w-1633 {
    width: 1633rem;
  }
  .xs:w-1634 {
    width: 1634rem;
  }
  .xs:w-1635 {
    width: 1635rem;
  }
  .xs:w-1636 {
    width: 1636rem;
  }
  .xs:w-1637 {
    width: 1637rem;
  }
  .xs:w-1638 {
    width: 1638rem;
  }
  .xs:w-1639 {
    width: 1639rem;
  }
  .xs:w-1640 {
    width: 1640rem;
  }
  .xs:w-1641 {
    width: 1641rem;
  }
  .xs:w-1642 {
    width: 1642rem;
  }
  .xs:w-1643 {
    width: 1643rem;
  }
  .xs:w-1644 {
    width: 1644rem;
  }
  .xs:w-1645 {
    width: 1645rem;
  }
  .xs:w-1646 {
    width: 1646rem;
  }
  .xs:w-1647 {
    width: 1647rem;
  }
  .xs:w-1648 {
    width: 1648rem;
  }
  .xs:w-1649 {
    width: 1649rem;
  }
  .xs:w-1650 {
    width: 1650rem;
  }
  .xs:w-1651 {
    width: 1651rem;
  }
  .xs:w-1652 {
    width: 1652rem;
  }
  .xs:w-1653 {
    width: 1653rem;
  }
  .xs:w-1654 {
    width: 1654rem;
  }
  .xs:w-1655 {
    width: 1655rem;
  }
  .xs:w-1656 {
    width: 1656rem;
  }
  .xs:w-1657 {
    width: 1657rem;
  }
  .xs:w-1658 {
    width: 1658rem;
  }
  .xs:w-1659 {
    width: 1659rem;
  }
  .xs:w-1660 {
    width: 1660rem;
  }
  .xs:w-1661 {
    width: 1661rem;
  }
  .xs:w-1662 {
    width: 1662rem;
  }
  .xs:w-1663 {
    width: 1663rem;
  }
  .xs:w-1664 {
    width: 1664rem;
  }
  .xs:w-1665 {
    width: 1665rem;
  }
  .xs:w-1666 {
    width: 1666rem;
  }
  .xs:w-1667 {
    width: 1667rem;
  }
  .xs:w-1668 {
    width: 1668rem;
  }
  .xs:w-1669 {
    width: 1669rem;
  }
  .xs:w-1670 {
    width: 1670rem;
  }
  .xs:w-1671 {
    width: 1671rem;
  }
  .xs:w-1672 {
    width: 1672rem;
  }
  .xs:w-1673 {
    width: 1673rem;
  }
  .xs:w-1674 {
    width: 1674rem;
  }
  .xs:w-1675 {
    width: 1675rem;
  }
  .xs:w-1676 {
    width: 1676rem;
  }
  .xs:w-1677 {
    width: 1677rem;
  }
  .xs:w-1678 {
    width: 1678rem;
  }
  .xs:w-1679 {
    width: 1679rem;
  }
  .xs:w-1680 {
    width: 1680rem;
  }
  .xs:w-1681 {
    width: 1681rem;
  }
  .xs:w-1682 {
    width: 1682rem;
  }
  .xs:w-1683 {
    width: 1683rem;
  }
  .xs:w-1684 {
    width: 1684rem;
  }
  .xs:w-1685 {
    width: 1685rem;
  }
  .xs:w-1686 {
    width: 1686rem;
  }
  .xs:w-1687 {
    width: 1687rem;
  }
  .xs:w-1688 {
    width: 1688rem;
  }
  .xs:w-1689 {
    width: 1689rem;
  }
  .xs:w-1690 {
    width: 1690rem;
  }
  .xs:w-1691 {
    width: 1691rem;
  }
  .xs:w-1692 {
    width: 1692rem;
  }
  .xs:w-1693 {
    width: 1693rem;
  }
  .xs:w-1694 {
    width: 1694rem;
  }
  .xs:w-1695 {
    width: 1695rem;
  }
  .xs:w-1696 {
    width: 1696rem;
  }
  .xs:w-1697 {
    width: 1697rem;
  }
  .xs:w-1698 {
    width: 1698rem;
  }
  .xs:w-1699 {
    width: 1699rem;
  }
  .xs:w-1700 {
    width: 1700rem;
  }
  .xs:w-1701 {
    width: 1701rem;
  }
  .xs:w-1702 {
    width: 1702rem;
  }
  .xs:w-1703 {
    width: 1703rem;
  }
  .xs:w-1704 {
    width: 1704rem;
  }
  .xs:w-1705 {
    width: 1705rem;
  }
  .xs:w-1706 {
    width: 1706rem;
  }
  .xs:w-1707 {
    width: 1707rem;
  }
  .xs:w-1708 {
    width: 1708rem;
  }
  .xs:w-1709 {
    width: 1709rem;
  }
  .xs:w-1710 {
    width: 1710rem;
  }
  .xs:w-1711 {
    width: 1711rem;
  }
  .xs:w-1712 {
    width: 1712rem;
  }
  .xs:w-1713 {
    width: 1713rem;
  }
  .xs:w-1714 {
    width: 1714rem;
  }
  .xs:w-1715 {
    width: 1715rem;
  }
  .xs:w-1716 {
    width: 1716rem;
  }
  .xs:w-1717 {
    width: 1717rem;
  }
  .xs:w-1718 {
    width: 1718rem;
  }
  .xs:w-1719 {
    width: 1719rem;
  }
  .xs:w-1720 {
    width: 1720rem;
  }
  .xs:w-1721 {
    width: 1721rem;
  }
  .xs:w-1722 {
    width: 1722rem;
  }
  .xs:w-1723 {
    width: 1723rem;
  }
  .xs:w-1724 {
    width: 1724rem;
  }
  .xs:w-1725 {
    width: 1725rem;
  }
  .xs:w-1726 {
    width: 1726rem;
  }
  .xs:w-1727 {
    width: 1727rem;
  }
  .xs:w-1728 {
    width: 1728rem;
  }
  .xs:w-1729 {
    width: 1729rem;
  }
  .xs:w-1730 {
    width: 1730rem;
  }
  .xs:w-1731 {
    width: 1731rem;
  }
  .xs:w-1732 {
    width: 1732rem;
  }
  .xs:w-1733 {
    width: 1733rem;
  }
  .xs:w-1734 {
    width: 1734rem;
  }
  .xs:w-1735 {
    width: 1735rem;
  }
  .xs:w-1736 {
    width: 1736rem;
  }
  .xs:w-1737 {
    width: 1737rem;
  }
  .xs:w-1738 {
    width: 1738rem;
  }
  .xs:w-1739 {
    width: 1739rem;
  }
  .xs:w-1740 {
    width: 1740rem;
  }
  .xs:w-1741 {
    width: 1741rem;
  }
  .xs:w-1742 {
    width: 1742rem;
  }
  .xs:w-1743 {
    width: 1743rem;
  }
  .xs:w-1744 {
    width: 1744rem;
  }
  .xs:w-1745 {
    width: 1745rem;
  }
  .xs:w-1746 {
    width: 1746rem;
  }
  .xs:w-1747 {
    width: 1747rem;
  }
  .xs:w-1748 {
    width: 1748rem;
  }
  .xs:w-1749 {
    width: 1749rem;
  }
  .xs:w-1750 {
    width: 1750rem;
  }
  .xs:w-1751 {
    width: 1751rem;
  }
  .xs:w-1752 {
    width: 1752rem;
  }
  .xs:w-1753 {
    width: 1753rem;
  }
  .xs:w-1754 {
    width: 1754rem;
  }
  .xs:w-1755 {
    width: 1755rem;
  }
  .xs:w-1756 {
    width: 1756rem;
  }
  .xs:w-1757 {
    width: 1757rem;
  }
  .xs:w-1758 {
    width: 1758rem;
  }
  .xs:w-1759 {
    width: 1759rem;
  }
  .xs:w-1760 {
    width: 1760rem;
  }
  .xs:w-1761 {
    width: 1761rem;
  }
  .xs:w-1762 {
    width: 1762rem;
  }
  .xs:w-1763 {
    width: 1763rem;
  }
  .xs:w-1764 {
    width: 1764rem;
  }
  .xs:w-1765 {
    width: 1765rem;
  }
  .xs:w-1766 {
    width: 1766rem;
  }
  .xs:w-1767 {
    width: 1767rem;
  }
  .xs:w-1768 {
    width: 1768rem;
  }
  .xs:w-1769 {
    width: 1769rem;
  }
  .xs:w-1770 {
    width: 1770rem;
  }
  .xs:w-1771 {
    width: 1771rem;
  }
  .xs:w-1772 {
    width: 1772rem;
  }
  .xs:w-1773 {
    width: 1773rem;
  }
  .xs:w-1774 {
    width: 1774rem;
  }
  .xs:w-1775 {
    width: 1775rem;
  }
  .xs:w-1776 {
    width: 1776rem;
  }
  .xs:w-1777 {
    width: 1777rem;
  }
  .xs:w-1778 {
    width: 1778rem;
  }
  .xs:w-1779 {
    width: 1779rem;
  }
  .xs:w-1780 {
    width: 1780rem;
  }
  .xs:w-1781 {
    width: 1781rem;
  }
  .xs:w-1782 {
    width: 1782rem;
  }
  .xs:w-1783 {
    width: 1783rem;
  }
  .xs:w-1784 {
    width: 1784rem;
  }
  .xs:w-1785 {
    width: 1785rem;
  }
  .xs:w-1786 {
    width: 1786rem;
  }
  .xs:w-1787 {
    width: 1787rem;
  }
  .xs:w-1788 {
    width: 1788rem;
  }
  .xs:w-1789 {
    width: 1789rem;
  }
  .xs:w-1790 {
    width: 1790rem;
  }
  .xs:w-1791 {
    width: 1791rem;
  }
  .xs:w-1792 {
    width: 1792rem;
  }
  .xs:w-1793 {
    width: 1793rem;
  }
  .xs:w-1794 {
    width: 1794rem;
  }
  .xs:w-1795 {
    width: 1795rem;
  }
  .xs:w-1796 {
    width: 1796rem;
  }
  .xs:w-1797 {
    width: 1797rem;
  }
  .xs:w-1798 {
    width: 1798rem;
  }
  .xs:w-1799 {
    width: 1799rem;
  }
  .xs:w-1800 {
    width: 1800rem;
  }
  .xs:w-1801 {
    width: 1801rem;
  }
  .xs:w-1802 {
    width: 1802rem;
  }
  .xs:w-1803 {
    width: 1803rem;
  }
  .xs:w-1804 {
    width: 1804rem;
  }
  .xs:w-1805 {
    width: 1805rem;
  }
  .xs:w-1806 {
    width: 1806rem;
  }
  .xs:w-1807 {
    width: 1807rem;
  }
  .xs:w-1808 {
    width: 1808rem;
  }
  .xs:w-1809 {
    width: 1809rem;
  }
  .xs:w-1810 {
    width: 1810rem;
  }
  .xs:w-1811 {
    width: 1811rem;
  }
  .xs:w-1812 {
    width: 1812rem;
  }
  .xs:w-1813 {
    width: 1813rem;
  }
  .xs:w-1814 {
    width: 1814rem;
  }
  .xs:w-1815 {
    width: 1815rem;
  }
  .xs:w-1816 {
    width: 1816rem;
  }
  .xs:w-1817 {
    width: 1817rem;
  }
  .xs:w-1818 {
    width: 1818rem;
  }
  .xs:w-1819 {
    width: 1819rem;
  }
  .xs:w-1820 {
    width: 1820rem;
  }
  .xs:w-1821 {
    width: 1821rem;
  }
  .xs:w-1822 {
    width: 1822rem;
  }
  .xs:w-1823 {
    width: 1823rem;
  }
  .xs:w-1824 {
    width: 1824rem;
  }
  .xs:w-1825 {
    width: 1825rem;
  }
  .xs:w-1826 {
    width: 1826rem;
  }
  .xs:w-1827 {
    width: 1827rem;
  }
  .xs:w-1828 {
    width: 1828rem;
  }
  .xs:w-1829 {
    width: 1829rem;
  }
  .xs:w-1830 {
    width: 1830rem;
  }
  .xs:w-1831 {
    width: 1831rem;
  }
  .xs:w-1832 {
    width: 1832rem;
  }
  .xs:w-1833 {
    width: 1833rem;
  }
  .xs:w-1834 {
    width: 1834rem;
  }
  .xs:w-1835 {
    width: 1835rem;
  }
  .xs:w-1836 {
    width: 1836rem;
  }
  .xs:w-1837 {
    width: 1837rem;
  }
  .xs:w-1838 {
    width: 1838rem;
  }
  .xs:w-1839 {
    width: 1839rem;
  }
  .xs:w-1840 {
    width: 1840rem;
  }
  .xs:w-1841 {
    width: 1841rem;
  }
  .xs:w-1842 {
    width: 1842rem;
  }
  .xs:w-1843 {
    width: 1843rem;
  }
  .xs:w-1844 {
    width: 1844rem;
  }
  .xs:w-1845 {
    width: 1845rem;
  }
  .xs:w-1846 {
    width: 1846rem;
  }
  .xs:w-1847 {
    width: 1847rem;
  }
  .xs:w-1848 {
    width: 1848rem;
  }
  .xs:w-1849 {
    width: 1849rem;
  }
  .xs:w-1850 {
    width: 1850rem;
  }
  .xs:w-1851 {
    width: 1851rem;
  }
  .xs:w-1852 {
    width: 1852rem;
  }
  .xs:w-1853 {
    width: 1853rem;
  }
  .xs:w-1854 {
    width: 1854rem;
  }
  .xs:w-1855 {
    width: 1855rem;
  }
  .xs:w-1856 {
    width: 1856rem;
  }
  .xs:w-1857 {
    width: 1857rem;
  }
  .xs:w-1858 {
    width: 1858rem;
  }
  .xs:w-1859 {
    width: 1859rem;
  }
  .xs:w-1860 {
    width: 1860rem;
  }
  .xs:w-1861 {
    width: 1861rem;
  }
  .xs:w-1862 {
    width: 1862rem;
  }
  .xs:w-1863 {
    width: 1863rem;
  }
  .xs:w-1864 {
    width: 1864rem;
  }
  .xs:w-1865 {
    width: 1865rem;
  }
  .xs:w-1866 {
    width: 1866rem;
  }
  .xs:w-1867 {
    width: 1867rem;
  }
  .xs:w-1868 {
    width: 1868rem;
  }
  .xs:w-1869 {
    width: 1869rem;
  }
  .xs:w-1870 {
    width: 1870rem;
  }
  .xs:w-1871 {
    width: 1871rem;
  }
  .xs:w-1872 {
    width: 1872rem;
  }
  .xs:w-1873 {
    width: 1873rem;
  }
  .xs:w-1874 {
    width: 1874rem;
  }
  .xs:w-1875 {
    width: 1875rem;
  }
  .xs:w-1876 {
    width: 1876rem;
  }
  .xs:w-1877 {
    width: 1877rem;
  }
  .xs:w-1878 {
    width: 1878rem;
  }
  .xs:w-1879 {
    width: 1879rem;
  }
  .xs:w-1880 {
    width: 1880rem;
  }
  .xs:w-1881 {
    width: 1881rem;
  }
  .xs:w-1882 {
    width: 1882rem;
  }
  .xs:w-1883 {
    width: 1883rem;
  }
  .xs:w-1884 {
    width: 1884rem;
  }
  .xs:w-1885 {
    width: 1885rem;
  }
  .xs:w-1886 {
    width: 1886rem;
  }
  .xs:w-1887 {
    width: 1887rem;
  }
  .xs:w-1888 {
    width: 1888rem;
  }
  .xs:w-1889 {
    width: 1889rem;
  }
  .xs:w-1890 {
    width: 1890rem;
  }
  .xs:w-1891 {
    width: 1891rem;
  }
  .xs:w-1892 {
    width: 1892rem;
  }
  .xs:w-1893 {
    width: 1893rem;
  }
  .xs:w-1894 {
    width: 1894rem;
  }
  .xs:w-1895 {
    width: 1895rem;
  }
  .xs:w-1896 {
    width: 1896rem;
  }
  .xs:w-1897 {
    width: 1897rem;
  }
  .xs:w-1898 {
    width: 1898rem;
  }
  .xs:w-1899 {
    width: 1899rem;
  }
  .xs:w-1900 {
    width: 1900rem;
  }
  .xs:w-1901 {
    width: 1901rem;
  }
  .xs:w-1902 {
    width: 1902rem;
  }
  .xs:w-1903 {
    width: 1903rem;
  }
  .xs:w-1904 {
    width: 1904rem;
  }
  .xs:w-1905 {
    width: 1905rem;
  }
  .xs:w-1906 {
    width: 1906rem;
  }
  .xs:w-1907 {
    width: 1907rem;
  }
  .xs:w-1908 {
    width: 1908rem;
  }
  .xs:w-1909 {
    width: 1909rem;
  }
  .xs:w-1910 {
    width: 1910rem;
  }
  .xs:w-1911 {
    width: 1911rem;
  }
  .xs:w-1912 {
    width: 1912rem;
  }
  .xs:w-1913 {
    width: 1913rem;
  }
  .xs:w-1914 {
    width: 1914rem;
  }
  .xs:w-1915 {
    width: 1915rem;
  }
  .xs:w-1916 {
    width: 1916rem;
  }
  .xs:w-1917 {
    width: 1917rem;
  }
  .xs:w-1918 {
    width: 1918rem;
  }
  .xs:w-1919 {
    width: 1919rem;
  }
  .xs:w-1920 {
    width: 1920rem;
  }
  .xs:w-1921 {
    width: 1921rem;
  }
  .xs:w-1922 {
    width: 1922rem;
  }
  .xs:w-1923 {
    width: 1923rem;
  }
  .xs:w-1924 {
    width: 1924rem;
  }
  .xs:w-1925 {
    width: 1925rem;
  }
  .xs:w-1926 {
    width: 1926rem;
  }
  .xs:w-1927 {
    width: 1927rem;
  }
  .xs:w-1928 {
    width: 1928rem;
  }
  .xs:w-1929 {
    width: 1929rem;
  }
  .xs:w-1930 {
    width: 1930rem;
  }
  .xs:w-1931 {
    width: 1931rem;
  }
  .xs:w-1932 {
    width: 1932rem;
  }
  .xs:w-1933 {
    width: 1933rem;
  }
  .xs:w-1934 {
    width: 1934rem;
  }
  .xs:w-1935 {
    width: 1935rem;
  }
  .xs:w-1936 {
    width: 1936rem;
  }
  .xs:w-1937 {
    width: 1937rem;
  }
  .xs:w-1938 {
    width: 1938rem;
  }
  .xs:w-1939 {
    width: 1939rem;
  }
  .xs:w-1940 {
    width: 1940rem;
  }
  .xs:w-1941 {
    width: 1941rem;
  }
  .xs:w-1942 {
    width: 1942rem;
  }
  .xs:w-1943 {
    width: 1943rem;
  }
  .xs:w-1944 {
    width: 1944rem;
  }
  .xs:w-1945 {
    width: 1945rem;
  }
  .xs:w-1946 {
    width: 1946rem;
  }
  .xs:w-1947 {
    width: 1947rem;
  }
  .xs:w-1948 {
    width: 1948rem;
  }
  .xs:w-1949 {
    width: 1949rem;
  }
  .xs:w-1950 {
    width: 1950rem;
  }
  .xs:w-1951 {
    width: 1951rem;
  }
  .xs:w-1952 {
    width: 1952rem;
  }
  .xs:w-1953 {
    width: 1953rem;
  }
  .xs:w-1954 {
    width: 1954rem;
  }
  .xs:w-1955 {
    width: 1955rem;
  }
  .xs:w-1956 {
    width: 1956rem;
  }
  .xs:w-1957 {
    width: 1957rem;
  }
  .xs:w-1958 {
    width: 1958rem;
  }
  .xs:w-1959 {
    width: 1959rem;
  }
  .xs:w-1960 {
    width: 1960rem;
  }
  .xs:w-1961 {
    width: 1961rem;
  }
  .xs:w-1962 {
    width: 1962rem;
  }
  .xs:w-1963 {
    width: 1963rem;
  }
  .xs:w-1964 {
    width: 1964rem;
  }
  .xs:w-1965 {
    width: 1965rem;
  }
  .xs:w-1966 {
    width: 1966rem;
  }
  .xs:w-1967 {
    width: 1967rem;
  }
  .xs:w-1968 {
    width: 1968rem;
  }
  .xs:w-1969 {
    width: 1969rem;
  }
  .xs:w-1970 {
    width: 1970rem;
  }
  .xs:w-1971 {
    width: 1971rem;
  }
  .xs:w-1972 {
    width: 1972rem;
  }
  .xs:w-1973 {
    width: 1973rem;
  }
  .xs:w-1974 {
    width: 1974rem;
  }
  .xs:w-1975 {
    width: 1975rem;
  }
  .xs:w-1976 {
    width: 1976rem;
  }
  .xs:w-1977 {
    width: 1977rem;
  }
  .xs:w-1978 {
    width: 1978rem;
  }
  .xs:w-1979 {
    width: 1979rem;
  }
  .xs:w-1980 {
    width: 1980rem;
  }
  .xs:w-1981 {
    width: 1981rem;
  }
  .xs:w-1982 {
    width: 1982rem;
  }
  .xs:w-1983 {
    width: 1983rem;
  }
  .xs:w-1984 {
    width: 1984rem;
  }
  .xs:w-1985 {
    width: 1985rem;
  }
  .xs:w-1986 {
    width: 1986rem;
  }
  .xs:w-1987 {
    width: 1987rem;
  }
  .xs:w-1988 {
    width: 1988rem;
  }
  .xs:w-1989 {
    width: 1989rem;
  }
  .xs:w-1990 {
    width: 1990rem;
  }
  .xs:w-1991 {
    width: 1991rem;
  }
  .xs:w-1992 {
    width: 1992rem;
  }
  .xs:w-1993 {
    width: 1993rem;
  }
  .xs:w-1994 {
    width: 1994rem;
  }
  .xs:w-1995 {
    width: 1995rem;
  }
  .xs:w-1996 {
    width: 1996rem;
  }
  .xs:w-1997 {
    width: 1997rem;
  }
  .xs:w-1998 {
    width: 1998rem;
  }
  .xs:w-1999 {
    width: 1999rem;
  }
  .curved {
    border-radius: 1rem;
  }
  .curved-top {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
  .rounded {
    border-radius: 50%;
  }
  .xs:wp-0 {
    width: 0%;
  }
  .xs:wp-1 {
    width: 1%;
  }
  .xs:wp-2 {
    width: 2%;
  }
  .xs:wp-3 {
    width: 3%;
  }
  .xs:wp-4 {
    width: 4%;
  }
  .xs:wp-5 {
    width: 5%;
  }
  .xs:wp-6 {
    width: 6%;
  }
  .xs:wp-7 {
    width: 7%;
  }
  .xs:wp-8 {
    width: 8%;
  }
  .xs:wp-9 {
    width: 9%;
  }
  .xs:wp-10 {
    width: 10%;
  }
  .xs:wp-11 {
    width: 11%;
  }
  .xs:wp-12 {
    width: 12%;
  }
  .xs:wp-13 {
    width: 13%;
  }
  .xs:wp-14 {
    width: 14%;
  }
  .xs:wp-15 {
    width: 15%;
  }
  .xs:wp-16 {
    width: 16%;
  }
  .xs:wp-17 {
    width: 17%;
  }
  .xs:wp-18 {
    width: 18%;
  }
  .xs:wp-19 {
    width: 19%;
  }
  .xs:wp-20 {
    width: 20%;
  }
  .xs:wp-21 {
    width: 21%;
  }
  .xs:wp-22 {
    width: 22%;
  }
  .xs:wp-23 {
    width: 23%;
  }
  .xs:wp-24 {
    width: 24%;
  }
  .xs:wp-25 {
    width: 25%;
  }
  .xs:wp-26 {
    width: 26%;
  }
  .xs:wp-27 {
    width: 27%;
  }
  .xs:wp-28 {
    width: 28%;
  }
  .xs:wp-29 {
    width: 29%;
  }
  .xs:wp-30 {
    width: 30%;
  }
  .xs:wp-31 {
    width: 31%;
  }
  .xs:wp-32 {
    width: 32%;
  }
  .xs:wp-33 {
    width: 33%;
  }
  .xs:wp-34 {
    width: 34%;
  }
  .xs:wp-35 {
    width: 35%;
  }
  .xs:wp-36 {
    width: 36%;
  }
  .xs:wp-37 {
    width: 37%;
  }
  .xs:wp-38 {
    width: 38%;
  }
  .xs:wp-39 {
    width: 39%;
  }
  .xs:wp-40 {
    width: 40%;
  }
  .xs:wp-41 {
    width: 41%;
  }
  .xs:wp-42 {
    width: 42%;
  }
  .xs:wp-43 {
    width: 43%;
  }
  .xs:wp-44 {
    width: 44%;
  }
  .xs:wp-45 {
    width: 45%;
  }
  .xs:wp-46 {
    width: 46%;
  }
  .xs:wp-47 {
    width: 47%;
  }
  .xs:wp-48 {
    width: 48%;
  }
  .xs:wp-49 {
    width: 49%;
  }
  .xs:wp-50 {
    width: 50%;
  }
  .xs:wp-51 {
    width: 51%;
  }
  .xs:wp-52 {
    width: 52%;
  }
  .xs:wp-53 {
    width: 53%;
  }
  .xs:wp-54 {
    width: 54%;
  }
  .xs:wp-55 {
    width: 55%;
  }
  .xs:wp-56 {
    width: 56%;
  }
  .xs:wp-57 {
    width: 57%;
  }
  .xs:wp-58 {
    width: 58%;
  }
  .xs:wp-59 {
    width: 59%;
  }
  .xs:wp-60 {
    width: 60%;
  }
  .xs:wp-61 {
    width: 61%;
  }
  .xs:wp-62 {
    width: 62%;
  }
  .xs:wp-63 {
    width: 63%;
  }
  .xs:wp-64 {
    width: 64%;
  }
  .xs:wp-65 {
    width: 65%;
  }
  .xs:wp-66 {
    width: 66%;
  }
  .xs:wp-67 {
    width: 67%;
  }
  .xs:wp-68 {
    width: 68%;
  }
  .xs:wp-69 {
    width: 69%;
  }
  .xs:wp-70 {
    width: 70%;
  }
  .xs:wp-71 {
    width: 71%;
  }
  .xs:wp-72 {
    width: 72%;
  }
  .xs:wp-73 {
    width: 73%;
  }
  .xs:wp-74 {
    width: 74%;
  }
  .xs:wp-75 {
    width: 75%;
  }
  .xs:wp-76 {
    width: 76%;
  }
  .xs:wp-77 {
    width: 77%;
  }
  .xs:wp-78 {
    width: 78%;
  }
  .xs:wp-79 {
    width: 79%;
  }
  .xs:wp-80 {
    width: 80%;
  }
  .xs:wp-81 {
    width: 81%;
  }
  .xs:wp-82 {
    width: 82%;
  }
  .xs:wp-83 {
    width: 83%;
  }
  .xs:wp-84 {
    width: 84%;
  }
  .xs:wp-85 {
    width: 85%;
  }
  .xs:wp-86 {
    width: 86%;
  }
  .xs:wp-87 {
    width: 87%;
  }
  .xs:wp-88 {
    width: 88%;
  }
  .xs:wp-89 {
    width: 89%;
  }
  .xs:wp-90 {
    width: 90%;
  }
  .xs:wp-91 {
    width: 91%;
  }
  .xs:wp-92 {
    width: 92%;
  }
  .xs:wp-93 {
    width: 93%;
  }
  .xs:wp-94 {
    width: 94%;
  }
  .xs:wp-95 {
    width: 95%;
  }
  .xs:wp-96 {
    width: 96%;
  }
  .xs:wp-97 {
    width: 97%;
  }
  .xs:wp-98 {
    width: 98%;
  }
  .xs:wp-99 {
    width: 99%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }
  .cursor-scroll {
    cursor: scroll;
  }
  .font-thin {
    font-weight: thin;
  }
  .font-normal {
    font-weight: normal;
  }
  .font-lighter {
    font-weight: lighter;
  }
  .font-light {
    font-weight: light;
  }
  .font-bold {
    font-weight: bold;
  }
  .font-bolder {
    font-weight: bolder;
  }
  .font-100 {
    font-weight: 100;
  }
  .font-200 {
    font-weight: 200;
  }
  .font-300 {
    font-weight: 300;
  }
  .font-400 {
    font-weight: 400;
  }
  .font-500 {
    font-weight: 500;
  }
  .font-600 {
    font-weight: 600;
  }
  .font-700 {
    font-weight: 700;
  }
  .font-800 {
    font-weight: 800;
  }
  .font-900 {
    font-weight: 900;
  }
  .shadow-xs {
    box-shadow: 0px 3px 4px -5px rgba(51, 51, 51, 0.03),
      0px 1px 2px rgba(51, 51, 51, 0.05);
  }
  .shadow-s {
    box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
      0px 0px 2px rgba(51, 51, 51, 0.02);
  }
  .shadow-a {
    box-shadow: 0px 32px 41px -23px rgba(51, 51, 51, 0.07),
      0px 2px 6px rgba(51, 51, 51, 0.06);
  }
}
@media (min-width: 480px) {
  .sm:p-0 {
    padding: 0rem;
  }
  .sm:p-1 {
    padding: 1rem;
  }
  .sm:p-2 {
    padding: 2rem;
  }
  .sm:p-3 {
    padding: 3rem;
  }
  .sm:p-4 {
    padding: 4rem;
  }
  .sm:p-5 {
    padding: 5rem;
  }
  .sm:p-6 {
    padding: 6rem;
  }
  .sm:p-7 {
    padding: 7rem;
  }
  .sm:p-8 {
    padding: 8rem;
  }
  .sm:p-9 {
    padding: 9rem;
  }
  .sm:p-10 {
    padding: 10rem;
  }
  .sm:p-11 {
    padding: 11rem;
  }
  .sm:p-12 {
    padding: 12rem;
  }
  .sm:p-13 {
    padding: 13rem;
  }
  .sm:p-14 {
    padding: 14rem;
  }
  .sm:p-15 {
    padding: 15rem;
  }
  .sm:p-16 {
    padding: 16rem;
  }
  .sm:p-17 {
    padding: 17rem;
  }
  .sm:p-18 {
    padding: 18rem;
  }
  .sm:p-19 {
    padding: 19rem;
  }
  .sm:p-20 {
    padding: 20rem;
  }
  .sm:p-21 {
    padding: 21rem;
  }
  .sm:p-22 {
    padding: 22rem;
  }
  .sm:p-23 {
    padding: 23rem;
  }
  .sm:p-24 {
    padding: 24rem;
  }
  .sm:p-25 {
    padding: 25rem;
  }
  .sm:p-26 {
    padding: 26rem;
  }
  .sm:p-27 {
    padding: 27rem;
  }
  .sm:p-28 {
    padding: 28rem;
  }
  .sm:p-29 {
    padding: 29rem;
  }
  .sm:p-30 {
    padding: 30rem;
  }
  .sm:p-31 {
    padding: 31rem;
  }
  .sm:p-32 {
    padding: 32rem;
  }
  .sm:p-33 {
    padding: 33rem;
  }
  .sm:p-34 {
    padding: 34rem;
  }
  .sm:p-35 {
    padding: 35rem;
  }
  .sm:p-36 {
    padding: 36rem;
  }
  .sm:p-37 {
    padding: 37rem;
  }
  .sm:p-38 {
    padding: 38rem;
  }
  .sm:p-39 {
    padding: 39rem;
  }
  .sm:p-40 {
    padding: 40rem;
  }
  .sm:p-41 {
    padding: 41rem;
  }
  .sm:p-42 {
    padding: 42rem;
  }
  .sm:p-43 {
    padding: 43rem;
  }
  .sm:p-44 {
    padding: 44rem;
  }
  .sm:p-45 {
    padding: 45rem;
  }
  .sm:p-46 {
    padding: 46rem;
  }
  .sm:p-47 {
    padding: 47rem;
  }
  .sm:p-48 {
    padding: 48rem;
  }
  .sm:p-49 {
    padding: 49rem;
  }
  .sm:p-50 {
    padding: 50rem;
  }
  .sm:p-51 {
    padding: 51rem;
  }
  .sm:p-52 {
    padding: 52rem;
  }
  .sm:p-53 {
    padding: 53rem;
  }
  .sm:p-54 {
    padding: 54rem;
  }
  .sm:p-55 {
    padding: 55rem;
  }
  .sm:p-56 {
    padding: 56rem;
  }
  .sm:p-57 {
    padding: 57rem;
  }
  .sm:p-58 {
    padding: 58rem;
  }
  .sm:p-59 {
    padding: 59rem;
  }
  .sm:p-60 {
    padding: 60rem;
  }
  .sm:p-61 {
    padding: 61rem;
  }
  .sm:p-62 {
    padding: 62rem;
  }
  .sm:p-63 {
    padding: 63rem;
  }
  .sm:p-64 {
    padding: 64rem;
  }
  .sm:p-65 {
    padding: 65rem;
  }
  .sm:p-66 {
    padding: 66rem;
  }
  .sm:p-67 {
    padding: 67rem;
  }
  .sm:p-68 {
    padding: 68rem;
  }
  .sm:p-69 {
    padding: 69rem;
  }
  .sm:p-70 {
    padding: 70rem;
  }
  .sm:p-71 {
    padding: 71rem;
  }
  .sm:p-72 {
    padding: 72rem;
  }
  .sm:p-0-0 {
    padding: 0rem;
  }
  .sm:p-0-1 {
    padding: 0.1rem;
  }
  .sm:p-0-2 {
    padding: 0.2rem;
  }
  .sm:p-0-3 {
    padding: 0.3rem;
  }
  .sm:p-0-4 {
    padding: 0.4rem;
  }
  .sm:p-0-5 {
    padding: 0.5rem;
  }
  .sm:p-0-6 {
    padding: 0.6rem;
  }
  .sm:p-0-7 {
    padding: 0.7rem;
  }
  .sm:p-0-8 {
    padding: 0.8rem;
  }
  .sm:p-0-9 {
    padding: 0.9rem;
  }
  .sm:p-1-0 {
    padding: 1rem;
  }
  .sm:p-1-1 {
    padding: 1.1rem;
  }
  .sm:p-1-2 {
    padding: 1.2rem;
  }
  .sm:p-1-3 {
    padding: 1.3rem;
  }
  .sm:p-1-4 {
    padding: 1.4rem;
  }
  .sm:p-1-5 {
    padding: 1.5rem;
  }
  .sm:p-1-6 {
    padding: 1.6rem;
  }
  .sm:p-1-7 {
    padding: 1.7rem;
  }
  .sm:p-1-8 {
    padding: 1.8rem;
  }
  .sm:p-1-9 {
    padding: 1.9rem;
  }
  .sm:p-2-0 {
    padding: 2rem;
  }
  .sm:p-2-1 {
    padding: 2.1rem;
  }
  .sm:p-2-2 {
    padding: 2.2rem;
  }
  .sm:p-2-3 {
    padding: 2.3rem;
  }
  .sm:p-2-4 {
    padding: 2.4rem;
  }
  .sm:p-2-5 {
    padding: 2.5rem;
  }
  .sm:p-2-6 {
    padding: 2.6rem;
  }
  .sm:p-2-7 {
    padding: 2.7rem;
  }
  .sm:p-2-8 {
    padding: 2.8rem;
  }
  .sm:p-2-9 {
    padding: 2.9rem;
  }
  .sm:p-3-0 {
    padding: 3rem;
  }
  .sm:p-3-1 {
    padding: 3.1rem;
  }
  .sm:p-3-2 {
    padding: 3.2rem;
  }
  .sm:p-3-3 {
    padding: 3.3rem;
  }
  .sm:p-3-4 {
    padding: 3.4rem;
  }
  .sm:p-3-5 {
    padding: 3.5rem;
  }
  .sm:p-3-6 {
    padding: 3.6rem;
  }
  .sm:p-3-7 {
    padding: 3.7rem;
  }
  .sm:p-3-8 {
    padding: 3.8rem;
  }
  .sm:p-3-9 {
    padding: 3.9rem;
  }
  .sm:p-4-0 {
    padding: 4rem;
  }
  .sm:p-4-1 {
    padding: 4.1rem;
  }
  .sm:p-4-2 {
    padding: 4.2rem;
  }
  .sm:p-4-3 {
    padding: 4.3rem;
  }
  .sm:p-4-4 {
    padding: 4.4rem;
  }
  .sm:p-4-5 {
    padding: 4.5rem;
  }
  .sm:p-4-6 {
    padding: 4.6rem;
  }
  .sm:p-4-7 {
    padding: 4.7rem;
  }
  .sm:p-4-8 {
    padding: 4.8rem;
  }
  .sm:p-4-9 {
    padding: 4.9rem;
  }
  .sm:p-5-0 {
    padding: 5rem;
  }
  .sm:p-5-1 {
    padding: 5.1rem;
  }
  .sm:p-5-2 {
    padding: 5.2rem;
  }
  .sm:p-5-3 {
    padding: 5.3rem;
  }
  .sm:p-5-4 {
    padding: 5.4rem;
  }
  .sm:p-5-5 {
    padding: 5.5rem;
  }
  .sm:p-5-6 {
    padding: 5.6rem;
  }
  .sm:p-5-7 {
    padding: 5.7rem;
  }
  .sm:p-5-8 {
    padding: 5.8rem;
  }
  .sm:p-5-9 {
    padding: 5.9rem;
  }
  .sm:p-6-0 {
    padding: 6rem;
  }
  .sm:p-6-1 {
    padding: 6.1rem;
  }
  .sm:p-6-2 {
    padding: 6.2rem;
  }
  .sm:p-6-3 {
    padding: 6.3rem;
  }
  .sm:p-6-4 {
    padding: 6.4rem;
  }
  .sm:p-6-5 {
    padding: 6.5rem;
  }
  .sm:p-6-6 {
    padding: 6.6rem;
  }
  .sm:p-6-7 {
    padding: 6.7rem;
  }
  .sm:p-6-8 {
    padding: 6.8rem;
  }
  .sm:p-6-9 {
    padding: 6.9rem;
  }
  .sm:p-7-0 {
    padding: 7rem;
  }
  .sm:p-7-1 {
    padding: 7.1rem;
  }
  .sm:p-7-2 {
    padding: 7.2rem;
  }
  .sm:p-7-3 {
    padding: 7.3rem;
  }
  .sm:p-7-4 {
    padding: 7.4rem;
  }
  .sm:p-7-5 {
    padding: 7.5rem;
  }
  .sm:p-7-6 {
    padding: 7.6rem;
  }
  .sm:p-7-7 {
    padding: 7.7rem;
  }
  .sm:p-7-8 {
    padding: 7.8rem;
  }
  .sm:p-7-9 {
    padding: 7.9rem;
  }
  .sm:p-8-0 {
    padding: 8rem;
  }
  .sm:p-8-1 {
    padding: 8.1rem;
  }
  .sm:p-8-2 {
    padding: 8.2rem;
  }
  .sm:p-8-3 {
    padding: 8.3rem;
  }
  .sm:p-8-4 {
    padding: 8.4rem;
  }
  .sm:p-8-5 {
    padding: 8.5rem;
  }
  .sm:p-8-6 {
    padding: 8.6rem;
  }
  .sm:p-8-7 {
    padding: 8.7rem;
  }
  .sm:p-8-8 {
    padding: 8.8rem;
  }
  .sm:p-8-9 {
    padding: 8.9rem;
  }
  .sm:p-9-0 {
    padding: 9rem;
  }
  .sm:p-9-1 {
    padding: 9.1rem;
  }
  .sm:p-9-2 {
    padding: 9.2rem;
  }
  .sm:p-9-3 {
    padding: 9.3rem;
  }
  .sm:p-9-4 {
    padding: 9.4rem;
  }
  .sm:p-9-5 {
    padding: 9.5rem;
  }
  .sm:p-9-6 {
    padding: 9.6rem;
  }
  .sm:p-9-7 {
    padding: 9.7rem;
  }
  .sm:p-9-8 {
    padding: 9.8rem;
  }
  .sm:p-9-9 {
    padding: 9.9rem;
  }
  .sm:p-10-0 {
    padding: 10rem;
  }
  .sm:p-10-1 {
    padding: 10.1rem;
  }
  .sm:p-10-2 {
    padding: 10.2rem;
  }
  .sm:p-10-3 {
    padding: 10.3rem;
  }
  .sm:p-10-4 {
    padding: 10.4rem;
  }
  .sm:p-10-5 {
    padding: 10.5rem;
  }
  .sm:p-10-6 {
    padding: 10.6rem;
  }
  .sm:p-10-7 {
    padding: 10.7rem;
  }
  .sm:p-10-8 {
    padding: 10.8rem;
  }
  .sm:p-10-9 {
    padding: 10.9rem;
  }
  .sm:p-11-0 {
    padding: 11rem;
  }
  .sm:p-11-1 {
    padding: 11.1rem;
  }
  .sm:p-11-2 {
    padding: 11.2rem;
  }
  .sm:p-11-3 {
    padding: 11.3rem;
  }
  .sm:p-11-4 {
    padding: 11.4rem;
  }
  .sm:p-11-5 {
    padding: 11.5rem;
  }
  .sm:p-11-6 {
    padding: 11.6rem;
  }
  .sm:p-11-7 {
    padding: 11.7rem;
  }
  .sm:p-11-8 {
    padding: 11.8rem;
  }
  .sm:p-11-9 {
    padding: 11.9rem;
  }
  .sm:p-12-0 {
    padding: 12rem;
  }
  .sm:p-12-1 {
    padding: 12.1rem;
  }
  .sm:p-12-2 {
    padding: 12.2rem;
  }
  .sm:p-12-3 {
    padding: 12.3rem;
  }
  .sm:p-12-4 {
    padding: 12.4rem;
  }
  .sm:p-12-5 {
    padding: 12.5rem;
  }
  .sm:p-12-6 {
    padding: 12.6rem;
  }
  .sm:p-12-7 {
    padding: 12.7rem;
  }
  .sm:p-12-8 {
    padding: 12.8rem;
  }
  .sm:p-12-9 {
    padding: 12.9rem;
  }
  .sm:p-13-0 {
    padding: 13rem;
  }
  .sm:p-13-1 {
    padding: 13.1rem;
  }
  .sm:p-13-2 {
    padding: 13.2rem;
  }
  .sm:p-13-3 {
    padding: 13.3rem;
  }
  .sm:p-13-4 {
    padding: 13.4rem;
  }
  .sm:p-13-5 {
    padding: 13.5rem;
  }
  .sm:p-13-6 {
    padding: 13.6rem;
  }
  .sm:p-13-7 {
    padding: 13.7rem;
  }
  .sm:p-13-8 {
    padding: 13.8rem;
  }
  .sm:p-13-9 {
    padding: 13.9rem;
  }
  .sm:p-14-0 {
    padding: 14rem;
  }
  .sm:p-14-1 {
    padding: 14.1rem;
  }
  .sm:p-14-2 {
    padding: 14.2rem;
  }
  .sm:p-14-3 {
    padding: 14.3rem;
  }
  .sm:p-14-4 {
    padding: 14.4rem;
  }
  .sm:p-14-5 {
    padding: 14.5rem;
  }
  .sm:p-14-6 {
    padding: 14.6rem;
  }
  .sm:p-14-7 {
    padding: 14.7rem;
  }
  .sm:p-14-8 {
    padding: 14.8rem;
  }
  .sm:p-14-9 {
    padding: 14.9rem;
  }
  .sm:p-15-0 {
    padding: 15rem;
  }
  .sm:p-15-1 {
    padding: 15.1rem;
  }
  .sm:p-15-2 {
    padding: 15.2rem;
  }
  .sm:p-15-3 {
    padding: 15.3rem;
  }
  .sm:p-15-4 {
    padding: 15.4rem;
  }
  .sm:p-15-5 {
    padding: 15.5rem;
  }
  .sm:p-15-6 {
    padding: 15.6rem;
  }
  .sm:p-15-7 {
    padding: 15.7rem;
  }
  .sm:p-15-8 {
    padding: 15.8rem;
  }
  .sm:p-15-9 {
    padding: 15.9rem;
  }
  .sm:p-16-0 {
    padding: 16rem;
  }
  .sm:p-16-1 {
    padding: 16.1rem;
  }
  .sm:p-16-2 {
    padding: 16.2rem;
  }
  .sm:p-16-3 {
    padding: 16.3rem;
  }
  .sm:p-16-4 {
    padding: 16.4rem;
  }
  .sm:p-16-5 {
    padding: 16.5rem;
  }
  .sm:p-16-6 {
    padding: 16.6rem;
  }
  .sm:p-16-7 {
    padding: 16.7rem;
  }
  .sm:p-16-8 {
    padding: 16.8rem;
  }
  .sm:p-16-9 {
    padding: 16.9rem;
  }
  .sm:p-17-0 {
    padding: 17rem;
  }
  .sm:p-17-1 {
    padding: 17.1rem;
  }
  .sm:p-17-2 {
    padding: 17.2rem;
  }
  .sm:p-17-3 {
    padding: 17.3rem;
  }
  .sm:p-17-4 {
    padding: 17.4rem;
  }
  .sm:p-17-5 {
    padding: 17.5rem;
  }
  .sm:p-17-6 {
    padding: 17.6rem;
  }
  .sm:p-17-7 {
    padding: 17.7rem;
  }
  .sm:p-17-8 {
    padding: 17.8rem;
  }
  .sm:p-17-9 {
    padding: 17.9rem;
  }
  .sm:p-18-0 {
    padding: 18rem;
  }
  .sm:p-18-1 {
    padding: 18.1rem;
  }
  .sm:p-18-2 {
    padding: 18.2rem;
  }
  .sm:p-18-3 {
    padding: 18.3rem;
  }
  .sm:p-18-4 {
    padding: 18.4rem;
  }
  .sm:p-18-5 {
    padding: 18.5rem;
  }
  .sm:p-18-6 {
    padding: 18.6rem;
  }
  .sm:p-18-7 {
    padding: 18.7rem;
  }
  .sm:p-18-8 {
    padding: 18.8rem;
  }
  .sm:p-18-9 {
    padding: 18.9rem;
  }
  .sm:p-19-0 {
    padding: 19rem;
  }
  .sm:p-19-1 {
    padding: 19.1rem;
  }
  .sm:p-19-2 {
    padding: 19.2rem;
  }
  .sm:p-19-3 {
    padding: 19.3rem;
  }
  .sm:p-19-4 {
    padding: 19.4rem;
  }
  .sm:p-19-5 {
    padding: 19.5rem;
  }
  .sm:p-19-6 {
    padding: 19.6rem;
  }
  .sm:p-19-7 {
    padding: 19.7rem;
  }
  .sm:p-19-8 {
    padding: 19.8rem;
  }
  .sm:p-19-9 {
    padding: 19.9rem;
  }
  .sm:p-20-0 {
    padding: 20rem;
  }
  .sm:p-20-1 {
    padding: 20.1rem;
  }
  .sm:p-20-2 {
    padding: 20.2rem;
  }
  .sm:p-20-3 {
    padding: 20.3rem;
  }
  .sm:p-20-4 {
    padding: 20.4rem;
  }
  .sm:p-20-5 {
    padding: 20.5rem;
  }
  .sm:p-20-6 {
    padding: 20.6rem;
  }
  .sm:p-20-7 {
    padding: 20.7rem;
  }
  .sm:p-20-8 {
    padding: 20.8rem;
  }
  .sm:p-20-9 {
    padding: 20.9rem;
  }
  .sm:p-21-0 {
    padding: 21rem;
  }
  .sm:p-21-1 {
    padding: 21.1rem;
  }
  .sm:p-21-2 {
    padding: 21.2rem;
  }
  .sm:p-21-3 {
    padding: 21.3rem;
  }
  .sm:p-21-4 {
    padding: 21.4rem;
  }
  .sm:p-21-5 {
    padding: 21.5rem;
  }
  .sm:p-21-6 {
    padding: 21.6rem;
  }
  .sm:p-21-7 {
    padding: 21.7rem;
  }
  .sm:p-21-8 {
    padding: 21.8rem;
  }
  .sm:p-21-9 {
    padding: 21.9rem;
  }
  .sm:p-22-0 {
    padding: 22rem;
  }
  .sm:p-22-1 {
    padding: 22.1rem;
  }
  .sm:p-22-2 {
    padding: 22.2rem;
  }
  .sm:p-22-3 {
    padding: 22.3rem;
  }
  .sm:p-22-4 {
    padding: 22.4rem;
  }
  .sm:p-22-5 {
    padding: 22.5rem;
  }
  .sm:p-22-6 {
    padding: 22.6rem;
  }
  .sm:p-22-7 {
    padding: 22.7rem;
  }
  .sm:p-22-8 {
    padding: 22.8rem;
  }
  .sm:p-22-9 {
    padding: 22.9rem;
  }
  .sm:p-23-0 {
    padding: 23rem;
  }
  .sm:p-23-1 {
    padding: 23.1rem;
  }
  .sm:p-23-2 {
    padding: 23.2rem;
  }
  .sm:p-23-3 {
    padding: 23.3rem;
  }
  .sm:p-23-4 {
    padding: 23.4rem;
  }
  .sm:p-23-5 {
    padding: 23.5rem;
  }
  .sm:p-23-6 {
    padding: 23.6rem;
  }
  .sm:p-23-7 {
    padding: 23.7rem;
  }
  .sm:p-23-8 {
    padding: 23.8rem;
  }
  .sm:p-23-9 {
    padding: 23.9rem;
  }
  .sm:p-24-0 {
    padding: 24rem;
  }
  .sm:p-24-1 {
    padding: 24.1rem;
  }
  .sm:p-24-2 {
    padding: 24.2rem;
  }
  .sm:p-24-3 {
    padding: 24.3rem;
  }
  .sm:p-24-4 {
    padding: 24.4rem;
  }
  .sm:p-24-5 {
    padding: 24.5rem;
  }
  .sm:p-24-6 {
    padding: 24.6rem;
  }
  .sm:p-24-7 {
    padding: 24.7rem;
  }
  .sm:p-24-8 {
    padding: 24.8rem;
  }
  .sm:p-24-9 {
    padding: 24.9rem;
  }
  .sm:p-25-0 {
    padding: 25rem;
  }
  .sm:p-25-1 {
    padding: 25.1rem;
  }
  .sm:p-25-2 {
    padding: 25.2rem;
  }
  .sm:p-25-3 {
    padding: 25.3rem;
  }
  .sm:p-25-4 {
    padding: 25.4rem;
  }
  .sm:p-25-5 {
    padding: 25.5rem;
  }
  .sm:p-25-6 {
    padding: 25.6rem;
  }
  .sm:p-25-7 {
    padding: 25.7rem;
  }
  .sm:p-25-8 {
    padding: 25.8rem;
  }
  .sm:p-25-9 {
    padding: 25.9rem;
  }
  .sm:p-26-0 {
    padding: 26rem;
  }
  .sm:p-26-1 {
    padding: 26.1rem;
  }
  .sm:p-26-2 {
    padding: 26.2rem;
  }
  .sm:p-26-3 {
    padding: 26.3rem;
  }
  .sm:p-26-4 {
    padding: 26.4rem;
  }
  .sm:p-26-5 {
    padding: 26.5rem;
  }
  .sm:p-26-6 {
    padding: 26.6rem;
  }
  .sm:p-26-7 {
    padding: 26.7rem;
  }
  .sm:p-26-8 {
    padding: 26.8rem;
  }
  .sm:p-26-9 {
    padding: 26.9rem;
  }
  .sm:p-27-0 {
    padding: 27rem;
  }
  .sm:p-27-1 {
    padding: 27.1rem;
  }
  .sm:p-27-2 {
    padding: 27.2rem;
  }
  .sm:p-27-3 {
    padding: 27.3rem;
  }
  .sm:p-27-4 {
    padding: 27.4rem;
  }
  .sm:p-27-5 {
    padding: 27.5rem;
  }
  .sm:p-27-6 {
    padding: 27.6rem;
  }
  .sm:p-27-7 {
    padding: 27.7rem;
  }
  .sm:p-27-8 {
    padding: 27.8rem;
  }
  .sm:p-27-9 {
    padding: 27.9rem;
  }
  .sm:p-28-0 {
    padding: 28rem;
  }
  .sm:p-28-1 {
    padding: 28.1rem;
  }
  .sm:p-28-2 {
    padding: 28.2rem;
  }
  .sm:p-28-3 {
    padding: 28.3rem;
  }
  .sm:p-28-4 {
    padding: 28.4rem;
  }
  .sm:p-28-5 {
    padding: 28.5rem;
  }
  .sm:p-28-6 {
    padding: 28.6rem;
  }
  .sm:p-28-7 {
    padding: 28.7rem;
  }
  .sm:p-28-8 {
    padding: 28.8rem;
  }
  .sm:p-28-9 {
    padding: 28.9rem;
  }
  .sm:p-29-0 {
    padding: 29rem;
  }
  .sm:p-29-1 {
    padding: 29.1rem;
  }
  .sm:p-29-2 {
    padding: 29.2rem;
  }
  .sm:p-29-3 {
    padding: 29.3rem;
  }
  .sm:p-29-4 {
    padding: 29.4rem;
  }
  .sm:p-29-5 {
    padding: 29.5rem;
  }
  .sm:p-29-6 {
    padding: 29.6rem;
  }
  .sm:p-29-7 {
    padding: 29.7rem;
  }
  .sm:p-29-8 {
    padding: 29.8rem;
  }
  .sm:p-29-9 {
    padding: 29.9rem;
  }
  .sm:p-30-0 {
    padding: 30rem;
  }
  .sm:p-30-1 {
    padding: 30.1rem;
  }
  .sm:p-30-2 {
    padding: 30.2rem;
  }
  .sm:p-30-3 {
    padding: 30.3rem;
  }
  .sm:p-30-4 {
    padding: 30.4rem;
  }
  .sm:p-30-5 {
    padding: 30.5rem;
  }
  .sm:p-30-6 {
    padding: 30.6rem;
  }
  .sm:p-30-7 {
    padding: 30.7rem;
  }
  .sm:p-30-8 {
    padding: 30.8rem;
  }
  .sm:p-30-9 {
    padding: 30.9rem;
  }
  .sm:p-31-0 {
    padding: 31rem;
  }
  .sm:p-31-1 {
    padding: 31.1rem;
  }
  .sm:p-31-2 {
    padding: 31.2rem;
  }
  .sm:p-31-3 {
    padding: 31.3rem;
  }
  .sm:p-31-4 {
    padding: 31.4rem;
  }
  .sm:p-31-5 {
    padding: 31.5rem;
  }
  .sm:p-31-6 {
    padding: 31.6rem;
  }
  .sm:p-31-7 {
    padding: 31.7rem;
  }
  .sm:p-31-8 {
    padding: 31.8rem;
  }
  .sm:p-31-9 {
    padding: 31.9rem;
  }
  .sm:p-32-0 {
    padding: 32rem;
  }
  .sm:p-32-1 {
    padding: 32.1rem;
  }
  .sm:p-32-2 {
    padding: 32.2rem;
  }
  .sm:p-32-3 {
    padding: 32.3rem;
  }
  .sm:p-32-4 {
    padding: 32.4rem;
  }
  .sm:p-32-5 {
    padding: 32.5rem;
  }
  .sm:p-32-6 {
    padding: 32.6rem;
  }
  .sm:p-32-7 {
    padding: 32.7rem;
  }
  .sm:p-32-8 {
    padding: 32.8rem;
  }
  .sm:p-32-9 {
    padding: 32.9rem;
  }
  .sm:p-33-0 {
    padding: 33rem;
  }
  .sm:p-33-1 {
    padding: 33.1rem;
  }
  .sm:p-33-2 {
    padding: 33.2rem;
  }
  .sm:p-33-3 {
    padding: 33.3rem;
  }
  .sm:p-33-4 {
    padding: 33.4rem;
  }
  .sm:p-33-5 {
    padding: 33.5rem;
  }
  .sm:p-33-6 {
    padding: 33.6rem;
  }
  .sm:p-33-7 {
    padding: 33.7rem;
  }
  .sm:p-33-8 {
    padding: 33.8rem;
  }
  .sm:p-33-9 {
    padding: 33.9rem;
  }
  .sm:p-34-0 {
    padding: 34rem;
  }
  .sm:p-34-1 {
    padding: 34.1rem;
  }
  .sm:p-34-2 {
    padding: 34.2rem;
  }
  .sm:p-34-3 {
    padding: 34.3rem;
  }
  .sm:p-34-4 {
    padding: 34.4rem;
  }
  .sm:p-34-5 {
    padding: 34.5rem;
  }
  .sm:p-34-6 {
    padding: 34.6rem;
  }
  .sm:p-34-7 {
    padding: 34.7rem;
  }
  .sm:p-34-8 {
    padding: 34.8rem;
  }
  .sm:p-34-9 {
    padding: 34.9rem;
  }
  .sm:p-35-0 {
    padding: 35rem;
  }
  .sm:p-35-1 {
    padding: 35.1rem;
  }
  .sm:p-35-2 {
    padding: 35.2rem;
  }
  .sm:p-35-3 {
    padding: 35.3rem;
  }
  .sm:p-35-4 {
    padding: 35.4rem;
  }
  .sm:p-35-5 {
    padding: 35.5rem;
  }
  .sm:p-35-6 {
    padding: 35.6rem;
  }
  .sm:p-35-7 {
    padding: 35.7rem;
  }
  .sm:p-35-8 {
    padding: 35.8rem;
  }
  .sm:p-35-9 {
    padding: 35.9rem;
  }
  .sm:p-36-0 {
    padding: 36rem;
  }
  .sm:p-36-1 {
    padding: 36.1rem;
  }
  .sm:p-36-2 {
    padding: 36.2rem;
  }
  .sm:p-36-3 {
    padding: 36.3rem;
  }
  .sm:p-36-4 {
    padding: 36.4rem;
  }
  .sm:p-36-5 {
    padding: 36.5rem;
  }
  .sm:p-36-6 {
    padding: 36.6rem;
  }
  .sm:p-36-7 {
    padding: 36.7rem;
  }
  .sm:p-36-8 {
    padding: 36.8rem;
  }
  .sm:p-36-9 {
    padding: 36.9rem;
  }
  .sm:p-37-0 {
    padding: 37rem;
  }
  .sm:p-37-1 {
    padding: 37.1rem;
  }
  .sm:p-37-2 {
    padding: 37.2rem;
  }
  .sm:p-37-3 {
    padding: 37.3rem;
  }
  .sm:p-37-4 {
    padding: 37.4rem;
  }
  .sm:p-37-5 {
    padding: 37.5rem;
  }
  .sm:p-37-6 {
    padding: 37.6rem;
  }
  .sm:p-37-7 {
    padding: 37.7rem;
  }
  .sm:p-37-8 {
    padding: 37.8rem;
  }
  .sm:p-37-9 {
    padding: 37.9rem;
  }
  .sm:p-38-0 {
    padding: 38rem;
  }
  .sm:p-38-1 {
    padding: 38.1rem;
  }
  .sm:p-38-2 {
    padding: 38.2rem;
  }
  .sm:p-38-3 {
    padding: 38.3rem;
  }
  .sm:p-38-4 {
    padding: 38.4rem;
  }
  .sm:p-38-5 {
    padding: 38.5rem;
  }
  .sm:p-38-6 {
    padding: 38.6rem;
  }
  .sm:p-38-7 {
    padding: 38.7rem;
  }
  .sm:p-38-8 {
    padding: 38.8rem;
  }
  .sm:p-38-9 {
    padding: 38.9rem;
  }
  .sm:p-39-0 {
    padding: 39rem;
  }
  .sm:p-39-1 {
    padding: 39.1rem;
  }
  .sm:p-39-2 {
    padding: 39.2rem;
  }
  .sm:p-39-3 {
    padding: 39.3rem;
  }
  .sm:p-39-4 {
    padding: 39.4rem;
  }
  .sm:p-39-5 {
    padding: 39.5rem;
  }
  .sm:p-39-6 {
    padding: 39.6rem;
  }
  .sm:p-39-7 {
    padding: 39.7rem;
  }
  .sm:p-39-8 {
    padding: 39.8rem;
  }
  .sm:p-39-9 {
    padding: 39.9rem;
  }
  .sm:p-40-0 {
    padding: 40rem;
  }
  .sm:p-40-1 {
    padding: 40.1rem;
  }
  .sm:p-40-2 {
    padding: 40.2rem;
  }
  .sm:p-40-3 {
    padding: 40.3rem;
  }
  .sm:p-40-4 {
    padding: 40.4rem;
  }
  .sm:p-40-5 {
    padding: 40.5rem;
  }
  .sm:p-40-6 {
    padding: 40.6rem;
  }
  .sm:p-40-7 {
    padding: 40.7rem;
  }
  .sm:p-40-8 {
    padding: 40.8rem;
  }
  .sm:p-40-9 {
    padding: 40.9rem;
  }
  .sm:p-41-0 {
    padding: 41rem;
  }
  .sm:p-41-1 {
    padding: 41.1rem;
  }
  .sm:p-41-2 {
    padding: 41.2rem;
  }
  .sm:p-41-3 {
    padding: 41.3rem;
  }
  .sm:p-41-4 {
    padding: 41.4rem;
  }
  .sm:p-41-5 {
    padding: 41.5rem;
  }
  .sm:p-41-6 {
    padding: 41.6rem;
  }
  .sm:p-41-7 {
    padding: 41.7rem;
  }
  .sm:p-41-8 {
    padding: 41.8rem;
  }
  .sm:p-41-9 {
    padding: 41.9rem;
  }
  .sm:p-42-0 {
    padding: 42rem;
  }
  .sm:p-42-1 {
    padding: 42.1rem;
  }
  .sm:p-42-2 {
    padding: 42.2rem;
  }
  .sm:p-42-3 {
    padding: 42.3rem;
  }
  .sm:p-42-4 {
    padding: 42.4rem;
  }
  .sm:p-42-5 {
    padding: 42.5rem;
  }
  .sm:p-42-6 {
    padding: 42.6rem;
  }
  .sm:p-42-7 {
    padding: 42.7rem;
  }
  .sm:p-42-8 {
    padding: 42.8rem;
  }
  .sm:p-42-9 {
    padding: 42.9rem;
  }
  .sm:p-43-0 {
    padding: 43rem;
  }
  .sm:p-43-1 {
    padding: 43.1rem;
  }
  .sm:p-43-2 {
    padding: 43.2rem;
  }
  .sm:p-43-3 {
    padding: 43.3rem;
  }
  .sm:p-43-4 {
    padding: 43.4rem;
  }
  .sm:p-43-5 {
    padding: 43.5rem;
  }
  .sm:p-43-6 {
    padding: 43.6rem;
  }
  .sm:p-43-7 {
    padding: 43.7rem;
  }
  .sm:p-43-8 {
    padding: 43.8rem;
  }
  .sm:p-43-9 {
    padding: 43.9rem;
  }
  .sm:p-44-0 {
    padding: 44rem;
  }
  .sm:p-44-1 {
    padding: 44.1rem;
  }
  .sm:p-44-2 {
    padding: 44.2rem;
  }
  .sm:p-44-3 {
    padding: 44.3rem;
  }
  .sm:p-44-4 {
    padding: 44.4rem;
  }
  .sm:p-44-5 {
    padding: 44.5rem;
  }
  .sm:p-44-6 {
    padding: 44.6rem;
  }
  .sm:p-44-7 {
    padding: 44.7rem;
  }
  .sm:p-44-8 {
    padding: 44.8rem;
  }
  .sm:p-44-9 {
    padding: 44.9rem;
  }
  .sm:p-45-0 {
    padding: 45rem;
  }
  .sm:p-45-1 {
    padding: 45.1rem;
  }
  .sm:p-45-2 {
    padding: 45.2rem;
  }
  .sm:p-45-3 {
    padding: 45.3rem;
  }
  .sm:p-45-4 {
    padding: 45.4rem;
  }
  .sm:p-45-5 {
    padding: 45.5rem;
  }
  .sm:p-45-6 {
    padding: 45.6rem;
  }
  .sm:p-45-7 {
    padding: 45.7rem;
  }
  .sm:p-45-8 {
    padding: 45.8rem;
  }
  .sm:p-45-9 {
    padding: 45.9rem;
  }
  .sm:p-46-0 {
    padding: 46rem;
  }
  .sm:p-46-1 {
    padding: 46.1rem;
  }
  .sm:p-46-2 {
    padding: 46.2rem;
  }
  .sm:p-46-3 {
    padding: 46.3rem;
  }
  .sm:p-46-4 {
    padding: 46.4rem;
  }
  .sm:p-46-5 {
    padding: 46.5rem;
  }
  .sm:p-46-6 {
    padding: 46.6rem;
  }
  .sm:p-46-7 {
    padding: 46.7rem;
  }
  .sm:p-46-8 {
    padding: 46.8rem;
  }
  .sm:p-46-9 {
    padding: 46.9rem;
  }
  .sm:p-47-0 {
    padding: 47rem;
  }
  .sm:p-47-1 {
    padding: 47.1rem;
  }
  .sm:p-47-2 {
    padding: 47.2rem;
  }
  .sm:p-47-3 {
    padding: 47.3rem;
  }
  .sm:p-47-4 {
    padding: 47.4rem;
  }
  .sm:p-47-5 {
    padding: 47.5rem;
  }
  .sm:p-47-6 {
    padding: 47.6rem;
  }
  .sm:p-47-7 {
    padding: 47.7rem;
  }
  .sm:p-47-8 {
    padding: 47.8rem;
  }
  .sm:p-47-9 {
    padding: 47.9rem;
  }
  .sm:p-48-0 {
    padding: 48rem;
  }
  .sm:p-48-1 {
    padding: 48.1rem;
  }
  .sm:p-48-2 {
    padding: 48.2rem;
  }
  .sm:p-48-3 {
    padding: 48.3rem;
  }
  .sm:p-48-4 {
    padding: 48.4rem;
  }
  .sm:p-48-5 {
    padding: 48.5rem;
  }
  .sm:p-48-6 {
    padding: 48.6rem;
  }
  .sm:p-48-7 {
    padding: 48.7rem;
  }
  .sm:p-48-8 {
    padding: 48.8rem;
  }
  .sm:p-48-9 {
    padding: 48.9rem;
  }
  .sm:p-49-0 {
    padding: 49rem;
  }
  .sm:p-49-1 {
    padding: 49.1rem;
  }
  .sm:p-49-2 {
    padding: 49.2rem;
  }
  .sm:p-49-3 {
    padding: 49.3rem;
  }
  .sm:p-49-4 {
    padding: 49.4rem;
  }
  .sm:p-49-5 {
    padding: 49.5rem;
  }
  .sm:p-49-6 {
    padding: 49.6rem;
  }
  .sm:p-49-7 {
    padding: 49.7rem;
  }
  .sm:p-49-8 {
    padding: 49.8rem;
  }
  .sm:p-49-9 {
    padding: 49.9rem;
  }
  .sm:p-50-0 {
    padding: 50rem;
  }
  .sm:p-50-1 {
    padding: 50.1rem;
  }
  .sm:p-50-2 {
    padding: 50.2rem;
  }
  .sm:p-50-3 {
    padding: 50.3rem;
  }
  .sm:p-50-4 {
    padding: 50.4rem;
  }
  .sm:p-50-5 {
    padding: 50.5rem;
  }
  .sm:p-50-6 {
    padding: 50.6rem;
  }
  .sm:p-50-7 {
    padding: 50.7rem;
  }
  .sm:p-50-8 {
    padding: 50.8rem;
  }
  .sm:p-50-9 {
    padding: 50.9rem;
  }
  .sm:p-51-0 {
    padding: 51rem;
  }
  .sm:p-51-1 {
    padding: 51.1rem;
  }
  .sm:p-51-2 {
    padding: 51.2rem;
  }
  .sm:p-51-3 {
    padding: 51.3rem;
  }
  .sm:p-51-4 {
    padding: 51.4rem;
  }
  .sm:p-51-5 {
    padding: 51.5rem;
  }
  .sm:p-51-6 {
    padding: 51.6rem;
  }
  .sm:p-51-7 {
    padding: 51.7rem;
  }
  .sm:p-51-8 {
    padding: 51.8rem;
  }
  .sm:p-51-9 {
    padding: 51.9rem;
  }
  .sm:p-52-0 {
    padding: 52rem;
  }
  .sm:p-52-1 {
    padding: 52.1rem;
  }
  .sm:p-52-2 {
    padding: 52.2rem;
  }
  .sm:p-52-3 {
    padding: 52.3rem;
  }
  .sm:p-52-4 {
    padding: 52.4rem;
  }
  .sm:p-52-5 {
    padding: 52.5rem;
  }
  .sm:p-52-6 {
    padding: 52.6rem;
  }
  .sm:p-52-7 {
    padding: 52.7rem;
  }
  .sm:p-52-8 {
    padding: 52.8rem;
  }
  .sm:p-52-9 {
    padding: 52.9rem;
  }
  .sm:p-53-0 {
    padding: 53rem;
  }
  .sm:p-53-1 {
    padding: 53.1rem;
  }
  .sm:p-53-2 {
    padding: 53.2rem;
  }
  .sm:p-53-3 {
    padding: 53.3rem;
  }
  .sm:p-53-4 {
    padding: 53.4rem;
  }
  .sm:p-53-5 {
    padding: 53.5rem;
  }
  .sm:p-53-6 {
    padding: 53.6rem;
  }
  .sm:p-53-7 {
    padding: 53.7rem;
  }
  .sm:p-53-8 {
    padding: 53.8rem;
  }
  .sm:p-53-9 {
    padding: 53.9rem;
  }
  .sm:p-54-0 {
    padding: 54rem;
  }
  .sm:p-54-1 {
    padding: 54.1rem;
  }
  .sm:p-54-2 {
    padding: 54.2rem;
  }
  .sm:p-54-3 {
    padding: 54.3rem;
  }
  .sm:p-54-4 {
    padding: 54.4rem;
  }
  .sm:p-54-5 {
    padding: 54.5rem;
  }
  .sm:p-54-6 {
    padding: 54.6rem;
  }
  .sm:p-54-7 {
    padding: 54.7rem;
  }
  .sm:p-54-8 {
    padding: 54.8rem;
  }
  .sm:p-54-9 {
    padding: 54.9rem;
  }
  .sm:p-55-0 {
    padding: 55rem;
  }
  .sm:p-55-1 {
    padding: 55.1rem;
  }
  .sm:p-55-2 {
    padding: 55.2rem;
  }
  .sm:p-55-3 {
    padding: 55.3rem;
  }
  .sm:p-55-4 {
    padding: 55.4rem;
  }
  .sm:p-55-5 {
    padding: 55.5rem;
  }
  .sm:p-55-6 {
    padding: 55.6rem;
  }
  .sm:p-55-7 {
    padding: 55.7rem;
  }
  .sm:p-55-8 {
    padding: 55.8rem;
  }
  .sm:p-55-9 {
    padding: 55.9rem;
  }
  .sm:p-56-0 {
    padding: 56rem;
  }
  .sm:p-56-1 {
    padding: 56.1rem;
  }
  .sm:p-56-2 {
    padding: 56.2rem;
  }
  .sm:p-56-3 {
    padding: 56.3rem;
  }
  .sm:p-56-4 {
    padding: 56.4rem;
  }
  .sm:p-56-5 {
    padding: 56.5rem;
  }
  .sm:p-56-6 {
    padding: 56.6rem;
  }
  .sm:p-56-7 {
    padding: 56.7rem;
  }
  .sm:p-56-8 {
    padding: 56.8rem;
  }
  .sm:p-56-9 {
    padding: 56.9rem;
  }
  .sm:p-57-0 {
    padding: 57rem;
  }
  .sm:p-57-1 {
    padding: 57.1rem;
  }
  .sm:p-57-2 {
    padding: 57.2rem;
  }
  .sm:p-57-3 {
    padding: 57.3rem;
  }
  .sm:p-57-4 {
    padding: 57.4rem;
  }
  .sm:p-57-5 {
    padding: 57.5rem;
  }
  .sm:p-57-6 {
    padding: 57.6rem;
  }
  .sm:p-57-7 {
    padding: 57.7rem;
  }
  .sm:p-57-8 {
    padding: 57.8rem;
  }
  .sm:p-57-9 {
    padding: 57.9rem;
  }
  .sm:p-58-0 {
    padding: 58rem;
  }
  .sm:p-58-1 {
    padding: 58.1rem;
  }
  .sm:p-58-2 {
    padding: 58.2rem;
  }
  .sm:p-58-3 {
    padding: 58.3rem;
  }
  .sm:p-58-4 {
    padding: 58.4rem;
  }
  .sm:p-58-5 {
    padding: 58.5rem;
  }
  .sm:p-58-6 {
    padding: 58.6rem;
  }
  .sm:p-58-7 {
    padding: 58.7rem;
  }
  .sm:p-58-8 {
    padding: 58.8rem;
  }
  .sm:p-58-9 {
    padding: 58.9rem;
  }
  .sm:p-59-0 {
    padding: 59rem;
  }
  .sm:p-59-1 {
    padding: 59.1rem;
  }
  .sm:p-59-2 {
    padding: 59.2rem;
  }
  .sm:p-59-3 {
    padding: 59.3rem;
  }
  .sm:p-59-4 {
    padding: 59.4rem;
  }
  .sm:p-59-5 {
    padding: 59.5rem;
  }
  .sm:p-59-6 {
    padding: 59.6rem;
  }
  .sm:p-59-7 {
    padding: 59.7rem;
  }
  .sm:p-59-8 {
    padding: 59.8rem;
  }
  .sm:p-59-9 {
    padding: 59.9rem;
  }
  .sm:p-60-0 {
    padding: 60rem;
  }
  .sm:p-60-1 {
    padding: 60.1rem;
  }
  .sm:p-60-2 {
    padding: 60.2rem;
  }
  .sm:p-60-3 {
    padding: 60.3rem;
  }
  .sm:p-60-4 {
    padding: 60.4rem;
  }
  .sm:p-60-5 {
    padding: 60.5rem;
  }
  .sm:p-60-6 {
    padding: 60.6rem;
  }
  .sm:p-60-7 {
    padding: 60.7rem;
  }
  .sm:p-60-8 {
    padding: 60.8rem;
  }
  .sm:p-60-9 {
    padding: 60.9rem;
  }
  .sm:p-61-0 {
    padding: 61rem;
  }
  .sm:p-61-1 {
    padding: 61.1rem;
  }
  .sm:p-61-2 {
    padding: 61.2rem;
  }
  .sm:p-61-3 {
    padding: 61.3rem;
  }
  .sm:p-61-4 {
    padding: 61.4rem;
  }
  .sm:p-61-5 {
    padding: 61.5rem;
  }
  .sm:p-61-6 {
    padding: 61.6rem;
  }
  .sm:p-61-7 {
    padding: 61.7rem;
  }
  .sm:p-61-8 {
    padding: 61.8rem;
  }
  .sm:p-61-9 {
    padding: 61.9rem;
  }
  .sm:p-62-0 {
    padding: 62rem;
  }
  .sm:p-62-1 {
    padding: 62.1rem;
  }
  .sm:p-62-2 {
    padding: 62.2rem;
  }
  .sm:p-62-3 {
    padding: 62.3rem;
  }
  .sm:p-62-4 {
    padding: 62.4rem;
  }
  .sm:p-62-5 {
    padding: 62.5rem;
  }
  .sm:p-62-6 {
    padding: 62.6rem;
  }
  .sm:p-62-7 {
    padding: 62.7rem;
  }
  .sm:p-62-8 {
    padding: 62.8rem;
  }
  .sm:p-62-9 {
    padding: 62.9rem;
  }
  .sm:p-63-0 {
    padding: 63rem;
  }
  .sm:p-63-1 {
    padding: 63.1rem;
  }
  .sm:p-63-2 {
    padding: 63.2rem;
  }
  .sm:p-63-3 {
    padding: 63.3rem;
  }
  .sm:p-63-4 {
    padding: 63.4rem;
  }
  .sm:p-63-5 {
    padding: 63.5rem;
  }
  .sm:p-63-6 {
    padding: 63.6rem;
  }
  .sm:p-63-7 {
    padding: 63.7rem;
  }
  .sm:p-63-8 {
    padding: 63.8rem;
  }
  .sm:p-63-9 {
    padding: 63.9rem;
  }
  .sm:p-64-0 {
    padding: 64rem;
  }
  .sm:p-64-1 {
    padding: 64.1rem;
  }
  .sm:p-64-2 {
    padding: 64.2rem;
  }
  .sm:p-64-3 {
    padding: 64.3rem;
  }
  .sm:p-64-4 {
    padding: 64.4rem;
  }
  .sm:p-64-5 {
    padding: 64.5rem;
  }
  .sm:p-64-6 {
    padding: 64.6rem;
  }
  .sm:p-64-7 {
    padding: 64.7rem;
  }
  .sm:p-64-8 {
    padding: 64.8rem;
  }
  .sm:p-64-9 {
    padding: 64.9rem;
  }
  .sm:p-65-0 {
    padding: 65rem;
  }
  .sm:p-65-1 {
    padding: 65.1rem;
  }
  .sm:p-65-2 {
    padding: 65.2rem;
  }
  .sm:p-65-3 {
    padding: 65.3rem;
  }
  .sm:p-65-4 {
    padding: 65.4rem;
  }
  .sm:p-65-5 {
    padding: 65.5rem;
  }
  .sm:p-65-6 {
    padding: 65.6rem;
  }
  .sm:p-65-7 {
    padding: 65.7rem;
  }
  .sm:p-65-8 {
    padding: 65.8rem;
  }
  .sm:p-65-9 {
    padding: 65.9rem;
  }
  .sm:p-66-0 {
    padding: 66rem;
  }
  .sm:p-66-1 {
    padding: 66.1rem;
  }
  .sm:p-66-2 {
    padding: 66.2rem;
  }
  .sm:p-66-3 {
    padding: 66.3rem;
  }
  .sm:p-66-4 {
    padding: 66.4rem;
  }
  .sm:p-66-5 {
    padding: 66.5rem;
  }
  .sm:p-66-6 {
    padding: 66.6rem;
  }
  .sm:p-66-7 {
    padding: 66.7rem;
  }
  .sm:p-66-8 {
    padding: 66.8rem;
  }
  .sm:p-66-9 {
    padding: 66.9rem;
  }
  .sm:p-67-0 {
    padding: 67rem;
  }
  .sm:p-67-1 {
    padding: 67.1rem;
  }
  .sm:p-67-2 {
    padding: 67.2rem;
  }
  .sm:p-67-3 {
    padding: 67.3rem;
  }
  .sm:p-67-4 {
    padding: 67.4rem;
  }
  .sm:p-67-5 {
    padding: 67.5rem;
  }
  .sm:p-67-6 {
    padding: 67.6rem;
  }
  .sm:p-67-7 {
    padding: 67.7rem;
  }
  .sm:p-67-8 {
    padding: 67.8rem;
  }
  .sm:p-67-9 {
    padding: 67.9rem;
  }
  .sm:p-68-0 {
    padding: 68rem;
  }
  .sm:p-68-1 {
    padding: 68.1rem;
  }
  .sm:p-68-2 {
    padding: 68.2rem;
  }
  .sm:p-68-3 {
    padding: 68.3rem;
  }
  .sm:p-68-4 {
    padding: 68.4rem;
  }
  .sm:p-68-5 {
    padding: 68.5rem;
  }
  .sm:p-68-6 {
    padding: 68.6rem;
  }
  .sm:p-68-7 {
    padding: 68.7rem;
  }
  .sm:p-68-8 {
    padding: 68.8rem;
  }
  .sm:p-68-9 {
    padding: 68.9rem;
  }
  .sm:p-69-0 {
    padding: 69rem;
  }
  .sm:p-69-1 {
    padding: 69.1rem;
  }
  .sm:p-69-2 {
    padding: 69.2rem;
  }
  .sm:p-69-3 {
    padding: 69.3rem;
  }
  .sm:p-69-4 {
    padding: 69.4rem;
  }
  .sm:p-69-5 {
    padding: 69.5rem;
  }
  .sm:p-69-6 {
    padding: 69.6rem;
  }
  .sm:p-69-7 {
    padding: 69.7rem;
  }
  .sm:p-69-8 {
    padding: 69.8rem;
  }
  .sm:p-69-9 {
    padding: 69.9rem;
  }
  .sm:p-70-0 {
    padding: 70rem;
  }
  .sm:p-70-1 {
    padding: 70.1rem;
  }
  .sm:p-70-2 {
    padding: 70.2rem;
  }
  .sm:p-70-3 {
    padding: 70.3rem;
  }
  .sm:p-70-4 {
    padding: 70.4rem;
  }
  .sm:p-70-5 {
    padding: 70.5rem;
  }
  .sm:p-70-6 {
    padding: 70.6rem;
  }
  .sm:p-70-7 {
    padding: 70.7rem;
  }
  .sm:p-70-8 {
    padding: 70.8rem;
  }
  .sm:p-70-9 {
    padding: 70.9rem;
  }
  .sm:p-71-0 {
    padding: 71rem;
  }
  .sm:p-71-1 {
    padding: 71.1rem;
  }
  .sm:p-71-2 {
    padding: 71.2rem;
  }
  .sm:p-71-3 {
    padding: 71.3rem;
  }
  .sm:p-71-4 {
    padding: 71.4rem;
  }
  .sm:p-71-5 {
    padding: 71.5rem;
  }
  .sm:p-71-6 {
    padding: 71.6rem;
  }
  .sm:p-71-7 {
    padding: 71.7rem;
  }
  .sm:p-71-8 {
    padding: 71.8rem;
  }
  .sm:p-71-9 {
    padding: 71.9rem;
  }
  .sm:p-72-0 {
    padding: 72rem;
  }
  .sm:p-72-1 {
    padding: 72.1rem;
  }
  .sm:p-72-2 {
    padding: 72.2rem;
  }
  .sm:p-72-3 {
    padding: 72.3rem;
  }
  .sm:p-72-4 {
    padding: 72.4rem;
  }
  .sm:p-72-5 {
    padding: 72.5rem;
  }
  .sm:p-72-6 {
    padding: 72.6rem;
  }
  .sm:p-72-7 {
    padding: 72.7rem;
  }
  .sm:p-72-8 {
    padding: 72.8rem;
  }
  .sm:p-72-9 {
    padding: 72.9rem;
  }
  .sm:p-0 {
    padding: 0;
  }
  .sm:p-1 {
    padding: 1rem;
  }
  .sm:p-2 {
    padding: 2rem;
  }
  .sm:p-3 {
    padding: 4rem;
  }
  .sm:p-4 {
    padding: 6rem;
  }
  .sm:p-5 {
    padding: 8rem;
  }
  .sm:pl-0 {
    padding-left: 0;
  }
  .sm:pl-1 {
    padding-left: 1rem;
  }
  .sm:pl-2 {
    padding-left: 2rem;
  }
  .sm:pl-3 {
    padding-left: 4rem;
  }
  .sm:pl-4 {
    padding-left: 6rem;
  }
  .sm:pl-5 {
    padding-left: 8rem;
  }
  .sm:pr-0 {
    padding-right: 0;
  }
  .sm:pr-1 {
    padding-right: 1rem;
  }
  .sm:pr-2 {
    padding-right: 2rem;
  }
  .sm:pr-3 {
    padding-right: 4rem;
  }
  .sm:pr-4 {
    padding-right: 6rem;
  }
  .sm:pr-5 {
    padding-right: 8rem;
  }
  .sm:pt-0 {
    padding-top: 0;
  }
  .sm:pt-1 {
    padding-top: 1rem;
  }
  .sm:pt-2 {
    padding-top: 2rem;
  }
  .sm:pt-3 {
    padding-top: 4rem;
  }
  .sm:pt-4 {
    padding-top: 6rem;
  }
  .sm:pt-5 {
    padding-top: 8rem;
  }
  .sm:pb-0 {
    padding-bottom: 0;
  }
  .sm:pb-1 {
    padding-bottom: 1rem;
  }
  .sm:pb-2 {
    padding-bottom: 2rem;
  }
  .sm:pb-3 {
    padding-bottom: 4rem;
  }
  .sm:pb-4 {
    padding-bottom: 6rem;
  }
  .sm:pb-5 {
    padding-bottom: 8rem;
  }
  .sm:m-0 {
    margin: 0;
  }
  .sm:m-1 {
    margin: 1rem;
  }
  .sm:m-2 {
    margin: 2rem;
  }
  .sm:m-3 {
    margin: 4rem;
  }
  .sm:m-4 {
    margin: 6rem;
  }
  .sm:m-5 {
    margin: 8rem;
  }
  .sm:ml-0 {
    margin-left: 0;
  }
  .sm:ml-1 {
    margin-left: 1rem;
  }
  .sm:ml-2 {
    margin-left: 2rem;
  }
  .sm:ml-3 {
    margin-left: 4rem;
  }
  .sm:ml-4 {
    margin-left: 6rem;
  }
  .sm:ml-5 {
    margin-left: 8rem;
  }
  .sm:mr-0 {
    margin-right: 0;
  }
  .sm:mr-1 {
    margin-right: 1rem;
  }
  .sm:mr-2 {
    margin-right: 2rem;
  }
  .sm:mr-3 {
    margin-right: 4rem;
  }
  .sm:mr-4 {
    margin-right: 6rem;
  }
  .sm:mr-5 {
    margin-right: 8rem;
  }
  .sm:mt-0 {
    margin-top: 0;
  }
  .sm:mt-1 {
    margin-top: 1rem;
  }
  .sm:mt-2 {
    margin-top: 2rem;
  }
  .sm:mt-3 {
    margin-top: 4rem;
  }
  .sm:mt-4 {
    margin-top: 6rem;
  }
  .sm:mt-5 {
    margin-top: 8rem;
  }
  .sm:mb-0 {
    margin-bottom: 0;
  }
  .sm:mb-1 {
    margin-bottom: 1rem;
  }
  .sm:mb-2 {
    margin-bottom: 2rem;
  }
  .sm:mb-3 {
    margin-bottom: 4rem;
  }
  .sm:mb-4 {
    margin-bottom: 6rem;
  }
  .sm:mb-5 {
    margin-bottom: 8rem;
  }
  .sm:o-10 {
    opacity: 0.1;
  }
  .sm:o-20 {
    opacity: 0.2;
  }
  .sm:o-30 {
    opacity: 0.3;
  }
  .sm:o-40 {
    opacity: 0.4;
  }
  .sm:o-50 {
    opacity: 0.5;
  }
  .sm:o-60 {
    opacity: 0.6;
  }
  .sm:o-70 {
    opacity: 0.7;
  }
  .sm:o-80 {
    opacity: 0.8;
  }
  .sm:o-90 {
    opacity: 0.9;
  }
  .sm:o-100 {
    opacity: 1;
  }
  .sm:br {
    border-radius: 0.8rem;
  }
  .sm:br-none {
    border-radius: 0;
  }
  .sm:br-xs {
    border-radius: 0.2rem;
  }
  .sm:br-sm {
    border-radius: 0.4rem;
  }
  .sm:br-lg {
    border-radius: 1.6rem;
  }
  .sm:br-full {
    border-radius: 50%;
  }
  .sm:display-n {
    display: none;
  }
  .sm:display-b {
    display: block;
  }
  .sm:display-f {
    display: flex;
  }
  .sm:display-i {
    display: inline;
  }
  .sm:display-i-b {
    display: inline-block;
  }
  .sm:align-f-start {
    align-items: flex-start;
  }
  .sm:align-f-end {
    align-items: flex-end;
  }
  .sm:align-center {
    align-items: center;
  }
  .sm:flex-row {
    flex-direction: row;
  }
  .sm:flex-column {
    flex-direction: column;
  }
  .sm:justify-between {
    justify-content: space-between;
  }
  .sm:justify-f-end {
    justify-content: flex-end;
  }
  .sm:justify-center {
    justify-content: center;
  }
  .sm:justify-around {
    justify-content: space-around;
  }
  .sm:justify-evenly {
    justify-content: space-evenly;
  }
  .sm:text-center {
    text-align: center;
  }
  .sm:text-start {
    text-align: start;
  }
  .sm:text-end {
    text-align: end;
  }
  .sm:text-left {
    text-align: left;
  }
  .sm:text-right {
    text-align: right;
  }
  .sm:text-justify {
    text-align: justify;
  }
  .sm:text-d2xl {
    font-size: 7.2rem;
  }
  .sm:text-dxl {
    font-size: 6rem;
  }
  .sm:text-dlg {
    font-size: 4.8rem;
  }
  .sm:text-dmd {
    font-size: 3.6rem;
  }
  .sm:text-dsm {
    font-size: 3.2rem;
  }
  .sm:text-dxs {
    font-size: 2.4rem;
  }
  .sm:text-xxl {
    font-size: 2.4rem;
  }
  .sm:text-xl {
    font-size: 2rem;
  }
  .sm:text-lg {
    font-size: 1.8rem;
  }
  .sm:text-md {
    font-size: 1.6rem;
  }
  .sm:text-sm {
    font-size: 1.4rem;
  }
  .sm:text-xs {
    font-size: 1.2rem;
  }
  .sm:text-macro {
    font-size: 1rem;
  }
  .gap-0 {
    gap: 0rem;
  }
  .gap-1 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 2rem;
  }
  .gap-3 {
    gap: 3rem;
  }
  .gap-4 {
    gap: 4rem;
  }
  .gap-5 {
    gap: 5rem;
  }
  .gap-6 {
    gap: 6rem;
  }
  .gap-7 {
    gap: 7rem;
  }
  .gap-8 {
    gap: 8rem;
  }
  .gap-9 {
    gap: 9rem;
  }
  .gap-10 {
    gap: 10rem;
  }
  .gap-11 {
    gap: 11rem;
  }
  .gap-12 {
    gap: 12rem;
  }
  .gap-13 {
    gap: 13rem;
  }
  .gap-14 {
    gap: 14rem;
  }
  .gap-15 {
    gap: 15rem;
  }
  .gap-16 {
    gap: 16rem;
  }
  .gap-17 {
    gap: 17rem;
  }
  .gap-18 {
    gap: 18rem;
  }
  .gap-19 {
    gap: 19rem;
  }
  .gap-20 {
    gap: 20rem;
  }
  .gap-21 {
    gap: 21rem;
  }
  .gap-22 {
    gap: 22rem;
  }
  .gap-23 {
    gap: 23rem;
  }
  .gap-24 {
    gap: 24rem;
  }
  .gap-25 {
    gap: 25rem;
  }
  .gap-26 {
    gap: 26rem;
  }
  .gap-27 {
    gap: 27rem;
  }
  .gap-28 {
    gap: 28rem;
  }
  .gap-29 {
    gap: 29rem;
  }
  .gap-30 {
    gap: 30rem;
  }
  .gap-31 {
    gap: 31rem;
  }
  .gap-32 {
    gap: 32rem;
  }
  .gap-33 {
    gap: 33rem;
  }
  .gap-34 {
    gap: 34rem;
  }
  .gap-35 {
    gap: 35rem;
  }
  .gap-36 {
    gap: 36rem;
  }
  .gap-37 {
    gap: 37rem;
  }
  .gap-38 {
    gap: 38rem;
  }
  .gap-39 {
    gap: 39rem;
  }
  .gap-40 {
    gap: 40rem;
  }
  .gap-41 {
    gap: 41rem;
  }
  .gap-42 {
    gap: 42rem;
  }
  .gap-43 {
    gap: 43rem;
  }
  .gap-44 {
    gap: 44rem;
  }
  .gap-45 {
    gap: 45rem;
  }
  .gap-46 {
    gap: 46rem;
  }
  .gap-47 {
    gap: 47rem;
  }
  .gap-48 {
    gap: 48rem;
  }
  .gap-49 {
    gap: 49rem;
  }
  .w-0 {
    width: 0rem;
  }
  .w-1 {
    width: 1rem;
  }
  .w-2 {
    width: 2rem;
  }
  .w-3 {
    width: 3rem;
  }
  .w-4 {
    width: 4rem;
  }
  .w-5 {
    width: 5rem;
  }
  .w-6 {
    width: 6rem;
  }
  .w-7 {
    width: 7rem;
  }
  .w-8 {
    width: 8rem;
  }
  .w-9 {
    width: 9rem;
  }
  .w-10 {
    width: 10rem;
  }
  .w-11 {
    width: 11rem;
  }
  .w-12 {
    width: 12rem;
  }
  .w-13 {
    width: 13rem;
  }
  .w-14 {
    width: 14rem;
  }
  .w-15 {
    width: 15rem;
  }
  .w-16 {
    width: 16rem;
  }
  .w-17 {
    width: 17rem;
  }
  .w-18 {
    width: 18rem;
  }
  .w-19 {
    width: 19rem;
  }
  .w-20 {
    width: 20rem;
  }
  .w-21 {
    width: 21rem;
  }
  .w-22 {
    width: 22rem;
  }
  .w-23 {
    width: 23rem;
  }
  .w-24 {
    width: 24rem;
  }
  .w-25 {
    width: 25rem;
  }
  .w-26 {
    width: 26rem;
  }
  .w-27 {
    width: 27rem;
  }
  .w-28 {
    width: 28rem;
  }
  .w-29 {
    width: 29rem;
  }
  .w-30 {
    width: 30rem;
  }
  .w-31 {
    width: 31rem;
  }
  .w-32 {
    width: 32rem;
  }
  .w-33 {
    width: 33rem;
  }
  .w-34 {
    width: 34rem;
  }
  .w-35 {
    width: 35rem;
  }
  .w-36 {
    width: 36rem;
  }
  .w-37 {
    width: 37rem;
  }
  .w-38 {
    width: 38rem;
  }
  .w-39 {
    width: 39rem;
  }
  .w-40 {
    width: 40rem;
  }
  .w-41 {
    width: 41rem;
  }
  .w-42 {
    width: 42rem;
  }
  .w-43 {
    width: 43rem;
  }
  .w-44 {
    width: 44rem;
  }
  .w-45 {
    width: 45rem;
  }
  .w-46 {
    width: 46rem;
  }
  .w-47 {
    width: 47rem;
  }
  .w-48 {
    width: 48rem;
  }
  .w-49 {
    width: 49rem;
  }
  .w-50 {
    width: 50rem;
  }
  .w-51 {
    width: 51rem;
  }
  .w-52 {
    width: 52rem;
  }
  .w-53 {
    width: 53rem;
  }
  .w-54 {
    width: 54rem;
  }
  .w-55 {
    width: 55rem;
  }
  .w-56 {
    width: 56rem;
  }
  .w-57 {
    width: 57rem;
  }
  .w-58 {
    width: 58rem;
  }
  .w-59 {
    width: 59rem;
  }
  .w-60 {
    width: 60rem;
  }
  .w-61 {
    width: 61rem;
  }
  .w-62 {
    width: 62rem;
  }
  .w-63 {
    width: 63rem;
  }
  .w-64 {
    width: 64rem;
  }
  .w-65 {
    width: 65rem;
  }
  .w-66 {
    width: 66rem;
  }
  .w-67 {
    width: 67rem;
  }
  .w-68 {
    width: 68rem;
  }
  .w-69 {
    width: 69rem;
  }
  .w-70 {
    width: 70rem;
  }
  .w-71 {
    width: 71rem;
  }
  .w-72 {
    width: 72rem;
  }
  .w-73 {
    width: 73rem;
  }
  .w-74 {
    width: 74rem;
  }
  .w-75 {
    width: 75rem;
  }
  .w-76 {
    width: 76rem;
  }
  .w-77 {
    width: 77rem;
  }
  .w-78 {
    width: 78rem;
  }
  .w-79 {
    width: 79rem;
  }
  .w-80 {
    width: 80rem;
  }
  .w-81 {
    width: 81rem;
  }
  .w-82 {
    width: 82rem;
  }
  .w-83 {
    width: 83rem;
  }
  .w-84 {
    width: 84rem;
  }
  .w-85 {
    width: 85rem;
  }
  .w-86 {
    width: 86rem;
  }
  .w-87 {
    width: 87rem;
  }
  .w-88 {
    width: 88rem;
  }
  .w-89 {
    width: 89rem;
  }
  .w-90 {
    width: 90rem;
  }
  .w-91 {
    width: 91rem;
  }
  .w-92 {
    width: 92rem;
  }
  .w-93 {
    width: 93rem;
  }
  .w-94 {
    width: 94rem;
  }
  .w-95 {
    width: 95rem;
  }
  .w-96 {
    width: 96rem;
  }
  .w-97 {
    width: 97rem;
  }
  .w-98 {
    width: 98rem;
  }
  .w-99 {
    width: 99rem;
  }
  .w-100 {
    width: 100rem;
  }
  .w-101 {
    width: 101rem;
  }
  .w-102 {
    width: 102rem;
  }
  .w-103 {
    width: 103rem;
  }
  .w-104 {
    width: 104rem;
  }
  .w-105 {
    width: 105rem;
  }
  .w-106 {
    width: 106rem;
  }
  .w-107 {
    width: 107rem;
  }
  .w-108 {
    width: 108rem;
  }
  .w-109 {
    width: 109rem;
  }
  .w-110 {
    width: 110rem;
  }
  .w-111 {
    width: 111rem;
  }
  .w-112 {
    width: 112rem;
  }
  .w-113 {
    width: 113rem;
  }
  .w-114 {
    width: 114rem;
  }
  .w-115 {
    width: 115rem;
  }
  .w-116 {
    width: 116rem;
  }
  .w-117 {
    width: 117rem;
  }
  .w-118 {
    width: 118rem;
  }
  .w-119 {
    width: 119rem;
  }
  .w-120 {
    width: 120rem;
  }
  .w-121 {
    width: 121rem;
  }
  .w-122 {
    width: 122rem;
  }
  .w-123 {
    width: 123rem;
  }
  .w-124 {
    width: 124rem;
  }
  .w-125 {
    width: 125rem;
  }
  .w-126 {
    width: 126rem;
  }
  .w-127 {
    width: 127rem;
  }
  .w-128 {
    width: 128rem;
  }
  .w-129 {
    width: 129rem;
  }
  .w-130 {
    width: 130rem;
  }
  .w-131 {
    width: 131rem;
  }
  .w-132 {
    width: 132rem;
  }
  .w-133 {
    width: 133rem;
  }
  .w-134 {
    width: 134rem;
  }
  .w-135 {
    width: 135rem;
  }
  .w-136 {
    width: 136rem;
  }
  .w-137 {
    width: 137rem;
  }
  .w-138 {
    width: 138rem;
  }
  .w-139 {
    width: 139rem;
  }
  .w-140 {
    width: 140rem;
  }
  .w-141 {
    width: 141rem;
  }
  .w-142 {
    width: 142rem;
  }
  .w-143 {
    width: 143rem;
  }
  .w-144 {
    width: 144rem;
  }
  .w-145 {
    width: 145rem;
  }
  .w-146 {
    width: 146rem;
  }
  .w-147 {
    width: 147rem;
  }
  .w-148 {
    width: 148rem;
  }
  .w-149 {
    width: 149rem;
  }
  .w-150 {
    width: 150rem;
  }
  .w-151 {
    width: 151rem;
  }
  .w-152 {
    width: 152rem;
  }
  .w-153 {
    width: 153rem;
  }
  .w-154 {
    width: 154rem;
  }
  .w-155 {
    width: 155rem;
  }
  .w-156 {
    width: 156rem;
  }
  .w-157 {
    width: 157rem;
  }
  .w-158 {
    width: 158rem;
  }
  .w-159 {
    width: 159rem;
  }
  .w-160 {
    width: 160rem;
  }
  .w-161 {
    width: 161rem;
  }
  .w-162 {
    width: 162rem;
  }
  .w-163 {
    width: 163rem;
  }
  .w-164 {
    width: 164rem;
  }
  .w-165 {
    width: 165rem;
  }
  .w-166 {
    width: 166rem;
  }
  .w-167 {
    width: 167rem;
  }
  .w-168 {
    width: 168rem;
  }
  .w-169 {
    width: 169rem;
  }
  .w-170 {
    width: 170rem;
  }
  .w-171 {
    width: 171rem;
  }
  .w-172 {
    width: 172rem;
  }
  .w-173 {
    width: 173rem;
  }
  .w-174 {
    width: 174rem;
  }
  .w-175 {
    width: 175rem;
  }
  .w-176 {
    width: 176rem;
  }
  .w-177 {
    width: 177rem;
  }
  .w-178 {
    width: 178rem;
  }
  .w-179 {
    width: 179rem;
  }
  .w-180 {
    width: 180rem;
  }
  .w-181 {
    width: 181rem;
  }
  .w-182 {
    width: 182rem;
  }
  .w-183 {
    width: 183rem;
  }
  .w-184 {
    width: 184rem;
  }
  .w-185 {
    width: 185rem;
  }
  .w-186 {
    width: 186rem;
  }
  .w-187 {
    width: 187rem;
  }
  .w-188 {
    width: 188rem;
  }
  .w-189 {
    width: 189rem;
  }
  .w-190 {
    width: 190rem;
  }
  .w-191 {
    width: 191rem;
  }
  .w-192 {
    width: 192rem;
  }
  .w-193 {
    width: 193rem;
  }
  .w-194 {
    width: 194rem;
  }
  .w-195 {
    width: 195rem;
  }
  .w-196 {
    width: 196rem;
  }
  .w-197 {
    width: 197rem;
  }
  .w-198 {
    width: 198rem;
  }
  .w-199 {
    width: 199rem;
  }
  .w-200 {
    width: 200rem;
  }
  .w-201 {
    width: 201rem;
  }
  .w-202 {
    width: 202rem;
  }
  .w-203 {
    width: 203rem;
  }
  .w-204 {
    width: 204rem;
  }
  .w-205 {
    width: 205rem;
  }
  .w-206 {
    width: 206rem;
  }
  .w-207 {
    width: 207rem;
  }
  .w-208 {
    width: 208rem;
  }
  .w-209 {
    width: 209rem;
  }
  .w-210 {
    width: 210rem;
  }
  .w-211 {
    width: 211rem;
  }
  .w-212 {
    width: 212rem;
  }
  .w-213 {
    width: 213rem;
  }
  .w-214 {
    width: 214rem;
  }
  .w-215 {
    width: 215rem;
  }
  .w-216 {
    width: 216rem;
  }
  .w-217 {
    width: 217rem;
  }
  .w-218 {
    width: 218rem;
  }
  .w-219 {
    width: 219rem;
  }
  .w-220 {
    width: 220rem;
  }
  .w-221 {
    width: 221rem;
  }
  .w-222 {
    width: 222rem;
  }
  .w-223 {
    width: 223rem;
  }
  .w-224 {
    width: 224rem;
  }
  .w-225 {
    width: 225rem;
  }
  .w-226 {
    width: 226rem;
  }
  .w-227 {
    width: 227rem;
  }
  .w-228 {
    width: 228rem;
  }
  .w-229 {
    width: 229rem;
  }
  .w-230 {
    width: 230rem;
  }
  .w-231 {
    width: 231rem;
  }
  .w-232 {
    width: 232rem;
  }
  .w-233 {
    width: 233rem;
  }
  .w-234 {
    width: 234rem;
  }
  .w-235 {
    width: 235rem;
  }
  .w-236 {
    width: 236rem;
  }
  .w-237 {
    width: 237rem;
  }
  .w-238 {
    width: 238rem;
  }
  .w-239 {
    width: 239rem;
  }
  .w-240 {
    width: 240rem;
  }
  .w-241 {
    width: 241rem;
  }
  .w-242 {
    width: 242rem;
  }
  .w-243 {
    width: 243rem;
  }
  .w-244 {
    width: 244rem;
  }
  .w-245 {
    width: 245rem;
  }
  .w-246 {
    width: 246rem;
  }
  .w-247 {
    width: 247rem;
  }
  .w-248 {
    width: 248rem;
  }
  .w-249 {
    width: 249rem;
  }
  .w-250 {
    width: 250rem;
  }
  .w-251 {
    width: 251rem;
  }
  .w-252 {
    width: 252rem;
  }
  .w-253 {
    width: 253rem;
  }
  .w-254 {
    width: 254rem;
  }
  .w-255 {
    width: 255rem;
  }
  .w-256 {
    width: 256rem;
  }
  .w-257 {
    width: 257rem;
  }
  .w-258 {
    width: 258rem;
  }
  .w-259 {
    width: 259rem;
  }
  .w-260 {
    width: 260rem;
  }
  .w-261 {
    width: 261rem;
  }
  .w-262 {
    width: 262rem;
  }
  .w-263 {
    width: 263rem;
  }
  .w-264 {
    width: 264rem;
  }
  .w-265 {
    width: 265rem;
  }
  .w-266 {
    width: 266rem;
  }
  .w-267 {
    width: 267rem;
  }
  .w-268 {
    width: 268rem;
  }
  .w-269 {
    width: 269rem;
  }
  .w-270 {
    width: 270rem;
  }
  .w-271 {
    width: 271rem;
  }
  .w-272 {
    width: 272rem;
  }
  .w-273 {
    width: 273rem;
  }
  .w-274 {
    width: 274rem;
  }
  .w-275 {
    width: 275rem;
  }
  .w-276 {
    width: 276rem;
  }
  .w-277 {
    width: 277rem;
  }
  .w-278 {
    width: 278rem;
  }
  .w-279 {
    width: 279rem;
  }
  .w-280 {
    width: 280rem;
  }
  .w-281 {
    width: 281rem;
  }
  .w-282 {
    width: 282rem;
  }
  .w-283 {
    width: 283rem;
  }
  .w-284 {
    width: 284rem;
  }
  .w-285 {
    width: 285rem;
  }
  .w-286 {
    width: 286rem;
  }
  .w-287 {
    width: 287rem;
  }
  .w-288 {
    width: 288rem;
  }
  .w-289 {
    width: 289rem;
  }
  .w-290 {
    width: 290rem;
  }
  .w-291 {
    width: 291rem;
  }
  .w-292 {
    width: 292rem;
  }
  .w-293 {
    width: 293rem;
  }
  .w-294 {
    width: 294rem;
  }
  .w-295 {
    width: 295rem;
  }
  .w-296 {
    width: 296rem;
  }
  .w-297 {
    width: 297rem;
  }
  .w-298 {
    width: 298rem;
  }
  .w-299 {
    width: 299rem;
  }
  .w-300 {
    width: 300rem;
  }
  .w-301 {
    width: 301rem;
  }
  .w-302 {
    width: 302rem;
  }
  .w-303 {
    width: 303rem;
  }
  .w-304 {
    width: 304rem;
  }
  .w-305 {
    width: 305rem;
  }
  .w-306 {
    width: 306rem;
  }
  .w-307 {
    width: 307rem;
  }
  .w-308 {
    width: 308rem;
  }
  .w-309 {
    width: 309rem;
  }
  .w-310 {
    width: 310rem;
  }
  .w-311 {
    width: 311rem;
  }
  .w-312 {
    width: 312rem;
  }
  .w-313 {
    width: 313rem;
  }
  .w-314 {
    width: 314rem;
  }
  .w-315 {
    width: 315rem;
  }
  .w-316 {
    width: 316rem;
  }
  .w-317 {
    width: 317rem;
  }
  .w-318 {
    width: 318rem;
  }
  .w-319 {
    width: 319rem;
  }
  .w-320 {
    width: 320rem;
  }
  .w-321 {
    width: 321rem;
  }
  .w-322 {
    width: 322rem;
  }
  .w-323 {
    width: 323rem;
  }
  .w-324 {
    width: 324rem;
  }
  .w-325 {
    width: 325rem;
  }
  .w-326 {
    width: 326rem;
  }
  .w-327 {
    width: 327rem;
  }
  .w-328 {
    width: 328rem;
  }
  .w-329 {
    width: 329rem;
  }
  .w-330 {
    width: 330rem;
  }
  .w-331 {
    width: 331rem;
  }
  .w-332 {
    width: 332rem;
  }
  .w-333 {
    width: 333rem;
  }
  .w-334 {
    width: 334rem;
  }
  .w-335 {
    width: 335rem;
  }
  .w-336 {
    width: 336rem;
  }
  .w-337 {
    width: 337rem;
  }
  .w-338 {
    width: 338rem;
  }
  .w-339 {
    width: 339rem;
  }
  .w-340 {
    width: 340rem;
  }
  .w-341 {
    width: 341rem;
  }
  .w-342 {
    width: 342rem;
  }
  .w-343 {
    width: 343rem;
  }
  .w-344 {
    width: 344rem;
  }
  .w-345 {
    width: 345rem;
  }
  .w-346 {
    width: 346rem;
  }
  .w-347 {
    width: 347rem;
  }
  .w-348 {
    width: 348rem;
  }
  .w-349 {
    width: 349rem;
  }
  .w-350 {
    width: 350rem;
  }
  .w-351 {
    width: 351rem;
  }
  .w-352 {
    width: 352rem;
  }
  .w-353 {
    width: 353rem;
  }
  .w-354 {
    width: 354rem;
  }
  .w-355 {
    width: 355rem;
  }
  .w-356 {
    width: 356rem;
  }
  .w-357 {
    width: 357rem;
  }
  .w-358 {
    width: 358rem;
  }
  .w-359 {
    width: 359rem;
  }
  .w-360 {
    width: 360rem;
  }
  .w-361 {
    width: 361rem;
  }
  .w-362 {
    width: 362rem;
  }
  .w-363 {
    width: 363rem;
  }
  .w-364 {
    width: 364rem;
  }
  .w-365 {
    width: 365rem;
  }
  .w-366 {
    width: 366rem;
  }
  .w-367 {
    width: 367rem;
  }
  .w-368 {
    width: 368rem;
  }
  .w-369 {
    width: 369rem;
  }
  .w-370 {
    width: 370rem;
  }
  .w-371 {
    width: 371rem;
  }
  .w-372 {
    width: 372rem;
  }
  .w-373 {
    width: 373rem;
  }
  .w-374 {
    width: 374rem;
  }
  .w-375 {
    width: 375rem;
  }
  .w-376 {
    width: 376rem;
  }
  .w-377 {
    width: 377rem;
  }
  .w-378 {
    width: 378rem;
  }
  .w-379 {
    width: 379rem;
  }
  .w-380 {
    width: 380rem;
  }
  .w-381 {
    width: 381rem;
  }
  .w-382 {
    width: 382rem;
  }
  .w-383 {
    width: 383rem;
  }
  .w-384 {
    width: 384rem;
  }
  .w-385 {
    width: 385rem;
  }
  .w-386 {
    width: 386rem;
  }
  .w-387 {
    width: 387rem;
  }
  .w-388 {
    width: 388rem;
  }
  .w-389 {
    width: 389rem;
  }
  .w-390 {
    width: 390rem;
  }
  .w-391 {
    width: 391rem;
  }
  .w-392 {
    width: 392rem;
  }
  .w-393 {
    width: 393rem;
  }
  .w-394 {
    width: 394rem;
  }
  .w-395 {
    width: 395rem;
  }
  .w-396 {
    width: 396rem;
  }
  .w-397 {
    width: 397rem;
  }
  .w-398 {
    width: 398rem;
  }
  .w-399 {
    width: 399rem;
  }
  .w-400 {
    width: 400rem;
  }
  .w-401 {
    width: 401rem;
  }
  .w-402 {
    width: 402rem;
  }
  .w-403 {
    width: 403rem;
  }
  .w-404 {
    width: 404rem;
  }
  .w-405 {
    width: 405rem;
  }
  .w-406 {
    width: 406rem;
  }
  .w-407 {
    width: 407rem;
  }
  .w-408 {
    width: 408rem;
  }
  .w-409 {
    width: 409rem;
  }
  .w-410 {
    width: 410rem;
  }
  .w-411 {
    width: 411rem;
  }
  .w-412 {
    width: 412rem;
  }
  .w-413 {
    width: 413rem;
  }
  .w-414 {
    width: 414rem;
  }
  .w-415 {
    width: 415rem;
  }
  .w-416 {
    width: 416rem;
  }
  .w-417 {
    width: 417rem;
  }
  .w-418 {
    width: 418rem;
  }
  .w-419 {
    width: 419rem;
  }
  .w-420 {
    width: 420rem;
  }
  .w-421 {
    width: 421rem;
  }
  .w-422 {
    width: 422rem;
  }
  .w-423 {
    width: 423rem;
  }
  .w-424 {
    width: 424rem;
  }
  .w-425 {
    width: 425rem;
  }
  .w-426 {
    width: 426rem;
  }
  .w-427 {
    width: 427rem;
  }
  .w-428 {
    width: 428rem;
  }
  .w-429 {
    width: 429rem;
  }
  .w-430 {
    width: 430rem;
  }
  .w-431 {
    width: 431rem;
  }
  .w-432 {
    width: 432rem;
  }
  .w-433 {
    width: 433rem;
  }
  .w-434 {
    width: 434rem;
  }
  .w-435 {
    width: 435rem;
  }
  .w-436 {
    width: 436rem;
  }
  .w-437 {
    width: 437rem;
  }
  .w-438 {
    width: 438rem;
  }
  .w-439 {
    width: 439rem;
  }
  .w-440 {
    width: 440rem;
  }
  .w-441 {
    width: 441rem;
  }
  .w-442 {
    width: 442rem;
  }
  .w-443 {
    width: 443rem;
  }
  .w-444 {
    width: 444rem;
  }
  .w-445 {
    width: 445rem;
  }
  .w-446 {
    width: 446rem;
  }
  .w-447 {
    width: 447rem;
  }
  .w-448 {
    width: 448rem;
  }
  .w-449 {
    width: 449rem;
  }
  .w-450 {
    width: 450rem;
  }
  .w-451 {
    width: 451rem;
  }
  .w-452 {
    width: 452rem;
  }
  .w-453 {
    width: 453rem;
  }
  .w-454 {
    width: 454rem;
  }
  .w-455 {
    width: 455rem;
  }
  .w-456 {
    width: 456rem;
  }
  .w-457 {
    width: 457rem;
  }
  .w-458 {
    width: 458rem;
  }
  .w-459 {
    width: 459rem;
  }
  .w-460 {
    width: 460rem;
  }
  .w-461 {
    width: 461rem;
  }
  .w-462 {
    width: 462rem;
  }
  .w-463 {
    width: 463rem;
  }
  .w-464 {
    width: 464rem;
  }
  .w-465 {
    width: 465rem;
  }
  .w-466 {
    width: 466rem;
  }
  .w-467 {
    width: 467rem;
  }
  .w-468 {
    width: 468rem;
  }
  .w-469 {
    width: 469rem;
  }
  .w-470 {
    width: 470rem;
  }
  .w-471 {
    width: 471rem;
  }
  .w-472 {
    width: 472rem;
  }
  .w-473 {
    width: 473rem;
  }
  .w-474 {
    width: 474rem;
  }
  .w-475 {
    width: 475rem;
  }
  .w-476 {
    width: 476rem;
  }
  .w-477 {
    width: 477rem;
  }
  .w-478 {
    width: 478rem;
  }
  .w-479 {
    width: 479rem;
  }
  .w-480 {
    width: 480rem;
  }
  .w-481 {
    width: 481rem;
  }
  .w-482 {
    width: 482rem;
  }
  .w-483 {
    width: 483rem;
  }
  .w-484 {
    width: 484rem;
  }
  .w-485 {
    width: 485rem;
  }
  .w-486 {
    width: 486rem;
  }
  .w-487 {
    width: 487rem;
  }
  .w-488 {
    width: 488rem;
  }
  .w-489 {
    width: 489rem;
  }
  .w-490 {
    width: 490rem;
  }
  .w-491 {
    width: 491rem;
  }
  .w-492 {
    width: 492rem;
  }
  .w-493 {
    width: 493rem;
  }
  .w-494 {
    width: 494rem;
  }
  .w-495 {
    width: 495rem;
  }
  .w-496 {
    width: 496rem;
  }
  .w-497 {
    width: 497rem;
  }
  .w-498 {
    width: 498rem;
  }
  .w-499 {
    width: 499rem;
  }
  .w-500 {
    width: 500rem;
  }
  .w-501 {
    width: 501rem;
  }
  .w-502 {
    width: 502rem;
  }
  .w-503 {
    width: 503rem;
  }
  .w-504 {
    width: 504rem;
  }
  .w-505 {
    width: 505rem;
  }
  .w-506 {
    width: 506rem;
  }
  .w-507 {
    width: 507rem;
  }
  .w-508 {
    width: 508rem;
  }
  .w-509 {
    width: 509rem;
  }
  .w-510 {
    width: 510rem;
  }
  .w-511 {
    width: 511rem;
  }
  .w-512 {
    width: 512rem;
  }
  .w-513 {
    width: 513rem;
  }
  .w-514 {
    width: 514rem;
  }
  .w-515 {
    width: 515rem;
  }
  .w-516 {
    width: 516rem;
  }
  .w-517 {
    width: 517rem;
  }
  .w-518 {
    width: 518rem;
  }
  .w-519 {
    width: 519rem;
  }
  .w-520 {
    width: 520rem;
  }
  .w-521 {
    width: 521rem;
  }
  .w-522 {
    width: 522rem;
  }
  .w-523 {
    width: 523rem;
  }
  .w-524 {
    width: 524rem;
  }
  .w-525 {
    width: 525rem;
  }
  .w-526 {
    width: 526rem;
  }
  .w-527 {
    width: 527rem;
  }
  .w-528 {
    width: 528rem;
  }
  .w-529 {
    width: 529rem;
  }
  .w-530 {
    width: 530rem;
  }
  .w-531 {
    width: 531rem;
  }
  .w-532 {
    width: 532rem;
  }
  .w-533 {
    width: 533rem;
  }
  .w-534 {
    width: 534rem;
  }
  .w-535 {
    width: 535rem;
  }
  .w-536 {
    width: 536rem;
  }
  .w-537 {
    width: 537rem;
  }
  .w-538 {
    width: 538rem;
  }
  .w-539 {
    width: 539rem;
  }
  .w-540 {
    width: 540rem;
  }
  .w-541 {
    width: 541rem;
  }
  .w-542 {
    width: 542rem;
  }
  .w-543 {
    width: 543rem;
  }
  .w-544 {
    width: 544rem;
  }
  .w-545 {
    width: 545rem;
  }
  .w-546 {
    width: 546rem;
  }
  .w-547 {
    width: 547rem;
  }
  .w-548 {
    width: 548rem;
  }
  .w-549 {
    width: 549rem;
  }
  .w-550 {
    width: 550rem;
  }
  .w-551 {
    width: 551rem;
  }
  .w-552 {
    width: 552rem;
  }
  .w-553 {
    width: 553rem;
  }
  .w-554 {
    width: 554rem;
  }
  .w-555 {
    width: 555rem;
  }
  .w-556 {
    width: 556rem;
  }
  .w-557 {
    width: 557rem;
  }
  .w-558 {
    width: 558rem;
  }
  .w-559 {
    width: 559rem;
  }
  .w-560 {
    width: 560rem;
  }
  .w-561 {
    width: 561rem;
  }
  .w-562 {
    width: 562rem;
  }
  .w-563 {
    width: 563rem;
  }
  .w-564 {
    width: 564rem;
  }
  .w-565 {
    width: 565rem;
  }
  .w-566 {
    width: 566rem;
  }
  .w-567 {
    width: 567rem;
  }
  .w-568 {
    width: 568rem;
  }
  .w-569 {
    width: 569rem;
  }
  .w-570 {
    width: 570rem;
  }
  .w-571 {
    width: 571rem;
  }
  .w-572 {
    width: 572rem;
  }
  .w-573 {
    width: 573rem;
  }
  .w-574 {
    width: 574rem;
  }
  .w-575 {
    width: 575rem;
  }
  .w-576 {
    width: 576rem;
  }
  .w-577 {
    width: 577rem;
  }
  .w-578 {
    width: 578rem;
  }
  .w-579 {
    width: 579rem;
  }
  .w-580 {
    width: 580rem;
  }
  .w-581 {
    width: 581rem;
  }
  .w-582 {
    width: 582rem;
  }
  .w-583 {
    width: 583rem;
  }
  .w-584 {
    width: 584rem;
  }
  .w-585 {
    width: 585rem;
  }
  .w-586 {
    width: 586rem;
  }
  .w-587 {
    width: 587rem;
  }
  .w-588 {
    width: 588rem;
  }
  .w-589 {
    width: 589rem;
  }
  .w-590 {
    width: 590rem;
  }
  .w-591 {
    width: 591rem;
  }
  .w-592 {
    width: 592rem;
  }
  .w-593 {
    width: 593rem;
  }
  .w-594 {
    width: 594rem;
  }
  .w-595 {
    width: 595rem;
  }
  .w-596 {
    width: 596rem;
  }
  .w-597 {
    width: 597rem;
  }
  .w-598 {
    width: 598rem;
  }
  .w-599 {
    width: 599rem;
  }
  .w-600 {
    width: 600rem;
  }
  .w-601 {
    width: 601rem;
  }
  .w-602 {
    width: 602rem;
  }
  .w-603 {
    width: 603rem;
  }
  .w-604 {
    width: 604rem;
  }
  .w-605 {
    width: 605rem;
  }
  .w-606 {
    width: 606rem;
  }
  .w-607 {
    width: 607rem;
  }
  .w-608 {
    width: 608rem;
  }
  .w-609 {
    width: 609rem;
  }
  .w-610 {
    width: 610rem;
  }
  .w-611 {
    width: 611rem;
  }
  .w-612 {
    width: 612rem;
  }
  .w-613 {
    width: 613rem;
  }
  .w-614 {
    width: 614rem;
  }
  .w-615 {
    width: 615rem;
  }
  .w-616 {
    width: 616rem;
  }
  .w-617 {
    width: 617rem;
  }
  .w-618 {
    width: 618rem;
  }
  .w-619 {
    width: 619rem;
  }
  .w-620 {
    width: 620rem;
  }
  .w-621 {
    width: 621rem;
  }
  .w-622 {
    width: 622rem;
  }
  .w-623 {
    width: 623rem;
  }
  .w-624 {
    width: 624rem;
  }
  .w-625 {
    width: 625rem;
  }
  .w-626 {
    width: 626rem;
  }
  .w-627 {
    width: 627rem;
  }
  .w-628 {
    width: 628rem;
  }
  .w-629 {
    width: 629rem;
  }
  .w-630 {
    width: 630rem;
  }
  .w-631 {
    width: 631rem;
  }
  .w-632 {
    width: 632rem;
  }
  .w-633 {
    width: 633rem;
  }
  .w-634 {
    width: 634rem;
  }
  .w-635 {
    width: 635rem;
  }
  .w-636 {
    width: 636rem;
  }
  .w-637 {
    width: 637rem;
  }
  .w-638 {
    width: 638rem;
  }
  .w-639 {
    width: 639rem;
  }
  .w-640 {
    width: 640rem;
  }
  .w-641 {
    width: 641rem;
  }
  .w-642 {
    width: 642rem;
  }
  .w-643 {
    width: 643rem;
  }
  .w-644 {
    width: 644rem;
  }
  .w-645 {
    width: 645rem;
  }
  .w-646 {
    width: 646rem;
  }
  .w-647 {
    width: 647rem;
  }
  .w-648 {
    width: 648rem;
  }
  .w-649 {
    width: 649rem;
  }
  .w-650 {
    width: 650rem;
  }
  .w-651 {
    width: 651rem;
  }
  .w-652 {
    width: 652rem;
  }
  .w-653 {
    width: 653rem;
  }
  .w-654 {
    width: 654rem;
  }
  .w-655 {
    width: 655rem;
  }
  .w-656 {
    width: 656rem;
  }
  .w-657 {
    width: 657rem;
  }
  .w-658 {
    width: 658rem;
  }
  .w-659 {
    width: 659rem;
  }
  .w-660 {
    width: 660rem;
  }
  .w-661 {
    width: 661rem;
  }
  .w-662 {
    width: 662rem;
  }
  .w-663 {
    width: 663rem;
  }
  .w-664 {
    width: 664rem;
  }
  .w-665 {
    width: 665rem;
  }
  .w-666 {
    width: 666rem;
  }
  .w-667 {
    width: 667rem;
  }
  .w-668 {
    width: 668rem;
  }
  .w-669 {
    width: 669rem;
  }
  .w-670 {
    width: 670rem;
  }
  .w-671 {
    width: 671rem;
  }
  .w-672 {
    width: 672rem;
  }
  .w-673 {
    width: 673rem;
  }
  .w-674 {
    width: 674rem;
  }
  .w-675 {
    width: 675rem;
  }
  .w-676 {
    width: 676rem;
  }
  .w-677 {
    width: 677rem;
  }
  .w-678 {
    width: 678rem;
  }
  .w-679 {
    width: 679rem;
  }
  .w-680 {
    width: 680rem;
  }
  .w-681 {
    width: 681rem;
  }
  .w-682 {
    width: 682rem;
  }
  .w-683 {
    width: 683rem;
  }
  .w-684 {
    width: 684rem;
  }
  .w-685 {
    width: 685rem;
  }
  .w-686 {
    width: 686rem;
  }
  .w-687 {
    width: 687rem;
  }
  .w-688 {
    width: 688rem;
  }
  .w-689 {
    width: 689rem;
  }
  .w-690 {
    width: 690rem;
  }
  .w-691 {
    width: 691rem;
  }
  .w-692 {
    width: 692rem;
  }
  .w-693 {
    width: 693rem;
  }
  .w-694 {
    width: 694rem;
  }
  .w-695 {
    width: 695rem;
  }
  .w-696 {
    width: 696rem;
  }
  .w-697 {
    width: 697rem;
  }
  .w-698 {
    width: 698rem;
  }
  .w-699 {
    width: 699rem;
  }
  .w-700 {
    width: 700rem;
  }
  .w-701 {
    width: 701rem;
  }
  .w-702 {
    width: 702rem;
  }
  .w-703 {
    width: 703rem;
  }
  .w-704 {
    width: 704rem;
  }
  .w-705 {
    width: 705rem;
  }
  .w-706 {
    width: 706rem;
  }
  .w-707 {
    width: 707rem;
  }
  .w-708 {
    width: 708rem;
  }
  .w-709 {
    width: 709rem;
  }
  .w-710 {
    width: 710rem;
  }
  .w-711 {
    width: 711rem;
  }
  .w-712 {
    width: 712rem;
  }
  .w-713 {
    width: 713rem;
  }
  .w-714 {
    width: 714rem;
  }
  .w-715 {
    width: 715rem;
  }
  .w-716 {
    width: 716rem;
  }
  .w-717 {
    width: 717rem;
  }
  .w-718 {
    width: 718rem;
  }
  .w-719 {
    width: 719rem;
  }
  .w-720 {
    width: 720rem;
  }
  .w-721 {
    width: 721rem;
  }
  .w-722 {
    width: 722rem;
  }
  .w-723 {
    width: 723rem;
  }
  .w-724 {
    width: 724rem;
  }
  .w-725 {
    width: 725rem;
  }
  .w-726 {
    width: 726rem;
  }
  .w-727 {
    width: 727rem;
  }
  .w-728 {
    width: 728rem;
  }
  .w-729 {
    width: 729rem;
  }
  .w-730 {
    width: 730rem;
  }
  .w-731 {
    width: 731rem;
  }
  .w-732 {
    width: 732rem;
  }
  .w-733 {
    width: 733rem;
  }
  .w-734 {
    width: 734rem;
  }
  .w-735 {
    width: 735rem;
  }
  .w-736 {
    width: 736rem;
  }
  .w-737 {
    width: 737rem;
  }
  .w-738 {
    width: 738rem;
  }
  .w-739 {
    width: 739rem;
  }
  .w-740 {
    width: 740rem;
  }
  .w-741 {
    width: 741rem;
  }
  .w-742 {
    width: 742rem;
  }
  .w-743 {
    width: 743rem;
  }
  .w-744 {
    width: 744rem;
  }
  .w-745 {
    width: 745rem;
  }
  .w-746 {
    width: 746rem;
  }
  .w-747 {
    width: 747rem;
  }
  .w-748 {
    width: 748rem;
  }
  .w-749 {
    width: 749rem;
  }
  .w-750 {
    width: 750rem;
  }
  .w-751 {
    width: 751rem;
  }
  .w-752 {
    width: 752rem;
  }
  .w-753 {
    width: 753rem;
  }
  .w-754 {
    width: 754rem;
  }
  .w-755 {
    width: 755rem;
  }
  .w-756 {
    width: 756rem;
  }
  .w-757 {
    width: 757rem;
  }
  .w-758 {
    width: 758rem;
  }
  .w-759 {
    width: 759rem;
  }
  .w-760 {
    width: 760rem;
  }
  .w-761 {
    width: 761rem;
  }
  .w-762 {
    width: 762rem;
  }
  .w-763 {
    width: 763rem;
  }
  .w-764 {
    width: 764rem;
  }
  .w-765 {
    width: 765rem;
  }
  .w-766 {
    width: 766rem;
  }
  .w-767 {
    width: 767rem;
  }
  .w-768 {
    width: 768rem;
  }
  .w-769 {
    width: 769rem;
  }
  .w-770 {
    width: 770rem;
  }
  .w-771 {
    width: 771rem;
  }
  .w-772 {
    width: 772rem;
  }
  .w-773 {
    width: 773rem;
  }
  .w-774 {
    width: 774rem;
  }
  .w-775 {
    width: 775rem;
  }
  .w-776 {
    width: 776rem;
  }
  .w-777 {
    width: 777rem;
  }
  .w-778 {
    width: 778rem;
  }
  .w-779 {
    width: 779rem;
  }
  .w-780 {
    width: 780rem;
  }
  .w-781 {
    width: 781rem;
  }
  .w-782 {
    width: 782rem;
  }
  .w-783 {
    width: 783rem;
  }
  .w-784 {
    width: 784rem;
  }
  .w-785 {
    width: 785rem;
  }
  .w-786 {
    width: 786rem;
  }
  .w-787 {
    width: 787rem;
  }
  .w-788 {
    width: 788rem;
  }
  .w-789 {
    width: 789rem;
  }
  .w-790 {
    width: 790rem;
  }
  .w-791 {
    width: 791rem;
  }
  .w-792 {
    width: 792rem;
  }
  .w-793 {
    width: 793rem;
  }
  .w-794 {
    width: 794rem;
  }
  .w-795 {
    width: 795rem;
  }
  .w-796 {
    width: 796rem;
  }
  .w-797 {
    width: 797rem;
  }
  .w-798 {
    width: 798rem;
  }
  .w-799 {
    width: 799rem;
  }
  .w-800 {
    width: 800rem;
  }
  .w-801 {
    width: 801rem;
  }
  .w-802 {
    width: 802rem;
  }
  .w-803 {
    width: 803rem;
  }
  .w-804 {
    width: 804rem;
  }
  .w-805 {
    width: 805rem;
  }
  .w-806 {
    width: 806rem;
  }
  .w-807 {
    width: 807rem;
  }
  .w-808 {
    width: 808rem;
  }
  .w-809 {
    width: 809rem;
  }
  .w-810 {
    width: 810rem;
  }
  .w-811 {
    width: 811rem;
  }
  .w-812 {
    width: 812rem;
  }
  .w-813 {
    width: 813rem;
  }
  .w-814 {
    width: 814rem;
  }
  .w-815 {
    width: 815rem;
  }
  .w-816 {
    width: 816rem;
  }
  .w-817 {
    width: 817rem;
  }
  .w-818 {
    width: 818rem;
  }
  .w-819 {
    width: 819rem;
  }
  .w-820 {
    width: 820rem;
  }
  .w-821 {
    width: 821rem;
  }
  .w-822 {
    width: 822rem;
  }
  .w-823 {
    width: 823rem;
  }
  .w-824 {
    width: 824rem;
  }
  .w-825 {
    width: 825rem;
  }
  .w-826 {
    width: 826rem;
  }
  .w-827 {
    width: 827rem;
  }
  .w-828 {
    width: 828rem;
  }
  .w-829 {
    width: 829rem;
  }
  .w-830 {
    width: 830rem;
  }
  .w-831 {
    width: 831rem;
  }
  .w-832 {
    width: 832rem;
  }
  .w-833 {
    width: 833rem;
  }
  .w-834 {
    width: 834rem;
  }
  .w-835 {
    width: 835rem;
  }
  .w-836 {
    width: 836rem;
  }
  .w-837 {
    width: 837rem;
  }
  .w-838 {
    width: 838rem;
  }
  .w-839 {
    width: 839rem;
  }
  .w-840 {
    width: 840rem;
  }
  .w-841 {
    width: 841rem;
  }
  .w-842 {
    width: 842rem;
  }
  .w-843 {
    width: 843rem;
  }
  .w-844 {
    width: 844rem;
  }
  .w-845 {
    width: 845rem;
  }
  .w-846 {
    width: 846rem;
  }
  .w-847 {
    width: 847rem;
  }
  .w-848 {
    width: 848rem;
  }
  .w-849 {
    width: 849rem;
  }
  .w-850 {
    width: 850rem;
  }
  .w-851 {
    width: 851rem;
  }
  .w-852 {
    width: 852rem;
  }
  .w-853 {
    width: 853rem;
  }
  .w-854 {
    width: 854rem;
  }
  .w-855 {
    width: 855rem;
  }
  .w-856 {
    width: 856rem;
  }
  .w-857 {
    width: 857rem;
  }
  .w-858 {
    width: 858rem;
  }
  .w-859 {
    width: 859rem;
  }
  .w-860 {
    width: 860rem;
  }
  .w-861 {
    width: 861rem;
  }
  .w-862 {
    width: 862rem;
  }
  .w-863 {
    width: 863rem;
  }
  .w-864 {
    width: 864rem;
  }
  .w-865 {
    width: 865rem;
  }
  .w-866 {
    width: 866rem;
  }
  .w-867 {
    width: 867rem;
  }
  .w-868 {
    width: 868rem;
  }
  .w-869 {
    width: 869rem;
  }
  .w-870 {
    width: 870rem;
  }
  .w-871 {
    width: 871rem;
  }
  .w-872 {
    width: 872rem;
  }
  .w-873 {
    width: 873rem;
  }
  .w-874 {
    width: 874rem;
  }
  .w-875 {
    width: 875rem;
  }
  .w-876 {
    width: 876rem;
  }
  .w-877 {
    width: 877rem;
  }
  .w-878 {
    width: 878rem;
  }
  .w-879 {
    width: 879rem;
  }
  .w-880 {
    width: 880rem;
  }
  .w-881 {
    width: 881rem;
  }
  .w-882 {
    width: 882rem;
  }
  .w-883 {
    width: 883rem;
  }
  .w-884 {
    width: 884rem;
  }
  .w-885 {
    width: 885rem;
  }
  .w-886 {
    width: 886rem;
  }
  .w-887 {
    width: 887rem;
  }
  .w-888 {
    width: 888rem;
  }
  .w-889 {
    width: 889rem;
  }
  .w-890 {
    width: 890rem;
  }
  .w-891 {
    width: 891rem;
  }
  .w-892 {
    width: 892rem;
  }
  .w-893 {
    width: 893rem;
  }
  .w-894 {
    width: 894rem;
  }
  .w-895 {
    width: 895rem;
  }
  .w-896 {
    width: 896rem;
  }
  .w-897 {
    width: 897rem;
  }
  .w-898 {
    width: 898rem;
  }
  .w-899 {
    width: 899rem;
  }
  .w-900 {
    width: 900rem;
  }
  .w-901 {
    width: 901rem;
  }
  .w-902 {
    width: 902rem;
  }
  .w-903 {
    width: 903rem;
  }
  .w-904 {
    width: 904rem;
  }
  .w-905 {
    width: 905rem;
  }
  .w-906 {
    width: 906rem;
  }
  .w-907 {
    width: 907rem;
  }
  .w-908 {
    width: 908rem;
  }
  .w-909 {
    width: 909rem;
  }
  .w-910 {
    width: 910rem;
  }
  .w-911 {
    width: 911rem;
  }
  .w-912 {
    width: 912rem;
  }
  .w-913 {
    width: 913rem;
  }
  .w-914 {
    width: 914rem;
  }
  .w-915 {
    width: 915rem;
  }
  .w-916 {
    width: 916rem;
  }
  .w-917 {
    width: 917rem;
  }
  .w-918 {
    width: 918rem;
  }
  .w-919 {
    width: 919rem;
  }
  .w-920 {
    width: 920rem;
  }
  .w-921 {
    width: 921rem;
  }
  .w-922 {
    width: 922rem;
  }
  .w-923 {
    width: 923rem;
  }
  .w-924 {
    width: 924rem;
  }
  .w-925 {
    width: 925rem;
  }
  .w-926 {
    width: 926rem;
  }
  .w-927 {
    width: 927rem;
  }
  .w-928 {
    width: 928rem;
  }
  .w-929 {
    width: 929rem;
  }
  .w-930 {
    width: 930rem;
  }
  .w-931 {
    width: 931rem;
  }
  .w-932 {
    width: 932rem;
  }
  .w-933 {
    width: 933rem;
  }
  .w-934 {
    width: 934rem;
  }
  .w-935 {
    width: 935rem;
  }
  .w-936 {
    width: 936rem;
  }
  .w-937 {
    width: 937rem;
  }
  .w-938 {
    width: 938rem;
  }
  .w-939 {
    width: 939rem;
  }
  .w-940 {
    width: 940rem;
  }
  .w-941 {
    width: 941rem;
  }
  .w-942 {
    width: 942rem;
  }
  .w-943 {
    width: 943rem;
  }
  .w-944 {
    width: 944rem;
  }
  .w-945 {
    width: 945rem;
  }
  .w-946 {
    width: 946rem;
  }
  .w-947 {
    width: 947rem;
  }
  .w-948 {
    width: 948rem;
  }
  .w-949 {
    width: 949rem;
  }
  .w-950 {
    width: 950rem;
  }
  .w-951 {
    width: 951rem;
  }
  .w-952 {
    width: 952rem;
  }
  .w-953 {
    width: 953rem;
  }
  .w-954 {
    width: 954rem;
  }
  .w-955 {
    width: 955rem;
  }
  .w-956 {
    width: 956rem;
  }
  .w-957 {
    width: 957rem;
  }
  .w-958 {
    width: 958rem;
  }
  .w-959 {
    width: 959rem;
  }
  .w-960 {
    width: 960rem;
  }
  .w-961 {
    width: 961rem;
  }
  .w-962 {
    width: 962rem;
  }
  .w-963 {
    width: 963rem;
  }
  .w-964 {
    width: 964rem;
  }
  .w-965 {
    width: 965rem;
  }
  .w-966 {
    width: 966rem;
  }
  .w-967 {
    width: 967rem;
  }
  .w-968 {
    width: 968rem;
  }
  .w-969 {
    width: 969rem;
  }
  .w-970 {
    width: 970rem;
  }
  .w-971 {
    width: 971rem;
  }
  .w-972 {
    width: 972rem;
  }
  .w-973 {
    width: 973rem;
  }
  .w-974 {
    width: 974rem;
  }
  .w-975 {
    width: 975rem;
  }
  .w-976 {
    width: 976rem;
  }
  .w-977 {
    width: 977rem;
  }
  .w-978 {
    width: 978rem;
  }
  .w-979 {
    width: 979rem;
  }
  .w-980 {
    width: 980rem;
  }
  .w-981 {
    width: 981rem;
  }
  .w-982 {
    width: 982rem;
  }
  .w-983 {
    width: 983rem;
  }
  .w-984 {
    width: 984rem;
  }
  .w-985 {
    width: 985rem;
  }
  .w-986 {
    width: 986rem;
  }
  .w-987 {
    width: 987rem;
  }
  .w-988 {
    width: 988rem;
  }
  .w-989 {
    width: 989rem;
  }
  .w-990 {
    width: 990rem;
  }
  .w-991 {
    width: 991rem;
  }
  .w-992 {
    width: 992rem;
  }
  .w-993 {
    width: 993rem;
  }
  .w-994 {
    width: 994rem;
  }
  .w-995 {
    width: 995rem;
  }
  .w-996 {
    width: 996rem;
  }
  .w-997 {
    width: 997rem;
  }
  .w-998 {
    width: 998rem;
  }
  .w-999 {
    width: 999rem;
  }
  .w-1000 {
    width: 1000rem;
  }
  .w-1001 {
    width: 1001rem;
  }
  .w-1002 {
    width: 1002rem;
  }
  .w-1003 {
    width: 1003rem;
  }
  .w-1004 {
    width: 1004rem;
  }
  .w-1005 {
    width: 1005rem;
  }
  .w-1006 {
    width: 1006rem;
  }
  .w-1007 {
    width: 1007rem;
  }
  .w-1008 {
    width: 1008rem;
  }
  .w-1009 {
    width: 1009rem;
  }
  .w-1010 {
    width: 1010rem;
  }
  .w-1011 {
    width: 1011rem;
  }
  .w-1012 {
    width: 1012rem;
  }
  .w-1013 {
    width: 1013rem;
  }
  .w-1014 {
    width: 1014rem;
  }
  .w-1015 {
    width: 1015rem;
  }
  .w-1016 {
    width: 1016rem;
  }
  .w-1017 {
    width: 1017rem;
  }
  .w-1018 {
    width: 1018rem;
  }
  .w-1019 {
    width: 1019rem;
  }
  .w-1020 {
    width: 1020rem;
  }
  .w-1021 {
    width: 1021rem;
  }
  .w-1022 {
    width: 1022rem;
  }
  .w-1023 {
    width: 1023rem;
  }
  .w-1024 {
    width: 1024rem;
  }
  .w-1025 {
    width: 1025rem;
  }
  .w-1026 {
    width: 1026rem;
  }
  .w-1027 {
    width: 1027rem;
  }
  .w-1028 {
    width: 1028rem;
  }
  .w-1029 {
    width: 1029rem;
  }
  .w-1030 {
    width: 1030rem;
  }
  .w-1031 {
    width: 1031rem;
  }
  .w-1032 {
    width: 1032rem;
  }
  .w-1033 {
    width: 1033rem;
  }
  .w-1034 {
    width: 1034rem;
  }
  .w-1035 {
    width: 1035rem;
  }
  .w-1036 {
    width: 1036rem;
  }
  .w-1037 {
    width: 1037rem;
  }
  .w-1038 {
    width: 1038rem;
  }
  .w-1039 {
    width: 1039rem;
  }
  .w-1040 {
    width: 1040rem;
  }
  .w-1041 {
    width: 1041rem;
  }
  .w-1042 {
    width: 1042rem;
  }
  .w-1043 {
    width: 1043rem;
  }
  .w-1044 {
    width: 1044rem;
  }
  .w-1045 {
    width: 1045rem;
  }
  .w-1046 {
    width: 1046rem;
  }
  .w-1047 {
    width: 1047rem;
  }
  .w-1048 {
    width: 1048rem;
  }
  .w-1049 {
    width: 1049rem;
  }
  .w-1050 {
    width: 1050rem;
  }
  .w-1051 {
    width: 1051rem;
  }
  .w-1052 {
    width: 1052rem;
  }
  .w-1053 {
    width: 1053rem;
  }
  .w-1054 {
    width: 1054rem;
  }
  .w-1055 {
    width: 1055rem;
  }
  .w-1056 {
    width: 1056rem;
  }
  .w-1057 {
    width: 1057rem;
  }
  .w-1058 {
    width: 1058rem;
  }
  .w-1059 {
    width: 1059rem;
  }
  .w-1060 {
    width: 1060rem;
  }
  .w-1061 {
    width: 1061rem;
  }
  .w-1062 {
    width: 1062rem;
  }
  .w-1063 {
    width: 1063rem;
  }
  .w-1064 {
    width: 1064rem;
  }
  .w-1065 {
    width: 1065rem;
  }
  .w-1066 {
    width: 1066rem;
  }
  .w-1067 {
    width: 1067rem;
  }
  .w-1068 {
    width: 1068rem;
  }
  .w-1069 {
    width: 1069rem;
  }
  .w-1070 {
    width: 1070rem;
  }
  .w-1071 {
    width: 1071rem;
  }
  .w-1072 {
    width: 1072rem;
  }
  .w-1073 {
    width: 1073rem;
  }
  .w-1074 {
    width: 1074rem;
  }
  .w-1075 {
    width: 1075rem;
  }
  .w-1076 {
    width: 1076rem;
  }
  .w-1077 {
    width: 1077rem;
  }
  .w-1078 {
    width: 1078rem;
  }
  .w-1079 {
    width: 1079rem;
  }
  .w-1080 {
    width: 1080rem;
  }
  .w-1081 {
    width: 1081rem;
  }
  .w-1082 {
    width: 1082rem;
  }
  .w-1083 {
    width: 1083rem;
  }
  .w-1084 {
    width: 1084rem;
  }
  .w-1085 {
    width: 1085rem;
  }
  .w-1086 {
    width: 1086rem;
  }
  .w-1087 {
    width: 1087rem;
  }
  .w-1088 {
    width: 1088rem;
  }
  .w-1089 {
    width: 1089rem;
  }
  .w-1090 {
    width: 1090rem;
  }
  .w-1091 {
    width: 1091rem;
  }
  .w-1092 {
    width: 1092rem;
  }
  .w-1093 {
    width: 1093rem;
  }
  .w-1094 {
    width: 1094rem;
  }
  .w-1095 {
    width: 1095rem;
  }
  .w-1096 {
    width: 1096rem;
  }
  .w-1097 {
    width: 1097rem;
  }
  .w-1098 {
    width: 1098rem;
  }
  .w-1099 {
    width: 1099rem;
  }
  .w-1100 {
    width: 1100rem;
  }
  .w-1101 {
    width: 1101rem;
  }
  .w-1102 {
    width: 1102rem;
  }
  .w-1103 {
    width: 1103rem;
  }
  .w-1104 {
    width: 1104rem;
  }
  .w-1105 {
    width: 1105rem;
  }
  .w-1106 {
    width: 1106rem;
  }
  .w-1107 {
    width: 1107rem;
  }
  .w-1108 {
    width: 1108rem;
  }
  .w-1109 {
    width: 1109rem;
  }
  .w-1110 {
    width: 1110rem;
  }
  .w-1111 {
    width: 1111rem;
  }
  .w-1112 {
    width: 1112rem;
  }
  .w-1113 {
    width: 1113rem;
  }
  .w-1114 {
    width: 1114rem;
  }
  .w-1115 {
    width: 1115rem;
  }
  .w-1116 {
    width: 1116rem;
  }
  .w-1117 {
    width: 1117rem;
  }
  .w-1118 {
    width: 1118rem;
  }
  .w-1119 {
    width: 1119rem;
  }
  .w-1120 {
    width: 1120rem;
  }
  .w-1121 {
    width: 1121rem;
  }
  .w-1122 {
    width: 1122rem;
  }
  .w-1123 {
    width: 1123rem;
  }
  .w-1124 {
    width: 1124rem;
  }
  .w-1125 {
    width: 1125rem;
  }
  .w-1126 {
    width: 1126rem;
  }
  .w-1127 {
    width: 1127rem;
  }
  .w-1128 {
    width: 1128rem;
  }
  .w-1129 {
    width: 1129rem;
  }
  .w-1130 {
    width: 1130rem;
  }
  .w-1131 {
    width: 1131rem;
  }
  .w-1132 {
    width: 1132rem;
  }
  .w-1133 {
    width: 1133rem;
  }
  .w-1134 {
    width: 1134rem;
  }
  .w-1135 {
    width: 1135rem;
  }
  .w-1136 {
    width: 1136rem;
  }
  .w-1137 {
    width: 1137rem;
  }
  .w-1138 {
    width: 1138rem;
  }
  .w-1139 {
    width: 1139rem;
  }
  .w-1140 {
    width: 1140rem;
  }
  .w-1141 {
    width: 1141rem;
  }
  .w-1142 {
    width: 1142rem;
  }
  .w-1143 {
    width: 1143rem;
  }
  .w-1144 {
    width: 1144rem;
  }
  .w-1145 {
    width: 1145rem;
  }
  .w-1146 {
    width: 1146rem;
  }
  .w-1147 {
    width: 1147rem;
  }
  .w-1148 {
    width: 1148rem;
  }
  .w-1149 {
    width: 1149rem;
  }
  .w-1150 {
    width: 1150rem;
  }
  .w-1151 {
    width: 1151rem;
  }
  .w-1152 {
    width: 1152rem;
  }
  .w-1153 {
    width: 1153rem;
  }
  .w-1154 {
    width: 1154rem;
  }
  .w-1155 {
    width: 1155rem;
  }
  .w-1156 {
    width: 1156rem;
  }
  .w-1157 {
    width: 1157rem;
  }
  .w-1158 {
    width: 1158rem;
  }
  .w-1159 {
    width: 1159rem;
  }
  .w-1160 {
    width: 1160rem;
  }
  .w-1161 {
    width: 1161rem;
  }
  .w-1162 {
    width: 1162rem;
  }
  .w-1163 {
    width: 1163rem;
  }
  .w-1164 {
    width: 1164rem;
  }
  .w-1165 {
    width: 1165rem;
  }
  .w-1166 {
    width: 1166rem;
  }
  .w-1167 {
    width: 1167rem;
  }
  .w-1168 {
    width: 1168rem;
  }
  .w-1169 {
    width: 1169rem;
  }
  .w-1170 {
    width: 1170rem;
  }
  .w-1171 {
    width: 1171rem;
  }
  .w-1172 {
    width: 1172rem;
  }
  .w-1173 {
    width: 1173rem;
  }
  .w-1174 {
    width: 1174rem;
  }
  .w-1175 {
    width: 1175rem;
  }
  .w-1176 {
    width: 1176rem;
  }
  .w-1177 {
    width: 1177rem;
  }
  .w-1178 {
    width: 1178rem;
  }
  .w-1179 {
    width: 1179rem;
  }
  .w-1180 {
    width: 1180rem;
  }
  .w-1181 {
    width: 1181rem;
  }
  .w-1182 {
    width: 1182rem;
  }
  .w-1183 {
    width: 1183rem;
  }
  .w-1184 {
    width: 1184rem;
  }
  .w-1185 {
    width: 1185rem;
  }
  .w-1186 {
    width: 1186rem;
  }
  .w-1187 {
    width: 1187rem;
  }
  .w-1188 {
    width: 1188rem;
  }
  .w-1189 {
    width: 1189rem;
  }
  .w-1190 {
    width: 1190rem;
  }
  .w-1191 {
    width: 1191rem;
  }
  .w-1192 {
    width: 1192rem;
  }
  .w-1193 {
    width: 1193rem;
  }
  .w-1194 {
    width: 1194rem;
  }
  .w-1195 {
    width: 1195rem;
  }
  .w-1196 {
    width: 1196rem;
  }
  .w-1197 {
    width: 1197rem;
  }
  .w-1198 {
    width: 1198rem;
  }
  .w-1199 {
    width: 1199rem;
  }
  .w-1200 {
    width: 1200rem;
  }
  .w-1201 {
    width: 1201rem;
  }
  .w-1202 {
    width: 1202rem;
  }
  .w-1203 {
    width: 1203rem;
  }
  .w-1204 {
    width: 1204rem;
  }
  .w-1205 {
    width: 1205rem;
  }
  .w-1206 {
    width: 1206rem;
  }
  .w-1207 {
    width: 1207rem;
  }
  .w-1208 {
    width: 1208rem;
  }
  .w-1209 {
    width: 1209rem;
  }
  .w-1210 {
    width: 1210rem;
  }
  .w-1211 {
    width: 1211rem;
  }
  .w-1212 {
    width: 1212rem;
  }
  .w-1213 {
    width: 1213rem;
  }
  .w-1214 {
    width: 1214rem;
  }
  .w-1215 {
    width: 1215rem;
  }
  .w-1216 {
    width: 1216rem;
  }
  .w-1217 {
    width: 1217rem;
  }
  .w-1218 {
    width: 1218rem;
  }
  .w-1219 {
    width: 1219rem;
  }
  .w-1220 {
    width: 1220rem;
  }
  .w-1221 {
    width: 1221rem;
  }
  .w-1222 {
    width: 1222rem;
  }
  .w-1223 {
    width: 1223rem;
  }
  .w-1224 {
    width: 1224rem;
  }
  .w-1225 {
    width: 1225rem;
  }
  .w-1226 {
    width: 1226rem;
  }
  .w-1227 {
    width: 1227rem;
  }
  .w-1228 {
    width: 1228rem;
  }
  .w-1229 {
    width: 1229rem;
  }
  .w-1230 {
    width: 1230rem;
  }
  .w-1231 {
    width: 1231rem;
  }
  .w-1232 {
    width: 1232rem;
  }
  .w-1233 {
    width: 1233rem;
  }
  .w-1234 {
    width: 1234rem;
  }
  .w-1235 {
    width: 1235rem;
  }
  .w-1236 {
    width: 1236rem;
  }
  .w-1237 {
    width: 1237rem;
  }
  .w-1238 {
    width: 1238rem;
  }
  .w-1239 {
    width: 1239rem;
  }
  .w-1240 {
    width: 1240rem;
  }
  .w-1241 {
    width: 1241rem;
  }
  .w-1242 {
    width: 1242rem;
  }
  .w-1243 {
    width: 1243rem;
  }
  .w-1244 {
    width: 1244rem;
  }
  .w-1245 {
    width: 1245rem;
  }
  .w-1246 {
    width: 1246rem;
  }
  .w-1247 {
    width: 1247rem;
  }
  .w-1248 {
    width: 1248rem;
  }
  .w-1249 {
    width: 1249rem;
  }
  .w-1250 {
    width: 1250rem;
  }
  .w-1251 {
    width: 1251rem;
  }
  .w-1252 {
    width: 1252rem;
  }
  .w-1253 {
    width: 1253rem;
  }
  .w-1254 {
    width: 1254rem;
  }
  .w-1255 {
    width: 1255rem;
  }
  .w-1256 {
    width: 1256rem;
  }
  .w-1257 {
    width: 1257rem;
  }
  .w-1258 {
    width: 1258rem;
  }
  .w-1259 {
    width: 1259rem;
  }
  .w-1260 {
    width: 1260rem;
  }
  .w-1261 {
    width: 1261rem;
  }
  .w-1262 {
    width: 1262rem;
  }
  .w-1263 {
    width: 1263rem;
  }
  .w-1264 {
    width: 1264rem;
  }
  .w-1265 {
    width: 1265rem;
  }
  .w-1266 {
    width: 1266rem;
  }
  .w-1267 {
    width: 1267rem;
  }
  .w-1268 {
    width: 1268rem;
  }
  .w-1269 {
    width: 1269rem;
  }
  .w-1270 {
    width: 1270rem;
  }
  .w-1271 {
    width: 1271rem;
  }
  .w-1272 {
    width: 1272rem;
  }
  .w-1273 {
    width: 1273rem;
  }
  .w-1274 {
    width: 1274rem;
  }
  .w-1275 {
    width: 1275rem;
  }
  .w-1276 {
    width: 1276rem;
  }
  .w-1277 {
    width: 1277rem;
  }
  .w-1278 {
    width: 1278rem;
  }
  .w-1279 {
    width: 1279rem;
  }
  .w-1280 {
    width: 1280rem;
  }
  .w-1281 {
    width: 1281rem;
  }
  .w-1282 {
    width: 1282rem;
  }
  .w-1283 {
    width: 1283rem;
  }
  .w-1284 {
    width: 1284rem;
  }
  .w-1285 {
    width: 1285rem;
  }
  .w-1286 {
    width: 1286rem;
  }
  .w-1287 {
    width: 1287rem;
  }
  .w-1288 {
    width: 1288rem;
  }
  .w-1289 {
    width: 1289rem;
  }
  .w-1290 {
    width: 1290rem;
  }
  .w-1291 {
    width: 1291rem;
  }
  .w-1292 {
    width: 1292rem;
  }
  .w-1293 {
    width: 1293rem;
  }
  .w-1294 {
    width: 1294rem;
  }
  .w-1295 {
    width: 1295rem;
  }
  .w-1296 {
    width: 1296rem;
  }
  .w-1297 {
    width: 1297rem;
  }
  .w-1298 {
    width: 1298rem;
  }
  .w-1299 {
    width: 1299rem;
  }
  .w-1300 {
    width: 1300rem;
  }
  .w-1301 {
    width: 1301rem;
  }
  .w-1302 {
    width: 1302rem;
  }
  .w-1303 {
    width: 1303rem;
  }
  .w-1304 {
    width: 1304rem;
  }
  .w-1305 {
    width: 1305rem;
  }
  .w-1306 {
    width: 1306rem;
  }
  .w-1307 {
    width: 1307rem;
  }
  .w-1308 {
    width: 1308rem;
  }
  .w-1309 {
    width: 1309rem;
  }
  .w-1310 {
    width: 1310rem;
  }
  .w-1311 {
    width: 1311rem;
  }
  .w-1312 {
    width: 1312rem;
  }
  .w-1313 {
    width: 1313rem;
  }
  .w-1314 {
    width: 1314rem;
  }
  .w-1315 {
    width: 1315rem;
  }
  .w-1316 {
    width: 1316rem;
  }
  .w-1317 {
    width: 1317rem;
  }
  .w-1318 {
    width: 1318rem;
  }
  .w-1319 {
    width: 1319rem;
  }
  .w-1320 {
    width: 1320rem;
  }
  .w-1321 {
    width: 1321rem;
  }
  .w-1322 {
    width: 1322rem;
  }
  .w-1323 {
    width: 1323rem;
  }
  .w-1324 {
    width: 1324rem;
  }
  .w-1325 {
    width: 1325rem;
  }
  .w-1326 {
    width: 1326rem;
  }
  .w-1327 {
    width: 1327rem;
  }
  .w-1328 {
    width: 1328rem;
  }
  .w-1329 {
    width: 1329rem;
  }
  .w-1330 {
    width: 1330rem;
  }
  .w-1331 {
    width: 1331rem;
  }
  .w-1332 {
    width: 1332rem;
  }
  .w-1333 {
    width: 1333rem;
  }
  .w-1334 {
    width: 1334rem;
  }
  .w-1335 {
    width: 1335rem;
  }
  .w-1336 {
    width: 1336rem;
  }
  .w-1337 {
    width: 1337rem;
  }
  .w-1338 {
    width: 1338rem;
  }
  .w-1339 {
    width: 1339rem;
  }
  .w-1340 {
    width: 1340rem;
  }
  .w-1341 {
    width: 1341rem;
  }
  .w-1342 {
    width: 1342rem;
  }
  .w-1343 {
    width: 1343rem;
  }
  .w-1344 {
    width: 1344rem;
  }
  .w-1345 {
    width: 1345rem;
  }
  .w-1346 {
    width: 1346rem;
  }
  .w-1347 {
    width: 1347rem;
  }
  .w-1348 {
    width: 1348rem;
  }
  .w-1349 {
    width: 1349rem;
  }
  .w-1350 {
    width: 1350rem;
  }
  .w-1351 {
    width: 1351rem;
  }
  .w-1352 {
    width: 1352rem;
  }
  .w-1353 {
    width: 1353rem;
  }
  .w-1354 {
    width: 1354rem;
  }
  .w-1355 {
    width: 1355rem;
  }
  .w-1356 {
    width: 1356rem;
  }
  .w-1357 {
    width: 1357rem;
  }
  .w-1358 {
    width: 1358rem;
  }
  .w-1359 {
    width: 1359rem;
  }
  .w-1360 {
    width: 1360rem;
  }
  .w-1361 {
    width: 1361rem;
  }
  .w-1362 {
    width: 1362rem;
  }
  .w-1363 {
    width: 1363rem;
  }
  .w-1364 {
    width: 1364rem;
  }
  .w-1365 {
    width: 1365rem;
  }
  .w-1366 {
    width: 1366rem;
  }
  .w-1367 {
    width: 1367rem;
  }
  .w-1368 {
    width: 1368rem;
  }
  .w-1369 {
    width: 1369rem;
  }
  .w-1370 {
    width: 1370rem;
  }
  .w-1371 {
    width: 1371rem;
  }
  .w-1372 {
    width: 1372rem;
  }
  .w-1373 {
    width: 1373rem;
  }
  .w-1374 {
    width: 1374rem;
  }
  .w-1375 {
    width: 1375rem;
  }
  .w-1376 {
    width: 1376rem;
  }
  .w-1377 {
    width: 1377rem;
  }
  .w-1378 {
    width: 1378rem;
  }
  .w-1379 {
    width: 1379rem;
  }
  .w-1380 {
    width: 1380rem;
  }
  .w-1381 {
    width: 1381rem;
  }
  .w-1382 {
    width: 1382rem;
  }
  .w-1383 {
    width: 1383rem;
  }
  .w-1384 {
    width: 1384rem;
  }
  .w-1385 {
    width: 1385rem;
  }
  .w-1386 {
    width: 1386rem;
  }
  .w-1387 {
    width: 1387rem;
  }
  .w-1388 {
    width: 1388rem;
  }
  .w-1389 {
    width: 1389rem;
  }
  .w-1390 {
    width: 1390rem;
  }
  .w-1391 {
    width: 1391rem;
  }
  .w-1392 {
    width: 1392rem;
  }
  .w-1393 {
    width: 1393rem;
  }
  .w-1394 {
    width: 1394rem;
  }
  .w-1395 {
    width: 1395rem;
  }
  .w-1396 {
    width: 1396rem;
  }
  .w-1397 {
    width: 1397rem;
  }
  .w-1398 {
    width: 1398rem;
  }
  .w-1399 {
    width: 1399rem;
  }
  .w-1400 {
    width: 1400rem;
  }
  .w-1401 {
    width: 1401rem;
  }
  .w-1402 {
    width: 1402rem;
  }
  .w-1403 {
    width: 1403rem;
  }
  .w-1404 {
    width: 1404rem;
  }
  .w-1405 {
    width: 1405rem;
  }
  .w-1406 {
    width: 1406rem;
  }
  .w-1407 {
    width: 1407rem;
  }
  .w-1408 {
    width: 1408rem;
  }
  .w-1409 {
    width: 1409rem;
  }
  .w-1410 {
    width: 1410rem;
  }
  .w-1411 {
    width: 1411rem;
  }
  .w-1412 {
    width: 1412rem;
  }
  .w-1413 {
    width: 1413rem;
  }
  .w-1414 {
    width: 1414rem;
  }
  .w-1415 {
    width: 1415rem;
  }
  .w-1416 {
    width: 1416rem;
  }
  .w-1417 {
    width: 1417rem;
  }
  .w-1418 {
    width: 1418rem;
  }
  .w-1419 {
    width: 1419rem;
  }
  .w-1420 {
    width: 1420rem;
  }
  .w-1421 {
    width: 1421rem;
  }
  .w-1422 {
    width: 1422rem;
  }
  .w-1423 {
    width: 1423rem;
  }
  .w-1424 {
    width: 1424rem;
  }
  .w-1425 {
    width: 1425rem;
  }
  .w-1426 {
    width: 1426rem;
  }
  .w-1427 {
    width: 1427rem;
  }
  .w-1428 {
    width: 1428rem;
  }
  .w-1429 {
    width: 1429rem;
  }
  .w-1430 {
    width: 1430rem;
  }
  .w-1431 {
    width: 1431rem;
  }
  .w-1432 {
    width: 1432rem;
  }
  .w-1433 {
    width: 1433rem;
  }
  .w-1434 {
    width: 1434rem;
  }
  .w-1435 {
    width: 1435rem;
  }
  .w-1436 {
    width: 1436rem;
  }
  .w-1437 {
    width: 1437rem;
  }
  .w-1438 {
    width: 1438rem;
  }
  .w-1439 {
    width: 1439rem;
  }
  .w-1440 {
    width: 1440rem;
  }
  .w-1441 {
    width: 1441rem;
  }
  .w-1442 {
    width: 1442rem;
  }
  .w-1443 {
    width: 1443rem;
  }
  .w-1444 {
    width: 1444rem;
  }
  .w-1445 {
    width: 1445rem;
  }
  .w-1446 {
    width: 1446rem;
  }
  .w-1447 {
    width: 1447rem;
  }
  .w-1448 {
    width: 1448rem;
  }
  .w-1449 {
    width: 1449rem;
  }
  .w-1450 {
    width: 1450rem;
  }
  .w-1451 {
    width: 1451rem;
  }
  .w-1452 {
    width: 1452rem;
  }
  .w-1453 {
    width: 1453rem;
  }
  .w-1454 {
    width: 1454rem;
  }
  .w-1455 {
    width: 1455rem;
  }
  .w-1456 {
    width: 1456rem;
  }
  .w-1457 {
    width: 1457rem;
  }
  .w-1458 {
    width: 1458rem;
  }
  .w-1459 {
    width: 1459rem;
  }
  .w-1460 {
    width: 1460rem;
  }
  .w-1461 {
    width: 1461rem;
  }
  .w-1462 {
    width: 1462rem;
  }
  .w-1463 {
    width: 1463rem;
  }
  .w-1464 {
    width: 1464rem;
  }
  .w-1465 {
    width: 1465rem;
  }
  .w-1466 {
    width: 1466rem;
  }
  .w-1467 {
    width: 1467rem;
  }
  .w-1468 {
    width: 1468rem;
  }
  .w-1469 {
    width: 1469rem;
  }
  .w-1470 {
    width: 1470rem;
  }
  .w-1471 {
    width: 1471rem;
  }
  .w-1472 {
    width: 1472rem;
  }
  .w-1473 {
    width: 1473rem;
  }
  .w-1474 {
    width: 1474rem;
  }
  .w-1475 {
    width: 1475rem;
  }
  .w-1476 {
    width: 1476rem;
  }
  .w-1477 {
    width: 1477rem;
  }
  .w-1478 {
    width: 1478rem;
  }
  .w-1479 {
    width: 1479rem;
  }
  .w-1480 {
    width: 1480rem;
  }
  .w-1481 {
    width: 1481rem;
  }
  .w-1482 {
    width: 1482rem;
  }
  .w-1483 {
    width: 1483rem;
  }
  .w-1484 {
    width: 1484rem;
  }
  .w-1485 {
    width: 1485rem;
  }
  .w-1486 {
    width: 1486rem;
  }
  .w-1487 {
    width: 1487rem;
  }
  .w-1488 {
    width: 1488rem;
  }
  .w-1489 {
    width: 1489rem;
  }
  .w-1490 {
    width: 1490rem;
  }
  .w-1491 {
    width: 1491rem;
  }
  .w-1492 {
    width: 1492rem;
  }
  .w-1493 {
    width: 1493rem;
  }
  .w-1494 {
    width: 1494rem;
  }
  .w-1495 {
    width: 1495rem;
  }
  .w-1496 {
    width: 1496rem;
  }
  .w-1497 {
    width: 1497rem;
  }
  .w-1498 {
    width: 1498rem;
  }
  .w-1499 {
    width: 1499rem;
  }
  .w-1500 {
    width: 1500rem;
  }
  .w-1501 {
    width: 1501rem;
  }
  .w-1502 {
    width: 1502rem;
  }
  .w-1503 {
    width: 1503rem;
  }
  .w-1504 {
    width: 1504rem;
  }
  .w-1505 {
    width: 1505rem;
  }
  .w-1506 {
    width: 1506rem;
  }
  .w-1507 {
    width: 1507rem;
  }
  .w-1508 {
    width: 1508rem;
  }
  .w-1509 {
    width: 1509rem;
  }
  .w-1510 {
    width: 1510rem;
  }
  .w-1511 {
    width: 1511rem;
  }
  .w-1512 {
    width: 1512rem;
  }
  .w-1513 {
    width: 1513rem;
  }
  .w-1514 {
    width: 1514rem;
  }
  .w-1515 {
    width: 1515rem;
  }
  .w-1516 {
    width: 1516rem;
  }
  .w-1517 {
    width: 1517rem;
  }
  .w-1518 {
    width: 1518rem;
  }
  .w-1519 {
    width: 1519rem;
  }
  .w-1520 {
    width: 1520rem;
  }
  .w-1521 {
    width: 1521rem;
  }
  .w-1522 {
    width: 1522rem;
  }
  .w-1523 {
    width: 1523rem;
  }
  .w-1524 {
    width: 1524rem;
  }
  .w-1525 {
    width: 1525rem;
  }
  .w-1526 {
    width: 1526rem;
  }
  .w-1527 {
    width: 1527rem;
  }
  .w-1528 {
    width: 1528rem;
  }
  .w-1529 {
    width: 1529rem;
  }
  .w-1530 {
    width: 1530rem;
  }
  .w-1531 {
    width: 1531rem;
  }
  .w-1532 {
    width: 1532rem;
  }
  .w-1533 {
    width: 1533rem;
  }
  .w-1534 {
    width: 1534rem;
  }
  .w-1535 {
    width: 1535rem;
  }
  .w-1536 {
    width: 1536rem;
  }
  .w-1537 {
    width: 1537rem;
  }
  .w-1538 {
    width: 1538rem;
  }
  .w-1539 {
    width: 1539rem;
  }
  .w-1540 {
    width: 1540rem;
  }
  .w-1541 {
    width: 1541rem;
  }
  .w-1542 {
    width: 1542rem;
  }
  .w-1543 {
    width: 1543rem;
  }
  .w-1544 {
    width: 1544rem;
  }
  .w-1545 {
    width: 1545rem;
  }
  .w-1546 {
    width: 1546rem;
  }
  .w-1547 {
    width: 1547rem;
  }
  .w-1548 {
    width: 1548rem;
  }
  .w-1549 {
    width: 1549rem;
  }
  .w-1550 {
    width: 1550rem;
  }
  .w-1551 {
    width: 1551rem;
  }
  .w-1552 {
    width: 1552rem;
  }
  .w-1553 {
    width: 1553rem;
  }
  .w-1554 {
    width: 1554rem;
  }
  .w-1555 {
    width: 1555rem;
  }
  .w-1556 {
    width: 1556rem;
  }
  .w-1557 {
    width: 1557rem;
  }
  .w-1558 {
    width: 1558rem;
  }
  .w-1559 {
    width: 1559rem;
  }
  .w-1560 {
    width: 1560rem;
  }
  .w-1561 {
    width: 1561rem;
  }
  .w-1562 {
    width: 1562rem;
  }
  .w-1563 {
    width: 1563rem;
  }
  .w-1564 {
    width: 1564rem;
  }
  .w-1565 {
    width: 1565rem;
  }
  .w-1566 {
    width: 1566rem;
  }
  .w-1567 {
    width: 1567rem;
  }
  .w-1568 {
    width: 1568rem;
  }
  .w-1569 {
    width: 1569rem;
  }
  .w-1570 {
    width: 1570rem;
  }
  .w-1571 {
    width: 1571rem;
  }
  .w-1572 {
    width: 1572rem;
  }
  .w-1573 {
    width: 1573rem;
  }
  .w-1574 {
    width: 1574rem;
  }
  .w-1575 {
    width: 1575rem;
  }
  .w-1576 {
    width: 1576rem;
  }
  .w-1577 {
    width: 1577rem;
  }
  .w-1578 {
    width: 1578rem;
  }
  .w-1579 {
    width: 1579rem;
  }
  .w-1580 {
    width: 1580rem;
  }
  .w-1581 {
    width: 1581rem;
  }
  .w-1582 {
    width: 1582rem;
  }
  .w-1583 {
    width: 1583rem;
  }
  .w-1584 {
    width: 1584rem;
  }
  .w-1585 {
    width: 1585rem;
  }
  .w-1586 {
    width: 1586rem;
  }
  .w-1587 {
    width: 1587rem;
  }
  .w-1588 {
    width: 1588rem;
  }
  .w-1589 {
    width: 1589rem;
  }
  .w-1590 {
    width: 1590rem;
  }
  .w-1591 {
    width: 1591rem;
  }
  .w-1592 {
    width: 1592rem;
  }
  .w-1593 {
    width: 1593rem;
  }
  .w-1594 {
    width: 1594rem;
  }
  .w-1595 {
    width: 1595rem;
  }
  .w-1596 {
    width: 1596rem;
  }
  .w-1597 {
    width: 1597rem;
  }
  .w-1598 {
    width: 1598rem;
  }
  .w-1599 {
    width: 1599rem;
  }
  .w-1600 {
    width: 1600rem;
  }
  .w-1601 {
    width: 1601rem;
  }
  .w-1602 {
    width: 1602rem;
  }
  .w-1603 {
    width: 1603rem;
  }
  .w-1604 {
    width: 1604rem;
  }
  .w-1605 {
    width: 1605rem;
  }
  .w-1606 {
    width: 1606rem;
  }
  .w-1607 {
    width: 1607rem;
  }
  .w-1608 {
    width: 1608rem;
  }
  .w-1609 {
    width: 1609rem;
  }
  .w-1610 {
    width: 1610rem;
  }
  .w-1611 {
    width: 1611rem;
  }
  .w-1612 {
    width: 1612rem;
  }
  .w-1613 {
    width: 1613rem;
  }
  .w-1614 {
    width: 1614rem;
  }
  .w-1615 {
    width: 1615rem;
  }
  .w-1616 {
    width: 1616rem;
  }
  .w-1617 {
    width: 1617rem;
  }
  .w-1618 {
    width: 1618rem;
  }
  .w-1619 {
    width: 1619rem;
  }
  .w-1620 {
    width: 1620rem;
  }
  .w-1621 {
    width: 1621rem;
  }
  .w-1622 {
    width: 1622rem;
  }
  .w-1623 {
    width: 1623rem;
  }
  .w-1624 {
    width: 1624rem;
  }
  .w-1625 {
    width: 1625rem;
  }
  .w-1626 {
    width: 1626rem;
  }
  .w-1627 {
    width: 1627rem;
  }
  .w-1628 {
    width: 1628rem;
  }
  .w-1629 {
    width: 1629rem;
  }
  .w-1630 {
    width: 1630rem;
  }
  .w-1631 {
    width: 1631rem;
  }
  .w-1632 {
    width: 1632rem;
  }
  .w-1633 {
    width: 1633rem;
  }
  .w-1634 {
    width: 1634rem;
  }
  .w-1635 {
    width: 1635rem;
  }
  .w-1636 {
    width: 1636rem;
  }
  .w-1637 {
    width: 1637rem;
  }
  .w-1638 {
    width: 1638rem;
  }
  .w-1639 {
    width: 1639rem;
  }
  .w-1640 {
    width: 1640rem;
  }
  .w-1641 {
    width: 1641rem;
  }
  .w-1642 {
    width: 1642rem;
  }
  .w-1643 {
    width: 1643rem;
  }
  .w-1644 {
    width: 1644rem;
  }
  .w-1645 {
    width: 1645rem;
  }
  .w-1646 {
    width: 1646rem;
  }
  .w-1647 {
    width: 1647rem;
  }
  .w-1648 {
    width: 1648rem;
  }
  .w-1649 {
    width: 1649rem;
  }
  .w-1650 {
    width: 1650rem;
  }
  .w-1651 {
    width: 1651rem;
  }
  .w-1652 {
    width: 1652rem;
  }
  .w-1653 {
    width: 1653rem;
  }
  .w-1654 {
    width: 1654rem;
  }
  .w-1655 {
    width: 1655rem;
  }
  .w-1656 {
    width: 1656rem;
  }
  .w-1657 {
    width: 1657rem;
  }
  .w-1658 {
    width: 1658rem;
  }
  .w-1659 {
    width: 1659rem;
  }
  .w-1660 {
    width: 1660rem;
  }
  .w-1661 {
    width: 1661rem;
  }
  .w-1662 {
    width: 1662rem;
  }
  .w-1663 {
    width: 1663rem;
  }
  .w-1664 {
    width: 1664rem;
  }
  .w-1665 {
    width: 1665rem;
  }
  .w-1666 {
    width: 1666rem;
  }
  .w-1667 {
    width: 1667rem;
  }
  .w-1668 {
    width: 1668rem;
  }
  .w-1669 {
    width: 1669rem;
  }
  .w-1670 {
    width: 1670rem;
  }
  .w-1671 {
    width: 1671rem;
  }
  .w-1672 {
    width: 1672rem;
  }
  .w-1673 {
    width: 1673rem;
  }
  .w-1674 {
    width: 1674rem;
  }
  .w-1675 {
    width: 1675rem;
  }
  .w-1676 {
    width: 1676rem;
  }
  .w-1677 {
    width: 1677rem;
  }
  .w-1678 {
    width: 1678rem;
  }
  .w-1679 {
    width: 1679rem;
  }
  .w-1680 {
    width: 1680rem;
  }
  .w-1681 {
    width: 1681rem;
  }
  .w-1682 {
    width: 1682rem;
  }
  .w-1683 {
    width: 1683rem;
  }
  .w-1684 {
    width: 1684rem;
  }
  .w-1685 {
    width: 1685rem;
  }
  .w-1686 {
    width: 1686rem;
  }
  .w-1687 {
    width: 1687rem;
  }
  .w-1688 {
    width: 1688rem;
  }
  .w-1689 {
    width: 1689rem;
  }
  .w-1690 {
    width: 1690rem;
  }
  .w-1691 {
    width: 1691rem;
  }
  .w-1692 {
    width: 1692rem;
  }
  .w-1693 {
    width: 1693rem;
  }
  .w-1694 {
    width: 1694rem;
  }
  .w-1695 {
    width: 1695rem;
  }
  .w-1696 {
    width: 1696rem;
  }
  .w-1697 {
    width: 1697rem;
  }
  .w-1698 {
    width: 1698rem;
  }
  .w-1699 {
    width: 1699rem;
  }
  .w-1700 {
    width: 1700rem;
  }
  .w-1701 {
    width: 1701rem;
  }
  .w-1702 {
    width: 1702rem;
  }
  .w-1703 {
    width: 1703rem;
  }
  .w-1704 {
    width: 1704rem;
  }
  .w-1705 {
    width: 1705rem;
  }
  .w-1706 {
    width: 1706rem;
  }
  .w-1707 {
    width: 1707rem;
  }
  .w-1708 {
    width: 1708rem;
  }
  .w-1709 {
    width: 1709rem;
  }
  .w-1710 {
    width: 1710rem;
  }
  .w-1711 {
    width: 1711rem;
  }
  .w-1712 {
    width: 1712rem;
  }
  .w-1713 {
    width: 1713rem;
  }
  .w-1714 {
    width: 1714rem;
  }
  .w-1715 {
    width: 1715rem;
  }
  .w-1716 {
    width: 1716rem;
  }
  .w-1717 {
    width: 1717rem;
  }
  .w-1718 {
    width: 1718rem;
  }
  .w-1719 {
    width: 1719rem;
  }
  .w-1720 {
    width: 1720rem;
  }
  .w-1721 {
    width: 1721rem;
  }
  .w-1722 {
    width: 1722rem;
  }
  .w-1723 {
    width: 1723rem;
  }
  .w-1724 {
    width: 1724rem;
  }
  .w-1725 {
    width: 1725rem;
  }
  .w-1726 {
    width: 1726rem;
  }
  .w-1727 {
    width: 1727rem;
  }
  .w-1728 {
    width: 1728rem;
  }
  .w-1729 {
    width: 1729rem;
  }
  .w-1730 {
    width: 1730rem;
  }
  .w-1731 {
    width: 1731rem;
  }
  .w-1732 {
    width: 1732rem;
  }
  .w-1733 {
    width: 1733rem;
  }
  .w-1734 {
    width: 1734rem;
  }
  .w-1735 {
    width: 1735rem;
  }
  .w-1736 {
    width: 1736rem;
  }
  .w-1737 {
    width: 1737rem;
  }
  .w-1738 {
    width: 1738rem;
  }
  .w-1739 {
    width: 1739rem;
  }
  .w-1740 {
    width: 1740rem;
  }
  .w-1741 {
    width: 1741rem;
  }
  .w-1742 {
    width: 1742rem;
  }
  .w-1743 {
    width: 1743rem;
  }
  .w-1744 {
    width: 1744rem;
  }
  .w-1745 {
    width: 1745rem;
  }
  .w-1746 {
    width: 1746rem;
  }
  .w-1747 {
    width: 1747rem;
  }
  .w-1748 {
    width: 1748rem;
  }
  .w-1749 {
    width: 1749rem;
  }
  .w-1750 {
    width: 1750rem;
  }
  .w-1751 {
    width: 1751rem;
  }
  .w-1752 {
    width: 1752rem;
  }
  .w-1753 {
    width: 1753rem;
  }
  .w-1754 {
    width: 1754rem;
  }
  .w-1755 {
    width: 1755rem;
  }
  .w-1756 {
    width: 1756rem;
  }
  .w-1757 {
    width: 1757rem;
  }
  .w-1758 {
    width: 1758rem;
  }
  .w-1759 {
    width: 1759rem;
  }
  .w-1760 {
    width: 1760rem;
  }
  .w-1761 {
    width: 1761rem;
  }
  .w-1762 {
    width: 1762rem;
  }
  .w-1763 {
    width: 1763rem;
  }
  .w-1764 {
    width: 1764rem;
  }
  .w-1765 {
    width: 1765rem;
  }
  .w-1766 {
    width: 1766rem;
  }
  .w-1767 {
    width: 1767rem;
  }
  .w-1768 {
    width: 1768rem;
  }
  .w-1769 {
    width: 1769rem;
  }
  .w-1770 {
    width: 1770rem;
  }
  .w-1771 {
    width: 1771rem;
  }
  .w-1772 {
    width: 1772rem;
  }
  .w-1773 {
    width: 1773rem;
  }
  .w-1774 {
    width: 1774rem;
  }
  .w-1775 {
    width: 1775rem;
  }
  .w-1776 {
    width: 1776rem;
  }
  .w-1777 {
    width: 1777rem;
  }
  .w-1778 {
    width: 1778rem;
  }
  .w-1779 {
    width: 1779rem;
  }
  .w-1780 {
    width: 1780rem;
  }
  .w-1781 {
    width: 1781rem;
  }
  .w-1782 {
    width: 1782rem;
  }
  .w-1783 {
    width: 1783rem;
  }
  .w-1784 {
    width: 1784rem;
  }
  .w-1785 {
    width: 1785rem;
  }
  .w-1786 {
    width: 1786rem;
  }
  .w-1787 {
    width: 1787rem;
  }
  .w-1788 {
    width: 1788rem;
  }
  .w-1789 {
    width: 1789rem;
  }
  .w-1790 {
    width: 1790rem;
  }
  .w-1791 {
    width: 1791rem;
  }
  .w-1792 {
    width: 1792rem;
  }
  .w-1793 {
    width: 1793rem;
  }
  .w-1794 {
    width: 1794rem;
  }
  .w-1795 {
    width: 1795rem;
  }
  .w-1796 {
    width: 1796rem;
  }
  .w-1797 {
    width: 1797rem;
  }
  .w-1798 {
    width: 1798rem;
  }
  .w-1799 {
    width: 1799rem;
  }
  .w-1800 {
    width: 1800rem;
  }
  .w-1801 {
    width: 1801rem;
  }
  .w-1802 {
    width: 1802rem;
  }
  .w-1803 {
    width: 1803rem;
  }
  .w-1804 {
    width: 1804rem;
  }
  .w-1805 {
    width: 1805rem;
  }
  .w-1806 {
    width: 1806rem;
  }
  .w-1807 {
    width: 1807rem;
  }
  .w-1808 {
    width: 1808rem;
  }
  .w-1809 {
    width: 1809rem;
  }
  .w-1810 {
    width: 1810rem;
  }
  .w-1811 {
    width: 1811rem;
  }
  .w-1812 {
    width: 1812rem;
  }
  .w-1813 {
    width: 1813rem;
  }
  .w-1814 {
    width: 1814rem;
  }
  .w-1815 {
    width: 1815rem;
  }
  .w-1816 {
    width: 1816rem;
  }
  .w-1817 {
    width: 1817rem;
  }
  .w-1818 {
    width: 1818rem;
  }
  .w-1819 {
    width: 1819rem;
  }
  .w-1820 {
    width: 1820rem;
  }
  .w-1821 {
    width: 1821rem;
  }
  .w-1822 {
    width: 1822rem;
  }
  .w-1823 {
    width: 1823rem;
  }
  .w-1824 {
    width: 1824rem;
  }
  .w-1825 {
    width: 1825rem;
  }
  .w-1826 {
    width: 1826rem;
  }
  .w-1827 {
    width: 1827rem;
  }
  .w-1828 {
    width: 1828rem;
  }
  .w-1829 {
    width: 1829rem;
  }
  .w-1830 {
    width: 1830rem;
  }
  .w-1831 {
    width: 1831rem;
  }
  .w-1832 {
    width: 1832rem;
  }
  .w-1833 {
    width: 1833rem;
  }
  .w-1834 {
    width: 1834rem;
  }
  .w-1835 {
    width: 1835rem;
  }
  .w-1836 {
    width: 1836rem;
  }
  .w-1837 {
    width: 1837rem;
  }
  .w-1838 {
    width: 1838rem;
  }
  .w-1839 {
    width: 1839rem;
  }
  .w-1840 {
    width: 1840rem;
  }
  .w-1841 {
    width: 1841rem;
  }
  .w-1842 {
    width: 1842rem;
  }
  .w-1843 {
    width: 1843rem;
  }
  .w-1844 {
    width: 1844rem;
  }
  .w-1845 {
    width: 1845rem;
  }
  .w-1846 {
    width: 1846rem;
  }
  .w-1847 {
    width: 1847rem;
  }
  .w-1848 {
    width: 1848rem;
  }
  .w-1849 {
    width: 1849rem;
  }
  .w-1850 {
    width: 1850rem;
  }
  .w-1851 {
    width: 1851rem;
  }
  .w-1852 {
    width: 1852rem;
  }
  .w-1853 {
    width: 1853rem;
  }
  .w-1854 {
    width: 1854rem;
  }
  .w-1855 {
    width: 1855rem;
  }
  .w-1856 {
    width: 1856rem;
  }
  .w-1857 {
    width: 1857rem;
  }
  .w-1858 {
    width: 1858rem;
  }
  .w-1859 {
    width: 1859rem;
  }
  .w-1860 {
    width: 1860rem;
  }
  .w-1861 {
    width: 1861rem;
  }
  .w-1862 {
    width: 1862rem;
  }
  .w-1863 {
    width: 1863rem;
  }
  .w-1864 {
    width: 1864rem;
  }
  .w-1865 {
    width: 1865rem;
  }
  .w-1866 {
    width: 1866rem;
  }
  .w-1867 {
    width: 1867rem;
  }
  .w-1868 {
    width: 1868rem;
  }
  .w-1869 {
    width: 1869rem;
  }
  .w-1870 {
    width: 1870rem;
  }
  .w-1871 {
    width: 1871rem;
  }
  .w-1872 {
    width: 1872rem;
  }
  .w-1873 {
    width: 1873rem;
  }
  .w-1874 {
    width: 1874rem;
  }
  .w-1875 {
    width: 1875rem;
  }
  .w-1876 {
    width: 1876rem;
  }
  .w-1877 {
    width: 1877rem;
  }
  .w-1878 {
    width: 1878rem;
  }
  .w-1879 {
    width: 1879rem;
  }
  .w-1880 {
    width: 1880rem;
  }
  .w-1881 {
    width: 1881rem;
  }
  .w-1882 {
    width: 1882rem;
  }
  .w-1883 {
    width: 1883rem;
  }
  .w-1884 {
    width: 1884rem;
  }
  .w-1885 {
    width: 1885rem;
  }
  .w-1886 {
    width: 1886rem;
  }
  .w-1887 {
    width: 1887rem;
  }
  .w-1888 {
    width: 1888rem;
  }
  .w-1889 {
    width: 1889rem;
  }
  .w-1890 {
    width: 1890rem;
  }
  .w-1891 {
    width: 1891rem;
  }
  .w-1892 {
    width: 1892rem;
  }
  .w-1893 {
    width: 1893rem;
  }
  .w-1894 {
    width: 1894rem;
  }
  .w-1895 {
    width: 1895rem;
  }
  .w-1896 {
    width: 1896rem;
  }
  .w-1897 {
    width: 1897rem;
  }
  .w-1898 {
    width: 1898rem;
  }
  .w-1899 {
    width: 1899rem;
  }
  .w-1900 {
    width: 1900rem;
  }
  .w-1901 {
    width: 1901rem;
  }
  .w-1902 {
    width: 1902rem;
  }
  .w-1903 {
    width: 1903rem;
  }
  .w-1904 {
    width: 1904rem;
  }
  .w-1905 {
    width: 1905rem;
  }
  .w-1906 {
    width: 1906rem;
  }
  .w-1907 {
    width: 1907rem;
  }
  .w-1908 {
    width: 1908rem;
  }
  .w-1909 {
    width: 1909rem;
  }
  .w-1910 {
    width: 1910rem;
  }
  .w-1911 {
    width: 1911rem;
  }
  .w-1912 {
    width: 1912rem;
  }
  .w-1913 {
    width: 1913rem;
  }
  .w-1914 {
    width: 1914rem;
  }
  .w-1915 {
    width: 1915rem;
  }
  .w-1916 {
    width: 1916rem;
  }
  .w-1917 {
    width: 1917rem;
  }
  .w-1918 {
    width: 1918rem;
  }
  .w-1919 {
    width: 1919rem;
  }
  .w-1920 {
    width: 1920rem;
  }
  .w-1921 {
    width: 1921rem;
  }
  .w-1922 {
    width: 1922rem;
  }
  .w-1923 {
    width: 1923rem;
  }
  .w-1924 {
    width: 1924rem;
  }
  .w-1925 {
    width: 1925rem;
  }
  .w-1926 {
    width: 1926rem;
  }
  .w-1927 {
    width: 1927rem;
  }
  .w-1928 {
    width: 1928rem;
  }
  .w-1929 {
    width: 1929rem;
  }
  .w-1930 {
    width: 1930rem;
  }
  .w-1931 {
    width: 1931rem;
  }
  .w-1932 {
    width: 1932rem;
  }
  .w-1933 {
    width: 1933rem;
  }
  .w-1934 {
    width: 1934rem;
  }
  .w-1935 {
    width: 1935rem;
  }
  .w-1936 {
    width: 1936rem;
  }
  .w-1937 {
    width: 1937rem;
  }
  .w-1938 {
    width: 1938rem;
  }
  .w-1939 {
    width: 1939rem;
  }
  .w-1940 {
    width: 1940rem;
  }
  .w-1941 {
    width: 1941rem;
  }
  .w-1942 {
    width: 1942rem;
  }
  .w-1943 {
    width: 1943rem;
  }
  .w-1944 {
    width: 1944rem;
  }
  .w-1945 {
    width: 1945rem;
  }
  .w-1946 {
    width: 1946rem;
  }
  .w-1947 {
    width: 1947rem;
  }
  .w-1948 {
    width: 1948rem;
  }
  .w-1949 {
    width: 1949rem;
  }
  .w-1950 {
    width: 1950rem;
  }
  .w-1951 {
    width: 1951rem;
  }
  .w-1952 {
    width: 1952rem;
  }
  .w-1953 {
    width: 1953rem;
  }
  .w-1954 {
    width: 1954rem;
  }
  .w-1955 {
    width: 1955rem;
  }
  .w-1956 {
    width: 1956rem;
  }
  .w-1957 {
    width: 1957rem;
  }
  .w-1958 {
    width: 1958rem;
  }
  .w-1959 {
    width: 1959rem;
  }
  .w-1960 {
    width: 1960rem;
  }
  .w-1961 {
    width: 1961rem;
  }
  .w-1962 {
    width: 1962rem;
  }
  .w-1963 {
    width: 1963rem;
  }
  .w-1964 {
    width: 1964rem;
  }
  .w-1965 {
    width: 1965rem;
  }
  .w-1966 {
    width: 1966rem;
  }
  .w-1967 {
    width: 1967rem;
  }
  .w-1968 {
    width: 1968rem;
  }
  .w-1969 {
    width: 1969rem;
  }
  .w-1970 {
    width: 1970rem;
  }
  .w-1971 {
    width: 1971rem;
  }
  .w-1972 {
    width: 1972rem;
  }
  .w-1973 {
    width: 1973rem;
  }
  .w-1974 {
    width: 1974rem;
  }
  .w-1975 {
    width: 1975rem;
  }
  .w-1976 {
    width: 1976rem;
  }
  .w-1977 {
    width: 1977rem;
  }
  .w-1978 {
    width: 1978rem;
  }
  .w-1979 {
    width: 1979rem;
  }
  .w-1980 {
    width: 1980rem;
  }
  .w-1981 {
    width: 1981rem;
  }
  .w-1982 {
    width: 1982rem;
  }
  .w-1983 {
    width: 1983rem;
  }
  .w-1984 {
    width: 1984rem;
  }
  .w-1985 {
    width: 1985rem;
  }
  .w-1986 {
    width: 1986rem;
  }
  .w-1987 {
    width: 1987rem;
  }
  .w-1988 {
    width: 1988rem;
  }
  .w-1989 {
    width: 1989rem;
  }
  .w-1990 {
    width: 1990rem;
  }
  .w-1991 {
    width: 1991rem;
  }
  .w-1992 {
    width: 1992rem;
  }
  .w-1993 {
    width: 1993rem;
  }
  .w-1994 {
    width: 1994rem;
  }
  .w-1995 {
    width: 1995rem;
  }
  .w-1996 {
    width: 1996rem;
  }
  .w-1997 {
    width: 1997rem;
  }
  .w-1998 {
    width: 1998rem;
  }
  .w-1999 {
    width: 1999rem;
  }
  .wp-0 {
    width: 0%;
  }
  .wp-1 {
    width: 1%;
  }
  .wp-2 {
    width: 2%;
  }
  .wp-3 {
    width: 3%;
  }
  .wp-4 {
    width: 4%;
  }
  .wp-5 {
    width: 5%;
  }
  .wp-6 {
    width: 6%;
  }
  .wp-7 {
    width: 7%;
  }
  .wp-8 {
    width: 8%;
  }
  .wp-9 {
    width: 9%;
  }
  .wp-10 {
    width: 10%;
  }
  .wp-11 {
    width: 11%;
  }
  .wp-12 {
    width: 12%;
  }
  .wp-13 {
    width: 13%;
  }
  .wp-14 {
    width: 14%;
  }
  .wp-15 {
    width: 15%;
  }
  .wp-16 {
    width: 16%;
  }
  .wp-17 {
    width: 17%;
  }
  .wp-18 {
    width: 18%;
  }
  .wp-19 {
    width: 19%;
  }
  .wp-20 {
    width: 20%;
  }
  .wp-21 {
    width: 21%;
  }
  .wp-22 {
    width: 22%;
  }
  .wp-23 {
    width: 23%;
  }
  .wp-24 {
    width: 24%;
  }
  .wp-25 {
    width: 25%;
  }
  .wp-26 {
    width: 26%;
  }
  .wp-27 {
    width: 27%;
  }
  .wp-28 {
    width: 28%;
  }
  .wp-29 {
    width: 29%;
  }
  .wp-30 {
    width: 30%;
  }
  .wp-31 {
    width: 31%;
  }
  .wp-32 {
    width: 32%;
  }
  .wp-33 {
    width: 33%;
  }
  .wp-34 {
    width: 34%;
  }
  .wp-35 {
    width: 35%;
  }
  .wp-36 {
    width: 36%;
  }
  .wp-37 {
    width: 37%;
  }
  .wp-38 {
    width: 38%;
  }
  .wp-39 {
    width: 39%;
  }
  .wp-40 {
    width: 40%;
  }
  .wp-41 {
    width: 41%;
  }
  .wp-42 {
    width: 42%;
  }
  .wp-43 {
    width: 43%;
  }
  .wp-44 {
    width: 44%;
  }
  .wp-45 {
    width: 45%;
  }
  .wp-46 {
    width: 46%;
  }
  .wp-47 {
    width: 47%;
  }
  .wp-48 {
    width: 48%;
  }
  .wp-49 {
    width: 49%;
  }
  .wp-50 {
    width: 50%;
  }
  .wp-51 {
    width: 51%;
  }
  .wp-52 {
    width: 52%;
  }
  .wp-53 {
    width: 53%;
  }
  .wp-54 {
    width: 54%;
  }
  .wp-55 {
    width: 55%;
  }
  .wp-56 {
    width: 56%;
  }
  .wp-57 {
    width: 57%;
  }
  .wp-58 {
    width: 58%;
  }
  .wp-59 {
    width: 59%;
  }
  .wp-60 {
    width: 60%;
  }
  .wp-61 {
    width: 61%;
  }
  .wp-62 {
    width: 62%;
  }
  .wp-63 {
    width: 63%;
  }
  .wp-64 {
    width: 64%;
  }
  .wp-65 {
    width: 65%;
  }
  .wp-66 {
    width: 66%;
  }
  .wp-67 {
    width: 67%;
  }
  .wp-68 {
    width: 68%;
  }
  .wp-69 {
    width: 69%;
  }
  .wp-70 {
    width: 70%;
  }
  .wp-71 {
    width: 71%;
  }
  .wp-72 {
    width: 72%;
  }
  .wp-73 {
    width: 73%;
  }
  .wp-74 {
    width: 74%;
  }
  .wp-75 {
    width: 75%;
  }
  .wp-76 {
    width: 76%;
  }
  .wp-77 {
    width: 77%;
  }
  .wp-78 {
    width: 78%;
  }
  .wp-79 {
    width: 79%;
  }
  .wp-80 {
    width: 80%;
  }
  .wp-81 {
    width: 81%;
  }
  .wp-82 {
    width: 82%;
  }
  .wp-83 {
    width: 83%;
  }
  .wp-84 {
    width: 84%;
  }
  .wp-85 {
    width: 85%;
  }
  .wp-86 {
    width: 86%;
  }
  .wp-87 {
    width: 87%;
  }
  .wp-88 {
    width: 88%;
  }
  .wp-89 {
    width: 89%;
  }
  .wp-90 {
    width: 90%;
  }
  .wp-91 {
    width: 91%;
  }
  .wp-92 {
    width: 92%;
  }
  .wp-93 {
    width: 93%;
  }
  .wp-94 {
    width: 94%;
  }
  .wp-95 {
    width: 95%;
  }
  .wp-96 {
    width: 96%;
  }
  .wp-97 {
    width: 97%;
  }
  .wp-98 {
    width: 98%;
  }
  .wp-99 {
    width: 99%;
  }
  .font-thin {
    font-weight: thin;
  }
  .font-normal {
    font-weight: normal;
  }
  .font-lighter {
    font-weight: lighter;
  }
  .font-light {
    font-weight: light;
  }
  .font-bold {
    font-weight: bold;
  }
  .font-bolder {
    font-weight: bolder;
  }
  .font-100 {
    font-weight: 100;
  }
  .font-200 {
    font-weight: 200;
  }
  .font-300 {
    font-weight: 300;
  }
  .font-400 {
    font-weight: 400;
  }
  .font-500 {
    font-weight: 500;
  }
  .font-600 {
    font-weight: 600;
  }
  .font-700 {
    font-weight: 700;
  }
  .font-800 {
    font-weight: 800;
  }
  .font-900 {
    font-weight: 900;
  }
  .shadow-xs {
    box-shadow: 0px 3px 4px -5px rgba(51, 51, 51, 0.03),
      0px 1px 2px rgba(51, 51, 51, 0.05);
  }
  .shadow-s {
    box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
      0px 0px 2px rgba(51, 51, 51, 0.02);
  }
  .shadow-a {
    box-shadow: 0px 32px 41px -23px rgba(51, 51, 51, 0.07),
      0px 2px 6px rgba(51, 51, 51, 0.06);
  }
}
@media (min-width: 720px) {
  .md:md:p-0 {
    padding: 0rem;
  }
  .md:md:p-1 {
    padding: 1rem;
  }
  .md:md:p-2 {
    padding: 2rem;
  }
  .md:md:p-3 {
    padding: 3rem;
  }
  .md:md:p-4 {
    padding: 4rem;
  }
  .md:md:p-5 {
    padding: 5rem;
  }
  .md:md:p-6 {
    padding: 6rem;
  }
  .md:md:p-7 {
    padding: 7rem;
  }
  .md:md:p-8 {
    padding: 8rem;
  }
  .md:md:p-9 {
    padding: 9rem;
  }
  .md:md:p-10 {
    padding: 10rem;
  }
  .md:md:p-11 {
    padding: 11rem;
  }
  .md:md:p-12 {
    padding: 12rem;
  }
  .md:md:p-13 {
    padding: 13rem;
  }
  .md:md:p-14 {
    padding: 14rem;
  }
  .md:md:p-15 {
    padding: 15rem;
  }
  .md:md:p-16 {
    padding: 16rem;
  }
  .md:md:p-17 {
    padding: 17rem;
  }
  .md:md:p-18 {
    padding: 18rem;
  }
  .md:md:p-19 {
    padding: 19rem;
  }
  .md:md:p-20 {
    padding: 20rem;
  }
  .md:md:p-21 {
    padding: 21rem;
  }
  .md:md:p-22 {
    padding: 22rem;
  }
  .md:md:p-23 {
    padding: 23rem;
  }
  .md:md:p-24 {
    padding: 24rem;
  }
  .md:md:p-25 {
    padding: 25rem;
  }
  .md:md:p-26 {
    padding: 26rem;
  }
  .md:md:p-27 {
    padding: 27rem;
  }
  .md:md:p-28 {
    padding: 28rem;
  }
  .md:md:p-29 {
    padding: 29rem;
  }
  .md:md:p-30 {
    padding: 30rem;
  }
  .md:md:p-31 {
    padding: 31rem;
  }
  .md:md:p-32 {
    padding: 32rem;
  }
  .md:md:p-33 {
    padding: 33rem;
  }
  .md:md:p-34 {
    padding: 34rem;
  }
  .md:md:p-35 {
    padding: 35rem;
  }
  .md:md:p-36 {
    padding: 36rem;
  }
  .md:md:p-37 {
    padding: 37rem;
  }
  .md:md:p-38 {
    padding: 38rem;
  }
  .md:md:p-39 {
    padding: 39rem;
  }
  .md:md:p-40 {
    padding: 40rem;
  }
  .md:md:p-41 {
    padding: 41rem;
  }
  .md:md:p-42 {
    padding: 42rem;
  }
  .md:md:p-43 {
    padding: 43rem;
  }
  .md:md:p-44 {
    padding: 44rem;
  }
  .md:md:p-45 {
    padding: 45rem;
  }
  .md:md:p-46 {
    padding: 46rem;
  }
  .md:md:p-47 {
    padding: 47rem;
  }
  .md:md:p-48 {
    padding: 48rem;
  }
  .md:md:p-49 {
    padding: 49rem;
  }
  .md:md:p-50 {
    padding: 50rem;
  }
  .md:md:p-51 {
    padding: 51rem;
  }
  .md:md:p-52 {
    padding: 52rem;
  }
  .md:md:p-53 {
    padding: 53rem;
  }
  .md:md:p-54 {
    padding: 54rem;
  }
  .md:md:p-55 {
    padding: 55rem;
  }
  .md:md:p-56 {
    padding: 56rem;
  }
  .md:md:p-57 {
    padding: 57rem;
  }
  .md:md:p-58 {
    padding: 58rem;
  }
  .md:md:p-59 {
    padding: 59rem;
  }
  .md:md:p-60 {
    padding: 60rem;
  }
  .md:md:p-61 {
    padding: 61rem;
  }
  .md:md:p-62 {
    padding: 62rem;
  }
  .md:md:p-63 {
    padding: 63rem;
  }
  .md:md:p-64 {
    padding: 64rem;
  }
  .md:md:p-65 {
    padding: 65rem;
  }
  .md:md:p-66 {
    padding: 66rem;
  }
  .md:md:p-67 {
    padding: 67rem;
  }
  .md:md:p-68 {
    padding: 68rem;
  }
  .md:md:p-69 {
    padding: 69rem;
  }
  .md:md:p-70 {
    padding: 70rem;
  }
  .md:md:p-71 {
    padding: 71rem;
  }
  .md:md:p-72 {
    padding: 72rem;
  }
  .md:md:p-0-0 {
    padding: 0rem;
  }
  .md:md:p-0-1 {
    padding: 0.1rem;
  }
  .md:md:p-0-2 {
    padding: 0.2rem;
  }
  .md:md:p-0-3 {
    padding: 0.3rem;
  }
  .md:md:p-0-4 {
    padding: 0.4rem;
  }
  .md:md:p-0-5 {
    padding: 0.5rem;
  }
  .md:md:p-0-6 {
    padding: 0.6rem;
  }
  .md:md:p-0-7 {
    padding: 0.7rem;
  }
  .md:md:p-0-8 {
    padding: 0.8rem;
  }
  .md:md:p-0-9 {
    padding: 0.9rem;
  }
  .md:md:p-1-0 {
    padding: 1rem;
  }
  .md:md:p-1-1 {
    padding: 1.1rem;
  }
  .md:md:p-1-2 {
    padding: 1.2rem;
  }
  .md:md:p-1-3 {
    padding: 1.3rem;
  }
  .md:md:p-1-4 {
    padding: 1.4rem;
  }
  .md:md:p-1-5 {
    padding: 1.5rem;
  }
  .md:md:p-1-6 {
    padding: 1.6rem;
  }
  .md:md:p-1-7 {
    padding: 1.7rem;
  }
  .md:md:p-1-8 {
    padding: 1.8rem;
  }
  .md:md:p-1-9 {
    padding: 1.9rem;
  }
  .md:md:p-2-0 {
    padding: 2rem;
  }
  .md:md:p-2-1 {
    padding: 2.1rem;
  }
  .md:md:p-2-2 {
    padding: 2.2rem;
  }
  .md:md:p-2-3 {
    padding: 2.3rem;
  }
  .md:md:p-2-4 {
    padding: 2.4rem;
  }
  .md:md:p-2-5 {
    padding: 2.5rem;
  }
  .md:md:p-2-6 {
    padding: 2.6rem;
  }
  .md:md:p-2-7 {
    padding: 2.7rem;
  }
  .md:md:p-2-8 {
    padding: 2.8rem;
  }
  .md:md:p-2-9 {
    padding: 2.9rem;
  }
  .md:md:p-3-0 {
    padding: 3rem;
  }
  .md:md:p-3-1 {
    padding: 3.1rem;
  }
  .md:md:p-3-2 {
    padding: 3.2rem;
  }
  .md:md:p-3-3 {
    padding: 3.3rem;
  }
  .md:md:p-3-4 {
    padding: 3.4rem;
  }
  .md:md:p-3-5 {
    padding: 3.5rem;
  }
  .md:md:p-3-6 {
    padding: 3.6rem;
  }
  .md:md:p-3-7 {
    padding: 3.7rem;
  }
  .md:md:p-3-8 {
    padding: 3.8rem;
  }
  .md:md:p-3-9 {
    padding: 3.9rem;
  }
  .md:md:p-4-0 {
    padding: 4rem;
  }
  .md:md:p-4-1 {
    padding: 4.1rem;
  }
  .md:md:p-4-2 {
    padding: 4.2rem;
  }
  .md:md:p-4-3 {
    padding: 4.3rem;
  }
  .md:md:p-4-4 {
    padding: 4.4rem;
  }
  .md:md:p-4-5 {
    padding: 4.5rem;
  }
  .md:md:p-4-6 {
    padding: 4.6rem;
  }
  .md:md:p-4-7 {
    padding: 4.7rem;
  }
  .md:md:p-4-8 {
    padding: 4.8rem;
  }
  .md:md:p-4-9 {
    padding: 4.9rem;
  }
  .md:md:p-5-0 {
    padding: 5rem;
  }
  .md:md:p-5-1 {
    padding: 5.1rem;
  }
  .md:md:p-5-2 {
    padding: 5.2rem;
  }
  .md:md:p-5-3 {
    padding: 5.3rem;
  }
  .md:md:p-5-4 {
    padding: 5.4rem;
  }
  .md:md:p-5-5 {
    padding: 5.5rem;
  }
  .md:md:p-5-6 {
    padding: 5.6rem;
  }
  .md:md:p-5-7 {
    padding: 5.7rem;
  }
  .md:md:p-5-8 {
    padding: 5.8rem;
  }
  .md:md:p-5-9 {
    padding: 5.9rem;
  }
  .md:md:p-6-0 {
    padding: 6rem;
  }
  .md:md:p-6-1 {
    padding: 6.1rem;
  }
  .md:md:p-6-2 {
    padding: 6.2rem;
  }
  .md:md:p-6-3 {
    padding: 6.3rem;
  }
  .md:md:p-6-4 {
    padding: 6.4rem;
  }
  .md:md:p-6-5 {
    padding: 6.5rem;
  }
  .md:md:p-6-6 {
    padding: 6.6rem;
  }
  .md:md:p-6-7 {
    padding: 6.7rem;
  }
  .md:md:p-6-8 {
    padding: 6.8rem;
  }
  .md:md:p-6-9 {
    padding: 6.9rem;
  }
  .md:md:p-7-0 {
    padding: 7rem;
  }
  .md:md:p-7-1 {
    padding: 7.1rem;
  }
  .md:md:p-7-2 {
    padding: 7.2rem;
  }
  .md:md:p-7-3 {
    padding: 7.3rem;
  }
  .md:md:p-7-4 {
    padding: 7.4rem;
  }
  .md:md:p-7-5 {
    padding: 7.5rem;
  }
  .md:md:p-7-6 {
    padding: 7.6rem;
  }
  .md:md:p-7-7 {
    padding: 7.7rem;
  }
  .md:md:p-7-8 {
    padding: 7.8rem;
  }
  .md:md:p-7-9 {
    padding: 7.9rem;
  }
  .md:md:p-8-0 {
    padding: 8rem;
  }
  .md:md:p-8-1 {
    padding: 8.1rem;
  }
  .md:md:p-8-2 {
    padding: 8.2rem;
  }
  .md:md:p-8-3 {
    padding: 8.3rem;
  }
  .md:md:p-8-4 {
    padding: 8.4rem;
  }
  .md:md:p-8-5 {
    padding: 8.5rem;
  }
  .md:md:p-8-6 {
    padding: 8.6rem;
  }
  .md:md:p-8-7 {
    padding: 8.7rem;
  }
  .md:md:p-8-8 {
    padding: 8.8rem;
  }
  .md:md:p-8-9 {
    padding: 8.9rem;
  }
  .md:md:p-9-0 {
    padding: 9rem;
  }
  .md:md:p-9-1 {
    padding: 9.1rem;
  }
  .md:md:p-9-2 {
    padding: 9.2rem;
  }
  .md:md:p-9-3 {
    padding: 9.3rem;
  }
  .md:md:p-9-4 {
    padding: 9.4rem;
  }
  .md:md:p-9-5 {
    padding: 9.5rem;
  }
  .md:md:p-9-6 {
    padding: 9.6rem;
  }
  .md:md:p-9-7 {
    padding: 9.7rem;
  }
  .md:md:p-9-8 {
    padding: 9.8rem;
  }
  .md:md:p-9-9 {
    padding: 9.9rem;
  }
  .md:md:p-10-0 {
    padding: 10rem;
  }
  .md:md:p-10-1 {
    padding: 10.1rem;
  }
  .md:md:p-10-2 {
    padding: 10.2rem;
  }
  .md:md:p-10-3 {
    padding: 10.3rem;
  }
  .md:md:p-10-4 {
    padding: 10.4rem;
  }
  .md:md:p-10-5 {
    padding: 10.5rem;
  }
  .md:md:p-10-6 {
    padding: 10.6rem;
  }
  .md:md:p-10-7 {
    padding: 10.7rem;
  }
  .md:md:p-10-8 {
    padding: 10.8rem;
  }
  .md:md:p-10-9 {
    padding: 10.9rem;
  }
  .md:md:p-11-0 {
    padding: 11rem;
  }
  .md:md:p-11-1 {
    padding: 11.1rem;
  }
  .md:md:p-11-2 {
    padding: 11.2rem;
  }
  .md:md:p-11-3 {
    padding: 11.3rem;
  }
  .md:md:p-11-4 {
    padding: 11.4rem;
  }
  .md:md:p-11-5 {
    padding: 11.5rem;
  }
  .md:md:p-11-6 {
    padding: 11.6rem;
  }
  .md:md:p-11-7 {
    padding: 11.7rem;
  }
  .md:md:p-11-8 {
    padding: 11.8rem;
  }
  .md:md:p-11-9 {
    padding: 11.9rem;
  }
  .md:md:p-12-0 {
    padding: 12rem;
  }
  .md:md:p-12-1 {
    padding: 12.1rem;
  }
  .md:md:p-12-2 {
    padding: 12.2rem;
  }
  .md:md:p-12-3 {
    padding: 12.3rem;
  }
  .md:md:p-12-4 {
    padding: 12.4rem;
  }
  .md:md:p-12-5 {
    padding: 12.5rem;
  }
  .md:md:p-12-6 {
    padding: 12.6rem;
  }
  .md:md:p-12-7 {
    padding: 12.7rem;
  }
  .md:md:p-12-8 {
    padding: 12.8rem;
  }
  .md:md:p-12-9 {
    padding: 12.9rem;
  }
  .md:md:p-13-0 {
    padding: 13rem;
  }
  .md:md:p-13-1 {
    padding: 13.1rem;
  }
  .md:md:p-13-2 {
    padding: 13.2rem;
  }
  .md:md:p-13-3 {
    padding: 13.3rem;
  }
  .md:md:p-13-4 {
    padding: 13.4rem;
  }
  .md:md:p-13-5 {
    padding: 13.5rem;
  }
  .md:md:p-13-6 {
    padding: 13.6rem;
  }
  .md:md:p-13-7 {
    padding: 13.7rem;
  }
  .md:md:p-13-8 {
    padding: 13.8rem;
  }
  .md:md:p-13-9 {
    padding: 13.9rem;
  }
  .md:md:p-14-0 {
    padding: 14rem;
  }
  .md:md:p-14-1 {
    padding: 14.1rem;
  }
  .md:md:p-14-2 {
    padding: 14.2rem;
  }
  .md:md:p-14-3 {
    padding: 14.3rem;
  }
  .md:md:p-14-4 {
    padding: 14.4rem;
  }
  .md:md:p-14-5 {
    padding: 14.5rem;
  }
  .md:md:p-14-6 {
    padding: 14.6rem;
  }
  .md:md:p-14-7 {
    padding: 14.7rem;
  }
  .md:md:p-14-8 {
    padding: 14.8rem;
  }
  .md:md:p-14-9 {
    padding: 14.9rem;
  }
  .md:md:p-15-0 {
    padding: 15rem;
  }
  .md:md:p-15-1 {
    padding: 15.1rem;
  }
  .md:md:p-15-2 {
    padding: 15.2rem;
  }
  .md:md:p-15-3 {
    padding: 15.3rem;
  }
  .md:md:p-15-4 {
    padding: 15.4rem;
  }
  .md:md:p-15-5 {
    padding: 15.5rem;
  }
  .md:md:p-15-6 {
    padding: 15.6rem;
  }
  .md:md:p-15-7 {
    padding: 15.7rem;
  }
  .md:md:p-15-8 {
    padding: 15.8rem;
  }
  .md:md:p-15-9 {
    padding: 15.9rem;
  }
  .md:md:p-16-0 {
    padding: 16rem;
  }
  .md:md:p-16-1 {
    padding: 16.1rem;
  }
  .md:md:p-16-2 {
    padding: 16.2rem;
  }
  .md:md:p-16-3 {
    padding: 16.3rem;
  }
  .md:md:p-16-4 {
    padding: 16.4rem;
  }
  .md:md:p-16-5 {
    padding: 16.5rem;
  }
  .md:md:p-16-6 {
    padding: 16.6rem;
  }
  .md:md:p-16-7 {
    padding: 16.7rem;
  }
  .md:md:p-16-8 {
    padding: 16.8rem;
  }
  .md:md:p-16-9 {
    padding: 16.9rem;
  }
  .md:md:p-17-0 {
    padding: 17rem;
  }
  .md:md:p-17-1 {
    padding: 17.1rem;
  }
  .md:md:p-17-2 {
    padding: 17.2rem;
  }
  .md:md:p-17-3 {
    padding: 17.3rem;
  }
  .md:md:p-17-4 {
    padding: 17.4rem;
  }
  .md:md:p-17-5 {
    padding: 17.5rem;
  }
  .md:md:p-17-6 {
    padding: 17.6rem;
  }
  .md:md:p-17-7 {
    padding: 17.7rem;
  }
  .md:md:p-17-8 {
    padding: 17.8rem;
  }
  .md:md:p-17-9 {
    padding: 17.9rem;
  }
  .md:md:p-18-0 {
    padding: 18rem;
  }
  .md:md:p-18-1 {
    padding: 18.1rem;
  }
  .md:md:p-18-2 {
    padding: 18.2rem;
  }
  .md:md:p-18-3 {
    padding: 18.3rem;
  }
  .md:md:p-18-4 {
    padding: 18.4rem;
  }
  .md:md:p-18-5 {
    padding: 18.5rem;
  }
  .md:md:p-18-6 {
    padding: 18.6rem;
  }
  .md:md:p-18-7 {
    padding: 18.7rem;
  }
  .md:md:p-18-8 {
    padding: 18.8rem;
  }
  .md:md:p-18-9 {
    padding: 18.9rem;
  }
  .md:md:p-19-0 {
    padding: 19rem;
  }
  .md:md:p-19-1 {
    padding: 19.1rem;
  }
  .md:md:p-19-2 {
    padding: 19.2rem;
  }
  .md:md:p-19-3 {
    padding: 19.3rem;
  }
  .md:md:p-19-4 {
    padding: 19.4rem;
  }
  .md:md:p-19-5 {
    padding: 19.5rem;
  }
  .md:md:p-19-6 {
    padding: 19.6rem;
  }
  .md:md:p-19-7 {
    padding: 19.7rem;
  }
  .md:md:p-19-8 {
    padding: 19.8rem;
  }
  .md:md:p-19-9 {
    padding: 19.9rem;
  }
  .md:md:p-20-0 {
    padding: 20rem;
  }
  .md:md:p-20-1 {
    padding: 20.1rem;
  }
  .md:md:p-20-2 {
    padding: 20.2rem;
  }
  .md:md:p-20-3 {
    padding: 20.3rem;
  }
  .md:md:p-20-4 {
    padding: 20.4rem;
  }
  .md:md:p-20-5 {
    padding: 20.5rem;
  }
  .md:md:p-20-6 {
    padding: 20.6rem;
  }
  .md:md:p-20-7 {
    padding: 20.7rem;
  }
  .md:md:p-20-8 {
    padding: 20.8rem;
  }
  .md:md:p-20-9 {
    padding: 20.9rem;
  }
  .md:md:p-21-0 {
    padding: 21rem;
  }
  .md:md:p-21-1 {
    padding: 21.1rem;
  }
  .md:md:p-21-2 {
    padding: 21.2rem;
  }
  .md:md:p-21-3 {
    padding: 21.3rem;
  }
  .md:md:p-21-4 {
    padding: 21.4rem;
  }
  .md:md:p-21-5 {
    padding: 21.5rem;
  }
  .md:md:p-21-6 {
    padding: 21.6rem;
  }
  .md:md:p-21-7 {
    padding: 21.7rem;
  }
  .md:md:p-21-8 {
    padding: 21.8rem;
  }
  .md:md:p-21-9 {
    padding: 21.9rem;
  }
  .md:md:p-22-0 {
    padding: 22rem;
  }
  .md:md:p-22-1 {
    padding: 22.1rem;
  }
  .md:md:p-22-2 {
    padding: 22.2rem;
  }
  .md:md:p-22-3 {
    padding: 22.3rem;
  }
  .md:md:p-22-4 {
    padding: 22.4rem;
  }
  .md:md:p-22-5 {
    padding: 22.5rem;
  }
  .md:md:p-22-6 {
    padding: 22.6rem;
  }
  .md:md:p-22-7 {
    padding: 22.7rem;
  }
  .md:md:p-22-8 {
    padding: 22.8rem;
  }
  .md:md:p-22-9 {
    padding: 22.9rem;
  }
  .md:md:p-23-0 {
    padding: 23rem;
  }
  .md:md:p-23-1 {
    padding: 23.1rem;
  }
  .md:md:p-23-2 {
    padding: 23.2rem;
  }
  .md:md:p-23-3 {
    padding: 23.3rem;
  }
  .md:md:p-23-4 {
    padding: 23.4rem;
  }
  .md:md:p-23-5 {
    padding: 23.5rem;
  }
  .md:md:p-23-6 {
    padding: 23.6rem;
  }
  .md:md:p-23-7 {
    padding: 23.7rem;
  }
  .md:md:p-23-8 {
    padding: 23.8rem;
  }
  .md:md:p-23-9 {
    padding: 23.9rem;
  }
  .md:md:p-24-0 {
    padding: 24rem;
  }
  .md:md:p-24-1 {
    padding: 24.1rem;
  }
  .md:md:p-24-2 {
    padding: 24.2rem;
  }
  .md:md:p-24-3 {
    padding: 24.3rem;
  }
  .md:md:p-24-4 {
    padding: 24.4rem;
  }
  .md:md:p-24-5 {
    padding: 24.5rem;
  }
  .md:md:p-24-6 {
    padding: 24.6rem;
  }
  .md:md:p-24-7 {
    padding: 24.7rem;
  }
  .md:md:p-24-8 {
    padding: 24.8rem;
  }
  .md:md:p-24-9 {
    padding: 24.9rem;
  }
  .md:md:p-25-0 {
    padding: 25rem;
  }
  .md:md:p-25-1 {
    padding: 25.1rem;
  }
  .md:md:p-25-2 {
    padding: 25.2rem;
  }
  .md:md:p-25-3 {
    padding: 25.3rem;
  }
  .md:md:p-25-4 {
    padding: 25.4rem;
  }
  .md:md:p-25-5 {
    padding: 25.5rem;
  }
  .md:md:p-25-6 {
    padding: 25.6rem;
  }
  .md:md:p-25-7 {
    padding: 25.7rem;
  }
  .md:md:p-25-8 {
    padding: 25.8rem;
  }
  .md:md:p-25-9 {
    padding: 25.9rem;
  }
  .md:md:p-26-0 {
    padding: 26rem;
  }
  .md:md:p-26-1 {
    padding: 26.1rem;
  }
  .md:md:p-26-2 {
    padding: 26.2rem;
  }
  .md:md:p-26-3 {
    padding: 26.3rem;
  }
  .md:md:p-26-4 {
    padding: 26.4rem;
  }
  .md:md:p-26-5 {
    padding: 26.5rem;
  }
  .md:md:p-26-6 {
    padding: 26.6rem;
  }
  .md:md:p-26-7 {
    padding: 26.7rem;
  }
  .md:md:p-26-8 {
    padding: 26.8rem;
  }
  .md:md:p-26-9 {
    padding: 26.9rem;
  }
  .md:md:p-27-0 {
    padding: 27rem;
  }
  .md:md:p-27-1 {
    padding: 27.1rem;
  }
  .md:md:p-27-2 {
    padding: 27.2rem;
  }
  .md:md:p-27-3 {
    padding: 27.3rem;
  }
  .md:md:p-27-4 {
    padding: 27.4rem;
  }
  .md:md:p-27-5 {
    padding: 27.5rem;
  }
  .md:md:p-27-6 {
    padding: 27.6rem;
  }
  .md:md:p-27-7 {
    padding: 27.7rem;
  }
  .md:md:p-27-8 {
    padding: 27.8rem;
  }
  .md:md:p-27-9 {
    padding: 27.9rem;
  }
  .md:md:p-28-0 {
    padding: 28rem;
  }
  .md:md:p-28-1 {
    padding: 28.1rem;
  }
  .md:md:p-28-2 {
    padding: 28.2rem;
  }
  .md:md:p-28-3 {
    padding: 28.3rem;
  }
  .md:md:p-28-4 {
    padding: 28.4rem;
  }
  .md:md:p-28-5 {
    padding: 28.5rem;
  }
  .md:md:p-28-6 {
    padding: 28.6rem;
  }
  .md:md:p-28-7 {
    padding: 28.7rem;
  }
  .md:md:p-28-8 {
    padding: 28.8rem;
  }
  .md:md:p-28-9 {
    padding: 28.9rem;
  }
  .md:md:p-29-0 {
    padding: 29rem;
  }
  .md:md:p-29-1 {
    padding: 29.1rem;
  }
  .md:md:p-29-2 {
    padding: 29.2rem;
  }
  .md:md:p-29-3 {
    padding: 29.3rem;
  }
  .md:md:p-29-4 {
    padding: 29.4rem;
  }
  .md:md:p-29-5 {
    padding: 29.5rem;
  }
  .md:md:p-29-6 {
    padding: 29.6rem;
  }
  .md:md:p-29-7 {
    padding: 29.7rem;
  }
  .md:md:p-29-8 {
    padding: 29.8rem;
  }
  .md:md:p-29-9 {
    padding: 29.9rem;
  }
  .md:md:p-30-0 {
    padding: 30rem;
  }
  .md:md:p-30-1 {
    padding: 30.1rem;
  }
  .md:md:p-30-2 {
    padding: 30.2rem;
  }
  .md:md:p-30-3 {
    padding: 30.3rem;
  }
  .md:md:p-30-4 {
    padding: 30.4rem;
  }
  .md:md:p-30-5 {
    padding: 30.5rem;
  }
  .md:md:p-30-6 {
    padding: 30.6rem;
  }
  .md:md:p-30-7 {
    padding: 30.7rem;
  }
  .md:md:p-30-8 {
    padding: 30.8rem;
  }
  .md:md:p-30-9 {
    padding: 30.9rem;
  }
  .md:md:p-31-0 {
    padding: 31rem;
  }
  .md:md:p-31-1 {
    padding: 31.1rem;
  }
  .md:md:p-31-2 {
    padding: 31.2rem;
  }
  .md:md:p-31-3 {
    padding: 31.3rem;
  }
  .md:md:p-31-4 {
    padding: 31.4rem;
  }
  .md:md:p-31-5 {
    padding: 31.5rem;
  }
  .md:md:p-31-6 {
    padding: 31.6rem;
  }
  .md:md:p-31-7 {
    padding: 31.7rem;
  }
  .md:md:p-31-8 {
    padding: 31.8rem;
  }
  .md:md:p-31-9 {
    padding: 31.9rem;
  }
  .md:md:p-32-0 {
    padding: 32rem;
  }
  .md:md:p-32-1 {
    padding: 32.1rem;
  }
  .md:md:p-32-2 {
    padding: 32.2rem;
  }
  .md:md:p-32-3 {
    padding: 32.3rem;
  }
  .md:md:p-32-4 {
    padding: 32.4rem;
  }
  .md:md:p-32-5 {
    padding: 32.5rem;
  }
  .md:md:p-32-6 {
    padding: 32.6rem;
  }
  .md:md:p-32-7 {
    padding: 32.7rem;
  }
  .md:md:p-32-8 {
    padding: 32.8rem;
  }
  .md:md:p-32-9 {
    padding: 32.9rem;
  }
  .md:md:p-33-0 {
    padding: 33rem;
  }
  .md:md:p-33-1 {
    padding: 33.1rem;
  }
  .md:md:p-33-2 {
    padding: 33.2rem;
  }
  .md:md:p-33-3 {
    padding: 33.3rem;
  }
  .md:md:p-33-4 {
    padding: 33.4rem;
  }
  .md:md:p-33-5 {
    padding: 33.5rem;
  }
  .md:md:p-33-6 {
    padding: 33.6rem;
  }
  .md:md:p-33-7 {
    padding: 33.7rem;
  }
  .md:md:p-33-8 {
    padding: 33.8rem;
  }
  .md:md:p-33-9 {
    padding: 33.9rem;
  }
  .md:md:p-34-0 {
    padding: 34rem;
  }
  .md:md:p-34-1 {
    padding: 34.1rem;
  }
  .md:md:p-34-2 {
    padding: 34.2rem;
  }
  .md:md:p-34-3 {
    padding: 34.3rem;
  }
  .md:md:p-34-4 {
    padding: 34.4rem;
  }
  .md:md:p-34-5 {
    padding: 34.5rem;
  }
  .md:md:p-34-6 {
    padding: 34.6rem;
  }
  .md:md:p-34-7 {
    padding: 34.7rem;
  }
  .md:md:p-34-8 {
    padding: 34.8rem;
  }
  .md:md:p-34-9 {
    padding: 34.9rem;
  }
  .md:md:p-35-0 {
    padding: 35rem;
  }
  .md:md:p-35-1 {
    padding: 35.1rem;
  }
  .md:md:p-35-2 {
    padding: 35.2rem;
  }
  .md:md:p-35-3 {
    padding: 35.3rem;
  }
  .md:md:p-35-4 {
    padding: 35.4rem;
  }
  .md:md:p-35-5 {
    padding: 35.5rem;
  }
  .md:md:p-35-6 {
    padding: 35.6rem;
  }
  .md:md:p-35-7 {
    padding: 35.7rem;
  }
  .md:md:p-35-8 {
    padding: 35.8rem;
  }
  .md:md:p-35-9 {
    padding: 35.9rem;
  }
  .md:md:p-36-0 {
    padding: 36rem;
  }
  .md:md:p-36-1 {
    padding: 36.1rem;
  }
  .md:md:p-36-2 {
    padding: 36.2rem;
  }
  .md:md:p-36-3 {
    padding: 36.3rem;
  }
  .md:md:p-36-4 {
    padding: 36.4rem;
  }
  .md:md:p-36-5 {
    padding: 36.5rem;
  }
  .md:md:p-36-6 {
    padding: 36.6rem;
  }
  .md:md:p-36-7 {
    padding: 36.7rem;
  }
  .md:md:p-36-8 {
    padding: 36.8rem;
  }
  .md:md:p-36-9 {
    padding: 36.9rem;
  }
  .md:md:p-37-0 {
    padding: 37rem;
  }
  .md:md:p-37-1 {
    padding: 37.1rem;
  }
  .md:md:p-37-2 {
    padding: 37.2rem;
  }
  .md:md:p-37-3 {
    padding: 37.3rem;
  }
  .md:md:p-37-4 {
    padding: 37.4rem;
  }
  .md:md:p-37-5 {
    padding: 37.5rem;
  }
  .md:md:p-37-6 {
    padding: 37.6rem;
  }
  .md:md:p-37-7 {
    padding: 37.7rem;
  }
  .md:md:p-37-8 {
    padding: 37.8rem;
  }
  .md:md:p-37-9 {
    padding: 37.9rem;
  }
  .md:md:p-38-0 {
    padding: 38rem;
  }
  .md:md:p-38-1 {
    padding: 38.1rem;
  }
  .md:md:p-38-2 {
    padding: 38.2rem;
  }
  .md:md:p-38-3 {
    padding: 38.3rem;
  }
  .md:md:p-38-4 {
    padding: 38.4rem;
  }
  .md:md:p-38-5 {
    padding: 38.5rem;
  }
  .md:md:p-38-6 {
    padding: 38.6rem;
  }
  .md:md:p-38-7 {
    padding: 38.7rem;
  }
  .md:md:p-38-8 {
    padding: 38.8rem;
  }
  .md:md:p-38-9 {
    padding: 38.9rem;
  }
  .md:md:p-39-0 {
    padding: 39rem;
  }
  .md:md:p-39-1 {
    padding: 39.1rem;
  }
  .md:md:p-39-2 {
    padding: 39.2rem;
  }
  .md:md:p-39-3 {
    padding: 39.3rem;
  }
  .md:md:p-39-4 {
    padding: 39.4rem;
  }
  .md:md:p-39-5 {
    padding: 39.5rem;
  }
  .md:md:p-39-6 {
    padding: 39.6rem;
  }
  .md:md:p-39-7 {
    padding: 39.7rem;
  }
  .md:md:p-39-8 {
    padding: 39.8rem;
  }
  .md:md:p-39-9 {
    padding: 39.9rem;
  }
  .md:md:p-40-0 {
    padding: 40rem;
  }
  .md:md:p-40-1 {
    padding: 40.1rem;
  }
  .md:md:p-40-2 {
    padding: 40.2rem;
  }
  .md:md:p-40-3 {
    padding: 40.3rem;
  }
  .md:md:p-40-4 {
    padding: 40.4rem;
  }
  .md:md:p-40-5 {
    padding: 40.5rem;
  }
  .md:md:p-40-6 {
    padding: 40.6rem;
  }
  .md:md:p-40-7 {
    padding: 40.7rem;
  }
  .md:md:p-40-8 {
    padding: 40.8rem;
  }
  .md:md:p-40-9 {
    padding: 40.9rem;
  }
  .md:md:p-41-0 {
    padding: 41rem;
  }
  .md:md:p-41-1 {
    padding: 41.1rem;
  }
  .md:md:p-41-2 {
    padding: 41.2rem;
  }
  .md:md:p-41-3 {
    padding: 41.3rem;
  }
  .md:md:p-41-4 {
    padding: 41.4rem;
  }
  .md:md:p-41-5 {
    padding: 41.5rem;
  }
  .md:md:p-41-6 {
    padding: 41.6rem;
  }
  .md:md:p-41-7 {
    padding: 41.7rem;
  }
  .md:md:p-41-8 {
    padding: 41.8rem;
  }
  .md:md:p-41-9 {
    padding: 41.9rem;
  }
  .md:md:p-42-0 {
    padding: 42rem;
  }
  .md:md:p-42-1 {
    padding: 42.1rem;
  }
  .md:md:p-42-2 {
    padding: 42.2rem;
  }
  .md:md:p-42-3 {
    padding: 42.3rem;
  }
  .md:md:p-42-4 {
    padding: 42.4rem;
  }
  .md:md:p-42-5 {
    padding: 42.5rem;
  }
  .md:md:p-42-6 {
    padding: 42.6rem;
  }
  .md:md:p-42-7 {
    padding: 42.7rem;
  }
  .md:md:p-42-8 {
    padding: 42.8rem;
  }
  .md:md:p-42-9 {
    padding: 42.9rem;
  }
  .md:md:p-43-0 {
    padding: 43rem;
  }
  .md:md:p-43-1 {
    padding: 43.1rem;
  }
  .md:md:p-43-2 {
    padding: 43.2rem;
  }
  .md:md:p-43-3 {
    padding: 43.3rem;
  }
  .md:md:p-43-4 {
    padding: 43.4rem;
  }
  .md:md:p-43-5 {
    padding: 43.5rem;
  }
  .md:md:p-43-6 {
    padding: 43.6rem;
  }
  .md:md:p-43-7 {
    padding: 43.7rem;
  }
  .md:md:p-43-8 {
    padding: 43.8rem;
  }
  .md:md:p-43-9 {
    padding: 43.9rem;
  }
  .md:md:p-44-0 {
    padding: 44rem;
  }
  .md:md:p-44-1 {
    padding: 44.1rem;
  }
  .md:md:p-44-2 {
    padding: 44.2rem;
  }
  .md:md:p-44-3 {
    padding: 44.3rem;
  }
  .md:md:p-44-4 {
    padding: 44.4rem;
  }
  .md:md:p-44-5 {
    padding: 44.5rem;
  }
  .md:md:p-44-6 {
    padding: 44.6rem;
  }
  .md:md:p-44-7 {
    padding: 44.7rem;
  }
  .md:md:p-44-8 {
    padding: 44.8rem;
  }
  .md:md:p-44-9 {
    padding: 44.9rem;
  }
  .md:md:p-45-0 {
    padding: 45rem;
  }
  .md:md:p-45-1 {
    padding: 45.1rem;
  }
  .md:md:p-45-2 {
    padding: 45.2rem;
  }
  .md:md:p-45-3 {
    padding: 45.3rem;
  }
  .md:md:p-45-4 {
    padding: 45.4rem;
  }
  .md:md:p-45-5 {
    padding: 45.5rem;
  }
  .md:md:p-45-6 {
    padding: 45.6rem;
  }
  .md:md:p-45-7 {
    padding: 45.7rem;
  }
  .md:md:p-45-8 {
    padding: 45.8rem;
  }
  .md:md:p-45-9 {
    padding: 45.9rem;
  }
  .md:md:p-46-0 {
    padding: 46rem;
  }
  .md:md:p-46-1 {
    padding: 46.1rem;
  }
  .md:md:p-46-2 {
    padding: 46.2rem;
  }
  .md:md:p-46-3 {
    padding: 46.3rem;
  }
  .md:md:p-46-4 {
    padding: 46.4rem;
  }
  .md:md:p-46-5 {
    padding: 46.5rem;
  }
  .md:md:p-46-6 {
    padding: 46.6rem;
  }
  .md:md:p-46-7 {
    padding: 46.7rem;
  }
  .md:md:p-46-8 {
    padding: 46.8rem;
  }
  .md:md:p-46-9 {
    padding: 46.9rem;
  }
  .md:md:p-47-0 {
    padding: 47rem;
  }
  .md:md:p-47-1 {
    padding: 47.1rem;
  }
  .md:md:p-47-2 {
    padding: 47.2rem;
  }
  .md:md:p-47-3 {
    padding: 47.3rem;
  }
  .md:md:p-47-4 {
    padding: 47.4rem;
  }
  .md:md:p-47-5 {
    padding: 47.5rem;
  }
  .md:md:p-47-6 {
    padding: 47.6rem;
  }
  .md:md:p-47-7 {
    padding: 47.7rem;
  }
  .md:md:p-47-8 {
    padding: 47.8rem;
  }
  .md:md:p-47-9 {
    padding: 47.9rem;
  }
  .md:md:p-48-0 {
    padding: 48rem;
  }
  .md:md:p-48-1 {
    padding: 48.1rem;
  }
  .md:md:p-48-2 {
    padding: 48.2rem;
  }
  .md:md:p-48-3 {
    padding: 48.3rem;
  }
  .md:md:p-48-4 {
    padding: 48.4rem;
  }
  .md:md:p-48-5 {
    padding: 48.5rem;
  }
  .md:md:p-48-6 {
    padding: 48.6rem;
  }
  .md:md:p-48-7 {
    padding: 48.7rem;
  }
  .md:md:p-48-8 {
    padding: 48.8rem;
  }
  .md:md:p-48-9 {
    padding: 48.9rem;
  }
  .md:md:p-49-0 {
    padding: 49rem;
  }
  .md:md:p-49-1 {
    padding: 49.1rem;
  }
  .md:md:p-49-2 {
    padding: 49.2rem;
  }
  .md:md:p-49-3 {
    padding: 49.3rem;
  }
  .md:md:p-49-4 {
    padding: 49.4rem;
  }
  .md:md:p-49-5 {
    padding: 49.5rem;
  }
  .md:md:p-49-6 {
    padding: 49.6rem;
  }
  .md:md:p-49-7 {
    padding: 49.7rem;
  }
  .md:md:p-49-8 {
    padding: 49.8rem;
  }
  .md:md:p-49-9 {
    padding: 49.9rem;
  }
  .md:md:p-50-0 {
    padding: 50rem;
  }
  .md:md:p-50-1 {
    padding: 50.1rem;
  }
  .md:md:p-50-2 {
    padding: 50.2rem;
  }
  .md:md:p-50-3 {
    padding: 50.3rem;
  }
  .md:md:p-50-4 {
    padding: 50.4rem;
  }
  .md:md:p-50-5 {
    padding: 50.5rem;
  }
  .md:md:p-50-6 {
    padding: 50.6rem;
  }
  .md:md:p-50-7 {
    padding: 50.7rem;
  }
  .md:md:p-50-8 {
    padding: 50.8rem;
  }
  .md:md:p-50-9 {
    padding: 50.9rem;
  }
  .md:md:p-51-0 {
    padding: 51rem;
  }
  .md:md:p-51-1 {
    padding: 51.1rem;
  }
  .md:md:p-51-2 {
    padding: 51.2rem;
  }
  .md:md:p-51-3 {
    padding: 51.3rem;
  }
  .md:md:p-51-4 {
    padding: 51.4rem;
  }
  .md:md:p-51-5 {
    padding: 51.5rem;
  }
  .md:md:p-51-6 {
    padding: 51.6rem;
  }
  .md:md:p-51-7 {
    padding: 51.7rem;
  }
  .md:md:p-51-8 {
    padding: 51.8rem;
  }
  .md:md:p-51-9 {
    padding: 51.9rem;
  }
  .md:md:p-52-0 {
    padding: 52rem;
  }
  .md:md:p-52-1 {
    padding: 52.1rem;
  }
  .md:md:p-52-2 {
    padding: 52.2rem;
  }
  .md:md:p-52-3 {
    padding: 52.3rem;
  }
  .md:md:p-52-4 {
    padding: 52.4rem;
  }
  .md:md:p-52-5 {
    padding: 52.5rem;
  }
  .md:md:p-52-6 {
    padding: 52.6rem;
  }
  .md:md:p-52-7 {
    padding: 52.7rem;
  }
  .md:md:p-52-8 {
    padding: 52.8rem;
  }
  .md:md:p-52-9 {
    padding: 52.9rem;
  }
  .md:md:p-53-0 {
    padding: 53rem;
  }
  .md:md:p-53-1 {
    padding: 53.1rem;
  }
  .md:md:p-53-2 {
    padding: 53.2rem;
  }
  .md:md:p-53-3 {
    padding: 53.3rem;
  }
  .md:md:p-53-4 {
    padding: 53.4rem;
  }
  .md:md:p-53-5 {
    padding: 53.5rem;
  }
  .md:md:p-53-6 {
    padding: 53.6rem;
  }
  .md:md:p-53-7 {
    padding: 53.7rem;
  }
  .md:md:p-53-8 {
    padding: 53.8rem;
  }
  .md:md:p-53-9 {
    padding: 53.9rem;
  }
  .md:md:p-54-0 {
    padding: 54rem;
  }
  .md:md:p-54-1 {
    padding: 54.1rem;
  }
  .md:md:p-54-2 {
    padding: 54.2rem;
  }
  .md:md:p-54-3 {
    padding: 54.3rem;
  }
  .md:md:p-54-4 {
    padding: 54.4rem;
  }
  .md:md:p-54-5 {
    padding: 54.5rem;
  }
  .md:md:p-54-6 {
    padding: 54.6rem;
  }
  .md:md:p-54-7 {
    padding: 54.7rem;
  }
  .md:md:p-54-8 {
    padding: 54.8rem;
  }
  .md:md:p-54-9 {
    padding: 54.9rem;
  }
  .md:md:p-55-0 {
    padding: 55rem;
  }
  .md:md:p-55-1 {
    padding: 55.1rem;
  }
  .md:md:p-55-2 {
    padding: 55.2rem;
  }
  .md:md:p-55-3 {
    padding: 55.3rem;
  }
  .md:md:p-55-4 {
    padding: 55.4rem;
  }
  .md:md:p-55-5 {
    padding: 55.5rem;
  }
  .md:md:p-55-6 {
    padding: 55.6rem;
  }
  .md:md:p-55-7 {
    padding: 55.7rem;
  }
  .md:md:p-55-8 {
    padding: 55.8rem;
  }
  .md:md:p-55-9 {
    padding: 55.9rem;
  }
  .md:md:p-56-0 {
    padding: 56rem;
  }
  .md:md:p-56-1 {
    padding: 56.1rem;
  }
  .md:md:p-56-2 {
    padding: 56.2rem;
  }
  .md:md:p-56-3 {
    padding: 56.3rem;
  }
  .md:md:p-56-4 {
    padding: 56.4rem;
  }
  .md:md:p-56-5 {
    padding: 56.5rem;
  }
  .md:md:p-56-6 {
    padding: 56.6rem;
  }
  .md:md:p-56-7 {
    padding: 56.7rem;
  }
  .md:md:p-56-8 {
    padding: 56.8rem;
  }
  .md:md:p-56-9 {
    padding: 56.9rem;
  }
  .md:md:p-57-0 {
    padding: 57rem;
  }
  .md:md:p-57-1 {
    padding: 57.1rem;
  }
  .md:md:p-57-2 {
    padding: 57.2rem;
  }
  .md:md:p-57-3 {
    padding: 57.3rem;
  }
  .md:md:p-57-4 {
    padding: 57.4rem;
  }
  .md:md:p-57-5 {
    padding: 57.5rem;
  }
  .md:md:p-57-6 {
    padding: 57.6rem;
  }
  .md:md:p-57-7 {
    padding: 57.7rem;
  }
  .md:md:p-57-8 {
    padding: 57.8rem;
  }
  .md:md:p-57-9 {
    padding: 57.9rem;
  }
  .md:md:p-58-0 {
    padding: 58rem;
  }
  .md:md:p-58-1 {
    padding: 58.1rem;
  }
  .md:md:p-58-2 {
    padding: 58.2rem;
  }
  .md:md:p-58-3 {
    padding: 58.3rem;
  }
  .md:md:p-58-4 {
    padding: 58.4rem;
  }
  .md:md:p-58-5 {
    padding: 58.5rem;
  }
  .md:md:p-58-6 {
    padding: 58.6rem;
  }
  .md:md:p-58-7 {
    padding: 58.7rem;
  }
  .md:md:p-58-8 {
    padding: 58.8rem;
  }
  .md:md:p-58-9 {
    padding: 58.9rem;
  }
  .md:md:p-59-0 {
    padding: 59rem;
  }
  .md:md:p-59-1 {
    padding: 59.1rem;
  }
  .md:md:p-59-2 {
    padding: 59.2rem;
  }
  .md:md:p-59-3 {
    padding: 59.3rem;
  }
  .md:md:p-59-4 {
    padding: 59.4rem;
  }
  .md:md:p-59-5 {
    padding: 59.5rem;
  }
  .md:md:p-59-6 {
    padding: 59.6rem;
  }
  .md:md:p-59-7 {
    padding: 59.7rem;
  }
  .md:md:p-59-8 {
    padding: 59.8rem;
  }
  .md:md:p-59-9 {
    padding: 59.9rem;
  }
  .md:md:p-60-0 {
    padding: 60rem;
  }
  .md:md:p-60-1 {
    padding: 60.1rem;
  }
  .md:md:p-60-2 {
    padding: 60.2rem;
  }
  .md:md:p-60-3 {
    padding: 60.3rem;
  }
  .md:md:p-60-4 {
    padding: 60.4rem;
  }
  .md:md:p-60-5 {
    padding: 60.5rem;
  }
  .md:md:p-60-6 {
    padding: 60.6rem;
  }
  .md:md:p-60-7 {
    padding: 60.7rem;
  }
  .md:md:p-60-8 {
    padding: 60.8rem;
  }
  .md:md:p-60-9 {
    padding: 60.9rem;
  }
  .md:md:p-61-0 {
    padding: 61rem;
  }
  .md:md:p-61-1 {
    padding: 61.1rem;
  }
  .md:md:p-61-2 {
    padding: 61.2rem;
  }
  .md:md:p-61-3 {
    padding: 61.3rem;
  }
  .md:md:p-61-4 {
    padding: 61.4rem;
  }
  .md:md:p-61-5 {
    padding: 61.5rem;
  }
  .md:md:p-61-6 {
    padding: 61.6rem;
  }
  .md:md:p-61-7 {
    padding: 61.7rem;
  }
  .md:md:p-61-8 {
    padding: 61.8rem;
  }
  .md:md:p-61-9 {
    padding: 61.9rem;
  }
  .md:md:p-62-0 {
    padding: 62rem;
  }
  .md:md:p-62-1 {
    padding: 62.1rem;
  }
  .md:md:p-62-2 {
    padding: 62.2rem;
  }
  .md:md:p-62-3 {
    padding: 62.3rem;
  }
  .md:md:p-62-4 {
    padding: 62.4rem;
  }
  .md:md:p-62-5 {
    padding: 62.5rem;
  }
  .md:md:p-62-6 {
    padding: 62.6rem;
  }
  .md:md:p-62-7 {
    padding: 62.7rem;
  }
  .md:md:p-62-8 {
    padding: 62.8rem;
  }
  .md:md:p-62-9 {
    padding: 62.9rem;
  }
  .md:md:p-63-0 {
    padding: 63rem;
  }
  .md:md:p-63-1 {
    padding: 63.1rem;
  }
  .md:md:p-63-2 {
    padding: 63.2rem;
  }
  .md:md:p-63-3 {
    padding: 63.3rem;
  }
  .md:md:p-63-4 {
    padding: 63.4rem;
  }
  .md:md:p-63-5 {
    padding: 63.5rem;
  }
  .md:md:p-63-6 {
    padding: 63.6rem;
  }
  .md:md:p-63-7 {
    padding: 63.7rem;
  }
  .md:md:p-63-8 {
    padding: 63.8rem;
  }
  .md:md:p-63-9 {
    padding: 63.9rem;
  }
  .md:md:p-64-0 {
    padding: 64rem;
  }
  .md:md:p-64-1 {
    padding: 64.1rem;
  }
  .md:md:p-64-2 {
    padding: 64.2rem;
  }
  .md:md:p-64-3 {
    padding: 64.3rem;
  }
  .md:md:p-64-4 {
    padding: 64.4rem;
  }
  .md:md:p-64-5 {
    padding: 64.5rem;
  }
  .md:md:p-64-6 {
    padding: 64.6rem;
  }
  .md:md:p-64-7 {
    padding: 64.7rem;
  }
  .md:md:p-64-8 {
    padding: 64.8rem;
  }
  .md:md:p-64-9 {
    padding: 64.9rem;
  }
  .md:md:p-65-0 {
    padding: 65rem;
  }
  .md:md:p-65-1 {
    padding: 65.1rem;
  }
  .md:md:p-65-2 {
    padding: 65.2rem;
  }
  .md:md:p-65-3 {
    padding: 65.3rem;
  }
  .md:md:p-65-4 {
    padding: 65.4rem;
  }
  .md:md:p-65-5 {
    padding: 65.5rem;
  }
  .md:md:p-65-6 {
    padding: 65.6rem;
  }
  .md:md:p-65-7 {
    padding: 65.7rem;
  }
  .md:md:p-65-8 {
    padding: 65.8rem;
  }
  .md:md:p-65-9 {
    padding: 65.9rem;
  }
  .md:md:p-66-0 {
    padding: 66rem;
  }
  .md:md:p-66-1 {
    padding: 66.1rem;
  }
  .md:md:p-66-2 {
    padding: 66.2rem;
  }
  .md:md:p-66-3 {
    padding: 66.3rem;
  }
  .md:md:p-66-4 {
    padding: 66.4rem;
  }
  .md:md:p-66-5 {
    padding: 66.5rem;
  }
  .md:md:p-66-6 {
    padding: 66.6rem;
  }
  .md:md:p-66-7 {
    padding: 66.7rem;
  }
  .md:md:p-66-8 {
    padding: 66.8rem;
  }
  .md:md:p-66-9 {
    padding: 66.9rem;
  }
  .md:md:p-67-0 {
    padding: 67rem;
  }
  .md:md:p-67-1 {
    padding: 67.1rem;
  }
  .md:md:p-67-2 {
    padding: 67.2rem;
  }
  .md:md:p-67-3 {
    padding: 67.3rem;
  }
  .md:md:p-67-4 {
    padding: 67.4rem;
  }
  .md:md:p-67-5 {
    padding: 67.5rem;
  }
  .md:md:p-67-6 {
    padding: 67.6rem;
  }
  .md:md:p-67-7 {
    padding: 67.7rem;
  }
  .md:md:p-67-8 {
    padding: 67.8rem;
  }
  .md:md:p-67-9 {
    padding: 67.9rem;
  }
  .md:md:p-68-0 {
    padding: 68rem;
  }
  .md:md:p-68-1 {
    padding: 68.1rem;
  }
  .md:md:p-68-2 {
    padding: 68.2rem;
  }
  .md:md:p-68-3 {
    padding: 68.3rem;
  }
  .md:md:p-68-4 {
    padding: 68.4rem;
  }
  .md:md:p-68-5 {
    padding: 68.5rem;
  }
  .md:md:p-68-6 {
    padding: 68.6rem;
  }
  .md:md:p-68-7 {
    padding: 68.7rem;
  }
  .md:md:p-68-8 {
    padding: 68.8rem;
  }
  .md:md:p-68-9 {
    padding: 68.9rem;
  }
  .md:md:p-69-0 {
    padding: 69rem;
  }
  .md:md:p-69-1 {
    padding: 69.1rem;
  }
  .md:md:p-69-2 {
    padding: 69.2rem;
  }
  .md:md:p-69-3 {
    padding: 69.3rem;
  }
  .md:md:p-69-4 {
    padding: 69.4rem;
  }
  .md:md:p-69-5 {
    padding: 69.5rem;
  }
  .md:md:p-69-6 {
    padding: 69.6rem;
  }
  .md:md:p-69-7 {
    padding: 69.7rem;
  }
  .md:md:p-69-8 {
    padding: 69.8rem;
  }
  .md:md:p-69-9 {
    padding: 69.9rem;
  }
  .md:md:p-70-0 {
    padding: 70rem;
  }
  .md:md:p-70-1 {
    padding: 70.1rem;
  }
  .md:md:p-70-2 {
    padding: 70.2rem;
  }
  .md:md:p-70-3 {
    padding: 70.3rem;
  }
  .md:md:p-70-4 {
    padding: 70.4rem;
  }
  .md:md:p-70-5 {
    padding: 70.5rem;
  }
  .md:md:p-70-6 {
    padding: 70.6rem;
  }
  .md:md:p-70-7 {
    padding: 70.7rem;
  }
  .md:md:p-70-8 {
    padding: 70.8rem;
  }
  .md:md:p-70-9 {
    padding: 70.9rem;
  }
  .md:md:p-71-0 {
    padding: 71rem;
  }
  .md:md:p-71-1 {
    padding: 71.1rem;
  }
  .md:md:p-71-2 {
    padding: 71.2rem;
  }
  .md:md:p-71-3 {
    padding: 71.3rem;
  }
  .md:md:p-71-4 {
    padding: 71.4rem;
  }
  .md:md:p-71-5 {
    padding: 71.5rem;
  }
  .md:md:p-71-6 {
    padding: 71.6rem;
  }
  .md:md:p-71-7 {
    padding: 71.7rem;
  }
  .md:md:p-71-8 {
    padding: 71.8rem;
  }
  .md:md:p-71-9 {
    padding: 71.9rem;
  }
  .md:md:p-72-0 {
    padding: 72rem;
  }
  .md:md:p-72-1 {
    padding: 72.1rem;
  }
  .md:md:p-72-2 {
    padding: 72.2rem;
  }
  .md:md:p-72-3 {
    padding: 72.3rem;
  }
  .md:md:p-72-4 {
    padding: 72.4rem;
  }
  .md:md:p-72-5 {
    padding: 72.5rem;
  }
  .md:md:p-72-6 {
    padding: 72.6rem;
  }
  .md:md:p-72-7 {
    padding: 72.7rem;
  }
  .md:md:p-72-8 {
    padding: 72.8rem;
  }
  .md:md:p-72-9 {
    padding: 72.9rem;
  }
  .md:md:p-0 {
    padding: 0;
  }
  .md:md:p-1 {
    padding: 1rem;
  }
  .md:md:p-2 {
    padding: 2rem;
  }
  .md:md:p-3 {
    padding: 4rem;
  }
  .md:md:p-4 {
    padding: 6rem;
  }
  .md:md:p-5 {
    padding: 8rem;
  }
  .md:md:pl-0 {
    padding-left: 0;
  }
  .md:md:pl-1 {
    padding-left: 1rem;
  }
  .md:md:pl-2 {
    padding-left: 2rem;
  }
  .md:md:pl-3 {
    padding-left: 4rem;
  }
  .md:md:pl-4 {
    padding-left: 6rem;
  }
  .md:md:pl-5 {
    padding-left: 8rem;
  }
  .md:md:pr-0 {
    padding-right: 0;
  }
  .md:md:pr-1 {
    padding-right: 1rem;
  }
  .md:md:pr-2 {
    padding-right: 2rem;
  }
  .md:md:pr-3 {
    padding-right: 4rem;
  }
  .md:md:pr-4 {
    padding-right: 6rem;
  }
  .md:md:pr-5 {
    padding-right: 8rem;
  }
  .md:md:pt-0 {
    padding-top: 0;
  }
  .md:md:pt-1 {
    padding-top: 1rem;
  }
  .md:md:pt-2 {
    padding-top: 2rem;
  }
  .md:md:pt-3 {
    padding-top: 4rem;
  }
  .md:md:pt-4 {
    padding-top: 6rem;
  }
  .md:md:pt-5 {
    padding-top: 8rem;
  }
  .md:md:pb-0 {
    padding-bottom: 0;
  }
  .md:md:pb-1 {
    padding-bottom: 1rem;
  }
  .md:md:pb-2 {
    padding-bottom: 2rem;
  }
  .md:md:pb-3 {
    padding-bottom: 4rem;
  }
  .md:md:pb-4 {
    padding-bottom: 6rem;
  }
  .md:md:pb-5 {
    padding-bottom: 8rem;
  }
  .md:md:m-0 {
    margin: 0;
  }
  .md:md:m-1 {
    margin: 1rem;
  }
  .md:md:m-2 {
    margin: 2rem;
  }
  .md:md:m-3 {
    margin: 4rem;
  }
  .md:md:m-4 {
    margin: 6rem;
  }
  .md:md:m-5 {
    margin: 8rem;
  }
  .md:md:ml-0 {
    margin-left: 0;
  }
  .md:md:ml-1 {
    margin-left: 1rem;
  }
  .md:md:ml-2 {
    margin-left: 2rem;
  }
  .md:md:ml-3 {
    margin-left: 4rem;
  }
  .md:md:ml-4 {
    margin-left: 6rem;
  }
  .md:md:ml-5 {
    margin-left: 8rem;
  }
  .md:md:mr-0 {
    margin-right: 0;
  }
  .md:md:mr-1 {
    margin-right: 1rem;
  }
  .md:md:mr-2 {
    margin-right: 2rem;
  }
  .md:md:mr-3 {
    margin-right: 4rem;
  }
  .md:md:mr-4 {
    margin-right: 6rem;
  }
  .md:md:mr-5 {
    margin-right: 8rem;
  }
  .md:md:mt-0 {
    margin-top: 0;
  }
  .md:md:mt-1 {
    margin-top: 1rem;
  }
  .md:md:mt-2 {
    margin-top: 2rem;
  }
  .md:md:mt-3 {
    margin-top: 4rem;
  }
  .md:md:mt-4 {
    margin-top: 6rem;
  }
  .md:md:mt-5 {
    margin-top: 8rem;
  }
  .md:md:mb-0 {
    margin-bottom: 0;
  }
  .md:md:mb-1 {
    margin-bottom: 1rem;
  }
  .md:md:mb-2 {
    margin-bottom: 2rem;
  }
  .md:md:mb-3 {
    margin-bottom: 4rem;
  }
  .md:md:mb-4 {
    margin-bottom: 6rem;
  }
  .md:md:mb-5 {
    margin-bottom: 8rem;
  }
  .md:md:o-10 {
    opacity: 0.1;
  }
  .md:md:o-20 {
    opacity: 0.2;
  }
  .md:md:o-30 {
    opacity: 0.3;
  }
  .md:md:o-40 {
    opacity: 0.4;
  }
  .md:md:o-50 {
    opacity: 0.5;
  }
  .md:md:o-60 {
    opacity: 0.6;
  }
  .md:md:o-70 {
    opacity: 0.7;
  }
  .md:md:o-80 {
    opacity: 0.8;
  }
  .md:md:o-90 {
    opacity: 0.9;
  }
  .md:md:o-100 {
    opacity: 1;
  }
  .md:md:br {
    border-radius: 0.8rem;
  }
  .md:md:br-none {
    border-radius: 0;
  }
  .md:md:br-xs {
    border-radius: 0.2rem;
  }
  .md:md:br-sm {
    border-radius: 0.4rem;
  }
  .md:md:br-lg {
    border-radius: 1.6rem;
  }
  .md:md:br-full {
    border-radius: 50%;
  }
  .md:md:display-n {
    display: none;
  }
  .md:md:display-b {
    display: block;
  }
  .md:md:display-f {
    display: flex;
  }
  .md:md:display-i {
    display: inline;
  }
  .md:md:display-i-b {
    display: inline-block;
  }
  .md:md:align-f-start {
    align-items: flex-start;
  }
  .md:md:align-f-end {
    align-items: flex-end;
  }
  .md:md:align-center {
    align-items: center;
  }
  .md:md:flex-row {
    flex-direction: row;
  }
  .md:md:flex-column {
    flex-direction: column;
  }
  .md:md:justify-between {
    justify-content: space-between;
  }
  .md:md:justify-f-end {
    justify-content: flex-end;
  }
  .md:md:justify-center {
    justify-content: center;
  }
  .md:md:justify-around {
    justify-content: space-around;
  }
  .md:md:justify-evenly {
    justify-content: space-evenly;
  }
  .md:md:text-center {
    text-align: center;
  }
  .md:md:text-start {
    text-align: start;
  }
  .md:md:text-end {
    text-align: end;
  }
  .md:md:text-left {
    text-align: left;
  }
  .md:md:text-right {
    text-align: right;
  }
  .md:md:text-justify {
    text-align: justify;
  }
  .md:md:text-d2xl {
    font-size: 7.2rem;
  }
  .md:md:text-dxl {
    font-size: 6rem;
  }
  .md:md:text-dlg {
    font-size: 4.8rem;
  }
  .md:md:text-dmd {
    font-size: 3.6rem;
  }
  .md:md:text-dsm {
    font-size: 3.2rem;
  }
  .md:md:text-dxs {
    font-size: 2.4rem;
  }
  .md:md:text-xxl {
    font-size: 2.4rem;
  }
  .md:md:text-xl {
    font-size: 2rem;
  }
  .md:md:text-lg {
    font-size: 1.8rem;
  }
  .md:md:text-md {
    font-size: 1.6rem;
  }
  .md:md:text-sm {
    font-size: 1.4rem;
  }
  .md:md:text-xs {
    font-size: 1.2rem;
  }
  .md:md:text-macro {
    font-size: 1rem;
  }
  .md:gap-0 {
    gap: 0rem;
  }
  .md:gap-1 {
    gap: 1rem;
  }
  .md:gap-2 {
    gap: 2rem;
  }
  .md:gap-3 {
    gap: 3rem;
  }
  .md:gap-4 {
    gap: 4rem;
  }
  .md:gap-5 {
    gap: 5rem;
  }
  .md:gap-6 {
    gap: 6rem;
  }
  .md:gap-7 {
    gap: 7rem;
  }
  .md:gap-8 {
    gap: 8rem;
  }
  .md:gap-9 {
    gap: 9rem;
  }
  .md:gap-10 {
    gap: 10rem;
  }
  .md:gap-11 {
    gap: 11rem;
  }
  .md:gap-12 {
    gap: 12rem;
  }
  .md:gap-13 {
    gap: 13rem;
  }
  .md:gap-14 {
    gap: 14rem;
  }
  .md:gap-15 {
    gap: 15rem;
  }
  .md:gap-16 {
    gap: 16rem;
  }
  .md:gap-17 {
    gap: 17rem;
  }
  .md:gap-18 {
    gap: 18rem;
  }
  .md:gap-19 {
    gap: 19rem;
  }
  .md:gap-20 {
    gap: 20rem;
  }
  .md:gap-21 {
    gap: 21rem;
  }
  .md:gap-22 {
    gap: 22rem;
  }
  .md:gap-23 {
    gap: 23rem;
  }
  .md:gap-24 {
    gap: 24rem;
  }
  .md:gap-25 {
    gap: 25rem;
  }
  .md:gap-26 {
    gap: 26rem;
  }
  .md:gap-27 {
    gap: 27rem;
  }
  .md:gap-28 {
    gap: 28rem;
  }
  .md:gap-29 {
    gap: 29rem;
  }
  .md:gap-30 {
    gap: 30rem;
  }
  .md:gap-31 {
    gap: 31rem;
  }
  .md:gap-32 {
    gap: 32rem;
  }
  .md:gap-33 {
    gap: 33rem;
  }
  .md:gap-34 {
    gap: 34rem;
  }
  .md:gap-35 {
    gap: 35rem;
  }
  .md:gap-36 {
    gap: 36rem;
  }
  .md:gap-37 {
    gap: 37rem;
  }
  .md:gap-38 {
    gap: 38rem;
  }
  .md:gap-39 {
    gap: 39rem;
  }
  .md:gap-40 {
    gap: 40rem;
  }
  .md:gap-41 {
    gap: 41rem;
  }
  .md:gap-42 {
    gap: 42rem;
  }
  .md:gap-43 {
    gap: 43rem;
  }
  .md:gap-44 {
    gap: 44rem;
  }
  .md:gap-45 {
    gap: 45rem;
  }
  .md:gap-46 {
    gap: 46rem;
  }
  .md:gap-47 {
    gap: 47rem;
  }
  .md:gap-48 {
    gap: 48rem;
  }
  .md:gap-49 {
    gap: 49rem;
  }
  .md:w-0 {
    width: 0rem;
  }
  .md:w-1 {
    width: 1rem;
  }
  .md:w-2 {
    width: 2rem;
  }
  .md:w-3 {
    width: 3rem;
  }
  .md:w-4 {
    width: 4rem;
  }
  .md:w-5 {
    width: 5rem;
  }
  .md:w-6 {
    width: 6rem;
  }
  .md:w-7 {
    width: 7rem;
  }
  .md:w-8 {
    width: 8rem;
  }
  .md:w-9 {
    width: 9rem;
  }
  .md:w-10 {
    width: 10rem;
  }
  .md:w-11 {
    width: 11rem;
  }
  .md:w-12 {
    width: 12rem;
  }
  .md:w-13 {
    width: 13rem;
  }
  .md:w-14 {
    width: 14rem;
  }
  .md:w-15 {
    width: 15rem;
  }
  .md:w-16 {
    width: 16rem;
  }
  .md:w-17 {
    width: 17rem;
  }
  .md:w-18 {
    width: 18rem;
  }
  .md:w-19 {
    width: 19rem;
  }
  .md:w-20 {
    width: 20rem;
  }
  .md:w-21 {
    width: 21rem;
  }
  .md:w-22 {
    width: 22rem;
  }
  .md:w-23 {
    width: 23rem;
  }
  .md:w-24 {
    width: 24rem;
  }
  .md:w-25 {
    width: 25rem;
  }
  .md:w-26 {
    width: 26rem;
  }
  .md:w-27 {
    width: 27rem;
  }
  .md:w-28 {
    width: 28rem;
  }
  .md:w-29 {
    width: 29rem;
  }
  .md:w-30 {
    width: 30rem;
  }
  .md:w-31 {
    width: 31rem;
  }
  .md:w-32 {
    width: 32rem;
  }
  .md:w-33 {
    width: 33rem;
  }
  .md:w-34 {
    width: 34rem;
  }
  .md:w-35 {
    width: 35rem;
  }
  .md:w-36 {
    width: 36rem;
  }
  .md:w-37 {
    width: 37rem;
  }
  .md:w-38 {
    width: 38rem;
  }
  .md:w-39 {
    width: 39rem;
  }
  .md:w-40 {
    width: 40rem;
  }
  .md:w-41 {
    width: 41rem;
  }
  .md:w-42 {
    width: 42rem;
  }
  .md:w-43 {
    width: 43rem;
  }
  .md:w-44 {
    width: 44rem;
  }
  .md:w-45 {
    width: 45rem;
  }
  .md:w-46 {
    width: 46rem;
  }
  .md:w-47 {
    width: 47rem;
  }
  .md:w-48 {
    width: 48rem;
  }
  .md:w-49 {
    width: 49rem;
  }
  .md:w-50 {
    width: 50rem;
  }
  .md:w-51 {
    width: 51rem;
  }
  .md:w-52 {
    width: 52rem;
  }
  .md:w-53 {
    width: 53rem;
  }
  .md:w-54 {
    width: 54rem;
  }
  .md:w-55 {
    width: 55rem;
  }
  .md:w-56 {
    width: 56rem;
  }
  .md:w-57 {
    width: 57rem;
  }
  .md:w-58 {
    width: 58rem;
  }
  .md:w-59 {
    width: 59rem;
  }
  .md:w-60 {
    width: 60rem;
  }
  .md:w-61 {
    width: 61rem;
  }
  .md:w-62 {
    width: 62rem;
  }
  .md:w-63 {
    width: 63rem;
  }
  .md:w-64 {
    width: 64rem;
  }
  .md:w-65 {
    width: 65rem;
  }
  .md:w-66 {
    width: 66rem;
  }
  .md:w-67 {
    width: 67rem;
  }
  .md:w-68 {
    width: 68rem;
  }
  .md:w-69 {
    width: 69rem;
  }
  .md:w-70 {
    width: 70rem;
  }
  .md:w-71 {
    width: 71rem;
  }
  .md:w-72 {
    width: 72rem;
  }
  .md:w-73 {
    width: 73rem;
  }
  .md:w-74 {
    width: 74rem;
  }
  .md:w-75 {
    width: 75rem;
  }
  .md:w-76 {
    width: 76rem;
  }
  .md:w-77 {
    width: 77rem;
  }
  .md:w-78 {
    width: 78rem;
  }
  .md:w-79 {
    width: 79rem;
  }
  .md:w-80 {
    width: 80rem;
  }
  .md:w-81 {
    width: 81rem;
  }
  .md:w-82 {
    width: 82rem;
  }
  .md:w-83 {
    width: 83rem;
  }
  .md:w-84 {
    width: 84rem;
  }
  .md:w-85 {
    width: 85rem;
  }
  .md:w-86 {
    width: 86rem;
  }
  .md:w-87 {
    width: 87rem;
  }
  .md:w-88 {
    width: 88rem;
  }
  .md:w-89 {
    width: 89rem;
  }
  .md:w-90 {
    width: 90rem;
  }
  .md:w-91 {
    width: 91rem;
  }
  .md:w-92 {
    width: 92rem;
  }
  .md:w-93 {
    width: 93rem;
  }
  .md:w-94 {
    width: 94rem;
  }
  .md:w-95 {
    width: 95rem;
  }
  .md:w-96 {
    width: 96rem;
  }
  .md:w-97 {
    width: 97rem;
  }
  .md:w-98 {
    width: 98rem;
  }
  .md:w-99 {
    width: 99rem;
  }
  .md:w-100 {
    width: 100rem;
  }
  .md:w-101 {
    width: 101rem;
  }
  .md:w-102 {
    width: 102rem;
  }
  .md:w-103 {
    width: 103rem;
  }
  .md:w-104 {
    width: 104rem;
  }
  .md:w-105 {
    width: 105rem;
  }
  .md:w-106 {
    width: 106rem;
  }
  .md:w-107 {
    width: 107rem;
  }
  .md:w-108 {
    width: 108rem;
  }
  .md:w-109 {
    width: 109rem;
  }
  .md:w-110 {
    width: 110rem;
  }
  .md:w-111 {
    width: 111rem;
  }
  .md:w-112 {
    width: 112rem;
  }
  .md:w-113 {
    width: 113rem;
  }
  .md:w-114 {
    width: 114rem;
  }
  .md:w-115 {
    width: 115rem;
  }
  .md:w-116 {
    width: 116rem;
  }
  .md:w-117 {
    width: 117rem;
  }
  .md:w-118 {
    width: 118rem;
  }
  .md:w-119 {
    width: 119rem;
  }
  .md:w-120 {
    width: 120rem;
  }
  .md:w-121 {
    width: 121rem;
  }
  .md:w-122 {
    width: 122rem;
  }
  .md:w-123 {
    width: 123rem;
  }
  .md:w-124 {
    width: 124rem;
  }
  .md:w-125 {
    width: 125rem;
  }
  .md:w-126 {
    width: 126rem;
  }
  .md:w-127 {
    width: 127rem;
  }
  .md:w-128 {
    width: 128rem;
  }
  .md:w-129 {
    width: 129rem;
  }
  .md:w-130 {
    width: 130rem;
  }
  .md:w-131 {
    width: 131rem;
  }
  .md:w-132 {
    width: 132rem;
  }
  .md:w-133 {
    width: 133rem;
  }
  .md:w-134 {
    width: 134rem;
  }
  .md:w-135 {
    width: 135rem;
  }
  .md:w-136 {
    width: 136rem;
  }
  .md:w-137 {
    width: 137rem;
  }
  .md:w-138 {
    width: 138rem;
  }
  .md:w-139 {
    width: 139rem;
  }
  .md:w-140 {
    width: 140rem;
  }
  .md:w-141 {
    width: 141rem;
  }
  .md:w-142 {
    width: 142rem;
  }
  .md:w-143 {
    width: 143rem;
  }
  .md:w-144 {
    width: 144rem;
  }
  .md:w-145 {
    width: 145rem;
  }
  .md:w-146 {
    width: 146rem;
  }
  .md:w-147 {
    width: 147rem;
  }
  .md:w-148 {
    width: 148rem;
  }
  .md:w-149 {
    width: 149rem;
  }
  .md:w-150 {
    width: 150rem;
  }
  .md:w-151 {
    width: 151rem;
  }
  .md:w-152 {
    width: 152rem;
  }
  .md:w-153 {
    width: 153rem;
  }
  .md:w-154 {
    width: 154rem;
  }
  .md:w-155 {
    width: 155rem;
  }
  .md:w-156 {
    width: 156rem;
  }
  .md:w-157 {
    width: 157rem;
  }
  .md:w-158 {
    width: 158rem;
  }
  .md:w-159 {
    width: 159rem;
  }
  .md:w-160 {
    width: 160rem;
  }
  .md:w-161 {
    width: 161rem;
  }
  .md:w-162 {
    width: 162rem;
  }
  .md:w-163 {
    width: 163rem;
  }
  .md:w-164 {
    width: 164rem;
  }
  .md:w-165 {
    width: 165rem;
  }
  .md:w-166 {
    width: 166rem;
  }
  .md:w-167 {
    width: 167rem;
  }
  .md:w-168 {
    width: 168rem;
  }
  .md:w-169 {
    width: 169rem;
  }
  .md:w-170 {
    width: 170rem;
  }
  .md:w-171 {
    width: 171rem;
  }
  .md:w-172 {
    width: 172rem;
  }
  .md:w-173 {
    width: 173rem;
  }
  .md:w-174 {
    width: 174rem;
  }
  .md:w-175 {
    width: 175rem;
  }
  .md:w-176 {
    width: 176rem;
  }
  .md:w-177 {
    width: 177rem;
  }
  .md:w-178 {
    width: 178rem;
  }
  .md:w-179 {
    width: 179rem;
  }
  .md:w-180 {
    width: 180rem;
  }
  .md:w-181 {
    width: 181rem;
  }
  .md:w-182 {
    width: 182rem;
  }
  .md:w-183 {
    width: 183rem;
  }
  .md:w-184 {
    width: 184rem;
  }
  .md:w-185 {
    width: 185rem;
  }
  .md:w-186 {
    width: 186rem;
  }
  .md:w-187 {
    width: 187rem;
  }
  .md:w-188 {
    width: 188rem;
  }
  .md:w-189 {
    width: 189rem;
  }
  .md:w-190 {
    width: 190rem;
  }
  .md:w-191 {
    width: 191rem;
  }
  .md:w-192 {
    width: 192rem;
  }
  .md:w-193 {
    width: 193rem;
  }
  .md:w-194 {
    width: 194rem;
  }
  .md:w-195 {
    width: 195rem;
  }
  .md:w-196 {
    width: 196rem;
  }
  .md:w-197 {
    width: 197rem;
  }
  .md:w-198 {
    width: 198rem;
  }
  .md:w-199 {
    width: 199rem;
  }
  .md:w-200 {
    width: 200rem;
  }
  .md:w-201 {
    width: 201rem;
  }
  .md:w-202 {
    width: 202rem;
  }
  .md:w-203 {
    width: 203rem;
  }
  .md:w-204 {
    width: 204rem;
  }
  .md:w-205 {
    width: 205rem;
  }
  .md:w-206 {
    width: 206rem;
  }
  .md:w-207 {
    width: 207rem;
  }
  .md:w-208 {
    width: 208rem;
  }
  .md:w-209 {
    width: 209rem;
  }
  .md:w-210 {
    width: 210rem;
  }
  .md:w-211 {
    width: 211rem;
  }
  .md:w-212 {
    width: 212rem;
  }
  .md:w-213 {
    width: 213rem;
  }
  .md:w-214 {
    width: 214rem;
  }
  .md:w-215 {
    width: 215rem;
  }
  .md:w-216 {
    width: 216rem;
  }
  .md:w-217 {
    width: 217rem;
  }
  .md:w-218 {
    width: 218rem;
  }
  .md:w-219 {
    width: 219rem;
  }
  .md:w-220 {
    width: 220rem;
  }
  .md:w-221 {
    width: 221rem;
  }
  .md:w-222 {
    width: 222rem;
  }
  .md:w-223 {
    width: 223rem;
  }
  .md:w-224 {
    width: 224rem;
  }
  .md:w-225 {
    width: 225rem;
  }
  .md:w-226 {
    width: 226rem;
  }
  .md:w-227 {
    width: 227rem;
  }
  .md:w-228 {
    width: 228rem;
  }
  .md:w-229 {
    width: 229rem;
  }
  .md:w-230 {
    width: 230rem;
  }
  .md:w-231 {
    width: 231rem;
  }
  .md:w-232 {
    width: 232rem;
  }
  .md:w-233 {
    width: 233rem;
  }
  .md:w-234 {
    width: 234rem;
  }
  .md:w-235 {
    width: 235rem;
  }
  .md:w-236 {
    width: 236rem;
  }
  .md:w-237 {
    width: 237rem;
  }
  .md:w-238 {
    width: 238rem;
  }
  .md:w-239 {
    width: 239rem;
  }
  .md:w-240 {
    width: 240rem;
  }
  .md:w-241 {
    width: 241rem;
  }
  .md:w-242 {
    width: 242rem;
  }
  .md:w-243 {
    width: 243rem;
  }
  .md:w-244 {
    width: 244rem;
  }
  .md:w-245 {
    width: 245rem;
  }
  .md:w-246 {
    width: 246rem;
  }
  .md:w-247 {
    width: 247rem;
  }
  .md:w-248 {
    width: 248rem;
  }
  .md:w-249 {
    width: 249rem;
  }
  .md:w-250 {
    width: 250rem;
  }
  .md:w-251 {
    width: 251rem;
  }
  .md:w-252 {
    width: 252rem;
  }
  .md:w-253 {
    width: 253rem;
  }
  .md:w-254 {
    width: 254rem;
  }
  .md:w-255 {
    width: 255rem;
  }
  .md:w-256 {
    width: 256rem;
  }
  .md:w-257 {
    width: 257rem;
  }
  .md:w-258 {
    width: 258rem;
  }
  .md:w-259 {
    width: 259rem;
  }
  .md:w-260 {
    width: 260rem;
  }
  .md:w-261 {
    width: 261rem;
  }
  .md:w-262 {
    width: 262rem;
  }
  .md:w-263 {
    width: 263rem;
  }
  .md:w-264 {
    width: 264rem;
  }
  .md:w-265 {
    width: 265rem;
  }
  .md:w-266 {
    width: 266rem;
  }
  .md:w-267 {
    width: 267rem;
  }
  .md:w-268 {
    width: 268rem;
  }
  .md:w-269 {
    width: 269rem;
  }
  .md:w-270 {
    width: 270rem;
  }
  .md:w-271 {
    width: 271rem;
  }
  .md:w-272 {
    width: 272rem;
  }
  .md:w-273 {
    width: 273rem;
  }
  .md:w-274 {
    width: 274rem;
  }
  .md:w-275 {
    width: 275rem;
  }
  .md:w-276 {
    width: 276rem;
  }
  .md:w-277 {
    width: 277rem;
  }
  .md:w-278 {
    width: 278rem;
  }
  .md:w-279 {
    width: 279rem;
  }
  .md:w-280 {
    width: 280rem;
  }
  .md:w-281 {
    width: 281rem;
  }
  .md:w-282 {
    width: 282rem;
  }
  .md:w-283 {
    width: 283rem;
  }
  .md:w-284 {
    width: 284rem;
  }
  .md:w-285 {
    width: 285rem;
  }
  .md:w-286 {
    width: 286rem;
  }
  .md:w-287 {
    width: 287rem;
  }
  .md:w-288 {
    width: 288rem;
  }
  .md:w-289 {
    width: 289rem;
  }
  .md:w-290 {
    width: 290rem;
  }
  .md:w-291 {
    width: 291rem;
  }
  .md:w-292 {
    width: 292rem;
  }
  .md:w-293 {
    width: 293rem;
  }
  .md:w-294 {
    width: 294rem;
  }
  .md:w-295 {
    width: 295rem;
  }
  .md:w-296 {
    width: 296rem;
  }
  .md:w-297 {
    width: 297rem;
  }
  .md:w-298 {
    width: 298rem;
  }
  .md:w-299 {
    width: 299rem;
  }
  .md:w-300 {
    width: 300rem;
  }
  .md:w-301 {
    width: 301rem;
  }
  .md:w-302 {
    width: 302rem;
  }
  .md:w-303 {
    width: 303rem;
  }
  .md:w-304 {
    width: 304rem;
  }
  .md:w-305 {
    width: 305rem;
  }
  .md:w-306 {
    width: 306rem;
  }
  .md:w-307 {
    width: 307rem;
  }
  .md:w-308 {
    width: 308rem;
  }
  .md:w-309 {
    width: 309rem;
  }
  .md:w-310 {
    width: 310rem;
  }
  .md:w-311 {
    width: 311rem;
  }
  .md:w-312 {
    width: 312rem;
  }
  .md:w-313 {
    width: 313rem;
  }
  .md:w-314 {
    width: 314rem;
  }
  .md:w-315 {
    width: 315rem;
  }
  .md:w-316 {
    width: 316rem;
  }
  .md:w-317 {
    width: 317rem;
  }
  .md:w-318 {
    width: 318rem;
  }
  .md:w-319 {
    width: 319rem;
  }
  .md:w-320 {
    width: 320rem;
  }
  .md:w-321 {
    width: 321rem;
  }
  .md:w-322 {
    width: 322rem;
  }
  .md:w-323 {
    width: 323rem;
  }
  .md:w-324 {
    width: 324rem;
  }
  .md:w-325 {
    width: 325rem;
  }
  .md:w-326 {
    width: 326rem;
  }
  .md:w-327 {
    width: 327rem;
  }
  .md:w-328 {
    width: 328rem;
  }
  .md:w-329 {
    width: 329rem;
  }
  .md:w-330 {
    width: 330rem;
  }
  .md:w-331 {
    width: 331rem;
  }
  .md:w-332 {
    width: 332rem;
  }
  .md:w-333 {
    width: 333rem;
  }
  .md:w-334 {
    width: 334rem;
  }
  .md:w-335 {
    width: 335rem;
  }
  .md:w-336 {
    width: 336rem;
  }
  .md:w-337 {
    width: 337rem;
  }
  .md:w-338 {
    width: 338rem;
  }
  .md:w-339 {
    width: 339rem;
  }
  .md:w-340 {
    width: 340rem;
  }
  .md:w-341 {
    width: 341rem;
  }
  .md:w-342 {
    width: 342rem;
  }
  .md:w-343 {
    width: 343rem;
  }
  .md:w-344 {
    width: 344rem;
  }
  .md:w-345 {
    width: 345rem;
  }
  .md:w-346 {
    width: 346rem;
  }
  .md:w-347 {
    width: 347rem;
  }
  .md:w-348 {
    width: 348rem;
  }
  .md:w-349 {
    width: 349rem;
  }
  .md:w-350 {
    width: 350rem;
  }
  .md:w-351 {
    width: 351rem;
  }
  .md:w-352 {
    width: 352rem;
  }
  .md:w-353 {
    width: 353rem;
  }
  .md:w-354 {
    width: 354rem;
  }
  .md:w-355 {
    width: 355rem;
  }
  .md:w-356 {
    width: 356rem;
  }
  .md:w-357 {
    width: 357rem;
  }
  .md:w-358 {
    width: 358rem;
  }
  .md:w-359 {
    width: 359rem;
  }
  .md:w-360 {
    width: 360rem;
  }
  .md:w-361 {
    width: 361rem;
  }
  .md:w-362 {
    width: 362rem;
  }
  .md:w-363 {
    width: 363rem;
  }
  .md:w-364 {
    width: 364rem;
  }
  .md:w-365 {
    width: 365rem;
  }
  .md:w-366 {
    width: 366rem;
  }
  .md:w-367 {
    width: 367rem;
  }
  .md:w-368 {
    width: 368rem;
  }
  .md:w-369 {
    width: 369rem;
  }
  .md:w-370 {
    width: 370rem;
  }
  .md:w-371 {
    width: 371rem;
  }
  .md:w-372 {
    width: 372rem;
  }
  .md:w-373 {
    width: 373rem;
  }
  .md:w-374 {
    width: 374rem;
  }
  .md:w-375 {
    width: 375rem;
  }
  .md:w-376 {
    width: 376rem;
  }
  .md:w-377 {
    width: 377rem;
  }
  .md:w-378 {
    width: 378rem;
  }
  .md:w-379 {
    width: 379rem;
  }
  .md:w-380 {
    width: 380rem;
  }
  .md:w-381 {
    width: 381rem;
  }
  .md:w-382 {
    width: 382rem;
  }
  .md:w-383 {
    width: 383rem;
  }
  .md:w-384 {
    width: 384rem;
  }
  .md:w-385 {
    width: 385rem;
  }
  .md:w-386 {
    width: 386rem;
  }
  .md:w-387 {
    width: 387rem;
  }
  .md:w-388 {
    width: 388rem;
  }
  .md:w-389 {
    width: 389rem;
  }
  .md:w-390 {
    width: 390rem;
  }
  .md:w-391 {
    width: 391rem;
  }
  .md:w-392 {
    width: 392rem;
  }
  .md:w-393 {
    width: 393rem;
  }
  .md:w-394 {
    width: 394rem;
  }
  .md:w-395 {
    width: 395rem;
  }
  .md:w-396 {
    width: 396rem;
  }
  .md:w-397 {
    width: 397rem;
  }
  .md:w-398 {
    width: 398rem;
  }
  .md:w-399 {
    width: 399rem;
  }
  .md:w-400 {
    width: 400rem;
  }
  .md:w-401 {
    width: 401rem;
  }
  .md:w-402 {
    width: 402rem;
  }
  .md:w-403 {
    width: 403rem;
  }
  .md:w-404 {
    width: 404rem;
  }
  .md:w-405 {
    width: 405rem;
  }
  .md:w-406 {
    width: 406rem;
  }
  .md:w-407 {
    width: 407rem;
  }
  .md:w-408 {
    width: 408rem;
  }
  .md:w-409 {
    width: 409rem;
  }
  .md:w-410 {
    width: 410rem;
  }
  .md:w-411 {
    width: 411rem;
  }
  .md:w-412 {
    width: 412rem;
  }
  .md:w-413 {
    width: 413rem;
  }
  .md:w-414 {
    width: 414rem;
  }
  .md:w-415 {
    width: 415rem;
  }
  .md:w-416 {
    width: 416rem;
  }
  .md:w-417 {
    width: 417rem;
  }
  .md:w-418 {
    width: 418rem;
  }
  .md:w-419 {
    width: 419rem;
  }
  .md:w-420 {
    width: 420rem;
  }
  .md:w-421 {
    width: 421rem;
  }
  .md:w-422 {
    width: 422rem;
  }
  .md:w-423 {
    width: 423rem;
  }
  .md:w-424 {
    width: 424rem;
  }
  .md:w-425 {
    width: 425rem;
  }
  .md:w-426 {
    width: 426rem;
  }
  .md:w-427 {
    width: 427rem;
  }
  .md:w-428 {
    width: 428rem;
  }
  .md:w-429 {
    width: 429rem;
  }
  .md:w-430 {
    width: 430rem;
  }
  .md:w-431 {
    width: 431rem;
  }
  .md:w-432 {
    width: 432rem;
  }
  .md:w-433 {
    width: 433rem;
  }
  .md:w-434 {
    width: 434rem;
  }
  .md:w-435 {
    width: 435rem;
  }
  .md:w-436 {
    width: 436rem;
  }
  .md:w-437 {
    width: 437rem;
  }
  .md:w-438 {
    width: 438rem;
  }
  .md:w-439 {
    width: 439rem;
  }
  .md:w-440 {
    width: 440rem;
  }
  .md:w-441 {
    width: 441rem;
  }
  .md:w-442 {
    width: 442rem;
  }
  .md:w-443 {
    width: 443rem;
  }
  .md:w-444 {
    width: 444rem;
  }
  .md:w-445 {
    width: 445rem;
  }
  .md:w-446 {
    width: 446rem;
  }
  .md:w-447 {
    width: 447rem;
  }
  .md:w-448 {
    width: 448rem;
  }
  .md:w-449 {
    width: 449rem;
  }
  .md:w-450 {
    width: 450rem;
  }
  .md:w-451 {
    width: 451rem;
  }
  .md:w-452 {
    width: 452rem;
  }
  .md:w-453 {
    width: 453rem;
  }
  .md:w-454 {
    width: 454rem;
  }
  .md:w-455 {
    width: 455rem;
  }
  .md:w-456 {
    width: 456rem;
  }
  .md:w-457 {
    width: 457rem;
  }
  .md:w-458 {
    width: 458rem;
  }
  .md:w-459 {
    width: 459rem;
  }
  .md:w-460 {
    width: 460rem;
  }
  .md:w-461 {
    width: 461rem;
  }
  .md:w-462 {
    width: 462rem;
  }
  .md:w-463 {
    width: 463rem;
  }
  .md:w-464 {
    width: 464rem;
  }
  .md:w-465 {
    width: 465rem;
  }
  .md:w-466 {
    width: 466rem;
  }
  .md:w-467 {
    width: 467rem;
  }
  .md:w-468 {
    width: 468rem;
  }
  .md:w-469 {
    width: 469rem;
  }
  .md:w-470 {
    width: 470rem;
  }
  .md:w-471 {
    width: 471rem;
  }
  .md:w-472 {
    width: 472rem;
  }
  .md:w-473 {
    width: 473rem;
  }
  .md:w-474 {
    width: 474rem;
  }
  .md:w-475 {
    width: 475rem;
  }
  .md:w-476 {
    width: 476rem;
  }
  .md:w-477 {
    width: 477rem;
  }
  .md:w-478 {
    width: 478rem;
  }
  .md:w-479 {
    width: 479rem;
  }
  .md:w-480 {
    width: 480rem;
  }
  .md:w-481 {
    width: 481rem;
  }
  .md:w-482 {
    width: 482rem;
  }
  .md:w-483 {
    width: 483rem;
  }
  .md:w-484 {
    width: 484rem;
  }
  .md:w-485 {
    width: 485rem;
  }
  .md:w-486 {
    width: 486rem;
  }
  .md:w-487 {
    width: 487rem;
  }
  .md:w-488 {
    width: 488rem;
  }
  .md:w-489 {
    width: 489rem;
  }
  .md:w-490 {
    width: 490rem;
  }
  .md:w-491 {
    width: 491rem;
  }
  .md:w-492 {
    width: 492rem;
  }
  .md:w-493 {
    width: 493rem;
  }
  .md:w-494 {
    width: 494rem;
  }
  .md:w-495 {
    width: 495rem;
  }
  .md:w-496 {
    width: 496rem;
  }
  .md:w-497 {
    width: 497rem;
  }
  .md:w-498 {
    width: 498rem;
  }
  .md:w-499 {
    width: 499rem;
  }
  .md:w-500 {
    width: 500rem;
  }
  .md:w-501 {
    width: 501rem;
  }
  .md:w-502 {
    width: 502rem;
  }
  .md:w-503 {
    width: 503rem;
  }
  .md:w-504 {
    width: 504rem;
  }
  .md:w-505 {
    width: 505rem;
  }
  .md:w-506 {
    width: 506rem;
  }
  .md:w-507 {
    width: 507rem;
  }
  .md:w-508 {
    width: 508rem;
  }
  .md:w-509 {
    width: 509rem;
  }
  .md:w-510 {
    width: 510rem;
  }
  .md:w-511 {
    width: 511rem;
  }
  .md:w-512 {
    width: 512rem;
  }
  .md:w-513 {
    width: 513rem;
  }
  .md:w-514 {
    width: 514rem;
  }
  .md:w-515 {
    width: 515rem;
  }
  .md:w-516 {
    width: 516rem;
  }
  .md:w-517 {
    width: 517rem;
  }
  .md:w-518 {
    width: 518rem;
  }
  .md:w-519 {
    width: 519rem;
  }
  .md:w-520 {
    width: 520rem;
  }
  .md:w-521 {
    width: 521rem;
  }
  .md:w-522 {
    width: 522rem;
  }
  .md:w-523 {
    width: 523rem;
  }
  .md:w-524 {
    width: 524rem;
  }
  .md:w-525 {
    width: 525rem;
  }
  .md:w-526 {
    width: 526rem;
  }
  .md:w-527 {
    width: 527rem;
  }
  .md:w-528 {
    width: 528rem;
  }
  .md:w-529 {
    width: 529rem;
  }
  .md:w-530 {
    width: 530rem;
  }
  .md:w-531 {
    width: 531rem;
  }
  .md:w-532 {
    width: 532rem;
  }
  .md:w-533 {
    width: 533rem;
  }
  .md:w-534 {
    width: 534rem;
  }
  .md:w-535 {
    width: 535rem;
  }
  .md:w-536 {
    width: 536rem;
  }
  .md:w-537 {
    width: 537rem;
  }
  .md:w-538 {
    width: 538rem;
  }
  .md:w-539 {
    width: 539rem;
  }
  .md:w-540 {
    width: 540rem;
  }
  .md:w-541 {
    width: 541rem;
  }
  .md:w-542 {
    width: 542rem;
  }
  .md:w-543 {
    width: 543rem;
  }
  .md:w-544 {
    width: 544rem;
  }
  .md:w-545 {
    width: 545rem;
  }
  .md:w-546 {
    width: 546rem;
  }
  .md:w-547 {
    width: 547rem;
  }
  .md:w-548 {
    width: 548rem;
  }
  .md:w-549 {
    width: 549rem;
  }
  .md:w-550 {
    width: 550rem;
  }
  .md:w-551 {
    width: 551rem;
  }
  .md:w-552 {
    width: 552rem;
  }
  .md:w-553 {
    width: 553rem;
  }
  .md:w-554 {
    width: 554rem;
  }
  .md:w-555 {
    width: 555rem;
  }
  .md:w-556 {
    width: 556rem;
  }
  .md:w-557 {
    width: 557rem;
  }
  .md:w-558 {
    width: 558rem;
  }
  .md:w-559 {
    width: 559rem;
  }
  .md:w-560 {
    width: 560rem;
  }
  .md:w-561 {
    width: 561rem;
  }
  .md:w-562 {
    width: 562rem;
  }
  .md:w-563 {
    width: 563rem;
  }
  .md:w-564 {
    width: 564rem;
  }
  .md:w-565 {
    width: 565rem;
  }
  .md:w-566 {
    width: 566rem;
  }
  .md:w-567 {
    width: 567rem;
  }
  .md:w-568 {
    width: 568rem;
  }
  .md:w-569 {
    width: 569rem;
  }
  .md:w-570 {
    width: 570rem;
  }
  .md:w-571 {
    width: 571rem;
  }
  .md:w-572 {
    width: 572rem;
  }
  .md:w-573 {
    width: 573rem;
  }
  .md:w-574 {
    width: 574rem;
  }
  .md:w-575 {
    width: 575rem;
  }
  .md:w-576 {
    width: 576rem;
  }
  .md:w-577 {
    width: 577rem;
  }
  .md:w-578 {
    width: 578rem;
  }
  .md:w-579 {
    width: 579rem;
  }
  .md:w-580 {
    width: 580rem;
  }
  .md:w-581 {
    width: 581rem;
  }
  .md:w-582 {
    width: 582rem;
  }
  .md:w-583 {
    width: 583rem;
  }
  .md:w-584 {
    width: 584rem;
  }
  .md:w-585 {
    width: 585rem;
  }
  .md:w-586 {
    width: 586rem;
  }
  .md:w-587 {
    width: 587rem;
  }
  .md:w-588 {
    width: 588rem;
  }
  .md:w-589 {
    width: 589rem;
  }
  .md:w-590 {
    width: 590rem;
  }
  .md:w-591 {
    width: 591rem;
  }
  .md:w-592 {
    width: 592rem;
  }
  .md:w-593 {
    width: 593rem;
  }
  .md:w-594 {
    width: 594rem;
  }
  .md:w-595 {
    width: 595rem;
  }
  .md:w-596 {
    width: 596rem;
  }
  .md:w-597 {
    width: 597rem;
  }
  .md:w-598 {
    width: 598rem;
  }
  .md:w-599 {
    width: 599rem;
  }
  .md:w-600 {
    width: 600rem;
  }
  .md:w-601 {
    width: 601rem;
  }
  .md:w-602 {
    width: 602rem;
  }
  .md:w-603 {
    width: 603rem;
  }
  .md:w-604 {
    width: 604rem;
  }
  .md:w-605 {
    width: 605rem;
  }
  .md:w-606 {
    width: 606rem;
  }
  .md:w-607 {
    width: 607rem;
  }
  .md:w-608 {
    width: 608rem;
  }
  .md:w-609 {
    width: 609rem;
  }
  .md:w-610 {
    width: 610rem;
  }
  .md:w-611 {
    width: 611rem;
  }
  .md:w-612 {
    width: 612rem;
  }
  .md:w-613 {
    width: 613rem;
  }
  .md:w-614 {
    width: 614rem;
  }
  .md:w-615 {
    width: 615rem;
  }
  .md:w-616 {
    width: 616rem;
  }
  .md:w-617 {
    width: 617rem;
  }
  .md:w-618 {
    width: 618rem;
  }
  .md:w-619 {
    width: 619rem;
  }
  .md:w-620 {
    width: 620rem;
  }
  .md:w-621 {
    width: 621rem;
  }
  .md:w-622 {
    width: 622rem;
  }
  .md:w-623 {
    width: 623rem;
  }
  .md:w-624 {
    width: 624rem;
  }
  .md:w-625 {
    width: 625rem;
  }
  .md:w-626 {
    width: 626rem;
  }
  .md:w-627 {
    width: 627rem;
  }
  .md:w-628 {
    width: 628rem;
  }
  .md:w-629 {
    width: 629rem;
  }
  .md:w-630 {
    width: 630rem;
  }
  .md:w-631 {
    width: 631rem;
  }
  .md:w-632 {
    width: 632rem;
  }
  .md:w-633 {
    width: 633rem;
  }
  .md:w-634 {
    width: 634rem;
  }
  .md:w-635 {
    width: 635rem;
  }
  .md:w-636 {
    width: 636rem;
  }
  .md:w-637 {
    width: 637rem;
  }
  .md:w-638 {
    width: 638rem;
  }
  .md:w-639 {
    width: 639rem;
  }
  .md:w-640 {
    width: 640rem;
  }
  .md:w-641 {
    width: 641rem;
  }
  .md:w-642 {
    width: 642rem;
  }
  .md:w-643 {
    width: 643rem;
  }
  .md:w-644 {
    width: 644rem;
  }
  .md:w-645 {
    width: 645rem;
  }
  .md:w-646 {
    width: 646rem;
  }
  .md:w-647 {
    width: 647rem;
  }
  .md:w-648 {
    width: 648rem;
  }
  .md:w-649 {
    width: 649rem;
  }
  .md:w-650 {
    width: 650rem;
  }
  .md:w-651 {
    width: 651rem;
  }
  .md:w-652 {
    width: 652rem;
  }
  .md:w-653 {
    width: 653rem;
  }
  .md:w-654 {
    width: 654rem;
  }
  .md:w-655 {
    width: 655rem;
  }
  .md:w-656 {
    width: 656rem;
  }
  .md:w-657 {
    width: 657rem;
  }
  .md:w-658 {
    width: 658rem;
  }
  .md:w-659 {
    width: 659rem;
  }
  .md:w-660 {
    width: 660rem;
  }
  .md:w-661 {
    width: 661rem;
  }
  .md:w-662 {
    width: 662rem;
  }
  .md:w-663 {
    width: 663rem;
  }
  .md:w-664 {
    width: 664rem;
  }
  .md:w-665 {
    width: 665rem;
  }
  .md:w-666 {
    width: 666rem;
  }
  .md:w-667 {
    width: 667rem;
  }
  .md:w-668 {
    width: 668rem;
  }
  .md:w-669 {
    width: 669rem;
  }
  .md:w-670 {
    width: 670rem;
  }
  .md:w-671 {
    width: 671rem;
  }
  .md:w-672 {
    width: 672rem;
  }
  .md:w-673 {
    width: 673rem;
  }
  .md:w-674 {
    width: 674rem;
  }
  .md:w-675 {
    width: 675rem;
  }
  .md:w-676 {
    width: 676rem;
  }
  .md:w-677 {
    width: 677rem;
  }
  .md:w-678 {
    width: 678rem;
  }
  .md:w-679 {
    width: 679rem;
  }
  .md:w-680 {
    width: 680rem;
  }
  .md:w-681 {
    width: 681rem;
  }
  .md:w-682 {
    width: 682rem;
  }
  .md:w-683 {
    width: 683rem;
  }
  .md:w-684 {
    width: 684rem;
  }
  .md:w-685 {
    width: 685rem;
  }
  .md:w-686 {
    width: 686rem;
  }
  .md:w-687 {
    width: 687rem;
  }
  .md:w-688 {
    width: 688rem;
  }
  .md:w-689 {
    width: 689rem;
  }
  .md:w-690 {
    width: 690rem;
  }
  .md:w-691 {
    width: 691rem;
  }
  .md:w-692 {
    width: 692rem;
  }
  .md:w-693 {
    width: 693rem;
  }
  .md:w-694 {
    width: 694rem;
  }
  .md:w-695 {
    width: 695rem;
  }
  .md:w-696 {
    width: 696rem;
  }
  .md:w-697 {
    width: 697rem;
  }
  .md:w-698 {
    width: 698rem;
  }
  .md:w-699 {
    width: 699rem;
  }
  .md:w-700 {
    width: 700rem;
  }
  .md:w-701 {
    width: 701rem;
  }
  .md:w-702 {
    width: 702rem;
  }
  .md:w-703 {
    width: 703rem;
  }
  .md:w-704 {
    width: 704rem;
  }
  .md:w-705 {
    width: 705rem;
  }
  .md:w-706 {
    width: 706rem;
  }
  .md:w-707 {
    width: 707rem;
  }
  .md:w-708 {
    width: 708rem;
  }
  .md:w-709 {
    width: 709rem;
  }
  .md:w-710 {
    width: 710rem;
  }
  .md:w-711 {
    width: 711rem;
  }
  .md:w-712 {
    width: 712rem;
  }
  .md:w-713 {
    width: 713rem;
  }
  .md:w-714 {
    width: 714rem;
  }
  .md:w-715 {
    width: 715rem;
  }
  .md:w-716 {
    width: 716rem;
  }
  .md:w-717 {
    width: 717rem;
  }
  .md:w-718 {
    width: 718rem;
  }
  .md:w-719 {
    width: 719rem;
  }
  .md:w-720 {
    width: 720rem;
  }
  .md:w-721 {
    width: 721rem;
  }
  .md:w-722 {
    width: 722rem;
  }
  .md:w-723 {
    width: 723rem;
  }
  .md:w-724 {
    width: 724rem;
  }
  .md:w-725 {
    width: 725rem;
  }
  .md:w-726 {
    width: 726rem;
  }
  .md:w-727 {
    width: 727rem;
  }
  .md:w-728 {
    width: 728rem;
  }
  .md:w-729 {
    width: 729rem;
  }
  .md:w-730 {
    width: 730rem;
  }
  .md:w-731 {
    width: 731rem;
  }
  .md:w-732 {
    width: 732rem;
  }
  .md:w-733 {
    width: 733rem;
  }
  .md:w-734 {
    width: 734rem;
  }
  .md:w-735 {
    width: 735rem;
  }
  .md:w-736 {
    width: 736rem;
  }
  .md:w-737 {
    width: 737rem;
  }
  .md:w-738 {
    width: 738rem;
  }
  .md:w-739 {
    width: 739rem;
  }
  .md:w-740 {
    width: 740rem;
  }
  .md:w-741 {
    width: 741rem;
  }
  .md:w-742 {
    width: 742rem;
  }
  .md:w-743 {
    width: 743rem;
  }
  .md:w-744 {
    width: 744rem;
  }
  .md:w-745 {
    width: 745rem;
  }
  .md:w-746 {
    width: 746rem;
  }
  .md:w-747 {
    width: 747rem;
  }
  .md:w-748 {
    width: 748rem;
  }
  .md:w-749 {
    width: 749rem;
  }
  .md:w-750 {
    width: 750rem;
  }
  .md:w-751 {
    width: 751rem;
  }
  .md:w-752 {
    width: 752rem;
  }
  .md:w-753 {
    width: 753rem;
  }
  .md:w-754 {
    width: 754rem;
  }
  .md:w-755 {
    width: 755rem;
  }
  .md:w-756 {
    width: 756rem;
  }
  .md:w-757 {
    width: 757rem;
  }
  .md:w-758 {
    width: 758rem;
  }
  .md:w-759 {
    width: 759rem;
  }
  .md:w-760 {
    width: 760rem;
  }
  .md:w-761 {
    width: 761rem;
  }
  .md:w-762 {
    width: 762rem;
  }
  .md:w-763 {
    width: 763rem;
  }
  .md:w-764 {
    width: 764rem;
  }
  .md:w-765 {
    width: 765rem;
  }
  .md:w-766 {
    width: 766rem;
  }
  .md:w-767 {
    width: 767rem;
  }
  .md:w-768 {
    width: 768rem;
  }
  .md:w-769 {
    width: 769rem;
  }
  .md:w-770 {
    width: 770rem;
  }
  .md:w-771 {
    width: 771rem;
  }
  .md:w-772 {
    width: 772rem;
  }
  .md:w-773 {
    width: 773rem;
  }
  .md:w-774 {
    width: 774rem;
  }
  .md:w-775 {
    width: 775rem;
  }
  .md:w-776 {
    width: 776rem;
  }
  .md:w-777 {
    width: 777rem;
  }
  .md:w-778 {
    width: 778rem;
  }
  .md:w-779 {
    width: 779rem;
  }
  .md:w-780 {
    width: 780rem;
  }
  .md:w-781 {
    width: 781rem;
  }
  .md:w-782 {
    width: 782rem;
  }
  .md:w-783 {
    width: 783rem;
  }
  .md:w-784 {
    width: 784rem;
  }
  .md:w-785 {
    width: 785rem;
  }
  .md:w-786 {
    width: 786rem;
  }
  .md:w-787 {
    width: 787rem;
  }
  .md:w-788 {
    width: 788rem;
  }
  .md:w-789 {
    width: 789rem;
  }
  .md:w-790 {
    width: 790rem;
  }
  .md:w-791 {
    width: 791rem;
  }
  .md:w-792 {
    width: 792rem;
  }
  .md:w-793 {
    width: 793rem;
  }
  .md:w-794 {
    width: 794rem;
  }
  .md:w-795 {
    width: 795rem;
  }
  .md:w-796 {
    width: 796rem;
  }
  .md:w-797 {
    width: 797rem;
  }
  .md:w-798 {
    width: 798rem;
  }
  .md:w-799 {
    width: 799rem;
  }
  .md:w-800 {
    width: 800rem;
  }
  .md:w-801 {
    width: 801rem;
  }
  .md:w-802 {
    width: 802rem;
  }
  .md:w-803 {
    width: 803rem;
  }
  .md:w-804 {
    width: 804rem;
  }
  .md:w-805 {
    width: 805rem;
  }
  .md:w-806 {
    width: 806rem;
  }
  .md:w-807 {
    width: 807rem;
  }
  .md:w-808 {
    width: 808rem;
  }
  .md:w-809 {
    width: 809rem;
  }
  .md:w-810 {
    width: 810rem;
  }
  .md:w-811 {
    width: 811rem;
  }
  .md:w-812 {
    width: 812rem;
  }
  .md:w-813 {
    width: 813rem;
  }
  .md:w-814 {
    width: 814rem;
  }
  .md:w-815 {
    width: 815rem;
  }
  .md:w-816 {
    width: 816rem;
  }
  .md:w-817 {
    width: 817rem;
  }
  .md:w-818 {
    width: 818rem;
  }
  .md:w-819 {
    width: 819rem;
  }
  .md:w-820 {
    width: 820rem;
  }
  .md:w-821 {
    width: 821rem;
  }
  .md:w-822 {
    width: 822rem;
  }
  .md:w-823 {
    width: 823rem;
  }
  .md:w-824 {
    width: 824rem;
  }
  .md:w-825 {
    width: 825rem;
  }
  .md:w-826 {
    width: 826rem;
  }
  .md:w-827 {
    width: 827rem;
  }
  .md:w-828 {
    width: 828rem;
  }
  .md:w-829 {
    width: 829rem;
  }
  .md:w-830 {
    width: 830rem;
  }
  .md:w-831 {
    width: 831rem;
  }
  .md:w-832 {
    width: 832rem;
  }
  .md:w-833 {
    width: 833rem;
  }
  .md:w-834 {
    width: 834rem;
  }
  .md:w-835 {
    width: 835rem;
  }
  .md:w-836 {
    width: 836rem;
  }
  .md:w-837 {
    width: 837rem;
  }
  .md:w-838 {
    width: 838rem;
  }
  .md:w-839 {
    width: 839rem;
  }
  .md:w-840 {
    width: 840rem;
  }
  .md:w-841 {
    width: 841rem;
  }
  .md:w-842 {
    width: 842rem;
  }
  .md:w-843 {
    width: 843rem;
  }
  .md:w-844 {
    width: 844rem;
  }
  .md:w-845 {
    width: 845rem;
  }
  .md:w-846 {
    width: 846rem;
  }
  .md:w-847 {
    width: 847rem;
  }
  .md:w-848 {
    width: 848rem;
  }
  .md:w-849 {
    width: 849rem;
  }
  .md:w-850 {
    width: 850rem;
  }
  .md:w-851 {
    width: 851rem;
  }
  .md:w-852 {
    width: 852rem;
  }
  .md:w-853 {
    width: 853rem;
  }
  .md:w-854 {
    width: 854rem;
  }
  .md:w-855 {
    width: 855rem;
  }
  .md:w-856 {
    width: 856rem;
  }
  .md:w-857 {
    width: 857rem;
  }
  .md:w-858 {
    width: 858rem;
  }
  .md:w-859 {
    width: 859rem;
  }
  .md:w-860 {
    width: 860rem;
  }
  .md:w-861 {
    width: 861rem;
  }
  .md:w-862 {
    width: 862rem;
  }
  .md:w-863 {
    width: 863rem;
  }
  .md:w-864 {
    width: 864rem;
  }
  .md:w-865 {
    width: 865rem;
  }
  .md:w-866 {
    width: 866rem;
  }
  .md:w-867 {
    width: 867rem;
  }
  .md:w-868 {
    width: 868rem;
  }
  .md:w-869 {
    width: 869rem;
  }
  .md:w-870 {
    width: 870rem;
  }
  .md:w-871 {
    width: 871rem;
  }
  .md:w-872 {
    width: 872rem;
  }
  .md:w-873 {
    width: 873rem;
  }
  .md:w-874 {
    width: 874rem;
  }
  .md:w-875 {
    width: 875rem;
  }
  .md:w-876 {
    width: 876rem;
  }
  .md:w-877 {
    width: 877rem;
  }
  .md:w-878 {
    width: 878rem;
  }
  .md:w-879 {
    width: 879rem;
  }
  .md:w-880 {
    width: 880rem;
  }
  .md:w-881 {
    width: 881rem;
  }
  .md:w-882 {
    width: 882rem;
  }
  .md:w-883 {
    width: 883rem;
  }
  .md:w-884 {
    width: 884rem;
  }
  .md:w-885 {
    width: 885rem;
  }
  .md:w-886 {
    width: 886rem;
  }
  .md:w-887 {
    width: 887rem;
  }
  .md:w-888 {
    width: 888rem;
  }
  .md:w-889 {
    width: 889rem;
  }
  .md:w-890 {
    width: 890rem;
  }
  .md:w-891 {
    width: 891rem;
  }
  .md:w-892 {
    width: 892rem;
  }
  .md:w-893 {
    width: 893rem;
  }
  .md:w-894 {
    width: 894rem;
  }
  .md:w-895 {
    width: 895rem;
  }
  .md:w-896 {
    width: 896rem;
  }
  .md:w-897 {
    width: 897rem;
  }
  .md:w-898 {
    width: 898rem;
  }
  .md:w-899 {
    width: 899rem;
  }
  .md:w-900 {
    width: 900rem;
  }
  .md:w-901 {
    width: 901rem;
  }
  .md:w-902 {
    width: 902rem;
  }
  .md:w-903 {
    width: 903rem;
  }
  .md:w-904 {
    width: 904rem;
  }
  .md:w-905 {
    width: 905rem;
  }
  .md:w-906 {
    width: 906rem;
  }
  .md:w-907 {
    width: 907rem;
  }
  .md:w-908 {
    width: 908rem;
  }
  .md:w-909 {
    width: 909rem;
  }
  .md:w-910 {
    width: 910rem;
  }
  .md:w-911 {
    width: 911rem;
  }
  .md:w-912 {
    width: 912rem;
  }
  .md:w-913 {
    width: 913rem;
  }
  .md:w-914 {
    width: 914rem;
  }
  .md:w-915 {
    width: 915rem;
  }
  .md:w-916 {
    width: 916rem;
  }
  .md:w-917 {
    width: 917rem;
  }
  .md:w-918 {
    width: 918rem;
  }
  .md:w-919 {
    width: 919rem;
  }
  .md:w-920 {
    width: 920rem;
  }
  .md:w-921 {
    width: 921rem;
  }
  .md:w-922 {
    width: 922rem;
  }
  .md:w-923 {
    width: 923rem;
  }
  .md:w-924 {
    width: 924rem;
  }
  .md:w-925 {
    width: 925rem;
  }
  .md:w-926 {
    width: 926rem;
  }
  .md:w-927 {
    width: 927rem;
  }
  .md:w-928 {
    width: 928rem;
  }
  .md:w-929 {
    width: 929rem;
  }
  .md:w-930 {
    width: 930rem;
  }
  .md:w-931 {
    width: 931rem;
  }
  .md:w-932 {
    width: 932rem;
  }
  .md:w-933 {
    width: 933rem;
  }
  .md:w-934 {
    width: 934rem;
  }
  .md:w-935 {
    width: 935rem;
  }
  .md:w-936 {
    width: 936rem;
  }
  .md:w-937 {
    width: 937rem;
  }
  .md:w-938 {
    width: 938rem;
  }
  .md:w-939 {
    width: 939rem;
  }
  .md:w-940 {
    width: 940rem;
  }
  .md:w-941 {
    width: 941rem;
  }
  .md:w-942 {
    width: 942rem;
  }
  .md:w-943 {
    width: 943rem;
  }
  .md:w-944 {
    width: 944rem;
  }
  .md:w-945 {
    width: 945rem;
  }
  .md:w-946 {
    width: 946rem;
  }
  .md:w-947 {
    width: 947rem;
  }
  .md:w-948 {
    width: 948rem;
  }
  .md:w-949 {
    width: 949rem;
  }
  .md:w-950 {
    width: 950rem;
  }
  .md:w-951 {
    width: 951rem;
  }
  .md:w-952 {
    width: 952rem;
  }
  .md:w-953 {
    width: 953rem;
  }
  .md:w-954 {
    width: 954rem;
  }
  .md:w-955 {
    width: 955rem;
  }
  .md:w-956 {
    width: 956rem;
  }
  .md:w-957 {
    width: 957rem;
  }
  .md:w-958 {
    width: 958rem;
  }
  .md:w-959 {
    width: 959rem;
  }
  .md:w-960 {
    width: 960rem;
  }
  .md:w-961 {
    width: 961rem;
  }
  .md:w-962 {
    width: 962rem;
  }
  .md:w-963 {
    width: 963rem;
  }
  .md:w-964 {
    width: 964rem;
  }
  .md:w-965 {
    width: 965rem;
  }
  .md:w-966 {
    width: 966rem;
  }
  .md:w-967 {
    width: 967rem;
  }
  .md:w-968 {
    width: 968rem;
  }
  .md:w-969 {
    width: 969rem;
  }
  .md:w-970 {
    width: 970rem;
  }
  .md:w-971 {
    width: 971rem;
  }
  .md:w-972 {
    width: 972rem;
  }
  .md:w-973 {
    width: 973rem;
  }
  .md:w-974 {
    width: 974rem;
  }
  .md:w-975 {
    width: 975rem;
  }
  .md:w-976 {
    width: 976rem;
  }
  .md:w-977 {
    width: 977rem;
  }
  .md:w-978 {
    width: 978rem;
  }
  .md:w-979 {
    width: 979rem;
  }
  .md:w-980 {
    width: 980rem;
  }
  .md:w-981 {
    width: 981rem;
  }
  .md:w-982 {
    width: 982rem;
  }
  .md:w-983 {
    width: 983rem;
  }
  .md:w-984 {
    width: 984rem;
  }
  .md:w-985 {
    width: 985rem;
  }
  .md:w-986 {
    width: 986rem;
  }
  .md:w-987 {
    width: 987rem;
  }
  .md:w-988 {
    width: 988rem;
  }
  .md:w-989 {
    width: 989rem;
  }
  .md:w-990 {
    width: 990rem;
  }
  .md:w-991 {
    width: 991rem;
  }
  .md:w-992 {
    width: 992rem;
  }
  .md:w-993 {
    width: 993rem;
  }
  .md:w-994 {
    width: 994rem;
  }
  .md:w-995 {
    width: 995rem;
  }
  .md:w-996 {
    width: 996rem;
  }
  .md:w-997 {
    width: 997rem;
  }
  .md:w-998 {
    width: 998rem;
  }
  .md:w-999 {
    width: 999rem;
  }
  .md:w-1000 {
    width: 1000rem;
  }
  .md:w-1001 {
    width: 1001rem;
  }
  .md:w-1002 {
    width: 1002rem;
  }
  .md:w-1003 {
    width: 1003rem;
  }
  .md:w-1004 {
    width: 1004rem;
  }
  .md:w-1005 {
    width: 1005rem;
  }
  .md:w-1006 {
    width: 1006rem;
  }
  .md:w-1007 {
    width: 1007rem;
  }
  .md:w-1008 {
    width: 1008rem;
  }
  .md:w-1009 {
    width: 1009rem;
  }
  .md:w-1010 {
    width: 1010rem;
  }
  .md:w-1011 {
    width: 1011rem;
  }
  .md:w-1012 {
    width: 1012rem;
  }
  .md:w-1013 {
    width: 1013rem;
  }
  .md:w-1014 {
    width: 1014rem;
  }
  .md:w-1015 {
    width: 1015rem;
  }
  .md:w-1016 {
    width: 1016rem;
  }
  .md:w-1017 {
    width: 1017rem;
  }
  .md:w-1018 {
    width: 1018rem;
  }
  .md:w-1019 {
    width: 1019rem;
  }
  .md:w-1020 {
    width: 1020rem;
  }
  .md:w-1021 {
    width: 1021rem;
  }
  .md:w-1022 {
    width: 1022rem;
  }
  .md:w-1023 {
    width: 1023rem;
  }
  .md:w-1024 {
    width: 1024rem;
  }
  .md:w-1025 {
    width: 1025rem;
  }
  .md:w-1026 {
    width: 1026rem;
  }
  .md:w-1027 {
    width: 1027rem;
  }
  .md:w-1028 {
    width: 1028rem;
  }
  .md:w-1029 {
    width: 1029rem;
  }
  .md:w-1030 {
    width: 1030rem;
  }
  .md:w-1031 {
    width: 1031rem;
  }
  .md:w-1032 {
    width: 1032rem;
  }
  .md:w-1033 {
    width: 1033rem;
  }
  .md:w-1034 {
    width: 1034rem;
  }
  .md:w-1035 {
    width: 1035rem;
  }
  .md:w-1036 {
    width: 1036rem;
  }
  .md:w-1037 {
    width: 1037rem;
  }
  .md:w-1038 {
    width: 1038rem;
  }
  .md:w-1039 {
    width: 1039rem;
  }
  .md:w-1040 {
    width: 1040rem;
  }
  .md:w-1041 {
    width: 1041rem;
  }
  .md:w-1042 {
    width: 1042rem;
  }
  .md:w-1043 {
    width: 1043rem;
  }
  .md:w-1044 {
    width: 1044rem;
  }
  .md:w-1045 {
    width: 1045rem;
  }
  .md:w-1046 {
    width: 1046rem;
  }
  .md:w-1047 {
    width: 1047rem;
  }
  .md:w-1048 {
    width: 1048rem;
  }
  .md:w-1049 {
    width: 1049rem;
  }
  .md:w-1050 {
    width: 1050rem;
  }
  .md:w-1051 {
    width: 1051rem;
  }
  .md:w-1052 {
    width: 1052rem;
  }
  .md:w-1053 {
    width: 1053rem;
  }
  .md:w-1054 {
    width: 1054rem;
  }
  .md:w-1055 {
    width: 1055rem;
  }
  .md:w-1056 {
    width: 1056rem;
  }
  .md:w-1057 {
    width: 1057rem;
  }
  .md:w-1058 {
    width: 1058rem;
  }
  .md:w-1059 {
    width: 1059rem;
  }
  .md:w-1060 {
    width: 1060rem;
  }
  .md:w-1061 {
    width: 1061rem;
  }
  .md:w-1062 {
    width: 1062rem;
  }
  .md:w-1063 {
    width: 1063rem;
  }
  .md:w-1064 {
    width: 1064rem;
  }
  .md:w-1065 {
    width: 1065rem;
  }
  .md:w-1066 {
    width: 1066rem;
  }
  .md:w-1067 {
    width: 1067rem;
  }
  .md:w-1068 {
    width: 1068rem;
  }
  .md:w-1069 {
    width: 1069rem;
  }
  .md:w-1070 {
    width: 1070rem;
  }
  .md:w-1071 {
    width: 1071rem;
  }
  .md:w-1072 {
    width: 1072rem;
  }
  .md:w-1073 {
    width: 1073rem;
  }
  .md:w-1074 {
    width: 1074rem;
  }
  .md:w-1075 {
    width: 1075rem;
  }
  .md:w-1076 {
    width: 1076rem;
  }
  .md:w-1077 {
    width: 1077rem;
  }
  .md:w-1078 {
    width: 1078rem;
  }
  .md:w-1079 {
    width: 1079rem;
  }
  .md:w-1080 {
    width: 1080rem;
  }
  .md:w-1081 {
    width: 1081rem;
  }
  .md:w-1082 {
    width: 1082rem;
  }
  .md:w-1083 {
    width: 1083rem;
  }
  .md:w-1084 {
    width: 1084rem;
  }
  .md:w-1085 {
    width: 1085rem;
  }
  .md:w-1086 {
    width: 1086rem;
  }
  .md:w-1087 {
    width: 1087rem;
  }
  .md:w-1088 {
    width: 1088rem;
  }
  .md:w-1089 {
    width: 1089rem;
  }
  .md:w-1090 {
    width: 1090rem;
  }
  .md:w-1091 {
    width: 1091rem;
  }
  .md:w-1092 {
    width: 1092rem;
  }
  .md:w-1093 {
    width: 1093rem;
  }
  .md:w-1094 {
    width: 1094rem;
  }
  .md:w-1095 {
    width: 1095rem;
  }
  .md:w-1096 {
    width: 1096rem;
  }
  .md:w-1097 {
    width: 1097rem;
  }
  .md:w-1098 {
    width: 1098rem;
  }
  .md:w-1099 {
    width: 1099rem;
  }
  .md:w-1100 {
    width: 1100rem;
  }
  .md:w-1101 {
    width: 1101rem;
  }
  .md:w-1102 {
    width: 1102rem;
  }
  .md:w-1103 {
    width: 1103rem;
  }
  .md:w-1104 {
    width: 1104rem;
  }
  .md:w-1105 {
    width: 1105rem;
  }
  .md:w-1106 {
    width: 1106rem;
  }
  .md:w-1107 {
    width: 1107rem;
  }
  .md:w-1108 {
    width: 1108rem;
  }
  .md:w-1109 {
    width: 1109rem;
  }
  .md:w-1110 {
    width: 1110rem;
  }
  .md:w-1111 {
    width: 1111rem;
  }
  .md:w-1112 {
    width: 1112rem;
  }
  .md:w-1113 {
    width: 1113rem;
  }
  .md:w-1114 {
    width: 1114rem;
  }
  .md:w-1115 {
    width: 1115rem;
  }
  .md:w-1116 {
    width: 1116rem;
  }
  .md:w-1117 {
    width: 1117rem;
  }
  .md:w-1118 {
    width: 1118rem;
  }
  .md:w-1119 {
    width: 1119rem;
  }
  .md:w-1120 {
    width: 1120rem;
  }
  .md:w-1121 {
    width: 1121rem;
  }
  .md:w-1122 {
    width: 1122rem;
  }
  .md:w-1123 {
    width: 1123rem;
  }
  .md:w-1124 {
    width: 1124rem;
  }
  .md:w-1125 {
    width: 1125rem;
  }
  .md:w-1126 {
    width: 1126rem;
  }
  .md:w-1127 {
    width: 1127rem;
  }
  .md:w-1128 {
    width: 1128rem;
  }
  .md:w-1129 {
    width: 1129rem;
  }
  .md:w-1130 {
    width: 1130rem;
  }
  .md:w-1131 {
    width: 1131rem;
  }
  .md:w-1132 {
    width: 1132rem;
  }
  .md:w-1133 {
    width: 1133rem;
  }
  .md:w-1134 {
    width: 1134rem;
  }
  .md:w-1135 {
    width: 1135rem;
  }
  .md:w-1136 {
    width: 1136rem;
  }
  .md:w-1137 {
    width: 1137rem;
  }
  .md:w-1138 {
    width: 1138rem;
  }
  .md:w-1139 {
    width: 1139rem;
  }
  .md:w-1140 {
    width: 1140rem;
  }
  .md:w-1141 {
    width: 1141rem;
  }
  .md:w-1142 {
    width: 1142rem;
  }
  .md:w-1143 {
    width: 1143rem;
  }
  .md:w-1144 {
    width: 1144rem;
  }
  .md:w-1145 {
    width: 1145rem;
  }
  .md:w-1146 {
    width: 1146rem;
  }
  .md:w-1147 {
    width: 1147rem;
  }
  .md:w-1148 {
    width: 1148rem;
  }
  .md:w-1149 {
    width: 1149rem;
  }
  .md:w-1150 {
    width: 1150rem;
  }
  .md:w-1151 {
    width: 1151rem;
  }
  .md:w-1152 {
    width: 1152rem;
  }
  .md:w-1153 {
    width: 1153rem;
  }
  .md:w-1154 {
    width: 1154rem;
  }
  .md:w-1155 {
    width: 1155rem;
  }
  .md:w-1156 {
    width: 1156rem;
  }
  .md:w-1157 {
    width: 1157rem;
  }
  .md:w-1158 {
    width: 1158rem;
  }
  .md:w-1159 {
    width: 1159rem;
  }
  .md:w-1160 {
    width: 1160rem;
  }
  .md:w-1161 {
    width: 1161rem;
  }
  .md:w-1162 {
    width: 1162rem;
  }
  .md:w-1163 {
    width: 1163rem;
  }
  .md:w-1164 {
    width: 1164rem;
  }
  .md:w-1165 {
    width: 1165rem;
  }
  .md:w-1166 {
    width: 1166rem;
  }
  .md:w-1167 {
    width: 1167rem;
  }
  .md:w-1168 {
    width: 1168rem;
  }
  .md:w-1169 {
    width: 1169rem;
  }
  .md:w-1170 {
    width: 1170rem;
  }
  .md:w-1171 {
    width: 1171rem;
  }
  .md:w-1172 {
    width: 1172rem;
  }
  .md:w-1173 {
    width: 1173rem;
  }
  .md:w-1174 {
    width: 1174rem;
  }
  .md:w-1175 {
    width: 1175rem;
  }
  .md:w-1176 {
    width: 1176rem;
  }
  .md:w-1177 {
    width: 1177rem;
  }
  .md:w-1178 {
    width: 1178rem;
  }
  .md:w-1179 {
    width: 1179rem;
  }
  .md:w-1180 {
    width: 1180rem;
  }
  .md:w-1181 {
    width: 1181rem;
  }
  .md:w-1182 {
    width: 1182rem;
  }
  .md:w-1183 {
    width: 1183rem;
  }
  .md:w-1184 {
    width: 1184rem;
  }
  .md:w-1185 {
    width: 1185rem;
  }
  .md:w-1186 {
    width: 1186rem;
  }
  .md:w-1187 {
    width: 1187rem;
  }
  .md:w-1188 {
    width: 1188rem;
  }
  .md:w-1189 {
    width: 1189rem;
  }
  .md:w-1190 {
    width: 1190rem;
  }
  .md:w-1191 {
    width: 1191rem;
  }
  .md:w-1192 {
    width: 1192rem;
  }
  .md:w-1193 {
    width: 1193rem;
  }
  .md:w-1194 {
    width: 1194rem;
  }
  .md:w-1195 {
    width: 1195rem;
  }
  .md:w-1196 {
    width: 1196rem;
  }
  .md:w-1197 {
    width: 1197rem;
  }
  .md:w-1198 {
    width: 1198rem;
  }
  .md:w-1199 {
    width: 1199rem;
  }
  .md:w-1200 {
    width: 1200rem;
  }
  .md:w-1201 {
    width: 1201rem;
  }
  .md:w-1202 {
    width: 1202rem;
  }
  .md:w-1203 {
    width: 1203rem;
  }
  .md:w-1204 {
    width: 1204rem;
  }
  .md:w-1205 {
    width: 1205rem;
  }
  .md:w-1206 {
    width: 1206rem;
  }
  .md:w-1207 {
    width: 1207rem;
  }
  .md:w-1208 {
    width: 1208rem;
  }
  .md:w-1209 {
    width: 1209rem;
  }
  .md:w-1210 {
    width: 1210rem;
  }
  .md:w-1211 {
    width: 1211rem;
  }
  .md:w-1212 {
    width: 1212rem;
  }
  .md:w-1213 {
    width: 1213rem;
  }
  .md:w-1214 {
    width: 1214rem;
  }
  .md:w-1215 {
    width: 1215rem;
  }
  .md:w-1216 {
    width: 1216rem;
  }
  .md:w-1217 {
    width: 1217rem;
  }
  .md:w-1218 {
    width: 1218rem;
  }
  .md:w-1219 {
    width: 1219rem;
  }
  .md:w-1220 {
    width: 1220rem;
  }
  .md:w-1221 {
    width: 1221rem;
  }
  .md:w-1222 {
    width: 1222rem;
  }
  .md:w-1223 {
    width: 1223rem;
  }
  .md:w-1224 {
    width: 1224rem;
  }
  .md:w-1225 {
    width: 1225rem;
  }
  .md:w-1226 {
    width: 1226rem;
  }
  .md:w-1227 {
    width: 1227rem;
  }
  .md:w-1228 {
    width: 1228rem;
  }
  .md:w-1229 {
    width: 1229rem;
  }
  .md:w-1230 {
    width: 1230rem;
  }
  .md:w-1231 {
    width: 1231rem;
  }
  .md:w-1232 {
    width: 1232rem;
  }
  .md:w-1233 {
    width: 1233rem;
  }
  .md:w-1234 {
    width: 1234rem;
  }
  .md:w-1235 {
    width: 1235rem;
  }
  .md:w-1236 {
    width: 1236rem;
  }
  .md:w-1237 {
    width: 1237rem;
  }
  .md:w-1238 {
    width: 1238rem;
  }
  .md:w-1239 {
    width: 1239rem;
  }
  .md:w-1240 {
    width: 1240rem;
  }
  .md:w-1241 {
    width: 1241rem;
  }
  .md:w-1242 {
    width: 1242rem;
  }
  .md:w-1243 {
    width: 1243rem;
  }
  .md:w-1244 {
    width: 1244rem;
  }
  .md:w-1245 {
    width: 1245rem;
  }
  .md:w-1246 {
    width: 1246rem;
  }
  .md:w-1247 {
    width: 1247rem;
  }
  .md:w-1248 {
    width: 1248rem;
  }
  .md:w-1249 {
    width: 1249rem;
  }
  .md:w-1250 {
    width: 1250rem;
  }
  .md:w-1251 {
    width: 1251rem;
  }
  .md:w-1252 {
    width: 1252rem;
  }
  .md:w-1253 {
    width: 1253rem;
  }
  .md:w-1254 {
    width: 1254rem;
  }
  .md:w-1255 {
    width: 1255rem;
  }
  .md:w-1256 {
    width: 1256rem;
  }
  .md:w-1257 {
    width: 1257rem;
  }
  .md:w-1258 {
    width: 1258rem;
  }
  .md:w-1259 {
    width: 1259rem;
  }
  .md:w-1260 {
    width: 1260rem;
  }
  .md:w-1261 {
    width: 1261rem;
  }
  .md:w-1262 {
    width: 1262rem;
  }
  .md:w-1263 {
    width: 1263rem;
  }
  .md:w-1264 {
    width: 1264rem;
  }
  .md:w-1265 {
    width: 1265rem;
  }
  .md:w-1266 {
    width: 1266rem;
  }
  .md:w-1267 {
    width: 1267rem;
  }
  .md:w-1268 {
    width: 1268rem;
  }
  .md:w-1269 {
    width: 1269rem;
  }
  .md:w-1270 {
    width: 1270rem;
  }
  .md:w-1271 {
    width: 1271rem;
  }
  .md:w-1272 {
    width: 1272rem;
  }
  .md:w-1273 {
    width: 1273rem;
  }
  .md:w-1274 {
    width: 1274rem;
  }
  .md:w-1275 {
    width: 1275rem;
  }
  .md:w-1276 {
    width: 1276rem;
  }
  .md:w-1277 {
    width: 1277rem;
  }
  .md:w-1278 {
    width: 1278rem;
  }
  .md:w-1279 {
    width: 1279rem;
  }
  .md:w-1280 {
    width: 1280rem;
  }
  .md:w-1281 {
    width: 1281rem;
  }
  .md:w-1282 {
    width: 1282rem;
  }
  .md:w-1283 {
    width: 1283rem;
  }
  .md:w-1284 {
    width: 1284rem;
  }
  .md:w-1285 {
    width: 1285rem;
  }
  .md:w-1286 {
    width: 1286rem;
  }
  .md:w-1287 {
    width: 1287rem;
  }
  .md:w-1288 {
    width: 1288rem;
  }
  .md:w-1289 {
    width: 1289rem;
  }
  .md:w-1290 {
    width: 1290rem;
  }
  .md:w-1291 {
    width: 1291rem;
  }
  .md:w-1292 {
    width: 1292rem;
  }
  .md:w-1293 {
    width: 1293rem;
  }
  .md:w-1294 {
    width: 1294rem;
  }
  .md:w-1295 {
    width: 1295rem;
  }
  .md:w-1296 {
    width: 1296rem;
  }
  .md:w-1297 {
    width: 1297rem;
  }
  .md:w-1298 {
    width: 1298rem;
  }
  .md:w-1299 {
    width: 1299rem;
  }
  .md:w-1300 {
    width: 1300rem;
  }
  .md:w-1301 {
    width: 1301rem;
  }
  .md:w-1302 {
    width: 1302rem;
  }
  .md:w-1303 {
    width: 1303rem;
  }
  .md:w-1304 {
    width: 1304rem;
  }
  .md:w-1305 {
    width: 1305rem;
  }
  .md:w-1306 {
    width: 1306rem;
  }
  .md:w-1307 {
    width: 1307rem;
  }
  .md:w-1308 {
    width: 1308rem;
  }
  .md:w-1309 {
    width: 1309rem;
  }
  .md:w-1310 {
    width: 1310rem;
  }
  .md:w-1311 {
    width: 1311rem;
  }
  .md:w-1312 {
    width: 1312rem;
  }
  .md:w-1313 {
    width: 1313rem;
  }
  .md:w-1314 {
    width: 1314rem;
  }
  .md:w-1315 {
    width: 1315rem;
  }
  .md:w-1316 {
    width: 1316rem;
  }
  .md:w-1317 {
    width: 1317rem;
  }
  .md:w-1318 {
    width: 1318rem;
  }
  .md:w-1319 {
    width: 1319rem;
  }
  .md:w-1320 {
    width: 1320rem;
  }
  .md:w-1321 {
    width: 1321rem;
  }
  .md:w-1322 {
    width: 1322rem;
  }
  .md:w-1323 {
    width: 1323rem;
  }
  .md:w-1324 {
    width: 1324rem;
  }
  .md:w-1325 {
    width: 1325rem;
  }
  .md:w-1326 {
    width: 1326rem;
  }
  .md:w-1327 {
    width: 1327rem;
  }
  .md:w-1328 {
    width: 1328rem;
  }
  .md:w-1329 {
    width: 1329rem;
  }
  .md:w-1330 {
    width: 1330rem;
  }
  .md:w-1331 {
    width: 1331rem;
  }
  .md:w-1332 {
    width: 1332rem;
  }
  .md:w-1333 {
    width: 1333rem;
  }
  .md:w-1334 {
    width: 1334rem;
  }
  .md:w-1335 {
    width: 1335rem;
  }
  .md:w-1336 {
    width: 1336rem;
  }
  .md:w-1337 {
    width: 1337rem;
  }
  .md:w-1338 {
    width: 1338rem;
  }
  .md:w-1339 {
    width: 1339rem;
  }
  .md:w-1340 {
    width: 1340rem;
  }
  .md:w-1341 {
    width: 1341rem;
  }
  .md:w-1342 {
    width: 1342rem;
  }
  .md:w-1343 {
    width: 1343rem;
  }
  .md:w-1344 {
    width: 1344rem;
  }
  .md:w-1345 {
    width: 1345rem;
  }
  .md:w-1346 {
    width: 1346rem;
  }
  .md:w-1347 {
    width: 1347rem;
  }
  .md:w-1348 {
    width: 1348rem;
  }
  .md:w-1349 {
    width: 1349rem;
  }
  .md:w-1350 {
    width: 1350rem;
  }
  .md:w-1351 {
    width: 1351rem;
  }
  .md:w-1352 {
    width: 1352rem;
  }
  .md:w-1353 {
    width: 1353rem;
  }
  .md:w-1354 {
    width: 1354rem;
  }
  .md:w-1355 {
    width: 1355rem;
  }
  .md:w-1356 {
    width: 1356rem;
  }
  .md:w-1357 {
    width: 1357rem;
  }
  .md:w-1358 {
    width: 1358rem;
  }
  .md:w-1359 {
    width: 1359rem;
  }
  .md:w-1360 {
    width: 1360rem;
  }
  .md:w-1361 {
    width: 1361rem;
  }
  .md:w-1362 {
    width: 1362rem;
  }
  .md:w-1363 {
    width: 1363rem;
  }
  .md:w-1364 {
    width: 1364rem;
  }
  .md:w-1365 {
    width: 1365rem;
  }
  .md:w-1366 {
    width: 1366rem;
  }
  .md:w-1367 {
    width: 1367rem;
  }
  .md:w-1368 {
    width: 1368rem;
  }
  .md:w-1369 {
    width: 1369rem;
  }
  .md:w-1370 {
    width: 1370rem;
  }
  .md:w-1371 {
    width: 1371rem;
  }
  .md:w-1372 {
    width: 1372rem;
  }
  .md:w-1373 {
    width: 1373rem;
  }
  .md:w-1374 {
    width: 1374rem;
  }
  .md:w-1375 {
    width: 1375rem;
  }
  .md:w-1376 {
    width: 1376rem;
  }
  .md:w-1377 {
    width: 1377rem;
  }
  .md:w-1378 {
    width: 1378rem;
  }
  .md:w-1379 {
    width: 1379rem;
  }
  .md:w-1380 {
    width: 1380rem;
  }
  .md:w-1381 {
    width: 1381rem;
  }
  .md:w-1382 {
    width: 1382rem;
  }
  .md:w-1383 {
    width: 1383rem;
  }
  .md:w-1384 {
    width: 1384rem;
  }
  .md:w-1385 {
    width: 1385rem;
  }
  .md:w-1386 {
    width: 1386rem;
  }
  .md:w-1387 {
    width: 1387rem;
  }
  .md:w-1388 {
    width: 1388rem;
  }
  .md:w-1389 {
    width: 1389rem;
  }
  .md:w-1390 {
    width: 1390rem;
  }
  .md:w-1391 {
    width: 1391rem;
  }
  .md:w-1392 {
    width: 1392rem;
  }
  .md:w-1393 {
    width: 1393rem;
  }
  .md:w-1394 {
    width: 1394rem;
  }
  .md:w-1395 {
    width: 1395rem;
  }
  .md:w-1396 {
    width: 1396rem;
  }
  .md:w-1397 {
    width: 1397rem;
  }
  .md:w-1398 {
    width: 1398rem;
  }
  .md:w-1399 {
    width: 1399rem;
  }
  .md:w-1400 {
    width: 1400rem;
  }
  .md:w-1401 {
    width: 1401rem;
  }
  .md:w-1402 {
    width: 1402rem;
  }
  .md:w-1403 {
    width: 1403rem;
  }
  .md:w-1404 {
    width: 1404rem;
  }
  .md:w-1405 {
    width: 1405rem;
  }
  .md:w-1406 {
    width: 1406rem;
  }
  .md:w-1407 {
    width: 1407rem;
  }
  .md:w-1408 {
    width: 1408rem;
  }
  .md:w-1409 {
    width: 1409rem;
  }
  .md:w-1410 {
    width: 1410rem;
  }
  .md:w-1411 {
    width: 1411rem;
  }
  .md:w-1412 {
    width: 1412rem;
  }
  .md:w-1413 {
    width: 1413rem;
  }
  .md:w-1414 {
    width: 1414rem;
  }
  .md:w-1415 {
    width: 1415rem;
  }
  .md:w-1416 {
    width: 1416rem;
  }
  .md:w-1417 {
    width: 1417rem;
  }
  .md:w-1418 {
    width: 1418rem;
  }
  .md:w-1419 {
    width: 1419rem;
  }
  .md:w-1420 {
    width: 1420rem;
  }
  .md:w-1421 {
    width: 1421rem;
  }
  .md:w-1422 {
    width: 1422rem;
  }
  .md:w-1423 {
    width: 1423rem;
  }
  .md:w-1424 {
    width: 1424rem;
  }
  .md:w-1425 {
    width: 1425rem;
  }
  .md:w-1426 {
    width: 1426rem;
  }
  .md:w-1427 {
    width: 1427rem;
  }
  .md:w-1428 {
    width: 1428rem;
  }
  .md:w-1429 {
    width: 1429rem;
  }
  .md:w-1430 {
    width: 1430rem;
  }
  .md:w-1431 {
    width: 1431rem;
  }
  .md:w-1432 {
    width: 1432rem;
  }
  .md:w-1433 {
    width: 1433rem;
  }
  .md:w-1434 {
    width: 1434rem;
  }
  .md:w-1435 {
    width: 1435rem;
  }
  .md:w-1436 {
    width: 1436rem;
  }
  .md:w-1437 {
    width: 1437rem;
  }
  .md:w-1438 {
    width: 1438rem;
  }
  .md:w-1439 {
    width: 1439rem;
  }
  .md:w-1440 {
    width: 1440rem;
  }
  .md:w-1441 {
    width: 1441rem;
  }
  .md:w-1442 {
    width: 1442rem;
  }
  .md:w-1443 {
    width: 1443rem;
  }
  .md:w-1444 {
    width: 1444rem;
  }
  .md:w-1445 {
    width: 1445rem;
  }
  .md:w-1446 {
    width: 1446rem;
  }
  .md:w-1447 {
    width: 1447rem;
  }
  .md:w-1448 {
    width: 1448rem;
  }
  .md:w-1449 {
    width: 1449rem;
  }
  .md:w-1450 {
    width: 1450rem;
  }
  .md:w-1451 {
    width: 1451rem;
  }
  .md:w-1452 {
    width: 1452rem;
  }
  .md:w-1453 {
    width: 1453rem;
  }
  .md:w-1454 {
    width: 1454rem;
  }
  .md:w-1455 {
    width: 1455rem;
  }
  .md:w-1456 {
    width: 1456rem;
  }
  .md:w-1457 {
    width: 1457rem;
  }
  .md:w-1458 {
    width: 1458rem;
  }
  .md:w-1459 {
    width: 1459rem;
  }
  .md:w-1460 {
    width: 1460rem;
  }
  .md:w-1461 {
    width: 1461rem;
  }
  .md:w-1462 {
    width: 1462rem;
  }
  .md:w-1463 {
    width: 1463rem;
  }
  .md:w-1464 {
    width: 1464rem;
  }
  .md:w-1465 {
    width: 1465rem;
  }
  .md:w-1466 {
    width: 1466rem;
  }
  .md:w-1467 {
    width: 1467rem;
  }
  .md:w-1468 {
    width: 1468rem;
  }
  .md:w-1469 {
    width: 1469rem;
  }
  .md:w-1470 {
    width: 1470rem;
  }
  .md:w-1471 {
    width: 1471rem;
  }
  .md:w-1472 {
    width: 1472rem;
  }
  .md:w-1473 {
    width: 1473rem;
  }
  .md:w-1474 {
    width: 1474rem;
  }
  .md:w-1475 {
    width: 1475rem;
  }
  .md:w-1476 {
    width: 1476rem;
  }
  .md:w-1477 {
    width: 1477rem;
  }
  .md:w-1478 {
    width: 1478rem;
  }
  .md:w-1479 {
    width: 1479rem;
  }
  .md:w-1480 {
    width: 1480rem;
  }
  .md:w-1481 {
    width: 1481rem;
  }
  .md:w-1482 {
    width: 1482rem;
  }
  .md:w-1483 {
    width: 1483rem;
  }
  .md:w-1484 {
    width: 1484rem;
  }
  .md:w-1485 {
    width: 1485rem;
  }
  .md:w-1486 {
    width: 1486rem;
  }
  .md:w-1487 {
    width: 1487rem;
  }
  .md:w-1488 {
    width: 1488rem;
  }
  .md:w-1489 {
    width: 1489rem;
  }
  .md:w-1490 {
    width: 1490rem;
  }
  .md:w-1491 {
    width: 1491rem;
  }
  .md:w-1492 {
    width: 1492rem;
  }
  .md:w-1493 {
    width: 1493rem;
  }
  .md:w-1494 {
    width: 1494rem;
  }
  .md:w-1495 {
    width: 1495rem;
  }
  .md:w-1496 {
    width: 1496rem;
  }
  .md:w-1497 {
    width: 1497rem;
  }
  .md:w-1498 {
    width: 1498rem;
  }
  .md:w-1499 {
    width: 1499rem;
  }
  .md:w-1500 {
    width: 1500rem;
  }
  .md:w-1501 {
    width: 1501rem;
  }
  .md:w-1502 {
    width: 1502rem;
  }
  .md:w-1503 {
    width: 1503rem;
  }
  .md:w-1504 {
    width: 1504rem;
  }
  .md:w-1505 {
    width: 1505rem;
  }
  .md:w-1506 {
    width: 1506rem;
  }
  .md:w-1507 {
    width: 1507rem;
  }
  .md:w-1508 {
    width: 1508rem;
  }
  .md:w-1509 {
    width: 1509rem;
  }
  .md:w-1510 {
    width: 1510rem;
  }
  .md:w-1511 {
    width: 1511rem;
  }
  .md:w-1512 {
    width: 1512rem;
  }
  .md:w-1513 {
    width: 1513rem;
  }
  .md:w-1514 {
    width: 1514rem;
  }
  .md:w-1515 {
    width: 1515rem;
  }
  .md:w-1516 {
    width: 1516rem;
  }
  .md:w-1517 {
    width: 1517rem;
  }
  .md:w-1518 {
    width: 1518rem;
  }
  .md:w-1519 {
    width: 1519rem;
  }
  .md:w-1520 {
    width: 1520rem;
  }
  .md:w-1521 {
    width: 1521rem;
  }
  .md:w-1522 {
    width: 1522rem;
  }
  .md:w-1523 {
    width: 1523rem;
  }
  .md:w-1524 {
    width: 1524rem;
  }
  .md:w-1525 {
    width: 1525rem;
  }
  .md:w-1526 {
    width: 1526rem;
  }
  .md:w-1527 {
    width: 1527rem;
  }
  .md:w-1528 {
    width: 1528rem;
  }
  .md:w-1529 {
    width: 1529rem;
  }
  .md:w-1530 {
    width: 1530rem;
  }
  .md:w-1531 {
    width: 1531rem;
  }
  .md:w-1532 {
    width: 1532rem;
  }
  .md:w-1533 {
    width: 1533rem;
  }
  .md:w-1534 {
    width: 1534rem;
  }
  .md:w-1535 {
    width: 1535rem;
  }
  .md:w-1536 {
    width: 1536rem;
  }
  .md:w-1537 {
    width: 1537rem;
  }
  .md:w-1538 {
    width: 1538rem;
  }
  .md:w-1539 {
    width: 1539rem;
  }
  .md:w-1540 {
    width: 1540rem;
  }
  .md:w-1541 {
    width: 1541rem;
  }
  .md:w-1542 {
    width: 1542rem;
  }
  .md:w-1543 {
    width: 1543rem;
  }
  .md:w-1544 {
    width: 1544rem;
  }
  .md:w-1545 {
    width: 1545rem;
  }
  .md:w-1546 {
    width: 1546rem;
  }
  .md:w-1547 {
    width: 1547rem;
  }
  .md:w-1548 {
    width: 1548rem;
  }
  .md:w-1549 {
    width: 1549rem;
  }
  .md:w-1550 {
    width: 1550rem;
  }
  .md:w-1551 {
    width: 1551rem;
  }
  .md:w-1552 {
    width: 1552rem;
  }
  .md:w-1553 {
    width: 1553rem;
  }
  .md:w-1554 {
    width: 1554rem;
  }
  .md:w-1555 {
    width: 1555rem;
  }
  .md:w-1556 {
    width: 1556rem;
  }
  .md:w-1557 {
    width: 1557rem;
  }
  .md:w-1558 {
    width: 1558rem;
  }
  .md:w-1559 {
    width: 1559rem;
  }
  .md:w-1560 {
    width: 1560rem;
  }
  .md:w-1561 {
    width: 1561rem;
  }
  .md:w-1562 {
    width: 1562rem;
  }
  .md:w-1563 {
    width: 1563rem;
  }
  .md:w-1564 {
    width: 1564rem;
  }
  .md:w-1565 {
    width: 1565rem;
  }
  .md:w-1566 {
    width: 1566rem;
  }
  .md:w-1567 {
    width: 1567rem;
  }
  .md:w-1568 {
    width: 1568rem;
  }
  .md:w-1569 {
    width: 1569rem;
  }
  .md:w-1570 {
    width: 1570rem;
  }
  .md:w-1571 {
    width: 1571rem;
  }
  .md:w-1572 {
    width: 1572rem;
  }
  .md:w-1573 {
    width: 1573rem;
  }
  .md:w-1574 {
    width: 1574rem;
  }
  .md:w-1575 {
    width: 1575rem;
  }
  .md:w-1576 {
    width: 1576rem;
  }
  .md:w-1577 {
    width: 1577rem;
  }
  .md:w-1578 {
    width: 1578rem;
  }
  .md:w-1579 {
    width: 1579rem;
  }
  .md:w-1580 {
    width: 1580rem;
  }
  .md:w-1581 {
    width: 1581rem;
  }
  .md:w-1582 {
    width: 1582rem;
  }
  .md:w-1583 {
    width: 1583rem;
  }
  .md:w-1584 {
    width: 1584rem;
  }
  .md:w-1585 {
    width: 1585rem;
  }
  .md:w-1586 {
    width: 1586rem;
  }
  .md:w-1587 {
    width: 1587rem;
  }
  .md:w-1588 {
    width: 1588rem;
  }
  .md:w-1589 {
    width: 1589rem;
  }
  .md:w-1590 {
    width: 1590rem;
  }
  .md:w-1591 {
    width: 1591rem;
  }
  .md:w-1592 {
    width: 1592rem;
  }
  .md:w-1593 {
    width: 1593rem;
  }
  .md:w-1594 {
    width: 1594rem;
  }
  .md:w-1595 {
    width: 1595rem;
  }
  .md:w-1596 {
    width: 1596rem;
  }
  .md:w-1597 {
    width: 1597rem;
  }
  .md:w-1598 {
    width: 1598rem;
  }
  .md:w-1599 {
    width: 1599rem;
  }
  .md:w-1600 {
    width: 1600rem;
  }
  .md:w-1601 {
    width: 1601rem;
  }
  .md:w-1602 {
    width: 1602rem;
  }
  .md:w-1603 {
    width: 1603rem;
  }
  .md:w-1604 {
    width: 1604rem;
  }
  .md:w-1605 {
    width: 1605rem;
  }
  .md:w-1606 {
    width: 1606rem;
  }
  .md:w-1607 {
    width: 1607rem;
  }
  .md:w-1608 {
    width: 1608rem;
  }
  .md:w-1609 {
    width: 1609rem;
  }
  .md:w-1610 {
    width: 1610rem;
  }
  .md:w-1611 {
    width: 1611rem;
  }
  .md:w-1612 {
    width: 1612rem;
  }
  .md:w-1613 {
    width: 1613rem;
  }
  .md:w-1614 {
    width: 1614rem;
  }
  .md:w-1615 {
    width: 1615rem;
  }
  .md:w-1616 {
    width: 1616rem;
  }
  .md:w-1617 {
    width: 1617rem;
  }
  .md:w-1618 {
    width: 1618rem;
  }
  .md:w-1619 {
    width: 1619rem;
  }
  .md:w-1620 {
    width: 1620rem;
  }
  .md:w-1621 {
    width: 1621rem;
  }
  .md:w-1622 {
    width: 1622rem;
  }
  .md:w-1623 {
    width: 1623rem;
  }
  .md:w-1624 {
    width: 1624rem;
  }
  .md:w-1625 {
    width: 1625rem;
  }
  .md:w-1626 {
    width: 1626rem;
  }
  .md:w-1627 {
    width: 1627rem;
  }
  .md:w-1628 {
    width: 1628rem;
  }
  .md:w-1629 {
    width: 1629rem;
  }
  .md:w-1630 {
    width: 1630rem;
  }
  .md:w-1631 {
    width: 1631rem;
  }
  .md:w-1632 {
    width: 1632rem;
  }
  .md:w-1633 {
    width: 1633rem;
  }
  .md:w-1634 {
    width: 1634rem;
  }
  .md:w-1635 {
    width: 1635rem;
  }
  .md:w-1636 {
    width: 1636rem;
  }
  .md:w-1637 {
    width: 1637rem;
  }
  .md:w-1638 {
    width: 1638rem;
  }
  .md:w-1639 {
    width: 1639rem;
  }
  .md:w-1640 {
    width: 1640rem;
  }
  .md:w-1641 {
    width: 1641rem;
  }
  .md:w-1642 {
    width: 1642rem;
  }
  .md:w-1643 {
    width: 1643rem;
  }
  .md:w-1644 {
    width: 1644rem;
  }
  .md:w-1645 {
    width: 1645rem;
  }
  .md:w-1646 {
    width: 1646rem;
  }
  .md:w-1647 {
    width: 1647rem;
  }
  .md:w-1648 {
    width: 1648rem;
  }
  .md:w-1649 {
    width: 1649rem;
  }
  .md:w-1650 {
    width: 1650rem;
  }
  .md:w-1651 {
    width: 1651rem;
  }
  .md:w-1652 {
    width: 1652rem;
  }
  .md:w-1653 {
    width: 1653rem;
  }
  .md:w-1654 {
    width: 1654rem;
  }
  .md:w-1655 {
    width: 1655rem;
  }
  .md:w-1656 {
    width: 1656rem;
  }
  .md:w-1657 {
    width: 1657rem;
  }
  .md:w-1658 {
    width: 1658rem;
  }
  .md:w-1659 {
    width: 1659rem;
  }
  .md:w-1660 {
    width: 1660rem;
  }
  .md:w-1661 {
    width: 1661rem;
  }
  .md:w-1662 {
    width: 1662rem;
  }
  .md:w-1663 {
    width: 1663rem;
  }
  .md:w-1664 {
    width: 1664rem;
  }
  .md:w-1665 {
    width: 1665rem;
  }
  .md:w-1666 {
    width: 1666rem;
  }
  .md:w-1667 {
    width: 1667rem;
  }
  .md:w-1668 {
    width: 1668rem;
  }
  .md:w-1669 {
    width: 1669rem;
  }
  .md:w-1670 {
    width: 1670rem;
  }
  .md:w-1671 {
    width: 1671rem;
  }
  .md:w-1672 {
    width: 1672rem;
  }
  .md:w-1673 {
    width: 1673rem;
  }
  .md:w-1674 {
    width: 1674rem;
  }
  .md:w-1675 {
    width: 1675rem;
  }
  .md:w-1676 {
    width: 1676rem;
  }
  .md:w-1677 {
    width: 1677rem;
  }
  .md:w-1678 {
    width: 1678rem;
  }
  .md:w-1679 {
    width: 1679rem;
  }
  .md:w-1680 {
    width: 1680rem;
  }
  .md:w-1681 {
    width: 1681rem;
  }
  .md:w-1682 {
    width: 1682rem;
  }
  .md:w-1683 {
    width: 1683rem;
  }
  .md:w-1684 {
    width: 1684rem;
  }
  .md:w-1685 {
    width: 1685rem;
  }
  .md:w-1686 {
    width: 1686rem;
  }
  .md:w-1687 {
    width: 1687rem;
  }
  .md:w-1688 {
    width: 1688rem;
  }
  .md:w-1689 {
    width: 1689rem;
  }
  .md:w-1690 {
    width: 1690rem;
  }
  .md:w-1691 {
    width: 1691rem;
  }
  .md:w-1692 {
    width: 1692rem;
  }
  .md:w-1693 {
    width: 1693rem;
  }
  .md:w-1694 {
    width: 1694rem;
  }
  .md:w-1695 {
    width: 1695rem;
  }
  .md:w-1696 {
    width: 1696rem;
  }
  .md:w-1697 {
    width: 1697rem;
  }
  .md:w-1698 {
    width: 1698rem;
  }
  .md:w-1699 {
    width: 1699rem;
  }
  .md:w-1700 {
    width: 1700rem;
  }
  .md:w-1701 {
    width: 1701rem;
  }
  .md:w-1702 {
    width: 1702rem;
  }
  .md:w-1703 {
    width: 1703rem;
  }
  .md:w-1704 {
    width: 1704rem;
  }
  .md:w-1705 {
    width: 1705rem;
  }
  .md:w-1706 {
    width: 1706rem;
  }
  .md:w-1707 {
    width: 1707rem;
  }
  .md:w-1708 {
    width: 1708rem;
  }
  .md:w-1709 {
    width: 1709rem;
  }
  .md:w-1710 {
    width: 1710rem;
  }
  .md:w-1711 {
    width: 1711rem;
  }
  .md:w-1712 {
    width: 1712rem;
  }
  .md:w-1713 {
    width: 1713rem;
  }
  .md:w-1714 {
    width: 1714rem;
  }
  .md:w-1715 {
    width: 1715rem;
  }
  .md:w-1716 {
    width: 1716rem;
  }
  .md:w-1717 {
    width: 1717rem;
  }
  .md:w-1718 {
    width: 1718rem;
  }
  .md:w-1719 {
    width: 1719rem;
  }
  .md:w-1720 {
    width: 1720rem;
  }
  .md:w-1721 {
    width: 1721rem;
  }
  .md:w-1722 {
    width: 1722rem;
  }
  .md:w-1723 {
    width: 1723rem;
  }
  .md:w-1724 {
    width: 1724rem;
  }
  .md:w-1725 {
    width: 1725rem;
  }
  .md:w-1726 {
    width: 1726rem;
  }
  .md:w-1727 {
    width: 1727rem;
  }
  .md:w-1728 {
    width: 1728rem;
  }
  .md:w-1729 {
    width: 1729rem;
  }
  .md:w-1730 {
    width: 1730rem;
  }
  .md:w-1731 {
    width: 1731rem;
  }
  .md:w-1732 {
    width: 1732rem;
  }
  .md:w-1733 {
    width: 1733rem;
  }
  .md:w-1734 {
    width: 1734rem;
  }
  .md:w-1735 {
    width: 1735rem;
  }
  .md:w-1736 {
    width: 1736rem;
  }
  .md:w-1737 {
    width: 1737rem;
  }
  .md:w-1738 {
    width: 1738rem;
  }
  .md:w-1739 {
    width: 1739rem;
  }
  .md:w-1740 {
    width: 1740rem;
  }
  .md:w-1741 {
    width: 1741rem;
  }
  .md:w-1742 {
    width: 1742rem;
  }
  .md:w-1743 {
    width: 1743rem;
  }
  .md:w-1744 {
    width: 1744rem;
  }
  .md:w-1745 {
    width: 1745rem;
  }
  .md:w-1746 {
    width: 1746rem;
  }
  .md:w-1747 {
    width: 1747rem;
  }
  .md:w-1748 {
    width: 1748rem;
  }
  .md:w-1749 {
    width: 1749rem;
  }
  .md:w-1750 {
    width: 1750rem;
  }
  .md:w-1751 {
    width: 1751rem;
  }
  .md:w-1752 {
    width: 1752rem;
  }
  .md:w-1753 {
    width: 1753rem;
  }
  .md:w-1754 {
    width: 1754rem;
  }
  .md:w-1755 {
    width: 1755rem;
  }
  .md:w-1756 {
    width: 1756rem;
  }
  .md:w-1757 {
    width: 1757rem;
  }
  .md:w-1758 {
    width: 1758rem;
  }
  .md:w-1759 {
    width: 1759rem;
  }
  .md:w-1760 {
    width: 1760rem;
  }
  .md:w-1761 {
    width: 1761rem;
  }
  .md:w-1762 {
    width: 1762rem;
  }
  .md:w-1763 {
    width: 1763rem;
  }
  .md:w-1764 {
    width: 1764rem;
  }
  .md:w-1765 {
    width: 1765rem;
  }
  .md:w-1766 {
    width: 1766rem;
  }
  .md:w-1767 {
    width: 1767rem;
  }
  .md:w-1768 {
    width: 1768rem;
  }
  .md:w-1769 {
    width: 1769rem;
  }
  .md:w-1770 {
    width: 1770rem;
  }
  .md:w-1771 {
    width: 1771rem;
  }
  .md:w-1772 {
    width: 1772rem;
  }
  .md:w-1773 {
    width: 1773rem;
  }
  .md:w-1774 {
    width: 1774rem;
  }
  .md:w-1775 {
    width: 1775rem;
  }
  .md:w-1776 {
    width: 1776rem;
  }
  .md:w-1777 {
    width: 1777rem;
  }
  .md:w-1778 {
    width: 1778rem;
  }
  .md:w-1779 {
    width: 1779rem;
  }
  .md:w-1780 {
    width: 1780rem;
  }
  .md:w-1781 {
    width: 1781rem;
  }
  .md:w-1782 {
    width: 1782rem;
  }
  .md:w-1783 {
    width: 1783rem;
  }
  .md:w-1784 {
    width: 1784rem;
  }
  .md:w-1785 {
    width: 1785rem;
  }
  .md:w-1786 {
    width: 1786rem;
  }
  .md:w-1787 {
    width: 1787rem;
  }
  .md:w-1788 {
    width: 1788rem;
  }
  .md:w-1789 {
    width: 1789rem;
  }
  .md:w-1790 {
    width: 1790rem;
  }
  .md:w-1791 {
    width: 1791rem;
  }
  .md:w-1792 {
    width: 1792rem;
  }
  .md:w-1793 {
    width: 1793rem;
  }
  .md:w-1794 {
    width: 1794rem;
  }
  .md:w-1795 {
    width: 1795rem;
  }
  .md:w-1796 {
    width: 1796rem;
  }
  .md:w-1797 {
    width: 1797rem;
  }
  .md:w-1798 {
    width: 1798rem;
  }
  .md:w-1799 {
    width: 1799rem;
  }
  .md:w-1800 {
    width: 1800rem;
  }
  .md:w-1801 {
    width: 1801rem;
  }
  .md:w-1802 {
    width: 1802rem;
  }
  .md:w-1803 {
    width: 1803rem;
  }
  .md:w-1804 {
    width: 1804rem;
  }
  .md:w-1805 {
    width: 1805rem;
  }
  .md:w-1806 {
    width: 1806rem;
  }
  .md:w-1807 {
    width: 1807rem;
  }
  .md:w-1808 {
    width: 1808rem;
  }
  .md:w-1809 {
    width: 1809rem;
  }
  .md:w-1810 {
    width: 1810rem;
  }
  .md:w-1811 {
    width: 1811rem;
  }
  .md:w-1812 {
    width: 1812rem;
  }
  .md:w-1813 {
    width: 1813rem;
  }
  .md:w-1814 {
    width: 1814rem;
  }
  .md:w-1815 {
    width: 1815rem;
  }
  .md:w-1816 {
    width: 1816rem;
  }
  .md:w-1817 {
    width: 1817rem;
  }
  .md:w-1818 {
    width: 1818rem;
  }
  .md:w-1819 {
    width: 1819rem;
  }
  .md:w-1820 {
    width: 1820rem;
  }
  .md:w-1821 {
    width: 1821rem;
  }
  .md:w-1822 {
    width: 1822rem;
  }
  .md:w-1823 {
    width: 1823rem;
  }
  .md:w-1824 {
    width: 1824rem;
  }
  .md:w-1825 {
    width: 1825rem;
  }
  .md:w-1826 {
    width: 1826rem;
  }
  .md:w-1827 {
    width: 1827rem;
  }
  .md:w-1828 {
    width: 1828rem;
  }
  .md:w-1829 {
    width: 1829rem;
  }
  .md:w-1830 {
    width: 1830rem;
  }
  .md:w-1831 {
    width: 1831rem;
  }
  .md:w-1832 {
    width: 1832rem;
  }
  .md:w-1833 {
    width: 1833rem;
  }
  .md:w-1834 {
    width: 1834rem;
  }
  .md:w-1835 {
    width: 1835rem;
  }
  .md:w-1836 {
    width: 1836rem;
  }
  .md:w-1837 {
    width: 1837rem;
  }
  .md:w-1838 {
    width: 1838rem;
  }
  .md:w-1839 {
    width: 1839rem;
  }
  .md:w-1840 {
    width: 1840rem;
  }
  .md:w-1841 {
    width: 1841rem;
  }
  .md:w-1842 {
    width: 1842rem;
  }
  .md:w-1843 {
    width: 1843rem;
  }
  .md:w-1844 {
    width: 1844rem;
  }
  .md:w-1845 {
    width: 1845rem;
  }
  .md:w-1846 {
    width: 1846rem;
  }
  .md:w-1847 {
    width: 1847rem;
  }
  .md:w-1848 {
    width: 1848rem;
  }
  .md:w-1849 {
    width: 1849rem;
  }
  .md:w-1850 {
    width: 1850rem;
  }
  .md:w-1851 {
    width: 1851rem;
  }
  .md:w-1852 {
    width: 1852rem;
  }
  .md:w-1853 {
    width: 1853rem;
  }
  .md:w-1854 {
    width: 1854rem;
  }
  .md:w-1855 {
    width: 1855rem;
  }
  .md:w-1856 {
    width: 1856rem;
  }
  .md:w-1857 {
    width: 1857rem;
  }
  .md:w-1858 {
    width: 1858rem;
  }
  .md:w-1859 {
    width: 1859rem;
  }
  .md:w-1860 {
    width: 1860rem;
  }
  .md:w-1861 {
    width: 1861rem;
  }
  .md:w-1862 {
    width: 1862rem;
  }
  .md:w-1863 {
    width: 1863rem;
  }
  .md:w-1864 {
    width: 1864rem;
  }
  .md:w-1865 {
    width: 1865rem;
  }
  .md:w-1866 {
    width: 1866rem;
  }
  .md:w-1867 {
    width: 1867rem;
  }
  .md:w-1868 {
    width: 1868rem;
  }
  .md:w-1869 {
    width: 1869rem;
  }
  .md:w-1870 {
    width: 1870rem;
  }
  .md:w-1871 {
    width: 1871rem;
  }
  .md:w-1872 {
    width: 1872rem;
  }
  .md:w-1873 {
    width: 1873rem;
  }
  .md:w-1874 {
    width: 1874rem;
  }
  .md:w-1875 {
    width: 1875rem;
  }
  .md:w-1876 {
    width: 1876rem;
  }
  .md:w-1877 {
    width: 1877rem;
  }
  .md:w-1878 {
    width: 1878rem;
  }
  .md:w-1879 {
    width: 1879rem;
  }
  .md:w-1880 {
    width: 1880rem;
  }
  .md:w-1881 {
    width: 1881rem;
  }
  .md:w-1882 {
    width: 1882rem;
  }
  .md:w-1883 {
    width: 1883rem;
  }
  .md:w-1884 {
    width: 1884rem;
  }
  .md:w-1885 {
    width: 1885rem;
  }
  .md:w-1886 {
    width: 1886rem;
  }
  .md:w-1887 {
    width: 1887rem;
  }
  .md:w-1888 {
    width: 1888rem;
  }
  .md:w-1889 {
    width: 1889rem;
  }
  .md:w-1890 {
    width: 1890rem;
  }
  .md:w-1891 {
    width: 1891rem;
  }
  .md:w-1892 {
    width: 1892rem;
  }
  .md:w-1893 {
    width: 1893rem;
  }
  .md:w-1894 {
    width: 1894rem;
  }
  .md:w-1895 {
    width: 1895rem;
  }
  .md:w-1896 {
    width: 1896rem;
  }
  .md:w-1897 {
    width: 1897rem;
  }
  .md:w-1898 {
    width: 1898rem;
  }
  .md:w-1899 {
    width: 1899rem;
  }
  .md:w-1900 {
    width: 1900rem;
  }
  .md:w-1901 {
    width: 1901rem;
  }
  .md:w-1902 {
    width: 1902rem;
  }
  .md:w-1903 {
    width: 1903rem;
  }
  .md:w-1904 {
    width: 1904rem;
  }
  .md:w-1905 {
    width: 1905rem;
  }
  .md:w-1906 {
    width: 1906rem;
  }
  .md:w-1907 {
    width: 1907rem;
  }
  .md:w-1908 {
    width: 1908rem;
  }
  .md:w-1909 {
    width: 1909rem;
  }
  .md:w-1910 {
    width: 1910rem;
  }
  .md:w-1911 {
    width: 1911rem;
  }
  .md:w-1912 {
    width: 1912rem;
  }
  .md:w-1913 {
    width: 1913rem;
  }
  .md:w-1914 {
    width: 1914rem;
  }
  .md:w-1915 {
    width: 1915rem;
  }
  .md:w-1916 {
    width: 1916rem;
  }
  .md:w-1917 {
    width: 1917rem;
  }
  .md:w-1918 {
    width: 1918rem;
  }
  .md:w-1919 {
    width: 1919rem;
  }
  .md:w-1920 {
    width: 1920rem;
  }
  .md:w-1921 {
    width: 1921rem;
  }
  .md:w-1922 {
    width: 1922rem;
  }
  .md:w-1923 {
    width: 1923rem;
  }
  .md:w-1924 {
    width: 1924rem;
  }
  .md:w-1925 {
    width: 1925rem;
  }
  .md:w-1926 {
    width: 1926rem;
  }
  .md:w-1927 {
    width: 1927rem;
  }
  .md:w-1928 {
    width: 1928rem;
  }
  .md:w-1929 {
    width: 1929rem;
  }
  .md:w-1930 {
    width: 1930rem;
  }
  .md:w-1931 {
    width: 1931rem;
  }
  .md:w-1932 {
    width: 1932rem;
  }
  .md:w-1933 {
    width: 1933rem;
  }
  .md:w-1934 {
    width: 1934rem;
  }
  .md:w-1935 {
    width: 1935rem;
  }
  .md:w-1936 {
    width: 1936rem;
  }
  .md:w-1937 {
    width: 1937rem;
  }
  .md:w-1938 {
    width: 1938rem;
  }
  .md:w-1939 {
    width: 1939rem;
  }
  .md:w-1940 {
    width: 1940rem;
  }
  .md:w-1941 {
    width: 1941rem;
  }
  .md:w-1942 {
    width: 1942rem;
  }
  .md:w-1943 {
    width: 1943rem;
  }
  .md:w-1944 {
    width: 1944rem;
  }
  .md:w-1945 {
    width: 1945rem;
  }
  .md:w-1946 {
    width: 1946rem;
  }
  .md:w-1947 {
    width: 1947rem;
  }
  .md:w-1948 {
    width: 1948rem;
  }
  .md:w-1949 {
    width: 1949rem;
  }
  .md:w-1950 {
    width: 1950rem;
  }
  .md:w-1951 {
    width: 1951rem;
  }
  .md:w-1952 {
    width: 1952rem;
  }
  .md:w-1953 {
    width: 1953rem;
  }
  .md:w-1954 {
    width: 1954rem;
  }
  .md:w-1955 {
    width: 1955rem;
  }
  .md:w-1956 {
    width: 1956rem;
  }
  .md:w-1957 {
    width: 1957rem;
  }
  .md:w-1958 {
    width: 1958rem;
  }
  .md:w-1959 {
    width: 1959rem;
  }
  .md:w-1960 {
    width: 1960rem;
  }
  .md:w-1961 {
    width: 1961rem;
  }
  .md:w-1962 {
    width: 1962rem;
  }
  .md:w-1963 {
    width: 1963rem;
  }
  .md:w-1964 {
    width: 1964rem;
  }
  .md:w-1965 {
    width: 1965rem;
  }
  .md:w-1966 {
    width: 1966rem;
  }
  .md:w-1967 {
    width: 1967rem;
  }
  .md:w-1968 {
    width: 1968rem;
  }
  .md:w-1969 {
    width: 1969rem;
  }
  .md:w-1970 {
    width: 1970rem;
  }
  .md:w-1971 {
    width: 1971rem;
  }
  .md:w-1972 {
    width: 1972rem;
  }
  .md:w-1973 {
    width: 1973rem;
  }
  .md:w-1974 {
    width: 1974rem;
  }
  .md:w-1975 {
    width: 1975rem;
  }
  .md:w-1976 {
    width: 1976rem;
  }
  .md:w-1977 {
    width: 1977rem;
  }
  .md:w-1978 {
    width: 1978rem;
  }
  .md:w-1979 {
    width: 1979rem;
  }
  .md:w-1980 {
    width: 1980rem;
  }
  .md:w-1981 {
    width: 1981rem;
  }
  .md:w-1982 {
    width: 1982rem;
  }
  .md:w-1983 {
    width: 1983rem;
  }
  .md:w-1984 {
    width: 1984rem;
  }
  .md:w-1985 {
    width: 1985rem;
  }
  .md:w-1986 {
    width: 1986rem;
  }
  .md:w-1987 {
    width: 1987rem;
  }
  .md:w-1988 {
    width: 1988rem;
  }
  .md:w-1989 {
    width: 1989rem;
  }
  .md:w-1990 {
    width: 1990rem;
  }
  .md:w-1991 {
    width: 1991rem;
  }
  .md:w-1992 {
    width: 1992rem;
  }
  .md:w-1993 {
    width: 1993rem;
  }
  .md:w-1994 {
    width: 1994rem;
  }
  .md:w-1995 {
    width: 1995rem;
  }
  .md:w-1996 {
    width: 1996rem;
  }
  .md:w-1997 {
    width: 1997rem;
  }
  .md:w-1998 {
    width: 1998rem;
  }
  .md:w-1999 {
    width: 1999rem;
  }
  .md:wp-0 {
    width: 0%;
  }
  .md:wp-1 {
    width: 1%;
  }
  .md:wp-2 {
    width: 2%;
  }
  .md:wp-3 {
    width: 3%;
  }
  .md:wp-4 {
    width: 4%;
  }
  .md:wp-5 {
    width: 5%;
  }
  .md:wp-6 {
    width: 6%;
  }
  .md:wp-7 {
    width: 7%;
  }
  .md:wp-8 {
    width: 8%;
  }
  .md:wp-9 {
    width: 9%;
  }
  .md:wp-10 {
    width: 10%;
  }
  .md:wp-11 {
    width: 11%;
  }
  .md:wp-12 {
    width: 12%;
  }
  .md:wp-13 {
    width: 13%;
  }
  .md:wp-14 {
    width: 14%;
  }
  .md:wp-15 {
    width: 15%;
  }
  .md:wp-16 {
    width: 16%;
  }
  .md:wp-17 {
    width: 17%;
  }
  .md:wp-18 {
    width: 18%;
  }
  .md:wp-19 {
    width: 19%;
  }
  .md:wp-20 {
    width: 20%;
  }
  .md:wp-21 {
    width: 21%;
  }
  .md:wp-22 {
    width: 22%;
  }
  .md:wp-23 {
    width: 23%;
  }
  .md:wp-24 {
    width: 24%;
  }
  .md:wp-25 {
    width: 25%;
  }
  .md:wp-26 {
    width: 26%;
  }
  .md:wp-27 {
    width: 27%;
  }
  .md:wp-28 {
    width: 28%;
  }
  .md:wp-29 {
    width: 29%;
  }
  .md:wp-30 {
    width: 30%;
  }
  .md:wp-31 {
    width: 31%;
  }
  .md:wp-32 {
    width: 32%;
  }
  .md:wp-33 {
    width: 33%;
  }
  .md:wp-34 {
    width: 34%;
  }
  .md:wp-35 {
    width: 35%;
  }
  .md:wp-36 {
    width: 36%;
  }
  .md:wp-37 {
    width: 37%;
  }
  .md:wp-38 {
    width: 38%;
  }
  .md:wp-39 {
    width: 39%;
  }
  .md:wp-40 {
    width: 40%;
  }
  .md:wp-41 {
    width: 41%;
  }
  .md:wp-42 {
    width: 42%;
  }
  .md:wp-43 {
    width: 43%;
  }
  .md:wp-44 {
    width: 44%;
  }
  .md:wp-45 {
    width: 45%;
  }
  .md:wp-46 {
    width: 46%;
  }
  .md:wp-47 {
    width: 47%;
  }
  .md:wp-48 {
    width: 48%;
  }
  .md:wp-49 {
    width: 49%;
  }
  .md:wp-50 {
    width: 50%;
  }
  .md:wp-51 {
    width: 51%;
  }
  .md:wp-52 {
    width: 52%;
  }
  .md:wp-53 {
    width: 53%;
  }
  .md:wp-54 {
    width: 54%;
  }
  .md:wp-55 {
    width: 55%;
  }
  .md:wp-56 {
    width: 56%;
  }
  .md:wp-57 {
    width: 57%;
  }
  .md:wp-58 {
    width: 58%;
  }
  .md:wp-59 {
    width: 59%;
  }
  .md:wp-60 {
    width: 60%;
  }
  .md:wp-61 {
    width: 61%;
  }
  .md:wp-62 {
    width: 62%;
  }
  .md:wp-63 {
    width: 63%;
  }
  .md:wp-64 {
    width: 64%;
  }
  .md:wp-65 {
    width: 65%;
  }
  .md:wp-66 {
    width: 66%;
  }
  .md:wp-67 {
    width: 67%;
  }
  .md:wp-68 {
    width: 68%;
  }
  .md:wp-69 {
    width: 69%;
  }
  .md:wp-70 {
    width: 70%;
  }
  .md:wp-71 {
    width: 71%;
  }
  .md:wp-72 {
    width: 72%;
  }
  .md:wp-73 {
    width: 73%;
  }
  .md:wp-74 {
    width: 74%;
  }
  .md:wp-75 {
    width: 75%;
  }
  .md:wp-76 {
    width: 76%;
  }
  .md:wp-77 {
    width: 77%;
  }
  .md:wp-78 {
    width: 78%;
  }
  .md:wp-79 {
    width: 79%;
  }
  .md:wp-80 {
    width: 80%;
  }
  .md:wp-81 {
    width: 81%;
  }
  .md:wp-82 {
    width: 82%;
  }
  .md:wp-83 {
    width: 83%;
  }
  .md:wp-84 {
    width: 84%;
  }
  .md:wp-85 {
    width: 85%;
  }
  .md:wp-86 {
    width: 86%;
  }
  .md:wp-87 {
    width: 87%;
  }
  .md:wp-88 {
    width: 88%;
  }
  .md:wp-89 {
    width: 89%;
  }
  .md:wp-90 {
    width: 90%;
  }
  .md:wp-91 {
    width: 91%;
  }
  .md:wp-92 {
    width: 92%;
  }
  .md:wp-93 {
    width: 93%;
  }
  .md:wp-94 {
    width: 94%;
  }
  .md:wp-95 {
    width: 95%;
  }
  .md:wp-96 {
    width: 96%;
  }
  .md:wp-97 {
    width: 97%;
  }
  .md:wp-98 {
    width: 98%;
  }
  .md:wp-99 {
    width: 99%;
  }
  .md:font-thin {
    font-weight: thin;
  }
  .md:font-normal {
    font-weight: normal;
  }
  .md:font-lighter {
    font-weight: lighter;
  }
  .md:font-light {
    font-weight: light;
  }
  .md:font-bold {
    font-weight: bold;
  }
  .md:font-bolder {
    font-weight: bolder;
  }
  .md:font-100 {
    font-weight: 100;
  }
  .md:font-200 {
    font-weight: 200;
  }
  .md:font-300 {
    font-weight: 300;
  }
  .md:font-400 {
    font-weight: 400;
  }
  .md:font-500 {
    font-weight: 500;
  }
  .md:font-600 {
    font-weight: 600;
  }
  .md:font-700 {
    font-weight: 700;
  }
  .md:font-800 {
    font-weight: 800;
  }
  .md:font-900 {
    font-weight: 900;
  }
  .shadow-xs {
    box-shadow: 0px 3px 4px -5px rgba(51, 51, 51, 0.03),
      0px 1px 2px rgba(51, 51, 51, 0.05);
  }
  .shadow-s {
    box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
      0px 0px 2px rgba(51, 51, 51, 0.02);
  }
  .shadow-a {
    box-shadow: 0px 32px 41px -23px rgba(51, 51, 51, 0.07),
      0px 2px 6px rgba(51, 51, 51, 0.06);
  }
}
@media (min-width: 960px) {
  .lg:p-0 {
    padding: 0rem;
  }
  .lg:p-1 {
    padding: 1rem;
  }
  .lg:p-2 {
    padding: 2rem;
  }
  .lg:p-3 {
    padding: 3rem;
  }
  .lg:p-4 {
    padding: 4rem;
  }
  .lg:p-5 {
    padding: 5rem;
  }
  .lg:p-6 {
    padding: 6rem;
  }
  .lg:p-7 {
    padding: 7rem;
  }
  .lg:p-8 {
    padding: 8rem;
  }
  .lg:p-9 {
    padding: 9rem;
  }
  .lg:p-10 {
    padding: 10rem;
  }
  .lg:p-11 {
    padding: 11rem;
  }
  .lg:p-12 {
    padding: 12rem;
  }
  .lg:p-13 {
    padding: 13rem;
  }
  .lg:p-14 {
    padding: 14rem;
  }
  .lg:p-15 {
    padding: 15rem;
  }
  .lg:p-16 {
    padding: 16rem;
  }
  .lg:p-17 {
    padding: 17rem;
  }
  .lg:p-18 {
    padding: 18rem;
  }
  .lg:p-19 {
    padding: 19rem;
  }
  .lg:p-20 {
    padding: 20rem;
  }
  .lg:p-21 {
    padding: 21rem;
  }
  .lg:p-22 {
    padding: 22rem;
  }
  .lg:p-23 {
    padding: 23rem;
  }
  .lg:p-24 {
    padding: 24rem;
  }
  .lg:p-25 {
    padding: 25rem;
  }
  .lg:p-26 {
    padding: 26rem;
  }
  .lg:p-27 {
    padding: 27rem;
  }
  .lg:p-28 {
    padding: 28rem;
  }
  .lg:p-29 {
    padding: 29rem;
  }
  .lg:p-30 {
    padding: 30rem;
  }
  .lg:p-31 {
    padding: 31rem;
  }
  .lg:p-32 {
    padding: 32rem;
  }
  .lg:p-33 {
    padding: 33rem;
  }
  .lg:p-34 {
    padding: 34rem;
  }
  .lg:p-35 {
    padding: 35rem;
  }
  .lg:p-36 {
    padding: 36rem;
  }
  .lg:p-37 {
    padding: 37rem;
  }
  .lg:p-38 {
    padding: 38rem;
  }
  .lg:p-39 {
    padding: 39rem;
  }
  .lg:p-40 {
    padding: 40rem;
  }
  .lg:p-41 {
    padding: 41rem;
  }
  .lg:p-42 {
    padding: 42rem;
  }
  .lg:p-43 {
    padding: 43rem;
  }
  .lg:p-44 {
    padding: 44rem;
  }
  .lg:p-45 {
    padding: 45rem;
  }
  .lg:p-46 {
    padding: 46rem;
  }
  .lg:p-47 {
    padding: 47rem;
  }
  .lg:p-48 {
    padding: 48rem;
  }
  .lg:p-49 {
    padding: 49rem;
  }
  .lg:p-50 {
    padding: 50rem;
  }
  .lg:p-51 {
    padding: 51rem;
  }
  .lg:p-52 {
    padding: 52rem;
  }
  .lg:p-53 {
    padding: 53rem;
  }
  .lg:p-54 {
    padding: 54rem;
  }
  .lg:p-55 {
    padding: 55rem;
  }
  .lg:p-56 {
    padding: 56rem;
  }
  .lg:p-57 {
    padding: 57rem;
  }
  .lg:p-58 {
    padding: 58rem;
  }
  .lg:p-59 {
    padding: 59rem;
  }
  .lg:p-60 {
    padding: 60rem;
  }
  .lg:p-61 {
    padding: 61rem;
  }
  .lg:p-62 {
    padding: 62rem;
  }
  .lg:p-63 {
    padding: 63rem;
  }
  .lg:p-64 {
    padding: 64rem;
  }
  .lg:p-65 {
    padding: 65rem;
  }
  .lg:p-66 {
    padding: 66rem;
  }
  .lg:p-67 {
    padding: 67rem;
  }
  .lg:p-68 {
    padding: 68rem;
  }
  .lg:p-69 {
    padding: 69rem;
  }
  .lg:p-70 {
    padding: 70rem;
  }
  .lg:p-71 {
    padding: 71rem;
  }
  .lg:p-72 {
    padding: 72rem;
  }
  .lg:p-0-0 {
    padding: 0rem;
  }
  .lg:p-0-1 {
    padding: 0.1rem;
  }
  .lg:p-0-2 {
    padding: 0.2rem;
  }
  .lg:p-0-3 {
    padding: 0.3rem;
  }
  .lg:p-0-4 {
    padding: 0.4rem;
  }
  .lg:p-0-5 {
    padding: 0.5rem;
  }
  .lg:p-0-6 {
    padding: 0.6rem;
  }
  .lg:p-0-7 {
    padding: 0.7rem;
  }
  .lg:p-0-8 {
    padding: 0.8rem;
  }
  .lg:p-0-9 {
    padding: 0.9rem;
  }
  .lg:p-1-0 {
    padding: 1rem;
  }
  .lg:p-1-1 {
    padding: 1.1rem;
  }
  .lg:p-1-2 {
    padding: 1.2rem;
  }
  .lg:p-1-3 {
    padding: 1.3rem;
  }
  .lg:p-1-4 {
    padding: 1.4rem;
  }
  .lg:p-1-5 {
    padding: 1.5rem;
  }
  .lg:p-1-6 {
    padding: 1.6rem;
  }
  .lg:p-1-7 {
    padding: 1.7rem;
  }
  .lg:p-1-8 {
    padding: 1.8rem;
  }
  .lg:p-1-9 {
    padding: 1.9rem;
  }
  .lg:p-2-0 {
    padding: 2rem;
  }
  .lg:p-2-1 {
    padding: 2.1rem;
  }
  .lg:p-2-2 {
    padding: 2.2rem;
  }
  .lg:p-2-3 {
    padding: 2.3rem;
  }
  .lg:p-2-4 {
    padding: 2.4rem;
  }
  .lg:p-2-5 {
    padding: 2.5rem;
  }
  .lg:p-2-6 {
    padding: 2.6rem;
  }
  .lg:p-2-7 {
    padding: 2.7rem;
  }
  .lg:p-2-8 {
    padding: 2.8rem;
  }
  .lg:p-2-9 {
    padding: 2.9rem;
  }
  .lg:p-3-0 {
    padding: 3rem;
  }
  .lg:p-3-1 {
    padding: 3.1rem;
  }
  .lg:p-3-2 {
    padding: 3.2rem;
  }
  .lg:p-3-3 {
    padding: 3.3rem;
  }
  .lg:p-3-4 {
    padding: 3.4rem;
  }
  .lg:p-3-5 {
    padding: 3.5rem;
  }
  .lg:p-3-6 {
    padding: 3.6rem;
  }
  .lg:p-3-7 {
    padding: 3.7rem;
  }
  .lg:p-3-8 {
    padding: 3.8rem;
  }
  .lg:p-3-9 {
    padding: 3.9rem;
  }
  .lg:p-4-0 {
    padding: 4rem;
  }
  .lg:p-4-1 {
    padding: 4.1rem;
  }
  .lg:p-4-2 {
    padding: 4.2rem;
  }
  .lg:p-4-3 {
    padding: 4.3rem;
  }
  .lg:p-4-4 {
    padding: 4.4rem;
  }
  .lg:p-4-5 {
    padding: 4.5rem;
  }
  .lg:p-4-6 {
    padding: 4.6rem;
  }
  .lg:p-4-7 {
    padding: 4.7rem;
  }
  .lg:p-4-8 {
    padding: 4.8rem;
  }
  .lg:p-4-9 {
    padding: 4.9rem;
  }
  .lg:p-5-0 {
    padding: 5rem;
  }
  .lg:p-5-1 {
    padding: 5.1rem;
  }
  .lg:p-5-2 {
    padding: 5.2rem;
  }
  .lg:p-5-3 {
    padding: 5.3rem;
  }
  .lg:p-5-4 {
    padding: 5.4rem;
  }
  .lg:p-5-5 {
    padding: 5.5rem;
  }
  .lg:p-5-6 {
    padding: 5.6rem;
  }
  .lg:p-5-7 {
    padding: 5.7rem;
  }
  .lg:p-5-8 {
    padding: 5.8rem;
  }
  .lg:p-5-9 {
    padding: 5.9rem;
  }
  .lg:p-6-0 {
    padding: 6rem;
  }
  .lg:p-6-1 {
    padding: 6.1rem;
  }
  .lg:p-6-2 {
    padding: 6.2rem;
  }
  .lg:p-6-3 {
    padding: 6.3rem;
  }
  .lg:p-6-4 {
    padding: 6.4rem;
  }
  .lg:p-6-5 {
    padding: 6.5rem;
  }
  .lg:p-6-6 {
    padding: 6.6rem;
  }
  .lg:p-6-7 {
    padding: 6.7rem;
  }
  .lg:p-6-8 {
    padding: 6.8rem;
  }
  .lg:p-6-9 {
    padding: 6.9rem;
  }
  .lg:p-7-0 {
    padding: 7rem;
  }
  .lg:p-7-1 {
    padding: 7.1rem;
  }
  .lg:p-7-2 {
    padding: 7.2rem;
  }
  .lg:p-7-3 {
    padding: 7.3rem;
  }
  .lg:p-7-4 {
    padding: 7.4rem;
  }
  .lg:p-7-5 {
    padding: 7.5rem;
  }
  .lg:p-7-6 {
    padding: 7.6rem;
  }
  .lg:p-7-7 {
    padding: 7.7rem;
  }
  .lg:p-7-8 {
    padding: 7.8rem;
  }
  .lg:p-7-9 {
    padding: 7.9rem;
  }
  .lg:p-8-0 {
    padding: 8rem;
  }
  .lg:p-8-1 {
    padding: 8.1rem;
  }
  .lg:p-8-2 {
    padding: 8.2rem;
  }
  .lg:p-8-3 {
    padding: 8.3rem;
  }
  .lg:p-8-4 {
    padding: 8.4rem;
  }
  .lg:p-8-5 {
    padding: 8.5rem;
  }
  .lg:p-8-6 {
    padding: 8.6rem;
  }
  .lg:p-8-7 {
    padding: 8.7rem;
  }
  .lg:p-8-8 {
    padding: 8.8rem;
  }
  .lg:p-8-9 {
    padding: 8.9rem;
  }
  .lg:p-9-0 {
    padding: 9rem;
  }
  .lg:p-9-1 {
    padding: 9.1rem;
  }
  .lg:p-9-2 {
    padding: 9.2rem;
  }
  .lg:p-9-3 {
    padding: 9.3rem;
  }
  .lg:p-9-4 {
    padding: 9.4rem;
  }
  .lg:p-9-5 {
    padding: 9.5rem;
  }
  .lg:p-9-6 {
    padding: 9.6rem;
  }
  .lg:p-9-7 {
    padding: 9.7rem;
  }
  .lg:p-9-8 {
    padding: 9.8rem;
  }
  .lg:p-9-9 {
    padding: 9.9rem;
  }
  .lg:p-10-0 {
    padding: 10rem;
  }
  .lg:p-10-1 {
    padding: 10.1rem;
  }
  .lg:p-10-2 {
    padding: 10.2rem;
  }
  .lg:p-10-3 {
    padding: 10.3rem;
  }
  .lg:p-10-4 {
    padding: 10.4rem;
  }
  .lg:p-10-5 {
    padding: 10.5rem;
  }
  .lg:p-10-6 {
    padding: 10.6rem;
  }
  .lg:p-10-7 {
    padding: 10.7rem;
  }
  .lg:p-10-8 {
    padding: 10.8rem;
  }
  .lg:p-10-9 {
    padding: 10.9rem;
  }
  .lg:p-11-0 {
    padding: 11rem;
  }
  .lg:p-11-1 {
    padding: 11.1rem;
  }
  .lg:p-11-2 {
    padding: 11.2rem;
  }
  .lg:p-11-3 {
    padding: 11.3rem;
  }
  .lg:p-11-4 {
    padding: 11.4rem;
  }
  .lg:p-11-5 {
    padding: 11.5rem;
  }
  .lg:p-11-6 {
    padding: 11.6rem;
  }
  .lg:p-11-7 {
    padding: 11.7rem;
  }
  .lg:p-11-8 {
    padding: 11.8rem;
  }
  .lg:p-11-9 {
    padding: 11.9rem;
  }
  .lg:p-12-0 {
    padding: 12rem;
  }
  .lg:p-12-1 {
    padding: 12.1rem;
  }
  .lg:p-12-2 {
    padding: 12.2rem;
  }
  .lg:p-12-3 {
    padding: 12.3rem;
  }
  .lg:p-12-4 {
    padding: 12.4rem;
  }
  .lg:p-12-5 {
    padding: 12.5rem;
  }
  .lg:p-12-6 {
    padding: 12.6rem;
  }
  .lg:p-12-7 {
    padding: 12.7rem;
  }
  .lg:p-12-8 {
    padding: 12.8rem;
  }
  .lg:p-12-9 {
    padding: 12.9rem;
  }
  .lg:p-13-0 {
    padding: 13rem;
  }
  .lg:p-13-1 {
    padding: 13.1rem;
  }
  .lg:p-13-2 {
    padding: 13.2rem;
  }
  .lg:p-13-3 {
    padding: 13.3rem;
  }
  .lg:p-13-4 {
    padding: 13.4rem;
  }
  .lg:p-13-5 {
    padding: 13.5rem;
  }
  .lg:p-13-6 {
    padding: 13.6rem;
  }
  .lg:p-13-7 {
    padding: 13.7rem;
  }
  .lg:p-13-8 {
    padding: 13.8rem;
  }
  .lg:p-13-9 {
    padding: 13.9rem;
  }
  .lg:p-14-0 {
    padding: 14rem;
  }
  .lg:p-14-1 {
    padding: 14.1rem;
  }
  .lg:p-14-2 {
    padding: 14.2rem;
  }
  .lg:p-14-3 {
    padding: 14.3rem;
  }
  .lg:p-14-4 {
    padding: 14.4rem;
  }
  .lg:p-14-5 {
    padding: 14.5rem;
  }
  .lg:p-14-6 {
    padding: 14.6rem;
  }
  .lg:p-14-7 {
    padding: 14.7rem;
  }
  .lg:p-14-8 {
    padding: 14.8rem;
  }
  .lg:p-14-9 {
    padding: 14.9rem;
  }
  .lg:p-15-0 {
    padding: 15rem;
  }
  .lg:p-15-1 {
    padding: 15.1rem;
  }
  .lg:p-15-2 {
    padding: 15.2rem;
  }
  .lg:p-15-3 {
    padding: 15.3rem;
  }
  .lg:p-15-4 {
    padding: 15.4rem;
  }
  .lg:p-15-5 {
    padding: 15.5rem;
  }
  .lg:p-15-6 {
    padding: 15.6rem;
  }
  .lg:p-15-7 {
    padding: 15.7rem;
  }
  .lg:p-15-8 {
    padding: 15.8rem;
  }
  .lg:p-15-9 {
    padding: 15.9rem;
  }
  .lg:p-16-0 {
    padding: 16rem;
  }
  .lg:p-16-1 {
    padding: 16.1rem;
  }
  .lg:p-16-2 {
    padding: 16.2rem;
  }
  .lg:p-16-3 {
    padding: 16.3rem;
  }
  .lg:p-16-4 {
    padding: 16.4rem;
  }
  .lg:p-16-5 {
    padding: 16.5rem;
  }
  .lg:p-16-6 {
    padding: 16.6rem;
  }
  .lg:p-16-7 {
    padding: 16.7rem;
  }
  .lg:p-16-8 {
    padding: 16.8rem;
  }
  .lg:p-16-9 {
    padding: 16.9rem;
  }
  .lg:p-17-0 {
    padding: 17rem;
  }
  .lg:p-17-1 {
    padding: 17.1rem;
  }
  .lg:p-17-2 {
    padding: 17.2rem;
  }
  .lg:p-17-3 {
    padding: 17.3rem;
  }
  .lg:p-17-4 {
    padding: 17.4rem;
  }
  .lg:p-17-5 {
    padding: 17.5rem;
  }
  .lg:p-17-6 {
    padding: 17.6rem;
  }
  .lg:p-17-7 {
    padding: 17.7rem;
  }
  .lg:p-17-8 {
    padding: 17.8rem;
  }
  .lg:p-17-9 {
    padding: 17.9rem;
  }
  .lg:p-18-0 {
    padding: 18rem;
  }
  .lg:p-18-1 {
    padding: 18.1rem;
  }
  .lg:p-18-2 {
    padding: 18.2rem;
  }
  .lg:p-18-3 {
    padding: 18.3rem;
  }
  .lg:p-18-4 {
    padding: 18.4rem;
  }
  .lg:p-18-5 {
    padding: 18.5rem;
  }
  .lg:p-18-6 {
    padding: 18.6rem;
  }
  .lg:p-18-7 {
    padding: 18.7rem;
  }
  .lg:p-18-8 {
    padding: 18.8rem;
  }
  .lg:p-18-9 {
    padding: 18.9rem;
  }
  .lg:p-19-0 {
    padding: 19rem;
  }
  .lg:p-19-1 {
    padding: 19.1rem;
  }
  .lg:p-19-2 {
    padding: 19.2rem;
  }
  .lg:p-19-3 {
    padding: 19.3rem;
  }
  .lg:p-19-4 {
    padding: 19.4rem;
  }
  .lg:p-19-5 {
    padding: 19.5rem;
  }
  .lg:p-19-6 {
    padding: 19.6rem;
  }
  .lg:p-19-7 {
    padding: 19.7rem;
  }
  .lg:p-19-8 {
    padding: 19.8rem;
  }
  .lg:p-19-9 {
    padding: 19.9rem;
  }
  .lg:p-20-0 {
    padding: 20rem;
  }
  .lg:p-20-1 {
    padding: 20.1rem;
  }
  .lg:p-20-2 {
    padding: 20.2rem;
  }
  .lg:p-20-3 {
    padding: 20.3rem;
  }
  .lg:p-20-4 {
    padding: 20.4rem;
  }
  .lg:p-20-5 {
    padding: 20.5rem;
  }
  .lg:p-20-6 {
    padding: 20.6rem;
  }
  .lg:p-20-7 {
    padding: 20.7rem;
  }
  .lg:p-20-8 {
    padding: 20.8rem;
  }
  .lg:p-20-9 {
    padding: 20.9rem;
  }
  .lg:p-21-0 {
    padding: 21rem;
  }
  .lg:p-21-1 {
    padding: 21.1rem;
  }
  .lg:p-21-2 {
    padding: 21.2rem;
  }
  .lg:p-21-3 {
    padding: 21.3rem;
  }
  .lg:p-21-4 {
    padding: 21.4rem;
  }
  .lg:p-21-5 {
    padding: 21.5rem;
  }
  .lg:p-21-6 {
    padding: 21.6rem;
  }
  .lg:p-21-7 {
    padding: 21.7rem;
  }
  .lg:p-21-8 {
    padding: 21.8rem;
  }
  .lg:p-21-9 {
    padding: 21.9rem;
  }
  .lg:p-22-0 {
    padding: 22rem;
  }
  .lg:p-22-1 {
    padding: 22.1rem;
  }
  .lg:p-22-2 {
    padding: 22.2rem;
  }
  .lg:p-22-3 {
    padding: 22.3rem;
  }
  .lg:p-22-4 {
    padding: 22.4rem;
  }
  .lg:p-22-5 {
    padding: 22.5rem;
  }
  .lg:p-22-6 {
    padding: 22.6rem;
  }
  .lg:p-22-7 {
    padding: 22.7rem;
  }
  .lg:p-22-8 {
    padding: 22.8rem;
  }
  .lg:p-22-9 {
    padding: 22.9rem;
  }
  .lg:p-23-0 {
    padding: 23rem;
  }
  .lg:p-23-1 {
    padding: 23.1rem;
  }
  .lg:p-23-2 {
    padding: 23.2rem;
  }
  .lg:p-23-3 {
    padding: 23.3rem;
  }
  .lg:p-23-4 {
    padding: 23.4rem;
  }
  .lg:p-23-5 {
    padding: 23.5rem;
  }
  .lg:p-23-6 {
    padding: 23.6rem;
  }
  .lg:p-23-7 {
    padding: 23.7rem;
  }
  .lg:p-23-8 {
    padding: 23.8rem;
  }
  .lg:p-23-9 {
    padding: 23.9rem;
  }
  .lg:p-24-0 {
    padding: 24rem;
  }
  .lg:p-24-1 {
    padding: 24.1rem;
  }
  .lg:p-24-2 {
    padding: 24.2rem;
  }
  .lg:p-24-3 {
    padding: 24.3rem;
  }
  .lg:p-24-4 {
    padding: 24.4rem;
  }
  .lg:p-24-5 {
    padding: 24.5rem;
  }
  .lg:p-24-6 {
    padding: 24.6rem;
  }
  .lg:p-24-7 {
    padding: 24.7rem;
  }
  .lg:p-24-8 {
    padding: 24.8rem;
  }
  .lg:p-24-9 {
    padding: 24.9rem;
  }
  .lg:p-25-0 {
    padding: 25rem;
  }
  .lg:p-25-1 {
    padding: 25.1rem;
  }
  .lg:p-25-2 {
    padding: 25.2rem;
  }
  .lg:p-25-3 {
    padding: 25.3rem;
  }
  .lg:p-25-4 {
    padding: 25.4rem;
  }
  .lg:p-25-5 {
    padding: 25.5rem;
  }
  .lg:p-25-6 {
    padding: 25.6rem;
  }
  .lg:p-25-7 {
    padding: 25.7rem;
  }
  .lg:p-25-8 {
    padding: 25.8rem;
  }
  .lg:p-25-9 {
    padding: 25.9rem;
  }
  .lg:p-26-0 {
    padding: 26rem;
  }
  .lg:p-26-1 {
    padding: 26.1rem;
  }
  .lg:p-26-2 {
    padding: 26.2rem;
  }
  .lg:p-26-3 {
    padding: 26.3rem;
  }
  .lg:p-26-4 {
    padding: 26.4rem;
  }
  .lg:p-26-5 {
    padding: 26.5rem;
  }
  .lg:p-26-6 {
    padding: 26.6rem;
  }
  .lg:p-26-7 {
    padding: 26.7rem;
  }
  .lg:p-26-8 {
    padding: 26.8rem;
  }
  .lg:p-26-9 {
    padding: 26.9rem;
  }
  .lg:p-27-0 {
    padding: 27rem;
  }
  .lg:p-27-1 {
    padding: 27.1rem;
  }
  .lg:p-27-2 {
    padding: 27.2rem;
  }
  .lg:p-27-3 {
    padding: 27.3rem;
  }
  .lg:p-27-4 {
    padding: 27.4rem;
  }
  .lg:p-27-5 {
    padding: 27.5rem;
  }
  .lg:p-27-6 {
    padding: 27.6rem;
  }
  .lg:p-27-7 {
    padding: 27.7rem;
  }
  .lg:p-27-8 {
    padding: 27.8rem;
  }
  .lg:p-27-9 {
    padding: 27.9rem;
  }
  .lg:p-28-0 {
    padding: 28rem;
  }
  .lg:p-28-1 {
    padding: 28.1rem;
  }
  .lg:p-28-2 {
    padding: 28.2rem;
  }
  .lg:p-28-3 {
    padding: 28.3rem;
  }
  .lg:p-28-4 {
    padding: 28.4rem;
  }
  .lg:p-28-5 {
    padding: 28.5rem;
  }
  .lg:p-28-6 {
    padding: 28.6rem;
  }
  .lg:p-28-7 {
    padding: 28.7rem;
  }
  .lg:p-28-8 {
    padding: 28.8rem;
  }
  .lg:p-28-9 {
    padding: 28.9rem;
  }
  .lg:p-29-0 {
    padding: 29rem;
  }
  .lg:p-29-1 {
    padding: 29.1rem;
  }
  .lg:p-29-2 {
    padding: 29.2rem;
  }
  .lg:p-29-3 {
    padding: 29.3rem;
  }
  .lg:p-29-4 {
    padding: 29.4rem;
  }
  .lg:p-29-5 {
    padding: 29.5rem;
  }
  .lg:p-29-6 {
    padding: 29.6rem;
  }
  .lg:p-29-7 {
    padding: 29.7rem;
  }
  .lg:p-29-8 {
    padding: 29.8rem;
  }
  .lg:p-29-9 {
    padding: 29.9rem;
  }
  .lg:p-30-0 {
    padding: 30rem;
  }
  .lg:p-30-1 {
    padding: 30.1rem;
  }
  .lg:p-30-2 {
    padding: 30.2rem;
  }
  .lg:p-30-3 {
    padding: 30.3rem;
  }
  .lg:p-30-4 {
    padding: 30.4rem;
  }
  .lg:p-30-5 {
    padding: 30.5rem;
  }
  .lg:p-30-6 {
    padding: 30.6rem;
  }
  .lg:p-30-7 {
    padding: 30.7rem;
  }
  .lg:p-30-8 {
    padding: 30.8rem;
  }
  .lg:p-30-9 {
    padding: 30.9rem;
  }
  .lg:p-31-0 {
    padding: 31rem;
  }
  .lg:p-31-1 {
    padding: 31.1rem;
  }
  .lg:p-31-2 {
    padding: 31.2rem;
  }
  .lg:p-31-3 {
    padding: 31.3rem;
  }
  .lg:p-31-4 {
    padding: 31.4rem;
  }
  .lg:p-31-5 {
    padding: 31.5rem;
  }
  .lg:p-31-6 {
    padding: 31.6rem;
  }
  .lg:p-31-7 {
    padding: 31.7rem;
  }
  .lg:p-31-8 {
    padding: 31.8rem;
  }
  .lg:p-31-9 {
    padding: 31.9rem;
  }
  .lg:p-32-0 {
    padding: 32rem;
  }
  .lg:p-32-1 {
    padding: 32.1rem;
  }
  .lg:p-32-2 {
    padding: 32.2rem;
  }
  .lg:p-32-3 {
    padding: 32.3rem;
  }
  .lg:p-32-4 {
    padding: 32.4rem;
  }
  .lg:p-32-5 {
    padding: 32.5rem;
  }
  .lg:p-32-6 {
    padding: 32.6rem;
  }
  .lg:p-32-7 {
    padding: 32.7rem;
  }
  .lg:p-32-8 {
    padding: 32.8rem;
  }
  .lg:p-32-9 {
    padding: 32.9rem;
  }
  .lg:p-33-0 {
    padding: 33rem;
  }
  .lg:p-33-1 {
    padding: 33.1rem;
  }
  .lg:p-33-2 {
    padding: 33.2rem;
  }
  .lg:p-33-3 {
    padding: 33.3rem;
  }
  .lg:p-33-4 {
    padding: 33.4rem;
  }
  .lg:p-33-5 {
    padding: 33.5rem;
  }
  .lg:p-33-6 {
    padding: 33.6rem;
  }
  .lg:p-33-7 {
    padding: 33.7rem;
  }
  .lg:p-33-8 {
    padding: 33.8rem;
  }
  .lg:p-33-9 {
    padding: 33.9rem;
  }
  .lg:p-34-0 {
    padding: 34rem;
  }
  .lg:p-34-1 {
    padding: 34.1rem;
  }
  .lg:p-34-2 {
    padding: 34.2rem;
  }
  .lg:p-34-3 {
    padding: 34.3rem;
  }
  .lg:p-34-4 {
    padding: 34.4rem;
  }
  .lg:p-34-5 {
    padding: 34.5rem;
  }
  .lg:p-34-6 {
    padding: 34.6rem;
  }
  .lg:p-34-7 {
    padding: 34.7rem;
  }
  .lg:p-34-8 {
    padding: 34.8rem;
  }
  .lg:p-34-9 {
    padding: 34.9rem;
  }
  .lg:p-35-0 {
    padding: 35rem;
  }
  .lg:p-35-1 {
    padding: 35.1rem;
  }
  .lg:p-35-2 {
    padding: 35.2rem;
  }
  .lg:p-35-3 {
    padding: 35.3rem;
  }
  .lg:p-35-4 {
    padding: 35.4rem;
  }
  .lg:p-35-5 {
    padding: 35.5rem;
  }
  .lg:p-35-6 {
    padding: 35.6rem;
  }
  .lg:p-35-7 {
    padding: 35.7rem;
  }
  .lg:p-35-8 {
    padding: 35.8rem;
  }
  .lg:p-35-9 {
    padding: 35.9rem;
  }
  .lg:p-36-0 {
    padding: 36rem;
  }
  .lg:p-36-1 {
    padding: 36.1rem;
  }
  .lg:p-36-2 {
    padding: 36.2rem;
  }
  .lg:p-36-3 {
    padding: 36.3rem;
  }
  .lg:p-36-4 {
    padding: 36.4rem;
  }
  .lg:p-36-5 {
    padding: 36.5rem;
  }
  .lg:p-36-6 {
    padding: 36.6rem;
  }
  .lg:p-36-7 {
    padding: 36.7rem;
  }
  .lg:p-36-8 {
    padding: 36.8rem;
  }
  .lg:p-36-9 {
    padding: 36.9rem;
  }
  .lg:p-37-0 {
    padding: 37rem;
  }
  .lg:p-37-1 {
    padding: 37.1rem;
  }
  .lg:p-37-2 {
    padding: 37.2rem;
  }
  .lg:p-37-3 {
    padding: 37.3rem;
  }
  .lg:p-37-4 {
    padding: 37.4rem;
  }
  .lg:p-37-5 {
    padding: 37.5rem;
  }
  .lg:p-37-6 {
    padding: 37.6rem;
  }
  .lg:p-37-7 {
    padding: 37.7rem;
  }
  .lg:p-37-8 {
    padding: 37.8rem;
  }
  .lg:p-37-9 {
    padding: 37.9rem;
  }
  .lg:p-38-0 {
    padding: 38rem;
  }
  .lg:p-38-1 {
    padding: 38.1rem;
  }
  .lg:p-38-2 {
    padding: 38.2rem;
  }
  .lg:p-38-3 {
    padding: 38.3rem;
  }
  .lg:p-38-4 {
    padding: 38.4rem;
  }
  .lg:p-38-5 {
    padding: 38.5rem;
  }
  .lg:p-38-6 {
    padding: 38.6rem;
  }
  .lg:p-38-7 {
    padding: 38.7rem;
  }
  .lg:p-38-8 {
    padding: 38.8rem;
  }
  .lg:p-38-9 {
    padding: 38.9rem;
  }
  .lg:p-39-0 {
    padding: 39rem;
  }
  .lg:p-39-1 {
    padding: 39.1rem;
  }
  .lg:p-39-2 {
    padding: 39.2rem;
  }
  .lg:p-39-3 {
    padding: 39.3rem;
  }
  .lg:p-39-4 {
    padding: 39.4rem;
  }
  .lg:p-39-5 {
    padding: 39.5rem;
  }
  .lg:p-39-6 {
    padding: 39.6rem;
  }
  .lg:p-39-7 {
    padding: 39.7rem;
  }
  .lg:p-39-8 {
    padding: 39.8rem;
  }
  .lg:p-39-9 {
    padding: 39.9rem;
  }
  .lg:p-40-0 {
    padding: 40rem;
  }
  .lg:p-40-1 {
    padding: 40.1rem;
  }
  .lg:p-40-2 {
    padding: 40.2rem;
  }
  .lg:p-40-3 {
    padding: 40.3rem;
  }
  .lg:p-40-4 {
    padding: 40.4rem;
  }
  .lg:p-40-5 {
    padding: 40.5rem;
  }
  .lg:p-40-6 {
    padding: 40.6rem;
  }
  .lg:p-40-7 {
    padding: 40.7rem;
  }
  .lg:p-40-8 {
    padding: 40.8rem;
  }
  .lg:p-40-9 {
    padding: 40.9rem;
  }
  .lg:p-41-0 {
    padding: 41rem;
  }
  .lg:p-41-1 {
    padding: 41.1rem;
  }
  .lg:p-41-2 {
    padding: 41.2rem;
  }
  .lg:p-41-3 {
    padding: 41.3rem;
  }
  .lg:p-41-4 {
    padding: 41.4rem;
  }
  .lg:p-41-5 {
    padding: 41.5rem;
  }
  .lg:p-41-6 {
    padding: 41.6rem;
  }
  .lg:p-41-7 {
    padding: 41.7rem;
  }
  .lg:p-41-8 {
    padding: 41.8rem;
  }
  .lg:p-41-9 {
    padding: 41.9rem;
  }
  .lg:p-42-0 {
    padding: 42rem;
  }
  .lg:p-42-1 {
    padding: 42.1rem;
  }
  .lg:p-42-2 {
    padding: 42.2rem;
  }
  .lg:p-42-3 {
    padding: 42.3rem;
  }
  .lg:p-42-4 {
    padding: 42.4rem;
  }
  .lg:p-42-5 {
    padding: 42.5rem;
  }
  .lg:p-42-6 {
    padding: 42.6rem;
  }
  .lg:p-42-7 {
    padding: 42.7rem;
  }
  .lg:p-42-8 {
    padding: 42.8rem;
  }
  .lg:p-42-9 {
    padding: 42.9rem;
  }
  .lg:p-43-0 {
    padding: 43rem;
  }
  .lg:p-43-1 {
    padding: 43.1rem;
  }
  .lg:p-43-2 {
    padding: 43.2rem;
  }
  .lg:p-43-3 {
    padding: 43.3rem;
  }
  .lg:p-43-4 {
    padding: 43.4rem;
  }
  .lg:p-43-5 {
    padding: 43.5rem;
  }
  .lg:p-43-6 {
    padding: 43.6rem;
  }
  .lg:p-43-7 {
    padding: 43.7rem;
  }
  .lg:p-43-8 {
    padding: 43.8rem;
  }
  .lg:p-43-9 {
    padding: 43.9rem;
  }
  .lg:p-44-0 {
    padding: 44rem;
  }
  .lg:p-44-1 {
    padding: 44.1rem;
  }
  .lg:p-44-2 {
    padding: 44.2rem;
  }
  .lg:p-44-3 {
    padding: 44.3rem;
  }
  .lg:p-44-4 {
    padding: 44.4rem;
  }
  .lg:p-44-5 {
    padding: 44.5rem;
  }
  .lg:p-44-6 {
    padding: 44.6rem;
  }
  .lg:p-44-7 {
    padding: 44.7rem;
  }
  .lg:p-44-8 {
    padding: 44.8rem;
  }
  .lg:p-44-9 {
    padding: 44.9rem;
  }
  .lg:p-45-0 {
    padding: 45rem;
  }
  .lg:p-45-1 {
    padding: 45.1rem;
  }
  .lg:p-45-2 {
    padding: 45.2rem;
  }
  .lg:p-45-3 {
    padding: 45.3rem;
  }
  .lg:p-45-4 {
    padding: 45.4rem;
  }
  .lg:p-45-5 {
    padding: 45.5rem;
  }
  .lg:p-45-6 {
    padding: 45.6rem;
  }
  .lg:p-45-7 {
    padding: 45.7rem;
  }
  .lg:p-45-8 {
    padding: 45.8rem;
  }
  .lg:p-45-9 {
    padding: 45.9rem;
  }
  .lg:p-46-0 {
    padding: 46rem;
  }
  .lg:p-46-1 {
    padding: 46.1rem;
  }
  .lg:p-46-2 {
    padding: 46.2rem;
  }
  .lg:p-46-3 {
    padding: 46.3rem;
  }
  .lg:p-46-4 {
    padding: 46.4rem;
  }
  .lg:p-46-5 {
    padding: 46.5rem;
  }
  .lg:p-46-6 {
    padding: 46.6rem;
  }
  .lg:p-46-7 {
    padding: 46.7rem;
  }
  .lg:p-46-8 {
    padding: 46.8rem;
  }
  .lg:p-46-9 {
    padding: 46.9rem;
  }
  .lg:p-47-0 {
    padding: 47rem;
  }
  .lg:p-47-1 {
    padding: 47.1rem;
  }
  .lg:p-47-2 {
    padding: 47.2rem;
  }
  .lg:p-47-3 {
    padding: 47.3rem;
  }
  .lg:p-47-4 {
    padding: 47.4rem;
  }
  .lg:p-47-5 {
    padding: 47.5rem;
  }
  .lg:p-47-6 {
    padding: 47.6rem;
  }
  .lg:p-47-7 {
    padding: 47.7rem;
  }
  .lg:p-47-8 {
    padding: 47.8rem;
  }
  .lg:p-47-9 {
    padding: 47.9rem;
  }
  .lg:p-48-0 {
    padding: 48rem;
  }
  .lg:p-48-1 {
    padding: 48.1rem;
  }
  .lg:p-48-2 {
    padding: 48.2rem;
  }
  .lg:p-48-3 {
    padding: 48.3rem;
  }
  .lg:p-48-4 {
    padding: 48.4rem;
  }
  .lg:p-48-5 {
    padding: 48.5rem;
  }
  .lg:p-48-6 {
    padding: 48.6rem;
  }
  .lg:p-48-7 {
    padding: 48.7rem;
  }
  .lg:p-48-8 {
    padding: 48.8rem;
  }
  .lg:p-48-9 {
    padding: 48.9rem;
  }
  .lg:p-49-0 {
    padding: 49rem;
  }
  .lg:p-49-1 {
    padding: 49.1rem;
  }
  .lg:p-49-2 {
    padding: 49.2rem;
  }
  .lg:p-49-3 {
    padding: 49.3rem;
  }
  .lg:p-49-4 {
    padding: 49.4rem;
  }
  .lg:p-49-5 {
    padding: 49.5rem;
  }
  .lg:p-49-6 {
    padding: 49.6rem;
  }
  .lg:p-49-7 {
    padding: 49.7rem;
  }
  .lg:p-49-8 {
    padding: 49.8rem;
  }
  .lg:p-49-9 {
    padding: 49.9rem;
  }
  .lg:p-50-0 {
    padding: 50rem;
  }
  .lg:p-50-1 {
    padding: 50.1rem;
  }
  .lg:p-50-2 {
    padding: 50.2rem;
  }
  .lg:p-50-3 {
    padding: 50.3rem;
  }
  .lg:p-50-4 {
    padding: 50.4rem;
  }
  .lg:p-50-5 {
    padding: 50.5rem;
  }
  .lg:p-50-6 {
    padding: 50.6rem;
  }
  .lg:p-50-7 {
    padding: 50.7rem;
  }
  .lg:p-50-8 {
    padding: 50.8rem;
  }
  .lg:p-50-9 {
    padding: 50.9rem;
  }
  .lg:p-51-0 {
    padding: 51rem;
  }
  .lg:p-51-1 {
    padding: 51.1rem;
  }
  .lg:p-51-2 {
    padding: 51.2rem;
  }
  .lg:p-51-3 {
    padding: 51.3rem;
  }
  .lg:p-51-4 {
    padding: 51.4rem;
  }
  .lg:p-51-5 {
    padding: 51.5rem;
  }
  .lg:p-51-6 {
    padding: 51.6rem;
  }
  .lg:p-51-7 {
    padding: 51.7rem;
  }
  .lg:p-51-8 {
    padding: 51.8rem;
  }
  .lg:p-51-9 {
    padding: 51.9rem;
  }
  .lg:p-52-0 {
    padding: 52rem;
  }
  .lg:p-52-1 {
    padding: 52.1rem;
  }
  .lg:p-52-2 {
    padding: 52.2rem;
  }
  .lg:p-52-3 {
    padding: 52.3rem;
  }
  .lg:p-52-4 {
    padding: 52.4rem;
  }
  .lg:p-52-5 {
    padding: 52.5rem;
  }
  .lg:p-52-6 {
    padding: 52.6rem;
  }
  .lg:p-52-7 {
    padding: 52.7rem;
  }
  .lg:p-52-8 {
    padding: 52.8rem;
  }
  .lg:p-52-9 {
    padding: 52.9rem;
  }
  .lg:p-53-0 {
    padding: 53rem;
  }
  .lg:p-53-1 {
    padding: 53.1rem;
  }
  .lg:p-53-2 {
    padding: 53.2rem;
  }
  .lg:p-53-3 {
    padding: 53.3rem;
  }
  .lg:p-53-4 {
    padding: 53.4rem;
  }
  .lg:p-53-5 {
    padding: 53.5rem;
  }
  .lg:p-53-6 {
    padding: 53.6rem;
  }
  .lg:p-53-7 {
    padding: 53.7rem;
  }
  .lg:p-53-8 {
    padding: 53.8rem;
  }
  .lg:p-53-9 {
    padding: 53.9rem;
  }
  .lg:p-54-0 {
    padding: 54rem;
  }
  .lg:p-54-1 {
    padding: 54.1rem;
  }
  .lg:p-54-2 {
    padding: 54.2rem;
  }
  .lg:p-54-3 {
    padding: 54.3rem;
  }
  .lg:p-54-4 {
    padding: 54.4rem;
  }
  .lg:p-54-5 {
    padding: 54.5rem;
  }
  .lg:p-54-6 {
    padding: 54.6rem;
  }
  .lg:p-54-7 {
    padding: 54.7rem;
  }
  .lg:p-54-8 {
    padding: 54.8rem;
  }
  .lg:p-54-9 {
    padding: 54.9rem;
  }
  .lg:p-55-0 {
    padding: 55rem;
  }
  .lg:p-55-1 {
    padding: 55.1rem;
  }
  .lg:p-55-2 {
    padding: 55.2rem;
  }
  .lg:p-55-3 {
    padding: 55.3rem;
  }
  .lg:p-55-4 {
    padding: 55.4rem;
  }
  .lg:p-55-5 {
    padding: 55.5rem;
  }
  .lg:p-55-6 {
    padding: 55.6rem;
  }
  .lg:p-55-7 {
    padding: 55.7rem;
  }
  .lg:p-55-8 {
    padding: 55.8rem;
  }
  .lg:p-55-9 {
    padding: 55.9rem;
  }
  .lg:p-56-0 {
    padding: 56rem;
  }
  .lg:p-56-1 {
    padding: 56.1rem;
  }
  .lg:p-56-2 {
    padding: 56.2rem;
  }
  .lg:p-56-3 {
    padding: 56.3rem;
  }
  .lg:p-56-4 {
    padding: 56.4rem;
  }
  .lg:p-56-5 {
    padding: 56.5rem;
  }
  .lg:p-56-6 {
    padding: 56.6rem;
  }
  .lg:p-56-7 {
    padding: 56.7rem;
  }
  .lg:p-56-8 {
    padding: 56.8rem;
  }
  .lg:p-56-9 {
    padding: 56.9rem;
  }
  .lg:p-57-0 {
    padding: 57rem;
  }
  .lg:p-57-1 {
    padding: 57.1rem;
  }
  .lg:p-57-2 {
    padding: 57.2rem;
  }
  .lg:p-57-3 {
    padding: 57.3rem;
  }
  .lg:p-57-4 {
    padding: 57.4rem;
  }
  .lg:p-57-5 {
    padding: 57.5rem;
  }
  .lg:p-57-6 {
    padding: 57.6rem;
  }
  .lg:p-57-7 {
    padding: 57.7rem;
  }
  .lg:p-57-8 {
    padding: 57.8rem;
  }
  .lg:p-57-9 {
    padding: 57.9rem;
  }
  .lg:p-58-0 {
    padding: 58rem;
  }
  .lg:p-58-1 {
    padding: 58.1rem;
  }
  .lg:p-58-2 {
    padding: 58.2rem;
  }
  .lg:p-58-3 {
    padding: 58.3rem;
  }
  .lg:p-58-4 {
    padding: 58.4rem;
  }
  .lg:p-58-5 {
    padding: 58.5rem;
  }
  .lg:p-58-6 {
    padding: 58.6rem;
  }
  .lg:p-58-7 {
    padding: 58.7rem;
  }
  .lg:p-58-8 {
    padding: 58.8rem;
  }
  .lg:p-58-9 {
    padding: 58.9rem;
  }
  .lg:p-59-0 {
    padding: 59rem;
  }
  .lg:p-59-1 {
    padding: 59.1rem;
  }
  .lg:p-59-2 {
    padding: 59.2rem;
  }
  .lg:p-59-3 {
    padding: 59.3rem;
  }
  .lg:p-59-4 {
    padding: 59.4rem;
  }
  .lg:p-59-5 {
    padding: 59.5rem;
  }
  .lg:p-59-6 {
    padding: 59.6rem;
  }
  .lg:p-59-7 {
    padding: 59.7rem;
  }
  .lg:p-59-8 {
    padding: 59.8rem;
  }
  .lg:p-59-9 {
    padding: 59.9rem;
  }
  .lg:p-60-0 {
    padding: 60rem;
  }
  .lg:p-60-1 {
    padding: 60.1rem;
  }
  .lg:p-60-2 {
    padding: 60.2rem;
  }
  .lg:p-60-3 {
    padding: 60.3rem;
  }
  .lg:p-60-4 {
    padding: 60.4rem;
  }
  .lg:p-60-5 {
    padding: 60.5rem;
  }
  .lg:p-60-6 {
    padding: 60.6rem;
  }
  .lg:p-60-7 {
    padding: 60.7rem;
  }
  .lg:p-60-8 {
    padding: 60.8rem;
  }
  .lg:p-60-9 {
    padding: 60.9rem;
  }
  .lg:p-61-0 {
    padding: 61rem;
  }
  .lg:p-61-1 {
    padding: 61.1rem;
  }
  .lg:p-61-2 {
    padding: 61.2rem;
  }
  .lg:p-61-3 {
    padding: 61.3rem;
  }
  .lg:p-61-4 {
    padding: 61.4rem;
  }
  .lg:p-61-5 {
    padding: 61.5rem;
  }
  .lg:p-61-6 {
    padding: 61.6rem;
  }
  .lg:p-61-7 {
    padding: 61.7rem;
  }
  .lg:p-61-8 {
    padding: 61.8rem;
  }
  .lg:p-61-9 {
    padding: 61.9rem;
  }
  .lg:p-62-0 {
    padding: 62rem;
  }
  .lg:p-62-1 {
    padding: 62.1rem;
  }
  .lg:p-62-2 {
    padding: 62.2rem;
  }
  .lg:p-62-3 {
    padding: 62.3rem;
  }
  .lg:p-62-4 {
    padding: 62.4rem;
  }
  .lg:p-62-5 {
    padding: 62.5rem;
  }
  .lg:p-62-6 {
    padding: 62.6rem;
  }
  .lg:p-62-7 {
    padding: 62.7rem;
  }
  .lg:p-62-8 {
    padding: 62.8rem;
  }
  .lg:p-62-9 {
    padding: 62.9rem;
  }
  .lg:p-63-0 {
    padding: 63rem;
  }
  .lg:p-63-1 {
    padding: 63.1rem;
  }
  .lg:p-63-2 {
    padding: 63.2rem;
  }
  .lg:p-63-3 {
    padding: 63.3rem;
  }
  .lg:p-63-4 {
    padding: 63.4rem;
  }
  .lg:p-63-5 {
    padding: 63.5rem;
  }
  .lg:p-63-6 {
    padding: 63.6rem;
  }
  .lg:p-63-7 {
    padding: 63.7rem;
  }
  .lg:p-63-8 {
    padding: 63.8rem;
  }
  .lg:p-63-9 {
    padding: 63.9rem;
  }
  .lg:p-64-0 {
    padding: 64rem;
  }
  .lg:p-64-1 {
    padding: 64.1rem;
  }
  .lg:p-64-2 {
    padding: 64.2rem;
  }
  .lg:p-64-3 {
    padding: 64.3rem;
  }
  .lg:p-64-4 {
    padding: 64.4rem;
  }
  .lg:p-64-5 {
    padding: 64.5rem;
  }
  .lg:p-64-6 {
    padding: 64.6rem;
  }
  .lg:p-64-7 {
    padding: 64.7rem;
  }
  .lg:p-64-8 {
    padding: 64.8rem;
  }
  .lg:p-64-9 {
    padding: 64.9rem;
  }
  .lg:p-65-0 {
    padding: 65rem;
  }
  .lg:p-65-1 {
    padding: 65.1rem;
  }
  .lg:p-65-2 {
    padding: 65.2rem;
  }
  .lg:p-65-3 {
    padding: 65.3rem;
  }
  .lg:p-65-4 {
    padding: 65.4rem;
  }
  .lg:p-65-5 {
    padding: 65.5rem;
  }
  .lg:p-65-6 {
    padding: 65.6rem;
  }
  .lg:p-65-7 {
    padding: 65.7rem;
  }
  .lg:p-65-8 {
    padding: 65.8rem;
  }
  .lg:p-65-9 {
    padding: 65.9rem;
  }
  .lg:p-66-0 {
    padding: 66rem;
  }
  .lg:p-66-1 {
    padding: 66.1rem;
  }
  .lg:p-66-2 {
    padding: 66.2rem;
  }
  .lg:p-66-3 {
    padding: 66.3rem;
  }
  .lg:p-66-4 {
    padding: 66.4rem;
  }
  .lg:p-66-5 {
    padding: 66.5rem;
  }
  .lg:p-66-6 {
    padding: 66.6rem;
  }
  .lg:p-66-7 {
    padding: 66.7rem;
  }
  .lg:p-66-8 {
    padding: 66.8rem;
  }
  .lg:p-66-9 {
    padding: 66.9rem;
  }
  .lg:p-67-0 {
    padding: 67rem;
  }
  .lg:p-67-1 {
    padding: 67.1rem;
  }
  .lg:p-67-2 {
    padding: 67.2rem;
  }
  .lg:p-67-3 {
    padding: 67.3rem;
  }
  .lg:p-67-4 {
    padding: 67.4rem;
  }
  .lg:p-67-5 {
    padding: 67.5rem;
  }
  .lg:p-67-6 {
    padding: 67.6rem;
  }
  .lg:p-67-7 {
    padding: 67.7rem;
  }
  .lg:p-67-8 {
    padding: 67.8rem;
  }
  .lg:p-67-9 {
    padding: 67.9rem;
  }
  .lg:p-68-0 {
    padding: 68rem;
  }
  .lg:p-68-1 {
    padding: 68.1rem;
  }
  .lg:p-68-2 {
    padding: 68.2rem;
  }
  .lg:p-68-3 {
    padding: 68.3rem;
  }
  .lg:p-68-4 {
    padding: 68.4rem;
  }
  .lg:p-68-5 {
    padding: 68.5rem;
  }
  .lg:p-68-6 {
    padding: 68.6rem;
  }
  .lg:p-68-7 {
    padding: 68.7rem;
  }
  .lg:p-68-8 {
    padding: 68.8rem;
  }
  .lg:p-68-9 {
    padding: 68.9rem;
  }
  .lg:p-69-0 {
    padding: 69rem;
  }
  .lg:p-69-1 {
    padding: 69.1rem;
  }
  .lg:p-69-2 {
    padding: 69.2rem;
  }
  .lg:p-69-3 {
    padding: 69.3rem;
  }
  .lg:p-69-4 {
    padding: 69.4rem;
  }
  .lg:p-69-5 {
    padding: 69.5rem;
  }
  .lg:p-69-6 {
    padding: 69.6rem;
  }
  .lg:p-69-7 {
    padding: 69.7rem;
  }
  .lg:p-69-8 {
    padding: 69.8rem;
  }
  .lg:p-69-9 {
    padding: 69.9rem;
  }
  .lg:p-70-0 {
    padding: 70rem;
  }
  .lg:p-70-1 {
    padding: 70.1rem;
  }
  .lg:p-70-2 {
    padding: 70.2rem;
  }
  .lg:p-70-3 {
    padding: 70.3rem;
  }
  .lg:p-70-4 {
    padding: 70.4rem;
  }
  .lg:p-70-5 {
    padding: 70.5rem;
  }
  .lg:p-70-6 {
    padding: 70.6rem;
  }
  .lg:p-70-7 {
    padding: 70.7rem;
  }
  .lg:p-70-8 {
    padding: 70.8rem;
  }
  .lg:p-70-9 {
    padding: 70.9rem;
  }
  .lg:p-71-0 {
    padding: 71rem;
  }
  .lg:p-71-1 {
    padding: 71.1rem;
  }
  .lg:p-71-2 {
    padding: 71.2rem;
  }
  .lg:p-71-3 {
    padding: 71.3rem;
  }
  .lg:p-71-4 {
    padding: 71.4rem;
  }
  .lg:p-71-5 {
    padding: 71.5rem;
  }
  .lg:p-71-6 {
    padding: 71.6rem;
  }
  .lg:p-71-7 {
    padding: 71.7rem;
  }
  .lg:p-71-8 {
    padding: 71.8rem;
  }
  .lg:p-71-9 {
    padding: 71.9rem;
  }
  .lg:p-72-0 {
    padding: 72rem;
  }
  .lg:p-72-1 {
    padding: 72.1rem;
  }
  .lg:p-72-2 {
    padding: 72.2rem;
  }
  .lg:p-72-3 {
    padding: 72.3rem;
  }
  .lg:p-72-4 {
    padding: 72.4rem;
  }
  .lg:p-72-5 {
    padding: 72.5rem;
  }
  .lg:p-72-6 {
    padding: 72.6rem;
  }
  .lg:p-72-7 {
    padding: 72.7rem;
  }
  .lg:p-72-8 {
    padding: 72.8rem;
  }
  .lg:p-72-9 {
    padding: 72.9rem;
  }
  .lg:p-0 {
    padding: 0;
  }
  .lg:p-1 {
    padding: 1rem;
  }
  .lg:p-2 {
    padding: 2rem;
  }
  .lg:p-3 {
    padding: 4rem;
  }
  .lg:p-4 {
    padding: 6rem;
  }
  .lg:p-5 {
    padding: 8rem;
  }
  .lg:pl-0 {
    padding-left: 0;
  }
  .lg:pl-1 {
    padding-left: 1rem;
  }
  .lg:pl-2 {
    padding-left: 2rem;
  }
  .lg:pl-3 {
    padding-left: 4rem;
  }
  .lg:pl-4 {
    padding-left: 6rem;
  }
  .lg:pl-5 {
    padding-left: 8rem;
  }
  .lg:pr-0 {
    padding-right: 0;
  }
  .lg:pr-1 {
    padding-right: 1rem;
  }
  .lg:pr-2 {
    padding-right: 2rem;
  }
  .lg:pr-3 {
    padding-right: 4rem;
  }
  .lg:pr-4 {
    padding-right: 6rem;
  }
  .lg:pr-5 {
    padding-right: 8rem;
  }
  .lg:pt-0 {
    padding-top: 0;
  }
  .lg:pt-1 {
    padding-top: 1rem;
  }
  .lg:pt-2 {
    padding-top: 2rem;
  }
  .lg:pt-3 {
    padding-top: 4rem;
  }
  .lg:pt-4 {
    padding-top: 6rem;
  }
  .lg:pt-5 {
    padding-top: 8rem;
  }
  .lg:pb-0 {
    padding-bottom: 0;
  }
  .lg:pb-1 {
    padding-bottom: 1rem;
  }
  .lg:pb-2 {
    padding-bottom: 2rem;
  }
  .lg:pb-3 {
    padding-bottom: 4rem;
  }
  .lg:pb-4 {
    padding-bottom: 6rem;
  }
  .lg:pb-5 {
    padding-bottom: 8rem;
  }
  .lg:m-0 {
    margin: 0;
  }
  .lg:m-1 {
    margin: 1rem;
  }
  .lg:m-2 {
    margin: 2rem;
  }
  .lg:m-3 {
    margin: 4rem;
  }
  .lg:m-4 {
    margin: 6rem;
  }
  .lg:m-5 {
    margin: 8rem;
  }
  .lg:ml-0 {
    margin-left: 0;
  }
  .lg:ml-1 {
    margin-left: 1rem;
  }
  .lg:ml-2 {
    margin-left: 2rem;
  }
  .lg:ml-3 {
    margin-left: 4rem;
  }
  .lg:ml-4 {
    margin-left: 6rem;
  }
  .lg:ml-5 {
    margin-left: 8rem;
  }
  .lg:mr-0 {
    margin-right: 0;
  }
  .lg:mr-1 {
    margin-right: 1rem;
  }
  .lg:mr-2 {
    margin-right: 2rem;
  }
  .lg:mr-3 {
    margin-right: 4rem;
  }
  .lg:mr-4 {
    margin-right: 6rem;
  }
  .lg:mr-5 {
    margin-right: 8rem;
  }
  .lg:mt-0 {
    margin-top: 0;
  }
  .lg:mt-1 {
    margin-top: 1rem;
  }
  .lg:mt-2 {
    margin-top: 2rem;
  }
  .lg:mt-3 {
    margin-top: 4rem;
  }
  .lg:mt-4 {
    margin-top: 6rem;
  }
  .lg:mt-5 {
    margin-top: 8rem;
  }
  .lg:mb-0 {
    margin-bottom: 0;
  }
  .lg:mb-1 {
    margin-bottom: 1rem;
  }
  .lg:mb-2 {
    margin-bottom: 2rem;
  }
  .lg:mb-3 {
    margin-bottom: 4rem;
  }
  .lg:mb-4 {
    margin-bottom: 6rem;
  }
  .lg:mb-5 {
    margin-bottom: 8rem;
  }
  .lg:o-10 {
    opacity: 0.1;
  }
  .lg:o-20 {
    opacity: 0.2;
  }
  .lg:o-30 {
    opacity: 0.3;
  }
  .lg:o-40 {
    opacity: 0.4;
  }
  .lg:o-50 {
    opacity: 0.5;
  }
  .lg:o-60 {
    opacity: 0.6;
  }
  .lg:o-70 {
    opacity: 0.7;
  }
  .lg:o-80 {
    opacity: 0.8;
  }
  .lg:o-90 {
    opacity: 0.9;
  }
  .lg:o-100 {
    opacity: 1;
  }
  .lg:br {
    border-radius: 0.8rem;
  }
  .lg:br-none {
    border-radius: 0;
  }
  .lg:br-xs {
    border-radius: 0.2rem;
  }
  .lg:br-sm {
    border-radius: 0.4rem;
  }
  .lg:br-lg {
    border-radius: 1.6rem;
  }
  .lg:br-full {
    border-radius: 50%;
  }
  .lg:display-n {
    display: none;
  }
  .lg:display-b {
    display: block;
  }
  .lg:display-f {
    display: flex;
  }
  .lg:display-i {
    display: inline;
  }
  .lg:display-i-b {
    display: inline-block;
  }
  .lg:align-f-start {
    align-items: flex-start;
  }
  .lg:align-f-end {
    align-items: flex-end;
  }
  .lg:align-center {
    align-items: center;
  }
  .lg:flex-row {
    flex-direction: row;
  }
  .lg:flex-column {
    flex-direction: column;
  }
  .lg:justify-between {
    justify-content: space-between;
  }
  .lg:justify-f-end {
    justify-content: flex-end;
  }
  .lg:justify-center {
    justify-content: center;
  }
  .lg:justify-around {
    justify-content: space-around;
  }
  .lg:justify-evenly {
    justify-content: space-evenly;
  }
  .lg:text-center {
    text-align: center;
  }
  .lg:text-start {
    text-align: start;
  }
  .lg:text-end {
    text-align: end;
  }
  .lg:text-left {
    text-align: left;
  }
  .lg:text-right {
    text-align: right;
  }
  .lg:text-justify {
    text-align: justify;
  }
  .lg:text-d2xl {
    font-size: 7.2rem;
  }
  .lg:text-dxl {
    font-size: 6rem;
  }
  .lg:text-dlg {
    font-size: 4.8rem;
  }
  .lg:text-dmd {
    font-size: 3.6rem;
  }
  .lg:text-dsm {
    font-size: 3.2rem;
  }
  .lg:text-dxs {
    font-size: 2.4rem;
  }
  .lg:text-xxl {
    font-size: 2.4rem;
  }
  .lg:text-xl {
    font-size: 2rem;
  }
  .lg:text-lg {
    font-size: 1.8rem;
  }
  .lg:text-md {
    font-size: 1.6rem;
  }
  .lg:text-sm {
    font-size: 1.4rem;
  }
  .lg:text-xs {
    font-size: 1.2rem;
  }
  .lg:text-macro {
    font-size: 1rem;
  }
  .lg:gap-0 {
    gap: 0rem;
  }
  .lg:gap-1 {
    gap: 1rem;
  }
  .lg:gap-2 {
    gap: 2rem;
  }
  .lg:gap-3 {
    gap: 3rem;
  }
  .lg:gap-4 {
    gap: 4rem;
  }
  .lg:gap-5 {
    gap: 5rem;
  }
  .lg:gap-6 {
    gap: 6rem;
  }
  .lg:gap-7 {
    gap: 7rem;
  }
  .lg:gap-8 {
    gap: 8rem;
  }
  .lg:gap-9 {
    gap: 9rem;
  }
  .lg:gap-10 {
    gap: 10rem;
  }
  .lg:gap-11 {
    gap: 11rem;
  }
  .lg:gap-12 {
    gap: 12rem;
  }
  .lg:gap-13 {
    gap: 13rem;
  }
  .lg:gap-14 {
    gap: 14rem;
  }
  .lg:gap-15 {
    gap: 15rem;
  }
  .lg:gap-16 {
    gap: 16rem;
  }
  .lg:gap-17 {
    gap: 17rem;
  }
  .lg:gap-18 {
    gap: 18rem;
  }
  .lg:gap-19 {
    gap: 19rem;
  }
  .lg:gap-20 {
    gap: 20rem;
  }
  .lg:gap-21 {
    gap: 21rem;
  }
  .lg:gap-22 {
    gap: 22rem;
  }
  .lg:gap-23 {
    gap: 23rem;
  }
  .lg:gap-24 {
    gap: 24rem;
  }
  .lg:gap-25 {
    gap: 25rem;
  }
  .lg:gap-26 {
    gap: 26rem;
  }
  .lg:gap-27 {
    gap: 27rem;
  }
  .lg:gap-28 {
    gap: 28rem;
  }
  .lg:gap-29 {
    gap: 29rem;
  }
  .lg:gap-30 {
    gap: 30rem;
  }
  .lg:gap-31 {
    gap: 31rem;
  }
  .lg:gap-32 {
    gap: 32rem;
  }
  .lg:gap-33 {
    gap: 33rem;
  }
  .lg:gap-34 {
    gap: 34rem;
  }
  .lg:gap-35 {
    gap: 35rem;
  }
  .lg:gap-36 {
    gap: 36rem;
  }
  .lg:gap-37 {
    gap: 37rem;
  }
  .lg:gap-38 {
    gap: 38rem;
  }
  .lg:gap-39 {
    gap: 39rem;
  }
  .lg:gap-40 {
    gap: 40rem;
  }
  .lg:gap-41 {
    gap: 41rem;
  }
  .lg:gap-42 {
    gap: 42rem;
  }
  .lg:gap-43 {
    gap: 43rem;
  }
  .lg:gap-44 {
    gap: 44rem;
  }
  .lg:gap-45 {
    gap: 45rem;
  }
  .lg:gap-46 {
    gap: 46rem;
  }
  .lg:gap-47 {
    gap: 47rem;
  }
  .lg:gap-48 {
    gap: 48rem;
  }
  .lg:gap-49 {
    gap: 49rem;
  }
  .lg:w-0 {
    width: 0rem;
  }
  .lg:w-1 {
    width: 1rem;
  }
  .lg:w-2 {
    width: 2rem;
  }
  .lg:w-3 {
    width: 3rem;
  }
  .lg:w-4 {
    width: 4rem;
  }
  .lg:w-5 {
    width: 5rem;
  }
  .lg:w-6 {
    width: 6rem;
  }
  .lg:w-7 {
    width: 7rem;
  }
  .lg:w-8 {
    width: 8rem;
  }
  .lg:w-9 {
    width: 9rem;
  }
  .lg:w-10 {
    width: 10rem;
  }
  .lg:w-11 {
    width: 11rem;
  }
  .lg:w-12 {
    width: 12rem;
  }
  .lg:w-13 {
    width: 13rem;
  }
  .lg:w-14 {
    width: 14rem;
  }
  .lg:w-15 {
    width: 15rem;
  }
  .lg:w-16 {
    width: 16rem;
  }
  .lg:w-17 {
    width: 17rem;
  }
  .lg:w-18 {
    width: 18rem;
  }
  .lg:w-19 {
    width: 19rem;
  }
  .lg:w-20 {
    width: 20rem;
  }
  .lg:w-21 {
    width: 21rem;
  }
  .lg:w-22 {
    width: 22rem;
  }
  .lg:w-23 {
    width: 23rem;
  }
  .lg:w-24 {
    width: 24rem;
  }
  .lg:w-25 {
    width: 25rem;
  }
  .lg:w-26 {
    width: 26rem;
  }
  .lg:w-27 {
    width: 27rem;
  }
  .lg:w-28 {
    width: 28rem;
  }
  .lg:w-29 {
    width: 29rem;
  }
  .lg:w-30 {
    width: 30rem;
  }
  .lg:w-31 {
    width: 31rem;
  }
  .lg:w-32 {
    width: 32rem;
  }
  .lg:w-33 {
    width: 33rem;
  }
  .lg:w-34 {
    width: 34rem;
  }
  .lg:w-35 {
    width: 35rem;
  }
  .lg:w-36 {
    width: 36rem;
  }
  .lg:w-37 {
    width: 37rem;
  }
  .lg:w-38 {
    width: 38rem;
  }
  .lg:w-39 {
    width: 39rem;
  }
  .lg:w-40 {
    width: 40rem;
  }
  .lg:w-41 {
    width: 41rem;
  }
  .lg:w-42 {
    width: 42rem;
  }
  .lg:w-43 {
    width: 43rem;
  }
  .lg:w-44 {
    width: 44rem;
  }
  .lg:w-45 {
    width: 45rem;
  }
  .lg:w-46 {
    width: 46rem;
  }
  .lg:w-47 {
    width: 47rem;
  }
  .lg:w-48 {
    width: 48rem;
  }
  .lg:w-49 {
    width: 49rem;
  }
  .lg:w-50 {
    width: 50rem;
  }
  .lg:w-51 {
    width: 51rem;
  }
  .lg:w-52 {
    width: 52rem;
  }
  .lg:w-53 {
    width: 53rem;
  }
  .lg:w-54 {
    width: 54rem;
  }
  .lg:w-55 {
    width: 55rem;
  }
  .lg:w-56 {
    width: 56rem;
  }
  .lg:w-57 {
    width: 57rem;
  }
  .lg:w-58 {
    width: 58rem;
  }
  .lg:w-59 {
    width: 59rem;
  }
  .lg:w-60 {
    width: 60rem;
  }
  .lg:w-61 {
    width: 61rem;
  }
  .lg:w-62 {
    width: 62rem;
  }
  .lg:w-63 {
    width: 63rem;
  }
  .lg:w-64 {
    width: 64rem;
  }
  .lg:w-65 {
    width: 65rem;
  }
  .lg:w-66 {
    width: 66rem;
  }
  .lg:w-67 {
    width: 67rem;
  }
  .lg:w-68 {
    width: 68rem;
  }
  .lg:w-69 {
    width: 69rem;
  }
  .lg:w-70 {
    width: 70rem;
  }
  .lg:w-71 {
    width: 71rem;
  }
  .lg:w-72 {
    width: 72rem;
  }
  .lg:w-73 {
    width: 73rem;
  }
  .lg:w-74 {
    width: 74rem;
  }
  .lg:w-75 {
    width: 75rem;
  }
  .lg:w-76 {
    width: 76rem;
  }
  .lg:w-77 {
    width: 77rem;
  }
  .lg:w-78 {
    width: 78rem;
  }
  .lg:w-79 {
    width: 79rem;
  }
  .lg:w-80 {
    width: 80rem;
  }
  .lg:w-81 {
    width: 81rem;
  }
  .lg:w-82 {
    width: 82rem;
  }
  .lg:w-83 {
    width: 83rem;
  }
  .lg:w-84 {
    width: 84rem;
  }
  .lg:w-85 {
    width: 85rem;
  }
  .lg:w-86 {
    width: 86rem;
  }
  .lg:w-87 {
    width: 87rem;
  }
  .lg:w-88 {
    width: 88rem;
  }
  .lg:w-89 {
    width: 89rem;
  }
  .lg:w-90 {
    width: 90rem;
  }
  .lg:w-91 {
    width: 91rem;
  }
  .lg:w-92 {
    width: 92rem;
  }
  .lg:w-93 {
    width: 93rem;
  }
  .lg:w-94 {
    width: 94rem;
  }
  .lg:w-95 {
    width: 95rem;
  }
  .lg:w-96 {
    width: 96rem;
  }
  .lg:w-97 {
    width: 97rem;
  }
  .lg:w-98 {
    width: 98rem;
  }
  .lg:w-99 {
    width: 99rem;
  }
  .lg:w-100 {
    width: 100rem;
  }
  .lg:w-101 {
    width: 101rem;
  }
  .lg:w-102 {
    width: 102rem;
  }
  .lg:w-103 {
    width: 103rem;
  }
  .lg:w-104 {
    width: 104rem;
  }
  .lg:w-105 {
    width: 105rem;
  }
  .lg:w-106 {
    width: 106rem;
  }
  .lg:w-107 {
    width: 107rem;
  }
  .lg:w-108 {
    width: 108rem;
  }
  .lg:w-109 {
    width: 109rem;
  }
  .lg:w-110 {
    width: 110rem;
  }
  .lg:w-111 {
    width: 111rem;
  }
  .lg:w-112 {
    width: 112rem;
  }
  .lg:w-113 {
    width: 113rem;
  }
  .lg:w-114 {
    width: 114rem;
  }
  .lg:w-115 {
    width: 115rem;
  }
  .lg:w-116 {
    width: 116rem;
  }
  .lg:w-117 {
    width: 117rem;
  }
  .lg:w-118 {
    width: 118rem;
  }
  .lg:w-119 {
    width: 119rem;
  }
  .lg:w-120 {
    width: 120rem;
  }
  .lg:w-121 {
    width: 121rem;
  }
  .lg:w-122 {
    width: 122rem;
  }
  .lg:w-123 {
    width: 123rem;
  }
  .lg:w-124 {
    width: 124rem;
  }
  .lg:w-125 {
    width: 125rem;
  }
  .lg:w-126 {
    width: 126rem;
  }
  .lg:w-127 {
    width: 127rem;
  }
  .lg:w-128 {
    width: 128rem;
  }
  .lg:w-129 {
    width: 129rem;
  }
  .lg:w-130 {
    width: 130rem;
  }
  .lg:w-131 {
    width: 131rem;
  }
  .lg:w-132 {
    width: 132rem;
  }
  .lg:w-133 {
    width: 133rem;
  }
  .lg:w-134 {
    width: 134rem;
  }
  .lg:w-135 {
    width: 135rem;
  }
  .lg:w-136 {
    width: 136rem;
  }
  .lg:w-137 {
    width: 137rem;
  }
  .lg:w-138 {
    width: 138rem;
  }
  .lg:w-139 {
    width: 139rem;
  }
  .lg:w-140 {
    width: 140rem;
  }
  .lg:w-141 {
    width: 141rem;
  }
  .lg:w-142 {
    width: 142rem;
  }
  .lg:w-143 {
    width: 143rem;
  }
  .lg:w-144 {
    width: 144rem;
  }
  .lg:w-145 {
    width: 145rem;
  }
  .lg:w-146 {
    width: 146rem;
  }
  .lg:w-147 {
    width: 147rem;
  }
  .lg:w-148 {
    width: 148rem;
  }
  .lg:w-149 {
    width: 149rem;
  }
  .lg:w-150 {
    width: 150rem;
  }
  .lg:w-151 {
    width: 151rem;
  }
  .lg:w-152 {
    width: 152rem;
  }
  .lg:w-153 {
    width: 153rem;
  }
  .lg:w-154 {
    width: 154rem;
  }
  .lg:w-155 {
    width: 155rem;
  }
  .lg:w-156 {
    width: 156rem;
  }
  .lg:w-157 {
    width: 157rem;
  }
  .lg:w-158 {
    width: 158rem;
  }
  .lg:w-159 {
    width: 159rem;
  }
  .lg:w-160 {
    width: 160rem;
  }
  .lg:w-161 {
    width: 161rem;
  }
  .lg:w-162 {
    width: 162rem;
  }
  .lg:w-163 {
    width: 163rem;
  }
  .lg:w-164 {
    width: 164rem;
  }
  .lg:w-165 {
    width: 165rem;
  }
  .lg:w-166 {
    width: 166rem;
  }
  .lg:w-167 {
    width: 167rem;
  }
  .lg:w-168 {
    width: 168rem;
  }
  .lg:w-169 {
    width: 169rem;
  }
  .lg:w-170 {
    width: 170rem;
  }
  .lg:w-171 {
    width: 171rem;
  }
  .lg:w-172 {
    width: 172rem;
  }
  .lg:w-173 {
    width: 173rem;
  }
  .lg:w-174 {
    width: 174rem;
  }
  .lg:w-175 {
    width: 175rem;
  }
  .lg:w-176 {
    width: 176rem;
  }
  .lg:w-177 {
    width: 177rem;
  }
  .lg:w-178 {
    width: 178rem;
  }
  .lg:w-179 {
    width: 179rem;
  }
  .lg:w-180 {
    width: 180rem;
  }
  .lg:w-181 {
    width: 181rem;
  }
  .lg:w-182 {
    width: 182rem;
  }
  .lg:w-183 {
    width: 183rem;
  }
  .lg:w-184 {
    width: 184rem;
  }
  .lg:w-185 {
    width: 185rem;
  }
  .lg:w-186 {
    width: 186rem;
  }
  .lg:w-187 {
    width: 187rem;
  }
  .lg:w-188 {
    width: 188rem;
  }
  .lg:w-189 {
    width: 189rem;
  }
  .lg:w-190 {
    width: 190rem;
  }
  .lg:w-191 {
    width: 191rem;
  }
  .lg:w-192 {
    width: 192rem;
  }
  .lg:w-193 {
    width: 193rem;
  }
  .lg:w-194 {
    width: 194rem;
  }
  .lg:w-195 {
    width: 195rem;
  }
  .lg:w-196 {
    width: 196rem;
  }
  .lg:w-197 {
    width: 197rem;
  }
  .lg:w-198 {
    width: 198rem;
  }
  .lg:w-199 {
    width: 199rem;
  }
  .lg:w-200 {
    width: 200rem;
  }
  .lg:w-201 {
    width: 201rem;
  }
  .lg:w-202 {
    width: 202rem;
  }
  .lg:w-203 {
    width: 203rem;
  }
  .lg:w-204 {
    width: 204rem;
  }
  .lg:w-205 {
    width: 205rem;
  }
  .lg:w-206 {
    width: 206rem;
  }
  .lg:w-207 {
    width: 207rem;
  }
  .lg:w-208 {
    width: 208rem;
  }
  .lg:w-209 {
    width: 209rem;
  }
  .lg:w-210 {
    width: 210rem;
  }
  .lg:w-211 {
    width: 211rem;
  }
  .lg:w-212 {
    width: 212rem;
  }
  .lg:w-213 {
    width: 213rem;
  }
  .lg:w-214 {
    width: 214rem;
  }
  .lg:w-215 {
    width: 215rem;
  }
  .lg:w-216 {
    width: 216rem;
  }
  .lg:w-217 {
    width: 217rem;
  }
  .lg:w-218 {
    width: 218rem;
  }
  .lg:w-219 {
    width: 219rem;
  }
  .lg:w-220 {
    width: 220rem;
  }
  .lg:w-221 {
    width: 221rem;
  }
  .lg:w-222 {
    width: 222rem;
  }
  .lg:w-223 {
    width: 223rem;
  }
  .lg:w-224 {
    width: 224rem;
  }
  .lg:w-225 {
    width: 225rem;
  }
  .lg:w-226 {
    width: 226rem;
  }
  .lg:w-227 {
    width: 227rem;
  }
  .lg:w-228 {
    width: 228rem;
  }
  .lg:w-229 {
    width: 229rem;
  }
  .lg:w-230 {
    width: 230rem;
  }
  .lg:w-231 {
    width: 231rem;
  }
  .lg:w-232 {
    width: 232rem;
  }
  .lg:w-233 {
    width: 233rem;
  }
  .lg:w-234 {
    width: 234rem;
  }
  .lg:w-235 {
    width: 235rem;
  }
  .lg:w-236 {
    width: 236rem;
  }
  .lg:w-237 {
    width: 237rem;
  }
  .lg:w-238 {
    width: 238rem;
  }
  .lg:w-239 {
    width: 239rem;
  }
  .lg:w-240 {
    width: 240rem;
  }
  .lg:w-241 {
    width: 241rem;
  }
  .lg:w-242 {
    width: 242rem;
  }
  .lg:w-243 {
    width: 243rem;
  }
  .lg:w-244 {
    width: 244rem;
  }
  .lg:w-245 {
    width: 245rem;
  }
  .lg:w-246 {
    width: 246rem;
  }
  .lg:w-247 {
    width: 247rem;
  }
  .lg:w-248 {
    width: 248rem;
  }
  .lg:w-249 {
    width: 249rem;
  }
  .lg:w-250 {
    width: 250rem;
  }
  .lg:w-251 {
    width: 251rem;
  }
  .lg:w-252 {
    width: 252rem;
  }
  .lg:w-253 {
    width: 253rem;
  }
  .lg:w-254 {
    width: 254rem;
  }
  .lg:w-255 {
    width: 255rem;
  }
  .lg:w-256 {
    width: 256rem;
  }
  .lg:w-257 {
    width: 257rem;
  }
  .lg:w-258 {
    width: 258rem;
  }
  .lg:w-259 {
    width: 259rem;
  }
  .lg:w-260 {
    width: 260rem;
  }
  .lg:w-261 {
    width: 261rem;
  }
  .lg:w-262 {
    width: 262rem;
  }
  .lg:w-263 {
    width: 263rem;
  }
  .lg:w-264 {
    width: 264rem;
  }
  .lg:w-265 {
    width: 265rem;
  }
  .lg:w-266 {
    width: 266rem;
  }
  .lg:w-267 {
    width: 267rem;
  }
  .lg:w-268 {
    width: 268rem;
  }
  .lg:w-269 {
    width: 269rem;
  }
  .lg:w-270 {
    width: 270rem;
  }
  .lg:w-271 {
    width: 271rem;
  }
  .lg:w-272 {
    width: 272rem;
  }
  .lg:w-273 {
    width: 273rem;
  }
  .lg:w-274 {
    width: 274rem;
  }
  .lg:w-275 {
    width: 275rem;
  }
  .lg:w-276 {
    width: 276rem;
  }
  .lg:w-277 {
    width: 277rem;
  }
  .lg:w-278 {
    width: 278rem;
  }
  .lg:w-279 {
    width: 279rem;
  }
  .lg:w-280 {
    width: 280rem;
  }
  .lg:w-281 {
    width: 281rem;
  }
  .lg:w-282 {
    width: 282rem;
  }
  .lg:w-283 {
    width: 283rem;
  }
  .lg:w-284 {
    width: 284rem;
  }
  .lg:w-285 {
    width: 285rem;
  }
  .lg:w-286 {
    width: 286rem;
  }
  .lg:w-287 {
    width: 287rem;
  }
  .lg:w-288 {
    width: 288rem;
  }
  .lg:w-289 {
    width: 289rem;
  }
  .lg:w-290 {
    width: 290rem;
  }
  .lg:w-291 {
    width: 291rem;
  }
  .lg:w-292 {
    width: 292rem;
  }
  .lg:w-293 {
    width: 293rem;
  }
  .lg:w-294 {
    width: 294rem;
  }
  .lg:w-295 {
    width: 295rem;
  }
  .lg:w-296 {
    width: 296rem;
  }
  .lg:w-297 {
    width: 297rem;
  }
  .lg:w-298 {
    width: 298rem;
  }
  .lg:w-299 {
    width: 299rem;
  }
  .lg:w-300 {
    width: 300rem;
  }
  .lg:w-301 {
    width: 301rem;
  }
  .lg:w-302 {
    width: 302rem;
  }
  .lg:w-303 {
    width: 303rem;
  }
  .lg:w-304 {
    width: 304rem;
  }
  .lg:w-305 {
    width: 305rem;
  }
  .lg:w-306 {
    width: 306rem;
  }
  .lg:w-307 {
    width: 307rem;
  }
  .lg:w-308 {
    width: 308rem;
  }
  .lg:w-309 {
    width: 309rem;
  }
  .lg:w-310 {
    width: 310rem;
  }
  .lg:w-311 {
    width: 311rem;
  }
  .lg:w-312 {
    width: 312rem;
  }
  .lg:w-313 {
    width: 313rem;
  }
  .lg:w-314 {
    width: 314rem;
  }
  .lg:w-315 {
    width: 315rem;
  }
  .lg:w-316 {
    width: 316rem;
  }
  .lg:w-317 {
    width: 317rem;
  }
  .lg:w-318 {
    width: 318rem;
  }
  .lg:w-319 {
    width: 319rem;
  }
  .lg:w-320 {
    width: 320rem;
  }
  .lg:w-321 {
    width: 321rem;
  }
  .lg:w-322 {
    width: 322rem;
  }
  .lg:w-323 {
    width: 323rem;
  }
  .lg:w-324 {
    width: 324rem;
  }
  .lg:w-325 {
    width: 325rem;
  }
  .lg:w-326 {
    width: 326rem;
  }
  .lg:w-327 {
    width: 327rem;
  }
  .lg:w-328 {
    width: 328rem;
  }
  .lg:w-329 {
    width: 329rem;
  }
  .lg:w-330 {
    width: 330rem;
  }
  .lg:w-331 {
    width: 331rem;
  }
  .lg:w-332 {
    width: 332rem;
  }
  .lg:w-333 {
    width: 333rem;
  }
  .lg:w-334 {
    width: 334rem;
  }
  .lg:w-335 {
    width: 335rem;
  }
  .lg:w-336 {
    width: 336rem;
  }
  .lg:w-337 {
    width: 337rem;
  }
  .lg:w-338 {
    width: 338rem;
  }
  .lg:w-339 {
    width: 339rem;
  }
  .lg:w-340 {
    width: 340rem;
  }
  .lg:w-341 {
    width: 341rem;
  }
  .lg:w-342 {
    width: 342rem;
  }
  .lg:w-343 {
    width: 343rem;
  }
  .lg:w-344 {
    width: 344rem;
  }
  .lg:w-345 {
    width: 345rem;
  }
  .lg:w-346 {
    width: 346rem;
  }
  .lg:w-347 {
    width: 347rem;
  }
  .lg:w-348 {
    width: 348rem;
  }
  .lg:w-349 {
    width: 349rem;
  }
  .lg:w-350 {
    width: 350rem;
  }
  .lg:w-351 {
    width: 351rem;
  }
  .lg:w-352 {
    width: 352rem;
  }
  .lg:w-353 {
    width: 353rem;
  }
  .lg:w-354 {
    width: 354rem;
  }
  .lg:w-355 {
    width: 355rem;
  }
  .lg:w-356 {
    width: 356rem;
  }
  .lg:w-357 {
    width: 357rem;
  }
  .lg:w-358 {
    width: 358rem;
  }
  .lg:w-359 {
    width: 359rem;
  }
  .lg:w-360 {
    width: 360rem;
  }
  .lg:w-361 {
    width: 361rem;
  }
  .lg:w-362 {
    width: 362rem;
  }
  .lg:w-363 {
    width: 363rem;
  }
  .lg:w-364 {
    width: 364rem;
  }
  .lg:w-365 {
    width: 365rem;
  }
  .lg:w-366 {
    width: 366rem;
  }
  .lg:w-367 {
    width: 367rem;
  }
  .lg:w-368 {
    width: 368rem;
  }
  .lg:w-369 {
    width: 369rem;
  }
  .lg:w-370 {
    width: 370rem;
  }
  .lg:w-371 {
    width: 371rem;
  }
  .lg:w-372 {
    width: 372rem;
  }
  .lg:w-373 {
    width: 373rem;
  }
  .lg:w-374 {
    width: 374rem;
  }
  .lg:w-375 {
    width: 375rem;
  }
  .lg:w-376 {
    width: 376rem;
  }
  .lg:w-377 {
    width: 377rem;
  }
  .lg:w-378 {
    width: 378rem;
  }
  .lg:w-379 {
    width: 379rem;
  }
  .lg:w-380 {
    width: 380rem;
  }
  .lg:w-381 {
    width: 381rem;
  }
  .lg:w-382 {
    width: 382rem;
  }
  .lg:w-383 {
    width: 383rem;
  }
  .lg:w-384 {
    width: 384rem;
  }
  .lg:w-385 {
    width: 385rem;
  }
  .lg:w-386 {
    width: 386rem;
  }
  .lg:w-387 {
    width: 387rem;
  }
  .lg:w-388 {
    width: 388rem;
  }
  .lg:w-389 {
    width: 389rem;
  }
  .lg:w-390 {
    width: 390rem;
  }
  .lg:w-391 {
    width: 391rem;
  }
  .lg:w-392 {
    width: 392rem;
  }
  .lg:w-393 {
    width: 393rem;
  }
  .lg:w-394 {
    width: 394rem;
  }
  .lg:w-395 {
    width: 395rem;
  }
  .lg:w-396 {
    width: 396rem;
  }
  .lg:w-397 {
    width: 397rem;
  }
  .lg:w-398 {
    width: 398rem;
  }
  .lg:w-399 {
    width: 399rem;
  }
  .lg:w-400 {
    width: 400rem;
  }
  .lg:w-401 {
    width: 401rem;
  }
  .lg:w-402 {
    width: 402rem;
  }
  .lg:w-403 {
    width: 403rem;
  }
  .lg:w-404 {
    width: 404rem;
  }
  .lg:w-405 {
    width: 405rem;
  }
  .lg:w-406 {
    width: 406rem;
  }
  .lg:w-407 {
    width: 407rem;
  }
  .lg:w-408 {
    width: 408rem;
  }
  .lg:w-409 {
    width: 409rem;
  }
  .lg:w-410 {
    width: 410rem;
  }
  .lg:w-411 {
    width: 411rem;
  }
  .lg:w-412 {
    width: 412rem;
  }
  .lg:w-413 {
    width: 413rem;
  }
  .lg:w-414 {
    width: 414rem;
  }
  .lg:w-415 {
    width: 415rem;
  }
  .lg:w-416 {
    width: 416rem;
  }
  .lg:w-417 {
    width: 417rem;
  }
  .lg:w-418 {
    width: 418rem;
  }
  .lg:w-419 {
    width: 419rem;
  }
  .lg:w-420 {
    width: 420rem;
  }
  .lg:w-421 {
    width: 421rem;
  }
  .lg:w-422 {
    width: 422rem;
  }
  .lg:w-423 {
    width: 423rem;
  }
  .lg:w-424 {
    width: 424rem;
  }
  .lg:w-425 {
    width: 425rem;
  }
  .lg:w-426 {
    width: 426rem;
  }
  .lg:w-427 {
    width: 427rem;
  }
  .lg:w-428 {
    width: 428rem;
  }
  .lg:w-429 {
    width: 429rem;
  }
  .lg:w-430 {
    width: 430rem;
  }
  .lg:w-431 {
    width: 431rem;
  }
  .lg:w-432 {
    width: 432rem;
  }
  .lg:w-433 {
    width: 433rem;
  }
  .lg:w-434 {
    width: 434rem;
  }
  .lg:w-435 {
    width: 435rem;
  }
  .lg:w-436 {
    width: 436rem;
  }
  .lg:w-437 {
    width: 437rem;
  }
  .lg:w-438 {
    width: 438rem;
  }
  .lg:w-439 {
    width: 439rem;
  }
  .lg:w-440 {
    width: 440rem;
  }
  .lg:w-441 {
    width: 441rem;
  }
  .lg:w-442 {
    width: 442rem;
  }
  .lg:w-443 {
    width: 443rem;
  }
  .lg:w-444 {
    width: 444rem;
  }
  .lg:w-445 {
    width: 445rem;
  }
  .lg:w-446 {
    width: 446rem;
  }
  .lg:w-447 {
    width: 447rem;
  }
  .lg:w-448 {
    width: 448rem;
  }
  .lg:w-449 {
    width: 449rem;
  }
  .lg:w-450 {
    width: 450rem;
  }
  .lg:w-451 {
    width: 451rem;
  }
  .lg:w-452 {
    width: 452rem;
  }
  .lg:w-453 {
    width: 453rem;
  }
  .lg:w-454 {
    width: 454rem;
  }
  .lg:w-455 {
    width: 455rem;
  }
  .lg:w-456 {
    width: 456rem;
  }
  .lg:w-457 {
    width: 457rem;
  }
  .lg:w-458 {
    width: 458rem;
  }
  .lg:w-459 {
    width: 459rem;
  }
  .lg:w-460 {
    width: 460rem;
  }
  .lg:w-461 {
    width: 461rem;
  }
  .lg:w-462 {
    width: 462rem;
  }
  .lg:w-463 {
    width: 463rem;
  }
  .lg:w-464 {
    width: 464rem;
  }
  .lg:w-465 {
    width: 465rem;
  }
  .lg:w-466 {
    width: 466rem;
  }
  .lg:w-467 {
    width: 467rem;
  }
  .lg:w-468 {
    width: 468rem;
  }
  .lg:w-469 {
    width: 469rem;
  }
  .lg:w-470 {
    width: 470rem;
  }
  .lg:w-471 {
    width: 471rem;
  }
  .lg:w-472 {
    width: 472rem;
  }
  .lg:w-473 {
    width: 473rem;
  }
  .lg:w-474 {
    width: 474rem;
  }
  .lg:w-475 {
    width: 475rem;
  }
  .lg:w-476 {
    width: 476rem;
  }
  .lg:w-477 {
    width: 477rem;
  }
  .lg:w-478 {
    width: 478rem;
  }
  .lg:w-479 {
    width: 479rem;
  }
  .lg:w-480 {
    width: 480rem;
  }
  .lg:w-481 {
    width: 481rem;
  }
  .lg:w-482 {
    width: 482rem;
  }
  .lg:w-483 {
    width: 483rem;
  }
  .lg:w-484 {
    width: 484rem;
  }
  .lg:w-485 {
    width: 485rem;
  }
  .lg:w-486 {
    width: 486rem;
  }
  .lg:w-487 {
    width: 487rem;
  }
  .lg:w-488 {
    width: 488rem;
  }
  .lg:w-489 {
    width: 489rem;
  }
  .lg:w-490 {
    width: 490rem;
  }
  .lg:w-491 {
    width: 491rem;
  }
  .lg:w-492 {
    width: 492rem;
  }
  .lg:w-493 {
    width: 493rem;
  }
  .lg:w-494 {
    width: 494rem;
  }
  .lg:w-495 {
    width: 495rem;
  }
  .lg:w-496 {
    width: 496rem;
  }
  .lg:w-497 {
    width: 497rem;
  }
  .lg:w-498 {
    width: 498rem;
  }
  .lg:w-499 {
    width: 499rem;
  }
  .lg:w-500 {
    width: 500rem;
  }
  .lg:w-501 {
    width: 501rem;
  }
  .lg:w-502 {
    width: 502rem;
  }
  .lg:w-503 {
    width: 503rem;
  }
  .lg:w-504 {
    width: 504rem;
  }
  .lg:w-505 {
    width: 505rem;
  }
  .lg:w-506 {
    width: 506rem;
  }
  .lg:w-507 {
    width: 507rem;
  }
  .lg:w-508 {
    width: 508rem;
  }
  .lg:w-509 {
    width: 509rem;
  }
  .lg:w-510 {
    width: 510rem;
  }
  .lg:w-511 {
    width: 511rem;
  }
  .lg:w-512 {
    width: 512rem;
  }
  .lg:w-513 {
    width: 513rem;
  }
  .lg:w-514 {
    width: 514rem;
  }
  .lg:w-515 {
    width: 515rem;
  }
  .lg:w-516 {
    width: 516rem;
  }
  .lg:w-517 {
    width: 517rem;
  }
  .lg:w-518 {
    width: 518rem;
  }
  .lg:w-519 {
    width: 519rem;
  }
  .lg:w-520 {
    width: 520rem;
  }
  .lg:w-521 {
    width: 521rem;
  }
  .lg:w-522 {
    width: 522rem;
  }
  .lg:w-523 {
    width: 523rem;
  }
  .lg:w-524 {
    width: 524rem;
  }
  .lg:w-525 {
    width: 525rem;
  }
  .lg:w-526 {
    width: 526rem;
  }
  .lg:w-527 {
    width: 527rem;
  }
  .lg:w-528 {
    width: 528rem;
  }
  .lg:w-529 {
    width: 529rem;
  }
  .lg:w-530 {
    width: 530rem;
  }
  .lg:w-531 {
    width: 531rem;
  }
  .lg:w-532 {
    width: 532rem;
  }
  .lg:w-533 {
    width: 533rem;
  }
  .lg:w-534 {
    width: 534rem;
  }
  .lg:w-535 {
    width: 535rem;
  }
  .lg:w-536 {
    width: 536rem;
  }
  .lg:w-537 {
    width: 537rem;
  }
  .lg:w-538 {
    width: 538rem;
  }
  .lg:w-539 {
    width: 539rem;
  }
  .lg:w-540 {
    width: 540rem;
  }
  .lg:w-541 {
    width: 541rem;
  }
  .lg:w-542 {
    width: 542rem;
  }
  .lg:w-543 {
    width: 543rem;
  }
  .lg:w-544 {
    width: 544rem;
  }
  .lg:w-545 {
    width: 545rem;
  }
  .lg:w-546 {
    width: 546rem;
  }
  .lg:w-547 {
    width: 547rem;
  }
  .lg:w-548 {
    width: 548rem;
  }
  .lg:w-549 {
    width: 549rem;
  }
  .lg:w-550 {
    width: 550rem;
  }
  .lg:w-551 {
    width: 551rem;
  }
  .lg:w-552 {
    width: 552rem;
  }
  .lg:w-553 {
    width: 553rem;
  }
  .lg:w-554 {
    width: 554rem;
  }
  .lg:w-555 {
    width: 555rem;
  }
  .lg:w-556 {
    width: 556rem;
  }
  .lg:w-557 {
    width: 557rem;
  }
  .lg:w-558 {
    width: 558rem;
  }
  .lg:w-559 {
    width: 559rem;
  }
  .lg:w-560 {
    width: 560rem;
  }
  .lg:w-561 {
    width: 561rem;
  }
  .lg:w-562 {
    width: 562rem;
  }
  .lg:w-563 {
    width: 563rem;
  }
  .lg:w-564 {
    width: 564rem;
  }
  .lg:w-565 {
    width: 565rem;
  }
  .lg:w-566 {
    width: 566rem;
  }
  .lg:w-567 {
    width: 567rem;
  }
  .lg:w-568 {
    width: 568rem;
  }
  .lg:w-569 {
    width: 569rem;
  }
  .lg:w-570 {
    width: 570rem;
  }
  .lg:w-571 {
    width: 571rem;
  }
  .lg:w-572 {
    width: 572rem;
  }
  .lg:w-573 {
    width: 573rem;
  }
  .lg:w-574 {
    width: 574rem;
  }
  .lg:w-575 {
    width: 575rem;
  }
  .lg:w-576 {
    width: 576rem;
  }
  .lg:w-577 {
    width: 577rem;
  }
  .lg:w-578 {
    width: 578rem;
  }
  .lg:w-579 {
    width: 579rem;
  }
  .lg:w-580 {
    width: 580rem;
  }
  .lg:w-581 {
    width: 581rem;
  }
  .lg:w-582 {
    width: 582rem;
  }
  .lg:w-583 {
    width: 583rem;
  }
  .lg:w-584 {
    width: 584rem;
  }
  .lg:w-585 {
    width: 585rem;
  }
  .lg:w-586 {
    width: 586rem;
  }
  .lg:w-587 {
    width: 587rem;
  }
  .lg:w-588 {
    width: 588rem;
  }
  .lg:w-589 {
    width: 589rem;
  }
  .lg:w-590 {
    width: 590rem;
  }
  .lg:w-591 {
    width: 591rem;
  }
  .lg:w-592 {
    width: 592rem;
  }
  .lg:w-593 {
    width: 593rem;
  }
  .lg:w-594 {
    width: 594rem;
  }
  .lg:w-595 {
    width: 595rem;
  }
  .lg:w-596 {
    width: 596rem;
  }
  .lg:w-597 {
    width: 597rem;
  }
  .lg:w-598 {
    width: 598rem;
  }
  .lg:w-599 {
    width: 599rem;
  }
  .lg:w-600 {
    width: 600rem;
  }
  .lg:w-601 {
    width: 601rem;
  }
  .lg:w-602 {
    width: 602rem;
  }
  .lg:w-603 {
    width: 603rem;
  }
  .lg:w-604 {
    width: 604rem;
  }
  .lg:w-605 {
    width: 605rem;
  }
  .lg:w-606 {
    width: 606rem;
  }
  .lg:w-607 {
    width: 607rem;
  }
  .lg:w-608 {
    width: 608rem;
  }
  .lg:w-609 {
    width: 609rem;
  }
  .lg:w-610 {
    width: 610rem;
  }
  .lg:w-611 {
    width: 611rem;
  }
  .lg:w-612 {
    width: 612rem;
  }
  .lg:w-613 {
    width: 613rem;
  }
  .lg:w-614 {
    width: 614rem;
  }
  .lg:w-615 {
    width: 615rem;
  }
  .lg:w-616 {
    width: 616rem;
  }
  .lg:w-617 {
    width: 617rem;
  }
  .lg:w-618 {
    width: 618rem;
  }
  .lg:w-619 {
    width: 619rem;
  }
  .lg:w-620 {
    width: 620rem;
  }
  .lg:w-621 {
    width: 621rem;
  }
  .lg:w-622 {
    width: 622rem;
  }
  .lg:w-623 {
    width: 623rem;
  }
  .lg:w-624 {
    width: 624rem;
  }
  .lg:w-625 {
    width: 625rem;
  }
  .lg:w-626 {
    width: 626rem;
  }
  .lg:w-627 {
    width: 627rem;
  }
  .lg:w-628 {
    width: 628rem;
  }
  .lg:w-629 {
    width: 629rem;
  }
  .lg:w-630 {
    width: 630rem;
  }
  .lg:w-631 {
    width: 631rem;
  }
  .lg:w-632 {
    width: 632rem;
  }
  .lg:w-633 {
    width: 633rem;
  }
  .lg:w-634 {
    width: 634rem;
  }
  .lg:w-635 {
    width: 635rem;
  }
  .lg:w-636 {
    width: 636rem;
  }
  .lg:w-637 {
    width: 637rem;
  }
  .lg:w-638 {
    width: 638rem;
  }
  .lg:w-639 {
    width: 639rem;
  }
  .lg:w-640 {
    width: 640rem;
  }
  .lg:w-641 {
    width: 641rem;
  }
  .lg:w-642 {
    width: 642rem;
  }
  .lg:w-643 {
    width: 643rem;
  }
  .lg:w-644 {
    width: 644rem;
  }
  .lg:w-645 {
    width: 645rem;
  }
  .lg:w-646 {
    width: 646rem;
  }
  .lg:w-647 {
    width: 647rem;
  }
  .lg:w-648 {
    width: 648rem;
  }
  .lg:w-649 {
    width: 649rem;
  }
  .lg:w-650 {
    width: 650rem;
  }
  .lg:w-651 {
    width: 651rem;
  }
  .lg:w-652 {
    width: 652rem;
  }
  .lg:w-653 {
    width: 653rem;
  }
  .lg:w-654 {
    width: 654rem;
  }
  .lg:w-655 {
    width: 655rem;
  }
  .lg:w-656 {
    width: 656rem;
  }
  .lg:w-657 {
    width: 657rem;
  }
  .lg:w-658 {
    width: 658rem;
  }
  .lg:w-659 {
    width: 659rem;
  }
  .lg:w-660 {
    width: 660rem;
  }
  .lg:w-661 {
    width: 661rem;
  }
  .lg:w-662 {
    width: 662rem;
  }
  .lg:w-663 {
    width: 663rem;
  }
  .lg:w-664 {
    width: 664rem;
  }
  .lg:w-665 {
    width: 665rem;
  }
  .lg:w-666 {
    width: 666rem;
  }
  .lg:w-667 {
    width: 667rem;
  }
  .lg:w-668 {
    width: 668rem;
  }
  .lg:w-669 {
    width: 669rem;
  }
  .lg:w-670 {
    width: 670rem;
  }
  .lg:w-671 {
    width: 671rem;
  }
  .lg:w-672 {
    width: 672rem;
  }
  .lg:w-673 {
    width: 673rem;
  }
  .lg:w-674 {
    width: 674rem;
  }
  .lg:w-675 {
    width: 675rem;
  }
  .lg:w-676 {
    width: 676rem;
  }
  .lg:w-677 {
    width: 677rem;
  }
  .lg:w-678 {
    width: 678rem;
  }
  .lg:w-679 {
    width: 679rem;
  }
  .lg:w-680 {
    width: 680rem;
  }
  .lg:w-681 {
    width: 681rem;
  }
  .lg:w-682 {
    width: 682rem;
  }
  .lg:w-683 {
    width: 683rem;
  }
  .lg:w-684 {
    width: 684rem;
  }
  .lg:w-685 {
    width: 685rem;
  }
  .lg:w-686 {
    width: 686rem;
  }
  .lg:w-687 {
    width: 687rem;
  }
  .lg:w-688 {
    width: 688rem;
  }
  .lg:w-689 {
    width: 689rem;
  }
  .lg:w-690 {
    width: 690rem;
  }
  .lg:w-691 {
    width: 691rem;
  }
  .lg:w-692 {
    width: 692rem;
  }
  .lg:w-693 {
    width: 693rem;
  }
  .lg:w-694 {
    width: 694rem;
  }
  .lg:w-695 {
    width: 695rem;
  }
  .lg:w-696 {
    width: 696rem;
  }
  .lg:w-697 {
    width: 697rem;
  }
  .lg:w-698 {
    width: 698rem;
  }
  .lg:w-699 {
    width: 699rem;
  }
  .lg:w-700 {
    width: 700rem;
  }
  .lg:w-701 {
    width: 701rem;
  }
  .lg:w-702 {
    width: 702rem;
  }
  .lg:w-703 {
    width: 703rem;
  }
  .lg:w-704 {
    width: 704rem;
  }
  .lg:w-705 {
    width: 705rem;
  }
  .lg:w-706 {
    width: 706rem;
  }
  .lg:w-707 {
    width: 707rem;
  }
  .lg:w-708 {
    width: 708rem;
  }
  .lg:w-709 {
    width: 709rem;
  }
  .lg:w-710 {
    width: 710rem;
  }
  .lg:w-711 {
    width: 711rem;
  }
  .lg:w-712 {
    width: 712rem;
  }
  .lg:w-713 {
    width: 713rem;
  }
  .lg:w-714 {
    width: 714rem;
  }
  .lg:w-715 {
    width: 715rem;
  }
  .lg:w-716 {
    width: 716rem;
  }
  .lg:w-717 {
    width: 717rem;
  }
  .lg:w-718 {
    width: 718rem;
  }
  .lg:w-719 {
    width: 719rem;
  }
  .lg:w-720 {
    width: 720rem;
  }
  .lg:w-721 {
    width: 721rem;
  }
  .lg:w-722 {
    width: 722rem;
  }
  .lg:w-723 {
    width: 723rem;
  }
  .lg:w-724 {
    width: 724rem;
  }
  .lg:w-725 {
    width: 725rem;
  }
  .lg:w-726 {
    width: 726rem;
  }
  .lg:w-727 {
    width: 727rem;
  }
  .lg:w-728 {
    width: 728rem;
  }
  .lg:w-729 {
    width: 729rem;
  }
  .lg:w-730 {
    width: 730rem;
  }
  .lg:w-731 {
    width: 731rem;
  }
  .lg:w-732 {
    width: 732rem;
  }
  .lg:w-733 {
    width: 733rem;
  }
  .lg:w-734 {
    width: 734rem;
  }
  .lg:w-735 {
    width: 735rem;
  }
  .lg:w-736 {
    width: 736rem;
  }
  .lg:w-737 {
    width: 737rem;
  }
  .lg:w-738 {
    width: 738rem;
  }
  .lg:w-739 {
    width: 739rem;
  }
  .lg:w-740 {
    width: 740rem;
  }
  .lg:w-741 {
    width: 741rem;
  }
  .lg:w-742 {
    width: 742rem;
  }
  .lg:w-743 {
    width: 743rem;
  }
  .lg:w-744 {
    width: 744rem;
  }
  .lg:w-745 {
    width: 745rem;
  }
  .lg:w-746 {
    width: 746rem;
  }
  .lg:w-747 {
    width: 747rem;
  }
  .lg:w-748 {
    width: 748rem;
  }
  .lg:w-749 {
    width: 749rem;
  }
  .lg:w-750 {
    width: 750rem;
  }
  .lg:w-751 {
    width: 751rem;
  }
  .lg:w-752 {
    width: 752rem;
  }
  .lg:w-753 {
    width: 753rem;
  }
  .lg:w-754 {
    width: 754rem;
  }
  .lg:w-755 {
    width: 755rem;
  }
  .lg:w-756 {
    width: 756rem;
  }
  .lg:w-757 {
    width: 757rem;
  }
  .lg:w-758 {
    width: 758rem;
  }
  .lg:w-759 {
    width: 759rem;
  }
  .lg:w-760 {
    width: 760rem;
  }
  .lg:w-761 {
    width: 761rem;
  }
  .lg:w-762 {
    width: 762rem;
  }
  .lg:w-763 {
    width: 763rem;
  }
  .lg:w-764 {
    width: 764rem;
  }
  .lg:w-765 {
    width: 765rem;
  }
  .lg:w-766 {
    width: 766rem;
  }
  .lg:w-767 {
    width: 767rem;
  }
  .lg:w-768 {
    width: 768rem;
  }
  .lg:w-769 {
    width: 769rem;
  }
  .lg:w-770 {
    width: 770rem;
  }
  .lg:w-771 {
    width: 771rem;
  }
  .lg:w-772 {
    width: 772rem;
  }
  .lg:w-773 {
    width: 773rem;
  }
  .lg:w-774 {
    width: 774rem;
  }
  .lg:w-775 {
    width: 775rem;
  }
  .lg:w-776 {
    width: 776rem;
  }
  .lg:w-777 {
    width: 777rem;
  }
  .lg:w-778 {
    width: 778rem;
  }
  .lg:w-779 {
    width: 779rem;
  }
  .lg:w-780 {
    width: 780rem;
  }
  .lg:w-781 {
    width: 781rem;
  }
  .lg:w-782 {
    width: 782rem;
  }
  .lg:w-783 {
    width: 783rem;
  }
  .lg:w-784 {
    width: 784rem;
  }
  .lg:w-785 {
    width: 785rem;
  }
  .lg:w-786 {
    width: 786rem;
  }
  .lg:w-787 {
    width: 787rem;
  }
  .lg:w-788 {
    width: 788rem;
  }
  .lg:w-789 {
    width: 789rem;
  }
  .lg:w-790 {
    width: 790rem;
  }
  .lg:w-791 {
    width: 791rem;
  }
  .lg:w-792 {
    width: 792rem;
  }
  .lg:w-793 {
    width: 793rem;
  }
  .lg:w-794 {
    width: 794rem;
  }
  .lg:w-795 {
    width: 795rem;
  }
  .lg:w-796 {
    width: 796rem;
  }
  .lg:w-797 {
    width: 797rem;
  }
  .lg:w-798 {
    width: 798rem;
  }
  .lg:w-799 {
    width: 799rem;
  }
  .lg:w-800 {
    width: 800rem;
  }
  .lg:w-801 {
    width: 801rem;
  }
  .lg:w-802 {
    width: 802rem;
  }
  .lg:w-803 {
    width: 803rem;
  }
  .lg:w-804 {
    width: 804rem;
  }
  .lg:w-805 {
    width: 805rem;
  }
  .lg:w-806 {
    width: 806rem;
  }
  .lg:w-807 {
    width: 807rem;
  }
  .lg:w-808 {
    width: 808rem;
  }
  .lg:w-809 {
    width: 809rem;
  }
  .lg:w-810 {
    width: 810rem;
  }
  .lg:w-811 {
    width: 811rem;
  }
  .lg:w-812 {
    width: 812rem;
  }
  .lg:w-813 {
    width: 813rem;
  }
  .lg:w-814 {
    width: 814rem;
  }
  .lg:w-815 {
    width: 815rem;
  }
  .lg:w-816 {
    width: 816rem;
  }
  .lg:w-817 {
    width: 817rem;
  }
  .lg:w-818 {
    width: 818rem;
  }
  .lg:w-819 {
    width: 819rem;
  }
  .lg:w-820 {
    width: 820rem;
  }
  .lg:w-821 {
    width: 821rem;
  }
  .lg:w-822 {
    width: 822rem;
  }
  .lg:w-823 {
    width: 823rem;
  }
  .lg:w-824 {
    width: 824rem;
  }
  .lg:w-825 {
    width: 825rem;
  }
  .lg:w-826 {
    width: 826rem;
  }
  .lg:w-827 {
    width: 827rem;
  }
  .lg:w-828 {
    width: 828rem;
  }
  .lg:w-829 {
    width: 829rem;
  }
  .lg:w-830 {
    width: 830rem;
  }
  .lg:w-831 {
    width: 831rem;
  }
  .lg:w-832 {
    width: 832rem;
  }
  .lg:w-833 {
    width: 833rem;
  }
  .lg:w-834 {
    width: 834rem;
  }
  .lg:w-835 {
    width: 835rem;
  }
  .lg:w-836 {
    width: 836rem;
  }
  .lg:w-837 {
    width: 837rem;
  }
  .lg:w-838 {
    width: 838rem;
  }
  .lg:w-839 {
    width: 839rem;
  }
  .lg:w-840 {
    width: 840rem;
  }
  .lg:w-841 {
    width: 841rem;
  }
  .lg:w-842 {
    width: 842rem;
  }
  .lg:w-843 {
    width: 843rem;
  }
  .lg:w-844 {
    width: 844rem;
  }
  .lg:w-845 {
    width: 845rem;
  }
  .lg:w-846 {
    width: 846rem;
  }
  .lg:w-847 {
    width: 847rem;
  }
  .lg:w-848 {
    width: 848rem;
  }
  .lg:w-849 {
    width: 849rem;
  }
  .lg:w-850 {
    width: 850rem;
  }
  .lg:w-851 {
    width: 851rem;
  }
  .lg:w-852 {
    width: 852rem;
  }
  .lg:w-853 {
    width: 853rem;
  }
  .lg:w-854 {
    width: 854rem;
  }
  .lg:w-855 {
    width: 855rem;
  }
  .lg:w-856 {
    width: 856rem;
  }
  .lg:w-857 {
    width: 857rem;
  }
  .lg:w-858 {
    width: 858rem;
  }
  .lg:w-859 {
    width: 859rem;
  }
  .lg:w-860 {
    width: 860rem;
  }
  .lg:w-861 {
    width: 861rem;
  }
  .lg:w-862 {
    width: 862rem;
  }
  .lg:w-863 {
    width: 863rem;
  }
  .lg:w-864 {
    width: 864rem;
  }
  .lg:w-865 {
    width: 865rem;
  }
  .lg:w-866 {
    width: 866rem;
  }
  .lg:w-867 {
    width: 867rem;
  }
  .lg:w-868 {
    width: 868rem;
  }
  .lg:w-869 {
    width: 869rem;
  }
  .lg:w-870 {
    width: 870rem;
  }
  .lg:w-871 {
    width: 871rem;
  }
  .lg:w-872 {
    width: 872rem;
  }
  .lg:w-873 {
    width: 873rem;
  }
  .lg:w-874 {
    width: 874rem;
  }
  .lg:w-875 {
    width: 875rem;
  }
  .lg:w-876 {
    width: 876rem;
  }
  .lg:w-877 {
    width: 877rem;
  }
  .lg:w-878 {
    width: 878rem;
  }
  .lg:w-879 {
    width: 879rem;
  }
  .lg:w-880 {
    width: 880rem;
  }
  .lg:w-881 {
    width: 881rem;
  }
  .lg:w-882 {
    width: 882rem;
  }
  .lg:w-883 {
    width: 883rem;
  }
  .lg:w-884 {
    width: 884rem;
  }
  .lg:w-885 {
    width: 885rem;
  }
  .lg:w-886 {
    width: 886rem;
  }
  .lg:w-887 {
    width: 887rem;
  }
  .lg:w-888 {
    width: 888rem;
  }
  .lg:w-889 {
    width: 889rem;
  }
  .lg:w-890 {
    width: 890rem;
  }
  .lg:w-891 {
    width: 891rem;
  }
  .lg:w-892 {
    width: 892rem;
  }
  .lg:w-893 {
    width: 893rem;
  }
  .lg:w-894 {
    width: 894rem;
  }
  .lg:w-895 {
    width: 895rem;
  }
  .lg:w-896 {
    width: 896rem;
  }
  .lg:w-897 {
    width: 897rem;
  }
  .lg:w-898 {
    width: 898rem;
  }
  .lg:w-899 {
    width: 899rem;
  }
  .lg:w-900 {
    width: 900rem;
  }
  .lg:w-901 {
    width: 901rem;
  }
  .lg:w-902 {
    width: 902rem;
  }
  .lg:w-903 {
    width: 903rem;
  }
  .lg:w-904 {
    width: 904rem;
  }
  .lg:w-905 {
    width: 905rem;
  }
  .lg:w-906 {
    width: 906rem;
  }
  .lg:w-907 {
    width: 907rem;
  }
  .lg:w-908 {
    width: 908rem;
  }
  .lg:w-909 {
    width: 909rem;
  }
  .lg:w-910 {
    width: 910rem;
  }
  .lg:w-911 {
    width: 911rem;
  }
  .lg:w-912 {
    width: 912rem;
  }
  .lg:w-913 {
    width: 913rem;
  }
  .lg:w-914 {
    width: 914rem;
  }
  .lg:w-915 {
    width: 915rem;
  }
  .lg:w-916 {
    width: 916rem;
  }
  .lg:w-917 {
    width: 917rem;
  }
  .lg:w-918 {
    width: 918rem;
  }
  .lg:w-919 {
    width: 919rem;
  }
  .lg:w-920 {
    width: 920rem;
  }
  .lg:w-921 {
    width: 921rem;
  }
  .lg:w-922 {
    width: 922rem;
  }
  .lg:w-923 {
    width: 923rem;
  }
  .lg:w-924 {
    width: 924rem;
  }
  .lg:w-925 {
    width: 925rem;
  }
  .lg:w-926 {
    width: 926rem;
  }
  .lg:w-927 {
    width: 927rem;
  }
  .lg:w-928 {
    width: 928rem;
  }
  .lg:w-929 {
    width: 929rem;
  }
  .lg:w-930 {
    width: 930rem;
  }
  .lg:w-931 {
    width: 931rem;
  }
  .lg:w-932 {
    width: 932rem;
  }
  .lg:w-933 {
    width: 933rem;
  }
  .lg:w-934 {
    width: 934rem;
  }
  .lg:w-935 {
    width: 935rem;
  }
  .lg:w-936 {
    width: 936rem;
  }
  .lg:w-937 {
    width: 937rem;
  }
  .lg:w-938 {
    width: 938rem;
  }
  .lg:w-939 {
    width: 939rem;
  }
  .lg:w-940 {
    width: 940rem;
  }
  .lg:w-941 {
    width: 941rem;
  }
  .lg:w-942 {
    width: 942rem;
  }
  .lg:w-943 {
    width: 943rem;
  }
  .lg:w-944 {
    width: 944rem;
  }
  .lg:w-945 {
    width: 945rem;
  }
  .lg:w-946 {
    width: 946rem;
  }
  .lg:w-947 {
    width: 947rem;
  }
  .lg:w-948 {
    width: 948rem;
  }
  .lg:w-949 {
    width: 949rem;
  }
  .lg:w-950 {
    width: 950rem;
  }
  .lg:w-951 {
    width: 951rem;
  }
  .lg:w-952 {
    width: 952rem;
  }
  .lg:w-953 {
    width: 953rem;
  }
  .lg:w-954 {
    width: 954rem;
  }
  .lg:w-955 {
    width: 955rem;
  }
  .lg:w-956 {
    width: 956rem;
  }
  .lg:w-957 {
    width: 957rem;
  }
  .lg:w-958 {
    width: 958rem;
  }
  .lg:w-959 {
    width: 959rem;
  }
  .lg:w-960 {
    width: 960rem;
  }
  .lg:w-961 {
    width: 961rem;
  }
  .lg:w-962 {
    width: 962rem;
  }
  .lg:w-963 {
    width: 963rem;
  }
  .lg:w-964 {
    width: 964rem;
  }
  .lg:w-965 {
    width: 965rem;
  }
  .lg:w-966 {
    width: 966rem;
  }
  .lg:w-967 {
    width: 967rem;
  }
  .lg:w-968 {
    width: 968rem;
  }
  .lg:w-969 {
    width: 969rem;
  }
  .lg:w-970 {
    width: 970rem;
  }
  .lg:w-971 {
    width: 971rem;
  }
  .lg:w-972 {
    width: 972rem;
  }
  .lg:w-973 {
    width: 973rem;
  }
  .lg:w-974 {
    width: 974rem;
  }
  .lg:w-975 {
    width: 975rem;
  }
  .lg:w-976 {
    width: 976rem;
  }
  .lg:w-977 {
    width: 977rem;
  }
  .lg:w-978 {
    width: 978rem;
  }
  .lg:w-979 {
    width: 979rem;
  }
  .lg:w-980 {
    width: 980rem;
  }
  .lg:w-981 {
    width: 981rem;
  }
  .lg:w-982 {
    width: 982rem;
  }
  .lg:w-983 {
    width: 983rem;
  }
  .lg:w-984 {
    width: 984rem;
  }
  .lg:w-985 {
    width: 985rem;
  }
  .lg:w-986 {
    width: 986rem;
  }
  .lg:w-987 {
    width: 987rem;
  }
  .lg:w-988 {
    width: 988rem;
  }
  .lg:w-989 {
    width: 989rem;
  }
  .lg:w-990 {
    width: 990rem;
  }
  .lg:w-991 {
    width: 991rem;
  }
  .lg:w-992 {
    width: 992rem;
  }
  .lg:w-993 {
    width: 993rem;
  }
  .lg:w-994 {
    width: 994rem;
  }
  .lg:w-995 {
    width: 995rem;
  }
  .lg:w-996 {
    width: 996rem;
  }
  .lg:w-997 {
    width: 997rem;
  }
  .lg:w-998 {
    width: 998rem;
  }
  .lg:w-999 {
    width: 999rem;
  }
  .lg:w-1000 {
    width: 1000rem;
  }
  .lg:w-1001 {
    width: 1001rem;
  }
  .lg:w-1002 {
    width: 1002rem;
  }
  .lg:w-1003 {
    width: 1003rem;
  }
  .lg:w-1004 {
    width: 1004rem;
  }
  .lg:w-1005 {
    width: 1005rem;
  }
  .lg:w-1006 {
    width: 1006rem;
  }
  .lg:w-1007 {
    width: 1007rem;
  }
  .lg:w-1008 {
    width: 1008rem;
  }
  .lg:w-1009 {
    width: 1009rem;
  }
  .lg:w-1010 {
    width: 1010rem;
  }
  .lg:w-1011 {
    width: 1011rem;
  }
  .lg:w-1012 {
    width: 1012rem;
  }
  .lg:w-1013 {
    width: 1013rem;
  }
  .lg:w-1014 {
    width: 1014rem;
  }
  .lg:w-1015 {
    width: 1015rem;
  }
  .lg:w-1016 {
    width: 1016rem;
  }
  .lg:w-1017 {
    width: 1017rem;
  }
  .lg:w-1018 {
    width: 1018rem;
  }
  .lg:w-1019 {
    width: 1019rem;
  }
  .lg:w-1020 {
    width: 1020rem;
  }
  .lg:w-1021 {
    width: 1021rem;
  }
  .lg:w-1022 {
    width: 1022rem;
  }
  .lg:w-1023 {
    width: 1023rem;
  }
  .lg:w-1024 {
    width: 1024rem;
  }
  .lg:w-1025 {
    width: 1025rem;
  }
  .lg:w-1026 {
    width: 1026rem;
  }
  .lg:w-1027 {
    width: 1027rem;
  }
  .lg:w-1028 {
    width: 1028rem;
  }
  .lg:w-1029 {
    width: 1029rem;
  }
  .lg:w-1030 {
    width: 1030rem;
  }
  .lg:w-1031 {
    width: 1031rem;
  }
  .lg:w-1032 {
    width: 1032rem;
  }
  .lg:w-1033 {
    width: 1033rem;
  }
  .lg:w-1034 {
    width: 1034rem;
  }
  .lg:w-1035 {
    width: 1035rem;
  }
  .lg:w-1036 {
    width: 1036rem;
  }
  .lg:w-1037 {
    width: 1037rem;
  }
  .lg:w-1038 {
    width: 1038rem;
  }
  .lg:w-1039 {
    width: 1039rem;
  }
  .lg:w-1040 {
    width: 1040rem;
  }
  .lg:w-1041 {
    width: 1041rem;
  }
  .lg:w-1042 {
    width: 1042rem;
  }
  .lg:w-1043 {
    width: 1043rem;
  }
  .lg:w-1044 {
    width: 1044rem;
  }
  .lg:w-1045 {
    width: 1045rem;
  }
  .lg:w-1046 {
    width: 1046rem;
  }
  .lg:w-1047 {
    width: 1047rem;
  }
  .lg:w-1048 {
    width: 1048rem;
  }
  .lg:w-1049 {
    width: 1049rem;
  }
  .lg:w-1050 {
    width: 1050rem;
  }
  .lg:w-1051 {
    width: 1051rem;
  }
  .lg:w-1052 {
    width: 1052rem;
  }
  .lg:w-1053 {
    width: 1053rem;
  }
  .lg:w-1054 {
    width: 1054rem;
  }
  .lg:w-1055 {
    width: 1055rem;
  }
  .lg:w-1056 {
    width: 1056rem;
  }
  .lg:w-1057 {
    width: 1057rem;
  }
  .lg:w-1058 {
    width: 1058rem;
  }
  .lg:w-1059 {
    width: 1059rem;
  }
  .lg:w-1060 {
    width: 1060rem;
  }
  .lg:w-1061 {
    width: 1061rem;
  }
  .lg:w-1062 {
    width: 1062rem;
  }
  .lg:w-1063 {
    width: 1063rem;
  }
  .lg:w-1064 {
    width: 1064rem;
  }
  .lg:w-1065 {
    width: 1065rem;
  }
  .lg:w-1066 {
    width: 1066rem;
  }
  .lg:w-1067 {
    width: 1067rem;
  }
  .lg:w-1068 {
    width: 1068rem;
  }
  .lg:w-1069 {
    width: 1069rem;
  }
  .lg:w-1070 {
    width: 1070rem;
  }
  .lg:w-1071 {
    width: 1071rem;
  }
  .lg:w-1072 {
    width: 1072rem;
  }
  .lg:w-1073 {
    width: 1073rem;
  }
  .lg:w-1074 {
    width: 1074rem;
  }
  .lg:w-1075 {
    width: 1075rem;
  }
  .lg:w-1076 {
    width: 1076rem;
  }
  .lg:w-1077 {
    width: 1077rem;
  }
  .lg:w-1078 {
    width: 1078rem;
  }
  .lg:w-1079 {
    width: 1079rem;
  }
  .lg:w-1080 {
    width: 1080rem;
  }
  .lg:w-1081 {
    width: 1081rem;
  }
  .lg:w-1082 {
    width: 1082rem;
  }
  .lg:w-1083 {
    width: 1083rem;
  }
  .lg:w-1084 {
    width: 1084rem;
  }
  .lg:w-1085 {
    width: 1085rem;
  }
  .lg:w-1086 {
    width: 1086rem;
  }
  .lg:w-1087 {
    width: 1087rem;
  }
  .lg:w-1088 {
    width: 1088rem;
  }
  .lg:w-1089 {
    width: 1089rem;
  }
  .lg:w-1090 {
    width: 1090rem;
  }
  .lg:w-1091 {
    width: 1091rem;
  }
  .lg:w-1092 {
    width: 1092rem;
  }
  .lg:w-1093 {
    width: 1093rem;
  }
  .lg:w-1094 {
    width: 1094rem;
  }
  .lg:w-1095 {
    width: 1095rem;
  }
  .lg:w-1096 {
    width: 1096rem;
  }
  .lg:w-1097 {
    width: 1097rem;
  }
  .lg:w-1098 {
    width: 1098rem;
  }
  .lg:w-1099 {
    width: 1099rem;
  }
  .lg:w-1100 {
    width: 1100rem;
  }
  .lg:w-1101 {
    width: 1101rem;
  }
  .lg:w-1102 {
    width: 1102rem;
  }
  .lg:w-1103 {
    width: 1103rem;
  }
  .lg:w-1104 {
    width: 1104rem;
  }
  .lg:w-1105 {
    width: 1105rem;
  }
  .lg:w-1106 {
    width: 1106rem;
  }
  .lg:w-1107 {
    width: 1107rem;
  }
  .lg:w-1108 {
    width: 1108rem;
  }
  .lg:w-1109 {
    width: 1109rem;
  }
  .lg:w-1110 {
    width: 1110rem;
  }
  .lg:w-1111 {
    width: 1111rem;
  }
  .lg:w-1112 {
    width: 1112rem;
  }
  .lg:w-1113 {
    width: 1113rem;
  }
  .lg:w-1114 {
    width: 1114rem;
  }
  .lg:w-1115 {
    width: 1115rem;
  }
  .lg:w-1116 {
    width: 1116rem;
  }
  .lg:w-1117 {
    width: 1117rem;
  }
  .lg:w-1118 {
    width: 1118rem;
  }
  .lg:w-1119 {
    width: 1119rem;
  }
  .lg:w-1120 {
    width: 1120rem;
  }
  .lg:w-1121 {
    width: 1121rem;
  }
  .lg:w-1122 {
    width: 1122rem;
  }
  .lg:w-1123 {
    width: 1123rem;
  }
  .lg:w-1124 {
    width: 1124rem;
  }
  .lg:w-1125 {
    width: 1125rem;
  }
  .lg:w-1126 {
    width: 1126rem;
  }
  .lg:w-1127 {
    width: 1127rem;
  }
  .lg:w-1128 {
    width: 1128rem;
  }
  .lg:w-1129 {
    width: 1129rem;
  }
  .lg:w-1130 {
    width: 1130rem;
  }
  .lg:w-1131 {
    width: 1131rem;
  }
  .lg:w-1132 {
    width: 1132rem;
  }
  .lg:w-1133 {
    width: 1133rem;
  }
  .lg:w-1134 {
    width: 1134rem;
  }
  .lg:w-1135 {
    width: 1135rem;
  }
  .lg:w-1136 {
    width: 1136rem;
  }
  .lg:w-1137 {
    width: 1137rem;
  }
  .lg:w-1138 {
    width: 1138rem;
  }
  .lg:w-1139 {
    width: 1139rem;
  }
  .lg:w-1140 {
    width: 1140rem;
  }
  .lg:w-1141 {
    width: 1141rem;
  }
  .lg:w-1142 {
    width: 1142rem;
  }
  .lg:w-1143 {
    width: 1143rem;
  }
  .lg:w-1144 {
    width: 1144rem;
  }
  .lg:w-1145 {
    width: 1145rem;
  }
  .lg:w-1146 {
    width: 1146rem;
  }
  .lg:w-1147 {
    width: 1147rem;
  }
  .lg:w-1148 {
    width: 1148rem;
  }
  .lg:w-1149 {
    width: 1149rem;
  }
  .lg:w-1150 {
    width: 1150rem;
  }
  .lg:w-1151 {
    width: 1151rem;
  }
  .lg:w-1152 {
    width: 1152rem;
  }
  .lg:w-1153 {
    width: 1153rem;
  }
  .lg:w-1154 {
    width: 1154rem;
  }
  .lg:w-1155 {
    width: 1155rem;
  }
  .lg:w-1156 {
    width: 1156rem;
  }
  .lg:w-1157 {
    width: 1157rem;
  }
  .lg:w-1158 {
    width: 1158rem;
  }
  .lg:w-1159 {
    width: 1159rem;
  }
  .lg:w-1160 {
    width: 1160rem;
  }
  .lg:w-1161 {
    width: 1161rem;
  }
  .lg:w-1162 {
    width: 1162rem;
  }
  .lg:w-1163 {
    width: 1163rem;
  }
  .lg:w-1164 {
    width: 1164rem;
  }
  .lg:w-1165 {
    width: 1165rem;
  }
  .lg:w-1166 {
    width: 1166rem;
  }
  .lg:w-1167 {
    width: 1167rem;
  }
  .lg:w-1168 {
    width: 1168rem;
  }
  .lg:w-1169 {
    width: 1169rem;
  }
  .lg:w-1170 {
    width: 1170rem;
  }
  .lg:w-1171 {
    width: 1171rem;
  }
  .lg:w-1172 {
    width: 1172rem;
  }
  .lg:w-1173 {
    width: 1173rem;
  }
  .lg:w-1174 {
    width: 1174rem;
  }
  .lg:w-1175 {
    width: 1175rem;
  }
  .lg:w-1176 {
    width: 1176rem;
  }
  .lg:w-1177 {
    width: 1177rem;
  }
  .lg:w-1178 {
    width: 1178rem;
  }
  .lg:w-1179 {
    width: 1179rem;
  }
  .lg:w-1180 {
    width: 1180rem;
  }
  .lg:w-1181 {
    width: 1181rem;
  }
  .lg:w-1182 {
    width: 1182rem;
  }
  .lg:w-1183 {
    width: 1183rem;
  }
  .lg:w-1184 {
    width: 1184rem;
  }
  .lg:w-1185 {
    width: 1185rem;
  }
  .lg:w-1186 {
    width: 1186rem;
  }
  .lg:w-1187 {
    width: 1187rem;
  }
  .lg:w-1188 {
    width: 1188rem;
  }
  .lg:w-1189 {
    width: 1189rem;
  }
  .lg:w-1190 {
    width: 1190rem;
  }
  .lg:w-1191 {
    width: 1191rem;
  }
  .lg:w-1192 {
    width: 1192rem;
  }
  .lg:w-1193 {
    width: 1193rem;
  }
  .lg:w-1194 {
    width: 1194rem;
  }
  .lg:w-1195 {
    width: 1195rem;
  }
  .lg:w-1196 {
    width: 1196rem;
  }
  .lg:w-1197 {
    width: 1197rem;
  }
  .lg:w-1198 {
    width: 1198rem;
  }
  .lg:w-1199 {
    width: 1199rem;
  }
  .lg:w-1200 {
    width: 1200rem;
  }
  .lg:w-1201 {
    width: 1201rem;
  }
  .lg:w-1202 {
    width: 1202rem;
  }
  .lg:w-1203 {
    width: 1203rem;
  }
  .lg:w-1204 {
    width: 1204rem;
  }
  .lg:w-1205 {
    width: 1205rem;
  }
  .lg:w-1206 {
    width: 1206rem;
  }
  .lg:w-1207 {
    width: 1207rem;
  }
  .lg:w-1208 {
    width: 1208rem;
  }
  .lg:w-1209 {
    width: 1209rem;
  }
  .lg:w-1210 {
    width: 1210rem;
  }
  .lg:w-1211 {
    width: 1211rem;
  }
  .lg:w-1212 {
    width: 1212rem;
  }
  .lg:w-1213 {
    width: 1213rem;
  }
  .lg:w-1214 {
    width: 1214rem;
  }
  .lg:w-1215 {
    width: 1215rem;
  }
  .lg:w-1216 {
    width: 1216rem;
  }
  .lg:w-1217 {
    width: 1217rem;
  }
  .lg:w-1218 {
    width: 1218rem;
  }
  .lg:w-1219 {
    width: 1219rem;
  }
  .lg:w-1220 {
    width: 1220rem;
  }
  .lg:w-1221 {
    width: 1221rem;
  }
  .lg:w-1222 {
    width: 1222rem;
  }
  .lg:w-1223 {
    width: 1223rem;
  }
  .lg:w-1224 {
    width: 1224rem;
  }
  .lg:w-1225 {
    width: 1225rem;
  }
  .lg:w-1226 {
    width: 1226rem;
  }
  .lg:w-1227 {
    width: 1227rem;
  }
  .lg:w-1228 {
    width: 1228rem;
  }
  .lg:w-1229 {
    width: 1229rem;
  }
  .lg:w-1230 {
    width: 1230rem;
  }
  .lg:w-1231 {
    width: 1231rem;
  }
  .lg:w-1232 {
    width: 1232rem;
  }
  .lg:w-1233 {
    width: 1233rem;
  }
  .lg:w-1234 {
    width: 1234rem;
  }
  .lg:w-1235 {
    width: 1235rem;
  }
  .lg:w-1236 {
    width: 1236rem;
  }
  .lg:w-1237 {
    width: 1237rem;
  }
  .lg:w-1238 {
    width: 1238rem;
  }
  .lg:w-1239 {
    width: 1239rem;
  }
  .lg:w-1240 {
    width: 1240rem;
  }
  .lg:w-1241 {
    width: 1241rem;
  }
  .lg:w-1242 {
    width: 1242rem;
  }
  .lg:w-1243 {
    width: 1243rem;
  }
  .lg:w-1244 {
    width: 1244rem;
  }
  .lg:w-1245 {
    width: 1245rem;
  }
  .lg:w-1246 {
    width: 1246rem;
  }
  .lg:w-1247 {
    width: 1247rem;
  }
  .lg:w-1248 {
    width: 1248rem;
  }
  .lg:w-1249 {
    width: 1249rem;
  }
  .lg:w-1250 {
    width: 1250rem;
  }
  .lg:w-1251 {
    width: 1251rem;
  }
  .lg:w-1252 {
    width: 1252rem;
  }
  .lg:w-1253 {
    width: 1253rem;
  }
  .lg:w-1254 {
    width: 1254rem;
  }
  .lg:w-1255 {
    width: 1255rem;
  }
  .lg:w-1256 {
    width: 1256rem;
  }
  .lg:w-1257 {
    width: 1257rem;
  }
  .lg:w-1258 {
    width: 1258rem;
  }
  .lg:w-1259 {
    width: 1259rem;
  }
  .lg:w-1260 {
    width: 1260rem;
  }
  .lg:w-1261 {
    width: 1261rem;
  }
  .lg:w-1262 {
    width: 1262rem;
  }
  .lg:w-1263 {
    width: 1263rem;
  }
  .lg:w-1264 {
    width: 1264rem;
  }
  .lg:w-1265 {
    width: 1265rem;
  }
  .lg:w-1266 {
    width: 1266rem;
  }
  .lg:w-1267 {
    width: 1267rem;
  }
  .lg:w-1268 {
    width: 1268rem;
  }
  .lg:w-1269 {
    width: 1269rem;
  }
  .lg:w-1270 {
    width: 1270rem;
  }
  .lg:w-1271 {
    width: 1271rem;
  }
  .lg:w-1272 {
    width: 1272rem;
  }
  .lg:w-1273 {
    width: 1273rem;
  }
  .lg:w-1274 {
    width: 1274rem;
  }
  .lg:w-1275 {
    width: 1275rem;
  }
  .lg:w-1276 {
    width: 1276rem;
  }
  .lg:w-1277 {
    width: 1277rem;
  }
  .lg:w-1278 {
    width: 1278rem;
  }
  .lg:w-1279 {
    width: 1279rem;
  }
  .lg:w-1280 {
    width: 1280rem;
  }
  .lg:w-1281 {
    width: 1281rem;
  }
  .lg:w-1282 {
    width: 1282rem;
  }
  .lg:w-1283 {
    width: 1283rem;
  }
  .lg:w-1284 {
    width: 1284rem;
  }
  .lg:w-1285 {
    width: 1285rem;
  }
  .lg:w-1286 {
    width: 1286rem;
  }
  .lg:w-1287 {
    width: 1287rem;
  }
  .lg:w-1288 {
    width: 1288rem;
  }
  .lg:w-1289 {
    width: 1289rem;
  }
  .lg:w-1290 {
    width: 1290rem;
  }
  .lg:w-1291 {
    width: 1291rem;
  }
  .lg:w-1292 {
    width: 1292rem;
  }
  .lg:w-1293 {
    width: 1293rem;
  }
  .lg:w-1294 {
    width: 1294rem;
  }
  .lg:w-1295 {
    width: 1295rem;
  }
  .lg:w-1296 {
    width: 1296rem;
  }
  .lg:w-1297 {
    width: 1297rem;
  }
  .lg:w-1298 {
    width: 1298rem;
  }
  .lg:w-1299 {
    width: 1299rem;
  }
  .lg:w-1300 {
    width: 1300rem;
  }
  .lg:w-1301 {
    width: 1301rem;
  }
  .lg:w-1302 {
    width: 1302rem;
  }
  .lg:w-1303 {
    width: 1303rem;
  }
  .lg:w-1304 {
    width: 1304rem;
  }
  .lg:w-1305 {
    width: 1305rem;
  }
  .lg:w-1306 {
    width: 1306rem;
  }
  .lg:w-1307 {
    width: 1307rem;
  }
  .lg:w-1308 {
    width: 1308rem;
  }
  .lg:w-1309 {
    width: 1309rem;
  }
  .lg:w-1310 {
    width: 1310rem;
  }
  .lg:w-1311 {
    width: 1311rem;
  }
  .lg:w-1312 {
    width: 1312rem;
  }
  .lg:w-1313 {
    width: 1313rem;
  }
  .lg:w-1314 {
    width: 1314rem;
  }
  .lg:w-1315 {
    width: 1315rem;
  }
  .lg:w-1316 {
    width: 1316rem;
  }
  .lg:w-1317 {
    width: 1317rem;
  }
  .lg:w-1318 {
    width: 1318rem;
  }
  .lg:w-1319 {
    width: 1319rem;
  }
  .lg:w-1320 {
    width: 1320rem;
  }
  .lg:w-1321 {
    width: 1321rem;
  }
  .lg:w-1322 {
    width: 1322rem;
  }
  .lg:w-1323 {
    width: 1323rem;
  }
  .lg:w-1324 {
    width: 1324rem;
  }
  .lg:w-1325 {
    width: 1325rem;
  }
  .lg:w-1326 {
    width: 1326rem;
  }
  .lg:w-1327 {
    width: 1327rem;
  }
  .lg:w-1328 {
    width: 1328rem;
  }
  .lg:w-1329 {
    width: 1329rem;
  }
  .lg:w-1330 {
    width: 1330rem;
  }
  .lg:w-1331 {
    width: 1331rem;
  }
  .lg:w-1332 {
    width: 1332rem;
  }
  .lg:w-1333 {
    width: 1333rem;
  }
  .lg:w-1334 {
    width: 1334rem;
  }
  .lg:w-1335 {
    width: 1335rem;
  }
  .lg:w-1336 {
    width: 1336rem;
  }
  .lg:w-1337 {
    width: 1337rem;
  }
  .lg:w-1338 {
    width: 1338rem;
  }
  .lg:w-1339 {
    width: 1339rem;
  }
  .lg:w-1340 {
    width: 1340rem;
  }
  .lg:w-1341 {
    width: 1341rem;
  }
  .lg:w-1342 {
    width: 1342rem;
  }
  .lg:w-1343 {
    width: 1343rem;
  }
  .lg:w-1344 {
    width: 1344rem;
  }
  .lg:w-1345 {
    width: 1345rem;
  }
  .lg:w-1346 {
    width: 1346rem;
  }
  .lg:w-1347 {
    width: 1347rem;
  }
  .lg:w-1348 {
    width: 1348rem;
  }
  .lg:w-1349 {
    width: 1349rem;
  }
  .lg:w-1350 {
    width: 1350rem;
  }
  .lg:w-1351 {
    width: 1351rem;
  }
  .lg:w-1352 {
    width: 1352rem;
  }
  .lg:w-1353 {
    width: 1353rem;
  }
  .lg:w-1354 {
    width: 1354rem;
  }
  .lg:w-1355 {
    width: 1355rem;
  }
  .lg:w-1356 {
    width: 1356rem;
  }
  .lg:w-1357 {
    width: 1357rem;
  }
  .lg:w-1358 {
    width: 1358rem;
  }
  .lg:w-1359 {
    width: 1359rem;
  }
  .lg:w-1360 {
    width: 1360rem;
  }
  .lg:w-1361 {
    width: 1361rem;
  }
  .lg:w-1362 {
    width: 1362rem;
  }
  .lg:w-1363 {
    width: 1363rem;
  }
  .lg:w-1364 {
    width: 1364rem;
  }
  .lg:w-1365 {
    width: 1365rem;
  }
  .lg:w-1366 {
    width: 1366rem;
  }
  .lg:w-1367 {
    width: 1367rem;
  }
  .lg:w-1368 {
    width: 1368rem;
  }
  .lg:w-1369 {
    width: 1369rem;
  }
  .lg:w-1370 {
    width: 1370rem;
  }
  .lg:w-1371 {
    width: 1371rem;
  }
  .lg:w-1372 {
    width: 1372rem;
  }
  .lg:w-1373 {
    width: 1373rem;
  }
  .lg:w-1374 {
    width: 1374rem;
  }
  .lg:w-1375 {
    width: 1375rem;
  }
  .lg:w-1376 {
    width: 1376rem;
  }
  .lg:w-1377 {
    width: 1377rem;
  }
  .lg:w-1378 {
    width: 1378rem;
  }
  .lg:w-1379 {
    width: 1379rem;
  }
  .lg:w-1380 {
    width: 1380rem;
  }
  .lg:w-1381 {
    width: 1381rem;
  }
  .lg:w-1382 {
    width: 1382rem;
  }
  .lg:w-1383 {
    width: 1383rem;
  }
  .lg:w-1384 {
    width: 1384rem;
  }
  .lg:w-1385 {
    width: 1385rem;
  }
  .lg:w-1386 {
    width: 1386rem;
  }
  .lg:w-1387 {
    width: 1387rem;
  }
  .lg:w-1388 {
    width: 1388rem;
  }
  .lg:w-1389 {
    width: 1389rem;
  }
  .lg:w-1390 {
    width: 1390rem;
  }
  .lg:w-1391 {
    width: 1391rem;
  }
  .lg:w-1392 {
    width: 1392rem;
  }
  .lg:w-1393 {
    width: 1393rem;
  }
  .lg:w-1394 {
    width: 1394rem;
  }
  .lg:w-1395 {
    width: 1395rem;
  }
  .lg:w-1396 {
    width: 1396rem;
  }
  .lg:w-1397 {
    width: 1397rem;
  }
  .lg:w-1398 {
    width: 1398rem;
  }
  .lg:w-1399 {
    width: 1399rem;
  }
  .lg:w-1400 {
    width: 1400rem;
  }
  .lg:w-1401 {
    width: 1401rem;
  }
  .lg:w-1402 {
    width: 1402rem;
  }
  .lg:w-1403 {
    width: 1403rem;
  }
  .lg:w-1404 {
    width: 1404rem;
  }
  .lg:w-1405 {
    width: 1405rem;
  }
  .lg:w-1406 {
    width: 1406rem;
  }
  .lg:w-1407 {
    width: 1407rem;
  }
  .lg:w-1408 {
    width: 1408rem;
  }
  .lg:w-1409 {
    width: 1409rem;
  }
  .lg:w-1410 {
    width: 1410rem;
  }
  .lg:w-1411 {
    width: 1411rem;
  }
  .lg:w-1412 {
    width: 1412rem;
  }
  .lg:w-1413 {
    width: 1413rem;
  }
  .lg:w-1414 {
    width: 1414rem;
  }
  .lg:w-1415 {
    width: 1415rem;
  }
  .lg:w-1416 {
    width: 1416rem;
  }
  .lg:w-1417 {
    width: 1417rem;
  }
  .lg:w-1418 {
    width: 1418rem;
  }
  .lg:w-1419 {
    width: 1419rem;
  }
  .lg:w-1420 {
    width: 1420rem;
  }
  .lg:w-1421 {
    width: 1421rem;
  }
  .lg:w-1422 {
    width: 1422rem;
  }
  .lg:w-1423 {
    width: 1423rem;
  }
  .lg:w-1424 {
    width: 1424rem;
  }
  .lg:w-1425 {
    width: 1425rem;
  }
  .lg:w-1426 {
    width: 1426rem;
  }
  .lg:w-1427 {
    width: 1427rem;
  }
  .lg:w-1428 {
    width: 1428rem;
  }
  .lg:w-1429 {
    width: 1429rem;
  }
  .lg:w-1430 {
    width: 1430rem;
  }
  .lg:w-1431 {
    width: 1431rem;
  }
  .lg:w-1432 {
    width: 1432rem;
  }
  .lg:w-1433 {
    width: 1433rem;
  }
  .lg:w-1434 {
    width: 1434rem;
  }
  .lg:w-1435 {
    width: 1435rem;
  }
  .lg:w-1436 {
    width: 1436rem;
  }
  .lg:w-1437 {
    width: 1437rem;
  }
  .lg:w-1438 {
    width: 1438rem;
  }
  .lg:w-1439 {
    width: 1439rem;
  }
  .lg:w-1440 {
    width: 1440rem;
  }
  .lg:w-1441 {
    width: 1441rem;
  }
  .lg:w-1442 {
    width: 1442rem;
  }
  .lg:w-1443 {
    width: 1443rem;
  }
  .lg:w-1444 {
    width: 1444rem;
  }
  .lg:w-1445 {
    width: 1445rem;
  }
  .lg:w-1446 {
    width: 1446rem;
  }
  .lg:w-1447 {
    width: 1447rem;
  }
  .lg:w-1448 {
    width: 1448rem;
  }
  .lg:w-1449 {
    width: 1449rem;
  }
  .lg:w-1450 {
    width: 1450rem;
  }
  .lg:w-1451 {
    width: 1451rem;
  }
  .lg:w-1452 {
    width: 1452rem;
  }
  .lg:w-1453 {
    width: 1453rem;
  }
  .lg:w-1454 {
    width: 1454rem;
  }
  .lg:w-1455 {
    width: 1455rem;
  }
  .lg:w-1456 {
    width: 1456rem;
  }
  .lg:w-1457 {
    width: 1457rem;
  }
  .lg:w-1458 {
    width: 1458rem;
  }
  .lg:w-1459 {
    width: 1459rem;
  }
  .lg:w-1460 {
    width: 1460rem;
  }
  .lg:w-1461 {
    width: 1461rem;
  }
  .lg:w-1462 {
    width: 1462rem;
  }
  .lg:w-1463 {
    width: 1463rem;
  }
  .lg:w-1464 {
    width: 1464rem;
  }
  .lg:w-1465 {
    width: 1465rem;
  }
  .lg:w-1466 {
    width: 1466rem;
  }
  .lg:w-1467 {
    width: 1467rem;
  }
  .lg:w-1468 {
    width: 1468rem;
  }
  .lg:w-1469 {
    width: 1469rem;
  }
  .lg:w-1470 {
    width: 1470rem;
  }
  .lg:w-1471 {
    width: 1471rem;
  }
  .lg:w-1472 {
    width: 1472rem;
  }
  .lg:w-1473 {
    width: 1473rem;
  }
  .lg:w-1474 {
    width: 1474rem;
  }
  .lg:w-1475 {
    width: 1475rem;
  }
  .lg:w-1476 {
    width: 1476rem;
  }
  .lg:w-1477 {
    width: 1477rem;
  }
  .lg:w-1478 {
    width: 1478rem;
  }
  .lg:w-1479 {
    width: 1479rem;
  }
  .lg:w-1480 {
    width: 1480rem;
  }
  .lg:w-1481 {
    width: 1481rem;
  }
  .lg:w-1482 {
    width: 1482rem;
  }
  .lg:w-1483 {
    width: 1483rem;
  }
  .lg:w-1484 {
    width: 1484rem;
  }
  .lg:w-1485 {
    width: 1485rem;
  }
  .lg:w-1486 {
    width: 1486rem;
  }
  .lg:w-1487 {
    width: 1487rem;
  }
  .lg:w-1488 {
    width: 1488rem;
  }
  .lg:w-1489 {
    width: 1489rem;
  }
  .lg:w-1490 {
    width: 1490rem;
  }
  .lg:w-1491 {
    width: 1491rem;
  }
  .lg:w-1492 {
    width: 1492rem;
  }
  .lg:w-1493 {
    width: 1493rem;
  }
  .lg:w-1494 {
    width: 1494rem;
  }
  .lg:w-1495 {
    width: 1495rem;
  }
  .lg:w-1496 {
    width: 1496rem;
  }
  .lg:w-1497 {
    width: 1497rem;
  }
  .lg:w-1498 {
    width: 1498rem;
  }
  .lg:w-1499 {
    width: 1499rem;
  }
  .lg:w-1500 {
    width: 1500rem;
  }
  .lg:w-1501 {
    width: 1501rem;
  }
  .lg:w-1502 {
    width: 1502rem;
  }
  .lg:w-1503 {
    width: 1503rem;
  }
  .lg:w-1504 {
    width: 1504rem;
  }
  .lg:w-1505 {
    width: 1505rem;
  }
  .lg:w-1506 {
    width: 1506rem;
  }
  .lg:w-1507 {
    width: 1507rem;
  }
  .lg:w-1508 {
    width: 1508rem;
  }
  .lg:w-1509 {
    width: 1509rem;
  }
  .lg:w-1510 {
    width: 1510rem;
  }
  .lg:w-1511 {
    width: 1511rem;
  }
  .lg:w-1512 {
    width: 1512rem;
  }
  .lg:w-1513 {
    width: 1513rem;
  }
  .lg:w-1514 {
    width: 1514rem;
  }
  .lg:w-1515 {
    width: 1515rem;
  }
  .lg:w-1516 {
    width: 1516rem;
  }
  .lg:w-1517 {
    width: 1517rem;
  }
  .lg:w-1518 {
    width: 1518rem;
  }
  .lg:w-1519 {
    width: 1519rem;
  }
  .lg:w-1520 {
    width: 1520rem;
  }
  .lg:w-1521 {
    width: 1521rem;
  }
  .lg:w-1522 {
    width: 1522rem;
  }
  .lg:w-1523 {
    width: 1523rem;
  }
  .lg:w-1524 {
    width: 1524rem;
  }
  .lg:w-1525 {
    width: 1525rem;
  }
  .lg:w-1526 {
    width: 1526rem;
  }
  .lg:w-1527 {
    width: 1527rem;
  }
  .lg:w-1528 {
    width: 1528rem;
  }
  .lg:w-1529 {
    width: 1529rem;
  }
  .lg:w-1530 {
    width: 1530rem;
  }
  .lg:w-1531 {
    width: 1531rem;
  }
  .lg:w-1532 {
    width: 1532rem;
  }
  .lg:w-1533 {
    width: 1533rem;
  }
  .lg:w-1534 {
    width: 1534rem;
  }
  .lg:w-1535 {
    width: 1535rem;
  }
  .lg:w-1536 {
    width: 1536rem;
  }
  .lg:w-1537 {
    width: 1537rem;
  }
  .lg:w-1538 {
    width: 1538rem;
  }
  .lg:w-1539 {
    width: 1539rem;
  }
  .lg:w-1540 {
    width: 1540rem;
  }
  .lg:w-1541 {
    width: 1541rem;
  }
  .lg:w-1542 {
    width: 1542rem;
  }
  .lg:w-1543 {
    width: 1543rem;
  }
  .lg:w-1544 {
    width: 1544rem;
  }
  .lg:w-1545 {
    width: 1545rem;
  }
  .lg:w-1546 {
    width: 1546rem;
  }
  .lg:w-1547 {
    width: 1547rem;
  }
  .lg:w-1548 {
    width: 1548rem;
  }
  .lg:w-1549 {
    width: 1549rem;
  }
  .lg:w-1550 {
    width: 1550rem;
  }
  .lg:w-1551 {
    width: 1551rem;
  }
  .lg:w-1552 {
    width: 1552rem;
  }
  .lg:w-1553 {
    width: 1553rem;
  }
  .lg:w-1554 {
    width: 1554rem;
  }
  .lg:w-1555 {
    width: 1555rem;
  }
  .lg:w-1556 {
    width: 1556rem;
  }
  .lg:w-1557 {
    width: 1557rem;
  }
  .lg:w-1558 {
    width: 1558rem;
  }
  .lg:w-1559 {
    width: 1559rem;
  }
  .lg:w-1560 {
    width: 1560rem;
  }
  .lg:w-1561 {
    width: 1561rem;
  }
  .lg:w-1562 {
    width: 1562rem;
  }
  .lg:w-1563 {
    width: 1563rem;
  }
  .lg:w-1564 {
    width: 1564rem;
  }
  .lg:w-1565 {
    width: 1565rem;
  }
  .lg:w-1566 {
    width: 1566rem;
  }
  .lg:w-1567 {
    width: 1567rem;
  }
  .lg:w-1568 {
    width: 1568rem;
  }
  .lg:w-1569 {
    width: 1569rem;
  }
  .lg:w-1570 {
    width: 1570rem;
  }
  .lg:w-1571 {
    width: 1571rem;
  }
  .lg:w-1572 {
    width: 1572rem;
  }
  .lg:w-1573 {
    width: 1573rem;
  }
  .lg:w-1574 {
    width: 1574rem;
  }
  .lg:w-1575 {
    width: 1575rem;
  }
  .lg:w-1576 {
    width: 1576rem;
  }
  .lg:w-1577 {
    width: 1577rem;
  }
  .lg:w-1578 {
    width: 1578rem;
  }
  .lg:w-1579 {
    width: 1579rem;
  }
  .lg:w-1580 {
    width: 1580rem;
  }
  .lg:w-1581 {
    width: 1581rem;
  }
  .lg:w-1582 {
    width: 1582rem;
  }
  .lg:w-1583 {
    width: 1583rem;
  }
  .lg:w-1584 {
    width: 1584rem;
  }
  .lg:w-1585 {
    width: 1585rem;
  }
  .lg:w-1586 {
    width: 1586rem;
  }
  .lg:w-1587 {
    width: 1587rem;
  }
  .lg:w-1588 {
    width: 1588rem;
  }
  .lg:w-1589 {
    width: 1589rem;
  }
  .lg:w-1590 {
    width: 1590rem;
  }
  .lg:w-1591 {
    width: 1591rem;
  }
  .lg:w-1592 {
    width: 1592rem;
  }
  .lg:w-1593 {
    width: 1593rem;
  }
  .lg:w-1594 {
    width: 1594rem;
  }
  .lg:w-1595 {
    width: 1595rem;
  }
  .lg:w-1596 {
    width: 1596rem;
  }
  .lg:w-1597 {
    width: 1597rem;
  }
  .lg:w-1598 {
    width: 1598rem;
  }
  .lg:w-1599 {
    width: 1599rem;
  }
  .lg:w-1600 {
    width: 1600rem;
  }
  .lg:w-1601 {
    width: 1601rem;
  }
  .lg:w-1602 {
    width: 1602rem;
  }
  .lg:w-1603 {
    width: 1603rem;
  }
  .lg:w-1604 {
    width: 1604rem;
  }
  .lg:w-1605 {
    width: 1605rem;
  }
  .lg:w-1606 {
    width: 1606rem;
  }
  .lg:w-1607 {
    width: 1607rem;
  }
  .lg:w-1608 {
    width: 1608rem;
  }
  .lg:w-1609 {
    width: 1609rem;
  }
  .lg:w-1610 {
    width: 1610rem;
  }
  .lg:w-1611 {
    width: 1611rem;
  }
  .lg:w-1612 {
    width: 1612rem;
  }
  .lg:w-1613 {
    width: 1613rem;
  }
  .lg:w-1614 {
    width: 1614rem;
  }
  .lg:w-1615 {
    width: 1615rem;
  }
  .lg:w-1616 {
    width: 1616rem;
  }
  .lg:w-1617 {
    width: 1617rem;
  }
  .lg:w-1618 {
    width: 1618rem;
  }
  .lg:w-1619 {
    width: 1619rem;
  }
  .lg:w-1620 {
    width: 1620rem;
  }
  .lg:w-1621 {
    width: 1621rem;
  }
  .lg:w-1622 {
    width: 1622rem;
  }
  .lg:w-1623 {
    width: 1623rem;
  }
  .lg:w-1624 {
    width: 1624rem;
  }
  .lg:w-1625 {
    width: 1625rem;
  }
  .lg:w-1626 {
    width: 1626rem;
  }
  .lg:w-1627 {
    width: 1627rem;
  }
  .lg:w-1628 {
    width: 1628rem;
  }
  .lg:w-1629 {
    width: 1629rem;
  }
  .lg:w-1630 {
    width: 1630rem;
  }
  .lg:w-1631 {
    width: 1631rem;
  }
  .lg:w-1632 {
    width: 1632rem;
  }
  .lg:w-1633 {
    width: 1633rem;
  }
  .lg:w-1634 {
    width: 1634rem;
  }
  .lg:w-1635 {
    width: 1635rem;
  }
  .lg:w-1636 {
    width: 1636rem;
  }
  .lg:w-1637 {
    width: 1637rem;
  }
  .lg:w-1638 {
    width: 1638rem;
  }
  .lg:w-1639 {
    width: 1639rem;
  }
  .lg:w-1640 {
    width: 1640rem;
  }
  .lg:w-1641 {
    width: 1641rem;
  }
  .lg:w-1642 {
    width: 1642rem;
  }
  .lg:w-1643 {
    width: 1643rem;
  }
  .lg:w-1644 {
    width: 1644rem;
  }
  .lg:w-1645 {
    width: 1645rem;
  }
  .lg:w-1646 {
    width: 1646rem;
  }
  .lg:w-1647 {
    width: 1647rem;
  }
  .lg:w-1648 {
    width: 1648rem;
  }
  .lg:w-1649 {
    width: 1649rem;
  }
  .lg:w-1650 {
    width: 1650rem;
  }
  .lg:w-1651 {
    width: 1651rem;
  }
  .lg:w-1652 {
    width: 1652rem;
  }
  .lg:w-1653 {
    width: 1653rem;
  }
  .lg:w-1654 {
    width: 1654rem;
  }
  .lg:w-1655 {
    width: 1655rem;
  }
  .lg:w-1656 {
    width: 1656rem;
  }
  .lg:w-1657 {
    width: 1657rem;
  }
  .lg:w-1658 {
    width: 1658rem;
  }
  .lg:w-1659 {
    width: 1659rem;
  }
  .lg:w-1660 {
    width: 1660rem;
  }
  .lg:w-1661 {
    width: 1661rem;
  }
  .lg:w-1662 {
    width: 1662rem;
  }
  .lg:w-1663 {
    width: 1663rem;
  }
  .lg:w-1664 {
    width: 1664rem;
  }
  .lg:w-1665 {
    width: 1665rem;
  }
  .lg:w-1666 {
    width: 1666rem;
  }
  .lg:w-1667 {
    width: 1667rem;
  }
  .lg:w-1668 {
    width: 1668rem;
  }
  .lg:w-1669 {
    width: 1669rem;
  }
  .lg:w-1670 {
    width: 1670rem;
  }
  .lg:w-1671 {
    width: 1671rem;
  }
  .lg:w-1672 {
    width: 1672rem;
  }
  .lg:w-1673 {
    width: 1673rem;
  }
  .lg:w-1674 {
    width: 1674rem;
  }
  .lg:w-1675 {
    width: 1675rem;
  }
  .lg:w-1676 {
    width: 1676rem;
  }
  .lg:w-1677 {
    width: 1677rem;
  }
  .lg:w-1678 {
    width: 1678rem;
  }
  .lg:w-1679 {
    width: 1679rem;
  }
  .lg:w-1680 {
    width: 1680rem;
  }
  .lg:w-1681 {
    width: 1681rem;
  }
  .lg:w-1682 {
    width: 1682rem;
  }
  .lg:w-1683 {
    width: 1683rem;
  }
  .lg:w-1684 {
    width: 1684rem;
  }
  .lg:w-1685 {
    width: 1685rem;
  }
  .lg:w-1686 {
    width: 1686rem;
  }
  .lg:w-1687 {
    width: 1687rem;
  }
  .lg:w-1688 {
    width: 1688rem;
  }
  .lg:w-1689 {
    width: 1689rem;
  }
  .lg:w-1690 {
    width: 1690rem;
  }
  .lg:w-1691 {
    width: 1691rem;
  }
  .lg:w-1692 {
    width: 1692rem;
  }
  .lg:w-1693 {
    width: 1693rem;
  }
  .lg:w-1694 {
    width: 1694rem;
  }
  .lg:w-1695 {
    width: 1695rem;
  }
  .lg:w-1696 {
    width: 1696rem;
  }
  .lg:w-1697 {
    width: 1697rem;
  }
  .lg:w-1698 {
    width: 1698rem;
  }
  .lg:w-1699 {
    width: 1699rem;
  }
  .lg:w-1700 {
    width: 1700rem;
  }
  .lg:w-1701 {
    width: 1701rem;
  }
  .lg:w-1702 {
    width: 1702rem;
  }
  .lg:w-1703 {
    width: 1703rem;
  }
  .lg:w-1704 {
    width: 1704rem;
  }
  .lg:w-1705 {
    width: 1705rem;
  }
  .lg:w-1706 {
    width: 1706rem;
  }
  .lg:w-1707 {
    width: 1707rem;
  }
  .lg:w-1708 {
    width: 1708rem;
  }
  .lg:w-1709 {
    width: 1709rem;
  }
  .lg:w-1710 {
    width: 1710rem;
  }
  .lg:w-1711 {
    width: 1711rem;
  }
  .lg:w-1712 {
    width: 1712rem;
  }
  .lg:w-1713 {
    width: 1713rem;
  }
  .lg:w-1714 {
    width: 1714rem;
  }
  .lg:w-1715 {
    width: 1715rem;
  }
  .lg:w-1716 {
    width: 1716rem;
  }
  .lg:w-1717 {
    width: 1717rem;
  }
  .lg:w-1718 {
    width: 1718rem;
  }
  .lg:w-1719 {
    width: 1719rem;
  }
  .lg:w-1720 {
    width: 1720rem;
  }
  .lg:w-1721 {
    width: 1721rem;
  }
  .lg:w-1722 {
    width: 1722rem;
  }
  .lg:w-1723 {
    width: 1723rem;
  }
  .lg:w-1724 {
    width: 1724rem;
  }
  .lg:w-1725 {
    width: 1725rem;
  }
  .lg:w-1726 {
    width: 1726rem;
  }
  .lg:w-1727 {
    width: 1727rem;
  }
  .lg:w-1728 {
    width: 1728rem;
  }
  .lg:w-1729 {
    width: 1729rem;
  }
  .lg:w-1730 {
    width: 1730rem;
  }
  .lg:w-1731 {
    width: 1731rem;
  }
  .lg:w-1732 {
    width: 1732rem;
  }
  .lg:w-1733 {
    width: 1733rem;
  }
  .lg:w-1734 {
    width: 1734rem;
  }
  .lg:w-1735 {
    width: 1735rem;
  }
  .lg:w-1736 {
    width: 1736rem;
  }
  .lg:w-1737 {
    width: 1737rem;
  }
  .lg:w-1738 {
    width: 1738rem;
  }
  .lg:w-1739 {
    width: 1739rem;
  }
  .lg:w-1740 {
    width: 1740rem;
  }
  .lg:w-1741 {
    width: 1741rem;
  }
  .lg:w-1742 {
    width: 1742rem;
  }
  .lg:w-1743 {
    width: 1743rem;
  }
  .lg:w-1744 {
    width: 1744rem;
  }
  .lg:w-1745 {
    width: 1745rem;
  }
  .lg:w-1746 {
    width: 1746rem;
  }
  .lg:w-1747 {
    width: 1747rem;
  }
  .lg:w-1748 {
    width: 1748rem;
  }
  .lg:w-1749 {
    width: 1749rem;
  }
  .lg:w-1750 {
    width: 1750rem;
  }
  .lg:w-1751 {
    width: 1751rem;
  }
  .lg:w-1752 {
    width: 1752rem;
  }
  .lg:w-1753 {
    width: 1753rem;
  }
  .lg:w-1754 {
    width: 1754rem;
  }
  .lg:w-1755 {
    width: 1755rem;
  }
  .lg:w-1756 {
    width: 1756rem;
  }
  .lg:w-1757 {
    width: 1757rem;
  }
  .lg:w-1758 {
    width: 1758rem;
  }
  .lg:w-1759 {
    width: 1759rem;
  }
  .lg:w-1760 {
    width: 1760rem;
  }
  .lg:w-1761 {
    width: 1761rem;
  }
  .lg:w-1762 {
    width: 1762rem;
  }
  .lg:w-1763 {
    width: 1763rem;
  }
  .lg:w-1764 {
    width: 1764rem;
  }
  .lg:w-1765 {
    width: 1765rem;
  }
  .lg:w-1766 {
    width: 1766rem;
  }
  .lg:w-1767 {
    width: 1767rem;
  }
  .lg:w-1768 {
    width: 1768rem;
  }
  .lg:w-1769 {
    width: 1769rem;
  }
  .lg:w-1770 {
    width: 1770rem;
  }
  .lg:w-1771 {
    width: 1771rem;
  }
  .lg:w-1772 {
    width: 1772rem;
  }
  .lg:w-1773 {
    width: 1773rem;
  }
  .lg:w-1774 {
    width: 1774rem;
  }
  .lg:w-1775 {
    width: 1775rem;
  }
  .lg:w-1776 {
    width: 1776rem;
  }
  .lg:w-1777 {
    width: 1777rem;
  }
  .lg:w-1778 {
    width: 1778rem;
  }
  .lg:w-1779 {
    width: 1779rem;
  }
  .lg:w-1780 {
    width: 1780rem;
  }
  .lg:w-1781 {
    width: 1781rem;
  }
  .lg:w-1782 {
    width: 1782rem;
  }
  .lg:w-1783 {
    width: 1783rem;
  }
  .lg:w-1784 {
    width: 1784rem;
  }
  .lg:w-1785 {
    width: 1785rem;
  }
  .lg:w-1786 {
    width: 1786rem;
  }
  .lg:w-1787 {
    width: 1787rem;
  }
  .lg:w-1788 {
    width: 1788rem;
  }
  .lg:w-1789 {
    width: 1789rem;
  }
  .lg:w-1790 {
    width: 1790rem;
  }
  .lg:w-1791 {
    width: 1791rem;
  }
  .lg:w-1792 {
    width: 1792rem;
  }
  .lg:w-1793 {
    width: 1793rem;
  }
  .lg:w-1794 {
    width: 1794rem;
  }
  .lg:w-1795 {
    width: 1795rem;
  }
  .lg:w-1796 {
    width: 1796rem;
  }
  .lg:w-1797 {
    width: 1797rem;
  }
  .lg:w-1798 {
    width: 1798rem;
  }
  .lg:w-1799 {
    width: 1799rem;
  }
  .lg:w-1800 {
    width: 1800rem;
  }
  .lg:w-1801 {
    width: 1801rem;
  }
  .lg:w-1802 {
    width: 1802rem;
  }
  .lg:w-1803 {
    width: 1803rem;
  }
  .lg:w-1804 {
    width: 1804rem;
  }
  .lg:w-1805 {
    width: 1805rem;
  }
  .lg:w-1806 {
    width: 1806rem;
  }
  .lg:w-1807 {
    width: 1807rem;
  }
  .lg:w-1808 {
    width: 1808rem;
  }
  .lg:w-1809 {
    width: 1809rem;
  }
  .lg:w-1810 {
    width: 1810rem;
  }
  .lg:w-1811 {
    width: 1811rem;
  }
  .lg:w-1812 {
    width: 1812rem;
  }
  .lg:w-1813 {
    width: 1813rem;
  }
  .lg:w-1814 {
    width: 1814rem;
  }
  .lg:w-1815 {
    width: 1815rem;
  }
  .lg:w-1816 {
    width: 1816rem;
  }
  .lg:w-1817 {
    width: 1817rem;
  }
  .lg:w-1818 {
    width: 1818rem;
  }
  .lg:w-1819 {
    width: 1819rem;
  }
  .lg:w-1820 {
    width: 1820rem;
  }
  .lg:w-1821 {
    width: 1821rem;
  }
  .lg:w-1822 {
    width: 1822rem;
  }
  .lg:w-1823 {
    width: 1823rem;
  }
  .lg:w-1824 {
    width: 1824rem;
  }
  .lg:w-1825 {
    width: 1825rem;
  }
  .lg:w-1826 {
    width: 1826rem;
  }
  .lg:w-1827 {
    width: 1827rem;
  }
  .lg:w-1828 {
    width: 1828rem;
  }
  .lg:w-1829 {
    width: 1829rem;
  }
  .lg:w-1830 {
    width: 1830rem;
  }
  .lg:w-1831 {
    width: 1831rem;
  }
  .lg:w-1832 {
    width: 1832rem;
  }
  .lg:w-1833 {
    width: 1833rem;
  }
  .lg:w-1834 {
    width: 1834rem;
  }
  .lg:w-1835 {
    width: 1835rem;
  }
  .lg:w-1836 {
    width: 1836rem;
  }
  .lg:w-1837 {
    width: 1837rem;
  }
  .lg:w-1838 {
    width: 1838rem;
  }
  .lg:w-1839 {
    width: 1839rem;
  }
  .lg:w-1840 {
    width: 1840rem;
  }
  .lg:w-1841 {
    width: 1841rem;
  }
  .lg:w-1842 {
    width: 1842rem;
  }
  .lg:w-1843 {
    width: 1843rem;
  }
  .lg:w-1844 {
    width: 1844rem;
  }
  .lg:w-1845 {
    width: 1845rem;
  }
  .lg:w-1846 {
    width: 1846rem;
  }
  .lg:w-1847 {
    width: 1847rem;
  }
  .lg:w-1848 {
    width: 1848rem;
  }
  .lg:w-1849 {
    width: 1849rem;
  }
  .lg:w-1850 {
    width: 1850rem;
  }
  .lg:w-1851 {
    width: 1851rem;
  }
  .lg:w-1852 {
    width: 1852rem;
  }
  .lg:w-1853 {
    width: 1853rem;
  }
  .lg:w-1854 {
    width: 1854rem;
  }
  .lg:w-1855 {
    width: 1855rem;
  }
  .lg:w-1856 {
    width: 1856rem;
  }
  .lg:w-1857 {
    width: 1857rem;
  }
  .lg:w-1858 {
    width: 1858rem;
  }
  .lg:w-1859 {
    width: 1859rem;
  }
  .lg:w-1860 {
    width: 1860rem;
  }
  .lg:w-1861 {
    width: 1861rem;
  }
  .lg:w-1862 {
    width: 1862rem;
  }
  .lg:w-1863 {
    width: 1863rem;
  }
  .lg:w-1864 {
    width: 1864rem;
  }
  .lg:w-1865 {
    width: 1865rem;
  }
  .lg:w-1866 {
    width: 1866rem;
  }
  .lg:w-1867 {
    width: 1867rem;
  }
  .lg:w-1868 {
    width: 1868rem;
  }
  .lg:w-1869 {
    width: 1869rem;
  }
  .lg:w-1870 {
    width: 1870rem;
  }
  .lg:w-1871 {
    width: 1871rem;
  }
  .lg:w-1872 {
    width: 1872rem;
  }
  .lg:w-1873 {
    width: 1873rem;
  }
  .lg:w-1874 {
    width: 1874rem;
  }
  .lg:w-1875 {
    width: 1875rem;
  }
  .lg:w-1876 {
    width: 1876rem;
  }
  .lg:w-1877 {
    width: 1877rem;
  }
  .lg:w-1878 {
    width: 1878rem;
  }
  .lg:w-1879 {
    width: 1879rem;
  }
  .lg:w-1880 {
    width: 1880rem;
  }
  .lg:w-1881 {
    width: 1881rem;
  }
  .lg:w-1882 {
    width: 1882rem;
  }
  .lg:w-1883 {
    width: 1883rem;
  }
  .lg:w-1884 {
    width: 1884rem;
  }
  .lg:w-1885 {
    width: 1885rem;
  }
  .lg:w-1886 {
    width: 1886rem;
  }
  .lg:w-1887 {
    width: 1887rem;
  }
  .lg:w-1888 {
    width: 1888rem;
  }
  .lg:w-1889 {
    width: 1889rem;
  }
  .lg:w-1890 {
    width: 1890rem;
  }
  .lg:w-1891 {
    width: 1891rem;
  }
  .lg:w-1892 {
    width: 1892rem;
  }
  .lg:w-1893 {
    width: 1893rem;
  }
  .lg:w-1894 {
    width: 1894rem;
  }
  .lg:w-1895 {
    width: 1895rem;
  }
  .lg:w-1896 {
    width: 1896rem;
  }
  .lg:w-1897 {
    width: 1897rem;
  }
  .lg:w-1898 {
    width: 1898rem;
  }
  .lg:w-1899 {
    width: 1899rem;
  }
  .lg:w-1900 {
    width: 1900rem;
  }
  .lg:w-1901 {
    width: 1901rem;
  }
  .lg:w-1902 {
    width: 1902rem;
  }
  .lg:w-1903 {
    width: 1903rem;
  }
  .lg:w-1904 {
    width: 1904rem;
  }
  .lg:w-1905 {
    width: 1905rem;
  }
  .lg:w-1906 {
    width: 1906rem;
  }
  .lg:w-1907 {
    width: 1907rem;
  }
  .lg:w-1908 {
    width: 1908rem;
  }
  .lg:w-1909 {
    width: 1909rem;
  }
  .lg:w-1910 {
    width: 1910rem;
  }
  .lg:w-1911 {
    width: 1911rem;
  }
  .lg:w-1912 {
    width: 1912rem;
  }
  .lg:w-1913 {
    width: 1913rem;
  }
  .lg:w-1914 {
    width: 1914rem;
  }
  .lg:w-1915 {
    width: 1915rem;
  }
  .lg:w-1916 {
    width: 1916rem;
  }
  .lg:w-1917 {
    width: 1917rem;
  }
  .lg:w-1918 {
    width: 1918rem;
  }
  .lg:w-1919 {
    width: 1919rem;
  }
  .lg:w-1920 {
    width: 1920rem;
  }
  .lg:w-1921 {
    width: 1921rem;
  }
  .lg:w-1922 {
    width: 1922rem;
  }
  .lg:w-1923 {
    width: 1923rem;
  }
  .lg:w-1924 {
    width: 1924rem;
  }
  .lg:w-1925 {
    width: 1925rem;
  }
  .lg:w-1926 {
    width: 1926rem;
  }
  .lg:w-1927 {
    width: 1927rem;
  }
  .lg:w-1928 {
    width: 1928rem;
  }
  .lg:w-1929 {
    width: 1929rem;
  }
  .lg:w-1930 {
    width: 1930rem;
  }
  .lg:w-1931 {
    width: 1931rem;
  }
  .lg:w-1932 {
    width: 1932rem;
  }
  .lg:w-1933 {
    width: 1933rem;
  }
  .lg:w-1934 {
    width: 1934rem;
  }
  .lg:w-1935 {
    width: 1935rem;
  }
  .lg:w-1936 {
    width: 1936rem;
  }
  .lg:w-1937 {
    width: 1937rem;
  }
  .lg:w-1938 {
    width: 1938rem;
  }
  .lg:w-1939 {
    width: 1939rem;
  }
  .lg:w-1940 {
    width: 1940rem;
  }
  .lg:w-1941 {
    width: 1941rem;
  }
  .lg:w-1942 {
    width: 1942rem;
  }
  .lg:w-1943 {
    width: 1943rem;
  }
  .lg:w-1944 {
    width: 1944rem;
  }
  .lg:w-1945 {
    width: 1945rem;
  }
  .lg:w-1946 {
    width: 1946rem;
  }
  .lg:w-1947 {
    width: 1947rem;
  }
  .lg:w-1948 {
    width: 1948rem;
  }
  .lg:w-1949 {
    width: 1949rem;
  }
  .lg:w-1950 {
    width: 1950rem;
  }
  .lg:w-1951 {
    width: 1951rem;
  }
  .lg:w-1952 {
    width: 1952rem;
  }
  .lg:w-1953 {
    width: 1953rem;
  }
  .lg:w-1954 {
    width: 1954rem;
  }
  .lg:w-1955 {
    width: 1955rem;
  }
  .lg:w-1956 {
    width: 1956rem;
  }
  .lg:w-1957 {
    width: 1957rem;
  }
  .lg:w-1958 {
    width: 1958rem;
  }
  .lg:w-1959 {
    width: 1959rem;
  }
  .lg:w-1960 {
    width: 1960rem;
  }
  .lg:w-1961 {
    width: 1961rem;
  }
  .lg:w-1962 {
    width: 1962rem;
  }
  .lg:w-1963 {
    width: 1963rem;
  }
  .lg:w-1964 {
    width: 1964rem;
  }
  .lg:w-1965 {
    width: 1965rem;
  }
  .lg:w-1966 {
    width: 1966rem;
  }
  .lg:w-1967 {
    width: 1967rem;
  }
  .lg:w-1968 {
    width: 1968rem;
  }
  .lg:w-1969 {
    width: 1969rem;
  }
  .lg:w-1970 {
    width: 1970rem;
  }
  .lg:w-1971 {
    width: 1971rem;
  }
  .lg:w-1972 {
    width: 1972rem;
  }
  .lg:w-1973 {
    width: 1973rem;
  }
  .lg:w-1974 {
    width: 1974rem;
  }
  .lg:w-1975 {
    width: 1975rem;
  }
  .lg:w-1976 {
    width: 1976rem;
  }
  .lg:w-1977 {
    width: 1977rem;
  }
  .lg:w-1978 {
    width: 1978rem;
  }
  .lg:w-1979 {
    width: 1979rem;
  }
  .lg:w-1980 {
    width: 1980rem;
  }
  .lg:w-1981 {
    width: 1981rem;
  }
  .lg:w-1982 {
    width: 1982rem;
  }
  .lg:w-1983 {
    width: 1983rem;
  }
  .lg:w-1984 {
    width: 1984rem;
  }
  .lg:w-1985 {
    width: 1985rem;
  }
  .lg:w-1986 {
    width: 1986rem;
  }
  .lg:w-1987 {
    width: 1987rem;
  }
  .lg:w-1988 {
    width: 1988rem;
  }
  .lg:w-1989 {
    width: 1989rem;
  }
  .lg:w-1990 {
    width: 1990rem;
  }
  .lg:w-1991 {
    width: 1991rem;
  }
  .lg:w-1992 {
    width: 1992rem;
  }
  .lg:w-1993 {
    width: 1993rem;
  }
  .lg:w-1994 {
    width: 1994rem;
  }
  .lg:w-1995 {
    width: 1995rem;
  }
  .lg:w-1996 {
    width: 1996rem;
  }
  .lg:w-1997 {
    width: 1997rem;
  }
  .lg:w-1998 {
    width: 1998rem;
  }
  .lg:w-1999 {
    width: 1999rem;
  }
  .lg:wp-0 {
    width: 0%;
  }
  .lg:wp-1 {
    width: 1%;
  }
  .lg:wp-2 {
    width: 2%;
  }
  .lg:wp-3 {
    width: 3%;
  }
  .lg:wp-4 {
    width: 4%;
  }
  .lg:wp-5 {
    width: 5%;
  }
  .lg:wp-6 {
    width: 6%;
  }
  .lg:wp-7 {
    width: 7%;
  }
  .lg:wp-8 {
    width: 8%;
  }
  .lg:wp-9 {
    width: 9%;
  }
  .lg:wp-10 {
    width: 10%;
  }
  .lg:wp-11 {
    width: 11%;
  }
  .lg:wp-12 {
    width: 12%;
  }
  .lg:wp-13 {
    width: 13%;
  }
  .lg:wp-14 {
    width: 14%;
  }
  .lg:wp-15 {
    width: 15%;
  }
  .lg:wp-16 {
    width: 16%;
  }
  .lg:wp-17 {
    width: 17%;
  }
  .lg:wp-18 {
    width: 18%;
  }
  .lg:wp-19 {
    width: 19%;
  }
  .lg:wp-20 {
    width: 20%;
  }
  .lg:wp-21 {
    width: 21%;
  }
  .lg:wp-22 {
    width: 22%;
  }
  .lg:wp-23 {
    width: 23%;
  }
  .lg:wp-24 {
    width: 24%;
  }
  .lg:wp-25 {
    width: 25%;
  }
  .lg:wp-26 {
    width: 26%;
  }
  .lg:wp-27 {
    width: 27%;
  }
  .lg:wp-28 {
    width: 28%;
  }
  .lg:wp-29 {
    width: 29%;
  }
  .lg:wp-30 {
    width: 30%;
  }
  .lg:wp-31 {
    width: 31%;
  }
  .lg:wp-32 {
    width: 32%;
  }
  .lg:wp-33 {
    width: 33%;
  }
  .lg:wp-34 {
    width: 34%;
  }
  .lg:wp-35 {
    width: 35%;
  }
  .lg:wp-36 {
    width: 36%;
  }
  .lg:wp-37 {
    width: 37%;
  }
  .lg:wp-38 {
    width: 38%;
  }
  .lg:wp-39 {
    width: 39%;
  }
  .lg:wp-40 {
    width: 40%;
  }
  .lg:wp-41 {
    width: 41%;
  }
  .lg:wp-42 {
    width: 42%;
  }
  .lg:wp-43 {
    width: 43%;
  }
  .lg:wp-44 {
    width: 44%;
  }
  .lg:wp-45 {
    width: 45%;
  }
  .lg:wp-46 {
    width: 46%;
  }
  .lg:wp-47 {
    width: 47%;
  }
  .lg:wp-48 {
    width: 48%;
  }
  .lg:wp-49 {
    width: 49%;
  }
  .lg:wp-50 {
    width: 50%;
  }
  .lg:wp-51 {
    width: 51%;
  }
  .lg:wp-52 {
    width: 52%;
  }
  .lg:wp-53 {
    width: 53%;
  }
  .lg:wp-54 {
    width: 54%;
  }
  .lg:wp-55 {
    width: 55%;
  }
  .lg:wp-56 {
    width: 56%;
  }
  .lg:wp-57 {
    width: 57%;
  }
  .lg:wp-58 {
    width: 58%;
  }
  .lg:wp-59 {
    width: 59%;
  }
  .lg:wp-60 {
    width: 60%;
  }
  .lg:wp-61 {
    width: 61%;
  }
  .lg:wp-62 {
    width: 62%;
  }
  .lg:wp-63 {
    width: 63%;
  }
  .lg:wp-64 {
    width: 64%;
  }
  .lg:wp-65 {
    width: 65%;
  }
  .lg:wp-66 {
    width: 66%;
  }
  .lg:wp-67 {
    width: 67%;
  }
  .lg:wp-68 {
    width: 68%;
  }
  .lg:wp-69 {
    width: 69%;
  }
  .lg:wp-70 {
    width: 70%;
  }
  .lg:wp-71 {
    width: 71%;
  }
  .lg:wp-72 {
    width: 72%;
  }
  .lg:wp-73 {
    width: 73%;
  }
  .lg:wp-74 {
    width: 74%;
  }
  .lg:wp-75 {
    width: 75%;
  }
  .lg:wp-76 {
    width: 76%;
  }
  .lg:wp-77 {
    width: 77%;
  }
  .lg:wp-78 {
    width: 78%;
  }
  .lg:wp-79 {
    width: 79%;
  }
  .lg:wp-80 {
    width: 80%;
  }
  .lg:wp-81 {
    width: 81%;
  }
  .lg:wp-82 {
    width: 82%;
  }
  .lg:wp-83 {
    width: 83%;
  }
  .lg:wp-84 {
    width: 84%;
  }
  .lg:wp-85 {
    width: 85%;
  }
  .lg:wp-86 {
    width: 86%;
  }
  .lg:wp-87 {
    width: 87%;
  }
  .lg:wp-88 {
    width: 88%;
  }
  .lg:wp-89 {
    width: 89%;
  }
  .lg:wp-90 {
    width: 90%;
  }
  .lg:wp-91 {
    width: 91%;
  }
  .lg:wp-92 {
    width: 92%;
  }
  .lg:wp-93 {
    width: 93%;
  }
  .lg:wp-94 {
    width: 94%;
  }
  .lg:wp-95 {
    width: 95%;
  }
  .lg:wp-96 {
    width: 96%;
  }
  .lg:wp-97 {
    width: 97%;
  }
  .lg:wp-98 {
    width: 98%;
  }
  .lg:wp-99 {
    width: 99%;
  }
  .lg:font-thin {
    font-weight: thin;
  }
  .lg:font-normal {
    font-weight: normal;
  }
  .lg:font-lighter {
    font-weight: lighter;
  }
  .lg:font-light {
    font-weight: light;
  }
  .lg:font-bold {
    font-weight: bold;
  }
  .lg:font-bolder {
    font-weight: bolder;
  }
  .lg:font-100 {
    font-weight: 100;
  }
  .lg:font-200 {
    font-weight: 200;
  }
  .lg:font-300 {
    font-weight: 300;
  }
  .lg:font-400 {
    font-weight: 400;
  }
  .lg:font-500 {
    font-weight: 500;
  }
  .lg:font-600 {
    font-weight: 600;
  }
  .lg:font-700 {
    font-weight: 700;
  }
  .lg:font-800 {
    font-weight: 800;
  }
  .lg:font-900 {
    font-weight: 900;
  }
  .shadow-xs {
    box-shadow: 0px 3px 4px -5px rgba(51, 51, 51, 0.03),
      0px 1px 2px rgba(51, 51, 51, 0.05);
  }
  .shadow-s {
    box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
      0px 0px 2px rgba(51, 51, 51, 0.02);
  }
  .shadow-a {
    box-shadow: 0px 32px 41px -23px rgba(51, 51, 51, 0.07),
      0px 2px 6px rgba(51, 51, 51, 0.06);
  }
}
@media (min-width: 1200px) {
  .xl:xl:p-0 {
    padding: 0rem;
  }
  .xl:xl:p-1 {
    padding: 1rem;
  }
  .xl:xl:p-2 {
    padding: 2rem;
  }
  .xl:xl:p-3 {
    padding: 3rem;
  }
  .xl:xl:p-4 {
    padding: 4rem;
  }
  .xl:xl:p-5 {
    padding: 5rem;
  }
  .xl:xl:p-6 {
    padding: 6rem;
  }
  .xl:xl:p-7 {
    padding: 7rem;
  }
  .xl:xl:p-8 {
    padding: 8rem;
  }
  .xl:xl:p-9 {
    padding: 9rem;
  }
  .xl:xl:p-10 {
    padding: 10rem;
  }
  .xl:xl:p-11 {
    padding: 11rem;
  }
  .xl:xl:p-12 {
    padding: 12rem;
  }
  .xl:xl:p-13 {
    padding: 13rem;
  }
  .xl:xl:p-14 {
    padding: 14rem;
  }
  .xl:xl:p-15 {
    padding: 15rem;
  }
  .xl:xl:p-16 {
    padding: 16rem;
  }
  .xl:xl:p-17 {
    padding: 17rem;
  }
  .xl:xl:p-18 {
    padding: 18rem;
  }
  .xl:xl:p-19 {
    padding: 19rem;
  }
  .xl:xl:p-20 {
    padding: 20rem;
  }
  .xl:xl:p-21 {
    padding: 21rem;
  }
  .xl:xl:p-22 {
    padding: 22rem;
  }
  .xl:xl:p-23 {
    padding: 23rem;
  }
  .xl:xl:p-24 {
    padding: 24rem;
  }
  .xl:xl:p-25 {
    padding: 25rem;
  }
  .xl:xl:p-26 {
    padding: 26rem;
  }
  .xl:xl:p-27 {
    padding: 27rem;
  }
  .xl:xl:p-28 {
    padding: 28rem;
  }
  .xl:xl:p-29 {
    padding: 29rem;
  }
  .xl:xl:p-30 {
    padding: 30rem;
  }
  .xl:xl:p-31 {
    padding: 31rem;
  }
  .xl:xl:p-32 {
    padding: 32rem;
  }
  .xl:xl:p-33 {
    padding: 33rem;
  }
  .xl:xl:p-34 {
    padding: 34rem;
  }
  .xl:xl:p-35 {
    padding: 35rem;
  }
  .xl:xl:p-36 {
    padding: 36rem;
  }
  .xl:xl:p-37 {
    padding: 37rem;
  }
  .xl:xl:p-38 {
    padding: 38rem;
  }
  .xl:xl:p-39 {
    padding: 39rem;
  }
  .xl:xl:p-40 {
    padding: 40rem;
  }
  .xl:xl:p-41 {
    padding: 41rem;
  }
  .xl:xl:p-42 {
    padding: 42rem;
  }
  .xl:xl:p-43 {
    padding: 43rem;
  }
  .xl:xl:p-44 {
    padding: 44rem;
  }
  .xl:xl:p-45 {
    padding: 45rem;
  }
  .xl:xl:p-46 {
    padding: 46rem;
  }
  .xl:xl:p-47 {
    padding: 47rem;
  }
  .xl:xl:p-48 {
    padding: 48rem;
  }
  .xl:xl:p-49 {
    padding: 49rem;
  }
  .xl:xl:p-50 {
    padding: 50rem;
  }
  .xl:xl:p-51 {
    padding: 51rem;
  }
  .xl:xl:p-52 {
    padding: 52rem;
  }
  .xl:xl:p-53 {
    padding: 53rem;
  }
  .xl:xl:p-54 {
    padding: 54rem;
  }
  .xl:xl:p-55 {
    padding: 55rem;
  }
  .xl:xl:p-56 {
    padding: 56rem;
  }
  .xl:xl:p-57 {
    padding: 57rem;
  }
  .xl:xl:p-58 {
    padding: 58rem;
  }
  .xl:xl:p-59 {
    padding: 59rem;
  }
  .xl:xl:p-60 {
    padding: 60rem;
  }
  .xl:xl:p-61 {
    padding: 61rem;
  }
  .xl:xl:p-62 {
    padding: 62rem;
  }
  .xl:xl:p-63 {
    padding: 63rem;
  }
  .xl:xl:p-64 {
    padding: 64rem;
  }
  .xl:xl:p-65 {
    padding: 65rem;
  }
  .xl:xl:p-66 {
    padding: 66rem;
  }
  .xl:xl:p-67 {
    padding: 67rem;
  }
  .xl:xl:p-68 {
    padding: 68rem;
  }
  .xl:xl:p-69 {
    padding: 69rem;
  }
  .xl:xl:p-70 {
    padding: 70rem;
  }
  .xl:xl:p-71 {
    padding: 71rem;
  }
  .xl:xl:p-72 {
    padding: 72rem;
  }
  .xl:xl:p-0-0 {
    padding: 0rem;
  }
  .xl:xl:p-0-1 {
    padding: 0.1rem;
  }
  .xl:xl:p-0-2 {
    padding: 0.2rem;
  }
  .xl:xl:p-0-3 {
    padding: 0.3rem;
  }
  .xl:xl:p-0-4 {
    padding: 0.4rem;
  }
  .xl:xl:p-0-5 {
    padding: 0.5rem;
  }
  .xl:xl:p-0-6 {
    padding: 0.6rem;
  }
  .xl:xl:p-0-7 {
    padding: 0.7rem;
  }
  .xl:xl:p-0-8 {
    padding: 0.8rem;
  }
  .xl:xl:p-0-9 {
    padding: 0.9rem;
  }
  .xl:xl:p-1-0 {
    padding: 1rem;
  }
  .xl:xl:p-1-1 {
    padding: 1.1rem;
  }
  .xl:xl:p-1-2 {
    padding: 1.2rem;
  }
  .xl:xl:p-1-3 {
    padding: 1.3rem;
  }
  .xl:xl:p-1-4 {
    padding: 1.4rem;
  }
  .xl:xl:p-1-5 {
    padding: 1.5rem;
  }
  .xl:xl:p-1-6 {
    padding: 1.6rem;
  }
  .xl:xl:p-1-7 {
    padding: 1.7rem;
  }
  .xl:xl:p-1-8 {
    padding: 1.8rem;
  }
  .xl:xl:p-1-9 {
    padding: 1.9rem;
  }
  .xl:xl:p-2-0 {
    padding: 2rem;
  }
  .xl:xl:p-2-1 {
    padding: 2.1rem;
  }
  .xl:xl:p-2-2 {
    padding: 2.2rem;
  }
  .xl:xl:p-2-3 {
    padding: 2.3rem;
  }
  .xl:xl:p-2-4 {
    padding: 2.4rem;
  }
  .xl:xl:p-2-5 {
    padding: 2.5rem;
  }
  .xl:xl:p-2-6 {
    padding: 2.6rem;
  }
  .xl:xl:p-2-7 {
    padding: 2.7rem;
  }
  .xl:xl:p-2-8 {
    padding: 2.8rem;
  }
  .xl:xl:p-2-9 {
    padding: 2.9rem;
  }
  .xl:xl:p-3-0 {
    padding: 3rem;
  }
  .xl:xl:p-3-1 {
    padding: 3.1rem;
  }
  .xl:xl:p-3-2 {
    padding: 3.2rem;
  }
  .xl:xl:p-3-3 {
    padding: 3.3rem;
  }
  .xl:xl:p-3-4 {
    padding: 3.4rem;
  }
  .xl:xl:p-3-5 {
    padding: 3.5rem;
  }
  .xl:xl:p-3-6 {
    padding: 3.6rem;
  }
  .xl:xl:p-3-7 {
    padding: 3.7rem;
  }
  .xl:xl:p-3-8 {
    padding: 3.8rem;
  }
  .xl:xl:p-3-9 {
    padding: 3.9rem;
  }
  .xl:xl:p-4-0 {
    padding: 4rem;
  }
  .xl:xl:p-4-1 {
    padding: 4.1rem;
  }
  .xl:xl:p-4-2 {
    padding: 4.2rem;
  }
  .xl:xl:p-4-3 {
    padding: 4.3rem;
  }
  .xl:xl:p-4-4 {
    padding: 4.4rem;
  }
  .xl:xl:p-4-5 {
    padding: 4.5rem;
  }
  .xl:xl:p-4-6 {
    padding: 4.6rem;
  }
  .xl:xl:p-4-7 {
    padding: 4.7rem;
  }
  .xl:xl:p-4-8 {
    padding: 4.8rem;
  }
  .xl:xl:p-4-9 {
    padding: 4.9rem;
  }
  .xl:xl:p-5-0 {
    padding: 5rem;
  }
  .xl:xl:p-5-1 {
    padding: 5.1rem;
  }
  .xl:xl:p-5-2 {
    padding: 5.2rem;
  }
  .xl:xl:p-5-3 {
    padding: 5.3rem;
  }
  .xl:xl:p-5-4 {
    padding: 5.4rem;
  }
  .xl:xl:p-5-5 {
    padding: 5.5rem;
  }
  .xl:xl:p-5-6 {
    padding: 5.6rem;
  }
  .xl:xl:p-5-7 {
    padding: 5.7rem;
  }
  .xl:xl:p-5-8 {
    padding: 5.8rem;
  }
  .xl:xl:p-5-9 {
    padding: 5.9rem;
  }
  .xl:xl:p-6-0 {
    padding: 6rem;
  }
  .xl:xl:p-6-1 {
    padding: 6.1rem;
  }
  .xl:xl:p-6-2 {
    padding: 6.2rem;
  }
  .xl:xl:p-6-3 {
    padding: 6.3rem;
  }
  .xl:xl:p-6-4 {
    padding: 6.4rem;
  }
  .xl:xl:p-6-5 {
    padding: 6.5rem;
  }
  .xl:xl:p-6-6 {
    padding: 6.6rem;
  }
  .xl:xl:p-6-7 {
    padding: 6.7rem;
  }
  .xl:xl:p-6-8 {
    padding: 6.8rem;
  }
  .xl:xl:p-6-9 {
    padding: 6.9rem;
  }
  .xl:xl:p-7-0 {
    padding: 7rem;
  }
  .xl:xl:p-7-1 {
    padding: 7.1rem;
  }
  .xl:xl:p-7-2 {
    padding: 7.2rem;
  }
  .xl:xl:p-7-3 {
    padding: 7.3rem;
  }
  .xl:xl:p-7-4 {
    padding: 7.4rem;
  }
  .xl:xl:p-7-5 {
    padding: 7.5rem;
  }
  .xl:xl:p-7-6 {
    padding: 7.6rem;
  }
  .xl:xl:p-7-7 {
    padding: 7.7rem;
  }
  .xl:xl:p-7-8 {
    padding: 7.8rem;
  }
  .xl:xl:p-7-9 {
    padding: 7.9rem;
  }
  .xl:xl:p-8-0 {
    padding: 8rem;
  }
  .xl:xl:p-8-1 {
    padding: 8.1rem;
  }
  .xl:xl:p-8-2 {
    padding: 8.2rem;
  }
  .xl:xl:p-8-3 {
    padding: 8.3rem;
  }
  .xl:xl:p-8-4 {
    padding: 8.4rem;
  }
  .xl:xl:p-8-5 {
    padding: 8.5rem;
  }
  .xl:xl:p-8-6 {
    padding: 8.6rem;
  }
  .xl:xl:p-8-7 {
    padding: 8.7rem;
  }
  .xl:xl:p-8-8 {
    padding: 8.8rem;
  }
  .xl:xl:p-8-9 {
    padding: 8.9rem;
  }
  .xl:xl:p-9-0 {
    padding: 9rem;
  }
  .xl:xl:p-9-1 {
    padding: 9.1rem;
  }
  .xl:xl:p-9-2 {
    padding: 9.2rem;
  }
  .xl:xl:p-9-3 {
    padding: 9.3rem;
  }
  .xl:xl:p-9-4 {
    padding: 9.4rem;
  }
  .xl:xl:p-9-5 {
    padding: 9.5rem;
  }
  .xl:xl:p-9-6 {
    padding: 9.6rem;
  }
  .xl:xl:p-9-7 {
    padding: 9.7rem;
  }
  .xl:xl:p-9-8 {
    padding: 9.8rem;
  }
  .xl:xl:p-9-9 {
    padding: 9.9rem;
  }
  .xl:xl:p-10-0 {
    padding: 10rem;
  }
  .xl:xl:p-10-1 {
    padding: 10.1rem;
  }
  .xl:xl:p-10-2 {
    padding: 10.2rem;
  }
  .xl:xl:p-10-3 {
    padding: 10.3rem;
  }
  .xl:xl:p-10-4 {
    padding: 10.4rem;
  }
  .xl:xl:p-10-5 {
    padding: 10.5rem;
  }
  .xl:xl:p-10-6 {
    padding: 10.6rem;
  }
  .xl:xl:p-10-7 {
    padding: 10.7rem;
  }
  .xl:xl:p-10-8 {
    padding: 10.8rem;
  }
  .xl:xl:p-10-9 {
    padding: 10.9rem;
  }
  .xl:xl:p-11-0 {
    padding: 11rem;
  }
  .xl:xl:p-11-1 {
    padding: 11.1rem;
  }
  .xl:xl:p-11-2 {
    padding: 11.2rem;
  }
  .xl:xl:p-11-3 {
    padding: 11.3rem;
  }
  .xl:xl:p-11-4 {
    padding: 11.4rem;
  }
  .xl:xl:p-11-5 {
    padding: 11.5rem;
  }
  .xl:xl:p-11-6 {
    padding: 11.6rem;
  }
  .xl:xl:p-11-7 {
    padding: 11.7rem;
  }
  .xl:xl:p-11-8 {
    padding: 11.8rem;
  }
  .xl:xl:p-11-9 {
    padding: 11.9rem;
  }
  .xl:xl:p-12-0 {
    padding: 12rem;
  }
  .xl:xl:p-12-1 {
    padding: 12.1rem;
  }
  .xl:xl:p-12-2 {
    padding: 12.2rem;
  }
  .xl:xl:p-12-3 {
    padding: 12.3rem;
  }
  .xl:xl:p-12-4 {
    padding: 12.4rem;
  }
  .xl:xl:p-12-5 {
    padding: 12.5rem;
  }
  .xl:xl:p-12-6 {
    padding: 12.6rem;
  }
  .xl:xl:p-12-7 {
    padding: 12.7rem;
  }
  .xl:xl:p-12-8 {
    padding: 12.8rem;
  }
  .xl:xl:p-12-9 {
    padding: 12.9rem;
  }
  .xl:xl:p-13-0 {
    padding: 13rem;
  }
  .xl:xl:p-13-1 {
    padding: 13.1rem;
  }
  .xl:xl:p-13-2 {
    padding: 13.2rem;
  }
  .xl:xl:p-13-3 {
    padding: 13.3rem;
  }
  .xl:xl:p-13-4 {
    padding: 13.4rem;
  }
  .xl:xl:p-13-5 {
    padding: 13.5rem;
  }
  .xl:xl:p-13-6 {
    padding: 13.6rem;
  }
  .xl:xl:p-13-7 {
    padding: 13.7rem;
  }
  .xl:xl:p-13-8 {
    padding: 13.8rem;
  }
  .xl:xl:p-13-9 {
    padding: 13.9rem;
  }
  .xl:xl:p-14-0 {
    padding: 14rem;
  }
  .xl:xl:p-14-1 {
    padding: 14.1rem;
  }
  .xl:xl:p-14-2 {
    padding: 14.2rem;
  }
  .xl:xl:p-14-3 {
    padding: 14.3rem;
  }
  .xl:xl:p-14-4 {
    padding: 14.4rem;
  }
  .xl:xl:p-14-5 {
    padding: 14.5rem;
  }
  .xl:xl:p-14-6 {
    padding: 14.6rem;
  }
  .xl:xl:p-14-7 {
    padding: 14.7rem;
  }
  .xl:xl:p-14-8 {
    padding: 14.8rem;
  }
  .xl:xl:p-14-9 {
    padding: 14.9rem;
  }
  .xl:xl:p-15-0 {
    padding: 15rem;
  }
  .xl:xl:p-15-1 {
    padding: 15.1rem;
  }
  .xl:xl:p-15-2 {
    padding: 15.2rem;
  }
  .xl:xl:p-15-3 {
    padding: 15.3rem;
  }
  .xl:xl:p-15-4 {
    padding: 15.4rem;
  }
  .xl:xl:p-15-5 {
    padding: 15.5rem;
  }
  .xl:xl:p-15-6 {
    padding: 15.6rem;
  }
  .xl:xl:p-15-7 {
    padding: 15.7rem;
  }
  .xl:xl:p-15-8 {
    padding: 15.8rem;
  }
  .xl:xl:p-15-9 {
    padding: 15.9rem;
  }
  .xl:xl:p-16-0 {
    padding: 16rem;
  }
  .xl:xl:p-16-1 {
    padding: 16.1rem;
  }
  .xl:xl:p-16-2 {
    padding: 16.2rem;
  }
  .xl:xl:p-16-3 {
    padding: 16.3rem;
  }
  .xl:xl:p-16-4 {
    padding: 16.4rem;
  }
  .xl:xl:p-16-5 {
    padding: 16.5rem;
  }
  .xl:xl:p-16-6 {
    padding: 16.6rem;
  }
  .xl:xl:p-16-7 {
    padding: 16.7rem;
  }
  .xl:xl:p-16-8 {
    padding: 16.8rem;
  }
  .xl:xl:p-16-9 {
    padding: 16.9rem;
  }
  .xl:xl:p-17-0 {
    padding: 17rem;
  }
  .xl:xl:p-17-1 {
    padding: 17.1rem;
  }
  .xl:xl:p-17-2 {
    padding: 17.2rem;
  }
  .xl:xl:p-17-3 {
    padding: 17.3rem;
  }
  .xl:xl:p-17-4 {
    padding: 17.4rem;
  }
  .xl:xl:p-17-5 {
    padding: 17.5rem;
  }
  .xl:xl:p-17-6 {
    padding: 17.6rem;
  }
  .xl:xl:p-17-7 {
    padding: 17.7rem;
  }
  .xl:xl:p-17-8 {
    padding: 17.8rem;
  }
  .xl:xl:p-17-9 {
    padding: 17.9rem;
  }
  .xl:xl:p-18-0 {
    padding: 18rem;
  }
  .xl:xl:p-18-1 {
    padding: 18.1rem;
  }
  .xl:xl:p-18-2 {
    padding: 18.2rem;
  }
  .xl:xl:p-18-3 {
    padding: 18.3rem;
  }
  .xl:xl:p-18-4 {
    padding: 18.4rem;
  }
  .xl:xl:p-18-5 {
    padding: 18.5rem;
  }
  .xl:xl:p-18-6 {
    padding: 18.6rem;
  }
  .xl:xl:p-18-7 {
    padding: 18.7rem;
  }
  .xl:xl:p-18-8 {
    padding: 18.8rem;
  }
  .xl:xl:p-18-9 {
    padding: 18.9rem;
  }
  .xl:xl:p-19-0 {
    padding: 19rem;
  }
  .xl:xl:p-19-1 {
    padding: 19.1rem;
  }
  .xl:xl:p-19-2 {
    padding: 19.2rem;
  }
  .xl:xl:p-19-3 {
    padding: 19.3rem;
  }
  .xl:xl:p-19-4 {
    padding: 19.4rem;
  }
  .xl:xl:p-19-5 {
    padding: 19.5rem;
  }
  .xl:xl:p-19-6 {
    padding: 19.6rem;
  }
  .xl:xl:p-19-7 {
    padding: 19.7rem;
  }
  .xl:xl:p-19-8 {
    padding: 19.8rem;
  }
  .xl:xl:p-19-9 {
    padding: 19.9rem;
  }
  .xl:xl:p-20-0 {
    padding: 20rem;
  }
  .xl:xl:p-20-1 {
    padding: 20.1rem;
  }
  .xl:xl:p-20-2 {
    padding: 20.2rem;
  }
  .xl:xl:p-20-3 {
    padding: 20.3rem;
  }
  .xl:xl:p-20-4 {
    padding: 20.4rem;
  }
  .xl:xl:p-20-5 {
    padding: 20.5rem;
  }
  .xl:xl:p-20-6 {
    padding: 20.6rem;
  }
  .xl:xl:p-20-7 {
    padding: 20.7rem;
  }
  .xl:xl:p-20-8 {
    padding: 20.8rem;
  }
  .xl:xl:p-20-9 {
    padding: 20.9rem;
  }
  .xl:xl:p-21-0 {
    padding: 21rem;
  }
  .xl:xl:p-21-1 {
    padding: 21.1rem;
  }
  .xl:xl:p-21-2 {
    padding: 21.2rem;
  }
  .xl:xl:p-21-3 {
    padding: 21.3rem;
  }
  .xl:xl:p-21-4 {
    padding: 21.4rem;
  }
  .xl:xl:p-21-5 {
    padding: 21.5rem;
  }
  .xl:xl:p-21-6 {
    padding: 21.6rem;
  }
  .xl:xl:p-21-7 {
    padding: 21.7rem;
  }
  .xl:xl:p-21-8 {
    padding: 21.8rem;
  }
  .xl:xl:p-21-9 {
    padding: 21.9rem;
  }
  .xl:xl:p-22-0 {
    padding: 22rem;
  }
  .xl:xl:p-22-1 {
    padding: 22.1rem;
  }
  .xl:xl:p-22-2 {
    padding: 22.2rem;
  }
  .xl:xl:p-22-3 {
    padding: 22.3rem;
  }
  .xl:xl:p-22-4 {
    padding: 22.4rem;
  }
  .xl:xl:p-22-5 {
    padding: 22.5rem;
  }
  .xl:xl:p-22-6 {
    padding: 22.6rem;
  }
  .xl:xl:p-22-7 {
    padding: 22.7rem;
  }
  .xl:xl:p-22-8 {
    padding: 22.8rem;
  }
  .xl:xl:p-22-9 {
    padding: 22.9rem;
  }
  .xl:xl:p-23-0 {
    padding: 23rem;
  }
  .xl:xl:p-23-1 {
    padding: 23.1rem;
  }
  .xl:xl:p-23-2 {
    padding: 23.2rem;
  }
  .xl:xl:p-23-3 {
    padding: 23.3rem;
  }
  .xl:xl:p-23-4 {
    padding: 23.4rem;
  }
  .xl:xl:p-23-5 {
    padding: 23.5rem;
  }
  .xl:xl:p-23-6 {
    padding: 23.6rem;
  }
  .xl:xl:p-23-7 {
    padding: 23.7rem;
  }
  .xl:xl:p-23-8 {
    padding: 23.8rem;
  }
  .xl:xl:p-23-9 {
    padding: 23.9rem;
  }
  .xl:xl:p-24-0 {
    padding: 24rem;
  }
  .xl:xl:p-24-1 {
    padding: 24.1rem;
  }
  .xl:xl:p-24-2 {
    padding: 24.2rem;
  }
  .xl:xl:p-24-3 {
    padding: 24.3rem;
  }
  .xl:xl:p-24-4 {
    padding: 24.4rem;
  }
  .xl:xl:p-24-5 {
    padding: 24.5rem;
  }
  .xl:xl:p-24-6 {
    padding: 24.6rem;
  }
  .xl:xl:p-24-7 {
    padding: 24.7rem;
  }
  .xl:xl:p-24-8 {
    padding: 24.8rem;
  }
  .xl:xl:p-24-9 {
    padding: 24.9rem;
  }
  .xl:xl:p-25-0 {
    padding: 25rem;
  }
  .xl:xl:p-25-1 {
    padding: 25.1rem;
  }
  .xl:xl:p-25-2 {
    padding: 25.2rem;
  }
  .xl:xl:p-25-3 {
    padding: 25.3rem;
  }
  .xl:xl:p-25-4 {
    padding: 25.4rem;
  }
  .xl:xl:p-25-5 {
    padding: 25.5rem;
  }
  .xl:xl:p-25-6 {
    padding: 25.6rem;
  }
  .xl:xl:p-25-7 {
    padding: 25.7rem;
  }
  .xl:xl:p-25-8 {
    padding: 25.8rem;
  }
  .xl:xl:p-25-9 {
    padding: 25.9rem;
  }
  .xl:xl:p-26-0 {
    padding: 26rem;
  }
  .xl:xl:p-26-1 {
    padding: 26.1rem;
  }
  .xl:xl:p-26-2 {
    padding: 26.2rem;
  }
  .xl:xl:p-26-3 {
    padding: 26.3rem;
  }
  .xl:xl:p-26-4 {
    padding: 26.4rem;
  }
  .xl:xl:p-26-5 {
    padding: 26.5rem;
  }
  .xl:xl:p-26-6 {
    padding: 26.6rem;
  }
  .xl:xl:p-26-7 {
    padding: 26.7rem;
  }
  .xl:xl:p-26-8 {
    padding: 26.8rem;
  }
  .xl:xl:p-26-9 {
    padding: 26.9rem;
  }
  .xl:xl:p-27-0 {
    padding: 27rem;
  }
  .xl:xl:p-27-1 {
    padding: 27.1rem;
  }
  .xl:xl:p-27-2 {
    padding: 27.2rem;
  }
  .xl:xl:p-27-3 {
    padding: 27.3rem;
  }
  .xl:xl:p-27-4 {
    padding: 27.4rem;
  }
  .xl:xl:p-27-5 {
    padding: 27.5rem;
  }
  .xl:xl:p-27-6 {
    padding: 27.6rem;
  }
  .xl:xl:p-27-7 {
    padding: 27.7rem;
  }
  .xl:xl:p-27-8 {
    padding: 27.8rem;
  }
  .xl:xl:p-27-9 {
    padding: 27.9rem;
  }
  .xl:xl:p-28-0 {
    padding: 28rem;
  }
  .xl:xl:p-28-1 {
    padding: 28.1rem;
  }
  .xl:xl:p-28-2 {
    padding: 28.2rem;
  }
  .xl:xl:p-28-3 {
    padding: 28.3rem;
  }
  .xl:xl:p-28-4 {
    padding: 28.4rem;
  }
  .xl:xl:p-28-5 {
    padding: 28.5rem;
  }
  .xl:xl:p-28-6 {
    padding: 28.6rem;
  }
  .xl:xl:p-28-7 {
    padding: 28.7rem;
  }
  .xl:xl:p-28-8 {
    padding: 28.8rem;
  }
  .xl:xl:p-28-9 {
    padding: 28.9rem;
  }
  .xl:xl:p-29-0 {
    padding: 29rem;
  }
  .xl:xl:p-29-1 {
    padding: 29.1rem;
  }
  .xl:xl:p-29-2 {
    padding: 29.2rem;
  }
  .xl:xl:p-29-3 {
    padding: 29.3rem;
  }
  .xl:xl:p-29-4 {
    padding: 29.4rem;
  }
  .xl:xl:p-29-5 {
    padding: 29.5rem;
  }
  .xl:xl:p-29-6 {
    padding: 29.6rem;
  }
  .xl:xl:p-29-7 {
    padding: 29.7rem;
  }
  .xl:xl:p-29-8 {
    padding: 29.8rem;
  }
  .xl:xl:p-29-9 {
    padding: 29.9rem;
  }
  .xl:xl:p-30-0 {
    padding: 30rem;
  }
  .xl:xl:p-30-1 {
    padding: 30.1rem;
  }
  .xl:xl:p-30-2 {
    padding: 30.2rem;
  }
  .xl:xl:p-30-3 {
    padding: 30.3rem;
  }
  .xl:xl:p-30-4 {
    padding: 30.4rem;
  }
  .xl:xl:p-30-5 {
    padding: 30.5rem;
  }
  .xl:xl:p-30-6 {
    padding: 30.6rem;
  }
  .xl:xl:p-30-7 {
    padding: 30.7rem;
  }
  .xl:xl:p-30-8 {
    padding: 30.8rem;
  }
  .xl:xl:p-30-9 {
    padding: 30.9rem;
  }
  .xl:xl:p-31-0 {
    padding: 31rem;
  }
  .xl:xl:p-31-1 {
    padding: 31.1rem;
  }
  .xl:xl:p-31-2 {
    padding: 31.2rem;
  }
  .xl:xl:p-31-3 {
    padding: 31.3rem;
  }
  .xl:xl:p-31-4 {
    padding: 31.4rem;
  }
  .xl:xl:p-31-5 {
    padding: 31.5rem;
  }
  .xl:xl:p-31-6 {
    padding: 31.6rem;
  }
  .xl:xl:p-31-7 {
    padding: 31.7rem;
  }
  .xl:xl:p-31-8 {
    padding: 31.8rem;
  }
  .xl:xl:p-31-9 {
    padding: 31.9rem;
  }
  .xl:xl:p-32-0 {
    padding: 32rem;
  }
  .xl:xl:p-32-1 {
    padding: 32.1rem;
  }
  .xl:xl:p-32-2 {
    padding: 32.2rem;
  }
  .xl:xl:p-32-3 {
    padding: 32.3rem;
  }
  .xl:xl:p-32-4 {
    padding: 32.4rem;
  }
  .xl:xl:p-32-5 {
    padding: 32.5rem;
  }
  .xl:xl:p-32-6 {
    padding: 32.6rem;
  }
  .xl:xl:p-32-7 {
    padding: 32.7rem;
  }
  .xl:xl:p-32-8 {
    padding: 32.8rem;
  }
  .xl:xl:p-32-9 {
    padding: 32.9rem;
  }
  .xl:xl:p-33-0 {
    padding: 33rem;
  }
  .xl:xl:p-33-1 {
    padding: 33.1rem;
  }
  .xl:xl:p-33-2 {
    padding: 33.2rem;
  }
  .xl:xl:p-33-3 {
    padding: 33.3rem;
  }
  .xl:xl:p-33-4 {
    padding: 33.4rem;
  }
  .xl:xl:p-33-5 {
    padding: 33.5rem;
  }
  .xl:xl:p-33-6 {
    padding: 33.6rem;
  }
  .xl:xl:p-33-7 {
    padding: 33.7rem;
  }
  .xl:xl:p-33-8 {
    padding: 33.8rem;
  }
  .xl:xl:p-33-9 {
    padding: 33.9rem;
  }
  .xl:xl:p-34-0 {
    padding: 34rem;
  }
  .xl:xl:p-34-1 {
    padding: 34.1rem;
  }
  .xl:xl:p-34-2 {
    padding: 34.2rem;
  }
  .xl:xl:p-34-3 {
    padding: 34.3rem;
  }
  .xl:xl:p-34-4 {
    padding: 34.4rem;
  }
  .xl:xl:p-34-5 {
    padding: 34.5rem;
  }
  .xl:xl:p-34-6 {
    padding: 34.6rem;
  }
  .xl:xl:p-34-7 {
    padding: 34.7rem;
  }
  .xl:xl:p-34-8 {
    padding: 34.8rem;
  }
  .xl:xl:p-34-9 {
    padding: 34.9rem;
  }
  .xl:xl:p-35-0 {
    padding: 35rem;
  }
  .xl:xl:p-35-1 {
    padding: 35.1rem;
  }
  .xl:xl:p-35-2 {
    padding: 35.2rem;
  }
  .xl:xl:p-35-3 {
    padding: 35.3rem;
  }
  .xl:xl:p-35-4 {
    padding: 35.4rem;
  }
  .xl:xl:p-35-5 {
    padding: 35.5rem;
  }
  .xl:xl:p-35-6 {
    padding: 35.6rem;
  }
  .xl:xl:p-35-7 {
    padding: 35.7rem;
  }
  .xl:xl:p-35-8 {
    padding: 35.8rem;
  }
  .xl:xl:p-35-9 {
    padding: 35.9rem;
  }
  .xl:xl:p-36-0 {
    padding: 36rem;
  }
  .xl:xl:p-36-1 {
    padding: 36.1rem;
  }
  .xl:xl:p-36-2 {
    padding: 36.2rem;
  }
  .xl:xl:p-36-3 {
    padding: 36.3rem;
  }
  .xl:xl:p-36-4 {
    padding: 36.4rem;
  }
  .xl:xl:p-36-5 {
    padding: 36.5rem;
  }
  .xl:xl:p-36-6 {
    padding: 36.6rem;
  }
  .xl:xl:p-36-7 {
    padding: 36.7rem;
  }
  .xl:xl:p-36-8 {
    padding: 36.8rem;
  }
  .xl:xl:p-36-9 {
    padding: 36.9rem;
  }
  .xl:xl:p-37-0 {
    padding: 37rem;
  }
  .xl:xl:p-37-1 {
    padding: 37.1rem;
  }
  .xl:xl:p-37-2 {
    padding: 37.2rem;
  }
  .xl:xl:p-37-3 {
    padding: 37.3rem;
  }
  .xl:xl:p-37-4 {
    padding: 37.4rem;
  }
  .xl:xl:p-37-5 {
    padding: 37.5rem;
  }
  .xl:xl:p-37-6 {
    padding: 37.6rem;
  }
  .xl:xl:p-37-7 {
    padding: 37.7rem;
  }
  .xl:xl:p-37-8 {
    padding: 37.8rem;
  }
  .xl:xl:p-37-9 {
    padding: 37.9rem;
  }
  .xl:xl:p-38-0 {
    padding: 38rem;
  }
  .xl:xl:p-38-1 {
    padding: 38.1rem;
  }
  .xl:xl:p-38-2 {
    padding: 38.2rem;
  }
  .xl:xl:p-38-3 {
    padding: 38.3rem;
  }
  .xl:xl:p-38-4 {
    padding: 38.4rem;
  }
  .xl:xl:p-38-5 {
    padding: 38.5rem;
  }
  .xl:xl:p-38-6 {
    padding: 38.6rem;
  }
  .xl:xl:p-38-7 {
    padding: 38.7rem;
  }
  .xl:xl:p-38-8 {
    padding: 38.8rem;
  }
  .xl:xl:p-38-9 {
    padding: 38.9rem;
  }
  .xl:xl:p-39-0 {
    padding: 39rem;
  }
  .xl:xl:p-39-1 {
    padding: 39.1rem;
  }
  .xl:xl:p-39-2 {
    padding: 39.2rem;
  }
  .xl:xl:p-39-3 {
    padding: 39.3rem;
  }
  .xl:xl:p-39-4 {
    padding: 39.4rem;
  }
  .xl:xl:p-39-5 {
    padding: 39.5rem;
  }
  .xl:xl:p-39-6 {
    padding: 39.6rem;
  }
  .xl:xl:p-39-7 {
    padding: 39.7rem;
  }
  .xl:xl:p-39-8 {
    padding: 39.8rem;
  }
  .xl:xl:p-39-9 {
    padding: 39.9rem;
  }
  .xl:xl:p-40-0 {
    padding: 40rem;
  }
  .xl:xl:p-40-1 {
    padding: 40.1rem;
  }
  .xl:xl:p-40-2 {
    padding: 40.2rem;
  }
  .xl:xl:p-40-3 {
    padding: 40.3rem;
  }
  .xl:xl:p-40-4 {
    padding: 40.4rem;
  }
  .xl:xl:p-40-5 {
    padding: 40.5rem;
  }
  .xl:xl:p-40-6 {
    padding: 40.6rem;
  }
  .xl:xl:p-40-7 {
    padding: 40.7rem;
  }
  .xl:xl:p-40-8 {
    padding: 40.8rem;
  }
  .xl:xl:p-40-9 {
    padding: 40.9rem;
  }
  .xl:xl:p-41-0 {
    padding: 41rem;
  }
  .xl:xl:p-41-1 {
    padding: 41.1rem;
  }
  .xl:xl:p-41-2 {
    padding: 41.2rem;
  }
  .xl:xl:p-41-3 {
    padding: 41.3rem;
  }
  .xl:xl:p-41-4 {
    padding: 41.4rem;
  }
  .xl:xl:p-41-5 {
    padding: 41.5rem;
  }
  .xl:xl:p-41-6 {
    padding: 41.6rem;
  }
  .xl:xl:p-41-7 {
    padding: 41.7rem;
  }
  .xl:xl:p-41-8 {
    padding: 41.8rem;
  }
  .xl:xl:p-41-9 {
    padding: 41.9rem;
  }
  .xl:xl:p-42-0 {
    padding: 42rem;
  }
  .xl:xl:p-42-1 {
    padding: 42.1rem;
  }
  .xl:xl:p-42-2 {
    padding: 42.2rem;
  }
  .xl:xl:p-42-3 {
    padding: 42.3rem;
  }
  .xl:xl:p-42-4 {
    padding: 42.4rem;
  }
  .xl:xl:p-42-5 {
    padding: 42.5rem;
  }
  .xl:xl:p-42-6 {
    padding: 42.6rem;
  }
  .xl:xl:p-42-7 {
    padding: 42.7rem;
  }
  .xl:xl:p-42-8 {
    padding: 42.8rem;
  }
  .xl:xl:p-42-9 {
    padding: 42.9rem;
  }
  .xl:xl:p-43-0 {
    padding: 43rem;
  }
  .xl:xl:p-43-1 {
    padding: 43.1rem;
  }
  .xl:xl:p-43-2 {
    padding: 43.2rem;
  }
  .xl:xl:p-43-3 {
    padding: 43.3rem;
  }
  .xl:xl:p-43-4 {
    padding: 43.4rem;
  }
  .xl:xl:p-43-5 {
    padding: 43.5rem;
  }
  .xl:xl:p-43-6 {
    padding: 43.6rem;
  }
  .xl:xl:p-43-7 {
    padding: 43.7rem;
  }
  .xl:xl:p-43-8 {
    padding: 43.8rem;
  }
  .xl:xl:p-43-9 {
    padding: 43.9rem;
  }
  .xl:xl:p-44-0 {
    padding: 44rem;
  }
  .xl:xl:p-44-1 {
    padding: 44.1rem;
  }
  .xl:xl:p-44-2 {
    padding: 44.2rem;
  }
  .xl:xl:p-44-3 {
    padding: 44.3rem;
  }
  .xl:xl:p-44-4 {
    padding: 44.4rem;
  }
  .xl:xl:p-44-5 {
    padding: 44.5rem;
  }
  .xl:xl:p-44-6 {
    padding: 44.6rem;
  }
  .xl:xl:p-44-7 {
    padding: 44.7rem;
  }
  .xl:xl:p-44-8 {
    padding: 44.8rem;
  }
  .xl:xl:p-44-9 {
    padding: 44.9rem;
  }
  .xl:xl:p-45-0 {
    padding: 45rem;
  }
  .xl:xl:p-45-1 {
    padding: 45.1rem;
  }
  .xl:xl:p-45-2 {
    padding: 45.2rem;
  }
  .xl:xl:p-45-3 {
    padding: 45.3rem;
  }
  .xl:xl:p-45-4 {
    padding: 45.4rem;
  }
  .xl:xl:p-45-5 {
    padding: 45.5rem;
  }
  .xl:xl:p-45-6 {
    padding: 45.6rem;
  }
  .xl:xl:p-45-7 {
    padding: 45.7rem;
  }
  .xl:xl:p-45-8 {
    padding: 45.8rem;
  }
  .xl:xl:p-45-9 {
    padding: 45.9rem;
  }
  .xl:xl:p-46-0 {
    padding: 46rem;
  }
  .xl:xl:p-46-1 {
    padding: 46.1rem;
  }
  .xl:xl:p-46-2 {
    padding: 46.2rem;
  }
  .xl:xl:p-46-3 {
    padding: 46.3rem;
  }
  .xl:xl:p-46-4 {
    padding: 46.4rem;
  }
  .xl:xl:p-46-5 {
    padding: 46.5rem;
  }
  .xl:xl:p-46-6 {
    padding: 46.6rem;
  }
  .xl:xl:p-46-7 {
    padding: 46.7rem;
  }
  .xl:xl:p-46-8 {
    padding: 46.8rem;
  }
  .xl:xl:p-46-9 {
    padding: 46.9rem;
  }
  .xl:xl:p-47-0 {
    padding: 47rem;
  }
  .xl:xl:p-47-1 {
    padding: 47.1rem;
  }
  .xl:xl:p-47-2 {
    padding: 47.2rem;
  }
  .xl:xl:p-47-3 {
    padding: 47.3rem;
  }
  .xl:xl:p-47-4 {
    padding: 47.4rem;
  }
  .xl:xl:p-47-5 {
    padding: 47.5rem;
  }
  .xl:xl:p-47-6 {
    padding: 47.6rem;
  }
  .xl:xl:p-47-7 {
    padding: 47.7rem;
  }
  .xl:xl:p-47-8 {
    padding: 47.8rem;
  }
  .xl:xl:p-47-9 {
    padding: 47.9rem;
  }
  .xl:xl:p-48-0 {
    padding: 48rem;
  }
  .xl:xl:p-48-1 {
    padding: 48.1rem;
  }
  .xl:xl:p-48-2 {
    padding: 48.2rem;
  }
  .xl:xl:p-48-3 {
    padding: 48.3rem;
  }
  .xl:xl:p-48-4 {
    padding: 48.4rem;
  }
  .xl:xl:p-48-5 {
    padding: 48.5rem;
  }
  .xl:xl:p-48-6 {
    padding: 48.6rem;
  }
  .xl:xl:p-48-7 {
    padding: 48.7rem;
  }
  .xl:xl:p-48-8 {
    padding: 48.8rem;
  }
  .xl:xl:p-48-9 {
    padding: 48.9rem;
  }
  .xl:xl:p-49-0 {
    padding: 49rem;
  }
  .xl:xl:p-49-1 {
    padding: 49.1rem;
  }
  .xl:xl:p-49-2 {
    padding: 49.2rem;
  }
  .xl:xl:p-49-3 {
    padding: 49.3rem;
  }
  .xl:xl:p-49-4 {
    padding: 49.4rem;
  }
  .xl:xl:p-49-5 {
    padding: 49.5rem;
  }
  .xl:xl:p-49-6 {
    padding: 49.6rem;
  }
  .xl:xl:p-49-7 {
    padding: 49.7rem;
  }
  .xl:xl:p-49-8 {
    padding: 49.8rem;
  }
  .xl:xl:p-49-9 {
    padding: 49.9rem;
  }
  .xl:xl:p-50-0 {
    padding: 50rem;
  }
  .xl:xl:p-50-1 {
    padding: 50.1rem;
  }
  .xl:xl:p-50-2 {
    padding: 50.2rem;
  }
  .xl:xl:p-50-3 {
    padding: 50.3rem;
  }
  .xl:xl:p-50-4 {
    padding: 50.4rem;
  }
  .xl:xl:p-50-5 {
    padding: 50.5rem;
  }
  .xl:xl:p-50-6 {
    padding: 50.6rem;
  }
  .xl:xl:p-50-7 {
    padding: 50.7rem;
  }
  .xl:xl:p-50-8 {
    padding: 50.8rem;
  }
  .xl:xl:p-50-9 {
    padding: 50.9rem;
  }
  .xl:xl:p-51-0 {
    padding: 51rem;
  }
  .xl:xl:p-51-1 {
    padding: 51.1rem;
  }
  .xl:xl:p-51-2 {
    padding: 51.2rem;
  }
  .xl:xl:p-51-3 {
    padding: 51.3rem;
  }
  .xl:xl:p-51-4 {
    padding: 51.4rem;
  }
  .xl:xl:p-51-5 {
    padding: 51.5rem;
  }
  .xl:xl:p-51-6 {
    padding: 51.6rem;
  }
  .xl:xl:p-51-7 {
    padding: 51.7rem;
  }
  .xl:xl:p-51-8 {
    padding: 51.8rem;
  }
  .xl:xl:p-51-9 {
    padding: 51.9rem;
  }
  .xl:xl:p-52-0 {
    padding: 52rem;
  }
  .xl:xl:p-52-1 {
    padding: 52.1rem;
  }
  .xl:xl:p-52-2 {
    padding: 52.2rem;
  }
  .xl:xl:p-52-3 {
    padding: 52.3rem;
  }
  .xl:xl:p-52-4 {
    padding: 52.4rem;
  }
  .xl:xl:p-52-5 {
    padding: 52.5rem;
  }
  .xl:xl:p-52-6 {
    padding: 52.6rem;
  }
  .xl:xl:p-52-7 {
    padding: 52.7rem;
  }
  .xl:xl:p-52-8 {
    padding: 52.8rem;
  }
  .xl:xl:p-52-9 {
    padding: 52.9rem;
  }
  .xl:xl:p-53-0 {
    padding: 53rem;
  }
  .xl:xl:p-53-1 {
    padding: 53.1rem;
  }
  .xl:xl:p-53-2 {
    padding: 53.2rem;
  }
  .xl:xl:p-53-3 {
    padding: 53.3rem;
  }
  .xl:xl:p-53-4 {
    padding: 53.4rem;
  }
  .xl:xl:p-53-5 {
    padding: 53.5rem;
  }
  .xl:xl:p-53-6 {
    padding: 53.6rem;
  }
  .xl:xl:p-53-7 {
    padding: 53.7rem;
  }
  .xl:xl:p-53-8 {
    padding: 53.8rem;
  }
  .xl:xl:p-53-9 {
    padding: 53.9rem;
  }
  .xl:xl:p-54-0 {
    padding: 54rem;
  }
  .xl:xl:p-54-1 {
    padding: 54.1rem;
  }
  .xl:xl:p-54-2 {
    padding: 54.2rem;
  }
  .xl:xl:p-54-3 {
    padding: 54.3rem;
  }
  .xl:xl:p-54-4 {
    padding: 54.4rem;
  }
  .xl:xl:p-54-5 {
    padding: 54.5rem;
  }
  .xl:xl:p-54-6 {
    padding: 54.6rem;
  }
  .xl:xl:p-54-7 {
    padding: 54.7rem;
  }
  .xl:xl:p-54-8 {
    padding: 54.8rem;
  }
  .xl:xl:p-54-9 {
    padding: 54.9rem;
  }
  .xl:xl:p-55-0 {
    padding: 55rem;
  }
  .xl:xl:p-55-1 {
    padding: 55.1rem;
  }
  .xl:xl:p-55-2 {
    padding: 55.2rem;
  }
  .xl:xl:p-55-3 {
    padding: 55.3rem;
  }
  .xl:xl:p-55-4 {
    padding: 55.4rem;
  }
  .xl:xl:p-55-5 {
    padding: 55.5rem;
  }
  .xl:xl:p-55-6 {
    padding: 55.6rem;
  }
  .xl:xl:p-55-7 {
    padding: 55.7rem;
  }
  .xl:xl:p-55-8 {
    padding: 55.8rem;
  }
  .xl:xl:p-55-9 {
    padding: 55.9rem;
  }
  .xl:xl:p-56-0 {
    padding: 56rem;
  }
  .xl:xl:p-56-1 {
    padding: 56.1rem;
  }
  .xl:xl:p-56-2 {
    padding: 56.2rem;
  }
  .xl:xl:p-56-3 {
    padding: 56.3rem;
  }
  .xl:xl:p-56-4 {
    padding: 56.4rem;
  }
  .xl:xl:p-56-5 {
    padding: 56.5rem;
  }
  .xl:xl:p-56-6 {
    padding: 56.6rem;
  }
  .xl:xl:p-56-7 {
    padding: 56.7rem;
  }
  .xl:xl:p-56-8 {
    padding: 56.8rem;
  }
  .xl:xl:p-56-9 {
    padding: 56.9rem;
  }
  .xl:xl:p-57-0 {
    padding: 57rem;
  }
  .xl:xl:p-57-1 {
    padding: 57.1rem;
  }
  .xl:xl:p-57-2 {
    padding: 57.2rem;
  }
  .xl:xl:p-57-3 {
    padding: 57.3rem;
  }
  .xl:xl:p-57-4 {
    padding: 57.4rem;
  }
  .xl:xl:p-57-5 {
    padding: 57.5rem;
  }
  .xl:xl:p-57-6 {
    padding: 57.6rem;
  }
  .xl:xl:p-57-7 {
    padding: 57.7rem;
  }
  .xl:xl:p-57-8 {
    padding: 57.8rem;
  }
  .xl:xl:p-57-9 {
    padding: 57.9rem;
  }
  .xl:xl:p-58-0 {
    padding: 58rem;
  }
  .xl:xl:p-58-1 {
    padding: 58.1rem;
  }
  .xl:xl:p-58-2 {
    padding: 58.2rem;
  }
  .xl:xl:p-58-3 {
    padding: 58.3rem;
  }
  .xl:xl:p-58-4 {
    padding: 58.4rem;
  }
  .xl:xl:p-58-5 {
    padding: 58.5rem;
  }
  .xl:xl:p-58-6 {
    padding: 58.6rem;
  }
  .xl:xl:p-58-7 {
    padding: 58.7rem;
  }
  .xl:xl:p-58-8 {
    padding: 58.8rem;
  }
  .xl:xl:p-58-9 {
    padding: 58.9rem;
  }
  .xl:xl:p-59-0 {
    padding: 59rem;
  }
  .xl:xl:p-59-1 {
    padding: 59.1rem;
  }
  .xl:xl:p-59-2 {
    padding: 59.2rem;
  }
  .xl:xl:p-59-3 {
    padding: 59.3rem;
  }
  .xl:xl:p-59-4 {
    padding: 59.4rem;
  }
  .xl:xl:p-59-5 {
    padding: 59.5rem;
  }
  .xl:xl:p-59-6 {
    padding: 59.6rem;
  }
  .xl:xl:p-59-7 {
    padding: 59.7rem;
  }
  .xl:xl:p-59-8 {
    padding: 59.8rem;
  }
  .xl:xl:p-59-9 {
    padding: 59.9rem;
  }
  .xl:xl:p-60-0 {
    padding: 60rem;
  }
  .xl:xl:p-60-1 {
    padding: 60.1rem;
  }
  .xl:xl:p-60-2 {
    padding: 60.2rem;
  }
  .xl:xl:p-60-3 {
    padding: 60.3rem;
  }
  .xl:xl:p-60-4 {
    padding: 60.4rem;
  }
  .xl:xl:p-60-5 {
    padding: 60.5rem;
  }
  .xl:xl:p-60-6 {
    padding: 60.6rem;
  }
  .xl:xl:p-60-7 {
    padding: 60.7rem;
  }
  .xl:xl:p-60-8 {
    padding: 60.8rem;
  }
  .xl:xl:p-60-9 {
    padding: 60.9rem;
  }
  .xl:xl:p-61-0 {
    padding: 61rem;
  }
  .xl:xl:p-61-1 {
    padding: 61.1rem;
  }
  .xl:xl:p-61-2 {
    padding: 61.2rem;
  }
  .xl:xl:p-61-3 {
    padding: 61.3rem;
  }
  .xl:xl:p-61-4 {
    padding: 61.4rem;
  }
  .xl:xl:p-61-5 {
    padding: 61.5rem;
  }
  .xl:xl:p-61-6 {
    padding: 61.6rem;
  }
  .xl:xl:p-61-7 {
    padding: 61.7rem;
  }
  .xl:xl:p-61-8 {
    padding: 61.8rem;
  }
  .xl:xl:p-61-9 {
    padding: 61.9rem;
  }
  .xl:xl:p-62-0 {
    padding: 62rem;
  }
  .xl:xl:p-62-1 {
    padding: 62.1rem;
  }
  .xl:xl:p-62-2 {
    padding: 62.2rem;
  }
  .xl:xl:p-62-3 {
    padding: 62.3rem;
  }
  .xl:xl:p-62-4 {
    padding: 62.4rem;
  }
  .xl:xl:p-62-5 {
    padding: 62.5rem;
  }
  .xl:xl:p-62-6 {
    padding: 62.6rem;
  }
  .xl:xl:p-62-7 {
    padding: 62.7rem;
  }
  .xl:xl:p-62-8 {
    padding: 62.8rem;
  }
  .xl:xl:p-62-9 {
    padding: 62.9rem;
  }
  .xl:xl:p-63-0 {
    padding: 63rem;
  }
  .xl:xl:p-63-1 {
    padding: 63.1rem;
  }
  .xl:xl:p-63-2 {
    padding: 63.2rem;
  }
  .xl:xl:p-63-3 {
    padding: 63.3rem;
  }
  .xl:xl:p-63-4 {
    padding: 63.4rem;
  }
  .xl:xl:p-63-5 {
    padding: 63.5rem;
  }
  .xl:xl:p-63-6 {
    padding: 63.6rem;
  }
  .xl:xl:p-63-7 {
    padding: 63.7rem;
  }
  .xl:xl:p-63-8 {
    padding: 63.8rem;
  }
  .xl:xl:p-63-9 {
    padding: 63.9rem;
  }
  .xl:xl:p-64-0 {
    padding: 64rem;
  }
  .xl:xl:p-64-1 {
    padding: 64.1rem;
  }
  .xl:xl:p-64-2 {
    padding: 64.2rem;
  }
  .xl:xl:p-64-3 {
    padding: 64.3rem;
  }
  .xl:xl:p-64-4 {
    padding: 64.4rem;
  }
  .xl:xl:p-64-5 {
    padding: 64.5rem;
  }
  .xl:xl:p-64-6 {
    padding: 64.6rem;
  }
  .xl:xl:p-64-7 {
    padding: 64.7rem;
  }
  .xl:xl:p-64-8 {
    padding: 64.8rem;
  }
  .xl:xl:p-64-9 {
    padding: 64.9rem;
  }
  .xl:xl:p-65-0 {
    padding: 65rem;
  }
  .xl:xl:p-65-1 {
    padding: 65.1rem;
  }
  .xl:xl:p-65-2 {
    padding: 65.2rem;
  }
  .xl:xl:p-65-3 {
    padding: 65.3rem;
  }
  .xl:xl:p-65-4 {
    padding: 65.4rem;
  }
  .xl:xl:p-65-5 {
    padding: 65.5rem;
  }
  .xl:xl:p-65-6 {
    padding: 65.6rem;
  }
  .xl:xl:p-65-7 {
    padding: 65.7rem;
  }
  .xl:xl:p-65-8 {
    padding: 65.8rem;
  }
  .xl:xl:p-65-9 {
    padding: 65.9rem;
  }
  .xl:xl:p-66-0 {
    padding: 66rem;
  }
  .xl:xl:p-66-1 {
    padding: 66.1rem;
  }
  .xl:xl:p-66-2 {
    padding: 66.2rem;
  }
  .xl:xl:p-66-3 {
    padding: 66.3rem;
  }
  .xl:xl:p-66-4 {
    padding: 66.4rem;
  }
  .xl:xl:p-66-5 {
    padding: 66.5rem;
  }
  .xl:xl:p-66-6 {
    padding: 66.6rem;
  }
  .xl:xl:p-66-7 {
    padding: 66.7rem;
  }
  .xl:xl:p-66-8 {
    padding: 66.8rem;
  }
  .xl:xl:p-66-9 {
    padding: 66.9rem;
  }
  .xl:xl:p-67-0 {
    padding: 67rem;
  }
  .xl:xl:p-67-1 {
    padding: 67.1rem;
  }
  .xl:xl:p-67-2 {
    padding: 67.2rem;
  }
  .xl:xl:p-67-3 {
    padding: 67.3rem;
  }
  .xl:xl:p-67-4 {
    padding: 67.4rem;
  }
  .xl:xl:p-67-5 {
    padding: 67.5rem;
  }
  .xl:xl:p-67-6 {
    padding: 67.6rem;
  }
  .xl:xl:p-67-7 {
    padding: 67.7rem;
  }
  .xl:xl:p-67-8 {
    padding: 67.8rem;
  }
  .xl:xl:p-67-9 {
    padding: 67.9rem;
  }
  .xl:xl:p-68-0 {
    padding: 68rem;
  }
  .xl:xl:p-68-1 {
    padding: 68.1rem;
  }
  .xl:xl:p-68-2 {
    padding: 68.2rem;
  }
  .xl:xl:p-68-3 {
    padding: 68.3rem;
  }
  .xl:xl:p-68-4 {
    padding: 68.4rem;
  }
  .xl:xl:p-68-5 {
    padding: 68.5rem;
  }
  .xl:xl:p-68-6 {
    padding: 68.6rem;
  }
  .xl:xl:p-68-7 {
    padding: 68.7rem;
  }
  .xl:xl:p-68-8 {
    padding: 68.8rem;
  }
  .xl:xl:p-68-9 {
    padding: 68.9rem;
  }
  .xl:xl:p-69-0 {
    padding: 69rem;
  }
  .xl:xl:p-69-1 {
    padding: 69.1rem;
  }
  .xl:xl:p-69-2 {
    padding: 69.2rem;
  }
  .xl:xl:p-69-3 {
    padding: 69.3rem;
  }
  .xl:xl:p-69-4 {
    padding: 69.4rem;
  }
  .xl:xl:p-69-5 {
    padding: 69.5rem;
  }
  .xl:xl:p-69-6 {
    padding: 69.6rem;
  }
  .xl:xl:p-69-7 {
    padding: 69.7rem;
  }
  .xl:xl:p-69-8 {
    padding: 69.8rem;
  }
  .xl:xl:p-69-9 {
    padding: 69.9rem;
  }
  .xl:xl:p-70-0 {
    padding: 70rem;
  }
  .xl:xl:p-70-1 {
    padding: 70.1rem;
  }
  .xl:xl:p-70-2 {
    padding: 70.2rem;
  }
  .xl:xl:p-70-3 {
    padding: 70.3rem;
  }
  .xl:xl:p-70-4 {
    padding: 70.4rem;
  }
  .xl:xl:p-70-5 {
    padding: 70.5rem;
  }
  .xl:xl:p-70-6 {
    padding: 70.6rem;
  }
  .xl:xl:p-70-7 {
    padding: 70.7rem;
  }
  .xl:xl:p-70-8 {
    padding: 70.8rem;
  }
  .xl:xl:p-70-9 {
    padding: 70.9rem;
  }
  .xl:xl:p-71-0 {
    padding: 71rem;
  }
  .xl:xl:p-71-1 {
    padding: 71.1rem;
  }
  .xl:xl:p-71-2 {
    padding: 71.2rem;
  }
  .xl:xl:p-71-3 {
    padding: 71.3rem;
  }
  .xl:xl:p-71-4 {
    padding: 71.4rem;
  }
  .xl:xl:p-71-5 {
    padding: 71.5rem;
  }
  .xl:xl:p-71-6 {
    padding: 71.6rem;
  }
  .xl:xl:p-71-7 {
    padding: 71.7rem;
  }
  .xl:xl:p-71-8 {
    padding: 71.8rem;
  }
  .xl:xl:p-71-9 {
    padding: 71.9rem;
  }
  .xl:xl:p-72-0 {
    padding: 72rem;
  }
  .xl:xl:p-72-1 {
    padding: 72.1rem;
  }
  .xl:xl:p-72-2 {
    padding: 72.2rem;
  }
  .xl:xl:p-72-3 {
    padding: 72.3rem;
  }
  .xl:xl:p-72-4 {
    padding: 72.4rem;
  }
  .xl:xl:p-72-5 {
    padding: 72.5rem;
  }
  .xl:xl:p-72-6 {
    padding: 72.6rem;
  }
  .xl:xl:p-72-7 {
    padding: 72.7rem;
  }
  .xl:xl:p-72-8 {
    padding: 72.8rem;
  }
  .xl:xl:p-72-9 {
    padding: 72.9rem;
  }
  .xl:xl:p-0 {
    padding: 0;
  }
  .xl:xl:p-1 {
    padding: 1rem;
  }
  .xl:xl:p-2 {
    padding: 2rem;
  }
  .xl:xl:p-3 {
    padding: 4rem;
  }
  .xl:xl:p-4 {
    padding: 6rem;
  }
  .xl:xl:p-5 {
    padding: 8rem;
  }
  .xl:xl:pl-0 {
    padding-left: 0;
  }
  .xl:xl:pl-1 {
    padding-left: 1rem;
  }
  .xl:xl:pl-2 {
    padding-left: 2rem;
  }
  .xl:xl:pl-3 {
    padding-left: 4rem;
  }
  .xl:xl:pl-4 {
    padding-left: 6rem;
  }
  .xl:xl:pl-5 {
    padding-left: 8rem;
  }
  .xl:xl:pr-0 {
    padding-right: 0;
  }
  .xl:xl:pr-1 {
    padding-right: 1rem;
  }
  .xl:xl:pr-2 {
    padding-right: 2rem;
  }
  .xl:xl:pr-3 {
    padding-right: 4rem;
  }
  .xl:xl:pr-4 {
    padding-right: 6rem;
  }
  .xl:xl:pr-5 {
    padding-right: 8rem;
  }
  .xl:xl:pt-0 {
    padding-top: 0;
  }
  .xl:xl:pt-1 {
    padding-top: 1rem;
  }
  .xl:xl:pt-2 {
    padding-top: 2rem;
  }
  .xl:xl:pt-3 {
    padding-top: 4rem;
  }
  .xl:xl:pt-4 {
    padding-top: 6rem;
  }
  .xl:xl:pt-5 {
    padding-top: 8rem;
  }
  .xl:xl:pb-0 {
    padding-bottom: 0;
  }
  .xl:xl:pb-1 {
    padding-bottom: 1rem;
  }
  .xl:xl:pb-2 {
    padding-bottom: 2rem;
  }
  .xl:xl:pb-3 {
    padding-bottom: 4rem;
  }
  .xl:xl:pb-4 {
    padding-bottom: 6rem;
  }
  .xl:xl:pb-5 {
    padding-bottom: 8rem;
  }
  .xl:xl:m-0 {
    margin: 0;
  }
  .xl:xl:m-1 {
    margin: 1rem;
  }
  .xl:xl:m-2 {
    margin: 2rem;
  }
  .xl:xl:m-3 {
    margin: 4rem;
  }
  .xl:xl:m-4 {
    margin: 6rem;
  }
  .xl:xl:m-5 {
    margin: 8rem;
  }
  .xl:xl:ml-0 {
    margin-left: 0;
  }
  .xl:xl:ml-1 {
    margin-left: 1rem;
  }
  .xl:xl:ml-2 {
    margin-left: 2rem;
  }
  .xl:xl:ml-3 {
    margin-left: 4rem;
  }
  .xl:xl:ml-4 {
    margin-left: 6rem;
  }
  .xl:xl:ml-5 {
    margin-left: 8rem;
  }
  .xl:xl:mr-0 {
    margin-right: 0;
  }
  .xl:xl:mr-1 {
    margin-right: 1rem;
  }
  .xl:xl:mr-2 {
    margin-right: 2rem;
  }
  .xl:xl:mr-3 {
    margin-right: 4rem;
  }
  .xl:xl:mr-4 {
    margin-right: 6rem;
  }
  .xl:xl:mr-5 {
    margin-right: 8rem;
  }
  .xl:xl:mt-0 {
    margin-top: 0;
  }
  .xl:xl:mt-1 {
    margin-top: 1rem;
  }
  .xl:xl:mt-2 {
    margin-top: 2rem;
  }
  .xl:xl:mt-3 {
    margin-top: 4rem;
  }
  .xl:xl:mt-4 {
    margin-top: 6rem;
  }
  .xl:xl:mt-5 {
    margin-top: 8rem;
  }
  .xl:xl:mb-0 {
    margin-bottom: 0;
  }
  .xl:xl:mb-1 {
    margin-bottom: 1rem;
  }
  .xl:xl:mb-2 {
    margin-bottom: 2rem;
  }
  .xl:xl:mb-3 {
    margin-bottom: 4rem;
  }
  .xl:xl:mb-4 {
    margin-bottom: 6rem;
  }
  .xl:xl:mb-5 {
    margin-bottom: 8rem;
  }
  .xl:xl:o-10 {
    opacity: 0.1;
  }
  .xl:xl:o-20 {
    opacity: 0.2;
  }
  .xl:xl:o-30 {
    opacity: 0.3;
  }
  .xl:xl:o-40 {
    opacity: 0.4;
  }
  .xl:xl:o-50 {
    opacity: 0.5;
  }
  .xl:xl:o-60 {
    opacity: 0.6;
  }
  .xl:xl:o-70 {
    opacity: 0.7;
  }
  .xl:xl:o-80 {
    opacity: 0.8;
  }
  .xl:xl:o-90 {
    opacity: 0.9;
  }
  .xl:xl:o-100 {
    opacity: 1;
  }
  .xl:xl:br {
    border-radius: 0.8rem;
  }
  .xl:xl:br-none {
    border-radius: 0;
  }
  .xl:xl:br-xs {
    border-radius: 0.2rem;
  }
  .xl:xl:br-sm {
    border-radius: 0.4rem;
  }
  .xl:xl:br-lg {
    border-radius: 1.6rem;
  }
  .xl:xl:br-full {
    border-radius: 50%;
  }
  .xl:xl:display-n {
    display: none;
  }
  .xl:xl:display-b {
    display: block;
  }
  .xl:xl:display-f {
    display: flex;
  }
  .xl:xl:display-i {
    display: inline;
  }
  .xl:xl:display-i-b {
    display: inline-block;
  }
  .xl:xl:align-f-start {
    align-items: flex-start;
  }
  .xl:xl:align-f-end {
    align-items: flex-end;
  }
  .xl:xl:align-center {
    align-items: center;
  }
  .xl:xl:flex-row {
    flex-direction: row;
  }
  .xl:xl:flex-column {
    flex-direction: column;
  }
  .xl:xl:justify-between {
    justify-content: space-between;
  }
  .xl:xl:justify-f-end {
    justify-content: flex-end;
  }
  .xl:xl:justify-center {
    justify-content: center;
  }
  .xl:xl:justify-around {
    justify-content: space-around;
  }
  .xl:xl:justify-evenly {
    justify-content: space-evenly;
  }
  .xl:xl:text-center {
    text-align: center;
  }
  .xl:xl:text-start {
    text-align: start;
  }
  .xl:xl:text-end {
    text-align: end;
  }
  .xl:xl:text-left {
    text-align: left;
  }
  .xl:xl:text-right {
    text-align: right;
  }
  .xl:xl:text-justify {
    text-align: justify;
  }
  .xl:xl:text-d2xl {
    font-size: 7.2rem;
  }
  .xl:xl:text-dxl {
    font-size: 6rem;
  }
  .xl:xl:text-dlg {
    font-size: 4.8rem;
  }
  .xl:xl:text-dmd {
    font-size: 3.6rem;
  }
  .xl:xl:text-dsm {
    font-size: 3.2rem;
  }
  .xl:xl:text-dxs {
    font-size: 2.4rem;
  }
  .xl:xl:text-xxl {
    font-size: 2.4rem;
  }
  .xl:xl:text-xl {
    font-size: 2rem;
  }
  .xl:xl:text-lg {
    font-size: 1.8rem;
  }
  .xl:xl:text-md {
    font-size: 1.6rem;
  }
  .xl:xl:text-sm {
    font-size: 1.4rem;
  }
  .xl:xl:text-xs {
    font-size: 1.2rem;
  }
  .xl:xl:text-macro {
    font-size: 1rem;
  }
  .gap-0 {
    gap: 0rem;
  }
  .gap-1 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 2rem;
  }
  .gap-3 {
    gap: 3rem;
  }
  .gap-4 {
    gap: 4rem;
  }
  .gap-5 {
    gap: 5rem;
  }
  .gap-6 {
    gap: 6rem;
  }
  .gap-7 {
    gap: 7rem;
  }
  .gap-8 {
    gap: 8rem;
  }
  .gap-9 {
    gap: 9rem;
  }
  .gap-10 {
    gap: 10rem;
  }
  .gap-11 {
    gap: 11rem;
  }
  .gap-12 {
    gap: 12rem;
  }
  .gap-13 {
    gap: 13rem;
  }
  .gap-14 {
    gap: 14rem;
  }
  .gap-15 {
    gap: 15rem;
  }
  .gap-16 {
    gap: 16rem;
  }
  .gap-17 {
    gap: 17rem;
  }
  .gap-18 {
    gap: 18rem;
  }
  .gap-19 {
    gap: 19rem;
  }
  .gap-20 {
    gap: 20rem;
  }
  .gap-21 {
    gap: 21rem;
  }
  .gap-22 {
    gap: 22rem;
  }
  .gap-23 {
    gap: 23rem;
  }
  .gap-24 {
    gap: 24rem;
  }
  .gap-25 {
    gap: 25rem;
  }
  .gap-26 {
    gap: 26rem;
  }
  .gap-27 {
    gap: 27rem;
  }
  .gap-28 {
    gap: 28rem;
  }
  .gap-29 {
    gap: 29rem;
  }
  .gap-30 {
    gap: 30rem;
  }
  .gap-31 {
    gap: 31rem;
  }
  .gap-32 {
    gap: 32rem;
  }
  .gap-33 {
    gap: 33rem;
  }
  .gap-34 {
    gap: 34rem;
  }
  .gap-35 {
    gap: 35rem;
  }
  .gap-36 {
    gap: 36rem;
  }
  .gap-37 {
    gap: 37rem;
  }
  .gap-38 {
    gap: 38rem;
  }
  .gap-39 {
    gap: 39rem;
  }
  .gap-40 {
    gap: 40rem;
  }
  .gap-41 {
    gap: 41rem;
  }
  .gap-42 {
    gap: 42rem;
  }
  .gap-43 {
    gap: 43rem;
  }
  .gap-44 {
    gap: 44rem;
  }
  .gap-45 {
    gap: 45rem;
  }
  .gap-46 {
    gap: 46rem;
  }
  .gap-47 {
    gap: 47rem;
  }
  .gap-48 {
    gap: 48rem;
  }
  .gap-49 {
    gap: 49rem;
  }
  .w-0 {
    width: 0rem;
  }
  .w-1 {
    width: 1rem;
  }
  .w-2 {
    width: 2rem;
  }
  .w-3 {
    width: 3rem;
  }
  .w-4 {
    width: 4rem;
  }
  .w-5 {
    width: 5rem;
  }
  .w-6 {
    width: 6rem;
  }
  .w-7 {
    width: 7rem;
  }
  .w-8 {
    width: 8rem;
  }
  .w-9 {
    width: 9rem;
  }
  .w-10 {
    width: 10rem;
  }
  .w-11 {
    width: 11rem;
  }
  .w-12 {
    width: 12rem;
  }
  .w-13 {
    width: 13rem;
  }
  .w-14 {
    width: 14rem;
  }
  .w-15 {
    width: 15rem;
  }
  .w-16 {
    width: 16rem;
  }
  .w-17 {
    width: 17rem;
  }
  .w-18 {
    width: 18rem;
  }
  .w-19 {
    width: 19rem;
  }
  .w-20 {
    width: 20rem;
  }
  .w-21 {
    width: 21rem;
  }
  .w-22 {
    width: 22rem;
  }
  .w-23 {
    width: 23rem;
  }
  .w-24 {
    width: 24rem;
  }
  .w-25 {
    width: 25rem;
  }
  .w-26 {
    width: 26rem;
  }
  .w-27 {
    width: 27rem;
  }
  .w-28 {
    width: 28rem;
  }
  .w-29 {
    width: 29rem;
  }
  .w-30 {
    width: 30rem;
  }
  .w-31 {
    width: 31rem;
  }
  .w-32 {
    width: 32rem;
  }
  .w-33 {
    width: 33rem;
  }
  .w-34 {
    width: 34rem;
  }
  .w-35 {
    width: 35rem;
  }
  .w-36 {
    width: 36rem;
  }
  .w-37 {
    width: 37rem;
  }
  .w-38 {
    width: 38rem;
  }
  .w-39 {
    width: 39rem;
  }
  .w-40 {
    width: 40rem;
  }
  .w-41 {
    width: 41rem;
  }
  .w-42 {
    width: 42rem;
  }
  .w-43 {
    width: 43rem;
  }
  .w-44 {
    width: 44rem;
  }
  .w-45 {
    width: 45rem;
  }
  .w-46 {
    width: 46rem;
  }
  .w-47 {
    width: 47rem;
  }
  .w-48 {
    width: 48rem;
  }
  .w-49 {
    width: 49rem;
  }
  .w-50 {
    width: 50rem;
  }
  .w-51 {
    width: 51rem;
  }
  .w-52 {
    width: 52rem;
  }
  .w-53 {
    width: 53rem;
  }
  .w-54 {
    width: 54rem;
  }
  .w-55 {
    width: 55rem;
  }
  .w-56 {
    width: 56rem;
  }
  .w-57 {
    width: 57rem;
  }
  .w-58 {
    width: 58rem;
  }
  .w-59 {
    width: 59rem;
  }
  .w-60 {
    width: 60rem;
  }
  .w-61 {
    width: 61rem;
  }
  .w-62 {
    width: 62rem;
  }
  .w-63 {
    width: 63rem;
  }
  .w-64 {
    width: 64rem;
  }
  .w-65 {
    width: 65rem;
  }
  .w-66 {
    width: 66rem;
  }
  .w-67 {
    width: 67rem;
  }
  .w-68 {
    width: 68rem;
  }
  .w-69 {
    width: 69rem;
  }
  .w-70 {
    width: 70rem;
  }
  .w-71 {
    width: 71rem;
  }
  .w-72 {
    width: 72rem;
  }
  .w-73 {
    width: 73rem;
  }
  .w-74 {
    width: 74rem;
  }
  .w-75 {
    width: 75rem;
  }
  .w-76 {
    width: 76rem;
  }
  .w-77 {
    width: 77rem;
  }
  .w-78 {
    width: 78rem;
  }
  .w-79 {
    width: 79rem;
  }
  .w-80 {
    width: 80rem;
  }
  .w-81 {
    width: 81rem;
  }
  .w-82 {
    width: 82rem;
  }
  .w-83 {
    width: 83rem;
  }
  .w-84 {
    width: 84rem;
  }
  .w-85 {
    width: 85rem;
  }
  .w-86 {
    width: 86rem;
  }
  .w-87 {
    width: 87rem;
  }
  .w-88 {
    width: 88rem;
  }
  .w-89 {
    width: 89rem;
  }
  .w-90 {
    width: 90rem;
  }
  .w-91 {
    width: 91rem;
  }
  .w-92 {
    width: 92rem;
  }
  .w-93 {
    width: 93rem;
  }
  .w-94 {
    width: 94rem;
  }
  .w-95 {
    width: 95rem;
  }
  .w-96 {
    width: 96rem;
  }
  .w-97 {
    width: 97rem;
  }
  .w-98 {
    width: 98rem;
  }
  .w-99 {
    width: 99rem;
  }
  .w-100 {
    width: 100rem;
  }
  .w-101 {
    width: 101rem;
  }
  .w-102 {
    width: 102rem;
  }
  .w-103 {
    width: 103rem;
  }
  .w-104 {
    width: 104rem;
  }
  .w-105 {
    width: 105rem;
  }
  .w-106 {
    width: 106rem;
  }
  .w-107 {
    width: 107rem;
  }
  .w-108 {
    width: 108rem;
  }
  .w-109 {
    width: 109rem;
  }
  .w-110 {
    width: 110rem;
  }
  .w-111 {
    width: 111rem;
  }
  .w-112 {
    width: 112rem;
  }
  .w-113 {
    width: 113rem;
  }
  .w-114 {
    width: 114rem;
  }
  .w-115 {
    width: 115rem;
  }
  .w-116 {
    width: 116rem;
  }
  .w-117 {
    width: 117rem;
  }
  .w-118 {
    width: 118rem;
  }
  .w-119 {
    width: 119rem;
  }
  .w-120 {
    width: 120rem;
  }
  .w-121 {
    width: 121rem;
  }
  .w-122 {
    width: 122rem;
  }
  .w-123 {
    width: 123rem;
  }
  .w-124 {
    width: 124rem;
  }
  .w-125 {
    width: 125rem;
  }
  .w-126 {
    width: 126rem;
  }
  .w-127 {
    width: 127rem;
  }
  .w-128 {
    width: 128rem;
  }
  .w-129 {
    width: 129rem;
  }
  .w-130 {
    width: 130rem;
  }
  .w-131 {
    width: 131rem;
  }
  .w-132 {
    width: 132rem;
  }
  .w-133 {
    width: 133rem;
  }
  .w-134 {
    width: 134rem;
  }
  .w-135 {
    width: 135rem;
  }
  .w-136 {
    width: 136rem;
  }
  .w-137 {
    width: 137rem;
  }
  .w-138 {
    width: 138rem;
  }
  .w-139 {
    width: 139rem;
  }
  .w-140 {
    width: 140rem;
  }
  .w-141 {
    width: 141rem;
  }
  .w-142 {
    width: 142rem;
  }
  .w-143 {
    width: 143rem;
  }
  .w-144 {
    width: 144rem;
  }
  .w-145 {
    width: 145rem;
  }
  .w-146 {
    width: 146rem;
  }
  .w-147 {
    width: 147rem;
  }
  .w-148 {
    width: 148rem;
  }
  .w-149 {
    width: 149rem;
  }
  .w-150 {
    width: 150rem;
  }
  .w-151 {
    width: 151rem;
  }
  .w-152 {
    width: 152rem;
  }
  .w-153 {
    width: 153rem;
  }
  .w-154 {
    width: 154rem;
  }
  .w-155 {
    width: 155rem;
  }
  .w-156 {
    width: 156rem;
  }
  .w-157 {
    width: 157rem;
  }
  .w-158 {
    width: 158rem;
  }
  .w-159 {
    width: 159rem;
  }
  .w-160 {
    width: 160rem;
  }
  .w-161 {
    width: 161rem;
  }
  .w-162 {
    width: 162rem;
  }
  .w-163 {
    width: 163rem;
  }
  .w-164 {
    width: 164rem;
  }
  .w-165 {
    width: 165rem;
  }
  .w-166 {
    width: 166rem;
  }
  .w-167 {
    width: 167rem;
  }
  .w-168 {
    width: 168rem;
  }
  .w-169 {
    width: 169rem;
  }
  .w-170 {
    width: 170rem;
  }
  .w-171 {
    width: 171rem;
  }
  .w-172 {
    width: 172rem;
  }
  .w-173 {
    width: 173rem;
  }
  .w-174 {
    width: 174rem;
  }
  .w-175 {
    width: 175rem;
  }
  .w-176 {
    width: 176rem;
  }
  .w-177 {
    width: 177rem;
  }
  .w-178 {
    width: 178rem;
  }
  .w-179 {
    width: 179rem;
  }
  .w-180 {
    width: 180rem;
  }
  .w-181 {
    width: 181rem;
  }
  .w-182 {
    width: 182rem;
  }
  .w-183 {
    width: 183rem;
  }
  .w-184 {
    width: 184rem;
  }
  .w-185 {
    width: 185rem;
  }
  .w-186 {
    width: 186rem;
  }
  .w-187 {
    width: 187rem;
  }
  .w-188 {
    width: 188rem;
  }
  .w-189 {
    width: 189rem;
  }
  .w-190 {
    width: 190rem;
  }
  .w-191 {
    width: 191rem;
  }
  .w-192 {
    width: 192rem;
  }
  .w-193 {
    width: 193rem;
  }
  .w-194 {
    width: 194rem;
  }
  .w-195 {
    width: 195rem;
  }
  .w-196 {
    width: 196rem;
  }
  .w-197 {
    width: 197rem;
  }
  .w-198 {
    width: 198rem;
  }
  .w-199 {
    width: 199rem;
  }
  .w-200 {
    width: 200rem;
  }
  .w-201 {
    width: 201rem;
  }
  .w-202 {
    width: 202rem;
  }
  .w-203 {
    width: 203rem;
  }
  .w-204 {
    width: 204rem;
  }
  .w-205 {
    width: 205rem;
  }
  .w-206 {
    width: 206rem;
  }
  .w-207 {
    width: 207rem;
  }
  .w-208 {
    width: 208rem;
  }
  .w-209 {
    width: 209rem;
  }
  .w-210 {
    width: 210rem;
  }
  .w-211 {
    width: 211rem;
  }
  .w-212 {
    width: 212rem;
  }
  .w-213 {
    width: 213rem;
  }
  .w-214 {
    width: 214rem;
  }
  .w-215 {
    width: 215rem;
  }
  .w-216 {
    width: 216rem;
  }
  .w-217 {
    width: 217rem;
  }
  .w-218 {
    width: 218rem;
  }
  .w-219 {
    width: 219rem;
  }
  .w-220 {
    width: 220rem;
  }
  .w-221 {
    width: 221rem;
  }
  .w-222 {
    width: 222rem;
  }
  .w-223 {
    width: 223rem;
  }
  .w-224 {
    width: 224rem;
  }
  .w-225 {
    width: 225rem;
  }
  .w-226 {
    width: 226rem;
  }
  .w-227 {
    width: 227rem;
  }
  .w-228 {
    width: 228rem;
  }
  .w-229 {
    width: 229rem;
  }
  .w-230 {
    width: 230rem;
  }
  .w-231 {
    width: 231rem;
  }
  .w-232 {
    width: 232rem;
  }
  .w-233 {
    width: 233rem;
  }
  .w-234 {
    width: 234rem;
  }
  .w-235 {
    width: 235rem;
  }
  .w-236 {
    width: 236rem;
  }
  .w-237 {
    width: 237rem;
  }
  .w-238 {
    width: 238rem;
  }
  .w-239 {
    width: 239rem;
  }
  .w-240 {
    width: 240rem;
  }
  .w-241 {
    width: 241rem;
  }
  .w-242 {
    width: 242rem;
  }
  .w-243 {
    width: 243rem;
  }
  .w-244 {
    width: 244rem;
  }
  .w-245 {
    width: 245rem;
  }
  .w-246 {
    width: 246rem;
  }
  .w-247 {
    width: 247rem;
  }
  .w-248 {
    width: 248rem;
  }
  .w-249 {
    width: 249rem;
  }
  .w-250 {
    width: 250rem;
  }
  .w-251 {
    width: 251rem;
  }
  .w-252 {
    width: 252rem;
  }
  .w-253 {
    width: 253rem;
  }
  .w-254 {
    width: 254rem;
  }
  .w-255 {
    width: 255rem;
  }
  .w-256 {
    width: 256rem;
  }
  .w-257 {
    width: 257rem;
  }
  .w-258 {
    width: 258rem;
  }
  .w-259 {
    width: 259rem;
  }
  .w-260 {
    width: 260rem;
  }
  .w-261 {
    width: 261rem;
  }
  .w-262 {
    width: 262rem;
  }
  .w-263 {
    width: 263rem;
  }
  .w-264 {
    width: 264rem;
  }
  .w-265 {
    width: 265rem;
  }
  .w-266 {
    width: 266rem;
  }
  .w-267 {
    width: 267rem;
  }
  .w-268 {
    width: 268rem;
  }
  .w-269 {
    width: 269rem;
  }
  .w-270 {
    width: 270rem;
  }
  .w-271 {
    width: 271rem;
  }
  .w-272 {
    width: 272rem;
  }
  .w-273 {
    width: 273rem;
  }
  .w-274 {
    width: 274rem;
  }
  .w-275 {
    width: 275rem;
  }
  .w-276 {
    width: 276rem;
  }
  .w-277 {
    width: 277rem;
  }
  .w-278 {
    width: 278rem;
  }
  .w-279 {
    width: 279rem;
  }
  .w-280 {
    width: 280rem;
  }
  .w-281 {
    width: 281rem;
  }
  .w-282 {
    width: 282rem;
  }
  .w-283 {
    width: 283rem;
  }
  .w-284 {
    width: 284rem;
  }
  .w-285 {
    width: 285rem;
  }
  .w-286 {
    width: 286rem;
  }
  .w-287 {
    width: 287rem;
  }
  .w-288 {
    width: 288rem;
  }
  .w-289 {
    width: 289rem;
  }
  .w-290 {
    width: 290rem;
  }
  .w-291 {
    width: 291rem;
  }
  .w-292 {
    width: 292rem;
  }
  .w-293 {
    width: 293rem;
  }
  .w-294 {
    width: 294rem;
  }
  .w-295 {
    width: 295rem;
  }
  .w-296 {
    width: 296rem;
  }
  .w-297 {
    width: 297rem;
  }
  .w-298 {
    width: 298rem;
  }
  .w-299 {
    width: 299rem;
  }
  .w-300 {
    width: 300rem;
  }
  .w-301 {
    width: 301rem;
  }
  .w-302 {
    width: 302rem;
  }
  .w-303 {
    width: 303rem;
  }
  .w-304 {
    width: 304rem;
  }
  .w-305 {
    width: 305rem;
  }
  .w-306 {
    width: 306rem;
  }
  .w-307 {
    width: 307rem;
  }
  .w-308 {
    width: 308rem;
  }
  .w-309 {
    width: 309rem;
  }
  .w-310 {
    width: 310rem;
  }
  .w-311 {
    width: 311rem;
  }
  .w-312 {
    width: 312rem;
  }
  .w-313 {
    width: 313rem;
  }
  .w-314 {
    width: 314rem;
  }
  .w-315 {
    width: 315rem;
  }
  .w-316 {
    width: 316rem;
  }
  .w-317 {
    width: 317rem;
  }
  .w-318 {
    width: 318rem;
  }
  .w-319 {
    width: 319rem;
  }
  .w-320 {
    width: 320rem;
  }
  .w-321 {
    width: 321rem;
  }
  .w-322 {
    width: 322rem;
  }
  .w-323 {
    width: 323rem;
  }
  .w-324 {
    width: 324rem;
  }
  .w-325 {
    width: 325rem;
  }
  .w-326 {
    width: 326rem;
  }
  .w-327 {
    width: 327rem;
  }
  .w-328 {
    width: 328rem;
  }
  .w-329 {
    width: 329rem;
  }
  .w-330 {
    width: 330rem;
  }
  .w-331 {
    width: 331rem;
  }
  .w-332 {
    width: 332rem;
  }
  .w-333 {
    width: 333rem;
  }
  .w-334 {
    width: 334rem;
  }
  .w-335 {
    width: 335rem;
  }
  .w-336 {
    width: 336rem;
  }
  .w-337 {
    width: 337rem;
  }
  .w-338 {
    width: 338rem;
  }
  .w-339 {
    width: 339rem;
  }
  .w-340 {
    width: 340rem;
  }
  .w-341 {
    width: 341rem;
  }
  .w-342 {
    width: 342rem;
  }
  .w-343 {
    width: 343rem;
  }
  .w-344 {
    width: 344rem;
  }
  .w-345 {
    width: 345rem;
  }
  .w-346 {
    width: 346rem;
  }
  .w-347 {
    width: 347rem;
  }
  .w-348 {
    width: 348rem;
  }
  .w-349 {
    width: 349rem;
  }
  .w-350 {
    width: 350rem;
  }
  .w-351 {
    width: 351rem;
  }
  .w-352 {
    width: 352rem;
  }
  .w-353 {
    width: 353rem;
  }
  .w-354 {
    width: 354rem;
  }
  .w-355 {
    width: 355rem;
  }
  .w-356 {
    width: 356rem;
  }
  .w-357 {
    width: 357rem;
  }
  .w-358 {
    width: 358rem;
  }
  .w-359 {
    width: 359rem;
  }
  .w-360 {
    width: 360rem;
  }
  .w-361 {
    width: 361rem;
  }
  .w-362 {
    width: 362rem;
  }
  .w-363 {
    width: 363rem;
  }
  .w-364 {
    width: 364rem;
  }
  .w-365 {
    width: 365rem;
  }
  .w-366 {
    width: 366rem;
  }
  .w-367 {
    width: 367rem;
  }
  .w-368 {
    width: 368rem;
  }
  .w-369 {
    width: 369rem;
  }
  .w-370 {
    width: 370rem;
  }
  .w-371 {
    width: 371rem;
  }
  .w-372 {
    width: 372rem;
  }
  .w-373 {
    width: 373rem;
  }
  .w-374 {
    width: 374rem;
  }
  .w-375 {
    width: 375rem;
  }
  .w-376 {
    width: 376rem;
  }
  .w-377 {
    width: 377rem;
  }
  .w-378 {
    width: 378rem;
  }
  .w-379 {
    width: 379rem;
  }
  .w-380 {
    width: 380rem;
  }
  .w-381 {
    width: 381rem;
  }
  .w-382 {
    width: 382rem;
  }
  .w-383 {
    width: 383rem;
  }
  .w-384 {
    width: 384rem;
  }
  .w-385 {
    width: 385rem;
  }
  .w-386 {
    width: 386rem;
  }
  .w-387 {
    width: 387rem;
  }
  .w-388 {
    width: 388rem;
  }
  .w-389 {
    width: 389rem;
  }
  .w-390 {
    width: 390rem;
  }
  .w-391 {
    width: 391rem;
  }
  .w-392 {
    width: 392rem;
  }
  .w-393 {
    width: 393rem;
  }
  .w-394 {
    width: 394rem;
  }
  .w-395 {
    width: 395rem;
  }
  .w-396 {
    width: 396rem;
  }
  .w-397 {
    width: 397rem;
  }
  .w-398 {
    width: 398rem;
  }
  .w-399 {
    width: 399rem;
  }
  .w-400 {
    width: 400rem;
  }
  .w-401 {
    width: 401rem;
  }
  .w-402 {
    width: 402rem;
  }
  .w-403 {
    width: 403rem;
  }
  .w-404 {
    width: 404rem;
  }
  .w-405 {
    width: 405rem;
  }
  .w-406 {
    width: 406rem;
  }
  .w-407 {
    width: 407rem;
  }
  .w-408 {
    width: 408rem;
  }
  .w-409 {
    width: 409rem;
  }
  .w-410 {
    width: 410rem;
  }
  .w-411 {
    width: 411rem;
  }
  .w-412 {
    width: 412rem;
  }
  .w-413 {
    width: 413rem;
  }
  .w-414 {
    width: 414rem;
  }
  .w-415 {
    width: 415rem;
  }
  .w-416 {
    width: 416rem;
  }
  .w-417 {
    width: 417rem;
  }
  .w-418 {
    width: 418rem;
  }
  .w-419 {
    width: 419rem;
  }
  .w-420 {
    width: 420rem;
  }
  .w-421 {
    width: 421rem;
  }
  .w-422 {
    width: 422rem;
  }
  .w-423 {
    width: 423rem;
  }
  .w-424 {
    width: 424rem;
  }
  .w-425 {
    width: 425rem;
  }
  .w-426 {
    width: 426rem;
  }
  .w-427 {
    width: 427rem;
  }
  .w-428 {
    width: 428rem;
  }
  .w-429 {
    width: 429rem;
  }
  .w-430 {
    width: 430rem;
  }
  .w-431 {
    width: 431rem;
  }
  .w-432 {
    width: 432rem;
  }
  .w-433 {
    width: 433rem;
  }
  .w-434 {
    width: 434rem;
  }
  .w-435 {
    width: 435rem;
  }
  .w-436 {
    width: 436rem;
  }
  .w-437 {
    width: 437rem;
  }
  .w-438 {
    width: 438rem;
  }
  .w-439 {
    width: 439rem;
  }
  .w-440 {
    width: 440rem;
  }
  .w-441 {
    width: 441rem;
  }
  .w-442 {
    width: 442rem;
  }
  .w-443 {
    width: 443rem;
  }
  .w-444 {
    width: 444rem;
  }
  .w-445 {
    width: 445rem;
  }
  .w-446 {
    width: 446rem;
  }
  .w-447 {
    width: 447rem;
  }
  .w-448 {
    width: 448rem;
  }
  .w-449 {
    width: 449rem;
  }
  .w-450 {
    width: 450rem;
  }
  .w-451 {
    width: 451rem;
  }
  .w-452 {
    width: 452rem;
  }
  .w-453 {
    width: 453rem;
  }
  .w-454 {
    width: 454rem;
  }
  .w-455 {
    width: 455rem;
  }
  .w-456 {
    width: 456rem;
  }
  .w-457 {
    width: 457rem;
  }
  .w-458 {
    width: 458rem;
  }
  .w-459 {
    width: 459rem;
  }
  .w-460 {
    width: 460rem;
  }
  .w-461 {
    width: 461rem;
  }
  .w-462 {
    width: 462rem;
  }
  .w-463 {
    width: 463rem;
  }
  .w-464 {
    width: 464rem;
  }
  .w-465 {
    width: 465rem;
  }
  .w-466 {
    width: 466rem;
  }
  .w-467 {
    width: 467rem;
  }
  .w-468 {
    width: 468rem;
  }
  .w-469 {
    width: 469rem;
  }
  .w-470 {
    width: 470rem;
  }
  .w-471 {
    width: 471rem;
  }
  .w-472 {
    width: 472rem;
  }
  .w-473 {
    width: 473rem;
  }
  .w-474 {
    width: 474rem;
  }
  .w-475 {
    width: 475rem;
  }
  .w-476 {
    width: 476rem;
  }
  .w-477 {
    width: 477rem;
  }
  .w-478 {
    width: 478rem;
  }
  .w-479 {
    width: 479rem;
  }
  .w-480 {
    width: 480rem;
  }
  .w-481 {
    width: 481rem;
  }
  .w-482 {
    width: 482rem;
  }
  .w-483 {
    width: 483rem;
  }
  .w-484 {
    width: 484rem;
  }
  .w-485 {
    width: 485rem;
  }
  .w-486 {
    width: 486rem;
  }
  .w-487 {
    width: 487rem;
  }
  .w-488 {
    width: 488rem;
  }
  .w-489 {
    width: 489rem;
  }
  .w-490 {
    width: 490rem;
  }
  .w-491 {
    width: 491rem;
  }
  .w-492 {
    width: 492rem;
  }
  .w-493 {
    width: 493rem;
  }
  .w-494 {
    width: 494rem;
  }
  .w-495 {
    width: 495rem;
  }
  .w-496 {
    width: 496rem;
  }
  .w-497 {
    width: 497rem;
  }
  .w-498 {
    width: 498rem;
  }
  .w-499 {
    width: 499rem;
  }
  .w-500 {
    width: 500rem;
  }
  .w-501 {
    width: 501rem;
  }
  .w-502 {
    width: 502rem;
  }
  .w-503 {
    width: 503rem;
  }
  .w-504 {
    width: 504rem;
  }
  .w-505 {
    width: 505rem;
  }
  .w-506 {
    width: 506rem;
  }
  .w-507 {
    width: 507rem;
  }
  .w-508 {
    width: 508rem;
  }
  .w-509 {
    width: 509rem;
  }
  .w-510 {
    width: 510rem;
  }
  .w-511 {
    width: 511rem;
  }
  .w-512 {
    width: 512rem;
  }
  .w-513 {
    width: 513rem;
  }
  .w-514 {
    width: 514rem;
  }
  .w-515 {
    width: 515rem;
  }
  .w-516 {
    width: 516rem;
  }
  .w-517 {
    width: 517rem;
  }
  .w-518 {
    width: 518rem;
  }
  .w-519 {
    width: 519rem;
  }
  .w-520 {
    width: 520rem;
  }
  .w-521 {
    width: 521rem;
  }
  .w-522 {
    width: 522rem;
  }
  .w-523 {
    width: 523rem;
  }
  .w-524 {
    width: 524rem;
  }
  .w-525 {
    width: 525rem;
  }
  .w-526 {
    width: 526rem;
  }
  .w-527 {
    width: 527rem;
  }
  .w-528 {
    width: 528rem;
  }
  .w-529 {
    width: 529rem;
  }
  .w-530 {
    width: 530rem;
  }
  .w-531 {
    width: 531rem;
  }
  .w-532 {
    width: 532rem;
  }
  .w-533 {
    width: 533rem;
  }
  .w-534 {
    width: 534rem;
  }
  .w-535 {
    width: 535rem;
  }
  .w-536 {
    width: 536rem;
  }
  .w-537 {
    width: 537rem;
  }
  .w-538 {
    width: 538rem;
  }
  .w-539 {
    width: 539rem;
  }
  .w-540 {
    width: 540rem;
  }
  .w-541 {
    width: 541rem;
  }
  .w-542 {
    width: 542rem;
  }
  .w-543 {
    width: 543rem;
  }
  .w-544 {
    width: 544rem;
  }
  .w-545 {
    width: 545rem;
  }
  .w-546 {
    width: 546rem;
  }
  .w-547 {
    width: 547rem;
  }
  .w-548 {
    width: 548rem;
  }
  .w-549 {
    width: 549rem;
  }
  .w-550 {
    width: 550rem;
  }
  .w-551 {
    width: 551rem;
  }
  .w-552 {
    width: 552rem;
  }
  .w-553 {
    width: 553rem;
  }
  .w-554 {
    width: 554rem;
  }
  .w-555 {
    width: 555rem;
  }
  .w-556 {
    width: 556rem;
  }
  .w-557 {
    width: 557rem;
  }
  .w-558 {
    width: 558rem;
  }
  .w-559 {
    width: 559rem;
  }
  .w-560 {
    width: 560rem;
  }
  .w-561 {
    width: 561rem;
  }
  .w-562 {
    width: 562rem;
  }
  .w-563 {
    width: 563rem;
  }
  .w-564 {
    width: 564rem;
  }
  .w-565 {
    width: 565rem;
  }
  .w-566 {
    width: 566rem;
  }
  .w-567 {
    width: 567rem;
  }
  .w-568 {
    width: 568rem;
  }
  .w-569 {
    width: 569rem;
  }
  .w-570 {
    width: 570rem;
  }
  .w-571 {
    width: 571rem;
  }
  .w-572 {
    width: 572rem;
  }
  .w-573 {
    width: 573rem;
  }
  .w-574 {
    width: 574rem;
  }
  .w-575 {
    width: 575rem;
  }
  .w-576 {
    width: 576rem;
  }
  .w-577 {
    width: 577rem;
  }
  .w-578 {
    width: 578rem;
  }
  .w-579 {
    width: 579rem;
  }
  .w-580 {
    width: 580rem;
  }
  .w-581 {
    width: 581rem;
  }
  .w-582 {
    width: 582rem;
  }
  .w-583 {
    width: 583rem;
  }
  .w-584 {
    width: 584rem;
  }
  .w-585 {
    width: 585rem;
  }
  .w-586 {
    width: 586rem;
  }
  .w-587 {
    width: 587rem;
  }
  .w-588 {
    width: 588rem;
  }
  .w-589 {
    width: 589rem;
  }
  .w-590 {
    width: 590rem;
  }
  .w-591 {
    width: 591rem;
  }
  .w-592 {
    width: 592rem;
  }
  .w-593 {
    width: 593rem;
  }
  .w-594 {
    width: 594rem;
  }
  .w-595 {
    width: 595rem;
  }
  .w-596 {
    width: 596rem;
  }
  .w-597 {
    width: 597rem;
  }
  .w-598 {
    width: 598rem;
  }
  .w-599 {
    width: 599rem;
  }
  .w-600 {
    width: 600rem;
  }
  .w-601 {
    width: 601rem;
  }
  .w-602 {
    width: 602rem;
  }
  .w-603 {
    width: 603rem;
  }
  .w-604 {
    width: 604rem;
  }
  .w-605 {
    width: 605rem;
  }
  .w-606 {
    width: 606rem;
  }
  .w-607 {
    width: 607rem;
  }
  .w-608 {
    width: 608rem;
  }
  .w-609 {
    width: 609rem;
  }
  .w-610 {
    width: 610rem;
  }
  .w-611 {
    width: 611rem;
  }
  .w-612 {
    width: 612rem;
  }
  .w-613 {
    width: 613rem;
  }
  .w-614 {
    width: 614rem;
  }
  .w-615 {
    width: 615rem;
  }
  .w-616 {
    width: 616rem;
  }
  .w-617 {
    width: 617rem;
  }
  .w-618 {
    width: 618rem;
  }
  .w-619 {
    width: 619rem;
  }
  .w-620 {
    width: 620rem;
  }
  .w-621 {
    width: 621rem;
  }
  .w-622 {
    width: 622rem;
  }
  .w-623 {
    width: 623rem;
  }
  .w-624 {
    width: 624rem;
  }
  .w-625 {
    width: 625rem;
  }
  .w-626 {
    width: 626rem;
  }
  .w-627 {
    width: 627rem;
  }
  .w-628 {
    width: 628rem;
  }
  .w-629 {
    width: 629rem;
  }
  .w-630 {
    width: 630rem;
  }
  .w-631 {
    width: 631rem;
  }
  .w-632 {
    width: 632rem;
  }
  .w-633 {
    width: 633rem;
  }
  .w-634 {
    width: 634rem;
  }
  .w-635 {
    width: 635rem;
  }
  .w-636 {
    width: 636rem;
  }
  .w-637 {
    width: 637rem;
  }
  .w-638 {
    width: 638rem;
  }
  .w-639 {
    width: 639rem;
  }
  .w-640 {
    width: 640rem;
  }
  .w-641 {
    width: 641rem;
  }
  .w-642 {
    width: 642rem;
  }
  .w-643 {
    width: 643rem;
  }
  .w-644 {
    width: 644rem;
  }
  .w-645 {
    width: 645rem;
  }
  .w-646 {
    width: 646rem;
  }
  .w-647 {
    width: 647rem;
  }
  .w-648 {
    width: 648rem;
  }
  .w-649 {
    width: 649rem;
  }
  .w-650 {
    width: 650rem;
  }
  .w-651 {
    width: 651rem;
  }
  .w-652 {
    width: 652rem;
  }
  .w-653 {
    width: 653rem;
  }
  .w-654 {
    width: 654rem;
  }
  .w-655 {
    width: 655rem;
  }
  .w-656 {
    width: 656rem;
  }
  .w-657 {
    width: 657rem;
  }
  .w-658 {
    width: 658rem;
  }
  .w-659 {
    width: 659rem;
  }
  .w-660 {
    width: 660rem;
  }
  .w-661 {
    width: 661rem;
  }
  .w-662 {
    width: 662rem;
  }
  .w-663 {
    width: 663rem;
  }
  .w-664 {
    width: 664rem;
  }
  .w-665 {
    width: 665rem;
  }
  .w-666 {
    width: 666rem;
  }
  .w-667 {
    width: 667rem;
  }
  .w-668 {
    width: 668rem;
  }
  .w-669 {
    width: 669rem;
  }
  .w-670 {
    width: 670rem;
  }
  .w-671 {
    width: 671rem;
  }
  .w-672 {
    width: 672rem;
  }
  .w-673 {
    width: 673rem;
  }
  .w-674 {
    width: 674rem;
  }
  .w-675 {
    width: 675rem;
  }
  .w-676 {
    width: 676rem;
  }
  .w-677 {
    width: 677rem;
  }
  .w-678 {
    width: 678rem;
  }
  .w-679 {
    width: 679rem;
  }
  .w-680 {
    width: 680rem;
  }
  .w-681 {
    width: 681rem;
  }
  .w-682 {
    width: 682rem;
  }
  .w-683 {
    width: 683rem;
  }
  .w-684 {
    width: 684rem;
  }
  .w-685 {
    width: 685rem;
  }
  .w-686 {
    width: 686rem;
  }
  .w-687 {
    width: 687rem;
  }
  .w-688 {
    width: 688rem;
  }
  .w-689 {
    width: 689rem;
  }
  .w-690 {
    width: 690rem;
  }
  .w-691 {
    width: 691rem;
  }
  .w-692 {
    width: 692rem;
  }
  .w-693 {
    width: 693rem;
  }
  .w-694 {
    width: 694rem;
  }
  .w-695 {
    width: 695rem;
  }
  .w-696 {
    width: 696rem;
  }
  .w-697 {
    width: 697rem;
  }
  .w-698 {
    width: 698rem;
  }
  .w-699 {
    width: 699rem;
  }
  .w-700 {
    width: 700rem;
  }
  .w-701 {
    width: 701rem;
  }
  .w-702 {
    width: 702rem;
  }
  .w-703 {
    width: 703rem;
  }
  .w-704 {
    width: 704rem;
  }
  .w-705 {
    width: 705rem;
  }
  .w-706 {
    width: 706rem;
  }
  .w-707 {
    width: 707rem;
  }
  .w-708 {
    width: 708rem;
  }
  .w-709 {
    width: 709rem;
  }
  .w-710 {
    width: 710rem;
  }
  .w-711 {
    width: 711rem;
  }
  .w-712 {
    width: 712rem;
  }
  .w-713 {
    width: 713rem;
  }
  .w-714 {
    width: 714rem;
  }
  .w-715 {
    width: 715rem;
  }
  .w-716 {
    width: 716rem;
  }
  .w-717 {
    width: 717rem;
  }
  .w-718 {
    width: 718rem;
  }
  .w-719 {
    width: 719rem;
  }
  .w-720 {
    width: 720rem;
  }
  .w-721 {
    width: 721rem;
  }
  .w-722 {
    width: 722rem;
  }
  .w-723 {
    width: 723rem;
  }
  .w-724 {
    width: 724rem;
  }
  .w-725 {
    width: 725rem;
  }
  .w-726 {
    width: 726rem;
  }
  .w-727 {
    width: 727rem;
  }
  .w-728 {
    width: 728rem;
  }
  .w-729 {
    width: 729rem;
  }
  .w-730 {
    width: 730rem;
  }
  .w-731 {
    width: 731rem;
  }
  .w-732 {
    width: 732rem;
  }
  .w-733 {
    width: 733rem;
  }
  .w-734 {
    width: 734rem;
  }
  .w-735 {
    width: 735rem;
  }
  .w-736 {
    width: 736rem;
  }
  .w-737 {
    width: 737rem;
  }
  .w-738 {
    width: 738rem;
  }
  .w-739 {
    width: 739rem;
  }
  .w-740 {
    width: 740rem;
  }
  .w-741 {
    width: 741rem;
  }
  .w-742 {
    width: 742rem;
  }
  .w-743 {
    width: 743rem;
  }
  .w-744 {
    width: 744rem;
  }
  .w-745 {
    width: 745rem;
  }
  .w-746 {
    width: 746rem;
  }
  .w-747 {
    width: 747rem;
  }
  .w-748 {
    width: 748rem;
  }
  .w-749 {
    width: 749rem;
  }
  .w-750 {
    width: 750rem;
  }
  .w-751 {
    width: 751rem;
  }
  .w-752 {
    width: 752rem;
  }
  .w-753 {
    width: 753rem;
  }
  .w-754 {
    width: 754rem;
  }
  .w-755 {
    width: 755rem;
  }
  .w-756 {
    width: 756rem;
  }
  .w-757 {
    width: 757rem;
  }
  .w-758 {
    width: 758rem;
  }
  .w-759 {
    width: 759rem;
  }
  .w-760 {
    width: 760rem;
  }
  .w-761 {
    width: 761rem;
  }
  .w-762 {
    width: 762rem;
  }
  .w-763 {
    width: 763rem;
  }
  .w-764 {
    width: 764rem;
  }
  .w-765 {
    width: 765rem;
  }
  .w-766 {
    width: 766rem;
  }
  .w-767 {
    width: 767rem;
  }
  .w-768 {
    width: 768rem;
  }
  .w-769 {
    width: 769rem;
  }
  .w-770 {
    width: 770rem;
  }
  .w-771 {
    width: 771rem;
  }
  .w-772 {
    width: 772rem;
  }
  .w-773 {
    width: 773rem;
  }
  .w-774 {
    width: 774rem;
  }
  .w-775 {
    width: 775rem;
  }
  .w-776 {
    width: 776rem;
  }
  .w-777 {
    width: 777rem;
  }
  .w-778 {
    width: 778rem;
  }
  .w-779 {
    width: 779rem;
  }
  .w-780 {
    width: 780rem;
  }
  .w-781 {
    width: 781rem;
  }
  .w-782 {
    width: 782rem;
  }
  .w-783 {
    width: 783rem;
  }
  .w-784 {
    width: 784rem;
  }
  .w-785 {
    width: 785rem;
  }
  .w-786 {
    width: 786rem;
  }
  .w-787 {
    width: 787rem;
  }
  .w-788 {
    width: 788rem;
  }
  .w-789 {
    width: 789rem;
  }
  .w-790 {
    width: 790rem;
  }
  .w-791 {
    width: 791rem;
  }
  .w-792 {
    width: 792rem;
  }
  .w-793 {
    width: 793rem;
  }
  .w-794 {
    width: 794rem;
  }
  .w-795 {
    width: 795rem;
  }
  .w-796 {
    width: 796rem;
  }
  .w-797 {
    width: 797rem;
  }
  .w-798 {
    width: 798rem;
  }
  .w-799 {
    width: 799rem;
  }
  .w-800 {
    width: 800rem;
  }
  .w-801 {
    width: 801rem;
  }
  .w-802 {
    width: 802rem;
  }
  .w-803 {
    width: 803rem;
  }
  .w-804 {
    width: 804rem;
  }
  .w-805 {
    width: 805rem;
  }
  .w-806 {
    width: 806rem;
  }
  .w-807 {
    width: 807rem;
  }
  .w-808 {
    width: 808rem;
  }
  .w-809 {
    width: 809rem;
  }
  .w-810 {
    width: 810rem;
  }
  .w-811 {
    width: 811rem;
  }
  .w-812 {
    width: 812rem;
  }
  .w-813 {
    width: 813rem;
  }
  .w-814 {
    width: 814rem;
  }
  .w-815 {
    width: 815rem;
  }
  .w-816 {
    width: 816rem;
  }
  .w-817 {
    width: 817rem;
  }
  .w-818 {
    width: 818rem;
  }
  .w-819 {
    width: 819rem;
  }
  .w-820 {
    width: 820rem;
  }
  .w-821 {
    width: 821rem;
  }
  .w-822 {
    width: 822rem;
  }
  .w-823 {
    width: 823rem;
  }
  .w-824 {
    width: 824rem;
  }
  .w-825 {
    width: 825rem;
  }
  .w-826 {
    width: 826rem;
  }
  .w-827 {
    width: 827rem;
  }
  .w-828 {
    width: 828rem;
  }
  .w-829 {
    width: 829rem;
  }
  .w-830 {
    width: 830rem;
  }
  .w-831 {
    width: 831rem;
  }
  .w-832 {
    width: 832rem;
  }
  .w-833 {
    width: 833rem;
  }
  .w-834 {
    width: 834rem;
  }
  .w-835 {
    width: 835rem;
  }
  .w-836 {
    width: 836rem;
  }
  .w-837 {
    width: 837rem;
  }
  .w-838 {
    width: 838rem;
  }
  .w-839 {
    width: 839rem;
  }
  .w-840 {
    width: 840rem;
  }
  .w-841 {
    width: 841rem;
  }
  .w-842 {
    width: 842rem;
  }
  .w-843 {
    width: 843rem;
  }
  .w-844 {
    width: 844rem;
  }
  .w-845 {
    width: 845rem;
  }
  .w-846 {
    width: 846rem;
  }
  .w-847 {
    width: 847rem;
  }
  .w-848 {
    width: 848rem;
  }
  .w-849 {
    width: 849rem;
  }
  .w-850 {
    width: 850rem;
  }
  .w-851 {
    width: 851rem;
  }
  .w-852 {
    width: 852rem;
  }
  .w-853 {
    width: 853rem;
  }
  .w-854 {
    width: 854rem;
  }
  .w-855 {
    width: 855rem;
  }
  .w-856 {
    width: 856rem;
  }
  .w-857 {
    width: 857rem;
  }
  .w-858 {
    width: 858rem;
  }
  .w-859 {
    width: 859rem;
  }
  .w-860 {
    width: 860rem;
  }
  .w-861 {
    width: 861rem;
  }
  .w-862 {
    width: 862rem;
  }
  .w-863 {
    width: 863rem;
  }
  .w-864 {
    width: 864rem;
  }
  .w-865 {
    width: 865rem;
  }
  .w-866 {
    width: 866rem;
  }
  .w-867 {
    width: 867rem;
  }
  .w-868 {
    width: 868rem;
  }
  .w-869 {
    width: 869rem;
  }
  .w-870 {
    width: 870rem;
  }
  .w-871 {
    width: 871rem;
  }
  .w-872 {
    width: 872rem;
  }
  .w-873 {
    width: 873rem;
  }
  .w-874 {
    width: 874rem;
  }
  .w-875 {
    width: 875rem;
  }
  .w-876 {
    width: 876rem;
  }
  .w-877 {
    width: 877rem;
  }
  .w-878 {
    width: 878rem;
  }
  .w-879 {
    width: 879rem;
  }
  .w-880 {
    width: 880rem;
  }
  .w-881 {
    width: 881rem;
  }
  .w-882 {
    width: 882rem;
  }
  .w-883 {
    width: 883rem;
  }
  .w-884 {
    width: 884rem;
  }
  .w-885 {
    width: 885rem;
  }
  .w-886 {
    width: 886rem;
  }
  .w-887 {
    width: 887rem;
  }
  .w-888 {
    width: 888rem;
  }
  .w-889 {
    width: 889rem;
  }
  .w-890 {
    width: 890rem;
  }
  .w-891 {
    width: 891rem;
  }
  .w-892 {
    width: 892rem;
  }
  .w-893 {
    width: 893rem;
  }
  .w-894 {
    width: 894rem;
  }
  .w-895 {
    width: 895rem;
  }
  .w-896 {
    width: 896rem;
  }
  .w-897 {
    width: 897rem;
  }
  .w-898 {
    width: 898rem;
  }
  .w-899 {
    width: 899rem;
  }
  .w-900 {
    width: 900rem;
  }
  .w-901 {
    width: 901rem;
  }
  .w-902 {
    width: 902rem;
  }
  .w-903 {
    width: 903rem;
  }
  .w-904 {
    width: 904rem;
  }
  .w-905 {
    width: 905rem;
  }
  .w-906 {
    width: 906rem;
  }
  .w-907 {
    width: 907rem;
  }
  .w-908 {
    width: 908rem;
  }
  .w-909 {
    width: 909rem;
  }
  .w-910 {
    width: 910rem;
  }
  .w-911 {
    width: 911rem;
  }
  .w-912 {
    width: 912rem;
  }
  .w-913 {
    width: 913rem;
  }
  .w-914 {
    width: 914rem;
  }
  .w-915 {
    width: 915rem;
  }
  .w-916 {
    width: 916rem;
  }
  .w-917 {
    width: 917rem;
  }
  .w-918 {
    width: 918rem;
  }
  .w-919 {
    width: 919rem;
  }
  .w-920 {
    width: 920rem;
  }
  .w-921 {
    width: 921rem;
  }
  .w-922 {
    width: 922rem;
  }
  .w-923 {
    width: 923rem;
  }
  .w-924 {
    width: 924rem;
  }
  .w-925 {
    width: 925rem;
  }
  .w-926 {
    width: 926rem;
  }
  .w-927 {
    width: 927rem;
  }
  .w-928 {
    width: 928rem;
  }
  .w-929 {
    width: 929rem;
  }
  .w-930 {
    width: 930rem;
  }
  .w-931 {
    width: 931rem;
  }
  .w-932 {
    width: 932rem;
  }
  .w-933 {
    width: 933rem;
  }
  .w-934 {
    width: 934rem;
  }
  .w-935 {
    width: 935rem;
  }
  .w-936 {
    width: 936rem;
  }
  .w-937 {
    width: 937rem;
  }
  .w-938 {
    width: 938rem;
  }
  .w-939 {
    width: 939rem;
  }
  .w-940 {
    width: 940rem;
  }
  .w-941 {
    width: 941rem;
  }
  .w-942 {
    width: 942rem;
  }
  .w-943 {
    width: 943rem;
  }
  .w-944 {
    width: 944rem;
  }
  .w-945 {
    width: 945rem;
  }
  .w-946 {
    width: 946rem;
  }
  .w-947 {
    width: 947rem;
  }
  .w-948 {
    width: 948rem;
  }
  .w-949 {
    width: 949rem;
  }
  .w-950 {
    width: 950rem;
  }
  .w-951 {
    width: 951rem;
  }
  .w-952 {
    width: 952rem;
  }
  .w-953 {
    width: 953rem;
  }
  .w-954 {
    width: 954rem;
  }
  .w-955 {
    width: 955rem;
  }
  .w-956 {
    width: 956rem;
  }
  .w-957 {
    width: 957rem;
  }
  .w-958 {
    width: 958rem;
  }
  .w-959 {
    width: 959rem;
  }
  .w-960 {
    width: 960rem;
  }
  .w-961 {
    width: 961rem;
  }
  .w-962 {
    width: 962rem;
  }
  .w-963 {
    width: 963rem;
  }
  .w-964 {
    width: 964rem;
  }
  .w-965 {
    width: 965rem;
  }
  .w-966 {
    width: 966rem;
  }
  .w-967 {
    width: 967rem;
  }
  .w-968 {
    width: 968rem;
  }
  .w-969 {
    width: 969rem;
  }
  .w-970 {
    width: 970rem;
  }
  .w-971 {
    width: 971rem;
  }
  .w-972 {
    width: 972rem;
  }
  .w-973 {
    width: 973rem;
  }
  .w-974 {
    width: 974rem;
  }
  .w-975 {
    width: 975rem;
  }
  .w-976 {
    width: 976rem;
  }
  .w-977 {
    width: 977rem;
  }
  .w-978 {
    width: 978rem;
  }
  .w-979 {
    width: 979rem;
  }
  .w-980 {
    width: 980rem;
  }
  .w-981 {
    width: 981rem;
  }
  .w-982 {
    width: 982rem;
  }
  .w-983 {
    width: 983rem;
  }
  .w-984 {
    width: 984rem;
  }
  .w-985 {
    width: 985rem;
  }
  .w-986 {
    width: 986rem;
  }
  .w-987 {
    width: 987rem;
  }
  .w-988 {
    width: 988rem;
  }
  .w-989 {
    width: 989rem;
  }
  .w-990 {
    width: 990rem;
  }
  .w-991 {
    width: 991rem;
  }
  .w-992 {
    width: 992rem;
  }
  .w-993 {
    width: 993rem;
  }
  .w-994 {
    width: 994rem;
  }
  .w-995 {
    width: 995rem;
  }
  .w-996 {
    width: 996rem;
  }
  .w-997 {
    width: 997rem;
  }
  .w-998 {
    width: 998rem;
  }
  .w-999 {
    width: 999rem;
  }
  .w-1000 {
    width: 1000rem;
  }
  .w-1001 {
    width: 1001rem;
  }
  .w-1002 {
    width: 1002rem;
  }
  .w-1003 {
    width: 1003rem;
  }
  .w-1004 {
    width: 1004rem;
  }
  .w-1005 {
    width: 1005rem;
  }
  .w-1006 {
    width: 1006rem;
  }
  .w-1007 {
    width: 1007rem;
  }
  .w-1008 {
    width: 1008rem;
  }
  .w-1009 {
    width: 1009rem;
  }
  .w-1010 {
    width: 1010rem;
  }
  .w-1011 {
    width: 1011rem;
  }
  .w-1012 {
    width: 1012rem;
  }
  .w-1013 {
    width: 1013rem;
  }
  .w-1014 {
    width: 1014rem;
  }
  .w-1015 {
    width: 1015rem;
  }
  .w-1016 {
    width: 1016rem;
  }
  .w-1017 {
    width: 1017rem;
  }
  .w-1018 {
    width: 1018rem;
  }
  .w-1019 {
    width: 1019rem;
  }
  .w-1020 {
    width: 1020rem;
  }
  .w-1021 {
    width: 1021rem;
  }
  .w-1022 {
    width: 1022rem;
  }
  .w-1023 {
    width: 1023rem;
  }
  .w-1024 {
    width: 1024rem;
  }
  .w-1025 {
    width: 1025rem;
  }
  .w-1026 {
    width: 1026rem;
  }
  .w-1027 {
    width: 1027rem;
  }
  .w-1028 {
    width: 1028rem;
  }
  .w-1029 {
    width: 1029rem;
  }
  .w-1030 {
    width: 1030rem;
  }
  .w-1031 {
    width: 1031rem;
  }
  .w-1032 {
    width: 1032rem;
  }
  .w-1033 {
    width: 1033rem;
  }
  .w-1034 {
    width: 1034rem;
  }
  .w-1035 {
    width: 1035rem;
  }
  .w-1036 {
    width: 1036rem;
  }
  .w-1037 {
    width: 1037rem;
  }
  .w-1038 {
    width: 1038rem;
  }
  .w-1039 {
    width: 1039rem;
  }
  .w-1040 {
    width: 1040rem;
  }
  .w-1041 {
    width: 1041rem;
  }
  .w-1042 {
    width: 1042rem;
  }
  .w-1043 {
    width: 1043rem;
  }
  .w-1044 {
    width: 1044rem;
  }
  .w-1045 {
    width: 1045rem;
  }
  .w-1046 {
    width: 1046rem;
  }
  .w-1047 {
    width: 1047rem;
  }
  .w-1048 {
    width: 1048rem;
  }
  .w-1049 {
    width: 1049rem;
  }
  .w-1050 {
    width: 1050rem;
  }
  .w-1051 {
    width: 1051rem;
  }
  .w-1052 {
    width: 1052rem;
  }
  .w-1053 {
    width: 1053rem;
  }
  .w-1054 {
    width: 1054rem;
  }
  .w-1055 {
    width: 1055rem;
  }
  .w-1056 {
    width: 1056rem;
  }
  .w-1057 {
    width: 1057rem;
  }
  .w-1058 {
    width: 1058rem;
  }
  .w-1059 {
    width: 1059rem;
  }
  .w-1060 {
    width: 1060rem;
  }
  .w-1061 {
    width: 1061rem;
  }
  .w-1062 {
    width: 1062rem;
  }
  .w-1063 {
    width: 1063rem;
  }
  .w-1064 {
    width: 1064rem;
  }
  .w-1065 {
    width: 1065rem;
  }
  .w-1066 {
    width: 1066rem;
  }
  .w-1067 {
    width: 1067rem;
  }
  .w-1068 {
    width: 1068rem;
  }
  .w-1069 {
    width: 1069rem;
  }
  .w-1070 {
    width: 1070rem;
  }
  .w-1071 {
    width: 1071rem;
  }
  .w-1072 {
    width: 1072rem;
  }
  .w-1073 {
    width: 1073rem;
  }
  .w-1074 {
    width: 1074rem;
  }
  .w-1075 {
    width: 1075rem;
  }
  .w-1076 {
    width: 1076rem;
  }
  .w-1077 {
    width: 1077rem;
  }
  .w-1078 {
    width: 1078rem;
  }
  .w-1079 {
    width: 1079rem;
  }
  .w-1080 {
    width: 1080rem;
  }
  .w-1081 {
    width: 1081rem;
  }
  .w-1082 {
    width: 1082rem;
  }
  .w-1083 {
    width: 1083rem;
  }
  .w-1084 {
    width: 1084rem;
  }
  .w-1085 {
    width: 1085rem;
  }
  .w-1086 {
    width: 1086rem;
  }
  .w-1087 {
    width: 1087rem;
  }
  .w-1088 {
    width: 1088rem;
  }
  .w-1089 {
    width: 1089rem;
  }
  .w-1090 {
    width: 1090rem;
  }
  .w-1091 {
    width: 1091rem;
  }
  .w-1092 {
    width: 1092rem;
  }
  .w-1093 {
    width: 1093rem;
  }
  .w-1094 {
    width: 1094rem;
  }
  .w-1095 {
    width: 1095rem;
  }
  .w-1096 {
    width: 1096rem;
  }
  .w-1097 {
    width: 1097rem;
  }
  .w-1098 {
    width: 1098rem;
  }
  .w-1099 {
    width: 1099rem;
  }
  .w-1100 {
    width: 1100rem;
  }
  .w-1101 {
    width: 1101rem;
  }
  .w-1102 {
    width: 1102rem;
  }
  .w-1103 {
    width: 1103rem;
  }
  .w-1104 {
    width: 1104rem;
  }
  .w-1105 {
    width: 1105rem;
  }
  .w-1106 {
    width: 1106rem;
  }
  .w-1107 {
    width: 1107rem;
  }
  .w-1108 {
    width: 1108rem;
  }
  .w-1109 {
    width: 1109rem;
  }
  .w-1110 {
    width: 1110rem;
  }
  .w-1111 {
    width: 1111rem;
  }
  .w-1112 {
    width: 1112rem;
  }
  .w-1113 {
    width: 1113rem;
  }
  .w-1114 {
    width: 1114rem;
  }
  .w-1115 {
    width: 1115rem;
  }
  .w-1116 {
    width: 1116rem;
  }
  .w-1117 {
    width: 1117rem;
  }
  .w-1118 {
    width: 1118rem;
  }
  .w-1119 {
    width: 1119rem;
  }
  .w-1120 {
    width: 1120rem;
  }
  .w-1121 {
    width: 1121rem;
  }
  .w-1122 {
    width: 1122rem;
  }
  .w-1123 {
    width: 1123rem;
  }
  .w-1124 {
    width: 1124rem;
  }
  .w-1125 {
    width: 1125rem;
  }
  .w-1126 {
    width: 1126rem;
  }
  .w-1127 {
    width: 1127rem;
  }
  .w-1128 {
    width: 1128rem;
  }
  .w-1129 {
    width: 1129rem;
  }
  .w-1130 {
    width: 1130rem;
  }
  .w-1131 {
    width: 1131rem;
  }
  .w-1132 {
    width: 1132rem;
  }
  .w-1133 {
    width: 1133rem;
  }
  .w-1134 {
    width: 1134rem;
  }
  .w-1135 {
    width: 1135rem;
  }
  .w-1136 {
    width: 1136rem;
  }
  .w-1137 {
    width: 1137rem;
  }
  .w-1138 {
    width: 1138rem;
  }
  .w-1139 {
    width: 1139rem;
  }
  .w-1140 {
    width: 1140rem;
  }
  .w-1141 {
    width: 1141rem;
  }
  .w-1142 {
    width: 1142rem;
  }
  .w-1143 {
    width: 1143rem;
  }
  .w-1144 {
    width: 1144rem;
  }
  .w-1145 {
    width: 1145rem;
  }
  .w-1146 {
    width: 1146rem;
  }
  .w-1147 {
    width: 1147rem;
  }
  .w-1148 {
    width: 1148rem;
  }
  .w-1149 {
    width: 1149rem;
  }
  .w-1150 {
    width: 1150rem;
  }
  .w-1151 {
    width: 1151rem;
  }
  .w-1152 {
    width: 1152rem;
  }
  .w-1153 {
    width: 1153rem;
  }
  .w-1154 {
    width: 1154rem;
  }
  .w-1155 {
    width: 1155rem;
  }
  .w-1156 {
    width: 1156rem;
  }
  .w-1157 {
    width: 1157rem;
  }
  .w-1158 {
    width: 1158rem;
  }
  .w-1159 {
    width: 1159rem;
  }
  .w-1160 {
    width: 1160rem;
  }
  .w-1161 {
    width: 1161rem;
  }
  .w-1162 {
    width: 1162rem;
  }
  .w-1163 {
    width: 1163rem;
  }
  .w-1164 {
    width: 1164rem;
  }
  .w-1165 {
    width: 1165rem;
  }
  .w-1166 {
    width: 1166rem;
  }
  .w-1167 {
    width: 1167rem;
  }
  .w-1168 {
    width: 1168rem;
  }
  .w-1169 {
    width: 1169rem;
  }
  .w-1170 {
    width: 1170rem;
  }
  .w-1171 {
    width: 1171rem;
  }
  .w-1172 {
    width: 1172rem;
  }
  .w-1173 {
    width: 1173rem;
  }
  .w-1174 {
    width: 1174rem;
  }
  .w-1175 {
    width: 1175rem;
  }
  .w-1176 {
    width: 1176rem;
  }
  .w-1177 {
    width: 1177rem;
  }
  .w-1178 {
    width: 1178rem;
  }
  .w-1179 {
    width: 1179rem;
  }
  .w-1180 {
    width: 1180rem;
  }
  .w-1181 {
    width: 1181rem;
  }
  .w-1182 {
    width: 1182rem;
  }
  .w-1183 {
    width: 1183rem;
  }
  .w-1184 {
    width: 1184rem;
  }
  .w-1185 {
    width: 1185rem;
  }
  .w-1186 {
    width: 1186rem;
  }
  .w-1187 {
    width: 1187rem;
  }
  .w-1188 {
    width: 1188rem;
  }
  .w-1189 {
    width: 1189rem;
  }
  .w-1190 {
    width: 1190rem;
  }
  .w-1191 {
    width: 1191rem;
  }
  .w-1192 {
    width: 1192rem;
  }
  .w-1193 {
    width: 1193rem;
  }
  .w-1194 {
    width: 1194rem;
  }
  .w-1195 {
    width: 1195rem;
  }
  .w-1196 {
    width: 1196rem;
  }
  .w-1197 {
    width: 1197rem;
  }
  .w-1198 {
    width: 1198rem;
  }
  .w-1199 {
    width: 1199rem;
  }
  .w-1200 {
    width: 1200rem;
  }
  .w-1201 {
    width: 1201rem;
  }
  .w-1202 {
    width: 1202rem;
  }
  .w-1203 {
    width: 1203rem;
  }
  .w-1204 {
    width: 1204rem;
  }
  .w-1205 {
    width: 1205rem;
  }
  .w-1206 {
    width: 1206rem;
  }
  .w-1207 {
    width: 1207rem;
  }
  .w-1208 {
    width: 1208rem;
  }
  .w-1209 {
    width: 1209rem;
  }
  .w-1210 {
    width: 1210rem;
  }
  .w-1211 {
    width: 1211rem;
  }
  .w-1212 {
    width: 1212rem;
  }
  .w-1213 {
    width: 1213rem;
  }
  .w-1214 {
    width: 1214rem;
  }
  .w-1215 {
    width: 1215rem;
  }
  .w-1216 {
    width: 1216rem;
  }
  .w-1217 {
    width: 1217rem;
  }
  .w-1218 {
    width: 1218rem;
  }
  .w-1219 {
    width: 1219rem;
  }
  .w-1220 {
    width: 1220rem;
  }
  .w-1221 {
    width: 1221rem;
  }
  .w-1222 {
    width: 1222rem;
  }
  .w-1223 {
    width: 1223rem;
  }
  .w-1224 {
    width: 1224rem;
  }
  .w-1225 {
    width: 1225rem;
  }
  .w-1226 {
    width: 1226rem;
  }
  .w-1227 {
    width: 1227rem;
  }
  .w-1228 {
    width: 1228rem;
  }
  .w-1229 {
    width: 1229rem;
  }
  .w-1230 {
    width: 1230rem;
  }
  .w-1231 {
    width: 1231rem;
  }
  .w-1232 {
    width: 1232rem;
  }
  .w-1233 {
    width: 1233rem;
  }
  .w-1234 {
    width: 1234rem;
  }
  .w-1235 {
    width: 1235rem;
  }
  .w-1236 {
    width: 1236rem;
  }
  .w-1237 {
    width: 1237rem;
  }
  .w-1238 {
    width: 1238rem;
  }
  .w-1239 {
    width: 1239rem;
  }
  .w-1240 {
    width: 1240rem;
  }
  .w-1241 {
    width: 1241rem;
  }
  .w-1242 {
    width: 1242rem;
  }
  .w-1243 {
    width: 1243rem;
  }
  .w-1244 {
    width: 1244rem;
  }
  .w-1245 {
    width: 1245rem;
  }
  .w-1246 {
    width: 1246rem;
  }
  .w-1247 {
    width: 1247rem;
  }
  .w-1248 {
    width: 1248rem;
  }
  .w-1249 {
    width: 1249rem;
  }
  .w-1250 {
    width: 1250rem;
  }
  .w-1251 {
    width: 1251rem;
  }
  .w-1252 {
    width: 1252rem;
  }
  .w-1253 {
    width: 1253rem;
  }
  .w-1254 {
    width: 1254rem;
  }
  .w-1255 {
    width: 1255rem;
  }
  .w-1256 {
    width: 1256rem;
  }
  .w-1257 {
    width: 1257rem;
  }
  .w-1258 {
    width: 1258rem;
  }
  .w-1259 {
    width: 1259rem;
  }
  .w-1260 {
    width: 1260rem;
  }
  .w-1261 {
    width: 1261rem;
  }
  .w-1262 {
    width: 1262rem;
  }
  .w-1263 {
    width: 1263rem;
  }
  .w-1264 {
    width: 1264rem;
  }
  .w-1265 {
    width: 1265rem;
  }
  .w-1266 {
    width: 1266rem;
  }
  .w-1267 {
    width: 1267rem;
  }
  .w-1268 {
    width: 1268rem;
  }
  .w-1269 {
    width: 1269rem;
  }
  .w-1270 {
    width: 1270rem;
  }
  .w-1271 {
    width: 1271rem;
  }
  .w-1272 {
    width: 1272rem;
  }
  .w-1273 {
    width: 1273rem;
  }
  .w-1274 {
    width: 1274rem;
  }
  .w-1275 {
    width: 1275rem;
  }
  .w-1276 {
    width: 1276rem;
  }
  .w-1277 {
    width: 1277rem;
  }
  .w-1278 {
    width: 1278rem;
  }
  .w-1279 {
    width: 1279rem;
  }
  .w-1280 {
    width: 1280rem;
  }
  .w-1281 {
    width: 1281rem;
  }
  .w-1282 {
    width: 1282rem;
  }
  .w-1283 {
    width: 1283rem;
  }
  .w-1284 {
    width: 1284rem;
  }
  .w-1285 {
    width: 1285rem;
  }
  .w-1286 {
    width: 1286rem;
  }
  .w-1287 {
    width: 1287rem;
  }
  .w-1288 {
    width: 1288rem;
  }
  .w-1289 {
    width: 1289rem;
  }
  .w-1290 {
    width: 1290rem;
  }
  .w-1291 {
    width: 1291rem;
  }
  .w-1292 {
    width: 1292rem;
  }
  .w-1293 {
    width: 1293rem;
  }
  .w-1294 {
    width: 1294rem;
  }
  .w-1295 {
    width: 1295rem;
  }
  .w-1296 {
    width: 1296rem;
  }
  .w-1297 {
    width: 1297rem;
  }
  .w-1298 {
    width: 1298rem;
  }
  .w-1299 {
    width: 1299rem;
  }
  .w-1300 {
    width: 1300rem;
  }
  .w-1301 {
    width: 1301rem;
  }
  .w-1302 {
    width: 1302rem;
  }
  .w-1303 {
    width: 1303rem;
  }
  .w-1304 {
    width: 1304rem;
  }
  .w-1305 {
    width: 1305rem;
  }
  .w-1306 {
    width: 1306rem;
  }
  .w-1307 {
    width: 1307rem;
  }
  .w-1308 {
    width: 1308rem;
  }
  .w-1309 {
    width: 1309rem;
  }
  .w-1310 {
    width: 1310rem;
  }
  .w-1311 {
    width: 1311rem;
  }
  .w-1312 {
    width: 1312rem;
  }
  .w-1313 {
    width: 1313rem;
  }
  .w-1314 {
    width: 1314rem;
  }
  .w-1315 {
    width: 1315rem;
  }
  .w-1316 {
    width: 1316rem;
  }
  .w-1317 {
    width: 1317rem;
  }
  .w-1318 {
    width: 1318rem;
  }
  .w-1319 {
    width: 1319rem;
  }
  .w-1320 {
    width: 1320rem;
  }
  .w-1321 {
    width: 1321rem;
  }
  .w-1322 {
    width: 1322rem;
  }
  .w-1323 {
    width: 1323rem;
  }
  .w-1324 {
    width: 1324rem;
  }
  .w-1325 {
    width: 1325rem;
  }
  .w-1326 {
    width: 1326rem;
  }
  .w-1327 {
    width: 1327rem;
  }
  .w-1328 {
    width: 1328rem;
  }
  .w-1329 {
    width: 1329rem;
  }
  .w-1330 {
    width: 1330rem;
  }
  .w-1331 {
    width: 1331rem;
  }
  .w-1332 {
    width: 1332rem;
  }
  .w-1333 {
    width: 1333rem;
  }
  .w-1334 {
    width: 1334rem;
  }
  .w-1335 {
    width: 1335rem;
  }
  .w-1336 {
    width: 1336rem;
  }
  .w-1337 {
    width: 1337rem;
  }
  .w-1338 {
    width: 1338rem;
  }
  .w-1339 {
    width: 1339rem;
  }
  .w-1340 {
    width: 1340rem;
  }
  .w-1341 {
    width: 1341rem;
  }
  .w-1342 {
    width: 1342rem;
  }
  .w-1343 {
    width: 1343rem;
  }
  .w-1344 {
    width: 1344rem;
  }
  .w-1345 {
    width: 1345rem;
  }
  .w-1346 {
    width: 1346rem;
  }
  .w-1347 {
    width: 1347rem;
  }
  .w-1348 {
    width: 1348rem;
  }
  .w-1349 {
    width: 1349rem;
  }
  .w-1350 {
    width: 1350rem;
  }
  .w-1351 {
    width: 1351rem;
  }
  .w-1352 {
    width: 1352rem;
  }
  .w-1353 {
    width: 1353rem;
  }
  .w-1354 {
    width: 1354rem;
  }
  .w-1355 {
    width: 1355rem;
  }
  .w-1356 {
    width: 1356rem;
  }
  .w-1357 {
    width: 1357rem;
  }
  .w-1358 {
    width: 1358rem;
  }
  .w-1359 {
    width: 1359rem;
  }
  .w-1360 {
    width: 1360rem;
  }
  .w-1361 {
    width: 1361rem;
  }
  .w-1362 {
    width: 1362rem;
  }
  .w-1363 {
    width: 1363rem;
  }
  .w-1364 {
    width: 1364rem;
  }
  .w-1365 {
    width: 1365rem;
  }
  .w-1366 {
    width: 1366rem;
  }
  .w-1367 {
    width: 1367rem;
  }
  .w-1368 {
    width: 1368rem;
  }
  .w-1369 {
    width: 1369rem;
  }
  .w-1370 {
    width: 1370rem;
  }
  .w-1371 {
    width: 1371rem;
  }
  .w-1372 {
    width: 1372rem;
  }
  .w-1373 {
    width: 1373rem;
  }
  .w-1374 {
    width: 1374rem;
  }
  .w-1375 {
    width: 1375rem;
  }
  .w-1376 {
    width: 1376rem;
  }
  .w-1377 {
    width: 1377rem;
  }
  .w-1378 {
    width: 1378rem;
  }
  .w-1379 {
    width: 1379rem;
  }
  .w-1380 {
    width: 1380rem;
  }
  .w-1381 {
    width: 1381rem;
  }
  .w-1382 {
    width: 1382rem;
  }
  .w-1383 {
    width: 1383rem;
  }
  .w-1384 {
    width: 1384rem;
  }
  .w-1385 {
    width: 1385rem;
  }
  .w-1386 {
    width: 1386rem;
  }
  .w-1387 {
    width: 1387rem;
  }
  .w-1388 {
    width: 1388rem;
  }
  .w-1389 {
    width: 1389rem;
  }
  .w-1390 {
    width: 1390rem;
  }
  .w-1391 {
    width: 1391rem;
  }
  .w-1392 {
    width: 1392rem;
  }
  .w-1393 {
    width: 1393rem;
  }
  .w-1394 {
    width: 1394rem;
  }
  .w-1395 {
    width: 1395rem;
  }
  .w-1396 {
    width: 1396rem;
  }
  .w-1397 {
    width: 1397rem;
  }
  .w-1398 {
    width: 1398rem;
  }
  .w-1399 {
    width: 1399rem;
  }
  .w-1400 {
    width: 1400rem;
  }
  .w-1401 {
    width: 1401rem;
  }
  .w-1402 {
    width: 1402rem;
  }
  .w-1403 {
    width: 1403rem;
  }
  .w-1404 {
    width: 1404rem;
  }
  .w-1405 {
    width: 1405rem;
  }
  .w-1406 {
    width: 1406rem;
  }
  .w-1407 {
    width: 1407rem;
  }
  .w-1408 {
    width: 1408rem;
  }
  .w-1409 {
    width: 1409rem;
  }
  .w-1410 {
    width: 1410rem;
  }
  .w-1411 {
    width: 1411rem;
  }
  .w-1412 {
    width: 1412rem;
  }
  .w-1413 {
    width: 1413rem;
  }
  .w-1414 {
    width: 1414rem;
  }
  .w-1415 {
    width: 1415rem;
  }
  .w-1416 {
    width: 1416rem;
  }
  .w-1417 {
    width: 1417rem;
  }
  .w-1418 {
    width: 1418rem;
  }
  .w-1419 {
    width: 1419rem;
  }
  .w-1420 {
    width: 1420rem;
  }
  .w-1421 {
    width: 1421rem;
  }
  .w-1422 {
    width: 1422rem;
  }
  .w-1423 {
    width: 1423rem;
  }
  .w-1424 {
    width: 1424rem;
  }
  .w-1425 {
    width: 1425rem;
  }
  .w-1426 {
    width: 1426rem;
  }
  .w-1427 {
    width: 1427rem;
  }
  .w-1428 {
    width: 1428rem;
  }
  .w-1429 {
    width: 1429rem;
  }
  .w-1430 {
    width: 1430rem;
  }
  .w-1431 {
    width: 1431rem;
  }
  .w-1432 {
    width: 1432rem;
  }
  .w-1433 {
    width: 1433rem;
  }
  .w-1434 {
    width: 1434rem;
  }
  .w-1435 {
    width: 1435rem;
  }
  .w-1436 {
    width: 1436rem;
  }
  .w-1437 {
    width: 1437rem;
  }
  .w-1438 {
    width: 1438rem;
  }
  .w-1439 {
    width: 1439rem;
  }
  .w-1440 {
    width: 1440rem;
  }
  .w-1441 {
    width: 1441rem;
  }
  .w-1442 {
    width: 1442rem;
  }
  .w-1443 {
    width: 1443rem;
  }
  .w-1444 {
    width: 1444rem;
  }
  .w-1445 {
    width: 1445rem;
  }
  .w-1446 {
    width: 1446rem;
  }
  .w-1447 {
    width: 1447rem;
  }
  .w-1448 {
    width: 1448rem;
  }
  .w-1449 {
    width: 1449rem;
  }
  .w-1450 {
    width: 1450rem;
  }
  .w-1451 {
    width: 1451rem;
  }
  .w-1452 {
    width: 1452rem;
  }
  .w-1453 {
    width: 1453rem;
  }
  .w-1454 {
    width: 1454rem;
  }
  .w-1455 {
    width: 1455rem;
  }
  .w-1456 {
    width: 1456rem;
  }
  .w-1457 {
    width: 1457rem;
  }
  .w-1458 {
    width: 1458rem;
  }
  .w-1459 {
    width: 1459rem;
  }
  .w-1460 {
    width: 1460rem;
  }
  .w-1461 {
    width: 1461rem;
  }
  .w-1462 {
    width: 1462rem;
  }
  .w-1463 {
    width: 1463rem;
  }
  .w-1464 {
    width: 1464rem;
  }
  .w-1465 {
    width: 1465rem;
  }
  .w-1466 {
    width: 1466rem;
  }
  .w-1467 {
    width: 1467rem;
  }
  .w-1468 {
    width: 1468rem;
  }
  .w-1469 {
    width: 1469rem;
  }
  .w-1470 {
    width: 1470rem;
  }
  .w-1471 {
    width: 1471rem;
  }
  .w-1472 {
    width: 1472rem;
  }
  .w-1473 {
    width: 1473rem;
  }
  .w-1474 {
    width: 1474rem;
  }
  .w-1475 {
    width: 1475rem;
  }
  .w-1476 {
    width: 1476rem;
  }
  .w-1477 {
    width: 1477rem;
  }
  .w-1478 {
    width: 1478rem;
  }
  .w-1479 {
    width: 1479rem;
  }
  .w-1480 {
    width: 1480rem;
  }
  .w-1481 {
    width: 1481rem;
  }
  .w-1482 {
    width: 1482rem;
  }
  .w-1483 {
    width: 1483rem;
  }
  .w-1484 {
    width: 1484rem;
  }
  .w-1485 {
    width: 1485rem;
  }
  .w-1486 {
    width: 1486rem;
  }
  .w-1487 {
    width: 1487rem;
  }
  .w-1488 {
    width: 1488rem;
  }
  .w-1489 {
    width: 1489rem;
  }
  .w-1490 {
    width: 1490rem;
  }
  .w-1491 {
    width: 1491rem;
  }
  .w-1492 {
    width: 1492rem;
  }
  .w-1493 {
    width: 1493rem;
  }
  .w-1494 {
    width: 1494rem;
  }
  .w-1495 {
    width: 1495rem;
  }
  .w-1496 {
    width: 1496rem;
  }
  .w-1497 {
    width: 1497rem;
  }
  .w-1498 {
    width: 1498rem;
  }
  .w-1499 {
    width: 1499rem;
  }
  .w-1500 {
    width: 1500rem;
  }
  .w-1501 {
    width: 1501rem;
  }
  .w-1502 {
    width: 1502rem;
  }
  .w-1503 {
    width: 1503rem;
  }
  .w-1504 {
    width: 1504rem;
  }
  .w-1505 {
    width: 1505rem;
  }
  .w-1506 {
    width: 1506rem;
  }
  .w-1507 {
    width: 1507rem;
  }
  .w-1508 {
    width: 1508rem;
  }
  .w-1509 {
    width: 1509rem;
  }
  .w-1510 {
    width: 1510rem;
  }
  .w-1511 {
    width: 1511rem;
  }
  .w-1512 {
    width: 1512rem;
  }
  .w-1513 {
    width: 1513rem;
  }
  .w-1514 {
    width: 1514rem;
  }
  .w-1515 {
    width: 1515rem;
  }
  .w-1516 {
    width: 1516rem;
  }
  .w-1517 {
    width: 1517rem;
  }
  .w-1518 {
    width: 1518rem;
  }
  .w-1519 {
    width: 1519rem;
  }
  .w-1520 {
    width: 1520rem;
  }
  .w-1521 {
    width: 1521rem;
  }
  .w-1522 {
    width: 1522rem;
  }
  .w-1523 {
    width: 1523rem;
  }
  .w-1524 {
    width: 1524rem;
  }
  .w-1525 {
    width: 1525rem;
  }
  .w-1526 {
    width: 1526rem;
  }
  .w-1527 {
    width: 1527rem;
  }
  .w-1528 {
    width: 1528rem;
  }
  .w-1529 {
    width: 1529rem;
  }
  .w-1530 {
    width: 1530rem;
  }
  .w-1531 {
    width: 1531rem;
  }
  .w-1532 {
    width: 1532rem;
  }
  .w-1533 {
    width: 1533rem;
  }
  .w-1534 {
    width: 1534rem;
  }
  .w-1535 {
    width: 1535rem;
  }
  .w-1536 {
    width: 1536rem;
  }
  .w-1537 {
    width: 1537rem;
  }
  .w-1538 {
    width: 1538rem;
  }
  .w-1539 {
    width: 1539rem;
  }
  .w-1540 {
    width: 1540rem;
  }
  .w-1541 {
    width: 1541rem;
  }
  .w-1542 {
    width: 1542rem;
  }
  .w-1543 {
    width: 1543rem;
  }
  .w-1544 {
    width: 1544rem;
  }
  .w-1545 {
    width: 1545rem;
  }
  .w-1546 {
    width: 1546rem;
  }
  .w-1547 {
    width: 1547rem;
  }
  .w-1548 {
    width: 1548rem;
  }
  .w-1549 {
    width: 1549rem;
  }
  .w-1550 {
    width: 1550rem;
  }
  .w-1551 {
    width: 1551rem;
  }
  .w-1552 {
    width: 1552rem;
  }
  .w-1553 {
    width: 1553rem;
  }
  .w-1554 {
    width: 1554rem;
  }
  .w-1555 {
    width: 1555rem;
  }
  .w-1556 {
    width: 1556rem;
  }
  .w-1557 {
    width: 1557rem;
  }
  .w-1558 {
    width: 1558rem;
  }
  .w-1559 {
    width: 1559rem;
  }
  .w-1560 {
    width: 1560rem;
  }
  .w-1561 {
    width: 1561rem;
  }
  .w-1562 {
    width: 1562rem;
  }
  .w-1563 {
    width: 1563rem;
  }
  .w-1564 {
    width: 1564rem;
  }
  .w-1565 {
    width: 1565rem;
  }
  .w-1566 {
    width: 1566rem;
  }
  .w-1567 {
    width: 1567rem;
  }
  .w-1568 {
    width: 1568rem;
  }
  .w-1569 {
    width: 1569rem;
  }
  .w-1570 {
    width: 1570rem;
  }
  .w-1571 {
    width: 1571rem;
  }
  .w-1572 {
    width: 1572rem;
  }
  .w-1573 {
    width: 1573rem;
  }
  .w-1574 {
    width: 1574rem;
  }
  .w-1575 {
    width: 1575rem;
  }
  .w-1576 {
    width: 1576rem;
  }
  .w-1577 {
    width: 1577rem;
  }
  .w-1578 {
    width: 1578rem;
  }
  .w-1579 {
    width: 1579rem;
  }
  .w-1580 {
    width: 1580rem;
  }
  .w-1581 {
    width: 1581rem;
  }
  .w-1582 {
    width: 1582rem;
  }
  .w-1583 {
    width: 1583rem;
  }
  .w-1584 {
    width: 1584rem;
  }
  .w-1585 {
    width: 1585rem;
  }
  .w-1586 {
    width: 1586rem;
  }
  .w-1587 {
    width: 1587rem;
  }
  .w-1588 {
    width: 1588rem;
  }
  .w-1589 {
    width: 1589rem;
  }
  .w-1590 {
    width: 1590rem;
  }
  .w-1591 {
    width: 1591rem;
  }
  .w-1592 {
    width: 1592rem;
  }
  .w-1593 {
    width: 1593rem;
  }
  .w-1594 {
    width: 1594rem;
  }
  .w-1595 {
    width: 1595rem;
  }
  .w-1596 {
    width: 1596rem;
  }
  .w-1597 {
    width: 1597rem;
  }
  .w-1598 {
    width: 1598rem;
  }
  .w-1599 {
    width: 1599rem;
  }
  .w-1600 {
    width: 1600rem;
  }
  .w-1601 {
    width: 1601rem;
  }
  .w-1602 {
    width: 1602rem;
  }
  .w-1603 {
    width: 1603rem;
  }
  .w-1604 {
    width: 1604rem;
  }
  .w-1605 {
    width: 1605rem;
  }
  .w-1606 {
    width: 1606rem;
  }
  .w-1607 {
    width: 1607rem;
  }
  .w-1608 {
    width: 1608rem;
  }
  .w-1609 {
    width: 1609rem;
  }
  .w-1610 {
    width: 1610rem;
  }
  .w-1611 {
    width: 1611rem;
  }
  .w-1612 {
    width: 1612rem;
  }
  .w-1613 {
    width: 1613rem;
  }
  .w-1614 {
    width: 1614rem;
  }
  .w-1615 {
    width: 1615rem;
  }
  .w-1616 {
    width: 1616rem;
  }
  .w-1617 {
    width: 1617rem;
  }
  .w-1618 {
    width: 1618rem;
  }
  .w-1619 {
    width: 1619rem;
  }
  .w-1620 {
    width: 1620rem;
  }
  .w-1621 {
    width: 1621rem;
  }
  .w-1622 {
    width: 1622rem;
  }
  .w-1623 {
    width: 1623rem;
  }
  .w-1624 {
    width: 1624rem;
  }
  .w-1625 {
    width: 1625rem;
  }
  .w-1626 {
    width: 1626rem;
  }
  .w-1627 {
    width: 1627rem;
  }
  .w-1628 {
    width: 1628rem;
  }
  .w-1629 {
    width: 1629rem;
  }
  .w-1630 {
    width: 1630rem;
  }
  .w-1631 {
    width: 1631rem;
  }
  .w-1632 {
    width: 1632rem;
  }
  .w-1633 {
    width: 1633rem;
  }
  .w-1634 {
    width: 1634rem;
  }
  .w-1635 {
    width: 1635rem;
  }
  .w-1636 {
    width: 1636rem;
  }
  .w-1637 {
    width: 1637rem;
  }
  .w-1638 {
    width: 1638rem;
  }
  .w-1639 {
    width: 1639rem;
  }
  .w-1640 {
    width: 1640rem;
  }
  .w-1641 {
    width: 1641rem;
  }
  .w-1642 {
    width: 1642rem;
  }
  .w-1643 {
    width: 1643rem;
  }
  .w-1644 {
    width: 1644rem;
  }
  .w-1645 {
    width: 1645rem;
  }
  .w-1646 {
    width: 1646rem;
  }
  .w-1647 {
    width: 1647rem;
  }
  .w-1648 {
    width: 1648rem;
  }
  .w-1649 {
    width: 1649rem;
  }
  .w-1650 {
    width: 1650rem;
  }
  .w-1651 {
    width: 1651rem;
  }
  .w-1652 {
    width: 1652rem;
  }
  .w-1653 {
    width: 1653rem;
  }
  .w-1654 {
    width: 1654rem;
  }
  .w-1655 {
    width: 1655rem;
  }
  .w-1656 {
    width: 1656rem;
  }
  .w-1657 {
    width: 1657rem;
  }
  .w-1658 {
    width: 1658rem;
  }
  .w-1659 {
    width: 1659rem;
  }
  .w-1660 {
    width: 1660rem;
  }
  .w-1661 {
    width: 1661rem;
  }
  .w-1662 {
    width: 1662rem;
  }
  .w-1663 {
    width: 1663rem;
  }
  .w-1664 {
    width: 1664rem;
  }
  .w-1665 {
    width: 1665rem;
  }
  .w-1666 {
    width: 1666rem;
  }
  .w-1667 {
    width: 1667rem;
  }
  .w-1668 {
    width: 1668rem;
  }
  .w-1669 {
    width: 1669rem;
  }
  .w-1670 {
    width: 1670rem;
  }
  .w-1671 {
    width: 1671rem;
  }
  .w-1672 {
    width: 1672rem;
  }
  .w-1673 {
    width: 1673rem;
  }
  .w-1674 {
    width: 1674rem;
  }
  .w-1675 {
    width: 1675rem;
  }
  .w-1676 {
    width: 1676rem;
  }
  .w-1677 {
    width: 1677rem;
  }
  .w-1678 {
    width: 1678rem;
  }
  .w-1679 {
    width: 1679rem;
  }
  .w-1680 {
    width: 1680rem;
  }
  .w-1681 {
    width: 1681rem;
  }
  .w-1682 {
    width: 1682rem;
  }
  .w-1683 {
    width: 1683rem;
  }
  .w-1684 {
    width: 1684rem;
  }
  .w-1685 {
    width: 1685rem;
  }
  .w-1686 {
    width: 1686rem;
  }
  .w-1687 {
    width: 1687rem;
  }
  .w-1688 {
    width: 1688rem;
  }
  .w-1689 {
    width: 1689rem;
  }
  .w-1690 {
    width: 1690rem;
  }
  .w-1691 {
    width: 1691rem;
  }
  .w-1692 {
    width: 1692rem;
  }
  .w-1693 {
    width: 1693rem;
  }
  .w-1694 {
    width: 1694rem;
  }
  .w-1695 {
    width: 1695rem;
  }
  .w-1696 {
    width: 1696rem;
  }
  .w-1697 {
    width: 1697rem;
  }
  .w-1698 {
    width: 1698rem;
  }
  .w-1699 {
    width: 1699rem;
  }
  .w-1700 {
    width: 1700rem;
  }
  .w-1701 {
    width: 1701rem;
  }
  .w-1702 {
    width: 1702rem;
  }
  .w-1703 {
    width: 1703rem;
  }
  .w-1704 {
    width: 1704rem;
  }
  .w-1705 {
    width: 1705rem;
  }
  .w-1706 {
    width: 1706rem;
  }
  .w-1707 {
    width: 1707rem;
  }
  .w-1708 {
    width: 1708rem;
  }
  .w-1709 {
    width: 1709rem;
  }
  .w-1710 {
    width: 1710rem;
  }
  .w-1711 {
    width: 1711rem;
  }
  .w-1712 {
    width: 1712rem;
  }
  .w-1713 {
    width: 1713rem;
  }
  .w-1714 {
    width: 1714rem;
  }
  .w-1715 {
    width: 1715rem;
  }
  .w-1716 {
    width: 1716rem;
  }
  .w-1717 {
    width: 1717rem;
  }
  .w-1718 {
    width: 1718rem;
  }
  .w-1719 {
    width: 1719rem;
  }
  .w-1720 {
    width: 1720rem;
  }
  .w-1721 {
    width: 1721rem;
  }
  .w-1722 {
    width: 1722rem;
  }
  .w-1723 {
    width: 1723rem;
  }
  .w-1724 {
    width: 1724rem;
  }
  .w-1725 {
    width: 1725rem;
  }
  .w-1726 {
    width: 1726rem;
  }
  .w-1727 {
    width: 1727rem;
  }
  .w-1728 {
    width: 1728rem;
  }
  .w-1729 {
    width: 1729rem;
  }
  .w-1730 {
    width: 1730rem;
  }
  .w-1731 {
    width: 1731rem;
  }
  .w-1732 {
    width: 1732rem;
  }
  .w-1733 {
    width: 1733rem;
  }
  .w-1734 {
    width: 1734rem;
  }
  .w-1735 {
    width: 1735rem;
  }
  .w-1736 {
    width: 1736rem;
  }
  .w-1737 {
    width: 1737rem;
  }
  .w-1738 {
    width: 1738rem;
  }
  .w-1739 {
    width: 1739rem;
  }
  .w-1740 {
    width: 1740rem;
  }
  .w-1741 {
    width: 1741rem;
  }
  .w-1742 {
    width: 1742rem;
  }
  .w-1743 {
    width: 1743rem;
  }
  .w-1744 {
    width: 1744rem;
  }
  .w-1745 {
    width: 1745rem;
  }
  .w-1746 {
    width: 1746rem;
  }
  .w-1747 {
    width: 1747rem;
  }
  .w-1748 {
    width: 1748rem;
  }
  .w-1749 {
    width: 1749rem;
  }
  .w-1750 {
    width: 1750rem;
  }
  .w-1751 {
    width: 1751rem;
  }
  .w-1752 {
    width: 1752rem;
  }
  .w-1753 {
    width: 1753rem;
  }
  .w-1754 {
    width: 1754rem;
  }
  .w-1755 {
    width: 1755rem;
  }
  .w-1756 {
    width: 1756rem;
  }
  .w-1757 {
    width: 1757rem;
  }
  .w-1758 {
    width: 1758rem;
  }
  .w-1759 {
    width: 1759rem;
  }
  .w-1760 {
    width: 1760rem;
  }
  .w-1761 {
    width: 1761rem;
  }
  .w-1762 {
    width: 1762rem;
  }
  .w-1763 {
    width: 1763rem;
  }
  .w-1764 {
    width: 1764rem;
  }
  .w-1765 {
    width: 1765rem;
  }
  .w-1766 {
    width: 1766rem;
  }
  .w-1767 {
    width: 1767rem;
  }
  .w-1768 {
    width: 1768rem;
  }
  .w-1769 {
    width: 1769rem;
  }
  .w-1770 {
    width: 1770rem;
  }
  .w-1771 {
    width: 1771rem;
  }
  .w-1772 {
    width: 1772rem;
  }
  .w-1773 {
    width: 1773rem;
  }
  .w-1774 {
    width: 1774rem;
  }
  .w-1775 {
    width: 1775rem;
  }
  .w-1776 {
    width: 1776rem;
  }
  .w-1777 {
    width: 1777rem;
  }
  .w-1778 {
    width: 1778rem;
  }
  .w-1779 {
    width: 1779rem;
  }
  .w-1780 {
    width: 1780rem;
  }
  .w-1781 {
    width: 1781rem;
  }
  .w-1782 {
    width: 1782rem;
  }
  .w-1783 {
    width: 1783rem;
  }
  .w-1784 {
    width: 1784rem;
  }
  .w-1785 {
    width: 1785rem;
  }
  .w-1786 {
    width: 1786rem;
  }
  .w-1787 {
    width: 1787rem;
  }
  .w-1788 {
    width: 1788rem;
  }
  .w-1789 {
    width: 1789rem;
  }
  .w-1790 {
    width: 1790rem;
  }
  .w-1791 {
    width: 1791rem;
  }
  .w-1792 {
    width: 1792rem;
  }
  .w-1793 {
    width: 1793rem;
  }
  .w-1794 {
    width: 1794rem;
  }
  .w-1795 {
    width: 1795rem;
  }
  .w-1796 {
    width: 1796rem;
  }
  .w-1797 {
    width: 1797rem;
  }
  .w-1798 {
    width: 1798rem;
  }
  .w-1799 {
    width: 1799rem;
  }
  .w-1800 {
    width: 1800rem;
  }
  .w-1801 {
    width: 1801rem;
  }
  .w-1802 {
    width: 1802rem;
  }
  .w-1803 {
    width: 1803rem;
  }
  .w-1804 {
    width: 1804rem;
  }
  .w-1805 {
    width: 1805rem;
  }
  .w-1806 {
    width: 1806rem;
  }
  .w-1807 {
    width: 1807rem;
  }
  .w-1808 {
    width: 1808rem;
  }
  .w-1809 {
    width: 1809rem;
  }
  .w-1810 {
    width: 1810rem;
  }
  .w-1811 {
    width: 1811rem;
  }
  .w-1812 {
    width: 1812rem;
  }
  .w-1813 {
    width: 1813rem;
  }
  .w-1814 {
    width: 1814rem;
  }
  .w-1815 {
    width: 1815rem;
  }
  .w-1816 {
    width: 1816rem;
  }
  .w-1817 {
    width: 1817rem;
  }
  .w-1818 {
    width: 1818rem;
  }
  .w-1819 {
    width: 1819rem;
  }
  .w-1820 {
    width: 1820rem;
  }
  .w-1821 {
    width: 1821rem;
  }
  .w-1822 {
    width: 1822rem;
  }
  .w-1823 {
    width: 1823rem;
  }
  .w-1824 {
    width: 1824rem;
  }
  .w-1825 {
    width: 1825rem;
  }
  .w-1826 {
    width: 1826rem;
  }
  .w-1827 {
    width: 1827rem;
  }
  .w-1828 {
    width: 1828rem;
  }
  .w-1829 {
    width: 1829rem;
  }
  .w-1830 {
    width: 1830rem;
  }
  .w-1831 {
    width: 1831rem;
  }
  .w-1832 {
    width: 1832rem;
  }
  .w-1833 {
    width: 1833rem;
  }
  .w-1834 {
    width: 1834rem;
  }
  .w-1835 {
    width: 1835rem;
  }
  .w-1836 {
    width: 1836rem;
  }
  .w-1837 {
    width: 1837rem;
  }
  .w-1838 {
    width: 1838rem;
  }
  .w-1839 {
    width: 1839rem;
  }
  .w-1840 {
    width: 1840rem;
  }
  .w-1841 {
    width: 1841rem;
  }
  .w-1842 {
    width: 1842rem;
  }
  .w-1843 {
    width: 1843rem;
  }
  .w-1844 {
    width: 1844rem;
  }
  .w-1845 {
    width: 1845rem;
  }
  .w-1846 {
    width: 1846rem;
  }
  .w-1847 {
    width: 1847rem;
  }
  .w-1848 {
    width: 1848rem;
  }
  .w-1849 {
    width: 1849rem;
  }
  .w-1850 {
    width: 1850rem;
  }
  .w-1851 {
    width: 1851rem;
  }
  .w-1852 {
    width: 1852rem;
  }
  .w-1853 {
    width: 1853rem;
  }
  .w-1854 {
    width: 1854rem;
  }
  .w-1855 {
    width: 1855rem;
  }
  .w-1856 {
    width: 1856rem;
  }
  .w-1857 {
    width: 1857rem;
  }
  .w-1858 {
    width: 1858rem;
  }
  .w-1859 {
    width: 1859rem;
  }
  .w-1860 {
    width: 1860rem;
  }
  .w-1861 {
    width: 1861rem;
  }
  .w-1862 {
    width: 1862rem;
  }
  .w-1863 {
    width: 1863rem;
  }
  .w-1864 {
    width: 1864rem;
  }
  .w-1865 {
    width: 1865rem;
  }
  .w-1866 {
    width: 1866rem;
  }
  .w-1867 {
    width: 1867rem;
  }
  .w-1868 {
    width: 1868rem;
  }
  .w-1869 {
    width: 1869rem;
  }
  .w-1870 {
    width: 1870rem;
  }
  .w-1871 {
    width: 1871rem;
  }
  .w-1872 {
    width: 1872rem;
  }
  .w-1873 {
    width: 1873rem;
  }
  .w-1874 {
    width: 1874rem;
  }
  .w-1875 {
    width: 1875rem;
  }
  .w-1876 {
    width: 1876rem;
  }
  .w-1877 {
    width: 1877rem;
  }
  .w-1878 {
    width: 1878rem;
  }
  .w-1879 {
    width: 1879rem;
  }
  .w-1880 {
    width: 1880rem;
  }
  .w-1881 {
    width: 1881rem;
  }
  .w-1882 {
    width: 1882rem;
  }
  .w-1883 {
    width: 1883rem;
  }
  .w-1884 {
    width: 1884rem;
  }
  .w-1885 {
    width: 1885rem;
  }
  .w-1886 {
    width: 1886rem;
  }
  .w-1887 {
    width: 1887rem;
  }
  .w-1888 {
    width: 1888rem;
  }
  .w-1889 {
    width: 1889rem;
  }
  .w-1890 {
    width: 1890rem;
  }
  .w-1891 {
    width: 1891rem;
  }
  .w-1892 {
    width: 1892rem;
  }
  .w-1893 {
    width: 1893rem;
  }
  .w-1894 {
    width: 1894rem;
  }
  .w-1895 {
    width: 1895rem;
  }
  .w-1896 {
    width: 1896rem;
  }
  .w-1897 {
    width: 1897rem;
  }
  .w-1898 {
    width: 1898rem;
  }
  .w-1899 {
    width: 1899rem;
  }
  .w-1900 {
    width: 1900rem;
  }
  .w-1901 {
    width: 1901rem;
  }
  .w-1902 {
    width: 1902rem;
  }
  .w-1903 {
    width: 1903rem;
  }
  .w-1904 {
    width: 1904rem;
  }
  .w-1905 {
    width: 1905rem;
  }
  .w-1906 {
    width: 1906rem;
  }
  .w-1907 {
    width: 1907rem;
  }
  .w-1908 {
    width: 1908rem;
  }
  .w-1909 {
    width: 1909rem;
  }
  .w-1910 {
    width: 1910rem;
  }
  .w-1911 {
    width: 1911rem;
  }
  .w-1912 {
    width: 1912rem;
  }
  .w-1913 {
    width: 1913rem;
  }
  .w-1914 {
    width: 1914rem;
  }
  .w-1915 {
    width: 1915rem;
  }
  .w-1916 {
    width: 1916rem;
  }
  .w-1917 {
    width: 1917rem;
  }
  .w-1918 {
    width: 1918rem;
  }
  .w-1919 {
    width: 1919rem;
  }
  .w-1920 {
    width: 1920rem;
  }
  .w-1921 {
    width: 1921rem;
  }
  .w-1922 {
    width: 1922rem;
  }
  .w-1923 {
    width: 1923rem;
  }
  .w-1924 {
    width: 1924rem;
  }
  .w-1925 {
    width: 1925rem;
  }
  .w-1926 {
    width: 1926rem;
  }
  .w-1927 {
    width: 1927rem;
  }
  .w-1928 {
    width: 1928rem;
  }
  .w-1929 {
    width: 1929rem;
  }
  .w-1930 {
    width: 1930rem;
  }
  .w-1931 {
    width: 1931rem;
  }
  .w-1932 {
    width: 1932rem;
  }
  .w-1933 {
    width: 1933rem;
  }
  .w-1934 {
    width: 1934rem;
  }
  .w-1935 {
    width: 1935rem;
  }
  .w-1936 {
    width: 1936rem;
  }
  .w-1937 {
    width: 1937rem;
  }
  .w-1938 {
    width: 1938rem;
  }
  .w-1939 {
    width: 1939rem;
  }
  .w-1940 {
    width: 1940rem;
  }
  .w-1941 {
    width: 1941rem;
  }
  .w-1942 {
    width: 1942rem;
  }
  .w-1943 {
    width: 1943rem;
  }
  .w-1944 {
    width: 1944rem;
  }
  .w-1945 {
    width: 1945rem;
  }
  .w-1946 {
    width: 1946rem;
  }
  .w-1947 {
    width: 1947rem;
  }
  .w-1948 {
    width: 1948rem;
  }
  .w-1949 {
    width: 1949rem;
  }
  .w-1950 {
    width: 1950rem;
  }
  .w-1951 {
    width: 1951rem;
  }
  .w-1952 {
    width: 1952rem;
  }
  .w-1953 {
    width: 1953rem;
  }
  .w-1954 {
    width: 1954rem;
  }
  .w-1955 {
    width: 1955rem;
  }
  .w-1956 {
    width: 1956rem;
  }
  .w-1957 {
    width: 1957rem;
  }
  .w-1958 {
    width: 1958rem;
  }
  .w-1959 {
    width: 1959rem;
  }
  .w-1960 {
    width: 1960rem;
  }
  .w-1961 {
    width: 1961rem;
  }
  .w-1962 {
    width: 1962rem;
  }
  .w-1963 {
    width: 1963rem;
  }
  .w-1964 {
    width: 1964rem;
  }
  .w-1965 {
    width: 1965rem;
  }
  .w-1966 {
    width: 1966rem;
  }
  .w-1967 {
    width: 1967rem;
  }
  .w-1968 {
    width: 1968rem;
  }
  .w-1969 {
    width: 1969rem;
  }
  .w-1970 {
    width: 1970rem;
  }
  .w-1971 {
    width: 1971rem;
  }
  .w-1972 {
    width: 1972rem;
  }
  .w-1973 {
    width: 1973rem;
  }
  .w-1974 {
    width: 1974rem;
  }
  .w-1975 {
    width: 1975rem;
  }
  .w-1976 {
    width: 1976rem;
  }
  .w-1977 {
    width: 1977rem;
  }
  .w-1978 {
    width: 1978rem;
  }
  .w-1979 {
    width: 1979rem;
  }
  .w-1980 {
    width: 1980rem;
  }
  .w-1981 {
    width: 1981rem;
  }
  .w-1982 {
    width: 1982rem;
  }
  .w-1983 {
    width: 1983rem;
  }
  .w-1984 {
    width: 1984rem;
  }
  .w-1985 {
    width: 1985rem;
  }
  .w-1986 {
    width: 1986rem;
  }
  .w-1987 {
    width: 1987rem;
  }
  .w-1988 {
    width: 1988rem;
  }
  .w-1989 {
    width: 1989rem;
  }
  .w-1990 {
    width: 1990rem;
  }
  .w-1991 {
    width: 1991rem;
  }
  .w-1992 {
    width: 1992rem;
  }
  .w-1993 {
    width: 1993rem;
  }
  .w-1994 {
    width: 1994rem;
  }
  .w-1995 {
    width: 1995rem;
  }
  .w-1996 {
    width: 1996rem;
  }
  .w-1997 {
    width: 1997rem;
  }
  .w-1998 {
    width: 1998rem;
  }
  .w-1999 {
    width: 1999rem;
  }
  .wp-0 {
    width: 0%;
  }
  .wp-1 {
    width: 1%;
  }
  .wp-2 {
    width: 2%;
  }
  .wp-3 {
    width: 3%;
  }
  .wp-4 {
    width: 4%;
  }
  .wp-5 {
    width: 5%;
  }
  .wp-6 {
    width: 6%;
  }
  .wp-7 {
    width: 7%;
  }
  .wp-8 {
    width: 8%;
  }
  .wp-9 {
    width: 9%;
  }
  .wp-10 {
    width: 10%;
  }
  .wp-11 {
    width: 11%;
  }
  .wp-12 {
    width: 12%;
  }
  .wp-13 {
    width: 13%;
  }
  .wp-14 {
    width: 14%;
  }
  .wp-15 {
    width: 15%;
  }
  .wp-16 {
    width: 16%;
  }
  .wp-17 {
    width: 17%;
  }
  .wp-18 {
    width: 18%;
  }
  .wp-19 {
    width: 19%;
  }
  .wp-20 {
    width: 20%;
  }
  .wp-21 {
    width: 21%;
  }
  .wp-22 {
    width: 22%;
  }
  .wp-23 {
    width: 23%;
  }
  .wp-24 {
    width: 24%;
  }
  .wp-25 {
    width: 25%;
  }
  .wp-26 {
    width: 26%;
  }
  .wp-27 {
    width: 27%;
  }
  .wp-28 {
    width: 28%;
  }
  .wp-29 {
    width: 29%;
  }
  .wp-30 {
    width: 30%;
  }
  .wp-31 {
    width: 31%;
  }
  .wp-32 {
    width: 32%;
  }
  .wp-33 {
    width: 33%;
  }
  .wp-34 {
    width: 34%;
  }
  .wp-35 {
    width: 35%;
  }
  .wp-36 {
    width: 36%;
  }
  .wp-37 {
    width: 37%;
  }
  .wp-38 {
    width: 38%;
  }
  .wp-39 {
    width: 39%;
  }
  .wp-40 {
    width: 40%;
  }
  .wp-41 {
    width: 41%;
  }
  .wp-42 {
    width: 42%;
  }
  .wp-43 {
    width: 43%;
  }
  .wp-44 {
    width: 44%;
  }
  .wp-45 {
    width: 45%;
  }
  .wp-46 {
    width: 46%;
  }
  .wp-47 {
    width: 47%;
  }
  .wp-48 {
    width: 48%;
  }
  .wp-49 {
    width: 49%;
  }
  .wp-50 {
    width: 50%;
  }
  .wp-51 {
    width: 51%;
  }
  .wp-52 {
    width: 52%;
  }
  .wp-53 {
    width: 53%;
  }
  .wp-54 {
    width: 54%;
  }
  .wp-55 {
    width: 55%;
  }
  .wp-56 {
    width: 56%;
  }
  .wp-57 {
    width: 57%;
  }
  .wp-58 {
    width: 58%;
  }
  .wp-59 {
    width: 59%;
  }
  .wp-60 {
    width: 60%;
  }
  .wp-61 {
    width: 61%;
  }
  .wp-62 {
    width: 62%;
  }
  .wp-63 {
    width: 63%;
  }
  .wp-64 {
    width: 64%;
  }
  .wp-65 {
    width: 65%;
  }
  .wp-66 {
    width: 66%;
  }
  .wp-67 {
    width: 67%;
  }
  .wp-68 {
    width: 68%;
  }
  .wp-69 {
    width: 69%;
  }
  .wp-70 {
    width: 70%;
  }
  .wp-71 {
    width: 71%;
  }
  .wp-72 {
    width: 72%;
  }
  .wp-73 {
    width: 73%;
  }
  .wp-74 {
    width: 74%;
  }
  .wp-75 {
    width: 75%;
  }
  .wp-76 {
    width: 76%;
  }
  .wp-77 {
    width: 77%;
  }
  .wp-78 {
    width: 78%;
  }
  .wp-79 {
    width: 79%;
  }
  .wp-80 {
    width: 80%;
  }
  .wp-81 {
    width: 81%;
  }
  .wp-82 {
    width: 82%;
  }
  .wp-83 {
    width: 83%;
  }
  .wp-84 {
    width: 84%;
  }
  .wp-85 {
    width: 85%;
  }
  .wp-86 {
    width: 86%;
  }
  .wp-87 {
    width: 87%;
  }
  .wp-88 {
    width: 88%;
  }
  .wp-89 {
    width: 89%;
  }
  .wp-90 {
    width: 90%;
  }
  .wp-91 {
    width: 91%;
  }
  .wp-92 {
    width: 92%;
  }
  .wp-93 {
    width: 93%;
  }
  .wp-94 {
    width: 94%;
  }
  .wp-95 {
    width: 95%;
  }
  .wp-96 {
    width: 96%;
  }
  .wp-97 {
    width: 97%;
  }
  .wp-98 {
    width: 98%;
  }
  .wp-99 {
    width: 99%;
  }
  .font-thin {
    font-weight: thin;
  }
  .font-normal {
    font-weight: normal;
  }
  .font-lighter {
    font-weight: lighter;
  }
  .font-light {
    font-weight: light;
  }
  .font-bold {
    font-weight: bold;
  }
  .font-bolder {
    font-weight: bolder;
  }
  .font-100 {
    font-weight: 100;
  }
  .font-200 {
    font-weight: 200;
  }
  .font-300 {
    font-weight: 300;
  }
  .font-400 {
    font-weight: 400;
  }
  .font-500 {
    font-weight: 500;
  }
  .font-600 {
    font-weight: 600;
  }
  .font-700 {
    font-weight: 700;
  }
  .font-800 {
    font-weight: 800;
  }
  .font-900 {
    font-weight: 900;
  }
  .shadow-xs {
    box-shadow: 0px 3px 4px -5px rgba(51, 51, 51, 0.03),
      0px 1px 2px rgba(51, 51, 51, 0.05);
  }
  .shadow-s {
    box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
      0px 0px 2px rgba(51, 51, 51, 0.02);
  }
  .shadow-a {
    box-shadow: 0px 32px 41px -23px rgba(51, 51, 51, 0.07),
      0px 2px 6px rgba(51, 51, 51, 0.06);
  }
}
/* 
   - This section contains utilities for padding
   - includes: padding, padding-left, padding-right, padding-top, padding-bottom, padding-x axis, padding-y axis
   - The sizing is implemented using rem but with px accuracy.
   - i.e if you want to give a padding of 30px, you can use p-30 which is 30px but converted to rem on background

*/
.p-0 {
  padding: 0rem;
}

.p-1 {
  padding: 0.1rem;
}

.p-2 {
  padding: 0.2rem;
}

.p-3 {
  padding: 0.3rem;
}

.p-4 {
  padding: 0.4rem;
}

.p-5 {
  padding: 0.5rem;
}

.p-6 {
  padding: 0.6rem;
}

.p-7 {
  padding: 0.7rem;
}

.p-8 {
  padding: 0.8rem;
}

.p-9 {
  padding: 0.9rem;
}

.p-10 {
  padding: 1rem;
}

.p-11 {
  padding: 1.1rem;
}

.p-12 {
  padding: 1.2rem;
}

.p-13 {
  padding: 1.3rem;
}

.p-14 {
  padding: 1.4rem;
}

.p-15 {
  padding: 1.5rem;
}

.p-16 {
  padding: 1.6rem;
}

.p-17 {
  padding: 1.7rem;
}

.p-18 {
  padding: 1.8rem;
}

.p-19 {
  padding: 1.9rem;
}

.p-20 {
  padding: 2rem;
}

.p-21 {
  padding: 2.1rem;
}

.p-22 {
  padding: 2.2rem;
}

.p-23 {
  padding: 2.3rem;
}

.p-24 {
  padding: 2.4rem;
}

.p-25 {
  padding: 2.5rem;
}

.p-26 {
  padding: 2.6rem;
}

.p-27 {
  padding: 2.7rem;
}

.p-28 {
  padding: 2.8rem;
}

.p-29 {
  padding: 2.9rem;
}

.p-30 {
  padding: 3rem;
}

.p-31 {
  padding: 3.1rem;
}

.p-32 {
  padding: 3.2rem;
}

.p-33 {
  padding: 3.3rem;
}

.p-34 {
  padding: 3.4rem;
}

.p-35 {
  padding: 3.5rem;
}

.p-36 {
  padding: 3.6rem;
}

.p-37 {
  padding: 3.7rem;
}

.p-38 {
  padding: 3.8rem;
}

.p-39 {
  padding: 3.9rem;
}

.p-40 {
  padding: 4rem;
}

.p-41 {
  padding: 4.1rem;
}

.p-42 {
  padding: 4.2rem;
}

.p-43 {
  padding: 4.3rem;
}

.p-44 {
  padding: 4.4rem;
}

.p-45 {
  padding: 4.5rem;
}

.p-46 {
  padding: 4.6rem;
}

.p-47 {
  padding: 4.7rem;
}

.p-48 {
  padding: 4.8rem;
}

.p-49 {
  padding: 4.9rem;
}

.p-50 {
  padding: 5rem;
}

.p-51 {
  padding: 5.1rem;
}

.p-52 {
  padding: 5.2rem;
}

.p-53 {
  padding: 5.3rem;
}

.p-54 {
  padding: 5.4rem;
}

.p-55 {
  padding: 5.5rem;
}

.p-56 {
  padding: 5.6rem;
}

.p-57 {
  padding: 5.7rem;
}

.p-58 {
  padding: 5.8rem;
}

.p-59 {
  padding: 5.9rem;
}

.p-60 {
  padding: 6rem;
}

.p-61 {
  padding: 6.1rem;
}

.p-62 {
  padding: 6.2rem;
}

.p-63 {
  padding: 6.3rem;
}

.p-64 {
  padding: 6.4rem;
}

.p-65 {
  padding: 6.5rem;
}

.p-66 {
  padding: 6.6rem;
}

.p-67 {
  padding: 6.7rem;
}

.p-68 {
  padding: 6.8rem;
}

.p-69 {
  padding: 6.9rem;
}

.p-70 {
  padding: 7rem;
}

.p-71 {
  padding: 7.1rem;
}

.p-72 {
  padding: 7.2rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pl-1 {
  padding-left: 0.1rem;
}

.pl-2 {
  padding-left: 0.2rem;
}

.pl-3 {
  padding-left: 0.3rem;
}

.pl-4 {
  padding-left: 0.4rem;
}

.pl-5 {
  padding-left: 0.5rem;
}

.pl-6 {
  padding-left: 0.6rem;
}

.pl-7 {
  padding-left: 0.7rem;
}

.pl-8 {
  padding-left: 0.8rem;
}

.pl-9 {
  padding-left: 0.9rem;
}

.pl-10 {
  padding-left: 1rem;
}

.pl-11 {
  padding-left: 1.1rem;
}

.pl-12 {
  padding-left: 1.2rem;
}

.pl-13 {
  padding-left: 1.3rem;
}

.pl-14 {
  padding-left: 1.4rem;
}

.pl-15 {
  padding-left: 1.5rem;
}

.pl-16 {
  padding-left: 1.6rem;
}

.pl-17 {
  padding-left: 1.7rem;
}

.pl-18 {
  padding-left: 1.8rem;
}

.pl-19 {
  padding-left: 1.9rem;
}

.pl-20 {
  padding-left: 2rem;
}

.pl-21 {
  padding-left: 2.1rem;
}

.pl-22 {
  padding-left: 2.2rem;
}

.pl-23 {
  padding-left: 2.3rem;
}

.pl-24 {
  padding-left: 2.4rem;
}

.pl-25 {
  padding-left: 2.5rem;
}

.pl-26 {
  padding-left: 2.6rem;
}

.pl-27 {
  padding-left: 2.7rem;
}

.pl-28 {
  padding-left: 2.8rem;
}

.pl-29 {
  padding-left: 2.9rem;
}

.pl-30 {
  padding-left: 3rem;
}

.pl-31 {
  padding-left: 3.1rem;
}

.pl-32 {
  padding-left: 3.2rem;
}

.pl-33 {
  padding-left: 3.3rem;
}

.pl-34 {
  padding-left: 3.4rem;
}

.pl-35 {
  padding-left: 3.5rem;
}

.pl-36 {
  padding-left: 3.6rem;
}

.pl-37 {
  padding-left: 3.7rem;
}

.pl-38 {
  padding-left: 3.8rem;
}

.pl-39 {
  padding-left: 3.9rem;
}

.pl-40 {
  padding-left: 4rem;
}

.pl-41 {
  padding-left: 4.1rem;
}

.pl-42 {
  padding-left: 4.2rem;
}

.pl-43 {
  padding-left: 4.3rem;
}

.pl-44 {
  padding-left: 4.4rem;
}

.pl-45 {
  padding-left: 4.5rem;
}

.pl-46 {
  padding-left: 4.6rem;
}

.pl-47 {
  padding-left: 4.7rem;
}

.pl-48 {
  padding-left: 4.8rem;
}

.pl-49 {
  padding-left: 4.9rem;
}

.pl-50 {
  padding-left: 5rem;
}

.pl-51 {
  padding-left: 5.1rem;
}

.pl-52 {
  padding-left: 5.2rem;
}

.pl-53 {
  padding-left: 5.3rem;
}

.pl-54 {
  padding-left: 5.4rem;
}

.pl-55 {
  padding-left: 5.5rem;
}

.pl-56 {
  padding-left: 5.6rem;
}

.pl-57 {
  padding-left: 5.7rem;
}

.pl-58 {
  padding-left: 5.8rem;
}

.pl-59 {
  padding-left: 5.9rem;
}

.pl-60 {
  padding-left: 6rem;
}

.pl-61 {
  padding-left: 6.1rem;
}

.pl-62 {
  padding-left: 6.2rem;
}

.pl-63 {
  padding-left: 6.3rem;
}

.pl-64 {
  padding-left: 6.4rem;
}

.pl-65 {
  padding-left: 6.5rem;
}

.pl-66 {
  padding-left: 6.6rem;
}

.pl-67 {
  padding-left: 6.7rem;
}

.pl-68 {
  padding-left: 6.8rem;
}

.pl-69 {
  padding-left: 6.9rem;
}

.pl-70 {
  padding-left: 7rem;
}

.pl-71 {
  padding-left: 7.1rem;
}

.pl-72 {
  padding-left: 7.2rem;
}

/* loop through and generate the classes */
.pr-0 {
  padding-right: 0rem;
}

/* loop through and generate the classes */
.pr-1 {
  padding-right: 0.1rem;
}

/* loop through and generate the classes */
.pr-2 {
  padding-right: 0.2rem;
}

/* loop through and generate the classes */
.pr-3 {
  padding-right: 0.3rem;
}

/* loop through and generate the classes */
.pr-4 {
  padding-right: 0.4rem;
}

/* loop through and generate the classes */
.pr-5 {
  padding-right: 0.5rem;
}

/* loop through and generate the classes */
.pr-6 {
  padding-right: 0.6rem;
}

/* loop through and generate the classes */
.pr-7 {
  padding-right: 0.7rem;
}

/* loop through and generate the classes */
.pr-8 {
  padding-right: 0.8rem;
}

/* loop through and generate the classes */
.pr-9 {
  padding-right: 0.9rem;
}

/* loop through and generate the classes */
.pr-10 {
  padding-right: 1rem;
}

/* loop through and generate the classes */
.pr-11 {
  padding-right: 1.1rem;
}

/* loop through and generate the classes */
.pr-12 {
  padding-right: 1.2rem;
}

/* loop through and generate the classes */
.pr-13 {
  padding-right: 1.3rem;
}

/* loop through and generate the classes */
.pr-14 {
  padding-right: 1.4rem;
}

/* loop through and generate the classes */
.pr-15 {
  padding-right: 1.5rem;
}

/* loop through and generate the classes */
.pr-16 {
  padding-right: 1.6rem;
}

/* loop through and generate the classes */
.pr-17 {
  padding-right: 1.7rem;
}

/* loop through and generate the classes */
.pr-18 {
  padding-right: 1.8rem;
}

/* loop through and generate the classes */
.pr-19 {
  padding-right: 1.9rem;
}

/* loop through and generate the classes */
.pr-20 {
  padding-right: 2rem;
}

/* loop through and generate the classes */
.pr-21 {
  padding-right: 2.1rem;
}

/* loop through and generate the classes */
.pr-22 {
  padding-right: 2.2rem;
}

/* loop through and generate the classes */
.pr-23 {
  padding-right: 2.3rem;
}

/* loop through and generate the classes */
.pr-24 {
  padding-right: 2.4rem;
}

/* loop through and generate the classes */
.pr-25 {
  padding-right: 2.5rem;
}

/* loop through and generate the classes */
.pr-26 {
  padding-right: 2.6rem;
}

/* loop through and generate the classes */
.pr-27 {
  padding-right: 2.7rem;
}

/* loop through and generate the classes */
.pr-28 {
  padding-right: 2.8rem;
}

/* loop through and generate the classes */
.pr-29 {
  padding-right: 2.9rem;
}

/* loop through and generate the classes */
.pr-30 {
  padding-right: 3rem;
}

/* loop through and generate the classes */
.pr-31 {
  padding-right: 3.1rem;
}

/* loop through and generate the classes */
.pr-32 {
  padding-right: 3.2rem;
}

/* loop through and generate the classes */
.pr-33 {
  padding-right: 3.3rem;
}

/* loop through and generate the classes */
.pr-34 {
  padding-right: 3.4rem;
}

/* loop through and generate the classes */
.pr-35 {
  padding-right: 3.5rem;
}

/* loop through and generate the classes */
.pr-36 {
  padding-right: 3.6rem;
}

/* loop through and generate the classes */
.pr-37 {
  padding-right: 3.7rem;
}

/* loop through and generate the classes */
.pr-38 {
  padding-right: 3.8rem;
}

/* loop through and generate the classes */
.pr-39 {
  padding-right: 3.9rem;
}

/* loop through and generate the classes */
.pr-40 {
  padding-right: 4rem;
}

/* loop through and generate the classes */
.pr-41 {
  padding-right: 4.1rem;
}

/* loop through and generate the classes */
.pr-42 {
  padding-right: 4.2rem;
}

/* loop through and generate the classes */
.pr-43 {
  padding-right: 4.3rem;
}

/* loop through and generate the classes */
.pr-44 {
  padding-right: 4.4rem;
}

/* loop through and generate the classes */
.pr-45 {
  padding-right: 4.5rem;
}

/* loop through and generate the classes */
.pr-46 {
  padding-right: 4.6rem;
}

/* loop through and generate the classes */
.pr-47 {
  padding-right: 4.7rem;
}

/* loop through and generate the classes */
.pr-48 {
  padding-right: 4.8rem;
}

/* loop through and generate the classes */
.pr-49 {
  padding-right: 4.9rem;
}

/* loop through and generate the classes */
.pr-50 {
  padding-right: 5rem;
}

/* loop through and generate the classes */
.pr-51 {
  padding-right: 5.1rem;
}

/* loop through and generate the classes */
.pr-52 {
  padding-right: 5.2rem;
}

/* loop through and generate the classes */
.pr-53 {
  padding-right: 5.3rem;
}

/* loop through and generate the classes */
.pr-54 {
  padding-right: 5.4rem;
}

/* loop through and generate the classes */
.pr-55 {
  padding-right: 5.5rem;
}

/* loop through and generate the classes */
.pr-56 {
  padding-right: 5.6rem;
}

/* loop through and generate the classes */
.pr-57 {
  padding-right: 5.7rem;
}

/* loop through and generate the classes */
.pr-58 {
  padding-right: 5.8rem;
}

/* loop through and generate the classes */
.pr-59 {
  padding-right: 5.9rem;
}

/* loop through and generate the classes */
.pr-60 {
  padding-right: 6rem;
}

/* loop through and generate the classes */
.pr-61 {
  padding-right: 6.1rem;
}

/* loop through and generate the classes */
.pr-62 {
  padding-right: 6.2rem;
}

/* loop through and generate the classes */
.pr-63 {
  padding-right: 6.3rem;
}

/* loop through and generate the classes */
.pr-64 {
  padding-right: 6.4rem;
}

/* loop through and generate the classes */
.pr-65 {
  padding-right: 6.5rem;
}

/* loop through and generate the classes */
.pr-66 {
  padding-right: 6.6rem;
}

/* loop through and generate the classes */
.pr-67 {
  padding-right: 6.7rem;
}

/* loop through and generate the classes */
.pr-68 {
  padding-right: 6.8rem;
}

/* loop through and generate the classes */
.pr-69 {
  padding-right: 6.9rem;
}

/* loop through and generate the classes */
.pr-70 {
  padding-right: 7rem;
}

/* loop through and generate the classes */
.pr-71 {
  padding-right: 7.1rem;
}

/* loop through and generate the classes */
.pr-72 {
  padding-right: 7.2rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pt-1 {
  padding-top: 0.1rem;
}

.pt-2 {
  padding-top: 0.2rem;
}

.pt-3 {
  padding-top: 0.3rem;
}

.pt-4 {
  padding-top: 0.4rem;
}

.pt-5 {
  padding-top: 0.5rem;
}

.pt-6 {
  padding-top: 0.6rem;
}

.pt-7 {
  padding-top: 0.7rem;
}

.pt-8 {
  padding-top: 0.8rem;
}

.pt-9 {
  padding-top: 0.9rem;
}

.pt-10 {
  padding-top: 1rem;
}

.pt-11 {
  padding-top: 1.1rem;
}

.pt-12 {
  padding-top: 1.2rem;
}

.pt-13 {
  padding-top: 1.3rem;
}

.pt-14 {
  padding-top: 1.4rem;
}

.pt-15 {
  padding-top: 1.5rem;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pt-17 {
  padding-top: 1.7rem;
}

.pt-18 {
  padding-top: 1.8rem;
}

.pt-19 {
  padding-top: 1.9rem;
}

.pt-20 {
  padding-top: 2rem;
}

.pt-21 {
  padding-top: 2.1rem;
}

.pt-22 {
  padding-top: 2.2rem;
}

.pt-23 {
  padding-top: 2.3rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pt-25 {
  padding-top: 2.5rem;
}

.pt-26 {
  padding-top: 2.6rem;
}

.pt-27 {
  padding-top: 2.7rem;
}

.pt-28 {
  padding-top: 2.8rem;
}

.pt-29 {
  padding-top: 2.9rem;
}

.pt-30 {
  padding-top: 3rem;
}

.pt-31 {
  padding-top: 3.1rem;
}

.pt-32 {
  padding-top: 3.2rem;
}

.pt-33 {
  padding-top: 3.3rem;
}

.pt-34 {
  padding-top: 3.4rem;
}

.pt-35 {
  padding-top: 3.5rem;
}

.pt-36 {
  padding-top: 3.6rem;
}

.pt-37 {
  padding-top: 3.7rem;
}

.pt-38 {
  padding-top: 3.8rem;
}

.pt-39 {
  padding-top: 3.9rem;
}

.pt-40 {
  padding-top: 4rem;
}

.pt-41 {
  padding-top: 4.1rem;
}

.pt-42 {
  padding-top: 4.2rem;
}

.pt-43 {
  padding-top: 4.3rem;
}

.pt-44 {
  padding-top: 4.4rem;
}

.pt-45 {
  padding-top: 4.5rem;
}

.pt-46 {
  padding-top: 4.6rem;
}

.pt-47 {
  padding-top: 4.7rem;
}

.pt-48 {
  padding-top: 4.8rem;
}

.pt-49 {
  padding-top: 4.9rem;
}

.pt-50 {
  padding-top: 5rem;
}

.pt-51 {
  padding-top: 5.1rem;
}

.pt-52 {
  padding-top: 5.2rem;
}

.pt-53 {
  padding-top: 5.3rem;
}

.pt-54 {
  padding-top: 5.4rem;
}

.pt-55 {
  padding-top: 5.5rem;
}

.pt-56 {
  padding-top: 5.6rem;
}

.pt-57 {
  padding-top: 5.7rem;
}

.pt-58 {
  padding-top: 5.8rem;
}

.pt-59 {
  padding-top: 5.9rem;
}

.pt-60 {
  padding-top: 6rem;
}

.pt-61 {
  padding-top: 6.1rem;
}

.pt-62 {
  padding-top: 6.2rem;
}

.pt-63 {
  padding-top: 6.3rem;
}

.pt-64 {
  padding-top: 6.4rem;
}

.pt-65 {
  padding-top: 6.5rem;
}

.pt-66 {
  padding-top: 6.6rem;
}

.pt-67 {
  padding-top: 6.7rem;
}

.pt-68 {
  padding-top: 6.8rem;
}

.pt-69 {
  padding-top: 6.9rem;
}

.pt-70 {
  padding-top: 7rem;
}

.pt-71 {
  padding-top: 7.1rem;
}

.pt-72 {
  padding-top: 7.2rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pb-1 {
  padding-bottom: 0.1rem;
}

.pb-2 {
  padding-bottom: 0.2rem;
}

.pb-3 {
  padding-bottom: 0.3rem;
}

.pb-4 {
  padding-bottom: 0.4rem;
}

.pb-5 {
  padding-bottom: 0.5rem;
}

.pb-6 {
  padding-bottom: 0.6rem;
}

.pb-7 {
  padding-bottom: 0.7rem;
}

.pb-8 {
  padding-bottom: 0.8rem;
}

.pb-9 {
  padding-bottom: 0.9rem;
}

.pb-10 {
  padding-bottom: 1rem;
}

.pb-11 {
  padding-bottom: 1.1rem;
}

.pb-12 {
  padding-bottom: 1.2rem;
}

.pb-13 {
  padding-bottom: 1.3rem;
}

.pb-14 {
  padding-bottom: 1.4rem;
}

.pb-15 {
  padding-bottom: 1.5rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pb-17 {
  padding-bottom: 1.7rem;
}

.pb-18 {
  padding-bottom: 1.8rem;
}

.pb-19 {
  padding-bottom: 1.9rem;
}

.pb-20 {
  padding-bottom: 2rem;
}

.pb-21 {
  padding-bottom: 2.1rem;
}

.pb-22 {
  padding-bottom: 2.2rem;
}

.pb-23 {
  padding-bottom: 2.3rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pb-25 {
  padding-bottom: 2.5rem;
}

.pb-26 {
  padding-bottom: 2.6rem;
}

.pb-27 {
  padding-bottom: 2.7rem;
}

.pb-28 {
  padding-bottom: 2.8rem;
}

.pb-29 {
  padding-bottom: 2.9rem;
}

.pb-30 {
  padding-bottom: 3rem;
}

.pb-31 {
  padding-bottom: 3.1rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pb-33 {
  padding-bottom: 3.3rem;
}

.pb-34 {
  padding-bottom: 3.4rem;
}

.pb-35 {
  padding-bottom: 3.5rem;
}

.pb-36 {
  padding-bottom: 3.6rem;
}

.pb-37 {
  padding-bottom: 3.7rem;
}

.pb-38 {
  padding-bottom: 3.8rem;
}

.pb-39 {
  padding-bottom: 3.9rem;
}

.pb-40 {
  padding-bottom: 4rem;
}

.pb-41 {
  padding-bottom: 4.1rem;
}

.pb-42 {
  padding-bottom: 4.2rem;
}

.pb-43 {
  padding-bottom: 4.3rem;
}

.pb-44 {
  padding-bottom: 4.4rem;
}

.pb-45 {
  padding-bottom: 4.5rem;
}

.pb-46 {
  padding-bottom: 4.6rem;
}

.pb-47 {
  padding-bottom: 4.7rem;
}

.pb-48 {
  padding-bottom: 4.8rem;
}

.pb-49 {
  padding-bottom: 4.9rem;
}

.pb-50 {
  padding-bottom: 5rem;
}

.pb-51 {
  padding-bottom: 5.1rem;
}

.pb-52 {
  padding-bottom: 5.2rem;
}

.pb-53 {
  padding-bottom: 5.3rem;
}

.pb-54 {
  padding-bottom: 5.4rem;
}

.pb-55 {
  padding-bottom: 5.5rem;
}

.pb-56 {
  padding-bottom: 5.6rem;
}

.pb-57 {
  padding-bottom: 5.7rem;
}

.pb-58 {
  padding-bottom: 5.8rem;
}

.pb-59 {
  padding-bottom: 5.9rem;
}

.pb-60 {
  padding-bottom: 6rem;
}

.pb-61 {
  padding-bottom: 6.1rem;
}

.pb-62 {
  padding-bottom: 6.2rem;
}

.pb-63 {
  padding-bottom: 6.3rem;
}

.pb-64 {
  padding-bottom: 6.4rem;
}

.pb-65 {
  padding-bottom: 6.5rem;
}

.pb-66 {
  padding-bottom: 6.6rem;
}

.pb-67 {
  padding-bottom: 6.7rem;
}

.pb-68 {
  padding-bottom: 6.8rem;
}

.pb-69 {
  padding-bottom: 6.9rem;
}

.pb-70 {
  padding-bottom: 7rem;
}

.pb-71 {
  padding-bottom: 7.1rem;
}

.pb-72 {
  padding-bottom: 7.2rem;
}

.p-x {
  padding-left: 0remrem;
  padding-right: 0remrem;
}

.p-x {
  padding-left: 0.1remrem;
  padding-right: 0.1remrem;
}

.p-x {
  padding-left: 0.2remrem;
  padding-right: 0.2remrem;
}

.p-x {
  padding-left: 0.3remrem;
  padding-right: 0.3remrem;
}

.p-x {
  padding-left: 0.4remrem;
  padding-right: 0.4remrem;
}

.p-x {
  padding-left: 0.5remrem;
  padding-right: 0.5remrem;
}

.p-x {
  padding-left: 0.6remrem;
  padding-right: 0.6remrem;
}

.p-x {
  padding-left: 0.7remrem;
  padding-right: 0.7remrem;
}

.p-x {
  padding-left: 0.8remrem;
  padding-right: 0.8remrem;
}

.p-x {
  padding-left: 0.9remrem;
  padding-right: 0.9remrem;
}

.p-x {
  padding-left: 1remrem;
  padding-right: 1remrem;
}

.p-x {
  padding-left: 1.1remrem;
  padding-right: 1.1remrem;
}

.p-x {
  padding-left: 1.2remrem;
  padding-right: 1.2remrem;
}

.p-x {
  padding-left: 1.3remrem;
  padding-right: 1.3remrem;
}

.p-x {
  padding-left: 1.4remrem;
  padding-right: 1.4remrem;
}

.p-x {
  padding-left: 1.5remrem;
  padding-right: 1.5remrem;
}

.p-x {
  padding-left: 1.6remrem;
  padding-right: 1.6remrem;
}

.p-x {
  padding-left: 1.7remrem;
  padding-right: 1.7remrem;
}

.p-x {
  padding-left: 1.8remrem;
  padding-right: 1.8remrem;
}

.p-x {
  padding-left: 1.9remrem;
  padding-right: 1.9remrem;
}

.p-x {
  padding-left: 2remrem;
  padding-right: 2remrem;
}

.p-x {
  padding-left: 2.1remrem;
  padding-right: 2.1remrem;
}

.p-x {
  padding-left: 2.2remrem;
  padding-right: 2.2remrem;
}

.p-x {
  padding-left: 2.3remrem;
  padding-right: 2.3remrem;
}

.p-x {
  padding-left: 2.4remrem;
  padding-right: 2.4remrem;
}

.p-x {
  padding-left: 2.5remrem;
  padding-right: 2.5remrem;
}

.p-x {
  padding-left: 2.6remrem;
  padding-right: 2.6remrem;
}

.p-x {
  padding-left: 2.7remrem;
  padding-right: 2.7remrem;
}

.p-x {
  padding-left: 2.8remrem;
  padding-right: 2.8remrem;
}

.p-x {
  padding-left: 2.9remrem;
  padding-right: 2.9remrem;
}

.p-x {
  padding-left: 3remrem;
  padding-right: 3remrem;
}

.p-x {
  padding-left: 3.1remrem;
  padding-right: 3.1remrem;
}

.p-x {
  padding-left: 3.2remrem;
  padding-right: 3.2remrem;
}

.p-x {
  padding-left: 3.3remrem;
  padding-right: 3.3remrem;
}

.p-x {
  padding-left: 3.4remrem;
  padding-right: 3.4remrem;
}

.p-x {
  padding-left: 3.5remrem;
  padding-right: 3.5remrem;
}

.p-x {
  padding-left: 3.6remrem;
  padding-right: 3.6remrem;
}

.p-x {
  padding-left: 3.7remrem;
  padding-right: 3.7remrem;
}

.p-x {
  padding-left: 3.8remrem;
  padding-right: 3.8remrem;
}

.p-x {
  padding-left: 3.9remrem;
  padding-right: 3.9remrem;
}

.p-x {
  padding-left: 4remrem;
  padding-right: 4remrem;
}

.p-x {
  padding-left: 4.1remrem;
  padding-right: 4.1remrem;
}

.p-x {
  padding-left: 4.2remrem;
  padding-right: 4.2remrem;
}

.p-x {
  padding-left: 4.3remrem;
  padding-right: 4.3remrem;
}

.p-x {
  padding-left: 4.4remrem;
  padding-right: 4.4remrem;
}

.p-x {
  padding-left: 4.5remrem;
  padding-right: 4.5remrem;
}

.p-x {
  padding-left: 4.6remrem;
  padding-right: 4.6remrem;
}

.p-x {
  padding-left: 4.7remrem;
  padding-right: 4.7remrem;
}

.p-x {
  padding-left: 4.8remrem;
  padding-right: 4.8remrem;
}

.p-x {
  padding-left: 4.9remrem;
  padding-right: 4.9remrem;
}

.p-x {
  padding-left: 5remrem;
  padding-right: 5remrem;
}

.p-x {
  padding-left: 5.1remrem;
  padding-right: 5.1remrem;
}

.p-x {
  padding-left: 5.2remrem;
  padding-right: 5.2remrem;
}

.p-x {
  padding-left: 5.3remrem;
  padding-right: 5.3remrem;
}

.p-x {
  padding-left: 5.4remrem;
  padding-right: 5.4remrem;
}

.p-x {
  padding-left: 5.5remrem;
  padding-right: 5.5remrem;
}

.p-x {
  padding-left: 5.6remrem;
  padding-right: 5.6remrem;
}

.p-x {
  padding-left: 5.7remrem;
  padding-right: 5.7remrem;
}

.p-x {
  padding-left: 5.8remrem;
  padding-right: 5.8remrem;
}

.p-x {
  padding-left: 5.9remrem;
  padding-right: 5.9remrem;
}

.p-x {
  padding-left: 6remrem;
  padding-right: 6remrem;
}

.p-x {
  padding-left: 6.1remrem;
  padding-right: 6.1remrem;
}

.p-x {
  padding-left: 6.2remrem;
  padding-right: 6.2remrem;
}

.p-x {
  padding-left: 6.3remrem;
  padding-right: 6.3remrem;
}

.p-x {
  padding-left: 6.4remrem;
  padding-right: 6.4remrem;
}

.p-x {
  padding-left: 6.5remrem;
  padding-right: 6.5remrem;
}

.p-x {
  padding-left: 6.6remrem;
  padding-right: 6.6remrem;
}

.p-x {
  padding-left: 6.7remrem;
  padding-right: 6.7remrem;
}

.p-x {
  padding-left: 6.8remrem;
  padding-right: 6.8remrem;
}

.p-x {
  padding-left: 6.9remrem;
  padding-right: 6.9remrem;
}

.p-x {
  padding-left: 7remrem;
  padding-right: 7remrem;
}

.p-x {
  padding-left: 7.1remrem;
  padding-right: 7.1remrem;
}

.p-x {
  padding-left: 7.2remrem;
  padding-right: 7.2remrem;
}

.p-y {
  padding-top: 0remrem;
  padding-bottom: 0remrem;
}

.p-y {
  padding-top: 0.1remrem;
  padding-bottom: 0.1remrem;
}

.p-y {
  padding-top: 0.2remrem;
  padding-bottom: 0.2remrem;
}

.p-y {
  padding-top: 0.3remrem;
  padding-bottom: 0.3remrem;
}

.p-y {
  padding-top: 0.4remrem;
  padding-bottom: 0.4remrem;
}

.p-y {
  padding-top: 0.5remrem;
  padding-bottom: 0.5remrem;
}

.p-y {
  padding-top: 0.6remrem;
  padding-bottom: 0.6remrem;
}

.p-y {
  padding-top: 0.7remrem;
  padding-bottom: 0.7remrem;
}

.p-y {
  padding-top: 0.8remrem;
  padding-bottom: 0.8remrem;
}

.p-y {
  padding-top: 0.9remrem;
  padding-bottom: 0.9remrem;
}

.p-y {
  padding-top: 1remrem;
  padding-bottom: 1remrem;
}

.p-y {
  padding-top: 1.1remrem;
  padding-bottom: 1.1remrem;
}

.p-y {
  padding-top: 1.2remrem;
  padding-bottom: 1.2remrem;
}

.p-y {
  padding-top: 1.3remrem;
  padding-bottom: 1.3remrem;
}

.p-y {
  padding-top: 1.4remrem;
  padding-bottom: 1.4remrem;
}

.p-y {
  padding-top: 1.5remrem;
  padding-bottom: 1.5remrem;
}

.p-y {
  padding-top: 1.6remrem;
  padding-bottom: 1.6remrem;
}

.p-y {
  padding-top: 1.7remrem;
  padding-bottom: 1.7remrem;
}

.p-y {
  padding-top: 1.8remrem;
  padding-bottom: 1.8remrem;
}

.p-y {
  padding-top: 1.9remrem;
  padding-bottom: 1.9remrem;
}

.p-y {
  padding-top: 2remrem;
  padding-bottom: 2remrem;
}

.p-y {
  padding-top: 2.1remrem;
  padding-bottom: 2.1remrem;
}

.p-y {
  padding-top: 2.2remrem;
  padding-bottom: 2.2remrem;
}

.p-y {
  padding-top: 2.3remrem;
  padding-bottom: 2.3remrem;
}

.p-y {
  padding-top: 2.4remrem;
  padding-bottom: 2.4remrem;
}

.p-y {
  padding-top: 2.5remrem;
  padding-bottom: 2.5remrem;
}

.p-y {
  padding-top: 2.6remrem;
  padding-bottom: 2.6remrem;
}

.p-y {
  padding-top: 2.7remrem;
  padding-bottom: 2.7remrem;
}

.p-y {
  padding-top: 2.8remrem;
  padding-bottom: 2.8remrem;
}

.p-y {
  padding-top: 2.9remrem;
  padding-bottom: 2.9remrem;
}

.p-y {
  padding-top: 3remrem;
  padding-bottom: 3remrem;
}

.p-y {
  padding-top: 3.1remrem;
  padding-bottom: 3.1remrem;
}

.p-y {
  padding-top: 3.2remrem;
  padding-bottom: 3.2remrem;
}

.p-y {
  padding-top: 3.3remrem;
  padding-bottom: 3.3remrem;
}

.p-y {
  padding-top: 3.4remrem;
  padding-bottom: 3.4remrem;
}

.p-y {
  padding-top: 3.5remrem;
  padding-bottom: 3.5remrem;
}

.p-y {
  padding-top: 3.6remrem;
  padding-bottom: 3.6remrem;
}

.p-y {
  padding-top: 3.7remrem;
  padding-bottom: 3.7remrem;
}

.p-y {
  padding-top: 3.8remrem;
  padding-bottom: 3.8remrem;
}

.p-y {
  padding-top: 3.9remrem;
  padding-bottom: 3.9remrem;
}

.p-y {
  padding-top: 4remrem;
  padding-bottom: 4remrem;
}

.p-y {
  padding-top: 4.1remrem;
  padding-bottom: 4.1remrem;
}

.p-y {
  padding-top: 4.2remrem;
  padding-bottom: 4.2remrem;
}

.p-y {
  padding-top: 4.3remrem;
  padding-bottom: 4.3remrem;
}

.p-y {
  padding-top: 4.4remrem;
  padding-bottom: 4.4remrem;
}

.p-y {
  padding-top: 4.5remrem;
  padding-bottom: 4.5remrem;
}

.p-y {
  padding-top: 4.6remrem;
  padding-bottom: 4.6remrem;
}

.p-y {
  padding-top: 4.7remrem;
  padding-bottom: 4.7remrem;
}

.p-y {
  padding-top: 4.8remrem;
  padding-bottom: 4.8remrem;
}

.p-y {
  padding-top: 4.9remrem;
  padding-bottom: 4.9remrem;
}

.p-y {
  padding-top: 5remrem;
  padding-bottom: 5remrem;
}

.p-y {
  padding-top: 5.1remrem;
  padding-bottom: 5.1remrem;
}

.p-y {
  padding-top: 5.2remrem;
  padding-bottom: 5.2remrem;
}

.p-y {
  padding-top: 5.3remrem;
  padding-bottom: 5.3remrem;
}

.p-y {
  padding-top: 5.4remrem;
  padding-bottom: 5.4remrem;
}

.p-y {
  padding-top: 5.5remrem;
  padding-bottom: 5.5remrem;
}

.p-y {
  padding-top: 5.6remrem;
  padding-bottom: 5.6remrem;
}

.p-y {
  padding-top: 5.7remrem;
  padding-bottom: 5.7remrem;
}

.p-y {
  padding-top: 5.8remrem;
  padding-bottom: 5.8remrem;
}

.p-y {
  padding-top: 5.9remrem;
  padding-bottom: 5.9remrem;
}

.p-y {
  padding-top: 6remrem;
  padding-bottom: 6remrem;
}

.p-y {
  padding-top: 6.1remrem;
  padding-bottom: 6.1remrem;
}

.p-y {
  padding-top: 6.2remrem;
  padding-bottom: 6.2remrem;
}

.p-y {
  padding-top: 6.3remrem;
  padding-bottom: 6.3remrem;
}

.p-y {
  padding-top: 6.4remrem;
  padding-bottom: 6.4remrem;
}

.p-y {
  padding-top: 6.5remrem;
  padding-bottom: 6.5remrem;
}

.p-y {
  padding-top: 6.6remrem;
  padding-bottom: 6.6remrem;
}

.p-y {
  padding-top: 6.7remrem;
  padding-bottom: 6.7remrem;
}

.p-y {
  padding-top: 6.8remrem;
  padding-bottom: 6.8remrem;
}

.p-y {
  padding-top: 6.9remrem;
  padding-bottom: 6.9remrem;
}

.p-y {
  padding-top: 7remrem;
  padding-bottom: 7remrem;
}

.p-y {
  padding-top: 7.1remrem;
  padding-bottom: 7.1remrem;
}

.p-y {
  padding-top: 7.2remrem;
  padding-bottom: 7.2remrem;
}

.p-auto {
  padding: auto;
}

.p-0-0 {
  padding: 0rem;
}

.p-0-1 {
  padding: 0.1rem;
}

.p-0-2 {
  padding: 0.2rem;
}

.p-0-3 {
  padding: 0.3rem;
}

.p-0-4 {
  padding: 0.4rem;
}

.p-0-5 {
  padding: 0.5rem;
}

.p-0-6 {
  padding: 0.6rem;
}

.p-0-7 {
  padding: 0.7rem;
}

.p-0-8 {
  padding: 0.8rem;
}

.p-0-9 {
  padding: 0.9rem;
}

.p-1-0 {
  padding: 1rem;
}

.p-1-1 {
  padding: 1.1rem;
}

.p-1-2 {
  padding: 1.2rem;
}

.p-1-3 {
  padding: 1.3rem;
}

.p-1-4 {
  padding: 1.4rem;
}

.p-1-5 {
  padding: 1.5rem;
}

.p-1-6 {
  padding: 1.6rem;
}

.p-1-7 {
  padding: 1.7rem;
}

.p-1-8 {
  padding: 1.8rem;
}

.p-1-9 {
  padding: 1.9rem;
}

.p-2-0 {
  padding: 2rem;
}

.p-2-1 {
  padding: 2.1rem;
}

.p-2-2 {
  padding: 2.2rem;
}

.p-2-3 {
  padding: 2.3rem;
}

.p-2-4 {
  padding: 2.4rem;
}

.p-2-5 {
  padding: 2.5rem;
}

.p-2-6 {
  padding: 2.6rem;
}

.p-2-7 {
  padding: 2.7rem;
}

.p-2-8 {
  padding: 2.8rem;
}

.p-2-9 {
  padding: 2.9rem;
}

.p-3-0 {
  padding: 3rem;
}

.p-3-1 {
  padding: 3.1rem;
}

.p-3-2 {
  padding: 3.2rem;
}

.p-3-3 {
  padding: 3.3rem;
}

.p-3-4 {
  padding: 3.4rem;
}

.p-3-5 {
  padding: 3.5rem;
}

.p-3-6 {
  padding: 3.6rem;
}

.p-3-7 {
  padding: 3.7rem;
}

.p-3-8 {
  padding: 3.8rem;
}

.p-3-9 {
  padding: 3.9rem;
}

.p-4-0 {
  padding: 4rem;
}

.p-4-1 {
  padding: 4.1rem;
}

.p-4-2 {
  padding: 4.2rem;
}

.p-4-3 {
  padding: 4.3rem;
}

.p-4-4 {
  padding: 4.4rem;
}

.p-4-5 {
  padding: 4.5rem;
}

.p-4-6 {
  padding: 4.6rem;
}

.p-4-7 {
  padding: 4.7rem;
}

.p-4-8 {
  padding: 4.8rem;
}

.p-4-9 {
  padding: 4.9rem;
}

.p-5-0 {
  padding: 5rem;
}

.p-5-1 {
  padding: 5.1rem;
}

.p-5-2 {
  padding: 5.2rem;
}

.p-5-3 {
  padding: 5.3rem;
}

.p-5-4 {
  padding: 5.4rem;
}

.p-5-5 {
  padding: 5.5rem;
}

.p-5-6 {
  padding: 5.6rem;
}

.p-5-7 {
  padding: 5.7rem;
}

.p-5-8 {
  padding: 5.8rem;
}

.p-5-9 {
  padding: 5.9rem;
}

.p-6-0 {
  padding: 6rem;
}

.p-6-1 {
  padding: 6.1rem;
}

.p-6-2 {
  padding: 6.2rem;
}

.p-6-3 {
  padding: 6.3rem;
}

.p-6-4 {
  padding: 6.4rem;
}

.p-6-5 {
  padding: 6.5rem;
}

.p-6-6 {
  padding: 6.6rem;
}

.p-6-7 {
  padding: 6.7rem;
}

.p-6-8 {
  padding: 6.8rem;
}

.p-6-9 {
  padding: 6.9rem;
}

.p-7-0 {
  padding: 7rem;
}

.p-7-1 {
  padding: 7.1rem;
}

.p-7-2 {
  padding: 7.2rem;
}

.p-7-3 {
  padding: 7.3rem;
}

.p-7-4 {
  padding: 7.4rem;
}

.p-7-5 {
  padding: 7.5rem;
}

.p-7-6 {
  padding: 7.6rem;
}

.p-7-7 {
  padding: 7.7rem;
}

.p-7-8 {
  padding: 7.8rem;
}

.p-7-9 {
  padding: 7.9rem;
}

.p-8-0 {
  padding: 8rem;
}

.p-8-1 {
  padding: 8.1rem;
}

.p-8-2 {
  padding: 8.2rem;
}

.p-8-3 {
  padding: 8.3rem;
}

.p-8-4 {
  padding: 8.4rem;
}

.p-8-5 {
  padding: 8.5rem;
}

.p-8-6 {
  padding: 8.6rem;
}

.p-8-7 {
  padding: 8.7rem;
}

.p-8-8 {
  padding: 8.8rem;
}

.p-8-9 {
  padding: 8.9rem;
}

.p-9-0 {
  padding: 9rem;
}

.p-9-1 {
  padding: 9.1rem;
}

.p-9-2 {
  padding: 9.2rem;
}

.p-9-3 {
  padding: 9.3rem;
}

.p-9-4 {
  padding: 9.4rem;
}

.p-9-5 {
  padding: 9.5rem;
}

.p-9-6 {
  padding: 9.6rem;
}

.p-9-7 {
  padding: 9.7rem;
}

.p-9-8 {
  padding: 9.8rem;
}

.p-9-9 {
  padding: 9.9rem;
}

.p-10-0 {
  padding: 10rem;
}

.p-10-1 {
  padding: 10.1rem;
}

.p-10-2 {
  padding: 10.2rem;
}

.p-10-3 {
  padding: 10.3rem;
}

.p-10-4 {
  padding: 10.4rem;
}

.p-10-5 {
  padding: 10.5rem;
}

.p-10-6 {
  padding: 10.6rem;
}

.p-10-7 {
  padding: 10.7rem;
}

.p-10-8 {
  padding: 10.8rem;
}

.p-10-9 {
  padding: 10.9rem;
}

.p-11-0 {
  padding: 11rem;
}

.p-11-1 {
  padding: 11.1rem;
}

.p-11-2 {
  padding: 11.2rem;
}

.p-11-3 {
  padding: 11.3rem;
}

.p-11-4 {
  padding: 11.4rem;
}

.p-11-5 {
  padding: 11.5rem;
}

.p-11-6 {
  padding: 11.6rem;
}

.p-11-7 {
  padding: 11.7rem;
}

.p-11-8 {
  padding: 11.8rem;
}

.p-11-9 {
  padding: 11.9rem;
}

.p-12-0 {
  padding: 12rem;
}

.p-12-1 {
  padding: 12.1rem;
}

.p-12-2 {
  padding: 12.2rem;
}

.p-12-3 {
  padding: 12.3rem;
}

.p-12-4 {
  padding: 12.4rem;
}

.p-12-5 {
  padding: 12.5rem;
}

.p-12-6 {
  padding: 12.6rem;
}

.p-12-7 {
  padding: 12.7rem;
}

.p-12-8 {
  padding: 12.8rem;
}

.p-12-9 {
  padding: 12.9rem;
}

.p-13-0 {
  padding: 13rem;
}

.p-13-1 {
  padding: 13.1rem;
}

.p-13-2 {
  padding: 13.2rem;
}

.p-13-3 {
  padding: 13.3rem;
}

.p-13-4 {
  padding: 13.4rem;
}

.p-13-5 {
  padding: 13.5rem;
}

.p-13-6 {
  padding: 13.6rem;
}

.p-13-7 {
  padding: 13.7rem;
}

.p-13-8 {
  padding: 13.8rem;
}

.p-13-9 {
  padding: 13.9rem;
}

.p-14-0 {
  padding: 14rem;
}

.p-14-1 {
  padding: 14.1rem;
}

.p-14-2 {
  padding: 14.2rem;
}

.p-14-3 {
  padding: 14.3rem;
}

.p-14-4 {
  padding: 14.4rem;
}

.p-14-5 {
  padding: 14.5rem;
}

.p-14-6 {
  padding: 14.6rem;
}

.p-14-7 {
  padding: 14.7rem;
}

.p-14-8 {
  padding: 14.8rem;
}

.p-14-9 {
  padding: 14.9rem;
}

.p-15-0 {
  padding: 15rem;
}

.p-15-1 {
  padding: 15.1rem;
}

.p-15-2 {
  padding: 15.2rem;
}

.p-15-3 {
  padding: 15.3rem;
}

.p-15-4 {
  padding: 15.4rem;
}

.p-15-5 {
  padding: 15.5rem;
}

.p-15-6 {
  padding: 15.6rem;
}

.p-15-7 {
  padding: 15.7rem;
}

.p-15-8 {
  padding: 15.8rem;
}

.p-15-9 {
  padding: 15.9rem;
}

.p-16-0 {
  padding: 16rem;
}

.p-16-1 {
  padding: 16.1rem;
}

.p-16-2 {
  padding: 16.2rem;
}

.p-16-3 {
  padding: 16.3rem;
}

.p-16-4 {
  padding: 16.4rem;
}

.p-16-5 {
  padding: 16.5rem;
}

.p-16-6 {
  padding: 16.6rem;
}

.p-16-7 {
  padding: 16.7rem;
}

.p-16-8 {
  padding: 16.8rem;
}

.p-16-9 {
  padding: 16.9rem;
}

.p-17-0 {
  padding: 17rem;
}

.p-17-1 {
  padding: 17.1rem;
}

.p-17-2 {
  padding: 17.2rem;
}

.p-17-3 {
  padding: 17.3rem;
}

.p-17-4 {
  padding: 17.4rem;
}

.p-17-5 {
  padding: 17.5rem;
}

.p-17-6 {
  padding: 17.6rem;
}

.p-17-7 {
  padding: 17.7rem;
}

.p-17-8 {
  padding: 17.8rem;
}

.p-17-9 {
  padding: 17.9rem;
}

.p-18-0 {
  padding: 18rem;
}

.p-18-1 {
  padding: 18.1rem;
}

.p-18-2 {
  padding: 18.2rem;
}

.p-18-3 {
  padding: 18.3rem;
}

.p-18-4 {
  padding: 18.4rem;
}

.p-18-5 {
  padding: 18.5rem;
}

.p-18-6 {
  padding: 18.6rem;
}

.p-18-7 {
  padding: 18.7rem;
}

.p-18-8 {
  padding: 18.8rem;
}

.p-18-9 {
  padding: 18.9rem;
}

.p-19-0 {
  padding: 19rem;
}

.p-19-1 {
  padding: 19.1rem;
}

.p-19-2 {
  padding: 19.2rem;
}

.p-19-3 {
  padding: 19.3rem;
}

.p-19-4 {
  padding: 19.4rem;
}

.p-19-5 {
  padding: 19.5rem;
}

.p-19-6 {
  padding: 19.6rem;
}

.p-19-7 {
  padding: 19.7rem;
}

.p-19-8 {
  padding: 19.8rem;
}

.p-19-9 {
  padding: 19.9rem;
}

.p-20-0 {
  padding: 20rem;
}

.p-20-1 {
  padding: 20.1rem;
}

.p-20-2 {
  padding: 20.2rem;
}

.p-20-3 {
  padding: 20.3rem;
}

.p-20-4 {
  padding: 20.4rem;
}

.p-20-5 {
  padding: 20.5rem;
}

.p-20-6 {
  padding: 20.6rem;
}

.p-20-7 {
  padding: 20.7rem;
}

.p-20-8 {
  padding: 20.8rem;
}

.p-20-9 {
  padding: 20.9rem;
}

.p-21-0 {
  padding: 21rem;
}

.p-21-1 {
  padding: 21.1rem;
}

.p-21-2 {
  padding: 21.2rem;
}

.p-21-3 {
  padding: 21.3rem;
}

.p-21-4 {
  padding: 21.4rem;
}

.p-21-5 {
  padding: 21.5rem;
}

.p-21-6 {
  padding: 21.6rem;
}

.p-21-7 {
  padding: 21.7rem;
}

.p-21-8 {
  padding: 21.8rem;
}

.p-21-9 {
  padding: 21.9rem;
}

.p-22-0 {
  padding: 22rem;
}

.p-22-1 {
  padding: 22.1rem;
}

.p-22-2 {
  padding: 22.2rem;
}

.p-22-3 {
  padding: 22.3rem;
}

.p-22-4 {
  padding: 22.4rem;
}

.p-22-5 {
  padding: 22.5rem;
}

.p-22-6 {
  padding: 22.6rem;
}

.p-22-7 {
  padding: 22.7rem;
}

.p-22-8 {
  padding: 22.8rem;
}

.p-22-9 {
  padding: 22.9rem;
}

.p-23-0 {
  padding: 23rem;
}

.p-23-1 {
  padding: 23.1rem;
}

.p-23-2 {
  padding: 23.2rem;
}

.p-23-3 {
  padding: 23.3rem;
}

.p-23-4 {
  padding: 23.4rem;
}

.p-23-5 {
  padding: 23.5rem;
}

.p-23-6 {
  padding: 23.6rem;
}

.p-23-7 {
  padding: 23.7rem;
}

.p-23-8 {
  padding: 23.8rem;
}

.p-23-9 {
  padding: 23.9rem;
}

.p-24-0 {
  padding: 24rem;
}

.p-24-1 {
  padding: 24.1rem;
}

.p-24-2 {
  padding: 24.2rem;
}

.p-24-3 {
  padding: 24.3rem;
}

.p-24-4 {
  padding: 24.4rem;
}

.p-24-5 {
  padding: 24.5rem;
}

.p-24-6 {
  padding: 24.6rem;
}

.p-24-7 {
  padding: 24.7rem;
}

.p-24-8 {
  padding: 24.8rem;
}

.p-24-9 {
  padding: 24.9rem;
}

.p-25-0 {
  padding: 25rem;
}

.p-25-1 {
  padding: 25.1rem;
}

.p-25-2 {
  padding: 25.2rem;
}

.p-25-3 {
  padding: 25.3rem;
}

.p-25-4 {
  padding: 25.4rem;
}

.p-25-5 {
  padding: 25.5rem;
}

.p-25-6 {
  padding: 25.6rem;
}

.p-25-7 {
  padding: 25.7rem;
}

.p-25-8 {
  padding: 25.8rem;
}

.p-25-9 {
  padding: 25.9rem;
}

.p-26-0 {
  padding: 26rem;
}

.p-26-1 {
  padding: 26.1rem;
}

.p-26-2 {
  padding: 26.2rem;
}

.p-26-3 {
  padding: 26.3rem;
}

.p-26-4 {
  padding: 26.4rem;
}

.p-26-5 {
  padding: 26.5rem;
}

.p-26-6 {
  padding: 26.6rem;
}

.p-26-7 {
  padding: 26.7rem;
}

.p-26-8 {
  padding: 26.8rem;
}

.p-26-9 {
  padding: 26.9rem;
}

.p-27-0 {
  padding: 27rem;
}

.p-27-1 {
  padding: 27.1rem;
}

.p-27-2 {
  padding: 27.2rem;
}

.p-27-3 {
  padding: 27.3rem;
}

.p-27-4 {
  padding: 27.4rem;
}

.p-27-5 {
  padding: 27.5rem;
}

.p-27-6 {
  padding: 27.6rem;
}

.p-27-7 {
  padding: 27.7rem;
}

.p-27-8 {
  padding: 27.8rem;
}

.p-27-9 {
  padding: 27.9rem;
}

.p-28-0 {
  padding: 28rem;
}

.p-28-1 {
  padding: 28.1rem;
}

.p-28-2 {
  padding: 28.2rem;
}

.p-28-3 {
  padding: 28.3rem;
}

.p-28-4 {
  padding: 28.4rem;
}

.p-28-5 {
  padding: 28.5rem;
}

.p-28-6 {
  padding: 28.6rem;
}

.p-28-7 {
  padding: 28.7rem;
}

.p-28-8 {
  padding: 28.8rem;
}

.p-28-9 {
  padding: 28.9rem;
}

.p-29-0 {
  padding: 29rem;
}

.p-29-1 {
  padding: 29.1rem;
}

.p-29-2 {
  padding: 29.2rem;
}

.p-29-3 {
  padding: 29.3rem;
}

.p-29-4 {
  padding: 29.4rem;
}

.p-29-5 {
  padding: 29.5rem;
}

.p-29-6 {
  padding: 29.6rem;
}

.p-29-7 {
  padding: 29.7rem;
}

.p-29-8 {
  padding: 29.8rem;
}

.p-29-9 {
  padding: 29.9rem;
}

.p-30-0 {
  padding: 30rem;
}

.p-30-1 {
  padding: 30.1rem;
}

.p-30-2 {
  padding: 30.2rem;
}

.p-30-3 {
  padding: 30.3rem;
}

.p-30-4 {
  padding: 30.4rem;
}

.p-30-5 {
  padding: 30.5rem;
}

.p-30-6 {
  padding: 30.6rem;
}

.p-30-7 {
  padding: 30.7rem;
}

.p-30-8 {
  padding: 30.8rem;
}

.p-30-9 {
  padding: 30.9rem;
}

.p-31-0 {
  padding: 31rem;
}

.p-31-1 {
  padding: 31.1rem;
}

.p-31-2 {
  padding: 31.2rem;
}

.p-31-3 {
  padding: 31.3rem;
}

.p-31-4 {
  padding: 31.4rem;
}

.p-31-5 {
  padding: 31.5rem;
}

.p-31-6 {
  padding: 31.6rem;
}

.p-31-7 {
  padding: 31.7rem;
}

.p-31-8 {
  padding: 31.8rem;
}

.p-31-9 {
  padding: 31.9rem;
}

.p-32-0 {
  padding: 32rem;
}

.p-32-1 {
  padding: 32.1rem;
}

.p-32-2 {
  padding: 32.2rem;
}

.p-32-3 {
  padding: 32.3rem;
}

.p-32-4 {
  padding: 32.4rem;
}

.p-32-5 {
  padding: 32.5rem;
}

.p-32-6 {
  padding: 32.6rem;
}

.p-32-7 {
  padding: 32.7rem;
}

.p-32-8 {
  padding: 32.8rem;
}

.p-32-9 {
  padding: 32.9rem;
}

.p-33-0 {
  padding: 33rem;
}

.p-33-1 {
  padding: 33.1rem;
}

.p-33-2 {
  padding: 33.2rem;
}

.p-33-3 {
  padding: 33.3rem;
}

.p-33-4 {
  padding: 33.4rem;
}

.p-33-5 {
  padding: 33.5rem;
}

.p-33-6 {
  padding: 33.6rem;
}

.p-33-7 {
  padding: 33.7rem;
}

.p-33-8 {
  padding: 33.8rem;
}

.p-33-9 {
  padding: 33.9rem;
}

.p-34-0 {
  padding: 34rem;
}

.p-34-1 {
  padding: 34.1rem;
}

.p-34-2 {
  padding: 34.2rem;
}

.p-34-3 {
  padding: 34.3rem;
}

.p-34-4 {
  padding: 34.4rem;
}

.p-34-5 {
  padding: 34.5rem;
}

.p-34-6 {
  padding: 34.6rem;
}

.p-34-7 {
  padding: 34.7rem;
}

.p-34-8 {
  padding: 34.8rem;
}

.p-34-9 {
  padding: 34.9rem;
}

.p-35-0 {
  padding: 35rem;
}

.p-35-1 {
  padding: 35.1rem;
}

.p-35-2 {
  padding: 35.2rem;
}

.p-35-3 {
  padding: 35.3rem;
}

.p-35-4 {
  padding: 35.4rem;
}

.p-35-5 {
  padding: 35.5rem;
}

.p-35-6 {
  padding: 35.6rem;
}

.p-35-7 {
  padding: 35.7rem;
}

.p-35-8 {
  padding: 35.8rem;
}

.p-35-9 {
  padding: 35.9rem;
}

.p-36-0 {
  padding: 36rem;
}

.p-36-1 {
  padding: 36.1rem;
}

.p-36-2 {
  padding: 36.2rem;
}

.p-36-3 {
  padding: 36.3rem;
}

.p-36-4 {
  padding: 36.4rem;
}

.p-36-5 {
  padding: 36.5rem;
}

.p-36-6 {
  padding: 36.6rem;
}

.p-36-7 {
  padding: 36.7rem;
}

.p-36-8 {
  padding: 36.8rem;
}

.p-36-9 {
  padding: 36.9rem;
}

.p-37-0 {
  padding: 37rem;
}

.p-37-1 {
  padding: 37.1rem;
}

.p-37-2 {
  padding: 37.2rem;
}

.p-37-3 {
  padding: 37.3rem;
}

.p-37-4 {
  padding: 37.4rem;
}

.p-37-5 {
  padding: 37.5rem;
}

.p-37-6 {
  padding: 37.6rem;
}

.p-37-7 {
  padding: 37.7rem;
}

.p-37-8 {
  padding: 37.8rem;
}

.p-37-9 {
  padding: 37.9rem;
}

.p-38-0 {
  padding: 38rem;
}

.p-38-1 {
  padding: 38.1rem;
}

.p-38-2 {
  padding: 38.2rem;
}

.p-38-3 {
  padding: 38.3rem;
}

.p-38-4 {
  padding: 38.4rem;
}

.p-38-5 {
  padding: 38.5rem;
}

.p-38-6 {
  padding: 38.6rem;
}

.p-38-7 {
  padding: 38.7rem;
}

.p-38-8 {
  padding: 38.8rem;
}

.p-38-9 {
  padding: 38.9rem;
}

.p-39-0 {
  padding: 39rem;
}

.p-39-1 {
  padding: 39.1rem;
}

.p-39-2 {
  padding: 39.2rem;
}

.p-39-3 {
  padding: 39.3rem;
}

.p-39-4 {
  padding: 39.4rem;
}

.p-39-5 {
  padding: 39.5rem;
}

.p-39-6 {
  padding: 39.6rem;
}

.p-39-7 {
  padding: 39.7rem;
}

.p-39-8 {
  padding: 39.8rem;
}

.p-39-9 {
  padding: 39.9rem;
}

.p-40-0 {
  padding: 40rem;
}

.p-40-1 {
  padding: 40.1rem;
}

.p-40-2 {
  padding: 40.2rem;
}

.p-40-3 {
  padding: 40.3rem;
}

.p-40-4 {
  padding: 40.4rem;
}

.p-40-5 {
  padding: 40.5rem;
}

.p-40-6 {
  padding: 40.6rem;
}

.p-40-7 {
  padding: 40.7rem;
}

.p-40-8 {
  padding: 40.8rem;
}

.p-40-9 {
  padding: 40.9rem;
}

.p-41-0 {
  padding: 41rem;
}

.p-41-1 {
  padding: 41.1rem;
}

.p-41-2 {
  padding: 41.2rem;
}

.p-41-3 {
  padding: 41.3rem;
}

.p-41-4 {
  padding: 41.4rem;
}

.p-41-5 {
  padding: 41.5rem;
}

.p-41-6 {
  padding: 41.6rem;
}

.p-41-7 {
  padding: 41.7rem;
}

.p-41-8 {
  padding: 41.8rem;
}

.p-41-9 {
  padding: 41.9rem;
}

.p-42-0 {
  padding: 42rem;
}

.p-42-1 {
  padding: 42.1rem;
}

.p-42-2 {
  padding: 42.2rem;
}

.p-42-3 {
  padding: 42.3rem;
}

.p-42-4 {
  padding: 42.4rem;
}

.p-42-5 {
  padding: 42.5rem;
}

.p-42-6 {
  padding: 42.6rem;
}

.p-42-7 {
  padding: 42.7rem;
}

.p-42-8 {
  padding: 42.8rem;
}

.p-42-9 {
  padding: 42.9rem;
}

.p-43-0 {
  padding: 43rem;
}

.p-43-1 {
  padding: 43.1rem;
}

.p-43-2 {
  padding: 43.2rem;
}

.p-43-3 {
  padding: 43.3rem;
}

.p-43-4 {
  padding: 43.4rem;
}

.p-43-5 {
  padding: 43.5rem;
}

.p-43-6 {
  padding: 43.6rem;
}

.p-43-7 {
  padding: 43.7rem;
}

.p-43-8 {
  padding: 43.8rem;
}

.p-43-9 {
  padding: 43.9rem;
}

.p-44-0 {
  padding: 44rem;
}

.p-44-1 {
  padding: 44.1rem;
}

.p-44-2 {
  padding: 44.2rem;
}

.p-44-3 {
  padding: 44.3rem;
}

.p-44-4 {
  padding: 44.4rem;
}

.p-44-5 {
  padding: 44.5rem;
}

.p-44-6 {
  padding: 44.6rem;
}

.p-44-7 {
  padding: 44.7rem;
}

.p-44-8 {
  padding: 44.8rem;
}

.p-44-9 {
  padding: 44.9rem;
}

.p-45-0 {
  padding: 45rem;
}

.p-45-1 {
  padding: 45.1rem;
}

.p-45-2 {
  padding: 45.2rem;
}

.p-45-3 {
  padding: 45.3rem;
}

.p-45-4 {
  padding: 45.4rem;
}

.p-45-5 {
  padding: 45.5rem;
}

.p-45-6 {
  padding: 45.6rem;
}

.p-45-7 {
  padding: 45.7rem;
}

.p-45-8 {
  padding: 45.8rem;
}

.p-45-9 {
  padding: 45.9rem;
}

.p-46-0 {
  padding: 46rem;
}

.p-46-1 {
  padding: 46.1rem;
}

.p-46-2 {
  padding: 46.2rem;
}

.p-46-3 {
  padding: 46.3rem;
}

.p-46-4 {
  padding: 46.4rem;
}

.p-46-5 {
  padding: 46.5rem;
}

.p-46-6 {
  padding: 46.6rem;
}

.p-46-7 {
  padding: 46.7rem;
}

.p-46-8 {
  padding: 46.8rem;
}

.p-46-9 {
  padding: 46.9rem;
}

.p-47-0 {
  padding: 47rem;
}

.p-47-1 {
  padding: 47.1rem;
}

.p-47-2 {
  padding: 47.2rem;
}

.p-47-3 {
  padding: 47.3rem;
}

.p-47-4 {
  padding: 47.4rem;
}

.p-47-5 {
  padding: 47.5rem;
}

.p-47-6 {
  padding: 47.6rem;
}

.p-47-7 {
  padding: 47.7rem;
}

.p-47-8 {
  padding: 47.8rem;
}

.p-47-9 {
  padding: 47.9rem;
}

.p-48-0 {
  padding: 48rem;
}

.p-48-1 {
  padding: 48.1rem;
}

.p-48-2 {
  padding: 48.2rem;
}

.p-48-3 {
  padding: 48.3rem;
}

.p-48-4 {
  padding: 48.4rem;
}

.p-48-5 {
  padding: 48.5rem;
}

.p-48-6 {
  padding: 48.6rem;
}

.p-48-7 {
  padding: 48.7rem;
}

.p-48-8 {
  padding: 48.8rem;
}

.p-48-9 {
  padding: 48.9rem;
}

.p-49-0 {
  padding: 49rem;
}

.p-49-1 {
  padding: 49.1rem;
}

.p-49-2 {
  padding: 49.2rem;
}

.p-49-3 {
  padding: 49.3rem;
}

.p-49-4 {
  padding: 49.4rem;
}

.p-49-5 {
  padding: 49.5rem;
}

.p-49-6 {
  padding: 49.6rem;
}

.p-49-7 {
  padding: 49.7rem;
}

.p-49-8 {
  padding: 49.8rem;
}

.p-49-9 {
  padding: 49.9rem;
}

.p-50-0 {
  padding: 50rem;
}

.p-50-1 {
  padding: 50.1rem;
}

.p-50-2 {
  padding: 50.2rem;
}

.p-50-3 {
  padding: 50.3rem;
}

.p-50-4 {
  padding: 50.4rem;
}

.p-50-5 {
  padding: 50.5rem;
}

.p-50-6 {
  padding: 50.6rem;
}

.p-50-7 {
  padding: 50.7rem;
}

.p-50-8 {
  padding: 50.8rem;
}

.p-50-9 {
  padding: 50.9rem;
}

.p-51-0 {
  padding: 51rem;
}

.p-51-1 {
  padding: 51.1rem;
}

.p-51-2 {
  padding: 51.2rem;
}

.p-51-3 {
  padding: 51.3rem;
}

.p-51-4 {
  padding: 51.4rem;
}

.p-51-5 {
  padding: 51.5rem;
}

.p-51-6 {
  padding: 51.6rem;
}

.p-51-7 {
  padding: 51.7rem;
}

.p-51-8 {
  padding: 51.8rem;
}

.p-51-9 {
  padding: 51.9rem;
}

.p-52-0 {
  padding: 52rem;
}

.p-52-1 {
  padding: 52.1rem;
}

.p-52-2 {
  padding: 52.2rem;
}

.p-52-3 {
  padding: 52.3rem;
}

.p-52-4 {
  padding: 52.4rem;
}

.p-52-5 {
  padding: 52.5rem;
}

.p-52-6 {
  padding: 52.6rem;
}

.p-52-7 {
  padding: 52.7rem;
}

.p-52-8 {
  padding: 52.8rem;
}

.p-52-9 {
  padding: 52.9rem;
}

.p-53-0 {
  padding: 53rem;
}

.p-53-1 {
  padding: 53.1rem;
}

.p-53-2 {
  padding: 53.2rem;
}

.p-53-3 {
  padding: 53.3rem;
}

.p-53-4 {
  padding: 53.4rem;
}

.p-53-5 {
  padding: 53.5rem;
}

.p-53-6 {
  padding: 53.6rem;
}

.p-53-7 {
  padding: 53.7rem;
}

.p-53-8 {
  padding: 53.8rem;
}

.p-53-9 {
  padding: 53.9rem;
}

.p-54-0 {
  padding: 54rem;
}

.p-54-1 {
  padding: 54.1rem;
}

.p-54-2 {
  padding: 54.2rem;
}

.p-54-3 {
  padding: 54.3rem;
}

.p-54-4 {
  padding: 54.4rem;
}

.p-54-5 {
  padding: 54.5rem;
}

.p-54-6 {
  padding: 54.6rem;
}

.p-54-7 {
  padding: 54.7rem;
}

.p-54-8 {
  padding: 54.8rem;
}

.p-54-9 {
  padding: 54.9rem;
}

.p-55-0 {
  padding: 55rem;
}

.p-55-1 {
  padding: 55.1rem;
}

.p-55-2 {
  padding: 55.2rem;
}

.p-55-3 {
  padding: 55.3rem;
}

.p-55-4 {
  padding: 55.4rem;
}

.p-55-5 {
  padding: 55.5rem;
}

.p-55-6 {
  padding: 55.6rem;
}

.p-55-7 {
  padding: 55.7rem;
}

.p-55-8 {
  padding: 55.8rem;
}

.p-55-9 {
  padding: 55.9rem;
}

.p-56-0 {
  padding: 56rem;
}

.p-56-1 {
  padding: 56.1rem;
}

.p-56-2 {
  padding: 56.2rem;
}

.p-56-3 {
  padding: 56.3rem;
}

.p-56-4 {
  padding: 56.4rem;
}

.p-56-5 {
  padding: 56.5rem;
}

.p-56-6 {
  padding: 56.6rem;
}

.p-56-7 {
  padding: 56.7rem;
}

.p-56-8 {
  padding: 56.8rem;
}

.p-56-9 {
  padding: 56.9rem;
}

.p-57-0 {
  padding: 57rem;
}

.p-57-1 {
  padding: 57.1rem;
}

.p-57-2 {
  padding: 57.2rem;
}

.p-57-3 {
  padding: 57.3rem;
}

.p-57-4 {
  padding: 57.4rem;
}

.p-57-5 {
  padding: 57.5rem;
}

.p-57-6 {
  padding: 57.6rem;
}

.p-57-7 {
  padding: 57.7rem;
}

.p-57-8 {
  padding: 57.8rem;
}

.p-57-9 {
  padding: 57.9rem;
}

.p-58-0 {
  padding: 58rem;
}

.p-58-1 {
  padding: 58.1rem;
}

.p-58-2 {
  padding: 58.2rem;
}

.p-58-3 {
  padding: 58.3rem;
}

.p-58-4 {
  padding: 58.4rem;
}

.p-58-5 {
  padding: 58.5rem;
}

.p-58-6 {
  padding: 58.6rem;
}

.p-58-7 {
  padding: 58.7rem;
}

.p-58-8 {
  padding: 58.8rem;
}

.p-58-9 {
  padding: 58.9rem;
}

.p-59-0 {
  padding: 59rem;
}

.p-59-1 {
  padding: 59.1rem;
}

.p-59-2 {
  padding: 59.2rem;
}

.p-59-3 {
  padding: 59.3rem;
}

.p-59-4 {
  padding: 59.4rem;
}

.p-59-5 {
  padding: 59.5rem;
}

.p-59-6 {
  padding: 59.6rem;
}

.p-59-7 {
  padding: 59.7rem;
}

.p-59-8 {
  padding: 59.8rem;
}

.p-59-9 {
  padding: 59.9rem;
}

.p-60-0 {
  padding: 60rem;
}

.p-60-1 {
  padding: 60.1rem;
}

.p-60-2 {
  padding: 60.2rem;
}

.p-60-3 {
  padding: 60.3rem;
}

.p-60-4 {
  padding: 60.4rem;
}

.p-60-5 {
  padding: 60.5rem;
}

.p-60-6 {
  padding: 60.6rem;
}

.p-60-7 {
  padding: 60.7rem;
}

.p-60-8 {
  padding: 60.8rem;
}

.p-60-9 {
  padding: 60.9rem;
}

.p-61-0 {
  padding: 61rem;
}

.p-61-1 {
  padding: 61.1rem;
}

.p-61-2 {
  padding: 61.2rem;
}

.p-61-3 {
  padding: 61.3rem;
}

.p-61-4 {
  padding: 61.4rem;
}

.p-61-5 {
  padding: 61.5rem;
}

.p-61-6 {
  padding: 61.6rem;
}

.p-61-7 {
  padding: 61.7rem;
}

.p-61-8 {
  padding: 61.8rem;
}

.p-61-9 {
  padding: 61.9rem;
}

.p-62-0 {
  padding: 62rem;
}

.p-62-1 {
  padding: 62.1rem;
}

.p-62-2 {
  padding: 62.2rem;
}

.p-62-3 {
  padding: 62.3rem;
}

.p-62-4 {
  padding: 62.4rem;
}

.p-62-5 {
  padding: 62.5rem;
}

.p-62-6 {
  padding: 62.6rem;
}

.p-62-7 {
  padding: 62.7rem;
}

.p-62-8 {
  padding: 62.8rem;
}

.p-62-9 {
  padding: 62.9rem;
}

.p-63-0 {
  padding: 63rem;
}

.p-63-1 {
  padding: 63.1rem;
}

.p-63-2 {
  padding: 63.2rem;
}

.p-63-3 {
  padding: 63.3rem;
}

.p-63-4 {
  padding: 63.4rem;
}

.p-63-5 {
  padding: 63.5rem;
}

.p-63-6 {
  padding: 63.6rem;
}

.p-63-7 {
  padding: 63.7rem;
}

.p-63-8 {
  padding: 63.8rem;
}

.p-63-9 {
  padding: 63.9rem;
}

.p-64-0 {
  padding: 64rem;
}

.p-64-1 {
  padding: 64.1rem;
}

.p-64-2 {
  padding: 64.2rem;
}

.p-64-3 {
  padding: 64.3rem;
}

.p-64-4 {
  padding: 64.4rem;
}

.p-64-5 {
  padding: 64.5rem;
}

.p-64-6 {
  padding: 64.6rem;
}

.p-64-7 {
  padding: 64.7rem;
}

.p-64-8 {
  padding: 64.8rem;
}

.p-64-9 {
  padding: 64.9rem;
}

.p-65-0 {
  padding: 65rem;
}

.p-65-1 {
  padding: 65.1rem;
}

.p-65-2 {
  padding: 65.2rem;
}

.p-65-3 {
  padding: 65.3rem;
}

.p-65-4 {
  padding: 65.4rem;
}

.p-65-5 {
  padding: 65.5rem;
}

.p-65-6 {
  padding: 65.6rem;
}

.p-65-7 {
  padding: 65.7rem;
}

.p-65-8 {
  padding: 65.8rem;
}

.p-65-9 {
  padding: 65.9rem;
}

.p-66-0 {
  padding: 66rem;
}

.p-66-1 {
  padding: 66.1rem;
}

.p-66-2 {
  padding: 66.2rem;
}

.p-66-3 {
  padding: 66.3rem;
}

.p-66-4 {
  padding: 66.4rem;
}

.p-66-5 {
  padding: 66.5rem;
}

.p-66-6 {
  padding: 66.6rem;
}

.p-66-7 {
  padding: 66.7rem;
}

.p-66-8 {
  padding: 66.8rem;
}

.p-66-9 {
  padding: 66.9rem;
}

.p-67-0 {
  padding: 67rem;
}

.p-67-1 {
  padding: 67.1rem;
}

.p-67-2 {
  padding: 67.2rem;
}

.p-67-3 {
  padding: 67.3rem;
}

.p-67-4 {
  padding: 67.4rem;
}

.p-67-5 {
  padding: 67.5rem;
}

.p-67-6 {
  padding: 67.6rem;
}

.p-67-7 {
  padding: 67.7rem;
}

.p-67-8 {
  padding: 67.8rem;
}

.p-67-9 {
  padding: 67.9rem;
}

.p-68-0 {
  padding: 68rem;
}

.p-68-1 {
  padding: 68.1rem;
}

.p-68-2 {
  padding: 68.2rem;
}

.p-68-3 {
  padding: 68.3rem;
}

.p-68-4 {
  padding: 68.4rem;
}

.p-68-5 {
  padding: 68.5rem;
}

.p-68-6 {
  padding: 68.6rem;
}

.p-68-7 {
  padding: 68.7rem;
}

.p-68-8 {
  padding: 68.8rem;
}

.p-68-9 {
  padding: 68.9rem;
}

.p-69-0 {
  padding: 69rem;
}

.p-69-1 {
  padding: 69.1rem;
}

.p-69-2 {
  padding: 69.2rem;
}

.p-69-3 {
  padding: 69.3rem;
}

.p-69-4 {
  padding: 69.4rem;
}

.p-69-5 {
  padding: 69.5rem;
}

.p-69-6 {
  padding: 69.6rem;
}

.p-69-7 {
  padding: 69.7rem;
}

.p-69-8 {
  padding: 69.8rem;
}

.p-69-9 {
  padding: 69.9rem;
}

.p-70-0 {
  padding: 70rem;
}

.p-70-1 {
  padding: 70.1rem;
}

.p-70-2 {
  padding: 70.2rem;
}

.p-70-3 {
  padding: 70.3rem;
}

.p-70-4 {
  padding: 70.4rem;
}

.p-70-5 {
  padding: 70.5rem;
}

.p-70-6 {
  padding: 70.6rem;
}

.p-70-7 {
  padding: 70.7rem;
}

.p-70-8 {
  padding: 70.8rem;
}

.p-70-9 {
  padding: 70.9rem;
}

.p-71-0 {
  padding: 71rem;
}

.p-71-1 {
  padding: 71.1rem;
}

.p-71-2 {
  padding: 71.2rem;
}

.p-71-3 {
  padding: 71.3rem;
}

.p-71-4 {
  padding: 71.4rem;
}

.p-71-5 {
  padding: 71.5rem;
}

.p-71-6 {
  padding: 71.6rem;
}

.p-71-7 {
  padding: 71.7rem;
}

.p-71-8 {
  padding: 71.8rem;
}

.p-71-9 {
  padding: 71.9rem;
}

.p-72-0 {
  padding: 72rem;
}

.p-72-1 {
  padding: 72.1rem;
}

.p-72-2 {
  padding: 72.2rem;
}

.p-72-3 {
  padding: 72.3rem;
}

.p-72-4 {
  padding: 72.4rem;
}

.p-72-5 {
  padding: 72.5rem;
}

.p-72-6 {
  padding: 72.6rem;
}

.p-72-7 {
  padding: 72.7rem;
}

.p-72-8 {
  padding: 72.8rem;
}

.p-72-9 {
  padding: 72.9rem;
}

/* 
   - This section contains utilities for margin
   - includes: margin, margin-left, margin-right, margin-top, margin-bottom, margin-x axis, margin-y axis
   - The sizing is implemented using rem but with px accuracy.
   - i.e if you want to give a margin of 30px, you can use m-30 which is 30px but converted to rem on background

*/
.m-0 {
  margin: 0rem;
}

.m-1 {
  margin: 0.1rem;
}

.m-2 {
  margin: 0.2rem;
}

.m-3 {
  margin: 0.3rem;
}

.m-4 {
  margin: 0.4rem;
}

.m-5 {
  margin: 0.5rem;
}

.m-6 {
  margin: 0.6rem;
}

.m-7 {
  margin: 0.7rem;
}

.m-8 {
  margin: 0.8rem;
}

.m-9 {
  margin: 0.9rem;
}

.m-10 {
  margin: 1rem;
}

.m-11 {
  margin: 1.1rem;
}

.m-12 {
  margin: 1.2rem;
}

.m-13 {
  margin: 1.3rem;
}

.m-14 {
  margin: 1.4rem;
}

.m-15 {
  margin: 1.5rem;
}

.m-16 {
  margin: 1.6rem;
}

.m-17 {
  margin: 1.7rem;
}

.m-18 {
  margin: 1.8rem;
}

.m-19 {
  margin: 1.9rem;
}

.m-20 {
  margin: 2rem;
}

.m-21 {
  margin: 2.1rem;
}

.m-22 {
  margin: 2.2rem;
}

.m-23 {
  margin: 2.3rem;
}

.m-24 {
  margin: 2.4rem;
}

.m-25 {
  margin: 2.5rem;
}

.m-26 {
  margin: 2.6rem;
}

.m-27 {
  margin: 2.7rem;
}

.m-28 {
  margin: 2.8rem;
}

.m-29 {
  margin: 2.9rem;
}

.m-30 {
  margin: 3rem;
}

.m-31 {
  margin: 3.1rem;
}

.m-32 {
  margin: 3.2rem;
}

.m-33 {
  margin: 3.3rem;
}

.m-34 {
  margin: 3.4rem;
}

.m-35 {
  margin: 3.5rem;
}

.m-36 {
  margin: 3.6rem;
}

.m-37 {
  margin: 3.7rem;
}

.m-38 {
  margin: 3.8rem;
}

.m-39 {
  margin: 3.9rem;
}

.m-40 {
  margin: 4rem;
}

.m-41 {
  margin: 4.1rem;
}

.m-42 {
  margin: 4.2rem;
}

.m-43 {
  margin: 4.3rem;
}

.m-44 {
  margin: 4.4rem;
}

.m-45 {
  margin: 4.5rem;
}

.m-46 {
  margin: 4.6rem;
}

.m-47 {
  margin: 4.7rem;
}

.m-48 {
  margin: 4.8rem;
}

.m-49 {
  margin: 4.9rem;
}

.m-50 {
  margin: 5rem;
}

.m-51 {
  margin: 5.1rem;
}

.m-52 {
  margin: 5.2rem;
}

.m-53 {
  margin: 5.3rem;
}

.m-54 {
  margin: 5.4rem;
}

.m-55 {
  margin: 5.5rem;
}

.m-56 {
  margin: 5.6rem;
}

.m-57 {
  margin: 5.7rem;
}

.m-58 {
  margin: 5.8rem;
}

.m-59 {
  margin: 5.9rem;
}

.m-60 {
  margin: 6rem;
}

.m-61 {
  margin: 6.1rem;
}

.m-62 {
  margin: 6.2rem;
}

.m-63 {
  margin: 6.3rem;
}

.m-64 {
  margin: 6.4rem;
}

.m-65 {
  margin: 6.5rem;
}

.m-66 {
  margin: 6.6rem;
}

.m-67 {
  margin: 6.7rem;
}

.m-68 {
  margin: 6.8rem;
}

.m-69 {
  margin: 6.9rem;
}

.m-70 {
  margin: 7rem;
}

.m-71 {
  margin: 7.1rem;
}

.m-72 {
  margin: 7.2rem;
}

.ml-0 {
  margin-left: 0rem;
}

.ml-1 {
  margin-left: 0.1rem;
}

.ml-2 {
  margin-left: 0.2rem;
}

.ml-3 {
  margin-left: 0.3rem;
}

.ml-4 {
  margin-left: 0.4rem;
}

.ml-5 {
  margin-left: 0.5rem;
}

.ml-6 {
  margin-left: 0.6rem;
}

.ml-7 {
  margin-left: 0.7rem;
}

.ml-8 {
  margin-left: 0.8rem;
}

.ml-9 {
  margin-left: 0.9rem;
}

.ml-10 {
  margin-left: 1rem;
}

.ml-11 {
  margin-left: 1.1rem;
}

.ml-12 {
  margin-left: 1.2rem;
}

.ml-13 {
  margin-left: 1.3rem;
}

.ml-14 {
  margin-left: 1.4rem;
}

.ml-15 {
  margin-left: 1.5rem;
}

.ml-16 {
  margin-left: 1.6rem;
}

.ml-17 {
  margin-left: 1.7rem;
}

.ml-18 {
  margin-left: 1.8rem;
}

.ml-19 {
  margin-left: 1.9rem;
}

.ml-20 {
  margin-left: 2rem;
}

.ml-21 {
  margin-left: 2.1rem;
}

.ml-22 {
  margin-left: 2.2rem;
}

.ml-23 {
  margin-left: 2.3rem;
}

.ml-24 {
  margin-left: 2.4rem;
}

.ml-25 {
  margin-left: 2.5rem;
}

.ml-26 {
  margin-left: 2.6rem;
}

.ml-27 {
  margin-left: 2.7rem;
}

.ml-28 {
  margin-left: 2.8rem;
}

.ml-29 {
  margin-left: 2.9rem;
}

.ml-30 {
  margin-left: 3rem;
}

.ml-31 {
  margin-left: 3.1rem;
}

.ml-32 {
  margin-left: 3.2rem;
}

.ml-33 {
  margin-left: 3.3rem;
}

.ml-34 {
  margin-left: 3.4rem;
}

.ml-35 {
  margin-left: 3.5rem;
}

.ml-36 {
  margin-left: 3.6rem;
}

.ml-37 {
  margin-left: 3.7rem;
}

.ml-38 {
  margin-left: 3.8rem;
}

.ml-39 {
  margin-left: 3.9rem;
}

.ml-40 {
  margin-left: 4rem;
}

.ml-41 {
  margin-left: 4.1rem;
}

.ml-42 {
  margin-left: 4.2rem;
}

.ml-43 {
  margin-left: 4.3rem;
}

.ml-44 {
  margin-left: 4.4rem;
}

.ml-45 {
  margin-left: 4.5rem;
}

.ml-46 {
  margin-left: 4.6rem;
}

.ml-47 {
  margin-left: 4.7rem;
}

.ml-48 {
  margin-left: 4.8rem;
}

.ml-49 {
  margin-left: 4.9rem;
}

.ml-50 {
  margin-left: 5rem;
}

.ml-51 {
  margin-left: 5.1rem;
}

.ml-52 {
  margin-left: 5.2rem;
}

.ml-53 {
  margin-left: 5.3rem;
}

.ml-54 {
  margin-left: 5.4rem;
}

.ml-55 {
  margin-left: 5.5rem;
}

.ml-56 {
  margin-left: 5.6rem;
}

.ml-57 {
  margin-left: 5.7rem;
}

.ml-58 {
  margin-left: 5.8rem;
}

.ml-59 {
  margin-left: 5.9rem;
}

.ml-60 {
  margin-left: 6rem;
}

.ml-61 {
  margin-left: 6.1rem;
}

.ml-62 {
  margin-left: 6.2rem;
}

.ml-63 {
  margin-left: 6.3rem;
}

.ml-64 {
  margin-left: 6.4rem;
}

.ml-65 {
  margin-left: 6.5rem;
}

.ml-66 {
  margin-left: 6.6rem;
}

.ml-67 {
  margin-left: 6.7rem;
}

.ml-68 {
  margin-left: 6.8rem;
}

.ml-69 {
  margin-left: 6.9rem;
}

.ml-70 {
  margin-left: 7rem;
}

.ml-71 {
  margin-left: 7.1rem;
}

.ml-72 {
  margin-left: 7.2rem;
}

.mr-0 {
  margin-right: 0rem;
}

.mr-1 {
  margin-right: 0.1rem;
}

.mr-2 {
  margin-right: 0.2rem;
}

.mr-3 {
  margin-right: 0.3rem;
}

.mr-4 {
  margin-right: 0.4rem;
}

.mr-5 {
  margin-right: 0.5rem;
}

.mr-6 {
  margin-right: 0.6rem;
}

.mr-7 {
  margin-right: 0.7rem;
}

.mr-8 {
  margin-right: 0.8rem;
}

.mr-9 {
  margin-right: 0.9rem;
}

.mr-10 {
  margin-right: 1rem;
}

.mr-11 {
  margin-right: 1.1rem;
}

.mr-12 {
  margin-right: 1.2rem;
}

.mr-13 {
  margin-right: 1.3rem;
}

.mr-14 {
  margin-right: 1.4rem;
}

.mr-15 {
  margin-right: 1.5rem;
}

.mr-16 {
  margin-right: 1.6rem;
}

.mr-17 {
  margin-right: 1.7rem;
}

.mr-18 {
  margin-right: 1.8rem;
}

.mr-19 {
  margin-right: 1.9rem;
}

.mr-20 {
  margin-right: 2rem;
}

.mr-21 {
  margin-right: 2.1rem;
}

.mr-22 {
  margin-right: 2.2rem;
}

.mr-23 {
  margin-right: 2.3rem;
}

.mr-24 {
  margin-right: 2.4rem;
}

.mr-25 {
  margin-right: 2.5rem;
}

.mr-26 {
  margin-right: 2.6rem;
}

.mr-27 {
  margin-right: 2.7rem;
}

.mr-28 {
  margin-right: 2.8rem;
}

.mr-29 {
  margin-right: 2.9rem;
}

.mr-30 {
  margin-right: 3rem;
}

.mr-31 {
  margin-right: 3.1rem;
}

.mr-32 {
  margin-right: 3.2rem;
}

.mr-33 {
  margin-right: 3.3rem;
}

.mr-34 {
  margin-right: 3.4rem;
}

.mr-35 {
  margin-right: 3.5rem;
}

.mr-36 {
  margin-right: 3.6rem;
}

.mr-37 {
  margin-right: 3.7rem;
}

.mr-38 {
  margin-right: 3.8rem;
}

.mr-39 {
  margin-right: 3.9rem;
}

.mr-40 {
  margin-right: 4rem;
}

.mr-41 {
  margin-right: 4.1rem;
}

.mr-42 {
  margin-right: 4.2rem;
}

.mr-43 {
  margin-right: 4.3rem;
}

.mr-44 {
  margin-right: 4.4rem;
}

.mr-45 {
  margin-right: 4.5rem;
}

.mr-46 {
  margin-right: 4.6rem;
}

.mr-47 {
  margin-right: 4.7rem;
}

.mr-48 {
  margin-right: 4.8rem;
}

.mr-49 {
  margin-right: 4.9rem;
}

.mr-50 {
  margin-right: 5rem;
}

.mr-51 {
  margin-right: 5.1rem;
}

.mr-52 {
  margin-right: 5.2rem;
}

.mr-53 {
  margin-right: 5.3rem;
}

.mr-54 {
  margin-right: 5.4rem;
}

.mr-55 {
  margin-right: 5.5rem;
}

.mr-56 {
  margin-right: 5.6rem;
}

.mr-57 {
  margin-right: 5.7rem;
}

.mr-58 {
  margin-right: 5.8rem;
}

.mr-59 {
  margin-right: 5.9rem;
}

.mr-60 {
  margin-right: 6rem;
}

.mr-61 {
  margin-right: 6.1rem;
}

.mr-62 {
  margin-right: 6.2rem;
}

.mr-63 {
  margin-right: 6.3rem;
}

.mr-64 {
  margin-right: 6.4rem;
}

.mr-65 {
  margin-right: 6.5rem;
}

.mr-66 {
  margin-right: 6.6rem;
}

.mr-67 {
  margin-right: 6.7rem;
}

.mr-68 {
  margin-right: 6.8rem;
}

.mr-69 {
  margin-right: 6.9rem;
}

.mr-70 {
  margin-right: 7rem;
}

.mr-71 {
  margin-right: 7.1rem;
}

.mr-72 {
  margin-right: 7.2rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mt-1 {
  margin-top: 0.1rem;
}

.mt-2 {
  margin-top: 0.2rem;
}

.mt-3 {
  margin-top: 0.3rem;
}

.mt-4 {
  margin-top: 0.4rem;
}

.mt-5 {
  margin-top: 0.5rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.mt-7 {
  margin-top: 0.7rem;
}

.mt-8 {
  margin-top: 0.8rem;
}

.mt-9 {
  margin-top: 0.9rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mt-11 {
  margin-top: 1.1rem;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mt-13 {
  margin-top: 1.3rem;
}

.mt-14 {
  margin-top: 1.4rem;
}

.mt-15 {
  margin-top: 1.5rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mt-17 {
  margin-top: 1.7rem;
}

.mt-18 {
  margin-top: 1.8rem;
}

.mt-19 {
  margin-top: 1.9rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-21 {
  margin-top: 2.1rem;
}

.mt-22 {
  margin-top: 2.2rem;
}

.mt-23 {
  margin-top: 2.3rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mt-25 {
  margin-top: 2.5rem;
}

.mt-26 {
  margin-top: 2.6rem;
}

.mt-27 {
  margin-top: 2.7rem;
}

.mt-28 {
  margin-top: 2.8rem;
}

.mt-29 {
  margin-top: 2.9rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-31 {
  margin-top: 3.1rem;
}

.mt-32 {
  margin-top: 3.2rem;
}

.mt-33 {
  margin-top: 3.3rem;
}

.mt-34 {
  margin-top: 3.4rem;
}

.mt-35 {
  margin-top: 3.5rem;
}

.mt-36 {
  margin-top: 3.6rem;
}

.mt-37 {
  margin-top: 3.7rem;
}

.mt-38 {
  margin-top: 3.8rem;
}

.mt-39 {
  margin-top: 3.9rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-41 {
  margin-top: 4.1rem;
}

.mt-42 {
  margin-top: 4.2rem;
}

.mt-43 {
  margin-top: 4.3rem;
}

.mt-44 {
  margin-top: 4.4rem;
}

.mt-45 {
  margin-top: 4.5rem;
}

.mt-46 {
  margin-top: 4.6rem;
}

.mt-47 {
  margin-top: 4.7rem;
}

.mt-48 {
  margin-top: 4.8rem;
}

.mt-49 {
  margin-top: 4.9rem;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-51 {
  margin-top: 5.1rem;
}

.mt-52 {
  margin-top: 5.2rem;
}

.mt-53 {
  margin-top: 5.3rem;
}

.mt-54 {
  margin-top: 5.4rem;
}

.mt-55 {
  margin-top: 5.5rem;
}

.mt-56 {
  margin-top: 5.6rem;
}

.mt-57 {
  margin-top: 5.7rem;
}

.mt-58 {
  margin-top: 5.8rem;
}

.mt-59 {
  margin-top: 5.9rem;
}

.mt-60 {
  margin-top: 6rem;
}

.mt-61 {
  margin-top: 6.1rem;
}

.mt-62 {
  margin-top: 6.2rem;
}

.mt-63 {
  margin-top: 6.3rem;
}

.mt-64 {
  margin-top: 6.4rem;
}

.mt-65 {
  margin-top: 6.5rem;
}

.mt-66 {
  margin-top: 6.6rem;
}

.mt-67 {
  margin-top: 6.7rem;
}

.mt-68 {
  margin-top: 6.8rem;
}

.mt-69 {
  margin-top: 6.9rem;
}

.mt-70 {
  margin-top: 7rem;
}

.mt-71 {
  margin-top: 7.1rem;
}

.mt-72 {
  margin-top: 7.2rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-1 {
  margin-bottom: 0.1rem;
}

.mb-2 {
  margin-bottom: 0.2rem;
}

.mb-3 {
  margin-bottom: 0.3rem;
}

.mb-4 {
  margin-bottom: 0.4rem;
}

.mb-5 {
  margin-bottom: 0.5rem;
}

.mb-6 {
  margin-bottom: 0.6rem;
}

.mb-7 {
  margin-bottom: 0.7rem;
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.mb-9 {
  margin-bottom: 0.9rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.mb-11 {
  margin-bottom: 1.1rem;
}

.mb-12 {
  margin-bottom: 1.2rem;
}

.mb-13 {
  margin-bottom: 1.3rem;
}

.mb-14 {
  margin-bottom: 1.4rem;
}

.mb-15 {
  margin-bottom: 1.5rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mb-17 {
  margin-bottom: 1.7rem;
}

.mb-18 {
  margin-bottom: 1.8rem;
}

.mb-19 {
  margin-bottom: 1.9rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.mb-21 {
  margin-bottom: 2.1rem;
}

.mb-22 {
  margin-bottom: 2.2rem;
}

.mb-23 {
  margin-bottom: 2.3rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mb-25 {
  margin-bottom: 2.5rem;
}

.mb-26 {
  margin-bottom: 2.6rem;
}

.mb-27 {
  margin-bottom: 2.7rem;
}

.mb-28 {
  margin-bottom: 2.8rem;
}

.mb-29 {
  margin-bottom: 2.9rem;
}

.mb-30 {
  margin-bottom: 3rem;
}

.mb-31 {
  margin-bottom: 3.1rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.mb-33 {
  margin-bottom: 3.3rem;
}

.mb-34 {
  margin-bottom: 3.4rem;
}

.mb-35 {
  margin-bottom: 3.5rem;
}

.mb-36 {
  margin-bottom: 3.6rem;
}

.mb-37 {
  margin-bottom: 3.7rem;
}

.mb-38 {
  margin-bottom: 3.8rem;
}

.mb-39 {
  margin-bottom: 3.9rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-41 {
  margin-bottom: 4.1rem;
}

.mb-42 {
  margin-bottom: 4.2rem;
}

.mb-43 {
  margin-bottom: 4.3rem;
}

.mb-44 {
  margin-bottom: 4.4rem;
}

.mb-45 {
  margin-bottom: 4.5rem;
}

.mb-46 {
  margin-bottom: 4.6rem;
}

.mb-47 {
  margin-bottom: 4.7rem;
}

.mb-48 {
  margin-bottom: 4.8rem;
}

.mb-49 {
  margin-bottom: 4.9rem;
}

.mb-50 {
  margin-bottom: 5rem;
}

.mb-51 {
  margin-bottom: 5.1rem;
}

.mb-52 {
  margin-bottom: 5.2rem;
}

.mb-53 {
  margin-bottom: 5.3rem;
}

.mb-54 {
  margin-bottom: 5.4rem;
}

.mb-55 {
  margin-bottom: 5.5rem;
}

.mb-56 {
  margin-bottom: 5.6rem;
}

.mb-57 {
  margin-bottom: 5.7rem;
}

.mb-58 {
  margin-bottom: 5.8rem;
}

.mb-59 {
  margin-bottom: 5.9rem;
}

.mb-60 {
  margin-bottom: 6rem;
}

.mb-61 {
  margin-bottom: 6.1rem;
}

.mb-62 {
  margin-bottom: 6.2rem;
}

.mb-63 {
  margin-bottom: 6.3rem;
}

.mb-64 {
  margin-bottom: 6.4rem;
}

.mb-65 {
  margin-bottom: 6.5rem;
}

.mb-66 {
  margin-bottom: 6.6rem;
}

.mb-67 {
  margin-bottom: 6.7rem;
}

.mb-68 {
  margin-bottom: 6.8rem;
}

.mb-69 {
  margin-bottom: 6.9rem;
}

.mb-70 {
  margin-bottom: 7rem;
}

.mb-71 {
  margin-bottom: 7.1rem;
}

.mb-72 {
  margin-bottom: 7.2rem;
}

.m-x {
  margin-left: 0remrem;
  margin-right: 0remrem;
}

.m-x {
  margin-left: 0.1remrem;
  margin-right: 0.1remrem;
}

.m-x {
  margin-left: 0.2remrem;
  margin-right: 0.2remrem;
}

.m-x {
  margin-left: 0.3remrem;
  margin-right: 0.3remrem;
}

.m-x {
  margin-left: 0.4remrem;
  margin-right: 0.4remrem;
}

.m-x {
  margin-left: 0.5remrem;
  margin-right: 0.5remrem;
}

.m-x {
  margin-left: 0.6remrem;
  margin-right: 0.6remrem;
}

.m-x {
  margin-left: 0.7remrem;
  margin-right: 0.7remrem;
}

.m-x {
  margin-left: 0.8remrem;
  margin-right: 0.8remrem;
}

.m-x {
  margin-left: 0.9remrem;
  margin-right: 0.9remrem;
}

.m-x {
  margin-left: 1remrem;
  margin-right: 1remrem;
}

.m-x {
  margin-left: 1.1remrem;
  margin-right: 1.1remrem;
}

.m-x {
  margin-left: 1.2remrem;
  margin-right: 1.2remrem;
}

.m-x {
  margin-left: 1.3remrem;
  margin-right: 1.3remrem;
}

.m-x {
  margin-left: 1.4remrem;
  margin-right: 1.4remrem;
}

.m-x {
  margin-left: 1.5remrem;
  margin-right: 1.5remrem;
}

.m-x {
  margin-left: 1.6remrem;
  margin-right: 1.6remrem;
}

.m-x {
  margin-left: 1.7remrem;
  margin-right: 1.7remrem;
}

.m-x {
  margin-left: 1.8remrem;
  margin-right: 1.8remrem;
}

.m-x {
  margin-left: 1.9remrem;
  margin-right: 1.9remrem;
}

.m-x {
  margin-left: 2remrem;
  margin-right: 2remrem;
}

.m-x {
  margin-left: 2.1remrem;
  margin-right: 2.1remrem;
}

.m-x {
  margin-left: 2.2remrem;
  margin-right: 2.2remrem;
}

.m-x {
  margin-left: 2.3remrem;
  margin-right: 2.3remrem;
}

.m-x {
  margin-left: 2.4remrem;
  margin-right: 2.4remrem;
}

.m-x {
  margin-left: 2.5remrem;
  margin-right: 2.5remrem;
}

.m-x {
  margin-left: 2.6remrem;
  margin-right: 2.6remrem;
}

.m-x {
  margin-left: 2.7remrem;
  margin-right: 2.7remrem;
}

.m-x {
  margin-left: 2.8remrem;
  margin-right: 2.8remrem;
}

.m-x {
  margin-left: 2.9remrem;
  margin-right: 2.9remrem;
}

.m-x {
  margin-left: 3remrem;
  margin-right: 3remrem;
}

.m-x {
  margin-left: 3.1remrem;
  margin-right: 3.1remrem;
}

.m-x {
  margin-left: 3.2remrem;
  margin-right: 3.2remrem;
}

.m-x {
  margin-left: 3.3remrem;
  margin-right: 3.3remrem;
}

.m-x {
  margin-left: 3.4remrem;
  margin-right: 3.4remrem;
}

.m-x {
  margin-left: 3.5remrem;
  margin-right: 3.5remrem;
}

.m-x {
  margin-left: 3.6remrem;
  margin-right: 3.6remrem;
}

.m-x {
  margin-left: 3.7remrem;
  margin-right: 3.7remrem;
}

.m-x {
  margin-left: 3.8remrem;
  margin-right: 3.8remrem;
}

.m-x {
  margin-left: 3.9remrem;
  margin-right: 3.9remrem;
}

.m-x {
  margin-left: 4remrem;
  margin-right: 4remrem;
}

.m-x {
  margin-left: 4.1remrem;
  margin-right: 4.1remrem;
}

.m-x {
  margin-left: 4.2remrem;
  margin-right: 4.2remrem;
}

.m-x {
  margin-left: 4.3remrem;
  margin-right: 4.3remrem;
}

.m-x {
  margin-left: 4.4remrem;
  margin-right: 4.4remrem;
}

.m-x {
  margin-left: 4.5remrem;
  margin-right: 4.5remrem;
}

.m-x {
  margin-left: 4.6remrem;
  margin-right: 4.6remrem;
}

.m-x {
  margin-left: 4.7remrem;
  margin-right: 4.7remrem;
}

.m-x {
  margin-left: 4.8remrem;
  margin-right: 4.8remrem;
}

.m-x {
  margin-left: 4.9remrem;
  margin-right: 4.9remrem;
}

.m-x {
  margin-left: 5remrem;
  margin-right: 5remrem;
}

.m-x {
  margin-left: 5.1remrem;
  margin-right: 5.1remrem;
}

.m-x {
  margin-left: 5.2remrem;
  margin-right: 5.2remrem;
}

.m-x {
  margin-left: 5.3remrem;
  margin-right: 5.3remrem;
}

.m-x {
  margin-left: 5.4remrem;
  margin-right: 5.4remrem;
}

.m-x {
  margin-left: 5.5remrem;
  margin-right: 5.5remrem;
}

.m-x {
  margin-left: 5.6remrem;
  margin-right: 5.6remrem;
}

.m-x {
  margin-left: 5.7remrem;
  margin-right: 5.7remrem;
}

.m-x {
  margin-left: 5.8remrem;
  margin-right: 5.8remrem;
}

.m-x {
  margin-left: 5.9remrem;
  margin-right: 5.9remrem;
}

.m-x {
  margin-left: 6remrem;
  margin-right: 6remrem;
}

.m-x {
  margin-left: 6.1remrem;
  margin-right: 6.1remrem;
}

.m-x {
  margin-left: 6.2remrem;
  margin-right: 6.2remrem;
}

.m-x {
  margin-left: 6.3remrem;
  margin-right: 6.3remrem;
}

.m-x {
  margin-left: 6.4remrem;
  margin-right: 6.4remrem;
}

.m-x {
  margin-left: 6.5remrem;
  margin-right: 6.5remrem;
}

.m-x {
  margin-left: 6.6remrem;
  margin-right: 6.6remrem;
}

.m-x {
  margin-left: 6.7remrem;
  margin-right: 6.7remrem;
}

.m-x {
  margin-left: 6.8remrem;
  margin-right: 6.8remrem;
}

.m-x {
  margin-left: 6.9remrem;
  margin-right: 6.9remrem;
}

.m-x {
  margin-left: 7remrem;
  margin-right: 7remrem;
}

.m-x {
  margin-left: 7.1remrem;
  margin-right: 7.1remrem;
}

.m-x {
  margin-left: 7.2remrem;
  margin-right: 7.2remrem;
}

.m-y {
  margin-top: 0remrem;
  margin-bottom: 0remrem;
}

.m-y {
  margin-top: 0.1remrem;
  margin-bottom: 0.1remrem;
}

.m-y {
  margin-top: 0.2remrem;
  margin-bottom: 0.2remrem;
}

.m-y {
  margin-top: 0.3remrem;
  margin-bottom: 0.3remrem;
}

.m-y {
  margin-top: 0.4remrem;
  margin-bottom: 0.4remrem;
}

.m-y {
  margin-top: 0.5remrem;
  margin-bottom: 0.5remrem;
}

.m-y {
  margin-top: 0.6remrem;
  margin-bottom: 0.6remrem;
}

.m-y {
  margin-top: 0.7remrem;
  margin-bottom: 0.7remrem;
}

.m-y {
  margin-top: 0.8remrem;
  margin-bottom: 0.8remrem;
}

.m-y {
  margin-top: 0.9remrem;
  margin-bottom: 0.9remrem;
}

.m-y {
  margin-top: 1remrem;
  margin-bottom: 1remrem;
}

.m-y {
  margin-top: 1.1remrem;
  margin-bottom: 1.1remrem;
}

.m-y {
  margin-top: 1.2remrem;
  margin-bottom: 1.2remrem;
}

.m-y {
  margin-top: 1.3remrem;
  margin-bottom: 1.3remrem;
}

.m-y {
  margin-top: 1.4remrem;
  margin-bottom: 1.4remrem;
}

.m-y {
  margin-top: 1.5remrem;
  margin-bottom: 1.5remrem;
}

.m-y {
  margin-top: 1.6remrem;
  margin-bottom: 1.6remrem;
}

.m-y {
  margin-top: 1.7remrem;
  margin-bottom: 1.7remrem;
}

.m-y {
  margin-top: 1.8remrem;
  margin-bottom: 1.8remrem;
}

.m-y {
  margin-top: 1.9remrem;
  margin-bottom: 1.9remrem;
}

.m-y {
  margin-top: 2remrem;
  margin-bottom: 2remrem;
}

.m-y {
  margin-top: 2.1remrem;
  margin-bottom: 2.1remrem;
}

.m-y {
  margin-top: 2.2remrem;
  margin-bottom: 2.2remrem;
}

.m-y {
  margin-top: 2.3remrem;
  margin-bottom: 2.3remrem;
}

.m-y {
  margin-top: 2.4remrem;
  margin-bottom: 2.4remrem;
}

.m-y {
  margin-top: 2.5remrem;
  margin-bottom: 2.5remrem;
}

.m-y {
  margin-top: 2.6remrem;
  margin-bottom: 2.6remrem;
}

.m-y {
  margin-top: 2.7remrem;
  margin-bottom: 2.7remrem;
}

.m-y {
  margin-top: 2.8remrem;
  margin-bottom: 2.8remrem;
}

.m-y {
  margin-top: 2.9remrem;
  margin-bottom: 2.9remrem;
}

.m-y {
  margin-top: 3remrem;
  margin-bottom: 3remrem;
}

.m-y {
  margin-top: 3.1remrem;
  margin-bottom: 3.1remrem;
}

.m-y {
  margin-top: 3.2remrem;
  margin-bottom: 3.2remrem;
}

.m-y {
  margin-top: 3.3remrem;
  margin-bottom: 3.3remrem;
}

.m-y {
  margin-top: 3.4remrem;
  margin-bottom: 3.4remrem;
}

.m-y {
  margin-top: 3.5remrem;
  margin-bottom: 3.5remrem;
}

.m-y {
  margin-top: 3.6remrem;
  margin-bottom: 3.6remrem;
}

.m-y {
  margin-top: 3.7remrem;
  margin-bottom: 3.7remrem;
}

.m-y {
  margin-top: 3.8remrem;
  margin-bottom: 3.8remrem;
}

.m-y {
  margin-top: 3.9remrem;
  margin-bottom: 3.9remrem;
}

.m-y {
  margin-top: 4remrem;
  margin-bottom: 4remrem;
}

.m-y {
  margin-top: 4.1remrem;
  margin-bottom: 4.1remrem;
}

.m-y {
  margin-top: 4.2remrem;
  margin-bottom: 4.2remrem;
}

.m-y {
  margin-top: 4.3remrem;
  margin-bottom: 4.3remrem;
}

.m-y {
  margin-top: 4.4remrem;
  margin-bottom: 4.4remrem;
}

.m-y {
  margin-top: 4.5remrem;
  margin-bottom: 4.5remrem;
}

.m-y {
  margin-top: 4.6remrem;
  margin-bottom: 4.6remrem;
}

.m-y {
  margin-top: 4.7remrem;
  margin-bottom: 4.7remrem;
}

.m-y {
  margin-top: 4.8remrem;
  margin-bottom: 4.8remrem;
}

.m-y {
  margin-top: 4.9remrem;
  margin-bottom: 4.9remrem;
}

.m-y {
  margin-top: 5remrem;
  margin-bottom: 5remrem;
}

.m-y {
  margin-top: 5.1remrem;
  margin-bottom: 5.1remrem;
}

.m-y {
  margin-top: 5.2remrem;
  margin-bottom: 5.2remrem;
}

.m-y {
  margin-top: 5.3remrem;
  margin-bottom: 5.3remrem;
}

.m-y {
  margin-top: 5.4remrem;
  margin-bottom: 5.4remrem;
}

.m-y {
  margin-top: 5.5remrem;
  margin-bottom: 5.5remrem;
}

.m-y {
  margin-top: 5.6remrem;
  margin-bottom: 5.6remrem;
}

.m-y {
  margin-top: 5.7remrem;
  margin-bottom: 5.7remrem;
}

.m-y {
  margin-top: 5.8remrem;
  margin-bottom: 5.8remrem;
}

.m-y {
  margin-top: 5.9remrem;
  margin-bottom: 5.9remrem;
}

.m-y {
  margin-top: 6remrem;
  margin-bottom: 6remrem;
}

.m-y {
  margin-top: 6.1remrem;
  margin-bottom: 6.1remrem;
}

.m-y {
  margin-top: 6.2remrem;
  margin-bottom: 6.2remrem;
}

.m-y {
  margin-top: 6.3remrem;
  margin-bottom: 6.3remrem;
}

.m-y {
  margin-top: 6.4remrem;
  margin-bottom: 6.4remrem;
}

.m-y {
  margin-top: 6.5remrem;
  margin-bottom: 6.5remrem;
}

.m-y {
  margin-top: 6.6remrem;
  margin-bottom: 6.6remrem;
}

.m-y {
  margin-top: 6.7remrem;
  margin-bottom: 6.7remrem;
}

.m-y {
  margin-top: 6.8remrem;
  margin-bottom: 6.8remrem;
}

.m-y {
  margin-top: 6.9remrem;
  margin-bottom: 6.9remrem;
}

.m-y {
  margin-top: 7remrem;
  margin-bottom: 7remrem;
}

.m-y {
  margin-top: 7.1remrem;
  margin-bottom: 7.1remrem;
}

.m-y {
  margin-top: 7.2remrem;
  margin-bottom: 7.2remrem;
}

.m-auto {
  margin: auto;
}

.m-0-0 {
  margin: 0rem;
}

.m-0-1 {
  margin: 0.1rem;
}

.m-0-2 {
  margin: 0.2rem;
}

.m-0-3 {
  margin: 0.3rem;
}

.m-0-4 {
  margin: 0.4rem;
}

.m-0-5 {
  margin: 0.5rem;
}

.m-0-6 {
  margin: 0.6rem;
}

.m-0-7 {
  margin: 0.7rem;
}

.m-0-8 {
  margin: 0.8rem;
}

.m-0-9 {
  margin: 0.9rem;
}

.m-1-0 {
  margin: 1rem;
}

.m-1-1 {
  margin: 1.1rem;
}

.m-1-2 {
  margin: 1.2rem;
}

.m-1-3 {
  margin: 1.3rem;
}

.m-1-4 {
  margin: 1.4rem;
}

.m-1-5 {
  margin: 1.5rem;
}

.m-1-6 {
  margin: 1.6rem;
}

.m-1-7 {
  margin: 1.7rem;
}

.m-1-8 {
  margin: 1.8rem;
}

.m-1-9 {
  margin: 1.9rem;
}

.m-2-0 {
  margin: 2rem;
}

.m-2-1 {
  margin: 2.1rem;
}

.m-2-2 {
  margin: 2.2rem;
}

.m-2-3 {
  margin: 2.3rem;
}

.m-2-4 {
  margin: 2.4rem;
}

.m-2-5 {
  margin: 2.5rem;
}

.m-2-6 {
  margin: 2.6rem;
}

.m-2-7 {
  margin: 2.7rem;
}

.m-2-8 {
  margin: 2.8rem;
}

.m-2-9 {
  margin: 2.9rem;
}

.m-3-0 {
  margin: 3rem;
}

.m-3-1 {
  margin: 3.1rem;
}

.m-3-2 {
  margin: 3.2rem;
}

.m-3-3 {
  margin: 3.3rem;
}

.m-3-4 {
  margin: 3.4rem;
}

.m-3-5 {
  margin: 3.5rem;
}

.m-3-6 {
  margin: 3.6rem;
}

.m-3-7 {
  margin: 3.7rem;
}

.m-3-8 {
  margin: 3.8rem;
}

.m-3-9 {
  margin: 3.9rem;
}

.m-4-0 {
  margin: 4rem;
}

.m-4-1 {
  margin: 4.1rem;
}

.m-4-2 {
  margin: 4.2rem;
}

.m-4-3 {
  margin: 4.3rem;
}

.m-4-4 {
  margin: 4.4rem;
}

.m-4-5 {
  margin: 4.5rem;
}

.m-4-6 {
  margin: 4.6rem;
}

.m-4-7 {
  margin: 4.7rem;
}

.m-4-8 {
  margin: 4.8rem;
}

.m-4-9 {
  margin: 4.9rem;
}

.m-5-0 {
  margin: 5rem;
}

.m-5-1 {
  margin: 5.1rem;
}

.m-5-2 {
  margin: 5.2rem;
}

.m-5-3 {
  margin: 5.3rem;
}

.m-5-4 {
  margin: 5.4rem;
}

.m-5-5 {
  margin: 5.5rem;
}

.m-5-6 {
  margin: 5.6rem;
}

.m-5-7 {
  margin: 5.7rem;
}

.m-5-8 {
  margin: 5.8rem;
}

.m-5-9 {
  margin: 5.9rem;
}

.m-6-0 {
  margin: 6rem;
}

.m-6-1 {
  margin: 6.1rem;
}

.m-6-2 {
  margin: 6.2rem;
}

.m-6-3 {
  margin: 6.3rem;
}

.m-6-4 {
  margin: 6.4rem;
}

.m-6-5 {
  margin: 6.5rem;
}

.m-6-6 {
  margin: 6.6rem;
}

.m-6-7 {
  margin: 6.7rem;
}

.m-6-8 {
  margin: 6.8rem;
}

.m-6-9 {
  margin: 6.9rem;
}

.m-7-0 {
  margin: 7rem;
}

.m-7-1 {
  margin: 7.1rem;
}

.m-7-2 {
  margin: 7.2rem;
}

.m-7-3 {
  margin: 7.3rem;
}

.m-7-4 {
  margin: 7.4rem;
}

.m-7-5 {
  margin: 7.5rem;
}

.m-7-6 {
  margin: 7.6rem;
}

.m-7-7 {
  margin: 7.7rem;
}

.m-7-8 {
  margin: 7.8rem;
}

.m-7-9 {
  margin: 7.9rem;
}

.m-8-0 {
  margin: 8rem;
}

.m-8-1 {
  margin: 8.1rem;
}

.m-8-2 {
  margin: 8.2rem;
}

.m-8-3 {
  margin: 8.3rem;
}

.m-8-4 {
  margin: 8.4rem;
}

.m-8-5 {
  margin: 8.5rem;
}

.m-8-6 {
  margin: 8.6rem;
}

.m-8-7 {
  margin: 8.7rem;
}

.m-8-8 {
  margin: 8.8rem;
}

.m-8-9 {
  margin: 8.9rem;
}

.m-9-0 {
  margin: 9rem;
}

.m-9-1 {
  margin: 9.1rem;
}

.m-9-2 {
  margin: 9.2rem;
}

.m-9-3 {
  margin: 9.3rem;
}

.m-9-4 {
  margin: 9.4rem;
}

.m-9-5 {
  margin: 9.5rem;
}

.m-9-6 {
  margin: 9.6rem;
}

.m-9-7 {
  margin: 9.7rem;
}

.m-9-8 {
  margin: 9.8rem;
}

.m-9-9 {
  margin: 9.9rem;
}

.m-10-0 {
  margin: 10rem;
}

.m-10-1 {
  margin: 10.1rem;
}

.m-10-2 {
  margin: 10.2rem;
}

.m-10-3 {
  margin: 10.3rem;
}

.m-10-4 {
  margin: 10.4rem;
}

.m-10-5 {
  margin: 10.5rem;
}

.m-10-6 {
  margin: 10.6rem;
}

.m-10-7 {
  margin: 10.7rem;
}

.m-10-8 {
  margin: 10.8rem;
}

.m-10-9 {
  margin: 10.9rem;
}

.m-11-0 {
  margin: 11rem;
}

.m-11-1 {
  margin: 11.1rem;
}

.m-11-2 {
  margin: 11.2rem;
}

.m-11-3 {
  margin: 11.3rem;
}

.m-11-4 {
  margin: 11.4rem;
}

.m-11-5 {
  margin: 11.5rem;
}

.m-11-6 {
  margin: 11.6rem;
}

.m-11-7 {
  margin: 11.7rem;
}

.m-11-8 {
  margin: 11.8rem;
}

.m-11-9 {
  margin: 11.9rem;
}

.m-12-0 {
  margin: 12rem;
}

.m-12-1 {
  margin: 12.1rem;
}

.m-12-2 {
  margin: 12.2rem;
}

.m-12-3 {
  margin: 12.3rem;
}

.m-12-4 {
  margin: 12.4rem;
}

.m-12-5 {
  margin: 12.5rem;
}

.m-12-6 {
  margin: 12.6rem;
}

.m-12-7 {
  margin: 12.7rem;
}

.m-12-8 {
  margin: 12.8rem;
}

.m-12-9 {
  margin: 12.9rem;
}

.m-13-0 {
  margin: 13rem;
}

.m-13-1 {
  margin: 13.1rem;
}

.m-13-2 {
  margin: 13.2rem;
}

.m-13-3 {
  margin: 13.3rem;
}

.m-13-4 {
  margin: 13.4rem;
}

.m-13-5 {
  margin: 13.5rem;
}

.m-13-6 {
  margin: 13.6rem;
}

.m-13-7 {
  margin: 13.7rem;
}

.m-13-8 {
  margin: 13.8rem;
}

.m-13-9 {
  margin: 13.9rem;
}

.m-14-0 {
  margin: 14rem;
}

.m-14-1 {
  margin: 14.1rem;
}

.m-14-2 {
  margin: 14.2rem;
}

.m-14-3 {
  margin: 14.3rem;
}

.m-14-4 {
  margin: 14.4rem;
}

.m-14-5 {
  margin: 14.5rem;
}

.m-14-6 {
  margin: 14.6rem;
}

.m-14-7 {
  margin: 14.7rem;
}

.m-14-8 {
  margin: 14.8rem;
}

.m-14-9 {
  margin: 14.9rem;
}

.m-15-0 {
  margin: 15rem;
}

.m-15-1 {
  margin: 15.1rem;
}

.m-15-2 {
  margin: 15.2rem;
}

.m-15-3 {
  margin: 15.3rem;
}

.m-15-4 {
  margin: 15.4rem;
}

.m-15-5 {
  margin: 15.5rem;
}

.m-15-6 {
  margin: 15.6rem;
}

.m-15-7 {
  margin: 15.7rem;
}

.m-15-8 {
  margin: 15.8rem;
}

.m-15-9 {
  margin: 15.9rem;
}

.m-16-0 {
  margin: 16rem;
}

.m-16-1 {
  margin: 16.1rem;
}

.m-16-2 {
  margin: 16.2rem;
}

.m-16-3 {
  margin: 16.3rem;
}

.m-16-4 {
  margin: 16.4rem;
}

.m-16-5 {
  margin: 16.5rem;
}

.m-16-6 {
  margin: 16.6rem;
}

.m-16-7 {
  margin: 16.7rem;
}

.m-16-8 {
  margin: 16.8rem;
}

.m-16-9 {
  margin: 16.9rem;
}

.m-17-0 {
  margin: 17rem;
}

.m-17-1 {
  margin: 17.1rem;
}

.m-17-2 {
  margin: 17.2rem;
}

.m-17-3 {
  margin: 17.3rem;
}

.m-17-4 {
  margin: 17.4rem;
}

.m-17-5 {
  margin: 17.5rem;
}

.m-17-6 {
  margin: 17.6rem;
}

.m-17-7 {
  margin: 17.7rem;
}

.m-17-8 {
  margin: 17.8rem;
}

.m-17-9 {
  margin: 17.9rem;
}

.m-18-0 {
  margin: 18rem;
}

.m-18-1 {
  margin: 18.1rem;
}

.m-18-2 {
  margin: 18.2rem;
}

.m-18-3 {
  margin: 18.3rem;
}

.m-18-4 {
  margin: 18.4rem;
}

.m-18-5 {
  margin: 18.5rem;
}

.m-18-6 {
  margin: 18.6rem;
}

.m-18-7 {
  margin: 18.7rem;
}

.m-18-8 {
  margin: 18.8rem;
}

.m-18-9 {
  margin: 18.9rem;
}

.m-19-0 {
  margin: 19rem;
}

.m-19-1 {
  margin: 19.1rem;
}

.m-19-2 {
  margin: 19.2rem;
}

.m-19-3 {
  margin: 19.3rem;
}

.m-19-4 {
  margin: 19.4rem;
}

.m-19-5 {
  margin: 19.5rem;
}

.m-19-6 {
  margin: 19.6rem;
}

.m-19-7 {
  margin: 19.7rem;
}

.m-19-8 {
  margin: 19.8rem;
}

.m-19-9 {
  margin: 19.9rem;
}

.m-20-0 {
  margin: 20rem;
}

.m-20-1 {
  margin: 20.1rem;
}

.m-20-2 {
  margin: 20.2rem;
}

.m-20-3 {
  margin: 20.3rem;
}

.m-20-4 {
  margin: 20.4rem;
}

.m-20-5 {
  margin: 20.5rem;
}

.m-20-6 {
  margin: 20.6rem;
}

.m-20-7 {
  margin: 20.7rem;
}

.m-20-8 {
  margin: 20.8rem;
}

.m-20-9 {
  margin: 20.9rem;
}

.m-21-0 {
  margin: 21rem;
}

.m-21-1 {
  margin: 21.1rem;
}

.m-21-2 {
  margin: 21.2rem;
}

.m-21-3 {
  margin: 21.3rem;
}

.m-21-4 {
  margin: 21.4rem;
}

.m-21-5 {
  margin: 21.5rem;
}

.m-21-6 {
  margin: 21.6rem;
}

.m-21-7 {
  margin: 21.7rem;
}

.m-21-8 {
  margin: 21.8rem;
}

.m-21-9 {
  margin: 21.9rem;
}

.m-22-0 {
  margin: 22rem;
}

.m-22-1 {
  margin: 22.1rem;
}

.m-22-2 {
  margin: 22.2rem;
}

.m-22-3 {
  margin: 22.3rem;
}

.m-22-4 {
  margin: 22.4rem;
}

.m-22-5 {
  margin: 22.5rem;
}

.m-22-6 {
  margin: 22.6rem;
}

.m-22-7 {
  margin: 22.7rem;
}

.m-22-8 {
  margin: 22.8rem;
}

.m-22-9 {
  margin: 22.9rem;
}

.m-23-0 {
  margin: 23rem;
}

.m-23-1 {
  margin: 23.1rem;
}

.m-23-2 {
  margin: 23.2rem;
}

.m-23-3 {
  margin: 23.3rem;
}

.m-23-4 {
  margin: 23.4rem;
}

.m-23-5 {
  margin: 23.5rem;
}

.m-23-6 {
  margin: 23.6rem;
}

.m-23-7 {
  margin: 23.7rem;
}

.m-23-8 {
  margin: 23.8rem;
}

.m-23-9 {
  margin: 23.9rem;
}

.m-24-0 {
  margin: 24rem;
}

.m-24-1 {
  margin: 24.1rem;
}

.m-24-2 {
  margin: 24.2rem;
}

.m-24-3 {
  margin: 24.3rem;
}

.m-24-4 {
  margin: 24.4rem;
}

.m-24-5 {
  margin: 24.5rem;
}

.m-24-6 {
  margin: 24.6rem;
}

.m-24-7 {
  margin: 24.7rem;
}

.m-24-8 {
  margin: 24.8rem;
}

.m-24-9 {
  margin: 24.9rem;
}

.m-25-0 {
  margin: 25rem;
}

.m-25-1 {
  margin: 25.1rem;
}

.m-25-2 {
  margin: 25.2rem;
}

.m-25-3 {
  margin: 25.3rem;
}

.m-25-4 {
  margin: 25.4rem;
}

.m-25-5 {
  margin: 25.5rem;
}

.m-25-6 {
  margin: 25.6rem;
}

.m-25-7 {
  margin: 25.7rem;
}

.m-25-8 {
  margin: 25.8rem;
}

.m-25-9 {
  margin: 25.9rem;
}

.m-26-0 {
  margin: 26rem;
}

.m-26-1 {
  margin: 26.1rem;
}

.m-26-2 {
  margin: 26.2rem;
}

.m-26-3 {
  margin: 26.3rem;
}

.m-26-4 {
  margin: 26.4rem;
}

.m-26-5 {
  margin: 26.5rem;
}

.m-26-6 {
  margin: 26.6rem;
}

.m-26-7 {
  margin: 26.7rem;
}

.m-26-8 {
  margin: 26.8rem;
}

.m-26-9 {
  margin: 26.9rem;
}

.m-27-0 {
  margin: 27rem;
}

.m-27-1 {
  margin: 27.1rem;
}

.m-27-2 {
  margin: 27.2rem;
}

.m-27-3 {
  margin: 27.3rem;
}

.m-27-4 {
  margin: 27.4rem;
}

.m-27-5 {
  margin: 27.5rem;
}

.m-27-6 {
  margin: 27.6rem;
}

.m-27-7 {
  margin: 27.7rem;
}

.m-27-8 {
  margin: 27.8rem;
}

.m-27-9 {
  margin: 27.9rem;
}

.m-28-0 {
  margin: 28rem;
}

.m-28-1 {
  margin: 28.1rem;
}

.m-28-2 {
  margin: 28.2rem;
}

.m-28-3 {
  margin: 28.3rem;
}

.m-28-4 {
  margin: 28.4rem;
}

.m-28-5 {
  margin: 28.5rem;
}

.m-28-6 {
  margin: 28.6rem;
}

.m-28-7 {
  margin: 28.7rem;
}

.m-28-8 {
  margin: 28.8rem;
}

.m-28-9 {
  margin: 28.9rem;
}

.m-29-0 {
  margin: 29rem;
}

.m-29-1 {
  margin: 29.1rem;
}

.m-29-2 {
  margin: 29.2rem;
}

.m-29-3 {
  margin: 29.3rem;
}

.m-29-4 {
  margin: 29.4rem;
}

.m-29-5 {
  margin: 29.5rem;
}

.m-29-6 {
  margin: 29.6rem;
}

.m-29-7 {
  margin: 29.7rem;
}

.m-29-8 {
  margin: 29.8rem;
}

.m-29-9 {
  margin: 29.9rem;
}

.m-30-0 {
  margin: 30rem;
}

.m-30-1 {
  margin: 30.1rem;
}

.m-30-2 {
  margin: 30.2rem;
}

.m-30-3 {
  margin: 30.3rem;
}

.m-30-4 {
  margin: 30.4rem;
}

.m-30-5 {
  margin: 30.5rem;
}

.m-30-6 {
  margin: 30.6rem;
}

.m-30-7 {
  margin: 30.7rem;
}

.m-30-8 {
  margin: 30.8rem;
}

.m-30-9 {
  margin: 30.9rem;
}

.m-31-0 {
  margin: 31rem;
}

.m-31-1 {
  margin: 31.1rem;
}

.m-31-2 {
  margin: 31.2rem;
}

.m-31-3 {
  margin: 31.3rem;
}

.m-31-4 {
  margin: 31.4rem;
}

.m-31-5 {
  margin: 31.5rem;
}

.m-31-6 {
  margin: 31.6rem;
}

.m-31-7 {
  margin: 31.7rem;
}

.m-31-8 {
  margin: 31.8rem;
}

.m-31-9 {
  margin: 31.9rem;
}

.m-32-0 {
  margin: 32rem;
}

.m-32-1 {
  margin: 32.1rem;
}

.m-32-2 {
  margin: 32.2rem;
}

.m-32-3 {
  margin: 32.3rem;
}

.m-32-4 {
  margin: 32.4rem;
}

.m-32-5 {
  margin: 32.5rem;
}

.m-32-6 {
  margin: 32.6rem;
}

.m-32-7 {
  margin: 32.7rem;
}

.m-32-8 {
  margin: 32.8rem;
}

.m-32-9 {
  margin: 32.9rem;
}

.m-33-0 {
  margin: 33rem;
}

.m-33-1 {
  margin: 33.1rem;
}

.m-33-2 {
  margin: 33.2rem;
}

.m-33-3 {
  margin: 33.3rem;
}

.m-33-4 {
  margin: 33.4rem;
}

.m-33-5 {
  margin: 33.5rem;
}

.m-33-6 {
  margin: 33.6rem;
}

.m-33-7 {
  margin: 33.7rem;
}

.m-33-8 {
  margin: 33.8rem;
}

.m-33-9 {
  margin: 33.9rem;
}

.m-34-0 {
  margin: 34rem;
}

.m-34-1 {
  margin: 34.1rem;
}

.m-34-2 {
  margin: 34.2rem;
}

.m-34-3 {
  margin: 34.3rem;
}

.m-34-4 {
  margin: 34.4rem;
}

.m-34-5 {
  margin: 34.5rem;
}

.m-34-6 {
  margin: 34.6rem;
}

.m-34-7 {
  margin: 34.7rem;
}

.m-34-8 {
  margin: 34.8rem;
}

.m-34-9 {
  margin: 34.9rem;
}

.m-35-0 {
  margin: 35rem;
}

.m-35-1 {
  margin: 35.1rem;
}

.m-35-2 {
  margin: 35.2rem;
}

.m-35-3 {
  margin: 35.3rem;
}

.m-35-4 {
  margin: 35.4rem;
}

.m-35-5 {
  margin: 35.5rem;
}

.m-35-6 {
  margin: 35.6rem;
}

.m-35-7 {
  margin: 35.7rem;
}

.m-35-8 {
  margin: 35.8rem;
}

.m-35-9 {
  margin: 35.9rem;
}

.m-36-0 {
  margin: 36rem;
}

.m-36-1 {
  margin: 36.1rem;
}

.m-36-2 {
  margin: 36.2rem;
}

.m-36-3 {
  margin: 36.3rem;
}

.m-36-4 {
  margin: 36.4rem;
}

.m-36-5 {
  margin: 36.5rem;
}

.m-36-6 {
  margin: 36.6rem;
}

.m-36-7 {
  margin: 36.7rem;
}

.m-36-8 {
  margin: 36.8rem;
}

.m-36-9 {
  margin: 36.9rem;
}

.m-37-0 {
  margin: 37rem;
}

.m-37-1 {
  margin: 37.1rem;
}

.m-37-2 {
  margin: 37.2rem;
}

.m-37-3 {
  margin: 37.3rem;
}

.m-37-4 {
  margin: 37.4rem;
}

.m-37-5 {
  margin: 37.5rem;
}

.m-37-6 {
  margin: 37.6rem;
}

.m-37-7 {
  margin: 37.7rem;
}

.m-37-8 {
  margin: 37.8rem;
}

.m-37-9 {
  margin: 37.9rem;
}

.m-38-0 {
  margin: 38rem;
}

.m-38-1 {
  margin: 38.1rem;
}

.m-38-2 {
  margin: 38.2rem;
}

.m-38-3 {
  margin: 38.3rem;
}

.m-38-4 {
  margin: 38.4rem;
}

.m-38-5 {
  margin: 38.5rem;
}

.m-38-6 {
  margin: 38.6rem;
}

.m-38-7 {
  margin: 38.7rem;
}

.m-38-8 {
  margin: 38.8rem;
}

.m-38-9 {
  margin: 38.9rem;
}

.m-39-0 {
  margin: 39rem;
}

.m-39-1 {
  margin: 39.1rem;
}

.m-39-2 {
  margin: 39.2rem;
}

.m-39-3 {
  margin: 39.3rem;
}

.m-39-4 {
  margin: 39.4rem;
}

.m-39-5 {
  margin: 39.5rem;
}

.m-39-6 {
  margin: 39.6rem;
}

.m-39-7 {
  margin: 39.7rem;
}

.m-39-8 {
  margin: 39.8rem;
}

.m-39-9 {
  margin: 39.9rem;
}

.m-40-0 {
  margin: 40rem;
}

.m-40-1 {
  margin: 40.1rem;
}

.m-40-2 {
  margin: 40.2rem;
}

.m-40-3 {
  margin: 40.3rem;
}

.m-40-4 {
  margin: 40.4rem;
}

.m-40-5 {
  margin: 40.5rem;
}

.m-40-6 {
  margin: 40.6rem;
}

.m-40-7 {
  margin: 40.7rem;
}

.m-40-8 {
  margin: 40.8rem;
}

.m-40-9 {
  margin: 40.9rem;
}

.m-41-0 {
  margin: 41rem;
}

.m-41-1 {
  margin: 41.1rem;
}

.m-41-2 {
  margin: 41.2rem;
}

.m-41-3 {
  margin: 41.3rem;
}

.m-41-4 {
  margin: 41.4rem;
}

.m-41-5 {
  margin: 41.5rem;
}

.m-41-6 {
  margin: 41.6rem;
}

.m-41-7 {
  margin: 41.7rem;
}

.m-41-8 {
  margin: 41.8rem;
}

.m-41-9 {
  margin: 41.9rem;
}

.m-42-0 {
  margin: 42rem;
}

.m-42-1 {
  margin: 42.1rem;
}

.m-42-2 {
  margin: 42.2rem;
}

.m-42-3 {
  margin: 42.3rem;
}

.m-42-4 {
  margin: 42.4rem;
}

.m-42-5 {
  margin: 42.5rem;
}

.m-42-6 {
  margin: 42.6rem;
}

.m-42-7 {
  margin: 42.7rem;
}

.m-42-8 {
  margin: 42.8rem;
}

.m-42-9 {
  margin: 42.9rem;
}

.m-43-0 {
  margin: 43rem;
}

.m-43-1 {
  margin: 43.1rem;
}

.m-43-2 {
  margin: 43.2rem;
}

.m-43-3 {
  margin: 43.3rem;
}

.m-43-4 {
  margin: 43.4rem;
}

.m-43-5 {
  margin: 43.5rem;
}

.m-43-6 {
  margin: 43.6rem;
}

.m-43-7 {
  margin: 43.7rem;
}

.m-43-8 {
  margin: 43.8rem;
}

.m-43-9 {
  margin: 43.9rem;
}

.m-44-0 {
  margin: 44rem;
}

.m-44-1 {
  margin: 44.1rem;
}

.m-44-2 {
  margin: 44.2rem;
}

.m-44-3 {
  margin: 44.3rem;
}

.m-44-4 {
  margin: 44.4rem;
}

.m-44-5 {
  margin: 44.5rem;
}

.m-44-6 {
  margin: 44.6rem;
}

.m-44-7 {
  margin: 44.7rem;
}

.m-44-8 {
  margin: 44.8rem;
}

.m-44-9 {
  margin: 44.9rem;
}

.m-45-0 {
  margin: 45rem;
}

.m-45-1 {
  margin: 45.1rem;
}

.m-45-2 {
  margin: 45.2rem;
}

.m-45-3 {
  margin: 45.3rem;
}

.m-45-4 {
  margin: 45.4rem;
}

.m-45-5 {
  margin: 45.5rem;
}

.m-45-6 {
  margin: 45.6rem;
}

.m-45-7 {
  margin: 45.7rem;
}

.m-45-8 {
  margin: 45.8rem;
}

.m-45-9 {
  margin: 45.9rem;
}

.m-46-0 {
  margin: 46rem;
}

.m-46-1 {
  margin: 46.1rem;
}

.m-46-2 {
  margin: 46.2rem;
}

.m-46-3 {
  margin: 46.3rem;
}

.m-46-4 {
  margin: 46.4rem;
}

.m-46-5 {
  margin: 46.5rem;
}

.m-46-6 {
  margin: 46.6rem;
}

.m-46-7 {
  margin: 46.7rem;
}

.m-46-8 {
  margin: 46.8rem;
}

.m-46-9 {
  margin: 46.9rem;
}

.m-47-0 {
  margin: 47rem;
}

.m-47-1 {
  margin: 47.1rem;
}

.m-47-2 {
  margin: 47.2rem;
}

.m-47-3 {
  margin: 47.3rem;
}

.m-47-4 {
  margin: 47.4rem;
}

.m-47-5 {
  margin: 47.5rem;
}

.m-47-6 {
  margin: 47.6rem;
}

.m-47-7 {
  margin: 47.7rem;
}

.m-47-8 {
  margin: 47.8rem;
}

.m-47-9 {
  margin: 47.9rem;
}

.m-48-0 {
  margin: 48rem;
}

.m-48-1 {
  margin: 48.1rem;
}

.m-48-2 {
  margin: 48.2rem;
}

.m-48-3 {
  margin: 48.3rem;
}

.m-48-4 {
  margin: 48.4rem;
}

.m-48-5 {
  margin: 48.5rem;
}

.m-48-6 {
  margin: 48.6rem;
}

.m-48-7 {
  margin: 48.7rem;
}

.m-48-8 {
  margin: 48.8rem;
}

.m-48-9 {
  margin: 48.9rem;
}

.m-49-0 {
  margin: 49rem;
}

.m-49-1 {
  margin: 49.1rem;
}

.m-49-2 {
  margin: 49.2rem;
}

.m-49-3 {
  margin: 49.3rem;
}

.m-49-4 {
  margin: 49.4rem;
}

.m-49-5 {
  margin: 49.5rem;
}

.m-49-6 {
  margin: 49.6rem;
}

.m-49-7 {
  margin: 49.7rem;
}

.m-49-8 {
  margin: 49.8rem;
}

.m-49-9 {
  margin: 49.9rem;
}

.m-50-0 {
  margin: 50rem;
}

.m-50-1 {
  margin: 50.1rem;
}

.m-50-2 {
  margin: 50.2rem;
}

.m-50-3 {
  margin: 50.3rem;
}

.m-50-4 {
  margin: 50.4rem;
}

.m-50-5 {
  margin: 50.5rem;
}

.m-50-6 {
  margin: 50.6rem;
}

.m-50-7 {
  margin: 50.7rem;
}

.m-50-8 {
  margin: 50.8rem;
}

.m-50-9 {
  margin: 50.9rem;
}

.m-51-0 {
  margin: 51rem;
}

.m-51-1 {
  margin: 51.1rem;
}

.m-51-2 {
  margin: 51.2rem;
}

.m-51-3 {
  margin: 51.3rem;
}

.m-51-4 {
  margin: 51.4rem;
}

.m-51-5 {
  margin: 51.5rem;
}

.m-51-6 {
  margin: 51.6rem;
}

.m-51-7 {
  margin: 51.7rem;
}

.m-51-8 {
  margin: 51.8rem;
}

.m-51-9 {
  margin: 51.9rem;
}

.m-52-0 {
  margin: 52rem;
}

.m-52-1 {
  margin: 52.1rem;
}

.m-52-2 {
  margin: 52.2rem;
}

.m-52-3 {
  margin: 52.3rem;
}

.m-52-4 {
  margin: 52.4rem;
}

.m-52-5 {
  margin: 52.5rem;
}

.m-52-6 {
  margin: 52.6rem;
}

.m-52-7 {
  margin: 52.7rem;
}

.m-52-8 {
  margin: 52.8rem;
}

.m-52-9 {
  margin: 52.9rem;
}

.m-53-0 {
  margin: 53rem;
}

.m-53-1 {
  margin: 53.1rem;
}

.m-53-2 {
  margin: 53.2rem;
}

.m-53-3 {
  margin: 53.3rem;
}

.m-53-4 {
  margin: 53.4rem;
}

.m-53-5 {
  margin: 53.5rem;
}

.m-53-6 {
  margin: 53.6rem;
}

.m-53-7 {
  margin: 53.7rem;
}

.m-53-8 {
  margin: 53.8rem;
}

.m-53-9 {
  margin: 53.9rem;
}

.m-54-0 {
  margin: 54rem;
}

.m-54-1 {
  margin: 54.1rem;
}

.m-54-2 {
  margin: 54.2rem;
}

.m-54-3 {
  margin: 54.3rem;
}

.m-54-4 {
  margin: 54.4rem;
}

.m-54-5 {
  margin: 54.5rem;
}

.m-54-6 {
  margin: 54.6rem;
}

.m-54-7 {
  margin: 54.7rem;
}

.m-54-8 {
  margin: 54.8rem;
}

.m-54-9 {
  margin: 54.9rem;
}

.m-55-0 {
  margin: 55rem;
}

.m-55-1 {
  margin: 55.1rem;
}

.m-55-2 {
  margin: 55.2rem;
}

.m-55-3 {
  margin: 55.3rem;
}

.m-55-4 {
  margin: 55.4rem;
}

.m-55-5 {
  margin: 55.5rem;
}

.m-55-6 {
  margin: 55.6rem;
}

.m-55-7 {
  margin: 55.7rem;
}

.m-55-8 {
  margin: 55.8rem;
}

.m-55-9 {
  margin: 55.9rem;
}

.m-56-0 {
  margin: 56rem;
}

.m-56-1 {
  margin: 56.1rem;
}

.m-56-2 {
  margin: 56.2rem;
}

.m-56-3 {
  margin: 56.3rem;
}

.m-56-4 {
  margin: 56.4rem;
}

.m-56-5 {
  margin: 56.5rem;
}

.m-56-6 {
  margin: 56.6rem;
}

.m-56-7 {
  margin: 56.7rem;
}

.m-56-8 {
  margin: 56.8rem;
}

.m-56-9 {
  margin: 56.9rem;
}

.m-57-0 {
  margin: 57rem;
}

.m-57-1 {
  margin: 57.1rem;
}

.m-57-2 {
  margin: 57.2rem;
}

.m-57-3 {
  margin: 57.3rem;
}

.m-57-4 {
  margin: 57.4rem;
}

.m-57-5 {
  margin: 57.5rem;
}

.m-57-6 {
  margin: 57.6rem;
}

.m-57-7 {
  margin: 57.7rem;
}

.m-57-8 {
  margin: 57.8rem;
}

.m-57-9 {
  margin: 57.9rem;
}

.m-58-0 {
  margin: 58rem;
}

.m-58-1 {
  margin: 58.1rem;
}

.m-58-2 {
  margin: 58.2rem;
}

.m-58-3 {
  margin: 58.3rem;
}

.m-58-4 {
  margin: 58.4rem;
}

.m-58-5 {
  margin: 58.5rem;
}

.m-58-6 {
  margin: 58.6rem;
}

.m-58-7 {
  margin: 58.7rem;
}

.m-58-8 {
  margin: 58.8rem;
}

.m-58-9 {
  margin: 58.9rem;
}

.m-59-0 {
  margin: 59rem;
}

.m-59-1 {
  margin: 59.1rem;
}

.m-59-2 {
  margin: 59.2rem;
}

.m-59-3 {
  margin: 59.3rem;
}

.m-59-4 {
  margin: 59.4rem;
}

.m-59-5 {
  margin: 59.5rem;
}

.m-59-6 {
  margin: 59.6rem;
}

.m-59-7 {
  margin: 59.7rem;
}

.m-59-8 {
  margin: 59.8rem;
}

.m-59-9 {
  margin: 59.9rem;
}

.m-60-0 {
  margin: 60rem;
}

.m-60-1 {
  margin: 60.1rem;
}

.m-60-2 {
  margin: 60.2rem;
}

.m-60-3 {
  margin: 60.3rem;
}

.m-60-4 {
  margin: 60.4rem;
}

.m-60-5 {
  margin: 60.5rem;
}

.m-60-6 {
  margin: 60.6rem;
}

.m-60-7 {
  margin: 60.7rem;
}

.m-60-8 {
  margin: 60.8rem;
}

.m-60-9 {
  margin: 60.9rem;
}

.m-61-0 {
  margin: 61rem;
}

.m-61-1 {
  margin: 61.1rem;
}

.m-61-2 {
  margin: 61.2rem;
}

.m-61-3 {
  margin: 61.3rem;
}

.m-61-4 {
  margin: 61.4rem;
}

.m-61-5 {
  margin: 61.5rem;
}

.m-61-6 {
  margin: 61.6rem;
}

.m-61-7 {
  margin: 61.7rem;
}

.m-61-8 {
  margin: 61.8rem;
}

.m-61-9 {
  margin: 61.9rem;
}

.m-62-0 {
  margin: 62rem;
}

.m-62-1 {
  margin: 62.1rem;
}

.m-62-2 {
  margin: 62.2rem;
}

.m-62-3 {
  margin: 62.3rem;
}

.m-62-4 {
  margin: 62.4rem;
}

.m-62-5 {
  margin: 62.5rem;
}

.m-62-6 {
  margin: 62.6rem;
}

.m-62-7 {
  margin: 62.7rem;
}

.m-62-8 {
  margin: 62.8rem;
}

.m-62-9 {
  margin: 62.9rem;
}

.m-63-0 {
  margin: 63rem;
}

.m-63-1 {
  margin: 63.1rem;
}

.m-63-2 {
  margin: 63.2rem;
}

.m-63-3 {
  margin: 63.3rem;
}

.m-63-4 {
  margin: 63.4rem;
}

.m-63-5 {
  margin: 63.5rem;
}

.m-63-6 {
  margin: 63.6rem;
}

.m-63-7 {
  margin: 63.7rem;
}

.m-63-8 {
  margin: 63.8rem;
}

.m-63-9 {
  margin: 63.9rem;
}

.m-64-0 {
  margin: 64rem;
}

.m-64-1 {
  margin: 64.1rem;
}

.m-64-2 {
  margin: 64.2rem;
}

.m-64-3 {
  margin: 64.3rem;
}

.m-64-4 {
  margin: 64.4rem;
}

.m-64-5 {
  margin: 64.5rem;
}

.m-64-6 {
  margin: 64.6rem;
}

.m-64-7 {
  margin: 64.7rem;
}

.m-64-8 {
  margin: 64.8rem;
}

.m-64-9 {
  margin: 64.9rem;
}

.m-65-0 {
  margin: 65rem;
}

.m-65-1 {
  margin: 65.1rem;
}

.m-65-2 {
  margin: 65.2rem;
}

.m-65-3 {
  margin: 65.3rem;
}

.m-65-4 {
  margin: 65.4rem;
}

.m-65-5 {
  margin: 65.5rem;
}

.m-65-6 {
  margin: 65.6rem;
}

.m-65-7 {
  margin: 65.7rem;
}

.m-65-8 {
  margin: 65.8rem;
}

.m-65-9 {
  margin: 65.9rem;
}

.m-66-0 {
  margin: 66rem;
}

.m-66-1 {
  margin: 66.1rem;
}

.m-66-2 {
  margin: 66.2rem;
}

.m-66-3 {
  margin: 66.3rem;
}

.m-66-4 {
  margin: 66.4rem;
}

.m-66-5 {
  margin: 66.5rem;
}

.m-66-6 {
  margin: 66.6rem;
}

.m-66-7 {
  margin: 66.7rem;
}

.m-66-8 {
  margin: 66.8rem;
}

.m-66-9 {
  margin: 66.9rem;
}

.m-67-0 {
  margin: 67rem;
}

.m-67-1 {
  margin: 67.1rem;
}

.m-67-2 {
  margin: 67.2rem;
}

.m-67-3 {
  margin: 67.3rem;
}

.m-67-4 {
  margin: 67.4rem;
}

.m-67-5 {
  margin: 67.5rem;
}

.m-67-6 {
  margin: 67.6rem;
}

.m-67-7 {
  margin: 67.7rem;
}

.m-67-8 {
  margin: 67.8rem;
}

.m-67-9 {
  margin: 67.9rem;
}

.m-68-0 {
  margin: 68rem;
}

.m-68-1 {
  margin: 68.1rem;
}

.m-68-2 {
  margin: 68.2rem;
}

.m-68-3 {
  margin: 68.3rem;
}

.m-68-4 {
  margin: 68.4rem;
}

.m-68-5 {
  margin: 68.5rem;
}

.m-68-6 {
  margin: 68.6rem;
}

.m-68-7 {
  margin: 68.7rem;
}

.m-68-8 {
  margin: 68.8rem;
}

.m-68-9 {
  margin: 68.9rem;
}

.m-69-0 {
  margin: 69rem;
}

.m-69-1 {
  margin: 69.1rem;
}

.m-69-2 {
  margin: 69.2rem;
}

.m-69-3 {
  margin: 69.3rem;
}

.m-69-4 {
  margin: 69.4rem;
}

.m-69-5 {
  margin: 69.5rem;
}

.m-69-6 {
  margin: 69.6rem;
}

.m-69-7 {
  margin: 69.7rem;
}

.m-69-8 {
  margin: 69.8rem;
}

.m-69-9 {
  margin: 69.9rem;
}

.m-70-0 {
  margin: 70rem;
}

.m-70-1 {
  margin: 70.1rem;
}

.m-70-2 {
  margin: 70.2rem;
}

.m-70-3 {
  margin: 70.3rem;
}

.m-70-4 {
  margin: 70.4rem;
}

.m-70-5 {
  margin: 70.5rem;
}

.m-70-6 {
  margin: 70.6rem;
}

.m-70-7 {
  margin: 70.7rem;
}

.m-70-8 {
  margin: 70.8rem;
}

.m-70-9 {
  margin: 70.9rem;
}

.m-71-0 {
  margin: 71rem;
}

.m-71-1 {
  margin: 71.1rem;
}

.m-71-2 {
  margin: 71.2rem;
}

.m-71-3 {
  margin: 71.3rem;
}

.m-71-4 {
  margin: 71.4rem;
}

.m-71-5 {
  margin: 71.5rem;
}

.m-71-6 {
  margin: 71.6rem;
}

.m-71-7 {
  margin: 71.7rem;
}

.m-71-8 {
  margin: 71.8rem;
}

.m-71-9 {
  margin: 71.9rem;
}

.m-72-0 {
  margin: 72rem;
}

.m-72-1 {
  margin: 72.1rem;
}

.m-72-2 {
  margin: 72.2rem;
}

.m-72-3 {
  margin: 72.3rem;
}

.m-72-4 {
  margin: 72.4rem;
}

.m-72-5 {
  margin: 72.5rem;
}

.m-72-6 {
  margin: 72.6rem;
}

.m-72-7 {
  margin: 72.7rem;
}

.m-72-8 {
  margin: 72.8rem;
}

.m-72-9 {
  margin: 72.9rem;
}

/* 
   - This section contains general utilities defined at the $utilities variable
   - includes: padding, margin, text, display, borders & radius, alignments and others.
   - The sizing is implemented using rem but with px accuracy.
   - i.e if you want to give a padding of 30px, you can use p-30 which is 30px but converted to rem on background

*/
.o-10 {
  opacity: 0.1;
}

.o-20 {
  opacity: 0.2;
}

.o-30 {
  opacity: 0.3;
}

.o-40 {
  opacity: 0.4;
}

.o-50 {
  opacity: 0.5;
}

.o-60 {
  opacity: 0.6;
}

.o-70 {
  opacity: 0.7;
}

.o-80 {
  opacity: 0.8;
}

.o-90 {
  opacity: 0.9;
}

.o-100 {
  opacity: 1;
}

.br {
  border-radius: 0.8rem;
}

.br-none {
  border-radius: 0;
}

.br-xs {
  border-radius: 0.2rem;
}

.br-sm {
  border-radius: 0.4rem;
}

.br-lg {
  border-radius: 1.6rem;
}

.br-full {
  border-radius: 50%;
}

.align-f-start {
  align-items: flex-start;
}

.align-f-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-start {
  justify-content: flex-start;
}

.justify-stretch {
  justify-content: stretch;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-dashed {
  -webkit-text-decoration: dashed;
  text-decoration: dashed;
}

.text-underline {
  text-decoration: underline;
}

.text-wavy {
  -webkit-text-decoration: wavy;
  text-decoration: wavy;
}

.text-dotted {
  -webkit-text-decoration: dotted;
  text-decoration: dotted;
}

.text-double {
  -webkit-text-decoration: double;
  text-decoration: double;
}

.text-line-through {
  text-decoration: line-through;
}

.text-solid {
  -webkit-text-decoration: solid;
  text-decoration: solid;
}

.text-none {
  text-decoration: none;
}

.font-b {
  font-weight: bold;
}

.font-i {
  font-weight: italic;
}

.font-n {
  font-weight: normal;
}

.font-l {
  font-weight: lighter;
}

.font-bolder {
  font-weight: bolder;
}

.text-dxxl {
  font-size: 7.2rem;
}

.text-dxl {
  font-size: 6rem;
}

.text-dlg {
  font-size: 4.8rem;
}

.text-dmd {
  font-size: 3.6rem;
}

.text-dsm {
  font-size: 3.2rem;
}

.text-dxs {
  font-size: 2.4rem;
}

.text-xxl {
  font-size: 2.4rem;
}

.text-xl {
  font-size: 2rem;
}

.text-lg {
  font-size: 1.8rem;
}

.text-md {
  font-size: 1.6rem;
}

.text-sm {
  font-size: 1.4rem;
}

.text-xs {
  font-size: 1.2rem;
}

.text-macro {
  font-size: 1rem;
}

.gap-0 {
  gap: 0rem;
}

.gap-1 {
  gap: 0.1rem;
}

.gap-2 {
  gap: 0.2rem;
}

.gap-3 {
  gap: 0.3rem;
}

.gap-4 {
  gap: 0.4rem;
}

.gap-5 {
  gap: 0.5rem;
}

.gap-6 {
  gap: 0.6rem;
}

.gap-7 {
  gap: 0.7rem;
}

.gap-8 {
  gap: 0.8rem;
}

.gap-9 {
  gap: 0.9rem;
}

.gap-10 {
  gap: 1rem;
}

.gap-11 {
  gap: 1.1rem;
}

.gap-12 {
  gap: 1.2rem;
}

.gap-13 {
  gap: 1.3rem;
}

.gap-14 {
  gap: 1.4rem;
}

.gap-15 {
  gap: 1.5rem;
}

.gap-16 {
  gap: 1.6rem;
}

.gap-17 {
  gap: 1.7rem;
}

.gap-18 {
  gap: 1.8rem;
}

.gap-19 {
  gap: 1.9rem;
}

.gap-20 {
  gap: 2rem;
}

.gap-21 {
  gap: 2.1rem;
}

.gap-22 {
  gap: 2.2rem;
}

.gap-23 {
  gap: 2.3rem;
}

.gap-24 {
  gap: 2.4rem;
}

.gap-25 {
  gap: 2.5rem;
}

.gap-26 {
  gap: 2.6rem;
}

.gap-27 {
  gap: 2.7rem;
}

.gap-28 {
  gap: 2.8rem;
}

.gap-29 {
  gap: 2.9rem;
}

.gap-30 {
  gap: 3rem;
}

.gap-31 {
  gap: 3.1rem;
}

.gap-32 {
  gap: 3.2rem;
}

.gap-33 {
  gap: 3.3rem;
}

.gap-34 {
  gap: 3.4rem;
}

.gap-35 {
  gap: 3.5rem;
}

.gap-36 {
  gap: 3.6rem;
}

.gap-37 {
  gap: 3.7rem;
}

.gap-38 {
  gap: 3.8rem;
}

.gap-39 {
  gap: 3.9rem;
}

.gap-40 {
  gap: 4rem;
}

.gap-41 {
  gap: 4.1rem;
}

.gap-42 {
  gap: 4.2rem;
}

.gap-43 {
  gap: 4.3rem;
}

.gap-44 {
  gap: 4.4rem;
}

.gap-45 {
  gap: 4.5rem;
}

.gap-46 {
  gap: 4.6rem;
}

.gap-47 {
  gap: 4.7rem;
}

.gap-48 {
  gap: 4.8rem;
}

.gap-49 {
  gap: 4.9rem;
}

.center {
  justify-content: center;
  align-items: center;
}

.w-0 {
  width: 0rem;
}

.w-1 {
  width: 0.1rem;
}

.w-2 {
  width: 0.2rem;
}

.w-3 {
  width: 0.3rem;
}

.w-4 {
  width: 0.4rem;
}

.w-5 {
  width: 0.5rem;
}

.w-6 {
  width: 0.6rem;
}

.w-7 {
  width: 0.7rem;
}

.w-8 {
  width: 0.8rem;
}

.w-9 {
  width: 0.9rem;
}

.w-10 {
  width: 1rem;
}

.w-11 {
  width: 1.1rem;
}

.w-12 {
  width: 1.2rem;
}

.w-13 {
  width: 1.3rem;
}

.w-14 {
  width: 1.4rem;
}

.w-15 {
  width: 1.5rem;
}

.w-16 {
  width: 1.6rem;
}

.w-17 {
  width: 1.7rem;
}

.w-18 {
  width: 1.8rem;
}

.w-19 {
  width: 1.9rem;
}

.w-20 {
  width: 2rem;
}

.w-21 {
  width: 2.1rem;
}

.w-22 {
  width: 2.2rem;
}

.w-23 {
  width: 2.3rem;
}

.w-24 {
  width: 2.4rem;
}

.w-25 {
  width: 2.5rem;
}

.w-26 {
  width: 2.6rem;
}

.w-27 {
  width: 2.7rem;
}

.w-28 {
  width: 2.8rem;
}

.w-29 {
  width: 2.9rem;
}

.w-30 {
  width: 3rem;
}

.w-31 {
  width: 3.1rem;
}

.w-32 {
  width: 3.2rem;
}

.w-33 {
  width: 3.3rem;
}

.w-34 {
  width: 3.4rem;
}

.w-35 {
  width: 3.5rem;
}

.w-36 {
  width: 3.6rem;
}

.w-37 {
  width: 3.7rem;
}

.w-38 {
  width: 3.8rem;
}

.w-39 {
  width: 3.9rem;
}

.w-40 {
  width: 4rem;
}

.w-41 {
  width: 4.1rem;
}

.w-42 {
  width: 4.2rem;
}

.w-43 {
  width: 4.3rem;
}

.w-44 {
  width: 4.4rem;
}

.w-45 {
  width: 4.5rem;
}

.w-46 {
  width: 4.6rem;
}

.w-47 {
  width: 4.7rem;
}

.w-48 {
  width: 4.8rem;
}

.w-49 {
  width: 4.9rem;
}

.w-50 {
  width: 5rem;
}

.w-51 {
  width: 5.1rem;
}

.w-52 {
  width: 5.2rem;
}

.w-53 {
  width: 5.3rem;
}

.w-54 {
  width: 5.4rem;
}

.w-55 {
  width: 5.5rem;
}

.w-56 {
  width: 5.6rem;
}

.w-57 {
  width: 5.7rem;
}

.w-58 {
  width: 5.8rem;
}

.w-59 {
  width: 5.9rem;
}

.w-60 {
  width: 6rem;
}

.w-61 {
  width: 6.1rem;
}

.w-62 {
  width: 6.2rem;
}

.w-63 {
  width: 6.3rem;
}

.w-64 {
  width: 6.4rem;
}

.w-65 {
  width: 6.5rem;
}

.w-66 {
  width: 6.6rem;
}

.w-67 {
  width: 6.7rem;
}

.w-68 {
  width: 6.8rem;
}

.w-69 {
  width: 6.9rem;
}

.w-70 {
  width: 7rem;
}

.w-71 {
  width: 7.1rem;
}

.w-72 {
  width: 7.2rem;
}

.w-73 {
  width: 7.3rem;
}

.w-74 {
  width: 7.4rem;
}

.w-75 {
  width: 7.5rem;
}

.w-76 {
  width: 7.6rem;
}

.w-77 {
  width: 7.7rem;
}

.w-78 {
  width: 7.8rem;
}

.w-79 {
  width: 7.9rem;
}

.w-80 {
  width: 8rem;
}

.w-81 {
  width: 8.1rem;
}

.w-82 {
  width: 8.2rem;
}

.w-83 {
  width: 8.3rem;
}

.w-84 {
  width: 8.4rem;
}

.w-85 {
  width: 8.5rem;
}

.w-86 {
  width: 8.6rem;
}

.w-87 {
  width: 8.7rem;
}

.w-88 {
  width: 8.8rem;
}

.w-89 {
  width: 8.9rem;
}

.w-90 {
  width: 9rem;
}

.w-91 {
  width: 9.1rem;
}

.w-92 {
  width: 9.2rem;
}

.w-93 {
  width: 9.3rem;
}

.w-94 {
  width: 9.4rem;
}

.w-95 {
  width: 9.5rem;
}

.w-96 {
  width: 9.6rem;
}

.w-97 {
  width: 9.7rem;
}

.w-98 {
  width: 9.8rem;
}

.w-99 {
  width: 9.9rem;
}

.w-100 {
  width: 10rem;
}

.w-101 {
  width: 10.1rem;
}

.w-102 {
  width: 10.2rem;
}

.w-103 {
  width: 10.3rem;
}

.w-104 {
  width: 10.4rem;
}

.w-105 {
  width: 10.5rem;
}

.w-106 {
  width: 10.6rem;
}

.w-107 {
  width: 10.7rem;
}

.w-108 {
  width: 10.8rem;
}

.w-109 {
  width: 10.9rem;
}

.w-110 {
  width: 11rem;
}

.w-111 {
  width: 11.1rem;
}

.w-112 {
  width: 11.2rem;
}

.w-113 {
  width: 11.3rem;
}

.w-114 {
  width: 11.4rem;
}

.w-115 {
  width: 11.5rem;
}

.w-116 {
  width: 11.6rem;
}

.w-117 {
  width: 11.7rem;
}

.w-118 {
  width: 11.8rem;
}

.w-119 {
  width: 11.9rem;
}

.w-120 {
  width: 12rem;
}

.w-121 {
  width: 12.1rem;
}

.w-122 {
  width: 12.2rem;
}

.w-123 {
  width: 12.3rem;
}

.w-124 {
  width: 12.4rem;
}

.w-125 {
  width: 12.5rem;
}

.w-126 {
  width: 12.6rem;
}

.w-127 {
  width: 12.7rem;
}

.w-128 {
  width: 12.8rem;
}

.w-129 {
  width: 12.9rem;
}

.w-130 {
  width: 13rem;
}

.w-131 {
  width: 13.1rem;
}

.w-132 {
  width: 13.2rem;
}

.w-133 {
  width: 13.3rem;
}

.w-134 {
  width: 13.4rem;
}

.w-135 {
  width: 13.5rem;
}

.w-136 {
  width: 13.6rem;
}

.w-137 {
  width: 13.7rem;
}

.w-138 {
  width: 13.8rem;
}

.w-139 {
  width: 13.9rem;
}

.w-140 {
  width: 14rem;
}

.w-141 {
  width: 14.1rem;
}

.w-142 {
  width: 14.2rem;
}

.w-143 {
  width: 14.3rem;
}

.w-144 {
  width: 14.4rem;
}

.w-145 {
  width: 14.5rem;
}

.w-146 {
  width: 14.6rem;
}

.w-147 {
  width: 14.7rem;
}

.w-148 {
  width: 14.8rem;
}

.w-149 {
  width: 14.9rem;
}

.w-150 {
  width: 15rem;
}

.w-151 {
  width: 15.1rem;
}

.w-152 {
  width: 15.2rem;
}

.w-153 {
  width: 15.3rem;
}

.w-154 {
  width: 15.4rem;
}

.w-155 {
  width: 15.5rem;
}

.w-156 {
  width: 15.6rem;
}

.w-157 {
  width: 15.7rem;
}

.w-158 {
  width: 15.8rem;
}

.w-159 {
  width: 15.9rem;
}

.w-160 {
  width: 16rem;
}

.w-161 {
  width: 16.1rem;
}

.w-162 {
  width: 16.2rem;
}

.w-163 {
  width: 16.3rem;
}

.w-164 {
  width: 16.4rem;
}

.w-165 {
  width: 16.5rem;
}

.w-166 {
  width: 16.6rem;
}

.w-167 {
  width: 16.7rem;
}

.w-168 {
  width: 16.8rem;
}

.w-169 {
  width: 16.9rem;
}

.w-170 {
  width: 17rem;
}

.w-171 {
  width: 17.1rem;
}

.w-172 {
  width: 17.2rem;
}

.w-173 {
  width: 17.3rem;
}

.w-174 {
  width: 17.4rem;
}

.w-175 {
  width: 17.5rem;
}

.w-176 {
  width: 17.6rem;
}

.w-177 {
  width: 17.7rem;
}

.w-178 {
  width: 17.8rem;
}

.w-179 {
  width: 17.9rem;
}

.w-180 {
  width: 18rem;
}

.w-181 {
  width: 18.1rem;
}

.w-182 {
  width: 18.2rem;
}

.w-183 {
  width: 18.3rem;
}

.w-184 {
  width: 18.4rem;
}

.w-185 {
  width: 18.5rem;
}

.w-186 {
  width: 18.6rem;
}

.w-187 {
  width: 18.7rem;
}

.w-188 {
  width: 18.8rem;
}

.w-189 {
  width: 18.9rem;
}

.w-190 {
  width: 19rem;
}

.w-191 {
  width: 19.1rem;
}

.w-192 {
  width: 19.2rem;
}

.w-193 {
  width: 19.3rem;
}

.w-194 {
  width: 19.4rem;
}

.w-195 {
  width: 19.5rem;
}

.w-196 {
  width: 19.6rem;
}

.w-197 {
  width: 19.7rem;
}

.w-198 {
  width: 19.8rem;
}

.w-199 {
  width: 19.9rem;
}

.w-200 {
  width: 20rem;
}

.w-201 {
  width: 20.1rem;
}

.w-202 {
  width: 20.2rem;
}

.w-203 {
  width: 20.3rem;
}

.w-204 {
  width: 20.4rem;
}

.w-205 {
  width: 20.5rem;
}

.w-206 {
  width: 20.6rem;
}

.w-207 {
  width: 20.7rem;
}

.w-208 {
  width: 20.8rem;
}

.w-209 {
  width: 20.9rem;
}

.w-210 {
  width: 21rem;
}

.w-211 {
  width: 21.1rem;
}

.w-212 {
  width: 21.2rem;
}

.w-213 {
  width: 21.3rem;
}

.w-214 {
  width: 21.4rem;
}

.w-215 {
  width: 21.5rem;
}

.w-216 {
  width: 21.6rem;
}

.w-217 {
  width: 21.7rem;
}

.w-218 {
  width: 21.8rem;
}

.w-219 {
  width: 21.9rem;
}

.w-220 {
  width: 22rem;
}

.w-221 {
  width: 22.1rem;
}

.w-222 {
  width: 22.2rem;
}

.w-223 {
  width: 22.3rem;
}

.w-224 {
  width: 22.4rem;
}

.w-225 {
  width: 22.5rem;
}

.w-226 {
  width: 22.6rem;
}

.w-227 {
  width: 22.7rem;
}

.w-228 {
  width: 22.8rem;
}

.w-229 {
  width: 22.9rem;
}

.w-230 {
  width: 23rem;
}

.w-231 {
  width: 23.1rem;
}

.w-232 {
  width: 23.2rem;
}

.w-233 {
  width: 23.3rem;
}

.w-234 {
  width: 23.4rem;
}

.w-235 {
  width: 23.5rem;
}

.w-236 {
  width: 23.6rem;
}

.w-237 {
  width: 23.7rem;
}

.w-238 {
  width: 23.8rem;
}

.w-239 {
  width: 23.9rem;
}

.w-240 {
  width: 24rem;
}

.w-241 {
  width: 24.1rem;
}

.w-242 {
  width: 24.2rem;
}

.w-243 {
  width: 24.3rem;
}

.w-244 {
  width: 24.4rem;
}

.w-245 {
  width: 24.5rem;
}

.w-246 {
  width: 24.6rem;
}

.w-247 {
  width: 24.7rem;
}

.w-248 {
  width: 24.8rem;
}

.w-249 {
  width: 24.9rem;
}

.w-250 {
  width: 25rem;
}

.w-251 {
  width: 25.1rem;
}

.w-252 {
  width: 25.2rem;
}

.w-253 {
  width: 25.3rem;
}

.w-254 {
  width: 25.4rem;
}

.w-255 {
  width: 25.5rem;
}

.w-256 {
  width: 25.6rem;
}

.w-257 {
  width: 25.7rem;
}

.w-258 {
  width: 25.8rem;
}

.w-259 {
  width: 25.9rem;
}

.w-260 {
  width: 26rem;
}

.w-261 {
  width: 26.1rem;
}

.w-262 {
  width: 26.2rem;
}

.w-263 {
  width: 26.3rem;
}

.w-264 {
  width: 26.4rem;
}

.w-265 {
  width: 26.5rem;
}

.w-266 {
  width: 26.6rem;
}

.w-267 {
  width: 26.7rem;
}

.w-268 {
  width: 26.8rem;
}

.w-269 {
  width: 26.9rem;
}

.w-270 {
  width: 27rem;
}

.w-271 {
  width: 27.1rem;
}

.w-272 {
  width: 27.2rem;
}

.w-273 {
  width: 27.3rem;
}

.w-274 {
  width: 27.4rem;
}

.w-275 {
  width: 27.5rem;
}

.w-276 {
  width: 27.6rem;
}

.w-277 {
  width: 27.7rem;
}

.w-278 {
  width: 27.8rem;
}

.w-279 {
  width: 27.9rem;
}

.w-280 {
  width: 28rem;
}

.w-281 {
  width: 28.1rem;
}

.w-282 {
  width: 28.2rem;
}

.w-283 {
  width: 28.3rem;
}

.w-284 {
  width: 28.4rem;
}

.w-285 {
  width: 28.5rem;
}

.w-286 {
  width: 28.6rem;
}

.w-287 {
  width: 28.7rem;
}

.w-288 {
  width: 28.8rem;
}

.w-289 {
  width: 28.9rem;
}

.w-290 {
  width: 29rem;
}

.w-291 {
  width: 29.1rem;
}

.w-292 {
  width: 29.2rem;
}

.w-293 {
  width: 29.3rem;
}

.w-294 {
  width: 29.4rem;
}

.w-295 {
  width: 29.5rem;
}

.w-296 {
  width: 29.6rem;
}

.w-297 {
  width: 29.7rem;
}

.w-298 {
  width: 29.8rem;
}

.w-299 {
  width: 29.9rem;
}

.w-300 {
  width: 30rem;
}

.w-301 {
  width: 30.1rem;
}

.w-302 {
  width: 30.2rem;
}

.w-303 {
  width: 30.3rem;
}

.w-304 {
  width: 30.4rem;
}

.w-305 {
  width: 30.5rem;
}

.w-306 {
  width: 30.6rem;
}

.w-307 {
  width: 30.7rem;
}

.w-308 {
  width: 30.8rem;
}

.w-309 {
  width: 30.9rem;
}

.w-310 {
  width: 31rem;
}

.w-311 {
  width: 31.1rem;
}

.w-312 {
  width: 31.2rem;
}

.w-313 {
  width: 31.3rem;
}

.w-314 {
  width: 31.4rem;
}

.w-315 {
  width: 31.5rem;
}

.w-316 {
  width: 31.6rem;
}

.w-317 {
  width: 31.7rem;
}

.w-318 {
  width: 31.8rem;
}

.w-319 {
  width: 31.9rem;
}

.w-320 {
  width: 32rem;
}

.w-321 {
  width: 32.1rem;
}

.w-322 {
  width: 32.2rem;
}

.w-323 {
  width: 32.3rem;
}

.w-324 {
  width: 32.4rem;
}

.w-325 {
  width: 32.5rem;
}

.w-326 {
  width: 32.6rem;
}

.w-327 {
  width: 32.7rem;
}

.w-328 {
  width: 32.8rem;
}

.w-329 {
  width: 32.9rem;
}

.w-330 {
  width: 33rem;
}

.w-331 {
  width: 33.1rem;
}

.w-332 {
  width: 33.2rem;
}

.w-333 {
  width: 33.3rem;
}

.w-334 {
  width: 33.4rem;
}

.w-335 {
  width: 33.5rem;
}

.w-336 {
  width: 33.6rem;
}

.w-337 {
  width: 33.7rem;
}

.w-338 {
  width: 33.8rem;
}

.w-339 {
  width: 33.9rem;
}

.w-340 {
  width: 34rem;
}

.w-341 {
  width: 34.1rem;
}

.w-342 {
  width: 34.2rem;
}

.w-343 {
  width: 34.3rem;
}

.w-344 {
  width: 34.4rem;
}

.w-345 {
  width: 34.5rem;
}

.w-346 {
  width: 34.6rem;
}

.w-347 {
  width: 34.7rem;
}

.w-348 {
  width: 34.8rem;
}

.w-349 {
  width: 34.9rem;
}

.w-350 {
  width: 35rem;
}

.w-351 {
  width: 35.1rem;
}

.w-352 {
  width: 35.2rem;
}

.w-353 {
  width: 35.3rem;
}

.w-354 {
  width: 35.4rem;
}

.w-355 {
  width: 35.5rem;
}

.w-356 {
  width: 35.6rem;
}

.w-357 {
  width: 35.7rem;
}

.w-358 {
  width: 35.8rem;
}

.w-359 {
  width: 35.9rem;
}

.w-360 {
  width: 36rem;
}

.w-361 {
  width: 36.1rem;
}

.w-362 {
  width: 36.2rem;
}

.w-363 {
  width: 36.3rem;
}

.w-364 {
  width: 36.4rem;
}

.w-365 {
  width: 36.5rem;
}

.w-366 {
  width: 36.6rem;
}

.w-367 {
  width: 36.7rem;
}

.w-368 {
  width: 36.8rem;
}

.w-369 {
  width: 36.9rem;
}

.w-370 {
  width: 37rem;
}

.w-371 {
  width: 37.1rem;
}

.w-372 {
  width: 37.2rem;
}

.w-373 {
  width: 37.3rem;
}

.w-374 {
  width: 37.4rem;
}

.w-375 {
  width: 37.5rem;
}

.w-376 {
  width: 37.6rem;
}

.w-377 {
  width: 37.7rem;
}

.w-378 {
  width: 37.8rem;
}

.w-379 {
  width: 37.9rem;
}

.w-380 {
  width: 38rem;
}

.w-381 {
  width: 38.1rem;
}

.w-382 {
  width: 38.2rem;
}

.w-383 {
  width: 38.3rem;
}

.w-384 {
  width: 38.4rem;
}

.w-385 {
  width: 38.5rem;
}

.w-386 {
  width: 38.6rem;
}

.w-387 {
  width: 38.7rem;
}

.w-388 {
  width: 38.8rem;
}

.w-389 {
  width: 38.9rem;
}

.w-390 {
  width: 39rem;
}

.w-391 {
  width: 39.1rem;
}

.w-392 {
  width: 39.2rem;
}

.w-393 {
  width: 39.3rem;
}

.w-394 {
  width: 39.4rem;
}

.w-395 {
  width: 39.5rem;
}

.w-396 {
  width: 39.6rem;
}

.w-397 {
  width: 39.7rem;
}

.w-398 {
  width: 39.8rem;
}

.w-399 {
  width: 39.9rem;
}

.w-400 {
  width: 40rem;
}

.w-401 {
  width: 40.1rem;
}

.w-402 {
  width: 40.2rem;
}

.w-403 {
  width: 40.3rem;
}

.w-404 {
  width: 40.4rem;
}

.w-405 {
  width: 40.5rem;
}

.w-406 {
  width: 40.6rem;
}

.w-407 {
  width: 40.7rem;
}

.w-408 {
  width: 40.8rem;
}

.w-409 {
  width: 40.9rem;
}

.w-410 {
  width: 41rem;
}

.w-411 {
  width: 41.1rem;
}

.w-412 {
  width: 41.2rem;
}

.w-413 {
  width: 41.3rem;
}

.w-414 {
  width: 41.4rem;
}

.w-415 {
  width: 41.5rem;
}

.w-416 {
  width: 41.6rem;
}

.w-417 {
  width: 41.7rem;
}

.w-418 {
  width: 41.8rem;
}

.w-419 {
  width: 41.9rem;
}

.w-420 {
  width: 42rem;
}

.w-421 {
  width: 42.1rem;
}

.w-422 {
  width: 42.2rem;
}

.w-423 {
  width: 42.3rem;
}

.w-424 {
  width: 42.4rem;
}

.w-425 {
  width: 42.5rem;
}

.w-426 {
  width: 42.6rem;
}

.w-427 {
  width: 42.7rem;
}

.w-428 {
  width: 42.8rem;
}

.w-429 {
  width: 42.9rem;
}

.w-430 {
  width: 43rem;
}

.w-431 {
  width: 43.1rem;
}

.w-432 {
  width: 43.2rem;
}

.w-433 {
  width: 43.3rem;
}

.w-434 {
  width: 43.4rem;
}

.w-435 {
  width: 43.5rem;
}

.w-436 {
  width: 43.6rem;
}

.w-437 {
  width: 43.7rem;
}

.w-438 {
  width: 43.8rem;
}

.w-439 {
  width: 43.9rem;
}

.w-440 {
  width: 44rem;
}

.w-441 {
  width: 44.1rem;
}

.w-442 {
  width: 44.2rem;
}

.w-443 {
  width: 44.3rem;
}

.w-444 {
  width: 44.4rem;
}

.w-445 {
  width: 44.5rem;
}

.w-446 {
  width: 44.6rem;
}

.w-447 {
  width: 44.7rem;
}

.w-448 {
  width: 44.8rem;
}

.w-449 {
  width: 44.9rem;
}

.w-450 {
  width: 45rem;
}

.w-451 {
  width: 45.1rem;
}

.w-452 {
  width: 45.2rem;
}

.w-453 {
  width: 45.3rem;
}

.w-454 {
  width: 45.4rem;
}

.w-455 {
  width: 45.5rem;
}

.w-456 {
  width: 45.6rem;
}

.w-457 {
  width: 45.7rem;
}

.w-458 {
  width: 45.8rem;
}

.w-459 {
  width: 45.9rem;
}

.w-460 {
  width: 46rem;
}

.w-461 {
  width: 46.1rem;
}

.w-462 {
  width: 46.2rem;
}

.w-463 {
  width: 46.3rem;
}

.w-464 {
  width: 46.4rem;
}

.w-465 {
  width: 46.5rem;
}

.w-466 {
  width: 46.6rem;
}

.w-467 {
  width: 46.7rem;
}

.w-468 {
  width: 46.8rem;
}

.w-469 {
  width: 46.9rem;
}

.w-470 {
  width: 47rem;
}

.w-471 {
  width: 47.1rem;
}

.w-472 {
  width: 47.2rem;
}

.w-473 {
  width: 47.3rem;
}

.w-474 {
  width: 47.4rem;
}

.w-475 {
  width: 47.5rem;
}

.w-476 {
  width: 47.6rem;
}

.w-477 {
  width: 47.7rem;
}

.w-478 {
  width: 47.8rem;
}

.w-479 {
  width: 47.9rem;
}

.w-480 {
  width: 48rem;
}

.w-481 {
  width: 48.1rem;
}

.w-482 {
  width: 48.2rem;
}

.w-483 {
  width: 48.3rem;
}

.w-484 {
  width: 48.4rem;
}

.w-485 {
  width: 48.5rem;
}

.w-486 {
  width: 48.6rem;
}

.w-487 {
  width: 48.7rem;
}

.w-488 {
  width: 48.8rem;
}

.w-489 {
  width: 48.9rem;
}

.w-490 {
  width: 49rem;
}

.w-491 {
  width: 49.1rem;
}

.w-492 {
  width: 49.2rem;
}

.w-493 {
  width: 49.3rem;
}

.w-494 {
  width: 49.4rem;
}

.w-495 {
  width: 49.5rem;
}

.w-496 {
  width: 49.6rem;
}

.w-497 {
  width: 49.7rem;
}

.w-498 {
  width: 49.8rem;
}

.w-499 {
  width: 49.9rem;
}

.w-500 {
  width: 50rem;
}

.w-501 {
  width: 50.1rem;
}

.w-502 {
  width: 50.2rem;
}

.w-503 {
  width: 50.3rem;
}

.w-504 {
  width: 50.4rem;
}

.w-505 {
  width: 50.5rem;
}

.w-506 {
  width: 50.6rem;
}

.w-507 {
  width: 50.7rem;
}

.w-508 {
  width: 50.8rem;
}

.w-509 {
  width: 50.9rem;
}

.w-510 {
  width: 51rem;
}

.w-511 {
  width: 51.1rem;
}

.w-512 {
  width: 51.2rem;
}

.w-513 {
  width: 51.3rem;
}

.w-514 {
  width: 51.4rem;
}

.w-515 {
  width: 51.5rem;
}

.w-516 {
  width: 51.6rem;
}

.w-517 {
  width: 51.7rem;
}

.w-518 {
  width: 51.8rem;
}

.w-519 {
  width: 51.9rem;
}

.w-520 {
  width: 52rem;
}

.w-521 {
  width: 52.1rem;
}

.w-522 {
  width: 52.2rem;
}

.w-523 {
  width: 52.3rem;
}

.w-524 {
  width: 52.4rem;
}

.w-525 {
  width: 52.5rem;
}

.w-526 {
  width: 52.6rem;
}

.w-527 {
  width: 52.7rem;
}

.w-528 {
  width: 52.8rem;
}

.w-529 {
  width: 52.9rem;
}

.w-530 {
  width: 53rem;
}

.w-531 {
  width: 53.1rem;
}

.w-532 {
  width: 53.2rem;
}

.w-533 {
  width: 53.3rem;
}

.w-534 {
  width: 53.4rem;
}

.w-535 {
  width: 53.5rem;
}

.w-536 {
  width: 53.6rem;
}

.w-537 {
  width: 53.7rem;
}

.w-538 {
  width: 53.8rem;
}

.w-539 {
  width: 53.9rem;
}

.w-540 {
  width: 54rem;
}

.w-541 {
  width: 54.1rem;
}

.w-542 {
  width: 54.2rem;
}

.w-543 {
  width: 54.3rem;
}

.w-544 {
  width: 54.4rem;
}

.w-545 {
  width: 54.5rem;
}

.w-546 {
  width: 54.6rem;
}

.w-547 {
  width: 54.7rem;
}

.w-548 {
  width: 54.8rem;
}

.w-549 {
  width: 54.9rem;
}

.w-550 {
  width: 55rem;
}

.w-551 {
  width: 55.1rem;
}

.w-552 {
  width: 55.2rem;
}

.w-553 {
  width: 55.3rem;
}

.w-554 {
  width: 55.4rem;
}

.w-555 {
  width: 55.5rem;
}

.w-556 {
  width: 55.6rem;
}

.w-557 {
  width: 55.7rem;
}

.w-558 {
  width: 55.8rem;
}

.w-559 {
  width: 55.9rem;
}

.w-560 {
  width: 56rem;
}

.w-561 {
  width: 56.1rem;
}

.w-562 {
  width: 56.2rem;
}

.w-563 {
  width: 56.3rem;
}

.w-564 {
  width: 56.4rem;
}

.w-565 {
  width: 56.5rem;
}

.w-566 {
  width: 56.6rem;
}

.w-567 {
  width: 56.7rem;
}

.w-568 {
  width: 56.8rem;
}

.w-569 {
  width: 56.9rem;
}

.w-570 {
  width: 57rem;
}

.w-571 {
  width: 57.1rem;
}

.w-572 {
  width: 57.2rem;
}

.w-573 {
  width: 57.3rem;
}

.w-574 {
  width: 57.4rem;
}

.w-575 {
  width: 57.5rem;
}

.w-576 {
  width: 57.6rem;
}

.w-577 {
  width: 57.7rem;
}

.w-578 {
  width: 57.8rem;
}

.w-579 {
  width: 57.9rem;
}

.w-580 {
  width: 58rem;
}

.w-581 {
  width: 58.1rem;
}

.w-582 {
  width: 58.2rem;
}

.w-583 {
  width: 58.3rem;
}

.w-584 {
  width: 58.4rem;
}

.w-585 {
  width: 58.5rem;
}

.w-586 {
  width: 58.6rem;
}

.w-587 {
  width: 58.7rem;
}

.w-588 {
  width: 58.8rem;
}

.w-589 {
  width: 58.9rem;
}

.w-590 {
  width: 59rem;
}

.w-591 {
  width: 59.1rem;
}

.w-592 {
  width: 59.2rem;
}

.w-593 {
  width: 59.3rem;
}

.w-594 {
  width: 59.4rem;
}

.w-595 {
  width: 59.5rem;
}

.w-596 {
  width: 59.6rem;
}

.w-597 {
  width: 59.7rem;
}

.w-598 {
  width: 59.8rem;
}

.w-599 {
  width: 59.9rem;
}

.w-600 {
  width: 60rem;
}

.w-601 {
  width: 60.1rem;
}

.w-602 {
  width: 60.2rem;
}

.w-603 {
  width: 60.3rem;
}

.w-604 {
  width: 60.4rem;
}

.w-605 {
  width: 60.5rem;
}

.w-606 {
  width: 60.6rem;
}

.w-607 {
  width: 60.7rem;
}

.w-608 {
  width: 60.8rem;
}

.w-609 {
  width: 60.9rem;
}

.w-610 {
  width: 61rem;
}

.w-611 {
  width: 61.1rem;
}

.w-612 {
  width: 61.2rem;
}

.w-613 {
  width: 61.3rem;
}

.w-614 {
  width: 61.4rem;
}

.w-615 {
  width: 61.5rem;
}

.w-616 {
  width: 61.6rem;
}

.w-617 {
  width: 61.7rem;
}

.w-618 {
  width: 61.8rem;
}

.w-619 {
  width: 61.9rem;
}

.w-620 {
  width: 62rem;
}

.w-621 {
  width: 62.1rem;
}

.w-622 {
  width: 62.2rem;
}

.w-623 {
  width: 62.3rem;
}

.w-624 {
  width: 62.4rem;
}

.w-625 {
  width: 62.5rem;
}

.w-626 {
  width: 62.6rem;
}

.w-627 {
  width: 62.7rem;
}

.w-628 {
  width: 62.8rem;
}

.w-629 {
  width: 62.9rem;
}

.w-630 {
  width: 63rem;
}

.w-631 {
  width: 63.1rem;
}

.w-632 {
  width: 63.2rem;
}

.w-633 {
  width: 63.3rem;
}

.w-634 {
  width: 63.4rem;
}

.w-635 {
  width: 63.5rem;
}

.w-636 {
  width: 63.6rem;
}

.w-637 {
  width: 63.7rem;
}

.w-638 {
  width: 63.8rem;
}

.w-639 {
  width: 63.9rem;
}

.w-640 {
  width: 64rem;
}

.w-641 {
  width: 64.1rem;
}

.w-642 {
  width: 64.2rem;
}

.w-643 {
  width: 64.3rem;
}

.w-644 {
  width: 64.4rem;
}

.w-645 {
  width: 64.5rem;
}

.w-646 {
  width: 64.6rem;
}

.w-647 {
  width: 64.7rem;
}

.w-648 {
  width: 64.8rem;
}

.w-649 {
  width: 64.9rem;
}

.w-650 {
  width: 65rem;
}

.w-651 {
  width: 65.1rem;
}

.w-652 {
  width: 65.2rem;
}

.w-653 {
  width: 65.3rem;
}

.w-654 {
  width: 65.4rem;
}

.w-655 {
  width: 65.5rem;
}

.w-656 {
  width: 65.6rem;
}

.w-657 {
  width: 65.7rem;
}

.w-658 {
  width: 65.8rem;
}

.w-659 {
  width: 65.9rem;
}

.w-660 {
  width: 66rem;
}

.w-661 {
  width: 66.1rem;
}

.w-662 {
  width: 66.2rem;
}

.w-663 {
  width: 66.3rem;
}

.w-664 {
  width: 66.4rem;
}

.w-665 {
  width: 66.5rem;
}

.w-666 {
  width: 66.6rem;
}

.w-667 {
  width: 66.7rem;
}

.w-668 {
  width: 66.8rem;
}

.w-669 {
  width: 66.9rem;
}

.w-670 {
  width: 67rem;
}

.w-671 {
  width: 67.1rem;
}

.w-672 {
  width: 67.2rem;
}

.w-673 {
  width: 67.3rem;
}

.w-674 {
  width: 67.4rem;
}

.w-675 {
  width: 67.5rem;
}

.w-676 {
  width: 67.6rem;
}

.w-677 {
  width: 67.7rem;
}

.w-678 {
  width: 67.8rem;
}

.w-679 {
  width: 67.9rem;
}

.w-680 {
  width: 68rem;
}

.w-681 {
  width: 68.1rem;
}

.w-682 {
  width: 68.2rem;
}

.w-683 {
  width: 68.3rem;
}

.w-684 {
  width: 68.4rem;
}

.w-685 {
  width: 68.5rem;
}

.w-686 {
  width: 68.6rem;
}

.w-687 {
  width: 68.7rem;
}

.w-688 {
  width: 68.8rem;
}

.w-689 {
  width: 68.9rem;
}

.w-690 {
  width: 69rem;
}

.w-691 {
  width: 69.1rem;
}

.w-692 {
  width: 69.2rem;
}

.w-693 {
  width: 69.3rem;
}

.w-694 {
  width: 69.4rem;
}

.w-695 {
  width: 69.5rem;
}

.w-696 {
  width: 69.6rem;
}

.w-697 {
  width: 69.7rem;
}

.w-698 {
  width: 69.8rem;
}

.w-699 {
  width: 69.9rem;
}

.w-700 {
  width: 70rem;
}

.w-701 {
  width: 70.1rem;
}

.w-702 {
  width: 70.2rem;
}

.w-703 {
  width: 70.3rem;
}

.w-704 {
  width: 70.4rem;
}

.w-705 {
  width: 70.5rem;
}

.w-706 {
  width: 70.6rem;
}

.w-707 {
  width: 70.7rem;
}

.w-708 {
  width: 70.8rem;
}

.w-709 {
  width: 70.9rem;
}

.w-710 {
  width: 71rem;
}

.w-711 {
  width: 71.1rem;
}

.w-712 {
  width: 71.2rem;
}

.w-713 {
  width: 71.3rem;
}

.w-714 {
  width: 71.4rem;
}

.w-715 {
  width: 71.5rem;
}

.w-716 {
  width: 71.6rem;
}

.w-717 {
  width: 71.7rem;
}

.w-718 {
  width: 71.8rem;
}

.w-719 {
  width: 71.9rem;
}

.w-720 {
  width: 72rem;
}

.w-721 {
  width: 72.1rem;
}

.w-722 {
  width: 72.2rem;
}

.w-723 {
  width: 72.3rem;
}

.w-724 {
  width: 72.4rem;
}

.w-725 {
  width: 72.5rem;
}

.w-726 {
  width: 72.6rem;
}

.w-727 {
  width: 72.7rem;
}

.w-728 {
  width: 72.8rem;
}

.w-729 {
  width: 72.9rem;
}

.w-730 {
  width: 73rem;
}

.w-731 {
  width: 73.1rem;
}

.w-732 {
  width: 73.2rem;
}

.w-733 {
  width: 73.3rem;
}

.w-734 {
  width: 73.4rem;
}

.w-735 {
  width: 73.5rem;
}

.w-736 {
  width: 73.6rem;
}

.w-737 {
  width: 73.7rem;
}

.w-738 {
  width: 73.8rem;
}

.w-739 {
  width: 73.9rem;
}

.w-740 {
  width: 74rem;
}

.w-741 {
  width: 74.1rem;
}

.w-742 {
  width: 74.2rem;
}

.w-743 {
  width: 74.3rem;
}

.w-744 {
  width: 74.4rem;
}

.w-745 {
  width: 74.5rem;
}

.w-746 {
  width: 74.6rem;
}

.w-747 {
  width: 74.7rem;
}

.w-748 {
  width: 74.8rem;
}

.w-749 {
  width: 74.9rem;
}

.w-750 {
  width: 75rem;
}

.w-751 {
  width: 75.1rem;
}

.w-752 {
  width: 75.2rem;
}

.w-753 {
  width: 75.3rem;
}

.w-754 {
  width: 75.4rem;
}

.w-755 {
  width: 75.5rem;
}

.w-756 {
  width: 75.6rem;
}

.w-757 {
  width: 75.7rem;
}

.w-758 {
  width: 75.8rem;
}

.w-759 {
  width: 75.9rem;
}

.w-760 {
  width: 76rem;
}

.w-761 {
  width: 76.1rem;
}

.w-762 {
  width: 76.2rem;
}

.w-763 {
  width: 76.3rem;
}

.w-764 {
  width: 76.4rem;
}

.w-765 {
  width: 76.5rem;
}

.w-766 {
  width: 76.6rem;
}

.w-767 {
  width: 76.7rem;
}

.w-768 {
  width: 76.8rem;
}

.w-769 {
  width: 76.9rem;
}

.w-770 {
  width: 77rem;
}

.w-771 {
  width: 77.1rem;
}

.w-772 {
  width: 77.2rem;
}

.w-773 {
  width: 77.3rem;
}

.w-774 {
  width: 77.4rem;
}

.w-775 {
  width: 77.5rem;
}

.w-776 {
  width: 77.6rem;
}

.w-777 {
  width: 77.7rem;
}

.w-778 {
  width: 77.8rem;
}

.w-779 {
  width: 77.9rem;
}

.w-780 {
  width: 78rem;
}

.w-781 {
  width: 78.1rem;
}

.w-782 {
  width: 78.2rem;
}

.w-783 {
  width: 78.3rem;
}

.w-784 {
  width: 78.4rem;
}

.w-785 {
  width: 78.5rem;
}

.w-786 {
  width: 78.6rem;
}

.w-787 {
  width: 78.7rem;
}

.w-788 {
  width: 78.8rem;
}

.w-789 {
  width: 78.9rem;
}

.w-790 {
  width: 79rem;
}

.w-791 {
  width: 79.1rem;
}

.w-792 {
  width: 79.2rem;
}

.w-793 {
  width: 79.3rem;
}

.w-794 {
  width: 79.4rem;
}

.w-795 {
  width: 79.5rem;
}

.w-796 {
  width: 79.6rem;
}

.w-797 {
  width: 79.7rem;
}

.w-798 {
  width: 79.8rem;
}

.w-799 {
  width: 79.9rem;
}

.w-800 {
  width: 80rem;
}

.w-801 {
  width: 80.1rem;
}

.w-802 {
  width: 80.2rem;
}

.w-803 {
  width: 80.3rem;
}

.w-804 {
  width: 80.4rem;
}

.w-805 {
  width: 80.5rem;
}

.w-806 {
  width: 80.6rem;
}

.w-807 {
  width: 80.7rem;
}

.w-808 {
  width: 80.8rem;
}

.w-809 {
  width: 80.9rem;
}

.w-810 {
  width: 81rem;
}

.w-811 {
  width: 81.1rem;
}

.w-812 {
  width: 81.2rem;
}

.w-813 {
  width: 81.3rem;
}

.w-814 {
  width: 81.4rem;
}

.w-815 {
  width: 81.5rem;
}

.w-816 {
  width: 81.6rem;
}

.w-817 {
  width: 81.7rem;
}

.w-818 {
  width: 81.8rem;
}

.w-819 {
  width: 81.9rem;
}

.w-820 {
  width: 82rem;
}

.w-821 {
  width: 82.1rem;
}

.w-822 {
  width: 82.2rem;
}

.w-823 {
  width: 82.3rem;
}

.w-824 {
  width: 82.4rem;
}

.w-825 {
  width: 82.5rem;
}

.w-826 {
  width: 82.6rem;
}

.w-827 {
  width: 82.7rem;
}

.w-828 {
  width: 82.8rem;
}

.w-829 {
  width: 82.9rem;
}

.w-830 {
  width: 83rem;
}

.w-831 {
  width: 83.1rem;
}

.w-832 {
  width: 83.2rem;
}

.w-833 {
  width: 83.3rem;
}

.w-834 {
  width: 83.4rem;
}

.w-835 {
  width: 83.5rem;
}

.w-836 {
  width: 83.6rem;
}

.w-837 {
  width: 83.7rem;
}

.w-838 {
  width: 83.8rem;
}

.w-839 {
  width: 83.9rem;
}

.w-840 {
  width: 84rem;
}

.w-841 {
  width: 84.1rem;
}

.w-842 {
  width: 84.2rem;
}

.w-843 {
  width: 84.3rem;
}

.w-844 {
  width: 84.4rem;
}

.w-845 {
  width: 84.5rem;
}

.w-846 {
  width: 84.6rem;
}

.w-847 {
  width: 84.7rem;
}

.w-848 {
  width: 84.8rem;
}

.w-849 {
  width: 84.9rem;
}

.w-850 {
  width: 85rem;
}

.w-851 {
  width: 85.1rem;
}

.w-852 {
  width: 85.2rem;
}

.w-853 {
  width: 85.3rem;
}

.w-854 {
  width: 85.4rem;
}

.w-855 {
  width: 85.5rem;
}

.w-856 {
  width: 85.6rem;
}

.w-857 {
  width: 85.7rem;
}

.w-858 {
  width: 85.8rem;
}

.w-859 {
  width: 85.9rem;
}

.w-860 {
  width: 86rem;
}

.w-861 {
  width: 86.1rem;
}

.w-862 {
  width: 86.2rem;
}

.w-863 {
  width: 86.3rem;
}

.w-864 {
  width: 86.4rem;
}

.w-865 {
  width: 86.5rem;
}

.w-866 {
  width: 86.6rem;
}

.w-867 {
  width: 86.7rem;
}

.w-868 {
  width: 86.8rem;
}

.w-869 {
  width: 86.9rem;
}

.w-870 {
  width: 87rem;
}

.w-871 {
  width: 87.1rem;
}

.w-872 {
  width: 87.2rem;
}

.w-873 {
  width: 87.3rem;
}

.w-874 {
  width: 87.4rem;
}

.w-875 {
  width: 87.5rem;
}

.w-876 {
  width: 87.6rem;
}

.w-877 {
  width: 87.7rem;
}

.w-878 {
  width: 87.8rem;
}

.w-879 {
  width: 87.9rem;
}

.w-880 {
  width: 88rem;
}

.w-881 {
  width: 88.1rem;
}

.w-882 {
  width: 88.2rem;
}

.w-883 {
  width: 88.3rem;
}

.w-884 {
  width: 88.4rem;
}

.w-885 {
  width: 88.5rem;
}

.w-886 {
  width: 88.6rem;
}

.w-887 {
  width: 88.7rem;
}

.w-888 {
  width: 88.8rem;
}

.w-889 {
  width: 88.9rem;
}

.w-890 {
  width: 89rem;
}

.w-891 {
  width: 89.1rem;
}

.w-892 {
  width: 89.2rem;
}

.w-893 {
  width: 89.3rem;
}

.w-894 {
  width: 89.4rem;
}

.w-895 {
  width: 89.5rem;
}

.w-896 {
  width: 89.6rem;
}

.w-897 {
  width: 89.7rem;
}

.w-898 {
  width: 89.8rem;
}

.w-899 {
  width: 89.9rem;
}

.w-900 {
  width: 90rem;
}

.w-901 {
  width: 90.1rem;
}

.w-902 {
  width: 90.2rem;
}

.w-903 {
  width: 90.3rem;
}

.w-904 {
  width: 90.4rem;
}

.w-905 {
  width: 90.5rem;
}

.w-906 {
  width: 90.6rem;
}

.w-907 {
  width: 90.7rem;
}

.w-908 {
  width: 90.8rem;
}

.w-909 {
  width: 90.9rem;
}

.w-910 {
  width: 91rem;
}

.w-911 {
  width: 91.1rem;
}

.w-912 {
  width: 91.2rem;
}

.w-913 {
  width: 91.3rem;
}

.w-914 {
  width: 91.4rem;
}

.w-915 {
  width: 91.5rem;
}

.w-916 {
  width: 91.6rem;
}

.w-917 {
  width: 91.7rem;
}

.w-918 {
  width: 91.8rem;
}

.w-919 {
  width: 91.9rem;
}

.w-920 {
  width: 92rem;
}

.w-921 {
  width: 92.1rem;
}

.w-922 {
  width: 92.2rem;
}

.w-923 {
  width: 92.3rem;
}

.w-924 {
  width: 92.4rem;
}

.w-925 {
  width: 92.5rem;
}

.w-926 {
  width: 92.6rem;
}

.w-927 {
  width: 92.7rem;
}

.w-928 {
  width: 92.8rem;
}

.w-929 {
  width: 92.9rem;
}

.w-930 {
  width: 93rem;
}

.w-931 {
  width: 93.1rem;
}

.w-932 {
  width: 93.2rem;
}

.w-933 {
  width: 93.3rem;
}

.w-934 {
  width: 93.4rem;
}

.w-935 {
  width: 93.5rem;
}

.w-936 {
  width: 93.6rem;
}

.w-937 {
  width: 93.7rem;
}

.w-938 {
  width: 93.8rem;
}

.w-939 {
  width: 93.9rem;
}

.w-940 {
  width: 94rem;
}

.w-941 {
  width: 94.1rem;
}

.w-942 {
  width: 94.2rem;
}

.w-943 {
  width: 94.3rem;
}

.w-944 {
  width: 94.4rem;
}

.w-945 {
  width: 94.5rem;
}

.w-946 {
  width: 94.6rem;
}

.w-947 {
  width: 94.7rem;
}

.w-948 {
  width: 94.8rem;
}

.w-949 {
  width: 94.9rem;
}

.w-950 {
  width: 95rem;
}

.w-951 {
  width: 95.1rem;
}

.w-952 {
  width: 95.2rem;
}

.w-953 {
  width: 95.3rem;
}

.w-954 {
  width: 95.4rem;
}

.w-955 {
  width: 95.5rem;
}

.w-956 {
  width: 95.6rem;
}

.w-957 {
  width: 95.7rem;
}

.w-958 {
  width: 95.8rem;
}

.w-959 {
  width: 95.9rem;
}

.w-960 {
  width: 96rem;
}

.w-961 {
  width: 96.1rem;
}

.w-962 {
  width: 96.2rem;
}

.w-963 {
  width: 96.3rem;
}

.w-964 {
  width: 96.4rem;
}

.w-965 {
  width: 96.5rem;
}

.w-966 {
  width: 96.6rem;
}

.w-967 {
  width: 96.7rem;
}

.w-968 {
  width: 96.8rem;
}

.w-969 {
  width: 96.9rem;
}

.w-970 {
  width: 97rem;
}

.w-971 {
  width: 97.1rem;
}

.w-972 {
  width: 97.2rem;
}

.w-973 {
  width: 97.3rem;
}

.w-974 {
  width: 97.4rem;
}

.w-975 {
  width: 97.5rem;
}

.w-976 {
  width: 97.6rem;
}

.w-977 {
  width: 97.7rem;
}

.w-978 {
  width: 97.8rem;
}

.w-979 {
  width: 97.9rem;
}

.w-980 {
  width: 98rem;
}

.w-981 {
  width: 98.1rem;
}

.w-982 {
  width: 98.2rem;
}

.w-983 {
  width: 98.3rem;
}

.w-984 {
  width: 98.4rem;
}

.w-985 {
  width: 98.5rem;
}

.w-986 {
  width: 98.6rem;
}

.w-987 {
  width: 98.7rem;
}

.w-988 {
  width: 98.8rem;
}

.w-989 {
  width: 98.9rem;
}

.w-990 {
  width: 99rem;
}

.w-991 {
  width: 99.1rem;
}

.w-992 {
  width: 99.2rem;
}

.w-993 {
  width: 99.3rem;
}

.w-994 {
  width: 99.4rem;
}

.w-995 {
  width: 99.5rem;
}

.w-996 {
  width: 99.6rem;
}

.w-997 {
  width: 99.7rem;
}

.w-998 {
  width: 99.8rem;
}

.w-999 {
  width: 99.9rem;
}

.w-1000 {
  width: 100rem;
}

.w-1001 {
  width: 100.1rem;
}

.w-1002 {
  width: 100.2rem;
}

.w-1003 {
  width: 100.3rem;
}

.w-1004 {
  width: 100.4rem;
}

.w-1005 {
  width: 100.5rem;
}

.w-1006 {
  width: 100.6rem;
}

.w-1007 {
  width: 100.7rem;
}

.w-1008 {
  width: 100.8rem;
}

.w-1009 {
  width: 100.9rem;
}

.w-1010 {
  width: 101rem;
}

.w-1011 {
  width: 101.1rem;
}

.w-1012 {
  width: 101.2rem;
}

.w-1013 {
  width: 101.3rem;
}

.w-1014 {
  width: 101.4rem;
}

.w-1015 {
  width: 101.5rem;
}

.w-1016 {
  width: 101.6rem;
}

.w-1017 {
  width: 101.7rem;
}

.w-1018 {
  width: 101.8rem;
}

.w-1019 {
  width: 101.9rem;
}

.w-1020 {
  width: 102rem;
}

.w-1021 {
  width: 102.1rem;
}

.w-1022 {
  width: 102.2rem;
}

.w-1023 {
  width: 102.3rem;
}

.w-1024 {
  width: 102.4rem;
}

.w-1025 {
  width: 102.5rem;
}

.w-1026 {
  width: 102.6rem;
}

.w-1027 {
  width: 102.7rem;
}

.w-1028 {
  width: 102.8rem;
}

.w-1029 {
  width: 102.9rem;
}

.w-1030 {
  width: 103rem;
}

.w-1031 {
  width: 103.1rem;
}

.w-1032 {
  width: 103.2rem;
}

.w-1033 {
  width: 103.3rem;
}

.w-1034 {
  width: 103.4rem;
}

.w-1035 {
  width: 103.5rem;
}

.w-1036 {
  width: 103.6rem;
}

.w-1037 {
  width: 103.7rem;
}

.w-1038 {
  width: 103.8rem;
}

.w-1039 {
  width: 103.9rem;
}

.w-1040 {
  width: 104rem;
}

.w-1041 {
  width: 104.1rem;
}

.w-1042 {
  width: 104.2rem;
}

.w-1043 {
  width: 104.3rem;
}

.w-1044 {
  width: 104.4rem;
}

.w-1045 {
  width: 104.5rem;
}

.w-1046 {
  width: 104.6rem;
}

.w-1047 {
  width: 104.7rem;
}

.w-1048 {
  width: 104.8rem;
}

.w-1049 {
  width: 104.9rem;
}

.w-1050 {
  width: 105rem;
}

.w-1051 {
  width: 105.1rem;
}

.w-1052 {
  width: 105.2rem;
}

.w-1053 {
  width: 105.3rem;
}

.w-1054 {
  width: 105.4rem;
}

.w-1055 {
  width: 105.5rem;
}

.w-1056 {
  width: 105.6rem;
}

.w-1057 {
  width: 105.7rem;
}

.w-1058 {
  width: 105.8rem;
}

.w-1059 {
  width: 105.9rem;
}

.w-1060 {
  width: 106rem;
}

.w-1061 {
  width: 106.1rem;
}

.w-1062 {
  width: 106.2rem;
}

.w-1063 {
  width: 106.3rem;
}

.w-1064 {
  width: 106.4rem;
}

.w-1065 {
  width: 106.5rem;
}

.w-1066 {
  width: 106.6rem;
}

.w-1067 {
  width: 106.7rem;
}

.w-1068 {
  width: 106.8rem;
}

.w-1069 {
  width: 106.9rem;
}

.w-1070 {
  width: 107rem;
}

.w-1071 {
  width: 107.1rem;
}

.w-1072 {
  width: 107.2rem;
}

.w-1073 {
  width: 107.3rem;
}

.w-1074 {
  width: 107.4rem;
}

.w-1075 {
  width: 107.5rem;
}

.w-1076 {
  width: 107.6rem;
}

.w-1077 {
  width: 107.7rem;
}

.w-1078 {
  width: 107.8rem;
}

.w-1079 {
  width: 107.9rem;
}

.w-1080 {
  width: 108rem;
}

.w-1081 {
  width: 108.1rem;
}

.w-1082 {
  width: 108.2rem;
}

.w-1083 {
  width: 108.3rem;
}

.w-1084 {
  width: 108.4rem;
}

.w-1085 {
  width: 108.5rem;
}

.w-1086 {
  width: 108.6rem;
}

.w-1087 {
  width: 108.7rem;
}

.w-1088 {
  width: 108.8rem;
}

.w-1089 {
  width: 108.9rem;
}

.w-1090 {
  width: 109rem;
}

.w-1091 {
  width: 109.1rem;
}

.w-1092 {
  width: 109.2rem;
}

.w-1093 {
  width: 109.3rem;
}

.w-1094 {
  width: 109.4rem;
}

.w-1095 {
  width: 109.5rem;
}

.w-1096 {
  width: 109.6rem;
}

.w-1097 {
  width: 109.7rem;
}

.w-1098 {
  width: 109.8rem;
}

.w-1099 {
  width: 109.9rem;
}

.w-1100 {
  width: 110rem;
}

.w-1101 {
  width: 110.1rem;
}

.w-1102 {
  width: 110.2rem;
}

.w-1103 {
  width: 110.3rem;
}

.w-1104 {
  width: 110.4rem;
}

.w-1105 {
  width: 110.5rem;
}

.w-1106 {
  width: 110.6rem;
}

.w-1107 {
  width: 110.7rem;
}

.w-1108 {
  width: 110.8rem;
}

.w-1109 {
  width: 110.9rem;
}

.w-1110 {
  width: 111rem;
}

.w-1111 {
  width: 111.1rem;
}

.w-1112 {
  width: 111.2rem;
}

.w-1113 {
  width: 111.3rem;
}

.w-1114 {
  width: 111.4rem;
}

.w-1115 {
  width: 111.5rem;
}

.w-1116 {
  width: 111.6rem;
}

.w-1117 {
  width: 111.7rem;
}

.w-1118 {
  width: 111.8rem;
}

.w-1119 {
  width: 111.9rem;
}

.w-1120 {
  width: 112rem;
}

.w-1121 {
  width: 112.1rem;
}

.w-1122 {
  width: 112.2rem;
}

.w-1123 {
  width: 112.3rem;
}

.w-1124 {
  width: 112.4rem;
}

.w-1125 {
  width: 112.5rem;
}

.w-1126 {
  width: 112.6rem;
}

.w-1127 {
  width: 112.7rem;
}

.w-1128 {
  width: 112.8rem;
}

.w-1129 {
  width: 112.9rem;
}

.w-1130 {
  width: 113rem;
}

.w-1131 {
  width: 113.1rem;
}

.w-1132 {
  width: 113.2rem;
}

.w-1133 {
  width: 113.3rem;
}

.w-1134 {
  width: 113.4rem;
}

.w-1135 {
  width: 113.5rem;
}

.w-1136 {
  width: 113.6rem;
}

.w-1137 {
  width: 113.7rem;
}

.w-1138 {
  width: 113.8rem;
}

.w-1139 {
  width: 113.9rem;
}

.w-1140 {
  width: 114rem;
}

.w-1141 {
  width: 114.1rem;
}

.w-1142 {
  width: 114.2rem;
}

.w-1143 {
  width: 114.3rem;
}

.w-1144 {
  width: 114.4rem;
}

.w-1145 {
  width: 114.5rem;
}

.w-1146 {
  width: 114.6rem;
}

.w-1147 {
  width: 114.7rem;
}

.w-1148 {
  width: 114.8rem;
}

.w-1149 {
  width: 114.9rem;
}

.w-1150 {
  width: 115rem;
}

.w-1151 {
  width: 115.1rem;
}

.w-1152 {
  width: 115.2rem;
}

.w-1153 {
  width: 115.3rem;
}

.w-1154 {
  width: 115.4rem;
}

.w-1155 {
  width: 115.5rem;
}

.w-1156 {
  width: 115.6rem;
}

.w-1157 {
  width: 115.7rem;
}

.w-1158 {
  width: 115.8rem;
}

.w-1159 {
  width: 115.9rem;
}

.w-1160 {
  width: 116rem;
}

.w-1161 {
  width: 116.1rem;
}

.w-1162 {
  width: 116.2rem;
}

.w-1163 {
  width: 116.3rem;
}

.w-1164 {
  width: 116.4rem;
}

.w-1165 {
  width: 116.5rem;
}

.w-1166 {
  width: 116.6rem;
}

.w-1167 {
  width: 116.7rem;
}

.w-1168 {
  width: 116.8rem;
}

.w-1169 {
  width: 116.9rem;
}

.w-1170 {
  width: 117rem;
}

.w-1171 {
  width: 117.1rem;
}

.w-1172 {
  width: 117.2rem;
}

.w-1173 {
  width: 117.3rem;
}

.w-1174 {
  width: 117.4rem;
}

.w-1175 {
  width: 117.5rem;
}

.w-1176 {
  width: 117.6rem;
}

.w-1177 {
  width: 117.7rem;
}

.w-1178 {
  width: 117.8rem;
}

.w-1179 {
  width: 117.9rem;
}

.w-1180 {
  width: 118rem;
}

.w-1181 {
  width: 118.1rem;
}

.w-1182 {
  width: 118.2rem;
}

.w-1183 {
  width: 118.3rem;
}

.w-1184 {
  width: 118.4rem;
}

.w-1185 {
  width: 118.5rem;
}

.w-1186 {
  width: 118.6rem;
}

.w-1187 {
  width: 118.7rem;
}

.w-1188 {
  width: 118.8rem;
}

.w-1189 {
  width: 118.9rem;
}

.w-1190 {
  width: 119rem;
}

.w-1191 {
  width: 119.1rem;
}

.w-1192 {
  width: 119.2rem;
}

.w-1193 {
  width: 119.3rem;
}

.w-1194 {
  width: 119.4rem;
}

.w-1195 {
  width: 119.5rem;
}

.w-1196 {
  width: 119.6rem;
}

.w-1197 {
  width: 119.7rem;
}

.w-1198 {
  width: 119.8rem;
}

.w-1199 {
  width: 119.9rem;
}

.w-1200 {
  width: 120rem;
}

.w-1201 {
  width: 120.1rem;
}

.w-1202 {
  width: 120.2rem;
}

.w-1203 {
  width: 120.3rem;
}

.w-1204 {
  width: 120.4rem;
}

.w-1205 {
  width: 120.5rem;
}

.w-1206 {
  width: 120.6rem;
}

.w-1207 {
  width: 120.7rem;
}

.w-1208 {
  width: 120.8rem;
}

.w-1209 {
  width: 120.9rem;
}

.w-1210 {
  width: 121rem;
}

.w-1211 {
  width: 121.1rem;
}

.w-1212 {
  width: 121.2rem;
}

.w-1213 {
  width: 121.3rem;
}

.w-1214 {
  width: 121.4rem;
}

.w-1215 {
  width: 121.5rem;
}

.w-1216 {
  width: 121.6rem;
}

.w-1217 {
  width: 121.7rem;
}

.w-1218 {
  width: 121.8rem;
}

.w-1219 {
  width: 121.9rem;
}

.w-1220 {
  width: 122rem;
}

.w-1221 {
  width: 122.1rem;
}

.w-1222 {
  width: 122.2rem;
}

.w-1223 {
  width: 122.3rem;
}

.w-1224 {
  width: 122.4rem;
}

.w-1225 {
  width: 122.5rem;
}

.w-1226 {
  width: 122.6rem;
}

.w-1227 {
  width: 122.7rem;
}

.w-1228 {
  width: 122.8rem;
}

.w-1229 {
  width: 122.9rem;
}

.w-1230 {
  width: 123rem;
}

.w-1231 {
  width: 123.1rem;
}

.w-1232 {
  width: 123.2rem;
}

.w-1233 {
  width: 123.3rem;
}

.w-1234 {
  width: 123.4rem;
}

.w-1235 {
  width: 123.5rem;
}

.w-1236 {
  width: 123.6rem;
}

.w-1237 {
  width: 123.7rem;
}

.w-1238 {
  width: 123.8rem;
}

.w-1239 {
  width: 123.9rem;
}

.w-1240 {
  width: 124rem;
}

.w-1241 {
  width: 124.1rem;
}

.w-1242 {
  width: 124.2rem;
}

.w-1243 {
  width: 124.3rem;
}

.w-1244 {
  width: 124.4rem;
}

.w-1245 {
  width: 124.5rem;
}

.w-1246 {
  width: 124.6rem;
}

.w-1247 {
  width: 124.7rem;
}

.w-1248 {
  width: 124.8rem;
}

.w-1249 {
  width: 124.9rem;
}

.w-1250 {
  width: 125rem;
}

.w-1251 {
  width: 125.1rem;
}

.w-1252 {
  width: 125.2rem;
}

.w-1253 {
  width: 125.3rem;
}

.w-1254 {
  width: 125.4rem;
}

.w-1255 {
  width: 125.5rem;
}

.w-1256 {
  width: 125.6rem;
}

.w-1257 {
  width: 125.7rem;
}

.w-1258 {
  width: 125.8rem;
}

.w-1259 {
  width: 125.9rem;
}

.w-1260 {
  width: 126rem;
}

.w-1261 {
  width: 126.1rem;
}

.w-1262 {
  width: 126.2rem;
}

.w-1263 {
  width: 126.3rem;
}

.w-1264 {
  width: 126.4rem;
}

.w-1265 {
  width: 126.5rem;
}

.w-1266 {
  width: 126.6rem;
}

.w-1267 {
  width: 126.7rem;
}

.w-1268 {
  width: 126.8rem;
}

.w-1269 {
  width: 126.9rem;
}

.w-1270 {
  width: 127rem;
}

.w-1271 {
  width: 127.1rem;
}

.w-1272 {
  width: 127.2rem;
}

.w-1273 {
  width: 127.3rem;
}

.w-1274 {
  width: 127.4rem;
}

.w-1275 {
  width: 127.5rem;
}

.w-1276 {
  width: 127.6rem;
}

.w-1277 {
  width: 127.7rem;
}

.w-1278 {
  width: 127.8rem;
}

.w-1279 {
  width: 127.9rem;
}

.w-1280 {
  width: 128rem;
}

.w-1281 {
  width: 128.1rem;
}

.w-1282 {
  width: 128.2rem;
}

.w-1283 {
  width: 128.3rem;
}

.w-1284 {
  width: 128.4rem;
}

.w-1285 {
  width: 128.5rem;
}

.w-1286 {
  width: 128.6rem;
}

.w-1287 {
  width: 128.7rem;
}

.w-1288 {
  width: 128.8rem;
}

.w-1289 {
  width: 128.9rem;
}

.w-1290 {
  width: 129rem;
}

.w-1291 {
  width: 129.1rem;
}

.w-1292 {
  width: 129.2rem;
}

.w-1293 {
  width: 129.3rem;
}

.w-1294 {
  width: 129.4rem;
}

.w-1295 {
  width: 129.5rem;
}

.w-1296 {
  width: 129.6rem;
}

.w-1297 {
  width: 129.7rem;
}

.w-1298 {
  width: 129.8rem;
}

.w-1299 {
  width: 129.9rem;
}

.w-1300 {
  width: 130rem;
}

.w-1301 {
  width: 130.1rem;
}

.w-1302 {
  width: 130.2rem;
}

.w-1303 {
  width: 130.3rem;
}

.w-1304 {
  width: 130.4rem;
}

.w-1305 {
  width: 130.5rem;
}

.w-1306 {
  width: 130.6rem;
}

.w-1307 {
  width: 130.7rem;
}

.w-1308 {
  width: 130.8rem;
}

.w-1309 {
  width: 130.9rem;
}

.w-1310 {
  width: 131rem;
}

.w-1311 {
  width: 131.1rem;
}

.w-1312 {
  width: 131.2rem;
}

.w-1313 {
  width: 131.3rem;
}

.w-1314 {
  width: 131.4rem;
}

.w-1315 {
  width: 131.5rem;
}

.w-1316 {
  width: 131.6rem;
}

.w-1317 {
  width: 131.7rem;
}

.w-1318 {
  width: 131.8rem;
}

.w-1319 {
  width: 131.9rem;
}

.w-1320 {
  width: 132rem;
}

.w-1321 {
  width: 132.1rem;
}

.w-1322 {
  width: 132.2rem;
}

.w-1323 {
  width: 132.3rem;
}

.w-1324 {
  width: 132.4rem;
}

.w-1325 {
  width: 132.5rem;
}

.w-1326 {
  width: 132.6rem;
}

.w-1327 {
  width: 132.7rem;
}

.w-1328 {
  width: 132.8rem;
}

.w-1329 {
  width: 132.9rem;
}

.w-1330 {
  width: 133rem;
}

.w-1331 {
  width: 133.1rem;
}

.w-1332 {
  width: 133.2rem;
}

.w-1333 {
  width: 133.3rem;
}

.w-1334 {
  width: 133.4rem;
}

.w-1335 {
  width: 133.5rem;
}

.w-1336 {
  width: 133.6rem;
}

.w-1337 {
  width: 133.7rem;
}

.w-1338 {
  width: 133.8rem;
}

.w-1339 {
  width: 133.9rem;
}

.w-1340 {
  width: 134rem;
}

.w-1341 {
  width: 134.1rem;
}

.w-1342 {
  width: 134.2rem;
}

.w-1343 {
  width: 134.3rem;
}

.w-1344 {
  width: 134.4rem;
}

.w-1345 {
  width: 134.5rem;
}

.w-1346 {
  width: 134.6rem;
}

.w-1347 {
  width: 134.7rem;
}

.w-1348 {
  width: 134.8rem;
}

.w-1349 {
  width: 134.9rem;
}

.w-1350 {
  width: 135rem;
}

.w-1351 {
  width: 135.1rem;
}

.w-1352 {
  width: 135.2rem;
}

.w-1353 {
  width: 135.3rem;
}

.w-1354 {
  width: 135.4rem;
}

.w-1355 {
  width: 135.5rem;
}

.w-1356 {
  width: 135.6rem;
}

.w-1357 {
  width: 135.7rem;
}

.w-1358 {
  width: 135.8rem;
}

.w-1359 {
  width: 135.9rem;
}

.w-1360 {
  width: 136rem;
}

.w-1361 {
  width: 136.1rem;
}

.w-1362 {
  width: 136.2rem;
}

.w-1363 {
  width: 136.3rem;
}

.w-1364 {
  width: 136.4rem;
}

.w-1365 {
  width: 136.5rem;
}

.w-1366 {
  width: 136.6rem;
}

.w-1367 {
  width: 136.7rem;
}

.w-1368 {
  width: 136.8rem;
}

.w-1369 {
  width: 136.9rem;
}

.w-1370 {
  width: 137rem;
}

.w-1371 {
  width: 137.1rem;
}

.w-1372 {
  width: 137.2rem;
}

.w-1373 {
  width: 137.3rem;
}

.w-1374 {
  width: 137.4rem;
}

.w-1375 {
  width: 137.5rem;
}

.w-1376 {
  width: 137.6rem;
}

.w-1377 {
  width: 137.7rem;
}

.w-1378 {
  width: 137.8rem;
}

.w-1379 {
  width: 137.9rem;
}

.w-1380 {
  width: 138rem;
}

.w-1381 {
  width: 138.1rem;
}

.w-1382 {
  width: 138.2rem;
}

.w-1383 {
  width: 138.3rem;
}

.w-1384 {
  width: 138.4rem;
}

.w-1385 {
  width: 138.5rem;
}

.w-1386 {
  width: 138.6rem;
}

.w-1387 {
  width: 138.7rem;
}

.w-1388 {
  width: 138.8rem;
}

.w-1389 {
  width: 138.9rem;
}

.w-1390 {
  width: 139rem;
}

.w-1391 {
  width: 139.1rem;
}

.w-1392 {
  width: 139.2rem;
}

.w-1393 {
  width: 139.3rem;
}

.w-1394 {
  width: 139.4rem;
}

.w-1395 {
  width: 139.5rem;
}

.w-1396 {
  width: 139.6rem;
}

.w-1397 {
  width: 139.7rem;
}

.w-1398 {
  width: 139.8rem;
}

.w-1399 {
  width: 139.9rem;
}

.w-1400 {
  width: 140rem;
}

.w-1401 {
  width: 140.1rem;
}

.w-1402 {
  width: 140.2rem;
}

.w-1403 {
  width: 140.3rem;
}

.w-1404 {
  width: 140.4rem;
}

.w-1405 {
  width: 140.5rem;
}

.w-1406 {
  width: 140.6rem;
}

.w-1407 {
  width: 140.7rem;
}

.w-1408 {
  width: 140.8rem;
}

.w-1409 {
  width: 140.9rem;
}

.w-1410 {
  width: 141rem;
}

.w-1411 {
  width: 141.1rem;
}

.w-1412 {
  width: 141.2rem;
}

.w-1413 {
  width: 141.3rem;
}

.w-1414 {
  width: 141.4rem;
}

.w-1415 {
  width: 141.5rem;
}

.w-1416 {
  width: 141.6rem;
}

.w-1417 {
  width: 141.7rem;
}

.w-1418 {
  width: 141.8rem;
}

.w-1419 {
  width: 141.9rem;
}

.w-1420 {
  width: 142rem;
}

.w-1421 {
  width: 142.1rem;
}

.w-1422 {
  width: 142.2rem;
}

.w-1423 {
  width: 142.3rem;
}

.w-1424 {
  width: 142.4rem;
}

.w-1425 {
  width: 142.5rem;
}

.w-1426 {
  width: 142.6rem;
}

.w-1427 {
  width: 142.7rem;
}

.w-1428 {
  width: 142.8rem;
}

.w-1429 {
  width: 142.9rem;
}

.w-1430 {
  width: 143rem;
}

.w-1431 {
  width: 143.1rem;
}

.w-1432 {
  width: 143.2rem;
}

.w-1433 {
  width: 143.3rem;
}

.w-1434 {
  width: 143.4rem;
}

.w-1435 {
  width: 143.5rem;
}

.w-1436 {
  width: 143.6rem;
}

.w-1437 {
  width: 143.7rem;
}

.w-1438 {
  width: 143.8rem;
}

.w-1439 {
  width: 143.9rem;
}

.w-1440 {
  width: 144rem;
}

.w-1441 {
  width: 144.1rem;
}

.w-1442 {
  width: 144.2rem;
}

.w-1443 {
  width: 144.3rem;
}

.w-1444 {
  width: 144.4rem;
}

.w-1445 {
  width: 144.5rem;
}

.w-1446 {
  width: 144.6rem;
}

.w-1447 {
  width: 144.7rem;
}

.w-1448 {
  width: 144.8rem;
}

.w-1449 {
  width: 144.9rem;
}

.w-1450 {
  width: 145rem;
}

.w-1451 {
  width: 145.1rem;
}

.w-1452 {
  width: 145.2rem;
}

.w-1453 {
  width: 145.3rem;
}

.w-1454 {
  width: 145.4rem;
}

.w-1455 {
  width: 145.5rem;
}

.w-1456 {
  width: 145.6rem;
}

.w-1457 {
  width: 145.7rem;
}

.w-1458 {
  width: 145.8rem;
}

.w-1459 {
  width: 145.9rem;
}

.w-1460 {
  width: 146rem;
}

.w-1461 {
  width: 146.1rem;
}

.w-1462 {
  width: 146.2rem;
}

.w-1463 {
  width: 146.3rem;
}

.w-1464 {
  width: 146.4rem;
}

.w-1465 {
  width: 146.5rem;
}

.w-1466 {
  width: 146.6rem;
}

.w-1467 {
  width: 146.7rem;
}

.w-1468 {
  width: 146.8rem;
}

.w-1469 {
  width: 146.9rem;
}

.w-1470 {
  width: 147rem;
}

.w-1471 {
  width: 147.1rem;
}

.w-1472 {
  width: 147.2rem;
}

.w-1473 {
  width: 147.3rem;
}

.w-1474 {
  width: 147.4rem;
}

.w-1475 {
  width: 147.5rem;
}

.w-1476 {
  width: 147.6rem;
}

.w-1477 {
  width: 147.7rem;
}

.w-1478 {
  width: 147.8rem;
}

.w-1479 {
  width: 147.9rem;
}

.w-1480 {
  width: 148rem;
}

.w-1481 {
  width: 148.1rem;
}

.w-1482 {
  width: 148.2rem;
}

.w-1483 {
  width: 148.3rem;
}

.w-1484 {
  width: 148.4rem;
}

.w-1485 {
  width: 148.5rem;
}

.w-1486 {
  width: 148.6rem;
}

.w-1487 {
  width: 148.7rem;
}

.w-1488 {
  width: 148.8rem;
}

.w-1489 {
  width: 148.9rem;
}

.w-1490 {
  width: 149rem;
}

.w-1491 {
  width: 149.1rem;
}

.w-1492 {
  width: 149.2rem;
}

.w-1493 {
  width: 149.3rem;
}

.w-1494 {
  width: 149.4rem;
}

.w-1495 {
  width: 149.5rem;
}

.w-1496 {
  width: 149.6rem;
}

.w-1497 {
  width: 149.7rem;
}

.w-1498 {
  width: 149.8rem;
}

.w-1499 {
  width: 149.9rem;
}

.w-1500 {
  width: 150rem;
}

.w-1501 {
  width: 150.1rem;
}

.w-1502 {
  width: 150.2rem;
}

.w-1503 {
  width: 150.3rem;
}

.w-1504 {
  width: 150.4rem;
}

.w-1505 {
  width: 150.5rem;
}

.w-1506 {
  width: 150.6rem;
}

.w-1507 {
  width: 150.7rem;
}

.w-1508 {
  width: 150.8rem;
}

.w-1509 {
  width: 150.9rem;
}

.w-1510 {
  width: 151rem;
}

.w-1511 {
  width: 151.1rem;
}

.w-1512 {
  width: 151.2rem;
}

.w-1513 {
  width: 151.3rem;
}

.w-1514 {
  width: 151.4rem;
}

.w-1515 {
  width: 151.5rem;
}

.w-1516 {
  width: 151.6rem;
}

.w-1517 {
  width: 151.7rem;
}

.w-1518 {
  width: 151.8rem;
}

.w-1519 {
  width: 151.9rem;
}

.w-1520 {
  width: 152rem;
}

.w-1521 {
  width: 152.1rem;
}

.w-1522 {
  width: 152.2rem;
}

.w-1523 {
  width: 152.3rem;
}

.w-1524 {
  width: 152.4rem;
}

.w-1525 {
  width: 152.5rem;
}

.w-1526 {
  width: 152.6rem;
}

.w-1527 {
  width: 152.7rem;
}

.w-1528 {
  width: 152.8rem;
}

.w-1529 {
  width: 152.9rem;
}

.w-1530 {
  width: 153rem;
}

.w-1531 {
  width: 153.1rem;
}

.w-1532 {
  width: 153.2rem;
}

.w-1533 {
  width: 153.3rem;
}

.w-1534 {
  width: 153.4rem;
}

.w-1535 {
  width: 153.5rem;
}

.w-1536 {
  width: 153.6rem;
}

.w-1537 {
  width: 153.7rem;
}

.w-1538 {
  width: 153.8rem;
}

.w-1539 {
  width: 153.9rem;
}

.w-1540 {
  width: 154rem;
}

.w-1541 {
  width: 154.1rem;
}

.w-1542 {
  width: 154.2rem;
}

.w-1543 {
  width: 154.3rem;
}

.w-1544 {
  width: 154.4rem;
}

.w-1545 {
  width: 154.5rem;
}

.w-1546 {
  width: 154.6rem;
}

.w-1547 {
  width: 154.7rem;
}

.w-1548 {
  width: 154.8rem;
}

.w-1549 {
  width: 154.9rem;
}

.w-1550 {
  width: 155rem;
}

.w-1551 {
  width: 155.1rem;
}

.w-1552 {
  width: 155.2rem;
}

.w-1553 {
  width: 155.3rem;
}

.w-1554 {
  width: 155.4rem;
}

.w-1555 {
  width: 155.5rem;
}

.w-1556 {
  width: 155.6rem;
}

.w-1557 {
  width: 155.7rem;
}

.w-1558 {
  width: 155.8rem;
}

.w-1559 {
  width: 155.9rem;
}

.w-1560 {
  width: 156rem;
}

.w-1561 {
  width: 156.1rem;
}

.w-1562 {
  width: 156.2rem;
}

.w-1563 {
  width: 156.3rem;
}

.w-1564 {
  width: 156.4rem;
}

.w-1565 {
  width: 156.5rem;
}

.w-1566 {
  width: 156.6rem;
}

.w-1567 {
  width: 156.7rem;
}

.w-1568 {
  width: 156.8rem;
}

.w-1569 {
  width: 156.9rem;
}

.w-1570 {
  width: 157rem;
}

.w-1571 {
  width: 157.1rem;
}

.w-1572 {
  width: 157.2rem;
}

.w-1573 {
  width: 157.3rem;
}

.w-1574 {
  width: 157.4rem;
}

.w-1575 {
  width: 157.5rem;
}

.w-1576 {
  width: 157.6rem;
}

.w-1577 {
  width: 157.7rem;
}

.w-1578 {
  width: 157.8rem;
}

.w-1579 {
  width: 157.9rem;
}

.w-1580 {
  width: 158rem;
}

.w-1581 {
  width: 158.1rem;
}

.w-1582 {
  width: 158.2rem;
}

.w-1583 {
  width: 158.3rem;
}

.w-1584 {
  width: 158.4rem;
}

.w-1585 {
  width: 158.5rem;
}

.w-1586 {
  width: 158.6rem;
}

.w-1587 {
  width: 158.7rem;
}

.w-1588 {
  width: 158.8rem;
}

.w-1589 {
  width: 158.9rem;
}

.w-1590 {
  width: 159rem;
}

.w-1591 {
  width: 159.1rem;
}

.w-1592 {
  width: 159.2rem;
}

.w-1593 {
  width: 159.3rem;
}

.w-1594 {
  width: 159.4rem;
}

.w-1595 {
  width: 159.5rem;
}

.w-1596 {
  width: 159.6rem;
}

.w-1597 {
  width: 159.7rem;
}

.w-1598 {
  width: 159.8rem;
}

.w-1599 {
  width: 159.9rem;
}

.w-1600 {
  width: 160rem;
}

.w-1601 {
  width: 160.1rem;
}

.w-1602 {
  width: 160.2rem;
}

.w-1603 {
  width: 160.3rem;
}

.w-1604 {
  width: 160.4rem;
}

.w-1605 {
  width: 160.5rem;
}

.w-1606 {
  width: 160.6rem;
}

.w-1607 {
  width: 160.7rem;
}

.w-1608 {
  width: 160.8rem;
}

.w-1609 {
  width: 160.9rem;
}

.w-1610 {
  width: 161rem;
}

.w-1611 {
  width: 161.1rem;
}

.w-1612 {
  width: 161.2rem;
}

.w-1613 {
  width: 161.3rem;
}

.w-1614 {
  width: 161.4rem;
}

.w-1615 {
  width: 161.5rem;
}

.w-1616 {
  width: 161.6rem;
}

.w-1617 {
  width: 161.7rem;
}

.w-1618 {
  width: 161.8rem;
}

.w-1619 {
  width: 161.9rem;
}

.w-1620 {
  width: 162rem;
}

.w-1621 {
  width: 162.1rem;
}

.w-1622 {
  width: 162.2rem;
}

.w-1623 {
  width: 162.3rem;
}

.w-1624 {
  width: 162.4rem;
}

.w-1625 {
  width: 162.5rem;
}

.w-1626 {
  width: 162.6rem;
}

.w-1627 {
  width: 162.7rem;
}

.w-1628 {
  width: 162.8rem;
}

.w-1629 {
  width: 162.9rem;
}

.w-1630 {
  width: 163rem;
}

.w-1631 {
  width: 163.1rem;
}

.w-1632 {
  width: 163.2rem;
}

.w-1633 {
  width: 163.3rem;
}

.w-1634 {
  width: 163.4rem;
}

.w-1635 {
  width: 163.5rem;
}

.w-1636 {
  width: 163.6rem;
}

.w-1637 {
  width: 163.7rem;
}

.w-1638 {
  width: 163.8rem;
}

.w-1639 {
  width: 163.9rem;
}

.w-1640 {
  width: 164rem;
}

.w-1641 {
  width: 164.1rem;
}

.w-1642 {
  width: 164.2rem;
}

.w-1643 {
  width: 164.3rem;
}

.w-1644 {
  width: 164.4rem;
}

.w-1645 {
  width: 164.5rem;
}

.w-1646 {
  width: 164.6rem;
}

.w-1647 {
  width: 164.7rem;
}

.w-1648 {
  width: 164.8rem;
}

.w-1649 {
  width: 164.9rem;
}

.w-1650 {
  width: 165rem;
}

.w-1651 {
  width: 165.1rem;
}

.w-1652 {
  width: 165.2rem;
}

.w-1653 {
  width: 165.3rem;
}

.w-1654 {
  width: 165.4rem;
}

.w-1655 {
  width: 165.5rem;
}

.w-1656 {
  width: 165.6rem;
}

.w-1657 {
  width: 165.7rem;
}

.w-1658 {
  width: 165.8rem;
}

.w-1659 {
  width: 165.9rem;
}

.w-1660 {
  width: 166rem;
}

.w-1661 {
  width: 166.1rem;
}

.w-1662 {
  width: 166.2rem;
}

.w-1663 {
  width: 166.3rem;
}

.w-1664 {
  width: 166.4rem;
}

.w-1665 {
  width: 166.5rem;
}

.w-1666 {
  width: 166.6rem;
}

.w-1667 {
  width: 166.7rem;
}

.w-1668 {
  width: 166.8rem;
}

.w-1669 {
  width: 166.9rem;
}

.w-1670 {
  width: 167rem;
}

.w-1671 {
  width: 167.1rem;
}

.w-1672 {
  width: 167.2rem;
}

.w-1673 {
  width: 167.3rem;
}

.w-1674 {
  width: 167.4rem;
}

.w-1675 {
  width: 167.5rem;
}

.w-1676 {
  width: 167.6rem;
}

.w-1677 {
  width: 167.7rem;
}

.w-1678 {
  width: 167.8rem;
}

.w-1679 {
  width: 167.9rem;
}

.w-1680 {
  width: 168rem;
}

.w-1681 {
  width: 168.1rem;
}

.w-1682 {
  width: 168.2rem;
}

.w-1683 {
  width: 168.3rem;
}

.w-1684 {
  width: 168.4rem;
}

.w-1685 {
  width: 168.5rem;
}

.w-1686 {
  width: 168.6rem;
}

.w-1687 {
  width: 168.7rem;
}

.w-1688 {
  width: 168.8rem;
}

.w-1689 {
  width: 168.9rem;
}

.w-1690 {
  width: 169rem;
}

.w-1691 {
  width: 169.1rem;
}

.w-1692 {
  width: 169.2rem;
}

.w-1693 {
  width: 169.3rem;
}

.w-1694 {
  width: 169.4rem;
}

.w-1695 {
  width: 169.5rem;
}

.w-1696 {
  width: 169.6rem;
}

.w-1697 {
  width: 169.7rem;
}

.w-1698 {
  width: 169.8rem;
}

.w-1699 {
  width: 169.9rem;
}

.w-1700 {
  width: 170rem;
}

.w-1701 {
  width: 170.1rem;
}

.w-1702 {
  width: 170.2rem;
}

.w-1703 {
  width: 170.3rem;
}

.w-1704 {
  width: 170.4rem;
}

.w-1705 {
  width: 170.5rem;
}

.w-1706 {
  width: 170.6rem;
}

.w-1707 {
  width: 170.7rem;
}

.w-1708 {
  width: 170.8rem;
}

.w-1709 {
  width: 170.9rem;
}

.w-1710 {
  width: 171rem;
}

.w-1711 {
  width: 171.1rem;
}

.w-1712 {
  width: 171.2rem;
}

.w-1713 {
  width: 171.3rem;
}

.w-1714 {
  width: 171.4rem;
}

.w-1715 {
  width: 171.5rem;
}

.w-1716 {
  width: 171.6rem;
}

.w-1717 {
  width: 171.7rem;
}

.w-1718 {
  width: 171.8rem;
}

.w-1719 {
  width: 171.9rem;
}

.w-1720 {
  width: 172rem;
}

.w-1721 {
  width: 172.1rem;
}

.w-1722 {
  width: 172.2rem;
}

.w-1723 {
  width: 172.3rem;
}

.w-1724 {
  width: 172.4rem;
}

.w-1725 {
  width: 172.5rem;
}

.w-1726 {
  width: 172.6rem;
}

.w-1727 {
  width: 172.7rem;
}

.w-1728 {
  width: 172.8rem;
}

.w-1729 {
  width: 172.9rem;
}

.w-1730 {
  width: 173rem;
}

.w-1731 {
  width: 173.1rem;
}

.w-1732 {
  width: 173.2rem;
}

.w-1733 {
  width: 173.3rem;
}

.w-1734 {
  width: 173.4rem;
}

.w-1735 {
  width: 173.5rem;
}

.w-1736 {
  width: 173.6rem;
}

.w-1737 {
  width: 173.7rem;
}

.w-1738 {
  width: 173.8rem;
}

.w-1739 {
  width: 173.9rem;
}

.w-1740 {
  width: 174rem;
}

.w-1741 {
  width: 174.1rem;
}

.w-1742 {
  width: 174.2rem;
}

.w-1743 {
  width: 174.3rem;
}

.w-1744 {
  width: 174.4rem;
}

.w-1745 {
  width: 174.5rem;
}

.w-1746 {
  width: 174.6rem;
}

.w-1747 {
  width: 174.7rem;
}

.w-1748 {
  width: 174.8rem;
}

.w-1749 {
  width: 174.9rem;
}

.w-1750 {
  width: 175rem;
}

.w-1751 {
  width: 175.1rem;
}

.w-1752 {
  width: 175.2rem;
}

.w-1753 {
  width: 175.3rem;
}

.w-1754 {
  width: 175.4rem;
}

.w-1755 {
  width: 175.5rem;
}

.w-1756 {
  width: 175.6rem;
}

.w-1757 {
  width: 175.7rem;
}

.w-1758 {
  width: 175.8rem;
}

.w-1759 {
  width: 175.9rem;
}

.w-1760 {
  width: 176rem;
}

.w-1761 {
  width: 176.1rem;
}

.w-1762 {
  width: 176.2rem;
}

.w-1763 {
  width: 176.3rem;
}

.w-1764 {
  width: 176.4rem;
}

.w-1765 {
  width: 176.5rem;
}

.w-1766 {
  width: 176.6rem;
}

.w-1767 {
  width: 176.7rem;
}

.w-1768 {
  width: 176.8rem;
}

.w-1769 {
  width: 176.9rem;
}

.w-1770 {
  width: 177rem;
}

.w-1771 {
  width: 177.1rem;
}

.w-1772 {
  width: 177.2rem;
}

.w-1773 {
  width: 177.3rem;
}

.w-1774 {
  width: 177.4rem;
}

.w-1775 {
  width: 177.5rem;
}

.w-1776 {
  width: 177.6rem;
}

.w-1777 {
  width: 177.7rem;
}

.w-1778 {
  width: 177.8rem;
}

.w-1779 {
  width: 177.9rem;
}

.w-1780 {
  width: 178rem;
}

.w-1781 {
  width: 178.1rem;
}

.w-1782 {
  width: 178.2rem;
}

.w-1783 {
  width: 178.3rem;
}

.w-1784 {
  width: 178.4rem;
}

.w-1785 {
  width: 178.5rem;
}

.w-1786 {
  width: 178.6rem;
}

.w-1787 {
  width: 178.7rem;
}

.w-1788 {
  width: 178.8rem;
}

.w-1789 {
  width: 178.9rem;
}

.w-1790 {
  width: 179rem;
}

.w-1791 {
  width: 179.1rem;
}

.w-1792 {
  width: 179.2rem;
}

.w-1793 {
  width: 179.3rem;
}

.w-1794 {
  width: 179.4rem;
}

.w-1795 {
  width: 179.5rem;
}

.w-1796 {
  width: 179.6rem;
}

.w-1797 {
  width: 179.7rem;
}

.w-1798 {
  width: 179.8rem;
}

.w-1799 {
  width: 179.9rem;
}

.w-1800 {
  width: 180rem;
}

.w-1801 {
  width: 180.1rem;
}

.w-1802 {
  width: 180.2rem;
}

.w-1803 {
  width: 180.3rem;
}

.w-1804 {
  width: 180.4rem;
}

.w-1805 {
  width: 180.5rem;
}

.w-1806 {
  width: 180.6rem;
}

.w-1807 {
  width: 180.7rem;
}

.w-1808 {
  width: 180.8rem;
}

.w-1809 {
  width: 180.9rem;
}

.w-1810 {
  width: 181rem;
}

.w-1811 {
  width: 181.1rem;
}

.w-1812 {
  width: 181.2rem;
}

.w-1813 {
  width: 181.3rem;
}

.w-1814 {
  width: 181.4rem;
}

.w-1815 {
  width: 181.5rem;
}

.w-1816 {
  width: 181.6rem;
}

.w-1817 {
  width: 181.7rem;
}

.w-1818 {
  width: 181.8rem;
}

.w-1819 {
  width: 181.9rem;
}

.w-1820 {
  width: 182rem;
}

.w-1821 {
  width: 182.1rem;
}

.w-1822 {
  width: 182.2rem;
}

.w-1823 {
  width: 182.3rem;
}

.w-1824 {
  width: 182.4rem;
}

.w-1825 {
  width: 182.5rem;
}

.w-1826 {
  width: 182.6rem;
}

.w-1827 {
  width: 182.7rem;
}

.w-1828 {
  width: 182.8rem;
}

.w-1829 {
  width: 182.9rem;
}

.w-1830 {
  width: 183rem;
}

.w-1831 {
  width: 183.1rem;
}

.w-1832 {
  width: 183.2rem;
}

.w-1833 {
  width: 183.3rem;
}

.w-1834 {
  width: 183.4rem;
}

.w-1835 {
  width: 183.5rem;
}

.w-1836 {
  width: 183.6rem;
}

.w-1837 {
  width: 183.7rem;
}

.w-1838 {
  width: 183.8rem;
}

.w-1839 {
  width: 183.9rem;
}

.w-1840 {
  width: 184rem;
}

.w-1841 {
  width: 184.1rem;
}

.w-1842 {
  width: 184.2rem;
}

.w-1843 {
  width: 184.3rem;
}

.w-1844 {
  width: 184.4rem;
}

.w-1845 {
  width: 184.5rem;
}

.w-1846 {
  width: 184.6rem;
}

.w-1847 {
  width: 184.7rem;
}

.w-1848 {
  width: 184.8rem;
}

.w-1849 {
  width: 184.9rem;
}

.w-1850 {
  width: 185rem;
}

.w-1851 {
  width: 185.1rem;
}

.w-1852 {
  width: 185.2rem;
}

.w-1853 {
  width: 185.3rem;
}

.w-1854 {
  width: 185.4rem;
}

.w-1855 {
  width: 185.5rem;
}

.w-1856 {
  width: 185.6rem;
}

.w-1857 {
  width: 185.7rem;
}

.w-1858 {
  width: 185.8rem;
}

.w-1859 {
  width: 185.9rem;
}

.w-1860 {
  width: 186rem;
}

.w-1861 {
  width: 186.1rem;
}

.w-1862 {
  width: 186.2rem;
}

.w-1863 {
  width: 186.3rem;
}

.w-1864 {
  width: 186.4rem;
}

.w-1865 {
  width: 186.5rem;
}

.w-1866 {
  width: 186.6rem;
}

.w-1867 {
  width: 186.7rem;
}

.w-1868 {
  width: 186.8rem;
}

.w-1869 {
  width: 186.9rem;
}

.w-1870 {
  width: 187rem;
}

.w-1871 {
  width: 187.1rem;
}

.w-1872 {
  width: 187.2rem;
}

.w-1873 {
  width: 187.3rem;
}

.w-1874 {
  width: 187.4rem;
}

.w-1875 {
  width: 187.5rem;
}

.w-1876 {
  width: 187.6rem;
}

.w-1877 {
  width: 187.7rem;
}

.w-1878 {
  width: 187.8rem;
}

.w-1879 {
  width: 187.9rem;
}

.w-1880 {
  width: 188rem;
}

.w-1881 {
  width: 188.1rem;
}

.w-1882 {
  width: 188.2rem;
}

.w-1883 {
  width: 188.3rem;
}

.w-1884 {
  width: 188.4rem;
}

.w-1885 {
  width: 188.5rem;
}

.w-1886 {
  width: 188.6rem;
}

.w-1887 {
  width: 188.7rem;
}

.w-1888 {
  width: 188.8rem;
}

.w-1889 {
  width: 188.9rem;
}

.w-1890 {
  width: 189rem;
}

.w-1891 {
  width: 189.1rem;
}

.w-1892 {
  width: 189.2rem;
}

.w-1893 {
  width: 189.3rem;
}

.w-1894 {
  width: 189.4rem;
}

.w-1895 {
  width: 189.5rem;
}

.w-1896 {
  width: 189.6rem;
}

.w-1897 {
  width: 189.7rem;
}

.w-1898 {
  width: 189.8rem;
}

.w-1899 {
  width: 189.9rem;
}

.w-1900 {
  width: 190rem;
}

.w-1901 {
  width: 190.1rem;
}

.w-1902 {
  width: 190.2rem;
}

.w-1903 {
  width: 190.3rem;
}

.w-1904 {
  width: 190.4rem;
}

.w-1905 {
  width: 190.5rem;
}

.w-1906 {
  width: 190.6rem;
}

.w-1907 {
  width: 190.7rem;
}

.w-1908 {
  width: 190.8rem;
}

.w-1909 {
  width: 190.9rem;
}

.w-1910 {
  width: 191rem;
}

.w-1911 {
  width: 191.1rem;
}

.w-1912 {
  width: 191.2rem;
}

.w-1913 {
  width: 191.3rem;
}

.w-1914 {
  width: 191.4rem;
}

.w-1915 {
  width: 191.5rem;
}

.w-1916 {
  width: 191.6rem;
}

.w-1917 {
  width: 191.7rem;
}

.w-1918 {
  width: 191.8rem;
}

.w-1919 {
  width: 191.9rem;
}

.w-1920 {
  width: 192rem;
}

.w-1921 {
  width: 192.1rem;
}

.w-1922 {
  width: 192.2rem;
}

.w-1923 {
  width: 192.3rem;
}

.w-1924 {
  width: 192.4rem;
}

.w-1925 {
  width: 192.5rem;
}

.w-1926 {
  width: 192.6rem;
}

.w-1927 {
  width: 192.7rem;
}

.w-1928 {
  width: 192.8rem;
}

.w-1929 {
  width: 192.9rem;
}

.w-1930 {
  width: 193rem;
}

.w-1931 {
  width: 193.1rem;
}

.w-1932 {
  width: 193.2rem;
}

.w-1933 {
  width: 193.3rem;
}

.w-1934 {
  width: 193.4rem;
}

.w-1935 {
  width: 193.5rem;
}

.w-1936 {
  width: 193.6rem;
}

.w-1937 {
  width: 193.7rem;
}

.w-1938 {
  width: 193.8rem;
}

.w-1939 {
  width: 193.9rem;
}

.w-1940 {
  width: 194rem;
}

.w-1941 {
  width: 194.1rem;
}

.w-1942 {
  width: 194.2rem;
}

.w-1943 {
  width: 194.3rem;
}

.w-1944 {
  width: 194.4rem;
}

.w-1945 {
  width: 194.5rem;
}

.w-1946 {
  width: 194.6rem;
}

.w-1947 {
  width: 194.7rem;
}

.w-1948 {
  width: 194.8rem;
}

.w-1949 {
  width: 194.9rem;
}

.w-1950 {
  width: 195rem;
}

.w-1951 {
  width: 195.1rem;
}

.w-1952 {
  width: 195.2rem;
}

.w-1953 {
  width: 195.3rem;
}

.w-1954 {
  width: 195.4rem;
}

.w-1955 {
  width: 195.5rem;
}

.w-1956 {
  width: 195.6rem;
}

.w-1957 {
  width: 195.7rem;
}

.w-1958 {
  width: 195.8rem;
}

.w-1959 {
  width: 195.9rem;
}

.w-1960 {
  width: 196rem;
}

.w-1961 {
  width: 196.1rem;
}

.w-1962 {
  width: 196.2rem;
}

.w-1963 {
  width: 196.3rem;
}

.w-1964 {
  width: 196.4rem;
}

.w-1965 {
  width: 196.5rem;
}

.w-1966 {
  width: 196.6rem;
}

.w-1967 {
  width: 196.7rem;
}

.w-1968 {
  width: 196.8rem;
}

.w-1969 {
  width: 196.9rem;
}

.w-1970 {
  width: 197rem;
}

.w-1971 {
  width: 197.1rem;
}

.w-1972 {
  width: 197.2rem;
}

.w-1973 {
  width: 197.3rem;
}

.w-1974 {
  width: 197.4rem;
}

.w-1975 {
  width: 197.5rem;
}

.w-1976 {
  width: 197.6rem;
}

.w-1977 {
  width: 197.7rem;
}

.w-1978 {
  width: 197.8rem;
}

.w-1979 {
  width: 197.9rem;
}

.w-1980 {
  width: 198rem;
}

.w-1981 {
  width: 198.1rem;
}

.w-1982 {
  width: 198.2rem;
}

.w-1983 {
  width: 198.3rem;
}

.w-1984 {
  width: 198.4rem;
}

.w-1985 {
  width: 198.5rem;
}

.w-1986 {
  width: 198.6rem;
}

.w-1987 {
  width: 198.7rem;
}

.w-1988 {
  width: 198.8rem;
}

.w-1989 {
  width: 198.9rem;
}

.w-1990 {
  width: 199rem;
}

.w-1991 {
  width: 199.1rem;
}

.w-1992 {
  width: 199.2rem;
}

.w-1993 {
  width: 199.3rem;
}

.w-1994 {
  width: 199.4rem;
}

.w-1995 {
  width: 199.5rem;
}

.w-1996 {
  width: 199.6rem;
}

.w-1997 {
  width: 199.7rem;
}

.w-1998 {
  width: 199.8rem;
}

.w-1999 {
  width: 199.9rem;
}

.wp-0 {
  width: 0%;
}

.wp-1 {
  width: 1%;
}

.wp-2 {
  width: 2%;
}

.wp-3 {
  width: 3%;
}

.wp-4 {
  width: 4%;
}

.wp-5 {
  width: 5%;
}

.wp-6 {
  width: 6%;
}

.wp-7 {
  width: 7%;
}

.wp-8 {
  width: 8%;
}

.wp-9 {
  width: 9%;
}

.wp-10 {
  width: 10%;
}

.wp-11 {
  width: 11%;
}

.wp-12 {
  width: 12%;
}

.wp-13 {
  width: 13%;
}

.wp-14 {
  width: 14%;
}

.wp-15 {
  width: 15%;
}

.wp-16 {
  width: 16%;
}

.wp-17 {
  width: 17%;
}

.wp-18 {
  width: 18%;
}

.wp-19 {
  width: 19%;
}

.wp-20 {
  width: 20%;
}

.wp-21 {
  width: 21%;
}

.wp-22 {
  width: 22%;
}

.wp-23 {
  width: 23%;
}

.wp-24 {
  width: 24%;
}

.wp-25 {
  width: 25%;
}

.wp-26 {
  width: 26%;
}

.wp-27 {
  width: 27%;
}

.wp-28 {
  width: 28%;
}

.wp-29 {
  width: 29%;
}

.wp-30 {
  width: 30%;
}

.wp-31 {
  width: 31%;
}

.wp-32 {
  width: 32%;
}

.wp-33 {
  width: 33%;
}

.wp-34 {
  width: 34%;
}

.wp-35 {
  width: 35%;
}

.wp-36 {
  width: 36%;
}

.wp-37 {
  width: 37%;
}

.wp-38 {
  width: 38%;
}

.wp-39 {
  width: 39%;
}

.wp-40 {
  width: 40%;
}

.wp-41 {
  width: 41%;
}

.wp-42 {
  width: 42%;
}

.wp-43 {
  width: 43%;
}

.wp-44 {
  width: 44%;
}

.wp-45 {
  width: 45%;
}

.wp-46 {
  width: 46%;
}

.wp-47 {
  width: 47%;
}

.wp-48 {
  width: 48%;
}

.wp-49 {
  width: 49%;
}

.wp-50 {
  width: 50%;
}

.wp-51 {
  width: 51%;
}

.wp-52 {
  width: 52%;
}

.wp-53 {
  width: 53%;
}

.wp-54 {
  width: 54%;
}

.wp-55 {
  width: 55%;
}

.wp-56 {
  width: 56%;
}

.wp-57 {
  width: 57%;
}

.wp-58 {
  width: 58%;
}

.wp-59 {
  width: 59%;
}

.wp-60 {
  width: 60%;
}

.wp-61 {
  width: 61%;
}

.wp-62 {
  width: 62%;
}

.wp-63 {
  width: 63%;
}

.wp-64 {
  width: 64%;
}

.wp-65 {
  width: 65%;
}

.wp-66 {
  width: 66%;
}

.wp-67 {
  width: 67%;
}

.wp-68 {
  width: 68%;
}

.wp-69 {
  width: 69%;
}

.wp-70 {
  width: 70%;
}

.wp-71 {
  width: 71%;
}

.wp-72 {
  width: 72%;
}

.wp-73 {
  width: 73%;
}

.wp-74 {
  width: 74%;
}

.wp-75 {
  width: 75%;
}

.wp-76 {
  width: 76%;
}

.wp-77 {
  width: 77%;
}

.wp-78 {
  width: 78%;
}

.wp-79 {
  width: 79%;
}

.wp-80 {
  width: 80%;
}

.wp-81 {
  width: 81%;
}

.wp-82 {
  width: 82%;
}

.wp-83 {
  width: 83%;
}

.wp-84 {
  width: 84%;
}

.wp-85 {
  width: 85%;
}

.wp-86 {
  width: 86%;
}

.wp-87 {
  width: 87%;
}

.wp-88 {
  width: 88%;
}

.wp-89 {
  width: 89%;
}

.wp-90 {
  width: 90%;
}

.wp-91 {
  width: 91%;
}

.wp-92 {
  width: 92%;
}

.wp-93 {
  width: 93%;
}

.wp-94 {
  width: 94%;
}

.wp-95 {
  width: 95%;
}

.wp-96 {
  width: 96%;
}

.wp-97 {
  width: 97%;
}

.wp-98 {
  width: 98%;
}

.wp-99 {
  width: 99%;
}

.wp-100 {
  width: 100%;
}

.wv-0 {
  width: 0vw;
}

.wv-1 {
  width: 1vw;
}

.wv-2 {
  width: 2vw;
}

.wv-3 {
  width: 3vw;
}

.wv-4 {
  width: 4vw;
}

.wv-5 {
  width: 5vw;
}

.wv-6 {
  width: 6vw;
}

.wv-7 {
  width: 7vw;
}

.wv-8 {
  width: 8vw;
}

.wv-9 {
  width: 9vw;
}

.wv-10 {
  width: 10vw;
}

.wv-11 {
  width: 11vw;
}

.wv-12 {
  width: 12vw;
}

.wv-13 {
  width: 13vw;
}

.wv-14 {
  width: 14vw;
}

.wv-15 {
  width: 15vw;
}

.wv-16 {
  width: 16vw;
}

.wv-17 {
  width: 17vw;
}

.wv-18 {
  width: 18vw;
}

.wv-19 {
  width: 19vw;
}

.wv-20 {
  width: 20vw;
}

.wv-21 {
  width: 21vw;
}

.wv-22 {
  width: 22vw;
}

.wv-23 {
  width: 23vw;
}

.wv-24 {
  width: 24vw;
}

.wv-25 {
  width: 25vw;
}

.wv-26 {
  width: 26vw;
}

.wv-27 {
  width: 27vw;
}

.wv-28 {
  width: 28vw;
}

.wv-29 {
  width: 29vw;
}

.wv-30 {
  width: 30vw;
}

.wv-31 {
  width: 31vw;
}

.wv-32 {
  width: 32vw;
}

.wv-33 {
  width: 33vw;
}

.wv-34 {
  width: 34vw;
}

.wv-35 {
  width: 35vw;
}

.wv-36 {
  width: 36vw;
}

.wv-37 {
  width: 37vw;
}

.wv-38 {
  width: 38vw;
}

.wv-39 {
  width: 39vw;
}

.wv-40 {
  width: 40vw;
}

.wv-41 {
  width: 41vw;
}

.wv-42 {
  width: 42vw;
}

.wv-43 {
  width: 43vw;
}

.wv-44 {
  width: 44vw;
}

.wv-45 {
  width: 45vw;
}

.wv-46 {
  width: 46vw;
}

.wv-47 {
  width: 47vw;
}

.wv-48 {
  width: 48vw;
}

.wv-49 {
  width: 49vw;
}

.wv-50 {
  width: 50vw;
}

.wv-51 {
  width: 51vw;
}

.wv-52 {
  width: 52vw;
}

.wv-53 {
  width: 53vw;
}

.wv-54 {
  width: 54vw;
}

.wv-55 {
  width: 55vw;
}

.wv-56 {
  width: 56vw;
}

.wv-57 {
  width: 57vw;
}

.wv-58 {
  width: 58vw;
}

.wv-59 {
  width: 59vw;
}

.wv-60 {
  width: 60vw;
}

.wv-61 {
  width: 61vw;
}

.wv-62 {
  width: 62vw;
}

.wv-63 {
  width: 63vw;
}

.wv-64 {
  width: 64vw;
}

.wv-65 {
  width: 65vw;
}

.wv-66 {
  width: 66vw;
}

.wv-67 {
  width: 67vw;
}

.wv-68 {
  width: 68vw;
}

.wv-69 {
  width: 69vw;
}

.wv-70 {
  width: 70vw;
}

.wv-71 {
  width: 71vw;
}

.wv-72 {
  width: 72vw;
}

.wv-73 {
  width: 73vw;
}

.wv-74 {
  width: 74vw;
}

.wv-75 {
  width: 75vw;
}

.wv-76 {
  width: 76vw;
}

.wv-77 {
  width: 77vw;
}

.wv-78 {
  width: 78vw;
}

.wv-79 {
  width: 79vw;
}

.wv-80 {
  width: 80vw;
}

.wv-81 {
  width: 81vw;
}

.wv-82 {
  width: 82vw;
}

.wv-83 {
  width: 83vw;
}

.wv-84 {
  width: 84vw;
}

.wv-85 {
  width: 85vw;
}

.wv-86 {
  width: 86vw;
}

.wv-87 {
  width: 87vw;
}

.wv-88 {
  width: 88vw;
}

.wv-89 {
  width: 89vw;
}

.wv-90 {
  width: 90vw;
}

.wv-91 {
  width: 91vw;
}

.wv-92 {
  width: 92vw;
}

.wv-93 {
  width: 93vw;
}

.wv-94 {
  width: 94vw;
}

.wv-95 {
  width: 95vw;
}

.wv-96 {
  width: 96vw;
}

.wv-97 {
  width: 97vw;
}

.wv-98 {
  width: 98vw;
}

.wv-99 {
  width: 99vw;
}

.wv-100 {
  width: 100vw;
}

.h-0 {
  height: 0rem;
}

.h-1 {
  height: 0.1rem;
}

.h-2 {
  height: 0.2rem;
}

.h-3 {
  height: 0.3rem;
}

.h-4 {
  height: 0.4rem;
}

.h-5 {
  height: 0.5rem;
}

.h-6 {
  height: 0.6rem;
}

.h-7 {
  height: 0.7rem;
}

.h-8 {
  height: 0.8rem;
}

.h-9 {
  height: 0.9rem;
}

.h-10 {
  height: 1rem;
}

.h-11 {
  height: 1.1rem;
}

.h-12 {
  height: 1.2rem;
}

.h-13 {
  height: 1.3rem;
}

.h-14 {
  height: 1.4rem;
}

.h-15 {
  height: 1.5rem;
}

.h-16 {
  height: 1.6rem;
}

.h-17 {
  height: 1.7rem;
}

.h-18 {
  height: 1.8rem;
}

.h-19 {
  height: 1.9rem;
}

.h-20 {
  height: 2rem;
}

.h-21 {
  height: 2.1rem;
}

.h-22 {
  height: 2.2rem;
}

.h-23 {
  height: 2.3rem;
}

.h-24 {
  height: 2.4rem;
}

.h-25 {
  height: 2.5rem;
}

.h-26 {
  height: 2.6rem;
}

.h-27 {
  height: 2.7rem;
}

.h-28 {
  height: 2.8rem;
}

.h-29 {
  height: 2.9rem;
}

.h-30 {
  height: 3rem;
}

.h-31 {
  height: 3.1rem;
}

.h-32 {
  height: 3.2rem;
}

.h-33 {
  height: 3.3rem;
}

.h-34 {
  height: 3.4rem;
}

.h-35 {
  height: 3.5rem;
}

.h-36 {
  height: 3.6rem;
}

.h-37 {
  height: 3.7rem;
}

.h-38 {
  height: 3.8rem;
}

.h-39 {
  height: 3.9rem;
}

.h-40 {
  height: 4rem;
}

.h-41 {
  height: 4.1rem;
}

.h-42 {
  height: 4.2rem;
}

.h-43 {
  height: 4.3rem;
}

.h-44 {
  height: 4.4rem;
}

.h-45 {
  height: 4.5rem;
}

.h-46 {
  height: 4.6rem;
}

.h-47 {
  height: 4.7rem;
}

.h-48 {
  height: 4.8rem;
}

.h-49 {
  height: 4.9rem;
}

.h-50 {
  height: 5rem;
}

.h-51 {
  height: 5.1rem;
}

.h-52 {
  height: 5.2rem;
}

.h-53 {
  height: 5.3rem;
}

.h-54 {
  height: 5.4rem;
}

.h-55 {
  height: 5.5rem;
}

.h-56 {
  height: 5.6rem;
}

.h-57 {
  height: 5.7rem;
}

.h-58 {
  height: 5.8rem;
}

.h-59 {
  height: 5.9rem;
}

.h-60 {
  height: 6rem;
}

.h-61 {
  height: 6.1rem;
}

.h-62 {
  height: 6.2rem;
}

.h-63 {
  height: 6.3rem;
}

.h-64 {
  height: 6.4rem;
}

.h-65 {
  height: 6.5rem;
}

.h-66 {
  height: 6.6rem;
}

.h-67 {
  height: 6.7rem;
}

.h-68 {
  height: 6.8rem;
}

.h-69 {
  height: 6.9rem;
}

.h-70 {
  height: 7rem;
}

.h-71 {
  height: 7.1rem;
}

.h-72 {
  height: 7.2rem;
}

.h-73 {
  height: 7.3rem;
}

.h-74 {
  height: 7.4rem;
}

.h-75 {
  height: 7.5rem;
}

.h-76 {
  height: 7.6rem;
}

.h-77 {
  height: 7.7rem;
}

.h-78 {
  height: 7.8rem;
}

.h-79 {
  height: 7.9rem;
}

.h-80 {
  height: 8rem;
}

.h-81 {
  height: 8.1rem;
}

.h-82 {
  height: 8.2rem;
}

.h-83 {
  height: 8.3rem;
}

.h-84 {
  height: 8.4rem;
}

.h-85 {
  height: 8.5rem;
}

.h-86 {
  height: 8.6rem;
}

.h-87 {
  height: 8.7rem;
}

.h-88 {
  height: 8.8rem;
}

.h-89 {
  height: 8.9rem;
}

.h-90 {
  height: 9rem;
}

.h-91 {
  height: 9.1rem;
}

.h-92 {
  height: 9.2rem;
}

.h-93 {
  height: 9.3rem;
}

.h-94 {
  height: 9.4rem;
}

.h-95 {
  height: 9.5rem;
}

.h-96 {
  height: 9.6rem;
}

.h-97 {
  height: 9.7rem;
}

.h-98 {
  height: 9.8rem;
}

.h-99 {
  height: 9.9rem;
}

.h-100 {
  height: 10rem;
}

.h-101 {
  height: 10.1rem;
}

.h-102 {
  height: 10.2rem;
}

.h-103 {
  height: 10.3rem;
}

.h-104 {
  height: 10.4rem;
}

.h-105 {
  height: 10.5rem;
}

.h-106 {
  height: 10.6rem;
}

.h-107 {
  height: 10.7rem;
}

.h-108 {
  height: 10.8rem;
}

.h-109 {
  height: 10.9rem;
}

.h-110 {
  height: 11rem;
}

.h-111 {
  height: 11.1rem;
}

.h-112 {
  height: 11.2rem;
}

.h-113 {
  height: 11.3rem;
}

.h-114 {
  height: 11.4rem;
}

.h-115 {
  height: 11.5rem;
}

.h-116 {
  height: 11.6rem;
}

.h-117 {
  height: 11.7rem;
}

.h-118 {
  height: 11.8rem;
}

.h-119 {
  height: 11.9rem;
}

.h-120 {
  height: 12rem;
}

.h-121 {
  height: 12.1rem;
}

.h-122 {
  height: 12.2rem;
}

.h-123 {
  height: 12.3rem;
}

.h-124 {
  height: 12.4rem;
}

.h-125 {
  height: 12.5rem;
}

.h-126 {
  height: 12.6rem;
}

.h-127 {
  height: 12.7rem;
}

.h-128 {
  height: 12.8rem;
}

.h-129 {
  height: 12.9rem;
}

.h-130 {
  height: 13rem;
}

.h-131 {
  height: 13.1rem;
}

.h-132 {
  height: 13.2rem;
}

.h-133 {
  height: 13.3rem;
}

.h-134 {
  height: 13.4rem;
}

.h-135 {
  height: 13.5rem;
}

.h-136 {
  height: 13.6rem;
}

.h-137 {
  height: 13.7rem;
}

.h-138 {
  height: 13.8rem;
}

.h-139 {
  height: 13.9rem;
}

.h-140 {
  height: 14rem;
}

.h-141 {
  height: 14.1rem;
}

.h-142 {
  height: 14.2rem;
}

.h-143 {
  height: 14.3rem;
}

.h-144 {
  height: 14.4rem;
}

.h-145 {
  height: 14.5rem;
}

.h-146 {
  height: 14.6rem;
}

.h-147 {
  height: 14.7rem;
}

.h-148 {
  height: 14.8rem;
}

.h-149 {
  height: 14.9rem;
}

.h-150 {
  height: 15rem;
}

.h-151 {
  height: 15.1rem;
}

.h-152 {
  height: 15.2rem;
}

.h-153 {
  height: 15.3rem;
}

.h-154 {
  height: 15.4rem;
}

.h-155 {
  height: 15.5rem;
}

.h-156 {
  height: 15.6rem;
}

.h-157 {
  height: 15.7rem;
}

.h-158 {
  height: 15.8rem;
}

.h-159 {
  height: 15.9rem;
}

.h-160 {
  height: 16rem;
}

.h-161 {
  height: 16.1rem;
}

.h-162 {
  height: 16.2rem;
}

.h-163 {
  height: 16.3rem;
}

.h-164 {
  height: 16.4rem;
}

.h-165 {
  height: 16.5rem;
}

.h-166 {
  height: 16.6rem;
}

.h-167 {
  height: 16.7rem;
}

.h-168 {
  height: 16.8rem;
}

.h-169 {
  height: 16.9rem;
}

.h-170 {
  height: 17rem;
}

.h-171 {
  height: 17.1rem;
}

.h-172 {
  height: 17.2rem;
}

.h-173 {
  height: 17.3rem;
}

.h-174 {
  height: 17.4rem;
}

.h-175 {
  height: 17.5rem;
}

.h-176 {
  height: 17.6rem;
}

.h-177 {
  height: 17.7rem;
}

.h-178 {
  height: 17.8rem;
}

.h-179 {
  height: 17.9rem;
}

.h-180 {
  height: 18rem;
}

.h-181 {
  height: 18.1rem;
}

.h-182 {
  height: 18.2rem;
}

.h-183 {
  height: 18.3rem;
}

.h-184 {
  height: 18.4rem;
}

.h-185 {
  height: 18.5rem;
}

.h-186 {
  height: 18.6rem;
}

.h-187 {
  height: 18.7rem;
}

.h-188 {
  height: 18.8rem;
}

.h-189 {
  height: 18.9rem;
}

.h-190 {
  height: 19rem;
}

.h-191 {
  height: 19.1rem;
}

.h-192 {
  height: 19.2rem;
}

.h-193 {
  height: 19.3rem;
}

.h-194 {
  height: 19.4rem;
}

.h-195 {
  height: 19.5rem;
}

.h-196 {
  height: 19.6rem;
}

.h-197 {
  height: 19.7rem;
}

.h-198 {
  height: 19.8rem;
}

.h-199 {
  height: 19.9rem;
}

.h-200 {
  height: 20rem;
}

.h-201 {
  height: 20.1rem;
}

.h-202 {
  height: 20.2rem;
}

.h-203 {
  height: 20.3rem;
}

.h-204 {
  height: 20.4rem;
}

.h-205 {
  height: 20.5rem;
}

.h-206 {
  height: 20.6rem;
}

.h-207 {
  height: 20.7rem;
}

.h-208 {
  height: 20.8rem;
}

.h-209 {
  height: 20.9rem;
}

.h-210 {
  height: 21rem;
}

.h-211 {
  height: 21.1rem;
}

.h-212 {
  height: 21.2rem;
}

.h-213 {
  height: 21.3rem;
}

.h-214 {
  height: 21.4rem;
}

.h-215 {
  height: 21.5rem;
}

.h-216 {
  height: 21.6rem;
}

.h-217 {
  height: 21.7rem;
}

.h-218 {
  height: 21.8rem;
}

.h-219 {
  height: 21.9rem;
}

.h-220 {
  height: 22rem;
}

.h-221 {
  height: 22.1rem;
}

.h-222 {
  height: 22.2rem;
}

.h-223 {
  height: 22.3rem;
}

.h-224 {
  height: 22.4rem;
}

.h-225 {
  height: 22.5rem;
}

.h-226 {
  height: 22.6rem;
}

.h-227 {
  height: 22.7rem;
}

.h-228 {
  height: 22.8rem;
}

.h-229 {
  height: 22.9rem;
}

.h-230 {
  height: 23rem;
}

.h-231 {
  height: 23.1rem;
}

.h-232 {
  height: 23.2rem;
}

.h-233 {
  height: 23.3rem;
}

.h-234 {
  height: 23.4rem;
}

.h-235 {
  height: 23.5rem;
}

.h-236 {
  height: 23.6rem;
}

.h-237 {
  height: 23.7rem;
}

.h-238 {
  height: 23.8rem;
}

.h-239 {
  height: 23.9rem;
}

.h-240 {
  height: 24rem;
}

.h-241 {
  height: 24.1rem;
}

.h-242 {
  height: 24.2rem;
}

.h-243 {
  height: 24.3rem;
}

.h-244 {
  height: 24.4rem;
}

.h-245 {
  height: 24.5rem;
}

.h-246 {
  height: 24.6rem;
}

.h-247 {
  height: 24.7rem;
}

.h-248 {
  height: 24.8rem;
}

.h-249 {
  height: 24.9rem;
}

.h-250 {
  height: 25rem;
}

.h-251 {
  height: 25.1rem;
}

.h-252 {
  height: 25.2rem;
}

.h-253 {
  height: 25.3rem;
}

.h-254 {
  height: 25.4rem;
}

.h-255 {
  height: 25.5rem;
}

.h-256 {
  height: 25.6rem;
}

.h-257 {
  height: 25.7rem;
}

.h-258 {
  height: 25.8rem;
}

.h-259 {
  height: 25.9rem;
}

.h-260 {
  height: 26rem;
}

.h-261 {
  height: 26.1rem;
}

.h-262 {
  height: 26.2rem;
}

.h-263 {
  height: 26.3rem;
}

.h-264 {
  height: 26.4rem;
}

.h-265 {
  height: 26.5rem;
}

.h-266 {
  height: 26.6rem;
}

.h-267 {
  height: 26.7rem;
}

.h-268 {
  height: 26.8rem;
}

.h-269 {
  height: 26.9rem;
}

.h-270 {
  height: 27rem;
}

.h-271 {
  height: 27.1rem;
}

.h-272 {
  height: 27.2rem;
}

.h-273 {
  height: 27.3rem;
}

.h-274 {
  height: 27.4rem;
}

.h-275 {
  height: 27.5rem;
}

.h-276 {
  height: 27.6rem;
}

.h-277 {
  height: 27.7rem;
}

.h-278 {
  height: 27.8rem;
}

.h-279 {
  height: 27.9rem;
}

.h-280 {
  height: 28rem;
}

.h-281 {
  height: 28.1rem;
}

.h-282 {
  height: 28.2rem;
}

.h-283 {
  height: 28.3rem;
}

.h-284 {
  height: 28.4rem;
}

.h-285 {
  height: 28.5rem;
}

.h-286 {
  height: 28.6rem;
}

.h-287 {
  height: 28.7rem;
}

.h-288 {
  height: 28.8rem;
}

.h-289 {
  height: 28.9rem;
}

.h-290 {
  height: 29rem;
}

.h-291 {
  height: 29.1rem;
}

.h-292 {
  height: 29.2rem;
}

.h-293 {
  height: 29.3rem;
}

.h-294 {
  height: 29.4rem;
}

.h-295 {
  height: 29.5rem;
}

.h-296 {
  height: 29.6rem;
}

.h-297 {
  height: 29.7rem;
}

.h-298 {
  height: 29.8rem;
}

.h-299 {
  height: 29.9rem;
}

.h-300 {
  height: 30rem;
}

.h-301 {
  height: 30.1rem;
}

.h-302 {
  height: 30.2rem;
}

.h-303 {
  height: 30.3rem;
}

.h-304 {
  height: 30.4rem;
}

.h-305 {
  height: 30.5rem;
}

.h-306 {
  height: 30.6rem;
}

.h-307 {
  height: 30.7rem;
}

.h-308 {
  height: 30.8rem;
}

.h-309 {
  height: 30.9rem;
}

.h-310 {
  height: 31rem;
}

.h-311 {
  height: 31.1rem;
}

.h-312 {
  height: 31.2rem;
}

.h-313 {
  height: 31.3rem;
}

.h-314 {
  height: 31.4rem;
}

.h-315 {
  height: 31.5rem;
}

.h-316 {
  height: 31.6rem;
}

.h-317 {
  height: 31.7rem;
}

.h-318 {
  height: 31.8rem;
}

.h-319 {
  height: 31.9rem;
}

.h-320 {
  height: 32rem;
}

.h-321 {
  height: 32.1rem;
}

.h-322 {
  height: 32.2rem;
}

.h-323 {
  height: 32.3rem;
}

.h-324 {
  height: 32.4rem;
}

.h-325 {
  height: 32.5rem;
}

.h-326 {
  height: 32.6rem;
}

.h-327 {
  height: 32.7rem;
}

.h-328 {
  height: 32.8rem;
}

.h-329 {
  height: 32.9rem;
}

.h-330 {
  height: 33rem;
}

.h-331 {
  height: 33.1rem;
}

.h-332 {
  height: 33.2rem;
}

.h-333 {
  height: 33.3rem;
}

.h-334 {
  height: 33.4rem;
}

.h-335 {
  height: 33.5rem;
}

.h-336 {
  height: 33.6rem;
}

.h-337 {
  height: 33.7rem;
}

.h-338 {
  height: 33.8rem;
}

.h-339 {
  height: 33.9rem;
}

.h-340 {
  height: 34rem;
}

.h-341 {
  height: 34.1rem;
}

.h-342 {
  height: 34.2rem;
}

.h-343 {
  height: 34.3rem;
}

.h-344 {
  height: 34.4rem;
}

.h-345 {
  height: 34.5rem;
}

.h-346 {
  height: 34.6rem;
}

.h-347 {
  height: 34.7rem;
}

.h-348 {
  height: 34.8rem;
}

.h-349 {
  height: 34.9rem;
}

.h-350 {
  height: 35rem;
}

.h-351 {
  height: 35.1rem;
}

.h-352 {
  height: 35.2rem;
}

.h-353 {
  height: 35.3rem;
}

.h-354 {
  height: 35.4rem;
}

.h-355 {
  height: 35.5rem;
}

.h-356 {
  height: 35.6rem;
}

.h-357 {
  height: 35.7rem;
}

.h-358 {
  height: 35.8rem;
}

.h-359 {
  height: 35.9rem;
}

.h-360 {
  height: 36rem;
}

.h-361 {
  height: 36.1rem;
}

.h-362 {
  height: 36.2rem;
}

.h-363 {
  height: 36.3rem;
}

.h-364 {
  height: 36.4rem;
}

.h-365 {
  height: 36.5rem;
}

.h-366 {
  height: 36.6rem;
}

.h-367 {
  height: 36.7rem;
}

.h-368 {
  height: 36.8rem;
}

.h-369 {
  height: 36.9rem;
}

.h-370 {
  height: 37rem;
}

.h-371 {
  height: 37.1rem;
}

.h-372 {
  height: 37.2rem;
}

.h-373 {
  height: 37.3rem;
}

.h-374 {
  height: 37.4rem;
}

.h-375 {
  height: 37.5rem;
}

.h-376 {
  height: 37.6rem;
}

.h-377 {
  height: 37.7rem;
}

.h-378 {
  height: 37.8rem;
}

.h-379 {
  height: 37.9rem;
}

.h-380 {
  height: 38rem;
}

.h-381 {
  height: 38.1rem;
}

.h-382 {
  height: 38.2rem;
}

.h-383 {
  height: 38.3rem;
}

.h-384 {
  height: 38.4rem;
}

.h-385 {
  height: 38.5rem;
}

.h-386 {
  height: 38.6rem;
}

.h-387 {
  height: 38.7rem;
}

.h-388 {
  height: 38.8rem;
}

.h-389 {
  height: 38.9rem;
}

.h-390 {
  height: 39rem;
}

.h-391 {
  height: 39.1rem;
}

.h-392 {
  height: 39.2rem;
}

.h-393 {
  height: 39.3rem;
}

.h-394 {
  height: 39.4rem;
}

.h-395 {
  height: 39.5rem;
}

.h-396 {
  height: 39.6rem;
}

.h-397 {
  height: 39.7rem;
}

.h-398 {
  height: 39.8rem;
}

.h-399 {
  height: 39.9rem;
}

.h-400 {
  height: 40rem;
}

.h-401 {
  height: 40.1rem;
}

.h-402 {
  height: 40.2rem;
}

.h-403 {
  height: 40.3rem;
}

.h-404 {
  height: 40.4rem;
}

.h-405 {
  height: 40.5rem;
}

.h-406 {
  height: 40.6rem;
}

.h-407 {
  height: 40.7rem;
}

.h-408 {
  height: 40.8rem;
}

.h-409 {
  height: 40.9rem;
}

.h-410 {
  height: 41rem;
}

.h-411 {
  height: 41.1rem;
}

.h-412 {
  height: 41.2rem;
}

.h-413 {
  height: 41.3rem;
}

.h-414 {
  height: 41.4rem;
}

.h-415 {
  height: 41.5rem;
}

.h-416 {
  height: 41.6rem;
}

.h-417 {
  height: 41.7rem;
}

.h-418 {
  height: 41.8rem;
}

.h-419 {
  height: 41.9rem;
}

.h-420 {
  height: 42rem;
}

.h-421 {
  height: 42.1rem;
}

.h-422 {
  height: 42.2rem;
}

.h-423 {
  height: 42.3rem;
}

.h-424 {
  height: 42.4rem;
}

.h-425 {
  height: 42.5rem;
}

.h-426 {
  height: 42.6rem;
}

.h-427 {
  height: 42.7rem;
}

.h-428 {
  height: 42.8rem;
}

.h-429 {
  height: 42.9rem;
}

.h-430 {
  height: 43rem;
}

.h-431 {
  height: 43.1rem;
}

.h-432 {
  height: 43.2rem;
}

.h-433 {
  height: 43.3rem;
}

.h-434 {
  height: 43.4rem;
}

.h-435 {
  height: 43.5rem;
}

.h-436 {
  height: 43.6rem;
}

.h-437 {
  height: 43.7rem;
}

.h-438 {
  height: 43.8rem;
}

.h-439 {
  height: 43.9rem;
}

.h-440 {
  height: 44rem;
}

.h-441 {
  height: 44.1rem;
}

.h-442 {
  height: 44.2rem;
}

.h-443 {
  height: 44.3rem;
}

.h-444 {
  height: 44.4rem;
}

.h-445 {
  height: 44.5rem;
}

.h-446 {
  height: 44.6rem;
}

.h-447 {
  height: 44.7rem;
}

.h-448 {
  height: 44.8rem;
}

.h-449 {
  height: 44.9rem;
}

.h-450 {
  height: 45rem;
}

.h-451 {
  height: 45.1rem;
}

.h-452 {
  height: 45.2rem;
}

.h-453 {
  height: 45.3rem;
}

.h-454 {
  height: 45.4rem;
}

.h-455 {
  height: 45.5rem;
}

.h-456 {
  height: 45.6rem;
}

.h-457 {
  height: 45.7rem;
}

.h-458 {
  height: 45.8rem;
}

.h-459 {
  height: 45.9rem;
}

.h-460 {
  height: 46rem;
}

.h-461 {
  height: 46.1rem;
}

.h-462 {
  height: 46.2rem;
}

.h-463 {
  height: 46.3rem;
}

.h-464 {
  height: 46.4rem;
}

.h-465 {
  height: 46.5rem;
}

.h-466 {
  height: 46.6rem;
}

.h-467 {
  height: 46.7rem;
}

.h-468 {
  height: 46.8rem;
}

.h-469 {
  height: 46.9rem;
}

.h-470 {
  height: 47rem;
}

.h-471 {
  height: 47.1rem;
}

.h-472 {
  height: 47.2rem;
}

.h-473 {
  height: 47.3rem;
}

.h-474 {
  height: 47.4rem;
}

.h-475 {
  height: 47.5rem;
}

.h-476 {
  height: 47.6rem;
}

.h-477 {
  height: 47.7rem;
}

.h-478 {
  height: 47.8rem;
}

.h-479 {
  height: 47.9rem;
}

.h-480 {
  height: 48rem;
}

.h-481 {
  height: 48.1rem;
}

.h-482 {
  height: 48.2rem;
}

.h-483 {
  height: 48.3rem;
}

.h-484 {
  height: 48.4rem;
}

.h-485 {
  height: 48.5rem;
}

.h-486 {
  height: 48.6rem;
}

.h-487 {
  height: 48.7rem;
}

.h-488 {
  height: 48.8rem;
}

.h-489 {
  height: 48.9rem;
}

.h-490 {
  height: 49rem;
}

.h-491 {
  height: 49.1rem;
}

.h-492 {
  height: 49.2rem;
}

.h-493 {
  height: 49.3rem;
}

.h-494 {
  height: 49.4rem;
}

.h-495 {
  height: 49.5rem;
}

.h-496 {
  height: 49.6rem;
}

.h-497 {
  height: 49.7rem;
}

.h-498 {
  height: 49.8rem;
}

.h-499 {
  height: 49.9rem;
}

.h-500 {
  height: 50rem;
}

.h-501 {
  height: 50.1rem;
}

.h-502 {
  height: 50.2rem;
}

.h-503 {
  height: 50.3rem;
}

.h-504 {
  height: 50.4rem;
}

.h-505 {
  height: 50.5rem;
}

.h-506 {
  height: 50.6rem;
}

.h-507 {
  height: 50.7rem;
}

.h-508 {
  height: 50.8rem;
}

.h-509 {
  height: 50.9rem;
}

.h-510 {
  height: 51rem;
}

.h-511 {
  height: 51.1rem;
}

.h-512 {
  height: 51.2rem;
}

.h-513 {
  height: 51.3rem;
}

.h-514 {
  height: 51.4rem;
}

.h-515 {
  height: 51.5rem;
}

.h-516 {
  height: 51.6rem;
}

.h-517 {
  height: 51.7rem;
}

.h-518 {
  height: 51.8rem;
}

.h-519 {
  height: 51.9rem;
}

.h-520 {
  height: 52rem;
}

.h-521 {
  height: 52.1rem;
}

.h-522 {
  height: 52.2rem;
}

.h-523 {
  height: 52.3rem;
}

.h-524 {
  height: 52.4rem;
}

.h-525 {
  height: 52.5rem;
}

.h-526 {
  height: 52.6rem;
}

.h-527 {
  height: 52.7rem;
}

.h-528 {
  height: 52.8rem;
}

.h-529 {
  height: 52.9rem;
}

.h-530 {
  height: 53rem;
}

.h-531 {
  height: 53.1rem;
}

.h-532 {
  height: 53.2rem;
}

.h-533 {
  height: 53.3rem;
}

.h-534 {
  height: 53.4rem;
}

.h-535 {
  height: 53.5rem;
}

.h-536 {
  height: 53.6rem;
}

.h-537 {
  height: 53.7rem;
}

.h-538 {
  height: 53.8rem;
}

.h-539 {
  height: 53.9rem;
}

.h-540 {
  height: 54rem;
}

.h-541 {
  height: 54.1rem;
}

.h-542 {
  height: 54.2rem;
}

.h-543 {
  height: 54.3rem;
}

.h-544 {
  height: 54.4rem;
}

.h-545 {
  height: 54.5rem;
}

.h-546 {
  height: 54.6rem;
}

.h-547 {
  height: 54.7rem;
}

.h-548 {
  height: 54.8rem;
}

.h-549 {
  height: 54.9rem;
}

.h-550 {
  height: 55rem;
}

.h-551 {
  height: 55.1rem;
}

.h-552 {
  height: 55.2rem;
}

.h-553 {
  height: 55.3rem;
}

.h-554 {
  height: 55.4rem;
}

.h-555 {
  height: 55.5rem;
}

.h-556 {
  height: 55.6rem;
}

.h-557 {
  height: 55.7rem;
}

.h-558 {
  height: 55.8rem;
}

.h-559 {
  height: 55.9rem;
}

.h-560 {
  height: 56rem;
}

.h-561 {
  height: 56.1rem;
}

.h-562 {
  height: 56.2rem;
}

.h-563 {
  height: 56.3rem;
}

.h-564 {
  height: 56.4rem;
}

.h-565 {
  height: 56.5rem;
}

.h-566 {
  height: 56.6rem;
}

.h-567 {
  height: 56.7rem;
}

.h-568 {
  height: 56.8rem;
}

.h-569 {
  height: 56.9rem;
}

.h-570 {
  height: 57rem;
}

.h-571 {
  height: 57.1rem;
}

.h-572 {
  height: 57.2rem;
}

.h-573 {
  height: 57.3rem;
}

.h-574 {
  height: 57.4rem;
}

.h-575 {
  height: 57.5rem;
}

.h-576 {
  height: 57.6rem;
}

.h-577 {
  height: 57.7rem;
}

.h-578 {
  height: 57.8rem;
}

.h-579 {
  height: 57.9rem;
}

.h-580 {
  height: 58rem;
}

.h-581 {
  height: 58.1rem;
}

.h-582 {
  height: 58.2rem;
}

.h-583 {
  height: 58.3rem;
}

.h-584 {
  height: 58.4rem;
}

.h-585 {
  height: 58.5rem;
}

.h-586 {
  height: 58.6rem;
}

.h-587 {
  height: 58.7rem;
}

.h-588 {
  height: 58.8rem;
}

.h-589 {
  height: 58.9rem;
}

.h-590 {
  height: 59rem;
}

.h-591 {
  height: 59.1rem;
}

.h-592 {
  height: 59.2rem;
}

.h-593 {
  height: 59.3rem;
}

.h-594 {
  height: 59.4rem;
}

.h-595 {
  height: 59.5rem;
}

.h-596 {
  height: 59.6rem;
}

.h-597 {
  height: 59.7rem;
}

.h-598 {
  height: 59.8rem;
}

.h-599 {
  height: 59.9rem;
}

.h-600 {
  height: 60rem;
}

.h-601 {
  height: 60.1rem;
}

.h-602 {
  height: 60.2rem;
}

.h-603 {
  height: 60.3rem;
}

.h-604 {
  height: 60.4rem;
}

.h-605 {
  height: 60.5rem;
}

.h-606 {
  height: 60.6rem;
}

.h-607 {
  height: 60.7rem;
}

.h-608 {
  height: 60.8rem;
}

.h-609 {
  height: 60.9rem;
}

.h-610 {
  height: 61rem;
}

.h-611 {
  height: 61.1rem;
}

.h-612 {
  height: 61.2rem;
}

.h-613 {
  height: 61.3rem;
}

.h-614 {
  height: 61.4rem;
}

.h-615 {
  height: 61.5rem;
}

.h-616 {
  height: 61.6rem;
}

.h-617 {
  height: 61.7rem;
}

.h-618 {
  height: 61.8rem;
}

.h-619 {
  height: 61.9rem;
}

.h-620 {
  height: 62rem;
}

.h-621 {
  height: 62.1rem;
}

.h-622 {
  height: 62.2rem;
}

.h-623 {
  height: 62.3rem;
}

.h-624 {
  height: 62.4rem;
}

.h-625 {
  height: 62.5rem;
}

.h-626 {
  height: 62.6rem;
}

.h-627 {
  height: 62.7rem;
}

.h-628 {
  height: 62.8rem;
}

.h-629 {
  height: 62.9rem;
}

.h-630 {
  height: 63rem;
}

.h-631 {
  height: 63.1rem;
}

.h-632 {
  height: 63.2rem;
}

.h-633 {
  height: 63.3rem;
}

.h-634 {
  height: 63.4rem;
}

.h-635 {
  height: 63.5rem;
}

.h-636 {
  height: 63.6rem;
}

.h-637 {
  height: 63.7rem;
}

.h-638 {
  height: 63.8rem;
}

.h-639 {
  height: 63.9rem;
}

.h-640 {
  height: 64rem;
}

.h-641 {
  height: 64.1rem;
}

.h-642 {
  height: 64.2rem;
}

.h-643 {
  height: 64.3rem;
}

.h-644 {
  height: 64.4rem;
}

.h-645 {
  height: 64.5rem;
}

.h-646 {
  height: 64.6rem;
}

.h-647 {
  height: 64.7rem;
}

.h-648 {
  height: 64.8rem;
}

.h-649 {
  height: 64.9rem;
}

.h-650 {
  height: 65rem;
}

.h-651 {
  height: 65.1rem;
}

.h-652 {
  height: 65.2rem;
}

.h-653 {
  height: 65.3rem;
}

.h-654 {
  height: 65.4rem;
}

.h-655 {
  height: 65.5rem;
}

.h-656 {
  height: 65.6rem;
}

.h-657 {
  height: 65.7rem;
}

.h-658 {
  height: 65.8rem;
}

.h-659 {
  height: 65.9rem;
}

.h-660 {
  height: 66rem;
}

.h-661 {
  height: 66.1rem;
}

.h-662 {
  height: 66.2rem;
}

.h-663 {
  height: 66.3rem;
}

.h-664 {
  height: 66.4rem;
}

.h-665 {
  height: 66.5rem;
}

.h-666 {
  height: 66.6rem;
}

.h-667 {
  height: 66.7rem;
}

.h-668 {
  height: 66.8rem;
}

.h-669 {
  height: 66.9rem;
}

.h-670 {
  height: 67rem;
}

.h-671 {
  height: 67.1rem;
}

.h-672 {
  height: 67.2rem;
}

.h-673 {
  height: 67.3rem;
}

.h-674 {
  height: 67.4rem;
}

.h-675 {
  height: 67.5rem;
}

.h-676 {
  height: 67.6rem;
}

.h-677 {
  height: 67.7rem;
}

.h-678 {
  height: 67.8rem;
}

.h-679 {
  height: 67.9rem;
}

.h-680 {
  height: 68rem;
}

.h-681 {
  height: 68.1rem;
}

.h-682 {
  height: 68.2rem;
}

.h-683 {
  height: 68.3rem;
}

.h-684 {
  height: 68.4rem;
}

.h-685 {
  height: 68.5rem;
}

.h-686 {
  height: 68.6rem;
}

.h-687 {
  height: 68.7rem;
}

.h-688 {
  height: 68.8rem;
}

.h-689 {
  height: 68.9rem;
}

.h-690 {
  height: 69rem;
}

.h-691 {
  height: 69.1rem;
}

.h-692 {
  height: 69.2rem;
}

.h-693 {
  height: 69.3rem;
}

.h-694 {
  height: 69.4rem;
}

.h-695 {
  height: 69.5rem;
}

.h-696 {
  height: 69.6rem;
}

.h-697 {
  height: 69.7rem;
}

.h-698 {
  height: 69.8rem;
}

.h-699 {
  height: 69.9rem;
}

.h-700 {
  height: 70rem;
}

.h-701 {
  height: 70.1rem;
}

.h-702 {
  height: 70.2rem;
}

.h-703 {
  height: 70.3rem;
}

.h-704 {
  height: 70.4rem;
}

.h-705 {
  height: 70.5rem;
}

.h-706 {
  height: 70.6rem;
}

.h-707 {
  height: 70.7rem;
}

.h-708 {
  height: 70.8rem;
}

.h-709 {
  height: 70.9rem;
}

.h-710 {
  height: 71rem;
}

.h-711 {
  height: 71.1rem;
}

.h-712 {
  height: 71.2rem;
}

.h-713 {
  height: 71.3rem;
}

.h-714 {
  height: 71.4rem;
}

.h-715 {
  height: 71.5rem;
}

.h-716 {
  height: 71.6rem;
}

.h-717 {
  height: 71.7rem;
}

.h-718 {
  height: 71.8rem;
}

.h-719 {
  height: 71.9rem;
}

.h-720 {
  height: 72rem;
}

.h-721 {
  height: 72.1rem;
}

.h-722 {
  height: 72.2rem;
}

.h-723 {
  height: 72.3rem;
}

.h-724 {
  height: 72.4rem;
}

.h-725 {
  height: 72.5rem;
}

.h-726 {
  height: 72.6rem;
}

.h-727 {
  height: 72.7rem;
}

.h-728 {
  height: 72.8rem;
}

.h-729 {
  height: 72.9rem;
}

.h-730 {
  height: 73rem;
}

.h-731 {
  height: 73.1rem;
}

.h-732 {
  height: 73.2rem;
}

.h-733 {
  height: 73.3rem;
}

.h-734 {
  height: 73.4rem;
}

.h-735 {
  height: 73.5rem;
}

.h-736 {
  height: 73.6rem;
}

.h-737 {
  height: 73.7rem;
}

.h-738 {
  height: 73.8rem;
}

.h-739 {
  height: 73.9rem;
}

.h-740 {
  height: 74rem;
}

.h-741 {
  height: 74.1rem;
}

.h-742 {
  height: 74.2rem;
}

.h-743 {
  height: 74.3rem;
}

.h-744 {
  height: 74.4rem;
}

.h-745 {
  height: 74.5rem;
}

.h-746 {
  height: 74.6rem;
}

.h-747 {
  height: 74.7rem;
}

.h-748 {
  height: 74.8rem;
}

.h-749 {
  height: 74.9rem;
}

.h-750 {
  height: 75rem;
}

.h-751 {
  height: 75.1rem;
}

.h-752 {
  height: 75.2rem;
}

.h-753 {
  height: 75.3rem;
}

.h-754 {
  height: 75.4rem;
}

.h-755 {
  height: 75.5rem;
}

.h-756 {
  height: 75.6rem;
}

.h-757 {
  height: 75.7rem;
}

.h-758 {
  height: 75.8rem;
}

.h-759 {
  height: 75.9rem;
}

.h-760 {
  height: 76rem;
}

.h-761 {
  height: 76.1rem;
}

.h-762 {
  height: 76.2rem;
}

.h-763 {
  height: 76.3rem;
}

.h-764 {
  height: 76.4rem;
}

.h-765 {
  height: 76.5rem;
}

.h-766 {
  height: 76.6rem;
}

.h-767 {
  height: 76.7rem;
}

.h-768 {
  height: 76.8rem;
}

.h-769 {
  height: 76.9rem;
}

.h-770 {
  height: 77rem;
}

.h-771 {
  height: 77.1rem;
}

.h-772 {
  height: 77.2rem;
}

.h-773 {
  height: 77.3rem;
}

.h-774 {
  height: 77.4rem;
}

.h-775 {
  height: 77.5rem;
}

.h-776 {
  height: 77.6rem;
}

.h-777 {
  height: 77.7rem;
}

.h-778 {
  height: 77.8rem;
}

.h-779 {
  height: 77.9rem;
}

.h-780 {
  height: 78rem;
}

.h-781 {
  height: 78.1rem;
}

.h-782 {
  height: 78.2rem;
}

.h-783 {
  height: 78.3rem;
}

.h-784 {
  height: 78.4rem;
}

.h-785 {
  height: 78.5rem;
}

.h-786 {
  height: 78.6rem;
}

.h-787 {
  height: 78.7rem;
}

.h-788 {
  height: 78.8rem;
}

.h-789 {
  height: 78.9rem;
}

.h-790 {
  height: 79rem;
}

.h-791 {
  height: 79.1rem;
}

.h-792 {
  height: 79.2rem;
}

.h-793 {
  height: 79.3rem;
}

.h-794 {
  height: 79.4rem;
}

.h-795 {
  height: 79.5rem;
}

.h-796 {
  height: 79.6rem;
}

.h-797 {
  height: 79.7rem;
}

.h-798 {
  height: 79.8rem;
}

.h-799 {
  height: 79.9rem;
}

.h-800 {
  height: 80rem;
}

.h-801 {
  height: 80.1rem;
}

.h-802 {
  height: 80.2rem;
}

.h-803 {
  height: 80.3rem;
}

.h-804 {
  height: 80.4rem;
}

.h-805 {
  height: 80.5rem;
}

.h-806 {
  height: 80.6rem;
}

.h-807 {
  height: 80.7rem;
}

.h-808 {
  height: 80.8rem;
}

.h-809 {
  height: 80.9rem;
}

.h-810 {
  height: 81rem;
}

.h-811 {
  height: 81.1rem;
}

.h-812 {
  height: 81.2rem;
}

.h-813 {
  height: 81.3rem;
}

.h-814 {
  height: 81.4rem;
}

.h-815 {
  height: 81.5rem;
}

.h-816 {
  height: 81.6rem;
}

.h-817 {
  height: 81.7rem;
}

.h-818 {
  height: 81.8rem;
}

.h-819 {
  height: 81.9rem;
}

.h-820 {
  height: 82rem;
}

.h-821 {
  height: 82.1rem;
}

.h-822 {
  height: 82.2rem;
}

.h-823 {
  height: 82.3rem;
}

.h-824 {
  height: 82.4rem;
}

.h-825 {
  height: 82.5rem;
}

.h-826 {
  height: 82.6rem;
}

.h-827 {
  height: 82.7rem;
}

.h-828 {
  height: 82.8rem;
}

.h-829 {
  height: 82.9rem;
}

.h-830 {
  height: 83rem;
}

.h-831 {
  height: 83.1rem;
}

.h-832 {
  height: 83.2rem;
}

.h-833 {
  height: 83.3rem;
}

.h-834 {
  height: 83.4rem;
}

.h-835 {
  height: 83.5rem;
}

.h-836 {
  height: 83.6rem;
}

.h-837 {
  height: 83.7rem;
}

.h-838 {
  height: 83.8rem;
}

.h-839 {
  height: 83.9rem;
}

.h-840 {
  height: 84rem;
}

.h-841 {
  height: 84.1rem;
}

.h-842 {
  height: 84.2rem;
}

.h-843 {
  height: 84.3rem;
}

.h-844 {
  height: 84.4rem;
}

.h-845 {
  height: 84.5rem;
}

.h-846 {
  height: 84.6rem;
}

.h-847 {
  height: 84.7rem;
}

.h-848 {
  height: 84.8rem;
}

.h-849 {
  height: 84.9rem;
}

.h-850 {
  height: 85rem;
}

.h-851 {
  height: 85.1rem;
}

.h-852 {
  height: 85.2rem;
}

.h-853 {
  height: 85.3rem;
}

.h-854 {
  height: 85.4rem;
}

.h-855 {
  height: 85.5rem;
}

.h-856 {
  height: 85.6rem;
}

.h-857 {
  height: 85.7rem;
}

.h-858 {
  height: 85.8rem;
}

.h-859 {
  height: 85.9rem;
}

.h-860 {
  height: 86rem;
}

.h-861 {
  height: 86.1rem;
}

.h-862 {
  height: 86.2rem;
}

.h-863 {
  height: 86.3rem;
}

.h-864 {
  height: 86.4rem;
}

.h-865 {
  height: 86.5rem;
}

.h-866 {
  height: 86.6rem;
}

.h-867 {
  height: 86.7rem;
}

.h-868 {
  height: 86.8rem;
}

.h-869 {
  height: 86.9rem;
}

.h-870 {
  height: 87rem;
}

.h-871 {
  height: 87.1rem;
}

.h-872 {
  height: 87.2rem;
}

.h-873 {
  height: 87.3rem;
}

.h-874 {
  height: 87.4rem;
}

.h-875 {
  height: 87.5rem;
}

.h-876 {
  height: 87.6rem;
}

.h-877 {
  height: 87.7rem;
}

.h-878 {
  height: 87.8rem;
}

.h-879 {
  height: 87.9rem;
}

.h-880 {
  height: 88rem;
}

.h-881 {
  height: 88.1rem;
}

.h-882 {
  height: 88.2rem;
}

.h-883 {
  height: 88.3rem;
}

.h-884 {
  height: 88.4rem;
}

.h-885 {
  height: 88.5rem;
}

.h-886 {
  height: 88.6rem;
}

.h-887 {
  height: 88.7rem;
}

.h-888 {
  height: 88.8rem;
}

.h-889 {
  height: 88.9rem;
}

.h-890 {
  height: 89rem;
}

.h-891 {
  height: 89.1rem;
}

.h-892 {
  height: 89.2rem;
}

.h-893 {
  height: 89.3rem;
}

.h-894 {
  height: 89.4rem;
}

.h-895 {
  height: 89.5rem;
}

.h-896 {
  height: 89.6rem;
}

.h-897 {
  height: 89.7rem;
}

.h-898 {
  height: 89.8rem;
}

.h-899 {
  height: 89.9rem;
}

.h-900 {
  height: 90rem;
}

.h-901 {
  height: 90.1rem;
}

.h-902 {
  height: 90.2rem;
}

.h-903 {
  height: 90.3rem;
}

.h-904 {
  height: 90.4rem;
}

.h-905 {
  height: 90.5rem;
}

.h-906 {
  height: 90.6rem;
}

.h-907 {
  height: 90.7rem;
}

.h-908 {
  height: 90.8rem;
}

.h-909 {
  height: 90.9rem;
}

.h-910 {
  height: 91rem;
}

.h-911 {
  height: 91.1rem;
}

.h-912 {
  height: 91.2rem;
}

.h-913 {
  height: 91.3rem;
}

.h-914 {
  height: 91.4rem;
}

.h-915 {
  height: 91.5rem;
}

.h-916 {
  height: 91.6rem;
}

.h-917 {
  height: 91.7rem;
}

.h-918 {
  height: 91.8rem;
}

.h-919 {
  height: 91.9rem;
}

.h-920 {
  height: 92rem;
}

.h-921 {
  height: 92.1rem;
}

.h-922 {
  height: 92.2rem;
}

.h-923 {
  height: 92.3rem;
}

.h-924 {
  height: 92.4rem;
}

.h-925 {
  height: 92.5rem;
}

.h-926 {
  height: 92.6rem;
}

.h-927 {
  height: 92.7rem;
}

.h-928 {
  height: 92.8rem;
}

.h-929 {
  height: 92.9rem;
}

.h-930 {
  height: 93rem;
}

.h-931 {
  height: 93.1rem;
}

.h-932 {
  height: 93.2rem;
}

.h-933 {
  height: 93.3rem;
}

.h-934 {
  height: 93.4rem;
}

.h-935 {
  height: 93.5rem;
}

.h-936 {
  height: 93.6rem;
}

.h-937 {
  height: 93.7rem;
}

.h-938 {
  height: 93.8rem;
}

.h-939 {
  height: 93.9rem;
}

.h-940 {
  height: 94rem;
}

.h-941 {
  height: 94.1rem;
}

.h-942 {
  height: 94.2rem;
}

.h-943 {
  height: 94.3rem;
}

.h-944 {
  height: 94.4rem;
}

.h-945 {
  height: 94.5rem;
}

.h-946 {
  height: 94.6rem;
}

.h-947 {
  height: 94.7rem;
}

.h-948 {
  height: 94.8rem;
}

.h-949 {
  height: 94.9rem;
}

.h-950 {
  height: 95rem;
}

.h-951 {
  height: 95.1rem;
}

.h-952 {
  height: 95.2rem;
}

.h-953 {
  height: 95.3rem;
}

.h-954 {
  height: 95.4rem;
}

.h-955 {
  height: 95.5rem;
}

.h-956 {
  height: 95.6rem;
}

.h-957 {
  height: 95.7rem;
}

.h-958 {
  height: 95.8rem;
}

.h-959 {
  height: 95.9rem;
}

.h-960 {
  height: 96rem;
}

.h-961 {
  height: 96.1rem;
}

.h-962 {
  height: 96.2rem;
}

.h-963 {
  height: 96.3rem;
}

.h-964 {
  height: 96.4rem;
}

.h-965 {
  height: 96.5rem;
}

.h-966 {
  height: 96.6rem;
}

.h-967 {
  height: 96.7rem;
}

.h-968 {
  height: 96.8rem;
}

.h-969 {
  height: 96.9rem;
}

.h-970 {
  height: 97rem;
}

.h-971 {
  height: 97.1rem;
}

.h-972 {
  height: 97.2rem;
}

.h-973 {
  height: 97.3rem;
}

.h-974 {
  height: 97.4rem;
}

.h-975 {
  height: 97.5rem;
}

.h-976 {
  height: 97.6rem;
}

.h-977 {
  height: 97.7rem;
}

.h-978 {
  height: 97.8rem;
}

.h-979 {
  height: 97.9rem;
}

.h-980 {
  height: 98rem;
}

.h-981 {
  height: 98.1rem;
}

.h-982 {
  height: 98.2rem;
}

.h-983 {
  height: 98.3rem;
}

.h-984 {
  height: 98.4rem;
}

.h-985 {
  height: 98.5rem;
}

.h-986 {
  height: 98.6rem;
}

.h-987 {
  height: 98.7rem;
}

.h-988 {
  height: 98.8rem;
}

.h-989 {
  height: 98.9rem;
}

.h-990 {
  height: 99rem;
}

.h-991 {
  height: 99.1rem;
}

.h-992 {
  height: 99.2rem;
}

.h-993 {
  height: 99.3rem;
}

.h-994 {
  height: 99.4rem;
}

.h-995 {
  height: 99.5rem;
}

.h-996 {
  height: 99.6rem;
}

.h-997 {
  height: 99.7rem;
}

.h-998 {
  height: 99.8rem;
}

.h-999 {
  height: 99.9rem;
}

.h-1000 {
  height: 100rem;
}

.h-1001 {
  height: 100.1rem;
}

.h-1002 {
  height: 100.2rem;
}

.h-1003 {
  height: 100.3rem;
}

.h-1004 {
  height: 100.4rem;
}

.h-1005 {
  height: 100.5rem;
}

.h-1006 {
  height: 100.6rem;
}

.h-1007 {
  height: 100.7rem;
}

.h-1008 {
  height: 100.8rem;
}

.h-1009 {
  height: 100.9rem;
}

.h-1010 {
  height: 101rem;
}

.h-1011 {
  height: 101.1rem;
}

.h-1012 {
  height: 101.2rem;
}

.h-1013 {
  height: 101.3rem;
}

.h-1014 {
  height: 101.4rem;
}

.h-1015 {
  height: 101.5rem;
}

.h-1016 {
  height: 101.6rem;
}

.h-1017 {
  height: 101.7rem;
}

.h-1018 {
  height: 101.8rem;
}

.h-1019 {
  height: 101.9rem;
}

.h-1020 {
  height: 102rem;
}

.h-1021 {
  height: 102.1rem;
}

.h-1022 {
  height: 102.2rem;
}

.h-1023 {
  height: 102.3rem;
}

.h-1024 {
  height: 102.4rem;
}

.h-1025 {
  height: 102.5rem;
}

.h-1026 {
  height: 102.6rem;
}

.h-1027 {
  height: 102.7rem;
}

.h-1028 {
  height: 102.8rem;
}

.h-1029 {
  height: 102.9rem;
}

.h-1030 {
  height: 103rem;
}

.h-1031 {
  height: 103.1rem;
}

.h-1032 {
  height: 103.2rem;
}

.h-1033 {
  height: 103.3rem;
}

.h-1034 {
  height: 103.4rem;
}

.h-1035 {
  height: 103.5rem;
}

.h-1036 {
  height: 103.6rem;
}

.h-1037 {
  height: 103.7rem;
}

.h-1038 {
  height: 103.8rem;
}

.h-1039 {
  height: 103.9rem;
}

.h-1040 {
  height: 104rem;
}

.h-1041 {
  height: 104.1rem;
}

.h-1042 {
  height: 104.2rem;
}

.h-1043 {
  height: 104.3rem;
}

.h-1044 {
  height: 104.4rem;
}

.h-1045 {
  height: 104.5rem;
}

.h-1046 {
  height: 104.6rem;
}

.h-1047 {
  height: 104.7rem;
}

.h-1048 {
  height: 104.8rem;
}

.h-1049 {
  height: 104.9rem;
}

.h-1050 {
  height: 105rem;
}

.h-1051 {
  height: 105.1rem;
}

.h-1052 {
  height: 105.2rem;
}

.h-1053 {
  height: 105.3rem;
}

.h-1054 {
  height: 105.4rem;
}

.h-1055 {
  height: 105.5rem;
}

.h-1056 {
  height: 105.6rem;
}

.h-1057 {
  height: 105.7rem;
}

.h-1058 {
  height: 105.8rem;
}

.h-1059 {
  height: 105.9rem;
}

.h-1060 {
  height: 106rem;
}

.h-1061 {
  height: 106.1rem;
}

.h-1062 {
  height: 106.2rem;
}

.h-1063 {
  height: 106.3rem;
}

.h-1064 {
  height: 106.4rem;
}

.h-1065 {
  height: 106.5rem;
}

.h-1066 {
  height: 106.6rem;
}

.h-1067 {
  height: 106.7rem;
}

.h-1068 {
  height: 106.8rem;
}

.h-1069 {
  height: 106.9rem;
}

.h-1070 {
  height: 107rem;
}

.h-1071 {
  height: 107.1rem;
}

.h-1072 {
  height: 107.2rem;
}

.h-1073 {
  height: 107.3rem;
}

.h-1074 {
  height: 107.4rem;
}

.h-1075 {
  height: 107.5rem;
}

.h-1076 {
  height: 107.6rem;
}

.h-1077 {
  height: 107.7rem;
}

.h-1078 {
  height: 107.8rem;
}

.h-1079 {
  height: 107.9rem;
}

.h-1080 {
  height: 108rem;
}

.h-1081 {
  height: 108.1rem;
}

.h-1082 {
  height: 108.2rem;
}

.h-1083 {
  height: 108.3rem;
}

.h-1084 {
  height: 108.4rem;
}

.h-1085 {
  height: 108.5rem;
}

.h-1086 {
  height: 108.6rem;
}

.h-1087 {
  height: 108.7rem;
}

.h-1088 {
  height: 108.8rem;
}

.h-1089 {
  height: 108.9rem;
}

.h-1090 {
  height: 109rem;
}

.h-1091 {
  height: 109.1rem;
}

.h-1092 {
  height: 109.2rem;
}

.h-1093 {
  height: 109.3rem;
}

.h-1094 {
  height: 109.4rem;
}

.h-1095 {
  height: 109.5rem;
}

.h-1096 {
  height: 109.6rem;
}

.h-1097 {
  height: 109.7rem;
}

.h-1098 {
  height: 109.8rem;
}

.h-1099 {
  height: 109.9rem;
}

.h-1100 {
  height: 110rem;
}

.h-1101 {
  height: 110.1rem;
}

.h-1102 {
  height: 110.2rem;
}

.h-1103 {
  height: 110.3rem;
}

.h-1104 {
  height: 110.4rem;
}

.h-1105 {
  height: 110.5rem;
}

.h-1106 {
  height: 110.6rem;
}

.h-1107 {
  height: 110.7rem;
}

.h-1108 {
  height: 110.8rem;
}

.h-1109 {
  height: 110.9rem;
}

.h-1110 {
  height: 111rem;
}

.h-1111 {
  height: 111.1rem;
}

.h-1112 {
  height: 111.2rem;
}

.h-1113 {
  height: 111.3rem;
}

.h-1114 {
  height: 111.4rem;
}

.h-1115 {
  height: 111.5rem;
}

.h-1116 {
  height: 111.6rem;
}

.h-1117 {
  height: 111.7rem;
}

.h-1118 {
  height: 111.8rem;
}

.h-1119 {
  height: 111.9rem;
}

.h-1120 {
  height: 112rem;
}

.h-1121 {
  height: 112.1rem;
}

.h-1122 {
  height: 112.2rem;
}

.h-1123 {
  height: 112.3rem;
}

.h-1124 {
  height: 112.4rem;
}

.h-1125 {
  height: 112.5rem;
}

.h-1126 {
  height: 112.6rem;
}

.h-1127 {
  height: 112.7rem;
}

.h-1128 {
  height: 112.8rem;
}

.h-1129 {
  height: 112.9rem;
}

.h-1130 {
  height: 113rem;
}

.h-1131 {
  height: 113.1rem;
}

.h-1132 {
  height: 113.2rem;
}

.h-1133 {
  height: 113.3rem;
}

.h-1134 {
  height: 113.4rem;
}

.h-1135 {
  height: 113.5rem;
}

.h-1136 {
  height: 113.6rem;
}

.h-1137 {
  height: 113.7rem;
}

.h-1138 {
  height: 113.8rem;
}

.h-1139 {
  height: 113.9rem;
}

.h-1140 {
  height: 114rem;
}

.h-1141 {
  height: 114.1rem;
}

.h-1142 {
  height: 114.2rem;
}

.h-1143 {
  height: 114.3rem;
}

.h-1144 {
  height: 114.4rem;
}

.h-1145 {
  height: 114.5rem;
}

.h-1146 {
  height: 114.6rem;
}

.h-1147 {
  height: 114.7rem;
}

.h-1148 {
  height: 114.8rem;
}

.h-1149 {
  height: 114.9rem;
}

.h-1150 {
  height: 115rem;
}

.h-1151 {
  height: 115.1rem;
}

.h-1152 {
  height: 115.2rem;
}

.h-1153 {
  height: 115.3rem;
}

.h-1154 {
  height: 115.4rem;
}

.h-1155 {
  height: 115.5rem;
}

.h-1156 {
  height: 115.6rem;
}

.h-1157 {
  height: 115.7rem;
}

.h-1158 {
  height: 115.8rem;
}

.h-1159 {
  height: 115.9rem;
}

.h-1160 {
  height: 116rem;
}

.h-1161 {
  height: 116.1rem;
}

.h-1162 {
  height: 116.2rem;
}

.h-1163 {
  height: 116.3rem;
}

.h-1164 {
  height: 116.4rem;
}

.h-1165 {
  height: 116.5rem;
}

.h-1166 {
  height: 116.6rem;
}

.h-1167 {
  height: 116.7rem;
}

.h-1168 {
  height: 116.8rem;
}

.h-1169 {
  height: 116.9rem;
}

.h-1170 {
  height: 117rem;
}

.h-1171 {
  height: 117.1rem;
}

.h-1172 {
  height: 117.2rem;
}

.h-1173 {
  height: 117.3rem;
}

.h-1174 {
  height: 117.4rem;
}

.h-1175 {
  height: 117.5rem;
}

.h-1176 {
  height: 117.6rem;
}

.h-1177 {
  height: 117.7rem;
}

.h-1178 {
  height: 117.8rem;
}

.h-1179 {
  height: 117.9rem;
}

.h-1180 {
  height: 118rem;
}

.h-1181 {
  height: 118.1rem;
}

.h-1182 {
  height: 118.2rem;
}

.h-1183 {
  height: 118.3rem;
}

.h-1184 {
  height: 118.4rem;
}

.h-1185 {
  height: 118.5rem;
}

.h-1186 {
  height: 118.6rem;
}

.h-1187 {
  height: 118.7rem;
}

.h-1188 {
  height: 118.8rem;
}

.h-1189 {
  height: 118.9rem;
}

.h-1190 {
  height: 119rem;
}

.h-1191 {
  height: 119.1rem;
}

.h-1192 {
  height: 119.2rem;
}

.h-1193 {
  height: 119.3rem;
}

.h-1194 {
  height: 119.4rem;
}

.h-1195 {
  height: 119.5rem;
}

.h-1196 {
  height: 119.6rem;
}

.h-1197 {
  height: 119.7rem;
}

.h-1198 {
  height: 119.8rem;
}

.h-1199 {
  height: 119.9rem;
}

.h-1200 {
  height: 120rem;
}

.h-1201 {
  height: 120.1rem;
}

.h-1202 {
  height: 120.2rem;
}

.h-1203 {
  height: 120.3rem;
}

.h-1204 {
  height: 120.4rem;
}

.h-1205 {
  height: 120.5rem;
}

.h-1206 {
  height: 120.6rem;
}

.h-1207 {
  height: 120.7rem;
}

.h-1208 {
  height: 120.8rem;
}

.h-1209 {
  height: 120.9rem;
}

.h-1210 {
  height: 121rem;
}

.h-1211 {
  height: 121.1rem;
}

.h-1212 {
  height: 121.2rem;
}

.h-1213 {
  height: 121.3rem;
}

.h-1214 {
  height: 121.4rem;
}

.h-1215 {
  height: 121.5rem;
}

.h-1216 {
  height: 121.6rem;
}

.h-1217 {
  height: 121.7rem;
}

.h-1218 {
  height: 121.8rem;
}

.h-1219 {
  height: 121.9rem;
}

.h-1220 {
  height: 122rem;
}

.h-1221 {
  height: 122.1rem;
}

.h-1222 {
  height: 122.2rem;
}

.h-1223 {
  height: 122.3rem;
}

.h-1224 {
  height: 122.4rem;
}

.h-1225 {
  height: 122.5rem;
}

.h-1226 {
  height: 122.6rem;
}

.h-1227 {
  height: 122.7rem;
}

.h-1228 {
  height: 122.8rem;
}

.h-1229 {
  height: 122.9rem;
}

.h-1230 {
  height: 123rem;
}

.h-1231 {
  height: 123.1rem;
}

.h-1232 {
  height: 123.2rem;
}

.h-1233 {
  height: 123.3rem;
}

.h-1234 {
  height: 123.4rem;
}

.h-1235 {
  height: 123.5rem;
}

.h-1236 {
  height: 123.6rem;
}

.h-1237 {
  height: 123.7rem;
}

.h-1238 {
  height: 123.8rem;
}

.h-1239 {
  height: 123.9rem;
}

.h-1240 {
  height: 124rem;
}

.h-1241 {
  height: 124.1rem;
}

.h-1242 {
  height: 124.2rem;
}

.h-1243 {
  height: 124.3rem;
}

.h-1244 {
  height: 124.4rem;
}

.h-1245 {
  height: 124.5rem;
}

.h-1246 {
  height: 124.6rem;
}

.h-1247 {
  height: 124.7rem;
}

.h-1248 {
  height: 124.8rem;
}

.h-1249 {
  height: 124.9rem;
}

.h-1250 {
  height: 125rem;
}

.h-1251 {
  height: 125.1rem;
}

.h-1252 {
  height: 125.2rem;
}

.h-1253 {
  height: 125.3rem;
}

.h-1254 {
  height: 125.4rem;
}

.h-1255 {
  height: 125.5rem;
}

.h-1256 {
  height: 125.6rem;
}

.h-1257 {
  height: 125.7rem;
}

.h-1258 {
  height: 125.8rem;
}

.h-1259 {
  height: 125.9rem;
}

.h-1260 {
  height: 126rem;
}

.h-1261 {
  height: 126.1rem;
}

.h-1262 {
  height: 126.2rem;
}

.h-1263 {
  height: 126.3rem;
}

.h-1264 {
  height: 126.4rem;
}

.h-1265 {
  height: 126.5rem;
}

.h-1266 {
  height: 126.6rem;
}

.h-1267 {
  height: 126.7rem;
}

.h-1268 {
  height: 126.8rem;
}

.h-1269 {
  height: 126.9rem;
}

.h-1270 {
  height: 127rem;
}

.h-1271 {
  height: 127.1rem;
}

.h-1272 {
  height: 127.2rem;
}

.h-1273 {
  height: 127.3rem;
}

.h-1274 {
  height: 127.4rem;
}

.h-1275 {
  height: 127.5rem;
}

.h-1276 {
  height: 127.6rem;
}

.h-1277 {
  height: 127.7rem;
}

.h-1278 {
  height: 127.8rem;
}

.h-1279 {
  height: 127.9rem;
}

.h-1280 {
  height: 128rem;
}

.h-1281 {
  height: 128.1rem;
}

.h-1282 {
  height: 128.2rem;
}

.h-1283 {
  height: 128.3rem;
}

.h-1284 {
  height: 128.4rem;
}

.h-1285 {
  height: 128.5rem;
}

.h-1286 {
  height: 128.6rem;
}

.h-1287 {
  height: 128.7rem;
}

.h-1288 {
  height: 128.8rem;
}

.h-1289 {
  height: 128.9rem;
}

.h-1290 {
  height: 129rem;
}

.h-1291 {
  height: 129.1rem;
}

.h-1292 {
  height: 129.2rem;
}

.h-1293 {
  height: 129.3rem;
}

.h-1294 {
  height: 129.4rem;
}

.h-1295 {
  height: 129.5rem;
}

.h-1296 {
  height: 129.6rem;
}

.h-1297 {
  height: 129.7rem;
}

.h-1298 {
  height: 129.8rem;
}

.h-1299 {
  height: 129.9rem;
}

.h-1300 {
  height: 130rem;
}

.h-1301 {
  height: 130.1rem;
}

.h-1302 {
  height: 130.2rem;
}

.h-1303 {
  height: 130.3rem;
}

.h-1304 {
  height: 130.4rem;
}

.h-1305 {
  height: 130.5rem;
}

.h-1306 {
  height: 130.6rem;
}

.h-1307 {
  height: 130.7rem;
}

.h-1308 {
  height: 130.8rem;
}

.h-1309 {
  height: 130.9rem;
}

.h-1310 {
  height: 131rem;
}

.h-1311 {
  height: 131.1rem;
}

.h-1312 {
  height: 131.2rem;
}

.h-1313 {
  height: 131.3rem;
}

.h-1314 {
  height: 131.4rem;
}

.h-1315 {
  height: 131.5rem;
}

.h-1316 {
  height: 131.6rem;
}

.h-1317 {
  height: 131.7rem;
}

.h-1318 {
  height: 131.8rem;
}

.h-1319 {
  height: 131.9rem;
}

.h-1320 {
  height: 132rem;
}

.h-1321 {
  height: 132.1rem;
}

.h-1322 {
  height: 132.2rem;
}

.h-1323 {
  height: 132.3rem;
}

.h-1324 {
  height: 132.4rem;
}

.h-1325 {
  height: 132.5rem;
}

.h-1326 {
  height: 132.6rem;
}

.h-1327 {
  height: 132.7rem;
}

.h-1328 {
  height: 132.8rem;
}

.h-1329 {
  height: 132.9rem;
}

.h-1330 {
  height: 133rem;
}

.h-1331 {
  height: 133.1rem;
}

.h-1332 {
  height: 133.2rem;
}

.h-1333 {
  height: 133.3rem;
}

.h-1334 {
  height: 133.4rem;
}

.h-1335 {
  height: 133.5rem;
}

.h-1336 {
  height: 133.6rem;
}

.h-1337 {
  height: 133.7rem;
}

.h-1338 {
  height: 133.8rem;
}

.h-1339 {
  height: 133.9rem;
}

.h-1340 {
  height: 134rem;
}

.h-1341 {
  height: 134.1rem;
}

.h-1342 {
  height: 134.2rem;
}

.h-1343 {
  height: 134.3rem;
}

.h-1344 {
  height: 134.4rem;
}

.h-1345 {
  height: 134.5rem;
}

.h-1346 {
  height: 134.6rem;
}

.h-1347 {
  height: 134.7rem;
}

.h-1348 {
  height: 134.8rem;
}

.h-1349 {
  height: 134.9rem;
}

.h-1350 {
  height: 135rem;
}

.h-1351 {
  height: 135.1rem;
}

.h-1352 {
  height: 135.2rem;
}

.h-1353 {
  height: 135.3rem;
}

.h-1354 {
  height: 135.4rem;
}

.h-1355 {
  height: 135.5rem;
}

.h-1356 {
  height: 135.6rem;
}

.h-1357 {
  height: 135.7rem;
}

.h-1358 {
  height: 135.8rem;
}

.h-1359 {
  height: 135.9rem;
}

.h-1360 {
  height: 136rem;
}

.h-1361 {
  height: 136.1rem;
}

.h-1362 {
  height: 136.2rem;
}

.h-1363 {
  height: 136.3rem;
}

.h-1364 {
  height: 136.4rem;
}

.h-1365 {
  height: 136.5rem;
}

.h-1366 {
  height: 136.6rem;
}

.h-1367 {
  height: 136.7rem;
}

.h-1368 {
  height: 136.8rem;
}

.h-1369 {
  height: 136.9rem;
}

.h-1370 {
  height: 137rem;
}

.h-1371 {
  height: 137.1rem;
}

.h-1372 {
  height: 137.2rem;
}

.h-1373 {
  height: 137.3rem;
}

.h-1374 {
  height: 137.4rem;
}

.h-1375 {
  height: 137.5rem;
}

.h-1376 {
  height: 137.6rem;
}

.h-1377 {
  height: 137.7rem;
}

.h-1378 {
  height: 137.8rem;
}

.h-1379 {
  height: 137.9rem;
}

.h-1380 {
  height: 138rem;
}

.h-1381 {
  height: 138.1rem;
}

.h-1382 {
  height: 138.2rem;
}

.h-1383 {
  height: 138.3rem;
}

.h-1384 {
  height: 138.4rem;
}

.h-1385 {
  height: 138.5rem;
}

.h-1386 {
  height: 138.6rem;
}

.h-1387 {
  height: 138.7rem;
}

.h-1388 {
  height: 138.8rem;
}

.h-1389 {
  height: 138.9rem;
}

.h-1390 {
  height: 139rem;
}

.h-1391 {
  height: 139.1rem;
}

.h-1392 {
  height: 139.2rem;
}

.h-1393 {
  height: 139.3rem;
}

.h-1394 {
  height: 139.4rem;
}

.h-1395 {
  height: 139.5rem;
}

.h-1396 {
  height: 139.6rem;
}

.h-1397 {
  height: 139.7rem;
}

.h-1398 {
  height: 139.8rem;
}

.h-1399 {
  height: 139.9rem;
}

.h-1400 {
  height: 140rem;
}

.h-1401 {
  height: 140.1rem;
}

.h-1402 {
  height: 140.2rem;
}

.h-1403 {
  height: 140.3rem;
}

.h-1404 {
  height: 140.4rem;
}

.h-1405 {
  height: 140.5rem;
}

.h-1406 {
  height: 140.6rem;
}

.h-1407 {
  height: 140.7rem;
}

.h-1408 {
  height: 140.8rem;
}

.h-1409 {
  height: 140.9rem;
}

.h-1410 {
  height: 141rem;
}

.h-1411 {
  height: 141.1rem;
}

.h-1412 {
  height: 141.2rem;
}

.h-1413 {
  height: 141.3rem;
}

.h-1414 {
  height: 141.4rem;
}

.h-1415 {
  height: 141.5rem;
}

.h-1416 {
  height: 141.6rem;
}

.h-1417 {
  height: 141.7rem;
}

.h-1418 {
  height: 141.8rem;
}

.h-1419 {
  height: 141.9rem;
}

.h-1420 {
  height: 142rem;
}

.h-1421 {
  height: 142.1rem;
}

.h-1422 {
  height: 142.2rem;
}

.h-1423 {
  height: 142.3rem;
}

.h-1424 {
  height: 142.4rem;
}

.h-1425 {
  height: 142.5rem;
}

.h-1426 {
  height: 142.6rem;
}

.h-1427 {
  height: 142.7rem;
}

.h-1428 {
  height: 142.8rem;
}

.h-1429 {
  height: 142.9rem;
}

.h-1430 {
  height: 143rem;
}

.h-1431 {
  height: 143.1rem;
}

.h-1432 {
  height: 143.2rem;
}

.h-1433 {
  height: 143.3rem;
}

.h-1434 {
  height: 143.4rem;
}

.h-1435 {
  height: 143.5rem;
}

.h-1436 {
  height: 143.6rem;
}

.h-1437 {
  height: 143.7rem;
}

.h-1438 {
  height: 143.8rem;
}

.h-1439 {
  height: 143.9rem;
}

.h-1440 {
  height: 144rem;
}

.h-1441 {
  height: 144.1rem;
}

.h-1442 {
  height: 144.2rem;
}

.h-1443 {
  height: 144.3rem;
}

.h-1444 {
  height: 144.4rem;
}

.h-1445 {
  height: 144.5rem;
}

.h-1446 {
  height: 144.6rem;
}

.h-1447 {
  height: 144.7rem;
}

.h-1448 {
  height: 144.8rem;
}

.h-1449 {
  height: 144.9rem;
}

.h-1450 {
  height: 145rem;
}

.h-1451 {
  height: 145.1rem;
}

.h-1452 {
  height: 145.2rem;
}

.h-1453 {
  height: 145.3rem;
}

.h-1454 {
  height: 145.4rem;
}

.h-1455 {
  height: 145.5rem;
}

.h-1456 {
  height: 145.6rem;
}

.h-1457 {
  height: 145.7rem;
}

.h-1458 {
  height: 145.8rem;
}

.h-1459 {
  height: 145.9rem;
}

.h-1460 {
  height: 146rem;
}

.h-1461 {
  height: 146.1rem;
}

.h-1462 {
  height: 146.2rem;
}

.h-1463 {
  height: 146.3rem;
}

.h-1464 {
  height: 146.4rem;
}

.h-1465 {
  height: 146.5rem;
}

.h-1466 {
  height: 146.6rem;
}

.h-1467 {
  height: 146.7rem;
}

.h-1468 {
  height: 146.8rem;
}

.h-1469 {
  height: 146.9rem;
}

.h-1470 {
  height: 147rem;
}

.h-1471 {
  height: 147.1rem;
}

.h-1472 {
  height: 147.2rem;
}

.h-1473 {
  height: 147.3rem;
}

.h-1474 {
  height: 147.4rem;
}

.h-1475 {
  height: 147.5rem;
}

.h-1476 {
  height: 147.6rem;
}

.h-1477 {
  height: 147.7rem;
}

.h-1478 {
  height: 147.8rem;
}

.h-1479 {
  height: 147.9rem;
}

.h-1480 {
  height: 148rem;
}

.h-1481 {
  height: 148.1rem;
}

.h-1482 {
  height: 148.2rem;
}

.h-1483 {
  height: 148.3rem;
}

.h-1484 {
  height: 148.4rem;
}

.h-1485 {
  height: 148.5rem;
}

.h-1486 {
  height: 148.6rem;
}

.h-1487 {
  height: 148.7rem;
}

.h-1488 {
  height: 148.8rem;
}

.h-1489 {
  height: 148.9rem;
}

.h-1490 {
  height: 149rem;
}

.h-1491 {
  height: 149.1rem;
}

.h-1492 {
  height: 149.2rem;
}

.h-1493 {
  height: 149.3rem;
}

.h-1494 {
  height: 149.4rem;
}

.h-1495 {
  height: 149.5rem;
}

.h-1496 {
  height: 149.6rem;
}

.h-1497 {
  height: 149.7rem;
}

.h-1498 {
  height: 149.8rem;
}

.h-1499 {
  height: 149.9rem;
}

.h-1500 {
  height: 150rem;
}

.h-1501 {
  height: 150.1rem;
}

.h-1502 {
  height: 150.2rem;
}

.h-1503 {
  height: 150.3rem;
}

.h-1504 {
  height: 150.4rem;
}

.h-1505 {
  height: 150.5rem;
}

.h-1506 {
  height: 150.6rem;
}

.h-1507 {
  height: 150.7rem;
}

.h-1508 {
  height: 150.8rem;
}

.h-1509 {
  height: 150.9rem;
}

.h-1510 {
  height: 151rem;
}

.h-1511 {
  height: 151.1rem;
}

.h-1512 {
  height: 151.2rem;
}

.h-1513 {
  height: 151.3rem;
}

.h-1514 {
  height: 151.4rem;
}

.h-1515 {
  height: 151.5rem;
}

.h-1516 {
  height: 151.6rem;
}

.h-1517 {
  height: 151.7rem;
}

.h-1518 {
  height: 151.8rem;
}

.h-1519 {
  height: 151.9rem;
}

.h-1520 {
  height: 152rem;
}

.h-1521 {
  height: 152.1rem;
}

.h-1522 {
  height: 152.2rem;
}

.h-1523 {
  height: 152.3rem;
}

.h-1524 {
  height: 152.4rem;
}

.h-1525 {
  height: 152.5rem;
}

.h-1526 {
  height: 152.6rem;
}

.h-1527 {
  height: 152.7rem;
}

.h-1528 {
  height: 152.8rem;
}

.h-1529 {
  height: 152.9rem;
}

.h-1530 {
  height: 153rem;
}

.h-1531 {
  height: 153.1rem;
}

.h-1532 {
  height: 153.2rem;
}

.h-1533 {
  height: 153.3rem;
}

.h-1534 {
  height: 153.4rem;
}

.h-1535 {
  height: 153.5rem;
}

.h-1536 {
  height: 153.6rem;
}

.h-1537 {
  height: 153.7rem;
}

.h-1538 {
  height: 153.8rem;
}

.h-1539 {
  height: 153.9rem;
}

.h-1540 {
  height: 154rem;
}

.h-1541 {
  height: 154.1rem;
}

.h-1542 {
  height: 154.2rem;
}

.h-1543 {
  height: 154.3rem;
}

.h-1544 {
  height: 154.4rem;
}

.h-1545 {
  height: 154.5rem;
}

.h-1546 {
  height: 154.6rem;
}

.h-1547 {
  height: 154.7rem;
}

.h-1548 {
  height: 154.8rem;
}

.h-1549 {
  height: 154.9rem;
}

.h-1550 {
  height: 155rem;
}

.h-1551 {
  height: 155.1rem;
}

.h-1552 {
  height: 155.2rem;
}

.h-1553 {
  height: 155.3rem;
}

.h-1554 {
  height: 155.4rem;
}

.h-1555 {
  height: 155.5rem;
}

.h-1556 {
  height: 155.6rem;
}

.h-1557 {
  height: 155.7rem;
}

.h-1558 {
  height: 155.8rem;
}

.h-1559 {
  height: 155.9rem;
}

.h-1560 {
  height: 156rem;
}

.h-1561 {
  height: 156.1rem;
}

.h-1562 {
  height: 156.2rem;
}

.h-1563 {
  height: 156.3rem;
}

.h-1564 {
  height: 156.4rem;
}

.h-1565 {
  height: 156.5rem;
}

.h-1566 {
  height: 156.6rem;
}

.h-1567 {
  height: 156.7rem;
}

.h-1568 {
  height: 156.8rem;
}

.h-1569 {
  height: 156.9rem;
}

.h-1570 {
  height: 157rem;
}

.h-1571 {
  height: 157.1rem;
}

.h-1572 {
  height: 157.2rem;
}

.h-1573 {
  height: 157.3rem;
}

.h-1574 {
  height: 157.4rem;
}

.h-1575 {
  height: 157.5rem;
}

.h-1576 {
  height: 157.6rem;
}

.h-1577 {
  height: 157.7rem;
}

.h-1578 {
  height: 157.8rem;
}

.h-1579 {
  height: 157.9rem;
}

.h-1580 {
  height: 158rem;
}

.h-1581 {
  height: 158.1rem;
}

.h-1582 {
  height: 158.2rem;
}

.h-1583 {
  height: 158.3rem;
}

.h-1584 {
  height: 158.4rem;
}

.h-1585 {
  height: 158.5rem;
}

.h-1586 {
  height: 158.6rem;
}

.h-1587 {
  height: 158.7rem;
}

.h-1588 {
  height: 158.8rem;
}

.h-1589 {
  height: 158.9rem;
}

.h-1590 {
  height: 159rem;
}

.h-1591 {
  height: 159.1rem;
}

.h-1592 {
  height: 159.2rem;
}

.h-1593 {
  height: 159.3rem;
}

.h-1594 {
  height: 159.4rem;
}

.h-1595 {
  height: 159.5rem;
}

.h-1596 {
  height: 159.6rem;
}

.h-1597 {
  height: 159.7rem;
}

.h-1598 {
  height: 159.8rem;
}

.h-1599 {
  height: 159.9rem;
}

.h-1600 {
  height: 160rem;
}

.h-1601 {
  height: 160.1rem;
}

.h-1602 {
  height: 160.2rem;
}

.h-1603 {
  height: 160.3rem;
}

.h-1604 {
  height: 160.4rem;
}

.h-1605 {
  height: 160.5rem;
}

.h-1606 {
  height: 160.6rem;
}

.h-1607 {
  height: 160.7rem;
}

.h-1608 {
  height: 160.8rem;
}

.h-1609 {
  height: 160.9rem;
}

.h-1610 {
  height: 161rem;
}

.h-1611 {
  height: 161.1rem;
}

.h-1612 {
  height: 161.2rem;
}

.h-1613 {
  height: 161.3rem;
}

.h-1614 {
  height: 161.4rem;
}

.h-1615 {
  height: 161.5rem;
}

.h-1616 {
  height: 161.6rem;
}

.h-1617 {
  height: 161.7rem;
}

.h-1618 {
  height: 161.8rem;
}

.h-1619 {
  height: 161.9rem;
}

.h-1620 {
  height: 162rem;
}

.h-1621 {
  height: 162.1rem;
}

.h-1622 {
  height: 162.2rem;
}

.h-1623 {
  height: 162.3rem;
}

.h-1624 {
  height: 162.4rem;
}

.h-1625 {
  height: 162.5rem;
}

.h-1626 {
  height: 162.6rem;
}

.h-1627 {
  height: 162.7rem;
}

.h-1628 {
  height: 162.8rem;
}

.h-1629 {
  height: 162.9rem;
}

.h-1630 {
  height: 163rem;
}

.h-1631 {
  height: 163.1rem;
}

.h-1632 {
  height: 163.2rem;
}

.h-1633 {
  height: 163.3rem;
}

.h-1634 {
  height: 163.4rem;
}

.h-1635 {
  height: 163.5rem;
}

.h-1636 {
  height: 163.6rem;
}

.h-1637 {
  height: 163.7rem;
}

.h-1638 {
  height: 163.8rem;
}

.h-1639 {
  height: 163.9rem;
}

.h-1640 {
  height: 164rem;
}

.h-1641 {
  height: 164.1rem;
}

.h-1642 {
  height: 164.2rem;
}

.h-1643 {
  height: 164.3rem;
}

.h-1644 {
  height: 164.4rem;
}

.h-1645 {
  height: 164.5rem;
}

.h-1646 {
  height: 164.6rem;
}

.h-1647 {
  height: 164.7rem;
}

.h-1648 {
  height: 164.8rem;
}

.h-1649 {
  height: 164.9rem;
}

.h-1650 {
  height: 165rem;
}

.h-1651 {
  height: 165.1rem;
}

.h-1652 {
  height: 165.2rem;
}

.h-1653 {
  height: 165.3rem;
}

.h-1654 {
  height: 165.4rem;
}

.h-1655 {
  height: 165.5rem;
}

.h-1656 {
  height: 165.6rem;
}

.h-1657 {
  height: 165.7rem;
}

.h-1658 {
  height: 165.8rem;
}

.h-1659 {
  height: 165.9rem;
}

.h-1660 {
  height: 166rem;
}

.h-1661 {
  height: 166.1rem;
}

.h-1662 {
  height: 166.2rem;
}

.h-1663 {
  height: 166.3rem;
}

.h-1664 {
  height: 166.4rem;
}

.h-1665 {
  height: 166.5rem;
}

.h-1666 {
  height: 166.6rem;
}

.h-1667 {
  height: 166.7rem;
}

.h-1668 {
  height: 166.8rem;
}

.h-1669 {
  height: 166.9rem;
}

.h-1670 {
  height: 167rem;
}

.h-1671 {
  height: 167.1rem;
}

.h-1672 {
  height: 167.2rem;
}

.h-1673 {
  height: 167.3rem;
}

.h-1674 {
  height: 167.4rem;
}

.h-1675 {
  height: 167.5rem;
}

.h-1676 {
  height: 167.6rem;
}

.h-1677 {
  height: 167.7rem;
}

.h-1678 {
  height: 167.8rem;
}

.h-1679 {
  height: 167.9rem;
}

.h-1680 {
  height: 168rem;
}

.h-1681 {
  height: 168.1rem;
}

.h-1682 {
  height: 168.2rem;
}

.h-1683 {
  height: 168.3rem;
}

.h-1684 {
  height: 168.4rem;
}

.h-1685 {
  height: 168.5rem;
}

.h-1686 {
  height: 168.6rem;
}

.h-1687 {
  height: 168.7rem;
}

.h-1688 {
  height: 168.8rem;
}

.h-1689 {
  height: 168.9rem;
}

.h-1690 {
  height: 169rem;
}

.h-1691 {
  height: 169.1rem;
}

.h-1692 {
  height: 169.2rem;
}

.h-1693 {
  height: 169.3rem;
}

.h-1694 {
  height: 169.4rem;
}

.h-1695 {
  height: 169.5rem;
}

.h-1696 {
  height: 169.6rem;
}

.h-1697 {
  height: 169.7rem;
}

.h-1698 {
  height: 169.8rem;
}

.h-1699 {
  height: 169.9rem;
}

.h-1700 {
  height: 170rem;
}

.h-1701 {
  height: 170.1rem;
}

.h-1702 {
  height: 170.2rem;
}

.h-1703 {
  height: 170.3rem;
}

.h-1704 {
  height: 170.4rem;
}

.h-1705 {
  height: 170.5rem;
}

.h-1706 {
  height: 170.6rem;
}

.h-1707 {
  height: 170.7rem;
}

.h-1708 {
  height: 170.8rem;
}

.h-1709 {
  height: 170.9rem;
}

.h-1710 {
  height: 171rem;
}

.h-1711 {
  height: 171.1rem;
}

.h-1712 {
  height: 171.2rem;
}

.h-1713 {
  height: 171.3rem;
}

.h-1714 {
  height: 171.4rem;
}

.h-1715 {
  height: 171.5rem;
}

.h-1716 {
  height: 171.6rem;
}

.h-1717 {
  height: 171.7rem;
}

.h-1718 {
  height: 171.8rem;
}

.h-1719 {
  height: 171.9rem;
}

.h-1720 {
  height: 172rem;
}

.h-1721 {
  height: 172.1rem;
}

.h-1722 {
  height: 172.2rem;
}

.h-1723 {
  height: 172.3rem;
}

.h-1724 {
  height: 172.4rem;
}

.h-1725 {
  height: 172.5rem;
}

.h-1726 {
  height: 172.6rem;
}

.h-1727 {
  height: 172.7rem;
}

.h-1728 {
  height: 172.8rem;
}

.h-1729 {
  height: 172.9rem;
}

.h-1730 {
  height: 173rem;
}

.h-1731 {
  height: 173.1rem;
}

.h-1732 {
  height: 173.2rem;
}

.h-1733 {
  height: 173.3rem;
}

.h-1734 {
  height: 173.4rem;
}

.h-1735 {
  height: 173.5rem;
}

.h-1736 {
  height: 173.6rem;
}

.h-1737 {
  height: 173.7rem;
}

.h-1738 {
  height: 173.8rem;
}

.h-1739 {
  height: 173.9rem;
}

.h-1740 {
  height: 174rem;
}

.h-1741 {
  height: 174.1rem;
}

.h-1742 {
  height: 174.2rem;
}

.h-1743 {
  height: 174.3rem;
}

.h-1744 {
  height: 174.4rem;
}

.h-1745 {
  height: 174.5rem;
}

.h-1746 {
  height: 174.6rem;
}

.h-1747 {
  height: 174.7rem;
}

.h-1748 {
  height: 174.8rem;
}

.h-1749 {
  height: 174.9rem;
}

.h-1750 {
  height: 175rem;
}

.h-1751 {
  height: 175.1rem;
}

.h-1752 {
  height: 175.2rem;
}

.h-1753 {
  height: 175.3rem;
}

.h-1754 {
  height: 175.4rem;
}

.h-1755 {
  height: 175.5rem;
}

.h-1756 {
  height: 175.6rem;
}

.h-1757 {
  height: 175.7rem;
}

.h-1758 {
  height: 175.8rem;
}

.h-1759 {
  height: 175.9rem;
}

.h-1760 {
  height: 176rem;
}

.h-1761 {
  height: 176.1rem;
}

.h-1762 {
  height: 176.2rem;
}

.h-1763 {
  height: 176.3rem;
}

.h-1764 {
  height: 176.4rem;
}

.h-1765 {
  height: 176.5rem;
}

.h-1766 {
  height: 176.6rem;
}

.h-1767 {
  height: 176.7rem;
}

.h-1768 {
  height: 176.8rem;
}

.h-1769 {
  height: 176.9rem;
}

.h-1770 {
  height: 177rem;
}

.h-1771 {
  height: 177.1rem;
}

.h-1772 {
  height: 177.2rem;
}

.h-1773 {
  height: 177.3rem;
}

.h-1774 {
  height: 177.4rem;
}

.h-1775 {
  height: 177.5rem;
}

.h-1776 {
  height: 177.6rem;
}

.h-1777 {
  height: 177.7rem;
}

.h-1778 {
  height: 177.8rem;
}

.h-1779 {
  height: 177.9rem;
}

.h-1780 {
  height: 178rem;
}

.h-1781 {
  height: 178.1rem;
}

.h-1782 {
  height: 178.2rem;
}

.h-1783 {
  height: 178.3rem;
}

.h-1784 {
  height: 178.4rem;
}

.h-1785 {
  height: 178.5rem;
}

.h-1786 {
  height: 178.6rem;
}

.h-1787 {
  height: 178.7rem;
}

.h-1788 {
  height: 178.8rem;
}

.h-1789 {
  height: 178.9rem;
}

.h-1790 {
  height: 179rem;
}

.h-1791 {
  height: 179.1rem;
}

.h-1792 {
  height: 179.2rem;
}

.h-1793 {
  height: 179.3rem;
}

.h-1794 {
  height: 179.4rem;
}

.h-1795 {
  height: 179.5rem;
}

.h-1796 {
  height: 179.6rem;
}

.h-1797 {
  height: 179.7rem;
}

.h-1798 {
  height: 179.8rem;
}

.h-1799 {
  height: 179.9rem;
}

.h-1800 {
  height: 180rem;
}

.h-1801 {
  height: 180.1rem;
}

.h-1802 {
  height: 180.2rem;
}

.h-1803 {
  height: 180.3rem;
}

.h-1804 {
  height: 180.4rem;
}

.h-1805 {
  height: 180.5rem;
}

.h-1806 {
  height: 180.6rem;
}

.h-1807 {
  height: 180.7rem;
}

.h-1808 {
  height: 180.8rem;
}

.h-1809 {
  height: 180.9rem;
}

.h-1810 {
  height: 181rem;
}

.h-1811 {
  height: 181.1rem;
}

.h-1812 {
  height: 181.2rem;
}

.h-1813 {
  height: 181.3rem;
}

.h-1814 {
  height: 181.4rem;
}

.h-1815 {
  height: 181.5rem;
}

.h-1816 {
  height: 181.6rem;
}

.h-1817 {
  height: 181.7rem;
}

.h-1818 {
  height: 181.8rem;
}

.h-1819 {
  height: 181.9rem;
}

.h-1820 {
  height: 182rem;
}

.h-1821 {
  height: 182.1rem;
}

.h-1822 {
  height: 182.2rem;
}

.h-1823 {
  height: 182.3rem;
}

.h-1824 {
  height: 182.4rem;
}

.h-1825 {
  height: 182.5rem;
}

.h-1826 {
  height: 182.6rem;
}

.h-1827 {
  height: 182.7rem;
}

.h-1828 {
  height: 182.8rem;
}

.h-1829 {
  height: 182.9rem;
}

.h-1830 {
  height: 183rem;
}

.h-1831 {
  height: 183.1rem;
}

.h-1832 {
  height: 183.2rem;
}

.h-1833 {
  height: 183.3rem;
}

.h-1834 {
  height: 183.4rem;
}

.h-1835 {
  height: 183.5rem;
}

.h-1836 {
  height: 183.6rem;
}

.h-1837 {
  height: 183.7rem;
}

.h-1838 {
  height: 183.8rem;
}

.h-1839 {
  height: 183.9rem;
}

.h-1840 {
  height: 184rem;
}

.h-1841 {
  height: 184.1rem;
}

.h-1842 {
  height: 184.2rem;
}

.h-1843 {
  height: 184.3rem;
}

.h-1844 {
  height: 184.4rem;
}

.h-1845 {
  height: 184.5rem;
}

.h-1846 {
  height: 184.6rem;
}

.h-1847 {
  height: 184.7rem;
}

.h-1848 {
  height: 184.8rem;
}

.h-1849 {
  height: 184.9rem;
}

.h-1850 {
  height: 185rem;
}

.h-1851 {
  height: 185.1rem;
}

.h-1852 {
  height: 185.2rem;
}

.h-1853 {
  height: 185.3rem;
}

.h-1854 {
  height: 185.4rem;
}

.h-1855 {
  height: 185.5rem;
}

.h-1856 {
  height: 185.6rem;
}

.h-1857 {
  height: 185.7rem;
}

.h-1858 {
  height: 185.8rem;
}

.h-1859 {
  height: 185.9rem;
}

.h-1860 {
  height: 186rem;
}

.h-1861 {
  height: 186.1rem;
}

.h-1862 {
  height: 186.2rem;
}

.h-1863 {
  height: 186.3rem;
}

.h-1864 {
  height: 186.4rem;
}

.h-1865 {
  height: 186.5rem;
}

.h-1866 {
  height: 186.6rem;
}

.h-1867 {
  height: 186.7rem;
}

.h-1868 {
  height: 186.8rem;
}

.h-1869 {
  height: 186.9rem;
}

.h-1870 {
  height: 187rem;
}

.h-1871 {
  height: 187.1rem;
}

.h-1872 {
  height: 187.2rem;
}

.h-1873 {
  height: 187.3rem;
}

.h-1874 {
  height: 187.4rem;
}

.h-1875 {
  height: 187.5rem;
}

.h-1876 {
  height: 187.6rem;
}

.h-1877 {
  height: 187.7rem;
}

.h-1878 {
  height: 187.8rem;
}

.h-1879 {
  height: 187.9rem;
}

.h-1880 {
  height: 188rem;
}

.h-1881 {
  height: 188.1rem;
}

.h-1882 {
  height: 188.2rem;
}

.h-1883 {
  height: 188.3rem;
}

.h-1884 {
  height: 188.4rem;
}

.h-1885 {
  height: 188.5rem;
}

.h-1886 {
  height: 188.6rem;
}

.h-1887 {
  height: 188.7rem;
}

.h-1888 {
  height: 188.8rem;
}

.h-1889 {
  height: 188.9rem;
}

.h-1890 {
  height: 189rem;
}

.h-1891 {
  height: 189.1rem;
}

.h-1892 {
  height: 189.2rem;
}

.h-1893 {
  height: 189.3rem;
}

.h-1894 {
  height: 189.4rem;
}

.h-1895 {
  height: 189.5rem;
}

.h-1896 {
  height: 189.6rem;
}

.h-1897 {
  height: 189.7rem;
}

.h-1898 {
  height: 189.8rem;
}

.h-1899 {
  height: 189.9rem;
}

.h-1900 {
  height: 190rem;
}

.h-1901 {
  height: 190.1rem;
}

.h-1902 {
  height: 190.2rem;
}

.h-1903 {
  height: 190.3rem;
}

.h-1904 {
  height: 190.4rem;
}

.h-1905 {
  height: 190.5rem;
}

.h-1906 {
  height: 190.6rem;
}

.h-1907 {
  height: 190.7rem;
}

.h-1908 {
  height: 190.8rem;
}

.h-1909 {
  height: 190.9rem;
}

.h-1910 {
  height: 191rem;
}

.h-1911 {
  height: 191.1rem;
}

.h-1912 {
  height: 191.2rem;
}

.h-1913 {
  height: 191.3rem;
}

.h-1914 {
  height: 191.4rem;
}

.h-1915 {
  height: 191.5rem;
}

.h-1916 {
  height: 191.6rem;
}

.h-1917 {
  height: 191.7rem;
}

.h-1918 {
  height: 191.8rem;
}

.h-1919 {
  height: 191.9rem;
}

.h-1920 {
  height: 192rem;
}

.h-1921 {
  height: 192.1rem;
}

.h-1922 {
  height: 192.2rem;
}

.h-1923 {
  height: 192.3rem;
}

.h-1924 {
  height: 192.4rem;
}

.h-1925 {
  height: 192.5rem;
}

.h-1926 {
  height: 192.6rem;
}

.h-1927 {
  height: 192.7rem;
}

.h-1928 {
  height: 192.8rem;
}

.h-1929 {
  height: 192.9rem;
}

.h-1930 {
  height: 193rem;
}

.h-1931 {
  height: 193.1rem;
}

.h-1932 {
  height: 193.2rem;
}

.h-1933 {
  height: 193.3rem;
}

.h-1934 {
  height: 193.4rem;
}

.h-1935 {
  height: 193.5rem;
}

.h-1936 {
  height: 193.6rem;
}

.h-1937 {
  height: 193.7rem;
}

.h-1938 {
  height: 193.8rem;
}

.h-1939 {
  height: 193.9rem;
}

.h-1940 {
  height: 194rem;
}

.h-1941 {
  height: 194.1rem;
}

.h-1942 {
  height: 194.2rem;
}

.h-1943 {
  height: 194.3rem;
}

.h-1944 {
  height: 194.4rem;
}

.h-1945 {
  height: 194.5rem;
}

.h-1946 {
  height: 194.6rem;
}

.h-1947 {
  height: 194.7rem;
}

.h-1948 {
  height: 194.8rem;
}

.h-1949 {
  height: 194.9rem;
}

.h-1950 {
  height: 195rem;
}

.h-1951 {
  height: 195.1rem;
}

.h-1952 {
  height: 195.2rem;
}

.h-1953 {
  height: 195.3rem;
}

.h-1954 {
  height: 195.4rem;
}

.h-1955 {
  height: 195.5rem;
}

.h-1956 {
  height: 195.6rem;
}

.h-1957 {
  height: 195.7rem;
}

.h-1958 {
  height: 195.8rem;
}

.h-1959 {
  height: 195.9rem;
}

.h-1960 {
  height: 196rem;
}

.h-1961 {
  height: 196.1rem;
}

.h-1962 {
  height: 196.2rem;
}

.h-1963 {
  height: 196.3rem;
}

.h-1964 {
  height: 196.4rem;
}

.h-1965 {
  height: 196.5rem;
}

.h-1966 {
  height: 196.6rem;
}

.h-1967 {
  height: 196.7rem;
}

.h-1968 {
  height: 196.8rem;
}

.h-1969 {
  height: 196.9rem;
}

.h-1970 {
  height: 197rem;
}

.h-1971 {
  height: 197.1rem;
}

.h-1972 {
  height: 197.2rem;
}

.h-1973 {
  height: 197.3rem;
}

.h-1974 {
  height: 197.4rem;
}

.h-1975 {
  height: 197.5rem;
}

.h-1976 {
  height: 197.6rem;
}

.h-1977 {
  height: 197.7rem;
}

.h-1978 {
  height: 197.8rem;
}

.h-1979 {
  height: 197.9rem;
}

.h-1980 {
  height: 198rem;
}

.h-1981 {
  height: 198.1rem;
}

.h-1982 {
  height: 198.2rem;
}

.h-1983 {
  height: 198.3rem;
}

.h-1984 {
  height: 198.4rem;
}

.h-1985 {
  height: 198.5rem;
}

.h-1986 {
  height: 198.6rem;
}

.h-1987 {
  height: 198.7rem;
}

.h-1988 {
  height: 198.8rem;
}

.h-1989 {
  height: 198.9rem;
}

.h-1990 {
  height: 199rem;
}

.h-1991 {
  height: 199.1rem;
}

.h-1992 {
  height: 199.2rem;
}

.h-1993 {
  height: 199.3rem;
}

.h-1994 {
  height: 199.4rem;
}

.h-1995 {
  height: 199.5rem;
}

.h-1996 {
  height: 199.6rem;
}

.h-1997 {
  height: 199.7rem;
}

.h-1998 {
  height: 199.8rem;
}

.h-1999 {
  height: 199.9rem;
}

.hp-0 {
  height: 0%;
}

.hp-1 {
  height: 1%;
}

.hp-2 {
  height: 2%;
}

.hp-3 {
  height: 3%;
}

.hp-4 {
  height: 4%;
}

.hp-5 {
  height: 5%;
}

.hp-6 {
  height: 6%;
}

.hp-7 {
  height: 7%;
}

.hp-8 {
  height: 8%;
}

.hp-9 {
  height: 9%;
}

.hp-10 {
  height: 10%;
}

.hp-11 {
  height: 11%;
}

.hp-12 {
  height: 12%;
}

.hp-13 {
  height: 13%;
}

.hp-14 {
  height: 14%;
}

.hp-15 {
  height: 15%;
}

.hp-16 {
  height: 16%;
}

.hp-17 {
  height: 17%;
}

.hp-18 {
  height: 18%;
}

.hp-19 {
  height: 19%;
}

.hp-20 {
  height: 20%;
}

.hp-21 {
  height: 21%;
}

.hp-22 {
  height: 22%;
}

.hp-23 {
  height: 23%;
}

.hp-24 {
  height: 24%;
}

.hp-25 {
  height: 25%;
}

.hp-26 {
  height: 26%;
}

.hp-27 {
  height: 27%;
}

.hp-28 {
  height: 28%;
}

.hp-29 {
  height: 29%;
}

.hp-30 {
  height: 30%;
}

.hp-31 {
  height: 31%;
}

.hp-32 {
  height: 32%;
}

.hp-33 {
  height: 33%;
}

.hp-34 {
  height: 34%;
}

.hp-35 {
  height: 35%;
}

.hp-36 {
  height: 36%;
}

.hp-37 {
  height: 37%;
}

.hp-38 {
  height: 38%;
}

.hp-39 {
  height: 39%;
}

.hp-40 {
  height: 40%;
}

.hp-41 {
  height: 41%;
}

.hp-42 {
  height: 42%;
}

.hp-43 {
  height: 43%;
}

.hp-44 {
  height: 44%;
}

.hp-45 {
  height: 45%;
}

.hp-46 {
  height: 46%;
}

.hp-47 {
  height: 47%;
}

.hp-48 {
  height: 48%;
}

.hp-49 {
  height: 49%;
}

.hp-50 {
  height: 50%;
}

.hp-51 {
  height: 51%;
}

.hp-52 {
  height: 52%;
}

.hp-53 {
  height: 53%;
}

.hp-54 {
  height: 54%;
}

.hp-55 {
  height: 55%;
}

.hp-56 {
  height: 56%;
}

.hp-57 {
  height: 57%;
}

.hp-58 {
  height: 58%;
}

.hp-59 {
  height: 59%;
}

.hp-60 {
  height: 60%;
}

.hp-61 {
  height: 61%;
}

.hp-62 {
  height: 62%;
}

.hp-63 {
  height: 63%;
}

.hp-64 {
  height: 64%;
}

.hp-65 {
  height: 65%;
}

.hp-66 {
  height: 66%;
}

.hp-67 {
  height: 67%;
}

.hp-68 {
  height: 68%;
}

.hp-69 {
  height: 69%;
}

.hp-70 {
  height: 70%;
}

.hp-71 {
  height: 71%;
}

.hp-72 {
  height: 72%;
}

.hp-73 {
  height: 73%;
}

.hp-74 {
  height: 74%;
}

.hp-75 {
  height: 75%;
}

.hp-76 {
  height: 76%;
}

.hp-77 {
  height: 77%;
}

.hp-78 {
  height: 78%;
}

.hp-79 {
  height: 79%;
}

.hp-80 {
  height: 80%;
}

.hp-81 {
  height: 81%;
}

.hp-82 {
  height: 82%;
}

.hp-83 {
  height: 83%;
}

.hp-84 {
  height: 84%;
}

.hp-85 {
  height: 85%;
}

.hp-86 {
  height: 86%;
}

.hp-87 {
  height: 87%;
}

.hp-88 {
  height: 88%;
}

.hp-89 {
  height: 89%;
}

.hp-90 {
  height: 90%;
}

.hp-91 {
  height: 91%;
}

.hp-92 {
  height: 92%;
}

.hp-93 {
  height: 93%;
}

.hp-94 {
  height: 94%;
}

.hp-95 {
  height: 95%;
}

.hp-96 {
  height: 96%;
}

.hp-97 {
  height: 97%;
}

.hp-98 {
  height: 98%;
}

.hp-99 {
  height: 99%;
}

.hv-0 {
  height: 0vh;
}

.hv-1 {
  height: 1vh;
}

.hv-2 {
  height: 2vh;
}

.hv-3 {
  height: 3vh;
}

.hv-4 {
  height: 4vh;
}

.hv-5 {
  height: 5vh;
}

.hv-6 {
  height: 6vh;
}

.hv-7 {
  height: 7vh;
}

.hv-8 {
  height: 8vh;
}

.hv-9 {
  height: 9vh;
}

.hv-10 {
  height: 10vh;
}

.hv-11 {
  height: 11vh;
}

.hv-12 {
  height: 12vh;
}

.hv-13 {
  height: 13vh;
}

.hv-14 {
  height: 14vh;
}

.hv-15 {
  height: 15vh;
}

.hv-16 {
  height: 16vh;
}

.hv-17 {
  height: 17vh;
}

.hv-18 {
  height: 18vh;
}

.hv-19 {
  height: 19vh;
}

.hv-20 {
  height: 20vh;
}

.hv-21 {
  height: 21vh;
}

.hv-22 {
  height: 22vh;
}

.hv-23 {
  height: 23vh;
}

.hv-24 {
  height: 24vh;
}

.hv-25 {
  height: 25vh;
}

.hv-26 {
  height: 26vh;
}

.hv-27 {
  height: 27vh;
}

.hv-28 {
  height: 28vh;
}

.hv-29 {
  height: 29vh;
}

.hv-30 {
  height: 30vh;
}

.hv-31 {
  height: 31vh;
}

.hv-32 {
  height: 32vh;
}

.hv-33 {
  height: 33vh;
}

.hv-34 {
  height: 34vh;
}

.hv-35 {
  height: 35vh;
}

.hv-36 {
  height: 36vh;
}

.hv-37 {
  height: 37vh;
}

.hv-38 {
  height: 38vh;
}

.hv-39 {
  height: 39vh;
}

.hv-40 {
  height: 40vh;
}

.hv-41 {
  height: 41vh;
}

.hv-42 {
  height: 42vh;
}

.hv-43 {
  height: 43vh;
}

.hv-44 {
  height: 44vh;
}

.hv-45 {
  height: 45vh;
}

.hv-46 {
  height: 46vh;
}

.hv-47 {
  height: 47vh;
}

.hv-48 {
  height: 48vh;
}

.hv-49 {
  height: 49vh;
}

.hv-50 {
  height: 50vh;
}

.hv-51 {
  height: 51vh;
}

.hv-52 {
  height: 52vh;
}

.hv-53 {
  height: 53vh;
}

.hv-54 {
  height: 54vh;
}

.hv-55 {
  height: 55vh;
}

.hv-56 {
  height: 56vh;
}

.hv-57 {
  height: 57vh;
}

.hv-58 {
  height: 58vh;
}

.hv-59 {
  height: 59vh;
}

.hv-60 {
  height: 60vh;
}

.hv-61 {
  height: 61vh;
}

.hv-62 {
  height: 62vh;
}

.hv-63 {
  height: 63vh;
}

.hv-64 {
  height: 64vh;
}

.hv-65 {
  height: 65vh;
}

.hv-66 {
  height: 66vh;
}

.hv-67 {
  height: 67vh;
}

.hv-68 {
  height: 68vh;
}

.hv-69 {
  height: 69vh;
}

.hv-70 {
  height: 70vh;
}

.hv-71 {
  height: 71vh;
}

.hv-72 {
  height: 72vh;
}

.hv-73 {
  height: 73vh;
}

.hv-74 {
  height: 74vh;
}

.hv-75 {
  height: 75vh;
}

.hv-76 {
  height: 76vh;
}

.hv-77 {
  height: 77vh;
}

.hv-78 {
  height: 78vh;
}

.hv-79 {
  height: 79vh;
}

.hv-80 {
  height: 80vh;
}

.hv-81 {
  height: 81vh;
}

.hv-82 {
  height: 82vh;
}

.hv-83 {
  height: 83vh;
}

.hv-84 {
  height: 84vh;
}

.hv-85 {
  height: 85vh;
}

.hv-86 {
  height: 86vh;
}

.hv-87 {
  height: 87vh;
}

.hv-88 {
  height: 88vh;
}

.hv-89 {
  height: 89vh;
}

.hv-90 {
  height: 90vh;
}

.hv-91 {
  height: 91vh;
}

.hv-92 {
  height: 92vh;
}

.hv-93 {
  height: 93vh;
}

.hv-94 {
  height: 94vh;
}

.hv-95 {
  height: 95vh;
}

.hv-96 {
  height: 96vh;
}

.hv-97 {
  height: 97vh;
}

.hv-98 {
  height: 98vh;
}

.hv-99 {
  height: 99vh;
}

.hv-100 {
  height: 100vh;
}

.font-thin {
  font-weight: thin;
}

.font-normal {
  font-weight: normal;
}

.font-lighter {
  font-weight: lighter;
}

.font-light {
  font-weight: light;
}

.font-bold {
  font-weight: bold;
}

.font-bolder {
  font-weight: bolder;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.shadow-xs {
  box-shadow: 0px 3px 4px -5px rgba(51, 51, 51, 0.03),
    0px 1px 2px rgba(51, 51, 51, 0.05);
}

.shadow-s {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
    0px 0px 2px rgba(51, 51, 51, 0.02);
}

.shadow-a {
  box-shadow: 0px 32px 41px -23px rgba(51, 51, 51, 0.07),
    0px 2px 6px rgba(51, 51, 51, 0.06);
}

.flex {
  display: flex;
}

.border-primary {
  border: 1px solid #cccccc;
}

.border-secondary {
  border: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error {
  border: 1px solid #ff0f00;
}

.border-info {
  border: 1px solid #f6c31c;
}

.border-white {
  border: 1px solid #f7f8f7;
}

.border-green {
  border: 1px solid #0b8376;
}

.border-purple {
  border: 1px solid #755ae2;
}

.border-orange {
  border: 1px solid #ea872d;
}

.border-black {
  border: 1px solid #020202;
}

.border-light-gray {
  border: 1px solid #fafafa;
}

.border-deep-green {
  border: 1px solid #014345;
}

.border-primary-1 {
  border: 1px solid #cccccc;
}

.border-secondary-1 {
  border: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1 {
  border: 1px solid #ff0f00;
}

.border-info-1 {
  border: 1px solid #f6c31c;
}

.border-white-1 {
  border: 1px solid #f7f8f7;
}

.border-green-1 {
  border: 1px solid #0b8376;
}

.border-purple-1 {
  border: 1px solid #755ae2;
}

.border-orange-1 {
  border: 1px solid #ea872d;
}

.border-black-1 {
  border: 1px solid #020202;
}

.border-light-gray-1 {
  border: 1px solid #fafafa;
}

.border-deep-green-1 {
  border: 1px solid #014345;
}

.border-t-primary {
  border-top: 1px solid #cccccc;
}

.border-t-secondary {
  border-top: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error {
  border-top: 1px solid #ff0f00;
}

.border-t-info {
  border-top: 1px solid #f6c31c;
}

.border-t-white {
  border-top: 1px solid #f7f8f7;
}

.border-t-green {
  border-top: 1px solid #0b8376;
}

.border-t-purple {
  border-top: 1px solid #755ae2;
}

.border-t-orange {
  border-top: 1px solid #ea872d;
}

.border-t-black {
  border-top: 1px solid #020202;
}

.border-t-light-gray {
  border-top: 1px solid #fafafa;
}

.border-t-deep-green {
  border-top: 1px solid #014345;
}

.border-t-primary-1 {
  border-top: 1px solid #cccccc;
}

.border-t-secondary-1 {
  border-top: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1 {
  border-top: 1px solid #ff0f00;
}

.border-t-info-1 {
  border-top: 1px solid #f6c31c;
}

.border-t-white-1 {
  border-top: 1px solid #f7f8f7;
}

.border-t-green-1 {
  border-top: 1px solid #0b8376;
}

.border-t-purple-1 {
  border-top: 1px solid #755ae2;
}

.border-t-orange-1 {
  border-top: 1px solid #ea872d;
}

.border-t-black-1 {
  border-top: 1px solid #020202;
}

.border-t-light-gray-1 {
  border-top: 1px solid #fafafa;
}

.border-t-deep-green-1 {
  border-top: 1px solid #014345;
}

.border-b-primary {
  border-bottom: 1px solid #cccccc;
}

.border-b-secondary {
  border-bottom: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error {
  border-bottom: 1px solid #ff0f00;
}

.border-b-info {
  border-bottom: 1px solid #f6c31c;
}

.border-b-white {
  border-bottom: 1px solid #f7f8f7;
}

.border-b-green {
  border-bottom: 1px solid #0b8376;
}

.border-b-purple {
  border-bottom: 1px solid #755ae2;
}

.border-b-orange {
  border-bottom: 1px solid #ea872d;
}

.border-b-black {
  border-bottom: 1px solid #020202;
}

.border-b-light-gray {
  border-bottom: 1px solid #fafafa;
}

.border-b-deep-green {
  border-bottom: 1px solid #014345;
}

.border-b-primary-1 {
  border-bottom: 1px solid #cccccc;
}

.border-b-secondary-1 {
  border-bottom: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1 {
  border-bottom: 1px solid #ff0f00;
}

.border-b-info-1 {
  border-bottom: 1px solid #f6c31c;
}

.border-b-white-1 {
  border-bottom: 1px solid #f7f8f7;
}

.border-b-green-1 {
  border-bottom: 1px solid #0b8376;
}

.border-b-purple-1 {
  border-bottom: 1px solid #755ae2;
}

.border-b-orange-1 {
  border-bottom: 1px solid #ea872d;
}

.border-b-black-1 {
  border-bottom: 1px solid #020202;
}

.border-b-light-gray-1 {
  border-bottom: 1px solid #fafafa;
}

.border-b-deep-green-1 {
  border-bottom: 1px solid #014345;
}

.border-l-primary {
  border-left: 1px solid #cccccc;
}

.border-l-secondary {
  border-left: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error {
  border-left: 1px solid #ff0f00;
}

.border-l-info {
  border-left: 1px solid #f6c31c;
}

.border-l-white {
  border-left: 1px solid #f7f8f7;
}

.border-l-green {
  border-left: 1px solid #0b8376;
}

.border-l-purple {
  border-left: 1px solid #755ae2;
}

.border-l-orange {
  border-left: 1px solid #ea872d;
}

.border-l-black {
  border-left: 1px solid #020202;
}

.border-l-light-gray {
  border-left: 1px solid #fafafa;
}

.border-l-deep-green {
  border-left: 1px solid #014345;
}

.border_l-primary-1 {
  border-left: 1px solid #cccccc;
}

.border_l-secondary-1 {
  border-left: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-1 {
  border-left: 1px solid #ff0f00;
}

.border_l-info-1 {
  border-left: 1px solid #f6c31c;
}

.border_l-white-1 {
  border-left: 1px solid #f7f8f7;
}

.border_l-green-1 {
  border-left: 1px solid #0b8376;
}

.border_l-purple-1 {
  border-left: 1px solid #755ae2;
}

.border_l-orange-1 {
  border-left: 1px solid #ea872d;
}

.border_l-black-1 {
  border-left: 1px solid #020202;
}

.border_l-light-gray-1 {
  border-left: 1px solid #fafafa;
}

.border_l-deep-green-1 {
  border-left: 1px solid #014345;
}

.border-r-primary {
  border-right: 1px solid #cccccc;
}

.border-r-secondary {
  border-right: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error {
  border-right: 1px solid #ff0f00;
}

.border-r-info {
  border-right: 1px solid #f6c31c;
}

.border-r-white {
  border-right: 1px solid #f7f8f7;
}

.border-r-green {
  border-right: 1px solid #0b8376;
}

.border-r-purple {
  border-right: 1px solid #755ae2;
}

.border-r-orange {
  border-right: 1px solid #ea872d;
}

.border-r-black {
  border-right: 1px solid #020202;
}

.border-r-light-gray {
  border-right: 1px solid #fafafa;
}

.border-r-deep-green {
  border-right: 1px solid #014345;
}

.border-r-primary-1 {
  border-right: 1px solid #cccccc;
}

.border-r-secondary-1 {
  border-right: 1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1 {
  border-right: 1px solid #ff0f00;
}

.border-r-info-1 {
  border-right: 1px solid #f6c31c;
}

.border-r-white-1 {
  border-right: 1px solid #f7f8f7;
}

.border-r-green-1 {
  border-right: 1px solid #0b8376;
}

.border-r-purple-1 {
  border-right: 1px solid #755ae2;
}

.border-r-orange-1 {
  border-right: 1px solid #ea872d;
}

.border-r-black-1 {
  border-right: 1px solid #020202;
}

.border-r-light-gray-1 {
  border-right: 1px solid #fafafa;
}

.border-r-deep-green-1 {
  border-right: 1px solid #014345;
}

.border-primary-1-1 {
  border: 1.1px solid #cccccc;
}

.border-secondary-1-1 {
  border: 1.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-1 {
  border: 1.1px solid #ff0f00;
}

.border-info-1-1 {
  border: 1.1px solid #f6c31c;
}

.border-white-1-1 {
  border: 1.1px solid #f7f8f7;
}

.border-green-1-1 {
  border: 1.1px solid #0b8376;
}

.border-purple-1-1 {
  border: 1.1px solid #755ae2;
}

.border-orange-1-1 {
  border: 1.1px solid #ea872d;
}

.border-black-1-1 {
  border: 1.1px solid #020202;
}

.border-light-gray-1-1 {
  border: 1.1px solid #fafafa;
}

.border-deep-green-1-1 {
  border: 1.1px solid #014345;
}

.border-t-primary-1-1 {
  border-top: 1.1px solid #cccccc;
}

.border-t-secondary-1-1 {
  border-top: 1.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-1 {
  border-top: 1.1px solid #ff0f00;
}

.border-t-info-1-1 {
  border-top: 1.1px solid #f6c31c;
}

.border-t-white-1-1 {
  border-top: 1.1px solid #f7f8f7;
}

.border-t-green-1-1 {
  border-top: 1.1px solid #0b8376;
}

.border-t-purple-1-1 {
  border-top: 1.1px solid #755ae2;
}

.border-t-orange-1-1 {
  border-top: 1.1px solid #ea872d;
}

.border-t-black-1-1 {
  border-top: 1.1px solid #020202;
}

.border-t-light-gray-1-1 {
  border-top: 1.1px solid #fafafa;
}

.border-t-deep-green-1-1 {
  border-top: 1.1px solid #014345;
}

.border-b-primary-1-1 {
  border-bottom: 1.1px solid #cccccc;
}

.border-b-secondary-1-1 {
  border-bottom: 1.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-1 {
  border-bottom: 1.1px solid #ff0f00;
}

.border-b-info-1-1 {
  border-bottom: 1.1px solid #f6c31c;
}

.border-b-white-1-1 {
  border-bottom: 1.1px solid #f7f8f7;
}

.border-b-green-1-1 {
  border-bottom: 1.1px solid #0b8376;
}

.border-b-purple-1-1 {
  border-bottom: 1.1px solid #755ae2;
}

.border-b-orange-1-1 {
  border-bottom: 1.1px solid #ea872d;
}

.border-b-black-1-1 {
  border-bottom: 1.1px solid #020202;
}

.border-b-light-gray-1-1 {
  border-bottom: 1.1px solid #fafafa;
}

.border-b-deep-green-1-1 {
  border-bottom: 1.1px solid #014345;
}

.border-l-primary-1-1 {
  border-left: 1.1px solid #cccccc;
}

.border-l-secondary-1-1 {
  border-left: 1.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-1 {
  border-left: 1.1px solid #ff0f00;
}

.border-l-info-1-1 {
  border-left: 1.1px solid #f6c31c;
}

.border-l-white-1-1 {
  border-left: 1.1px solid #f7f8f7;
}

.border-l-green-1-1 {
  border-left: 1.1px solid #0b8376;
}

.border-l-purple-1-1 {
  border-left: 1.1px solid #755ae2;
}

.border-l-orange-1-1 {
  border-left: 1.1px solid #ea872d;
}

.border-l-black-1-1 {
  border-left: 1.1px solid #020202;
}

.border-l-light-gray-1-1 {
  border-left: 1.1px solid #fafafa;
}

.border-l-deep-green-1-1 {
  border-left: 1.1px solid #014345;
}

.border-r-primary-1-1 {
  border-right: 1.1px solid #cccccc;
}

.border-r-secondary-1-1 {
  border-right: 1.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-1 {
  border-right: 1.1px solid #ff0f00;
}

.border-r-info-1-1 {
  border-right: 1.1px solid #f6c31c;
}

.border-r-white-1-1 {
  border-right: 1.1px solid #f7f8f7;
}

.border-r-green-1-1 {
  border-right: 1.1px solid #0b8376;
}

.border-r-purple-1-1 {
  border-right: 1.1px solid #755ae2;
}

.border-r-orange-1-1 {
  border-right: 1.1px solid #ea872d;
}

.border-r-black-1-1 {
  border-right: 1.1px solid #020202;
}

.border-r-light-gray-1-1 {
  border-right: 1.1px solid #fafafa;
}

.border-r-deep-green-1-1 {
  border-right: 1.1px solid #014345;
}

.border-primary-1-2 {
  border: 1.2px solid #cccccc;
}

.border-secondary-1-2 {
  border: 1.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-2 {
  border: 1.2px solid #ff0f00;
}

.border-info-1-2 {
  border: 1.2px solid #f6c31c;
}

.border-white-1-2 {
  border: 1.2px solid #f7f8f7;
}

.border-green-1-2 {
  border: 1.2px solid #0b8376;
}

.border-purple-1-2 {
  border: 1.2px solid #755ae2;
}

.border-orange-1-2 {
  border: 1.2px solid #ea872d;
}

.border-black-1-2 {
  border: 1.2px solid #020202;
}

.border-light-gray-1-2 {
  border: 1.2px solid #fafafa;
}

.border-deep-green-1-2 {
  border: 1.2px solid #014345;
}

.border-t-primary-1-2 {
  border-top: 1.2px solid #cccccc;
}

.border-t-secondary-1-2 {
  border-top: 1.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-2 {
  border-top: 1.2px solid #ff0f00;
}

.border-t-info-1-2 {
  border-top: 1.2px solid #f6c31c;
}

.border-t-white-1-2 {
  border-top: 1.2px solid #f7f8f7;
}

.border-t-green-1-2 {
  border-top: 1.2px solid #0b8376;
}

.border-t-purple-1-2 {
  border-top: 1.2px solid #755ae2;
}

.border-t-orange-1-2 {
  border-top: 1.2px solid #ea872d;
}

.border-t-black-1-2 {
  border-top: 1.2px solid #020202;
}

.border-t-light-gray-1-2 {
  border-top: 1.2px solid #fafafa;
}

.border-t-deep-green-1-2 {
  border-top: 1.2px solid #014345;
}

.border-b-primary-1-2 {
  border-bottom: 1.2px solid #cccccc;
}

.border-b-secondary-1-2 {
  border-bottom: 1.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-2 {
  border-bottom: 1.2px solid #ff0f00;
}

.border-b-info-1-2 {
  border-bottom: 1.2px solid #f6c31c;
}

.border-b-white-1-2 {
  border-bottom: 1.2px solid #f7f8f7;
}

.border-b-green-1-2 {
  border-bottom: 1.2px solid #0b8376;
}

.border-b-purple-1-2 {
  border-bottom: 1.2px solid #755ae2;
}

.border-b-orange-1-2 {
  border-bottom: 1.2px solid #ea872d;
}

.border-b-black-1-2 {
  border-bottom: 1.2px solid #020202;
}

.border-b-light-gray-1-2 {
  border-bottom: 1.2px solid #fafafa;
}

.border-b-deep-green-1-2 {
  border-bottom: 1.2px solid #014345;
}

.border-l-primary-1-2 {
  border-left: 1.2px solid #cccccc;
}

.border-l-secondary-1-2 {
  border-left: 1.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-2 {
  border-left: 1.2px solid #ff0f00;
}

.border-l-info-1-2 {
  border-left: 1.2px solid #f6c31c;
}

.border-l-white-1-2 {
  border-left: 1.2px solid #f7f8f7;
}

.border-l-green-1-2 {
  border-left: 1.2px solid #0b8376;
}

.border-l-purple-1-2 {
  border-left: 1.2px solid #755ae2;
}

.border-l-orange-1-2 {
  border-left: 1.2px solid #ea872d;
}

.border-l-black-1-2 {
  border-left: 1.2px solid #020202;
}

.border-l-light-gray-1-2 {
  border-left: 1.2px solid #fafafa;
}

.border-l-deep-green-1-2 {
  border-left: 1.2px solid #014345;
}

.border-r-primary-1-2 {
  border-right: 1.2px solid #cccccc;
}

.border-r-secondary-1-2 {
  border-right: 1.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-2 {
  border-right: 1.2px solid #ff0f00;
}

.border-r-info-1-2 {
  border-right: 1.2px solid #f6c31c;
}

.border-r-white-1-2 {
  border-right: 1.2px solid #f7f8f7;
}

.border-r-green-1-2 {
  border-right: 1.2px solid #0b8376;
}

.border-r-purple-1-2 {
  border-right: 1.2px solid #755ae2;
}

.border-r-orange-1-2 {
  border-right: 1.2px solid #ea872d;
}

.border-r-black-1-2 {
  border-right: 1.2px solid #020202;
}

.border-r-light-gray-1-2 {
  border-right: 1.2px solid #fafafa;
}

.border-r-deep-green-1-2 {
  border-right: 1.2px solid #014345;
}

.border-primary-1-3 {
  border: 1.3px solid #cccccc;
}

.border-secondary-1-3 {
  border: 1.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-3 {
  border: 1.3px solid #ff0f00;
}

.border-info-1-3 {
  border: 1.3px solid #f6c31c;
}

.border-white-1-3 {
  border: 1.3px solid #f7f8f7;
}

.border-green-1-3 {
  border: 1.3px solid #0b8376;
}

.border-purple-1-3 {
  border: 1.3px solid #755ae2;
}

.border-orange-1-3 {
  border: 1.3px solid #ea872d;
}

.border-black-1-3 {
  border: 1.3px solid #020202;
}

.border-light-gray-1-3 {
  border: 1.3px solid #fafafa;
}

.border-deep-green-1-3 {
  border: 1.3px solid #014345;
}

.border-t-primary-1-3 {
  border-top: 1.3px solid #cccccc;
}

.border-t-secondary-1-3 {
  border-top: 1.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-3 {
  border-top: 1.3px solid #ff0f00;
}

.border-t-info-1-3 {
  border-top: 1.3px solid #f6c31c;
}

.border-t-white-1-3 {
  border-top: 1.3px solid #f7f8f7;
}

.border-t-green-1-3 {
  border-top: 1.3px solid #0b8376;
}

.border-t-purple-1-3 {
  border-top: 1.3px solid #755ae2;
}

.border-t-orange-1-3 {
  border-top: 1.3px solid #ea872d;
}

.border-t-black-1-3 {
  border-top: 1.3px solid #020202;
}

.border-t-light-gray-1-3 {
  border-top: 1.3px solid #fafafa;
}

.border-t-deep-green-1-3 {
  border-top: 1.3px solid #014345;
}

.border-b-primary-1-3 {
  border-bottom: 1.3px solid #cccccc;
}

.border-b-secondary-1-3 {
  border-bottom: 1.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-3 {
  border-bottom: 1.3px solid #ff0f00;
}

.border-b-info-1-3 {
  border-bottom: 1.3px solid #f6c31c;
}

.border-b-white-1-3 {
  border-bottom: 1.3px solid #f7f8f7;
}

.border-b-green-1-3 {
  border-bottom: 1.3px solid #0b8376;
}

.border-b-purple-1-3 {
  border-bottom: 1.3px solid #755ae2;
}

.border-b-orange-1-3 {
  border-bottom: 1.3px solid #ea872d;
}

.border-b-black-1-3 {
  border-bottom: 1.3px solid #020202;
}

.border-b-light-gray-1-3 {
  border-bottom: 1.3px solid #fafafa;
}

.border-b-deep-green-1-3 {
  border-bottom: 1.3px solid #014345;
}

.border-l-primary-1-3 {
  border-left: 1.3px solid #cccccc;
}

.border-l-secondary-1-3 {
  border-left: 1.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-3 {
  border-left: 1.3px solid #ff0f00;
}

.border-l-info-1-3 {
  border-left: 1.3px solid #f6c31c;
}

.border-l-white-1-3 {
  border-left: 1.3px solid #f7f8f7;
}

.border-l-green-1-3 {
  border-left: 1.3px solid #0b8376;
}

.border-l-purple-1-3 {
  border-left: 1.3px solid #755ae2;
}

.border-l-orange-1-3 {
  border-left: 1.3px solid #ea872d;
}

.border-l-black-1-3 {
  border-left: 1.3px solid #020202;
}

.border-l-light-gray-1-3 {
  border-left: 1.3px solid #fafafa;
}

.border-l-deep-green-1-3 {
  border-left: 1.3px solid #014345;
}

.border-r-primary-1-3 {
  border-right: 1.3px solid #cccccc;
}

.border-r-secondary-1-3 {
  border-right: 1.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-3 {
  border-right: 1.3px solid #ff0f00;
}

.border-r-info-1-3 {
  border-right: 1.3px solid #f6c31c;
}

.border-r-white-1-3 {
  border-right: 1.3px solid #f7f8f7;
}

.border-r-green-1-3 {
  border-right: 1.3px solid #0b8376;
}

.border-r-purple-1-3 {
  border-right: 1.3px solid #755ae2;
}

.border-r-orange-1-3 {
  border-right: 1.3px solid #ea872d;
}

.border-r-black-1-3 {
  border-right: 1.3px solid #020202;
}

.border-r-light-gray-1-3 {
  border-right: 1.3px solid #fafafa;
}

.border-r-deep-green-1-3 {
  border-right: 1.3px solid #014345;
}

.border-primary-1-4 {
  border: 1.4px solid #cccccc;
}

.border-secondary-1-4 {
  border: 1.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-4 {
  border: 1.4px solid #ff0f00;
}

.border-info-1-4 {
  border: 1.4px solid #f6c31c;
}

.border-white-1-4 {
  border: 1.4px solid #f7f8f7;
}

.border-green-1-4 {
  border: 1.4px solid #0b8376;
}

.border-purple-1-4 {
  border: 1.4px solid #755ae2;
}

.border-orange-1-4 {
  border: 1.4px solid #ea872d;
}

.border-black-1-4 {
  border: 1.4px solid #020202;
}

.border-light-gray-1-4 {
  border: 1.4px solid #fafafa;
}

.border-deep-green-1-4 {
  border: 1.4px solid #014345;
}

.border-t-primary-1-4 {
  border-top: 1.4px solid #cccccc;
}

.border-t-secondary-1-4 {
  border-top: 1.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-4 {
  border-top: 1.4px solid #ff0f00;
}

.border-t-info-1-4 {
  border-top: 1.4px solid #f6c31c;
}

.border-t-white-1-4 {
  border-top: 1.4px solid #f7f8f7;
}

.border-t-green-1-4 {
  border-top: 1.4px solid #0b8376;
}

.border-t-purple-1-4 {
  border-top: 1.4px solid #755ae2;
}

.border-t-orange-1-4 {
  border-top: 1.4px solid #ea872d;
}

.border-t-black-1-4 {
  border-top: 1.4px solid #020202;
}

.border-t-light-gray-1-4 {
  border-top: 1.4px solid #fafafa;
}

.border-t-deep-green-1-4 {
  border-top: 1.4px solid #014345;
}

.border-b-primary-1-4 {
  border-bottom: 1.4px solid #cccccc;
}

.border-b-secondary-1-4 {
  border-bottom: 1.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-4 {
  border-bottom: 1.4px solid #ff0f00;
}

.border-b-info-1-4 {
  border-bottom: 1.4px solid #f6c31c;
}

.border-b-white-1-4 {
  border-bottom: 1.4px solid #f7f8f7;
}

.border-b-green-1-4 {
  border-bottom: 1.4px solid #0b8376;
}

.border-b-purple-1-4 {
  border-bottom: 1.4px solid #755ae2;
}

.border-b-orange-1-4 {
  border-bottom: 1.4px solid #ea872d;
}

.border-b-black-1-4 {
  border-bottom: 1.4px solid #020202;
}

.border-b-light-gray-1-4 {
  border-bottom: 1.4px solid #fafafa;
}

.border-b-deep-green-1-4 {
  border-bottom: 1.4px solid #014345;
}

.border-l-primary-1-4 {
  border-left: 1.4px solid #cccccc;
}

.border-l-secondary-1-4 {
  border-left: 1.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-4 {
  border-left: 1.4px solid #ff0f00;
}

.border-l-info-1-4 {
  border-left: 1.4px solid #f6c31c;
}

.border-l-white-1-4 {
  border-left: 1.4px solid #f7f8f7;
}

.border-l-green-1-4 {
  border-left: 1.4px solid #0b8376;
}

.border-l-purple-1-4 {
  border-left: 1.4px solid #755ae2;
}

.border-l-orange-1-4 {
  border-left: 1.4px solid #ea872d;
}

.border-l-black-1-4 {
  border-left: 1.4px solid #020202;
}

.border-l-light-gray-1-4 {
  border-left: 1.4px solid #fafafa;
}

.border-l-deep-green-1-4 {
  border-left: 1.4px solid #014345;
}

.border-r-primary-1-4 {
  border-right: 1.4px solid #cccccc;
}

.border-r-secondary-1-4 {
  border-right: 1.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-4 {
  border-right: 1.4px solid #ff0f00;
}

.border-r-info-1-4 {
  border-right: 1.4px solid #f6c31c;
}

.border-r-white-1-4 {
  border-right: 1.4px solid #f7f8f7;
}

.border-r-green-1-4 {
  border-right: 1.4px solid #0b8376;
}

.border-r-purple-1-4 {
  border-right: 1.4px solid #755ae2;
}

.border-r-orange-1-4 {
  border-right: 1.4px solid #ea872d;
}

.border-r-black-1-4 {
  border-right: 1.4px solid #020202;
}

.border-r-light-gray-1-4 {
  border-right: 1.4px solid #fafafa;
}

.border-r-deep-green-1-4 {
  border-right: 1.4px solid #014345;
}

.border-primary-1-5 {
  border: 1.5px solid #cccccc;
}

.border-secondary-1-5 {
  border: 1.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-5 {
  border: 1.5px solid #ff0f00;
}

.border-info-1-5 {
  border: 1.5px solid #f6c31c;
}

.border-white-1-5 {
  border: 1.5px solid #f7f8f7;
}

.border-green-1-5 {
  border: 1.5px solid #0b8376;
}

.border-purple-1-5 {
  border: 1.5px solid #755ae2;
}

.border-orange-1-5 {
  border: 1.5px solid #ea872d;
}

.border-black-1-5 {
  border: 1.5px solid #020202;
}

.border-light-gray-1-5 {
  border: 1.5px solid #fafafa;
}

.border-deep-green-1-5 {
  border: 1.5px solid #014345;
}

.border-t-primary-1-5 {
  border-top: 1.5px solid #cccccc;
}

.border-t-secondary-1-5 {
  border-top: 1.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-5 {
  border-top: 1.5px solid #ff0f00;
}

.border-t-info-1-5 {
  border-top: 1.5px solid #f6c31c;
}

.border-t-white-1-5 {
  border-top: 1.5px solid #f7f8f7;
}

.border-t-green-1-5 {
  border-top: 1.5px solid #0b8376;
}

.border-t-purple-1-5 {
  border-top: 1.5px solid #755ae2;
}

.border-t-orange-1-5 {
  border-top: 1.5px solid #ea872d;
}

.border-t-black-1-5 {
  border-top: 1.5px solid #020202;
}

.border-t-light-gray-1-5 {
  border-top: 1.5px solid #fafafa;
}

.border-t-deep-green-1-5 {
  border-top: 1.5px solid #014345;
}

.border-b-primary-1-5 {
  border-bottom: 1.5px solid #cccccc;
}

.border-b-secondary-1-5 {
  border-bottom: 1.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-5 {
  border-bottom: 1.5px solid #ff0f00;
}

.border-b-info-1-5 {
  border-bottom: 1.5px solid #f6c31c;
}

.border-b-white-1-5 {
  border-bottom: 1.5px solid #f7f8f7;
}

.border-b-green-1-5 {
  border-bottom: 1.5px solid #0b8376;
}

.border-b-purple-1-5 {
  border-bottom: 1.5px solid #755ae2;
}

.border-b-orange-1-5 {
  border-bottom: 1.5px solid #ea872d;
}

.border-b-black-1-5 {
  border-bottom: 1.5px solid #020202;
}

.border-b-light-gray-1-5 {
  border-bottom: 1.5px solid #fafafa;
}

.border-b-deep-green-1-5 {
  border-bottom: 1.5px solid #014345;
}

.border-l-primary-1-5 {
  border-left: 1.5px solid #cccccc;
}

.border-l-secondary-1-5 {
  border-left: 1.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-5 {
  border-left: 1.5px solid #ff0f00;
}

.border-l-info-1-5 {
  border-left: 1.5px solid #f6c31c;
}

.border-l-white-1-5 {
  border-left: 1.5px solid #f7f8f7;
}

.border-l-green-1-5 {
  border-left: 1.5px solid #0b8376;
}

.border-l-purple-1-5 {
  border-left: 1.5px solid #755ae2;
}

.border-l-orange-1-5 {
  border-left: 1.5px solid #ea872d;
}

.border-l-black-1-5 {
  border-left: 1.5px solid #020202;
}

.border-l-light-gray-1-5 {
  border-left: 1.5px solid #fafafa;
}

.border-l-deep-green-1-5 {
  border-left: 1.5px solid #014345;
}

.border-r-primary-1-5 {
  border-right: 1.5px solid #cccccc;
}

.border-r-secondary-1-5 {
  border-right: 1.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-5 {
  border-right: 1.5px solid #ff0f00;
}

.border-r-info-1-5 {
  border-right: 1.5px solid #f6c31c;
}

.border-r-white-1-5 {
  border-right: 1.5px solid #f7f8f7;
}

.border-r-green-1-5 {
  border-right: 1.5px solid #0b8376;
}

.border-r-purple-1-5 {
  border-right: 1.5px solid #755ae2;
}

.border-r-orange-1-5 {
  border-right: 1.5px solid #ea872d;
}

.border-r-black-1-5 {
  border-right: 1.5px solid #020202;
}

.border-r-light-gray-1-5 {
  border-right: 1.5px solid #fafafa;
}

.border-r-deep-green-1-5 {
  border-right: 1.5px solid #014345;
}

.border-primary-1-6 {
  border: 1.6px solid #cccccc;
}

.border-secondary-1-6 {
  border: 1.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-6 {
  border: 1.6px solid #ff0f00;
}

.border-info-1-6 {
  border: 1.6px solid #f6c31c;
}

.border-white-1-6 {
  border: 1.6px solid #f7f8f7;
}

.border-green-1-6 {
  border: 1.6px solid #0b8376;
}

.border-purple-1-6 {
  border: 1.6px solid #755ae2;
}

.border-orange-1-6 {
  border: 1.6px solid #ea872d;
}

.border-black-1-6 {
  border: 1.6px solid #020202;
}

.border-light-gray-1-6 {
  border: 1.6px solid #fafafa;
}

.border-deep-green-1-6 {
  border: 1.6px solid #014345;
}

.border-t-primary-1-6 {
  border-top: 1.6px solid #cccccc;
}

.border-t-secondary-1-6 {
  border-top: 1.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-6 {
  border-top: 1.6px solid #ff0f00;
}

.border-t-info-1-6 {
  border-top: 1.6px solid #f6c31c;
}

.border-t-white-1-6 {
  border-top: 1.6px solid #f7f8f7;
}

.border-t-green-1-6 {
  border-top: 1.6px solid #0b8376;
}

.border-t-purple-1-6 {
  border-top: 1.6px solid #755ae2;
}

.border-t-orange-1-6 {
  border-top: 1.6px solid #ea872d;
}

.border-t-black-1-6 {
  border-top: 1.6px solid #020202;
}

.border-t-light-gray-1-6 {
  border-top: 1.6px solid #fafafa;
}

.border-t-deep-green-1-6 {
  border-top: 1.6px solid #014345;
}

.border-b-primary-1-6 {
  border-bottom: 1.6px solid #cccccc;
}

.border-b-secondary-1-6 {
  border-bottom: 1.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-6 {
  border-bottom: 1.6px solid #ff0f00;
}

.border-b-info-1-6 {
  border-bottom: 1.6px solid #f6c31c;
}

.border-b-white-1-6 {
  border-bottom: 1.6px solid #f7f8f7;
}

.border-b-green-1-6 {
  border-bottom: 1.6px solid #0b8376;
}

.border-b-purple-1-6 {
  border-bottom: 1.6px solid #755ae2;
}

.border-b-orange-1-6 {
  border-bottom: 1.6px solid #ea872d;
}

.border-b-black-1-6 {
  border-bottom: 1.6px solid #020202;
}

.border-b-light-gray-1-6 {
  border-bottom: 1.6px solid #fafafa;
}

.border-b-deep-green-1-6 {
  border-bottom: 1.6px solid #014345;
}

.border-l-primary-1-6 {
  border-left: 1.6px solid #cccccc;
}

.border-l-secondary-1-6 {
  border-left: 1.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-6 {
  border-left: 1.6px solid #ff0f00;
}

.border-l-info-1-6 {
  border-left: 1.6px solid #f6c31c;
}

.border-l-white-1-6 {
  border-left: 1.6px solid #f7f8f7;
}

.border-l-green-1-6 {
  border-left: 1.6px solid #0b8376;
}

.border-l-purple-1-6 {
  border-left: 1.6px solid #755ae2;
}

.border-l-orange-1-6 {
  border-left: 1.6px solid #ea872d;
}

.border-l-black-1-6 {
  border-left: 1.6px solid #020202;
}

.border-l-light-gray-1-6 {
  border-left: 1.6px solid #fafafa;
}

.border-l-deep-green-1-6 {
  border-left: 1.6px solid #014345;
}

.border-r-primary-1-6 {
  border-right: 1.6px solid #cccccc;
}

.border-r-secondary-1-6 {
  border-right: 1.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-6 {
  border-right: 1.6px solid #ff0f00;
}

.border-r-info-1-6 {
  border-right: 1.6px solid #f6c31c;
}

.border-r-white-1-6 {
  border-right: 1.6px solid #f7f8f7;
}

.border-r-green-1-6 {
  border-right: 1.6px solid #0b8376;
}

.border-r-purple-1-6 {
  border-right: 1.6px solid #755ae2;
}

.border-r-orange-1-6 {
  border-right: 1.6px solid #ea872d;
}

.border-r-black-1-6 {
  border-right: 1.6px solid #020202;
}

.border-r-light-gray-1-6 {
  border-right: 1.6px solid #fafafa;
}

.border-r-deep-green-1-6 {
  border-right: 1.6px solid #014345;
}

.border-primary-1-7 {
  border: 1.7px solid #cccccc;
}

.border-secondary-1-7 {
  border: 1.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-7 {
  border: 1.7px solid #ff0f00;
}

.border-info-1-7 {
  border: 1.7px solid #f6c31c;
}

.border-white-1-7 {
  border: 1.7px solid #f7f8f7;
}

.border-green-1-7 {
  border: 1.7px solid #0b8376;
}

.border-purple-1-7 {
  border: 1.7px solid #755ae2;
}

.border-orange-1-7 {
  border: 1.7px solid #ea872d;
}

.border-black-1-7 {
  border: 1.7px solid #020202;
}

.border-light-gray-1-7 {
  border: 1.7px solid #fafafa;
}

.border-deep-green-1-7 {
  border: 1.7px solid #014345;
}

.border-t-primary-1-7 {
  border-top: 1.7px solid #cccccc;
}

.border-t-secondary-1-7 {
  border-top: 1.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-7 {
  border-top: 1.7px solid #ff0f00;
}

.border-t-info-1-7 {
  border-top: 1.7px solid #f6c31c;
}

.border-t-white-1-7 {
  border-top: 1.7px solid #f7f8f7;
}

.border-t-green-1-7 {
  border-top: 1.7px solid #0b8376;
}

.border-t-purple-1-7 {
  border-top: 1.7px solid #755ae2;
}

.border-t-orange-1-7 {
  border-top: 1.7px solid #ea872d;
}

.border-t-black-1-7 {
  border-top: 1.7px solid #020202;
}

.border-t-light-gray-1-7 {
  border-top: 1.7px solid #fafafa;
}

.border-t-deep-green-1-7 {
  border-top: 1.7px solid #014345;
}

.border-b-primary-1-7 {
  border-bottom: 1.7px solid #cccccc;
}

.border-b-secondary-1-7 {
  border-bottom: 1.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-7 {
  border-bottom: 1.7px solid #ff0f00;
}

.border-b-info-1-7 {
  border-bottom: 1.7px solid #f6c31c;
}

.border-b-white-1-7 {
  border-bottom: 1.7px solid #f7f8f7;
}

.border-b-green-1-7 {
  border-bottom: 1.7px solid #0b8376;
}

.border-b-purple-1-7 {
  border-bottom: 1.7px solid #755ae2;
}

.border-b-orange-1-7 {
  border-bottom: 1.7px solid #ea872d;
}

.border-b-black-1-7 {
  border-bottom: 1.7px solid #020202;
}

.border-b-light-gray-1-7 {
  border-bottom: 1.7px solid #fafafa;
}

.border-b-deep-green-1-7 {
  border-bottom: 1.7px solid #014345;
}

.border-l-primary-1-7 {
  border-left: 1.7px solid #cccccc;
}

.border-l-secondary-1-7 {
  border-left: 1.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-7 {
  border-left: 1.7px solid #ff0f00;
}

.border-l-info-1-7 {
  border-left: 1.7px solid #f6c31c;
}

.border-l-white-1-7 {
  border-left: 1.7px solid #f7f8f7;
}

.border-l-green-1-7 {
  border-left: 1.7px solid #0b8376;
}

.border-l-purple-1-7 {
  border-left: 1.7px solid #755ae2;
}

.border-l-orange-1-7 {
  border-left: 1.7px solid #ea872d;
}

.border-l-black-1-7 {
  border-left: 1.7px solid #020202;
}

.border-l-light-gray-1-7 {
  border-left: 1.7px solid #fafafa;
}

.border-l-deep-green-1-7 {
  border-left: 1.7px solid #014345;
}

.border-r-primary-1-7 {
  border-right: 1.7px solid #cccccc;
}

.border-r-secondary-1-7 {
  border-right: 1.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-7 {
  border-right: 1.7px solid #ff0f00;
}

.border-r-info-1-7 {
  border-right: 1.7px solid #f6c31c;
}

.border-r-white-1-7 {
  border-right: 1.7px solid #f7f8f7;
}

.border-r-green-1-7 {
  border-right: 1.7px solid #0b8376;
}

.border-r-purple-1-7 {
  border-right: 1.7px solid #755ae2;
}

.border-r-orange-1-7 {
  border-right: 1.7px solid #ea872d;
}

.border-r-black-1-7 {
  border-right: 1.7px solid #020202;
}

.border-r-light-gray-1-7 {
  border-right: 1.7px solid #fafafa;
}

.border-r-deep-green-1-7 {
  border-right: 1.7px solid #014345;
}

.border-primary-1-8 {
  border: 1.8px solid #cccccc;
}

.border-secondary-1-8 {
  border: 1.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-8 {
  border: 1.8px solid #ff0f00;
}

.border-info-1-8 {
  border: 1.8px solid #f6c31c;
}

.border-white-1-8 {
  border: 1.8px solid #f7f8f7;
}

.border-green-1-8 {
  border: 1.8px solid #0b8376;
}

.border-purple-1-8 {
  border: 1.8px solid #755ae2;
}

.border-orange-1-8 {
  border: 1.8px solid #ea872d;
}

.border-black-1-8 {
  border: 1.8px solid #020202;
}

.border-light-gray-1-8 {
  border: 1.8px solid #fafafa;
}

.border-deep-green-1-8 {
  border: 1.8px solid #014345;
}

.border-t-primary-1-8 {
  border-top: 1.8px solid #cccccc;
}

.border-t-secondary-1-8 {
  border-top: 1.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-8 {
  border-top: 1.8px solid #ff0f00;
}

.border-t-info-1-8 {
  border-top: 1.8px solid #f6c31c;
}

.border-t-white-1-8 {
  border-top: 1.8px solid #f7f8f7;
}

.border-t-green-1-8 {
  border-top: 1.8px solid #0b8376;
}

.border-t-purple-1-8 {
  border-top: 1.8px solid #755ae2;
}

.border-t-orange-1-8 {
  border-top: 1.8px solid #ea872d;
}

.border-t-black-1-8 {
  border-top: 1.8px solid #020202;
}

.border-t-light-gray-1-8 {
  border-top: 1.8px solid #fafafa;
}

.border-t-deep-green-1-8 {
  border-top: 1.8px solid #014345;
}

.border-b-primary-1-8 {
  border-bottom: 1.8px solid #cccccc;
}

.border-b-secondary-1-8 {
  border-bottom: 1.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-8 {
  border-bottom: 1.8px solid #ff0f00;
}

.border-b-info-1-8 {
  border-bottom: 1.8px solid #f6c31c;
}

.border-b-white-1-8 {
  border-bottom: 1.8px solid #f7f8f7;
}

.border-b-green-1-8 {
  border-bottom: 1.8px solid #0b8376;
}

.border-b-purple-1-8 {
  border-bottom: 1.8px solid #755ae2;
}

.border-b-orange-1-8 {
  border-bottom: 1.8px solid #ea872d;
}

.border-b-black-1-8 {
  border-bottom: 1.8px solid #020202;
}

.border-b-light-gray-1-8 {
  border-bottom: 1.8px solid #fafafa;
}

.border-b-deep-green-1-8 {
  border-bottom: 1.8px solid #014345;
}

.border-l-primary-1-8 {
  border-left: 1.8px solid #cccccc;
}

.border-l-secondary-1-8 {
  border-left: 1.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-8 {
  border-left: 1.8px solid #ff0f00;
}

.border-l-info-1-8 {
  border-left: 1.8px solid #f6c31c;
}

.border-l-white-1-8 {
  border-left: 1.8px solid #f7f8f7;
}

.border-l-green-1-8 {
  border-left: 1.8px solid #0b8376;
}

.border-l-purple-1-8 {
  border-left: 1.8px solid #755ae2;
}

.border-l-orange-1-8 {
  border-left: 1.8px solid #ea872d;
}

.border-l-black-1-8 {
  border-left: 1.8px solid #020202;
}

.border-l-light-gray-1-8 {
  border-left: 1.8px solid #fafafa;
}

.border-l-deep-green-1-8 {
  border-left: 1.8px solid #014345;
}

.border-r-primary-1-8 {
  border-right: 1.8px solid #cccccc;
}

.border-r-secondary-1-8 {
  border-right: 1.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-8 {
  border-right: 1.8px solid #ff0f00;
}

.border-r-info-1-8 {
  border-right: 1.8px solid #f6c31c;
}

.border-r-white-1-8 {
  border-right: 1.8px solid #f7f8f7;
}

.border-r-green-1-8 {
  border-right: 1.8px solid #0b8376;
}

.border-r-purple-1-8 {
  border-right: 1.8px solid #755ae2;
}

.border-r-orange-1-8 {
  border-right: 1.8px solid #ea872d;
}

.border-r-black-1-8 {
  border-right: 1.8px solid #020202;
}

.border-r-light-gray-1-8 {
  border-right: 1.8px solid #fafafa;
}

.border-r-deep-green-1-8 {
  border-right: 1.8px solid #014345;
}

.border-primary-1-9 {
  border: 1.9px solid #cccccc;
}

.border-secondary-1-9 {
  border: 1.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-1-9 {
  border: 1.9px solid #ff0f00;
}

.border-info-1-9 {
  border: 1.9px solid #f6c31c;
}

.border-white-1-9 {
  border: 1.9px solid #f7f8f7;
}

.border-green-1-9 {
  border: 1.9px solid #0b8376;
}

.border-purple-1-9 {
  border: 1.9px solid #755ae2;
}

.border-orange-1-9 {
  border: 1.9px solid #ea872d;
}

.border-black-1-9 {
  border: 1.9px solid #020202;
}

.border-light-gray-1-9 {
  border: 1.9px solid #fafafa;
}

.border-deep-green-1-9 {
  border: 1.9px solid #014345;
}

.border-t-primary-1-9 {
  border-top: 1.9px solid #cccccc;
}

.border-t-secondary-1-9 {
  border-top: 1.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-1-9 {
  border-top: 1.9px solid #ff0f00;
}

.border-t-info-1-9 {
  border-top: 1.9px solid #f6c31c;
}

.border-t-white-1-9 {
  border-top: 1.9px solid #f7f8f7;
}

.border-t-green-1-9 {
  border-top: 1.9px solid #0b8376;
}

.border-t-purple-1-9 {
  border-top: 1.9px solid #755ae2;
}

.border-t-orange-1-9 {
  border-top: 1.9px solid #ea872d;
}

.border-t-black-1-9 {
  border-top: 1.9px solid #020202;
}

.border-t-light-gray-1-9 {
  border-top: 1.9px solid #fafafa;
}

.border-t-deep-green-1-9 {
  border-top: 1.9px solid #014345;
}

.border-b-primary-1-9 {
  border-bottom: 1.9px solid #cccccc;
}

.border-b-secondary-1-9 {
  border-bottom: 1.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-1-9 {
  border-bottom: 1.9px solid #ff0f00;
}

.border-b-info-1-9 {
  border-bottom: 1.9px solid #f6c31c;
}

.border-b-white-1-9 {
  border-bottom: 1.9px solid #f7f8f7;
}

.border-b-green-1-9 {
  border-bottom: 1.9px solid #0b8376;
}

.border-b-purple-1-9 {
  border-bottom: 1.9px solid #755ae2;
}

.border-b-orange-1-9 {
  border-bottom: 1.9px solid #ea872d;
}

.border-b-black-1-9 {
  border-bottom: 1.9px solid #020202;
}

.border-b-light-gray-1-9 {
  border-bottom: 1.9px solid #fafafa;
}

.border-b-deep-green-1-9 {
  border-bottom: 1.9px solid #014345;
}

.border-l-primary-1-9 {
  border-left: 1.9px solid #cccccc;
}

.border-l-secondary-1-9 {
  border-left: 1.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-1-9 {
  border-left: 1.9px solid #ff0f00;
}

.border-l-info-1-9 {
  border-left: 1.9px solid #f6c31c;
}

.border-l-white-1-9 {
  border-left: 1.9px solid #f7f8f7;
}

.border-l-green-1-9 {
  border-left: 1.9px solid #0b8376;
}

.border-l-purple-1-9 {
  border-left: 1.9px solid #755ae2;
}

.border-l-orange-1-9 {
  border-left: 1.9px solid #ea872d;
}

.border-l-black-1-9 {
  border-left: 1.9px solid #020202;
}

.border-l-light-gray-1-9 {
  border-left: 1.9px solid #fafafa;
}

.border-l-deep-green-1-9 {
  border-left: 1.9px solid #014345;
}

.border-r-primary-1-9 {
  border-right: 1.9px solid #cccccc;
}

.border-r-secondary-1-9 {
  border-right: 1.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-1-9 {
  border-right: 1.9px solid #ff0f00;
}

.border-r-info-1-9 {
  border-right: 1.9px solid #f6c31c;
}

.border-r-white-1-9 {
  border-right: 1.9px solid #f7f8f7;
}

.border-r-green-1-9 {
  border-right: 1.9px solid #0b8376;
}

.border-r-purple-1-9 {
  border-right: 1.9px solid #755ae2;
}

.border-r-orange-1-9 {
  border-right: 1.9px solid #ea872d;
}

.border-r-black-1-9 {
  border-right: 1.9px solid #020202;
}

.border-r-light-gray-1-9 {
  border-right: 1.9px solid #fafafa;
}

.border-r-deep-green-1-9 {
  border-right: 1.9px solid #014345;
}

.border-primary-2 {
  border: 2px solid #cccccc;
}

.border-secondary-2 {
  border: 2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2 {
  border: 2px solid #ff0f00;
}

.border-info-2 {
  border: 2px solid #f6c31c;
}

.border-white-2 {
  border: 2px solid #f7f8f7;
}

.border-green-2 {
  border: 2px solid #0b8376;
}

.border-purple-2 {
  border: 2px solid #755ae2;
}

.border-orange-2 {
  border: 2px solid #ea872d;
}

.border-black-2 {
  border: 2px solid #020202;
}

.border-light-gray-2 {
  border: 2px solid #fafafa;
}

.border-deep-green-2 {
  border: 2px solid #014345;
}

.border-t-primary-2 {
  border-top: 2px solid #cccccc;
}

.border-t-secondary-2 {
  border-top: 2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2 {
  border-top: 2px solid #ff0f00;
}

.border-t-info-2 {
  border-top: 2px solid #f6c31c;
}

.border-t-white-2 {
  border-top: 2px solid #f7f8f7;
}

.border-t-green-2 {
  border-top: 2px solid #0b8376;
}

.border-t-purple-2 {
  border-top: 2px solid #755ae2;
}

.border-t-orange-2 {
  border-top: 2px solid #ea872d;
}

.border-t-black-2 {
  border-top: 2px solid #020202;
}

.border-t-light-gray-2 {
  border-top: 2px solid #fafafa;
}

.border-t-deep-green-2 {
  border-top: 2px solid #014345;
}

.border-b-primary-2 {
  border-bottom: 2px solid #cccccc;
}

.border-b-secondary-2 {
  border-bottom: 2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2 {
  border-bottom: 2px solid #ff0f00;
}

.border-b-info-2 {
  border-bottom: 2px solid #f6c31c;
}

.border-b-white-2 {
  border-bottom: 2px solid #f7f8f7;
}

.border-b-green-2 {
  border-bottom: 2px solid #0b8376;
}

.border-b-purple-2 {
  border-bottom: 2px solid #755ae2;
}

.border-b-orange-2 {
  border-bottom: 2px solid #ea872d;
}

.border-b-black-2 {
  border-bottom: 2px solid #020202;
}

.border-b-light-gray-2 {
  border-bottom: 2px solid #fafafa;
}

.border-b-deep-green-2 {
  border-bottom: 2px solid #014345;
}

.border_l-primary-2 {
  border-left: 2px solid #cccccc;
}

.border_l-secondary-2 {
  border-left: 2px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-2 {
  border-left: 2px solid #ff0f00;
}

.border_l-info-2 {
  border-left: 2px solid #f6c31c;
}

.border_l-white-2 {
  border-left: 2px solid #f7f8f7;
}

.border_l-green-2 {
  border-left: 2px solid #0b8376;
}

.border_l-purple-2 {
  border-left: 2px solid #755ae2;
}

.border_l-orange-2 {
  border-left: 2px solid #ea872d;
}

.border_l-black-2 {
  border-left: 2px solid #020202;
}

.border_l-light-gray-2 {
  border-left: 2px solid #fafafa;
}

.border_l-deep-green-2 {
  border-left: 2px solid #014345;
}

.border-r-primary-2 {
  border-right: 2px solid #cccccc;
}

.border-r-secondary-2 {
  border-right: 2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2 {
  border-right: 2px solid #ff0f00;
}

.border-r-info-2 {
  border-right: 2px solid #f6c31c;
}

.border-r-white-2 {
  border-right: 2px solid #f7f8f7;
}

.border-r-green-2 {
  border-right: 2px solid #0b8376;
}

.border-r-purple-2 {
  border-right: 2px solid #755ae2;
}

.border-r-orange-2 {
  border-right: 2px solid #ea872d;
}

.border-r-black-2 {
  border-right: 2px solid #020202;
}

.border-r-light-gray-2 {
  border-right: 2px solid #fafafa;
}

.border-r-deep-green-2 {
  border-right: 2px solid #014345;
}

.border-primary-2-1 {
  border: 2.1px solid #cccccc;
}

.border-secondary-2-1 {
  border: 2.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-1 {
  border: 2.1px solid #ff0f00;
}

.border-info-2-1 {
  border: 2.1px solid #f6c31c;
}

.border-white-2-1 {
  border: 2.1px solid #f7f8f7;
}

.border-green-2-1 {
  border: 2.1px solid #0b8376;
}

.border-purple-2-1 {
  border: 2.1px solid #755ae2;
}

.border-orange-2-1 {
  border: 2.1px solid #ea872d;
}

.border-black-2-1 {
  border: 2.1px solid #020202;
}

.border-light-gray-2-1 {
  border: 2.1px solid #fafafa;
}

.border-deep-green-2-1 {
  border: 2.1px solid #014345;
}

.border-t-primary-2-1 {
  border-top: 2.1px solid #cccccc;
}

.border-t-secondary-2-1 {
  border-top: 2.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-1 {
  border-top: 2.1px solid #ff0f00;
}

.border-t-info-2-1 {
  border-top: 2.1px solid #f6c31c;
}

.border-t-white-2-1 {
  border-top: 2.1px solid #f7f8f7;
}

.border-t-green-2-1 {
  border-top: 2.1px solid #0b8376;
}

.border-t-purple-2-1 {
  border-top: 2.1px solid #755ae2;
}

.border-t-orange-2-1 {
  border-top: 2.1px solid #ea872d;
}

.border-t-black-2-1 {
  border-top: 2.1px solid #020202;
}

.border-t-light-gray-2-1 {
  border-top: 2.1px solid #fafafa;
}

.border-t-deep-green-2-1 {
  border-top: 2.1px solid #014345;
}

.border-b-primary-2-1 {
  border-bottom: 2.1px solid #cccccc;
}

.border-b-secondary-2-1 {
  border-bottom: 2.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-1 {
  border-bottom: 2.1px solid #ff0f00;
}

.border-b-info-2-1 {
  border-bottom: 2.1px solid #f6c31c;
}

.border-b-white-2-1 {
  border-bottom: 2.1px solid #f7f8f7;
}

.border-b-green-2-1 {
  border-bottom: 2.1px solid #0b8376;
}

.border-b-purple-2-1 {
  border-bottom: 2.1px solid #755ae2;
}

.border-b-orange-2-1 {
  border-bottom: 2.1px solid #ea872d;
}

.border-b-black-2-1 {
  border-bottom: 2.1px solid #020202;
}

.border-b-light-gray-2-1 {
  border-bottom: 2.1px solid #fafafa;
}

.border-b-deep-green-2-1 {
  border-bottom: 2.1px solid #014345;
}

.border-l-primary-2-1 {
  border-left: 2.1px solid #cccccc;
}

.border-l-secondary-2-1 {
  border-left: 2.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-1 {
  border-left: 2.1px solid #ff0f00;
}

.border-l-info-2-1 {
  border-left: 2.1px solid #f6c31c;
}

.border-l-white-2-1 {
  border-left: 2.1px solid #f7f8f7;
}

.border-l-green-2-1 {
  border-left: 2.1px solid #0b8376;
}

.border-l-purple-2-1 {
  border-left: 2.1px solid #755ae2;
}

.border-l-orange-2-1 {
  border-left: 2.1px solid #ea872d;
}

.border-l-black-2-1 {
  border-left: 2.1px solid #020202;
}

.border-l-light-gray-2-1 {
  border-left: 2.1px solid #fafafa;
}

.border-l-deep-green-2-1 {
  border-left: 2.1px solid #014345;
}

.border-r-primary-2-1 {
  border-right: 2.1px solid #cccccc;
}

.border-r-secondary-2-1 {
  border-right: 2.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-1 {
  border-right: 2.1px solid #ff0f00;
}

.border-r-info-2-1 {
  border-right: 2.1px solid #f6c31c;
}

.border-r-white-2-1 {
  border-right: 2.1px solid #f7f8f7;
}

.border-r-green-2-1 {
  border-right: 2.1px solid #0b8376;
}

.border-r-purple-2-1 {
  border-right: 2.1px solid #755ae2;
}

.border-r-orange-2-1 {
  border-right: 2.1px solid #ea872d;
}

.border-r-black-2-1 {
  border-right: 2.1px solid #020202;
}

.border-r-light-gray-2-1 {
  border-right: 2.1px solid #fafafa;
}

.border-r-deep-green-2-1 {
  border-right: 2.1px solid #014345;
}

.border-primary-2-2 {
  border: 2.2px solid #cccccc;
}

.border-secondary-2-2 {
  border: 2.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-2 {
  border: 2.2px solid #ff0f00;
}

.border-info-2-2 {
  border: 2.2px solid #f6c31c;
}

.border-white-2-2 {
  border: 2.2px solid #f7f8f7;
}

.border-green-2-2 {
  border: 2.2px solid #0b8376;
}

.border-purple-2-2 {
  border: 2.2px solid #755ae2;
}

.border-orange-2-2 {
  border: 2.2px solid #ea872d;
}

.border-black-2-2 {
  border: 2.2px solid #020202;
}

.border-light-gray-2-2 {
  border: 2.2px solid #fafafa;
}

.border-deep-green-2-2 {
  border: 2.2px solid #014345;
}

.border-t-primary-2-2 {
  border-top: 2.2px solid #cccccc;
}

.border-t-secondary-2-2 {
  border-top: 2.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-2 {
  border-top: 2.2px solid #ff0f00;
}

.border-t-info-2-2 {
  border-top: 2.2px solid #f6c31c;
}

.border-t-white-2-2 {
  border-top: 2.2px solid #f7f8f7;
}

.border-t-green-2-2 {
  border-top: 2.2px solid #0b8376;
}

.border-t-purple-2-2 {
  border-top: 2.2px solid #755ae2;
}

.border-t-orange-2-2 {
  border-top: 2.2px solid #ea872d;
}

.border-t-black-2-2 {
  border-top: 2.2px solid #020202;
}

.border-t-light-gray-2-2 {
  border-top: 2.2px solid #fafafa;
}

.border-t-deep-green-2-2 {
  border-top: 2.2px solid #014345;
}

.border-b-primary-2-2 {
  border-bottom: 2.2px solid #cccccc;
}

.border-b-secondary-2-2 {
  border-bottom: 2.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-2 {
  border-bottom: 2.2px solid #ff0f00;
}

.border-b-info-2-2 {
  border-bottom: 2.2px solid #f6c31c;
}

.border-b-white-2-2 {
  border-bottom: 2.2px solid #f7f8f7;
}

.border-b-green-2-2 {
  border-bottom: 2.2px solid #0b8376;
}

.border-b-purple-2-2 {
  border-bottom: 2.2px solid #755ae2;
}

.border-b-orange-2-2 {
  border-bottom: 2.2px solid #ea872d;
}

.border-b-black-2-2 {
  border-bottom: 2.2px solid #020202;
}

.border-b-light-gray-2-2 {
  border-bottom: 2.2px solid #fafafa;
}

.border-b-deep-green-2-2 {
  border-bottom: 2.2px solid #014345;
}

.border-l-primary-2-2 {
  border-left: 2.2px solid #cccccc;
}

.border-l-secondary-2-2 {
  border-left: 2.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-2 {
  border-left: 2.2px solid #ff0f00;
}

.border-l-info-2-2 {
  border-left: 2.2px solid #f6c31c;
}

.border-l-white-2-2 {
  border-left: 2.2px solid #f7f8f7;
}

.border-l-green-2-2 {
  border-left: 2.2px solid #0b8376;
}

.border-l-purple-2-2 {
  border-left: 2.2px solid #755ae2;
}

.border-l-orange-2-2 {
  border-left: 2.2px solid #ea872d;
}

.border-l-black-2-2 {
  border-left: 2.2px solid #020202;
}

.border-l-light-gray-2-2 {
  border-left: 2.2px solid #fafafa;
}

.border-l-deep-green-2-2 {
  border-left: 2.2px solid #014345;
}

.border-r-primary-2-2 {
  border-right: 2.2px solid #cccccc;
}

.border-r-secondary-2-2 {
  border-right: 2.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-2 {
  border-right: 2.2px solid #ff0f00;
}

.border-r-info-2-2 {
  border-right: 2.2px solid #f6c31c;
}

.border-r-white-2-2 {
  border-right: 2.2px solid #f7f8f7;
}

.border-r-green-2-2 {
  border-right: 2.2px solid #0b8376;
}

.border-r-purple-2-2 {
  border-right: 2.2px solid #755ae2;
}

.border-r-orange-2-2 {
  border-right: 2.2px solid #ea872d;
}

.border-r-black-2-2 {
  border-right: 2.2px solid #020202;
}

.border-r-light-gray-2-2 {
  border-right: 2.2px solid #fafafa;
}

.border-r-deep-green-2-2 {
  border-right: 2.2px solid #014345;
}

.border-primary-2-3 {
  border: 2.3px solid #cccccc;
}

.border-secondary-2-3 {
  border: 2.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-3 {
  border: 2.3px solid #ff0f00;
}

.border-info-2-3 {
  border: 2.3px solid #f6c31c;
}

.border-white-2-3 {
  border: 2.3px solid #f7f8f7;
}

.border-green-2-3 {
  border: 2.3px solid #0b8376;
}

.border-purple-2-3 {
  border: 2.3px solid #755ae2;
}

.border-orange-2-3 {
  border: 2.3px solid #ea872d;
}

.border-black-2-3 {
  border: 2.3px solid #020202;
}

.border-light-gray-2-3 {
  border: 2.3px solid #fafafa;
}

.border-deep-green-2-3 {
  border: 2.3px solid #014345;
}

.border-t-primary-2-3 {
  border-top: 2.3px solid #cccccc;
}

.border-t-secondary-2-3 {
  border-top: 2.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-3 {
  border-top: 2.3px solid #ff0f00;
}

.border-t-info-2-3 {
  border-top: 2.3px solid #f6c31c;
}

.border-t-white-2-3 {
  border-top: 2.3px solid #f7f8f7;
}

.border-t-green-2-3 {
  border-top: 2.3px solid #0b8376;
}

.border-t-purple-2-3 {
  border-top: 2.3px solid #755ae2;
}

.border-t-orange-2-3 {
  border-top: 2.3px solid #ea872d;
}

.border-t-black-2-3 {
  border-top: 2.3px solid #020202;
}

.border-t-light-gray-2-3 {
  border-top: 2.3px solid #fafafa;
}

.border-t-deep-green-2-3 {
  border-top: 2.3px solid #014345;
}

.border-b-primary-2-3 {
  border-bottom: 2.3px solid #cccccc;
}

.border-b-secondary-2-3 {
  border-bottom: 2.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-3 {
  border-bottom: 2.3px solid #ff0f00;
}

.border-b-info-2-3 {
  border-bottom: 2.3px solid #f6c31c;
}

.border-b-white-2-3 {
  border-bottom: 2.3px solid #f7f8f7;
}

.border-b-green-2-3 {
  border-bottom: 2.3px solid #0b8376;
}

.border-b-purple-2-3 {
  border-bottom: 2.3px solid #755ae2;
}

.border-b-orange-2-3 {
  border-bottom: 2.3px solid #ea872d;
}

.border-b-black-2-3 {
  border-bottom: 2.3px solid #020202;
}

.border-b-light-gray-2-3 {
  border-bottom: 2.3px solid #fafafa;
}

.border-b-deep-green-2-3 {
  border-bottom: 2.3px solid #014345;
}

.border-l-primary-2-3 {
  border-left: 2.3px solid #cccccc;
}

.border-l-secondary-2-3 {
  border-left: 2.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-3 {
  border-left: 2.3px solid #ff0f00;
}

.border-l-info-2-3 {
  border-left: 2.3px solid #f6c31c;
}

.border-l-white-2-3 {
  border-left: 2.3px solid #f7f8f7;
}

.border-l-green-2-3 {
  border-left: 2.3px solid #0b8376;
}

.border-l-purple-2-3 {
  border-left: 2.3px solid #755ae2;
}

.border-l-orange-2-3 {
  border-left: 2.3px solid #ea872d;
}

.border-l-black-2-3 {
  border-left: 2.3px solid #020202;
}

.border-l-light-gray-2-3 {
  border-left: 2.3px solid #fafafa;
}

.border-l-deep-green-2-3 {
  border-left: 2.3px solid #014345;
}

.border-r-primary-2-3 {
  border-right: 2.3px solid #cccccc;
}

.border-r-secondary-2-3 {
  border-right: 2.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-3 {
  border-right: 2.3px solid #ff0f00;
}

.border-r-info-2-3 {
  border-right: 2.3px solid #f6c31c;
}

.border-r-white-2-3 {
  border-right: 2.3px solid #f7f8f7;
}

.border-r-green-2-3 {
  border-right: 2.3px solid #0b8376;
}

.border-r-purple-2-3 {
  border-right: 2.3px solid #755ae2;
}

.border-r-orange-2-3 {
  border-right: 2.3px solid #ea872d;
}

.border-r-black-2-3 {
  border-right: 2.3px solid #020202;
}

.border-r-light-gray-2-3 {
  border-right: 2.3px solid #fafafa;
}

.border-r-deep-green-2-3 {
  border-right: 2.3px solid #014345;
}

.border-primary-2-4 {
  border: 2.4px solid #cccccc;
}

.border-secondary-2-4 {
  border: 2.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-4 {
  border: 2.4px solid #ff0f00;
}

.border-info-2-4 {
  border: 2.4px solid #f6c31c;
}

.border-white-2-4 {
  border: 2.4px solid #f7f8f7;
}

.border-green-2-4 {
  border: 2.4px solid #0b8376;
}

.border-purple-2-4 {
  border: 2.4px solid #755ae2;
}

.border-orange-2-4 {
  border: 2.4px solid #ea872d;
}

.border-black-2-4 {
  border: 2.4px solid #020202;
}

.border-light-gray-2-4 {
  border: 2.4px solid #fafafa;
}

.border-deep-green-2-4 {
  border: 2.4px solid #014345;
}

.border-t-primary-2-4 {
  border-top: 2.4px solid #cccccc;
}

.border-t-secondary-2-4 {
  border-top: 2.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-4 {
  border-top: 2.4px solid #ff0f00;
}

.border-t-info-2-4 {
  border-top: 2.4px solid #f6c31c;
}

.border-t-white-2-4 {
  border-top: 2.4px solid #f7f8f7;
}

.border-t-green-2-4 {
  border-top: 2.4px solid #0b8376;
}

.border-t-purple-2-4 {
  border-top: 2.4px solid #755ae2;
}

.border-t-orange-2-4 {
  border-top: 2.4px solid #ea872d;
}

.border-t-black-2-4 {
  border-top: 2.4px solid #020202;
}

.border-t-light-gray-2-4 {
  border-top: 2.4px solid #fafafa;
}

.border-t-deep-green-2-4 {
  border-top: 2.4px solid #014345;
}

.border-b-primary-2-4 {
  border-bottom: 2.4px solid #cccccc;
}

.border-b-secondary-2-4 {
  border-bottom: 2.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-4 {
  border-bottom: 2.4px solid #ff0f00;
}

.border-b-info-2-4 {
  border-bottom: 2.4px solid #f6c31c;
}

.border-b-white-2-4 {
  border-bottom: 2.4px solid #f7f8f7;
}

.border-b-green-2-4 {
  border-bottom: 2.4px solid #0b8376;
}

.border-b-purple-2-4 {
  border-bottom: 2.4px solid #755ae2;
}

.border-b-orange-2-4 {
  border-bottom: 2.4px solid #ea872d;
}

.border-b-black-2-4 {
  border-bottom: 2.4px solid #020202;
}

.border-b-light-gray-2-4 {
  border-bottom: 2.4px solid #fafafa;
}

.border-b-deep-green-2-4 {
  border-bottom: 2.4px solid #014345;
}

.border-l-primary-2-4 {
  border-left: 2.4px solid #cccccc;
}

.border-l-secondary-2-4 {
  border-left: 2.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-4 {
  border-left: 2.4px solid #ff0f00;
}

.border-l-info-2-4 {
  border-left: 2.4px solid #f6c31c;
}

.border-l-white-2-4 {
  border-left: 2.4px solid #f7f8f7;
}

.border-l-green-2-4 {
  border-left: 2.4px solid #0b8376;
}

.border-l-purple-2-4 {
  border-left: 2.4px solid #755ae2;
}

.border-l-orange-2-4 {
  border-left: 2.4px solid #ea872d;
}

.border-l-black-2-4 {
  border-left: 2.4px solid #020202;
}

.border-l-light-gray-2-4 {
  border-left: 2.4px solid #fafafa;
}

.border-l-deep-green-2-4 {
  border-left: 2.4px solid #014345;
}

.border-r-primary-2-4 {
  border-right: 2.4px solid #cccccc;
}

.border-r-secondary-2-4 {
  border-right: 2.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-4 {
  border-right: 2.4px solid #ff0f00;
}

.border-r-info-2-4 {
  border-right: 2.4px solid #f6c31c;
}

.border-r-white-2-4 {
  border-right: 2.4px solid #f7f8f7;
}

.border-r-green-2-4 {
  border-right: 2.4px solid #0b8376;
}

.border-r-purple-2-4 {
  border-right: 2.4px solid #755ae2;
}

.border-r-orange-2-4 {
  border-right: 2.4px solid #ea872d;
}

.border-r-black-2-4 {
  border-right: 2.4px solid #020202;
}

.border-r-light-gray-2-4 {
  border-right: 2.4px solid #fafafa;
}

.border-r-deep-green-2-4 {
  border-right: 2.4px solid #014345;
}

.border-primary-2-5 {
  border: 2.5px solid #cccccc;
}

.border-secondary-2-5 {
  border: 2.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-5 {
  border: 2.5px solid #ff0f00;
}

.border-info-2-5 {
  border: 2.5px solid #f6c31c;
}

.border-white-2-5 {
  border: 2.5px solid #f7f8f7;
}

.border-green-2-5 {
  border: 2.5px solid #0b8376;
}

.border-purple-2-5 {
  border: 2.5px solid #755ae2;
}

.border-orange-2-5 {
  border: 2.5px solid #ea872d;
}

.border-black-2-5 {
  border: 2.5px solid #020202;
}

.border-light-gray-2-5 {
  border: 2.5px solid #fafafa;
}

.border-deep-green-2-5 {
  border: 2.5px solid #014345;
}

.border-t-primary-2-5 {
  border-top: 2.5px solid #cccccc;
}

.border-t-secondary-2-5 {
  border-top: 2.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-5 {
  border-top: 2.5px solid #ff0f00;
}

.border-t-info-2-5 {
  border-top: 2.5px solid #f6c31c;
}

.border-t-white-2-5 {
  border-top: 2.5px solid #f7f8f7;
}

.border-t-green-2-5 {
  border-top: 2.5px solid #0b8376;
}

.border-t-purple-2-5 {
  border-top: 2.5px solid #755ae2;
}

.border-t-orange-2-5 {
  border-top: 2.5px solid #ea872d;
}

.border-t-black-2-5 {
  border-top: 2.5px solid #020202;
}

.border-t-light-gray-2-5 {
  border-top: 2.5px solid #fafafa;
}

.border-t-deep-green-2-5 {
  border-top: 2.5px solid #014345;
}

.border-b-primary-2-5 {
  border-bottom: 2.5px solid #cccccc;
}

.border-b-secondary-2-5 {
  border-bottom: 2.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-5 {
  border-bottom: 2.5px solid #ff0f00;
}

.border-b-info-2-5 {
  border-bottom: 2.5px solid #f6c31c;
}

.border-b-white-2-5 {
  border-bottom: 2.5px solid #f7f8f7;
}

.border-b-green-2-5 {
  border-bottom: 2.5px solid #0b8376;
}

.border-b-purple-2-5 {
  border-bottom: 2.5px solid #755ae2;
}

.border-b-orange-2-5 {
  border-bottom: 2.5px solid #ea872d;
}

.border-b-black-2-5 {
  border-bottom: 2.5px solid #020202;
}

.border-b-light-gray-2-5 {
  border-bottom: 2.5px solid #fafafa;
}

.border-b-deep-green-2-5 {
  border-bottom: 2.5px solid #014345;
}

.border-l-primary-2-5 {
  border-left: 2.5px solid #cccccc;
}

.border-l-secondary-2-5 {
  border-left: 2.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-5 {
  border-left: 2.5px solid #ff0f00;
}

.border-l-info-2-5 {
  border-left: 2.5px solid #f6c31c;
}

.border-l-white-2-5 {
  border-left: 2.5px solid #f7f8f7;
}

.border-l-green-2-5 {
  border-left: 2.5px solid #0b8376;
}

.border-l-purple-2-5 {
  border-left: 2.5px solid #755ae2;
}

.border-l-orange-2-5 {
  border-left: 2.5px solid #ea872d;
}

.border-l-black-2-5 {
  border-left: 2.5px solid #020202;
}

.border-l-light-gray-2-5 {
  border-left: 2.5px solid #fafafa;
}

.border-l-deep-green-2-5 {
  border-left: 2.5px solid #014345;
}

.border-r-primary-2-5 {
  border-right: 2.5px solid #cccccc;
}

.border-r-secondary-2-5 {
  border-right: 2.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-5 {
  border-right: 2.5px solid #ff0f00;
}

.border-r-info-2-5 {
  border-right: 2.5px solid #f6c31c;
}

.border-r-white-2-5 {
  border-right: 2.5px solid #f7f8f7;
}

.border-r-green-2-5 {
  border-right: 2.5px solid #0b8376;
}

.border-r-purple-2-5 {
  border-right: 2.5px solid #755ae2;
}

.border-r-orange-2-5 {
  border-right: 2.5px solid #ea872d;
}

.border-r-black-2-5 {
  border-right: 2.5px solid #020202;
}

.border-r-light-gray-2-5 {
  border-right: 2.5px solid #fafafa;
}

.border-r-deep-green-2-5 {
  border-right: 2.5px solid #014345;
}

.border-primary-2-6 {
  border: 2.6px solid #cccccc;
}

.border-secondary-2-6 {
  border: 2.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-6 {
  border: 2.6px solid #ff0f00;
}

.border-info-2-6 {
  border: 2.6px solid #f6c31c;
}

.border-white-2-6 {
  border: 2.6px solid #f7f8f7;
}

.border-green-2-6 {
  border: 2.6px solid #0b8376;
}

.border-purple-2-6 {
  border: 2.6px solid #755ae2;
}

.border-orange-2-6 {
  border: 2.6px solid #ea872d;
}

.border-black-2-6 {
  border: 2.6px solid #020202;
}

.border-light-gray-2-6 {
  border: 2.6px solid #fafafa;
}

.border-deep-green-2-6 {
  border: 2.6px solid #014345;
}

.border-t-primary-2-6 {
  border-top: 2.6px solid #cccccc;
}

.border-t-secondary-2-6 {
  border-top: 2.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-6 {
  border-top: 2.6px solid #ff0f00;
}

.border-t-info-2-6 {
  border-top: 2.6px solid #f6c31c;
}

.border-t-white-2-6 {
  border-top: 2.6px solid #f7f8f7;
}

.border-t-green-2-6 {
  border-top: 2.6px solid #0b8376;
}

.border-t-purple-2-6 {
  border-top: 2.6px solid #755ae2;
}

.border-t-orange-2-6 {
  border-top: 2.6px solid #ea872d;
}

.border-t-black-2-6 {
  border-top: 2.6px solid #020202;
}

.border-t-light-gray-2-6 {
  border-top: 2.6px solid #fafafa;
}

.border-t-deep-green-2-6 {
  border-top: 2.6px solid #014345;
}

.border-b-primary-2-6 {
  border-bottom: 2.6px solid #cccccc;
}

.border-b-secondary-2-6 {
  border-bottom: 2.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-6 {
  border-bottom: 2.6px solid #ff0f00;
}

.border-b-info-2-6 {
  border-bottom: 2.6px solid #f6c31c;
}

.border-b-white-2-6 {
  border-bottom: 2.6px solid #f7f8f7;
}

.border-b-green-2-6 {
  border-bottom: 2.6px solid #0b8376;
}

.border-b-purple-2-6 {
  border-bottom: 2.6px solid #755ae2;
}

.border-b-orange-2-6 {
  border-bottom: 2.6px solid #ea872d;
}

.border-b-black-2-6 {
  border-bottom: 2.6px solid #020202;
}

.border-b-light-gray-2-6 {
  border-bottom: 2.6px solid #fafafa;
}

.border-b-deep-green-2-6 {
  border-bottom: 2.6px solid #014345;
}

.border-l-primary-2-6 {
  border-left: 2.6px solid #cccccc;
}

.border-l-secondary-2-6 {
  border-left: 2.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-6 {
  border-left: 2.6px solid #ff0f00;
}

.border-l-info-2-6 {
  border-left: 2.6px solid #f6c31c;
}

.border-l-white-2-6 {
  border-left: 2.6px solid #f7f8f7;
}

.border-l-green-2-6 {
  border-left: 2.6px solid #0b8376;
}

.border-l-purple-2-6 {
  border-left: 2.6px solid #755ae2;
}

.border-l-orange-2-6 {
  border-left: 2.6px solid #ea872d;
}

.border-l-black-2-6 {
  border-left: 2.6px solid #020202;
}

.border-l-light-gray-2-6 {
  border-left: 2.6px solid #fafafa;
}

.border-l-deep-green-2-6 {
  border-left: 2.6px solid #014345;
}

.border-r-primary-2-6 {
  border-right: 2.6px solid #cccccc;
}

.border-r-secondary-2-6 {
  border-right: 2.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-6 {
  border-right: 2.6px solid #ff0f00;
}

.border-r-info-2-6 {
  border-right: 2.6px solid #f6c31c;
}

.border-r-white-2-6 {
  border-right: 2.6px solid #f7f8f7;
}

.border-r-green-2-6 {
  border-right: 2.6px solid #0b8376;
}

.border-r-purple-2-6 {
  border-right: 2.6px solid #755ae2;
}

.border-r-orange-2-6 {
  border-right: 2.6px solid #ea872d;
}

.border-r-black-2-6 {
  border-right: 2.6px solid #020202;
}

.border-r-light-gray-2-6 {
  border-right: 2.6px solid #fafafa;
}

.border-r-deep-green-2-6 {
  border-right: 2.6px solid #014345;
}

.border-primary-2-7 {
  border: 2.7px solid #cccccc;
}

.border-secondary-2-7 {
  border: 2.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-7 {
  border: 2.7px solid #ff0f00;
}

.border-info-2-7 {
  border: 2.7px solid #f6c31c;
}

.border-white-2-7 {
  border: 2.7px solid #f7f8f7;
}

.border-green-2-7 {
  border: 2.7px solid #0b8376;
}

.border-purple-2-7 {
  border: 2.7px solid #755ae2;
}

.border-orange-2-7 {
  border: 2.7px solid #ea872d;
}

.border-black-2-7 {
  border: 2.7px solid #020202;
}

.border-light-gray-2-7 {
  border: 2.7px solid #fafafa;
}

.border-deep-green-2-7 {
  border: 2.7px solid #014345;
}

.border-t-primary-2-7 {
  border-top: 2.7px solid #cccccc;
}

.border-t-secondary-2-7 {
  border-top: 2.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-7 {
  border-top: 2.7px solid #ff0f00;
}

.border-t-info-2-7 {
  border-top: 2.7px solid #f6c31c;
}

.border-t-white-2-7 {
  border-top: 2.7px solid #f7f8f7;
}

.border-t-green-2-7 {
  border-top: 2.7px solid #0b8376;
}

.border-t-purple-2-7 {
  border-top: 2.7px solid #755ae2;
}

.border-t-orange-2-7 {
  border-top: 2.7px solid #ea872d;
}

.border-t-black-2-7 {
  border-top: 2.7px solid #020202;
}

.border-t-light-gray-2-7 {
  border-top: 2.7px solid #fafafa;
}

.border-t-deep-green-2-7 {
  border-top: 2.7px solid #014345;
}

.border-b-primary-2-7 {
  border-bottom: 2.7px solid #cccccc;
}

.border-b-secondary-2-7 {
  border-bottom: 2.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-7 {
  border-bottom: 2.7px solid #ff0f00;
}

.border-b-info-2-7 {
  border-bottom: 2.7px solid #f6c31c;
}

.border-b-white-2-7 {
  border-bottom: 2.7px solid #f7f8f7;
}

.border-b-green-2-7 {
  border-bottom: 2.7px solid #0b8376;
}

.border-b-purple-2-7 {
  border-bottom: 2.7px solid #755ae2;
}

.border-b-orange-2-7 {
  border-bottom: 2.7px solid #ea872d;
}

.border-b-black-2-7 {
  border-bottom: 2.7px solid #020202;
}

.border-b-light-gray-2-7 {
  border-bottom: 2.7px solid #fafafa;
}

.border-b-deep-green-2-7 {
  border-bottom: 2.7px solid #014345;
}

.border-l-primary-2-7 {
  border-left: 2.7px solid #cccccc;
}

.border-l-secondary-2-7 {
  border-left: 2.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-7 {
  border-left: 2.7px solid #ff0f00;
}

.border-l-info-2-7 {
  border-left: 2.7px solid #f6c31c;
}

.border-l-white-2-7 {
  border-left: 2.7px solid #f7f8f7;
}

.border-l-green-2-7 {
  border-left: 2.7px solid #0b8376;
}

.border-l-purple-2-7 {
  border-left: 2.7px solid #755ae2;
}

.border-l-orange-2-7 {
  border-left: 2.7px solid #ea872d;
}

.border-l-black-2-7 {
  border-left: 2.7px solid #020202;
}

.border-l-light-gray-2-7 {
  border-left: 2.7px solid #fafafa;
}

.border-l-deep-green-2-7 {
  border-left: 2.7px solid #014345;
}

.border-r-primary-2-7 {
  border-right: 2.7px solid #cccccc;
}

.border-r-secondary-2-7 {
  border-right: 2.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-7 {
  border-right: 2.7px solid #ff0f00;
}

.border-r-info-2-7 {
  border-right: 2.7px solid #f6c31c;
}

.border-r-white-2-7 {
  border-right: 2.7px solid #f7f8f7;
}

.border-r-green-2-7 {
  border-right: 2.7px solid #0b8376;
}

.border-r-purple-2-7 {
  border-right: 2.7px solid #755ae2;
}

.border-r-orange-2-7 {
  border-right: 2.7px solid #ea872d;
}

.border-r-black-2-7 {
  border-right: 2.7px solid #020202;
}

.border-r-light-gray-2-7 {
  border-right: 2.7px solid #fafafa;
}

.border-r-deep-green-2-7 {
  border-right: 2.7px solid #014345;
}

.border-primary-2-8 {
  border: 2.8px solid #cccccc;
}

.border-secondary-2-8 {
  border: 2.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-8 {
  border: 2.8px solid #ff0f00;
}

.border-info-2-8 {
  border: 2.8px solid #f6c31c;
}

.border-white-2-8 {
  border: 2.8px solid #f7f8f7;
}

.border-green-2-8 {
  border: 2.8px solid #0b8376;
}

.border-purple-2-8 {
  border: 2.8px solid #755ae2;
}

.border-orange-2-8 {
  border: 2.8px solid #ea872d;
}

.border-black-2-8 {
  border: 2.8px solid #020202;
}

.border-light-gray-2-8 {
  border: 2.8px solid #fafafa;
}

.border-deep-green-2-8 {
  border: 2.8px solid #014345;
}

.border-t-primary-2-8 {
  border-top: 2.8px solid #cccccc;
}

.border-t-secondary-2-8 {
  border-top: 2.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-8 {
  border-top: 2.8px solid #ff0f00;
}

.border-t-info-2-8 {
  border-top: 2.8px solid #f6c31c;
}

.border-t-white-2-8 {
  border-top: 2.8px solid #f7f8f7;
}

.border-t-green-2-8 {
  border-top: 2.8px solid #0b8376;
}

.border-t-purple-2-8 {
  border-top: 2.8px solid #755ae2;
}

.border-t-orange-2-8 {
  border-top: 2.8px solid #ea872d;
}

.border-t-black-2-8 {
  border-top: 2.8px solid #020202;
}

.border-t-light-gray-2-8 {
  border-top: 2.8px solid #fafafa;
}

.border-t-deep-green-2-8 {
  border-top: 2.8px solid #014345;
}

.border-b-primary-2-8 {
  border-bottom: 2.8px solid #cccccc;
}

.border-b-secondary-2-8 {
  border-bottom: 2.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-8 {
  border-bottom: 2.8px solid #ff0f00;
}

.border-b-info-2-8 {
  border-bottom: 2.8px solid #f6c31c;
}

.border-b-white-2-8 {
  border-bottom: 2.8px solid #f7f8f7;
}

.border-b-green-2-8 {
  border-bottom: 2.8px solid #0b8376;
}

.border-b-purple-2-8 {
  border-bottom: 2.8px solid #755ae2;
}

.border-b-orange-2-8 {
  border-bottom: 2.8px solid #ea872d;
}

.border-b-black-2-8 {
  border-bottom: 2.8px solid #020202;
}

.border-b-light-gray-2-8 {
  border-bottom: 2.8px solid #fafafa;
}

.border-b-deep-green-2-8 {
  border-bottom: 2.8px solid #014345;
}

.border-l-primary-2-8 {
  border-left: 2.8px solid #cccccc;
}

.border-l-secondary-2-8 {
  border-left: 2.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-8 {
  border-left: 2.8px solid #ff0f00;
}

.border-l-info-2-8 {
  border-left: 2.8px solid #f6c31c;
}

.border-l-white-2-8 {
  border-left: 2.8px solid #f7f8f7;
}

.border-l-green-2-8 {
  border-left: 2.8px solid #0b8376;
}

.border-l-purple-2-8 {
  border-left: 2.8px solid #755ae2;
}

.border-l-orange-2-8 {
  border-left: 2.8px solid #ea872d;
}

.border-l-black-2-8 {
  border-left: 2.8px solid #020202;
}

.border-l-light-gray-2-8 {
  border-left: 2.8px solid #fafafa;
}

.border-l-deep-green-2-8 {
  border-left: 2.8px solid #014345;
}

.border-r-primary-2-8 {
  border-right: 2.8px solid #cccccc;
}

.border-r-secondary-2-8 {
  border-right: 2.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-8 {
  border-right: 2.8px solid #ff0f00;
}

.border-r-info-2-8 {
  border-right: 2.8px solid #f6c31c;
}

.border-r-white-2-8 {
  border-right: 2.8px solid #f7f8f7;
}

.border-r-green-2-8 {
  border-right: 2.8px solid #0b8376;
}

.border-r-purple-2-8 {
  border-right: 2.8px solid #755ae2;
}

.border-r-orange-2-8 {
  border-right: 2.8px solid #ea872d;
}

.border-r-black-2-8 {
  border-right: 2.8px solid #020202;
}

.border-r-light-gray-2-8 {
  border-right: 2.8px solid #fafafa;
}

.border-r-deep-green-2-8 {
  border-right: 2.8px solid #014345;
}

.border-primary-2-9 {
  border: 2.9px solid #cccccc;
}

.border-secondary-2-9 {
  border: 2.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-2-9 {
  border: 2.9px solid #ff0f00;
}

.border-info-2-9 {
  border: 2.9px solid #f6c31c;
}

.border-white-2-9 {
  border: 2.9px solid #f7f8f7;
}

.border-green-2-9 {
  border: 2.9px solid #0b8376;
}

.border-purple-2-9 {
  border: 2.9px solid #755ae2;
}

.border-orange-2-9 {
  border: 2.9px solid #ea872d;
}

.border-black-2-9 {
  border: 2.9px solid #020202;
}

.border-light-gray-2-9 {
  border: 2.9px solid #fafafa;
}

.border-deep-green-2-9 {
  border: 2.9px solid #014345;
}

.border-t-primary-2-9 {
  border-top: 2.9px solid #cccccc;
}

.border-t-secondary-2-9 {
  border-top: 2.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-2-9 {
  border-top: 2.9px solid #ff0f00;
}

.border-t-info-2-9 {
  border-top: 2.9px solid #f6c31c;
}

.border-t-white-2-9 {
  border-top: 2.9px solid #f7f8f7;
}

.border-t-green-2-9 {
  border-top: 2.9px solid #0b8376;
}

.border-t-purple-2-9 {
  border-top: 2.9px solid #755ae2;
}

.border-t-orange-2-9 {
  border-top: 2.9px solid #ea872d;
}

.border-t-black-2-9 {
  border-top: 2.9px solid #020202;
}

.border-t-light-gray-2-9 {
  border-top: 2.9px solid #fafafa;
}

.border-t-deep-green-2-9 {
  border-top: 2.9px solid #014345;
}

.border-b-primary-2-9 {
  border-bottom: 2.9px solid #cccccc;
}

.border-b-secondary-2-9 {
  border-bottom: 2.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-2-9 {
  border-bottom: 2.9px solid #ff0f00;
}

.border-b-info-2-9 {
  border-bottom: 2.9px solid #f6c31c;
}

.border-b-white-2-9 {
  border-bottom: 2.9px solid #f7f8f7;
}

.border-b-green-2-9 {
  border-bottom: 2.9px solid #0b8376;
}

.border-b-purple-2-9 {
  border-bottom: 2.9px solid #755ae2;
}

.border-b-orange-2-9 {
  border-bottom: 2.9px solid #ea872d;
}

.border-b-black-2-9 {
  border-bottom: 2.9px solid #020202;
}

.border-b-light-gray-2-9 {
  border-bottom: 2.9px solid #fafafa;
}

.border-b-deep-green-2-9 {
  border-bottom: 2.9px solid #014345;
}

.border-l-primary-2-9 {
  border-left: 2.9px solid #cccccc;
}

.border-l-secondary-2-9 {
  border-left: 2.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-2-9 {
  border-left: 2.9px solid #ff0f00;
}

.border-l-info-2-9 {
  border-left: 2.9px solid #f6c31c;
}

.border-l-white-2-9 {
  border-left: 2.9px solid #f7f8f7;
}

.border-l-green-2-9 {
  border-left: 2.9px solid #0b8376;
}

.border-l-purple-2-9 {
  border-left: 2.9px solid #755ae2;
}

.border-l-orange-2-9 {
  border-left: 2.9px solid #ea872d;
}

.border-l-black-2-9 {
  border-left: 2.9px solid #020202;
}

.border-l-light-gray-2-9 {
  border-left: 2.9px solid #fafafa;
}

.border-l-deep-green-2-9 {
  border-left: 2.9px solid #014345;
}

.border-r-primary-2-9 {
  border-right: 2.9px solid #cccccc;
}

.border-r-secondary-2-9 {
  border-right: 2.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-2-9 {
  border-right: 2.9px solid #ff0f00;
}

.border-r-info-2-9 {
  border-right: 2.9px solid #f6c31c;
}

.border-r-white-2-9 {
  border-right: 2.9px solid #f7f8f7;
}

.border-r-green-2-9 {
  border-right: 2.9px solid #0b8376;
}

.border-r-purple-2-9 {
  border-right: 2.9px solid #755ae2;
}

.border-r-orange-2-9 {
  border-right: 2.9px solid #ea872d;
}

.border-r-black-2-9 {
  border-right: 2.9px solid #020202;
}

.border-r-light-gray-2-9 {
  border-right: 2.9px solid #fafafa;
}

.border-r-deep-green-2-9 {
  border-right: 2.9px solid #014345;
}

.border-primary-3 {
  border: 3px solid #cccccc;
}

.border-secondary-3 {
  border: 3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3 {
  border: 3px solid #ff0f00;
}

.border-info-3 {
  border: 3px solid #f6c31c;
}

.border-white-3 {
  border: 3px solid #f7f8f7;
}

.border-green-3 {
  border: 3px solid #0b8376;
}

.border-purple-3 {
  border: 3px solid #755ae2;
}

.border-orange-3 {
  border: 3px solid #ea872d;
}

.border-black-3 {
  border: 3px solid #020202;
}

.border-light-gray-3 {
  border: 3px solid #fafafa;
}

.border-deep-green-3 {
  border: 3px solid #014345;
}

.border-t-primary-3 {
  border-top: 3px solid #cccccc;
}

.border-t-secondary-3 {
  border-top: 3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3 {
  border-top: 3px solid #ff0f00;
}

.border-t-info-3 {
  border-top: 3px solid #f6c31c;
}

.border-t-white-3 {
  border-top: 3px solid #f7f8f7;
}

.border-t-green-3 {
  border-top: 3px solid #0b8376;
}

.border-t-purple-3 {
  border-top: 3px solid #755ae2;
}

.border-t-orange-3 {
  border-top: 3px solid #ea872d;
}

.border-t-black-3 {
  border-top: 3px solid #020202;
}

.border-t-light-gray-3 {
  border-top: 3px solid #fafafa;
}

.border-t-deep-green-3 {
  border-top: 3px solid #014345;
}

.border-b-primary-3 {
  border-bottom: 3px solid #cccccc;
}

.border-b-secondary-3 {
  border-bottom: 3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3 {
  border-bottom: 3px solid #ff0f00;
}

.border-b-info-3 {
  border-bottom: 3px solid #f6c31c;
}

.border-b-white-3 {
  border-bottom: 3px solid #f7f8f7;
}

.border-b-green-3 {
  border-bottom: 3px solid #0b8376;
}

.border-b-purple-3 {
  border-bottom: 3px solid #755ae2;
}

.border-b-orange-3 {
  border-bottom: 3px solid #ea872d;
}

.border-b-black-3 {
  border-bottom: 3px solid #020202;
}

.border-b-light-gray-3 {
  border-bottom: 3px solid #fafafa;
}

.border-b-deep-green-3 {
  border-bottom: 3px solid #014345;
}

.border_l-primary-3 {
  border-left: 3px solid #cccccc;
}

.border_l-secondary-3 {
  border-left: 3px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-3 {
  border-left: 3px solid #ff0f00;
}

.border_l-info-3 {
  border-left: 3px solid #f6c31c;
}

.border_l-white-3 {
  border-left: 3px solid #f7f8f7;
}

.border_l-green-3 {
  border-left: 3px solid #0b8376;
}

.border_l-purple-3 {
  border-left: 3px solid #755ae2;
}

.border_l-orange-3 {
  border-left: 3px solid #ea872d;
}

.border_l-black-3 {
  border-left: 3px solid #020202;
}

.border_l-light-gray-3 {
  border-left: 3px solid #fafafa;
}

.border_l-deep-green-3 {
  border-left: 3px solid #014345;
}

.border-r-primary-3 {
  border-right: 3px solid #cccccc;
}

.border-r-secondary-3 {
  border-right: 3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3 {
  border-right: 3px solid #ff0f00;
}

.border-r-info-3 {
  border-right: 3px solid #f6c31c;
}

.border-r-white-3 {
  border-right: 3px solid #f7f8f7;
}

.border-r-green-3 {
  border-right: 3px solid #0b8376;
}

.border-r-purple-3 {
  border-right: 3px solid #755ae2;
}

.border-r-orange-3 {
  border-right: 3px solid #ea872d;
}

.border-r-black-3 {
  border-right: 3px solid #020202;
}

.border-r-light-gray-3 {
  border-right: 3px solid #fafafa;
}

.border-r-deep-green-3 {
  border-right: 3px solid #014345;
}

.border-primary-3-1 {
  border: 3.1px solid #cccccc;
}

.border-secondary-3-1 {
  border: 3.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-1 {
  border: 3.1px solid #ff0f00;
}

.border-info-3-1 {
  border: 3.1px solid #f6c31c;
}

.border-white-3-1 {
  border: 3.1px solid #f7f8f7;
}

.border-green-3-1 {
  border: 3.1px solid #0b8376;
}

.border-purple-3-1 {
  border: 3.1px solid #755ae2;
}

.border-orange-3-1 {
  border: 3.1px solid #ea872d;
}

.border-black-3-1 {
  border: 3.1px solid #020202;
}

.border-light-gray-3-1 {
  border: 3.1px solid #fafafa;
}

.border-deep-green-3-1 {
  border: 3.1px solid #014345;
}

.border-t-primary-3-1 {
  border-top: 3.1px solid #cccccc;
}

.border-t-secondary-3-1 {
  border-top: 3.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-1 {
  border-top: 3.1px solid #ff0f00;
}

.border-t-info-3-1 {
  border-top: 3.1px solid #f6c31c;
}

.border-t-white-3-1 {
  border-top: 3.1px solid #f7f8f7;
}

.border-t-green-3-1 {
  border-top: 3.1px solid #0b8376;
}

.border-t-purple-3-1 {
  border-top: 3.1px solid #755ae2;
}

.border-t-orange-3-1 {
  border-top: 3.1px solid #ea872d;
}

.border-t-black-3-1 {
  border-top: 3.1px solid #020202;
}

.border-t-light-gray-3-1 {
  border-top: 3.1px solid #fafafa;
}

.border-t-deep-green-3-1 {
  border-top: 3.1px solid #014345;
}

.border-b-primary-3-1 {
  border-bottom: 3.1px solid #cccccc;
}

.border-b-secondary-3-1 {
  border-bottom: 3.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-1 {
  border-bottom: 3.1px solid #ff0f00;
}

.border-b-info-3-1 {
  border-bottom: 3.1px solid #f6c31c;
}

.border-b-white-3-1 {
  border-bottom: 3.1px solid #f7f8f7;
}

.border-b-green-3-1 {
  border-bottom: 3.1px solid #0b8376;
}

.border-b-purple-3-1 {
  border-bottom: 3.1px solid #755ae2;
}

.border-b-orange-3-1 {
  border-bottom: 3.1px solid #ea872d;
}

.border-b-black-3-1 {
  border-bottom: 3.1px solid #020202;
}

.border-b-light-gray-3-1 {
  border-bottom: 3.1px solid #fafafa;
}

.border-b-deep-green-3-1 {
  border-bottom: 3.1px solid #014345;
}

.border-l-primary-3-1 {
  border-left: 3.1px solid #cccccc;
}

.border-l-secondary-3-1 {
  border-left: 3.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-1 {
  border-left: 3.1px solid #ff0f00;
}

.border-l-info-3-1 {
  border-left: 3.1px solid #f6c31c;
}

.border-l-white-3-1 {
  border-left: 3.1px solid #f7f8f7;
}

.border-l-green-3-1 {
  border-left: 3.1px solid #0b8376;
}

.border-l-purple-3-1 {
  border-left: 3.1px solid #755ae2;
}

.border-l-orange-3-1 {
  border-left: 3.1px solid #ea872d;
}

.border-l-black-3-1 {
  border-left: 3.1px solid #020202;
}

.border-l-light-gray-3-1 {
  border-left: 3.1px solid #fafafa;
}

.border-l-deep-green-3-1 {
  border-left: 3.1px solid #014345;
}

.border-r-primary-3-1 {
  border-right: 3.1px solid #cccccc;
}

.border-r-secondary-3-1 {
  border-right: 3.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-1 {
  border-right: 3.1px solid #ff0f00;
}

.border-r-info-3-1 {
  border-right: 3.1px solid #f6c31c;
}

.border-r-white-3-1 {
  border-right: 3.1px solid #f7f8f7;
}

.border-r-green-3-1 {
  border-right: 3.1px solid #0b8376;
}

.border-r-purple-3-1 {
  border-right: 3.1px solid #755ae2;
}

.border-r-orange-3-1 {
  border-right: 3.1px solid #ea872d;
}

.border-r-black-3-1 {
  border-right: 3.1px solid #020202;
}

.border-r-light-gray-3-1 {
  border-right: 3.1px solid #fafafa;
}

.border-r-deep-green-3-1 {
  border-right: 3.1px solid #014345;
}

.border-primary-3-2 {
  border: 3.2px solid #cccccc;
}

.border-secondary-3-2 {
  border: 3.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-2 {
  border: 3.2px solid #ff0f00;
}

.border-info-3-2 {
  border: 3.2px solid #f6c31c;
}

.border-white-3-2 {
  border: 3.2px solid #f7f8f7;
}

.border-green-3-2 {
  border: 3.2px solid #0b8376;
}

.border-purple-3-2 {
  border: 3.2px solid #755ae2;
}

.border-orange-3-2 {
  border: 3.2px solid #ea872d;
}

.border-black-3-2 {
  border: 3.2px solid #020202;
}

.border-light-gray-3-2 {
  border: 3.2px solid #fafafa;
}

.border-deep-green-3-2 {
  border: 3.2px solid #014345;
}

.border-t-primary-3-2 {
  border-top: 3.2px solid #cccccc;
}

.border-t-secondary-3-2 {
  border-top: 3.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-2 {
  border-top: 3.2px solid #ff0f00;
}

.border-t-info-3-2 {
  border-top: 3.2px solid #f6c31c;
}

.border-t-white-3-2 {
  border-top: 3.2px solid #f7f8f7;
}

.border-t-green-3-2 {
  border-top: 3.2px solid #0b8376;
}

.border-t-purple-3-2 {
  border-top: 3.2px solid #755ae2;
}

.border-t-orange-3-2 {
  border-top: 3.2px solid #ea872d;
}

.border-t-black-3-2 {
  border-top: 3.2px solid #020202;
}

.border-t-light-gray-3-2 {
  border-top: 3.2px solid #fafafa;
}

.border-t-deep-green-3-2 {
  border-top: 3.2px solid #014345;
}

.border-b-primary-3-2 {
  border-bottom: 3.2px solid #cccccc;
}

.border-b-secondary-3-2 {
  border-bottom: 3.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-2 {
  border-bottom: 3.2px solid #ff0f00;
}

.border-b-info-3-2 {
  border-bottom: 3.2px solid #f6c31c;
}

.border-b-white-3-2 {
  border-bottom: 3.2px solid #f7f8f7;
}

.border-b-green-3-2 {
  border-bottom: 3.2px solid #0b8376;
}

.border-b-purple-3-2 {
  border-bottom: 3.2px solid #755ae2;
}

.border-b-orange-3-2 {
  border-bottom: 3.2px solid #ea872d;
}

.border-b-black-3-2 {
  border-bottom: 3.2px solid #020202;
}

.border-b-light-gray-3-2 {
  border-bottom: 3.2px solid #fafafa;
}

.border-b-deep-green-3-2 {
  border-bottom: 3.2px solid #014345;
}

.border-l-primary-3-2 {
  border-left: 3.2px solid #cccccc;
}

.border-l-secondary-3-2 {
  border-left: 3.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-2 {
  border-left: 3.2px solid #ff0f00;
}

.border-l-info-3-2 {
  border-left: 3.2px solid #f6c31c;
}

.border-l-white-3-2 {
  border-left: 3.2px solid #f7f8f7;
}

.border-l-green-3-2 {
  border-left: 3.2px solid #0b8376;
}

.border-l-purple-3-2 {
  border-left: 3.2px solid #755ae2;
}

.border-l-orange-3-2 {
  border-left: 3.2px solid #ea872d;
}

.border-l-black-3-2 {
  border-left: 3.2px solid #020202;
}

.border-l-light-gray-3-2 {
  border-left: 3.2px solid #fafafa;
}

.border-l-deep-green-3-2 {
  border-left: 3.2px solid #014345;
}

.border-r-primary-3-2 {
  border-right: 3.2px solid #cccccc;
}

.border-r-secondary-3-2 {
  border-right: 3.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-2 {
  border-right: 3.2px solid #ff0f00;
}

.border-r-info-3-2 {
  border-right: 3.2px solid #f6c31c;
}

.border-r-white-3-2 {
  border-right: 3.2px solid #f7f8f7;
}

.border-r-green-3-2 {
  border-right: 3.2px solid #0b8376;
}

.border-r-purple-3-2 {
  border-right: 3.2px solid #755ae2;
}

.border-r-orange-3-2 {
  border-right: 3.2px solid #ea872d;
}

.border-r-black-3-2 {
  border-right: 3.2px solid #020202;
}

.border-r-light-gray-3-2 {
  border-right: 3.2px solid #fafafa;
}

.border-r-deep-green-3-2 {
  border-right: 3.2px solid #014345;
}

.border-primary-3-3 {
  border: 3.3px solid #cccccc;
}

.border-secondary-3-3 {
  border: 3.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-3 {
  border: 3.3px solid #ff0f00;
}

.border-info-3-3 {
  border: 3.3px solid #f6c31c;
}

.border-white-3-3 {
  border: 3.3px solid #f7f8f7;
}

.border-green-3-3 {
  border: 3.3px solid #0b8376;
}

.border-purple-3-3 {
  border: 3.3px solid #755ae2;
}

.border-orange-3-3 {
  border: 3.3px solid #ea872d;
}

.border-black-3-3 {
  border: 3.3px solid #020202;
}

.border-light-gray-3-3 {
  border: 3.3px solid #fafafa;
}

.border-deep-green-3-3 {
  border: 3.3px solid #014345;
}

.border-t-primary-3-3 {
  border-top: 3.3px solid #cccccc;
}

.border-t-secondary-3-3 {
  border-top: 3.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-3 {
  border-top: 3.3px solid #ff0f00;
}

.border-t-info-3-3 {
  border-top: 3.3px solid #f6c31c;
}

.border-t-white-3-3 {
  border-top: 3.3px solid #f7f8f7;
}

.border-t-green-3-3 {
  border-top: 3.3px solid #0b8376;
}

.border-t-purple-3-3 {
  border-top: 3.3px solid #755ae2;
}

.border-t-orange-3-3 {
  border-top: 3.3px solid #ea872d;
}

.border-t-black-3-3 {
  border-top: 3.3px solid #020202;
}

.border-t-light-gray-3-3 {
  border-top: 3.3px solid #fafafa;
}

.border-t-deep-green-3-3 {
  border-top: 3.3px solid #014345;
}

.border-b-primary-3-3 {
  border-bottom: 3.3px solid #cccccc;
}

.border-b-secondary-3-3 {
  border-bottom: 3.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-3 {
  border-bottom: 3.3px solid #ff0f00;
}

.border-b-info-3-3 {
  border-bottom: 3.3px solid #f6c31c;
}

.border-b-white-3-3 {
  border-bottom: 3.3px solid #f7f8f7;
}

.border-b-green-3-3 {
  border-bottom: 3.3px solid #0b8376;
}

.border-b-purple-3-3 {
  border-bottom: 3.3px solid #755ae2;
}

.border-b-orange-3-3 {
  border-bottom: 3.3px solid #ea872d;
}

.border-b-black-3-3 {
  border-bottom: 3.3px solid #020202;
}

.border-b-light-gray-3-3 {
  border-bottom: 3.3px solid #fafafa;
}

.border-b-deep-green-3-3 {
  border-bottom: 3.3px solid #014345;
}

.border-l-primary-3-3 {
  border-left: 3.3px solid #cccccc;
}

.border-l-secondary-3-3 {
  border-left: 3.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-3 {
  border-left: 3.3px solid #ff0f00;
}

.border-l-info-3-3 {
  border-left: 3.3px solid #f6c31c;
}

.border-l-white-3-3 {
  border-left: 3.3px solid #f7f8f7;
}

.border-l-green-3-3 {
  border-left: 3.3px solid #0b8376;
}

.border-l-purple-3-3 {
  border-left: 3.3px solid #755ae2;
}

.border-l-orange-3-3 {
  border-left: 3.3px solid #ea872d;
}

.border-l-black-3-3 {
  border-left: 3.3px solid #020202;
}

.border-l-light-gray-3-3 {
  border-left: 3.3px solid #fafafa;
}

.border-l-deep-green-3-3 {
  border-left: 3.3px solid #014345;
}

.border-r-primary-3-3 {
  border-right: 3.3px solid #cccccc;
}

.border-r-secondary-3-3 {
  border-right: 3.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-3 {
  border-right: 3.3px solid #ff0f00;
}

.border-r-info-3-3 {
  border-right: 3.3px solid #f6c31c;
}

.border-r-white-3-3 {
  border-right: 3.3px solid #f7f8f7;
}

.border-r-green-3-3 {
  border-right: 3.3px solid #0b8376;
}

.border-r-purple-3-3 {
  border-right: 3.3px solid #755ae2;
}

.border-r-orange-3-3 {
  border-right: 3.3px solid #ea872d;
}

.border-r-black-3-3 {
  border-right: 3.3px solid #020202;
}

.border-r-light-gray-3-3 {
  border-right: 3.3px solid #fafafa;
}

.border-r-deep-green-3-3 {
  border-right: 3.3px solid #014345;
}

.border-primary-3-4 {
  border: 3.4px solid #cccccc;
}

.border-secondary-3-4 {
  border: 3.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-4 {
  border: 3.4px solid #ff0f00;
}

.border-info-3-4 {
  border: 3.4px solid #f6c31c;
}

.border-white-3-4 {
  border: 3.4px solid #f7f8f7;
}

.border-green-3-4 {
  border: 3.4px solid #0b8376;
}

.border-purple-3-4 {
  border: 3.4px solid #755ae2;
}

.border-orange-3-4 {
  border: 3.4px solid #ea872d;
}

.border-black-3-4 {
  border: 3.4px solid #020202;
}

.border-light-gray-3-4 {
  border: 3.4px solid #fafafa;
}

.border-deep-green-3-4 {
  border: 3.4px solid #014345;
}

.border-t-primary-3-4 {
  border-top: 3.4px solid #cccccc;
}

.border-t-secondary-3-4 {
  border-top: 3.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-4 {
  border-top: 3.4px solid #ff0f00;
}

.border-t-info-3-4 {
  border-top: 3.4px solid #f6c31c;
}

.border-t-white-3-4 {
  border-top: 3.4px solid #f7f8f7;
}

.border-t-green-3-4 {
  border-top: 3.4px solid #0b8376;
}

.border-t-purple-3-4 {
  border-top: 3.4px solid #755ae2;
}

.border-t-orange-3-4 {
  border-top: 3.4px solid #ea872d;
}

.border-t-black-3-4 {
  border-top: 3.4px solid #020202;
}

.border-t-light-gray-3-4 {
  border-top: 3.4px solid #fafafa;
}

.border-t-deep-green-3-4 {
  border-top: 3.4px solid #014345;
}

.border-b-primary-3-4 {
  border-bottom: 3.4px solid #cccccc;
}

.border-b-secondary-3-4 {
  border-bottom: 3.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-4 {
  border-bottom: 3.4px solid #ff0f00;
}

.border-b-info-3-4 {
  border-bottom: 3.4px solid #f6c31c;
}

.border-b-white-3-4 {
  border-bottom: 3.4px solid #f7f8f7;
}

.border-b-green-3-4 {
  border-bottom: 3.4px solid #0b8376;
}

.border-b-purple-3-4 {
  border-bottom: 3.4px solid #755ae2;
}

.border-b-orange-3-4 {
  border-bottom: 3.4px solid #ea872d;
}

.border-b-black-3-4 {
  border-bottom: 3.4px solid #020202;
}

.border-b-light-gray-3-4 {
  border-bottom: 3.4px solid #fafafa;
}

.border-b-deep-green-3-4 {
  border-bottom: 3.4px solid #014345;
}

.border-l-primary-3-4 {
  border-left: 3.4px solid #cccccc;
}

.border-l-secondary-3-4 {
  border-left: 3.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-4 {
  border-left: 3.4px solid #ff0f00;
}

.border-l-info-3-4 {
  border-left: 3.4px solid #f6c31c;
}

.border-l-white-3-4 {
  border-left: 3.4px solid #f7f8f7;
}

.border-l-green-3-4 {
  border-left: 3.4px solid #0b8376;
}

.border-l-purple-3-4 {
  border-left: 3.4px solid #755ae2;
}

.border-l-orange-3-4 {
  border-left: 3.4px solid #ea872d;
}

.border-l-black-3-4 {
  border-left: 3.4px solid #020202;
}

.border-l-light-gray-3-4 {
  border-left: 3.4px solid #fafafa;
}

.border-l-deep-green-3-4 {
  border-left: 3.4px solid #014345;
}

.border-r-primary-3-4 {
  border-right: 3.4px solid #cccccc;
}

.border-r-secondary-3-4 {
  border-right: 3.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-4 {
  border-right: 3.4px solid #ff0f00;
}

.border-r-info-3-4 {
  border-right: 3.4px solid #f6c31c;
}

.border-r-white-3-4 {
  border-right: 3.4px solid #f7f8f7;
}

.border-r-green-3-4 {
  border-right: 3.4px solid #0b8376;
}

.border-r-purple-3-4 {
  border-right: 3.4px solid #755ae2;
}

.border-r-orange-3-4 {
  border-right: 3.4px solid #ea872d;
}

.border-r-black-3-4 {
  border-right: 3.4px solid #020202;
}

.border-r-light-gray-3-4 {
  border-right: 3.4px solid #fafafa;
}

.border-r-deep-green-3-4 {
  border-right: 3.4px solid #014345;
}

.border-primary-3-5 {
  border: 3.5px solid #cccccc;
}

.border-secondary-3-5 {
  border: 3.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-5 {
  border: 3.5px solid #ff0f00;
}

.border-info-3-5 {
  border: 3.5px solid #f6c31c;
}

.border-white-3-5 {
  border: 3.5px solid #f7f8f7;
}

.border-green-3-5 {
  border: 3.5px solid #0b8376;
}

.border-purple-3-5 {
  border: 3.5px solid #755ae2;
}

.border-orange-3-5 {
  border: 3.5px solid #ea872d;
}

.border-black-3-5 {
  border: 3.5px solid #020202;
}

.border-light-gray-3-5 {
  border: 3.5px solid #fafafa;
}

.border-deep-green-3-5 {
  border: 3.5px solid #014345;
}

.border-t-primary-3-5 {
  border-top: 3.5px solid #cccccc;
}

.border-t-secondary-3-5 {
  border-top: 3.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-5 {
  border-top: 3.5px solid #ff0f00;
}

.border-t-info-3-5 {
  border-top: 3.5px solid #f6c31c;
}

.border-t-white-3-5 {
  border-top: 3.5px solid #f7f8f7;
}

.border-t-green-3-5 {
  border-top: 3.5px solid #0b8376;
}

.border-t-purple-3-5 {
  border-top: 3.5px solid #755ae2;
}

.border-t-orange-3-5 {
  border-top: 3.5px solid #ea872d;
}

.border-t-black-3-5 {
  border-top: 3.5px solid #020202;
}

.border-t-light-gray-3-5 {
  border-top: 3.5px solid #fafafa;
}

.border-t-deep-green-3-5 {
  border-top: 3.5px solid #014345;
}

.border-b-primary-3-5 {
  border-bottom: 3.5px solid #cccccc;
}

.border-b-secondary-3-5 {
  border-bottom: 3.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-5 {
  border-bottom: 3.5px solid #ff0f00;
}

.border-b-info-3-5 {
  border-bottom: 3.5px solid #f6c31c;
}

.border-b-white-3-5 {
  border-bottom: 3.5px solid #f7f8f7;
}

.border-b-green-3-5 {
  border-bottom: 3.5px solid #0b8376;
}

.border-b-purple-3-5 {
  border-bottom: 3.5px solid #755ae2;
}

.border-b-orange-3-5 {
  border-bottom: 3.5px solid #ea872d;
}

.border-b-black-3-5 {
  border-bottom: 3.5px solid #020202;
}

.border-b-light-gray-3-5 {
  border-bottom: 3.5px solid #fafafa;
}

.border-b-deep-green-3-5 {
  border-bottom: 3.5px solid #014345;
}

.border-l-primary-3-5 {
  border-left: 3.5px solid #cccccc;
}

.border-l-secondary-3-5 {
  border-left: 3.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-5 {
  border-left: 3.5px solid #ff0f00;
}

.border-l-info-3-5 {
  border-left: 3.5px solid #f6c31c;
}

.border-l-white-3-5 {
  border-left: 3.5px solid #f7f8f7;
}

.border-l-green-3-5 {
  border-left: 3.5px solid #0b8376;
}

.border-l-purple-3-5 {
  border-left: 3.5px solid #755ae2;
}

.border-l-orange-3-5 {
  border-left: 3.5px solid #ea872d;
}

.border-l-black-3-5 {
  border-left: 3.5px solid #020202;
}

.border-l-light-gray-3-5 {
  border-left: 3.5px solid #fafafa;
}

.border-l-deep-green-3-5 {
  border-left: 3.5px solid #014345;
}

.border-r-primary-3-5 {
  border-right: 3.5px solid #cccccc;
}

.border-r-secondary-3-5 {
  border-right: 3.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-5 {
  border-right: 3.5px solid #ff0f00;
}

.border-r-info-3-5 {
  border-right: 3.5px solid #f6c31c;
}

.border-r-white-3-5 {
  border-right: 3.5px solid #f7f8f7;
}

.border-r-green-3-5 {
  border-right: 3.5px solid #0b8376;
}

.border-r-purple-3-5 {
  border-right: 3.5px solid #755ae2;
}

.border-r-orange-3-5 {
  border-right: 3.5px solid #ea872d;
}

.border-r-black-3-5 {
  border-right: 3.5px solid #020202;
}

.border-r-light-gray-3-5 {
  border-right: 3.5px solid #fafafa;
}

.border-r-deep-green-3-5 {
  border-right: 3.5px solid #014345;
}

.border-primary-3-6 {
  border: 3.6px solid #cccccc;
}

.border-secondary-3-6 {
  border: 3.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-6 {
  border: 3.6px solid #ff0f00;
}

.border-info-3-6 {
  border: 3.6px solid #f6c31c;
}

.border-white-3-6 {
  border: 3.6px solid #f7f8f7;
}

.border-green-3-6 {
  border: 3.6px solid #0b8376;
}

.border-purple-3-6 {
  border: 3.6px solid #755ae2;
}

.border-orange-3-6 {
  border: 3.6px solid #ea872d;
}

.border-black-3-6 {
  border: 3.6px solid #020202;
}

.border-light-gray-3-6 {
  border: 3.6px solid #fafafa;
}

.border-deep-green-3-6 {
  border: 3.6px solid #014345;
}

.border-t-primary-3-6 {
  border-top: 3.6px solid #cccccc;
}

.border-t-secondary-3-6 {
  border-top: 3.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-6 {
  border-top: 3.6px solid #ff0f00;
}

.border-t-info-3-6 {
  border-top: 3.6px solid #f6c31c;
}

.border-t-white-3-6 {
  border-top: 3.6px solid #f7f8f7;
}

.border-t-green-3-6 {
  border-top: 3.6px solid #0b8376;
}

.border-t-purple-3-6 {
  border-top: 3.6px solid #755ae2;
}

.border-t-orange-3-6 {
  border-top: 3.6px solid #ea872d;
}

.border-t-black-3-6 {
  border-top: 3.6px solid #020202;
}

.border-t-light-gray-3-6 {
  border-top: 3.6px solid #fafafa;
}

.border-t-deep-green-3-6 {
  border-top: 3.6px solid #014345;
}

.border-b-primary-3-6 {
  border-bottom: 3.6px solid #cccccc;
}

.border-b-secondary-3-6 {
  border-bottom: 3.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-6 {
  border-bottom: 3.6px solid #ff0f00;
}

.border-b-info-3-6 {
  border-bottom: 3.6px solid #f6c31c;
}

.border-b-white-3-6 {
  border-bottom: 3.6px solid #f7f8f7;
}

.border-b-green-3-6 {
  border-bottom: 3.6px solid #0b8376;
}

.border-b-purple-3-6 {
  border-bottom: 3.6px solid #755ae2;
}

.border-b-orange-3-6 {
  border-bottom: 3.6px solid #ea872d;
}

.border-b-black-3-6 {
  border-bottom: 3.6px solid #020202;
}

.border-b-light-gray-3-6 {
  border-bottom: 3.6px solid #fafafa;
}

.border-b-deep-green-3-6 {
  border-bottom: 3.6px solid #014345;
}

.border-l-primary-3-6 {
  border-left: 3.6px solid #cccccc;
}

.border-l-secondary-3-6 {
  border-left: 3.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-6 {
  border-left: 3.6px solid #ff0f00;
}

.border-l-info-3-6 {
  border-left: 3.6px solid #f6c31c;
}

.border-l-white-3-6 {
  border-left: 3.6px solid #f7f8f7;
}

.border-l-green-3-6 {
  border-left: 3.6px solid #0b8376;
}

.border-l-purple-3-6 {
  border-left: 3.6px solid #755ae2;
}

.border-l-orange-3-6 {
  border-left: 3.6px solid #ea872d;
}

.border-l-black-3-6 {
  border-left: 3.6px solid #020202;
}

.border-l-light-gray-3-6 {
  border-left: 3.6px solid #fafafa;
}

.border-l-deep-green-3-6 {
  border-left: 3.6px solid #014345;
}

.border-r-primary-3-6 {
  border-right: 3.6px solid #cccccc;
}

.border-r-secondary-3-6 {
  border-right: 3.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-6 {
  border-right: 3.6px solid #ff0f00;
}

.border-r-info-3-6 {
  border-right: 3.6px solid #f6c31c;
}

.border-r-white-3-6 {
  border-right: 3.6px solid #f7f8f7;
}

.border-r-green-3-6 {
  border-right: 3.6px solid #0b8376;
}

.border-r-purple-3-6 {
  border-right: 3.6px solid #755ae2;
}

.border-r-orange-3-6 {
  border-right: 3.6px solid #ea872d;
}

.border-r-black-3-6 {
  border-right: 3.6px solid #020202;
}

.border-r-light-gray-3-6 {
  border-right: 3.6px solid #fafafa;
}

.border-r-deep-green-3-6 {
  border-right: 3.6px solid #014345;
}

.border-primary-3-7 {
  border: 3.7px solid #cccccc;
}

.border-secondary-3-7 {
  border: 3.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-7 {
  border: 3.7px solid #ff0f00;
}

.border-info-3-7 {
  border: 3.7px solid #f6c31c;
}

.border-white-3-7 {
  border: 3.7px solid #f7f8f7;
}

.border-green-3-7 {
  border: 3.7px solid #0b8376;
}

.border-purple-3-7 {
  border: 3.7px solid #755ae2;
}

.border-orange-3-7 {
  border: 3.7px solid #ea872d;
}

.border-black-3-7 {
  border: 3.7px solid #020202;
}

.border-light-gray-3-7 {
  border: 3.7px solid #fafafa;
}

.border-deep-green-3-7 {
  border: 3.7px solid #014345;
}

.border-t-primary-3-7 {
  border-top: 3.7px solid #cccccc;
}

.border-t-secondary-3-7 {
  border-top: 3.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-7 {
  border-top: 3.7px solid #ff0f00;
}

.border-t-info-3-7 {
  border-top: 3.7px solid #f6c31c;
}

.border-t-white-3-7 {
  border-top: 3.7px solid #f7f8f7;
}

.border-t-green-3-7 {
  border-top: 3.7px solid #0b8376;
}

.border-t-purple-3-7 {
  border-top: 3.7px solid #755ae2;
}

.border-t-orange-3-7 {
  border-top: 3.7px solid #ea872d;
}

.border-t-black-3-7 {
  border-top: 3.7px solid #020202;
}

.border-t-light-gray-3-7 {
  border-top: 3.7px solid #fafafa;
}

.border-t-deep-green-3-7 {
  border-top: 3.7px solid #014345;
}

.border-b-primary-3-7 {
  border-bottom: 3.7px solid #cccccc;
}

.border-b-secondary-3-7 {
  border-bottom: 3.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-7 {
  border-bottom: 3.7px solid #ff0f00;
}

.border-b-info-3-7 {
  border-bottom: 3.7px solid #f6c31c;
}

.border-b-white-3-7 {
  border-bottom: 3.7px solid #f7f8f7;
}

.border-b-green-3-7 {
  border-bottom: 3.7px solid #0b8376;
}

.border-b-purple-3-7 {
  border-bottom: 3.7px solid #755ae2;
}

.border-b-orange-3-7 {
  border-bottom: 3.7px solid #ea872d;
}

.border-b-black-3-7 {
  border-bottom: 3.7px solid #020202;
}

.border-b-light-gray-3-7 {
  border-bottom: 3.7px solid #fafafa;
}

.border-b-deep-green-3-7 {
  border-bottom: 3.7px solid #014345;
}

.border-l-primary-3-7 {
  border-left: 3.7px solid #cccccc;
}

.border-l-secondary-3-7 {
  border-left: 3.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-7 {
  border-left: 3.7px solid #ff0f00;
}

.border-l-info-3-7 {
  border-left: 3.7px solid #f6c31c;
}

.border-l-white-3-7 {
  border-left: 3.7px solid #f7f8f7;
}

.border-l-green-3-7 {
  border-left: 3.7px solid #0b8376;
}

.border-l-purple-3-7 {
  border-left: 3.7px solid #755ae2;
}

.border-l-orange-3-7 {
  border-left: 3.7px solid #ea872d;
}

.border-l-black-3-7 {
  border-left: 3.7px solid #020202;
}

.border-l-light-gray-3-7 {
  border-left: 3.7px solid #fafafa;
}

.border-l-deep-green-3-7 {
  border-left: 3.7px solid #014345;
}

.border-r-primary-3-7 {
  border-right: 3.7px solid #cccccc;
}

.border-r-secondary-3-7 {
  border-right: 3.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-7 {
  border-right: 3.7px solid #ff0f00;
}

.border-r-info-3-7 {
  border-right: 3.7px solid #f6c31c;
}

.border-r-white-3-7 {
  border-right: 3.7px solid #f7f8f7;
}

.border-r-green-3-7 {
  border-right: 3.7px solid #0b8376;
}

.border-r-purple-3-7 {
  border-right: 3.7px solid #755ae2;
}

.border-r-orange-3-7 {
  border-right: 3.7px solid #ea872d;
}

.border-r-black-3-7 {
  border-right: 3.7px solid #020202;
}

.border-r-light-gray-3-7 {
  border-right: 3.7px solid #fafafa;
}

.border-r-deep-green-3-7 {
  border-right: 3.7px solid #014345;
}

.border-primary-3-8 {
  border: 3.8px solid #cccccc;
}

.border-secondary-3-8 {
  border: 3.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-8 {
  border: 3.8px solid #ff0f00;
}

.border-info-3-8 {
  border: 3.8px solid #f6c31c;
}

.border-white-3-8 {
  border: 3.8px solid #f7f8f7;
}

.border-green-3-8 {
  border: 3.8px solid #0b8376;
}

.border-purple-3-8 {
  border: 3.8px solid #755ae2;
}

.border-orange-3-8 {
  border: 3.8px solid #ea872d;
}

.border-black-3-8 {
  border: 3.8px solid #020202;
}

.border-light-gray-3-8 {
  border: 3.8px solid #fafafa;
}

.border-deep-green-3-8 {
  border: 3.8px solid #014345;
}

.border-t-primary-3-8 {
  border-top: 3.8px solid #cccccc;
}

.border-t-secondary-3-8 {
  border-top: 3.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-8 {
  border-top: 3.8px solid #ff0f00;
}

.border-t-info-3-8 {
  border-top: 3.8px solid #f6c31c;
}

.border-t-white-3-8 {
  border-top: 3.8px solid #f7f8f7;
}

.border-t-green-3-8 {
  border-top: 3.8px solid #0b8376;
}

.border-t-purple-3-8 {
  border-top: 3.8px solid #755ae2;
}

.border-t-orange-3-8 {
  border-top: 3.8px solid #ea872d;
}

.border-t-black-3-8 {
  border-top: 3.8px solid #020202;
}

.border-t-light-gray-3-8 {
  border-top: 3.8px solid #fafafa;
}

.border-t-deep-green-3-8 {
  border-top: 3.8px solid #014345;
}

.border-b-primary-3-8 {
  border-bottom: 3.8px solid #cccccc;
}

.border-b-secondary-3-8 {
  border-bottom: 3.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-8 {
  border-bottom: 3.8px solid #ff0f00;
}

.border-b-info-3-8 {
  border-bottom: 3.8px solid #f6c31c;
}

.border-b-white-3-8 {
  border-bottom: 3.8px solid #f7f8f7;
}

.border-b-green-3-8 {
  border-bottom: 3.8px solid #0b8376;
}

.border-b-purple-3-8 {
  border-bottom: 3.8px solid #755ae2;
}

.border-b-orange-3-8 {
  border-bottom: 3.8px solid #ea872d;
}

.border-b-black-3-8 {
  border-bottom: 3.8px solid #020202;
}

.border-b-light-gray-3-8 {
  border-bottom: 3.8px solid #fafafa;
}

.border-b-deep-green-3-8 {
  border-bottom: 3.8px solid #014345;
}

.border-l-primary-3-8 {
  border-left: 3.8px solid #cccccc;
}

.border-l-secondary-3-8 {
  border-left: 3.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-8 {
  border-left: 3.8px solid #ff0f00;
}

.border-l-info-3-8 {
  border-left: 3.8px solid #f6c31c;
}

.border-l-white-3-8 {
  border-left: 3.8px solid #f7f8f7;
}

.border-l-green-3-8 {
  border-left: 3.8px solid #0b8376;
}

.border-l-purple-3-8 {
  border-left: 3.8px solid #755ae2;
}

.border-l-orange-3-8 {
  border-left: 3.8px solid #ea872d;
}

.border-l-black-3-8 {
  border-left: 3.8px solid #020202;
}

.border-l-light-gray-3-8 {
  border-left: 3.8px solid #fafafa;
}

.border-l-deep-green-3-8 {
  border-left: 3.8px solid #014345;
}

.border-r-primary-3-8 {
  border-right: 3.8px solid #cccccc;
}

.border-r-secondary-3-8 {
  border-right: 3.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-8 {
  border-right: 3.8px solid #ff0f00;
}

.border-r-info-3-8 {
  border-right: 3.8px solid #f6c31c;
}

.border-r-white-3-8 {
  border-right: 3.8px solid #f7f8f7;
}

.border-r-green-3-8 {
  border-right: 3.8px solid #0b8376;
}

.border-r-purple-3-8 {
  border-right: 3.8px solid #755ae2;
}

.border-r-orange-3-8 {
  border-right: 3.8px solid #ea872d;
}

.border-r-black-3-8 {
  border-right: 3.8px solid #020202;
}

.border-r-light-gray-3-8 {
  border-right: 3.8px solid #fafafa;
}

.border-r-deep-green-3-8 {
  border-right: 3.8px solid #014345;
}

.border-primary-3-9 {
  border: 3.9px solid #cccccc;
}

.border-secondary-3-9 {
  border: 3.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-3-9 {
  border: 3.9px solid #ff0f00;
}

.border-info-3-9 {
  border: 3.9px solid #f6c31c;
}

.border-white-3-9 {
  border: 3.9px solid #f7f8f7;
}

.border-green-3-9 {
  border: 3.9px solid #0b8376;
}

.border-purple-3-9 {
  border: 3.9px solid #755ae2;
}

.border-orange-3-9 {
  border: 3.9px solid #ea872d;
}

.border-black-3-9 {
  border: 3.9px solid #020202;
}

.border-light-gray-3-9 {
  border: 3.9px solid #fafafa;
}

.border-deep-green-3-9 {
  border: 3.9px solid #014345;
}

.border-t-primary-3-9 {
  border-top: 3.9px solid #cccccc;
}

.border-t-secondary-3-9 {
  border-top: 3.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-3-9 {
  border-top: 3.9px solid #ff0f00;
}

.border-t-info-3-9 {
  border-top: 3.9px solid #f6c31c;
}

.border-t-white-3-9 {
  border-top: 3.9px solid #f7f8f7;
}

.border-t-green-3-9 {
  border-top: 3.9px solid #0b8376;
}

.border-t-purple-3-9 {
  border-top: 3.9px solid #755ae2;
}

.border-t-orange-3-9 {
  border-top: 3.9px solid #ea872d;
}

.border-t-black-3-9 {
  border-top: 3.9px solid #020202;
}

.border-t-light-gray-3-9 {
  border-top: 3.9px solid #fafafa;
}

.border-t-deep-green-3-9 {
  border-top: 3.9px solid #014345;
}

.border-b-primary-3-9 {
  border-bottom: 3.9px solid #cccccc;
}

.border-b-secondary-3-9 {
  border-bottom: 3.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-3-9 {
  border-bottom: 3.9px solid #ff0f00;
}

.border-b-info-3-9 {
  border-bottom: 3.9px solid #f6c31c;
}

.border-b-white-3-9 {
  border-bottom: 3.9px solid #f7f8f7;
}

.border-b-green-3-9 {
  border-bottom: 3.9px solid #0b8376;
}

.border-b-purple-3-9 {
  border-bottom: 3.9px solid #755ae2;
}

.border-b-orange-3-9 {
  border-bottom: 3.9px solid #ea872d;
}

.border-b-black-3-9 {
  border-bottom: 3.9px solid #020202;
}

.border-b-light-gray-3-9 {
  border-bottom: 3.9px solid #fafafa;
}

.border-b-deep-green-3-9 {
  border-bottom: 3.9px solid #014345;
}

.border-l-primary-3-9 {
  border-left: 3.9px solid #cccccc;
}

.border-l-secondary-3-9 {
  border-left: 3.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-3-9 {
  border-left: 3.9px solid #ff0f00;
}

.border-l-info-3-9 {
  border-left: 3.9px solid #f6c31c;
}

.border-l-white-3-9 {
  border-left: 3.9px solid #f7f8f7;
}

.border-l-green-3-9 {
  border-left: 3.9px solid #0b8376;
}

.border-l-purple-3-9 {
  border-left: 3.9px solid #755ae2;
}

.border-l-orange-3-9 {
  border-left: 3.9px solid #ea872d;
}

.border-l-black-3-9 {
  border-left: 3.9px solid #020202;
}

.border-l-light-gray-3-9 {
  border-left: 3.9px solid #fafafa;
}

.border-l-deep-green-3-9 {
  border-left: 3.9px solid #014345;
}

.border-r-primary-3-9 {
  border-right: 3.9px solid #cccccc;
}

.border-r-secondary-3-9 {
  border-right: 3.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-3-9 {
  border-right: 3.9px solid #ff0f00;
}

.border-r-info-3-9 {
  border-right: 3.9px solid #f6c31c;
}

.border-r-white-3-9 {
  border-right: 3.9px solid #f7f8f7;
}

.border-r-green-3-9 {
  border-right: 3.9px solid #0b8376;
}

.border-r-purple-3-9 {
  border-right: 3.9px solid #755ae2;
}

.border-r-orange-3-9 {
  border-right: 3.9px solid #ea872d;
}

.border-r-black-3-9 {
  border-right: 3.9px solid #020202;
}

.border-r-light-gray-3-9 {
  border-right: 3.9px solid #fafafa;
}

.border-r-deep-green-3-9 {
  border-right: 3.9px solid #014345;
}

.border-primary-4 {
  border: 4px solid #cccccc;
}

.border-secondary-4 {
  border: 4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4 {
  border: 4px solid #ff0f00;
}

.border-info-4 {
  border: 4px solid #f6c31c;
}

.border-white-4 {
  border: 4px solid #f7f8f7;
}

.border-green-4 {
  border: 4px solid #0b8376;
}

.border-purple-4 {
  border: 4px solid #755ae2;
}

.border-orange-4 {
  border: 4px solid #ea872d;
}

.border-black-4 {
  border: 4px solid #020202;
}

.border-light-gray-4 {
  border: 4px solid #fafafa;
}

.border-deep-green-4 {
  border: 4px solid #014345;
}

.border-t-primary-4 {
  border-top: 4px solid #cccccc;
}

.border-t-secondary-4 {
  border-top: 4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4 {
  border-top: 4px solid #ff0f00;
}

.border-t-info-4 {
  border-top: 4px solid #f6c31c;
}

.border-t-white-4 {
  border-top: 4px solid #f7f8f7;
}

.border-t-green-4 {
  border-top: 4px solid #0b8376;
}

.border-t-purple-4 {
  border-top: 4px solid #755ae2;
}

.border-t-orange-4 {
  border-top: 4px solid #ea872d;
}

.border-t-black-4 {
  border-top: 4px solid #020202;
}

.border-t-light-gray-4 {
  border-top: 4px solid #fafafa;
}

.border-t-deep-green-4 {
  border-top: 4px solid #014345;
}

.border-b-primary-4 {
  border-bottom: 4px solid #cccccc;
}

.border-b-secondary-4 {
  border-bottom: 4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4 {
  border-bottom: 4px solid #ff0f00;
}

.border-b-info-4 {
  border-bottom: 4px solid #f6c31c;
}

.border-b-white-4 {
  border-bottom: 4px solid #f7f8f7;
}

.border-b-green-4 {
  border-bottom: 4px solid #0b8376;
}

.border-b-purple-4 {
  border-bottom: 4px solid #755ae2;
}

.border-b-orange-4 {
  border-bottom: 4px solid #ea872d;
}

.border-b-black-4 {
  border-bottom: 4px solid #020202;
}

.border-b-light-gray-4 {
  border-bottom: 4px solid #fafafa;
}

.border-b-deep-green-4 {
  border-bottom: 4px solid #014345;
}

.border_l-primary-4 {
  border-left: 4px solid #cccccc;
}

.border_l-secondary-4 {
  border-left: 4px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-4 {
  border-left: 4px solid #ff0f00;
}

.border_l-info-4 {
  border-left: 4px solid #f6c31c;
}

.border_l-white-4 {
  border-left: 4px solid #f7f8f7;
}

.border_l-green-4 {
  border-left: 4px solid #0b8376;
}

.border_l-purple-4 {
  border-left: 4px solid #755ae2;
}

.border_l-orange-4 {
  border-left: 4px solid #ea872d;
}

.border_l-black-4 {
  border-left: 4px solid #020202;
}

.border_l-light-gray-4 {
  border-left: 4px solid #fafafa;
}

.border_l-deep-green-4 {
  border-left: 4px solid #014345;
}

.border-r-primary-4 {
  border-right: 4px solid #cccccc;
}

.border-r-secondary-4 {
  border-right: 4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4 {
  border-right: 4px solid #ff0f00;
}

.border-r-info-4 {
  border-right: 4px solid #f6c31c;
}

.border-r-white-4 {
  border-right: 4px solid #f7f8f7;
}

.border-r-green-4 {
  border-right: 4px solid #0b8376;
}

.border-r-purple-4 {
  border-right: 4px solid #755ae2;
}

.border-r-orange-4 {
  border-right: 4px solid #ea872d;
}

.border-r-black-4 {
  border-right: 4px solid #020202;
}

.border-r-light-gray-4 {
  border-right: 4px solid #fafafa;
}

.border-r-deep-green-4 {
  border-right: 4px solid #014345;
}

.border-primary-4-1 {
  border: 4.1px solid #cccccc;
}

.border-secondary-4-1 {
  border: 4.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-1 {
  border: 4.1px solid #ff0f00;
}

.border-info-4-1 {
  border: 4.1px solid #f6c31c;
}

.border-white-4-1 {
  border: 4.1px solid #f7f8f7;
}

.border-green-4-1 {
  border: 4.1px solid #0b8376;
}

.border-purple-4-1 {
  border: 4.1px solid #755ae2;
}

.border-orange-4-1 {
  border: 4.1px solid #ea872d;
}

.border-black-4-1 {
  border: 4.1px solid #020202;
}

.border-light-gray-4-1 {
  border: 4.1px solid #fafafa;
}

.border-deep-green-4-1 {
  border: 4.1px solid #014345;
}

.border-t-primary-4-1 {
  border-top: 4.1px solid #cccccc;
}

.border-t-secondary-4-1 {
  border-top: 4.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-1 {
  border-top: 4.1px solid #ff0f00;
}

.border-t-info-4-1 {
  border-top: 4.1px solid #f6c31c;
}

.border-t-white-4-1 {
  border-top: 4.1px solid #f7f8f7;
}

.border-t-green-4-1 {
  border-top: 4.1px solid #0b8376;
}

.border-t-purple-4-1 {
  border-top: 4.1px solid #755ae2;
}

.border-t-orange-4-1 {
  border-top: 4.1px solid #ea872d;
}

.border-t-black-4-1 {
  border-top: 4.1px solid #020202;
}

.border-t-light-gray-4-1 {
  border-top: 4.1px solid #fafafa;
}

.border-t-deep-green-4-1 {
  border-top: 4.1px solid #014345;
}

.border-b-primary-4-1 {
  border-bottom: 4.1px solid #cccccc;
}

.border-b-secondary-4-1 {
  border-bottom: 4.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-1 {
  border-bottom: 4.1px solid #ff0f00;
}

.border-b-info-4-1 {
  border-bottom: 4.1px solid #f6c31c;
}

.border-b-white-4-1 {
  border-bottom: 4.1px solid #f7f8f7;
}

.border-b-green-4-1 {
  border-bottom: 4.1px solid #0b8376;
}

.border-b-purple-4-1 {
  border-bottom: 4.1px solid #755ae2;
}

.border-b-orange-4-1 {
  border-bottom: 4.1px solid #ea872d;
}

.border-b-black-4-1 {
  border-bottom: 4.1px solid #020202;
}

.border-b-light-gray-4-1 {
  border-bottom: 4.1px solid #fafafa;
}

.border-b-deep-green-4-1 {
  border-bottom: 4.1px solid #014345;
}

.border-l-primary-4-1 {
  border-left: 4.1px solid #cccccc;
}

.border-l-secondary-4-1 {
  border-left: 4.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-1 {
  border-left: 4.1px solid #ff0f00;
}

.border-l-info-4-1 {
  border-left: 4.1px solid #f6c31c;
}

.border-l-white-4-1 {
  border-left: 4.1px solid #f7f8f7;
}

.border-l-green-4-1 {
  border-left: 4.1px solid #0b8376;
}

.border-l-purple-4-1 {
  border-left: 4.1px solid #755ae2;
}

.border-l-orange-4-1 {
  border-left: 4.1px solid #ea872d;
}

.border-l-black-4-1 {
  border-left: 4.1px solid #020202;
}

.border-l-light-gray-4-1 {
  border-left: 4.1px solid #fafafa;
}

.border-l-deep-green-4-1 {
  border-left: 4.1px solid #014345;
}

.border-r-primary-4-1 {
  border-right: 4.1px solid #cccccc;
}

.border-r-secondary-4-1 {
  border-right: 4.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-1 {
  border-right: 4.1px solid #ff0f00;
}

.border-r-info-4-1 {
  border-right: 4.1px solid #f6c31c;
}

.border-r-white-4-1 {
  border-right: 4.1px solid #f7f8f7;
}

.border-r-green-4-1 {
  border-right: 4.1px solid #0b8376;
}

.border-r-purple-4-1 {
  border-right: 4.1px solid #755ae2;
}

.border-r-orange-4-1 {
  border-right: 4.1px solid #ea872d;
}

.border-r-black-4-1 {
  border-right: 4.1px solid #020202;
}

.border-r-light-gray-4-1 {
  border-right: 4.1px solid #fafafa;
}

.border-r-deep-green-4-1 {
  border-right: 4.1px solid #014345;
}

.border-primary-4-2 {
  border: 4.2px solid #cccccc;
}

.border-secondary-4-2 {
  border: 4.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-2 {
  border: 4.2px solid #ff0f00;
}

.border-info-4-2 {
  border: 4.2px solid #f6c31c;
}

.border-white-4-2 {
  border: 4.2px solid #f7f8f7;
}

.border-green-4-2 {
  border: 4.2px solid #0b8376;
}

.border-purple-4-2 {
  border: 4.2px solid #755ae2;
}

.border-orange-4-2 {
  border: 4.2px solid #ea872d;
}

.border-black-4-2 {
  border: 4.2px solid #020202;
}

.border-light-gray-4-2 {
  border: 4.2px solid #fafafa;
}

.border-deep-green-4-2 {
  border: 4.2px solid #014345;
}

.border-t-primary-4-2 {
  border-top: 4.2px solid #cccccc;
}

.border-t-secondary-4-2 {
  border-top: 4.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-2 {
  border-top: 4.2px solid #ff0f00;
}

.border-t-info-4-2 {
  border-top: 4.2px solid #f6c31c;
}

.border-t-white-4-2 {
  border-top: 4.2px solid #f7f8f7;
}

.border-t-green-4-2 {
  border-top: 4.2px solid #0b8376;
}

.border-t-purple-4-2 {
  border-top: 4.2px solid #755ae2;
}

.border-t-orange-4-2 {
  border-top: 4.2px solid #ea872d;
}

.border-t-black-4-2 {
  border-top: 4.2px solid #020202;
}

.border-t-light-gray-4-2 {
  border-top: 4.2px solid #fafafa;
}

.border-t-deep-green-4-2 {
  border-top: 4.2px solid #014345;
}

.border-b-primary-4-2 {
  border-bottom: 4.2px solid #cccccc;
}

.border-b-secondary-4-2 {
  border-bottom: 4.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-2 {
  border-bottom: 4.2px solid #ff0f00;
}

.border-b-info-4-2 {
  border-bottom: 4.2px solid #f6c31c;
}

.border-b-white-4-2 {
  border-bottom: 4.2px solid #f7f8f7;
}

.border-b-green-4-2 {
  border-bottom: 4.2px solid #0b8376;
}

.border-b-purple-4-2 {
  border-bottom: 4.2px solid #755ae2;
}

.border-b-orange-4-2 {
  border-bottom: 4.2px solid #ea872d;
}

.border-b-black-4-2 {
  border-bottom: 4.2px solid #020202;
}

.border-b-light-gray-4-2 {
  border-bottom: 4.2px solid #fafafa;
}

.border-b-deep-green-4-2 {
  border-bottom: 4.2px solid #014345;
}

.border-l-primary-4-2 {
  border-left: 4.2px solid #cccccc;
}

.border-l-secondary-4-2 {
  border-left: 4.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-2 {
  border-left: 4.2px solid #ff0f00;
}

.border-l-info-4-2 {
  border-left: 4.2px solid #f6c31c;
}

.border-l-white-4-2 {
  border-left: 4.2px solid #f7f8f7;
}

.border-l-green-4-2 {
  border-left: 4.2px solid #0b8376;
}

.border-l-purple-4-2 {
  border-left: 4.2px solid #755ae2;
}

.border-l-orange-4-2 {
  border-left: 4.2px solid #ea872d;
}

.border-l-black-4-2 {
  border-left: 4.2px solid #020202;
}

.border-l-light-gray-4-2 {
  border-left: 4.2px solid #fafafa;
}

.border-l-deep-green-4-2 {
  border-left: 4.2px solid #014345;
}

.border-r-primary-4-2 {
  border-right: 4.2px solid #cccccc;
}

.border-r-secondary-4-2 {
  border-right: 4.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-2 {
  border-right: 4.2px solid #ff0f00;
}

.border-r-info-4-2 {
  border-right: 4.2px solid #f6c31c;
}

.border-r-white-4-2 {
  border-right: 4.2px solid #f7f8f7;
}

.border-r-green-4-2 {
  border-right: 4.2px solid #0b8376;
}

.border-r-purple-4-2 {
  border-right: 4.2px solid #755ae2;
}

.border-r-orange-4-2 {
  border-right: 4.2px solid #ea872d;
}

.border-r-black-4-2 {
  border-right: 4.2px solid #020202;
}

.border-r-light-gray-4-2 {
  border-right: 4.2px solid #fafafa;
}

.border-r-deep-green-4-2 {
  border-right: 4.2px solid #014345;
}

.border-primary-4-3 {
  border: 4.3px solid #cccccc;
}

.border-secondary-4-3 {
  border: 4.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-3 {
  border: 4.3px solid #ff0f00;
}

.border-info-4-3 {
  border: 4.3px solid #f6c31c;
}

.border-white-4-3 {
  border: 4.3px solid #f7f8f7;
}

.border-green-4-3 {
  border: 4.3px solid #0b8376;
}

.border-purple-4-3 {
  border: 4.3px solid #755ae2;
}

.border-orange-4-3 {
  border: 4.3px solid #ea872d;
}

.border-black-4-3 {
  border: 4.3px solid #020202;
}

.border-light-gray-4-3 {
  border: 4.3px solid #fafafa;
}

.border-deep-green-4-3 {
  border: 4.3px solid #014345;
}

.border-t-primary-4-3 {
  border-top: 4.3px solid #cccccc;
}

.border-t-secondary-4-3 {
  border-top: 4.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-3 {
  border-top: 4.3px solid #ff0f00;
}

.border-t-info-4-3 {
  border-top: 4.3px solid #f6c31c;
}

.border-t-white-4-3 {
  border-top: 4.3px solid #f7f8f7;
}

.border-t-green-4-3 {
  border-top: 4.3px solid #0b8376;
}

.border-t-purple-4-3 {
  border-top: 4.3px solid #755ae2;
}

.border-t-orange-4-3 {
  border-top: 4.3px solid #ea872d;
}

.border-t-black-4-3 {
  border-top: 4.3px solid #020202;
}

.border-t-light-gray-4-3 {
  border-top: 4.3px solid #fafafa;
}

.border-t-deep-green-4-3 {
  border-top: 4.3px solid #014345;
}

.border-b-primary-4-3 {
  border-bottom: 4.3px solid #cccccc;
}

.border-b-secondary-4-3 {
  border-bottom: 4.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-3 {
  border-bottom: 4.3px solid #ff0f00;
}

.border-b-info-4-3 {
  border-bottom: 4.3px solid #f6c31c;
}

.border-b-white-4-3 {
  border-bottom: 4.3px solid #f7f8f7;
}

.border-b-green-4-3 {
  border-bottom: 4.3px solid #0b8376;
}

.border-b-purple-4-3 {
  border-bottom: 4.3px solid #755ae2;
}

.border-b-orange-4-3 {
  border-bottom: 4.3px solid #ea872d;
}

.border-b-black-4-3 {
  border-bottom: 4.3px solid #020202;
}

.border-b-light-gray-4-3 {
  border-bottom: 4.3px solid #fafafa;
}

.border-b-deep-green-4-3 {
  border-bottom: 4.3px solid #014345;
}

.border-l-primary-4-3 {
  border-left: 4.3px solid #cccccc;
}

.border-l-secondary-4-3 {
  border-left: 4.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-3 {
  border-left: 4.3px solid #ff0f00;
}

.border-l-info-4-3 {
  border-left: 4.3px solid #f6c31c;
}

.border-l-white-4-3 {
  border-left: 4.3px solid #f7f8f7;
}

.border-l-green-4-3 {
  border-left: 4.3px solid #0b8376;
}

.border-l-purple-4-3 {
  border-left: 4.3px solid #755ae2;
}

.border-l-orange-4-3 {
  border-left: 4.3px solid #ea872d;
}

.border-l-black-4-3 {
  border-left: 4.3px solid #020202;
}

.border-l-light-gray-4-3 {
  border-left: 4.3px solid #fafafa;
}

.border-l-deep-green-4-3 {
  border-left: 4.3px solid #014345;
}

.border-r-primary-4-3 {
  border-right: 4.3px solid #cccccc;
}

.border-r-secondary-4-3 {
  border-right: 4.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-3 {
  border-right: 4.3px solid #ff0f00;
}

.border-r-info-4-3 {
  border-right: 4.3px solid #f6c31c;
}

.border-r-white-4-3 {
  border-right: 4.3px solid #f7f8f7;
}

.border-r-green-4-3 {
  border-right: 4.3px solid #0b8376;
}

.border-r-purple-4-3 {
  border-right: 4.3px solid #755ae2;
}

.border-r-orange-4-3 {
  border-right: 4.3px solid #ea872d;
}

.border-r-black-4-3 {
  border-right: 4.3px solid #020202;
}

.border-r-light-gray-4-3 {
  border-right: 4.3px solid #fafafa;
}

.border-r-deep-green-4-3 {
  border-right: 4.3px solid #014345;
}

.border-primary-4-4 {
  border: 4.4px solid #cccccc;
}

.border-secondary-4-4 {
  border: 4.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-4 {
  border: 4.4px solid #ff0f00;
}

.border-info-4-4 {
  border: 4.4px solid #f6c31c;
}

.border-white-4-4 {
  border: 4.4px solid #f7f8f7;
}

.border-green-4-4 {
  border: 4.4px solid #0b8376;
}

.border-purple-4-4 {
  border: 4.4px solid #755ae2;
}

.border-orange-4-4 {
  border: 4.4px solid #ea872d;
}

.border-black-4-4 {
  border: 4.4px solid #020202;
}

.border-light-gray-4-4 {
  border: 4.4px solid #fafafa;
}

.border-deep-green-4-4 {
  border: 4.4px solid #014345;
}

.border-t-primary-4-4 {
  border-top: 4.4px solid #cccccc;
}

.border-t-secondary-4-4 {
  border-top: 4.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-4 {
  border-top: 4.4px solid #ff0f00;
}

.border-t-info-4-4 {
  border-top: 4.4px solid #f6c31c;
}

.border-t-white-4-4 {
  border-top: 4.4px solid #f7f8f7;
}

.border-t-green-4-4 {
  border-top: 4.4px solid #0b8376;
}

.border-t-purple-4-4 {
  border-top: 4.4px solid #755ae2;
}

.border-t-orange-4-4 {
  border-top: 4.4px solid #ea872d;
}

.border-t-black-4-4 {
  border-top: 4.4px solid #020202;
}

.border-t-light-gray-4-4 {
  border-top: 4.4px solid #fafafa;
}

.border-t-deep-green-4-4 {
  border-top: 4.4px solid #014345;
}

.border-b-primary-4-4 {
  border-bottom: 4.4px solid #cccccc;
}

.border-b-secondary-4-4 {
  border-bottom: 4.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-4 {
  border-bottom: 4.4px solid #ff0f00;
}

.border-b-info-4-4 {
  border-bottom: 4.4px solid #f6c31c;
}

.border-b-white-4-4 {
  border-bottom: 4.4px solid #f7f8f7;
}

.border-b-green-4-4 {
  border-bottom: 4.4px solid #0b8376;
}

.border-b-purple-4-4 {
  border-bottom: 4.4px solid #755ae2;
}

.border-b-orange-4-4 {
  border-bottom: 4.4px solid #ea872d;
}

.border-b-black-4-4 {
  border-bottom: 4.4px solid #020202;
}

.border-b-light-gray-4-4 {
  border-bottom: 4.4px solid #fafafa;
}

.border-b-deep-green-4-4 {
  border-bottom: 4.4px solid #014345;
}

.border-l-primary-4-4 {
  border-left: 4.4px solid #cccccc;
}

.border-l-secondary-4-4 {
  border-left: 4.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-4 {
  border-left: 4.4px solid #ff0f00;
}

.border-l-info-4-4 {
  border-left: 4.4px solid #f6c31c;
}

.border-l-white-4-4 {
  border-left: 4.4px solid #f7f8f7;
}

.border-l-green-4-4 {
  border-left: 4.4px solid #0b8376;
}

.border-l-purple-4-4 {
  border-left: 4.4px solid #755ae2;
}

.border-l-orange-4-4 {
  border-left: 4.4px solid #ea872d;
}

.border-l-black-4-4 {
  border-left: 4.4px solid #020202;
}

.border-l-light-gray-4-4 {
  border-left: 4.4px solid #fafafa;
}

.border-l-deep-green-4-4 {
  border-left: 4.4px solid #014345;
}

.border-r-primary-4-4 {
  border-right: 4.4px solid #cccccc;
}

.border-r-secondary-4-4 {
  border-right: 4.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-4 {
  border-right: 4.4px solid #ff0f00;
}

.border-r-info-4-4 {
  border-right: 4.4px solid #f6c31c;
}

.border-r-white-4-4 {
  border-right: 4.4px solid #f7f8f7;
}

.border-r-green-4-4 {
  border-right: 4.4px solid #0b8376;
}

.border-r-purple-4-4 {
  border-right: 4.4px solid #755ae2;
}

.border-r-orange-4-4 {
  border-right: 4.4px solid #ea872d;
}

.border-r-black-4-4 {
  border-right: 4.4px solid #020202;
}

.border-r-light-gray-4-4 {
  border-right: 4.4px solid #fafafa;
}

.border-r-deep-green-4-4 {
  border-right: 4.4px solid #014345;
}

.border-primary-4-5 {
  border: 4.5px solid #cccccc;
}

.border-secondary-4-5 {
  border: 4.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-5 {
  border: 4.5px solid #ff0f00;
}

.border-info-4-5 {
  border: 4.5px solid #f6c31c;
}

.border-white-4-5 {
  border: 4.5px solid #f7f8f7;
}

.border-green-4-5 {
  border: 4.5px solid #0b8376;
}

.border-purple-4-5 {
  border: 4.5px solid #755ae2;
}

.border-orange-4-5 {
  border: 4.5px solid #ea872d;
}

.border-black-4-5 {
  border: 4.5px solid #020202;
}

.border-light-gray-4-5 {
  border: 4.5px solid #fafafa;
}

.border-deep-green-4-5 {
  border: 4.5px solid #014345;
}

.border-t-primary-4-5 {
  border-top: 4.5px solid #cccccc;
}

.border-t-secondary-4-5 {
  border-top: 4.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-5 {
  border-top: 4.5px solid #ff0f00;
}

.border-t-info-4-5 {
  border-top: 4.5px solid #f6c31c;
}

.border-t-white-4-5 {
  border-top: 4.5px solid #f7f8f7;
}

.border-t-green-4-5 {
  border-top: 4.5px solid #0b8376;
}

.border-t-purple-4-5 {
  border-top: 4.5px solid #755ae2;
}

.border-t-orange-4-5 {
  border-top: 4.5px solid #ea872d;
}

.border-t-black-4-5 {
  border-top: 4.5px solid #020202;
}

.border-t-light-gray-4-5 {
  border-top: 4.5px solid #fafafa;
}

.border-t-deep-green-4-5 {
  border-top: 4.5px solid #014345;
}

.border-b-primary-4-5 {
  border-bottom: 4.5px solid #cccccc;
}

.border-b-secondary-4-5 {
  border-bottom: 4.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-5 {
  border-bottom: 4.5px solid #ff0f00;
}

.border-b-info-4-5 {
  border-bottom: 4.5px solid #f6c31c;
}

.border-b-white-4-5 {
  border-bottom: 4.5px solid #f7f8f7;
}

.border-b-green-4-5 {
  border-bottom: 4.5px solid #0b8376;
}

.border-b-purple-4-5 {
  border-bottom: 4.5px solid #755ae2;
}

.border-b-orange-4-5 {
  border-bottom: 4.5px solid #ea872d;
}

.border-b-black-4-5 {
  border-bottom: 4.5px solid #020202;
}

.border-b-light-gray-4-5 {
  border-bottom: 4.5px solid #fafafa;
}

.border-b-deep-green-4-5 {
  border-bottom: 4.5px solid #014345;
}

.border-l-primary-4-5 {
  border-left: 4.5px solid #cccccc;
}

.border-l-secondary-4-5 {
  border-left: 4.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-5 {
  border-left: 4.5px solid #ff0f00;
}

.border-l-info-4-5 {
  border-left: 4.5px solid #f6c31c;
}

.border-l-white-4-5 {
  border-left: 4.5px solid #f7f8f7;
}

.border-l-green-4-5 {
  border-left: 4.5px solid #0b8376;
}

.border-l-purple-4-5 {
  border-left: 4.5px solid #755ae2;
}

.border-l-orange-4-5 {
  border-left: 4.5px solid #ea872d;
}

.border-l-black-4-5 {
  border-left: 4.5px solid #020202;
}

.border-l-light-gray-4-5 {
  border-left: 4.5px solid #fafafa;
}

.border-l-deep-green-4-5 {
  border-left: 4.5px solid #014345;
}

.border-r-primary-4-5 {
  border-right: 4.5px solid #cccccc;
}

.border-r-secondary-4-5 {
  border-right: 4.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-5 {
  border-right: 4.5px solid #ff0f00;
}

.border-r-info-4-5 {
  border-right: 4.5px solid #f6c31c;
}

.border-r-white-4-5 {
  border-right: 4.5px solid #f7f8f7;
}

.border-r-green-4-5 {
  border-right: 4.5px solid #0b8376;
}

.border-r-purple-4-5 {
  border-right: 4.5px solid #755ae2;
}

.border-r-orange-4-5 {
  border-right: 4.5px solid #ea872d;
}

.border-r-black-4-5 {
  border-right: 4.5px solid #020202;
}

.border-r-light-gray-4-5 {
  border-right: 4.5px solid #fafafa;
}

.border-r-deep-green-4-5 {
  border-right: 4.5px solid #014345;
}

.border-primary-4-6 {
  border: 4.6px solid #cccccc;
}

.border-secondary-4-6 {
  border: 4.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-6 {
  border: 4.6px solid #ff0f00;
}

.border-info-4-6 {
  border: 4.6px solid #f6c31c;
}

.border-white-4-6 {
  border: 4.6px solid #f7f8f7;
}

.border-green-4-6 {
  border: 4.6px solid #0b8376;
}

.border-purple-4-6 {
  border: 4.6px solid #755ae2;
}

.border-orange-4-6 {
  border: 4.6px solid #ea872d;
}

.border-black-4-6 {
  border: 4.6px solid #020202;
}

.border-light-gray-4-6 {
  border: 4.6px solid #fafafa;
}

.border-deep-green-4-6 {
  border: 4.6px solid #014345;
}

.border-t-primary-4-6 {
  border-top: 4.6px solid #cccccc;
}

.border-t-secondary-4-6 {
  border-top: 4.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-6 {
  border-top: 4.6px solid #ff0f00;
}

.border-t-info-4-6 {
  border-top: 4.6px solid #f6c31c;
}

.border-t-white-4-6 {
  border-top: 4.6px solid #f7f8f7;
}

.border-t-green-4-6 {
  border-top: 4.6px solid #0b8376;
}

.border-t-purple-4-6 {
  border-top: 4.6px solid #755ae2;
}

.border-t-orange-4-6 {
  border-top: 4.6px solid #ea872d;
}

.border-t-black-4-6 {
  border-top: 4.6px solid #020202;
}

.border-t-light-gray-4-6 {
  border-top: 4.6px solid #fafafa;
}

.border-t-deep-green-4-6 {
  border-top: 4.6px solid #014345;
}

.border-b-primary-4-6 {
  border-bottom: 4.6px solid #cccccc;
}

.border-b-secondary-4-6 {
  border-bottom: 4.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-6 {
  border-bottom: 4.6px solid #ff0f00;
}

.border-b-info-4-6 {
  border-bottom: 4.6px solid #f6c31c;
}

.border-b-white-4-6 {
  border-bottom: 4.6px solid #f7f8f7;
}

.border-b-green-4-6 {
  border-bottom: 4.6px solid #0b8376;
}

.border-b-purple-4-6 {
  border-bottom: 4.6px solid #755ae2;
}

.border-b-orange-4-6 {
  border-bottom: 4.6px solid #ea872d;
}

.border-b-black-4-6 {
  border-bottom: 4.6px solid #020202;
}

.border-b-light-gray-4-6 {
  border-bottom: 4.6px solid #fafafa;
}

.border-b-deep-green-4-6 {
  border-bottom: 4.6px solid #014345;
}

.border-l-primary-4-6 {
  border-left: 4.6px solid #cccccc;
}

.border-l-secondary-4-6 {
  border-left: 4.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-6 {
  border-left: 4.6px solid #ff0f00;
}

.border-l-info-4-6 {
  border-left: 4.6px solid #f6c31c;
}

.border-l-white-4-6 {
  border-left: 4.6px solid #f7f8f7;
}

.border-l-green-4-6 {
  border-left: 4.6px solid #0b8376;
}

.border-l-purple-4-6 {
  border-left: 4.6px solid #755ae2;
}

.border-l-orange-4-6 {
  border-left: 4.6px solid #ea872d;
}

.border-l-black-4-6 {
  border-left: 4.6px solid #020202;
}

.border-l-light-gray-4-6 {
  border-left: 4.6px solid #fafafa;
}

.border-l-deep-green-4-6 {
  border-left: 4.6px solid #014345;
}

.border-r-primary-4-6 {
  border-right: 4.6px solid #cccccc;
}

.border-r-secondary-4-6 {
  border-right: 4.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-6 {
  border-right: 4.6px solid #ff0f00;
}

.border-r-info-4-6 {
  border-right: 4.6px solid #f6c31c;
}

.border-r-white-4-6 {
  border-right: 4.6px solid #f7f8f7;
}

.border-r-green-4-6 {
  border-right: 4.6px solid #0b8376;
}

.border-r-purple-4-6 {
  border-right: 4.6px solid #755ae2;
}

.border-r-orange-4-6 {
  border-right: 4.6px solid #ea872d;
}

.border-r-black-4-6 {
  border-right: 4.6px solid #020202;
}

.border-r-light-gray-4-6 {
  border-right: 4.6px solid #fafafa;
}

.border-r-deep-green-4-6 {
  border-right: 4.6px solid #014345;
}

.border-primary-4-7 {
  border: 4.7px solid #cccccc;
}

.border-secondary-4-7 {
  border: 4.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-7 {
  border: 4.7px solid #ff0f00;
}

.border-info-4-7 {
  border: 4.7px solid #f6c31c;
}

.border-white-4-7 {
  border: 4.7px solid #f7f8f7;
}

.border-green-4-7 {
  border: 4.7px solid #0b8376;
}

.border-purple-4-7 {
  border: 4.7px solid #755ae2;
}

.border-orange-4-7 {
  border: 4.7px solid #ea872d;
}

.border-black-4-7 {
  border: 4.7px solid #020202;
}

.border-light-gray-4-7 {
  border: 4.7px solid #fafafa;
}

.border-deep-green-4-7 {
  border: 4.7px solid #014345;
}

.border-t-primary-4-7 {
  border-top: 4.7px solid #cccccc;
}

.border-t-secondary-4-7 {
  border-top: 4.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-7 {
  border-top: 4.7px solid #ff0f00;
}

.border-t-info-4-7 {
  border-top: 4.7px solid #f6c31c;
}

.border-t-white-4-7 {
  border-top: 4.7px solid #f7f8f7;
}

.border-t-green-4-7 {
  border-top: 4.7px solid #0b8376;
}

.border-t-purple-4-7 {
  border-top: 4.7px solid #755ae2;
}

.border-t-orange-4-7 {
  border-top: 4.7px solid #ea872d;
}

.border-t-black-4-7 {
  border-top: 4.7px solid #020202;
}

.border-t-light-gray-4-7 {
  border-top: 4.7px solid #fafafa;
}

.border-t-deep-green-4-7 {
  border-top: 4.7px solid #014345;
}

.border-b-primary-4-7 {
  border-bottom: 4.7px solid #cccccc;
}

.border-b-secondary-4-7 {
  border-bottom: 4.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-7 {
  border-bottom: 4.7px solid #ff0f00;
}

.border-b-info-4-7 {
  border-bottom: 4.7px solid #f6c31c;
}

.border-b-white-4-7 {
  border-bottom: 4.7px solid #f7f8f7;
}

.border-b-green-4-7 {
  border-bottom: 4.7px solid #0b8376;
}

.border-b-purple-4-7 {
  border-bottom: 4.7px solid #755ae2;
}

.border-b-orange-4-7 {
  border-bottom: 4.7px solid #ea872d;
}

.border-b-black-4-7 {
  border-bottom: 4.7px solid #020202;
}

.border-b-light-gray-4-7 {
  border-bottom: 4.7px solid #fafafa;
}

.border-b-deep-green-4-7 {
  border-bottom: 4.7px solid #014345;
}

.border-l-primary-4-7 {
  border-left: 4.7px solid #cccccc;
}

.border-l-secondary-4-7 {
  border-left: 4.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-7 {
  border-left: 4.7px solid #ff0f00;
}

.border-l-info-4-7 {
  border-left: 4.7px solid #f6c31c;
}

.border-l-white-4-7 {
  border-left: 4.7px solid #f7f8f7;
}

.border-l-green-4-7 {
  border-left: 4.7px solid #0b8376;
}

.border-l-purple-4-7 {
  border-left: 4.7px solid #755ae2;
}

.border-l-orange-4-7 {
  border-left: 4.7px solid #ea872d;
}

.border-l-black-4-7 {
  border-left: 4.7px solid #020202;
}

.border-l-light-gray-4-7 {
  border-left: 4.7px solid #fafafa;
}

.border-l-deep-green-4-7 {
  border-left: 4.7px solid #014345;
}

.border-r-primary-4-7 {
  border-right: 4.7px solid #cccccc;
}

.border-r-secondary-4-7 {
  border-right: 4.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-7 {
  border-right: 4.7px solid #ff0f00;
}

.border-r-info-4-7 {
  border-right: 4.7px solid #f6c31c;
}

.border-r-white-4-7 {
  border-right: 4.7px solid #f7f8f7;
}

.border-r-green-4-7 {
  border-right: 4.7px solid #0b8376;
}

.border-r-purple-4-7 {
  border-right: 4.7px solid #755ae2;
}

.border-r-orange-4-7 {
  border-right: 4.7px solid #ea872d;
}

.border-r-black-4-7 {
  border-right: 4.7px solid #020202;
}

.border-r-light-gray-4-7 {
  border-right: 4.7px solid #fafafa;
}

.border-r-deep-green-4-7 {
  border-right: 4.7px solid #014345;
}

.border-primary-4-8 {
  border: 4.8px solid #cccccc;
}

.border-secondary-4-8 {
  border: 4.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-8 {
  border: 4.8px solid #ff0f00;
}

.border-info-4-8 {
  border: 4.8px solid #f6c31c;
}

.border-white-4-8 {
  border: 4.8px solid #f7f8f7;
}

.border-green-4-8 {
  border: 4.8px solid #0b8376;
}

.border-purple-4-8 {
  border: 4.8px solid #755ae2;
}

.border-orange-4-8 {
  border: 4.8px solid #ea872d;
}

.border-black-4-8 {
  border: 4.8px solid #020202;
}

.border-light-gray-4-8 {
  border: 4.8px solid #fafafa;
}

.border-deep-green-4-8 {
  border: 4.8px solid #014345;
}

.border-t-primary-4-8 {
  border-top: 4.8px solid #cccccc;
}

.border-t-secondary-4-8 {
  border-top: 4.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-8 {
  border-top: 4.8px solid #ff0f00;
}

.border-t-info-4-8 {
  border-top: 4.8px solid #f6c31c;
}

.border-t-white-4-8 {
  border-top: 4.8px solid #f7f8f7;
}

.border-t-green-4-8 {
  border-top: 4.8px solid #0b8376;
}

.border-t-purple-4-8 {
  border-top: 4.8px solid #755ae2;
}

.border-t-orange-4-8 {
  border-top: 4.8px solid #ea872d;
}

.border-t-black-4-8 {
  border-top: 4.8px solid #020202;
}

.border-t-light-gray-4-8 {
  border-top: 4.8px solid #fafafa;
}

.border-t-deep-green-4-8 {
  border-top: 4.8px solid #014345;
}

.border-b-primary-4-8 {
  border-bottom: 4.8px solid #cccccc;
}

.border-b-secondary-4-8 {
  border-bottom: 4.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-8 {
  border-bottom: 4.8px solid #ff0f00;
}

.border-b-info-4-8 {
  border-bottom: 4.8px solid #f6c31c;
}

.border-b-white-4-8 {
  border-bottom: 4.8px solid #f7f8f7;
}

.border-b-green-4-8 {
  border-bottom: 4.8px solid #0b8376;
}

.border-b-purple-4-8 {
  border-bottom: 4.8px solid #755ae2;
}

.border-b-orange-4-8 {
  border-bottom: 4.8px solid #ea872d;
}

.border-b-black-4-8 {
  border-bottom: 4.8px solid #020202;
}

.border-b-light-gray-4-8 {
  border-bottom: 4.8px solid #fafafa;
}

.border-b-deep-green-4-8 {
  border-bottom: 4.8px solid #014345;
}

.border-l-primary-4-8 {
  border-left: 4.8px solid #cccccc;
}

.border-l-secondary-4-8 {
  border-left: 4.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-8 {
  border-left: 4.8px solid #ff0f00;
}

.border-l-info-4-8 {
  border-left: 4.8px solid #f6c31c;
}

.border-l-white-4-8 {
  border-left: 4.8px solid #f7f8f7;
}

.border-l-green-4-8 {
  border-left: 4.8px solid #0b8376;
}

.border-l-purple-4-8 {
  border-left: 4.8px solid #755ae2;
}

.border-l-orange-4-8 {
  border-left: 4.8px solid #ea872d;
}

.border-l-black-4-8 {
  border-left: 4.8px solid #020202;
}

.border-l-light-gray-4-8 {
  border-left: 4.8px solid #fafafa;
}

.border-l-deep-green-4-8 {
  border-left: 4.8px solid #014345;
}

.border-r-primary-4-8 {
  border-right: 4.8px solid #cccccc;
}

.border-r-secondary-4-8 {
  border-right: 4.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-8 {
  border-right: 4.8px solid #ff0f00;
}

.border-r-info-4-8 {
  border-right: 4.8px solid #f6c31c;
}

.border-r-white-4-8 {
  border-right: 4.8px solid #f7f8f7;
}

.border-r-green-4-8 {
  border-right: 4.8px solid #0b8376;
}

.border-r-purple-4-8 {
  border-right: 4.8px solid #755ae2;
}

.border-r-orange-4-8 {
  border-right: 4.8px solid #ea872d;
}

.border-r-black-4-8 {
  border-right: 4.8px solid #020202;
}

.border-r-light-gray-4-8 {
  border-right: 4.8px solid #fafafa;
}

.border-r-deep-green-4-8 {
  border-right: 4.8px solid #014345;
}

.border-primary-4-9 {
  border: 4.9px solid #cccccc;
}

.border-secondary-4-9 {
  border: 4.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-4-9 {
  border: 4.9px solid #ff0f00;
}

.border-info-4-9 {
  border: 4.9px solid #f6c31c;
}

.border-white-4-9 {
  border: 4.9px solid #f7f8f7;
}

.border-green-4-9 {
  border: 4.9px solid #0b8376;
}

.border-purple-4-9 {
  border: 4.9px solid #755ae2;
}

.border-orange-4-9 {
  border: 4.9px solid #ea872d;
}

.border-black-4-9 {
  border: 4.9px solid #020202;
}

.border-light-gray-4-9 {
  border: 4.9px solid #fafafa;
}

.border-deep-green-4-9 {
  border: 4.9px solid #014345;
}

.border-t-primary-4-9 {
  border-top: 4.9px solid #cccccc;
}

.border-t-secondary-4-9 {
  border-top: 4.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-4-9 {
  border-top: 4.9px solid #ff0f00;
}

.border-t-info-4-9 {
  border-top: 4.9px solid #f6c31c;
}

.border-t-white-4-9 {
  border-top: 4.9px solid #f7f8f7;
}

.border-t-green-4-9 {
  border-top: 4.9px solid #0b8376;
}

.border-t-purple-4-9 {
  border-top: 4.9px solid #755ae2;
}

.border-t-orange-4-9 {
  border-top: 4.9px solid #ea872d;
}

.border-t-black-4-9 {
  border-top: 4.9px solid #020202;
}

.border-t-light-gray-4-9 {
  border-top: 4.9px solid #fafafa;
}

.border-t-deep-green-4-9 {
  border-top: 4.9px solid #014345;
}

.border-b-primary-4-9 {
  border-bottom: 4.9px solid #cccccc;
}

.border-b-secondary-4-9 {
  border-bottom: 4.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-4-9 {
  border-bottom: 4.9px solid #ff0f00;
}

.border-b-info-4-9 {
  border-bottom: 4.9px solid #f6c31c;
}

.border-b-white-4-9 {
  border-bottom: 4.9px solid #f7f8f7;
}

.border-b-green-4-9 {
  border-bottom: 4.9px solid #0b8376;
}

.border-b-purple-4-9 {
  border-bottom: 4.9px solid #755ae2;
}

.border-b-orange-4-9 {
  border-bottom: 4.9px solid #ea872d;
}

.border-b-black-4-9 {
  border-bottom: 4.9px solid #020202;
}

.border-b-light-gray-4-9 {
  border-bottom: 4.9px solid #fafafa;
}

.border-b-deep-green-4-9 {
  border-bottom: 4.9px solid #014345;
}

.border-l-primary-4-9 {
  border-left: 4.9px solid #cccccc;
}

.border-l-secondary-4-9 {
  border-left: 4.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-4-9 {
  border-left: 4.9px solid #ff0f00;
}

.border-l-info-4-9 {
  border-left: 4.9px solid #f6c31c;
}

.border-l-white-4-9 {
  border-left: 4.9px solid #f7f8f7;
}

.border-l-green-4-9 {
  border-left: 4.9px solid #0b8376;
}

.border-l-purple-4-9 {
  border-left: 4.9px solid #755ae2;
}

.border-l-orange-4-9 {
  border-left: 4.9px solid #ea872d;
}

.border-l-black-4-9 {
  border-left: 4.9px solid #020202;
}

.border-l-light-gray-4-9 {
  border-left: 4.9px solid #fafafa;
}

.border-l-deep-green-4-9 {
  border-left: 4.9px solid #014345;
}

.border-r-primary-4-9 {
  border-right: 4.9px solid #cccccc;
}

.border-r-secondary-4-9 {
  border-right: 4.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-4-9 {
  border-right: 4.9px solid #ff0f00;
}

.border-r-info-4-9 {
  border-right: 4.9px solid #f6c31c;
}

.border-r-white-4-9 {
  border-right: 4.9px solid #f7f8f7;
}

.border-r-green-4-9 {
  border-right: 4.9px solid #0b8376;
}

.border-r-purple-4-9 {
  border-right: 4.9px solid #755ae2;
}

.border-r-orange-4-9 {
  border-right: 4.9px solid #ea872d;
}

.border-r-black-4-9 {
  border-right: 4.9px solid #020202;
}

.border-r-light-gray-4-9 {
  border-right: 4.9px solid #fafafa;
}

.border-r-deep-green-4-9 {
  border-right: 4.9px solid #014345;
}

.border-primary-5 {
  border: 5px solid #cccccc;
}

.border-secondary-5 {
  border: 5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5 {
  border: 5px solid #ff0f00;
}

.border-info-5 {
  border: 5px solid #f6c31c;
}

.border-white-5 {
  border: 5px solid #f7f8f7;
}

.border-green-5 {
  border: 5px solid #0b8376;
}

.border-purple-5 {
  border: 5px solid #755ae2;
}

.border-orange-5 {
  border: 5px solid #ea872d;
}

.border-black-5 {
  border: 5px solid #020202;
}

.border-light-gray-5 {
  border: 5px solid #fafafa;
}

.border-deep-green-5 {
  border: 5px solid #014345;
}

.border-t-primary-5 {
  border-top: 5px solid #cccccc;
}

.border-t-secondary-5 {
  border-top: 5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5 {
  border-top: 5px solid #ff0f00;
}

.border-t-info-5 {
  border-top: 5px solid #f6c31c;
}

.border-t-white-5 {
  border-top: 5px solid #f7f8f7;
}

.border-t-green-5 {
  border-top: 5px solid #0b8376;
}

.border-t-purple-5 {
  border-top: 5px solid #755ae2;
}

.border-t-orange-5 {
  border-top: 5px solid #ea872d;
}

.border-t-black-5 {
  border-top: 5px solid #020202;
}

.border-t-light-gray-5 {
  border-top: 5px solid #fafafa;
}

.border-t-deep-green-5 {
  border-top: 5px solid #014345;
}

.border-b-primary-5 {
  border-bottom: 5px solid #cccccc;
}

.border-b-secondary-5 {
  border-bottom: 5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5 {
  border-bottom: 5px solid #ff0f00;
}

.border-b-info-5 {
  border-bottom: 5px solid #f6c31c;
}

.border-b-white-5 {
  border-bottom: 5px solid #f7f8f7;
}

.border-b-green-5 {
  border-bottom: 5px solid #0b8376;
}

.border-b-purple-5 {
  border-bottom: 5px solid #755ae2;
}

.border-b-orange-5 {
  border-bottom: 5px solid #ea872d;
}

.border-b-black-5 {
  border-bottom: 5px solid #020202;
}

.border-b-light-gray-5 {
  border-bottom: 5px solid #fafafa;
}

.border-b-deep-green-5 {
  border-bottom: 5px solid #014345;
}

.border_l-primary-5 {
  border-left: 5px solid #cccccc;
}

.border_l-secondary-5 {
  border-left: 5px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-5 {
  border-left: 5px solid #ff0f00;
}

.border_l-info-5 {
  border-left: 5px solid #f6c31c;
}

.border_l-white-5 {
  border-left: 5px solid #f7f8f7;
}

.border_l-green-5 {
  border-left: 5px solid #0b8376;
}

.border_l-purple-5 {
  border-left: 5px solid #755ae2;
}

.border_l-orange-5 {
  border-left: 5px solid #ea872d;
}

.border_l-black-5 {
  border-left: 5px solid #020202;
}

.border_l-light-gray-5 {
  border-left: 5px solid #fafafa;
}

.border_l-deep-green-5 {
  border-left: 5px solid #014345;
}

.border-r-primary-5 {
  border-right: 5px solid #cccccc;
}

.border-r-secondary-5 {
  border-right: 5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5 {
  border-right: 5px solid #ff0f00;
}

.border-r-info-5 {
  border-right: 5px solid #f6c31c;
}

.border-r-white-5 {
  border-right: 5px solid #f7f8f7;
}

.border-r-green-5 {
  border-right: 5px solid #0b8376;
}

.border-r-purple-5 {
  border-right: 5px solid #755ae2;
}

.border-r-orange-5 {
  border-right: 5px solid #ea872d;
}

.border-r-black-5 {
  border-right: 5px solid #020202;
}

.border-r-light-gray-5 {
  border-right: 5px solid #fafafa;
}

.border-r-deep-green-5 {
  border-right: 5px solid #014345;
}

.border-primary-5-1 {
  border: 5.1px solid #cccccc;
}

.border-secondary-5-1 {
  border: 5.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-1 {
  border: 5.1px solid #ff0f00;
}

.border-info-5-1 {
  border: 5.1px solid #f6c31c;
}

.border-white-5-1 {
  border: 5.1px solid #f7f8f7;
}

.border-green-5-1 {
  border: 5.1px solid #0b8376;
}

.border-purple-5-1 {
  border: 5.1px solid #755ae2;
}

.border-orange-5-1 {
  border: 5.1px solid #ea872d;
}

.border-black-5-1 {
  border: 5.1px solid #020202;
}

.border-light-gray-5-1 {
  border: 5.1px solid #fafafa;
}

.border-deep-green-5-1 {
  border: 5.1px solid #014345;
}

.border-t-primary-5-1 {
  border-top: 5.1px solid #cccccc;
}

.border-t-secondary-5-1 {
  border-top: 5.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-1 {
  border-top: 5.1px solid #ff0f00;
}

.border-t-info-5-1 {
  border-top: 5.1px solid #f6c31c;
}

.border-t-white-5-1 {
  border-top: 5.1px solid #f7f8f7;
}

.border-t-green-5-1 {
  border-top: 5.1px solid #0b8376;
}

.border-t-purple-5-1 {
  border-top: 5.1px solid #755ae2;
}

.border-t-orange-5-1 {
  border-top: 5.1px solid #ea872d;
}

.border-t-black-5-1 {
  border-top: 5.1px solid #020202;
}

.border-t-light-gray-5-1 {
  border-top: 5.1px solid #fafafa;
}

.border-t-deep-green-5-1 {
  border-top: 5.1px solid #014345;
}

.border-b-primary-5-1 {
  border-bottom: 5.1px solid #cccccc;
}

.border-b-secondary-5-1 {
  border-bottom: 5.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-1 {
  border-bottom: 5.1px solid #ff0f00;
}

.border-b-info-5-1 {
  border-bottom: 5.1px solid #f6c31c;
}

.border-b-white-5-1 {
  border-bottom: 5.1px solid #f7f8f7;
}

.border-b-green-5-1 {
  border-bottom: 5.1px solid #0b8376;
}

.border-b-purple-5-1 {
  border-bottom: 5.1px solid #755ae2;
}

.border-b-orange-5-1 {
  border-bottom: 5.1px solid #ea872d;
}

.border-b-black-5-1 {
  border-bottom: 5.1px solid #020202;
}

.border-b-light-gray-5-1 {
  border-bottom: 5.1px solid #fafafa;
}

.border-b-deep-green-5-1 {
  border-bottom: 5.1px solid #014345;
}

.border-l-primary-5-1 {
  border-left: 5.1px solid #cccccc;
}

.border-l-secondary-5-1 {
  border-left: 5.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-1 {
  border-left: 5.1px solid #ff0f00;
}

.border-l-info-5-1 {
  border-left: 5.1px solid #f6c31c;
}

.border-l-white-5-1 {
  border-left: 5.1px solid #f7f8f7;
}

.border-l-green-5-1 {
  border-left: 5.1px solid #0b8376;
}

.border-l-purple-5-1 {
  border-left: 5.1px solid #755ae2;
}

.border-l-orange-5-1 {
  border-left: 5.1px solid #ea872d;
}

.border-l-black-5-1 {
  border-left: 5.1px solid #020202;
}

.border-l-light-gray-5-1 {
  border-left: 5.1px solid #fafafa;
}

.border-l-deep-green-5-1 {
  border-left: 5.1px solid #014345;
}

.border-r-primary-5-1 {
  border-right: 5.1px solid #cccccc;
}

.border-r-secondary-5-1 {
  border-right: 5.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-1 {
  border-right: 5.1px solid #ff0f00;
}

.border-r-info-5-1 {
  border-right: 5.1px solid #f6c31c;
}

.border-r-white-5-1 {
  border-right: 5.1px solid #f7f8f7;
}

.border-r-green-5-1 {
  border-right: 5.1px solid #0b8376;
}

.border-r-purple-5-1 {
  border-right: 5.1px solid #755ae2;
}

.border-r-orange-5-1 {
  border-right: 5.1px solid #ea872d;
}

.border-r-black-5-1 {
  border-right: 5.1px solid #020202;
}

.border-r-light-gray-5-1 {
  border-right: 5.1px solid #fafafa;
}

.border-r-deep-green-5-1 {
  border-right: 5.1px solid #014345;
}

.border-primary-5-2 {
  border: 5.2px solid #cccccc;
}

.border-secondary-5-2 {
  border: 5.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-2 {
  border: 5.2px solid #ff0f00;
}

.border-info-5-2 {
  border: 5.2px solid #f6c31c;
}

.border-white-5-2 {
  border: 5.2px solid #f7f8f7;
}

.border-green-5-2 {
  border: 5.2px solid #0b8376;
}

.border-purple-5-2 {
  border: 5.2px solid #755ae2;
}

.border-orange-5-2 {
  border: 5.2px solid #ea872d;
}

.border-black-5-2 {
  border: 5.2px solid #020202;
}

.border-light-gray-5-2 {
  border: 5.2px solid #fafafa;
}

.border-deep-green-5-2 {
  border: 5.2px solid #014345;
}

.border-t-primary-5-2 {
  border-top: 5.2px solid #cccccc;
}

.border-t-secondary-5-2 {
  border-top: 5.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-2 {
  border-top: 5.2px solid #ff0f00;
}

.border-t-info-5-2 {
  border-top: 5.2px solid #f6c31c;
}

.border-t-white-5-2 {
  border-top: 5.2px solid #f7f8f7;
}

.border-t-green-5-2 {
  border-top: 5.2px solid #0b8376;
}

.border-t-purple-5-2 {
  border-top: 5.2px solid #755ae2;
}

.border-t-orange-5-2 {
  border-top: 5.2px solid #ea872d;
}

.border-t-black-5-2 {
  border-top: 5.2px solid #020202;
}

.border-t-light-gray-5-2 {
  border-top: 5.2px solid #fafafa;
}

.border-t-deep-green-5-2 {
  border-top: 5.2px solid #014345;
}

.border-b-primary-5-2 {
  border-bottom: 5.2px solid #cccccc;
}

.border-b-secondary-5-2 {
  border-bottom: 5.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-2 {
  border-bottom: 5.2px solid #ff0f00;
}

.border-b-info-5-2 {
  border-bottom: 5.2px solid #f6c31c;
}

.border-b-white-5-2 {
  border-bottom: 5.2px solid #f7f8f7;
}

.border-b-green-5-2 {
  border-bottom: 5.2px solid #0b8376;
}

.border-b-purple-5-2 {
  border-bottom: 5.2px solid #755ae2;
}

.border-b-orange-5-2 {
  border-bottom: 5.2px solid #ea872d;
}

.border-b-black-5-2 {
  border-bottom: 5.2px solid #020202;
}

.border-b-light-gray-5-2 {
  border-bottom: 5.2px solid #fafafa;
}

.border-b-deep-green-5-2 {
  border-bottom: 5.2px solid #014345;
}

.border-l-primary-5-2 {
  border-left: 5.2px solid #cccccc;
}

.border-l-secondary-5-2 {
  border-left: 5.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-2 {
  border-left: 5.2px solid #ff0f00;
}

.border-l-info-5-2 {
  border-left: 5.2px solid #f6c31c;
}

.border-l-white-5-2 {
  border-left: 5.2px solid #f7f8f7;
}

.border-l-green-5-2 {
  border-left: 5.2px solid #0b8376;
}

.border-l-purple-5-2 {
  border-left: 5.2px solid #755ae2;
}

.border-l-orange-5-2 {
  border-left: 5.2px solid #ea872d;
}

.border-l-black-5-2 {
  border-left: 5.2px solid #020202;
}

.border-l-light-gray-5-2 {
  border-left: 5.2px solid #fafafa;
}

.border-l-deep-green-5-2 {
  border-left: 5.2px solid #014345;
}

.border-r-primary-5-2 {
  border-right: 5.2px solid #cccccc;
}

.border-r-secondary-5-2 {
  border-right: 5.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-2 {
  border-right: 5.2px solid #ff0f00;
}

.border-r-info-5-2 {
  border-right: 5.2px solid #f6c31c;
}

.border-r-white-5-2 {
  border-right: 5.2px solid #f7f8f7;
}

.border-r-green-5-2 {
  border-right: 5.2px solid #0b8376;
}

.border-r-purple-5-2 {
  border-right: 5.2px solid #755ae2;
}

.border-r-orange-5-2 {
  border-right: 5.2px solid #ea872d;
}

.border-r-black-5-2 {
  border-right: 5.2px solid #020202;
}

.border-r-light-gray-5-2 {
  border-right: 5.2px solid #fafafa;
}

.border-r-deep-green-5-2 {
  border-right: 5.2px solid #014345;
}

.border-primary-5-3 {
  border: 5.3px solid #cccccc;
}

.border-secondary-5-3 {
  border: 5.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-3 {
  border: 5.3px solid #ff0f00;
}

.border-info-5-3 {
  border: 5.3px solid #f6c31c;
}

.border-white-5-3 {
  border: 5.3px solid #f7f8f7;
}

.border-green-5-3 {
  border: 5.3px solid #0b8376;
}

.border-purple-5-3 {
  border: 5.3px solid #755ae2;
}

.border-orange-5-3 {
  border: 5.3px solid #ea872d;
}

.border-black-5-3 {
  border: 5.3px solid #020202;
}

.border-light-gray-5-3 {
  border: 5.3px solid #fafafa;
}

.border-deep-green-5-3 {
  border: 5.3px solid #014345;
}

.border-t-primary-5-3 {
  border-top: 5.3px solid #cccccc;
}

.border-t-secondary-5-3 {
  border-top: 5.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-3 {
  border-top: 5.3px solid #ff0f00;
}

.border-t-info-5-3 {
  border-top: 5.3px solid #f6c31c;
}

.border-t-white-5-3 {
  border-top: 5.3px solid #f7f8f7;
}

.border-t-green-5-3 {
  border-top: 5.3px solid #0b8376;
}

.border-t-purple-5-3 {
  border-top: 5.3px solid #755ae2;
}

.border-t-orange-5-3 {
  border-top: 5.3px solid #ea872d;
}

.border-t-black-5-3 {
  border-top: 5.3px solid #020202;
}

.border-t-light-gray-5-3 {
  border-top: 5.3px solid #fafafa;
}

.border-t-deep-green-5-3 {
  border-top: 5.3px solid #014345;
}

.border-b-primary-5-3 {
  border-bottom: 5.3px solid #cccccc;
}

.border-b-secondary-5-3 {
  border-bottom: 5.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-3 {
  border-bottom: 5.3px solid #ff0f00;
}

.border-b-info-5-3 {
  border-bottom: 5.3px solid #f6c31c;
}

.border-b-white-5-3 {
  border-bottom: 5.3px solid #f7f8f7;
}

.border-b-green-5-3 {
  border-bottom: 5.3px solid #0b8376;
}

.border-b-purple-5-3 {
  border-bottom: 5.3px solid #755ae2;
}

.border-b-orange-5-3 {
  border-bottom: 5.3px solid #ea872d;
}

.border-b-black-5-3 {
  border-bottom: 5.3px solid #020202;
}

.border-b-light-gray-5-3 {
  border-bottom: 5.3px solid #fafafa;
}

.border-b-deep-green-5-3 {
  border-bottom: 5.3px solid #014345;
}

.border-l-primary-5-3 {
  border-left: 5.3px solid #cccccc;
}

.border-l-secondary-5-3 {
  border-left: 5.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-3 {
  border-left: 5.3px solid #ff0f00;
}

.border-l-info-5-3 {
  border-left: 5.3px solid #f6c31c;
}

.border-l-white-5-3 {
  border-left: 5.3px solid #f7f8f7;
}

.border-l-green-5-3 {
  border-left: 5.3px solid #0b8376;
}

.border-l-purple-5-3 {
  border-left: 5.3px solid #755ae2;
}

.border-l-orange-5-3 {
  border-left: 5.3px solid #ea872d;
}

.border-l-black-5-3 {
  border-left: 5.3px solid #020202;
}

.border-l-light-gray-5-3 {
  border-left: 5.3px solid #fafafa;
}

.border-l-deep-green-5-3 {
  border-left: 5.3px solid #014345;
}

.border-r-primary-5-3 {
  border-right: 5.3px solid #cccccc;
}

.border-r-secondary-5-3 {
  border-right: 5.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-3 {
  border-right: 5.3px solid #ff0f00;
}

.border-r-info-5-3 {
  border-right: 5.3px solid #f6c31c;
}

.border-r-white-5-3 {
  border-right: 5.3px solid #f7f8f7;
}

.border-r-green-5-3 {
  border-right: 5.3px solid #0b8376;
}

.border-r-purple-5-3 {
  border-right: 5.3px solid #755ae2;
}

.border-r-orange-5-3 {
  border-right: 5.3px solid #ea872d;
}

.border-r-black-5-3 {
  border-right: 5.3px solid #020202;
}

.border-r-light-gray-5-3 {
  border-right: 5.3px solid #fafafa;
}

.border-r-deep-green-5-3 {
  border-right: 5.3px solid #014345;
}

.border-primary-5-4 {
  border: 5.4px solid #cccccc;
}

.border-secondary-5-4 {
  border: 5.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-4 {
  border: 5.4px solid #ff0f00;
}

.border-info-5-4 {
  border: 5.4px solid #f6c31c;
}

.border-white-5-4 {
  border: 5.4px solid #f7f8f7;
}

.border-green-5-4 {
  border: 5.4px solid #0b8376;
}

.border-purple-5-4 {
  border: 5.4px solid #755ae2;
}

.border-orange-5-4 {
  border: 5.4px solid #ea872d;
}

.border-black-5-4 {
  border: 5.4px solid #020202;
}

.border-light-gray-5-4 {
  border: 5.4px solid #fafafa;
}

.border-deep-green-5-4 {
  border: 5.4px solid #014345;
}

.border-t-primary-5-4 {
  border-top: 5.4px solid #cccccc;
}

.border-t-secondary-5-4 {
  border-top: 5.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-4 {
  border-top: 5.4px solid #ff0f00;
}

.border-t-info-5-4 {
  border-top: 5.4px solid #f6c31c;
}

.border-t-white-5-4 {
  border-top: 5.4px solid #f7f8f7;
}

.border-t-green-5-4 {
  border-top: 5.4px solid #0b8376;
}

.border-t-purple-5-4 {
  border-top: 5.4px solid #755ae2;
}

.border-t-orange-5-4 {
  border-top: 5.4px solid #ea872d;
}

.border-t-black-5-4 {
  border-top: 5.4px solid #020202;
}

.border-t-light-gray-5-4 {
  border-top: 5.4px solid #fafafa;
}

.border-t-deep-green-5-4 {
  border-top: 5.4px solid #014345;
}

.border-b-primary-5-4 {
  border-bottom: 5.4px solid #cccccc;
}

.border-b-secondary-5-4 {
  border-bottom: 5.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-4 {
  border-bottom: 5.4px solid #ff0f00;
}

.border-b-info-5-4 {
  border-bottom: 5.4px solid #f6c31c;
}

.border-b-white-5-4 {
  border-bottom: 5.4px solid #f7f8f7;
}

.border-b-green-5-4 {
  border-bottom: 5.4px solid #0b8376;
}

.border-b-purple-5-4 {
  border-bottom: 5.4px solid #755ae2;
}

.border-b-orange-5-4 {
  border-bottom: 5.4px solid #ea872d;
}

.border-b-black-5-4 {
  border-bottom: 5.4px solid #020202;
}

.border-b-light-gray-5-4 {
  border-bottom: 5.4px solid #fafafa;
}

.border-b-deep-green-5-4 {
  border-bottom: 5.4px solid #014345;
}

.border-l-primary-5-4 {
  border-left: 5.4px solid #cccccc;
}

.border-l-secondary-5-4 {
  border-left: 5.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-4 {
  border-left: 5.4px solid #ff0f00;
}

.border-l-info-5-4 {
  border-left: 5.4px solid #f6c31c;
}

.border-l-white-5-4 {
  border-left: 5.4px solid #f7f8f7;
}

.border-l-green-5-4 {
  border-left: 5.4px solid #0b8376;
}

.border-l-purple-5-4 {
  border-left: 5.4px solid #755ae2;
}

.border-l-orange-5-4 {
  border-left: 5.4px solid #ea872d;
}

.border-l-black-5-4 {
  border-left: 5.4px solid #020202;
}

.border-l-light-gray-5-4 {
  border-left: 5.4px solid #fafafa;
}

.border-l-deep-green-5-4 {
  border-left: 5.4px solid #014345;
}

.border-r-primary-5-4 {
  border-right: 5.4px solid #cccccc;
}

.border-r-secondary-5-4 {
  border-right: 5.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-4 {
  border-right: 5.4px solid #ff0f00;
}

.border-r-info-5-4 {
  border-right: 5.4px solid #f6c31c;
}

.border-r-white-5-4 {
  border-right: 5.4px solid #f7f8f7;
}

.border-r-green-5-4 {
  border-right: 5.4px solid #0b8376;
}

.border-r-purple-5-4 {
  border-right: 5.4px solid #755ae2;
}

.border-r-orange-5-4 {
  border-right: 5.4px solid #ea872d;
}

.border-r-black-5-4 {
  border-right: 5.4px solid #020202;
}

.border-r-light-gray-5-4 {
  border-right: 5.4px solid #fafafa;
}

.border-r-deep-green-5-4 {
  border-right: 5.4px solid #014345;
}

.border-primary-5-5 {
  border: 5.5px solid #cccccc;
}

.border-secondary-5-5 {
  border: 5.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-5 {
  border: 5.5px solid #ff0f00;
}

.border-info-5-5 {
  border: 5.5px solid #f6c31c;
}

.border-white-5-5 {
  border: 5.5px solid #f7f8f7;
}

.border-green-5-5 {
  border: 5.5px solid #0b8376;
}

.border-purple-5-5 {
  border: 5.5px solid #755ae2;
}

.border-orange-5-5 {
  border: 5.5px solid #ea872d;
}

.border-black-5-5 {
  border: 5.5px solid #020202;
}

.border-light-gray-5-5 {
  border: 5.5px solid #fafafa;
}

.border-deep-green-5-5 {
  border: 5.5px solid #014345;
}

.border-t-primary-5-5 {
  border-top: 5.5px solid #cccccc;
}

.border-t-secondary-5-5 {
  border-top: 5.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-5 {
  border-top: 5.5px solid #ff0f00;
}

.border-t-info-5-5 {
  border-top: 5.5px solid #f6c31c;
}

.border-t-white-5-5 {
  border-top: 5.5px solid #f7f8f7;
}

.border-t-green-5-5 {
  border-top: 5.5px solid #0b8376;
}

.border-t-purple-5-5 {
  border-top: 5.5px solid #755ae2;
}

.border-t-orange-5-5 {
  border-top: 5.5px solid #ea872d;
}

.border-t-black-5-5 {
  border-top: 5.5px solid #020202;
}

.border-t-light-gray-5-5 {
  border-top: 5.5px solid #fafafa;
}

.border-t-deep-green-5-5 {
  border-top: 5.5px solid #014345;
}

.border-b-primary-5-5 {
  border-bottom: 5.5px solid #cccccc;
}

.border-b-secondary-5-5 {
  border-bottom: 5.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-5 {
  border-bottom: 5.5px solid #ff0f00;
}

.border-b-info-5-5 {
  border-bottom: 5.5px solid #f6c31c;
}

.border-b-white-5-5 {
  border-bottom: 5.5px solid #f7f8f7;
}

.border-b-green-5-5 {
  border-bottom: 5.5px solid #0b8376;
}

.border-b-purple-5-5 {
  border-bottom: 5.5px solid #755ae2;
}

.border-b-orange-5-5 {
  border-bottom: 5.5px solid #ea872d;
}

.border-b-black-5-5 {
  border-bottom: 5.5px solid #020202;
}

.border-b-light-gray-5-5 {
  border-bottom: 5.5px solid #fafafa;
}

.border-b-deep-green-5-5 {
  border-bottom: 5.5px solid #014345;
}

.border-l-primary-5-5 {
  border-left: 5.5px solid #cccccc;
}

.border-l-secondary-5-5 {
  border-left: 5.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-5 {
  border-left: 5.5px solid #ff0f00;
}

.border-l-info-5-5 {
  border-left: 5.5px solid #f6c31c;
}

.border-l-white-5-5 {
  border-left: 5.5px solid #f7f8f7;
}

.border-l-green-5-5 {
  border-left: 5.5px solid #0b8376;
}

.border-l-purple-5-5 {
  border-left: 5.5px solid #755ae2;
}

.border-l-orange-5-5 {
  border-left: 5.5px solid #ea872d;
}

.border-l-black-5-5 {
  border-left: 5.5px solid #020202;
}

.border-l-light-gray-5-5 {
  border-left: 5.5px solid #fafafa;
}

.border-l-deep-green-5-5 {
  border-left: 5.5px solid #014345;
}

.border-r-primary-5-5 {
  border-right: 5.5px solid #cccccc;
}

.border-r-secondary-5-5 {
  border-right: 5.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-5 {
  border-right: 5.5px solid #ff0f00;
}

.border-r-info-5-5 {
  border-right: 5.5px solid #f6c31c;
}

.border-r-white-5-5 {
  border-right: 5.5px solid #f7f8f7;
}

.border-r-green-5-5 {
  border-right: 5.5px solid #0b8376;
}

.border-r-purple-5-5 {
  border-right: 5.5px solid #755ae2;
}

.border-r-orange-5-5 {
  border-right: 5.5px solid #ea872d;
}

.border-r-black-5-5 {
  border-right: 5.5px solid #020202;
}

.border-r-light-gray-5-5 {
  border-right: 5.5px solid #fafafa;
}

.border-r-deep-green-5-5 {
  border-right: 5.5px solid #014345;
}

.border-primary-5-6 {
  border: 5.6px solid #cccccc;
}

.border-secondary-5-6 {
  border: 5.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-6 {
  border: 5.6px solid #ff0f00;
}

.border-info-5-6 {
  border: 5.6px solid #f6c31c;
}

.border-white-5-6 {
  border: 5.6px solid #f7f8f7;
}

.border-green-5-6 {
  border: 5.6px solid #0b8376;
}

.border-purple-5-6 {
  border: 5.6px solid #755ae2;
}

.border-orange-5-6 {
  border: 5.6px solid #ea872d;
}

.border-black-5-6 {
  border: 5.6px solid #020202;
}

.border-light-gray-5-6 {
  border: 5.6px solid #fafafa;
}

.border-deep-green-5-6 {
  border: 5.6px solid #014345;
}

.border-t-primary-5-6 {
  border-top: 5.6px solid #cccccc;
}

.border-t-secondary-5-6 {
  border-top: 5.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-6 {
  border-top: 5.6px solid #ff0f00;
}

.border-t-info-5-6 {
  border-top: 5.6px solid #f6c31c;
}

.border-t-white-5-6 {
  border-top: 5.6px solid #f7f8f7;
}

.border-t-green-5-6 {
  border-top: 5.6px solid #0b8376;
}

.border-t-purple-5-6 {
  border-top: 5.6px solid #755ae2;
}

.border-t-orange-5-6 {
  border-top: 5.6px solid #ea872d;
}

.border-t-black-5-6 {
  border-top: 5.6px solid #020202;
}

.border-t-light-gray-5-6 {
  border-top: 5.6px solid #fafafa;
}

.border-t-deep-green-5-6 {
  border-top: 5.6px solid #014345;
}

.border-b-primary-5-6 {
  border-bottom: 5.6px solid #cccccc;
}

.border-b-secondary-5-6 {
  border-bottom: 5.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-6 {
  border-bottom: 5.6px solid #ff0f00;
}

.border-b-info-5-6 {
  border-bottom: 5.6px solid #f6c31c;
}

.border-b-white-5-6 {
  border-bottom: 5.6px solid #f7f8f7;
}

.border-b-green-5-6 {
  border-bottom: 5.6px solid #0b8376;
}

.border-b-purple-5-6 {
  border-bottom: 5.6px solid #755ae2;
}

.border-b-orange-5-6 {
  border-bottom: 5.6px solid #ea872d;
}

.border-b-black-5-6 {
  border-bottom: 5.6px solid #020202;
}

.border-b-light-gray-5-6 {
  border-bottom: 5.6px solid #fafafa;
}

.border-b-deep-green-5-6 {
  border-bottom: 5.6px solid #014345;
}

.border-l-primary-5-6 {
  border-left: 5.6px solid #cccccc;
}

.border-l-secondary-5-6 {
  border-left: 5.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-6 {
  border-left: 5.6px solid #ff0f00;
}

.border-l-info-5-6 {
  border-left: 5.6px solid #f6c31c;
}

.border-l-white-5-6 {
  border-left: 5.6px solid #f7f8f7;
}

.border-l-green-5-6 {
  border-left: 5.6px solid #0b8376;
}

.border-l-purple-5-6 {
  border-left: 5.6px solid #755ae2;
}

.border-l-orange-5-6 {
  border-left: 5.6px solid #ea872d;
}

.border-l-black-5-6 {
  border-left: 5.6px solid #020202;
}

.border-l-light-gray-5-6 {
  border-left: 5.6px solid #fafafa;
}

.border-l-deep-green-5-6 {
  border-left: 5.6px solid #014345;
}

.border-r-primary-5-6 {
  border-right: 5.6px solid #cccccc;
}

.border-r-secondary-5-6 {
  border-right: 5.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-6 {
  border-right: 5.6px solid #ff0f00;
}

.border-r-info-5-6 {
  border-right: 5.6px solid #f6c31c;
}

.border-r-white-5-6 {
  border-right: 5.6px solid #f7f8f7;
}

.border-r-green-5-6 {
  border-right: 5.6px solid #0b8376;
}

.border-r-purple-5-6 {
  border-right: 5.6px solid #755ae2;
}

.border-r-orange-5-6 {
  border-right: 5.6px solid #ea872d;
}

.border-r-black-5-6 {
  border-right: 5.6px solid #020202;
}

.border-r-light-gray-5-6 {
  border-right: 5.6px solid #fafafa;
}

.border-r-deep-green-5-6 {
  border-right: 5.6px solid #014345;
}

.border-primary-5-7 {
  border: 5.7px solid #cccccc;
}

.border-secondary-5-7 {
  border: 5.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-7 {
  border: 5.7px solid #ff0f00;
}

.border-info-5-7 {
  border: 5.7px solid #f6c31c;
}

.border-white-5-7 {
  border: 5.7px solid #f7f8f7;
}

.border-green-5-7 {
  border: 5.7px solid #0b8376;
}

.border-purple-5-7 {
  border: 5.7px solid #755ae2;
}

.border-orange-5-7 {
  border: 5.7px solid #ea872d;
}

.border-black-5-7 {
  border: 5.7px solid #020202;
}

.border-light-gray-5-7 {
  border: 5.7px solid #fafafa;
}

.border-deep-green-5-7 {
  border: 5.7px solid #014345;
}

.border-t-primary-5-7 {
  border-top: 5.7px solid #cccccc;
}

.border-t-secondary-5-7 {
  border-top: 5.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-7 {
  border-top: 5.7px solid #ff0f00;
}

.border-t-info-5-7 {
  border-top: 5.7px solid #f6c31c;
}

.border-t-white-5-7 {
  border-top: 5.7px solid #f7f8f7;
}

.border-t-green-5-7 {
  border-top: 5.7px solid #0b8376;
}

.border-t-purple-5-7 {
  border-top: 5.7px solid #755ae2;
}

.border-t-orange-5-7 {
  border-top: 5.7px solid #ea872d;
}

.border-t-black-5-7 {
  border-top: 5.7px solid #020202;
}

.border-t-light-gray-5-7 {
  border-top: 5.7px solid #fafafa;
}

.border-t-deep-green-5-7 {
  border-top: 5.7px solid #014345;
}

.border-b-primary-5-7 {
  border-bottom: 5.7px solid #cccccc;
}

.border-b-secondary-5-7 {
  border-bottom: 5.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-7 {
  border-bottom: 5.7px solid #ff0f00;
}

.border-b-info-5-7 {
  border-bottom: 5.7px solid #f6c31c;
}

.border-b-white-5-7 {
  border-bottom: 5.7px solid #f7f8f7;
}

.border-b-green-5-7 {
  border-bottom: 5.7px solid #0b8376;
}

.border-b-purple-5-7 {
  border-bottom: 5.7px solid #755ae2;
}

.border-b-orange-5-7 {
  border-bottom: 5.7px solid #ea872d;
}

.border-b-black-5-7 {
  border-bottom: 5.7px solid #020202;
}

.border-b-light-gray-5-7 {
  border-bottom: 5.7px solid #fafafa;
}

.border-b-deep-green-5-7 {
  border-bottom: 5.7px solid #014345;
}

.border-l-primary-5-7 {
  border-left: 5.7px solid #cccccc;
}

.border-l-secondary-5-7 {
  border-left: 5.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-7 {
  border-left: 5.7px solid #ff0f00;
}

.border-l-info-5-7 {
  border-left: 5.7px solid #f6c31c;
}

.border-l-white-5-7 {
  border-left: 5.7px solid #f7f8f7;
}

.border-l-green-5-7 {
  border-left: 5.7px solid #0b8376;
}

.border-l-purple-5-7 {
  border-left: 5.7px solid #755ae2;
}

.border-l-orange-5-7 {
  border-left: 5.7px solid #ea872d;
}

.border-l-black-5-7 {
  border-left: 5.7px solid #020202;
}

.border-l-light-gray-5-7 {
  border-left: 5.7px solid #fafafa;
}

.border-l-deep-green-5-7 {
  border-left: 5.7px solid #014345;
}

.border-r-primary-5-7 {
  border-right: 5.7px solid #cccccc;
}

.border-r-secondary-5-7 {
  border-right: 5.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-7 {
  border-right: 5.7px solid #ff0f00;
}

.border-r-info-5-7 {
  border-right: 5.7px solid #f6c31c;
}

.border-r-white-5-7 {
  border-right: 5.7px solid #f7f8f7;
}

.border-r-green-5-7 {
  border-right: 5.7px solid #0b8376;
}

.border-r-purple-5-7 {
  border-right: 5.7px solid #755ae2;
}

.border-r-orange-5-7 {
  border-right: 5.7px solid #ea872d;
}

.border-r-black-5-7 {
  border-right: 5.7px solid #020202;
}

.border-r-light-gray-5-7 {
  border-right: 5.7px solid #fafafa;
}

.border-r-deep-green-5-7 {
  border-right: 5.7px solid #014345;
}

.border-primary-5-8 {
  border: 5.8px solid #cccccc;
}

.border-secondary-5-8 {
  border: 5.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-8 {
  border: 5.8px solid #ff0f00;
}

.border-info-5-8 {
  border: 5.8px solid #f6c31c;
}

.border-white-5-8 {
  border: 5.8px solid #f7f8f7;
}

.border-green-5-8 {
  border: 5.8px solid #0b8376;
}

.border-purple-5-8 {
  border: 5.8px solid #755ae2;
}

.border-orange-5-8 {
  border: 5.8px solid #ea872d;
}

.border-black-5-8 {
  border: 5.8px solid #020202;
}

.border-light-gray-5-8 {
  border: 5.8px solid #fafafa;
}

.border-deep-green-5-8 {
  border: 5.8px solid #014345;
}

.border-t-primary-5-8 {
  border-top: 5.8px solid #cccccc;
}

.border-t-secondary-5-8 {
  border-top: 5.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-8 {
  border-top: 5.8px solid #ff0f00;
}

.border-t-info-5-8 {
  border-top: 5.8px solid #f6c31c;
}

.border-t-white-5-8 {
  border-top: 5.8px solid #f7f8f7;
}

.border-t-green-5-8 {
  border-top: 5.8px solid #0b8376;
}

.border-t-purple-5-8 {
  border-top: 5.8px solid #755ae2;
}

.border-t-orange-5-8 {
  border-top: 5.8px solid #ea872d;
}

.border-t-black-5-8 {
  border-top: 5.8px solid #020202;
}

.border-t-light-gray-5-8 {
  border-top: 5.8px solid #fafafa;
}

.border-t-deep-green-5-8 {
  border-top: 5.8px solid #014345;
}

.border-b-primary-5-8 {
  border-bottom: 5.8px solid #cccccc;
}

.border-b-secondary-5-8 {
  border-bottom: 5.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-8 {
  border-bottom: 5.8px solid #ff0f00;
}

.border-b-info-5-8 {
  border-bottom: 5.8px solid #f6c31c;
}

.border-b-white-5-8 {
  border-bottom: 5.8px solid #f7f8f7;
}

.border-b-green-5-8 {
  border-bottom: 5.8px solid #0b8376;
}

.border-b-purple-5-8 {
  border-bottom: 5.8px solid #755ae2;
}

.border-b-orange-5-8 {
  border-bottom: 5.8px solid #ea872d;
}

.border-b-black-5-8 {
  border-bottom: 5.8px solid #020202;
}

.border-b-light-gray-5-8 {
  border-bottom: 5.8px solid #fafafa;
}

.border-b-deep-green-5-8 {
  border-bottom: 5.8px solid #014345;
}

.border-l-primary-5-8 {
  border-left: 5.8px solid #cccccc;
}

.border-l-secondary-5-8 {
  border-left: 5.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-8 {
  border-left: 5.8px solid #ff0f00;
}

.border-l-info-5-8 {
  border-left: 5.8px solid #f6c31c;
}

.border-l-white-5-8 {
  border-left: 5.8px solid #f7f8f7;
}

.border-l-green-5-8 {
  border-left: 5.8px solid #0b8376;
}

.border-l-purple-5-8 {
  border-left: 5.8px solid #755ae2;
}

.border-l-orange-5-8 {
  border-left: 5.8px solid #ea872d;
}

.border-l-black-5-8 {
  border-left: 5.8px solid #020202;
}

.border-l-light-gray-5-8 {
  border-left: 5.8px solid #fafafa;
}

.border-l-deep-green-5-8 {
  border-left: 5.8px solid #014345;
}

.border-r-primary-5-8 {
  border-right: 5.8px solid #cccccc;
}

.border-r-secondary-5-8 {
  border-right: 5.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-8 {
  border-right: 5.8px solid #ff0f00;
}

.border-r-info-5-8 {
  border-right: 5.8px solid #f6c31c;
}

.border-r-white-5-8 {
  border-right: 5.8px solid #f7f8f7;
}

.border-r-green-5-8 {
  border-right: 5.8px solid #0b8376;
}

.border-r-purple-5-8 {
  border-right: 5.8px solid #755ae2;
}

.border-r-orange-5-8 {
  border-right: 5.8px solid #ea872d;
}

.border-r-black-5-8 {
  border-right: 5.8px solid #020202;
}

.border-r-light-gray-5-8 {
  border-right: 5.8px solid #fafafa;
}

.border-r-deep-green-5-8 {
  border-right: 5.8px solid #014345;
}

.border-primary-5-9 {
  border: 5.9px solid #cccccc;
}

.border-secondary-5-9 {
  border: 5.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-5-9 {
  border: 5.9px solid #ff0f00;
}

.border-info-5-9 {
  border: 5.9px solid #f6c31c;
}

.border-white-5-9 {
  border: 5.9px solid #f7f8f7;
}

.border-green-5-9 {
  border: 5.9px solid #0b8376;
}

.border-purple-5-9 {
  border: 5.9px solid #755ae2;
}

.border-orange-5-9 {
  border: 5.9px solid #ea872d;
}

.border-black-5-9 {
  border: 5.9px solid #020202;
}

.border-light-gray-5-9 {
  border: 5.9px solid #fafafa;
}

.border-deep-green-5-9 {
  border: 5.9px solid #014345;
}

.border-t-primary-5-9 {
  border-top: 5.9px solid #cccccc;
}

.border-t-secondary-5-9 {
  border-top: 5.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-5-9 {
  border-top: 5.9px solid #ff0f00;
}

.border-t-info-5-9 {
  border-top: 5.9px solid #f6c31c;
}

.border-t-white-5-9 {
  border-top: 5.9px solid #f7f8f7;
}

.border-t-green-5-9 {
  border-top: 5.9px solid #0b8376;
}

.border-t-purple-5-9 {
  border-top: 5.9px solid #755ae2;
}

.border-t-orange-5-9 {
  border-top: 5.9px solid #ea872d;
}

.border-t-black-5-9 {
  border-top: 5.9px solid #020202;
}

.border-t-light-gray-5-9 {
  border-top: 5.9px solid #fafafa;
}

.border-t-deep-green-5-9 {
  border-top: 5.9px solid #014345;
}

.border-b-primary-5-9 {
  border-bottom: 5.9px solid #cccccc;
}

.border-b-secondary-5-9 {
  border-bottom: 5.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-5-9 {
  border-bottom: 5.9px solid #ff0f00;
}

.border-b-info-5-9 {
  border-bottom: 5.9px solid #f6c31c;
}

.border-b-white-5-9 {
  border-bottom: 5.9px solid #f7f8f7;
}

.border-b-green-5-9 {
  border-bottom: 5.9px solid #0b8376;
}

.border-b-purple-5-9 {
  border-bottom: 5.9px solid #755ae2;
}

.border-b-orange-5-9 {
  border-bottom: 5.9px solid #ea872d;
}

.border-b-black-5-9 {
  border-bottom: 5.9px solid #020202;
}

.border-b-light-gray-5-9 {
  border-bottom: 5.9px solid #fafafa;
}

.border-b-deep-green-5-9 {
  border-bottom: 5.9px solid #014345;
}

.border-l-primary-5-9 {
  border-left: 5.9px solid #cccccc;
}

.border-l-secondary-5-9 {
  border-left: 5.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-5-9 {
  border-left: 5.9px solid #ff0f00;
}

.border-l-info-5-9 {
  border-left: 5.9px solid #f6c31c;
}

.border-l-white-5-9 {
  border-left: 5.9px solid #f7f8f7;
}

.border-l-green-5-9 {
  border-left: 5.9px solid #0b8376;
}

.border-l-purple-5-9 {
  border-left: 5.9px solid #755ae2;
}

.border-l-orange-5-9 {
  border-left: 5.9px solid #ea872d;
}

.border-l-black-5-9 {
  border-left: 5.9px solid #020202;
}

.border-l-light-gray-5-9 {
  border-left: 5.9px solid #fafafa;
}

.border-l-deep-green-5-9 {
  border-left: 5.9px solid #014345;
}

.border-r-primary-5-9 {
  border-right: 5.9px solid #cccccc;
}

.border-r-secondary-5-9 {
  border-right: 5.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-5-9 {
  border-right: 5.9px solid #ff0f00;
}

.border-r-info-5-9 {
  border-right: 5.9px solid #f6c31c;
}

.border-r-white-5-9 {
  border-right: 5.9px solid #f7f8f7;
}

.border-r-green-5-9 {
  border-right: 5.9px solid #0b8376;
}

.border-r-purple-5-9 {
  border-right: 5.9px solid #755ae2;
}

.border-r-orange-5-9 {
  border-right: 5.9px solid #ea872d;
}

.border-r-black-5-9 {
  border-right: 5.9px solid #020202;
}

.border-r-light-gray-5-9 {
  border-right: 5.9px solid #fafafa;
}

.border-r-deep-green-5-9 {
  border-right: 5.9px solid #014345;
}

.border-primary-6 {
  border: 6px solid #cccccc;
}

.border-secondary-6 {
  border: 6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6 {
  border: 6px solid #ff0f00;
}

.border-info-6 {
  border: 6px solid #f6c31c;
}

.border-white-6 {
  border: 6px solid #f7f8f7;
}

.border-green-6 {
  border: 6px solid #0b8376;
}

.border-purple-6 {
  border: 6px solid #755ae2;
}

.border-orange-6 {
  border: 6px solid #ea872d;
}

.border-black-6 {
  border: 6px solid #020202;
}

.border-light-gray-6 {
  border: 6px solid #fafafa;
}

.border-deep-green-6 {
  border: 6px solid #014345;
}

.border-t-primary-6 {
  border-top: 6px solid #cccccc;
}

.border-t-secondary-6 {
  border-top: 6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6 {
  border-top: 6px solid #ff0f00;
}

.border-t-info-6 {
  border-top: 6px solid #f6c31c;
}

.border-t-white-6 {
  border-top: 6px solid #f7f8f7;
}

.border-t-green-6 {
  border-top: 6px solid #0b8376;
}

.border-t-purple-6 {
  border-top: 6px solid #755ae2;
}

.border-t-orange-6 {
  border-top: 6px solid #ea872d;
}

.border-t-black-6 {
  border-top: 6px solid #020202;
}

.border-t-light-gray-6 {
  border-top: 6px solid #fafafa;
}

.border-t-deep-green-6 {
  border-top: 6px solid #014345;
}

.border-b-primary-6 {
  border-bottom: 6px solid #cccccc;
}

.border-b-secondary-6 {
  border-bottom: 6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6 {
  border-bottom: 6px solid #ff0f00;
}

.border-b-info-6 {
  border-bottom: 6px solid #f6c31c;
}

.border-b-white-6 {
  border-bottom: 6px solid #f7f8f7;
}

.border-b-green-6 {
  border-bottom: 6px solid #0b8376;
}

.border-b-purple-6 {
  border-bottom: 6px solid #755ae2;
}

.border-b-orange-6 {
  border-bottom: 6px solid #ea872d;
}

.border-b-black-6 {
  border-bottom: 6px solid #020202;
}

.border-b-light-gray-6 {
  border-bottom: 6px solid #fafafa;
}

.border-b-deep-green-6 {
  border-bottom: 6px solid #014345;
}

.border_l-primary-6 {
  border-left: 6px solid #cccccc;
}

.border_l-secondary-6 {
  border-left: 6px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-6 {
  border-left: 6px solid #ff0f00;
}

.border_l-info-6 {
  border-left: 6px solid #f6c31c;
}

.border_l-white-6 {
  border-left: 6px solid #f7f8f7;
}

.border_l-green-6 {
  border-left: 6px solid #0b8376;
}

.border_l-purple-6 {
  border-left: 6px solid #755ae2;
}

.border_l-orange-6 {
  border-left: 6px solid #ea872d;
}

.border_l-black-6 {
  border-left: 6px solid #020202;
}

.border_l-light-gray-6 {
  border-left: 6px solid #fafafa;
}

.border_l-deep-green-6 {
  border-left: 6px solid #014345;
}

.border-r-primary-6 {
  border-right: 6px solid #cccccc;
}

.border-r-secondary-6 {
  border-right: 6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6 {
  border-right: 6px solid #ff0f00;
}

.border-r-info-6 {
  border-right: 6px solid #f6c31c;
}

.border-r-white-6 {
  border-right: 6px solid #f7f8f7;
}

.border-r-green-6 {
  border-right: 6px solid #0b8376;
}

.border-r-purple-6 {
  border-right: 6px solid #755ae2;
}

.border-r-orange-6 {
  border-right: 6px solid #ea872d;
}

.border-r-black-6 {
  border-right: 6px solid #020202;
}

.border-r-light-gray-6 {
  border-right: 6px solid #fafafa;
}

.border-r-deep-green-6 {
  border-right: 6px solid #014345;
}

.border-primary-6-1 {
  border: 6.1px solid #cccccc;
}

.border-secondary-6-1 {
  border: 6.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-1 {
  border: 6.1px solid #ff0f00;
}

.border-info-6-1 {
  border: 6.1px solid #f6c31c;
}

.border-white-6-1 {
  border: 6.1px solid #f7f8f7;
}

.border-green-6-1 {
  border: 6.1px solid #0b8376;
}

.border-purple-6-1 {
  border: 6.1px solid #755ae2;
}

.border-orange-6-1 {
  border: 6.1px solid #ea872d;
}

.border-black-6-1 {
  border: 6.1px solid #020202;
}

.border-light-gray-6-1 {
  border: 6.1px solid #fafafa;
}

.border-deep-green-6-1 {
  border: 6.1px solid #014345;
}

.border-t-primary-6-1 {
  border-top: 6.1px solid #cccccc;
}

.border-t-secondary-6-1 {
  border-top: 6.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-1 {
  border-top: 6.1px solid #ff0f00;
}

.border-t-info-6-1 {
  border-top: 6.1px solid #f6c31c;
}

.border-t-white-6-1 {
  border-top: 6.1px solid #f7f8f7;
}

.border-t-green-6-1 {
  border-top: 6.1px solid #0b8376;
}

.border-t-purple-6-1 {
  border-top: 6.1px solid #755ae2;
}

.border-t-orange-6-1 {
  border-top: 6.1px solid #ea872d;
}

.border-t-black-6-1 {
  border-top: 6.1px solid #020202;
}

.border-t-light-gray-6-1 {
  border-top: 6.1px solid #fafafa;
}

.border-t-deep-green-6-1 {
  border-top: 6.1px solid #014345;
}

.border-b-primary-6-1 {
  border-bottom: 6.1px solid #cccccc;
}

.border-b-secondary-6-1 {
  border-bottom: 6.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-1 {
  border-bottom: 6.1px solid #ff0f00;
}

.border-b-info-6-1 {
  border-bottom: 6.1px solid #f6c31c;
}

.border-b-white-6-1 {
  border-bottom: 6.1px solid #f7f8f7;
}

.border-b-green-6-1 {
  border-bottom: 6.1px solid #0b8376;
}

.border-b-purple-6-1 {
  border-bottom: 6.1px solid #755ae2;
}

.border-b-orange-6-1 {
  border-bottom: 6.1px solid #ea872d;
}

.border-b-black-6-1 {
  border-bottom: 6.1px solid #020202;
}

.border-b-light-gray-6-1 {
  border-bottom: 6.1px solid #fafafa;
}

.border-b-deep-green-6-1 {
  border-bottom: 6.1px solid #014345;
}

.border-l-primary-6-1 {
  border-left: 6.1px solid #cccccc;
}

.border-l-secondary-6-1 {
  border-left: 6.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-1 {
  border-left: 6.1px solid #ff0f00;
}

.border-l-info-6-1 {
  border-left: 6.1px solid #f6c31c;
}

.border-l-white-6-1 {
  border-left: 6.1px solid #f7f8f7;
}

.border-l-green-6-1 {
  border-left: 6.1px solid #0b8376;
}

.border-l-purple-6-1 {
  border-left: 6.1px solid #755ae2;
}

.border-l-orange-6-1 {
  border-left: 6.1px solid #ea872d;
}

.border-l-black-6-1 {
  border-left: 6.1px solid #020202;
}

.border-l-light-gray-6-1 {
  border-left: 6.1px solid #fafafa;
}

.border-l-deep-green-6-1 {
  border-left: 6.1px solid #014345;
}

.border-r-primary-6-1 {
  border-right: 6.1px solid #cccccc;
}

.border-r-secondary-6-1 {
  border-right: 6.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-1 {
  border-right: 6.1px solid #ff0f00;
}

.border-r-info-6-1 {
  border-right: 6.1px solid #f6c31c;
}

.border-r-white-6-1 {
  border-right: 6.1px solid #f7f8f7;
}

.border-r-green-6-1 {
  border-right: 6.1px solid #0b8376;
}

.border-r-purple-6-1 {
  border-right: 6.1px solid #755ae2;
}

.border-r-orange-6-1 {
  border-right: 6.1px solid #ea872d;
}

.border-r-black-6-1 {
  border-right: 6.1px solid #020202;
}

.border-r-light-gray-6-1 {
  border-right: 6.1px solid #fafafa;
}

.border-r-deep-green-6-1 {
  border-right: 6.1px solid #014345;
}

.border-primary-6-2 {
  border: 6.2px solid #cccccc;
}

.border-secondary-6-2 {
  border: 6.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-2 {
  border: 6.2px solid #ff0f00;
}

.border-info-6-2 {
  border: 6.2px solid #f6c31c;
}

.border-white-6-2 {
  border: 6.2px solid #f7f8f7;
}

.border-green-6-2 {
  border: 6.2px solid #0b8376;
}

.border-purple-6-2 {
  border: 6.2px solid #755ae2;
}

.border-orange-6-2 {
  border: 6.2px solid #ea872d;
}

.border-black-6-2 {
  border: 6.2px solid #020202;
}

.border-light-gray-6-2 {
  border: 6.2px solid #fafafa;
}

.border-deep-green-6-2 {
  border: 6.2px solid #014345;
}

.border-t-primary-6-2 {
  border-top: 6.2px solid #cccccc;
}

.border-t-secondary-6-2 {
  border-top: 6.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-2 {
  border-top: 6.2px solid #ff0f00;
}

.border-t-info-6-2 {
  border-top: 6.2px solid #f6c31c;
}

.border-t-white-6-2 {
  border-top: 6.2px solid #f7f8f7;
}

.border-t-green-6-2 {
  border-top: 6.2px solid #0b8376;
}

.border-t-purple-6-2 {
  border-top: 6.2px solid #755ae2;
}

.border-t-orange-6-2 {
  border-top: 6.2px solid #ea872d;
}

.border-t-black-6-2 {
  border-top: 6.2px solid #020202;
}

.border-t-light-gray-6-2 {
  border-top: 6.2px solid #fafafa;
}

.border-t-deep-green-6-2 {
  border-top: 6.2px solid #014345;
}

.border-b-primary-6-2 {
  border-bottom: 6.2px solid #cccccc;
}

.border-b-secondary-6-2 {
  border-bottom: 6.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-2 {
  border-bottom: 6.2px solid #ff0f00;
}

.border-b-info-6-2 {
  border-bottom: 6.2px solid #f6c31c;
}

.border-b-white-6-2 {
  border-bottom: 6.2px solid #f7f8f7;
}

.border-b-green-6-2 {
  border-bottom: 6.2px solid #0b8376;
}

.border-b-purple-6-2 {
  border-bottom: 6.2px solid #755ae2;
}

.border-b-orange-6-2 {
  border-bottom: 6.2px solid #ea872d;
}

.border-b-black-6-2 {
  border-bottom: 6.2px solid #020202;
}

.border-b-light-gray-6-2 {
  border-bottom: 6.2px solid #fafafa;
}

.border-b-deep-green-6-2 {
  border-bottom: 6.2px solid #014345;
}

.border-l-primary-6-2 {
  border-left: 6.2px solid #cccccc;
}

.border-l-secondary-6-2 {
  border-left: 6.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-2 {
  border-left: 6.2px solid #ff0f00;
}

.border-l-info-6-2 {
  border-left: 6.2px solid #f6c31c;
}

.border-l-white-6-2 {
  border-left: 6.2px solid #f7f8f7;
}

.border-l-green-6-2 {
  border-left: 6.2px solid #0b8376;
}

.border-l-purple-6-2 {
  border-left: 6.2px solid #755ae2;
}

.border-l-orange-6-2 {
  border-left: 6.2px solid #ea872d;
}

.border-l-black-6-2 {
  border-left: 6.2px solid #020202;
}

.border-l-light-gray-6-2 {
  border-left: 6.2px solid #fafafa;
}

.border-l-deep-green-6-2 {
  border-left: 6.2px solid #014345;
}

.border-r-primary-6-2 {
  border-right: 6.2px solid #cccccc;
}

.border-r-secondary-6-2 {
  border-right: 6.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-2 {
  border-right: 6.2px solid #ff0f00;
}

.border-r-info-6-2 {
  border-right: 6.2px solid #f6c31c;
}

.border-r-white-6-2 {
  border-right: 6.2px solid #f7f8f7;
}

.border-r-green-6-2 {
  border-right: 6.2px solid #0b8376;
}

.border-r-purple-6-2 {
  border-right: 6.2px solid #755ae2;
}

.border-r-orange-6-2 {
  border-right: 6.2px solid #ea872d;
}

.border-r-black-6-2 {
  border-right: 6.2px solid #020202;
}

.border-r-light-gray-6-2 {
  border-right: 6.2px solid #fafafa;
}

.border-r-deep-green-6-2 {
  border-right: 6.2px solid #014345;
}

.border-primary-6-3 {
  border: 6.3px solid #cccccc;
}

.border-secondary-6-3 {
  border: 6.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-3 {
  border: 6.3px solid #ff0f00;
}

.border-info-6-3 {
  border: 6.3px solid #f6c31c;
}

.border-white-6-3 {
  border: 6.3px solid #f7f8f7;
}

.border-green-6-3 {
  border: 6.3px solid #0b8376;
}

.border-purple-6-3 {
  border: 6.3px solid #755ae2;
}

.border-orange-6-3 {
  border: 6.3px solid #ea872d;
}

.border-black-6-3 {
  border: 6.3px solid #020202;
}

.border-light-gray-6-3 {
  border: 6.3px solid #fafafa;
}

.border-deep-green-6-3 {
  border: 6.3px solid #014345;
}

.border-t-primary-6-3 {
  border-top: 6.3px solid #cccccc;
}

.border-t-secondary-6-3 {
  border-top: 6.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-3 {
  border-top: 6.3px solid #ff0f00;
}

.border-t-info-6-3 {
  border-top: 6.3px solid #f6c31c;
}

.border-t-white-6-3 {
  border-top: 6.3px solid #f7f8f7;
}

.border-t-green-6-3 {
  border-top: 6.3px solid #0b8376;
}

.border-t-purple-6-3 {
  border-top: 6.3px solid #755ae2;
}

.border-t-orange-6-3 {
  border-top: 6.3px solid #ea872d;
}

.border-t-black-6-3 {
  border-top: 6.3px solid #020202;
}

.border-t-light-gray-6-3 {
  border-top: 6.3px solid #fafafa;
}

.border-t-deep-green-6-3 {
  border-top: 6.3px solid #014345;
}

.border-b-primary-6-3 {
  border-bottom: 6.3px solid #cccccc;
}

.border-b-secondary-6-3 {
  border-bottom: 6.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-3 {
  border-bottom: 6.3px solid #ff0f00;
}

.border-b-info-6-3 {
  border-bottom: 6.3px solid #f6c31c;
}

.border-b-white-6-3 {
  border-bottom: 6.3px solid #f7f8f7;
}

.border-b-green-6-3 {
  border-bottom: 6.3px solid #0b8376;
}

.border-b-purple-6-3 {
  border-bottom: 6.3px solid #755ae2;
}

.border-b-orange-6-3 {
  border-bottom: 6.3px solid #ea872d;
}

.border-b-black-6-3 {
  border-bottom: 6.3px solid #020202;
}

.border-b-light-gray-6-3 {
  border-bottom: 6.3px solid #fafafa;
}

.border-b-deep-green-6-3 {
  border-bottom: 6.3px solid #014345;
}

.border-l-primary-6-3 {
  border-left: 6.3px solid #cccccc;
}

.border-l-secondary-6-3 {
  border-left: 6.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-3 {
  border-left: 6.3px solid #ff0f00;
}

.border-l-info-6-3 {
  border-left: 6.3px solid #f6c31c;
}

.border-l-white-6-3 {
  border-left: 6.3px solid #f7f8f7;
}

.border-l-green-6-3 {
  border-left: 6.3px solid #0b8376;
}

.border-l-purple-6-3 {
  border-left: 6.3px solid #755ae2;
}

.border-l-orange-6-3 {
  border-left: 6.3px solid #ea872d;
}

.border-l-black-6-3 {
  border-left: 6.3px solid #020202;
}

.border-l-light-gray-6-3 {
  border-left: 6.3px solid #fafafa;
}

.border-l-deep-green-6-3 {
  border-left: 6.3px solid #014345;
}

.border-r-primary-6-3 {
  border-right: 6.3px solid #cccccc;
}

.border-r-secondary-6-3 {
  border-right: 6.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-3 {
  border-right: 6.3px solid #ff0f00;
}

.border-r-info-6-3 {
  border-right: 6.3px solid #f6c31c;
}

.border-r-white-6-3 {
  border-right: 6.3px solid #f7f8f7;
}

.border-r-green-6-3 {
  border-right: 6.3px solid #0b8376;
}

.border-r-purple-6-3 {
  border-right: 6.3px solid #755ae2;
}

.border-r-orange-6-3 {
  border-right: 6.3px solid #ea872d;
}

.border-r-black-6-3 {
  border-right: 6.3px solid #020202;
}

.border-r-light-gray-6-3 {
  border-right: 6.3px solid #fafafa;
}

.border-r-deep-green-6-3 {
  border-right: 6.3px solid #014345;
}

.border-primary-6-4 {
  border: 6.4px solid #cccccc;
}

.border-secondary-6-4 {
  border: 6.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-4 {
  border: 6.4px solid #ff0f00;
}

.border-info-6-4 {
  border: 6.4px solid #f6c31c;
}

.border-white-6-4 {
  border: 6.4px solid #f7f8f7;
}

.border-green-6-4 {
  border: 6.4px solid #0b8376;
}

.border-purple-6-4 {
  border: 6.4px solid #755ae2;
}

.border-orange-6-4 {
  border: 6.4px solid #ea872d;
}

.border-black-6-4 {
  border: 6.4px solid #020202;
}

.border-light-gray-6-4 {
  border: 6.4px solid #fafafa;
}

.border-deep-green-6-4 {
  border: 6.4px solid #014345;
}

.border-t-primary-6-4 {
  border-top: 6.4px solid #cccccc;
}

.border-t-secondary-6-4 {
  border-top: 6.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-4 {
  border-top: 6.4px solid #ff0f00;
}

.border-t-info-6-4 {
  border-top: 6.4px solid #f6c31c;
}

.border-t-white-6-4 {
  border-top: 6.4px solid #f7f8f7;
}

.border-t-green-6-4 {
  border-top: 6.4px solid #0b8376;
}

.border-t-purple-6-4 {
  border-top: 6.4px solid #755ae2;
}

.border-t-orange-6-4 {
  border-top: 6.4px solid #ea872d;
}

.border-t-black-6-4 {
  border-top: 6.4px solid #020202;
}

.border-t-light-gray-6-4 {
  border-top: 6.4px solid #fafafa;
}

.border-t-deep-green-6-4 {
  border-top: 6.4px solid #014345;
}

.border-b-primary-6-4 {
  border-bottom: 6.4px solid #cccccc;
}

.border-b-secondary-6-4 {
  border-bottom: 6.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-4 {
  border-bottom: 6.4px solid #ff0f00;
}

.border-b-info-6-4 {
  border-bottom: 6.4px solid #f6c31c;
}

.border-b-white-6-4 {
  border-bottom: 6.4px solid #f7f8f7;
}

.border-b-green-6-4 {
  border-bottom: 6.4px solid #0b8376;
}

.border-b-purple-6-4 {
  border-bottom: 6.4px solid #755ae2;
}

.border-b-orange-6-4 {
  border-bottom: 6.4px solid #ea872d;
}

.border-b-black-6-4 {
  border-bottom: 6.4px solid #020202;
}

.border-b-light-gray-6-4 {
  border-bottom: 6.4px solid #fafafa;
}

.border-b-deep-green-6-4 {
  border-bottom: 6.4px solid #014345;
}

.border-l-primary-6-4 {
  border-left: 6.4px solid #cccccc;
}

.border-l-secondary-6-4 {
  border-left: 6.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-4 {
  border-left: 6.4px solid #ff0f00;
}

.border-l-info-6-4 {
  border-left: 6.4px solid #f6c31c;
}

.border-l-white-6-4 {
  border-left: 6.4px solid #f7f8f7;
}

.border-l-green-6-4 {
  border-left: 6.4px solid #0b8376;
}

.border-l-purple-6-4 {
  border-left: 6.4px solid #755ae2;
}

.border-l-orange-6-4 {
  border-left: 6.4px solid #ea872d;
}

.border-l-black-6-4 {
  border-left: 6.4px solid #020202;
}

.border-l-light-gray-6-4 {
  border-left: 6.4px solid #fafafa;
}

.border-l-deep-green-6-4 {
  border-left: 6.4px solid #014345;
}

.border-r-primary-6-4 {
  border-right: 6.4px solid #cccccc;
}

.border-r-secondary-6-4 {
  border-right: 6.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-4 {
  border-right: 6.4px solid #ff0f00;
}

.border-r-info-6-4 {
  border-right: 6.4px solid #f6c31c;
}

.border-r-white-6-4 {
  border-right: 6.4px solid #f7f8f7;
}

.border-r-green-6-4 {
  border-right: 6.4px solid #0b8376;
}

.border-r-purple-6-4 {
  border-right: 6.4px solid #755ae2;
}

.border-r-orange-6-4 {
  border-right: 6.4px solid #ea872d;
}

.border-r-black-6-4 {
  border-right: 6.4px solid #020202;
}

.border-r-light-gray-6-4 {
  border-right: 6.4px solid #fafafa;
}

.border-r-deep-green-6-4 {
  border-right: 6.4px solid #014345;
}

.border-primary-6-5 {
  border: 6.5px solid #cccccc;
}

.border-secondary-6-5 {
  border: 6.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-5 {
  border: 6.5px solid #ff0f00;
}

.border-info-6-5 {
  border: 6.5px solid #f6c31c;
}

.border-white-6-5 {
  border: 6.5px solid #f7f8f7;
}

.border-green-6-5 {
  border: 6.5px solid #0b8376;
}

.border-purple-6-5 {
  border: 6.5px solid #755ae2;
}

.border-orange-6-5 {
  border: 6.5px solid #ea872d;
}

.border-black-6-5 {
  border: 6.5px solid #020202;
}

.border-light-gray-6-5 {
  border: 6.5px solid #fafafa;
}

.border-deep-green-6-5 {
  border: 6.5px solid #014345;
}

.border-t-primary-6-5 {
  border-top: 6.5px solid #cccccc;
}

.border-t-secondary-6-5 {
  border-top: 6.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-5 {
  border-top: 6.5px solid #ff0f00;
}

.border-t-info-6-5 {
  border-top: 6.5px solid #f6c31c;
}

.border-t-white-6-5 {
  border-top: 6.5px solid #f7f8f7;
}

.border-t-green-6-5 {
  border-top: 6.5px solid #0b8376;
}

.border-t-purple-6-5 {
  border-top: 6.5px solid #755ae2;
}

.border-t-orange-6-5 {
  border-top: 6.5px solid #ea872d;
}

.border-t-black-6-5 {
  border-top: 6.5px solid #020202;
}

.border-t-light-gray-6-5 {
  border-top: 6.5px solid #fafafa;
}

.border-t-deep-green-6-5 {
  border-top: 6.5px solid #014345;
}

.border-b-primary-6-5 {
  border-bottom: 6.5px solid #cccccc;
}

.border-b-secondary-6-5 {
  border-bottom: 6.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-5 {
  border-bottom: 6.5px solid #ff0f00;
}

.border-b-info-6-5 {
  border-bottom: 6.5px solid #f6c31c;
}

.border-b-white-6-5 {
  border-bottom: 6.5px solid #f7f8f7;
}

.border-b-green-6-5 {
  border-bottom: 6.5px solid #0b8376;
}

.border-b-purple-6-5 {
  border-bottom: 6.5px solid #755ae2;
}

.border-b-orange-6-5 {
  border-bottom: 6.5px solid #ea872d;
}

.border-b-black-6-5 {
  border-bottom: 6.5px solid #020202;
}

.border-b-light-gray-6-5 {
  border-bottom: 6.5px solid #fafafa;
}

.border-b-deep-green-6-5 {
  border-bottom: 6.5px solid #014345;
}

.border-l-primary-6-5 {
  border-left: 6.5px solid #cccccc;
}

.border-l-secondary-6-5 {
  border-left: 6.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-5 {
  border-left: 6.5px solid #ff0f00;
}

.border-l-info-6-5 {
  border-left: 6.5px solid #f6c31c;
}

.border-l-white-6-5 {
  border-left: 6.5px solid #f7f8f7;
}

.border-l-green-6-5 {
  border-left: 6.5px solid #0b8376;
}

.border-l-purple-6-5 {
  border-left: 6.5px solid #755ae2;
}

.border-l-orange-6-5 {
  border-left: 6.5px solid #ea872d;
}

.border-l-black-6-5 {
  border-left: 6.5px solid #020202;
}

.border-l-light-gray-6-5 {
  border-left: 6.5px solid #fafafa;
}

.border-l-deep-green-6-5 {
  border-left: 6.5px solid #014345;
}

.border-r-primary-6-5 {
  border-right: 6.5px solid #cccccc;
}

.border-r-secondary-6-5 {
  border-right: 6.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-5 {
  border-right: 6.5px solid #ff0f00;
}

.border-r-info-6-5 {
  border-right: 6.5px solid #f6c31c;
}

.border-r-white-6-5 {
  border-right: 6.5px solid #f7f8f7;
}

.border-r-green-6-5 {
  border-right: 6.5px solid #0b8376;
}

.border-r-purple-6-5 {
  border-right: 6.5px solid #755ae2;
}

.border-r-orange-6-5 {
  border-right: 6.5px solid #ea872d;
}

.border-r-black-6-5 {
  border-right: 6.5px solid #020202;
}

.border-r-light-gray-6-5 {
  border-right: 6.5px solid #fafafa;
}

.border-r-deep-green-6-5 {
  border-right: 6.5px solid #014345;
}

.border-primary-6-6 {
  border: 6.6px solid #cccccc;
}

.border-secondary-6-6 {
  border: 6.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-6 {
  border: 6.6px solid #ff0f00;
}

.border-info-6-6 {
  border: 6.6px solid #f6c31c;
}

.border-white-6-6 {
  border: 6.6px solid #f7f8f7;
}

.border-green-6-6 {
  border: 6.6px solid #0b8376;
}

.border-purple-6-6 {
  border: 6.6px solid #755ae2;
}

.border-orange-6-6 {
  border: 6.6px solid #ea872d;
}

.border-black-6-6 {
  border: 6.6px solid #020202;
}

.border-light-gray-6-6 {
  border: 6.6px solid #fafafa;
}

.border-deep-green-6-6 {
  border: 6.6px solid #014345;
}

.border-t-primary-6-6 {
  border-top: 6.6px solid #cccccc;
}

.border-t-secondary-6-6 {
  border-top: 6.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-6 {
  border-top: 6.6px solid #ff0f00;
}

.border-t-info-6-6 {
  border-top: 6.6px solid #f6c31c;
}

.border-t-white-6-6 {
  border-top: 6.6px solid #f7f8f7;
}

.border-t-green-6-6 {
  border-top: 6.6px solid #0b8376;
}

.border-t-purple-6-6 {
  border-top: 6.6px solid #755ae2;
}

.border-t-orange-6-6 {
  border-top: 6.6px solid #ea872d;
}

.border-t-black-6-6 {
  border-top: 6.6px solid #020202;
}

.border-t-light-gray-6-6 {
  border-top: 6.6px solid #fafafa;
}

.border-t-deep-green-6-6 {
  border-top: 6.6px solid #014345;
}

.border-b-primary-6-6 {
  border-bottom: 6.6px solid #cccccc;
}

.border-b-secondary-6-6 {
  border-bottom: 6.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-6 {
  border-bottom: 6.6px solid #ff0f00;
}

.border-b-info-6-6 {
  border-bottom: 6.6px solid #f6c31c;
}

.border-b-white-6-6 {
  border-bottom: 6.6px solid #f7f8f7;
}

.border-b-green-6-6 {
  border-bottom: 6.6px solid #0b8376;
}

.border-b-purple-6-6 {
  border-bottom: 6.6px solid #755ae2;
}

.border-b-orange-6-6 {
  border-bottom: 6.6px solid #ea872d;
}

.border-b-black-6-6 {
  border-bottom: 6.6px solid #020202;
}

.border-b-light-gray-6-6 {
  border-bottom: 6.6px solid #fafafa;
}

.border-b-deep-green-6-6 {
  border-bottom: 6.6px solid #014345;
}

.border-l-primary-6-6 {
  border-left: 6.6px solid #cccccc;
}

.border-l-secondary-6-6 {
  border-left: 6.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-6 {
  border-left: 6.6px solid #ff0f00;
}

.border-l-info-6-6 {
  border-left: 6.6px solid #f6c31c;
}

.border-l-white-6-6 {
  border-left: 6.6px solid #f7f8f7;
}

.border-l-green-6-6 {
  border-left: 6.6px solid #0b8376;
}

.border-l-purple-6-6 {
  border-left: 6.6px solid #755ae2;
}

.border-l-orange-6-6 {
  border-left: 6.6px solid #ea872d;
}

.border-l-black-6-6 {
  border-left: 6.6px solid #020202;
}

.border-l-light-gray-6-6 {
  border-left: 6.6px solid #fafafa;
}

.border-l-deep-green-6-6 {
  border-left: 6.6px solid #014345;
}

.border-r-primary-6-6 {
  border-right: 6.6px solid #cccccc;
}

.border-r-secondary-6-6 {
  border-right: 6.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-6 {
  border-right: 6.6px solid #ff0f00;
}

.border-r-info-6-6 {
  border-right: 6.6px solid #f6c31c;
}

.border-r-white-6-6 {
  border-right: 6.6px solid #f7f8f7;
}

.border-r-green-6-6 {
  border-right: 6.6px solid #0b8376;
}

.border-r-purple-6-6 {
  border-right: 6.6px solid #755ae2;
}

.border-r-orange-6-6 {
  border-right: 6.6px solid #ea872d;
}

.border-r-black-6-6 {
  border-right: 6.6px solid #020202;
}

.border-r-light-gray-6-6 {
  border-right: 6.6px solid #fafafa;
}

.border-r-deep-green-6-6 {
  border-right: 6.6px solid #014345;
}

.border-primary-6-7 {
  border: 6.7px solid #cccccc;
}

.border-secondary-6-7 {
  border: 6.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-7 {
  border: 6.7px solid #ff0f00;
}

.border-info-6-7 {
  border: 6.7px solid #f6c31c;
}

.border-white-6-7 {
  border: 6.7px solid #f7f8f7;
}

.border-green-6-7 {
  border: 6.7px solid #0b8376;
}

.border-purple-6-7 {
  border: 6.7px solid #755ae2;
}

.border-orange-6-7 {
  border: 6.7px solid #ea872d;
}

.border-black-6-7 {
  border: 6.7px solid #020202;
}

.border-light-gray-6-7 {
  border: 6.7px solid #fafafa;
}

.border-deep-green-6-7 {
  border: 6.7px solid #014345;
}

.border-t-primary-6-7 {
  border-top: 6.7px solid #cccccc;
}

.border-t-secondary-6-7 {
  border-top: 6.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-7 {
  border-top: 6.7px solid #ff0f00;
}

.border-t-info-6-7 {
  border-top: 6.7px solid #f6c31c;
}

.border-t-white-6-7 {
  border-top: 6.7px solid #f7f8f7;
}

.border-t-green-6-7 {
  border-top: 6.7px solid #0b8376;
}

.border-t-purple-6-7 {
  border-top: 6.7px solid #755ae2;
}

.border-t-orange-6-7 {
  border-top: 6.7px solid #ea872d;
}

.border-t-black-6-7 {
  border-top: 6.7px solid #020202;
}

.border-t-light-gray-6-7 {
  border-top: 6.7px solid #fafafa;
}

.border-t-deep-green-6-7 {
  border-top: 6.7px solid #014345;
}

.border-b-primary-6-7 {
  border-bottom: 6.7px solid #cccccc;
}

.border-b-secondary-6-7 {
  border-bottom: 6.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-7 {
  border-bottom: 6.7px solid #ff0f00;
}

.border-b-info-6-7 {
  border-bottom: 6.7px solid #f6c31c;
}

.border-b-white-6-7 {
  border-bottom: 6.7px solid #f7f8f7;
}

.border-b-green-6-7 {
  border-bottom: 6.7px solid #0b8376;
}

.border-b-purple-6-7 {
  border-bottom: 6.7px solid #755ae2;
}

.border-b-orange-6-7 {
  border-bottom: 6.7px solid #ea872d;
}

.border-b-black-6-7 {
  border-bottom: 6.7px solid #020202;
}

.border-b-light-gray-6-7 {
  border-bottom: 6.7px solid #fafafa;
}

.border-b-deep-green-6-7 {
  border-bottom: 6.7px solid #014345;
}

.border-l-primary-6-7 {
  border-left: 6.7px solid #cccccc;
}

.border-l-secondary-6-7 {
  border-left: 6.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-7 {
  border-left: 6.7px solid #ff0f00;
}

.border-l-info-6-7 {
  border-left: 6.7px solid #f6c31c;
}

.border-l-white-6-7 {
  border-left: 6.7px solid #f7f8f7;
}

.border-l-green-6-7 {
  border-left: 6.7px solid #0b8376;
}

.border-l-purple-6-7 {
  border-left: 6.7px solid #755ae2;
}

.border-l-orange-6-7 {
  border-left: 6.7px solid #ea872d;
}

.border-l-black-6-7 {
  border-left: 6.7px solid #020202;
}

.border-l-light-gray-6-7 {
  border-left: 6.7px solid #fafafa;
}

.border-l-deep-green-6-7 {
  border-left: 6.7px solid #014345;
}

.border-r-primary-6-7 {
  border-right: 6.7px solid #cccccc;
}

.border-r-secondary-6-7 {
  border-right: 6.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-7 {
  border-right: 6.7px solid #ff0f00;
}

.border-r-info-6-7 {
  border-right: 6.7px solid #f6c31c;
}

.border-r-white-6-7 {
  border-right: 6.7px solid #f7f8f7;
}

.border-r-green-6-7 {
  border-right: 6.7px solid #0b8376;
}

.border-r-purple-6-7 {
  border-right: 6.7px solid #755ae2;
}

.border-r-orange-6-7 {
  border-right: 6.7px solid #ea872d;
}

.border-r-black-6-7 {
  border-right: 6.7px solid #020202;
}

.border-r-light-gray-6-7 {
  border-right: 6.7px solid #fafafa;
}

.border-r-deep-green-6-7 {
  border-right: 6.7px solid #014345;
}

.border-primary-6-8 {
  border: 6.8px solid #cccccc;
}

.border-secondary-6-8 {
  border: 6.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-8 {
  border: 6.8px solid #ff0f00;
}

.border-info-6-8 {
  border: 6.8px solid #f6c31c;
}

.border-white-6-8 {
  border: 6.8px solid #f7f8f7;
}

.border-green-6-8 {
  border: 6.8px solid #0b8376;
}

.border-purple-6-8 {
  border: 6.8px solid #755ae2;
}

.border-orange-6-8 {
  border: 6.8px solid #ea872d;
}

.border-black-6-8 {
  border: 6.8px solid #020202;
}

.border-light-gray-6-8 {
  border: 6.8px solid #fafafa;
}

.border-deep-green-6-8 {
  border: 6.8px solid #014345;
}

.border-t-primary-6-8 {
  border-top: 6.8px solid #cccccc;
}

.border-t-secondary-6-8 {
  border-top: 6.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-8 {
  border-top: 6.8px solid #ff0f00;
}

.border-t-info-6-8 {
  border-top: 6.8px solid #f6c31c;
}

.border-t-white-6-8 {
  border-top: 6.8px solid #f7f8f7;
}

.border-t-green-6-8 {
  border-top: 6.8px solid #0b8376;
}

.border-t-purple-6-8 {
  border-top: 6.8px solid #755ae2;
}

.border-t-orange-6-8 {
  border-top: 6.8px solid #ea872d;
}

.border-t-black-6-8 {
  border-top: 6.8px solid #020202;
}

.border-t-light-gray-6-8 {
  border-top: 6.8px solid #fafafa;
}

.border-t-deep-green-6-8 {
  border-top: 6.8px solid #014345;
}

.border-b-primary-6-8 {
  border-bottom: 6.8px solid #cccccc;
}

.border-b-secondary-6-8 {
  border-bottom: 6.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-8 {
  border-bottom: 6.8px solid #ff0f00;
}

.border-b-info-6-8 {
  border-bottom: 6.8px solid #f6c31c;
}

.border-b-white-6-8 {
  border-bottom: 6.8px solid #f7f8f7;
}

.border-b-green-6-8 {
  border-bottom: 6.8px solid #0b8376;
}

.border-b-purple-6-8 {
  border-bottom: 6.8px solid #755ae2;
}

.border-b-orange-6-8 {
  border-bottom: 6.8px solid #ea872d;
}

.border-b-black-6-8 {
  border-bottom: 6.8px solid #020202;
}

.border-b-light-gray-6-8 {
  border-bottom: 6.8px solid #fafafa;
}

.border-b-deep-green-6-8 {
  border-bottom: 6.8px solid #014345;
}

.border-l-primary-6-8 {
  border-left: 6.8px solid #cccccc;
}

.border-l-secondary-6-8 {
  border-left: 6.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-8 {
  border-left: 6.8px solid #ff0f00;
}

.border-l-info-6-8 {
  border-left: 6.8px solid #f6c31c;
}

.border-l-white-6-8 {
  border-left: 6.8px solid #f7f8f7;
}

.border-l-green-6-8 {
  border-left: 6.8px solid #0b8376;
}

.border-l-purple-6-8 {
  border-left: 6.8px solid #755ae2;
}

.border-l-orange-6-8 {
  border-left: 6.8px solid #ea872d;
}

.border-l-black-6-8 {
  border-left: 6.8px solid #020202;
}

.border-l-light-gray-6-8 {
  border-left: 6.8px solid #fafafa;
}

.border-l-deep-green-6-8 {
  border-left: 6.8px solid #014345;
}

.border-r-primary-6-8 {
  border-right: 6.8px solid #cccccc;
}

.border-r-secondary-6-8 {
  border-right: 6.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-8 {
  border-right: 6.8px solid #ff0f00;
}

.border-r-info-6-8 {
  border-right: 6.8px solid #f6c31c;
}

.border-r-white-6-8 {
  border-right: 6.8px solid #f7f8f7;
}

.border-r-green-6-8 {
  border-right: 6.8px solid #0b8376;
}

.border-r-purple-6-8 {
  border-right: 6.8px solid #755ae2;
}

.border-r-orange-6-8 {
  border-right: 6.8px solid #ea872d;
}

.border-r-black-6-8 {
  border-right: 6.8px solid #020202;
}

.border-r-light-gray-6-8 {
  border-right: 6.8px solid #fafafa;
}

.border-r-deep-green-6-8 {
  border-right: 6.8px solid #014345;
}

.border-primary-6-9 {
  border: 6.9px solid #cccccc;
}

.border-secondary-6-9 {
  border: 6.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-6-9 {
  border: 6.9px solid #ff0f00;
}

.border-info-6-9 {
  border: 6.9px solid #f6c31c;
}

.border-white-6-9 {
  border: 6.9px solid #f7f8f7;
}

.border-green-6-9 {
  border: 6.9px solid #0b8376;
}

.border-purple-6-9 {
  border: 6.9px solid #755ae2;
}

.border-orange-6-9 {
  border: 6.9px solid #ea872d;
}

.border-black-6-9 {
  border: 6.9px solid #020202;
}

.border-light-gray-6-9 {
  border: 6.9px solid #fafafa;
}

.border-deep-green-6-9 {
  border: 6.9px solid #014345;
}

.border-t-primary-6-9 {
  border-top: 6.9px solid #cccccc;
}

.border-t-secondary-6-9 {
  border-top: 6.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-6-9 {
  border-top: 6.9px solid #ff0f00;
}

.border-t-info-6-9 {
  border-top: 6.9px solid #f6c31c;
}

.border-t-white-6-9 {
  border-top: 6.9px solid #f7f8f7;
}

.border-t-green-6-9 {
  border-top: 6.9px solid #0b8376;
}

.border-t-purple-6-9 {
  border-top: 6.9px solid #755ae2;
}

.border-t-orange-6-9 {
  border-top: 6.9px solid #ea872d;
}

.border-t-black-6-9 {
  border-top: 6.9px solid #020202;
}

.border-t-light-gray-6-9 {
  border-top: 6.9px solid #fafafa;
}

.border-t-deep-green-6-9 {
  border-top: 6.9px solid #014345;
}

.border-b-primary-6-9 {
  border-bottom: 6.9px solid #cccccc;
}

.border-b-secondary-6-9 {
  border-bottom: 6.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-6-9 {
  border-bottom: 6.9px solid #ff0f00;
}

.border-b-info-6-9 {
  border-bottom: 6.9px solid #f6c31c;
}

.border-b-white-6-9 {
  border-bottom: 6.9px solid #f7f8f7;
}

.border-b-green-6-9 {
  border-bottom: 6.9px solid #0b8376;
}

.border-b-purple-6-9 {
  border-bottom: 6.9px solid #755ae2;
}

.border-b-orange-6-9 {
  border-bottom: 6.9px solid #ea872d;
}

.border-b-black-6-9 {
  border-bottom: 6.9px solid #020202;
}

.border-b-light-gray-6-9 {
  border-bottom: 6.9px solid #fafafa;
}

.border-b-deep-green-6-9 {
  border-bottom: 6.9px solid #014345;
}

.border-l-primary-6-9 {
  border-left: 6.9px solid #cccccc;
}

.border-l-secondary-6-9 {
  border-left: 6.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-6-9 {
  border-left: 6.9px solid #ff0f00;
}

.border-l-info-6-9 {
  border-left: 6.9px solid #f6c31c;
}

.border-l-white-6-9 {
  border-left: 6.9px solid #f7f8f7;
}

.border-l-green-6-9 {
  border-left: 6.9px solid #0b8376;
}

.border-l-purple-6-9 {
  border-left: 6.9px solid #755ae2;
}

.border-l-orange-6-9 {
  border-left: 6.9px solid #ea872d;
}

.border-l-black-6-9 {
  border-left: 6.9px solid #020202;
}

.border-l-light-gray-6-9 {
  border-left: 6.9px solid #fafafa;
}

.border-l-deep-green-6-9 {
  border-left: 6.9px solid #014345;
}

.border-r-primary-6-9 {
  border-right: 6.9px solid #cccccc;
}

.border-r-secondary-6-9 {
  border-right: 6.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-6-9 {
  border-right: 6.9px solid #ff0f00;
}

.border-r-info-6-9 {
  border-right: 6.9px solid #f6c31c;
}

.border-r-white-6-9 {
  border-right: 6.9px solid #f7f8f7;
}

.border-r-green-6-9 {
  border-right: 6.9px solid #0b8376;
}

.border-r-purple-6-9 {
  border-right: 6.9px solid #755ae2;
}

.border-r-orange-6-9 {
  border-right: 6.9px solid #ea872d;
}

.border-r-black-6-9 {
  border-right: 6.9px solid #020202;
}

.border-r-light-gray-6-9 {
  border-right: 6.9px solid #fafafa;
}

.border-r-deep-green-6-9 {
  border-right: 6.9px solid #014345;
}

.border-primary-7 {
  border: 7px solid #cccccc;
}

.border-secondary-7 {
  border: 7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7 {
  border: 7px solid #ff0f00;
}

.border-info-7 {
  border: 7px solid #f6c31c;
}

.border-white-7 {
  border: 7px solid #f7f8f7;
}

.border-green-7 {
  border: 7px solid #0b8376;
}

.border-purple-7 {
  border: 7px solid #755ae2;
}

.border-orange-7 {
  border: 7px solid #ea872d;
}

.border-black-7 {
  border: 7px solid #020202;
}

.border-light-gray-7 {
  border: 7px solid #fafafa;
}

.border-deep-green-7 {
  border: 7px solid #014345;
}

.border-t-primary-7 {
  border-top: 7px solid #cccccc;
}

.border-t-secondary-7 {
  border-top: 7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7 {
  border-top: 7px solid #ff0f00;
}

.border-t-info-7 {
  border-top: 7px solid #f6c31c;
}

.border-t-white-7 {
  border-top: 7px solid #f7f8f7;
}

.border-t-green-7 {
  border-top: 7px solid #0b8376;
}

.border-t-purple-7 {
  border-top: 7px solid #755ae2;
}

.border-t-orange-7 {
  border-top: 7px solid #ea872d;
}

.border-t-black-7 {
  border-top: 7px solid #020202;
}

.border-t-light-gray-7 {
  border-top: 7px solid #fafafa;
}

.border-t-deep-green-7 {
  border-top: 7px solid #014345;
}

.border-b-primary-7 {
  border-bottom: 7px solid #cccccc;
}

.border-b-secondary-7 {
  border-bottom: 7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7 {
  border-bottom: 7px solid #ff0f00;
}

.border-b-info-7 {
  border-bottom: 7px solid #f6c31c;
}

.border-b-white-7 {
  border-bottom: 7px solid #f7f8f7;
}

.border-b-green-7 {
  border-bottom: 7px solid #0b8376;
}

.border-b-purple-7 {
  border-bottom: 7px solid #755ae2;
}

.border-b-orange-7 {
  border-bottom: 7px solid #ea872d;
}

.border-b-black-7 {
  border-bottom: 7px solid #020202;
}

.border-b-light-gray-7 {
  border-bottom: 7px solid #fafafa;
}

.border-b-deep-green-7 {
  border-bottom: 7px solid #014345;
}

.border_l-primary-7 {
  border-left: 7px solid #cccccc;
}

.border_l-secondary-7 {
  border-left: 7px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-7 {
  border-left: 7px solid #ff0f00;
}

.border_l-info-7 {
  border-left: 7px solid #f6c31c;
}

.border_l-white-7 {
  border-left: 7px solid #f7f8f7;
}

.border_l-green-7 {
  border-left: 7px solid #0b8376;
}

.border_l-purple-7 {
  border-left: 7px solid #755ae2;
}

.border_l-orange-7 {
  border-left: 7px solid #ea872d;
}

.border_l-black-7 {
  border-left: 7px solid #020202;
}

.border_l-light-gray-7 {
  border-left: 7px solid #fafafa;
}

.border_l-deep-green-7 {
  border-left: 7px solid #014345;
}

.border-r-primary-7 {
  border-right: 7px solid #cccccc;
}

.border-r-secondary-7 {
  border-right: 7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7 {
  border-right: 7px solid #ff0f00;
}

.border-r-info-7 {
  border-right: 7px solid #f6c31c;
}

.border-r-white-7 {
  border-right: 7px solid #f7f8f7;
}

.border-r-green-7 {
  border-right: 7px solid #0b8376;
}

.border-r-purple-7 {
  border-right: 7px solid #755ae2;
}

.border-r-orange-7 {
  border-right: 7px solid #ea872d;
}

.border-r-black-7 {
  border-right: 7px solid #020202;
}

.border-r-light-gray-7 {
  border-right: 7px solid #fafafa;
}

.border-r-deep-green-7 {
  border-right: 7px solid #014345;
}

.border-primary-7-1 {
  border: 7.1px solid #cccccc;
}

.border-secondary-7-1 {
  border: 7.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-1 {
  border: 7.1px solid #ff0f00;
}

.border-info-7-1 {
  border: 7.1px solid #f6c31c;
}

.border-white-7-1 {
  border: 7.1px solid #f7f8f7;
}

.border-green-7-1 {
  border: 7.1px solid #0b8376;
}

.border-purple-7-1 {
  border: 7.1px solid #755ae2;
}

.border-orange-7-1 {
  border: 7.1px solid #ea872d;
}

.border-black-7-1 {
  border: 7.1px solid #020202;
}

.border-light-gray-7-1 {
  border: 7.1px solid #fafafa;
}

.border-deep-green-7-1 {
  border: 7.1px solid #014345;
}

.border-t-primary-7-1 {
  border-top: 7.1px solid #cccccc;
}

.border-t-secondary-7-1 {
  border-top: 7.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-1 {
  border-top: 7.1px solid #ff0f00;
}

.border-t-info-7-1 {
  border-top: 7.1px solid #f6c31c;
}

.border-t-white-7-1 {
  border-top: 7.1px solid #f7f8f7;
}

.border-t-green-7-1 {
  border-top: 7.1px solid #0b8376;
}

.border-t-purple-7-1 {
  border-top: 7.1px solid #755ae2;
}

.border-t-orange-7-1 {
  border-top: 7.1px solid #ea872d;
}

.border-t-black-7-1 {
  border-top: 7.1px solid #020202;
}

.border-t-light-gray-7-1 {
  border-top: 7.1px solid #fafafa;
}

.border-t-deep-green-7-1 {
  border-top: 7.1px solid #014345;
}

.border-b-primary-7-1 {
  border-bottom: 7.1px solid #cccccc;
}

.border-b-secondary-7-1 {
  border-bottom: 7.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-1 {
  border-bottom: 7.1px solid #ff0f00;
}

.border-b-info-7-1 {
  border-bottom: 7.1px solid #f6c31c;
}

.border-b-white-7-1 {
  border-bottom: 7.1px solid #f7f8f7;
}

.border-b-green-7-1 {
  border-bottom: 7.1px solid #0b8376;
}

.border-b-purple-7-1 {
  border-bottom: 7.1px solid #755ae2;
}

.border-b-orange-7-1 {
  border-bottom: 7.1px solid #ea872d;
}

.border-b-black-7-1 {
  border-bottom: 7.1px solid #020202;
}

.border-b-light-gray-7-1 {
  border-bottom: 7.1px solid #fafafa;
}

.border-b-deep-green-7-1 {
  border-bottom: 7.1px solid #014345;
}

.border-l-primary-7-1 {
  border-left: 7.1px solid #cccccc;
}

.border-l-secondary-7-1 {
  border-left: 7.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-1 {
  border-left: 7.1px solid #ff0f00;
}

.border-l-info-7-1 {
  border-left: 7.1px solid #f6c31c;
}

.border-l-white-7-1 {
  border-left: 7.1px solid #f7f8f7;
}

.border-l-green-7-1 {
  border-left: 7.1px solid #0b8376;
}

.border-l-purple-7-1 {
  border-left: 7.1px solid #755ae2;
}

.border-l-orange-7-1 {
  border-left: 7.1px solid #ea872d;
}

.border-l-black-7-1 {
  border-left: 7.1px solid #020202;
}

.border-l-light-gray-7-1 {
  border-left: 7.1px solid #fafafa;
}

.border-l-deep-green-7-1 {
  border-left: 7.1px solid #014345;
}

.border-r-primary-7-1 {
  border-right: 7.1px solid #cccccc;
}

.border-r-secondary-7-1 {
  border-right: 7.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-1 {
  border-right: 7.1px solid #ff0f00;
}

.border-r-info-7-1 {
  border-right: 7.1px solid #f6c31c;
}

.border-r-white-7-1 {
  border-right: 7.1px solid #f7f8f7;
}

.border-r-green-7-1 {
  border-right: 7.1px solid #0b8376;
}

.border-r-purple-7-1 {
  border-right: 7.1px solid #755ae2;
}

.border-r-orange-7-1 {
  border-right: 7.1px solid #ea872d;
}

.border-r-black-7-1 {
  border-right: 7.1px solid #020202;
}

.border-r-light-gray-7-1 {
  border-right: 7.1px solid #fafafa;
}

.border-r-deep-green-7-1 {
  border-right: 7.1px solid #014345;
}

.border-primary-7-2 {
  border: 7.2px solid #cccccc;
}

.border-secondary-7-2 {
  border: 7.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-2 {
  border: 7.2px solid #ff0f00;
}

.border-info-7-2 {
  border: 7.2px solid #f6c31c;
}

.border-white-7-2 {
  border: 7.2px solid #f7f8f7;
}

.border-green-7-2 {
  border: 7.2px solid #0b8376;
}

.border-purple-7-2 {
  border: 7.2px solid #755ae2;
}

.border-orange-7-2 {
  border: 7.2px solid #ea872d;
}

.border-black-7-2 {
  border: 7.2px solid #020202;
}

.border-light-gray-7-2 {
  border: 7.2px solid #fafafa;
}

.border-deep-green-7-2 {
  border: 7.2px solid #014345;
}

.border-t-primary-7-2 {
  border-top: 7.2px solid #cccccc;
}

.border-t-secondary-7-2 {
  border-top: 7.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-2 {
  border-top: 7.2px solid #ff0f00;
}

.border-t-info-7-2 {
  border-top: 7.2px solid #f6c31c;
}

.border-t-white-7-2 {
  border-top: 7.2px solid #f7f8f7;
}

.border-t-green-7-2 {
  border-top: 7.2px solid #0b8376;
}

.border-t-purple-7-2 {
  border-top: 7.2px solid #755ae2;
}

.border-t-orange-7-2 {
  border-top: 7.2px solid #ea872d;
}

.border-t-black-7-2 {
  border-top: 7.2px solid #020202;
}

.border-t-light-gray-7-2 {
  border-top: 7.2px solid #fafafa;
}

.border-t-deep-green-7-2 {
  border-top: 7.2px solid #014345;
}

.border-b-primary-7-2 {
  border-bottom: 7.2px solid #cccccc;
}

.border-b-secondary-7-2 {
  border-bottom: 7.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-2 {
  border-bottom: 7.2px solid #ff0f00;
}

.border-b-info-7-2 {
  border-bottom: 7.2px solid #f6c31c;
}

.border-b-white-7-2 {
  border-bottom: 7.2px solid #f7f8f7;
}

.border-b-green-7-2 {
  border-bottom: 7.2px solid #0b8376;
}

.border-b-purple-7-2 {
  border-bottom: 7.2px solid #755ae2;
}

.border-b-orange-7-2 {
  border-bottom: 7.2px solid #ea872d;
}

.border-b-black-7-2 {
  border-bottom: 7.2px solid #020202;
}

.border-b-light-gray-7-2 {
  border-bottom: 7.2px solid #fafafa;
}

.border-b-deep-green-7-2 {
  border-bottom: 7.2px solid #014345;
}

.border-l-primary-7-2 {
  border-left: 7.2px solid #cccccc;
}

.border-l-secondary-7-2 {
  border-left: 7.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-2 {
  border-left: 7.2px solid #ff0f00;
}

.border-l-info-7-2 {
  border-left: 7.2px solid #f6c31c;
}

.border-l-white-7-2 {
  border-left: 7.2px solid #f7f8f7;
}

.border-l-green-7-2 {
  border-left: 7.2px solid #0b8376;
}

.border-l-purple-7-2 {
  border-left: 7.2px solid #755ae2;
}

.border-l-orange-7-2 {
  border-left: 7.2px solid #ea872d;
}

.border-l-black-7-2 {
  border-left: 7.2px solid #020202;
}

.border-l-light-gray-7-2 {
  border-left: 7.2px solid #fafafa;
}

.border-l-deep-green-7-2 {
  border-left: 7.2px solid #014345;
}

.border-r-primary-7-2 {
  border-right: 7.2px solid #cccccc;
}

.border-r-secondary-7-2 {
  border-right: 7.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-2 {
  border-right: 7.2px solid #ff0f00;
}

.border-r-info-7-2 {
  border-right: 7.2px solid #f6c31c;
}

.border-r-white-7-2 {
  border-right: 7.2px solid #f7f8f7;
}

.border-r-green-7-2 {
  border-right: 7.2px solid #0b8376;
}

.border-r-purple-7-2 {
  border-right: 7.2px solid #755ae2;
}

.border-r-orange-7-2 {
  border-right: 7.2px solid #ea872d;
}

.border-r-black-7-2 {
  border-right: 7.2px solid #020202;
}

.border-r-light-gray-7-2 {
  border-right: 7.2px solid #fafafa;
}

.border-r-deep-green-7-2 {
  border-right: 7.2px solid #014345;
}

.border-primary-7-3 {
  border: 7.3px solid #cccccc;
}

.border-secondary-7-3 {
  border: 7.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-3 {
  border: 7.3px solid #ff0f00;
}

.border-info-7-3 {
  border: 7.3px solid #f6c31c;
}

.border-white-7-3 {
  border: 7.3px solid #f7f8f7;
}

.border-green-7-3 {
  border: 7.3px solid #0b8376;
}

.border-purple-7-3 {
  border: 7.3px solid #755ae2;
}

.border-orange-7-3 {
  border: 7.3px solid #ea872d;
}

.border-black-7-3 {
  border: 7.3px solid #020202;
}

.border-light-gray-7-3 {
  border: 7.3px solid #fafafa;
}

.border-deep-green-7-3 {
  border: 7.3px solid #014345;
}

.border-t-primary-7-3 {
  border-top: 7.3px solid #cccccc;
}

.border-t-secondary-7-3 {
  border-top: 7.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-3 {
  border-top: 7.3px solid #ff0f00;
}

.border-t-info-7-3 {
  border-top: 7.3px solid #f6c31c;
}

.border-t-white-7-3 {
  border-top: 7.3px solid #f7f8f7;
}

.border-t-green-7-3 {
  border-top: 7.3px solid #0b8376;
}

.border-t-purple-7-3 {
  border-top: 7.3px solid #755ae2;
}

.border-t-orange-7-3 {
  border-top: 7.3px solid #ea872d;
}

.border-t-black-7-3 {
  border-top: 7.3px solid #020202;
}

.border-t-light-gray-7-3 {
  border-top: 7.3px solid #fafafa;
}

.border-t-deep-green-7-3 {
  border-top: 7.3px solid #014345;
}

.border-b-primary-7-3 {
  border-bottom: 7.3px solid #cccccc;
}

.border-b-secondary-7-3 {
  border-bottom: 7.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-3 {
  border-bottom: 7.3px solid #ff0f00;
}

.border-b-info-7-3 {
  border-bottom: 7.3px solid #f6c31c;
}

.border-b-white-7-3 {
  border-bottom: 7.3px solid #f7f8f7;
}

.border-b-green-7-3 {
  border-bottom: 7.3px solid #0b8376;
}

.border-b-purple-7-3 {
  border-bottom: 7.3px solid #755ae2;
}

.border-b-orange-7-3 {
  border-bottom: 7.3px solid #ea872d;
}

.border-b-black-7-3 {
  border-bottom: 7.3px solid #020202;
}

.border-b-light-gray-7-3 {
  border-bottom: 7.3px solid #fafafa;
}

.border-b-deep-green-7-3 {
  border-bottom: 7.3px solid #014345;
}

.border-l-primary-7-3 {
  border-left: 7.3px solid #cccccc;
}

.border-l-secondary-7-3 {
  border-left: 7.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-3 {
  border-left: 7.3px solid #ff0f00;
}

.border-l-info-7-3 {
  border-left: 7.3px solid #f6c31c;
}

.border-l-white-7-3 {
  border-left: 7.3px solid #f7f8f7;
}

.border-l-green-7-3 {
  border-left: 7.3px solid #0b8376;
}

.border-l-purple-7-3 {
  border-left: 7.3px solid #755ae2;
}

.border-l-orange-7-3 {
  border-left: 7.3px solid #ea872d;
}

.border-l-black-7-3 {
  border-left: 7.3px solid #020202;
}

.border-l-light-gray-7-3 {
  border-left: 7.3px solid #fafafa;
}

.border-l-deep-green-7-3 {
  border-left: 7.3px solid #014345;
}

.border-r-primary-7-3 {
  border-right: 7.3px solid #cccccc;
}

.border-r-secondary-7-3 {
  border-right: 7.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-3 {
  border-right: 7.3px solid #ff0f00;
}

.border-r-info-7-3 {
  border-right: 7.3px solid #f6c31c;
}

.border-r-white-7-3 {
  border-right: 7.3px solid #f7f8f7;
}

.border-r-green-7-3 {
  border-right: 7.3px solid #0b8376;
}

.border-r-purple-7-3 {
  border-right: 7.3px solid #755ae2;
}

.border-r-orange-7-3 {
  border-right: 7.3px solid #ea872d;
}

.border-r-black-7-3 {
  border-right: 7.3px solid #020202;
}

.border-r-light-gray-7-3 {
  border-right: 7.3px solid #fafafa;
}

.border-r-deep-green-7-3 {
  border-right: 7.3px solid #014345;
}

.border-primary-7-4 {
  border: 7.4px solid #cccccc;
}

.border-secondary-7-4 {
  border: 7.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-4 {
  border: 7.4px solid #ff0f00;
}

.border-info-7-4 {
  border: 7.4px solid #f6c31c;
}

.border-white-7-4 {
  border: 7.4px solid #f7f8f7;
}

.border-green-7-4 {
  border: 7.4px solid #0b8376;
}

.border-purple-7-4 {
  border: 7.4px solid #755ae2;
}

.border-orange-7-4 {
  border: 7.4px solid #ea872d;
}

.border-black-7-4 {
  border: 7.4px solid #020202;
}

.border-light-gray-7-4 {
  border: 7.4px solid #fafafa;
}

.border-deep-green-7-4 {
  border: 7.4px solid #014345;
}

.border-t-primary-7-4 {
  border-top: 7.4px solid #cccccc;
}

.border-t-secondary-7-4 {
  border-top: 7.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-4 {
  border-top: 7.4px solid #ff0f00;
}

.border-t-info-7-4 {
  border-top: 7.4px solid #f6c31c;
}

.border-t-white-7-4 {
  border-top: 7.4px solid #f7f8f7;
}

.border-t-green-7-4 {
  border-top: 7.4px solid #0b8376;
}

.border-t-purple-7-4 {
  border-top: 7.4px solid #755ae2;
}

.border-t-orange-7-4 {
  border-top: 7.4px solid #ea872d;
}

.border-t-black-7-4 {
  border-top: 7.4px solid #020202;
}

.border-t-light-gray-7-4 {
  border-top: 7.4px solid #fafafa;
}

.border-t-deep-green-7-4 {
  border-top: 7.4px solid #014345;
}

.border-b-primary-7-4 {
  border-bottom: 7.4px solid #cccccc;
}

.border-b-secondary-7-4 {
  border-bottom: 7.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-4 {
  border-bottom: 7.4px solid #ff0f00;
}

.border-b-info-7-4 {
  border-bottom: 7.4px solid #f6c31c;
}

.border-b-white-7-4 {
  border-bottom: 7.4px solid #f7f8f7;
}

.border-b-green-7-4 {
  border-bottom: 7.4px solid #0b8376;
}

.border-b-purple-7-4 {
  border-bottom: 7.4px solid #755ae2;
}

.border-b-orange-7-4 {
  border-bottom: 7.4px solid #ea872d;
}

.border-b-black-7-4 {
  border-bottom: 7.4px solid #020202;
}

.border-b-light-gray-7-4 {
  border-bottom: 7.4px solid #fafafa;
}

.border-b-deep-green-7-4 {
  border-bottom: 7.4px solid #014345;
}

.border-l-primary-7-4 {
  border-left: 7.4px solid #cccccc;
}

.border-l-secondary-7-4 {
  border-left: 7.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-4 {
  border-left: 7.4px solid #ff0f00;
}

.border-l-info-7-4 {
  border-left: 7.4px solid #f6c31c;
}

.border-l-white-7-4 {
  border-left: 7.4px solid #f7f8f7;
}

.border-l-green-7-4 {
  border-left: 7.4px solid #0b8376;
}

.border-l-purple-7-4 {
  border-left: 7.4px solid #755ae2;
}

.border-l-orange-7-4 {
  border-left: 7.4px solid #ea872d;
}

.border-l-black-7-4 {
  border-left: 7.4px solid #020202;
}

.border-l-light-gray-7-4 {
  border-left: 7.4px solid #fafafa;
}

.border-l-deep-green-7-4 {
  border-left: 7.4px solid #014345;
}

.border-r-primary-7-4 {
  border-right: 7.4px solid #cccccc;
}

.border-r-secondary-7-4 {
  border-right: 7.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-4 {
  border-right: 7.4px solid #ff0f00;
}

.border-r-info-7-4 {
  border-right: 7.4px solid #f6c31c;
}

.border-r-white-7-4 {
  border-right: 7.4px solid #f7f8f7;
}

.border-r-green-7-4 {
  border-right: 7.4px solid #0b8376;
}

.border-r-purple-7-4 {
  border-right: 7.4px solid #755ae2;
}

.border-r-orange-7-4 {
  border-right: 7.4px solid #ea872d;
}

.border-r-black-7-4 {
  border-right: 7.4px solid #020202;
}

.border-r-light-gray-7-4 {
  border-right: 7.4px solid #fafafa;
}

.border-r-deep-green-7-4 {
  border-right: 7.4px solid #014345;
}

.border-primary-7-5 {
  border: 7.5px solid #cccccc;
}

.border-secondary-7-5 {
  border: 7.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-5 {
  border: 7.5px solid #ff0f00;
}

.border-info-7-5 {
  border: 7.5px solid #f6c31c;
}

.border-white-7-5 {
  border: 7.5px solid #f7f8f7;
}

.border-green-7-5 {
  border: 7.5px solid #0b8376;
}

.border-purple-7-5 {
  border: 7.5px solid #755ae2;
}

.border-orange-7-5 {
  border: 7.5px solid #ea872d;
}

.border-black-7-5 {
  border: 7.5px solid #020202;
}

.border-light-gray-7-5 {
  border: 7.5px solid #fafafa;
}

.border-deep-green-7-5 {
  border: 7.5px solid #014345;
}

.border-t-primary-7-5 {
  border-top: 7.5px solid #cccccc;
}

.border-t-secondary-7-5 {
  border-top: 7.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-5 {
  border-top: 7.5px solid #ff0f00;
}

.border-t-info-7-5 {
  border-top: 7.5px solid #f6c31c;
}

.border-t-white-7-5 {
  border-top: 7.5px solid #f7f8f7;
}

.border-t-green-7-5 {
  border-top: 7.5px solid #0b8376;
}

.border-t-purple-7-5 {
  border-top: 7.5px solid #755ae2;
}

.border-t-orange-7-5 {
  border-top: 7.5px solid #ea872d;
}

.border-t-black-7-5 {
  border-top: 7.5px solid #020202;
}

.border-t-light-gray-7-5 {
  border-top: 7.5px solid #fafafa;
}

.border-t-deep-green-7-5 {
  border-top: 7.5px solid #014345;
}

.border-b-primary-7-5 {
  border-bottom: 7.5px solid #cccccc;
}

.border-b-secondary-7-5 {
  border-bottom: 7.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-5 {
  border-bottom: 7.5px solid #ff0f00;
}

.border-b-info-7-5 {
  border-bottom: 7.5px solid #f6c31c;
}

.border-b-white-7-5 {
  border-bottom: 7.5px solid #f7f8f7;
}

.border-b-green-7-5 {
  border-bottom: 7.5px solid #0b8376;
}

.border-b-purple-7-5 {
  border-bottom: 7.5px solid #755ae2;
}

.border-b-orange-7-5 {
  border-bottom: 7.5px solid #ea872d;
}

.border-b-black-7-5 {
  border-bottom: 7.5px solid #020202;
}

.border-b-light-gray-7-5 {
  border-bottom: 7.5px solid #fafafa;
}

.border-b-deep-green-7-5 {
  border-bottom: 7.5px solid #014345;
}

.border-l-primary-7-5 {
  border-left: 7.5px solid #cccccc;
}

.border-l-secondary-7-5 {
  border-left: 7.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-5 {
  border-left: 7.5px solid #ff0f00;
}

.border-l-info-7-5 {
  border-left: 7.5px solid #f6c31c;
}

.border-l-white-7-5 {
  border-left: 7.5px solid #f7f8f7;
}

.border-l-green-7-5 {
  border-left: 7.5px solid #0b8376;
}

.border-l-purple-7-5 {
  border-left: 7.5px solid #755ae2;
}

.border-l-orange-7-5 {
  border-left: 7.5px solid #ea872d;
}

.border-l-black-7-5 {
  border-left: 7.5px solid #020202;
}

.border-l-light-gray-7-5 {
  border-left: 7.5px solid #fafafa;
}

.border-l-deep-green-7-5 {
  border-left: 7.5px solid #014345;
}

.border-r-primary-7-5 {
  border-right: 7.5px solid #cccccc;
}

.border-r-secondary-7-5 {
  border-right: 7.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-5 {
  border-right: 7.5px solid #ff0f00;
}

.border-r-info-7-5 {
  border-right: 7.5px solid #f6c31c;
}

.border-r-white-7-5 {
  border-right: 7.5px solid #f7f8f7;
}

.border-r-green-7-5 {
  border-right: 7.5px solid #0b8376;
}

.border-r-purple-7-5 {
  border-right: 7.5px solid #755ae2;
}

.border-r-orange-7-5 {
  border-right: 7.5px solid #ea872d;
}

.border-r-black-7-5 {
  border-right: 7.5px solid #020202;
}

.border-r-light-gray-7-5 {
  border-right: 7.5px solid #fafafa;
}

.border-r-deep-green-7-5 {
  border-right: 7.5px solid #014345;
}

.border-primary-7-6 {
  border: 7.6px solid #cccccc;
}

.border-secondary-7-6 {
  border: 7.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-6 {
  border: 7.6px solid #ff0f00;
}

.border-info-7-6 {
  border: 7.6px solid #f6c31c;
}

.border-white-7-6 {
  border: 7.6px solid #f7f8f7;
}

.border-green-7-6 {
  border: 7.6px solid #0b8376;
}

.border-purple-7-6 {
  border: 7.6px solid #755ae2;
}

.border-orange-7-6 {
  border: 7.6px solid #ea872d;
}

.border-black-7-6 {
  border: 7.6px solid #020202;
}

.border-light-gray-7-6 {
  border: 7.6px solid #fafafa;
}

.border-deep-green-7-6 {
  border: 7.6px solid #014345;
}

.border-t-primary-7-6 {
  border-top: 7.6px solid #cccccc;
}

.border-t-secondary-7-6 {
  border-top: 7.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-6 {
  border-top: 7.6px solid #ff0f00;
}

.border-t-info-7-6 {
  border-top: 7.6px solid #f6c31c;
}

.border-t-white-7-6 {
  border-top: 7.6px solid #f7f8f7;
}

.border-t-green-7-6 {
  border-top: 7.6px solid #0b8376;
}

.border-t-purple-7-6 {
  border-top: 7.6px solid #755ae2;
}

.border-t-orange-7-6 {
  border-top: 7.6px solid #ea872d;
}

.border-t-black-7-6 {
  border-top: 7.6px solid #020202;
}

.border-t-light-gray-7-6 {
  border-top: 7.6px solid #fafafa;
}

.border-t-deep-green-7-6 {
  border-top: 7.6px solid #014345;
}

.border-b-primary-7-6 {
  border-bottom: 7.6px solid #cccccc;
}

.border-b-secondary-7-6 {
  border-bottom: 7.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-6 {
  border-bottom: 7.6px solid #ff0f00;
}

.border-b-info-7-6 {
  border-bottom: 7.6px solid #f6c31c;
}

.border-b-white-7-6 {
  border-bottom: 7.6px solid #f7f8f7;
}

.border-b-green-7-6 {
  border-bottom: 7.6px solid #0b8376;
}

.border-b-purple-7-6 {
  border-bottom: 7.6px solid #755ae2;
}

.border-b-orange-7-6 {
  border-bottom: 7.6px solid #ea872d;
}

.border-b-black-7-6 {
  border-bottom: 7.6px solid #020202;
}

.border-b-light-gray-7-6 {
  border-bottom: 7.6px solid #fafafa;
}

.border-b-deep-green-7-6 {
  border-bottom: 7.6px solid #014345;
}

.border-l-primary-7-6 {
  border-left: 7.6px solid #cccccc;
}

.border-l-secondary-7-6 {
  border-left: 7.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-6 {
  border-left: 7.6px solid #ff0f00;
}

.border-l-info-7-6 {
  border-left: 7.6px solid #f6c31c;
}

.border-l-white-7-6 {
  border-left: 7.6px solid #f7f8f7;
}

.border-l-green-7-6 {
  border-left: 7.6px solid #0b8376;
}

.border-l-purple-7-6 {
  border-left: 7.6px solid #755ae2;
}

.border-l-orange-7-6 {
  border-left: 7.6px solid #ea872d;
}

.border-l-black-7-6 {
  border-left: 7.6px solid #020202;
}

.border-l-light-gray-7-6 {
  border-left: 7.6px solid #fafafa;
}

.border-l-deep-green-7-6 {
  border-left: 7.6px solid #014345;
}

.border-r-primary-7-6 {
  border-right: 7.6px solid #cccccc;
}

.border-r-secondary-7-6 {
  border-right: 7.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-6 {
  border-right: 7.6px solid #ff0f00;
}

.border-r-info-7-6 {
  border-right: 7.6px solid #f6c31c;
}

.border-r-white-7-6 {
  border-right: 7.6px solid #f7f8f7;
}

.border-r-green-7-6 {
  border-right: 7.6px solid #0b8376;
}

.border-r-purple-7-6 {
  border-right: 7.6px solid #755ae2;
}

.border-r-orange-7-6 {
  border-right: 7.6px solid #ea872d;
}

.border-r-black-7-6 {
  border-right: 7.6px solid #020202;
}

.border-r-light-gray-7-6 {
  border-right: 7.6px solid #fafafa;
}

.border-r-deep-green-7-6 {
  border-right: 7.6px solid #014345;
}

.border-primary-7-7 {
  border: 7.7px solid #cccccc;
}

.border-secondary-7-7 {
  border: 7.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-7 {
  border: 7.7px solid #ff0f00;
}

.border-info-7-7 {
  border: 7.7px solid #f6c31c;
}

.border-white-7-7 {
  border: 7.7px solid #f7f8f7;
}

.border-green-7-7 {
  border: 7.7px solid #0b8376;
}

.border-purple-7-7 {
  border: 7.7px solid #755ae2;
}

.border-orange-7-7 {
  border: 7.7px solid #ea872d;
}

.border-black-7-7 {
  border: 7.7px solid #020202;
}

.border-light-gray-7-7 {
  border: 7.7px solid #fafafa;
}

.border-deep-green-7-7 {
  border: 7.7px solid #014345;
}

.border-t-primary-7-7 {
  border-top: 7.7px solid #cccccc;
}

.border-t-secondary-7-7 {
  border-top: 7.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-7 {
  border-top: 7.7px solid #ff0f00;
}

.border-t-info-7-7 {
  border-top: 7.7px solid #f6c31c;
}

.border-t-white-7-7 {
  border-top: 7.7px solid #f7f8f7;
}

.border-t-green-7-7 {
  border-top: 7.7px solid #0b8376;
}

.border-t-purple-7-7 {
  border-top: 7.7px solid #755ae2;
}

.border-t-orange-7-7 {
  border-top: 7.7px solid #ea872d;
}

.border-t-black-7-7 {
  border-top: 7.7px solid #020202;
}

.border-t-light-gray-7-7 {
  border-top: 7.7px solid #fafafa;
}

.border-t-deep-green-7-7 {
  border-top: 7.7px solid #014345;
}

.border-b-primary-7-7 {
  border-bottom: 7.7px solid #cccccc;
}

.border-b-secondary-7-7 {
  border-bottom: 7.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-7 {
  border-bottom: 7.7px solid #ff0f00;
}

.border-b-info-7-7 {
  border-bottom: 7.7px solid #f6c31c;
}

.border-b-white-7-7 {
  border-bottom: 7.7px solid #f7f8f7;
}

.border-b-green-7-7 {
  border-bottom: 7.7px solid #0b8376;
}

.border-b-purple-7-7 {
  border-bottom: 7.7px solid #755ae2;
}

.border-b-orange-7-7 {
  border-bottom: 7.7px solid #ea872d;
}

.border-b-black-7-7 {
  border-bottom: 7.7px solid #020202;
}

.border-b-light-gray-7-7 {
  border-bottom: 7.7px solid #fafafa;
}

.border-b-deep-green-7-7 {
  border-bottom: 7.7px solid #014345;
}

.border-l-primary-7-7 {
  border-left: 7.7px solid #cccccc;
}

.border-l-secondary-7-7 {
  border-left: 7.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-7 {
  border-left: 7.7px solid #ff0f00;
}

.border-l-info-7-7 {
  border-left: 7.7px solid #f6c31c;
}

.border-l-white-7-7 {
  border-left: 7.7px solid #f7f8f7;
}

.border-l-green-7-7 {
  border-left: 7.7px solid #0b8376;
}

.border-l-purple-7-7 {
  border-left: 7.7px solid #755ae2;
}

.border-l-orange-7-7 {
  border-left: 7.7px solid #ea872d;
}

.border-l-black-7-7 {
  border-left: 7.7px solid #020202;
}

.border-l-light-gray-7-7 {
  border-left: 7.7px solid #fafafa;
}

.border-l-deep-green-7-7 {
  border-left: 7.7px solid #014345;
}

.border-r-primary-7-7 {
  border-right: 7.7px solid #cccccc;
}

.border-r-secondary-7-7 {
  border-right: 7.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-7 {
  border-right: 7.7px solid #ff0f00;
}

.border-r-info-7-7 {
  border-right: 7.7px solid #f6c31c;
}

.border-r-white-7-7 {
  border-right: 7.7px solid #f7f8f7;
}

.border-r-green-7-7 {
  border-right: 7.7px solid #0b8376;
}

.border-r-purple-7-7 {
  border-right: 7.7px solid #755ae2;
}

.border-r-orange-7-7 {
  border-right: 7.7px solid #ea872d;
}

.border-r-black-7-7 {
  border-right: 7.7px solid #020202;
}

.border-r-light-gray-7-7 {
  border-right: 7.7px solid #fafafa;
}

.border-r-deep-green-7-7 {
  border-right: 7.7px solid #014345;
}

.border-primary-7-8 {
  border: 7.8px solid #cccccc;
}

.border-secondary-7-8 {
  border: 7.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-8 {
  border: 7.8px solid #ff0f00;
}

.border-info-7-8 {
  border: 7.8px solid #f6c31c;
}

.border-white-7-8 {
  border: 7.8px solid #f7f8f7;
}

.border-green-7-8 {
  border: 7.8px solid #0b8376;
}

.border-purple-7-8 {
  border: 7.8px solid #755ae2;
}

.border-orange-7-8 {
  border: 7.8px solid #ea872d;
}

.border-black-7-8 {
  border: 7.8px solid #020202;
}

.border-light-gray-7-8 {
  border: 7.8px solid #fafafa;
}

.border-deep-green-7-8 {
  border: 7.8px solid #014345;
}

.border-t-primary-7-8 {
  border-top: 7.8px solid #cccccc;
}

.border-t-secondary-7-8 {
  border-top: 7.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-8 {
  border-top: 7.8px solid #ff0f00;
}

.border-t-info-7-8 {
  border-top: 7.8px solid #f6c31c;
}

.border-t-white-7-8 {
  border-top: 7.8px solid #f7f8f7;
}

.border-t-green-7-8 {
  border-top: 7.8px solid #0b8376;
}

.border-t-purple-7-8 {
  border-top: 7.8px solid #755ae2;
}

.border-t-orange-7-8 {
  border-top: 7.8px solid #ea872d;
}

.border-t-black-7-8 {
  border-top: 7.8px solid #020202;
}

.border-t-light-gray-7-8 {
  border-top: 7.8px solid #fafafa;
}

.border-t-deep-green-7-8 {
  border-top: 7.8px solid #014345;
}

.border-b-primary-7-8 {
  border-bottom: 7.8px solid #cccccc;
}

.border-b-secondary-7-8 {
  border-bottom: 7.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-8 {
  border-bottom: 7.8px solid #ff0f00;
}

.border-b-info-7-8 {
  border-bottom: 7.8px solid #f6c31c;
}

.border-b-white-7-8 {
  border-bottom: 7.8px solid #f7f8f7;
}

.border-b-green-7-8 {
  border-bottom: 7.8px solid #0b8376;
}

.border-b-purple-7-8 {
  border-bottom: 7.8px solid #755ae2;
}

.border-b-orange-7-8 {
  border-bottom: 7.8px solid #ea872d;
}

.border-b-black-7-8 {
  border-bottom: 7.8px solid #020202;
}

.border-b-light-gray-7-8 {
  border-bottom: 7.8px solid #fafafa;
}

.border-b-deep-green-7-8 {
  border-bottom: 7.8px solid #014345;
}

.border-l-primary-7-8 {
  border-left: 7.8px solid #cccccc;
}

.border-l-secondary-7-8 {
  border-left: 7.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-8 {
  border-left: 7.8px solid #ff0f00;
}

.border-l-info-7-8 {
  border-left: 7.8px solid #f6c31c;
}

.border-l-white-7-8 {
  border-left: 7.8px solid #f7f8f7;
}

.border-l-green-7-8 {
  border-left: 7.8px solid #0b8376;
}

.border-l-purple-7-8 {
  border-left: 7.8px solid #755ae2;
}

.border-l-orange-7-8 {
  border-left: 7.8px solid #ea872d;
}

.border-l-black-7-8 {
  border-left: 7.8px solid #020202;
}

.border-l-light-gray-7-8 {
  border-left: 7.8px solid #fafafa;
}

.border-l-deep-green-7-8 {
  border-left: 7.8px solid #014345;
}

.border-r-primary-7-8 {
  border-right: 7.8px solid #cccccc;
}

.border-r-secondary-7-8 {
  border-right: 7.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-8 {
  border-right: 7.8px solid #ff0f00;
}

.border-r-info-7-8 {
  border-right: 7.8px solid #f6c31c;
}

.border-r-white-7-8 {
  border-right: 7.8px solid #f7f8f7;
}

.border-r-green-7-8 {
  border-right: 7.8px solid #0b8376;
}

.border-r-purple-7-8 {
  border-right: 7.8px solid #755ae2;
}

.border-r-orange-7-8 {
  border-right: 7.8px solid #ea872d;
}

.border-r-black-7-8 {
  border-right: 7.8px solid #020202;
}

.border-r-light-gray-7-8 {
  border-right: 7.8px solid #fafafa;
}

.border-r-deep-green-7-8 {
  border-right: 7.8px solid #014345;
}

.border-primary-7-9 {
  border: 7.9px solid #cccccc;
}

.border-secondary-7-9 {
  border: 7.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-7-9 {
  border: 7.9px solid #ff0f00;
}

.border-info-7-9 {
  border: 7.9px solid #f6c31c;
}

.border-white-7-9 {
  border: 7.9px solid #f7f8f7;
}

.border-green-7-9 {
  border: 7.9px solid #0b8376;
}

.border-purple-7-9 {
  border: 7.9px solid #755ae2;
}

.border-orange-7-9 {
  border: 7.9px solid #ea872d;
}

.border-black-7-9 {
  border: 7.9px solid #020202;
}

.border-light-gray-7-9 {
  border: 7.9px solid #fafafa;
}

.border-deep-green-7-9 {
  border: 7.9px solid #014345;
}

.border-t-primary-7-9 {
  border-top: 7.9px solid #cccccc;
}

.border-t-secondary-7-9 {
  border-top: 7.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-7-9 {
  border-top: 7.9px solid #ff0f00;
}

.border-t-info-7-9 {
  border-top: 7.9px solid #f6c31c;
}

.border-t-white-7-9 {
  border-top: 7.9px solid #f7f8f7;
}

.border-t-green-7-9 {
  border-top: 7.9px solid #0b8376;
}

.border-t-purple-7-9 {
  border-top: 7.9px solid #755ae2;
}

.border-t-orange-7-9 {
  border-top: 7.9px solid #ea872d;
}

.border-t-black-7-9 {
  border-top: 7.9px solid #020202;
}

.border-t-light-gray-7-9 {
  border-top: 7.9px solid #fafafa;
}

.border-t-deep-green-7-9 {
  border-top: 7.9px solid #014345;
}

.border-b-primary-7-9 {
  border-bottom: 7.9px solid #cccccc;
}

.border-b-secondary-7-9 {
  border-bottom: 7.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-7-9 {
  border-bottom: 7.9px solid #ff0f00;
}

.border-b-info-7-9 {
  border-bottom: 7.9px solid #f6c31c;
}

.border-b-white-7-9 {
  border-bottom: 7.9px solid #f7f8f7;
}

.border-b-green-7-9 {
  border-bottom: 7.9px solid #0b8376;
}

.border-b-purple-7-9 {
  border-bottom: 7.9px solid #755ae2;
}

.border-b-orange-7-9 {
  border-bottom: 7.9px solid #ea872d;
}

.border-b-black-7-9 {
  border-bottom: 7.9px solid #020202;
}

.border-b-light-gray-7-9 {
  border-bottom: 7.9px solid #fafafa;
}

.border-b-deep-green-7-9 {
  border-bottom: 7.9px solid #014345;
}

.border-l-primary-7-9 {
  border-left: 7.9px solid #cccccc;
}

.border-l-secondary-7-9 {
  border-left: 7.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-7-9 {
  border-left: 7.9px solid #ff0f00;
}

.border-l-info-7-9 {
  border-left: 7.9px solid #f6c31c;
}

.border-l-white-7-9 {
  border-left: 7.9px solid #f7f8f7;
}

.border-l-green-7-9 {
  border-left: 7.9px solid #0b8376;
}

.border-l-purple-7-9 {
  border-left: 7.9px solid #755ae2;
}

.border-l-orange-7-9 {
  border-left: 7.9px solid #ea872d;
}

.border-l-black-7-9 {
  border-left: 7.9px solid #020202;
}

.border-l-light-gray-7-9 {
  border-left: 7.9px solid #fafafa;
}

.border-l-deep-green-7-9 {
  border-left: 7.9px solid #014345;
}

.border-r-primary-7-9 {
  border-right: 7.9px solid #cccccc;
}

.border-r-secondary-7-9 {
  border-right: 7.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-7-9 {
  border-right: 7.9px solid #ff0f00;
}

.border-r-info-7-9 {
  border-right: 7.9px solid #f6c31c;
}

.border-r-white-7-9 {
  border-right: 7.9px solid #f7f8f7;
}

.border-r-green-7-9 {
  border-right: 7.9px solid #0b8376;
}

.border-r-purple-7-9 {
  border-right: 7.9px solid #755ae2;
}

.border-r-orange-7-9 {
  border-right: 7.9px solid #ea872d;
}

.border-r-black-7-9 {
  border-right: 7.9px solid #020202;
}

.border-r-light-gray-7-9 {
  border-right: 7.9px solid #fafafa;
}

.border-r-deep-green-7-9 {
  border-right: 7.9px solid #014345;
}

.border-primary-8 {
  border: 8px solid #cccccc;
}

.border-secondary-8 {
  border: 8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8 {
  border: 8px solid #ff0f00;
}

.border-info-8 {
  border: 8px solid #f6c31c;
}

.border-white-8 {
  border: 8px solid #f7f8f7;
}

.border-green-8 {
  border: 8px solid #0b8376;
}

.border-purple-8 {
  border: 8px solid #755ae2;
}

.border-orange-8 {
  border: 8px solid #ea872d;
}

.border-black-8 {
  border: 8px solid #020202;
}

.border-light-gray-8 {
  border: 8px solid #fafafa;
}

.border-deep-green-8 {
  border: 8px solid #014345;
}

.border-t-primary-8 {
  border-top: 8px solid #cccccc;
}

.border-t-secondary-8 {
  border-top: 8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8 {
  border-top: 8px solid #ff0f00;
}

.border-t-info-8 {
  border-top: 8px solid #f6c31c;
}

.border-t-white-8 {
  border-top: 8px solid #f7f8f7;
}

.border-t-green-8 {
  border-top: 8px solid #0b8376;
}

.border-t-purple-8 {
  border-top: 8px solid #755ae2;
}

.border-t-orange-8 {
  border-top: 8px solid #ea872d;
}

.border-t-black-8 {
  border-top: 8px solid #020202;
}

.border-t-light-gray-8 {
  border-top: 8px solid #fafafa;
}

.border-t-deep-green-8 {
  border-top: 8px solid #014345;
}

.border-b-primary-8 {
  border-bottom: 8px solid #cccccc;
}

.border-b-secondary-8 {
  border-bottom: 8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8 {
  border-bottom: 8px solid #ff0f00;
}

.border-b-info-8 {
  border-bottom: 8px solid #f6c31c;
}

.border-b-white-8 {
  border-bottom: 8px solid #f7f8f7;
}

.border-b-green-8 {
  border-bottom: 8px solid #0b8376;
}

.border-b-purple-8 {
  border-bottom: 8px solid #755ae2;
}

.border-b-orange-8 {
  border-bottom: 8px solid #ea872d;
}

.border-b-black-8 {
  border-bottom: 8px solid #020202;
}

.border-b-light-gray-8 {
  border-bottom: 8px solid #fafafa;
}

.border-b-deep-green-8 {
  border-bottom: 8px solid #014345;
}

.border_l-primary-8 {
  border-left: 8px solid #cccccc;
}

.border_l-secondary-8 {
  border-left: 8px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-8 {
  border-left: 8px solid #ff0f00;
}

.border_l-info-8 {
  border-left: 8px solid #f6c31c;
}

.border_l-white-8 {
  border-left: 8px solid #f7f8f7;
}

.border_l-green-8 {
  border-left: 8px solid #0b8376;
}

.border_l-purple-8 {
  border-left: 8px solid #755ae2;
}

.border_l-orange-8 {
  border-left: 8px solid #ea872d;
}

.border_l-black-8 {
  border-left: 8px solid #020202;
}

.border_l-light-gray-8 {
  border-left: 8px solid #fafafa;
}

.border_l-deep-green-8 {
  border-left: 8px solid #014345;
}

.border-r-primary-8 {
  border-right: 8px solid #cccccc;
}

.border-r-secondary-8 {
  border-right: 8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8 {
  border-right: 8px solid #ff0f00;
}

.border-r-info-8 {
  border-right: 8px solid #f6c31c;
}

.border-r-white-8 {
  border-right: 8px solid #f7f8f7;
}

.border-r-green-8 {
  border-right: 8px solid #0b8376;
}

.border-r-purple-8 {
  border-right: 8px solid #755ae2;
}

.border-r-orange-8 {
  border-right: 8px solid #ea872d;
}

.border-r-black-8 {
  border-right: 8px solid #020202;
}

.border-r-light-gray-8 {
  border-right: 8px solid #fafafa;
}

.border-r-deep-green-8 {
  border-right: 8px solid #014345;
}

.border-primary-8-1 {
  border: 8.1px solid #cccccc;
}

.border-secondary-8-1 {
  border: 8.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-1 {
  border: 8.1px solid #ff0f00;
}

.border-info-8-1 {
  border: 8.1px solid #f6c31c;
}

.border-white-8-1 {
  border: 8.1px solid #f7f8f7;
}

.border-green-8-1 {
  border: 8.1px solid #0b8376;
}

.border-purple-8-1 {
  border: 8.1px solid #755ae2;
}

.border-orange-8-1 {
  border: 8.1px solid #ea872d;
}

.border-black-8-1 {
  border: 8.1px solid #020202;
}

.border-light-gray-8-1 {
  border: 8.1px solid #fafafa;
}

.border-deep-green-8-1 {
  border: 8.1px solid #014345;
}

.border-t-primary-8-1 {
  border-top: 8.1px solid #cccccc;
}

.border-t-secondary-8-1 {
  border-top: 8.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-1 {
  border-top: 8.1px solid #ff0f00;
}

.border-t-info-8-1 {
  border-top: 8.1px solid #f6c31c;
}

.border-t-white-8-1 {
  border-top: 8.1px solid #f7f8f7;
}

.border-t-green-8-1 {
  border-top: 8.1px solid #0b8376;
}

.border-t-purple-8-1 {
  border-top: 8.1px solid #755ae2;
}

.border-t-orange-8-1 {
  border-top: 8.1px solid #ea872d;
}

.border-t-black-8-1 {
  border-top: 8.1px solid #020202;
}

.border-t-light-gray-8-1 {
  border-top: 8.1px solid #fafafa;
}

.border-t-deep-green-8-1 {
  border-top: 8.1px solid #014345;
}

.border-b-primary-8-1 {
  border-bottom: 8.1px solid #cccccc;
}

.border-b-secondary-8-1 {
  border-bottom: 8.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-1 {
  border-bottom: 8.1px solid #ff0f00;
}

.border-b-info-8-1 {
  border-bottom: 8.1px solid #f6c31c;
}

.border-b-white-8-1 {
  border-bottom: 8.1px solid #f7f8f7;
}

.border-b-green-8-1 {
  border-bottom: 8.1px solid #0b8376;
}

.border-b-purple-8-1 {
  border-bottom: 8.1px solid #755ae2;
}

.border-b-orange-8-1 {
  border-bottom: 8.1px solid #ea872d;
}

.border-b-black-8-1 {
  border-bottom: 8.1px solid #020202;
}

.border-b-light-gray-8-1 {
  border-bottom: 8.1px solid #fafafa;
}

.border-b-deep-green-8-1 {
  border-bottom: 8.1px solid #014345;
}

.border-l-primary-8-1 {
  border-left: 8.1px solid #cccccc;
}

.border-l-secondary-8-1 {
  border-left: 8.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-1 {
  border-left: 8.1px solid #ff0f00;
}

.border-l-info-8-1 {
  border-left: 8.1px solid #f6c31c;
}

.border-l-white-8-1 {
  border-left: 8.1px solid #f7f8f7;
}

.border-l-green-8-1 {
  border-left: 8.1px solid #0b8376;
}

.border-l-purple-8-1 {
  border-left: 8.1px solid #755ae2;
}

.border-l-orange-8-1 {
  border-left: 8.1px solid #ea872d;
}

.border-l-black-8-1 {
  border-left: 8.1px solid #020202;
}

.border-l-light-gray-8-1 {
  border-left: 8.1px solid #fafafa;
}

.border-l-deep-green-8-1 {
  border-left: 8.1px solid #014345;
}

.border-r-primary-8-1 {
  border-right: 8.1px solid #cccccc;
}

.border-r-secondary-8-1 {
  border-right: 8.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-1 {
  border-right: 8.1px solid #ff0f00;
}

.border-r-info-8-1 {
  border-right: 8.1px solid #f6c31c;
}

.border-r-white-8-1 {
  border-right: 8.1px solid #f7f8f7;
}

.border-r-green-8-1 {
  border-right: 8.1px solid #0b8376;
}

.border-r-purple-8-1 {
  border-right: 8.1px solid #755ae2;
}

.border-r-orange-8-1 {
  border-right: 8.1px solid #ea872d;
}

.border-r-black-8-1 {
  border-right: 8.1px solid #020202;
}

.border-r-light-gray-8-1 {
  border-right: 8.1px solid #fafafa;
}

.border-r-deep-green-8-1 {
  border-right: 8.1px solid #014345;
}

.border-primary-8-2 {
  border: 8.2px solid #cccccc;
}

.border-secondary-8-2 {
  border: 8.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-2 {
  border: 8.2px solid #ff0f00;
}

.border-info-8-2 {
  border: 8.2px solid #f6c31c;
}

.border-white-8-2 {
  border: 8.2px solid #f7f8f7;
}

.border-green-8-2 {
  border: 8.2px solid #0b8376;
}

.border-purple-8-2 {
  border: 8.2px solid #755ae2;
}

.border-orange-8-2 {
  border: 8.2px solid #ea872d;
}

.border-black-8-2 {
  border: 8.2px solid #020202;
}

.border-light-gray-8-2 {
  border: 8.2px solid #fafafa;
}

.border-deep-green-8-2 {
  border: 8.2px solid #014345;
}

.border-t-primary-8-2 {
  border-top: 8.2px solid #cccccc;
}

.border-t-secondary-8-2 {
  border-top: 8.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-2 {
  border-top: 8.2px solid #ff0f00;
}

.border-t-info-8-2 {
  border-top: 8.2px solid #f6c31c;
}

.border-t-white-8-2 {
  border-top: 8.2px solid #f7f8f7;
}

.border-t-green-8-2 {
  border-top: 8.2px solid #0b8376;
}

.border-t-purple-8-2 {
  border-top: 8.2px solid #755ae2;
}

.border-t-orange-8-2 {
  border-top: 8.2px solid #ea872d;
}

.border-t-black-8-2 {
  border-top: 8.2px solid #020202;
}

.border-t-light-gray-8-2 {
  border-top: 8.2px solid #fafafa;
}

.border-t-deep-green-8-2 {
  border-top: 8.2px solid #014345;
}

.border-b-primary-8-2 {
  border-bottom: 8.2px solid #cccccc;
}

.border-b-secondary-8-2 {
  border-bottom: 8.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-2 {
  border-bottom: 8.2px solid #ff0f00;
}

.border-b-info-8-2 {
  border-bottom: 8.2px solid #f6c31c;
}

.border-b-white-8-2 {
  border-bottom: 8.2px solid #f7f8f7;
}

.border-b-green-8-2 {
  border-bottom: 8.2px solid #0b8376;
}

.border-b-purple-8-2 {
  border-bottom: 8.2px solid #755ae2;
}

.border-b-orange-8-2 {
  border-bottom: 8.2px solid #ea872d;
}

.border-b-black-8-2 {
  border-bottom: 8.2px solid #020202;
}

.border-b-light-gray-8-2 {
  border-bottom: 8.2px solid #fafafa;
}

.border-b-deep-green-8-2 {
  border-bottom: 8.2px solid #014345;
}

.border-l-primary-8-2 {
  border-left: 8.2px solid #cccccc;
}

.border-l-secondary-8-2 {
  border-left: 8.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-2 {
  border-left: 8.2px solid #ff0f00;
}

.border-l-info-8-2 {
  border-left: 8.2px solid #f6c31c;
}

.border-l-white-8-2 {
  border-left: 8.2px solid #f7f8f7;
}

.border-l-green-8-2 {
  border-left: 8.2px solid #0b8376;
}

.border-l-purple-8-2 {
  border-left: 8.2px solid #755ae2;
}

.border-l-orange-8-2 {
  border-left: 8.2px solid #ea872d;
}

.border-l-black-8-2 {
  border-left: 8.2px solid #020202;
}

.border-l-light-gray-8-2 {
  border-left: 8.2px solid #fafafa;
}

.border-l-deep-green-8-2 {
  border-left: 8.2px solid #014345;
}

.border-r-primary-8-2 {
  border-right: 8.2px solid #cccccc;
}

.border-r-secondary-8-2 {
  border-right: 8.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-2 {
  border-right: 8.2px solid #ff0f00;
}

.border-r-info-8-2 {
  border-right: 8.2px solid #f6c31c;
}

.border-r-white-8-2 {
  border-right: 8.2px solid #f7f8f7;
}

.border-r-green-8-2 {
  border-right: 8.2px solid #0b8376;
}

.border-r-purple-8-2 {
  border-right: 8.2px solid #755ae2;
}

.border-r-orange-8-2 {
  border-right: 8.2px solid #ea872d;
}

.border-r-black-8-2 {
  border-right: 8.2px solid #020202;
}

.border-r-light-gray-8-2 {
  border-right: 8.2px solid #fafafa;
}

.border-r-deep-green-8-2 {
  border-right: 8.2px solid #014345;
}

.border-primary-8-3 {
  border: 8.3px solid #cccccc;
}

.border-secondary-8-3 {
  border: 8.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-3 {
  border: 8.3px solid #ff0f00;
}

.border-info-8-3 {
  border: 8.3px solid #f6c31c;
}

.border-white-8-3 {
  border: 8.3px solid #f7f8f7;
}

.border-green-8-3 {
  border: 8.3px solid #0b8376;
}

.border-purple-8-3 {
  border: 8.3px solid #755ae2;
}

.border-orange-8-3 {
  border: 8.3px solid #ea872d;
}

.border-black-8-3 {
  border: 8.3px solid #020202;
}

.border-light-gray-8-3 {
  border: 8.3px solid #fafafa;
}

.border-deep-green-8-3 {
  border: 8.3px solid #014345;
}

.border-t-primary-8-3 {
  border-top: 8.3px solid #cccccc;
}

.border-t-secondary-8-3 {
  border-top: 8.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-3 {
  border-top: 8.3px solid #ff0f00;
}

.border-t-info-8-3 {
  border-top: 8.3px solid #f6c31c;
}

.border-t-white-8-3 {
  border-top: 8.3px solid #f7f8f7;
}

.border-t-green-8-3 {
  border-top: 8.3px solid #0b8376;
}

.border-t-purple-8-3 {
  border-top: 8.3px solid #755ae2;
}

.border-t-orange-8-3 {
  border-top: 8.3px solid #ea872d;
}

.border-t-black-8-3 {
  border-top: 8.3px solid #020202;
}

.border-t-light-gray-8-3 {
  border-top: 8.3px solid #fafafa;
}

.border-t-deep-green-8-3 {
  border-top: 8.3px solid #014345;
}

.border-b-primary-8-3 {
  border-bottom: 8.3px solid #cccccc;
}

.border-b-secondary-8-3 {
  border-bottom: 8.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-3 {
  border-bottom: 8.3px solid #ff0f00;
}

.border-b-info-8-3 {
  border-bottom: 8.3px solid #f6c31c;
}

.border-b-white-8-3 {
  border-bottom: 8.3px solid #f7f8f7;
}

.border-b-green-8-3 {
  border-bottom: 8.3px solid #0b8376;
}

.border-b-purple-8-3 {
  border-bottom: 8.3px solid #755ae2;
}

.border-b-orange-8-3 {
  border-bottom: 8.3px solid #ea872d;
}

.border-b-black-8-3 {
  border-bottom: 8.3px solid #020202;
}

.border-b-light-gray-8-3 {
  border-bottom: 8.3px solid #fafafa;
}

.border-b-deep-green-8-3 {
  border-bottom: 8.3px solid #014345;
}

.border-l-primary-8-3 {
  border-left: 8.3px solid #cccccc;
}

.border-l-secondary-8-3 {
  border-left: 8.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-3 {
  border-left: 8.3px solid #ff0f00;
}

.border-l-info-8-3 {
  border-left: 8.3px solid #f6c31c;
}

.border-l-white-8-3 {
  border-left: 8.3px solid #f7f8f7;
}

.border-l-green-8-3 {
  border-left: 8.3px solid #0b8376;
}

.border-l-purple-8-3 {
  border-left: 8.3px solid #755ae2;
}

.border-l-orange-8-3 {
  border-left: 8.3px solid #ea872d;
}

.border-l-black-8-3 {
  border-left: 8.3px solid #020202;
}

.border-l-light-gray-8-3 {
  border-left: 8.3px solid #fafafa;
}

.border-l-deep-green-8-3 {
  border-left: 8.3px solid #014345;
}

.border-r-primary-8-3 {
  border-right: 8.3px solid #cccccc;
}

.border-r-secondary-8-3 {
  border-right: 8.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-3 {
  border-right: 8.3px solid #ff0f00;
}

.border-r-info-8-3 {
  border-right: 8.3px solid #f6c31c;
}

.border-r-white-8-3 {
  border-right: 8.3px solid #f7f8f7;
}

.border-r-green-8-3 {
  border-right: 8.3px solid #0b8376;
}

.border-r-purple-8-3 {
  border-right: 8.3px solid #755ae2;
}

.border-r-orange-8-3 {
  border-right: 8.3px solid #ea872d;
}

.border-r-black-8-3 {
  border-right: 8.3px solid #020202;
}

.border-r-light-gray-8-3 {
  border-right: 8.3px solid #fafafa;
}

.border-r-deep-green-8-3 {
  border-right: 8.3px solid #014345;
}

.border-primary-8-4 {
  border: 8.4px solid #cccccc;
}

.border-secondary-8-4 {
  border: 8.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-4 {
  border: 8.4px solid #ff0f00;
}

.border-info-8-4 {
  border: 8.4px solid #f6c31c;
}

.border-white-8-4 {
  border: 8.4px solid #f7f8f7;
}

.border-green-8-4 {
  border: 8.4px solid #0b8376;
}

.border-purple-8-4 {
  border: 8.4px solid #755ae2;
}

.border-orange-8-4 {
  border: 8.4px solid #ea872d;
}

.border-black-8-4 {
  border: 8.4px solid #020202;
}

.border-light-gray-8-4 {
  border: 8.4px solid #fafafa;
}

.border-deep-green-8-4 {
  border: 8.4px solid #014345;
}

.border-t-primary-8-4 {
  border-top: 8.4px solid #cccccc;
}

.border-t-secondary-8-4 {
  border-top: 8.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-4 {
  border-top: 8.4px solid #ff0f00;
}

.border-t-info-8-4 {
  border-top: 8.4px solid #f6c31c;
}

.border-t-white-8-4 {
  border-top: 8.4px solid #f7f8f7;
}

.border-t-green-8-4 {
  border-top: 8.4px solid #0b8376;
}

.border-t-purple-8-4 {
  border-top: 8.4px solid #755ae2;
}

.border-t-orange-8-4 {
  border-top: 8.4px solid #ea872d;
}

.border-t-black-8-4 {
  border-top: 8.4px solid #020202;
}

.border-t-light-gray-8-4 {
  border-top: 8.4px solid #fafafa;
}

.border-t-deep-green-8-4 {
  border-top: 8.4px solid #014345;
}

.border-b-primary-8-4 {
  border-bottom: 8.4px solid #cccccc;
}

.border-b-secondary-8-4 {
  border-bottom: 8.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-4 {
  border-bottom: 8.4px solid #ff0f00;
}

.border-b-info-8-4 {
  border-bottom: 8.4px solid #f6c31c;
}

.border-b-white-8-4 {
  border-bottom: 8.4px solid #f7f8f7;
}

.border-b-green-8-4 {
  border-bottom: 8.4px solid #0b8376;
}

.border-b-purple-8-4 {
  border-bottom: 8.4px solid #755ae2;
}

.border-b-orange-8-4 {
  border-bottom: 8.4px solid #ea872d;
}

.border-b-black-8-4 {
  border-bottom: 8.4px solid #020202;
}

.border-b-light-gray-8-4 {
  border-bottom: 8.4px solid #fafafa;
}

.border-b-deep-green-8-4 {
  border-bottom: 8.4px solid #014345;
}

.border-l-primary-8-4 {
  border-left: 8.4px solid #cccccc;
}

.border-l-secondary-8-4 {
  border-left: 8.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-4 {
  border-left: 8.4px solid #ff0f00;
}

.border-l-info-8-4 {
  border-left: 8.4px solid #f6c31c;
}

.border-l-white-8-4 {
  border-left: 8.4px solid #f7f8f7;
}

.border-l-green-8-4 {
  border-left: 8.4px solid #0b8376;
}

.border-l-purple-8-4 {
  border-left: 8.4px solid #755ae2;
}

.border-l-orange-8-4 {
  border-left: 8.4px solid #ea872d;
}

.border-l-black-8-4 {
  border-left: 8.4px solid #020202;
}

.border-l-light-gray-8-4 {
  border-left: 8.4px solid #fafafa;
}

.border-l-deep-green-8-4 {
  border-left: 8.4px solid #014345;
}

.border-r-primary-8-4 {
  border-right: 8.4px solid #cccccc;
}

.border-r-secondary-8-4 {
  border-right: 8.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-4 {
  border-right: 8.4px solid #ff0f00;
}

.border-r-info-8-4 {
  border-right: 8.4px solid #f6c31c;
}

.border-r-white-8-4 {
  border-right: 8.4px solid #f7f8f7;
}

.border-r-green-8-4 {
  border-right: 8.4px solid #0b8376;
}

.border-r-purple-8-4 {
  border-right: 8.4px solid #755ae2;
}

.border-r-orange-8-4 {
  border-right: 8.4px solid #ea872d;
}

.border-r-black-8-4 {
  border-right: 8.4px solid #020202;
}

.border-r-light-gray-8-4 {
  border-right: 8.4px solid #fafafa;
}

.border-r-deep-green-8-4 {
  border-right: 8.4px solid #014345;
}

.border-primary-8-5 {
  border: 8.5px solid #cccccc;
}

.border-secondary-8-5 {
  border: 8.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-5 {
  border: 8.5px solid #ff0f00;
}

.border-info-8-5 {
  border: 8.5px solid #f6c31c;
}

.border-white-8-5 {
  border: 8.5px solid #f7f8f7;
}

.border-green-8-5 {
  border: 8.5px solid #0b8376;
}

.border-purple-8-5 {
  border: 8.5px solid #755ae2;
}

.border-orange-8-5 {
  border: 8.5px solid #ea872d;
}

.border-black-8-5 {
  border: 8.5px solid #020202;
}

.border-light-gray-8-5 {
  border: 8.5px solid #fafafa;
}

.border-deep-green-8-5 {
  border: 8.5px solid #014345;
}

.border-t-primary-8-5 {
  border-top: 8.5px solid #cccccc;
}

.border-t-secondary-8-5 {
  border-top: 8.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-5 {
  border-top: 8.5px solid #ff0f00;
}

.border-t-info-8-5 {
  border-top: 8.5px solid #f6c31c;
}

.border-t-white-8-5 {
  border-top: 8.5px solid #f7f8f7;
}

.border-t-green-8-5 {
  border-top: 8.5px solid #0b8376;
}

.border-t-purple-8-5 {
  border-top: 8.5px solid #755ae2;
}

.border-t-orange-8-5 {
  border-top: 8.5px solid #ea872d;
}

.border-t-black-8-5 {
  border-top: 8.5px solid #020202;
}

.border-t-light-gray-8-5 {
  border-top: 8.5px solid #fafafa;
}

.border-t-deep-green-8-5 {
  border-top: 8.5px solid #014345;
}

.border-b-primary-8-5 {
  border-bottom: 8.5px solid #cccccc;
}

.border-b-secondary-8-5 {
  border-bottom: 8.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-5 {
  border-bottom: 8.5px solid #ff0f00;
}

.border-b-info-8-5 {
  border-bottom: 8.5px solid #f6c31c;
}

.border-b-white-8-5 {
  border-bottom: 8.5px solid #f7f8f7;
}

.border-b-green-8-5 {
  border-bottom: 8.5px solid #0b8376;
}

.border-b-purple-8-5 {
  border-bottom: 8.5px solid #755ae2;
}

.border-b-orange-8-5 {
  border-bottom: 8.5px solid #ea872d;
}

.border-b-black-8-5 {
  border-bottom: 8.5px solid #020202;
}

.border-b-light-gray-8-5 {
  border-bottom: 8.5px solid #fafafa;
}

.border-b-deep-green-8-5 {
  border-bottom: 8.5px solid #014345;
}

.border-l-primary-8-5 {
  border-left: 8.5px solid #cccccc;
}

.border-l-secondary-8-5 {
  border-left: 8.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-5 {
  border-left: 8.5px solid #ff0f00;
}

.border-l-info-8-5 {
  border-left: 8.5px solid #f6c31c;
}

.border-l-white-8-5 {
  border-left: 8.5px solid #f7f8f7;
}

.border-l-green-8-5 {
  border-left: 8.5px solid #0b8376;
}

.border-l-purple-8-5 {
  border-left: 8.5px solid #755ae2;
}

.border-l-orange-8-5 {
  border-left: 8.5px solid #ea872d;
}

.border-l-black-8-5 {
  border-left: 8.5px solid #020202;
}

.border-l-light-gray-8-5 {
  border-left: 8.5px solid #fafafa;
}

.border-l-deep-green-8-5 {
  border-left: 8.5px solid #014345;
}

.border-r-primary-8-5 {
  border-right: 8.5px solid #cccccc;
}

.border-r-secondary-8-5 {
  border-right: 8.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-5 {
  border-right: 8.5px solid #ff0f00;
}

.border-r-info-8-5 {
  border-right: 8.5px solid #f6c31c;
}

.border-r-white-8-5 {
  border-right: 8.5px solid #f7f8f7;
}

.border-r-green-8-5 {
  border-right: 8.5px solid #0b8376;
}

.border-r-purple-8-5 {
  border-right: 8.5px solid #755ae2;
}

.border-r-orange-8-5 {
  border-right: 8.5px solid #ea872d;
}

.border-r-black-8-5 {
  border-right: 8.5px solid #020202;
}

.border-r-light-gray-8-5 {
  border-right: 8.5px solid #fafafa;
}

.border-r-deep-green-8-5 {
  border-right: 8.5px solid #014345;
}

.border-primary-8-6 {
  border: 8.6px solid #cccccc;
}

.border-secondary-8-6 {
  border: 8.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-6 {
  border: 8.6px solid #ff0f00;
}

.border-info-8-6 {
  border: 8.6px solid #f6c31c;
}

.border-white-8-6 {
  border: 8.6px solid #f7f8f7;
}

.border-green-8-6 {
  border: 8.6px solid #0b8376;
}

.border-purple-8-6 {
  border: 8.6px solid #755ae2;
}

.border-orange-8-6 {
  border: 8.6px solid #ea872d;
}

.border-black-8-6 {
  border: 8.6px solid #020202;
}

.border-light-gray-8-6 {
  border: 8.6px solid #fafafa;
}

.border-deep-green-8-6 {
  border: 8.6px solid #014345;
}

.border-t-primary-8-6 {
  border-top: 8.6px solid #cccccc;
}

.border-t-secondary-8-6 {
  border-top: 8.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-6 {
  border-top: 8.6px solid #ff0f00;
}

.border-t-info-8-6 {
  border-top: 8.6px solid #f6c31c;
}

.border-t-white-8-6 {
  border-top: 8.6px solid #f7f8f7;
}

.border-t-green-8-6 {
  border-top: 8.6px solid #0b8376;
}

.border-t-purple-8-6 {
  border-top: 8.6px solid #755ae2;
}

.border-t-orange-8-6 {
  border-top: 8.6px solid #ea872d;
}

.border-t-black-8-6 {
  border-top: 8.6px solid #020202;
}

.border-t-light-gray-8-6 {
  border-top: 8.6px solid #fafafa;
}

.border-t-deep-green-8-6 {
  border-top: 8.6px solid #014345;
}

.border-b-primary-8-6 {
  border-bottom: 8.6px solid #cccccc;
}

.border-b-secondary-8-6 {
  border-bottom: 8.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-6 {
  border-bottom: 8.6px solid #ff0f00;
}

.border-b-info-8-6 {
  border-bottom: 8.6px solid #f6c31c;
}

.border-b-white-8-6 {
  border-bottom: 8.6px solid #f7f8f7;
}

.border-b-green-8-6 {
  border-bottom: 8.6px solid #0b8376;
}

.border-b-purple-8-6 {
  border-bottom: 8.6px solid #755ae2;
}

.border-b-orange-8-6 {
  border-bottom: 8.6px solid #ea872d;
}

.border-b-black-8-6 {
  border-bottom: 8.6px solid #020202;
}

.border-b-light-gray-8-6 {
  border-bottom: 8.6px solid #fafafa;
}

.border-b-deep-green-8-6 {
  border-bottom: 8.6px solid #014345;
}

.border-l-primary-8-6 {
  border-left: 8.6px solid #cccccc;
}

.border-l-secondary-8-6 {
  border-left: 8.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-6 {
  border-left: 8.6px solid #ff0f00;
}

.border-l-info-8-6 {
  border-left: 8.6px solid #f6c31c;
}

.border-l-white-8-6 {
  border-left: 8.6px solid #f7f8f7;
}

.border-l-green-8-6 {
  border-left: 8.6px solid #0b8376;
}

.border-l-purple-8-6 {
  border-left: 8.6px solid #755ae2;
}

.border-l-orange-8-6 {
  border-left: 8.6px solid #ea872d;
}

.border-l-black-8-6 {
  border-left: 8.6px solid #020202;
}

.border-l-light-gray-8-6 {
  border-left: 8.6px solid #fafafa;
}

.border-l-deep-green-8-6 {
  border-left: 8.6px solid #014345;
}

.border-r-primary-8-6 {
  border-right: 8.6px solid #cccccc;
}

.border-r-secondary-8-6 {
  border-right: 8.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-6 {
  border-right: 8.6px solid #ff0f00;
}

.border-r-info-8-6 {
  border-right: 8.6px solid #f6c31c;
}

.border-r-white-8-6 {
  border-right: 8.6px solid #f7f8f7;
}

.border-r-green-8-6 {
  border-right: 8.6px solid #0b8376;
}

.border-r-purple-8-6 {
  border-right: 8.6px solid #755ae2;
}

.border-r-orange-8-6 {
  border-right: 8.6px solid #ea872d;
}

.border-r-black-8-6 {
  border-right: 8.6px solid #020202;
}

.border-r-light-gray-8-6 {
  border-right: 8.6px solid #fafafa;
}

.border-r-deep-green-8-6 {
  border-right: 8.6px solid #014345;
}

.border-primary-8-7 {
  border: 8.7px solid #cccccc;
}

.border-secondary-8-7 {
  border: 8.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-7 {
  border: 8.7px solid #ff0f00;
}

.border-info-8-7 {
  border: 8.7px solid #f6c31c;
}

.border-white-8-7 {
  border: 8.7px solid #f7f8f7;
}

.border-green-8-7 {
  border: 8.7px solid #0b8376;
}

.border-purple-8-7 {
  border: 8.7px solid #755ae2;
}

.border-orange-8-7 {
  border: 8.7px solid #ea872d;
}

.border-black-8-7 {
  border: 8.7px solid #020202;
}

.border-light-gray-8-7 {
  border: 8.7px solid #fafafa;
}

.border-deep-green-8-7 {
  border: 8.7px solid #014345;
}

.border-t-primary-8-7 {
  border-top: 8.7px solid #cccccc;
}

.border-t-secondary-8-7 {
  border-top: 8.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-7 {
  border-top: 8.7px solid #ff0f00;
}

.border-t-info-8-7 {
  border-top: 8.7px solid #f6c31c;
}

.border-t-white-8-7 {
  border-top: 8.7px solid #f7f8f7;
}

.border-t-green-8-7 {
  border-top: 8.7px solid #0b8376;
}

.border-t-purple-8-7 {
  border-top: 8.7px solid #755ae2;
}

.border-t-orange-8-7 {
  border-top: 8.7px solid #ea872d;
}

.border-t-black-8-7 {
  border-top: 8.7px solid #020202;
}

.border-t-light-gray-8-7 {
  border-top: 8.7px solid #fafafa;
}

.border-t-deep-green-8-7 {
  border-top: 8.7px solid #014345;
}

.border-b-primary-8-7 {
  border-bottom: 8.7px solid #cccccc;
}

.border-b-secondary-8-7 {
  border-bottom: 8.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-7 {
  border-bottom: 8.7px solid #ff0f00;
}

.border-b-info-8-7 {
  border-bottom: 8.7px solid #f6c31c;
}

.border-b-white-8-7 {
  border-bottom: 8.7px solid #f7f8f7;
}

.border-b-green-8-7 {
  border-bottom: 8.7px solid #0b8376;
}

.border-b-purple-8-7 {
  border-bottom: 8.7px solid #755ae2;
}

.border-b-orange-8-7 {
  border-bottom: 8.7px solid #ea872d;
}

.border-b-black-8-7 {
  border-bottom: 8.7px solid #020202;
}

.border-b-light-gray-8-7 {
  border-bottom: 8.7px solid #fafafa;
}

.border-b-deep-green-8-7 {
  border-bottom: 8.7px solid #014345;
}

.border-l-primary-8-7 {
  border-left: 8.7px solid #cccccc;
}

.border-l-secondary-8-7 {
  border-left: 8.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-7 {
  border-left: 8.7px solid #ff0f00;
}

.border-l-info-8-7 {
  border-left: 8.7px solid #f6c31c;
}

.border-l-white-8-7 {
  border-left: 8.7px solid #f7f8f7;
}

.border-l-green-8-7 {
  border-left: 8.7px solid #0b8376;
}

.border-l-purple-8-7 {
  border-left: 8.7px solid #755ae2;
}

.border-l-orange-8-7 {
  border-left: 8.7px solid #ea872d;
}

.border-l-black-8-7 {
  border-left: 8.7px solid #020202;
}

.border-l-light-gray-8-7 {
  border-left: 8.7px solid #fafafa;
}

.border-l-deep-green-8-7 {
  border-left: 8.7px solid #014345;
}

.border-r-primary-8-7 {
  border-right: 8.7px solid #cccccc;
}

.border-r-secondary-8-7 {
  border-right: 8.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-7 {
  border-right: 8.7px solid #ff0f00;
}

.border-r-info-8-7 {
  border-right: 8.7px solid #f6c31c;
}

.border-r-white-8-7 {
  border-right: 8.7px solid #f7f8f7;
}

.border-r-green-8-7 {
  border-right: 8.7px solid #0b8376;
}

.border-r-purple-8-7 {
  border-right: 8.7px solid #755ae2;
}

.border-r-orange-8-7 {
  border-right: 8.7px solid #ea872d;
}

.border-r-black-8-7 {
  border-right: 8.7px solid #020202;
}

.border-r-light-gray-8-7 {
  border-right: 8.7px solid #fafafa;
}

.border-r-deep-green-8-7 {
  border-right: 8.7px solid #014345;
}

.border-primary-8-8 {
  border: 8.8px solid #cccccc;
}

.border-secondary-8-8 {
  border: 8.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-8 {
  border: 8.8px solid #ff0f00;
}

.border-info-8-8 {
  border: 8.8px solid #f6c31c;
}

.border-white-8-8 {
  border: 8.8px solid #f7f8f7;
}

.border-green-8-8 {
  border: 8.8px solid #0b8376;
}

.border-purple-8-8 {
  border: 8.8px solid #755ae2;
}

.border-orange-8-8 {
  border: 8.8px solid #ea872d;
}

.border-black-8-8 {
  border: 8.8px solid #020202;
}

.border-light-gray-8-8 {
  border: 8.8px solid #fafafa;
}

.border-deep-green-8-8 {
  border: 8.8px solid #014345;
}

.border-t-primary-8-8 {
  border-top: 8.8px solid #cccccc;
}

.border-t-secondary-8-8 {
  border-top: 8.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-8 {
  border-top: 8.8px solid #ff0f00;
}

.border-t-info-8-8 {
  border-top: 8.8px solid #f6c31c;
}

.border-t-white-8-8 {
  border-top: 8.8px solid #f7f8f7;
}

.border-t-green-8-8 {
  border-top: 8.8px solid #0b8376;
}

.border-t-purple-8-8 {
  border-top: 8.8px solid #755ae2;
}

.border-t-orange-8-8 {
  border-top: 8.8px solid #ea872d;
}

.border-t-black-8-8 {
  border-top: 8.8px solid #020202;
}

.border-t-light-gray-8-8 {
  border-top: 8.8px solid #fafafa;
}

.border-t-deep-green-8-8 {
  border-top: 8.8px solid #014345;
}

.border-b-primary-8-8 {
  border-bottom: 8.8px solid #cccccc;
}

.border-b-secondary-8-8 {
  border-bottom: 8.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-8 {
  border-bottom: 8.8px solid #ff0f00;
}

.border-b-info-8-8 {
  border-bottom: 8.8px solid #f6c31c;
}

.border-b-white-8-8 {
  border-bottom: 8.8px solid #f7f8f7;
}

.border-b-green-8-8 {
  border-bottom: 8.8px solid #0b8376;
}

.border-b-purple-8-8 {
  border-bottom: 8.8px solid #755ae2;
}

.border-b-orange-8-8 {
  border-bottom: 8.8px solid #ea872d;
}

.border-b-black-8-8 {
  border-bottom: 8.8px solid #020202;
}

.border-b-light-gray-8-8 {
  border-bottom: 8.8px solid #fafafa;
}

.border-b-deep-green-8-8 {
  border-bottom: 8.8px solid #014345;
}

.border-l-primary-8-8 {
  border-left: 8.8px solid #cccccc;
}

.border-l-secondary-8-8 {
  border-left: 8.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-8 {
  border-left: 8.8px solid #ff0f00;
}

.border-l-info-8-8 {
  border-left: 8.8px solid #f6c31c;
}

.border-l-white-8-8 {
  border-left: 8.8px solid #f7f8f7;
}

.border-l-green-8-8 {
  border-left: 8.8px solid #0b8376;
}

.border-l-purple-8-8 {
  border-left: 8.8px solid #755ae2;
}

.border-l-orange-8-8 {
  border-left: 8.8px solid #ea872d;
}

.border-l-black-8-8 {
  border-left: 8.8px solid #020202;
}

.border-l-light-gray-8-8 {
  border-left: 8.8px solid #fafafa;
}

.border-l-deep-green-8-8 {
  border-left: 8.8px solid #014345;
}

.border-r-primary-8-8 {
  border-right: 8.8px solid #cccccc;
}

.border-r-secondary-8-8 {
  border-right: 8.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-8 {
  border-right: 8.8px solid #ff0f00;
}

.border-r-info-8-8 {
  border-right: 8.8px solid #f6c31c;
}

.border-r-white-8-8 {
  border-right: 8.8px solid #f7f8f7;
}

.border-r-green-8-8 {
  border-right: 8.8px solid #0b8376;
}

.border-r-purple-8-8 {
  border-right: 8.8px solid #755ae2;
}

.border-r-orange-8-8 {
  border-right: 8.8px solid #ea872d;
}

.border-r-black-8-8 {
  border-right: 8.8px solid #020202;
}

.border-r-light-gray-8-8 {
  border-right: 8.8px solid #fafafa;
}

.border-r-deep-green-8-8 {
  border-right: 8.8px solid #014345;
}

.border-primary-8-9 {
  border: 8.9px solid #cccccc;
}

.border-secondary-8-9 {
  border: 8.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-8-9 {
  border: 8.9px solid #ff0f00;
}

.border-info-8-9 {
  border: 8.9px solid #f6c31c;
}

.border-white-8-9 {
  border: 8.9px solid #f7f8f7;
}

.border-green-8-9 {
  border: 8.9px solid #0b8376;
}

.border-purple-8-9 {
  border: 8.9px solid #755ae2;
}

.border-orange-8-9 {
  border: 8.9px solid #ea872d;
}

.border-black-8-9 {
  border: 8.9px solid #020202;
}

.border-light-gray-8-9 {
  border: 8.9px solid #fafafa;
}

.border-deep-green-8-9 {
  border: 8.9px solid #014345;
}

.border-t-primary-8-9 {
  border-top: 8.9px solid #cccccc;
}

.border-t-secondary-8-9 {
  border-top: 8.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-8-9 {
  border-top: 8.9px solid #ff0f00;
}

.border-t-info-8-9 {
  border-top: 8.9px solid #f6c31c;
}

.border-t-white-8-9 {
  border-top: 8.9px solid #f7f8f7;
}

.border-t-green-8-9 {
  border-top: 8.9px solid #0b8376;
}

.border-t-purple-8-9 {
  border-top: 8.9px solid #755ae2;
}

.border-t-orange-8-9 {
  border-top: 8.9px solid #ea872d;
}

.border-t-black-8-9 {
  border-top: 8.9px solid #020202;
}

.border-t-light-gray-8-9 {
  border-top: 8.9px solid #fafafa;
}

.border-t-deep-green-8-9 {
  border-top: 8.9px solid #014345;
}

.border-b-primary-8-9 {
  border-bottom: 8.9px solid #cccccc;
}

.border-b-secondary-8-9 {
  border-bottom: 8.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-8-9 {
  border-bottom: 8.9px solid #ff0f00;
}

.border-b-info-8-9 {
  border-bottom: 8.9px solid #f6c31c;
}

.border-b-white-8-9 {
  border-bottom: 8.9px solid #f7f8f7;
}

.border-b-green-8-9 {
  border-bottom: 8.9px solid #0b8376;
}

.border-b-purple-8-9 {
  border-bottom: 8.9px solid #755ae2;
}

.border-b-orange-8-9 {
  border-bottom: 8.9px solid #ea872d;
}

.border-b-black-8-9 {
  border-bottom: 8.9px solid #020202;
}

.border-b-light-gray-8-9 {
  border-bottom: 8.9px solid #fafafa;
}

.border-b-deep-green-8-9 {
  border-bottom: 8.9px solid #014345;
}

.border-l-primary-8-9 {
  border-left: 8.9px solid #cccccc;
}

.border-l-secondary-8-9 {
  border-left: 8.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-8-9 {
  border-left: 8.9px solid #ff0f00;
}

.border-l-info-8-9 {
  border-left: 8.9px solid #f6c31c;
}

.border-l-white-8-9 {
  border-left: 8.9px solid #f7f8f7;
}

.border-l-green-8-9 {
  border-left: 8.9px solid #0b8376;
}

.border-l-purple-8-9 {
  border-left: 8.9px solid #755ae2;
}

.border-l-orange-8-9 {
  border-left: 8.9px solid #ea872d;
}

.border-l-black-8-9 {
  border-left: 8.9px solid #020202;
}

.border-l-light-gray-8-9 {
  border-left: 8.9px solid #fafafa;
}

.border-l-deep-green-8-9 {
  border-left: 8.9px solid #014345;
}

.border-r-primary-8-9 {
  border-right: 8.9px solid #cccccc;
}

.border-r-secondary-8-9 {
  border-right: 8.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-8-9 {
  border-right: 8.9px solid #ff0f00;
}

.border-r-info-8-9 {
  border-right: 8.9px solid #f6c31c;
}

.border-r-white-8-9 {
  border-right: 8.9px solid #f7f8f7;
}

.border-r-green-8-9 {
  border-right: 8.9px solid #0b8376;
}

.border-r-purple-8-9 {
  border-right: 8.9px solid #755ae2;
}

.border-r-orange-8-9 {
  border-right: 8.9px solid #ea872d;
}

.border-r-black-8-9 {
  border-right: 8.9px solid #020202;
}

.border-r-light-gray-8-9 {
  border-right: 8.9px solid #fafafa;
}

.border-r-deep-green-8-9 {
  border-right: 8.9px solid #014345;
}

.border-primary-9 {
  border: 9px solid #cccccc;
}

.border-secondary-9 {
  border: 9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9 {
  border: 9px solid #ff0f00;
}

.border-info-9 {
  border: 9px solid #f6c31c;
}

.border-white-9 {
  border: 9px solid #f7f8f7;
}

.border-green-9 {
  border: 9px solid #0b8376;
}

.border-purple-9 {
  border: 9px solid #755ae2;
}

.border-orange-9 {
  border: 9px solid #ea872d;
}

.border-black-9 {
  border: 9px solid #020202;
}

.border-light-gray-9 {
  border: 9px solid #fafafa;
}

.border-deep-green-9 {
  border: 9px solid #014345;
}

.border-t-primary-9 {
  border-top: 9px solid #cccccc;
}

.border-t-secondary-9 {
  border-top: 9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9 {
  border-top: 9px solid #ff0f00;
}

.border-t-info-9 {
  border-top: 9px solid #f6c31c;
}

.border-t-white-9 {
  border-top: 9px solid #f7f8f7;
}

.border-t-green-9 {
  border-top: 9px solid #0b8376;
}

.border-t-purple-9 {
  border-top: 9px solid #755ae2;
}

.border-t-orange-9 {
  border-top: 9px solid #ea872d;
}

.border-t-black-9 {
  border-top: 9px solid #020202;
}

.border-t-light-gray-9 {
  border-top: 9px solid #fafafa;
}

.border-t-deep-green-9 {
  border-top: 9px solid #014345;
}

.border-b-primary-9 {
  border-bottom: 9px solid #cccccc;
}

.border-b-secondary-9 {
  border-bottom: 9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9 {
  border-bottom: 9px solid #ff0f00;
}

.border-b-info-9 {
  border-bottom: 9px solid #f6c31c;
}

.border-b-white-9 {
  border-bottom: 9px solid #f7f8f7;
}

.border-b-green-9 {
  border-bottom: 9px solid #0b8376;
}

.border-b-purple-9 {
  border-bottom: 9px solid #755ae2;
}

.border-b-orange-9 {
  border-bottom: 9px solid #ea872d;
}

.border-b-black-9 {
  border-bottom: 9px solid #020202;
}

.border-b-light-gray-9 {
  border-bottom: 9px solid #fafafa;
}

.border-b-deep-green-9 {
  border-bottom: 9px solid #014345;
}

.border_l-primary-9 {
  border-left: 9px solid #cccccc;
}

.border_l-secondary-9 {
  border-left: 9px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-9 {
  border-left: 9px solid #ff0f00;
}

.border_l-info-9 {
  border-left: 9px solid #f6c31c;
}

.border_l-white-9 {
  border-left: 9px solid #f7f8f7;
}

.border_l-green-9 {
  border-left: 9px solid #0b8376;
}

.border_l-purple-9 {
  border-left: 9px solid #755ae2;
}

.border_l-orange-9 {
  border-left: 9px solid #ea872d;
}

.border_l-black-9 {
  border-left: 9px solid #020202;
}

.border_l-light-gray-9 {
  border-left: 9px solid #fafafa;
}

.border_l-deep-green-9 {
  border-left: 9px solid #014345;
}

.border-r-primary-9 {
  border-right: 9px solid #cccccc;
}

.border-r-secondary-9 {
  border-right: 9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9 {
  border-right: 9px solid #ff0f00;
}

.border-r-info-9 {
  border-right: 9px solid #f6c31c;
}

.border-r-white-9 {
  border-right: 9px solid #f7f8f7;
}

.border-r-green-9 {
  border-right: 9px solid #0b8376;
}

.border-r-purple-9 {
  border-right: 9px solid #755ae2;
}

.border-r-orange-9 {
  border-right: 9px solid #ea872d;
}

.border-r-black-9 {
  border-right: 9px solid #020202;
}

.border-r-light-gray-9 {
  border-right: 9px solid #fafafa;
}

.border-r-deep-green-9 {
  border-right: 9px solid #014345;
}

.border-primary-9-1 {
  border: 9.1px solid #cccccc;
}

.border-secondary-9-1 {
  border: 9.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-1 {
  border: 9.1px solid #ff0f00;
}

.border-info-9-1 {
  border: 9.1px solid #f6c31c;
}

.border-white-9-1 {
  border: 9.1px solid #f7f8f7;
}

.border-green-9-1 {
  border: 9.1px solid #0b8376;
}

.border-purple-9-1 {
  border: 9.1px solid #755ae2;
}

.border-orange-9-1 {
  border: 9.1px solid #ea872d;
}

.border-black-9-1 {
  border: 9.1px solid #020202;
}

.border-light-gray-9-1 {
  border: 9.1px solid #fafafa;
}

.border-deep-green-9-1 {
  border: 9.1px solid #014345;
}

.border-t-primary-9-1 {
  border-top: 9.1px solid #cccccc;
}

.border-t-secondary-9-1 {
  border-top: 9.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-1 {
  border-top: 9.1px solid #ff0f00;
}

.border-t-info-9-1 {
  border-top: 9.1px solid #f6c31c;
}

.border-t-white-9-1 {
  border-top: 9.1px solid #f7f8f7;
}

.border-t-green-9-1 {
  border-top: 9.1px solid #0b8376;
}

.border-t-purple-9-1 {
  border-top: 9.1px solid #755ae2;
}

.border-t-orange-9-1 {
  border-top: 9.1px solid #ea872d;
}

.border-t-black-9-1 {
  border-top: 9.1px solid #020202;
}

.border-t-light-gray-9-1 {
  border-top: 9.1px solid #fafafa;
}

.border-t-deep-green-9-1 {
  border-top: 9.1px solid #014345;
}

.border-b-primary-9-1 {
  border-bottom: 9.1px solid #cccccc;
}

.border-b-secondary-9-1 {
  border-bottom: 9.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-1 {
  border-bottom: 9.1px solid #ff0f00;
}

.border-b-info-9-1 {
  border-bottom: 9.1px solid #f6c31c;
}

.border-b-white-9-1 {
  border-bottom: 9.1px solid #f7f8f7;
}

.border-b-green-9-1 {
  border-bottom: 9.1px solid #0b8376;
}

.border-b-purple-9-1 {
  border-bottom: 9.1px solid #755ae2;
}

.border-b-orange-9-1 {
  border-bottom: 9.1px solid #ea872d;
}

.border-b-black-9-1 {
  border-bottom: 9.1px solid #020202;
}

.border-b-light-gray-9-1 {
  border-bottom: 9.1px solid #fafafa;
}

.border-b-deep-green-9-1 {
  border-bottom: 9.1px solid #014345;
}

.border-l-primary-9-1 {
  border-left: 9.1px solid #cccccc;
}

.border-l-secondary-9-1 {
  border-left: 9.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-1 {
  border-left: 9.1px solid #ff0f00;
}

.border-l-info-9-1 {
  border-left: 9.1px solid #f6c31c;
}

.border-l-white-9-1 {
  border-left: 9.1px solid #f7f8f7;
}

.border-l-green-9-1 {
  border-left: 9.1px solid #0b8376;
}

.border-l-purple-9-1 {
  border-left: 9.1px solid #755ae2;
}

.border-l-orange-9-1 {
  border-left: 9.1px solid #ea872d;
}

.border-l-black-9-1 {
  border-left: 9.1px solid #020202;
}

.border-l-light-gray-9-1 {
  border-left: 9.1px solid #fafafa;
}

.border-l-deep-green-9-1 {
  border-left: 9.1px solid #014345;
}

.border-r-primary-9-1 {
  border-right: 9.1px solid #cccccc;
}

.border-r-secondary-9-1 {
  border-right: 9.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-1 {
  border-right: 9.1px solid #ff0f00;
}

.border-r-info-9-1 {
  border-right: 9.1px solid #f6c31c;
}

.border-r-white-9-1 {
  border-right: 9.1px solid #f7f8f7;
}

.border-r-green-9-1 {
  border-right: 9.1px solid #0b8376;
}

.border-r-purple-9-1 {
  border-right: 9.1px solid #755ae2;
}

.border-r-orange-9-1 {
  border-right: 9.1px solid #ea872d;
}

.border-r-black-9-1 {
  border-right: 9.1px solid #020202;
}

.border-r-light-gray-9-1 {
  border-right: 9.1px solid #fafafa;
}

.border-r-deep-green-9-1 {
  border-right: 9.1px solid #014345;
}

.border-primary-9-2 {
  border: 9.2px solid #cccccc;
}

.border-secondary-9-2 {
  border: 9.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-2 {
  border: 9.2px solid #ff0f00;
}

.border-info-9-2 {
  border: 9.2px solid #f6c31c;
}

.border-white-9-2 {
  border: 9.2px solid #f7f8f7;
}

.border-green-9-2 {
  border: 9.2px solid #0b8376;
}

.border-purple-9-2 {
  border: 9.2px solid #755ae2;
}

.border-orange-9-2 {
  border: 9.2px solid #ea872d;
}

.border-black-9-2 {
  border: 9.2px solid #020202;
}

.border-light-gray-9-2 {
  border: 9.2px solid #fafafa;
}

.border-deep-green-9-2 {
  border: 9.2px solid #014345;
}

.border-t-primary-9-2 {
  border-top: 9.2px solid #cccccc;
}

.border-t-secondary-9-2 {
  border-top: 9.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-2 {
  border-top: 9.2px solid #ff0f00;
}

.border-t-info-9-2 {
  border-top: 9.2px solid #f6c31c;
}

.border-t-white-9-2 {
  border-top: 9.2px solid #f7f8f7;
}

.border-t-green-9-2 {
  border-top: 9.2px solid #0b8376;
}

.border-t-purple-9-2 {
  border-top: 9.2px solid #755ae2;
}

.border-t-orange-9-2 {
  border-top: 9.2px solid #ea872d;
}

.border-t-black-9-2 {
  border-top: 9.2px solid #020202;
}

.border-t-light-gray-9-2 {
  border-top: 9.2px solid #fafafa;
}

.border-t-deep-green-9-2 {
  border-top: 9.2px solid #014345;
}

.border-b-primary-9-2 {
  border-bottom: 9.2px solid #cccccc;
}

.border-b-secondary-9-2 {
  border-bottom: 9.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-2 {
  border-bottom: 9.2px solid #ff0f00;
}

.border-b-info-9-2 {
  border-bottom: 9.2px solid #f6c31c;
}

.border-b-white-9-2 {
  border-bottom: 9.2px solid #f7f8f7;
}

.border-b-green-9-2 {
  border-bottom: 9.2px solid #0b8376;
}

.border-b-purple-9-2 {
  border-bottom: 9.2px solid #755ae2;
}

.border-b-orange-9-2 {
  border-bottom: 9.2px solid #ea872d;
}

.border-b-black-9-2 {
  border-bottom: 9.2px solid #020202;
}

.border-b-light-gray-9-2 {
  border-bottom: 9.2px solid #fafafa;
}

.border-b-deep-green-9-2 {
  border-bottom: 9.2px solid #014345;
}

.border-l-primary-9-2 {
  border-left: 9.2px solid #cccccc;
}

.border-l-secondary-9-2 {
  border-left: 9.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-2 {
  border-left: 9.2px solid #ff0f00;
}

.border-l-info-9-2 {
  border-left: 9.2px solid #f6c31c;
}

.border-l-white-9-2 {
  border-left: 9.2px solid #f7f8f7;
}

.border-l-green-9-2 {
  border-left: 9.2px solid #0b8376;
}

.border-l-purple-9-2 {
  border-left: 9.2px solid #755ae2;
}

.border-l-orange-9-2 {
  border-left: 9.2px solid #ea872d;
}

.border-l-black-9-2 {
  border-left: 9.2px solid #020202;
}

.border-l-light-gray-9-2 {
  border-left: 9.2px solid #fafafa;
}

.border-l-deep-green-9-2 {
  border-left: 9.2px solid #014345;
}

.border-r-primary-9-2 {
  border-right: 9.2px solid #cccccc;
}

.border-r-secondary-9-2 {
  border-right: 9.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-2 {
  border-right: 9.2px solid #ff0f00;
}

.border-r-info-9-2 {
  border-right: 9.2px solid #f6c31c;
}

.border-r-white-9-2 {
  border-right: 9.2px solid #f7f8f7;
}

.border-r-green-9-2 {
  border-right: 9.2px solid #0b8376;
}

.border-r-purple-9-2 {
  border-right: 9.2px solid #755ae2;
}

.border-r-orange-9-2 {
  border-right: 9.2px solid #ea872d;
}

.border-r-black-9-2 {
  border-right: 9.2px solid #020202;
}

.border-r-light-gray-9-2 {
  border-right: 9.2px solid #fafafa;
}

.border-r-deep-green-9-2 {
  border-right: 9.2px solid #014345;
}

.border-primary-9-3 {
  border: 9.3px solid #cccccc;
}

.border-secondary-9-3 {
  border: 9.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-3 {
  border: 9.3px solid #ff0f00;
}

.border-info-9-3 {
  border: 9.3px solid #f6c31c;
}

.border-white-9-3 {
  border: 9.3px solid #f7f8f7;
}

.border-green-9-3 {
  border: 9.3px solid #0b8376;
}

.border-purple-9-3 {
  border: 9.3px solid #755ae2;
}

.border-orange-9-3 {
  border: 9.3px solid #ea872d;
}

.border-black-9-3 {
  border: 9.3px solid #020202;
}

.border-light-gray-9-3 {
  border: 9.3px solid #fafafa;
}

.border-deep-green-9-3 {
  border: 9.3px solid #014345;
}

.border-t-primary-9-3 {
  border-top: 9.3px solid #cccccc;
}

.border-t-secondary-9-3 {
  border-top: 9.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-3 {
  border-top: 9.3px solid #ff0f00;
}

.border-t-info-9-3 {
  border-top: 9.3px solid #f6c31c;
}

.border-t-white-9-3 {
  border-top: 9.3px solid #f7f8f7;
}

.border-t-green-9-3 {
  border-top: 9.3px solid #0b8376;
}

.border-t-purple-9-3 {
  border-top: 9.3px solid #755ae2;
}

.border-t-orange-9-3 {
  border-top: 9.3px solid #ea872d;
}

.border-t-black-9-3 {
  border-top: 9.3px solid #020202;
}

.border-t-light-gray-9-3 {
  border-top: 9.3px solid #fafafa;
}

.border-t-deep-green-9-3 {
  border-top: 9.3px solid #014345;
}

.border-b-primary-9-3 {
  border-bottom: 9.3px solid #cccccc;
}

.border-b-secondary-9-3 {
  border-bottom: 9.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-3 {
  border-bottom: 9.3px solid #ff0f00;
}

.border-b-info-9-3 {
  border-bottom: 9.3px solid #f6c31c;
}

.border-b-white-9-3 {
  border-bottom: 9.3px solid #f7f8f7;
}

.border-b-green-9-3 {
  border-bottom: 9.3px solid #0b8376;
}

.border-b-purple-9-3 {
  border-bottom: 9.3px solid #755ae2;
}

.border-b-orange-9-3 {
  border-bottom: 9.3px solid #ea872d;
}

.border-b-black-9-3 {
  border-bottom: 9.3px solid #020202;
}

.border-b-light-gray-9-3 {
  border-bottom: 9.3px solid #fafafa;
}

.border-b-deep-green-9-3 {
  border-bottom: 9.3px solid #014345;
}

.border-l-primary-9-3 {
  border-left: 9.3px solid #cccccc;
}

.border-l-secondary-9-3 {
  border-left: 9.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-3 {
  border-left: 9.3px solid #ff0f00;
}

.border-l-info-9-3 {
  border-left: 9.3px solid #f6c31c;
}

.border-l-white-9-3 {
  border-left: 9.3px solid #f7f8f7;
}

.border-l-green-9-3 {
  border-left: 9.3px solid #0b8376;
}

.border-l-purple-9-3 {
  border-left: 9.3px solid #755ae2;
}

.border-l-orange-9-3 {
  border-left: 9.3px solid #ea872d;
}

.border-l-black-9-3 {
  border-left: 9.3px solid #020202;
}

.border-l-light-gray-9-3 {
  border-left: 9.3px solid #fafafa;
}

.border-l-deep-green-9-3 {
  border-left: 9.3px solid #014345;
}

.border-r-primary-9-3 {
  border-right: 9.3px solid #cccccc;
}

.border-r-secondary-9-3 {
  border-right: 9.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-3 {
  border-right: 9.3px solid #ff0f00;
}

.border-r-info-9-3 {
  border-right: 9.3px solid #f6c31c;
}

.border-r-white-9-3 {
  border-right: 9.3px solid #f7f8f7;
}

.border-r-green-9-3 {
  border-right: 9.3px solid #0b8376;
}

.border-r-purple-9-3 {
  border-right: 9.3px solid #755ae2;
}

.border-r-orange-9-3 {
  border-right: 9.3px solid #ea872d;
}

.border-r-black-9-3 {
  border-right: 9.3px solid #020202;
}

.border-r-light-gray-9-3 {
  border-right: 9.3px solid #fafafa;
}

.border-r-deep-green-9-3 {
  border-right: 9.3px solid #014345;
}

.border-primary-9-4 {
  border: 9.4px solid #cccccc;
}

.border-secondary-9-4 {
  border: 9.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-4 {
  border: 9.4px solid #ff0f00;
}

.border-info-9-4 {
  border: 9.4px solid #f6c31c;
}

.border-white-9-4 {
  border: 9.4px solid #f7f8f7;
}

.border-green-9-4 {
  border: 9.4px solid #0b8376;
}

.border-purple-9-4 {
  border: 9.4px solid #755ae2;
}

.border-orange-9-4 {
  border: 9.4px solid #ea872d;
}

.border-black-9-4 {
  border: 9.4px solid #020202;
}

.border-light-gray-9-4 {
  border: 9.4px solid #fafafa;
}

.border-deep-green-9-4 {
  border: 9.4px solid #014345;
}

.border-t-primary-9-4 {
  border-top: 9.4px solid #cccccc;
}

.border-t-secondary-9-4 {
  border-top: 9.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-4 {
  border-top: 9.4px solid #ff0f00;
}

.border-t-info-9-4 {
  border-top: 9.4px solid #f6c31c;
}

.border-t-white-9-4 {
  border-top: 9.4px solid #f7f8f7;
}

.border-t-green-9-4 {
  border-top: 9.4px solid #0b8376;
}

.border-t-purple-9-4 {
  border-top: 9.4px solid #755ae2;
}

.border-t-orange-9-4 {
  border-top: 9.4px solid #ea872d;
}

.border-t-black-9-4 {
  border-top: 9.4px solid #020202;
}

.border-t-light-gray-9-4 {
  border-top: 9.4px solid #fafafa;
}

.border-t-deep-green-9-4 {
  border-top: 9.4px solid #014345;
}

.border-b-primary-9-4 {
  border-bottom: 9.4px solid #cccccc;
}

.border-b-secondary-9-4 {
  border-bottom: 9.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-4 {
  border-bottom: 9.4px solid #ff0f00;
}

.border-b-info-9-4 {
  border-bottom: 9.4px solid #f6c31c;
}

.border-b-white-9-4 {
  border-bottom: 9.4px solid #f7f8f7;
}

.border-b-green-9-4 {
  border-bottom: 9.4px solid #0b8376;
}

.border-b-purple-9-4 {
  border-bottom: 9.4px solid #755ae2;
}

.border-b-orange-9-4 {
  border-bottom: 9.4px solid #ea872d;
}

.border-b-black-9-4 {
  border-bottom: 9.4px solid #020202;
}

.border-b-light-gray-9-4 {
  border-bottom: 9.4px solid #fafafa;
}

.border-b-deep-green-9-4 {
  border-bottom: 9.4px solid #014345;
}

.border-l-primary-9-4 {
  border-left: 9.4px solid #cccccc;
}

.border-l-secondary-9-4 {
  border-left: 9.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-4 {
  border-left: 9.4px solid #ff0f00;
}

.border-l-info-9-4 {
  border-left: 9.4px solid #f6c31c;
}

.border-l-white-9-4 {
  border-left: 9.4px solid #f7f8f7;
}

.border-l-green-9-4 {
  border-left: 9.4px solid #0b8376;
}

.border-l-purple-9-4 {
  border-left: 9.4px solid #755ae2;
}

.border-l-orange-9-4 {
  border-left: 9.4px solid #ea872d;
}

.border-l-black-9-4 {
  border-left: 9.4px solid #020202;
}

.border-l-light-gray-9-4 {
  border-left: 9.4px solid #fafafa;
}

.border-l-deep-green-9-4 {
  border-left: 9.4px solid #014345;
}

.border-r-primary-9-4 {
  border-right: 9.4px solid #cccccc;
}

.border-r-secondary-9-4 {
  border-right: 9.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-4 {
  border-right: 9.4px solid #ff0f00;
}

.border-r-info-9-4 {
  border-right: 9.4px solid #f6c31c;
}

.border-r-white-9-4 {
  border-right: 9.4px solid #f7f8f7;
}

.border-r-green-9-4 {
  border-right: 9.4px solid #0b8376;
}

.border-r-purple-9-4 {
  border-right: 9.4px solid #755ae2;
}

.border-r-orange-9-4 {
  border-right: 9.4px solid #ea872d;
}

.border-r-black-9-4 {
  border-right: 9.4px solid #020202;
}

.border-r-light-gray-9-4 {
  border-right: 9.4px solid #fafafa;
}

.border-r-deep-green-9-4 {
  border-right: 9.4px solid #014345;
}

.border-primary-9-5 {
  border: 9.5px solid #cccccc;
}

.border-secondary-9-5 {
  border: 9.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-5 {
  border: 9.5px solid #ff0f00;
}

.border-info-9-5 {
  border: 9.5px solid #f6c31c;
}

.border-white-9-5 {
  border: 9.5px solid #f7f8f7;
}

.border-green-9-5 {
  border: 9.5px solid #0b8376;
}

.border-purple-9-5 {
  border: 9.5px solid #755ae2;
}

.border-orange-9-5 {
  border: 9.5px solid #ea872d;
}

.border-black-9-5 {
  border: 9.5px solid #020202;
}

.border-light-gray-9-5 {
  border: 9.5px solid #fafafa;
}

.border-deep-green-9-5 {
  border: 9.5px solid #014345;
}

.border-t-primary-9-5 {
  border-top: 9.5px solid #cccccc;
}

.border-t-secondary-9-5 {
  border-top: 9.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-5 {
  border-top: 9.5px solid #ff0f00;
}

.border-t-info-9-5 {
  border-top: 9.5px solid #f6c31c;
}

.border-t-white-9-5 {
  border-top: 9.5px solid #f7f8f7;
}

.border-t-green-9-5 {
  border-top: 9.5px solid #0b8376;
}

.border-t-purple-9-5 {
  border-top: 9.5px solid #755ae2;
}

.border-t-orange-9-5 {
  border-top: 9.5px solid #ea872d;
}

.border-t-black-9-5 {
  border-top: 9.5px solid #020202;
}

.border-t-light-gray-9-5 {
  border-top: 9.5px solid #fafafa;
}

.border-t-deep-green-9-5 {
  border-top: 9.5px solid #014345;
}

.border-b-primary-9-5 {
  border-bottom: 9.5px solid #cccccc;
}

.border-b-secondary-9-5 {
  border-bottom: 9.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-5 {
  border-bottom: 9.5px solid #ff0f00;
}

.border-b-info-9-5 {
  border-bottom: 9.5px solid #f6c31c;
}

.border-b-white-9-5 {
  border-bottom: 9.5px solid #f7f8f7;
}

.border-b-green-9-5 {
  border-bottom: 9.5px solid #0b8376;
}

.border-b-purple-9-5 {
  border-bottom: 9.5px solid #755ae2;
}

.border-b-orange-9-5 {
  border-bottom: 9.5px solid #ea872d;
}

.border-b-black-9-5 {
  border-bottom: 9.5px solid #020202;
}

.border-b-light-gray-9-5 {
  border-bottom: 9.5px solid #fafafa;
}

.border-b-deep-green-9-5 {
  border-bottom: 9.5px solid #014345;
}

.border-l-primary-9-5 {
  border-left: 9.5px solid #cccccc;
}

.border-l-secondary-9-5 {
  border-left: 9.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-5 {
  border-left: 9.5px solid #ff0f00;
}

.border-l-info-9-5 {
  border-left: 9.5px solid #f6c31c;
}

.border-l-white-9-5 {
  border-left: 9.5px solid #f7f8f7;
}

.border-l-green-9-5 {
  border-left: 9.5px solid #0b8376;
}

.border-l-purple-9-5 {
  border-left: 9.5px solid #755ae2;
}

.border-l-orange-9-5 {
  border-left: 9.5px solid #ea872d;
}

.border-l-black-9-5 {
  border-left: 9.5px solid #020202;
}

.border-l-light-gray-9-5 {
  border-left: 9.5px solid #fafafa;
}

.border-l-deep-green-9-5 {
  border-left: 9.5px solid #014345;
}

.border-r-primary-9-5 {
  border-right: 9.5px solid #cccccc;
}

.border-r-secondary-9-5 {
  border-right: 9.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-5 {
  border-right: 9.5px solid #ff0f00;
}

.border-r-info-9-5 {
  border-right: 9.5px solid #f6c31c;
}

.border-r-white-9-5 {
  border-right: 9.5px solid #f7f8f7;
}

.border-r-green-9-5 {
  border-right: 9.5px solid #0b8376;
}

.border-r-purple-9-5 {
  border-right: 9.5px solid #755ae2;
}

.border-r-orange-9-5 {
  border-right: 9.5px solid #ea872d;
}

.border-r-black-9-5 {
  border-right: 9.5px solid #020202;
}

.border-r-light-gray-9-5 {
  border-right: 9.5px solid #fafafa;
}

.border-r-deep-green-9-5 {
  border-right: 9.5px solid #014345;
}

.border-primary-9-6 {
  border: 9.6px solid #cccccc;
}

.border-secondary-9-6 {
  border: 9.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-6 {
  border: 9.6px solid #ff0f00;
}

.border-info-9-6 {
  border: 9.6px solid #f6c31c;
}

.border-white-9-6 {
  border: 9.6px solid #f7f8f7;
}

.border-green-9-6 {
  border: 9.6px solid #0b8376;
}

.border-purple-9-6 {
  border: 9.6px solid #755ae2;
}

.border-orange-9-6 {
  border: 9.6px solid #ea872d;
}

.border-black-9-6 {
  border: 9.6px solid #020202;
}

.border-light-gray-9-6 {
  border: 9.6px solid #fafafa;
}

.border-deep-green-9-6 {
  border: 9.6px solid #014345;
}

.border-t-primary-9-6 {
  border-top: 9.6px solid #cccccc;
}

.border-t-secondary-9-6 {
  border-top: 9.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-6 {
  border-top: 9.6px solid #ff0f00;
}

.border-t-info-9-6 {
  border-top: 9.6px solid #f6c31c;
}

.border-t-white-9-6 {
  border-top: 9.6px solid #f7f8f7;
}

.border-t-green-9-6 {
  border-top: 9.6px solid #0b8376;
}

.border-t-purple-9-6 {
  border-top: 9.6px solid #755ae2;
}

.border-t-orange-9-6 {
  border-top: 9.6px solid #ea872d;
}

.border-t-black-9-6 {
  border-top: 9.6px solid #020202;
}

.border-t-light-gray-9-6 {
  border-top: 9.6px solid #fafafa;
}

.border-t-deep-green-9-6 {
  border-top: 9.6px solid #014345;
}

.border-b-primary-9-6 {
  border-bottom: 9.6px solid #cccccc;
}

.border-b-secondary-9-6 {
  border-bottom: 9.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-6 {
  border-bottom: 9.6px solid #ff0f00;
}

.border-b-info-9-6 {
  border-bottom: 9.6px solid #f6c31c;
}

.border-b-white-9-6 {
  border-bottom: 9.6px solid #f7f8f7;
}

.border-b-green-9-6 {
  border-bottom: 9.6px solid #0b8376;
}

.border-b-purple-9-6 {
  border-bottom: 9.6px solid #755ae2;
}

.border-b-orange-9-6 {
  border-bottom: 9.6px solid #ea872d;
}

.border-b-black-9-6 {
  border-bottom: 9.6px solid #020202;
}

.border-b-light-gray-9-6 {
  border-bottom: 9.6px solid #fafafa;
}

.border-b-deep-green-9-6 {
  border-bottom: 9.6px solid #014345;
}

.border-l-primary-9-6 {
  border-left: 9.6px solid #cccccc;
}

.border-l-secondary-9-6 {
  border-left: 9.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-6 {
  border-left: 9.6px solid #ff0f00;
}

.border-l-info-9-6 {
  border-left: 9.6px solid #f6c31c;
}

.border-l-white-9-6 {
  border-left: 9.6px solid #f7f8f7;
}

.border-l-green-9-6 {
  border-left: 9.6px solid #0b8376;
}

.border-l-purple-9-6 {
  border-left: 9.6px solid #755ae2;
}

.border-l-orange-9-6 {
  border-left: 9.6px solid #ea872d;
}

.border-l-black-9-6 {
  border-left: 9.6px solid #020202;
}

.border-l-light-gray-9-6 {
  border-left: 9.6px solid #fafafa;
}

.border-l-deep-green-9-6 {
  border-left: 9.6px solid #014345;
}

.border-r-primary-9-6 {
  border-right: 9.6px solid #cccccc;
}

.border-r-secondary-9-6 {
  border-right: 9.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-6 {
  border-right: 9.6px solid #ff0f00;
}

.border-r-info-9-6 {
  border-right: 9.6px solid #f6c31c;
}

.border-r-white-9-6 {
  border-right: 9.6px solid #f7f8f7;
}

.border-r-green-9-6 {
  border-right: 9.6px solid #0b8376;
}

.border-r-purple-9-6 {
  border-right: 9.6px solid #755ae2;
}

.border-r-orange-9-6 {
  border-right: 9.6px solid #ea872d;
}

.border-r-black-9-6 {
  border-right: 9.6px solid #020202;
}

.border-r-light-gray-9-6 {
  border-right: 9.6px solid #fafafa;
}

.border-r-deep-green-9-6 {
  border-right: 9.6px solid #014345;
}

.border-primary-9-7 {
  border: 9.7px solid #cccccc;
}

.border-secondary-9-7 {
  border: 9.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-7 {
  border: 9.7px solid #ff0f00;
}

.border-info-9-7 {
  border: 9.7px solid #f6c31c;
}

.border-white-9-7 {
  border: 9.7px solid #f7f8f7;
}

.border-green-9-7 {
  border: 9.7px solid #0b8376;
}

.border-purple-9-7 {
  border: 9.7px solid #755ae2;
}

.border-orange-9-7 {
  border: 9.7px solid #ea872d;
}

.border-black-9-7 {
  border: 9.7px solid #020202;
}

.border-light-gray-9-7 {
  border: 9.7px solid #fafafa;
}

.border-deep-green-9-7 {
  border: 9.7px solid #014345;
}

.border-t-primary-9-7 {
  border-top: 9.7px solid #cccccc;
}

.border-t-secondary-9-7 {
  border-top: 9.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-7 {
  border-top: 9.7px solid #ff0f00;
}

.border-t-info-9-7 {
  border-top: 9.7px solid #f6c31c;
}

.border-t-white-9-7 {
  border-top: 9.7px solid #f7f8f7;
}

.border-t-green-9-7 {
  border-top: 9.7px solid #0b8376;
}

.border-t-purple-9-7 {
  border-top: 9.7px solid #755ae2;
}

.border-t-orange-9-7 {
  border-top: 9.7px solid #ea872d;
}

.border-t-black-9-7 {
  border-top: 9.7px solid #020202;
}

.border-t-light-gray-9-7 {
  border-top: 9.7px solid #fafafa;
}

.border-t-deep-green-9-7 {
  border-top: 9.7px solid #014345;
}

.border-b-primary-9-7 {
  border-bottom: 9.7px solid #cccccc;
}

.border-b-secondary-9-7 {
  border-bottom: 9.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-7 {
  border-bottom: 9.7px solid #ff0f00;
}

.border-b-info-9-7 {
  border-bottom: 9.7px solid #f6c31c;
}

.border-b-white-9-7 {
  border-bottom: 9.7px solid #f7f8f7;
}

.border-b-green-9-7 {
  border-bottom: 9.7px solid #0b8376;
}

.border-b-purple-9-7 {
  border-bottom: 9.7px solid #755ae2;
}

.border-b-orange-9-7 {
  border-bottom: 9.7px solid #ea872d;
}

.border-b-black-9-7 {
  border-bottom: 9.7px solid #020202;
}

.border-b-light-gray-9-7 {
  border-bottom: 9.7px solid #fafafa;
}

.border-b-deep-green-9-7 {
  border-bottom: 9.7px solid #014345;
}

.border-l-primary-9-7 {
  border-left: 9.7px solid #cccccc;
}

.border-l-secondary-9-7 {
  border-left: 9.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-7 {
  border-left: 9.7px solid #ff0f00;
}

.border-l-info-9-7 {
  border-left: 9.7px solid #f6c31c;
}

.border-l-white-9-7 {
  border-left: 9.7px solid #f7f8f7;
}

.border-l-green-9-7 {
  border-left: 9.7px solid #0b8376;
}

.border-l-purple-9-7 {
  border-left: 9.7px solid #755ae2;
}

.border-l-orange-9-7 {
  border-left: 9.7px solid #ea872d;
}

.border-l-black-9-7 {
  border-left: 9.7px solid #020202;
}

.border-l-light-gray-9-7 {
  border-left: 9.7px solid #fafafa;
}

.border-l-deep-green-9-7 {
  border-left: 9.7px solid #014345;
}

.border-r-primary-9-7 {
  border-right: 9.7px solid #cccccc;
}

.border-r-secondary-9-7 {
  border-right: 9.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-7 {
  border-right: 9.7px solid #ff0f00;
}

.border-r-info-9-7 {
  border-right: 9.7px solid #f6c31c;
}

.border-r-white-9-7 {
  border-right: 9.7px solid #f7f8f7;
}

.border-r-green-9-7 {
  border-right: 9.7px solid #0b8376;
}

.border-r-purple-9-7 {
  border-right: 9.7px solid #755ae2;
}

.border-r-orange-9-7 {
  border-right: 9.7px solid #ea872d;
}

.border-r-black-9-7 {
  border-right: 9.7px solid #020202;
}

.border-r-light-gray-9-7 {
  border-right: 9.7px solid #fafafa;
}

.border-r-deep-green-9-7 {
  border-right: 9.7px solid #014345;
}

.border-primary-9-8 {
  border: 9.8px solid #cccccc;
}

.border-secondary-9-8 {
  border: 9.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-8 {
  border: 9.8px solid #ff0f00;
}

.border-info-9-8 {
  border: 9.8px solid #f6c31c;
}

.border-white-9-8 {
  border: 9.8px solid #f7f8f7;
}

.border-green-9-8 {
  border: 9.8px solid #0b8376;
}

.border-purple-9-8 {
  border: 9.8px solid #755ae2;
}

.border-orange-9-8 {
  border: 9.8px solid #ea872d;
}

.border-black-9-8 {
  border: 9.8px solid #020202;
}

.border-light-gray-9-8 {
  border: 9.8px solid #fafafa;
}

.border-deep-green-9-8 {
  border: 9.8px solid #014345;
}

.border-t-primary-9-8 {
  border-top: 9.8px solid #cccccc;
}

.border-t-secondary-9-8 {
  border-top: 9.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-8 {
  border-top: 9.8px solid #ff0f00;
}

.border-t-info-9-8 {
  border-top: 9.8px solid #f6c31c;
}

.border-t-white-9-8 {
  border-top: 9.8px solid #f7f8f7;
}

.border-t-green-9-8 {
  border-top: 9.8px solid #0b8376;
}

.border-t-purple-9-8 {
  border-top: 9.8px solid #755ae2;
}

.border-t-orange-9-8 {
  border-top: 9.8px solid #ea872d;
}

.border-t-black-9-8 {
  border-top: 9.8px solid #020202;
}

.border-t-light-gray-9-8 {
  border-top: 9.8px solid #fafafa;
}

.border-t-deep-green-9-8 {
  border-top: 9.8px solid #014345;
}

.border-b-primary-9-8 {
  border-bottom: 9.8px solid #cccccc;
}

.border-b-secondary-9-8 {
  border-bottom: 9.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-8 {
  border-bottom: 9.8px solid #ff0f00;
}

.border-b-info-9-8 {
  border-bottom: 9.8px solid #f6c31c;
}

.border-b-white-9-8 {
  border-bottom: 9.8px solid #f7f8f7;
}

.border-b-green-9-8 {
  border-bottom: 9.8px solid #0b8376;
}

.border-b-purple-9-8 {
  border-bottom: 9.8px solid #755ae2;
}

.border-b-orange-9-8 {
  border-bottom: 9.8px solid #ea872d;
}

.border-b-black-9-8 {
  border-bottom: 9.8px solid #020202;
}

.border-b-light-gray-9-8 {
  border-bottom: 9.8px solid #fafafa;
}

.border-b-deep-green-9-8 {
  border-bottom: 9.8px solid #014345;
}

.border-l-primary-9-8 {
  border-left: 9.8px solid #cccccc;
}

.border-l-secondary-9-8 {
  border-left: 9.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-8 {
  border-left: 9.8px solid #ff0f00;
}

.border-l-info-9-8 {
  border-left: 9.8px solid #f6c31c;
}

.border-l-white-9-8 {
  border-left: 9.8px solid #f7f8f7;
}

.border-l-green-9-8 {
  border-left: 9.8px solid #0b8376;
}

.border-l-purple-9-8 {
  border-left: 9.8px solid #755ae2;
}

.border-l-orange-9-8 {
  border-left: 9.8px solid #ea872d;
}

.border-l-black-9-8 {
  border-left: 9.8px solid #020202;
}

.border-l-light-gray-9-8 {
  border-left: 9.8px solid #fafafa;
}

.border-l-deep-green-9-8 {
  border-left: 9.8px solid #014345;
}

.border-r-primary-9-8 {
  border-right: 9.8px solid #cccccc;
}

.border-r-secondary-9-8 {
  border-right: 9.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-8 {
  border-right: 9.8px solid #ff0f00;
}

.border-r-info-9-8 {
  border-right: 9.8px solid #f6c31c;
}

.border-r-white-9-8 {
  border-right: 9.8px solid #f7f8f7;
}

.border-r-green-9-8 {
  border-right: 9.8px solid #0b8376;
}

.border-r-purple-9-8 {
  border-right: 9.8px solid #755ae2;
}

.border-r-orange-9-8 {
  border-right: 9.8px solid #ea872d;
}

.border-r-black-9-8 {
  border-right: 9.8px solid #020202;
}

.border-r-light-gray-9-8 {
  border-right: 9.8px solid #fafafa;
}

.border-r-deep-green-9-8 {
  border-right: 9.8px solid #014345;
}

.border-primary-9-9 {
  border: 9.9px solid #cccccc;
}

.border-secondary-9-9 {
  border: 9.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-9-9 {
  border: 9.9px solid #ff0f00;
}

.border-info-9-9 {
  border: 9.9px solid #f6c31c;
}

.border-white-9-9 {
  border: 9.9px solid #f7f8f7;
}

.border-green-9-9 {
  border: 9.9px solid #0b8376;
}

.border-purple-9-9 {
  border: 9.9px solid #755ae2;
}

.border-orange-9-9 {
  border: 9.9px solid #ea872d;
}

.border-black-9-9 {
  border: 9.9px solid #020202;
}

.border-light-gray-9-9 {
  border: 9.9px solid #fafafa;
}

.border-deep-green-9-9 {
  border: 9.9px solid #014345;
}

.border-t-primary-9-9 {
  border-top: 9.9px solid #cccccc;
}

.border-t-secondary-9-9 {
  border-top: 9.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-9-9 {
  border-top: 9.9px solid #ff0f00;
}

.border-t-info-9-9 {
  border-top: 9.9px solid #f6c31c;
}

.border-t-white-9-9 {
  border-top: 9.9px solid #f7f8f7;
}

.border-t-green-9-9 {
  border-top: 9.9px solid #0b8376;
}

.border-t-purple-9-9 {
  border-top: 9.9px solid #755ae2;
}

.border-t-orange-9-9 {
  border-top: 9.9px solid #ea872d;
}

.border-t-black-9-9 {
  border-top: 9.9px solid #020202;
}

.border-t-light-gray-9-9 {
  border-top: 9.9px solid #fafafa;
}

.border-t-deep-green-9-9 {
  border-top: 9.9px solid #014345;
}

.border-b-primary-9-9 {
  border-bottom: 9.9px solid #cccccc;
}

.border-b-secondary-9-9 {
  border-bottom: 9.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-9-9 {
  border-bottom: 9.9px solid #ff0f00;
}

.border-b-info-9-9 {
  border-bottom: 9.9px solid #f6c31c;
}

.border-b-white-9-9 {
  border-bottom: 9.9px solid #f7f8f7;
}

.border-b-green-9-9 {
  border-bottom: 9.9px solid #0b8376;
}

.border-b-purple-9-9 {
  border-bottom: 9.9px solid #755ae2;
}

.border-b-orange-9-9 {
  border-bottom: 9.9px solid #ea872d;
}

.border-b-black-9-9 {
  border-bottom: 9.9px solid #020202;
}

.border-b-light-gray-9-9 {
  border-bottom: 9.9px solid #fafafa;
}

.border-b-deep-green-9-9 {
  border-bottom: 9.9px solid #014345;
}

.border-l-primary-9-9 {
  border-left: 9.9px solid #cccccc;
}

.border-l-secondary-9-9 {
  border-left: 9.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-9-9 {
  border-left: 9.9px solid #ff0f00;
}

.border-l-info-9-9 {
  border-left: 9.9px solid #f6c31c;
}

.border-l-white-9-9 {
  border-left: 9.9px solid #f7f8f7;
}

.border-l-green-9-9 {
  border-left: 9.9px solid #0b8376;
}

.border-l-purple-9-9 {
  border-left: 9.9px solid #755ae2;
}

.border-l-orange-9-9 {
  border-left: 9.9px solid #ea872d;
}

.border-l-black-9-9 {
  border-left: 9.9px solid #020202;
}

.border-l-light-gray-9-9 {
  border-left: 9.9px solid #fafafa;
}

.border-l-deep-green-9-9 {
  border-left: 9.9px solid #014345;
}

.border-r-primary-9-9 {
  border-right: 9.9px solid #cccccc;
}

.border-r-secondary-9-9 {
  border-right: 9.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-9-9 {
  border-right: 9.9px solid #ff0f00;
}

.border-r-info-9-9 {
  border-right: 9.9px solid #f6c31c;
}

.border-r-white-9-9 {
  border-right: 9.9px solid #f7f8f7;
}

.border-r-green-9-9 {
  border-right: 9.9px solid #0b8376;
}

.border-r-purple-9-9 {
  border-right: 9.9px solid #755ae2;
}

.border-r-orange-9-9 {
  border-right: 9.9px solid #ea872d;
}

.border-r-black-9-9 {
  border-right: 9.9px solid #020202;
}

.border-r-light-gray-9-9 {
  border-right: 9.9px solid #fafafa;
}

.border-r-deep-green-9-9 {
  border-right: 9.9px solid #014345;
}

.border-primary-10 {
  border: 10px solid #cccccc;
}

.border-secondary-10 {
  border: 10px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10 {
  border: 10px solid #ff0f00;
}

.border-info-10 {
  border: 10px solid #f6c31c;
}

.border-white-10 {
  border: 10px solid #f7f8f7;
}

.border-green-10 {
  border: 10px solid #0b8376;
}

.border-purple-10 {
  border: 10px solid #755ae2;
}

.border-orange-10 {
  border: 10px solid #ea872d;
}

.border-black-10 {
  border: 10px solid #020202;
}

.border-light-gray-10 {
  border: 10px solid #fafafa;
}

.border-deep-green-10 {
  border: 10px solid #014345;
}

.border-t-primary-10 {
  border-top: 10px solid #cccccc;
}

.border-t-secondary-10 {
  border-top: 10px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10 {
  border-top: 10px solid #ff0f00;
}

.border-t-info-10 {
  border-top: 10px solid #f6c31c;
}

.border-t-white-10 {
  border-top: 10px solid #f7f8f7;
}

.border-t-green-10 {
  border-top: 10px solid #0b8376;
}

.border-t-purple-10 {
  border-top: 10px solid #755ae2;
}

.border-t-orange-10 {
  border-top: 10px solid #ea872d;
}

.border-t-black-10 {
  border-top: 10px solid #020202;
}

.border-t-light-gray-10 {
  border-top: 10px solid #fafafa;
}

.border-t-deep-green-10 {
  border-top: 10px solid #014345;
}

.border-b-primary-10 {
  border-bottom: 10px solid #cccccc;
}

.border-b-secondary-10 {
  border-bottom: 10px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10 {
  border-bottom: 10px solid #ff0f00;
}

.border-b-info-10 {
  border-bottom: 10px solid #f6c31c;
}

.border-b-white-10 {
  border-bottom: 10px solid #f7f8f7;
}

.border-b-green-10 {
  border-bottom: 10px solid #0b8376;
}

.border-b-purple-10 {
  border-bottom: 10px solid #755ae2;
}

.border-b-orange-10 {
  border-bottom: 10px solid #ea872d;
}

.border-b-black-10 {
  border-bottom: 10px solid #020202;
}

.border-b-light-gray-10 {
  border-bottom: 10px solid #fafafa;
}

.border-b-deep-green-10 {
  border-bottom: 10px solid #014345;
}

.border_l-primary-10 {
  border-left: 10px solid #cccccc;
}

.border_l-secondary-10 {
  border-left: 10px solid rgba(223, 66, 9, 0.631372549);
}

.border_l-error-10 {
  border-left: 10px solid #ff0f00;
}

.border_l-info-10 {
  border-left: 10px solid #f6c31c;
}

.border_l-white-10 {
  border-left: 10px solid #f7f8f7;
}

.border_l-green-10 {
  border-left: 10px solid #0b8376;
}

.border_l-purple-10 {
  border-left: 10px solid #755ae2;
}

.border_l-orange-10 {
  border-left: 10px solid #ea872d;
}

.border_l-black-10 {
  border-left: 10px solid #020202;
}

.border_l-light-gray-10 {
  border-left: 10px solid #fafafa;
}

.border_l-deep-green-10 {
  border-left: 10px solid #014345;
}

.border-r-primary-10 {
  border-right: 10px solid #cccccc;
}

.border-r-secondary-10 {
  border-right: 10px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10 {
  border-right: 10px solid #ff0f00;
}

.border-r-info-10 {
  border-right: 10px solid #f6c31c;
}

.border-r-white-10 {
  border-right: 10px solid #f7f8f7;
}

.border-r-green-10 {
  border-right: 10px solid #0b8376;
}

.border-r-purple-10 {
  border-right: 10px solid #755ae2;
}

.border-r-orange-10 {
  border-right: 10px solid #ea872d;
}

.border-r-black-10 {
  border-right: 10px solid #020202;
}

.border-r-light-gray-10 {
  border-right: 10px solid #fafafa;
}

.border-r-deep-green-10 {
  border-right: 10px solid #014345;
}

.border-primary-10-1 {
  border: 10.1px solid #cccccc;
}

.border-secondary-10-1 {
  border: 10.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-1 {
  border: 10.1px solid #ff0f00;
}

.border-info-10-1 {
  border: 10.1px solid #f6c31c;
}

.border-white-10-1 {
  border: 10.1px solid #f7f8f7;
}

.border-green-10-1 {
  border: 10.1px solid #0b8376;
}

.border-purple-10-1 {
  border: 10.1px solid #755ae2;
}

.border-orange-10-1 {
  border: 10.1px solid #ea872d;
}

.border-black-10-1 {
  border: 10.1px solid #020202;
}

.border-light-gray-10-1 {
  border: 10.1px solid #fafafa;
}

.border-deep-green-10-1 {
  border: 10.1px solid #014345;
}

.border-t-primary-10-1 {
  border-top: 10.1px solid #cccccc;
}

.border-t-secondary-10-1 {
  border-top: 10.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-1 {
  border-top: 10.1px solid #ff0f00;
}

.border-t-info-10-1 {
  border-top: 10.1px solid #f6c31c;
}

.border-t-white-10-1 {
  border-top: 10.1px solid #f7f8f7;
}

.border-t-green-10-1 {
  border-top: 10.1px solid #0b8376;
}

.border-t-purple-10-1 {
  border-top: 10.1px solid #755ae2;
}

.border-t-orange-10-1 {
  border-top: 10.1px solid #ea872d;
}

.border-t-black-10-1 {
  border-top: 10.1px solid #020202;
}

.border-t-light-gray-10-1 {
  border-top: 10.1px solid #fafafa;
}

.border-t-deep-green-10-1 {
  border-top: 10.1px solid #014345;
}

.border-b-primary-10-1 {
  border-bottom: 10.1px solid #cccccc;
}

.border-b-secondary-10-1 {
  border-bottom: 10.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-1 {
  border-bottom: 10.1px solid #ff0f00;
}

.border-b-info-10-1 {
  border-bottom: 10.1px solid #f6c31c;
}

.border-b-white-10-1 {
  border-bottom: 10.1px solid #f7f8f7;
}

.border-b-green-10-1 {
  border-bottom: 10.1px solid #0b8376;
}

.border-b-purple-10-1 {
  border-bottom: 10.1px solid #755ae2;
}

.border-b-orange-10-1 {
  border-bottom: 10.1px solid #ea872d;
}

.border-b-black-10-1 {
  border-bottom: 10.1px solid #020202;
}

.border-b-light-gray-10-1 {
  border-bottom: 10.1px solid #fafafa;
}

.border-b-deep-green-10-1 {
  border-bottom: 10.1px solid #014345;
}

.border-l-primary-10-1 {
  border-left: 10.1px solid #cccccc;
}

.border-l-secondary-10-1 {
  border-left: 10.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-1 {
  border-left: 10.1px solid #ff0f00;
}

.border-l-info-10-1 {
  border-left: 10.1px solid #f6c31c;
}

.border-l-white-10-1 {
  border-left: 10.1px solid #f7f8f7;
}

.border-l-green-10-1 {
  border-left: 10.1px solid #0b8376;
}

.border-l-purple-10-1 {
  border-left: 10.1px solid #755ae2;
}

.border-l-orange-10-1 {
  border-left: 10.1px solid #ea872d;
}

.border-l-black-10-1 {
  border-left: 10.1px solid #020202;
}

.border-l-light-gray-10-1 {
  border-left: 10.1px solid #fafafa;
}

.border-l-deep-green-10-1 {
  border-left: 10.1px solid #014345;
}

.border-r-primary-10-1 {
  border-right: 10.1px solid #cccccc;
}

.border-r-secondary-10-1 {
  border-right: 10.1px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-1 {
  border-right: 10.1px solid #ff0f00;
}

.border-r-info-10-1 {
  border-right: 10.1px solid #f6c31c;
}

.border-r-white-10-1 {
  border-right: 10.1px solid #f7f8f7;
}

.border-r-green-10-1 {
  border-right: 10.1px solid #0b8376;
}

.border-r-purple-10-1 {
  border-right: 10.1px solid #755ae2;
}

.border-r-orange-10-1 {
  border-right: 10.1px solid #ea872d;
}

.border-r-black-10-1 {
  border-right: 10.1px solid #020202;
}

.border-r-light-gray-10-1 {
  border-right: 10.1px solid #fafafa;
}

.border-r-deep-green-10-1 {
  border-right: 10.1px solid #014345;
}

.border-primary-10-2 {
  border: 10.2px solid #cccccc;
}

.border-secondary-10-2 {
  border: 10.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-2 {
  border: 10.2px solid #ff0f00;
}

.border-info-10-2 {
  border: 10.2px solid #f6c31c;
}

.border-white-10-2 {
  border: 10.2px solid #f7f8f7;
}

.border-green-10-2 {
  border: 10.2px solid #0b8376;
}

.border-purple-10-2 {
  border: 10.2px solid #755ae2;
}

.border-orange-10-2 {
  border: 10.2px solid #ea872d;
}

.border-black-10-2 {
  border: 10.2px solid #020202;
}

.border-light-gray-10-2 {
  border: 10.2px solid #fafafa;
}

.border-deep-green-10-2 {
  border: 10.2px solid #014345;
}

.border-t-primary-10-2 {
  border-top: 10.2px solid #cccccc;
}

.border-t-secondary-10-2 {
  border-top: 10.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-2 {
  border-top: 10.2px solid #ff0f00;
}

.border-t-info-10-2 {
  border-top: 10.2px solid #f6c31c;
}

.border-t-white-10-2 {
  border-top: 10.2px solid #f7f8f7;
}

.border-t-green-10-2 {
  border-top: 10.2px solid #0b8376;
}

.border-t-purple-10-2 {
  border-top: 10.2px solid #755ae2;
}

.border-t-orange-10-2 {
  border-top: 10.2px solid #ea872d;
}

.border-t-black-10-2 {
  border-top: 10.2px solid #020202;
}

.border-t-light-gray-10-2 {
  border-top: 10.2px solid #fafafa;
}

.border-t-deep-green-10-2 {
  border-top: 10.2px solid #014345;
}

.border-b-primary-10-2 {
  border-bottom: 10.2px solid #cccccc;
}

.border-b-secondary-10-2 {
  border-bottom: 10.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-2 {
  border-bottom: 10.2px solid #ff0f00;
}

.border-b-info-10-2 {
  border-bottom: 10.2px solid #f6c31c;
}

.border-b-white-10-2 {
  border-bottom: 10.2px solid #f7f8f7;
}

.border-b-green-10-2 {
  border-bottom: 10.2px solid #0b8376;
}

.border-b-purple-10-2 {
  border-bottom: 10.2px solid #755ae2;
}

.border-b-orange-10-2 {
  border-bottom: 10.2px solid #ea872d;
}

.border-b-black-10-2 {
  border-bottom: 10.2px solid #020202;
}

.border-b-light-gray-10-2 {
  border-bottom: 10.2px solid #fafafa;
}

.border-b-deep-green-10-2 {
  border-bottom: 10.2px solid #014345;
}

.border-l-primary-10-2 {
  border-left: 10.2px solid #cccccc;
}

.border-l-secondary-10-2 {
  border-left: 10.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-2 {
  border-left: 10.2px solid #ff0f00;
}

.border-l-info-10-2 {
  border-left: 10.2px solid #f6c31c;
}

.border-l-white-10-2 {
  border-left: 10.2px solid #f7f8f7;
}

.border-l-green-10-2 {
  border-left: 10.2px solid #0b8376;
}

.border-l-purple-10-2 {
  border-left: 10.2px solid #755ae2;
}

.border-l-orange-10-2 {
  border-left: 10.2px solid #ea872d;
}

.border-l-black-10-2 {
  border-left: 10.2px solid #020202;
}

.border-l-light-gray-10-2 {
  border-left: 10.2px solid #fafafa;
}

.border-l-deep-green-10-2 {
  border-left: 10.2px solid #014345;
}

.border-r-primary-10-2 {
  border-right: 10.2px solid #cccccc;
}

.border-r-secondary-10-2 {
  border-right: 10.2px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-2 {
  border-right: 10.2px solid #ff0f00;
}

.border-r-info-10-2 {
  border-right: 10.2px solid #f6c31c;
}

.border-r-white-10-2 {
  border-right: 10.2px solid #f7f8f7;
}

.border-r-green-10-2 {
  border-right: 10.2px solid #0b8376;
}

.border-r-purple-10-2 {
  border-right: 10.2px solid #755ae2;
}

.border-r-orange-10-2 {
  border-right: 10.2px solid #ea872d;
}

.border-r-black-10-2 {
  border-right: 10.2px solid #020202;
}

.border-r-light-gray-10-2 {
  border-right: 10.2px solid #fafafa;
}

.border-r-deep-green-10-2 {
  border-right: 10.2px solid #014345;
}

.border-primary-10-3 {
  border: 10.3px solid #cccccc;
}

.border-secondary-10-3 {
  border: 10.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-3 {
  border: 10.3px solid #ff0f00;
}

.border-info-10-3 {
  border: 10.3px solid #f6c31c;
}

.border-white-10-3 {
  border: 10.3px solid #f7f8f7;
}

.border-green-10-3 {
  border: 10.3px solid #0b8376;
}

.border-purple-10-3 {
  border: 10.3px solid #755ae2;
}

.border-orange-10-3 {
  border: 10.3px solid #ea872d;
}

.border-black-10-3 {
  border: 10.3px solid #020202;
}

.border-light-gray-10-3 {
  border: 10.3px solid #fafafa;
}

.border-deep-green-10-3 {
  border: 10.3px solid #014345;
}

.border-t-primary-10-3 {
  border-top: 10.3px solid #cccccc;
}

.border-t-secondary-10-3 {
  border-top: 10.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-3 {
  border-top: 10.3px solid #ff0f00;
}

.border-t-info-10-3 {
  border-top: 10.3px solid #f6c31c;
}

.border-t-white-10-3 {
  border-top: 10.3px solid #f7f8f7;
}

.border-t-green-10-3 {
  border-top: 10.3px solid #0b8376;
}

.border-t-purple-10-3 {
  border-top: 10.3px solid #755ae2;
}

.border-t-orange-10-3 {
  border-top: 10.3px solid #ea872d;
}

.border-t-black-10-3 {
  border-top: 10.3px solid #020202;
}

.border-t-light-gray-10-3 {
  border-top: 10.3px solid #fafafa;
}

.border-t-deep-green-10-3 {
  border-top: 10.3px solid #014345;
}

.border-b-primary-10-3 {
  border-bottom: 10.3px solid #cccccc;
}

.border-b-secondary-10-3 {
  border-bottom: 10.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-3 {
  border-bottom: 10.3px solid #ff0f00;
}

.border-b-info-10-3 {
  border-bottom: 10.3px solid #f6c31c;
}

.border-b-white-10-3 {
  border-bottom: 10.3px solid #f7f8f7;
}

.border-b-green-10-3 {
  border-bottom: 10.3px solid #0b8376;
}

.border-b-purple-10-3 {
  border-bottom: 10.3px solid #755ae2;
}

.border-b-orange-10-3 {
  border-bottom: 10.3px solid #ea872d;
}

.border-b-black-10-3 {
  border-bottom: 10.3px solid #020202;
}

.border-b-light-gray-10-3 {
  border-bottom: 10.3px solid #fafafa;
}

.border-b-deep-green-10-3 {
  border-bottom: 10.3px solid #014345;
}

.border-l-primary-10-3 {
  border-left: 10.3px solid #cccccc;
}

.border-l-secondary-10-3 {
  border-left: 10.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-3 {
  border-left: 10.3px solid #ff0f00;
}

.border-l-info-10-3 {
  border-left: 10.3px solid #f6c31c;
}

.border-l-white-10-3 {
  border-left: 10.3px solid #f7f8f7;
}

.border-l-green-10-3 {
  border-left: 10.3px solid #0b8376;
}

.border-l-purple-10-3 {
  border-left: 10.3px solid #755ae2;
}

.border-l-orange-10-3 {
  border-left: 10.3px solid #ea872d;
}

.border-l-black-10-3 {
  border-left: 10.3px solid #020202;
}

.border-l-light-gray-10-3 {
  border-left: 10.3px solid #fafafa;
}

.border-l-deep-green-10-3 {
  border-left: 10.3px solid #014345;
}

.border-r-primary-10-3 {
  border-right: 10.3px solid #cccccc;
}

.border-r-secondary-10-3 {
  border-right: 10.3px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-3 {
  border-right: 10.3px solid #ff0f00;
}

.border-r-info-10-3 {
  border-right: 10.3px solid #f6c31c;
}

.border-r-white-10-3 {
  border-right: 10.3px solid #f7f8f7;
}

.border-r-green-10-3 {
  border-right: 10.3px solid #0b8376;
}

.border-r-purple-10-3 {
  border-right: 10.3px solid #755ae2;
}

.border-r-orange-10-3 {
  border-right: 10.3px solid #ea872d;
}

.border-r-black-10-3 {
  border-right: 10.3px solid #020202;
}

.border-r-light-gray-10-3 {
  border-right: 10.3px solid #fafafa;
}

.border-r-deep-green-10-3 {
  border-right: 10.3px solid #014345;
}

.border-primary-10-4 {
  border: 10.4px solid #cccccc;
}

.border-secondary-10-4 {
  border: 10.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-4 {
  border: 10.4px solid #ff0f00;
}

.border-info-10-4 {
  border: 10.4px solid #f6c31c;
}

.border-white-10-4 {
  border: 10.4px solid #f7f8f7;
}

.border-green-10-4 {
  border: 10.4px solid #0b8376;
}

.border-purple-10-4 {
  border: 10.4px solid #755ae2;
}

.border-orange-10-4 {
  border: 10.4px solid #ea872d;
}

.border-black-10-4 {
  border: 10.4px solid #020202;
}

.border-light-gray-10-4 {
  border: 10.4px solid #fafafa;
}

.border-deep-green-10-4 {
  border: 10.4px solid #014345;
}

.border-t-primary-10-4 {
  border-top: 10.4px solid #cccccc;
}

.border-t-secondary-10-4 {
  border-top: 10.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-4 {
  border-top: 10.4px solid #ff0f00;
}

.border-t-info-10-4 {
  border-top: 10.4px solid #f6c31c;
}

.border-t-white-10-4 {
  border-top: 10.4px solid #f7f8f7;
}

.border-t-green-10-4 {
  border-top: 10.4px solid #0b8376;
}

.border-t-purple-10-4 {
  border-top: 10.4px solid #755ae2;
}

.border-t-orange-10-4 {
  border-top: 10.4px solid #ea872d;
}

.border-t-black-10-4 {
  border-top: 10.4px solid #020202;
}

.border-t-light-gray-10-4 {
  border-top: 10.4px solid #fafafa;
}

.border-t-deep-green-10-4 {
  border-top: 10.4px solid #014345;
}

.border-b-primary-10-4 {
  border-bottom: 10.4px solid #cccccc;
}

.border-b-secondary-10-4 {
  border-bottom: 10.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-4 {
  border-bottom: 10.4px solid #ff0f00;
}

.border-b-info-10-4 {
  border-bottom: 10.4px solid #f6c31c;
}

.border-b-white-10-4 {
  border-bottom: 10.4px solid #f7f8f7;
}

.border-b-green-10-4 {
  border-bottom: 10.4px solid #0b8376;
}

.border-b-purple-10-4 {
  border-bottom: 10.4px solid #755ae2;
}

.border-b-orange-10-4 {
  border-bottom: 10.4px solid #ea872d;
}

.border-b-black-10-4 {
  border-bottom: 10.4px solid #020202;
}

.border-b-light-gray-10-4 {
  border-bottom: 10.4px solid #fafafa;
}

.border-b-deep-green-10-4 {
  border-bottom: 10.4px solid #014345;
}

.border-l-primary-10-4 {
  border-left: 10.4px solid #cccccc;
}

.border-l-secondary-10-4 {
  border-left: 10.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-4 {
  border-left: 10.4px solid #ff0f00;
}

.border-l-info-10-4 {
  border-left: 10.4px solid #f6c31c;
}

.border-l-white-10-4 {
  border-left: 10.4px solid #f7f8f7;
}

.border-l-green-10-4 {
  border-left: 10.4px solid #0b8376;
}

.border-l-purple-10-4 {
  border-left: 10.4px solid #755ae2;
}

.border-l-orange-10-4 {
  border-left: 10.4px solid #ea872d;
}

.border-l-black-10-4 {
  border-left: 10.4px solid #020202;
}

.border-l-light-gray-10-4 {
  border-left: 10.4px solid #fafafa;
}

.border-l-deep-green-10-4 {
  border-left: 10.4px solid #014345;
}

.border-r-primary-10-4 {
  border-right: 10.4px solid #cccccc;
}

.border-r-secondary-10-4 {
  border-right: 10.4px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-4 {
  border-right: 10.4px solid #ff0f00;
}

.border-r-info-10-4 {
  border-right: 10.4px solid #f6c31c;
}

.border-r-white-10-4 {
  border-right: 10.4px solid #f7f8f7;
}

.border-r-green-10-4 {
  border-right: 10.4px solid #0b8376;
}

.border-r-purple-10-4 {
  border-right: 10.4px solid #755ae2;
}

.border-r-orange-10-4 {
  border-right: 10.4px solid #ea872d;
}

.border-r-black-10-4 {
  border-right: 10.4px solid #020202;
}

.border-r-light-gray-10-4 {
  border-right: 10.4px solid #fafafa;
}

.border-r-deep-green-10-4 {
  border-right: 10.4px solid #014345;
}

.border-primary-10-5 {
  border: 10.5px solid #cccccc;
}

.border-secondary-10-5 {
  border: 10.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-5 {
  border: 10.5px solid #ff0f00;
}

.border-info-10-5 {
  border: 10.5px solid #f6c31c;
}

.border-white-10-5 {
  border: 10.5px solid #f7f8f7;
}

.border-green-10-5 {
  border: 10.5px solid #0b8376;
}

.border-purple-10-5 {
  border: 10.5px solid #755ae2;
}

.border-orange-10-5 {
  border: 10.5px solid #ea872d;
}

.border-black-10-5 {
  border: 10.5px solid #020202;
}

.border-light-gray-10-5 {
  border: 10.5px solid #fafafa;
}

.border-deep-green-10-5 {
  border: 10.5px solid #014345;
}

.border-t-primary-10-5 {
  border-top: 10.5px solid #cccccc;
}

.border-t-secondary-10-5 {
  border-top: 10.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-5 {
  border-top: 10.5px solid #ff0f00;
}

.border-t-info-10-5 {
  border-top: 10.5px solid #f6c31c;
}

.border-t-white-10-5 {
  border-top: 10.5px solid #f7f8f7;
}

.border-t-green-10-5 {
  border-top: 10.5px solid #0b8376;
}

.border-t-purple-10-5 {
  border-top: 10.5px solid #755ae2;
}

.border-t-orange-10-5 {
  border-top: 10.5px solid #ea872d;
}

.border-t-black-10-5 {
  border-top: 10.5px solid #020202;
}

.border-t-light-gray-10-5 {
  border-top: 10.5px solid #fafafa;
}

.border-t-deep-green-10-5 {
  border-top: 10.5px solid #014345;
}

.border-b-primary-10-5 {
  border-bottom: 10.5px solid #cccccc;
}

.border-b-secondary-10-5 {
  border-bottom: 10.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-5 {
  border-bottom: 10.5px solid #ff0f00;
}

.border-b-info-10-5 {
  border-bottom: 10.5px solid #f6c31c;
}

.border-b-white-10-5 {
  border-bottom: 10.5px solid #f7f8f7;
}

.border-b-green-10-5 {
  border-bottom: 10.5px solid #0b8376;
}

.border-b-purple-10-5 {
  border-bottom: 10.5px solid #755ae2;
}

.border-b-orange-10-5 {
  border-bottom: 10.5px solid #ea872d;
}

.border-b-black-10-5 {
  border-bottom: 10.5px solid #020202;
}

.border-b-light-gray-10-5 {
  border-bottom: 10.5px solid #fafafa;
}

.border-b-deep-green-10-5 {
  border-bottom: 10.5px solid #014345;
}

.border-l-primary-10-5 {
  border-left: 10.5px solid #cccccc;
}

.border-l-secondary-10-5 {
  border-left: 10.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-5 {
  border-left: 10.5px solid #ff0f00;
}

.border-l-info-10-5 {
  border-left: 10.5px solid #f6c31c;
}

.border-l-white-10-5 {
  border-left: 10.5px solid #f7f8f7;
}

.border-l-green-10-5 {
  border-left: 10.5px solid #0b8376;
}

.border-l-purple-10-5 {
  border-left: 10.5px solid #755ae2;
}

.border-l-orange-10-5 {
  border-left: 10.5px solid #ea872d;
}

.border-l-black-10-5 {
  border-left: 10.5px solid #020202;
}

.border-l-light-gray-10-5 {
  border-left: 10.5px solid #fafafa;
}

.border-l-deep-green-10-5 {
  border-left: 10.5px solid #014345;
}

.border-r-primary-10-5 {
  border-right: 10.5px solid #cccccc;
}

.border-r-secondary-10-5 {
  border-right: 10.5px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-5 {
  border-right: 10.5px solid #ff0f00;
}

.border-r-info-10-5 {
  border-right: 10.5px solid #f6c31c;
}

.border-r-white-10-5 {
  border-right: 10.5px solid #f7f8f7;
}

.border-r-green-10-5 {
  border-right: 10.5px solid #0b8376;
}

.border-r-purple-10-5 {
  border-right: 10.5px solid #755ae2;
}

.border-r-orange-10-5 {
  border-right: 10.5px solid #ea872d;
}

.border-r-black-10-5 {
  border-right: 10.5px solid #020202;
}

.border-r-light-gray-10-5 {
  border-right: 10.5px solid #fafafa;
}

.border-r-deep-green-10-5 {
  border-right: 10.5px solid #014345;
}

.border-primary-10-6 {
  border: 10.6px solid #cccccc;
}

.border-secondary-10-6 {
  border: 10.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-6 {
  border: 10.6px solid #ff0f00;
}

.border-info-10-6 {
  border: 10.6px solid #f6c31c;
}

.border-white-10-6 {
  border: 10.6px solid #f7f8f7;
}

.border-green-10-6 {
  border: 10.6px solid #0b8376;
}

.border-purple-10-6 {
  border: 10.6px solid #755ae2;
}

.border-orange-10-6 {
  border: 10.6px solid #ea872d;
}

.border-black-10-6 {
  border: 10.6px solid #020202;
}

.border-light-gray-10-6 {
  border: 10.6px solid #fafafa;
}

.border-deep-green-10-6 {
  border: 10.6px solid #014345;
}

.border-t-primary-10-6 {
  border-top: 10.6px solid #cccccc;
}

.border-t-secondary-10-6 {
  border-top: 10.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-6 {
  border-top: 10.6px solid #ff0f00;
}

.border-t-info-10-6 {
  border-top: 10.6px solid #f6c31c;
}

.border-t-white-10-6 {
  border-top: 10.6px solid #f7f8f7;
}

.border-t-green-10-6 {
  border-top: 10.6px solid #0b8376;
}

.border-t-purple-10-6 {
  border-top: 10.6px solid #755ae2;
}

.border-t-orange-10-6 {
  border-top: 10.6px solid #ea872d;
}

.border-t-black-10-6 {
  border-top: 10.6px solid #020202;
}

.border-t-light-gray-10-6 {
  border-top: 10.6px solid #fafafa;
}

.border-t-deep-green-10-6 {
  border-top: 10.6px solid #014345;
}

.border-b-primary-10-6 {
  border-bottom: 10.6px solid #cccccc;
}

.border-b-secondary-10-6 {
  border-bottom: 10.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-6 {
  border-bottom: 10.6px solid #ff0f00;
}

.border-b-info-10-6 {
  border-bottom: 10.6px solid #f6c31c;
}

.border-b-white-10-6 {
  border-bottom: 10.6px solid #f7f8f7;
}

.border-b-green-10-6 {
  border-bottom: 10.6px solid #0b8376;
}

.border-b-purple-10-6 {
  border-bottom: 10.6px solid #755ae2;
}

.border-b-orange-10-6 {
  border-bottom: 10.6px solid #ea872d;
}

.border-b-black-10-6 {
  border-bottom: 10.6px solid #020202;
}

.border-b-light-gray-10-6 {
  border-bottom: 10.6px solid #fafafa;
}

.border-b-deep-green-10-6 {
  border-bottom: 10.6px solid #014345;
}

.border-l-primary-10-6 {
  border-left: 10.6px solid #cccccc;
}

.border-l-secondary-10-6 {
  border-left: 10.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-6 {
  border-left: 10.6px solid #ff0f00;
}

.border-l-info-10-6 {
  border-left: 10.6px solid #f6c31c;
}

.border-l-white-10-6 {
  border-left: 10.6px solid #f7f8f7;
}

.border-l-green-10-6 {
  border-left: 10.6px solid #0b8376;
}

.border-l-purple-10-6 {
  border-left: 10.6px solid #755ae2;
}

.border-l-orange-10-6 {
  border-left: 10.6px solid #ea872d;
}

.border-l-black-10-6 {
  border-left: 10.6px solid #020202;
}

.border-l-light-gray-10-6 {
  border-left: 10.6px solid #fafafa;
}

.border-l-deep-green-10-6 {
  border-left: 10.6px solid #014345;
}

.border-r-primary-10-6 {
  border-right: 10.6px solid #cccccc;
}

.border-r-secondary-10-6 {
  border-right: 10.6px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-6 {
  border-right: 10.6px solid #ff0f00;
}

.border-r-info-10-6 {
  border-right: 10.6px solid #f6c31c;
}

.border-r-white-10-6 {
  border-right: 10.6px solid #f7f8f7;
}

.border-r-green-10-6 {
  border-right: 10.6px solid #0b8376;
}

.border-r-purple-10-6 {
  border-right: 10.6px solid #755ae2;
}

.border-r-orange-10-6 {
  border-right: 10.6px solid #ea872d;
}

.border-r-black-10-6 {
  border-right: 10.6px solid #020202;
}

.border-r-light-gray-10-6 {
  border-right: 10.6px solid #fafafa;
}

.border-r-deep-green-10-6 {
  border-right: 10.6px solid #014345;
}

.border-primary-10-7 {
  border: 10.7px solid #cccccc;
}

.border-secondary-10-7 {
  border: 10.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-7 {
  border: 10.7px solid #ff0f00;
}

.border-info-10-7 {
  border: 10.7px solid #f6c31c;
}

.border-white-10-7 {
  border: 10.7px solid #f7f8f7;
}

.border-green-10-7 {
  border: 10.7px solid #0b8376;
}

.border-purple-10-7 {
  border: 10.7px solid #755ae2;
}

.border-orange-10-7 {
  border: 10.7px solid #ea872d;
}

.border-black-10-7 {
  border: 10.7px solid #020202;
}

.border-light-gray-10-7 {
  border: 10.7px solid #fafafa;
}

.border-deep-green-10-7 {
  border: 10.7px solid #014345;
}

.border-t-primary-10-7 {
  border-top: 10.7px solid #cccccc;
}

.border-t-secondary-10-7 {
  border-top: 10.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-7 {
  border-top: 10.7px solid #ff0f00;
}

.border-t-info-10-7 {
  border-top: 10.7px solid #f6c31c;
}

.border-t-white-10-7 {
  border-top: 10.7px solid #f7f8f7;
}

.border-t-green-10-7 {
  border-top: 10.7px solid #0b8376;
}

.border-t-purple-10-7 {
  border-top: 10.7px solid #755ae2;
}

.border-t-orange-10-7 {
  border-top: 10.7px solid #ea872d;
}

.border-t-black-10-7 {
  border-top: 10.7px solid #020202;
}

.border-t-light-gray-10-7 {
  border-top: 10.7px solid #fafafa;
}

.border-t-deep-green-10-7 {
  border-top: 10.7px solid #014345;
}

.border-b-primary-10-7 {
  border-bottom: 10.7px solid #cccccc;
}

.border-b-secondary-10-7 {
  border-bottom: 10.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-7 {
  border-bottom: 10.7px solid #ff0f00;
}

.border-b-info-10-7 {
  border-bottom: 10.7px solid #f6c31c;
}

.border-b-white-10-7 {
  border-bottom: 10.7px solid #f7f8f7;
}

.border-b-green-10-7 {
  border-bottom: 10.7px solid #0b8376;
}

.border-b-purple-10-7 {
  border-bottom: 10.7px solid #755ae2;
}

.border-b-orange-10-7 {
  border-bottom: 10.7px solid #ea872d;
}

.border-b-black-10-7 {
  border-bottom: 10.7px solid #020202;
}

.border-b-light-gray-10-7 {
  border-bottom: 10.7px solid #fafafa;
}

.border-b-deep-green-10-7 {
  border-bottom: 10.7px solid #014345;
}

.border-l-primary-10-7 {
  border-left: 10.7px solid #cccccc;
}

.border-l-secondary-10-7 {
  border-left: 10.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-7 {
  border-left: 10.7px solid #ff0f00;
}

.border-l-info-10-7 {
  border-left: 10.7px solid #f6c31c;
}

.border-l-white-10-7 {
  border-left: 10.7px solid #f7f8f7;
}

.border-l-green-10-7 {
  border-left: 10.7px solid #0b8376;
}

.border-l-purple-10-7 {
  border-left: 10.7px solid #755ae2;
}

.border-l-orange-10-7 {
  border-left: 10.7px solid #ea872d;
}

.border-l-black-10-7 {
  border-left: 10.7px solid #020202;
}

.border-l-light-gray-10-7 {
  border-left: 10.7px solid #fafafa;
}

.border-l-deep-green-10-7 {
  border-left: 10.7px solid #014345;
}

.border-r-primary-10-7 {
  border-right: 10.7px solid #cccccc;
}

.border-r-secondary-10-7 {
  border-right: 10.7px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-7 {
  border-right: 10.7px solid #ff0f00;
}

.border-r-info-10-7 {
  border-right: 10.7px solid #f6c31c;
}

.border-r-white-10-7 {
  border-right: 10.7px solid #f7f8f7;
}

.border-r-green-10-7 {
  border-right: 10.7px solid #0b8376;
}

.border-r-purple-10-7 {
  border-right: 10.7px solid #755ae2;
}

.border-r-orange-10-7 {
  border-right: 10.7px solid #ea872d;
}

.border-r-black-10-7 {
  border-right: 10.7px solid #020202;
}

.border-r-light-gray-10-7 {
  border-right: 10.7px solid #fafafa;
}

.border-r-deep-green-10-7 {
  border-right: 10.7px solid #014345;
}

.border-primary-10-8 {
  border: 10.8px solid #cccccc;
}

.border-secondary-10-8 {
  border: 10.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-8 {
  border: 10.8px solid #ff0f00;
}

.border-info-10-8 {
  border: 10.8px solid #f6c31c;
}

.border-white-10-8 {
  border: 10.8px solid #f7f8f7;
}

.border-green-10-8 {
  border: 10.8px solid #0b8376;
}

.border-purple-10-8 {
  border: 10.8px solid #755ae2;
}

.border-orange-10-8 {
  border: 10.8px solid #ea872d;
}

.border-black-10-8 {
  border: 10.8px solid #020202;
}

.border-light-gray-10-8 {
  border: 10.8px solid #fafafa;
}

.border-deep-green-10-8 {
  border: 10.8px solid #014345;
}

.border-t-primary-10-8 {
  border-top: 10.8px solid #cccccc;
}

.border-t-secondary-10-8 {
  border-top: 10.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-8 {
  border-top: 10.8px solid #ff0f00;
}

.border-t-info-10-8 {
  border-top: 10.8px solid #f6c31c;
}

.border-t-white-10-8 {
  border-top: 10.8px solid #f7f8f7;
}

.border-t-green-10-8 {
  border-top: 10.8px solid #0b8376;
}

.border-t-purple-10-8 {
  border-top: 10.8px solid #755ae2;
}

.border-t-orange-10-8 {
  border-top: 10.8px solid #ea872d;
}

.border-t-black-10-8 {
  border-top: 10.8px solid #020202;
}

.border-t-light-gray-10-8 {
  border-top: 10.8px solid #fafafa;
}

.border-t-deep-green-10-8 {
  border-top: 10.8px solid #014345;
}

.border-b-primary-10-8 {
  border-bottom: 10.8px solid #cccccc;
}

.border-b-secondary-10-8 {
  border-bottom: 10.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-8 {
  border-bottom: 10.8px solid #ff0f00;
}

.border-b-info-10-8 {
  border-bottom: 10.8px solid #f6c31c;
}

.border-b-white-10-8 {
  border-bottom: 10.8px solid #f7f8f7;
}

.border-b-green-10-8 {
  border-bottom: 10.8px solid #0b8376;
}

.border-b-purple-10-8 {
  border-bottom: 10.8px solid #755ae2;
}

.border-b-orange-10-8 {
  border-bottom: 10.8px solid #ea872d;
}

.border-b-black-10-8 {
  border-bottom: 10.8px solid #020202;
}

.border-b-light-gray-10-8 {
  border-bottom: 10.8px solid #fafafa;
}

.border-b-deep-green-10-8 {
  border-bottom: 10.8px solid #014345;
}

.border-l-primary-10-8 {
  border-left: 10.8px solid #cccccc;
}

.border-l-secondary-10-8 {
  border-left: 10.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-8 {
  border-left: 10.8px solid #ff0f00;
}

.border-l-info-10-8 {
  border-left: 10.8px solid #f6c31c;
}

.border-l-white-10-8 {
  border-left: 10.8px solid #f7f8f7;
}

.border-l-green-10-8 {
  border-left: 10.8px solid #0b8376;
}

.border-l-purple-10-8 {
  border-left: 10.8px solid #755ae2;
}

.border-l-orange-10-8 {
  border-left: 10.8px solid #ea872d;
}

.border-l-black-10-8 {
  border-left: 10.8px solid #020202;
}

.border-l-light-gray-10-8 {
  border-left: 10.8px solid #fafafa;
}

.border-l-deep-green-10-8 {
  border-left: 10.8px solid #014345;
}

.border-r-primary-10-8 {
  border-right: 10.8px solid #cccccc;
}

.border-r-secondary-10-8 {
  border-right: 10.8px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-8 {
  border-right: 10.8px solid #ff0f00;
}

.border-r-info-10-8 {
  border-right: 10.8px solid #f6c31c;
}

.border-r-white-10-8 {
  border-right: 10.8px solid #f7f8f7;
}

.border-r-green-10-8 {
  border-right: 10.8px solid #0b8376;
}

.border-r-purple-10-8 {
  border-right: 10.8px solid #755ae2;
}

.border-r-orange-10-8 {
  border-right: 10.8px solid #ea872d;
}

.border-r-black-10-8 {
  border-right: 10.8px solid #020202;
}

.border-r-light-gray-10-8 {
  border-right: 10.8px solid #fafafa;
}

.border-r-deep-green-10-8 {
  border-right: 10.8px solid #014345;
}

.border-primary-10-9 {
  border: 10.9px solid #cccccc;
}

.border-secondary-10-9 {
  border: 10.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-error-10-9 {
  border: 10.9px solid #ff0f00;
}

.border-info-10-9 {
  border: 10.9px solid #f6c31c;
}

.border-white-10-9 {
  border: 10.9px solid #f7f8f7;
}

.border-green-10-9 {
  border: 10.9px solid #0b8376;
}

.border-purple-10-9 {
  border: 10.9px solid #755ae2;
}

.border-orange-10-9 {
  border: 10.9px solid #ea872d;
}

.border-black-10-9 {
  border: 10.9px solid #020202;
}

.border-light-gray-10-9 {
  border: 10.9px solid #fafafa;
}

.border-deep-green-10-9 {
  border: 10.9px solid #014345;
}

.border-t-primary-10-9 {
  border-top: 10.9px solid #cccccc;
}

.border-t-secondary-10-9 {
  border-top: 10.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-t-error-10-9 {
  border-top: 10.9px solid #ff0f00;
}

.border-t-info-10-9 {
  border-top: 10.9px solid #f6c31c;
}

.border-t-white-10-9 {
  border-top: 10.9px solid #f7f8f7;
}

.border-t-green-10-9 {
  border-top: 10.9px solid #0b8376;
}

.border-t-purple-10-9 {
  border-top: 10.9px solid #755ae2;
}

.border-t-orange-10-9 {
  border-top: 10.9px solid #ea872d;
}

.border-t-black-10-9 {
  border-top: 10.9px solid #020202;
}

.border-t-light-gray-10-9 {
  border-top: 10.9px solid #fafafa;
}

.border-t-deep-green-10-9 {
  border-top: 10.9px solid #014345;
}

.border-b-primary-10-9 {
  border-bottom: 10.9px solid #cccccc;
}

.border-b-secondary-10-9 {
  border-bottom: 10.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-b-error-10-9 {
  border-bottom: 10.9px solid #ff0f00;
}

.border-b-info-10-9 {
  border-bottom: 10.9px solid #f6c31c;
}

.border-b-white-10-9 {
  border-bottom: 10.9px solid #f7f8f7;
}

.border-b-green-10-9 {
  border-bottom: 10.9px solid #0b8376;
}

.border-b-purple-10-9 {
  border-bottom: 10.9px solid #755ae2;
}

.border-b-orange-10-9 {
  border-bottom: 10.9px solid #ea872d;
}

.border-b-black-10-9 {
  border-bottom: 10.9px solid #020202;
}

.border-b-light-gray-10-9 {
  border-bottom: 10.9px solid #fafafa;
}

.border-b-deep-green-10-9 {
  border-bottom: 10.9px solid #014345;
}

.border-l-primary-10-9 {
  border-left: 10.9px solid #cccccc;
}

.border-l-secondary-10-9 {
  border-left: 10.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-l-error-10-9 {
  border-left: 10.9px solid #ff0f00;
}

.border-l-info-10-9 {
  border-left: 10.9px solid #f6c31c;
}

.border-l-white-10-9 {
  border-left: 10.9px solid #f7f8f7;
}

.border-l-green-10-9 {
  border-left: 10.9px solid #0b8376;
}

.border-l-purple-10-9 {
  border-left: 10.9px solid #755ae2;
}

.border-l-orange-10-9 {
  border-left: 10.9px solid #ea872d;
}

.border-l-black-10-9 {
  border-left: 10.9px solid #020202;
}

.border-l-light-gray-10-9 {
  border-left: 10.9px solid #fafafa;
}

.border-l-deep-green-10-9 {
  border-left: 10.9px solid #014345;
}

.border-r-primary-10-9 {
  border-right: 10.9px solid #cccccc;
}

.border-r-secondary-10-9 {
  border-right: 10.9px solid rgba(223, 66, 9, 0.631372549);
}

.border-r-error-10-9 {
  border-right: 10.9px solid #ff0f00;
}

.border-r-info-10-9 {
  border-right: 10.9px solid #f6c31c;
}

.border-r-white-10-9 {
  border-right: 10.9px solid #f7f8f7;
}

.border-r-green-10-9 {
  border-right: 10.9px solid #0b8376;
}

.border-r-purple-10-9 {
  border-right: 10.9px solid #755ae2;
}

.border-r-orange-10-9 {
  border-right: 10.9px solid #ea872d;
}

.border-r-black-10-9 {
  border-right: 10.9px solid #020202;
}

.border-r-light-gray-10-9 {
  border-right: 10.9px solid #fafafa;
}

.border-r-deep-green-10-9 {
  border-right: 10.9px solid #014345;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.transition-fast {
  transition: all 0.2s ease-in-out;
}

.transition-normal {
  transition: all 0.5s ease-in-out;
}

.transition-slow {
  transition: all 1s ease-in-out;
}

.grid-center {
  display: grid;
  place-items: center;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section .hero-body {
  z-index: 1;
}

.hero-section-20 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 20vh;
}
.hero-section-20 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-20 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-20 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-30 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 30vh;
}
.hero-section-30 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-30 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-30 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-40 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 40vh;
}
.hero-section-40 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-40 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-40 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-50 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
}
.hero-section-50 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-50 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-50 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-60 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
}
.hero-section-60 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-60 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-60 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-70 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 70vh;
}
.hero-section-70 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-70 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-70 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-80 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 80vh;
}
.hero-section-80 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-80 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-80 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-90 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
}
.hero-section-90 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-90 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-90 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

.hero-section-100 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}
.hero-section-100 .hero-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, rgba(47, 27, 15, 0.4), rgba(47, 27, 15, 0.4)),
    rgba(47, 27, 15, 0.4);
}
.hero-section-100 .hero-body {
  z-index: 1;
  width: 100%;
}
.hero-section-100 .hero-body .hero-navbar {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}

input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  font: muli;
  color: currentColor;
  width: 1.15rem;
  height: 1.15rem;
  border: 0.15rem solid currentColor;
  border-radius: 0.15rem;
  -webkit-transform: translateY(-0.075em);
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(
    14% 44%,
    0 65%,
    50% 100%,
    100% 16%,
    80% 0%,
    43% 62%
  );
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 1em 1em white;
}

input[type='checkbox']:checked::before {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: var(--raven-black);
}

input[type='checkbox']:checked {
  background-color: var(--raven-black);
  /* color: white */
}

input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--raven-mercury);
  cursor: not-allowed;
}

input[type='text'] {
  padding: 1.5rem;
  border: 0.5px solid #cccccc;
  border-radius: 1.2rem;
  width: 100%;
}

.input[type='text']:focus {
  border: 1px solid #020202;
}

input[type='number'] {
  padding: 1.5rem;
  border: 0.5px solid #cccccc;
  border-radius: 1.2rem;
  width: 100%;
}

.input[type='number']:focus {
  border: 1px solid #020202;
}

/* begin select input type reset and styling */
select {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  padding: 1.6rem;
}

select::-ms-expand {
  display: none;
}

.select {
  /* width: 16%; */
  /* min-width: 10ch; */
  /* border: 1px solid var(--raven-mercury); */
  border-radius: 1.2rem;
  display: flex;
  font-size: 1.25rem;
  cursor: pointer;
  position: relative;
  line-height: 1.1;
  background-color: #fff;
  /* grid-template-areas: "select"; */
}

.select select::after {
  content: '';
  width: 0.8em;
  height: 0.5rem;
  background-color: var(--raven-black);
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.select select:after {
  /* grid-area: select; */
}

.select select:focus {
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid var(--raven-black);
  border-radius: inherit;
}

.select:not(.select--multiple)::after select[multiple] {
  height: 6rem;
  padding-right: 0;
}

select[multiple] option {
  white-space: normal;
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.phone-input {
  width: 3rem;
}

input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid currentColor;
  border-radius: 50%;
  -webkit-transform: translateY(-0.075em);
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

label {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: -0.04em;
  color: #000000;
  line-height: 1.1;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 1em 1em var(--raven-black);
}

input[type='radio']:checked::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

textarea {
  background: #ffffff;
  border: 0.5px solid #cccccc;
  border-radius: 1.2rem;
  padding: 1.6rem;
  width: 100%;
}

textarea:focus {
  border: 1px solid var(--raven-black);
}

input[type='date'] {
  font-family: inherit;
  font: inherit;
  font-size: 1.2rem;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: var(--raven-black);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6px; /* Centers thumb on the track */
  /*custom styles*/
  width: 28.55px;
  height: 16.65px;
  background: rgb(250, 245, 245);
  border: 1.18947px solid #f7f8f7;
  box-shadow: 0px 1.18947px 4.75789px rgba(0, 0, 0, 0.14);
  border-radius: 66.6105px;
}

input[type='range']:focus::-webkit-slider-thumb {
  border: 1px solid #053a5f;
  outline: 1px solid #053a5f;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type='range']::-moz-range-track {
  background-color: var(--raven-black);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
  /*custom styles*/
  background-color: #5cd5eb;
  height: 2rem;
  width: 1rem;
}

input[type='range']:focus::-moz-range-thumb {
  border: 1px solid #053a5f;
  outline: 1px solid #053a5f;
  outline-offset: 0.125rem;
}

input[type='range'] {
  width: 100%;
  margin-top: 1rem;
}

input[type='password']::-webkit-autofill,
input[type='password']::-webkit-autofill:hover,
input[type='password']::-webkit-autofill:focus,
input[type='password']::-webkit-autofill:active {
  box-shadow: 0 0 0 3rem white inset !important;
  -webkit-box-shadow: 0 0 0 3rem white inset !important;
}

.select-react {
  width: 100%;
  box-shadow: none !important;
}
.select-react div:first-child {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.select-react input:focus {
  box-shadow: none;
}

.select-react > * {
  text-align: left;
}

.select-react > *:nth-child(3) {
  border: 0.1rem solid #cccccc;
  box-shadow: none !important;
  padding: 0.3rem 1.2rem;
  border-radius: 1rem;
}
