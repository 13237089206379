.timeline-mobile {
  display: none;
}

@media screen and (max-width: 29.9375em) {
  .desktop-timeline {
    display: none;
  }
  .timeline-mobile {
    display: flex;
    width: 100%;
    border: 0.5px solid #cccccc;
    width: 100%;
    gap: 2rem;
    padding: 2rem;
    border-radius: 2rem;
    flex-direction: column;
  }
  .timeline-mobile .author_section {
    display: flex;
    justify-content: space-between;
  }
  .timeline-mobile .info_price {
    display: flex;
    height: 4rem;
    color: orange;
    width: auto;
    margin-left: auto;
    align-self: flex-end;
    justify-self: flex-end;
  }
  .timeline-mobile .info_price h6 {
    font-size: 1.6rem;
  }
  .timeline-mobile .profile {
    display: flex;
    gap: 1rem;
    width: 100%;
    height: -moz-max-content;
    height: max-content;
  }
  .timeline-mobile .profile .name p {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgb(44, 44, 44);
  }
  .timeline-mobile .profile .avatar {
    width: 5rem;
    height: 5rem;
  }
  .timeline-mobile .profile .avatar img {
    border-radius: 50%;
  }
  .hero-text-container h5 {
    width: unset;
  }
  .hero-text-container p {
    width: unset;
  }
  .hero-text-container .search-container {
    width: unset;
  }
  .hero-main-container {
    height: -moz-max-content !important;
    height: max-content !important;
    padding-bottom: 10rem;
  }
}/*# sourceMappingURL=mobile.css.map */