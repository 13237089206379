.balance {
  height: fit-content;
  width: 36.6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .mainBalance {
    position: relative;
    height: 22.8rem;
    background-color: #ffe7d6;
    border-radius: 8px;
    flex-direction: column;
    display: flex;

    .btn-add-money {
      width: 100%;
    }

    .left {
      margin: 3rem;
      display: flex;
      flex-direction: column;
      align-content: space-around;

      h2 {
        font-size: 3.2rem;
        font-weight: normal;
        margin-top: 2rem;
      }

      p {
        font-size: 1.6rem;
      }
    }

    .right {
      flex-direction: row;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 2rem;
      gap: 2rem;
    }
  }

  .details {
    display: flex;
    margin-top: 3rem;
    width: 100%;
    justify-content: space-between;

    .withdrawn,
    .pending {
      display: flex;
      background-color: #f0f0f0;
      border-radius: 0.8rem;
      padding: 1rem 2rem;
      align-items: center;

      p {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #929191;
      }

      h2 {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 19px;

        color: #212121;
      }

      .left {
        align-items: flex-start;
        display: flex;

        svg {
          width: 70%;
        }

        &.icon {
          color: #df4309;
        }
      }

      .right {
        align-items: start;
        display: flex;
        flex-direction: column;
        text-align: flex-start;
      }
    }

    // .pending{
    //     background-color: #F0F0F0;
    //     width: 175px;
    //     height: 60px;
    //     border-radius: 8px;
    //     margin-left: 10px;

    //     p, h2{
    //         font-size: 12px;
    //     }

    //     .left, .right{
    //         margin: 10px;
    //     }
  }
}

.modal_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .modal_title {
    display: flex;
    flex-direction: column;

    p {
      font-size: 1.8rem;
      font-weight: 600;
      color: rgba(20, 24, 31, 0.963);
    }

    span {
      position: absolute;
      right: 2rem;
      top: 2rem;
      h6 {
        font-weight: 2.4rem;
        color: #df4309;
      }

      p {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }

  .info_content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span {
      h6 {
        font-weight: 500;
        font-size: 1rem;
        color: #4c4c4c;
        text-transform: uppercase;
      }

      p {
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
  }

  .author_section {
    border-top: 0.5px solid #cccccc;
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      h6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #313030;
        gap: 0.5rem;
        text-transform: uppercase;

        svg {
          height: 1.5rem;
          width: 1.5rem;
          display: grid;
          place-items: center;
        }
      }

      p {
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }
}
